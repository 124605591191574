import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import PillBtn from "../../components/Common/PillBtn";
import StrategyCard from "./Components/StrategyCard";
import { Alert, Card, CardBody } from "reactstrap";
import { RenderIf, getValidStores, isUserHaveFullAccess } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import StrategyModal from "./Components/StrategyModal";
import CreateRepricerStrategiesForm from "./Components/CreateRepricerStrategiesForm";
import { useFormik } from "formik";
import * as actions from "../../store/repricer/actions";
import $ from "lodash";
import SimpleSelect from "../../components/Common/SimpleSelect";
import { setPreloader } from "../../store/actions";
import Preloader from "../../components/Common/Preloader";
import RemoveStrategyModal from "./Components/RemoveStrategyModal";

const RepricerListingsStrategies = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      strategyNameField: "",
      matchingPriceType: "price_below",
      matchingType: "ALL",
      matchingTypeAmount: "",
      beat_by_competition_when_gt_listed_price: "",
      noCompetition: "",
      competitionBelow: "",
      competitionAbove: "",
      matchCompetition: "",
      haveBuyBox: "",
      excludeSellerRating: "",
      whenBuyBoxIsSuppressed: "",
    },
    onSubmit: (values) => null,
    validateOnChange: true,
    validateOnBlur: true,
  });
  const [stepCounter, setStepCounter] = useState(1); // to 1
  const [operation, setOperation] = useState(null);
  const [strategyId, setStrategyId] = useState(null);
  const [strategyOperation, setStrategyOperation] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({});
  const [platformOptions, setPlatformOptions] = useState([
    { label: "Walmart", value: "walmart", show: true },
    { label: "Amazon", value: "amazon", show: true },
  ]);
  const filteredPlatforms = platformOptions.filter(platform => platform.show);
  const [selectedPlatform, setSelectedPlatform] = useState(filteredPlatforms[0]);
  const {
    strategyModalIsOpen,
    removeStrategyModalIsOpen,
    strategies,
    strategiesStats,
    strategiesLoading,
    strategiesStatsLoading,
    success,
    error,
    removeStrategyError,
  } = useSelector((state) => state.Repricer);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const assignedAccounts = strategiesStats?.strategy_statistics?.[strategyId]?.assigned_accounts;

  useEffect(() => {
    if (!isUserHaveFullAccess("repricer")) return;
    dispatch(actions.fetchListingsFormulas(selectedPlatform.value));
    dispatch(actions.getStrategies(selectedPlatform.value, $.merge({ ...filters })));
    dispatch(actions.getStrategiesStats(selectedPlatform.value));
    dispatch(actions.getAllRepricerAccounts(selectedPlatform.value));
  }, [filters, selectedPlatform]);

  const stores = getValidStores(accounts, { forRepricer: true });
  useEffect(() => {
    const filteredStores = (stores || []).filter(store => store.marketplace === selectedPlatform.value);
    const areAllAccountsArchived = !$.size(filteredStores);

    if ($.size(stores) && areAllAccountsArchived) {
      setPlatformOptions(platformOptions.map(platform => {
        if (platform.value === selectedPlatform.value) {
          platform.show = false;
        }
        return platform;
      }));
      setSelectedPlatform(platformOptions.filter(platform => platform.value !== selectedPlatform.value)[0]);
    }
  }, [JSON.stringify(stores)]);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (!search.trim()) return;
    setFilters({ ...filters, search: search.trim() });
  };

  useEffect(() => {
    dispatch(setPreloader(strategiesLoading));
  }, [strategiesLoading, strategiesStatsLoading]);

  const deleteStrategyMsg = ({ message, data } = {}) =>
    `${message} because this is associated with ${data?.accounts_associated} account(s) and ${data?.products_associated} product(s).`;

  const Error = ({ isShow, error }) => (
    <RenderIf isTrue={isShow}>
      <div className="auto-hide">
        <Alert color="danger" className="my-1">
          <i className="bx bx-info-circle pr-2"></i>
          {error || ""}
        </Alert>
      </div>
    </RenderIf>
  );

  return (
    <>
      <RenderIf isTrue={strategiesLoading || strategiesStatsLoading}>
        <Preloader />
      </RenderIf>
      <RenderIf isTrue={success}>
        <div className="auto-hide">
          <Alert color="success" className="my-1">
            <i className="bx bx-check pr-2"></i>
            {success || ""}
          </Alert>
        </div>
      </RenderIf>
      <Error isShow={!!error} error={error || ""} />
      <Error
        isShow={removeStrategyError?.message === "You cannot delete this strategy"}
        error={deleteStrategyMsg(removeStrategyError) || ""}
      />
      <RenderIf
        isTrue={strategyOperation}
        fallback={
          <>
            <RenderIf isTrue={strategyModalIsOpen}>
              <StrategyModal
                title={operation}
                strategyId={strategyId}
                platform={selectedPlatform.value}
                assignedAccounts={assignedAccounts}
              />
            </RenderIf>
            <RenderIf isTrue={removeStrategyModalIsOpen}>
              <RemoveStrategyModal
                strategyId={strategyId}
                platform={selectedPlatform.value}
              />
            </RenderIf>
            <Breadcrumb
              title="Strategies"
              children={
                <div className="d-flex flex-wrap align-items-baseline">
                  <div className="d-flex inputs-group mr-3">
                    <div className="d-flex align-items-center">
                      <i className="ml-3 font-size-16 bx bx bx-search" />
                    </div>
                    <form className="app-search p-0 d-lg-block" onSubmit={onSearchSubmit}>
                      <div className="position-relative">
                        <i
                          onClick={onSearchSubmit}
                          className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                        />
                        <input
                          disabled={strategiesLoading || strategiesStatsLoading}
                          id="search_field"
                          type="text"
                          className="form-control search-input"
                          placeholder="Search Strategy..."
                          value={search}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setFilters({ search: "" });
                              setSearch("");
                            } else setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="mr-3">
                    <SimpleSelect
                      className="mx-1"
                      name="Platform"
                      value={selectedPlatform}
                      onChange={setSelectedPlatform}
                      options={filteredPlatforms}
                      formStyle={{ width: "132px" }}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div>
                    <PillBtn
                      disabled={!selectedPlatform?.show}
                      className="mx-1"
                      title="Add New Strategy"
                      name="Add New Strategy"
                      color="primary"
                      icon="bx bx-xs bx-plus"
                      onClick={() => {
                        setStrategyOperation("Create");
                        setStrategyId(null);
                      }}
                    />
                  </div>
                </div>
              }
            />
            <RenderIf
              isTrue={!$.isEmpty(strategies)}
              fallback={
                <RenderIf isTrue={!strategiesLoading && $.isEmpty(strategies) && !$.isUndefined(strategies)}>
                  <Card>
                    <CardBody>
                      <h2 className="d-flex justify-content-center">No Records Found</h2>
                    </CardBody>
                  </Card>
                </RenderIf>
              }
            >
              <>
                {strategies?.map((cardData, index) => {
                  const cardParams = {
                    id: cardData.id,
                    title: cardData.name,
                    beatByCompetitor: cardData.beat_by_competitor,
                    competitionBelow: cardData.competition_below_min_price,
                    competitionAbove: cardData.competition_above_listed_price,
                    beat_by_competition_when_gt_listed_price: cardData.beat_by_competition_when_gt_listed_price,
                    haveBuyBox: cardData.maximise_profit,
                    noCompetition: cardData.no_competition,
                    matchCompetition: cardData.competition_matches_min_price,
                    minimumValue: cardData.min_value,
                    maximumValue: cardData.max_value,
                    minimumPercentage: cardData.min_profit,
                    maximumPercentage: cardData.max_profit,
                    minimumHybridValue: cardData.min_value,
                    maximumHybridValue: cardData.max_value,
                    minimumHybridPercentage: cardData.min_profit,
                    maximumHybridPercentage: cardData.max_profit,
                    minMaxType: cardData.min_max_type,
                    minPriceFormula: cardData.min_price_formula,
                    maxPriceFormula: cardData.max_price_formula,
                    excludeSellerRating: cardData.exclude_seller_rating,
                    whenBuyBoxIsSuppressed: cardData.when_buybox_is_suppressed,
                  };
                  return (
                    <StrategyCard
                      key={index}
                      cardParams={cardParams}
                      setStrategyId={setStrategyId}
                      setStrategyOperation={setStrategyOperation}
                      setOperation={setOperation}
                      formik={formik}
                      selectedPlatform={selectedPlatform.value}
                    />
                  );
                })}
              </>
            </RenderIf>
          </>
        }
      >
        <CreateRepricerStrategiesForm
          stepCounter={stepCounter}
          setStepCounter={setStepCounter}
          strategyId={strategyId}
          setStrategyId={setStrategyId}
          title={strategyOperation}
          setStrategyOperation={setStrategyOperation}
          setOperation={setOperation}
          formik={formik}
          selectedPlatform={selectedPlatform.value}
        />
      </RenderIf>
    </>
  );
};

export default RepricerListingsStrategies;
