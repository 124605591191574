import React, { useEffect, useState, useRef, Suspense } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

// actions
import {
  fetchSalesGraph,
  fetchExpensesGraph,
  fetchAllTenantSalesGraph,
  fetchAllTenantExpensesGraph,
} from "../../../store/actions";
import { formatNumber, RenderIf } from "../../../utils/common";
import { DATE_FORMATS } from "../../../constants";

const { DATE, GRAPH_DATE } = DATE_FORMATS;

const DateRange = React.lazy(() => import("../../../components/Common/DateRange"));
const ChartName = React.lazy(() => import("./ChartName"));

const SalesAndExpensesGraph = (props) => {
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const store = props.marketplace;
  const { salesGraph, expensesGraph, salesGraphLoader } = useSelector((state) => state.Graph);
  const currentDate = moment().startOf("day");
  const oneWeekBefore = moment().subtract(6, "days").startOf("day");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);

  const fetchGraphDetails = ({ startDate, endDate }) => {
    const sharedParams = { start_date: startDate, end_date: endDate };
    if (props.type === "global") {
      dispatch(fetchAllTenantSalesGraph({ ...sharedParams, store_name: store }));
      dispatch(fetchAllTenantExpensesGraph({ ...sharedParams, store_name: store }));
    } else {
      dispatch(
        fetchSalesGraph({
          ...sharedParams,
          marketplace_id: accountId,
          utc_offset: moment().format("Z"),
        }),
      );
      dispatch(
        fetchExpensesGraph({
          ...sharedParams,
          marketplace_id: accountId,
          utc_offset: moment().format("Z"),
        }),
      );
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    !salesGraphLoader &&
      fetchGraphDetails({
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
      });
  }, [dates[0], dates[1], store]);

  const series = [
    {
      name: "Sales",
      data: (salesGraph?.data?.length && salesGraph.data.map((x) => [x?.date, parseFloat(x.total).toFixed(2)])) || [
        [dates[0]],
        [dates[1]],
      ],
    },
    {
      name: "Expenses",
      data: (expensesGraph?.data?.length &&
        expensesGraph.data.map((x) => [x?.date, parseFloat(x.total).toFixed(2)])) || [[dates[0]], [dates[1]]],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No Sales Data for given date range",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#8ced5c", "#f46a6a"],
    xaxis: {
      type: "datetime",
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE) }, // Timestamp is ALWAYS UTC
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatNumber(value);
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: GRAPH_DATE,
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <div className="mb-4 d-flex justify-content-between">
          <Suspense fallback={<></>}>
            <RenderIf isTrue={!salesGraphLoader} fallback={<h4 className="card-title">Loading...</h4>}>
              <>
                <ChartName
                  name="Total Sales"
                  dates={dates}
                  totalOrders={
                    formatNumber(
                      salesGraph?.data?.reduce((acc, x) => acc + parseFloat(x.total), 0).toFixed(2) || 0,
                      "currency",
                    )
                  }
                />
                <ChartName
                  name="Total Expenses"
                  dates={dates}
                  totalOrders={
                    formatNumber(
                      expensesGraph?.data?.reduce((acc, x) => acc + parseFloat(x.total), 0).toFixed(2) || 0,
                      "currency",
                    )
                  }
                />
              </>
            </RenderIf>
            <DateRange dates={dates} setDates={setDates} loader={salesGraphLoader} timePeriods={[7, 30, 365]} />
          </Suspense>
        </div>
        <div className="clearfix"></div>
        <ReactApexChart options={options} series={series} type="line" height="350" />
      </CardBody>
    </Card>
  );
};

export default SalesAndExpensesGraph;
