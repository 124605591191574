import React, { useState, useEffect } from "react";
import { Row, Button, Input, Label } from "reactstrap";
import Modal from "../../components/Common/Modal";
import { startCase } from "lodash";

const initialDimensions = {
  height: "",
  width: "",
  length: "",
  weight: "",
};
const dimensionsArray = Object.keys(initialDimensions);

const getFormattedDimensions = (dimensions) =>
  Object.keys(dimensions).reduce((acc, key) => {
    const value = parseFloat(dimensions[key]);
    if (!isNaN(value) && value !== 0) {
      acc[key] = parseFloat(value?.toFixed(2));
    }
    return acc;
  }, {});

const RowInput = ({ label, name, step, value, onChange, errorMessage }) => {
  return (
    <Row>
      <Label>{label}</Label>
      <Input type="number" name={name} step={step} value={value} onChange={onChange} />
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </Row>
  );
};

const DimensionModal = ({ isOpen, toggleDimensionModal, data, updateProduct, storeFront }) => {
  const [dimensions, setDimensions] = useState(initialDimensions);
  const [errors, setErrors] = useState(initialDimensions);

  useEffect(() => {
    if (data?.dimension) {
      const newDimensions = getFormattedDimensions(data.dimension);
      setDimensions(newDimensions);
      setErrors(initialDimensions);
    }
  }, [data, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = parseFloat(value) <= 0 ? `${startCase(name)} must be greater than 0` : "";
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
    setDimensions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const dimensionsToUpdate = getFormattedDimensions(dimensions);
    updateProduct("dimension", data.id, dimensionsToUpdate, storeFront);
    toggleDimensionModal();
  };

  const hasError = Object.values(errors).some((error) => error !== "");

  return (
    <Modal isOpen={isOpen} toggle={toggleDimensionModal}>
      <div className="modal-header">
        <h5 className="modal-title">Edit Dimensions</h5>
      </div>
      <div className="modal-body">
        {dimensionsArray.map((dimension) => (
          <RowInput
            key={dimension}
            label={startCase(dimension)}
            name={dimension}
            step="0.01"
            value={dimensions[dimension]}
            onChange={handleChange}
            errorMessage={errors[dimension]}
          />
        ))}
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleSubmit} disabled={hasError}>
          Submit
        </Button>
        <Button color="secondary" onClick={toggleDimensionModal}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DimensionModal;
