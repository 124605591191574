import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
  NavItem,
  NavLink,
  Nav,
  Row,
  Container,
  Input,
} from "reactstrap";
import { startCase } from "lodash";

// availity-reactstrap-validation
import { checkStore, setPreloader, changeStoreState } from "../../store/actions";
const marketplaces = ["amazon", "walmart"];

function ScratchPad (_) {
  const dispatch = useDispatch();
  const [marketplace, setMarketplace] = useState(0);
  const { storeStatus, loading } = useSelector((state) => state.MarketPlaceAccount);

  const formik = useFormik({
    initialValues: { access_id: "", secret_key: "" },
    validate: (values) => {
      const errors = {};
      Object.entries(values).forEach((key, val) => !val && (errors[key] = "This field is required"));
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      dispatch(
        checkStore({
          accessId: values.access_id,
          accessSecret: values.secret_key,
          marketplace: marketplaces[marketplace],
        }),
      );
    },
  });

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(changeStoreState({ storeStatus: "" }));
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between m-2">
          <Breadcrumbs title="Scratchpad" />
        </div>
        <Row>
          <Col lg={12}>
            <Nav pills className="navtab-bg nav-justified">
              {marketplaces.map((store, key) => (
                <NavItem key={"_col_" + key}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: key === marketplace })}
                    onClick={() => {
                      setMarketplace(key);
                      formik.setFieldValue("access_id", "");
                      formik.setFieldValue("secret_key", "");
                      dispatch(changeStoreState({ storeStatus: "" }));
                    }}
                  >
                    {startCase(store)}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
        <div className="my-4">
          <form onSubmit={formik.handleSubmit}>
            <Label>{marketplace ? "Access Key" : "Seller Id"}</Label>
            <Input
              name="access_id"
              type="text"
              value={formik.values.access_id}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.access_id && formik.errors.access_id
              ? (
              <span className="text-danger mt-1">{formik.errors.access_id}</span>
                )
              : null}
            <Label className="mt-3">{marketplace ? "Secret Key" : "MWS Token"}</Label>
            <Input
              name="secret_key"
              type="text"
              value={formik.values.secret_key}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.secret_key && formik.errors.secret_key
              ? (
              <span className="text-danger mt-1">{formik.errors.secret_key}</span>
                )
              : null}
            <Button type="submit" color="success" className="mt-3 float-right">
              Check Credentials
            </Button>
          </form>
          {storeStatus
            ? (
            <Row className="m-4">
              <Card>
                <CardHeader>Reponse</CardHeader>
                <CardBody>
                  <pre>
                    <code>{JSON.stringify(storeStatus, null, 2)}</code>
                  </pre>
                </CardBody>
              </Card>
            </Row>
              )
            : null}
        </div>
      </Container>
    </div>
  );
}

export default ScratchPad;
