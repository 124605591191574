import React from "react";
import * as $ from "lodash";
import { amountText, getUserInfo, isAdmin, RenderIf, toUpper } from "../../utils/common";
import CustomTooltip from "../../components/Common/CustomTooltip";
import {
  CHANGE_WH_ALLOWED_USERS,
  SOURCE_ORDER_STATUSES,
  SUPPLIER_NAMES,
  SUPPLIER_ORDER_STATUSES,
} from "../../constants";

const checkIncludes = (collection, target) => $.includes($.lowerCase(collection), target);

const getCommissionPercentage = (items) => {
  const totalPrice = $.sumBy(items, (item) => $.get(item, "qty", 0) * $.get(item, "price", 0));
  const totalCommission = $.sumBy(items, (item) => Math.abs($.get(item, "settlement_details.Sale.fees.Commission", 0)));

  return $.round((Math.abs(totalCommission) / totalPrice) * 100);
};

const displayCategory = (show, item) => (show ? ` (${$.get(item, "category")}): ` : ": ");

const displayPercentage = (show, item) => (show ? ` (${getCommissionPercentage([item])}%)` : "");

export function isAOHidden (sourceOrder) {
  const picked = $.values($.pick(SOURCE_ORDER_STATUSES, "delayed", "in_queue", "processing", "checking"));
  return picked.includes(sourceOrder?.status?.toLowerCase());
}

export function isEditShippingAllowed (sourceOrder, activeTab) {
  const isWHTab = activeTab === 1;
  if (isWHTab && !isAdmin()) return false;
  if (
    isWHTab &&
    !CHANGE_WH_ALLOWED_USERS.includes(getUserInfo()?.user_email) &&
    sourceOrder.supplier_orders?.some((x) => x.status === SUPPLIER_ORDER_STATUSES.processed)
  )
    return false;
  if (isWHTab && sourceOrder.tracking_items?.some((x) => x.wh_tracking_number)) return false;
  const picked = $.values($.omit(SOURCE_ORDER_STATUSES, "shipped", "completed"));
  return picked.includes(sourceOrder?.status?.toLowerCase());
}

export function isStoreFulfilled (props) {
  const channel = props?.SourceOrders?.sourceOrderDetail?.data?.fulfillment_channel;
  return { WFS: "WFS", AFN: "FBA" }[channel];
}

export function isIFulfil (sourceOrder) {
  return (
    sourceOrder?.source_items?.length > 0 &&
    sourceOrder?.source_items?.every((item) => item?.supplier_name === SUPPLIER_NAMES.ifulfill)
  );
}

export function isManualFulfilled (sourceOrder) {
  return (
    sourceOrder?.status !== SOURCE_ORDER_STATUSES.manual_fulfillment ||
    sourceOrder?.order_logs?.some(
      (x) => toUpper(getUserInfo()?.username).includes(x.created_by) && x.note.includes("manual_fulfillment"),
    )
  );
}

export function Anchor (href, text) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
}

export const parseInQueueStatus = (order) =>
  ["checking", "processing", "delayed"].includes(order?.status) ? "in_queue" : order?.status;

export const RightAlignText = ({ heading, text, valueStyle = "" }) => (
  <div className="align-items-center d-flex">
    <h6 className="mb-0 mr-2">{heading}</h6>
    <span className={valueStyle}>{typeof text === "number" ? `${amountText(text || 0)}` : text}</span>
  </div>
);

export const ColouredRightAlignText = ({ amount }) => (
  <RightAlignText heading="Total:" text={amount} valueStyle={amount < 0 ? "text-danger" : "text-success"} />
);

export const ConditionalColouredValue = ({ title, value }) => {
  const hasTaxPromotionOrVat = ["tax", "promotion", "vat"].some(keyword => checkIncludes(title, keyword));

  return (
    <RenderIf isTrue={$.round(value, 2)}>
      <div className="d-flex mb-2">
        <h6 className="w-100">{$.startCase(title)}</h6>
        <span
          className={
            "text-" +
            (hasTaxPromotionOrVat ? "warning" : value < 0 ? "danger" : "success")
          }
        >
          {amountText(value)}
        </span>
      </div>
    </RenderIf>
  );
};

export const FeesTooltip = ({ index, underline = true, sourceItem, title, fees, total }) => (
  <p className="my-1 cursor-pointer">
    <span className="d-flex align-items-center gap-1">
      <RenderIf isTrue={underline} fallback={title}>
        <u>{title}</u>
      </RenderIf>
      <CustomTooltip
        placement="bottom"
        style={{ maxWidth: "fit-content" }}
        content={
          <div className="p-0">
            <div className="d-flex mb-3 font-weight-semibold font-size-16">Marketplace Fees Details</div>
            {fees
              .filter(({ value }) => $.round(value, 2))
              ?.map((fee, innerIndex) => {
                const showCategory = sourceItem && $.lowerCase(fee.title).includes("commission");
                return (
                  <div key={innerIndex} className="font-weight-medium font-size-12 mb-3">
                    <div className="d-flex justify-content-between gap-3" style={{ minWidth: "max-content" }}>
                      <span>{`${$.startCase(fee.title)}${displayCategory(showCategory, sourceItem)}`}</span>
                      <span className={fee.value < 0 ? "text-danger" : "text-success"}>{`${amountText(
                        fee.value,
                      )}${displayPercentage(showCategory, sourceItem)}`}</span>
                    </div>
                    <hr className="m-0 mt-1" />
                  </div>
                );
              })}
            <div className="d-flex justify-content-between font-weight-semibold font-size-16">
              <span>Total:</span>
              <span className={total < 0 ? "text-danger" : "text-success"}>{amountText(total)}</span>
            </div>
          </div>
        }
        target={`tooltip-${$.kebabCase(index || title)}`}
        modifiers={{ preventOverflow: { enabled: false }, fade: { enabled: false } }}
      />
      <i
        id={`tooltip-${$.kebabCase(index || title)}`}
        className="bx bx-xs bx-info-circle text-primary cursor-pointer"
      />
    </span>
  </p>
);

export const CategoriesTooltip = ({ sourceItems }) => {
  const breakdown = $.reduce(
    $.groupBy(sourceItems, "category"),
    (breakdown, items, category) => ({ ...breakdown, [category]: getCommissionPercentage(items) }),
    {},
  );

  return (
    <>
      <CustomTooltip
        target="category-tooltip"
        placement="bottom"
        style={{ maxWidth: "fit-content" }}
        content={
          <div className="d-flex flex-column align-items-center justify-content-center py-1">
            <h1 className="mb-3 font-weight-semibold font-size-16">Categorywise Breakdown</h1>
            {$.map(breakdown, (value, category) => (
              <div key={category} className="w-100 mb-2">
                <div className="mb-1 d-flex align-items-center justify-content-between gap-3 font-weight-medium font-size-12">
                  <span>{category}:</span>
                  <span className={value < 0 ? "text-danger" : "text-success"}>{value}%</span>
                </div>
                <hr className="m-0" />
              </div>
            ))}
          </div>
        }
      />
      <i id="category-tooltip" className="bx bx-xs bx-info-circle text-primary cursor-pointer" />
    </>
  );
};

export const ShowProfit = ({ order }) => {
  const profit = order.order_profit || 0;
  return <td className={`text-${profit >= 0 ? "success" : "danger"}`}>{amountText(profit)}</td>;
};
