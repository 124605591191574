import React, { useEffect, useState } from "react";
import { getDateParamObj, options } from "../common";
import * as $ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { RenderIf, isProfitCalculationAllowed, formatNumber, amountText } from "../../../utils/common";
import { fetchSalesRevenueStats } from "../../../store/actions";
import moment from "moment";
import DateRange from "../../../components/Common/DateRange";

const statsColorsMapping = {
  profit: { title: "Profit", color: "success", icon: "bxs-check-square" },
  sales: { title: "Revenue", color: "primary", icon: "bxs-check-square" },
  expenses: { title: "Supplier COGS", color: "warning", icon: "bxs-check-square" },
  mp_fees: { title: "Marketplace Fees", color: "danger", icon: "bxs-check-square" },
  wh_costs: { title: "Shipping & Handling", color: "parrot", icon: "bxs-check-square" },
  refunds: { title: "Returns", color: "purple", icon: "bxs-check-square" },
};

export default function SalesRevenueChart ({ storeId, utcOffset }) {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([moment().subtract(6, "days").startOf("days"), moment().startOf("days")]);
  const { salesRevenueStats, salesRevenueStatsLoading } = useSelector((state) => state.Graph);
  const [profits, sales, supplierCost, whCosts, mpComission, refund] = [
    { type: "profits", key: "total" },
    { type: "sales", key: "total" },
    { type: "expenses", key: "total" },
    { type: "wh_costs", key: "fee" },
    { type: "mp_fees", key: "fee" },
    { type: "refunds", key: "total" },
  ].map(({ type, key }) => $.map(salesRevenueStats[type], (stats) => ({ x: stats.date, y: stats[key] })));

  const filterData = (name) => (["Supplier COGS", "Profit"].includes(name) ? isProfitCalculationAllowed() : true);

  const data = [
    { name: "Revenue", data: sales },
    { name: "Supplier COGS", data: supplierCost },
    { name: "Shipping & Handling", data: whCosts },
    { name: "Marketplace Fees", data: mpComission },
    { name: "Profit", data: profits },
    { name: "Returns", data: refund },
  ].filter((x) => filterData(x.name));
  const [whCostTotal, refundsTotal] = $.map(
    ["wh_costs", "refunds"],
    (key) => salesRevenueStats?.impressions?.[key]?.total,
  );

  useEffect(() => {
    if (!storeId) return;

    dispatch(fetchSalesRevenueStats(getDateParamObj({ marketplace_id: storeId, dates, utcOffset })));
  }, [storeId, dates, utcOffset]);

  return (
    <>
      <Breadcrumb
        isCapitalize={true}
        title="Sales Revenue"
        responsive
        children={
          <RenderIf isTrue={storeId}>
            <div className="d-flex flex-row float-sm-right">
              <DateRange
                dates={dates}
                setDates={setDates}
                loader={salesRevenueStatsLoading}
                timePeriods={[7, 30, 365]}
              />
            </div>
          </RenderIf>
        }
      />
      <div className={`row ${salesRevenueStatsLoading ? "justify-content-center" : ""}`}>
        <RenderIf
          isTrue={!salesRevenueStatsLoading}
          fallback={<Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />}
        >
          <div className="col-xl-2 col-md-3 col-sm-4" style={{ borderRight: "1px solid #A6B0CF33" }}>
            {$.map(
              $.pick(salesRevenueStats?.impressions, ["profit", "sales", "expenses", "wh_costs", "mp_fees", "refunds"]),
              ({ imp_typ: impression, value, total }, type) => {
                const statProps = statsColorsMapping[type] || {};
                return (
                  <RenderIf
                    key={"___" + type + "___"}
                    isTrue={
                      (["Shipping & Handling", "Returns"].includes(statProps.title) ? total : true) &&
                      statsColorsMapping[type] &&
                      filterData(statProps.title)
                    }
                  >
                    <div className="mr-2 mt-3 d-flex">
                      <div>
                        <i className={`bx bx-xs bxs-check-square text-${statProps.color}`}></i>
                      </div>
                      <div>
                        <div>
                          <label className="mx-1 form-check-label font-size-13 mb-1" htmlFor="flexCheckDefault">
                            {statProps.title}
                          </label>
                        </div>
                        <div className="mx-1 d-flex inventory-color font-size-16">
                          {amountText(total || 0, true)}
                          &nbsp;&nbsp;
                          <div className="mx-1 font-size-12 d-flex align-items-center">
                            <span className="card-text">{formatNumber(value || 0, "float")}%</span>
                            <i
                              className={`bx bx-sm bx-${impression}-arrow-alt text-${
                                impression === "up" ? "success" : "danger"
                              }`}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </RenderIf>
                );
              },
            )}
          </div>

          <div className="col-xl-10 col-md-9 col-sm-8">
            <ReactApexChart
              options={{
                ...options,
                colors: $.compact([
                  "#556ee6",
                  "#f1b44c",
                  whCostTotal && "#25C35C",
                  "#f46a6a",
                  "#34c38f",
                  refundsTotal && "#9D60FB",
                ]),
                tooltip: {
                  y: {
                    formatter: function (value) {
                      return amountText(value);
                    },
                  },
                },
              }}
              series={data.filter(
                (x) =>
                  !["Shipping & Handling", "Returns"].includes(x.name) ||
                  (x.name === "Shipping & Handling" && whCostTotal) ||
                  (x.name === "Returns" && refundsTotal),
              )}
              type="area"
              height="350"
            />
          </div>
        </RenderIf>
      </div>
    </>
  );
}
