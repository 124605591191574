import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Alert,
} from "reactstrap";
import {
  fetchInventoryItems,
  setPreloader,
  updateInventoryItemStatus,
  setSelectedInventoryItem,
  setDownloadLabelModal,
  fetchUserWarehouses,
  downloadInventoryReport,
  setAddInventoryModal,
  setUpdateTenantModal,
  fetchWarehouseAccounts,
  setInventoryCheckBoxes,
  resetErrorSuccessStates,
} from "../../../store/actions";
import DownloadReturnLabelModal from "./DownloadReturnLabelModal";
import AddInventoryModal from "./AddInventoryModal";
import NoteTrackingItemModal from "./AddWarehouseNoteModal";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CustomPagination from "../../../components/Common/CustomPagination";
import UploadReturnLabelModal from "./UploadReturnLabel";
import {
  getDate,
  getMenuPlacement,
  getUserInfo,
  getWarehouseName,
  RenderIf,
  toPascalCase,
  amountText,
} from "../../../utils/common";
import {
  DATE_FORMATS,
  INVENTORY_ITEM_TYPES,
  INVENTORY_PRODUCTS_STATUSES,
  INVENTORY_WH_STATUS,
  LABEL_UPLOAD_TYPES,
  USER_ROLES,
  WH_NOTICES_TYPES,
} from "../../../constants/index";
import { keys, merge, pick, some, startCase, values, omit } from "lodash";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { DatePicker } from "../../../components/Common/DatePickr";
import returnPickUpIcon from "../../../assets/svg/warehouse/delivery_truck.svg";
import PillBtn from "../../../components/Common/PillBtn";
import AddBinNoModal from "./AddBinNo";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import ShowUpc from "../../../components/Common/ShowUpc";
import CheckBox from "../../../components/Common/CheckBox";
import UpdateTenantModal from "./UpdateTenantModal";
import AddReturnModal from "../../Returns/components/Common/AddReturnModal";

const { DATE_TIME } = DATE_FORMATS;

const fieldsMapping = { "Date Added": "created_at", "Shipped Date": "shipped_date", "Last Updated At": "updated_at" };

const tableHeaders = [
  "Title",
  "Qty",
  "UPC",
  "Account ID",
  "Marketplace Order Id",
  "Tracking Number",
  "Customer Name",
  ...keys(fieldsMapping),
  "Price",
  "Shipping",
  "Inventory Status",
  "Bin #",
  "Type",
  "Warehouse",
  "WH Status",
  "Actions",
];

const returnPickupOpts = { "With Return Pickup": "true", "Without Return Pickup": "false" };

const InventoryItems = (_) => {
  const dispatch = useDispatch();
  const {
    warehouseInventory,
    loading,
    success,
    error,
    userWarehouses,
    binNoModalIsOpen,
    warehouseNoteModalIsOpen,
    wareHouseAccounts,
    uploadReturnLabelModalIsOpen,
    updateTenantModalIsOpen,
    selectedInventoryItem,
    downloadLabelModalIsOpen,
    addInventoryModalIsOpen,
    inventoryCheckBoxes,
  } = useSelector((state) => state.WareHouses);
  const { tenantsData } = useSelector((state) => state.Settings);
  const {
    addReturnModal,
    success: inventorySuccess,
    loading: inventoryLoading,
  } = useSelector((state) => state.Returns);
  const isWH = getUserInfo()?.isWH;
  const sortCount = useRef(0);
  const [search, setSearch] = useState("");
  const [customFilters, setCustomFilters] = useState({});
  const [filters, setFilters] = useState({ page: 1, per_page: 20 });

  function handleUpdateTenant () {
    dispatch(setUpdateTenantModal(true));
  }

  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;

  const getSortFilter = (title, dir) => {
    setFilters({
      ...filters,
      sort_by: fieldsMapping[title],
      sort_dir: !dir || dir === "desc" ? "asc" : "desc",
      filter_index: 0,
    });
  };

  const handleChange = (e, field) => {
    setCustomFilters({ ...customFilters, [field]: e });
    if (e.value !== customFilters[field]) setFilters({ ...filters, [field]: e.value });
  };

  const SortArrows = ({ title }) => {
    const sortOpts = [
      ["asc", "up"],
      ["desc", "down"],
    ];
    const isSameCol = fieldsMapping[title] === filters.sort_by;
    return (
      <span
        onClick={() => {
          if (fieldsMapping[title] === filters.sort_by) sortCount.current++;
          else sortCount.current = 1;
          if (sortCount.current === 3) setFilters({ ...filters, sort_by: null, sort_dir: null });
          else getSortFilter(title, isSameCol && sortCount.current < 3 ? filters.sort_dir : null);
        }}
      >
        {sortOpts
          .filter(([dir]) => (isSameCol ? dir === filters.sort_dir : true))
          .map(([, arrow], index) => (
            <i
              key={"_sort_opts_" + index}
              id={arrow + "-filter"}
              style={{ fontSize: "10px" }}
              className={`dripicons-arrow-thin-${arrow} cursor-pointer`}
            ></i>
          ))}
      </span>
    );
  };

  const getStatusLabel = (status) =>
    values(pick(INVENTORY_PRODUCTS_STATUSES, "fba", "wfs")).includes(status)
      ? status.toUpperCase()
      : toPascalCase(getText(status));

  useEffect(() => {
    dispatch(setPreloader(loading || inventoryLoading));
  }, [loading, inventoryLoading]);

  useEffect(() => {
    dispatch(resetErrorSuccessStates());
    dispatch(fetchUserWarehouses());
    dispatch(setInventoryCheckBoxes({}));
    !wareHouseAccounts.length && dispatch(fetchWarehouseAccounts());
  }, []);

  useEffect(() => {
    const query = queryString.parse(document.location.search);
    if (query.search && filters.search !== query.search) setFilters({ ...filters, page: 1, search: query.search });
    else if (filters.search) setFilters({ ...filters, search: "" });
  }, [document.location.search]);

  useEffect(() => {
    dispatch(fetchInventoryItems(filters));
  }, [filters, wareHouseAccounts.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error, success]);

  const getText = (text) => text || "N/A";
  const updateProduct = (data, id) => dispatch(updateInventoryItemStatus({ data, id, filters }));

  const ActionButtons = ({ item }) => {
    return (
      <td>
        <div className="d-flex align-items-center">
          <RenderIf isTrue={item.labels}>
            <i
              title="Download Return Label"
              className="bx bx-sm bx bx-download pl-2 text-primary cursor-pointer"
              onClick={() => {
                dispatch(setSelectedInventoryItem(item));
                dispatch(setDownloadLabelModal(true));
              }}
            ></i>
          </RenderIf>
        </div>
      </td>
    );
  };

  return (
    <React.Fragment>
      <Alert color="warning">
        <i className="bx bx-info-circle pr-2"></i>
        All actions have been removed from this page and have been moved to <Link to="/warehouse_dashboard">New Inventory Flow</Link>.
      </Alert>
      {warehouseNoteModalIsOpen && (
        <NoteTrackingItemModal
          notice_type={WH_NOTICES_TYPES.inventory_products}
          notices={selectedInventoryItem?.warehouse_notices}
          selectedItem={selectedInventoryItem}
          warehouses={userWarehouses}
        />
      )}
      {binNoModalIsOpen && (
        <AddBinNoModal selectedItem={selectedInventoryItem} type="inventory" action="edit" filters={filters} />
      )}
      {updateTenantModalIsOpen && <UpdateTenantModal filters={filters} setFilters={setFilters} />}
      {uploadReturnLabelModalIsOpen && (
        <UploadReturnLabelModal item={selectedInventoryItem} type={LABEL_UPLOAD_TYPES.inventory} />
      )}
      {downloadLabelModalIsOpen && (
        <DownloadReturnLabelModal item={selectedInventoryItem} type={LABEL_UPLOAD_TYPES.inventory} filters={filters} />
      )}
      <RenderIf isTrue={addReturnModal}>
        <AddReturnModal />
      </RenderIf>
      {addInventoryModalIsOpen && <AddInventoryModal filters={filters} />}
      <div className="page-content">
        {(success || inventorySuccess) && !updateTenantModalIsOpen && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success || inventorySuccess}
            </Alert>
          </div>
        )}
        {error && !updateTenantModalIsOpen && (
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}
        <Container fluid>
          <Breadcrumbs
            title="Warehouse Storage"
            children={
              <div className="d-flex align-items-center">
                <RenderIf isTrue={keys(inventoryCheckBoxes).length > 0}>
                  <PillBtn
                    className="mr-2"
                    style={{ width: "180px", height: "40px" }}
                    title="Move to Other User"
                    name="Move to Other User"
                    color="primary"
                    icon="mdi mdi-cart-arrow-down bx-xs"
                    onClick={handleUpdateTenant}
                  />
                </RenderIf>
                <RenderIf isTrue={false}>
                  <PillBtn
                    style={{ height: "40px" }}
                    title="Add Inventory Item"
                    name="Add Inventory Item"
                    color="primary"
                    icon="mdi mdi-cart-arrow-down bx-xs"
                    className="ml-1"
                    onClick={() => dispatch(setAddInventoryModal(true))}
                  />
                </RenderIf>
                <RenderIf isTrue={isAdmin}>
                  <i
                    title="Export to XLSX"
                    className="fas fa-file-export bx-md pl-2 text-primary cursor-pointer"
                    onClick={() => dispatch(downloadInventoryReport(pick(filters, "warehouse_id")))}
                  ></i>
                </RenderIf>
                {wareHouseAccounts?.length > 0 && isAdmin && (
                  <div style={{ marginTop: "-0.125rem" }}>
                    <SimpleSelect
                      name="account_id"
                      placeholder="All Accounts"
                      className="col mb-0"
                      onChange={(acc) => {
                        const newF = omit(
                          { ...filters, account_id: acc.value, page: 1 },
                          acc.value ? "" : "account_id",
                        );
                        setFilters(newF);
                      }}
                      options={[{ value: "", label: "All Accounts" }].concat(
                        wareHouseAccounts?.map((x) => ({ value: x.id, label: x.email })),
                      )}
                      formStyle={{ minWidth: "250px", marginBottom: 0 }}
                    />
                  </div>
                )}
              </div>
            }
          />
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (search.trim()) {
                      setFilters({ ...filters, search: search.trim(), page: 1 });
                    }
                  }}
                >
                  <div className="position-relative mr-2" style={{ width: "310px" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search SKU, Order ID, Tracking #, UPC"
                      maxLength="40"
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          delete filters.search;
                          setSearch("");
                          setFilters({ ...filters, page: 1 });
                        } else setSearch(e.target.value);
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
                <div className="d-flex mt-3 flex-wrap">
                  <RenderIf
                    isTrue={!isWH}
                    children={
                      <SimpleSelect
                        name="warehouse_id"
                        className="pr-2"
                        value={{
                          value: customFilters.warehouse_id?.value || "",
                          label: customFilters.warehouse_id?.label || "Select Warehouse",
                        }}
                        onChange={(e) => handleChange(e, "warehouse_id")}
                        options={Object.keys(userWarehouses || {}).reduce(
                          (acc, x) => {
                            acc.push({ label: getWarehouseName(userWarehouses[x]), value: userWarehouses[x].id });
                            return acc;
                          },
                          filters.warehouse_id ? [{ value: "", label: "All Warehouses" }] : [],
                        )}
                        formStyle={{ width: "200px" }}
                      />
                    }
                  ></RenderIf>
                  <SimpleSelect
                    name="is_return_pickup"
                    className="pr-2"
                    value={{
                      value: customFilters.is_return_pickup?.value || "",
                      label: customFilters.is_return_pickup?.label || "Select Pickup Option",
                    }}
                    onChange={(e) => handleChange(e, "is_return_pickup")}
                    options={Object.entries(returnPickupOpts).reduce(
                      (acc, [label, value]) => {
                        acc.push({ label, value });
                        return acc;
                      },
                      filters.is_return_pickup ? [{ value: "", label: "All Pickup Options" }] : [],
                    )}
                    formStyle={{ width: "200px" }}
                  />
                  <SimpleSelect
                    className="pr-2"
                    name="type"
                    value={{
                      value: customFilters.type?.value || "",
                      label: customFilters.type?.label || "Select Type",
                    }}
                    onChange={(e) => handleChange(e, "type")}
                    options={values(INVENTORY_ITEM_TYPES).reduce(
                      (acc, x) => {
                        acc.push({ value: x, label: startCase(x) });
                        return acc;
                      },
                      filters.type ? [{ value: "", label: "All Item Types" }] : [],
                    )}
                    formStyle={{ width: "200px" }}
                  />
                  <SimpleSelect
                    className="pr-2"
                    name="wh_status"
                    value={{
                      value: customFilters.wh_status?.value || "",
                      label: customFilters.wh_status?.label || "Select WH Status",
                    }}
                    onChange={(e) => handleChange(e, "wh_status")}
                    options={values(INVENTORY_WH_STATUS).reduce(
                      (acc, x) => {
                        acc.push({ value: x, label: startCase(x) });
                        return acc;
                      },
                      filters.wh_status ? [{ value: "", label: "All WH Statuses" }] : [],
                    )}
                    formStyle={{ width: "200px" }}
                  />
                  <SimpleSelect
                    className="pr-2"
                    name="status"
                    value={{
                      value: customFilters.status?.value || "",
                      label: customFilters.status?.label || "Select Inventory Status",
                    }}
                    onChange={(e) => handleChange(e, "status")}
                    options={values(INVENTORY_PRODUCTS_STATUSES).reduce(
                      (acc, x) => {
                        acc.push({ value: x, label: getStatusLabel(x) });
                        return acc;
                      },
                      filters.status ? [{ value: "", label: "All Inventory Statuses" }] : [],
                    )}
                    formStyle={{ width: "210px" }}
                  />
                  <DatePicker onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })} />
                </div>
              </div>
            </CardHeader>
            {Array.isArray(warehouseInventory?.data) && warehouseInventory?.data?.length
              ? (
              <CardBody>
                <div className="table-responsive" style={{ minHeight: "220px" }}>
                  <table className="table table-centered select table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {isAdmin && (
                          <th>
                            <CheckBox
                              state={keys(inventoryCheckBoxes).length === warehouseInventory?.data?.length}
                              setState={(_) => {
                                const { data } = warehouseInventory;
                                if (keys(inventoryCheckBoxes).length === data.length)
                                  dispatch(setInventoryCheckBoxes({}));
                                else
                                  dispatch(
                                    setInventoryCheckBoxes(
                                      data?.reduce((acc, cur) => merge(acc, { [cur.id]: true }), {}),
                                    ),
                                  );
                              }}
                              isSwitch={false}
                            />
                          </th>
                        )}
                        {tableHeaders
                          .filter((x) => x !== "Warehouse" || !isWH)
                          .map((header, index) => {
                            if (header === "Warehouse" && isWH) return null;
                            if (header === "Bin #" && !isWH) return null;
                            if (header.includes("Account")) header = isWH ? "Account ID" : "Account Email";
                            return (
                              (header !== "Status" || !filters.status.value) && (
                                <th key={`table-header ${index}`}>
                                  {header}
                                  <RenderIf isTrue={keys(fieldsMapping).includes(header)}>
                                    <div className="d-inline">
                                      <SortArrows title={header} />
                                    </div>
                                  </RenderIf>
                                </th>
                              )
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {warehouseInventory.data.map((item, key) => (
                        <tr key={"_inventory_items_" + key}>
                          {isAdmin && (
                            <td>
                              <CheckBox
                                state={inventoryCheckBoxes[item.id] || false}
                                setState={(e) => {
                                  if (inventoryCheckBoxes[item.id]) delete inventoryCheckBoxes[item.id];
                                  else inventoryCheckBoxes[item.id] = true;
                                  dispatch(setInventoryCheckBoxes({ ...inventoryCheckBoxes }));
                                }}
                                isSwitch={false}
                              />
                            </td>
                          )}
                          <td>
                            <div className="d-flex flex-column">
                              <span>
                                {getText(item?.item_name?.slice(0, 75))}
                                {item?.item_name?.length > 75 && "..."}
                              </span>
                              <span>
                                SKU:
                                <span className="inventory-color">{` ${item?.sku}`}</span>{" "}
                                <CopyToClipBoard text={item?.sku} />
                              </span>
                            </div>
                          </td>
                          <td>{getText(item?.qty)}</td>
                          <td>
                            <ShowUpc index={key} upc={item.upc} />
                          </td>
                          <td>{getText(isWH ? item.account_id : tenantsData?.[item.account_id]?.email)}</td>
                          <td>{getText(item?.order_id)}</td>
                          <td>{getText(item?.tracking_number)}</td>
                          <td>{getText(item?.customer_name)}</td>
                          <td>{getDate(item?.created_at, DATE_TIME)}</td>
                          <td>{getDate(item?.shipped_date, DATE_TIME)}</td>
                          <td>{getDate(item?.updated_at, DATE_TIME)}</td>
                          <td>{amountText(item?.price)}</td>
                          <td>{amountText(item?.shipping)}</td>
                          <td>
                            {getStatusLabel(item?.status)}
                            <RenderIf isTrue={some(item?.labels, "is_return_pickup")}>
                              <img className="icon-sizes ml-2" src={returnPickUpIcon} alt="Return Pickup" />
                            </RenderIf>
                          </td>

                          <RenderIf isTrue={isWH}>
                            <td style={{ minWidth: "100px" }}>
                              <div className="d-flex">
                                <div className="d-flex align-items-center">
                                  <RenderIf isTrue={item?.InventoryLocation?.length} fallback="N/A">
                                    <div>
                                      <CustomTooltip
                                        placement="top"
                                        content={item?.InventoryLocation?.map((x, idx) => (
                                          <React.Fragment key={"_bin_qty_" + (idx + 1)}>
                                            Bin #: {x?.bin_no}, Qty: {x?.inventory_locations?.qty}
                                            <br />
                                          </React.Fragment>
                                        ))}
                                        target={`tooltip-${key}`}
                                      />
                                      <span id={`tooltip-${key}`}>
                                        {item?.InventoryLocation?.[0]?.bin_no || "N/A"}
                                        {item?.InventoryLocation?.length > 1 && (
                                          <span className="text-primary">
                                            <br />
                                            more...
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </RenderIf>
                                </div>
                              </div>
                            </td>
                          </RenderIf>
                          <td>{getText(startCase(item?.type))}</td>
                          {!isWH && <td>{getWarehouseName(userWarehouses?.[item?.warehouse_id])}</td>}

                          <td>
                            <RenderIf isTrue={isWH} fallback={startCase(item?.wh_status)}>
                              <SimpleSelect
                                value={{
                                  label: startCase(item?.wh_status) || "Select WH Status",
                                  value: item?.wh_status || "",
                                }}
                                menuPlacement={getMenuPlacement(warehouseInventory.data, key)}
                                onChange={(e) =>
                                  e.value !== item?.wh_status ? updateProduct({ wh_status: e.value }, item.id) : null
                                }
                                name={`__wh_status__${item.id}`}
                                options={Object.values(INVENTORY_WH_STATUS).map((value) => ({
                                  label: startCase(value),
                                  value,
                                }))}
                                formStyle={{ minWidth: "180px", marginBottom: "0" }}
                              />
                            </RenderIf>
                          </td>
                          <ActionButtons item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {warehouseInventory?.data?.length
                  ? (
                  <CustomPagination
                    total={warehouseInventory.count}
                    pageOptions={[20, 50, 100]}
                    page={filters.page}
                    perPage={filters.per_page}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                    )
                  : null}
              </CardBody>
                )
              : null}
            {(!Array.isArray(warehouseInventory?.data) || warehouseInventory?.data?.length === 0) && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InventoryItems;
