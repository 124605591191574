import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import Modal from "../../../components/Common/Modal";

// actions
import {
  setEditModalMarketplaceAccount,
  setAddModalMarketplaceAccount,
  updateMarketplaceAccounts,
  createMarketplaceAccount,
} from "../../../store/actions";

// utils
import { MARKET_PLACES, REPRICER_PROFIT_TYPES, REPRICER_TYPES } from "../../../constants";
import { getKeyByValue, RenderIf, toPascalCase } from "../../../utils/common";
import { omit } from "lodash";

const MarketPlaceAccountEditModal = (props) => {
  const dispatch = useDispatch();
  const { selectedAccount, error, success, onboardAccountSuccess, loading, editModalIsOpen, addModalIsOpen } =
    useSelector((state) => state.MarketPlaceAccount);
  const { account } = useSelector((state) => state.Settings);

  function handleUpdate (data) {
    dispatch(updateMarketplaceAccounts({ ...data, params: { repricer_setting: true } }));
  }

  function handleAdd (data) {
    dispatch(createMarketplaceAccount(data));
  }

  const initialValues = {
    informed_api_key: selectedAccount.informed_api_key || "",
    price_percentage_threshold: selectedAccount.price_percentage_threshold || "",
    profit_percentage: selectedAccount.profit_percentage || "",
    fix_profit: selectedAccount.fix_profit || "",
    profit_type:
      {
        label: getKeyByValue(REPRICER_PROFIT_TYPES, selectedAccount.profit_type),
        value: selectedAccount.profit_type,
      } || "",
    disable_repricer: Boolean(selectedAccount.disable_repricer),
    informed_repricer: Boolean(selectedAccount.informed_repricer),
    repricer_type:
      {
        label: toPascalCase(getKeyByValue(REPRICER_TYPES, selectedAccount.repricer_type)),
        value: selectedAccount.repricer_type,
      } || {},
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (props.type === "edit" && selectedAccount) {
        handleUpdate({
          data: {
            ...values,
            profit_type: values.profit_type.value,
            repricer_type: values.repricer_type.value,
            disable_repricer: false,
          },
          id: selectedAccount.id,
        });
      } else if (props.type === "add") {
        handleAdd({
          ...values,
        });
      }
    },
  });

  function isFieldEnabled (name) {
    let isEnabled = false;
    switch (name) {
      case "fix_profit":
        isEnabled =
          [REPRICER_PROFIT_TYPES["Fixed Profit"], REPRICER_PROFIT_TYPES["Choose Max"]].includes(
            formik.values.profit_type.value,
          ) &&
          !formik.values.disable_repricer &&
          !["Informed", "Ecom Repricer"].includes(formik.values.repricer_type.label);
        break;
      case "profit_percentage":
        isEnabled =
          [REPRICER_PROFIT_TYPES["Percentage Profit"], REPRICER_PROFIT_TYPES["Choose Max"]].includes(
            formik.values.profit_type.value,
          ) &&
          !formik.values.disable_repricer &&
          !formik.values.informed_repricer &&
          !["Ecom Repricer", "Informed"].includes(formik.values.repricer_type.label);
        break;
      default:
        break;
    }
    return isEnabled;
  }

  useEffect(() => {
    (success || onboardAccountSuccess) && !loading && formik.resetForm();
  }, [success, loading, onboardAccountSuccess]);

  const getOmitArray = () => {
    if (selectedAccount.repricer_type !== REPRICER_TYPES.old_repricer) return ["old_repricer"];
    else if (!account?.data?.api_key_repricer || selectedAccount.marketplace === MARKET_PLACES.shopify) return ["ecom_repricer", "informed"];
  };

  const toggleModal = () => {
    props.type === "edit"
      ? dispatch(setEditModalMarketplaceAccount(!editModalIsOpen))
      : dispatch(setAddModalMarketplaceAccount(!addModalIsOpen));
    formik.resetForm();
  };

  return (
    <Modal size="md" isOpen={editModalIsOpen || addModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.type === "edit"
            ? `Edit Repricer Settings for ${selectedAccount.name} - ${selectedAccount.marketplace}`
            : "Add Marketplace Account"}
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            formik.handleSubmit(v);
          }}
        >
          <Col>
            <div className="my-3">
              <div>
                <FormGroup className="select2-container">
                  <Label>Repricer Type</Label>
                  <Select
                    name="repricer_type"
                    value={formik.values.repricer_type}
                    onChange={(e) => formik.setFieldValue("repricer_type", e)}
                    options={Object.keys(omit(REPRICER_TYPES, getOmitArray())).map((x) => ({
                      label: toPascalCase(x),
                      value: REPRICER_TYPES[x],
                    }))}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </div>
              {formik.values.repricer_type.label === "Informed" && (
                <>
                  <small> (Enter your informed api key)</small>
                  <AvField
                    name="informed_api_key"
                    type="text"
                    value={formik.values.informed_api_key}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Informed Api Key is requried",
                      },
                    }}
                  />
                </>
              )}
            </div>
          </Col>
          {formik.values.repricer_type.label === "Old Repricer" && (
            <Col>
              <FormGroup className="select2-container mt-3">
                <Label>Profit Type*</Label>
                <Select
                  name="profit_type"
                  value={formik.values.profit_type}
                  onChange={(val) => {
                    formik.setFieldValue("profit_type", val);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Select profit type"
                  options={Object.keys(REPRICER_PROFIT_TYPES).map((key, i) => ({
                    label: key,
                    value: REPRICER_PROFIT_TYPES[key],
                    key: i,
                  }))}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
          )}
          <RenderIf isTrue={formik.values.repricer_type.label === "Old Repricer"}>
          {isFieldEnabled("fix_profit") && (
            <Col>
              <AvField
                name="fix_profit"
                label="Fixed Profit*"
                type="number"
                step="0.01"
                value={formik.values.fix_profit}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Fixed Profit is requried",
                  },
                  min: {
                    value: 0.1,
                    errorMessage: "Minimum value allowed is 0.01",
                  },
                }}
              />
            </Col>
          )}

          {isFieldEnabled("profit_percentage") && (
            <Col>
              <AvField
                name="profit_percentage"
                label="Profit Percentage*"
                type="number"
                step="0.01"
                value={formik.values.profit_percentage}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Profit Percentage is requried",
                  },
                  min: {
                    value: 0.1,
                    errorMessage: "Minimum value allowed is 0.01",
                  },
                  max: {
                    value: 99,
                    errorMessage: "Maximum value allowed is 99",
                  },
                }}
              />
            </Col>
          )}
          </RenderIf>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3" disabled={!formik.values.repricer_type?.label}>
              Submit
            </Button>
          </Row>
        </AvForm>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default MarketPlaceAccountEditModal;
