import {
  FETCH_RESTRICTED_BRANDS,
  FETCH_RESTRICTED_BRANDS_SUCCESS,
  SET_SELECTED_BRANDS,
  SET_BRANDS_BULK_UPDATE_MODAL,
  BRANDS_BULK_UPDATE,
  BRANDS_BULK_UPDATE_SUCCESS,
  ADD_RESTRICTED_BRANDS,
  ADD_RESTRICTED_BRANDS_SUCCESS,
  DEL_RESTRICTED_BRANDS,
  DEL_RESTRICTED_BRANDS_SUCCESS,
  UPDATE_RESTRICTED_BRANDS,
  UPDATE_RESTRICTED_BRANDS_SUCCESS,
  SET_BRAND_ADD_MODAL,
  SET_BRAND_EDIT_MODAL,
  API_ERROR,
  SET_BRAND_BULK_ADD_MODAL,
  BULK_ADD_RESTRICTED_BRANDS,
  BULK_ADD_RESTRICTED_BRANDS_SUCCESS,
} from "./actionTypes";

export const fetchRestrictedBrands = (params) => {
  return {
    type: FETCH_RESTRICTED_BRANDS,
    payload: { params },
  };
};

export const fetchRestrictedBrandsSuccess = (brands) => {
  return {
    type: FETCH_RESTRICTED_BRANDS_SUCCESS,
    payload: brands,
  };
};

export const setSelectedBrands = (id) => {
  return {
    type: SET_SELECTED_BRANDS,
    payload: id,
  };
};

export const setBrandsBulkUpdateModal = (bool) => {
  return {
    type: SET_BRANDS_BULK_UPDATE_MODAL,
    payload: bool,
  };
};

export const bulkUpdateBrands = (data) => {
  return {
    type: BRANDS_BULK_UPDATE,
    payload: { data },
  };
};

export const bulkUpdateBrandsSuccess = () => {
  return {
    type: BRANDS_BULK_UPDATE_SUCCESS,
    payload: {},
  };
};

export const addNewBrand = (data) => {
  return {
    type: ADD_RESTRICTED_BRANDS,
    payload: data,
  };
};

export const addNewBrandSuccess = (res) => {
  return {
    type: ADD_RESTRICTED_BRANDS_SUCCESS,
    payload: {},
  };
};

export const bulkAddNewBrand = (data) => {
  return {
    type: BULK_ADD_RESTRICTED_BRANDS,
    payload: data,
  };
};

export const bulkAddNewBrandSuccess = (res) => {
  return {
    type: BULK_ADD_RESTRICTED_BRANDS_SUCCESS,
    payload: {},
  };
};

export const deleteBrand = (data) => {
  return {
    type: DEL_RESTRICTED_BRANDS,
    payload: data,
  };
};

export const deleteBrandSuccess = (res) => {
  return {
    type: DEL_RESTRICTED_BRANDS_SUCCESS,
    payload: res,
  };
};

export const updateBrand = (data) => {
  return {
    type: UPDATE_RESTRICTED_BRANDS,
    payload: data,
  };
};

export const updateBrandSuccess = (res) => {
  return {
    type: UPDATE_RESTRICTED_BRANDS_SUCCESS,
    payload: res,
  };
};

export const setAddBrandModal = (bool) => {
  return {
    type: SET_BRAND_ADD_MODAL,
    payload: bool,
  };
};

export const setBulkAddBrandModal = (bool) => {
  return {
    type: SET_BRAND_BULK_ADD_MODAL,
    payload: bool,
  };
};

export const setEditAddBrandModal = (bool) => {
  return {
    type: SET_BRAND_EDIT_MODAL,
    payload: bool,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
