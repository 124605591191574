import React, { useState } from "react";
import { Tooltip } from "reactstrap";

function CustomTooltip ({ placement, target, content, style = {}, modifiers }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      placement={placement || "right"}
      isOpen={tooltipOpen}
      target={target}
      toggle={toggleTooltip}
      style={style}
      modifiers={modifiers}
    >
      {content}
    </Tooltip>
  );
}

export default CustomTooltip;
