import request from "../restClient";
const resource = "/accounts/feeds";

export const getAllMarketplaceFeed = async (params) => {
  const url =
    resource +
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${typeof params[key] === "object" ? JSON.stringify(params[key]) : params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};
