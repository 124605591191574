import { getDropDownValues, getFilterTitle, getFilterValueLabel, getFilters, parseFilterKey } from "./common";
import { RenderIf } from "../../../utils/common";
import $, { isEmpty } from "lodash";
import FilterChip from "./FilterChip";
import React, { useMemo } from "react";

export default function FilterChipsBar ({ filters, setFilters, initialOptions }) {
  const localFilters = useMemo(() => getFilters(filters), [filters]);
  const dropDownValues = useMemo(() => getDropDownValues(initialOptions), [initialOptions]);

  return (
    <RenderIf isTrue={!isEmpty(localFilters)}>
      <div className="d-flex justify-content-start align-items-center flex-wrap gap-3 p-4">
        {$.map(localFilters, (filterValue, filterKey) => {
          const [parsedFilterKey, parsedFilterOperator] = parseFilterKey(filterKey);
          return (
            <FilterChip
              key={`__${filterKey}Chip__`}
              filterTitle={getFilterTitle(parsedFilterKey)}
              filterValueLabel={getFilterValueLabel(dropDownValues, parsedFilterKey, filterValue)}
              operator={parsedFilterOperator}
              removeFilter={() => setFilters({ ...$.omit(filters, [filterKey]), page: 1, page_number: 1 })}
            />
          );
        })}
      </div>
    </RenderIf>
  );
}
