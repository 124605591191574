import {
  FETCH_EMAILS_SUCCESS,
  FETCH_EMAILS,
  API_ERROR,
  FETCH_ORDER_UPDATES,
  FETCH_ORDER_UPDATES_SUCCESS,
  FETCH_S3_OBJECTS_SUCCESS,
  FETCH_S3_OBJECTS,
  UPDATE_S3_OBJECT,
  UPDATE_S3_OBJECT_SUCCESS,
} from "./actionTypes";

export const fetchEmails = (data) => {
  return {
    type: FETCH_EMAILS,
    payload: { data },
  };
};

export const fetchEmailsSuccess = (emails) => {
  return {
    type: FETCH_EMAILS_SUCCESS,
    payload: emails,
  };
};

export const fetchS3Objects = (data) => {
  return {
    type: FETCH_S3_OBJECTS,
    payload: { data },
  };
};

export const updateS3Object = (id, params) => {
  return {
    type: UPDATE_S3_OBJECT,
    payload: { id, params },
  };
};

export const updateS3ObjectSuccess = (data) => {
  return {
    type: UPDATE_S3_OBJECT_SUCCESS,
    payload: data,
  };
};

export const fetchS3ObjectsSuccess = (data) => {
  return {
    type: FETCH_S3_OBJECTS_SUCCESS,
    payload: data,
  };
};

export const fetchOrderUpdate = (data) => {
  return {
    type: FETCH_ORDER_UPDATES,
    payload: { data },
  };
};

export const fetchOrderUpdatesSuccess = (emails) => {
  return {
    type: FETCH_ORDER_UPDATES_SUCCESS,
    payload: emails,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
