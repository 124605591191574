import {
  LABEL_UPLOAD_TYPES,
  SHIPMENT_LINE_STATUSES,
  USER_ROLES,
  WH_NOTICES_TYPES,
  CONTENT_TYPE_MAPPING,
} from "../../constants";
import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import { isArray, merge, isEmpty, pick, omit, last } from "lodash";
import * as types from "./actionTypes";
import * as apis from "../../api";
import * as actions from "./actions";
import { getUserInfo, getInventoryItem, getWarehouseInventoryLocation } from "../../utils/common";
const { REACT_APP_BASE_URL } = process.env;
const {
  getAllWarehouses,
  getAllWarehouseItems,
  fetchWarehouseAccounts,
  createWareHouse,
  updateWarehouse,
  assignUserWarehouse,
  updateWarehouseItem,
  deleteWarehouseUser,
  addWarehouseNote,
  resolveStrandedItem,
  fetchInventoryItems,
  updateInventoryItemStatus,
  uploadReturnLabelFile,
  downloadReturnLabel,
  assignAgencyToWarehouse,
  deleteWHAccount,
  fetchWHChargeHistories,
  updateWHChargeHistory,
  deleteWarehouse,
  updateWHAccount,
  updateBulkWarehouseItems,
  markNotReceived,
  deleteReturnLabel,
  fetchUserWarehouses,
  downloadChargesReport,
  fetchAllShippingRatesAPI,
  generateLabel,
  fetchWHStatusStats,
  fetchAllShipmentsAPI,
  fetchAllOutboundShipmentsAPI,
  refundLabelAPI,
  downloadShippingLabel,
  downloadInventoryReport,
  addInventoryItem,
  updateInventoryItemBins,
  deleteInventoryItemBin,
  fetchPurchaseOrderAPI,
  fetchPurchaseOrderMetadataAPI,
  fetchPurchaseOrderLogsAPI,
  fetchPurchaseOrderStatsAPI,
  fetchPurchaseOrderSuppliersAPI,
  createPurchaseOrderAPI,
  createLineItemAPI,
  createPOShipmentAPI,
  deletePurchaseOrderAPI,
  updatePurchaseOrderAPI,
  updatePurchaseOrderItemAPI,
  updatePurchaseOrderShipmentAPI,
  deletePurchaseOrderItemAPI,
  deletePurchaseOrderShipmentAPI,
  updateInventoryItemsApi,
  fetchInboundsApi,
  fetchInboundsStatsApi,
  updateTrackingItemShippingStatusApi,
  addShipmentApi,
  shipmentsCheckedInApi,
  downloadWarehouseItemsReport,
  receiveShipmentApi,
  fetchWHInventoryApi,
  fetchWHInventoryStatsApi,
  fetchWHOutboundApi,
  downloadWHLabelReport,
  fetchWhOutboundStatsApi,
  fetchLabelGenerationStats,
  updateReturnsOutbounds,
} = apis;

// Login Redux States
const {
  FETCH_ALL_WAREHOUSES,
  FETCH_ALL_WAREHOUSE_ITEMS,
  FETCH_WAREHOUSE_ACCOUNTS,
  CREATE_WAREHOUSE,
  ASSIGN_USER_WAREHOUSE,
  UPDATE_WAREHOUSE_ITEM,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE_USER,
  ADD_WAREHOUSE_NOTE,
  RESOLVE_STRANDED_ITEM,
  FETCH_INVENTORY_ITEMS,
  UPDATE_INVENTORY_ITEM_STATUS,
  UPLOAD_RETURN_LABEL_FILE,
  DOWNLOAD_RETURN_LABEL,
  ASSIGN_AGENCY_TO_WAREHOUSE,
  DELETE_WH_ACCOUNT,
  FETCH_WH_CHARGE_HISTORIES,
  UPDATE_WH_CHARGE_HISTORY,
  DELETE_WAREHOUSE,
  UPDATE_WH_ACCOUNT,
  UPDATE_BULK_ITEMS,
  MARK_NOT_RECEIVED,
  DELETE_RETURN_LABEL,
  FETCH_USER_WAREHOUSES,
  DOWNLOAD_CHARGES_REPORT,
  DOWNLOAD_INVENTORY_REPORT,
  FETCH_ALL_SHIPPING_RATES,
  GENERATE_LABEL,
  FETCH_WH_CHARGES_STATUS_STATS,
  FETCH_ALL_SHIPMENTS,
  FETCH_ALL_OUTBOUND_SHIPMENTS,
  REFUND_SHIPMENT_LABEL,
  DOWNLOAD_SHIPPING_LABEL,
  ADD_INVENTORY_ITEM,
  UPDATE_INVENTORY_ITEM_BINS,
  DELETE_INVENTORY_ITEM_BIN,
  FETCH_PURCHASE_ORDERS,
  CREATE_PURCHASE_ORDERS,
  CREATE_LINE_ITEM,
  CREATE_PO_SHIPMENT,
  DELETE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_SHIPMENT,
  DELETE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_SHIPMENT,
  FETCH_PURCHASE_ORDERS_METADATA,
  FETCH_PO_LOGS,
  FETCH_PO_STATS,
  FETCH_PO_SUPPLIERS,
  BULK_UPDATE_INVENTORY_ITEM,
  FETCH_INBOUND_ITEMS,
  FETCH_WH_INBOUND_STATS,
  SEARCH_ORDER,
  UPDATE_TRACKING_ITEM_SHIPPPING_STATUS,
  ADD_SHIPMENT,
  SHIPMENT_CHECKED_IN,
  DOWNLOAD_WH_ITEMS_REPORT,
  RECEIVE_SHIPMENT,
  FETCH_WH_INVENTORY,
  FETCH_WH_INVENTORY_STATS,
  FETCH_WH_OUTBOUNDS,
  DOWNLOAD_WH_LABEL_REPORT,
  FETCH_WH_OUTBOUND_STATS,
  FETCH_LABEL_USERS_AND_COUNT,
  UPDATE_RETURNS_OUTBOUND,
} = types;

const {
  fetchAllWarehousesSuccess,
  fetchAllWarehouseItemsSuccess,
  fetchWarehouseAccountsSuccess,
  createWareHouseSuccess,
  updateWarehouseSuccess,
  assignUserWareHouseSuccess,
  updateWareHouseItemSuccess,
  deleteWareHouseUserSuccess,
  setAddWareHouseModal,
  setAssignUserWareHouseModal,
  addWarehouseNoteSuccess,
  resolveStrandedItemSuccess,
  fetchInventoryItemsSuccess,
  updateInventoryItemStatusSuccess,
  uploadReturnLabelFileSuccess,
  downloadReturnLabelSuccess,
  apiError,
  assignAgencyToWarehouseSuccess,
  deleteWHAccountSuccess,
  fetchWHChargeHistoriesSuccess,
  updateWHChargeHistorySuccess,
  deleteWarehouseSuccess,
  updateWHAccountSuccess,
  updateBulkItemsSuccess,
  markNotReceivedSuccess,
  deleteReturnLabelSuccess,
  fetchUserWarehousesSuccess,
  downloadChargesReportSuccess,
  fetchAllShippingRatesSuccess,
  generateLabelSuccess,
  fetchWHStatusStatsSuccess,
  fetchAllShipmentsSuccess,
  fetchAllOutboundShipmentsSuccess,
  refundShipmentLabelSuccess,
  downloadShippingLabelSuccess,
  downloadInventoryReprotSuccess,
  addInventoryItemSuccess,
  updateInventoryItemBinsSuccess,
  deleteInventoryItemBinSuccess,
  fetchPurchaseOrdersSuccess,
  createPurchaseOrderSuccess,
  createLineItemSuccess,
  createPOShipmentSuccess,
  deletePurchaseOrderSuccess,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderItemSuccess,
  updatePurchaseOrderShipmentSuccess,
  deletePOItemSuccess,
  deletePOShipmentSuccess,
  fetchPurchaseOrderMetadataSuccess,
  fetchPurchaseOrderLogsSuccess,
  fetchPurchaseOrderStatsSuccess,
  fetchPurchaseOrderSuppliersSuccess,
  updateBulkInventoryItemsSuccess,
  setInventoryCheckBoxes,
  setUpdateTenantModal,
  fetchInboundsSuccess,
  fetchWHInboundStatsSuccess,
  addShipmentSuccess,
  searchOrderSuccess,
  shipmentsCheckedInSuccess,
  downloadWarehouseItemsReportSuccess,
  receivedShipmentSuccess,
  fetchWHInventorySuccess,
  fetchWHInventoryStatsSuccess,
  fetchWHOutboundsSuccess,
  downloadWHLabelReportSuccess,
  fetchWhOutboundStatsSuccess,
  fetchLabelGenerationStatsSuccess,
  updateReturnOutboundsSuccess,
} = actions;

const WareHouses = (state) => state.WareHouses;
const Returns = (state) => state.Returns;
const MarketPlaceAccount = (state) => state.MarketPlaceAccount;

function * updateReturnsOutboundsSaga ({ payload: { id, data, showSuccess, filters } }) {
  try {
    const res = yield call(updateReturnsOutbounds, { id, data });
    yield put(updateReturnOutboundsSuccess({ ...res, showSuccess }));
    if (res?.success) {
      yield call(fetchWHOutboundSaga, { payload: filters });
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchAllWarehouseSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllWarehouses, params);
    if (res?.data) yield put(fetchAllWarehousesSuccess(merge(res, { is_associated: !!params.associated })));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchAllWarehouseItemsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllWarehouseItems, params);
    if (res?.data) yield put(fetchAllWarehouseItemsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchStrandedCountSaga () {
  try {
    const res = yield call(apis.fetchStrandedItemsCount);
    yield put(actions.fetchStrandedCountSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchWarehouseAccountsSaga () {
  try {
    const res = yield call(fetchWarehouseAccounts);
    if (res?.success) yield put(fetchWarehouseAccountsSuccess(res.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadWHLabelReportSaga ({ payload }) {
  try {
    const res = yield call(downloadWHLabelReport, payload);
    yield put(downloadWHLabelReportSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createWareHouseSaga ({ payload: { data, params } }) {
  try {
    const res = yield call(createWareHouse, data, params);
    if (res?.data && res?.isNewRecord) {
      const { wareHouses } = yield select(WareHouses);
      wareHouses.data = [...wareHouses.data, res.data];
    }
    if (res?.success) {
      const { accounts } = yield select(MarketPlaceAccount);
      const { return_store_ids: returnStoreIds, two_step_ids: twoStepIds } = data;
      Array.isArray(accounts.data) &&
        accounts.data.forEach((acc) => {
          Array.isArray(returnStoreIds) && returnStoreIds.includes(acc.id) && (acc.return_wh_id = res?.data?.id);
          if (Array.isArray(twoStepIds) && twoStepIds.includes(acc.id)) {
            acc.two_step_wh_id = res?.data?.id;
            acc.two_step_enabled = true;
          }
        });
    }
    yield put(createWareHouseSuccess(res.message));
    yield call(fetchAllWarehouseSaga, { payload: { params: { associated: true } } });
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * assignUserWareHouseSaga ({ payload: { data } }) {
  try {
    const res = yield call(assignUserWarehouse, data);
    if (res?.success) {
      const { wareHouses } = yield select(WareHouses);
      const newWareHouse = wareHouses.data?.find((x) => x.id === res.data.warehouse_id);
      newWareHouse.users = [...(newWareHouse.users || []), res.data];
      yield put(setAssignUserWareHouseModal(false));
      yield put(assignUserWareHouseSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addWarehouseNoteSaga ({ payload }) {
  try {
    const res = yield call(addWarehouseNote, payload);
    if (res.success) {
      const { wareHouseItems, warehouseInventory, inboundsData, shipments } = yield select(WareHouses);
      const { returns } = yield select(Returns);
      const stateData = {
        [WH_NOTICES_TYPES.tracking_items]: wareHouseItems,
        [WH_NOTICES_TYPES.inventory_products]: warehouseInventory,
        [WH_NOTICES_TYPES.returns]: returns,
        [WH_NOTICES_TYPES.shipment_lines]: payload?.body?.is_warehouse ? shipments : inboundsData,
      };
      const data = stateData[res.data.notice_type].data?.find((x) => x.id === +res.data.noticeable_id);
      data.warehouse_notices.push(res.data);
      yield put(addWarehouseNoteSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateWareHouseItemSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateWarehouseItem, { data, id });
    if (res?.success) {
      const { wareHouseItems } = yield select(WareHouses);
      const index = wareHouseItems.data.findIndex((x) => x.id === res.data.id);
      wareHouseItems.data[index] = merge(wareHouseItems.data[index], res.data);
      yield put(setAddWareHouseModal(false));
      yield put(updateWareHouseItemSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateInventoryItemStatusSaga ({ payload: { data, id, filters } }) {
  try {
    const res = yield call(updateInventoryItemStatus, { data, id });
    if (res?.success) {
      const { warehouseInventory, alertData } = yield select(WareHouses);
      let index = warehouseInventory.data.findIndex((x) => x.id === res.data.id);
      const isStatusUpdated = ["status", "wh_status"].some(
        (x) => warehouseInventory.data[index]?.[x] !== res.data?.[x],
      );

      warehouseInventory.data[index] = merge(warehouseInventory.data[index], res.data);
      if (getUserInfo()?.role !== USER_ROLES.whStaff) {
        index = alertData.data.findIndex((x) => x.id === res.data.id);
        alertData.data[index] = merge(alertData.data[index], res.data);
      }
      if (isStatusUpdated) yield fetchInventoryItemsSaga({ payload: { params: filters } });
      yield put(updateInventoryItemStatusSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadReturnLabelSaga ({ payload: { id, params } }) {
  try {
    const data = yield call(downloadReturnLabel, { id, params });
    params?.is_preview && window.open(data.url, "_blank", "application/pdf") && delete data.url;
    yield put(downloadReturnLabelSuccess(data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadShippingLabelSaga ({ payload: { id, params } }) {
  try {
    const data = yield call(downloadShippingLabel, { id, params });
    if (params?.is_preview) {
      const urlToShow = data.fromS3
        ? data?.url
        : `${REACT_APP_BASE_URL}/${
            data?.shipmentId?.includes("shp_") ? "easypost_labels" : "stamps_labels"
          }/shipping_label_` + data?.url;
      window.open(urlToShow, "_blank", CONTENT_TYPE_MAPPING[params?.file_name?.slice(-3)]);
    }
    yield put(downloadShippingLabelSuccess(!params?.is_preview ? { url: "" } : data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteReturnLabelSaga ({ payload: { id, params, filters } }) {
  try {
    const res = yield call(deleteReturnLabel, { id, params, filters });
    if (res?.success) {
      let allItems;
      if (params.item_type === LABEL_UPLOAD_TYPES.inventory) allItems = (yield select(WareHouses))?.warehouseInventory;
      else allItems = (yield select(Returns))?.returns;
      const index = allItems?.data?.findIndex((x) => x.id === id);
      allItems.data[index] = { ...allItems.data[index], ...res.data };
      if (filters) yield fetchInventoryItemsSaga({ payload: { params: filters } });
    }
    yield put(deleteReturnLabelSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * uploadReturnLabelFileSaga ({ payload: { data, id, params } }) {
  try {
    const res = yield call(uploadReturnLabelFile, { data, id, params });
    if (res?.success) {
      let allItems;
      if (params.item_type === LABEL_UPLOAD_TYPES.inventory) allItems = (yield select(WareHouses))?.warehouseInventory;
      else allItems = (yield select(Returns))?.returns;
      const index = allItems.data?.findIndex((x) => x.id === res.data.id);
      allItems.data[index] = merge(allItems.data[index], res.data);
      yield put(uploadReturnLabelFileSuccess(res.success));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateWarehouseSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateWarehouse, { data, id });
    if (res?.success) {
      const { wareHouses } = yield select(WareHouses);
      const warehouse = wareHouses.data?.find((x) => x.id === res.data.id);
      merge(warehouse, res.data);
      yield put(updateWarehouseSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateBulkWarehouseItemsSaga ({ payload: { data, ids } }) {
  try {
    const res = yield call(updateBulkWarehouseItems, { data, ids });
    if (res?.success) {
      const { wareHouseItems } = yield select(WareHouses);
      wareHouseItems.data.forEach((x) => ids.includes(x.id) && merge(x, data));
      yield put(updateBulkItemsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * markNotReceivedSaga ({ payload: id }) {
  try {
    const res = yield call(markNotReceived, id);
    if (res?.success) {
      const { wareHouseItems } = yield select(WareHouses);
      const index = wareHouseItems.data.findIndex((x) => x.id === res.data.id);
      wareHouseItems.data[index] = merge(wareHouseItems.data[index], res.data);
      yield put(markNotReceivedSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * assignAgencyToWarehouseSaga ({ payload }) {
  try {
    const res = yield call(assignAgencyToWarehouse, payload);
    if (res?.success) {
      const { wareHouses } = yield select(WareHouses);
      const warehouseObj = wareHouses.data?.find((x) => x.id === payload.wh_id);
      if (!isArray(warehouseObj.AccountWarehouse)) warehouseObj.AccountWarehouse = [];
      warehouseObj.AccountWarehouse = warehouseObj.AccountWarehouse.filter((x) => x.email !== payload.agency_id[1]);
      warehouseObj.AccountWarehouse.push({ id: payload.agency_id[0], email: payload.agency_id[1], agency_id: null });
      yield put(assignAgencyToWarehouseSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteWHAccountSaga ({ payload }) {
  try {
    const res = yield call(deleteWHAccount, payload);
    if (res?.success) {
      if (getUserInfo()?.role === USER_ROLES.admin) yield call(fetchAllWarehouseSaga, { payload: { params: {} } });
      else {
        const { wareHouses } = yield select(WareHouses);
        wareHouses.data = wareHouses?.data.filter((x) => x.id === payload.wh_id);
      }
      yield put(deleteWHAccountSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteWarehouseSaga ({ payload: id }) {
  try {
    const { accounts } = yield select(MarketPlaceAccount);
    const res = yield call(deleteWarehouse, id);
    if (res?.success) {
      accounts.data.forEach((store) => {
        if (store.two_step_wh_id === id) store.two_step_wh_id = null;
        if (store.return_wh_id === id) store.return_wh_id = null;
      });
      if (getUserInfo()?.role === USER_ROLES.admin) yield call(fetchAllWarehouseSaga, { payload: { params: {} } });
      else {
        const { wareHouses } = yield select(WareHouses);
        wareHouses.data = wareHouses?.data.filter((x) => x.id === id);
      }
      yield put(deleteWarehouseSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateWHAccountSaga ({ payload: { id, data } }) {
  try {
    const res = yield call(updateWHAccount, id, data);
    if (res?.success) {
      const { wareHouses } = yield select(WareHouses);
      const warehouseObj = wareHouses.data?.find((x) => x.id === id);
      const accountWH = warehouseObj.AccountWarehouse?.find((x) => x.id === getUserInfo()?.account_id);
      merge(accountWH.account_warehouses, res?.data);
      yield put(updateWHAccountSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteWareHouseUserSaga ({ payload }) {
  try {
    const res = yield call(deleteWarehouseUser, payload);
    if (res?.success) {
      const { wareHouses } = yield select(WareHouses);
      const deleteAbleWH = wareHouses.data?.find((x) => x.id === res.data.warehouse_id);
      deleteAbleWH.users = deleteAbleWH.users.filter((x) => x.email !== res.data.email);
      res.wareHouses = wareHouses;
      yield put(deleteWareHouseUserSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * resolveStrandedItemSaga ({ payload: { id, data } }) {
  try {
    const res = yield call(resolveStrandedItem, { id, data });
    if (res?.success) {
      const { wareHouseItems } = yield select(WareHouses);
      const index = wareHouseItems.data.findIndex((x) => x.id === res.trackingItem.id);
      wareHouseItems.data[index] = merge(wareHouseItems.data[index], res.trackingItem);
      yield put(resolveStrandedItemSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchInventoryItemsSaga ({ payload: { params } }) {
  try {
    const res = yield call(fetchInventoryItems, params);
    if (res?.data) yield put(fetchInventoryItemsSuccess({ res, isAlertData: !Object.keys(params).length }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addInventoryItemSaga ({ payload: { data, filters } }) {
  try {
    const res = yield call(addInventoryItem, data);
    if (res?.data) {
      yield fetchInventoryItemsSaga({ payload: { params: filters } });
      yield put(addInventoryItemSuccess(res?.message));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchUserWarehousesSaga ({ payload: params }) {
  try {
    params = merge({ include_deleted: true }, params);
    const res = yield call(fetchUserWarehouses, params);
    yield put(fetchUserWarehousesSuccess(res.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadChargesReportSaga ({ payload: params }) {
  try {
    yield call(downloadChargesReport, { params });
    yield put(downloadChargesReportSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchInvoiceDetailSaga ({ payload: param }) {
  try {
    const res = yield call(apis.fetchInvoiceDetail, param);
    yield put(actions.fetchInvoiceDetailSuccess(res.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadInventoryReportSaga ({ payload: params }) {
  try {
    yield call(downloadInventoryReport, { params });
    yield put(downloadInventoryReprotSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadWarehouseInventoryReportSaga ({ payload: params }) {
  try {
    yield call(downloadWarehouseItemsReport, params);
    yield put(downloadWarehouseItemsReportSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchWHStatusStatsSaga ({ payload: params }) {
  try {
    const res = yield call(fetchWHStatusStats, params);
    yield put(fetchWHStatusStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchWHChargeHistoriesSaga ({ payload: { params } }) {
  try {
    const res = yield call(fetchWHChargeHistories, params);
    if (res?.data) yield put(fetchWHChargeHistoriesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateWHChargeHistorySaga ({ payload }) {
  try {
    const res = yield call(updateWHChargeHistory, payload);
    if (res?.success) {
      yield put(updateWHChargeHistorySuccess(res));
      yield put(actions.fetchWHChargeHistories(payload.getOtherFilters()));
      yield put(actions.fetchWHStatusStats(pick(payload.getOtherFilters(), "account_id", "warehouse_id")));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchAllShippingRatesSaga ({ payload: { data } }) {
  try {
    const res = yield call(fetchAllShippingRatesAPI, { data });
    if (isEmpty(res?.data)) {
      const { getAllShippingRates } = yield select(WareHouses);
      getAllShippingRates.data = {};
    }
    if (res.success) yield put(fetchAllShippingRatesSuccess(res));
  } catch (error) {
    const { getAllShippingRates } = yield select(WareHouses);
    getAllShippingRates.data = {};
    yield put(apiError(error.message));
  }
}

function * fetchAllShipmentsSaga () {
  try {
    const res = yield call(fetchAllShipmentsAPI);
    if (res.success) yield put(fetchAllShipmentsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchAllOutboundShipmentsSaga ({ payload }) {
  try {
    const res = yield call(fetchAllOutboundShipmentsAPI, payload);
    if (res.success) yield put(fetchAllOutboundShipmentsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * refundLabelSaga ({ payload: { id, params } }) {
  try {
    const res = yield call(refundLabelAPI, { id, params: omit(params, "outbound_ids") });
    if (res?.success) {
      const { allShipments, whOutboundData } = yield select(WareHouses);
      if (params?.label_from === "outbound") {
        const allOS = yield call(fetchAllOutboundShipmentsAPI, params?.outbound_ids);
        if (allOS.success) yield put(fetchAllOutboundShipmentsSuccess(allOS));
        const index = whOutboundData?.data.findIndex((x) => x.id === res?.outbound?.id);
        whOutboundData.data[index] = res?.outbound;
      } else {
        const index = allShipments?.findIndex((x) => x.id === res?.data?.id);
        allShipments[index] = res?.data;
      }
      yield put(refundShipmentLabelSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * generateLabelSaga ({ payload: { data, id } }) {
  try {
    if (data?.label_from === "outbounds") {
      const res = yield call(generateLabel, { data: omit(data, "outbound_ids"), id });
      if (res?.success) {
        const { whOutboundData } = yield select(WareHouses);
        const allOS = yield call(fetchAllOutboundShipmentsAPI, data?.outbound_ids);
        if (allOS.success) yield put(fetchAllOutboundShipmentsSuccess(allOS));
        const index = whOutboundData?.data.findIndex((x) => x.id === res?.outbound?.id);
        whOutboundData.data[index] = res?.outbound;
        if (res?.url) window.open(res?.url, "_blank");
        yield put(generateLabelSuccess(res));
      }
    } else {
      const res = yield call(generateLabel, { data, id });
      if (res?.success) {
        yield call(fetchAllShipmentsAPI);
        if (res?.url) window.open(res?.url, "_blank");
        const { allShipments } = yield select(WareHouses);
        const index = allShipments?.findIndex((x) => x?.id === res?.data?.id);
        allShipments[index] = res?.data;
        yield put(generateLabelSuccess(res));
      }
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateInventoryItemBinsSaga ({ payload: { data, id, filters } }) {
  try {
    const res = yield call(updateInventoryItemBins, { id, data });
    if (res.success) {
      yield fetchInventoryItemsSaga({ payload: { params: filters } });
      yield put(updateInventoryItemBinsSuccess(res));
    }
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function * deleteInventoryItemBinSaga ({ payload: { id, params, filters } }) {
  try {
    const res = yield call(deleteInventoryItemBin, { params, id });
    if (res.success) {
      yield put(deleteInventoryItemBinSuccess(res));
      yield fetchInventoryItemsSaga({ payload: { params: filters } });
    }
  } catch (err) {
    yield put(apiError(err.message));
  }
}

// Purchase Oredrs
function * fetchPurchaseOrdersSaga ({ payload }) {
  try {
    const res = yield call(fetchPurchaseOrderAPI, payload);
    if (res?.data) yield put(fetchPurchaseOrdersSuccess({ res }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchPurchaseOrderMetadataSaga ({ payload: id }) {
  try {
    const res = yield call(fetchPurchaseOrderMetadataAPI, id);
    if (res?.data) yield put(fetchPurchaseOrderMetadataSuccess({ res }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchPurchaseOrderLogsSaga ({ payload: id }) {
  try {
    const res = yield call(fetchPurchaseOrderLogsAPI, id);
    if (res?.data) yield put(fetchPurchaseOrderLogsSuccess({ res }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchPurchaseOrderStatsSaga () {
  try {
    const res = yield call(fetchPurchaseOrderStatsAPI);
    if (res?.data) yield put(fetchPurchaseOrderStatsSuccess({ res }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchPurchaseOrderStatsSuppliersSaga () {
  try {
    const res = yield call(fetchPurchaseOrderSuppliersAPI);
    if (res?.data) yield put(fetchPurchaseOrderSuppliersSuccess({ res }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createPurchaseOrderSaga ({ payload: { id, data, pageSize } }) {
  try {
    const res = yield call(createPurchaseOrderAPI, id, data);
    const { purchaseOrders } = yield select(WareHouses);
    if (res?.data) {
      purchaseOrders.res.data.unshift(res?.data);
      purchaseOrders.res.data.length = Math.min(pageSize, purchaseOrders.res.data.length);
    }
    yield call(fetchPurchaseOrderStatsSuppliersSaga);
    yield put(createPurchaseOrderSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createLineItemSaga ({ payload }) {
  try {
    const res = yield call(createLineItemAPI, payload?.itemsObj);
    if (res?.data) {
      const { purchaseOrders, purchaseOrdersMetadata } = yield select(WareHouses);
      const bufferPO = purchaseOrders?.res?.data?.find((x) => x.id === payload?.whOrderId);
      Object.keys(payload?.purchaseOrderObj).map((x) => (bufferPO.purchase_order[x] += payload?.purchaseOrderObj[x]));
      purchaseOrdersMetadata.line_items = [...purchaseOrdersMetadata.line_items, ...res?.data];
    }
    yield put(createLineItemSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createPOShipmentSaga ({ payload }) {
  try {
    const res = yield call(createPOShipmentAPI, payload);
    if (res?.data) {
      const { purchaseOrdersMetadata } = yield select(WareHouses);
      purchaseOrdersMetadata.line_items = res?.data?.line_items;
      purchaseOrdersMetadata.shipments = [...purchaseOrdersMetadata.shipments, ...res?.data?.shipments];
    }
    yield put(createPOShipmentSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deletePurchaseOrderSaga ({ payload }) {
  try {
    const res = yield call(deletePurchaseOrderAPI, payload);
    if (res?.success) {
      const { purchaseOrders } = yield select(WareHouses);
      purchaseOrders.res.data = purchaseOrders?.res?.data.filter((x) => x.id !== res?.data?.id);
      yield put(deletePurchaseOrderSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updatePurchaseOrderSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updatePurchaseOrderAPI, { data, id });
    if (res?.success) {
      const { purchaseOrders } = yield select(WareHouses);
      const bufferPo = purchaseOrders?.res?.data?.find((x) => x.id === res.data.id);
      merge(bufferPo, res.data);
      yield put(updatePurchaseOrderSuccess(res));
      yield call(fetchPurchaseOrderStatsSuppliersSaga);
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updatePurchaseOrderItemSaga ({ payload: { data, id, purchaseOrderObj } }) {
  const dataForAPI = pick(data, [
    "shipping_per_item",
    "cost_per_item",
    "qty_multiplier",
    "quantity",
    "asin",
    "supplier_url",
    "item_id",
    "supplier_sku",
    "sku",
    "upc",
    "item_title",
    "tax",
  ]);
  try {
    const res = yield call(updatePurchaseOrderItemAPI, { dataForAPI, id, purchaseOrderObj });
    if (res?.success) {
      const { purchaseOrders, purchaseOrdersMetadata } = yield select(WareHouses);
      const bufferPO = purchaseOrders?.res?.data?.find((x) => x.id === data?.wh_order_id);
      Object.keys(purchaseOrderObj).map((x) => (bufferPO.purchase_order[x] += purchaseOrderObj[x]));
      purchaseOrdersMetadata.line_items = res?.data?.line_items;
      purchaseOrdersMetadata.shipments = res?.data?.shipments;
      yield put(updatePurchaseOrderItemSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updatePurchaseOrderShipmentSaga ({ payload: { updateShipmentObj, id } }) {
  try {
    const res = yield call(updatePurchaseOrderShipmentAPI, { updateShipmentObj, id });
    if (res?.success) {
      const { purchaseOrdersMetadata } = yield select(WareHouses);
      const bufferShipment = purchaseOrdersMetadata?.shipments?.find((x) => x.id === res?.data?.shipments[0]?.id);
      merge(bufferShipment, res?.data?.shipments[0]);
      purchaseOrdersMetadata.line_items = res?.data?.line_items;
      yield put(updatePurchaseOrderShipmentSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deletePurchaseOrderItemSaga ({ payload }) {
  try {
    const res = yield call(deletePurchaseOrderItemAPI, payload);
    const { purchaseOrders, purchaseOrdersMetadata } = yield select(WareHouses);
    const bufferItem = purchaseOrdersMetadata.line_items?.find((x) => x.id === payload?.ItemId);
    const index = purchaseOrders?.res?.data?.findIndex((x) => x.id === bufferItem?.wh_order_id);
    const responsedPodata = purchaseOrders.res.data[index];
    responsedPodata.purchase_order.total_quantity -= bufferItem?.quantity * bufferItem?.qty_multiplier;
    responsedPodata.purchase_order.total_shipping -= bufferItem?.quantity * bufferItem?.shipping_per_item;
    responsedPodata.purchase_order.total_cost -= bufferItem?.quantity * bufferItem?.cost_per_item;

    purchaseOrdersMetadata.line_items = purchaseOrdersMetadata.line_items.filter((x) => x.id !== payload?.ItemId);
    if (res?.success) {
      yield put(deletePOItemSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deletePurchaseOrderShipmentSaga ({ payload }) {
  try {
    const res = yield call(deletePurchaseOrderShipmentAPI, payload);
    const { purchaseOrdersMetadata } = yield select(WareHouses);
    purchaseOrdersMetadata.line_items = res?.data?.line_items;
    purchaseOrdersMetadata.shipments = res?.data?.shipments;
    if (res?.success) {
      yield put(deletePOShipmentSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateBulkInventoryItemsSaga ({ payload: { data, ids, props } }) {
  try {
    const res = yield call(updateInventoryItemsApi, { data, ids });
    if (res?.success) {
      const { filters, setFilters } = props;
      yield put(updateBulkInventoryItemsSuccess(res));
      yield put(setInventoryCheckBoxes({}));
      yield put(setUpdateTenantModal(false));
      yield fetchInventoryItemsSaga({ payload: { params: filters } });
      if (setFilters) setFilters({ ...filters, page: 1 });
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

// Warehouse Dashboard

function * searchOrderSaga ({ payload }) {
  try {
    const res = yield call(fetchInboundsApi, payload);
    if (res?.success) {
      yield put(searchOrderSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateTrackingItemShippingStatusSaga ({ payload: { id } }) {
  try {
    const res = yield call(updateTrackingItemShippingStatusApi, { id });
    if (res?.success) {
      yield put(updateWareHouseItemSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

// Inbounds generators
function * fetchInboundsSaga ({ payload: data }) {
  try {
    const res = yield call(fetchInboundsApi, data);
    if (res?.success) yield put(fetchInboundsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function * fetchInboundsIsLiquidatedSaga ({ payload: data }) {
  try {
    const res = yield call(apis.fetchInboundsIsLiquidated, data);
    yield put(actions.fetchInboundIsLiquatedSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function * fetchWHInboundStatsSage ({ payload: data }) {
  try {
    const res = yield call(fetchInboundsStatsApi, data);
    if (res?.success) yield put(fetchWHInboundStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addShipmentSaga ({ payload: data }) {
  try {
    const res = yield call(addShipmentApi, data);
    if (res?.success) yield put(addShipmentSuccess(res));
    const { inboundsData } = yield select(WareHouses);
    const fetchRes = yield call(fetchInboundsApi, {
      page: inboundsData?.page || 1,
      per_page: inboundsData?.per_page || 20,
    });
    if (fetchRes?.success) yield put(fetchInboundsSuccess(fetchRes));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * shipmentsCheckedInSaga ({ payload: { data, filters } }) {
  try {
    const res = yield call(shipmentsCheckedInApi, data);
    if (res?.success) {
      const { inboundsData } = yield select(WareHouses);
      const inboundItems = inboundsData?.data?.filter((x) => res?.data?.includes(x?.id));
      inboundItems.forEach((x) => {
        x.status = SHIPMENT_LINE_STATUSES.checked_in;
      });
      const fetchRes = yield call(fetchInboundsApi, filters);
      if (fetchRes?.success) yield put(fetchInboundsSuccess(fetchRes));
      yield put(shipmentsCheckedInSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * receiveShipmentSaga ({ payload: { data, filters } }) {
  try {
    const res = yield call(receiveShipmentApi, data);
    if (res?.success) yield put(receivedShipmentSuccess(res));
    const fetchRes = yield call(fetchInboundsApi, filters);
    if (fetchRes?.success) yield put(fetchInboundsSuccess(fetchRes));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

// Warehouse Invetory items
function * fetchWHInventorySaga ({ payload: data }) {
  try {
    const res = yield call(fetchWHInventoryApi, data);
    if (res?.success) {
      yield put(fetchWHInventorySuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchInventoryShipmentWiseSaga ({ payload: data }) {
  try {
    const res = yield call(apis.fetchInventoryShipmentWiseApi, data);
    if (res?.success) {
      yield put(actions.fetchInventoryShipmentWiseSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchWHInventoryStatsSaga () {
  try {
    const res = yield call(fetchWHInventoryStatsApi);
    if (res?.success) {
      yield put(fetchWHInventoryStatsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchWHInventoryActionSaga ({ payload: { itemID, data } }) {
  try {
    const res = yield call(apis.fetchWHInventoryActionApi, { itemID, data });
    if (res?.success) {
      const { whInvetoryData } = yield select(WareHouses);
      if (data.recv_shipment_id) {
        const bufferItem = whInvetoryData?.inventoryItems?.find((x) => x.id === data.recv_shipment_id);
        merge(bufferItem, last(res?.inventory?.received_shipments));
      } else {
        const bufferItem = whInvetoryData?.inventoryItems?.find((x) => x.id === itemID);
        merge(bufferItem, res?.inventory);
      }
      yield put(actions.fetchWHInventoryActionSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteInventoryBinSaga ({ payload: { id, recShipId } }) {
  try {
    const { whInvetoryData } = yield select(WareHouses);
    const res = yield call(apis.deleteInventoryBinApi, { id, recShipId });
    const { recIndex, inventoryItem } = getInventoryItem(whInvetoryData, recShipId);
    const data = res?.data || {};

    const whInventoryQty = inventoryItem.warehouse_inventory || inventoryItem;
    const whInventoryRemQty = inventoryItem.received_shipments?.[0] || inventoryItem;
    if (+data.inventoryQty >= 0) whInventoryQty.qty = data.inventoryQty;
    if (+data.remainingQty >= 0) whInventoryRemQty.remaining_qty = data.remainingQty;

    const args = [inventoryItem?.WhInventoryLocation || inventoryItem?.received_shipments?.[recIndex]?.WhInventoryLocation, "delete", id];
    if (!inventoryItem?.received_shipments) inventoryItem.WhInventoryLocation = getWarehouseInventoryLocation(...args);
    else inventoryItem.received_shipments[recIndex].WhInventoryLocation = getWarehouseInventoryLocation(...args);

    yield put(actions.deleteInventoryBinSucess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateInventoryBinSaga ({ payload: { id, recShipId, body, isShipmentWise } }) {
  try {
    body.recShipId = recShipId;
    body.isShipmentWise = isShipmentWise;
    const { whInvetoryData } = yield select(WareHouses);
    const res = yield call(apis.updateInventoryBinApi, { id, body });
    const { recIndex, inventoryItem } = getInventoryItem(whInvetoryData, recShipId);
    if (+res?.data?.inventoryQty >= 0) inventoryItem.warehouse_inventory.qty = res.data.inventoryQty;
    if (+res?.data?.remainingQty >= 0) inventoryItem.remaining_qty = res.data.remainingQty;
    const bufferItem2 = getWarehouseInventoryLocation(inventoryItem?.WhInventoryLocation || inventoryItem?.received_shipments?.[recIndex]?.WhInventoryLocation, "update", id);
    if (body?.qty) bufferItem2.wh_inventory_locations.qty = body?.qty;
    if (body?.binNo) bufferItem2.bin_no = body?.binNo;

    yield put(actions.updateInventoryBinSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createInventoryBinSaga ({ payload: { recShipId, body, isShipmentWise } }) {
  try {
    const { whInvetoryData } = yield select(WareHouses);
    const res = yield call(apis.createInventoryBinApi, { recShipId, ...body, isShipmentWise });
    const { recIndex, inventoryItem } = getInventoryItem(whInvetoryData, recShipId);
    if (res?.data?.inventoryQty) inventoryItem.warehouse_inventory.qty = res.data.inventoryQty;
    if (res?.data?.remainingQty) inventoryItem.remaining_qty = res.data.remainingQty;
    const bufferItem2 = inventoryItem?.WhInventoryLocation || inventoryItem?.received_shipments?.[recIndex]?.WhInventoryLocation;
    const inventoryLocationResponse = res?.data?.newInventoryLocation || res?.data?.inventoryLocation;
    const item = getWarehouseInventoryLocation(bufferItem2, "create", inventoryLocationResponse?.id);
    if (item) {
      item.wh_inventory_locations.qty = inventoryLocationResponse?.qty;
    } else {
      const obj = {
        bin_no: body?.binNo,
        wh_inventory_locations: {
          qty: body?.qty,
          id: inventoryLocationResponse?.id,
          created_at: inventoryLocationResponse?.updated_at,
        },
      };
      bufferItem2.unshift(obj);
    }

    yield put(actions.createInventoryBinSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateItemOversizedSaga ({ payload }) {
  try {
    const res = yield call(apis.updateItemOversizedApi, payload);
    const { whInvetoryData } = yield select(WareHouses);
    whInvetoryData.inventoryItems[payload.index].oversized = payload.oversized;
    yield put(actions.updateInventoryOversizedSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteInventoryItemSaga ({ payload: { body } }) {
  try {
    const res = yield call(apis.deleteInventoryItem, body);
    if (res.success) {
      const response = yield call(fetchWHInventoryApi, { page: 1, per_page: 30 });
      yield put(fetchWHInventorySuccess(response));
      yield put(actions.deleteInventoryItemSucess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

// Warehouse Outbound items
function * fetchWHOutboundSaga ({ payload: data }) {
  try {
    const res = yield call(fetchWHOutboundApi, data);
    if (res?.success) {
      yield put(fetchWHOutboundsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchWhOutboundStatsSaga ({ payload: params }) {
  try {
    const res = yield call(fetchWhOutboundStatsApi, params);
    if (res?.success) {
      yield put(fetchWhOutboundStatsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * markAsCompleteItemSaga ({ payload: { itemId, statType } }) {
  try {
    const res = yield call(apis.markAsCompleteItemAPI, itemId);
    if (res?.success) {
      const { whOutboundData } = yield select(WareHouses);
      whOutboundData.data = whOutboundData?.data?.filter((x) => x.id !== res?.outbound?.id);
      yield put(actions.markAsCompleteItemSuccess(res));

      const stats = yield call(fetchWhOutboundStatsApi);
      yield put(fetchWhOutboundStatsSuccess(stats));

      const outbounds = yield call(fetchWHOutboundApi, { page: 1, per_page: 30, statType: statType || "returnsToShip" });
      yield put(fetchWHOutboundsSuccess(outbounds));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkMarkAsCompleteSaga ({ payload: { ids, params } }) {
  try {
    const res = yield call(apis.bulkMarkAsCompleteAPI, { ids });
    if (res?.success) {
      const payload = params;
      const data = { payload };
      yield call(fetchWHOutboundSaga, data);
      yield call(fetchWhOutboundStatsSaga, { payload: { status: params?.statType } });
      yield put(actions.bulkMarkAsCompleteItemSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateInventoryItemPriceSaga ({ payload: { id, body } }) {
  try {
    const res = yield call(apis.updateInventoryItemPriceAPI, { id, body });
    if (res?.success) {
      yield put(actions.updateInventoryItemPriceSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadOutboundLabelSaga ({ payload: { id, params } }) {
  try {
    const data = yield call(apis.downloadOutboundLabel, { id, params });
    params?.is_preview && window.open(data.url, "_blank", "application/pdf") && delete data.url;
    yield put(actions.downloadOutboundLabelSuccess(data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchLabelGenerationStatsSaga ({ payload: params }) {
  try {
    const res = yield call(fetchLabelGenerationStats, params);
    if (res?.success) {
      yield put(fetchLabelGenerationStatsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteOutboundItemSaga ({ payload: { id, body } }) {
  try {
    const { whOutboundData } = yield select(WareHouses);
    const res = yield call(apis.deleteOutboundItemApi, { id });
    if (res?.success) {
      whOutboundData.data.forEach(
        (item) =>
          item.id === body?.outboundId && (item.outbound_items = item.outbound_items.filter((x) => x.id !== id)),
      );
    }
    yield put(actions.deleteOutboundItemSucess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

// Scanforms
function * fetchShippingScanformsSaga ({ payload: params }) {
  try {
    const res = yield call(apis.fetchShippingScanforms, params);
    if (res?.success) {
      yield put(actions.fetchShippingScanformsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * generateScanformSaga ({ payload: data }) {
  try {
    const res = yield call(apis.generateScanforms, data);
    yield put(actions.setGenerateScanformModal(false));
    if (res?.success) {
      const { scanforms } = yield select(WareHouses);
      scanforms?.data && scanforms.data.unshift(res?.data);
      yield put(actions.generateScanformSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadFormFileSaga ({ payload }) {
  try {
    const res = yield call(apis.downloadFormFile, payload);
    yield put(actions.downloadFormFileSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchScanformShipmentsSaga ({ payload: params }) {
  try {
    const res = yield call(apis.fetchScanformShipmentsAPI, params);
    if (res?.success) {
      yield put(actions.fetchScanformShipmentsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchSearchOrder () {
  yield takeEvery(SEARCH_ORDER, searchOrderSaga);
}

export function * watchdeleteWHAccount () {
  yield takeEvery(DELETE_WH_ACCOUNT, deleteWHAccountSaga);
}

export function * watchAssignWarehouseToAgency () {
  yield takeEvery(ASSIGN_AGENCY_TO_WAREHOUSE, assignAgencyToWarehouseSaga);
}

export function * watchFetchWarehouses () {
  yield takeEvery(FETCH_ALL_WAREHOUSES, fetchAllWarehouseSaga);
}

export function * watchFetchAllWarehouseItems () {
  yield takeEvery(FETCH_ALL_WAREHOUSE_ITEMS, fetchAllWarehouseItemsSaga);
}

export function * watchUpdateReturnsOutbounds () {
  yield takeEvery(UPDATE_RETURNS_OUTBOUND, updateReturnsOutboundsSaga);
}

export function * watchFetchWarehouseAccounts () {
  yield takeEvery(FETCH_WAREHOUSE_ACCOUNTS, fetchWarehouseAccountsSaga);
}

export function * watchUpdateWarehouseAccount () {
  yield takeEvery(UPDATE_WH_ACCOUNT, updateWHAccountSaga);
}

export function * watchCreateWarehouse () {
  yield takeEvery(CREATE_WAREHOUSE, createWareHouseSaga);
}

export function * watchAssignUserWareHouse () {
  yield takeEvery(ASSIGN_USER_WAREHOUSE, assignUserWareHouseSaga);
}

export function * watchUpdateWareHouseItem () {
  yield takeEvery(UPDATE_WAREHOUSE_ITEM, updateWareHouseItemSaga);
}

export function * watchResolveStrandedItem () {
  yield takeEvery(RESOLVE_STRANDED_ITEM, resolveStrandedItemSaga);
}

export function * watchFetchInventoryItem () {
  yield takeEvery(FETCH_INVENTORY_ITEMS, fetchInventoryItemsSaga);
}

export function * watchFetchWHChargeHistories () {
  yield takeEvery(FETCH_WH_CHARGE_HISTORIES, fetchWHChargeHistoriesSaga);
}

export function * watchupdateWHChargeHistory () {
  yield takeEvery(UPDATE_WH_CHARGE_HISTORY, updateWHChargeHistorySaga);
}

export function * watchDeleteWareHouseUser () {
  yield takeEvery(DELETE_WAREHOUSE_USER, deleteWareHouseUserSaga);
}

export function * watchDeleteWarehouse () {
  yield takeEvery(DELETE_WAREHOUSE, deleteWarehouseSaga);
}

export function * watchUpdateWarehouse () {
  yield takeEvery(UPDATE_WAREHOUSE, updateWarehouseSaga);
}

export function * watchDownloadReturnLabel () {
  yield takeEvery(DOWNLOAD_RETURN_LABEL, downloadReturnLabelSaga);
}

export function * watchInventoryItemStatus () {
  yield takeEvery(UPDATE_INVENTORY_ITEM_STATUS, updateInventoryItemStatusSaga);
}

export function * watchAddInventoryItem () {
  yield takeEvery(ADD_INVENTORY_ITEM, addInventoryItemSaga);
}

export function * watchUploadReturnLabelFile () {
  yield takeEvery(UPLOAD_RETURN_LABEL_FILE, uploadReturnLabelFileSaga);
}

export function * watchAddTrackingItemNote () {
  yield takeEvery(ADD_WAREHOUSE_NOTE, addWarehouseNoteSaga);
}

export function * watchUpdateBulkWareHouseItems () {
  yield takeEvery(UPDATE_BULK_ITEMS, updateBulkWarehouseItemsSaga);
}

export function * watchMarkNotReceived () {
  yield takeEvery(MARK_NOT_RECEIVED, markNotReceivedSaga);
}

export function * watchDeleteReturnLabel () {
  yield takeEvery(DELETE_RETURN_LABEL, deleteReturnLabelSaga);
}

export function * watchUserWarehouses () {
  yield takeEvery(FETCH_USER_WAREHOUSES, fetchUserWarehousesSaga);
}

export function * watchDownloadCharges () {
  yield takeEvery(DOWNLOAD_CHARGES_REPORT, downloadChargesReportSaga);
}

export function * watchFetchInvoiceDetail () {
  yield takeEvery(types.FETCH_INVOICE_DETAIL, fetchInvoiceDetailSaga);
}

export function * watchDownloadInventoryReport () {
  yield takeEvery(DOWNLOAD_INVENTORY_REPORT, downloadInventoryReportSaga);
}

export function * watchDownloadWarehouseItemsReport () {
  yield takeEvery(DOWNLOAD_WH_ITEMS_REPORT, downloadWarehouseInventoryReportSaga);
}

export function * watchfetchAllShippingRates () {
  yield takeEvery(FETCH_ALL_SHIPPING_RATES, fetchAllShippingRatesSaga);
}

export function * watchGenerateLabel () {
  yield takeEvery(GENERATE_LABEL, generateLabelSaga);
}

export function * watchFetchAllShipments () {
  yield takeEvery(FETCH_ALL_SHIPMENTS, fetchAllShipmentsSaga);
}

export function * watchFetchAllOutboundShipments () {
  yield takeEvery(FETCH_ALL_OUTBOUND_SHIPMENTS, fetchAllOutboundShipmentsSaga);
}

export function * watchRefundShipmentLabel () {
  yield takeEvery(REFUND_SHIPMENT_LABEL, refundLabelSaga);
}

export function * watchWHStatusStats () {
  yield takeEvery(FETCH_WH_CHARGES_STATUS_STATS, fetchWHStatusStatsSaga);
}

export function * watchDownloadShippingLabel () {
  yield takeEvery(DOWNLOAD_SHIPPING_LABEL, downloadShippingLabelSaga);
}
export function * watchUpdateInventoryItemBins () {
  yield takeEvery(UPDATE_INVENTORY_ITEM_BINS, updateInventoryItemBinsSaga);
}

export function * watchDeleteInventoryItemBin () {
  yield takeEvery(DELETE_INVENTORY_ITEM_BIN, deleteInventoryItemBinSaga);
}

// Purchase Orders
export function * watchPurchaseOrders () {
  yield takeEvery(FETCH_PURCHASE_ORDERS, fetchPurchaseOrdersSaga);
}

export function * watchPurchaseOrderMetadata () {
  yield takeEvery(FETCH_PURCHASE_ORDERS_METADATA, fetchPurchaseOrderMetadataSaga);
}

export function * watchFetchPurchaseOrderLogs () {
  yield takeEvery(FETCH_PO_LOGS, fetchPurchaseOrderLogsSaga);
}

export function * watchFetchPurchaseOrderStats () {
  yield takeEvery(FETCH_PO_STATS, fetchPurchaseOrderStatsSaga);
}

export function * watchFetchPurchaseOrderSuppliers () {
  yield takeEvery(FETCH_PO_SUPPLIERS, fetchPurchaseOrderStatsSuppliersSaga);
}

export function * watchCreatePurchaseOrder () {
  yield takeEvery(CREATE_PURCHASE_ORDERS, createPurchaseOrderSaga);
}

export function * watchcreateLineItem () {
  yield takeEvery(CREATE_LINE_ITEM, createLineItemSaga);
}

export function * watchCreatePOShipment () {
  yield takeEvery(CREATE_PO_SHIPMENT, createPOShipmentSaga);
}

export function * watchDeletePurchaseOrder () {
  yield takeEvery(DELETE_PURCHASE_ORDER, deletePurchaseOrderSaga);
}

export function * watchUpdatePurchaseOrder () {
  yield takeEvery(UPDATE_PURCHASE_ORDER, updatePurchaseOrderSaga);
}

export function * watchUpdatePurchaseOrderItem () {
  yield takeEvery(UPDATE_PURCHASE_ORDER_ITEM, updatePurchaseOrderItemSaga);
}

export function * watchUpdatePurchaseOrderShipment () {
  yield takeEvery(UPDATE_PURCHASE_ORDER_SHIPMENT, updatePurchaseOrderShipmentSaga);
}

export function * watchDeletePurchaseOrderItem () {
  yield takeEvery(DELETE_PURCHASE_ORDER_ITEM, deletePurchaseOrderItemSaga);
}

export function * watchDeletePurchaseOrderShipment () {
  yield takeEvery(DELETE_PURCHASE_ORDER_SHIPMENT, deletePurchaseOrderShipmentSaga);
}

export function * watchUpdateBulkInventoryItems () {
  yield takeEvery(BULK_UPDATE_INVENTORY_ITEM, updateBulkInventoryItemsSaga);
}

export function * watchUpdateTrackingItemShippingStatus () {
  yield takeEvery(UPDATE_TRACKING_ITEM_SHIPPPING_STATUS, updateTrackingItemShippingStatusSaga);
}

export function * watchdownloadWHLabelReportSaga () {
  yield takeEvery(DOWNLOAD_WH_LABEL_REPORT, downloadWHLabelReportSaga);
}

// Inbounds watchers
export function * watchFetchInbounds () {
  yield takeEvery(FETCH_INBOUND_ITEMS, fetchInboundsSaga);
}

export function * watchFetchInboundsIsLiquidated () {
  yield takeEvery(types.FETCH_INBOUND_IS_LIQUATED, fetchInboundsIsLiquidatedSaga);
}

export function * watchFetchWHInboundStats () {
  yield takeEvery(FETCH_WH_INBOUND_STATS, fetchWHInboundStatsSage);
}

export function * watchReceiveShipment () {
  yield takeEvery(RECEIVE_SHIPMENT, receiveShipmentSaga);
}

export function * watchShipmentsCheckedIn () {
  yield takeEvery(SHIPMENT_CHECKED_IN, shipmentsCheckedInSaga);
}

export function * watchAddShipment () {
  yield takeEvery(ADD_SHIPMENT, addShipmentSaga);
}

// Warehouse Invetory items
export function * watchFetchWHInventory () {
  yield takeEvery(FETCH_WH_INVENTORY, fetchWHInventorySaga);
}
export function * watchFetchInventoryShipmentWise () {
  yield takeEvery(types.FETCH_INVENTORY_SHIPMENT_WISE, fetchInventoryShipmentWiseSaga);
}
export function * watchFetchWHInventoryStats () {
  yield takeEvery(FETCH_WH_INVENTORY_STATS, fetchWHInventoryStatsSaga);
}
export function * watchFetchWHInventoryAction () {
  yield takeEvery(types.FETCH_WH_INVENTORY_ACTIONS, fetchWHInventoryActionSaga);
}
export function * watchUpdateItemOversizedAction () {
  yield takeEvery(types.UPDATE_INVENTORY_OVERSIZED, updateItemOversizedSaga);
}
export function * watchDeleteInventoryBin () {
  yield takeEvery(types.DELETE_WH_INVENTORY_BIN, deleteInventoryBinSaga);
}
export function * watchUpdateInventoryBin () {
  yield takeEvery(types.UPDATE_WH_INVENTORY_BIN, updateInventoryBinSaga);
}
export function * watchCraeteInventoryBin () {
  yield takeEvery(types.CREATE_WH_INVENTORY_BIN, createInventoryBinSaga);
}
export function * watchDeleteInventoryItem () {
  yield takeEvery(types.DELETE_INVENTORY_ITEM, deleteInventoryItemSaga);
}

export function * watchFetchStrandedCount () {
  yield takeEvery(types.FETCH_STRANDED_COUNT, fetchStrandedCountSaga);
}

// Warehouse Outbound items
export function * watchFetchWHOutbound () {
  yield takeEvery(FETCH_WH_OUTBOUNDS, fetchWHOutboundSaga);
}
export function * watchFetchWhOutboundStats () {
  yield takeEvery(FETCH_WH_OUTBOUND_STATS, fetchWhOutboundStatsSaga);
}
export function * watchMarkAsCompleteItem () {
  yield takeEvery(types.MARK_AS_COMPLETE_ITEM, markAsCompleteItemSaga);
}
export function * watchBulkMarkAsComplete () {
  yield takeEvery(types.BULK_MARK_AS_COMPLETE, bulkMarkAsCompleteSaga);
}
export function * watchUpdateInventoryItemPrice () {
  yield takeEvery(types.UPDATE_INVENTORY_ITEM_PRICE, updateInventoryItemPriceSaga);
}

export function * watchDownloadOutboundLabel () {
  yield takeEvery(types.DOWNLOAD_OUTBOUND_LABEL, downloadOutboundLabelSaga);
}

export function * watchDeleteOutboundItem () {
  yield takeEvery(types.DELETE_OUTBOUND_ITEM, deleteOutboundItemSaga);
}

// Label Generation Stats
export function * watchfetchLabelGenerationStats () {
  yield takeEvery(FETCH_LABEL_USERS_AND_COUNT, fetchLabelGenerationStatsSaga);
}

// Scanforms
export function * watchFetchShippingScanformsSaga () {
  yield takeEvery(types.FETCH_SHIPPING_SCANFORMS, fetchShippingScanformsSaga);
}

export function * watchGenerateScanformSaga () {
  yield takeEvery(types.GENERATE_SCANFORM, generateScanformSaga);
}

export function * watchDownloadFormFileSaga () {
  yield takeEvery(types.DOWNLOAD_FORM_FILE, downloadFormFileSaga);
}

export function * watchFetchScanformShipments () {
  yield takeEvery(types.FETCH_SCANFORM_SHIPMENTS, fetchScanformShipmentsSaga);
}

function * WareHouseSaga () {
  yield all([
    fork(watchReceiveShipment),
    fork(watchShipmentsCheckedIn),
    fork(watchAddShipment),
    fork(watchFetchInbounds),
    fork(watchFetchInboundsIsLiquidated),
    fork(watchFetchWHInboundStats),
    fork(watchSearchOrder),
    fork(watchdeleteWHAccount),
    fork(watchAssignWarehouseToAgency),
    fork(watchFetchWarehouses),
    fork(watchFetchAllWarehouseItems),
    fork(watchFetchWarehouseAccounts),
    fork(watchUpdateReturnsOutbounds),
    fork(watchCreateWarehouse),
    fork(watchAssignUserWareHouse),
    fork(watchUpdateWareHouseItem),
    fork(watchUpdateWarehouse),
    fork(watchDeleteWareHouseUser),
    fork(watchAddTrackingItemNote),
    fork(watchResolveStrandedItem),
    fork(watchFetchInventoryItem),
    fork(watchFetchWHChargeHistories),
    fork(watchupdateWHChargeHistory),
    fork(watchInventoryItemStatus),
    fork(watchUploadReturnLabelFile),
    fork(watchDownloadReturnLabel),
    fork(watchDeleteWarehouse),
    fork(watchUpdateWarehouseAccount),
    fork(watchUpdateBulkWareHouseItems),
    fork(watchMarkNotReceived),
    fork(watchDeleteReturnLabel),
    fork(watchUserWarehouses),
    fork(watchDownloadCharges),
    fork(watchfetchAllShippingRates),
    fork(watchGenerateLabel),
    fork(watchWHStatusStats),
    fork(watchFetchAllShipments),
    fork(watchFetchAllOutboundShipments),
    fork(watchRefundShipmentLabel),
    fork(watchDownloadShippingLabel),
    fork(watchDownloadInventoryReport),
    fork(watchDownloadWarehouseItemsReport),
    fork(watchAddInventoryItem),
    fork(watchUpdateInventoryItemBins),
    fork(watchDeleteInventoryItemBin),
    fork(watchPurchaseOrders),
    fork(watchPurchaseOrderMetadata),
    fork(watchFetchPurchaseOrderLogs),
    fork(watchFetchPurchaseOrderStats),
    fork(watchFetchPurchaseOrderSuppliers),
    fork(watchCreatePurchaseOrder),
    fork(watchcreateLineItem),
    fork(watchCreatePOShipment),
    fork(watchDeletePurchaseOrder),
    fork(watchUpdatePurchaseOrder),
    fork(watchUpdatePurchaseOrderItem),
    fork(watchUpdatePurchaseOrderShipment),
    fork(watchDeletePurchaseOrderItem),
    fork(watchDeletePurchaseOrderShipment),
    fork(watchUpdateBulkInventoryItems),
    fork(watchUpdateTrackingItemShippingStatus),
    fork(watchdownloadWHLabelReportSaga),
    fork(watchFetchWHInventory),
    fork(watchFetchInventoryShipmentWise),
    fork(watchFetchWHInventoryStats),
    fork(watchFetchWHOutbound),
    fork(watchFetchWhOutboundStats),
    fork(watchMarkAsCompleteItem),
    fork(watchBulkMarkAsComplete),
    fork(watchUpdateInventoryItemPrice),
    fork(watchfetchLabelGenerationStats),
    fork(watchFetchWHInventoryAction),
    fork(watchUpdateInventoryBin),
    fork(watchDeleteInventoryBin),
    fork(watchCraeteInventoryBin),
    fork(watchFetchInvoiceDetail),
    fork(watchDownloadOutboundLabel),
    fork(watchDeleteOutboundItem),
    fork(watchFetchShippingScanformsSaga),
    fork(watchGenerateScanformSaga),
    fork(watchDownloadFormFileSaga),
    fork(watchFetchScanformShipments),
    fork(watchUpdateItemOversizedAction),
    fork(watchDeleteInventoryItem),
    fork(watchFetchStrandedCount),
  ]);
}

export default WareHouseSaga;
