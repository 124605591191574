import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Badge,
  CardHeader,
  NavItem,
  FormGroup,
  Nav,
  Col,
  NavLink,
  Tooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import Select, { components } from "react-select";
import { useCookies } from "react-cookie";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  ISSUE_OPTIONS,
  STATUS_COLOR_CODE,
  ORDER_STATUS_MAPPING,
  SOURCE_ORDER_STATUSES,
  DATE_FORMATS,
  MARKET_PLACES,
} from "../../constants";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomPagination from "../../components/Common/CustomPagination";
import { isAutoOrderable, getUniqueSkus } from "../../utils/profitCalculator";
import MarkAsIssue from "../../components/Common/MarkAsIssue";
import OrderLogModal from "./components/OrderLogModal";
import PillBtn from "../../components/Common/PillBtn";
import OrderStatusModal from "./components/OrderStatusModal";
import UploadOrdersModal from "./components/UploadOrdersModal";
import ShipAddress from "../../components/Common/ShipAddress";
import ImportOrderManuallyModal from "./components/ImportOrderManuallyModal";
import SetupWhBanner from "../../components/Common/SetupWhBanner";
import moment from "moment-timezone";
import {
  decode,
  toPascalCase,
  last,
  parseIssueNote,
  calculateSale,
  RenderIf,
  getEstDate,
  encode,
  formatNumber,
  isUserHaveFullAccess,
} from "../../utils/common";
import queryString from "query-string";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import SupplierSKU from "./components/SupplierSKU";
import { countBy, omit, uniq, keys, pick, findIndex, orderBy, pickBy } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// actions
import {
  fetchSourceOrders,
  setPreloader,
  updateAOStatus,
  fetchPlatforms,
  setSelectedOrders,
  setOrdersBulkUpdateModal,
  fetchAOBotStatus,
  setExportOrderModal,
  setPaymentReportModal,
  setOrdersImportModal,
  setUploadOrdersModal,
  setOrderLogModal,
  fetchAllWarehouses,
} from "../../store/actions";
import getBrandImageSrc from "../../utils/brandImages";
import { DatePicker } from "../../components/Common/DatePickr";
import ArchiveBadge from "../../components/Common/ArchiveBadge";
import { ShowProfit } from "./common";

const parseInQueueStatus = (order) =>
  ["checking", "processing", "delayed"].includes(order?.status) ? "in_queue" : order?.status;
const { DATE, FULL_DATE } = DATE_FORMATS;
const aoCounts = (props) => props?.SourceOrders?.sourceOrders?.metadata?.aoCounts;
const { Option } = components;
const channels = {
  warhouse: { value: "WH", label: "Warehouse Fulfilled" },
  merchant: { value: "MFN", label: "Merchant Fulfilled" },
  all: { value: "ALL", label: "All Types" },
};

const aoStatusColor = (props) => {
  const { aoStatus, aoCredsPresent } = props?.Settings || {};
  if (!aoCredsPresent) return "FFA500";
  return aoStatus ? "00FF00" : "FF0000";
};

const channelOptions = (marketPlace, props, isWh, activeTab, isTwoStep) => {
  if (isWh) return [channels.warhouse];
  const accounts = props.SourceOrders?.sourceOrders?.accounts;
  const marketplaces = Array.isArray(accounts)
    ? uniq(accounts.map((x) => x.marketplace).filter((x) => x))
    : ["amazon", "walmart"];
  let allChannels = {
    all: channels.all.label,
    MFN: channels.merchant.label,
    AFN: "Amazon Fulfilled",
    WFS: "Walmart Fulfilled",
  };

  if (isTwoStep) allChannels.WH = channels.warhouse.label;
  if (marketPlace?.marketplace === "amazon" || !marketplaces.includes("walmart")) {
    allChannels = omit(allChannels, "WFS");
  }

  if (activeTab !== "Shipped") allChannels = omit(allChannels, "WFS", "AFN");
  else if (marketPlace?.marketplace === "walmart" || !marketplaces.includes("amazon")) {
    allChannels = omit(allChannels, "AFN");
  }

  return Object.entries(allChannels).reduce((acc, row) => {
    acc.push({ value: row[0], label: row[1] });
    return acc;
  }, []);
};

const StoreOption = (props) => (
  <Option {...props} className="d-flex align-items-center">
    {getBrandImageSrc(props.data.marketplace) ? (
      <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
    ) : null}
    {props.data.label}
    {props.data.is_archive && ArchiveBadge()}
  </Option>
);
const IssueMenuOption = (props) => {
  const { label } = props.data;
  return (
    <Option {...props}>
      {label}
      {label === ISSUE_OPTIONS.Critical ? <i className="ml-2 fas fa-star-of-life text-danger"></i> : null}
    </Option>
  );
};
const sortedReasons = (props) => {
  const arr = props?.SourceOrders?.sourceOrders?.metadata?.issueReasons || [];
  const index = arr.findIndex((x) => x.issue_reason === ISSUE_OPTIONS.Critical);
  if (index > -1) {
    const [issue] = arr.splice(index, 1);
    arr.unshift(issue);
  }
  return arr;
};

const isTabVisible = (props, status) => !!props?.SourceOrders?.sourceOrders?.tabsVisibilities?.[status];
const getFulfillmentChannel = (item) => {
  if (item.store_name === MARKET_PLACES.walmart && item.fulfillment_channel === "WFS") return item.fulfillment_channel;
  if (item.store_name === MARKET_PLACES.amazon && item.fulfillment_channel === "AFN") return "FBA";
  return "FBM";
};

const SourceOrders = (props) => {
  const hasFullAccess = isUserHaveFullAccess();
  const isTwoStep = props.Settings.account?.data?.two_step_feature;
  const allColumns = {
    Platform: true,
    "Order Id": true,
    "Order Date": true,
    "Expected Ship Date": true,
    "Order Details": true,
    "Ship Name & Address": true,
    "Total Price": true,
    "Estimated Profit": true,
    Profit: true,
    "Last Checked": true,
    "Verified Match": hasFullAccess,
    "Processed By": true,
    "Internal Status": true,
    "Marketplace Status": true,
    "Supplier Orders": true,
    Suppliers: hasFullAccess,
    Action: hasFullAccess,
  };

  const [columns, setColumns] = useState({
    Check: false,
    Uncheck: true,
    ...allColumns,
  });

  const [orderItem, setOrderItem] = useState(null);
  const { associatedWarehousesObj } = props?.WareHouses;
  const stores = props?.MarketPlaceAccount?.accounts?.data;
  const defaultColumns = ["Order Id", "Order Date", "Expected Ship Date", "Last Checked"];

  const [cookies, setCookie] = useCookies(["date_filter"]);
  const [verifiedToggleConfirmation, setVerifiedToggleConfirmation] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportDropdown, setReportDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [aoTooltipOpen, setAOTooltipOpen] = useState(false);
  const [internalStatusTooltip, setInternalStatusTooltip] = useState({});
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleAOTooltip = () => setAOTooltipOpen(!aoTooltipOpen);
  const [aoAttemptSummary, setAoAttemptSummary] = useState({});
  const { statusTab } = useParams();
  const queryParameters = queryString.parse(props.location.search);
  const [marketPlace, setMarketPlace] = useState({
    value: "",
    label: "Select an Account",
  });

  const isWH = ["wh_delivered", "wh_shipped"].some((x) => statusTab?.includes(x));
  const [fulfillmentChannel, setFulfillmentChannel] = useState(isWH ? channels.warhouse : channels.all);
  const [errorPrefix, setErrorPrefix] = useState({
    value: "",
    label: "All Errors",
  });
  const [issueReason, setIssueReason] = useState({
    value: "",
    label: "All Issues",
  });

  const tableTabs = [
    { title: "All", param: {}, url: "all" },
    { title: "New", param: { status: SOURCE_ORDER_STATUSES.new }, url: "new" },
    {
      title: "In Queue",
      param: { status: SOURCE_ORDER_STATUSES.delayed },
      show: isTabVisible(props, "inQueueTab"),
      url: "delayed",
    },
    {
      title: "Manual Fulfillment",
      param: { status: SOURCE_ORDER_STATUSES.manual_fulfillment },
      show: isTabVisible(props, "manuallyFulfilledTab"),
      url: "manual_fulfillment",
    },
    {
      title: "Manual Checking",
      param: { status: SOURCE_ORDER_STATUSES.manual_checking },
      show: isTabVisible(props, "manualCheckingTab"),
      url: "manual_checking",
    },
    {
      title: "Partially Processed",
      param: {
        status: SOURCE_ORDER_STATUSES.partially_processed,
      },
      show: isTabVisible(props, "partialTab"),
      url: "partially_processed",
    },
    {
      title: "Errored",
      param: { status: SOURCE_ORDER_STATUSES.errored },
      show: isTabVisible(props, "erroredTab"),
      url: "errored",
    },
    {
      title: "Processed",
      param: { status: SOURCE_ORDER_STATUSES.processed },
      url: "processed",
      show: isTabVisible(props, "processedTab"),
    },
    {
      title: "Shipped To Warehouse",
      param: { status: SOURCE_ORDER_STATUSES.wh_shipped },
      show: isTabVisible(props, "whShippedTab"),
      url: "wh_shipped",
    },
    {
      title: "Delivered To Warehouse",
      param: { status: SOURCE_ORDER_STATUSES.wh_delivered },
      show: isTabVisible(props, "whDeliveredTab"),
      url: "wh_delivered",
    },
    {
      title: "Marked Not Shipped",
      param: { status: SOURCE_ORDER_STATUSES.marked_not_shipped },
      show: isTabVisible(props, "markedNotShippedTab"),
      url: "marked_not_shipped",
    },
    {
      title: "Shipped With TBA",
      param: { status: SOURCE_ORDER_STATUSES.shipped_with_tba },
      show: isTabVisible(props, "shippedWithTBATab"),
      url: "shipped_with_tba",
    },
    {
      title: "Delivered With TBA",
      param: { status: SOURCE_ORDER_STATUSES.delivered_with_tba },
      show: isTabVisible(props, "deliveredWithTBATab"),
      url: "delivered_with_tba",
    },
    {
      title: "Shipped",
      param: { status: SOURCE_ORDER_STATUSES.shipped },
      url: "shipped",
    },
    {
      title: "Issue",
      param: { issue: "true" },
      show: isTabVisible(props, "issueTab"),
      url: "issue",
    },
    {
      title: "Missing COGS",
      param: { missing_cogs: "true", ...pick(queryParameters, "marketplace_account_id") },
      show: isTabVisible(props, "missingCogsTab"),
      url: "missing_cogs",
    },
  ];

  const SourceOrderIds = props.SourceOrders.sourceOrders?.data?.map((x) => x.id);

  const [activeTab, setActiveTab] = useState(tableTabs.findIndex((tab) => tab.url === statusTab) || 1);

  function handleBulkUpdateOrders () {
    props.setOrdersBulkUpdateModal(true);
  }

  const getDateValues = () => {
    const value = cookies.date_filter || {};
    if (value.start_date && value.end_date) {
      return [moment(value.start_date), moment(value.end_date)];
    }
    return [moment().subtract(3, "months"), moment()];
  };

  const dates = getDateValues();
  const [tabsFilter, setTabFilter] = useState({
    ...(tableTabs[activeTab]?.param || {}),
    sort_by: "order_date",
    sort_direction: "desc",
    page: queryParameters?.page || 1,
    per_page: 50,
    marketplace_account_id: queryParameters?.marketplace_account_id || "",
    store_channel: isWH ? "WH" : channels.all.value,
    issue_reason: queryParameters.issue_reason || "",
    error_prefix: queryParameters.error_prefix || "",
    start_date: dates[0].format(DATE_FORMATS.BACKEND),
    end_date: dates[1].format(DATE_FORMATS.BACKEND),
    utc_offset: moment().format("Z"),
    marketplace_status: "",
  });

  const [orderByParams, setOrderByParams] = useState({});

  const [unacknowledgedOrdersOnly, setUnacknowledgedOrdersOnly] = useState(false);

  const getMarketplaceStatus = (toggleValue) => (toggleValue ? "Created" : "");

  const hideTabsFilterIcons = () => {
    const upIcon = document.getElementById(`${tabsFilter.sort_by}-up`);
    const downIcon = document.getElementById(`${tabsFilter.sort_by}-down`);

    if (upIcon) upIcon.style.visibility = tabsFilter.sort_direction === "desc" ? "hidden" : "initial";
    if (downIcon) downIcon.style.visibility = tabsFilter.sort_direction === "asc" ? "hidden" : "initial";
  };

  const toggleOrder = (currentOrder) => {
    let [func, value] = [resetFilter, null];

    if (!currentOrder) value = "asc";
    else if (currentOrder === "asc") value = "desc";
    else func = hideTabsFilterIcons;

    func();
    return value;
  };

  const tableHeaders = [
    { title: "Platform" },
    { title: "Order Id" },
    {
      title: "Order Date",
      col: "order_date",
      onClick: function () {
        setTabFilter({
          ...tabsFilter,
          sort_by: this.col,
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
        });
      },
    },
    {
      title: "Expected Ship Date",
      col: "required_shipping_date",
      onClick: function () {
        setTabFilter({
          ...tabsFilter,
          sort_by: this.col,
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
        });
      },
    },
    { title: "Order Details" },
    { title: "Ship Name & Address" },
    { title: "Total Price" },
    {
      title: ["New", "Errored", "Manual Fulfillment"].includes(tableTabs[activeTab].title)
        ? "Estimated Profit"
        : "Profit",
      onClick: () =>
        setOrderByParams(pickBy({ order_profit: toggleOrder(orderByParams.order_profit) }, (val) => val !== null)),
    },
    {
      title: "Last Checked",
      col: "last_checked",
      onClick: function () {
        setTabFilter({
          ...tabsFilter,
          sort_by: this.col,
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
        });
      },
      show: tableTabs[activeTab].title === "Errored",
    },
    { isHidden: !hasFullAccess, title: "Verified Match" },
    {
      title: "Processed By",
      show: tableTabs[activeTab].title === "Manual Fulfillment",
    },
    {
      title: "Internal Status",
      show: tableTabs[activeTab].title !== "Manual Fulfillment",
    },
    {
      title: "Marketplace Status",
      show: tableTabs[activeTab].title !== "Manual Fulfillment",
    },
    {
      title: "Supplier Orders",
      show: tableTabs[activeTab].title !== "New",
    },
    { isHidden: !hasFullAccess, title: "Suppliers" },
    { isHidden: !hasFullAccess, title: "Action" },
  ];

  const toggleCustomJustified = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getAccount = (acc, idx) => ({
    label: acc.name,
    value: acc.id || acc.value,
    marketplace: acc.marketplace,
    key: idx,
    is_archive: acc.is_archive,
  });

  const resetFilter = () => {
    document.querySelectorAll(".dripicons-arrow-thin-up").forEach((element) => {
      element.style.visibility = "initial";
    });
    document.querySelectorAll(".dripicons-arrow-thin-down").forEach((element) => {
      element.style.visibility = "initial";
    });
  };

  const refreshSourceOrders = () => setTabFilter({ ...tabsFilter });

  useEffect(() => {
    if (tabsFilter.search) setActiveTab(0);
    props.fetchSourceOrders(tabsFilter);
    setOrderByParams({});
    resetFilter();
    if (["up", "down"].some((type) => !document.getElementsByClassName(`dripicons-arrow-thin-${type}`).length)) return;

    hideTabsFilterIcons();
  }, [tabsFilter]);

  useEffect(() => {
    !props.Platform.platforms.data?.length && props.fetchPlatforms({});
    props.fetchAllWarehouses({ associated: true });
  }, []);

  useEffect(() => {
    props.Settings?.account?.data?.ao_enabled === true && props.fetchAOBotStatus();
  }, [props.Settings?.account?.data?.ao_enabled]);

  useEffect(() => {
    props.setPreloader(props.SourceOrders.loading || props.SourceOrders.shippingTemplateLoading);
  }, [props.SourceOrders.loading, props.SourceOrders.shippingTemplateLoading]);

  useEffect(() => {
    props.setPreloader(props.Supplier.loading);
  }, [props.Supplier.loading]);

  useEffect(() => {
    props.Supplier.success && !props.Supplier.loading && props.fetchSourceOrders(tabsFilter);
  }, [props.Supplier.success, props.Supplier.loading]);

  useEffect(() => {
    if (!Array.isArray(props?.SourceOrders?.sourceOrders?.data)) return;
    const summary = props.SourceOrders.sourceOrders.data?.reduce((obj, item) => {
      const orderAttempts = item?.order_attempts?.filter(
        (x) =>
          x.error_description &&
          x.error_description !== "." &&
          !["bot will", "bot wont"].some((str) => x.error_description?.toLowerCase().includes(str)),
      );
      const stats = countBy(orderAttempts, "error_description");
      if (keys(stats).length) obj[item.id] = stats;
      return obj;
    }, {});
    setAoAttemptSummary(summary);
  }, [props?.SourceOrders?.sourceOrders?.data]);

  useEffect(() => {
    const { marketplace_account_id: storeId } = queryParameters || {};
    if (!storeId) return;

    const accounts = props.SourceOrders?.sourceOrders?.accounts || [];
    const accIdx = findIndex(accounts, (acc) => acc.id === +storeId);
    if (accIdx === -1) return;

    setMarketPlace(getAccount(accounts[accIdx], accIdx));
  }, [props.SourceOrders?.sourceOrders?.accounts]);

  return (
    <React.Fragment>
      {props.SourceOrders.ordersBulkUpdateModalIsOpen && (
        <OrderStatusModal
          isOpen={props.SourceOrders.ordersBulkUpdateModalIsOpen}
          toggleIsOpen={props.setOrdersBulkUpdateModal}
          type="multi"
          refresh={refreshSourceOrders}
        />
      )}
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            props.updateAOStatus(omit(verifiedToggleConfirmation, "itemId"), verifiedToggleConfirmation.itemId);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {`to ${verifiedToggleConfirmation?.status ? "enable" : "disable"} verified match`}
        </SweetAlert>
      </RenderIf>

      {props.SourceOrders.ordersImportModalIsOpen && <ImportOrderManuallyModal />}
      {props.SourceOrders.uploadOrdersModalIsOpen && <UploadOrdersModal />}
      {props.SourceOrders.orderLogModalIsOpen && (
        <OrderLogModal
          isOpen={props.SourceOrders.orderLogModalIsOpen}
          toggleIsOpen={props.setOrderLogModal}
          sourceOrderId={orderItem.id}
          sourceOrder={orderItem}
          error={props.SourceOrders.error}
          type="order_note"
        />
      )}
      <div className="page-content">
        <Container fluid>
          <SetupWhBanner associatedWarehouses={associatedWarehousesObj} stores={stores} />
          {props.SourceOrders?.success && !props.isLoading && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.SourceOrders?.success || ""}
              </Alert>
            </div>
          )}
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Orders"
            children={
              <div className="d-flex gap-1 flex-wrap">
                <div className="d-flex align-items-center inbound-date-range">
                  <DatePicker
                    icon
                    placeholder="Specify Order Date"
                    style={{ width: "280px", border: "none" }}
                    className="my-2"
                    pickrClass="pl-2"
                    onClose={(dates) => {
                      setCookie("date_filter", dates, { expires: moment().add(4, "hours").toDate() });
                      setTabFilter({ ...tabsFilter, ...dates, page: 1 });
                    }}
                    initialDates={dates.map((x) => getEstDate(x).toDate())}
                  />
                  <i className="bx font-size-16 bx-calendar text-primary mx-3" />
                </div>

                <RenderIf isTrue={hasFullAccess}>
                  <ButtonDropdown isOpen={reportDropdown} toggle={() => setReportDropdown(!reportDropdown)}>
                    <DropdownToggle className="d-inline-flex align-items-center gap-1" caret color="primary">
                      Report
                      <i className="bx bx-xs bx-cloud-download" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* 1st part */}
                      <DropdownItem header>Download Report</DropdownItem>
                      <DropdownItem onClick={() => props.setExportOrderModal(true, "order")}>Order Report</DropdownItem>
                      <DropdownItem onClick={() => props.history.push("/view_report")}>
                        VA Performance Report
                      </DropdownItem>
                      <DropdownItem onClick={() => props.history.push("/order_processor_report")}>
                        Order Processor Report
                      </DropdownItem>
                      <DropdownItem onClick={() => props.setPaymentReportModal(true)}>Payment Report</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem header>Upload Report</DropdownItem>
                      <DropdownItem onClick={() => props.setUploadOrdersModal(true)}>Upload Orders Report</DropdownItem>
                      <DropdownItem>
                        <a href={"/template_file_upload_orders.csv"} download>
                          Upload Orders Sample File
                        </a>
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </RenderIf>

                <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                  <DropdownToggle caret color="primary">
                    Select Columns
                    <i className="mdi mdi-chevron-down ml-1"></i>
                  </DropdownToggle>
                  <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }}>
                    {columns.Uncheck && (
                      <DropdownItem
                        onClick={() =>
                          setColumns({
                            Check: true,
                            ...Object.keys(allColumns).reduce((acc, x) => {
                              if (defaultColumns.includes(x)) acc[x] = true;
                              else acc[x] = false;
                              return acc;
                            }, {}),
                          })
                        }
                      >
                        Uncheck All
                      </DropdownItem>
                    )}
                    {columns.Check && (
                      <DropdownItem
                        onClick={() =>
                          setColumns({
                            Uncheck: true,
                            ...Object.keys(allColumns).reduce((acc, x) => {
                              acc[x] = true;
                              return acc;
                            }, {}),
                          })
                        }
                      >
                        Check All
                      </DropdownItem>
                    )}
                    {tableHeaders.map((header, key) =>
                      header.show === false ? null : (
                        <DropdownItem
                          key={`_select_columns_+ ${key}`}
                          onClick={() => {
                            if (header.title === "Estimated Profit" || header.title === "Profit") {
                              setColumns({
                                ...columns,
                                "Estimated Profit": !columns[header.title],
                                Profit: !columns[header.title],
                              });
                            } else {
                              setColumns({
                                ...columns,
                                [header.title]: !columns[header.title],
                              });
                            }
                          }}
                        >
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={columns[header.title]}
                              onChange={() => false}
                            />
                            <label className="custom-control-label">{header.title}</label>
                          </div>
                        </DropdownItem>
                      ),
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
                <PillBtn
                  color="success"
                  title="Import Order Manually"
                  name="Import Order Manually"
                  icon="bx bx-xs bx-cloud-download"
                  onClick={() => props.setOrdersImportModal(true)}
                />
                <RenderIf isTrue={hasFullAccess && props.SourceOrders?.selectedOrders?.length}>
                  <PillBtn
                    color="warning"
                    title="Edit Internal Status"
                    name="Edit Internal Status"
                    icon="bx bx-xs bx-edit"
                    onClick={handleBulkUpdateOrders}
                  />
                </RenderIf>
              </div>
            }
          />
          {props.SourceOrders.importOrderRes && (
            <div className="auto-hide">
              <Alert color="warning">
                <i className="bx bx-info-circle pr-2"></i>
                {props.SourceOrders.importOrderRes.message}
              </Alert>
            </div>
          )}
          <Card>
            <CardHeader>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center flex-wrap">
                  <form
                    className="app-search d-none d-lg-block"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (search.trim()) {
                        setActiveTab(0);
                        tabsFilter.status && delete tabsFilter.status;
                        setTabFilter({
                          ...tabsFilter,
                          search: search.trim(),
                          page: 1,
                        });
                      }
                    }}
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => {
                          if (!e.target.value) {
                            tabsFilter.search && delete tabsFilter.search;
                            tabsFilter.status && delete tabsFilter.status;
                            setActiveTab(1);
                            setTabFilter({
                              ...tabsFilter,
                              status: SOURCE_ORDER_STATUSES.new,
                              page: 1,
                            });
                            setSearch("");
                          } else {
                            setSearch(e.target.value);
                          }
                        }}
                      />
                      <span className="bx bx-search-alt"></span>
                    </div>
                  </form>
                  <FormGroup className="select2-container ml-2" style={{ width: "200px", marginTop: 15 }}>
                    <Select
                      name="marketplace_account_id"
                      value={{
                        value: marketPlace.value,
                        label: marketPlace.label,
                      }}
                      onChange={(e) => {
                        setFulfillmentChannel(channels.all);
                        const isWalmart = e.marketplace === "walmart";
                        setTabFilter({
                          ...tabsFilter,
                          marketplace_account_id: e.value,
                          store_channel: channels.all.value,
                          page: 1,
                          ...(!isWalmart && { marketplace_status: "" }),
                        });
                        !isWalmart && setUnacknowledgedOrdersOnly(false);
                        setMarketPlace(e);
                      }}
                      options={props.SourceOrders?.sourceOrders?.accounts?.reduce(
                        (init, acc, i) => {
                          init.push(getAccount(acc, i));
                          return init;
                        },
                        [{ value: "", label: "All Accounts" }],
                      )}
                      classNamePrefix="select2-selection"
                      components={{ Option: StoreOption }}
                    />
                  </FormGroup>
                  <FormGroup className="select2-container ml-2" style={{ width: "200px", marginTop: 15 }}>
                    <Select
                      value={{ value: fulfillmentChannel.value, label: fulfillmentChannel.label }}
                      onChange={(e) => {
                        setFulfillmentChannel(e);
                        setTabFilter({
                          ...tabsFilter,
                          store_channel: e.value,
                          page: 1,
                        });
                      }}
                      name="store_channel"
                      options={channelOptions(marketPlace, props, isWH, tableTabs[activeTab].title, isTwoStep)}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <RenderIf isTrue={!tabsFilter.marketplace_account_id || marketPlace.marketplace === "walmart"}>
                    <div className="custom-control custom-switch custom-switch-lg ml-2 my-auto">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="unacknowledged_orders"
                        checked={unacknowledgedOrdersOnly}
                        onChange={() => null}
                      />
                      <label
                        onClick={() => {
                          const newToggleValue = !unacknowledgedOrdersOnly;
                          setTabFilter({ ...tabsFilter, marketplace_status: getMarketplaceStatus(newToggleValue) });
                          setUnacknowledgedOrdersOnly(newToggleValue);
                        }}
                        className="custom-control-label cursor-pointer"
                      >
                        Unacknowledged Orders
                      </label>
                    </div>
                  </RenderIf>
                  {hasFullAccess && aoCounts(props) && (
                    <>
                      {props.Settings?.account?.data?.ao_enabled === true ? (
                        <>
                          <h6 className="m-2 px-2">
                            Processing: {aoCounts(props).checking + aoCounts(props).processing}
                          </h6>
                          <span id="aoQueue">
                            <h6 className="px-2 mb-0">
                              AO Queue: {aoCounts(props).delayed + +aoCounts(props).in_queue}
                            </h6>
                            <Tooltip
                              placement="right"
                              isOpen={aoTooltipOpen}
                              target="aoQueue"
                              toggle={toggleAOTooltip}
                              id="tooltip-top"
                            >
                              There are two kind of orders in this queue
                              <br />
                              1: Waiting for try
                              <br />
                              2: Delay to next try
                            </Tooltip>
                          </span>
                          <h6 className="px-2 mb-0 d-flex align-items-center">
                            AO Service:
                            <i
                              id="aoBotStatus"
                              className="bx bx-sm bxs-circle pl-2"
                              style={{ color: "#" + aoStatusColor(props) }}
                            ></i>
                            <Tooltip placement="right" isOpen={tooltipOpen} target="aoBotStatus" toggle={toggleTooltip}>
                              {props.Settings?.aoStatusNote}
                            </Tooltip>
                          </h6>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
                {tableTabs[activeTab].title === "Issue" && (
                  <FormGroup className="select2-container ml-2" style={{ width: "15vw" }}>
                    <Select
                      name="issue_reason"
                      value={{
                        value: queryParameters.issue_reason || issueReason.value,
                        label: queryParameters.issue_reason || issueReason.label,
                      }}
                      onChange={(e) => {
                        setTabFilter({
                          ...tabsFilter,
                          issue_reason: e.value,
                          page: 1,
                        });
                        queryParameters.issue_reason = e.value;
                        props.history.push(
                          `/source_orders/${tableTabs[activeTab].url}?${Object.keys(queryParameters)
                            .map((key) => `${key}=${queryParameters[key]}`)
                            .join("&")}`,
                        );
                        setIssueReason({ value: e.value, label: e.label });
                      }}
                      options={sortedReasons(props)?.reduce(
                        (init, item, i) => {
                          init.push({
                            label: item?.issue_reason,
                            value: item?.issue_reason,
                            key: i,
                          });
                          return init;
                        },
                        [{ label: "All Issues", value: "" }],
                      )}
                      components={{ Option: IssueMenuOption }}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                )}
                {tableTabs[activeTab].title === "Errored" && (
                  <FormGroup className="select2-container ml-2" style={{ width: "15vw" }}>
                    <Select
                      name="error_prefix"
                      value={{
                        value: queryParameters.error_prefix || errorPrefix.value,
                        label: queryParameters.error_prefix || errorPrefix.label,
                      }}
                      onChange={(e) => {
                        setTabFilter({
                          ...tabsFilter,
                          error_prefix: e.value,
                          page: 1,
                        });
                        queryParameters.error_prefix = e.value;
                        props.history.push(
                          `/source_orders/${tableTabs[activeTab].url}?${Object.keys(queryParameters)
                            .map((key) => `${key}=${queryParameters[key]}`)
                            .join("&")}`,
                        );
                        setErrorPrefix({ value: e.value, label: e.label });
                      }}
                      options={props.SourceOrders.sourceOrders?.metadata?.distinctErrorKeys?.reduce(
                        (init, error, i) => {
                          init.push({
                            label: error,
                            value: error,
                            key: i,
                          });
                          return init;
                        },
                        [{ label: "All Errors", value: "" }],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                )}
              </div>
            </CardHeader>

            <RenderIf isTrue={props.SourceOrders.sourceOrders}>
              <CardBody>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {tableTabs.map(
                          (tab, index) =>
                            tab.show !== false && (
                              <NavItem key={`tab-${index}`} className={activeTab !== 0 && index === 0 ? "d-none" : ""}>
                                {/* <Link > */}
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab === index,
                                  })}
                                  onClick={() => {
                                    const isWhTab = ["wh_delivered", "wh_shipped"].includes(tab.url);
                                    props.setSelectedOrders([]);
                                    toggleCustomJustified(index);
                                    setSearch("");
                                    let newChannel = fulfillmentChannel;
                                    if (isWhTab && fulfillmentChannel.value !== channels.warhouse.value)
                                      newChannel = channels.warhouse;
                                    if (!isWhTab && fulfillmentChannel.value === channels.warhouse.value)
                                      newChannel = channels.all;
                                    setFulfillmentChannel(newChannel);

                                    setTabFilter({
                                      ...tab.param,
                                      page: 1,
                                      per_page: 100,
                                      sort_by: "order_date",
                                      sort_direction: "desc",
                                      store_channel: newChannel.value,
                                      ...pick(
                                        tabsFilter,
                                        "marketplace_account_id",
                                        "start_date",
                                        "end_date",
                                        "utc_offset",
                                        "marketplace_status",
                                      ),
                                    });
                                    resetFilter();
                                    props.history.push("/source_orders/" + tab.url);
                                  }}
                                >
                                  <span className="d-none d-sm-block">{tab.title}</span>
                                </NavLink>
                                {/* </Link> */}
                              </NavItem>
                            ),
                        )}
                      </Nav>
                    </CardBody>
                  </Card>
                </Col>

                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <RenderIf isTrue={hasFullAccess && fulfillmentChannel?.value === "MFN"}>
                          <th>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={
                                  SourceOrderIds?.length > 0 &&
                                  SourceOrderIds?.length === props.SourceOrders?.selectedOrders?.length
                                }
                                onChange={() => false}
                              />
                              <label
                                className="custom-control-label"
                                onClick={(e) => {
                                  if (SourceOrderIds?.length === props.SourceOrders?.selectedOrders?.length) {
                                    props.setSelectedOrders([]);
                                  } else {
                                    props.setSelectedOrders(SourceOrderIds?.map((item) => item));
                                  }
                                }}
                              >
                                &nbsp;
                              </label>
                            </div>
                          </th>
                        </RenderIf>
                        {/* table headers */}
                        {tableHeaders
                          .filter((header) => !header.isHidden)
                          .map(
                            (header, index) =>
                              columns[header.title] &&
                              (header.show === false ? null : (
                                <th key={`table-header ${index}`} onClick={() => header.onClick()}>
                                  {header.title}
                                  <RenderIf
                                    isTrue={["Order Date", "Expected Ship Date", "Last Checked"].includes(header.title)}
                                  >
                                    <div className="d-inline">
                                      <i id={`${header.col}-up`} className="dripicons-arrow-thin-up cursor-pointer" />
                                      <i
                                        id={`${header.col}-down`}
                                        className="dripicons-arrow-thin-down cursor-pointer"
                                      />
                                    </div>
                                  </RenderIf>
                                  <RenderIf isTrue={["Profit", "Estimated Profit"].includes(header.title)}>
                                    <div id="sortProfitTooltip" className="d-inline">
                                      {[
                                        { order: "asc", dir: "up", hide: "desc" },
                                        { order: "desc", dir: "down", hide: "asc" },
                                      ].map(({ order, dir, hide }, index) => (
                                        <i
                                          key={`sort-profit-icon-${index}`}
                                          id={`sort-profit-${order}`}
                                          className={`dripicons-arrow-thin-${dir} cursor-pointer`}
                                          style={{
                                            visibility: orderByParams.order_profit === hide ? "hidden" : "visible",
                                          }}
                                        />
                                      ))}
                                    </div>
                                    <UncontrolledTooltip placement="right" target="sortProfitTooltip">
                                      Profit sorting applies only to visible records on this page, not all records
                                    </UncontrolledTooltip>
                                  </RenderIf>
                                </th>
                              )),
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {/* iterating data */}
                      {props.SourceOrders.sourceOrders &&
                        !props.SourceOrders.loading &&
                        orderBy(
                          props.SourceOrders.sourceOrders.data,
                          Object.keys(orderByParams),
                          Object.values(orderByParams),
                        ).map((item, key) => (
                          <tr key={"source_order" + key} className="mt-3 mb-3">
                            <RenderIf isTrue={hasFullAccess && fulfillmentChannel?.value === "MFN"}>
                              <td>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    checked={props.SourceOrders?.selectedOrders?.includes(item.id)}
                                    onChange={() => false}
                                  />
                                  <label
                                    className="custom-control-label mt-2"
                                    onClick={() => {
                                      if (props.SourceOrders?.selectedOrders?.includes(item.id)) {
                                        props.setSelectedOrders(
                                          props.SourceOrders?.selectedOrders?.filter((id) => item.id !== id),
                                        );
                                      } else {
                                        props.setSelectedOrders([...props.SourceOrders.selectedOrders, item.id]);
                                      }
                                    }}
                                  ></label>
                                </div>
                              </td>
                            </RenderIf>
                            {columns.Platform && (
                              <td>
                                <div className="d-flex flex-column align-items-center">
                                  <div className="d-flex flex-row justify-content-between">
                                    {item?.store_name && (
                                      <img
                                        title={item.store_name}
                                        src={getBrandImageSrc(item.store_name)}
                                        alt={item.store_name}
                                      />
                                    )}
                                    {item?.issue_reason && (
                                      <i
                                        title={item.issue_reason + ": " + parseIssueNote(item)}
                                        className="bx bx-md bx-info-circle text-danger cursor-pointer"
                                      ></i>
                                    )}
                                  </div>
                                  <p>
                                    {props.SourceOrders?.sourceOrders &&
                                      props.SourceOrders?.sourceOrders?.accounts?.find(
                                        (x) => x.id === item.marketplace_account_id,
                                      )?.name}
                                  </p>
                                </div>
                              </td>
                            )}
                            {columns["Order Id"] && (
                              <td>
                                <Link
                                  to={`/source_orders_details/${item.marketplace_order_id}/${item.id}`}
                                  className="text-primary mb-1"
                                >
                                  {item.marketplace_order_id}
                                  {item.marketplace_customer_order_id ? " / " + item.marketplace_customer_order_id : ""}
                                </Link>
                                <CopyToClipBoard text={item.marketplace_order_id} />
                                <br />
                                <Badge className={"mt-1 font-size-14 badge-soft-success"} color="success" pill>
                                  {getFulfillmentChannel(item)}
                                </Badge>
                              </td>
                            )}
                            {columns["Order Date"] && (
                              <td>
                                <span className="text-body mb-1">
                                  {(item.order_date && moment(item.order_date).format(FULL_DATE)) || ""}
                                </span>
                              </td>
                            )}
                            {columns["Expected Ship Date"] && (
                              <td>
                                {(item.required_shipping_date && moment(item.required_shipping_date).format(DATE)) ||
                                  ""}
                              </td>
                            )}
                            {columns["Order Details"] && (
                              <td>
                                {item.source_items &&
                                  itemQty(item).map((sourceItem, key) => (
                                    <div key={`source_item_${key}`} className="mb-2 d-flex flex-row">
                                      <RenderIf
                                        isTrue={hasFullAccess}
                                        fallback={
                                          <div className="mb-1">
                                            <div title={sourceItem.name} className="text-overflow-wrap ">
                                              {sourceItem.name}
                                            </div>
                                            <small>{sourceItem.sku}</small>
                                          </div>
                                        }
                                      >
                                        <Link
                                          to={`/listings/${sourceItem?.sku?.replace(
                                            "%",
                                            String.fromCharCode(1),
                                          )}/suppliers?platform=${item?.store_name}&storeId=${
                                            item?.marketplace_account_id
                                          }`}
                                          target="_blank"
                                          className="text-primary mb-1"
                                        >
                                          <div
                                            title={sourceItem.name}
                                            className="text-primary text-overflow-wrap cursor-pointer"
                                          >
                                            {sourceItem.name}
                                          </div>
                                          <small>{sourceItem.sku}</small>
                                        </Link>
                                      </RenderIf>
                                      <span>QTY:{" " + sourceItem.qty}</span>
                                      <CopyToClipBoard text={sourceItem.sku} />
                                    </div>
                                  ))}
                              </td>
                            )}
                            {columns["Ship Name & Address"] && (
                              <ShipAddress address={item?.source_order_address} buyer_name={item?.buyer_name} />
                            )}
                            {columns["Total Price"] && (
                              <td>{item?.source_items && formatNumber(calculateSale(item, false))}</td>
                            )}
                            {(columns["Estimated Profit"] || columns.Profit) && <ShowProfit order={item} />}
                            {columns["Last Checked"] && tableTabs[activeTab].title === "Errored" && (
                              <td>{(item.last_checked && moment(item.last_checked).format(DATE)) || ""}</td>
                            )}
                            {columns["Verified Match"] && (
                              <td>
                                <div className="d-flex flex-column align-items-center">
                                  {item?.source_items &&
                                    getUniqueSkus(item.source_items).map((sku, key) => (
                                      <div key={key} className="p-1 custom-control custom-switch custom-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={"_ao_toggle_index_" + key}
                                          checked={isAutoOrderable(
                                            sku,
                                            props?.SourceOrders?.sourceOrders?.skuSupplier || false,
                                            item?.marketplace_account_id,
                                          )}
                                          onChange={() => null}
                                        />
                                        <label
                                          onClick={() => {
                                            setVerifiedToggleConfirmation({
                                              platform: item?.store_name,
                                              [item?.store_name === MARKET_PLACES.shopify ? "item_id" : "sku"]: sku,
                                              status: !isAutoOrderable(
                                                sku,
                                                props?.SourceOrders?.sourceOrders?.skuSupplier,
                                                item?.marketplace_account_id,
                                              ),
                                              partner_id: item?.marketplace_account_id,
                                              itemId: item?.id,
                                            });
                                            setIsOpen(true);
                                          }}
                                          className="cursor-pointer custom-control-label"
                                        ></label>
                                      </div>
                                    ))}
                                </div>
                              </td>
                            )}
                            {tableTabs[activeTab].title !== "Manual Fulfillment" ? (
                              <>
                                {columns["Internal Status"] && (
                                  <td id={"internal-status" + key}>
                                    <Status sourceOrder={item} tab={tableTabs[activeTab]} />
                                    {aoAttemptSummary[item.id] ? (
                                      <Tooltip
                                        style={{ maxWidth: "320px" }}
                                        placement="right"
                                        isOpen={internalStatusTooltip["internal-status" + key]}
                                        target={"internal-status" + key}
                                        toggle={() => {
                                          setInternalStatusTooltip({
                                            ["internal-status" + key]: !internalStatusTooltip["internal-status" + key],
                                          });
                                        }}
                                      >
                                        {Object.keys(aoAttemptSummary[item.id]).map((x, tooltipKey) => (
                                          <div key={"tooltip" + tooltipKey}>
                                            {x + ": "}
                                            <strong>{aoAttemptSummary[item.id][x]}</strong>
                                            <br></br>
                                          </div>
                                        ))}
                                      </Tooltip>
                                    ) : null}
                                  </td>
                                )}
                                {columns["Marketplace Status"] && (
                                  <td>
                                    <MarketPlaceStatus sourceOrder={item} tab={tableTabs[activeTab]} />
                                  </td>
                                )}
                              </>
                            ) : (
                              <>{columns["Processed By"] && <td>{item?.order_logs?.slice(-1)?.[0]?.created_by}</td>}</>
                            )}
                            {columns["Supplier Orders"] && tableTabs[activeTab].title !== "New" && (
                              <td>
                                <div className="d-flex flex-column">
                                  {item?.supplier_orders
                                    ?.filter(
                                      (v, i, a) => a.findIndex((t) => t.target_order_id === v.target_order_id) === i,
                                    )
                                    ?.map(
                                      (order, index) =>
                                        order &&
                                        order.status !== "ignored" && (
                                          <div key={`supplier_order_${index}`} className="d-flex flex-column mb-2">
                                            <RenderIf
                                              isTrue={hasFullAccess}
                                              fallback={<p>{order?.purchasing_account_name}</p>}
                                            >
                                              <a
                                                href={`/credentials/${encode(order?.purchasing_account_name)}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {order?.purchasing_account_name}
                                              </a>
                                            </RenderIf>

                                            <span>
                                              {order?.cc_detail?.card_holder_name &&
                                                "CC:" + decode(order?.cc_detail?.card_holder_name)}
                                              -{order?.cc_detail?.last_four}
                                            </span>
                                            <span>{"Id:" + order?.target_order_id}</span>
                                            {order?.tracking_num &&
                                              ["Shipped With TBA", "Shipped", "Delivered With TBA"].some(
                                                (x) => tableTabs[activeTab].title === x,
                                              ) && (
                                                <span>
                                                  Track:{" "}
                                                  {order?.tracking_url ? (
                                                    <a
                                                      href={order.tracking_url}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {order?.tracking_num}
                                                    </a>
                                                  ) : (
                                                    order?.tracking_num
                                                  )}
                                                </span>
                                            )}
                                          </div>
                                        ),
                                    )}
                                </div>
                              </td>
                            )}
                            {columns.Suppliers && (
                              <td>
                                <SupplierSKU item={item} skuSupplier={props?.SourceOrders?.sourceOrders?.skuSupplier} />
                              </td>
                            )}
                            {columns.Action && (
                              <td>
                                <MarkAsIssue
                                  issue={item.issue_reason}
                                  data={{
                                    title: item.issue_reason,
                                    note: item.issue_note,
                                  }}
                                  refresh={refreshSourceOrders}
                                  sourceOrderId={item.id}
                                />
                                <i
                                  className={"bx bx-sm bx-note pl-2 text-primary cursor-pointer"}
                                  onClick={() => {
                                    setOrderItem(item);
                                    props.setOrderLogModal(true);
                                  }}
                                ></i>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {!props.SourceOrders.loading &&
                    !props.SourceOrders.sourceOrders?.data?.length &&
                    (tableTabs[activeTab].title !== "New" ? (
                      <h2 className="mt-3 text-center">No Records Found</h2>
                    ) : (
                      <h2 className="mt-3 text-center">All of your orders are processed! Well done!</h2>
                    ))}
                </div>
                {props.SourceOrders.sourceOrders && (
                  <CustomPagination
                    total={props.SourceOrders.sourceOrders.total}
                    page={props.SourceOrders.sourceOrders.page}
                    perPage={props.SourceOrders.sourceOrders.perPage}
                    tabsFilter={tabsFilter}
                    setTabFilter={setTabFilter}
                  />
                )}
              </CardBody>
            </RenderIf>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { SourceOrders, Supplier, Platform, Settings, MarketPlaceAccount, WareHouses } = state;
  return { SourceOrders, Supplier, Platform, Settings, MarketPlaceAccount, WareHouses };
};

export default connect(mapStatetoProps, {
  fetchSourceOrders,
  setPreloader,
  updateAOStatus,
  fetchPlatforms,
  fetchAOBotStatus,
  setSelectedOrders,
  setOrdersBulkUpdateModal,
  setOrderLogModal,
  setExportOrderModal,
  setPaymentReportModal,
  setOrdersImportModal,
  setUploadOrdersModal,
  fetchAllWarehouses,
})(SourceOrders);

function Status ({ tab, sourceOrder }) {
  const parsedStatus = parseInQueueStatus(sourceOrder);
  return (
    <>
      <Badge
        className={"font-size-12 badge-soft-" + STATUS_COLOR_CODE[parsedStatus]}
        color={STATUS_COLOR_CODE[parsedStatus]}
        pill
      >
        {toPascalCase(ORDER_STATUS_MAPPING[parsedStatus] || parsedStatus)}
      </Badge>
      {sourceOrder?.order_attempts && tab.title?.toLowerCase() === SOURCE_ORDER_STATUSES.errored && (
        <div title={last(sourceOrder.order_attempts)?.error_description} className="text-overflow-wrap">
          {last(sourceOrder.order_attempts)?.error_description}
        </div>
      )}
      {sourceOrder?.issue_reason && tab?.title?.toLowerCase() === "issue" && (
        <div title={sourceOrder?.issue_note} className="text-overflow-wrap">
          {sourceOrder?.issue_reason}
        </div>
      )}
    </>
  );
}

function MarketPlaceStatus ({ tab, sourceOrder }) {
  return (
    <>
      <Badge
        className={"font-size-12 badge-soft-" + STATUS_COLOR_CODE[sourceOrder?.marketplace_status]}
        color={STATUS_COLOR_CODE[sourceOrder?.marketplace_status]}
        pill
      >
        {toPascalCase(sourceOrder?.marketplace_status)}
      </Badge>
    </>
  );
}

function itemQty ({ source_items: items }) {
  return Object.values(
    items?.reduce((acc, i) => {
      if (acc[i.sku]) {
        acc[i.sku].qty = acc[i.sku].qty + 1;
      } else {
        acc[i.sku] = { qty: i.qty, sku: i.sku, name: i.name };
      }
      return acc;
    }, {}),
  );
}
