import React from "react";
import * as actions from "../../../store/actions";
import * as $ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Common/Modal";
import PillBtn from "../../../components/Common/PillBtn";
import { localeNum } from "../../../utils/common";

const AssignStrategyConfirmationDialog = ({
  productKey,
  strategyId,
  selectAll,
  strategy = "N/A",
  selectedlistings = 0,
  setSelectAll,
  setCheckBox,
  params = {},
}) => {
  const dispatch = useDispatch();
  const { assignStrategyModalIsOpen, listings } = useSelector((state) => state.Repricer);
  const toggleModal = () => dispatch(actions.setAssignStrategyModal(!assignStrategyModalIsOpen));
  const selectedListingsCount = selectAll ? listings?.count : $.size(selectedlistings);
  const data = {
    key: productKey,
    value: strategyId,
  };

  if (!selectAll) data.product_ids = $.map(selectedlistings, (value) => +value);

  return (
    <Modal style={{ maxWidth: "534px" }} size="md" isOpen={assignStrategyModalIsOpen}>
      <div className="px-4 pb-4">
        <i
          className="bx bx-sm bx-x inventory-color cursor-pointer"
          style={{ position: "absolute", right: "24px", top: "24px" }}
          onClick={toggleModal}
        />
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <i style={{ fontSize: "100px" }} className="text-danger bx bx-error" />
        </div>
        <div className="mt-3 font-size-20 text-center inventory-color font-weight-semibold d-flex justify-content-center align-items-center">
          Do you want to assign strategy on these selected listings?
        </div>
        <div className="mt-4 w-100 font-size-13 font-weight-semibold card-layout rounded p-3">
          <div className="inventory-color mb-3">Details</div>
          <div className="d-flex justify-content-between align-items-center inventory-color mb-3">
            <div>Strategy</div>
            <div>Total Listing{selectedListingsCount > 1 ? "s" : ""}</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center card-text mb-3">
            <div>{strategy}</div>
            <div>{localeNum(selectedListingsCount)}</div>
          </div>
        </div>
      </div>
      <div className="modal-footer p-3 let-modal">
        <PillBtn className="mr-2" title="Cancel" name="Cancel" color="primary" outline={true} onClick={toggleModal} />
        <PillBtn
          className="ml-2"
          title="Confirm"
          name="Confirm"
          color="primary"
          onClick={() => {
            dispatch(actions.assignListingsStrategy(data, params));
            setSelectAll(false);
            setCheckBox({});
            toggleModal();
          }}
        />
      </div>
    </Modal>
  );
};

export default AssignStrategyConfirmationDialog;
