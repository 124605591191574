import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import React, { Suspense, useEffect, useState } from "react";
import { concat, entries, map, merge, omit, pick, startCase, values } from "lodash";
import { Alert, Card, CardHeader, Container, FormGroup } from "reactstrap";

import PillBtn from "../../components/Common/PillBtn";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { DatePicker } from "../../components/Common/DatePickr";

import AddShipmentModal from "./components/AddShipmentModal";
import ReceivedShipmentModal from "./components/ReceivedShipmentModal";

import { RenderIf, getEstDate, parseString } from "../../utils/common";
import {
  INBOUNDS_SEARCH_TYPES,
  ORDERABLE_TYPES,
  SHIPMENT_LINE_STATUSES,
  WH_NOTICES_TYPES,
  TRACKING_STATUS_OPTIONS,
  DATE_FORMATS,
} from "../../constants";

import {
  fetchInbounds,
  setAddShipmentModal,
  setPreloader,
  setReceivedShipmentModel,
  shipmentsCheckedIn,
  fetchUserWarehouses,
} from "../../store/actions";

import SimpleSelect from "../../components/Common/SimpleSelect";
import ShipmentsTable from "./components/ShipmentsTable";
import moment from "moment-timezone";

const InboundNoticeModal = React.lazy(() => import("../WareHouse/Components/AddWarehouseNoteModal"));

const Inbounds = () => {
  const dates = [moment().subtract(3, "months"), moment()];
  const dispatch = useDispatch();
  const filterStatusMapping = {
    [SHIPMENT_LINE_STATUSES.incomplete]: "Awaiting Shipment",
    [SHIPMENT_LINE_STATUSES.complete]: "Moved To Inventory",
  };
  const initialStatus = {
    value: SHIPMENT_LINE_STATUSES.incomplete,
    label: filterStatusMapping?.[SHIPMENT_LINE_STATUSES.incomplete],
  };
  const orderableTypesMapping = {
    tracking_items: "2-Step",
  };
  const [ids, setIds] = useState([]);
  const [search, setSearch] = useState("");
  const [shipment, setShipment] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(initialStatus);
  const [orderableType, setOrderableType] = useState({ value: "tracking_items", label: "2 Step" });
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20,
    status: status.value,
    orderable_type: "tracking_items",
    start_date: dates[0].format(DATE_FORMATS.BACKEND),
    end_date: dates[1].format(DATE_FORMATS.BACKEND),
  });
  const [selectedStatusFilterOption, setSelectedStatusFilterOption] = useState(null);

  const [searchType, setSearchType] = useState({ label: "All", value: INBOUNDS_SEARCH_TYPES.All });
  const {
    inboundsData,
    inboundShipment,
    loading,
    addShipmentModal,
    receivedShipmentModel,
    warehouseNoteModalIsOpen,
    userWarehouses,
    message,
    success,
    error,
  } = useSelector((state) => state.WareHouses);

  const orderableTypeOptions = () =>
    concat(
      [{ value: "", label: "All Orders Type" }],
      values(ORDERABLE_TYPES).map((x) => ({
        value: x,
        label: startCase(orderableTypesMapping?.[x] || x),
      })),
    );

  const handleSubmit = () => {
    setFilters({
      ...filters,
      search,
      search_column: searchType?.value,
      page_number: 1,
      page: 1,
    });
  };

  useEffect(() => {
    dispatch(setReceivedShipmentModel(false));
    dispatch(fetchUserWarehouses());
  }, []);

  useEffect(() => {
    dispatch(fetchInbounds(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    if (shipment) dispatch(setReceivedShipmentModel(true));
  }, [shipment]);

  return (
    <>
      <RenderIf isTrue={addShipmentModal}>
        <AddShipmentModal />
      </RenderIf>
      <RenderIf isTrue={receivedShipmentModel}>
        <ReceivedShipmentModal shipment={shipment} filters={filters} setShipment={setShipment} />
      </RenderIf>
      <RenderIf isTrue={warehouseNoteModalIsOpen}>
        <Suspense fallback={<></>}>
          <InboundNoticeModal
            notice_type={WH_NOTICES_TYPES.shipment_lines}
            notices={inboundShipment?.warehouse_notices || []}
            selectedItem={inboundShipment}
            warehouses={userWarehouses}
          />
        </Suspense>
      </RenderIf>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(shipmentsCheckedIn(ids, filters));
            setIsOpen(false);
            setIds();
          }}
          onCancel={() => {
            setIds();
            setIsOpen(false);
          }}
        ></SweetAlert>
      </RenderIf>
      <div className="page-content purchase-order">
        <RenderIf isTrue={success}>
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {message || success}
            </Alert>
          </div>
        </RenderIf>
        <RenderIf isTrue={error && !addShipmentModal && !receivedShipmentModel}>
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        </RenderIf>
        <Container fluid>
          <Breadcrumb
            title="Inbound Items"
            children={
              <>
                <PillBtn
                  title="Add Shipment"
                  name="Add Shipment"
                  icon="bx bx-plus"
                  color="primary"
                  onClick={() => dispatch(setAddShipmentModal(true))}
                />
              </>
            }
          />
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between flex-wrap">
                <form
                  className="app-search d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (search.trim()) {
                      setFilters({
                        ...pick(filters, "per_page", "status", "tracking_status", "orderable_type"),
                        page: 1,
                        search: search.trim(),
                        search_column: searchType?.value,
                      });
                    }
                  }}
                >
                  <input type="submit" hidden />
                  <div className="d-flex inputs-group-inbounds">
                    <Select
                      name="select_type"
                      value={searchType}
                      style={{ width: "200px" }}
                      className="select2-container mb-0 form-group"
                      onChange={(e) => setSearchType({ label: e.label, value: e.value })}
                      options={map(entries(INBOUNDS_SEARCH_TYPES), ([l, v]) => ({ label: l, value: v }))}
                      classNamePrefix="select2-selection"
                    />
                    <div className="vertical-line2" />
                    <div className="position-relative">
                      <i
                        onClick={handleSubmit}
                        className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                      />
                      <input
                        id="search_field"
                        type="text"
                        className="form-control search-input"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => {
                          if (!e.target.value.trim()) {
                            setSearch("");
                            setFilters({ ...merge(omit(filters, "page", "search"), { page: 1 }) });
                          } else {
                            setSearch(parseString(e.target.value));
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
                <div className="d-flex align-items-center">
                  <FormGroup className="select2-container ml-2" style={{ width: "200px", marginTop: 15 }}>
                    <Select
                      classNamePrefix="select2-selection"
                      name="tracking_status"
                      defaultValue={selectedStatusFilterOption}
                      placeholder="Select Tracking Status"
                      options={TRACKING_STATUS_OPTIONS}
                      formStyle={{ Width: "100%" }}
                      onChange={(e) => {
                        setFilters({
                          ...merge(filters, {
                            page: 1,
                            tracking_status: e.value,
                          }),
                        });
                        setSelectedStatusFilterOption(e);
                      }}
                    />
                  </FormGroup>
                  <SimpleSelect
                    name="status"
                    value={status}
                    onChange={(e) => {
                      setStatus({ value: e.value, label: e.label });
                      setFilters({ ...merge(filters, { status: e.value }), page: 1 });
                    }}
                    options={values(SHIPMENT_LINE_STATUSES).reduce(
                      (acc, x) => {
                        acc.push({ value: x, label: startCase(filterStatusMapping?.[x] || x) });
                        return acc;
                      },
                      [{ value: "", label: "All Statuses" }],
                    )}
                    formStyle={{ minWidth: "250px", marginBottom: 0, marginRight: "0.5rem", marginLeft: "0.5rem" }}
                  />
                  <SimpleSelect
                    name="orderable_type"
                    value={orderableType}
                    onChange={(e) => {
                      setOrderableType(e);
                      setFilters({ ...merge(filters, { orderable_type: e.value }), page: 1 });
                    }}
                    options={orderableTypeOptions()}
                    formStyle={{ minWidth: "250px", marginBottom: 0, marginRight: "0.5rem", marginLeft: "0.5rem" }}
                  />
                  <div className="d-flex align-items-center inbound-date-range">
                    <DatePicker
                      icon
                      style={{ width: "280px", border: "none" }}
                      placeholder="Select Date Range for order placed"
                      className="mx-1 my-2"
                      pickrClass="pl-2"
                      onClose={(dates) => {
                        if (!dates.start_date) setStatus(initialStatus);
                        setFilters({ ...filters, ...dates, page: 1 });
                      }}
                      initialDates={dates.map((x) => getEstDate(x).toDate())}
                    />
                    <i className="bx font-size-16 bx-calendar text-primary mx-3" />
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
          <ShipmentsTable
            inboundsData={inboundsData}
            filters={filters}
            setFilters={setFilters}
            shipment={shipment}
            setShipment={setShipment}
            setIsOpen={setIsOpen}
            setIds={setIds}
            ids={ids}
          />
        </Container>
      </div>
    </>
  );
};

export default Inbounds;
