import React from "react";
import { useDispatch } from "react-redux";
import { orderBy } from "lodash";
import Lazy from "../../../../components/Common/LazyComponent";
import viewNote from "../../../../assets/svg/viewNote.svg";
import { setAddTrackingItemNoteModal } from "../../../../store/actions";
import { getUserInfo, RenderIf } from "../../../../utils/common";
import { USER_ROLES } from "../../../../constants";

const ViewNotesIcon = ({ item, notices, setSelected, className = "" }) => {
  const isWH = getUserInfo()?.role === USER_ROLES.whStaff;
  const dispatch = useDispatch();
  return (
    <RenderIf isTrue={isWH || notices?.length > 0}>
      <img
        id={"notes_" + item.id}
        className={`icons-sizes cursor-pointer ${className}`}
        title={notices?.length > 0 ? "" : `${isWH ? "Add " : ""} or View Notes`}
        src={viewNote}
        alt="Add/View Note"
        onClick={() => {
          dispatch(setSelected(item));
          dispatch(setAddTrackingItemNoteModal(true));
        }}
      />

      <RenderIf isTrue={notices?.length}>
        {notices?.length > 0 && (
          <Lazy
            path="./CustomTooltip"
            target={"notes_" + item.id}
            placement="right"
            content={orderBy(notices, "created_at", "desc")
              .slice(0, 3)
              ?.map((item, index) => (
                <React.Fragment key={"_notice_no_" + (index + 1)}>
                  {index + 1 + ": " + item?.note}
                  <br />
                </React.Fragment>
              ))}
          />
        )}
      </RenderIf>
    </RenderIf>
  );
};

export default ViewNotesIcon;
