import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

// Import Images
import error from "../assets/images/error-img.webp";

const Page500 = () => (
  <React.Fragment>
    <div className="account-pages my-5 pt-5">
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium">
                5<i className="bx bx-buoy bx-spin text-primary display-3"></i>0
              </h1>
              <h4 className="text-uppercase">Internal Server Error</h4>
              <div className="mt-5 text-center">
                <Button
                  className="waves-effect waves-light"
                  onClick={(_) => (window.location.href = "/")}
                  color="primary"
                >
                  Back to Dashboard
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
);

export default Page500;
