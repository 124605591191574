import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from "reactstrap";
import Select, { components } from "react-select";
import { setSalesRank } from "../../../store/actions";
import { salesRankOptions } from "../common";

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>Sales Rank: {children}</components.SingleValue>
);

const SelectSalesRank = () => {
  const { salesRank } = useSelector((state) => state.ProfitAnalyzer);
  const dispatch = useDispatch();

  return (
    <FormGroup className={"select2-container mt-3 text-[#C3CBE4]"} style={{ width: "195px" }}>
      <Select
        name="salesRank"
        value={salesRank}
        options={salesRankOptions}
        onChange={(e) => {
          const { value, label, name } = e;
          dispatch(setSalesRank({ value, label, name }));
        }}
        classNamePrefix="select2-selection"
        components={{ SingleValue }}
      />
    </FormGroup>
  );
};

export default SelectSalesRank;
