import { toF } from "./common";

// Google Tag Manager Helpers
const APP_NAME = "Ecom Circles";

const APP_NAME_MAPPING = { extension: "Ecom Extension", wfs_calculator: "WFS Calculator" };

const addEvent = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

const addEventByType = type => addEvent({ event: type, item_name: APP_NAME });

export const register = () => addEventByType("registration");
export const verified = () => addEventByType("verified");
export const extensionInstalled = (extName) =>
  addEvent({ event: "extension_installed", extension_name: APP_NAME_MAPPING[extName] });
export const purchase = ({ id: trxId, plan: { id: itemId, amount } } = {}) =>
  addEvent({
    event: "subscribed",
    ecommerce: {
      currency: "USD",
      value: toF(amount / 100, 2, true),
      transaction_id: trxId,
      items: [{ item_name: APP_NAME, item_id: itemId }],
    },
  });
