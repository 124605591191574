import { COMPARISION_DROPDOWN_OPTIONS, COMPARISON_DROPDOWN, checkFilterValue } from "./common";
import { FormGroup, Input, Label } from "reactstrap";
import { RenderIf } from "../../../utils/common";
import FilterCard from "./FilterCard";
import FilterChip from "./FilterChip";
import FilterContext from "./FilterContext";
import PillBtn from "../../../components/Common/PillBtn";
import React, { useContext } from "react";
import SimpleSelect from "../../../components/Common/SimpleSelect";

export default function ComparisionDropDown ({ filterKey, filterTitle }) {
  const { localFilters, setFilter, removeFilter, filterOperators, setOperator, addedFilters, addFilter } =
    useContext(FilterContext);
  const filterOperator = filterOperators[filterKey];
  const filterValue = localFilters[filterKey];
  const canAddFilter = checkFilterValue(filterValue);
  const isFilterAdded = addedFilters[filterKey];
  return (
    <FilterCard filterKey={filterKey} filterTitle={filterTitle}>
      <RenderIf isTrue={isFilterAdded}>
        <FilterChip
          filterTitle={filterTitle}
          filterValueLabel={filterValue}
          operator={filterOperator}
          removeFilter={() => removeFilter(filterKey)}
        />
      </RenderIf>
      <div className="w-100 d-flex justify-content-start align-items-end gap-3">
        <div style={{ flex: 1 }}>
          <SimpleSelect
            disabled={isFilterAdded}
            label="Select Value to Apply Filter"
            className={`mb-0 ${isFilterAdded ? "cursor-not-allowed" : ""}`}
            options={COMPARISION_DROPDOWN_OPTIONS}
            value={{
              label: COMPARISON_DROPDOWN[filterOperator] ?? "Select Value",
              value: filterOperator,
            }}
            onChange={(e) => setOperator(filterKey, e.value)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <FormGroup className={`select2-container mb-0 ${isFilterAdded ? "cursor-not-allowed" : ""}`}>
            <Label>Value</Label>
            <Input
              disabled={isFilterAdded}
              type="number"
              className={`form-control ${isFilterAdded ? "cursor-not-allowed" : ""}`}
              step="0.01"
              min="0"
              value={filterValue}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                setFilter(filterKey, isNaN(value) ? "" : value);
              }}
              onKeyPress={(e) => ["-", "e", "E"].includes(e.key) && e.preventDefault()}
            />
          </FormGroup>
        </div>
        <PillBtn
          disabled={!canAddFilter || isFilterAdded}
          className={`${!canAddFilter || isFilterAdded ? "cursor-not-allowed" : ""}`}
          color="primary"
          name="Add"
          onClick={() => addFilter(filterKey)}
        />
      </div>
    </FilterCard>
  );
}
