import request from "../restClient";
import { makeQP } from "../../utils/common";

const resource = "/returns";
export const getAllReturns = (params = {}) => request({ url: resource + makeQP(params), method: "GET" });
export const updateReturn = ({ id, data }) => request({ url: `${resource}/${id}`, method: "PUT", body: data });
export const updateBulkReturns = ({ ids, data }) =>
  request({ url: `${resource}/bulk_update`, method: "PUT", body: { ids, data } });
export const moveToInventory = ({ id, data }) =>
  request({ url: `${resource}/${id}/move_to_inventory`, method: "POST", body: data });
export const uploadImages = async ({ data, id }) => {
  const formData = new FormData();
  data.forEach((x) => Object.keys(x).forEach((key) => formData.append(key, x[key])));
  return await request({ url: `${resource}/upload_images/${id}`, method: "POST", formData });
};
export const downloadImages = ({ id, params }) =>
  request({ url: `${resource}/download_image/${id}` + makeQP(params), method: "GET", download: true });
export const deleteImage = ({ id, params }) =>
  request({ url: `${resource}/delete_image/${id}` + makeQP(params), method: "DELETE" });
export const getImagesUrl = ({ id }) => request({ url: `${resource}/get_images/${id}`, method: "GET" });
export const addItems = (body) => request({ url: "/tracking_items/create_inventory", method: "POST", body });
