import {
  FETCH_ALL_GRAPHS,
  FETCH_ALL_GRAPHS_SUCCESS,
  FETCH_ALL_TENANT_GRAPHS,
  FETCH_ALL_TENANT_GRAPHS_SUCCESS,
  FETCH_ORDER_GRAPH_DETAILS,
  FETCH_ORDER_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_ORDER_GRAPH,
  FETCH_ALL_TENANT_ORDER_GRAPH_SUCCESS,
  FETCH_SALES_GRAPH_DETAILS,
  FETCH_SALES_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_SALES_GRAPH,
  FETCH_ALL_TENANT_SALES_GRAPH_SUCCESS,
  FETCH_PROFIT_GRAPH_DETAILS,
  FETCH_PROFIT_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_PROFIT_GRAPH,
  FETCH_ALL_TENANT_PROFIT_GRAPH_SUCCESS,
  FETCH_SHARED_PROFIT_GRAPH_DETAILS,
  FETCH_SHARED_PROFIT_GRAPH_DETAILS_SUCCESS,
  FETCH_DAILY_REPORT,
  FETCH_DAILY_REPORT_SUCCESS,
  FETCH_SALES_REVENUE_STATS,
  FETCH_SALES_REVENUE_STATS_SUCCESS,
  FETCH_SHARED_ORDER_STATUS,
  FETCH_SHARED_ORDER_STATUS_SUCCESS,
  FETCH_EXPENSES_GRAPH_DETAILS,
  FETCH_EXPENSES_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_EXPENSE_GRAPH,
  FETCH_ALL_TENANT_EXPENSE_GRAPH_SUCCESS,
  FETCH_RETURNS_GRAPH_DETAILS,
  FETCH_RETURNS_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_RETURNS_GRAPH,
  FETCH_ALL_TENANT_RETURNS_GRAPH_SUCCESS,
  FETCH_USED_CREDITS_DETAILS,
  FETCH_USED_CREDITS_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_USED_CREDITS_DETAILS,
  FETCH_ALL_TENANT_USED_CREDITS_DETAILS_SUCCESS,
  FETCH_ISSUE_DETAILS,
  FETCH_ISSUE_DETAILS_SUCCESS,
  FETCH_LISTING_DETAILS,
  FETCH_LISTING_DETAILS_SUCCESS,
  FETCH_BUYBOX_DETAILS,
  FETCH_BUYBOX_DETAILS_SUCCESS,
  FETCH_ERROR_STATS,
  FETCH_ERROR_STATS_SUCCESS,
  FETCH_TOP_SELLING_ITEMS,
  FETCH_TOP_SELLING_ITEMS_SUCCESS,
  FETCH_GLOBAL_TOP_SELLING_ITEMS,
  FETCH_GLOBAL_TOP_SELLING_ITEMS_SUCCESS,
  API_ERROR,
  FETCH_LEAST_SELLING_ITEMS,
  FETCH_LEAST_SELLING_ITEMS_SUCCESS,
  FETCH_CLIENTS_STATS,
  FETCH_CLIENTS_STATS_SUCCESS,
  FETCH_FEES_DETAILS,
  FETCH_FEES_DETAILS_SUCCESS,
  FETCH_BEST_SELLERS_DATA_SUCCESS,
  FETCH_BEST_SELLERS_DATA,
  FETCH_PRODUCTS_BY_SALES_SUCCESS,
  FETCH_PRODUCTS_BY_SALES,
  FETCH_PRODUCTS_BY_CATEGORY,
  FETCH_PRODUCTS_BY_CATEGORY_SUCCESS,
} from "./actionTypes";

export const fetchAllGraphs = (params) => {
  return {
    type: FETCH_ALL_GRAPHS,
    payload: { params },
  };
};

export const fetchAllGraphsSuccess = (data) => {
  return {
    type: FETCH_ALL_GRAPHS_SUCCESS,
    payload: data,
  };
};

export const fetchProductsbySales = (params) => ({ type: FETCH_PRODUCTS_BY_SALES, payload: params });
export const fetchProductsbySalesSuccess = (data) => ({ type: FETCH_PRODUCTS_BY_SALES_SUCCESS, payload: data });

export const fetchProductsbyCategory = (params) => ({ type: FETCH_PRODUCTS_BY_CATEGORY, payload: params });
export const fetchProductsbyCategorySuccess = (data) => ({
  type: FETCH_PRODUCTS_BY_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchFeesDetails = (params) => ({ type: FETCH_FEES_DETAILS, payload: params });
export const fetchFeesDetailsSuccess = (data) => ({ type: FETCH_FEES_DETAILS_SUCCESS, payload: data });

export const fetchAllTenantGraphs = (params) => {
  return {
    type: FETCH_ALL_TENANT_GRAPHS,
    payload: { params },
  };
};

export const fetchAllTenantGraphsSuccess = (data) => {
  return {
    type: FETCH_ALL_TENANT_GRAPHS_SUCCESS,
    payload: data,
  };
};

export const fetchOrderGraph = (params) => {
  return {
    type: FETCH_ORDER_GRAPH_DETAILS,
    payload: { params },
  };
};

export const fetchOrderGraphSuccess = (data) => {
  return {
    type: FETCH_ORDER_GRAPH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchClientStats = (params) => {
  return {
    type: FETCH_CLIENTS_STATS,
    payload: { params },
  };
};

export const fetchClientStatsSuccess = (data) => {
  return {
    type: FETCH_CLIENTS_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchBestSellersData = (params) => ({ type: FETCH_BEST_SELLERS_DATA, payload: params });
export const fetchBestSellersDataSuccess = (data) => ({ type: FETCH_BEST_SELLERS_DATA_SUCCESS, payload: data });

export const fetchAllTenantOrderGraph = (params) => {
  return {
    type: FETCH_ALL_TENANT_ORDER_GRAPH,
    payload: { params },
  };
};

export const fetchAllTenantOrderGraphSuccess = (data) => {
  return {
    type: FETCH_ALL_TENANT_ORDER_GRAPH_SUCCESS,
    payload: data,
  };
};

export const fetchSalesGraph = (params) => {
  return {
    type: FETCH_SALES_GRAPH_DETAILS,
    payload: { params },
  };
};

export const fetchSalesGraphSuccess = (data) => {
  return {
    type: FETCH_SALES_GRAPH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchAllTenantSalesGraph = (params) => {
  return {
    type: FETCH_ALL_TENANT_SALES_GRAPH,
    payload: { params },
  };
};

export const fetchAllTenantSalesGraphSuccess = (data) => {
  return {
    type: FETCH_ALL_TENANT_SALES_GRAPH_SUCCESS,
    payload: data,
  };
};

export const fetchExpensesGraph = (params) => {
  return {
    type: FETCH_EXPENSES_GRAPH_DETAILS,
    payload: { params },
  };
};

export const fetchExpensesGraphSuccess = (data) => {
  return {
    type: FETCH_EXPENSES_GRAPH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchAllTenantExpensesGraph = (params) => {
  return {
    type: FETCH_ALL_TENANT_EXPENSE_GRAPH,
    payload: { params },
  };
};

export const fetchAllTenantExpensesGraphSuccess = (data) => {
  return {
    type: FETCH_ALL_TENANT_EXPENSE_GRAPH_SUCCESS,
    payload: data,
  };
};

export const fetchProfitGraph = (params) => {
  return {
    type: FETCH_PROFIT_GRAPH_DETAILS,
    payload: { params },
  };
};

export const fetchProfitGraphSuccess = (data) => {
  return {
    type: FETCH_PROFIT_GRAPH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchAllTenantProfitGraph = (params) => {
  return {
    type: FETCH_ALL_TENANT_PROFIT_GRAPH,
    payload: { params },
  };
};

export const fetchAllTenantProfitGraphSuccess = (data) => {
  return {
    type: FETCH_ALL_TENANT_PROFIT_GRAPH_SUCCESS,
    payload: data,
  };
};

export const fetchSharedProfitGraph = (params) => {
  return {
    type: FETCH_SHARED_PROFIT_GRAPH_DETAILS,
    payload: { params },
  };
};

export const fetchSharedProfitGraphSuccess = (data) => {
  return {
    type: FETCH_SHARED_PROFIT_GRAPH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchDailyReport = (params) => {
  return {
    type: FETCH_DAILY_REPORT,
    payload: { params },
  };
};

export const fetchDailyReportSuccess = (data) => {
  return {
    type: FETCH_DAILY_REPORT_SUCCESS,
    payload: data,
  };
};

export const fetchSalesRevenueStats = (params) => ({
  type: FETCH_SALES_REVENUE_STATS,
  payload: { params },
});

export const fetchSalesRevenueStatsSuccess = (data) => ({
  type: FETCH_SALES_REVENUE_STATS_SUCCESS,
  payload: data,
});

export const fetchSharedOrderStatus = (params) => {
  return {
    type: FETCH_SHARED_ORDER_STATUS,
    payload: { params },
  };
};

export const fetchSharedOrderStatusSuccess = (data) => {
  return {
    type: FETCH_SHARED_ORDER_STATUS_SUCCESS,
    payload: data,
  };
};

export const fetchReturnsGraph = (params) => {
  return {
    type: FETCH_RETURNS_GRAPH_DETAILS,
    payload: { params },
  };
};

export const fetchReturnsGraphSuccess = (data) => {
  return {
    type: FETCH_RETURNS_GRAPH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchAllTenantReturnsGraph = (params) => {
  return {
    type: FETCH_ALL_TENANT_RETURNS_GRAPH,
    payload: { params },
  };
};

export const fetchAllTenantReturnsGraphSuccess = (data) => {
  return {
    type: FETCH_ALL_TENANT_RETURNS_GRAPH_SUCCESS,
    payload: data,
  };
};

export const fetchUsedCreditsGraph = (params) => {
  return {
    type: FETCH_USED_CREDITS_DETAILS,
    payload: { params },
  };
};

export const fetchUsedCreditsGraphSuccess = (data) => {
  return {
    type: FETCH_USED_CREDITS_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchAllTenantUsedCreditsGraph = (params) => {
  return {
    type: FETCH_ALL_TENANT_USED_CREDITS_DETAILS,
    payload: { params },
  };
};

export const fetchAllTenantUsedCreditsGraphSuccess = (data) => {
  return {
    type: FETCH_ALL_TENANT_USED_CREDITS_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchIssueDetails = (params) => {
  return {
    type: FETCH_ISSUE_DETAILS,
    payload: { params },
  };
};

export const fetchIssueDetailsSuccess = (data) => {
  return {
    type: FETCH_ISSUE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchListingStats = (params) => {
  return {
    type: FETCH_LISTING_DETAILS,
    payload: { params },
  };
};

export const fetchListingStatsSuccess = (data) => {
  return {
    type: FETCH_LISTING_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchBuyBoxStats = (params) => {
  return {
    type: FETCH_BUYBOX_DETAILS,
    payload: { params },
  };
};

export const fetchBuyBoxStatsSuccess = (data) => {
  return {
    type: FETCH_BUYBOX_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchErrorStats = (params) => {
  return {
    type: FETCH_ERROR_STATS,
    payload: { params },
  };
};

export const fetchErrorStatsSuccess = (data) => {
  return {
    type: FETCH_ERROR_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchTopSellingItems = (params = {}) => {
  return {
    type: FETCH_TOP_SELLING_ITEMS,
    payload: { params },
  };
};

export const fetchTopSellingItemsSuccess = (data) => {
  return {
    type: FETCH_TOP_SELLING_ITEMS_SUCCESS,
    payload: data,
  };
};

export const fetchGlobalTopSellingItems = (params = {}) => {
  return {
    type: FETCH_GLOBAL_TOP_SELLING_ITEMS,
    payload: { params },
  };
};

export const fetchGlobalTopSellingItemsSuccess = (data) => {
  return {
    type: FETCH_GLOBAL_TOP_SELLING_ITEMS_SUCCESS,
    payload: data,
  };
};

export const fetchLeastSellingItems = (params = {}) => {
  return {
    type: FETCH_LEAST_SELLING_ITEMS,
    payload: { params },
  };
};

export const fetchLeastSellingItemsSuccess = (data) => {
  return {
    type: FETCH_LEAST_SELLING_ITEMS_SUCCESS,
    payload: data,
  };
};
export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
