export const UPDATE_ACCOUNT_STATE = " UPDATE_ACCOUNT_STATE";
export const FETCH_ACCOUNT_SETTINGS = "FETCH_ACCOUNT_SETTINGS";
export const FETCH_ACCOUNT_SETTINGS_SUCCESS = "FETCH_ACCOUNT_SETTINGS_SUCCESS";
export const FETCH_ALL_ACCOUNT_SETTINGS_SUCCESS = "FETCH_ALL_ACCOUNT_SETTINGS_SUCCESS";
export const FETCH_ABILITIES = "FETCH_ABILITIES";
export const FETCH_ABILITIES_SUCCESS = "FETCH_ABILITIES_SUCCESS";
export const FETCH_PROXIES = "FETCH_PROXIES";
export const FETCH_PROXIES_SUCCESS = "FETCH_PROXIES_SUCCESS";
export const FETCH_AVAILABLE_MATCH_COUNTS = "FETCH_AVAILABLE_MATCH_COUNTS";
export const FETCH_AVAILABLE_MATCH_COUNTS_SUCCESS = "FETCH_AVAILABLE_MATCH_COUNTS_SUCCESS";
export const SUBMIT_MATCHES_REQUEST = "SUBMIT_MATCHES_REQUEST";
export const SUBMIT_MATCHES_REQUEST_SUCCESS = "SUBMIT_MATCHES_REQUEST_SUCCESS";
export const UPDATE_ACCOUNT_SETTINGS = "UPDATE_ACCOUNT_SETTINGS";
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = "UPDATE_ACCOUNT_SETTINGS_SUCCESS";
export const API_ERROR = "SETTINGS_API_ERROR";
export const FETCH_AGENCIES_SUCCESS = "FETCH_AGENCIES_SUCCESS";
export const SET_ADD_AGENCY_MODAL = "SET_ADD_AGENCY_MODAL";
export const SET_ADD_LABEL_GENERATION_SERVICE = "SET_ADD_LABEL_GENERATION_SERVICE";
export const ADD_STAMP_CREDENTIALS = "ADD_STAMP_CREDENTIALS";
export const ADD_STAMP_CREDENTIALS_SUCCESS = "ADD_STAMP_CREDENTIALS_SUCCESS";
export const FETCH_STAMPS_CREDS = "FETCH_STAMPS_CREDS";
export const FETCH_STAMPS_CREDS_SUCCESS = "FETCH_STAMPS_CREDS_SUCCESS";
export const UPDATE_STAMPS_CREDS = "UPDATE_STAMPS_CREDS";
export const DELETE_STAMPS_CREDS = "DELETE_STAMPS_CREDS";
export const DELETE_STAMPS_CREDS_SUCCESS = "DELETE_STAMPS_CREDS_SUCCESS";
export const ENABLE_STAMPS_CREDS = "ENABLE_STAMPS_CREDS";
export const ENABLE_STAMPS_CREDS_SUCCESS = "ENABLE_STAMPS_CREDS_SUCCESS";
export const ADD_AGENCY_CHILD_ACCOUNT = "ADD_AGENCY_CHILD_ACCOUNT";
export const ADD_AGENCY_CHILD_ACCOUNT_SUCCESS = "ADD_AGENCY_CHILD_ACCOUNT_SUCCESS";
export const INVITE_TO_AGENCY = "INVITE_TO_AGENCY";
export const INVITE_TO_AGENCY_SUCCESS = "INVITE_TO_AGENCY_SUCCESS";
export const SET_VERIFY_ERROR = "SET_VERIFY_ERROR";
export const VERIFY_AGENCY_INVITE = "VERIFY_AGENCY_INVITE";
export const VERIFY_AGENCY_INVITE_SUCCESS = "VERIFY_AGENCY_INVITE_SUCCESS";
export const UNLINK_AGENCY = "UNLINK_AGENCY";
export const UNLINK_AGENCY_SUCCESS = "UNLINK_AGENCY_SUCCESS";
export const FETCH_TOKEN = "FETCH_TOKEN";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const SET_SELECTED_TENAT_ACC = "SET_SELECTED_TENAT_ACC";
export const SET_CANCEL_FEEDBACK_MODAL = "SET_CANCEL_FEEDBACK_MODAL";
export const SUBMIT_CANCEL_FEEDBACK = "SUBMIT_CANCEL_FEEDBACK";
export const SUBMIT_CANCEL_FEEDBACK_SUCCESS = "SUBMIT_CANCEL_FEEDBACK_SUCCESS";
export const SET_EDIT_TENAT_ACC_MODAL = "SET_EDIT_TENAT_ACC_MODAL";
export const FETCH_CANCELLED_SUBSCRIPTIONS = "FETCH_CANCELLED_SUBSCRIPTIONS";
export const FETCH_CANCELLED_SUBSCRIPTIONS_SUCCESS = "FETCH_CANCELLED_SUBSCRIPTIONS_SUCCESS";
export const UPDATE_ONBORDING_FLAG = "UPDATE_ONBORDING_FLAG";
export const FETCH_AO_BOT_STATUS = "FETCH_AO_BOT_STATUS";
export const FETCH_AO_BOT_STATUS_SUCCESS = "FETCH_AO_BOT_STATUS_SUCCESS";
export const CHECK_FOR_AO_SERVICE_UPDATE = "CHECK_FOR_AO_SERVICE_UPDATE";
export const CHECK_FOR_AO_SERVICE_UPDATE_SUCCESS = "CHECK_FOR_AO_SERVICE_UPDATE_SUCCESS";
export const FETCH_PROXY_TRACKING_SERVICES = "FETCH_PROXY_TRACKING_SERVICES";
export const FETCH_PROXY_TRACKING_SERVICES_SUCCESS = "FETCH_PROXY_TRACKING_SERVICES_SUCCESS";
export const SET_RETURN_ADDRESS_MODAL = "SET_RETURN_ADDRESS_MODAL";
export const RESET_AVAILABLE_MATCHES = "RESET_AVAILABLE_MATCHES";
export const SET_SETTING_MESSAGE = "SET_SETTING_MESSAGE";
export const FETCH_ACTIVITY_LOGS = "FETCH_ACTIVITY_LOGS";
export const FETCH_ACTIVITY_LOGS_SUCCESS = "FETCH_ACTIVITY_LOGS_SUCCESS";
export const FILTER_ALL_TENANTS = "FILTER_ALL_TENANTS";
export const FILTER_ALL_TENANTS_SUCCESS = "FILTER_ALL_TENANTS_SUCCESS";
export const SET_TRIGGERS_MODAL = "SET_TRIGGERS_MODAL";
export const FETCH_STORE_WISE_STATS = "FETCH_STORE_WISE_STATS";
export const FETCH_STORE_WISE_STATS_SUCCESS = "FETCH_STORE_WISE_STATS_SUCCESS";
export const FETCH_TENANT_WISE_STATS = "FETCH_TENANT_WISE_STATS";
export const FETCH_TENANT_WISE_STATS_SUCCESS = "FETCH_TENANT_WISE_STATS_SUCCESS";
export const SET_AGENCY_PROFIT_MODAL = "SET_AGENCY_PROFIT_MODAL";
export const UPDATE_AGENCY_PROFIT = "UPDATE_AGENCY_PROFIT";
export const UPDATE_AGENCY_PROFIT_SUCCESS = "UPDATE_AGENCY_PROFIT_SUCCESS";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const SET_SHOW_ONBOARDING = "SET_SHOW_ONBOARDING";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";
export const SET_ADD_REPRICER_STORE = "SET_ADD_REPRICER_STORE";
export const SET_ONBOARDING_FLOW = "SET_ONBOARDING_FLOW";
export const GET_PAYMENT_FAILED_USERS = "GET_PAYMENT_FAILED_USERS";
export const GET_PAYMENT_FAILED_USERS_SUCCESS = "GET_PAYMENT_FAILED_USERS_SUCCESS";
export const GET_CANCELED_USERS = "GET_CANCELED_USERS";
export const GET_CANCELED_USERS_SUCCESS = "GET_CANCELED_USERS_SUCCESS";
export const GET_STATUSES_COUNT = "GET_STATUSES_COUNT";
export const GET_STATUSES_COUNT_SUCCESS = "GET_STATUSES_COUNT_SUCCESS";
export const GET_DROPDOWN_DATA = "GET_DROPDOWN_DATA";
export const GET_DROPDOWN_DATA_SUCCESS = "GET_DROPDOWN_DATA_SUCCESS";
export const GET_AGENCY_DROPDOWN_DATA = "GET_AGENCY_DROPDOWN_DATA";
export const GET_AGENCY_DROPDOWN_DATA_SUCCESS = "GET_AGENCY_DROPDOWN_DATA_SUCCESS";
export const FETCH_MATCHES_REQUESTS_STATUS = "FETCH_MATCHES_REQUESTS_STATUS";
export const FETCH_MATCHES_REQUESTS_STATUS_SUCCESS = "FETCH_MATCHES_REQUESTS_STATUS_SUCCESS";
export const SET_DASHBOARD_PDF_OPTS = "SET_DASHBOARD_PDF_OPTS";
export const SKIP_ONBOARD_AGENCY = "SKIP_ONBOARD_AGENCY";
export const UPDATE_AGENCY_NAME = "UPDATE_AGENCY_NAME";
export const UPDATE_AGENCY_NAME_SUCCESS = "UPDATE_AGENCY_NAME_SUCCESS";
export const REFRESH_AUTH_TOKEN = "REFRESH_AUTH_TOKEN";
export const REFRESH_AUTH_TOKEN_SUCCESS = "REFRESH_AUTH_TOKEN_SUCCESS";
