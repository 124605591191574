import { makeQP } from "../../utils/common";
import request from "../restClient";

const resource = "/cc_details";

export const getAllCreditCards = (params = {}) => request({ url: resource + makeQP(params) });

export const getAllCardTypes = () => request({ url: `${resource}/card_types` });

export const updateCreditCards = ({ data, id }) => request({ url: `${resource}/${id}`, method: "PUT", body: data });

export const deleteCreditCards = ({ id }) => request({ url: `${resource}/${id}`, method: "DELETE" });

export const createCreditCards = ({ data }) => request({ url: resource, method: "POST", body: data });

export const setCardPriorities = (ids) => request({ url: `${resource}/set_priority`, method: "PUT", body: { ids } });
