import React from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PillBtn from "../../../components/Common/PillBtn";
import { Row, Col, Alert, Label, Input } from "reactstrap";
import Modal from "../../../components/Common/Modal";
// actions
import { setSendInvoiceModal, sendInvoiceEmail } from "../../../store/actions";

const SendInvoiceModal = (props) => {
  const toggleModal = () => props.setSendInvoiceModal(!props.FiftyFiftyProfit.sendInvoiceModalIsOpen);

  const validate = (values) => {
    const errors = {};
    !values.email && (errors.email = "Email is required");
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      formik.errors.email = "";
      props.sendInvoiceEmail({ email: values.email, date: props.date });
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.FiftyFiftyProfit.sendInvoiceModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <div>
            <h5 className="modal-title">Send Invoice</h5>
            <span className="font-size-11">(Only pending items will be exported)</span>
          </div>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.FiftyFiftyProfit.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.FiftyFiftyProfit.error}
            </Alert>
          )}
          {formik.errors.email && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {formik.errors.email}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mb-2">
                <Label>Email*</Label>
                <Input name="email" type="email" value={formik.values.email} onChange={formik.handleChange} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <PillBtn
                color="secondary"
                title="Cancel"
                name="Cancel"
                className="pr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              />
              <PillBtn type="submit" color="success" title="Send" name="Send" className="pr-3 ml-3" />
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { FiftyFiftyProfit } = state;
  return { FiftyFiftyProfit };
};

export default connect(mapStateToProps, {
  setSendInvoiceModal,
  sendInvoiceEmail,
})(SendInvoiceModal);
