import React, { useEffect, useState, useRef, Suspense } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { formatNumber, RenderIf } from "../../../utils/common";

// actions
import { fetchProfitGraph, fetchSharedProfitGraph, fetchAllTenantProfitGraph } from "../../../store/actions";
import { DATE_FORMATS } from "../../../constants";

const { DATE, GRAPH_DATE } = DATE_FORMATS;

const DateRange = React.lazy(() => import("../../../components/Common/DateRange"));
const ChartName = React.lazy(() => import("./ChartName"));

const ProtfitGraph = (props) => {
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const store = props.marketplace;
  const userId = props.userId;
  const { profitGraph, sharedProfitGraph, profitLoading } = useSelector((state) => state.Graph);
  const currentDate = moment().startOf("days");
  const oneWeekBefore = moment().subtract(6, "days").startOf("days");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);
  const [average, SetAverage] = useState({
    roi: "",
    avg_profit: "",
    profit: "",
  });

  const fetchGraphDetails = ({ startDate, endDate }) => {
    const sharedParams = { start_date: startDate, end_date: endDate };
    if (props.type === "shared")
      dispatch(fetchSharedProfitGraph({ ...sharedParams, user_id: userId, store_name: store }));
    else if (props.type === "global")
      dispatch(fetchAllTenantProfitGraph({ ...sharedParams, store_name: props.marketplace }));
    else dispatch(fetchProfitGraph({ ...sharedParams, marketplace_id: accountId, utc_offset: moment().format("Z") }));
  };

  useEffect(() => {
    props.type === "shared" &&
      fetchGraphDetails({
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
      });
  }, [userId, store]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    !profitLoading &&
      fetchGraphDetails({
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
      });
  }, [dates[0], dates[1], store]);

  useEffect(() => {
    if (profitGraph?.data) {
      SetAverage({
        avg_profit: (
          profitGraph.data.reduce((acc, data) => (acc += Number(data.avg)), 0.0) / profitGraph.data.length
        ).toFixed(2),
        roi: (profitGraph.data.reduce((acc, data) => (acc += Number(data.roi)), 0.0) / profitGraph.data.length).toFixed(
          2,
        ),
        profit: (
          profitGraph.data.reduce((acc, data) => (acc += Number(data.total)), 0.0) / profitGraph.data.length
        ).toFixed(2),
      });
    }
  }, [profitGraph?.data]);

  const series = [
    {
      name: `Daily Profit (${formatNumber(isNaN(average?.profit) ? "0.00" : average?.profit)})`,
      data: (profitGraph?.data?.length && profitGraph.data.map((x) => [x?.date, parseFloat(x.total).toFixed(2)])) || [
        [dates[0]],
        [dates[1]],
      ],
    },
  ];

  if (!["global", "shared"].includes(props.type)) {
    series.push(
      {
        name: `Avg. Order Profit (${formatNumber(isNaN(average?.avg_profit) ? "0.00" : average?.avg_profit)})`,
        data: (profitGraph?.data?.length && profitGraph.data.map((x) => [x?.date, parseFloat(x.avg).toFixed(2)])) || [
          [dates[0]],
          [dates[1]],
        ],
      },
      {
        name: `ROI (%${isNaN(average?.roi) ? "0.00" : average?.roi})`,
        data: (profitGraph?.data?.length && profitGraph.data.map((x) => [x?.date, parseFloat(x.roi).toFixed(2)])) || [
          [dates[0]],
          [dates[1]],
        ],
      },
    );
  }

  const sharedSeries = [
    {
      name: "Daily Profit",
      data: (sharedProfitGraph?.data?.length &&
        sharedProfitGraph.data.map((x) => [x?.date, parseFloat(x.total).toFixed(2)])) || [[dates[0]], [dates[1]]],
    },
    {
      name: "Avg. Order Profit",
      data: (sharedProfitGraph?.data?.length &&
        sharedProfitGraph.data.map((x) => [x?.date, parseFloat(x.avg).toFixed(2)])) || [[dates[0]], [dates[1]]],
    },
    {
      name: "ROI",
      data: (sharedProfitGraph?.data?.length &&
        sharedProfitGraph.data.map((x) => [x?.date, parseFloat(x.roi).toFixed(2)])) || [[dates[0]], [dates[1]]],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No Data for given date range",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#34c38f", "#ed5c5c", "#8ced5c"],
    xaxis: {
      type: "datetime",
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE) }, // Timestamp is ALWAYS UTC
    },
    yaxis: [
      {
        title: {
          text: "Daily Profit",
        },
      },
      {
        opposite: true,
        title: {
          text: "ROI, Avg. Order Profit",
        },
      },
    ],
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: GRAPH_DATE,
      },
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " $";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " $";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " %";
            },
          },
        },
      ],
    },
  };

  return (
    <Card>
      <CardBody>
        <div className="mb-4 d-flex justify-content-between">
          <Suspense fallback={<></>}>
            <RenderIf isTrue={!profitLoading} fallback={<h4 className="card-title">Loading...</h4>}>
              <ChartName
                name={props.type === "shared" ? "Total Shared Profits" : "Total Profits"}
                dates={dates}
                totalOrders={
                  props.type === "shared"
                    ? formatNumber(
                      sharedProfitGraph?.data?.reduce((acc, x) => acc + parseFloat(x.total), 0) || 0,
                      "currency",
                    )
                    : formatNumber(
                      profitGraph?.data?.reduce((acc, x) => acc + parseFloat(x.total), 0) || 0,
                      "currency",
                    )
                }
              />
            </RenderIf>
            <DateRange dates={dates} setDates={setDates} loader={profitLoading} timePeriods={[7, 30, 365]} />
          </Suspense>
        </div>
        <div className="clearfix"></div>
        <ReactApexChart
          options={options}
          series={props.type === "shared" ? sharedSeries : series}
          type="line"
          height="350"
        />
      </CardBody>
    </Card>
  );
};

export default ProtfitGraph;
