import PropTypes from "prop-types";
import React, { Component } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Row } from "reactstrap";

class Slidewithfade extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeIndex: this.props.activeSlide > -1 ? this.props.activeSlide : 0,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting () {
    this.animating = true;
  }

  onExited () {
    this.animating = false;
  }

  next () {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous () {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex (newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  co;

  render () {
    const { activeIndex } = this.state;

    const slides = this.props.items.map((item, key) => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={"carousel_item_" + key}>
          <Row className={`justify-content-center ${this.props.itemClass || ""}`}>{item}</Row>
        </CarouselItem>
      );
    });

    return (
      <React.Fragment>
        <Carousel activeIndex={activeIndex} fade={true} next={this.next} previous={this.previous} interval={false}>
          {this.props.showIndicators
            ? (
            <CarouselIndicators
              items={this.props.items.map((x, key) => ({ key }))}
              activeIndex={activeIndex}
              onClickHandler={this.goToIndex}
            />
              )
            : null}
          {slides}
          {this.props.hideControls
            ? null
            : (
            <>
              <CarouselControl
                className={this.props.controlClass || ""}
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                className={this.props.controlClass || ""}
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </>
              )}
        </Carousel>
      </React.Fragment>
    );
  }
}

export default Slidewithfade;

Slidewithfade.propTypes = {
  items: PropTypes.array,
};
