import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button, Spinner } from "reactstrap";
import { fetchNotifications, fetchNotificationsSuccess, deleteNotification } from "../../../store/actions";
import { getUserInfo, isAgency, isWHStaff } from "../../../utils/common";
import { filter } from "lodash";
import FadeEffect from "../../Common/FadeEffect";
import SimpleBar from "simplebar-react";

const NotificationDropdown = (_) => {
  const [menu, setMenu] = useState(false);
  const { notifications, count, hasNotifications, notifyLoading } = useSelector((state) => state.Settings);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const toggle = () => setMenu(!menu);

  useEffect(() => {
    if (!isAgency() && count === undefined && !notifyLoading && !isWHStaff()) {
      dispatch(fetchNotifications(getUserInfo()?.id, 0));
    }
  }, [notifications]);

  useEffect(() => {
    if (!notifyLoading) setLoading(notifyLoading);
  }, [notifyLoading]);

  const [fadeStatuses, setFadeStatuses] = useState({});

  async function fetchNext () {
    if (notifications?.length >= count) {
      return;
    }
    if (Array.isArray(notifications))
      setFadeStatuses(
        notifications?.reduce((obj, notify) => {
          const identifier = notify.users_notifications[0].id;
          obj[identifier] = obj[identifier] === undefined ? true : obj[identifier];
          return obj;
        }, {}),
      );

    if (!["service", "dev"].includes(getUserInfo()?.role)) {
      setLoading(true);
      dispatch(fetchNotifications(getUserInfo()?.id, notifications?.length));
    }
  }

  function removeNotification (id) {
    const noti = filter(notifications, (notification) => {
      return notification?.users_notifications[0].id !== id;
    });
    dispatch(deleteNotification(id));
    dispatch(fetchNotificationsSuccess({ notifications: noti, count: count - 1, hasNotifications }));
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="dropdown d-inline-block" tag="li">
        <DropdownToggle
          className="btn header-item noti-icon notification-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada"></i>
          <span className="badge badge-danger badge-pill">{count >= 10 ? "9+" : count !== 0 && count}</span>
        </DropdownToggle>

        {notifications?.length
          ? (
          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {"Notifications"} </h6>
                  <hr style={{ marginBottom: "-15px", borderTop: "1px solid #454c61" }} />
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {notifications?.map((note, index) => {
                return (
                  <FadeEffect
                    key={`_notifications_${note?.users_notifications[0].id}`}
                    index={index}
                    fadeStatuses={fadeStatuses}
                    setFadeStatuses={setFadeStatuses}
                    note={note}
                    toggleMenu={toggle}
                    removeNotification={() => removeNotification(note?.users_notifications[0].id)}
                  />
                );
              })}
            </SimpleBar>
            <div className="p-2 border-top text-center" style={{ height: "50px" }}>
              {loading
                ? (
                <div className="text-center mt-2">
                  <Spinner size="sm" animation="border" role="status" />
                </div>
                  )
                : notifications?.length < count
                  ? (
                <Button
                  onClick={() => fetchNext()}
                  tag={"i"}
                  color="link"
                  disabled={notifications?.length >= count}
                  style={{ textDecoration: "none", fontStyle: "normal" }}
                >
                  <i className="bx bxs-right-arrow-circle" />
                  {"  "} {"View More..."}
                </Button>
                    )
                  : (
                <p className="mt-2">
                  All up for today{" "}
                  <span role="img" aria-labelledby="winking-face">
                    😉
                  </span>
                </p>
                    )}
            </div>
          </DropdownMenu>
            )
          : (
          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 text-center" style={{ height: "230px" }} right>
            {" "}
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h5 className="m-0"> {"No Notifications"} </h5>
                </Col>
              </Row>
            </div>
          </DropdownMenu>
            )}
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
