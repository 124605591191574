import { useFormik } from "formik";
import { keys, merge, omit, reduce, startCase } from "lodash";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Alert, Label, Col, Input, FormGroup } from "reactstrap";
import Select from "react-select";
import Modal from "../../../components/Common/Modal";

// actions
import { addInventoryItem, setAddInventoryModal } from "../../../store/actions";
import { getUserInfo, RenderIf } from "../../../utils/common";
import { INVENTORY_ITEM_TYPES } from "../../../constants";
import NumberField from "../../../components/Common/NumberField";

const getOpt = (key) => ({ label: startCase(key), value: INVENTORY_ITEM_TYPES[key] });

const dropDownFields = ["type"];
const requiredFields = ["tracking_number", "sku"];
const optionalFields = ["qty", "price", "shipping", "bin_no", "upc", "order_id"];
const defaultValues = { qty: 1, price: 0, shipping: 0, type: getOpt("two_step") };

const AddInventoryModal = (props) => {
  const dispatch = useDispatch();
  const { error, addInventoryModalIsOpen } = useSelector((state) => state.WareHouses);
  const toggleModal = () => dispatch(setAddInventoryModal(!addInventoryModalIsOpen));

  const initialValues = merge(
    { tracking_number: "", account_id: "", sku: "", upc: "", order_id: "" },
    reduce(dropDownFields, (obj, x) => merge(obj, { [x]: "" }), {}),
    defaultValues,
  );

  const validate = (values) => {
    const errors = {};
    Object.keys(omit(values, optionalFields)).forEach((key) => {
      if (dropDownFields.includes(key) && values[key].value.trim() === "") errors[key] = "Field is Required";
      else if (requiredFields.includes(key) && values[key].trim() === "") errors[key] = "Field is Required";
    });

    if (!keys(errors).length) return;
    if (!Object.values(INVENTORY_ITEM_TYPES).includes(values.type.value)) errors.type = "Not a correct type selected";
    return errors;
  };
  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const data = {};
      Object.entries(values).forEach(([key, value]) => value && (data[key] = value));
      dispatch(
        addInventoryItem({
          data: merge(
            omit(data, dropDownFields),
            reduce(dropDownFields, (obj, x) => merge(obj, data[x].value && { [x]: data[x].value }), {}),
            { warehouse_id: getUserInfo()?.warehouse_id, account_id: data.suite_no },
          ),
          filters: props.filters,
        }),
      );
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={addInventoryModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">ADD INVENTORY</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>

        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error || ""}
          </Alert>
        )}

        <div className="modal-body">
          <form className="m-2 mt-3" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="6 mb-2">
                <Label>Tracking Number*</Label>
                <Input
                  placeholder="Enter Tracking Number"
                  name="tracking_number"
                  value={formik.values.tracking_number}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <RenderIf isTrue={formik.touched.tracking_number && formik.errors.tracking_number}>
                  <p className="text-danger pt-1">{formik.errors.tracking_number}</p>
                </RenderIf>
              </Col>
              <Col lg="6 mb-2">
                <Label>Suite #</Label>
                <Input
                  placeholder="Enter Suite #"
                  name="account_id"
                  value={formik.values.account_id}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <RenderIf isTrue={formik.touched.account_id && formik.errors.account_id}>
                  <p className="text-danger pt-1">{formik.errors.account_id}</p>
                </RenderIf>
              </Col>
            </Row>
            <Row>
              <Col lg="6 mb-2">
                <Label>SKU*</Label>
                <Input
                  placeholder="Enter SKU"
                  name="sku"
                  value={formik.values.sku}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <RenderIf isTrue={formik.touched.sku && formik.errors.sku}>
                  <p className="text-danger pt-1">{formik.errors.sku}</p>
                </RenderIf>
              </Col>
              <Col lg="6 mb-2">
                <Label>UPC</Label>
                <Input
                  placeholder="Enter UPC"
                  name="upc"
                  value={formik.values.upc}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <RenderIf isTrue={formik.touched.upc && formik.errors.upc}>
                  <p className="text-danger pt-1">{formik.errors.upc}</p>
                </RenderIf>
              </Col>
            </Row>
            <Row>
              <Col lg="6 mb-2">
                <Label>Type</Label>
                <FormGroup className="select2-container w-100">
                  <Select
                    name="type"
                    value={formik.values.type}
                    onChange={(e) => {
                      formik.resetForm();
                      formik.setFieldValue("type", e);
                    }}
                    options={keys(INVENTORY_ITEM_TYPES).reduce((arr, x) => {
                      arr.push(getOpt(x));
                      return arr;
                    }, [])}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                <RenderIf isTrue={formik.touched.type && formik.errors.type}>
                  <p className="text-danger pt-1">{formik.errors.type}</p>
                </RenderIf>
              </Col>
              <Col lg="6 mb-2">
                <Label>Order Id</Label>
                <Input
                  placeholder="Enter Order Id"
                  name="order_id"
                  value={formik.values.order_id}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <RenderIf isTrue={formik.touched.order_id && formik.errors.order_id}>
                  <p className="text-danger pt-1">{formik.errors.order_id}</p>
                </RenderIf>
              </Col>
            </Row>
            <Row>
              <Col lg="6 mb-2">
                <Label>Price</Label>
                <NumberField
                  placeholder="Enter Price"
                  name="price"
                  value={formik.values.price}
                  min={0}
                  max={9000000000}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <RenderIf isTrue={formik.touched.price && formik.errors.price}>
                  <p className="text-danger pt-1">{formik.errors.price}</p>
                </RenderIf>
              </Col>
              <Col lg="6 mb-2">
                <Label>Qty</Label>
                <NumberField
                  placeholder="Enter Qty"
                  name="qty"
                  value={formik.values.qty}
                  isPositive={true}
                  isInteger={true}
                  min="1"
                  max="200000"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <RenderIf isTrue={formik.touched.qty && formik.errors.qty}>
                  <p className="text-danger pt-1">{formik.errors.qty}</p>
                </RenderIf>
              </Col>
            </Row>
            <Row>
              <Col lg="6 mb-2">
                <Label>Shipping</Label>
                <NumberField
                  placeholder="Enter Shipping"
                  name="shipping"
                  value={formik.values.shipping}
                  min={0}
                  max={9000000000}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <RenderIf isTrue={formik.touched.shipping && formik.errors.shipping}>
                  <p className="text-danger pt-1">{formik.errors.shipping}</p>
                </RenderIf>
              </Col>
            </Row>
          </form>
          <Row className="d-flex justify-content-end mt-3 mr-3">
            <Button
              type="reset"
              color="primary"
              onClick={toggleModal}
              outline
              className="btn btn-outline-primary waves-effect waves-light m-1"
            >
              Cancel
            </Button>
            <Button type="submit" onClick={formik.handleSubmit} color="primary" className="btn btn-primary m-1">
              Submit
            </Button>
          </Row>
        </div>

        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default AddInventoryModal;
