import React, { useState, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Alert, Button, Progress, Badge } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Carousel from "../../../components/Common/Carousel";
import * as _ from "lodash";
import * as gtm from "../../../utils/gtmHelpers";

// api action
import {
  fetchSubscriptionPlans,
  createSubscription,
  cancelSubscription,
  updateSubscription,
  payByInvoice as payByInvoiceApi,
} from "../../../api/Stripe";

import { getListingsCount } from "../../../api/Listings";
import CancelFeedback from "../components/CancelFeedback";

// actions
import {
  setPreloader,
  fetchAccountSettings,
  setCancelFeedbackModal,
  updateAccountStatus,
} from "../../../store/actions";
import {
  RenderIf,
  toPascalCase,
  setAuthTokenInBrowser,
  isUserHaveFullAccess,
  daysLeftInTrial,
  formatNumber,
  removeURLQueryParams,
} from "../../../utils/common";

import moment from "moment-timezone";
import { PRIVACY_URL, METADATA_KEYS } from "../../../constants";
import StatusBadge from "../../../components/Common/StatusBadge";

const getAmount = (val) => +val.toFixed(2) / 100;

const isAllInOnePrice = (price) =>
  [
    "Forever Free",
    "Basic Plan",
    "Repricer Plan",
    "Profit Analyzer Plan",
    "Dropship Automation Platform",
    "Everything Plan",
  ].includes(price?.metadata?.title);

const SubscriptionDescription = ({ text, learnMore = false }) => (
  <div>
    <span className="card-text">{text}&nbsp;</span>
    <RenderIf isTrue={learnMore}>
      <u className="text-primary">Learn More</u>
    </RenderIf>
  </div>
);

const SubscriptionDetails = ({ text, moreDetail }) => (
  <div className="d-flex align-items-top mb-3 card-text">
    <i className="bx bx-xs bx-check-circle text-success mr-3" />
    <div>
      {text}&nbsp;
      <RenderIf isTrue={moreDetail}>
        <i className="text-primary">({moreDetail})</i>
      </RenderIf>
    </div>
  </div>
);

const subscriptionCardContent = {
  "Forever Free": {
    title: "Forever Free",
    description: {
      text: "This is your launchpad for your ecommerce business! We're excited to have you here!",
    },
    content: [
      { text: "Ecom Circles Extension", moreDetail: "limited" },
      { text: "Revenue Dashboard" },
      { text: "Use Our Warehouses for Returns", moreDetail: "$3/return" },
      { text: "Facebook Group w/ 6 & 7-figure sellers" },
    ],
  },
  "Basic Plan": {
    title: "Basic Plan",
    description: {
      text: "The perfect plan for sellers who are new or growing their businesses.",
    },
    content: [
      { text: "Everything in Forever Free" },
      { text: "EC Seller Tools Extension", moreDetail: "Unlimited" },
      { text: "Full Dashboard" },
      { text: "Add COGS to track profits" },
      { text: "Fulfillment from our warehouses" },
    ],
  },
  "Repricer Plan": {
    title: "Advanced",
    description: { text: "Everything in the Basic & Scanner plans + Repricer features" },
    content: [
      { text: "Everything in Basic Plan" },
      { text: "Make your own strategies" },
      { text: "Create your own formulas" },
      { text: "Add 3 sub-users" },
      { text: "Download P&L Reports" },
    ],
  },
  "Profit Analyzer Plan": {
    title: "Pro",
    description: {
      text: "Quickly analyze profitability, ROI, estimated sales, and more with this product research tool.",
    },
    content: [
      { text: "Everything in the Basic Plan" },
      { text: "Store and ship items from our warehouse" },
      { text: "Make your own strategies" },
      { text: "Add 3 sub-users" },
      { text: "Download P&L Reports" },
    ],
  },
  "Dropship Automation Platform": {
    title: "Dropship Automation Platform",
    description: {
      text: "We help users in handling stock, repricing, and order placements.",
      learnMore: true,
    },
    content: [
      { text: "Everything in Repricer Plan" },
      { text: "Track stock and price from 25+ suppliers" },
      { text: "Automate tracking number upload" },
      { text: "Automate order processing at some suppliers" },
    ],
  },
  "Everything Plan": {
    title: "Everything Plan",
    description: {
      text: "With our Everything Plan we provide all in one E-commerce Management and Software Services for your business growth.",
      learnMore: true,
    },
    content: [
      { text: "Add unlimited storefronts" },
      { text: "Add up to 100k listings for stock and price checking" },
      { text: "Add unlimited sub-users" },
      { text: "Unlimited inventory items" },
    ],
  },
};

const Plans = (props) => {
  const [loading, setLoading] = useState(false);
  const queryParams = queryString.parse(document.location.search);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [product, setProduct] = useState([]);
  const [subscription, setSubscription] = useState(undefined);
  const [activeListings, setActiveListings] = useState(0);
  const { account, isCancelFeedbackModalOpen: cancelModal } = useSelector((state) => state.Settings);
  const [clickable, setClickable] = useState(true);
  const { currentListingCount } = account?.data?.plan_metadata || {};
  const dispatch = useDispatch();

  async function getPaymentPlans (isLoading = true) {
    try {
      setError(false);
      setSuccess(false);
      setLoading(isLoading);
      const { clickable, product, subscription } = await fetchSubscriptionPlans();
      setClickable(clickable);
      product.prices = _.orderBy(product.prices, ["unit_amount"], ["desc"]);
      try {
        if (currentListingCount) setActiveListings(currentListingCount);
        else {
          const { results } = await getListingsCount();
          setActiveListings(_.sumBy(results, "listings_count"));
        }
      } catch (error) {}
      setProduct(product);
      setSubscription(subscription);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  async function cancelSub () {
    try {
      setError(false);
      setSuccess(false);
      setLoading(true);
      const { subscription, token, account } = await cancelSubscription();
      setSubscription(subscription);
      setSuccess("Subscription cancelled");
      setLoading(false);
      account && dispatch(updateAccountStatus(account));
      dispatch(setCancelFeedbackModal(!cancelModal));
      if (token) setAuthTokenInBrowser(token);
      setTimeout(() => getPaymentPlans(false), 1000);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  async function chanePlan (id, isTrialEnd = false) {
    try {
      setError(false);
      setSuccess(false);
      setLoading(true);
      const { subscription, token } = await updateSubscription(id, { is_trial_end: isTrialEnd });
      setSubscription(undefined);
      setSubscription(subscription);
      setSuccess("Subscription update successfull");
      setLoading(false);
      if (token) setAuthTokenInBrowser(token);
      gtm.purchase(subscription);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  async function subscribe (id) {
    try {
      setError(false);
      setSuccess(false);
      setLoading(true);
      const { subscription, account, token } = await createSubscription(id);
      account && dispatch(updateAccountStatus(account));
      setSubscription(subscription);
      ["active", "trialing"].includes(subscription?.status)
        ? setSuccess("Subscription Successfull")
        : setError(`Your Subscription is ${subscription?.status}`);
      dispatch(fetchAccountSettings(false));
      setLoading(false);
      gtm.purchase(subscription);
      if (token) setAuthTokenInBrowser(token);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  async function payByInvoice (invoice) {
    try {
      setError(false);
      setSuccess(false);
      setLoading(true);
      const res = await payByInvoiceApi(invoice);
      res.success && setClickable(false);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  function setLoader (bool) {
    dispatch(setPreloader(bool));
  }

  useEffect(() => {
    getPaymentPlans();
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const currentUsage = () => {
    return parseInt((activeListings / account?.data.plan_metadata?.metadata?.listings) * 100);
  };

  const filterPlan = (price) =>
    price?.metadata?.title ? isAllInOnePrice(price) && price.recurring.interval === "month" : true;

  const prices = _.orderBy(product?.prices?.filter(filterPlan), "unit_amount");
  const subPlan = subscription?.plan;

  useEffect(() => {
    if (!queryParams.plan_type || !subPlan) return;

    const price = _.find(prices, (pricing) =>
      _.result(pricing, "metadata.title", "").toLowerCase().includes(queryParams.plan_type),
    );
    if (!price || subPlan?.metadata?.title === price?.metadata?.title) return;

    chanePlan(price.id, queryParams.plan_type === "basic" && queryParams.trial === "false");
    removeURLQueryParams();
  }, [product?.prices, subPlan, queryParams.plan_type]);

  const isCustomPlan = useMemo(
    () => subscription !== null && _.findIndex(prices, ["id", _.get(subscription, "plan.id")]) === -1,
    [product, subscription],
  );

  return (
    <React.Fragment>
      {success && (
        <div className="auto-hide">
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {success}
          </Alert>
        </div>
      )}
      {error && (
        <div className="auto-hide">
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        </div>
      )}

      {cancelModal && <CancelFeedback />}

      <RenderIf isTrue={!isCustomPlan}>
        <Row className="justify-content-center mb-5">
          <div className="text-center">
            {account?.data?.plan_metadata && activeListings
              ? account?.data?.plan_metadata?.metadata?.listings !== "unlimited" && (
                  <Progress animated color="success" className="mb-3 progress-plan" value={currentUsage()}>
                    <span className="px-1">{` You have currently used ${currentUsage()}% (${activeListings} used out of ${
                      account?.data?.plan_metadata?.metadata?.listings
                    }) of your plan `}</span>
                  </Progress>
              )
              : null}
            <PlanDates account={props.account} subscription={subscription} />
          </div>
        </Row>
      </RenderIf>
      <Row className="justify-content-center m-3">
        {product?.prices && subscription !== undefined && (
          <RenderIf
            isTrue={!isCustomPlan}
            fallback={<CustomPlan subscription={subscription} stores={props?.accounts?.data?.length} />}
          >
            <Carousel
              activeSlide={_.chunk(prices, 3).findIndex((x) =>
                x.includes(prices?.find((x) => x.id === subscription?.plan?.id)),
              )}
              controlClass="carousel-btns-plans"
              itemClass="subscription-cards-width"
              hideControls={prices?.length <= 3}
              items={_.chunk(
                prices?.map((pricing, key) => (
                  <CardPricing
                    account={props?.account?.data}
                    pricing={pricing}
                    stores={props?.accounts?.data?.length}
                    product={product}
                    cancelSub={cancelSub}
                    chanePlan={chanePlan}
                    payByInvoice={payByInvoice}
                    subscription={subscription}
                    loading={loading}
                    subscribe={subscribe}
                    clickable={clickable}
                    key={"_pricing_" + key}
                  />
                )),
                3,
              )}
            />
          </RenderIf>
        )}
        <RenderIf isTrue={!product?.prices}>
          <div className="d-flex justify-content-center m-2">
            <div className="d-flex flex-column">
              <h1>Fetching Plans...</h1>
            </div>
          </div>
        </RenderIf>
      </Row>
      <RenderIf isTrue={!isCustomPlan}>
        <Row className="justify-content-center">
          <div className="text-center">
            <p className="text-muted">
              Have any Concerns? <a href="mailto:support@ecomcircles.com">support@ecomcircles.com</a>
            </p>
          </div>
        </Row>
      </RenderIf>
    </React.Fragment>
  );
};

export default Plans;

function PlanAmount ({ pricing, subscription, stores }) {
  const isNum = (val) => !isNaN(+val);
  const toNum = (val) => +val.toFixed(2);
  let amount = 0;
  if (
    pricing.billing_scheme === "tiered" &&
    Array.isArray(subscription?.plan?.tiers) &&
    isNum(subscription?.plan?.tiers[0].amount)
  ) {
    amount = toNum((subscription?.plan?.tiers[0].amount * stores) / 100);
  } else if (isNum(pricing?.unit_amount)) {
    amount = toNum(pricing?.unit_amount / 100);
  }
  return (
    <>
      <small>$</small>
      {amount}
      <small>/{pricing?.recurring?.interval}</small>
    </>
  );
}

function CardPricing ({
  account,
  subscription,
  product,
  pricing,
  subscribe,
  cancelSub,
  chanePlan,
  stores,
  payByInvoice,
  clickable,
}) {
  const statusCondition = ["active", "trialing"].includes(subscription?.status);
  const isSubscribed = subscription?.plan?.id === pricing?.id;
  const subscriptionTitle = _.result(pricing?.metadata, "title");
  const daysLeft = daysLeftInTrial(account);
  const isTrialEligible = () => {
    if (!subscriptionTitle) return;

    return account?.trial_end ? daysLeft > 0 : pricing.trial_days;
  };
  return (
    <Col xl={4} lg={6} sm={12} className="mb-3">
      <Card className="py-1 subscription-border h-100">
        <CardBody>
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            <RenderIf isTrue={!subscriptionTitle}>
              <h5>{product?.name}</h5>
            </RenderIf>
            <RenderIf isTrue={isSubscribed}>
              <RenderIf
                isTrue={statusCondition}
                fallback={
                  <i title={_.startCase(subscription?.status)} className="bx bx-sm bx-x-circle text-danger pl-1" />
                }
              >
                <div className="ribbon ribbon-top-right">
                  <span>Selected Plan</span>
                </div>
              </RenderIf>
            </RenderIf>
            <RenderIf isTrue={isSubscribed && subscription?.status === "past_due" && clickable}>
              <i
                title="Retry failed charge"
                className="mdi mdi-refresh m-0 ml-2 h2 bx-sm cursor-pointer"
                onClick={() => payByInvoice(subscription.latest_invoice)}
              />
            </RenderIf>
          </div>
          <RenderIf isTrue={subscriptionCardContent[subscriptionTitle]?.title}>
            <div className="d-flex justify-content-center mt-3 mb-4">
              <StatusBadge
                style={{ whiteSpace: "pre-line" }}
                className="p-2 px-3"
                status={subscriptionCardContent[subscriptionTitle]?.title}
                colorsMapping={{
                  "Basic Plan": "orange",
                  "Forever Free": "warning",
                  "Everything Plan": "success",
                  Advanced: "primary",
                  Pro: "purple",
                  "Dropship Automation Platform": "pink",
                }}
              />
            </div>
          </RenderIf>
          <div className="text-center d-flex justify-content-center mb-3">
            <SubscriptionDescription
              text={subscriptionCardContent[subscriptionTitle]?.description?.text}
              learnMore={subscriptionCardContent[subscriptionTitle]?.description?.learnMore}
            />
          </div>
          <div className="py-4 text-center">
            <h2>
              <PlanAmount pricing={pricing} stores={stores} subscription={subscription} />
            </h2>
          </div>
          <div className="text-center mb-3">
            {isSubscribed && pricing?.metadata?.title !== "Forever Free" && (
              <CofirmDialog
                color="danger"
                title="Cancel"
                message={
                  <>
                    <p>When you cancel your account with Ecom Circles...</p>
                    <br />
                    <ul className="text-left">
                      <RenderIf isTrue={isUserHaveFullAccess()}>
                        <li>We will immediately stop repricing your items and fetching tracking from your accounts</li>
                      </RenderIf>
                      <br />
                      <li>You will not be able to re-subscribe with a plan that we no longer offer</li>
                      <br />
                      <li>
                        Unused time is non-refundable, but if you come back, we will credit the account with whatever
                        was left over
                      </li>
                      <br />
                      <li>You will not be charged again for your subscription or any auto-renew credits</li>
                      <br />
                      <li>
                        By cancelling, you agree to our{" "}
                        <a target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
                          {" "}
                          Refund and Cancellation Policy
                        </a>
                      </li>
                      <br />
                    </ul>
                  </>
                }
                action={cancelSub}
              />
            )}
            {subscription?.plan?.id && subscription?.plan?.id !== pricing?.id && (
              <CofirmDialog
                color="info"
                title={
                  isTrialEligible() ? (
                    <span>
                      Start your <b className="font-size-15">{daysLeft > 0 ? daysLeft : pricing.trial_days}</b> days
                      trial
                      <i aria-hidden="true" className="text-white bx bx-rocket pl-2"></i>
                    </span>
                  ) : (
                    "Change Plan"
                  )
                }
                message="to change selected plan."
                action={() => chanePlan(pricing?.id)}
              />
            )}
            {!subscription?.plan?.id && account?.payment_source_added && (
              <CofirmDialog
                color="primary"
                title="Subscribe"
                message="to subscribe selected plan."
                action={() => subscribe(pricing?.id)}
              />
            )}
          </div>
          <RenderIf
            isTrue={!isAllInOnePrice(pricing)}
            fallback={_.map(subscriptionCardContent[subscriptionTitle]?.content, (subscription, index) => (
              <SubscriptionDetails
                key={`__plans-${index}__`}
                text={subscription.text}
                moreDetail={subscription.moreDetail}
              />
            ))}
          >
            <div className="plan-features mt-5">
              {Object.keys(_.pick(pricing?.metadata, METADATA_KEYS)).map((key, index) => (
                <div key={"_feature_" + index} className="d-flex">
                  <i className="bx bx-sm bx-check text-primary mr-3" />{" "}
                  <p className="mt-1">{getDescription(pricing?.metadata, key)}</p>
                </div>
              ))}
            </div>
          </RenderIf>
        </CardBody>
      </Card>
    </Col>
  );
}

function CofirmDialog ({ action, color, title, message, id }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            action();
            setIsOpen(false);
          }}
          onCancel={() => {
            setIsOpen(false);
          }}
        >
          {message}
        </SweetAlert>
      </RenderIf>
      <Button
        color={color}
        id={id || ""}
        onClick={() => setIsOpen(true)}
        className="btn btn-md btn-block waves-effect waves-light mb-3"
      >
        {title}
      </Button>
    </>
  );
}

function getDescription (metadata, key) {
  if (key.includes("ao")) return metadata[key].toLocaleString() + " Free AO Credits";
  else if (key.includes("va")) return toPascalCase(metadata[key].toLocaleString()) + " VA Accounts";
  else if (key.includes("repricer")) return toPascalCase(key);
  else if (key.includes("fees"))
    return (
      <span>
        {toPascalCase(key)}
        <span className="text-warning">{`(${formatNumber(metadata[key] || 0)})`}</span>
      </span>
    );
  else return toPascalCase(metadata[key] + " " + key);
}

function PlanDates ({ account, subscription }) {
  function getMessage (date) {
    const [now, eventDate] = [moment(), moment(date)];
    const isPast = eventDate.isBefore(now);
    const timeDifference = isPast ? now.diff(eventDate, "seconds") : eventDate.diff(now, "seconds");
    const timeAgo = moment.duration(timeDifference, "seconds").humanize();
    return (
      <span>
        &nbsp;{isPast ? "ended" : "ends in"}&nbsp;
        <span className="text-warning">
          {timeAgo}
          {isPast ? " ago" : ""}
        </span>
      </span>
    );
  }
  const textMapping = { active: "Subscription Period", trialing: "Trial Period" };
  return (
    <RenderIf isTrue={subscription?.plan?.amount}>
      <span>
        <RenderIf
          isTrue={subscription?.current_period_end}
          fallback={
            <RenderIf isTrue={account?.data?.trial_end && !account?.data?.stripe_subscription_id}>
              <h6>
                {textMapping.trialing}
                {getMessage(account.data?.trial_end)}
              </h6>
            </RenderIf>
          }
        >
          <h6>
            {textMapping[subscription?.status] && (
              <span>
                {textMapping[subscription?.status]}
                {getMessage(subscription.current_period_end * 1000)}
              </span>
            )}
          </h6>
        </RenderIf>
      </span>
    </RenderIf>
  );
}

function CustomPlan ({ subscription, stores }) {
  const [amount, interval] = useMemo(() => {
    const price = _.get(subscription, "items.data[0].price");

    let amount = 0;
    const interval = _.get(price, "recurring.interval", "N/A");

    if (price) {
      if (price.billing_scheme === "tiered") {
        const tierAmount = _.get(subscription, "plan.tiers[0].amount");
        if (isFinite(tierAmount)) amount = getAmount(tierAmount * stores);
      } else {
        const unitAmount = _.get(price, "unit_amount");
        if (isFinite(unitAmount)) amount = getAmount(unitAmount);
      }
    }

    return [amount, interval];
  }, [subscription, stores]);

  return (
    <Col sm={12}>
      <div className="w-100 d-flex flex-column align-items-start">
        <h2>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>Current Plan</p>
        </h2>
        <Card className="w-100 subscription-border">
          <CardBody className="d-flex flex-column align-items-start" style={{ rowGap: "16px" }}>
            <div className="ribbon ribbon-top-right">
              <span>Selected Plan</span>
            </div>
            <div className="d-flex">
              <Badge className="p-2" color="success">
                <span style={{ fontSize: "16px", fontWeight: 600 }}>Enterprise Custom</span>
              </Badge>
            </div>
            <div>
              <h2>
                <span style={{ fontSize: "30px", fontWeight: 600 }}>${amount}</span>
                <span style={{ fontSize: "16px", fontWeight: 400 }}>/{interval}</span>
              </h2>
            </div>
            <p style={{ fontSize: "13px", fontWeight: 400 }}>
              You are on a Custom Enterprise plan! That means you're special.
            </p>
            <Alert color="primary" className="w-100 d-flex align-items-center" style={{ columnGap: "8px" }}>
              <i className="bx bx-sm bx-info-circle" />
              <span style={{ fontSize: "13px", fontWeight: 500 }}>
                Please{" "}
                <a
                  href="https://api.leadconnectorhq.com/widget/bookings/ecomscirclessupport"
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    color: "#556EE6",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  Click here
                </a>{" "}
                to contact support to change your current subscribed plan.
              </span>
            </Alert>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
}
