import React, { useEffect, useState } from "react";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
// components
import { Card, CardBody, CardHeader, Alert, Tooltip, FormGroup } from "reactstrap";
import EditModal from "../components/EditModal";
import PillBtn from "../../../components/Common/PillBtn";
import SweetAlert from "react-bootstrap-sweetalert";

// utils
import {
  toPascalCase,
  getUserInfo,
  getWarehouseName,
  RenderIf,
  buildState,
  removeURLQueryParams,
  connectToAMZStores,
  isUserHaveFullAccess,
} from "../../../utils/common";

import {
  ASC_URL,
  DELETE_ACCOUNT_USERS,
  MARKET_PLACES,
  onboardingStepsIds,
  RETURN_LABEL_USERS,
  SHOPIFY_AUTH_URL,
} from "../../../constants";

// actions
import {
  setEditModalMarketplaceAccount,
  setSelectedMarketplaceAccount,
  deleteMarketPlaceAccount,
  setTwoStepModal,
  setPreloader,
  updateVacationMode,
  updateMarketplaceAccounts,
  setReturnAddressModal,
  setIsAmz as _setIsAmz,
  setShowOnboarding as _showOnboarding,
  setOnboardingStep as _onboardingStep,
  setOnboardingFlow as _onboardingFlow,
  fetchAllWarehouses,
} from "../../../store/actions";

import { apiError } from "../../../store/marketPlaceAccounts/actions";

import ReturnAddressModal from "../components/ReturnAddressModal";
import TwoStepPopUp from "../components/TwoStepPopUp";
import { pick, merge, omit, startCase, values, size, filter } from "lodash";
import getBrandImageSrc from "../../../utils/brandImages";
const dialogKeys = ["reduxAction", "dialogMsg", "field", "store"];

const isWalmart = (marketplace) => marketplace === MARKET_PLACES.walmart;
const isShopify = (marketplace) => marketplace === MARKET_PLACES.shopify;

const Channels = () => {
  const {
    accounts,
    selectedAccount,
    success,
    onboardAccountSuccess,
    error,
    loading,
    editModalIsOpen,
    twoStepModalIsOpen,
  } = useSelector((state) => state.MarketPlaceAccount);
  const { associatedWarehousesObj } = useSelector((state) => state.WareHouses);
  const [tooltipSelfAO, setTooltipSelfAO] = useState(false);
  const [marketPlaceAccounts, setMarketPlaceAccounts] = useState([]);
  const setShowOnboarding = (...args) => dispatch(_showOnboarding(...args));
  const setOnboardingStep = (...args) => dispatch(_onboardingStep(...args));
  const setOnboardingFlow = (...args) => dispatch(_onboardingFlow(...args));
  const setIsAmz = (...args) => dispatch(_setIsAmz(...args));

  const { account, returnAddressModal, success: settingSuccess, warning } = useSelector((state) => state.Settings);
  const isFullAccess = isUserHaveFullAccess();
  const [isTwoStep, setIsTwoStep] = useState(false);
  const [search, setSearch] = useState("");

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [viewArchived, setViewArchived] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const tableHeaders = [
    { title: "Name" },
    getUserInfo()?.role === "admin" && { title: "Status" },
    { title: "Marketplace Enabled", class: "text-center" },
    { title: "Two Step", class: "text-center" },
    { title: "Two Step Warehouse" },
    { title: "Return Warehouse" },
    { title: "Auto Acknowledge Orders", class: "text-center" },
    ...(isFullAccess ? [{ title: "Shipping Templates", class: "text-center" }] : []),
    { title: "Vacation Mode", class: "text-center" },
    { title: "Self AO", class: "text-center", id: "self-ao-header" },
    { title: "Upload Tracking", class: "text-center" },
    { title: "View Logs" },
    { title: "Actions" },
  ].filter(Boolean);

  const filteredTableHeaders = isFullAccess
    ? tableHeaders
    : tableHeaders.filter((x) => !["Two Step", "Two Step Warehouse", "Upload Tracking"].includes(x.title));

  function handleUpdate (data) {
    const dialogData = pick(data, dialogKeys);
    dialogKeys.forEach((key) => delete data[key]);
    setUpdateData(merge(data, { history }));
    setConfirmDialog(dialogData);
  }

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }

  function getReturnWHValue (whId) {
    if (!whId) return "";
    if (whId && !associatedWarehousesObj?.[whId]) return "disabled";
    return whId;
  }

  function handleEditAcc (acc) {
    dispatch(setSelectedMarketplaceAccount(acc));
    dispatch(setEditModalMarketplaceAccount(true));
  }

  function handleDeleteMarketPlaceAccount (account) {
    dispatch(deleteMarketPlaceAccount(account.id));
  }

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    dispatch(fetchAllWarehouses({ associated: true }));
  }, []);

  useEffect(() => {
    dispatch(apiError(""));
    dispatch(setTwoStepModal(false));
  }, []);

  useEffect(() => {
    setMarketPlaceAccounts(viewArchived ? accounts?.data : filter(accounts?.data, (x) => !x.is_archive));
  }, [accounts?.data, viewArchived]);

  const filterStores = (mpAccounts) =>
    mpAccounts.filter((x) => !search || x.name.toLowerCase().includes(search.toLowerCase()));

  const Warehouse = ({ item, field }) => (
    <FormGroup className="select2-container" style={{ minWidth: "250px" }}>
      <RenderIf
        fallback={"N/A"}
        isTrue={[MARKET_PLACES.amazon, MARKET_PLACES.walmart, MARKET_PLACES.shopify].includes(item.marketplace)}
      >
        <select
          value={getReturnWHValue(item[field])}
          name={`__${field}__${item.id}`}
          className="custom-select"
          onChange={(e) => {
            if (e.target.value !== item[field])
              dispatch(
                updateMarketplaceAccounts({
                  data: { ...pick(item, "repricer_type", "disable_repricer"), [field]: e.target.value || null },
                  id: item.id,
                }),
              );
          }}
        >
          <RenderIf
            isTrue={!item[field] || (item[field] && !associatedWarehousesObj?.[item[field]])}
            children={
              <option className="select2-selection__option " value="">
                Select Warehouse
              </option>
            }
          />
          {values(associatedWarehousesObj || {}).map((y, i) => (
            <option key={`_option_wh_id_${i}`} className="select2-selection__option css-yt9ioa-option" value={y.id}>
              {getWarehouseName(y)}
            </option>
          ))}

          <RenderIf
            isTrue={item[field] && associatedWarehousesObj?.[item[field]]}
            children={
              <option className="select2-selection__option css-yt9ioa-option" value="">
                Unselect Warehouse
              </option>
            }
          ></RenderIf>
        </select>
      </RenderIf>
    </FormGroup>
  );

  const handleArchiveAccount = (data, viewArchived) => {
    dispatch(updateMarketplaceAccounts(data, viewArchived));
  };

  return (
    <React.Fragment>
      {twoStepModalIsOpen && <TwoStepPopUp isOpen={twoStepModalIsOpen} data={updateData} />}
      {returnAddressModal && <ReturnAddressModal returnAddress={account?.data?.return_address || {}} />}
      {selectedAccount && editModalIsOpen && <EditModal type="edit" />}

      {confirmDialog && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(confirmDialog.reduxAction(isTwoStep ? true : omit(updateData, "history")));
            setIsTwoStep(false);
            setConfirmDialog(false);
          }}
          onCancel={() => {
            setConfirmDialog(false);
            setIsTwoStep(false);
          }}
        >
          {confirmDialog.dialogMsg}
          <RenderIf isTrue={confirmDialog.field === "two_step_enabled"}>
            <div className="custom-control custom-switch custom-switch-lg my-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="mark_orders"
                checked={!!updateData?.data?.mark_orders}
                onChange={() => null}
              />
              <label
                onClick={(_) => {
                  merge(updateData?.data, { mark_orders: !updateData?.data?.mark_orders });
                  setUpdateData({ ...updateData });
                }}
                className="custom-control-label cursor-pointer"
              >
                {`Mark all non-fulfilled orders as ${confirmDialog?.store?.two_step_enabled ? "Direct" : "2 Step"}`}
              </label>
            </div>
          </RenderIf>
        </SweetAlert>
      )}

      {/* Render Breadcrumb */}
      {(success || onboardAccountSuccess || settingSuccess) && (
        <div className="auto-hide">
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {success || onboardAccountSuccess || settingSuccess}
          </Alert>
        </div>
      )}
      {warning && (
        <div className="auto-hide">
          <Alert color="warning">
            <i className="bx bx-info-circle pr-2"></i>
            {warning}
          </Alert>
        </div>
      )}

      {error && !editModalIsOpen && (
        <div className="auto-hide">
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        </div>
      )}
      <Card>
        <CardHeader>
          <div className="row d-flex align-items-center justify-content-between">
            <form className="app-search d-none d-lg-block" onSubmit={(e) => e.preventDefault()}>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value || "")}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
            <RenderIf isTrue={isUserHaveFullAccess()}>
              <div>
                <PillBtn
                  color="success"
                  title="Add"
                  name="Add"
                  icon="bx bx-xs bx-plus"
                  className="ml-1"
                  onClick={() => {
                    setIsAmz({ isOpen: true });
                    setOnboardingFlow(false);
                    setOnboardingStep(onboardingStepsIds.welcom);
                    setShowOnboarding(true);
                    removeURLQueryParams();
                  }}
                />
                <PillBtn
                  color="primary"
                  title="Email Forwarder"
                  name="Email Forwarder"
                  icon="bx bx-xs bx-envelope"
                  className="ml-1"
                  onClick={() => {
                    setOnboardingFlow(false);
                    setOnboardingStep(onboardingStepsIds.emailForwarder);
                    setShowOnboarding(true);
                  }}
                />
                {RETURN_LABEL_USERS.includes(getUserInfo()?.id) ? (
                  <PillBtn
                    color="primary"
                    title="Return Address"
                    name="Return Address"
                    icon="bx bx-xs bx-undo"
                    className="ml-1"
                    onClick={() => {
                      dispatch(setReturnAddressModal(true));
                    }}
                  />
                ) : null}
                <RenderIf isTrue={size(marketPlaceAccounts)}>
                  <PillBtn
                    color="primary"
                    title={`${viewArchived ? "Hide" : "View"} Archived`}
                    name={`${viewArchived ? "Hide" : "View"} Archived`}
                    icon="bx bx-xs bx-archive-in"
                    className="ml-1"
                    onClick={() => {
                      setViewArchived(!viewArchived);
                    }}
                  />
                </RenderIf>
              </div>
            </RenderIf>
          </div>
        </CardHeader>

        <CardBody>
          {marketPlaceAccounts?.length ? (
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    {/* table headers */}
                    {filteredTableHeaders
                      .filter((x) => {
                        if (x.title === "View Logs") return getUserInfo()?.account_id === 2;
                        if (x.title.includes("Two Step")) return account?.data?.two_step_feature;
                        return true;
                      })
                      .map((header, index) =>
                        !/self ao/i.test(header.title) || account?.data?.ao_enabled ? (
                          <th className={header.class || ""} id={header.id || ""} key={`table-header ${index}`}>
                            {header.title}
                          </th>
                        ) : null,
                      )}
                  </tr>
                  {account?.data?.ao_enabled ? (
                    <Tooltip
                      placement="right"
                      isOpen={tooltipSelfAO}
                      target="self-ao-header"
                      toggle={() => setTooltipSelfAO(!tooltipSelfAO)}
                    >
                      AO will attempt to place the order on the marketplace site if the supplier offer is not viable
                    </Tooltip>
                  ) : null}
                </thead>
                <tbody>
                  {/* iterating data */}
                  {filterStores(marketPlaceAccounts || []).map((item, key) => (
                    <React.Fragment key={"_listings_" + key}>
                      <tr
                        title={!item.valid ? "Invalid Credentials" : ""}
                        key={"_listings_" + key}
                        className={!item.valid ? "mt-3 mb-3 row-danger" : "mt-3 mb-3 "}
                      >
                        <td>
                          <div className="left-0">
                            {getBrandImageSrc(item.marketplace) && (
                              <img title={item.name} src={getBrandImageSrc(item.marketplace)} alt={item.marketplace} />
                            )}
                            {toPascalCase(item.name)}
                          </div>
                        </td>
                        {getUserInfo()?.role === "admin" && <td>{startCase(item.status)}</td>}
                        <td className="text-center">
                          {item.enabled ? (
                            <i
                              title="Account Enabled For order import"
                              className="bx bx-sm bx-check-circle text-success"
                            ></i>
                          ) : (
                            <i title="Account Disabled For order import" className="bx bx-sm bx-block text-danger"></i>
                          )}
                        </td>
                        <RenderIf isTrue={isFullAccess && account?.data?.two_step_feature}>
                          <td className="text-center">
                            {item.valid && !isShopify(item.marketplace) ? (
                              <TwoStepCheckBox handleUpdate={handleUpdate} item={item} setIsTwoStep={setIsTwoStep} />
                            ) : (
                              <i
                                title={
                                  isShopify(item.marketplace)
                                    ? "Shopify doesn't allow 2-Step shipping yet"
                                    : "Two step can't be enabled because credentials are invalid"
                                }
                                className="bx bx-sm bx-block text-danger"
                              ></i>
                            )}
                          </td>
                          <td>
                            <RenderIf isTrue={item.two_step_enabled} fallback="N/A">
                              <Warehouse key={"__two_step_wh" + item.id} item={item} field="two_step_wh_id" />
                            </RenderIf>
                          </td>
                        </RenderIf>

                        <td>
                          <RenderIf isTrue={!isShopify(item.marketplace)} fallback="N/A">
                            <Warehouse key={"__return_wh__" + item.id} item={item} field="return_wh_id" />
                          </RenderIf>
                        </td>
                        <td className="text-center">
                          <RenderIf
                            isTrue={isWalmart(item.marketplace)}
                            fallback={
                              <i
                                title="This option is exclusive to Walmart accounts"
                                className="bx bx-sm bx-block text-danger"
                              />
                            }
                          >
                            <AutoAcknowledgeOrdersCheckbox handleUpdate={handleUpdate} item={item} />
                          </RenderIf>
                        </td>
                        <RenderIf isTrue={isFullAccess}>
                          <td className="text-center">
                            <ShippingTemplatesCheckbox handleUpdate={handleUpdate} item={item} />
                          </td>
                        </RenderIf>
                        <td className="text-center">
                          <VacationModeCheckBox handleUpdate={handleUpdate} item={item} />
                        </td>
                        <RenderIf isTrue={account?.data?.ao_enabled}>
                          <RenderIf isTrue={item?.marketplace === MARKET_PLACES.amazon} fallback={<td></td>}>
                            <td className="text-center">
                              {item.self_ao ? (
                                <i
                                  title="AO Enabled to buy from store"
                                  className="bx bx-sm bx-check-circle text-success"
                                ></i>
                              ) : (
                                <i title="AO Disbaled to buy from store" className="bx bx-sm bx-block text-danger"></i>
                              )}
                            </td>
                          </RenderIf>
                        </RenderIf>
                        <RenderIf isTrue={isFullAccess}>
                          <td className="text-center">
                            {item.tracking_enabled ? (
                              <i
                                title="EC will upload the tracking to marketplace"
                                className="bx bx-sm bx-check-circle text-success"
                              ></i>
                            ) : (
                              <i
                                title="EC will not upload the tracking to marketplace"
                                className="bx bx-sm bx-block text-danger"
                              ></i>
                            )}
                          </td>
                        </RenderIf>
                        {getUserInfo().account_id === 2 && (
                          <td>
                            <Link
                              to={`/matching_logs?partner_id=${item.id}&url_type=account_activity`}
                              target="_blank"
                              className="text-primary mb-1"
                            >
                              View Logs
                            </Link>
                          </td>
                        )}
                        <td>
                          <i
                            title="Edit"
                            className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                            onClick={() => handleEditAcc(item)}
                          ></i>
                          {DELETE_ACCOUNT_USERS.includes(getUserInfo()?.id) || marketPlaceAccounts?.length !== 1 ? (
                            <ConfirmDialogIcon
                              icon={{
                                title: "Delete Account",
                                className: "bx bx-sm text-danger bx-trash",
                              }}
                              msg={
                                <p>
                                  To Delete Marketplace Account.
                                  <br />
                                  This will also remove all of its Associated Data
                                </p>
                              }
                              onConfirm={() => handleDeleteMarketPlaceAccount(item)}
                            />
                          ) : null}
                          <RenderIf isTrue={connectToAMZStores([item])}>
                            <i
                              title="Connect to Amazon"
                              className="bx bx-sm bxl-amazon p-1 text-warning cursor-pointer"
                              onClick={() => {
                                document.location.href = ASC_URL(
                                  buildState({ name: item.name, id: item.id, seller_id: item.access_id }, true),
                                );
                              }}
                            ></i>
                          </RenderIf>
                          <RenderIf
                            isTrue={item.marketplace === MARKET_PLACES.shopify && (!item.refresh_token || !item.valid)}
                          >
                            <i
                              title="Connect to Shopify"
                              className="bx bx-sm bxl-shopify p-1 text-warning cursor-pointer"
                              onClick={() => {
                                document.location.href = SHOPIFY_AUTH_URL(
                                  item.name,
                                  `867564edftuygr46576tgyftd657_update_id_${item.id}`,
                                );
                              }}
                            ></i>
                          </RenderIf>
                          <RenderIf isTrue={item.status === "terminated" && item.is_archive === false}>
                            <ConfirmDialogIcon
                              icon={{
                                title: "Archive Account",
                                className: "bx bx-sm text-primary bx-archive-in",
                              }}
                              msg={
                                <p>
                                  To Archive Marketplace Account.
                                  <br />
                                  This will also hide all of its Associated Data
                                </p>
                              }
                              onConfirm={() =>
                                handleArchiveAccount({
                                  data: merge(pick(item, ["disable_repricer", "repricer_type", "is_archive"])),
                                  id: item.id,
                                })
                              }
                            />
                          </RenderIf>
                          <RenderIf isTrue={item.status === "terminated" && item.is_archive}>
                            <ConfirmDialogIcon
                              icon={{
                                title: "Unarchive Account",
                                className: "bx bx-sm text-primary bx-archive-out",
                              }}
                              msg={
                                <p>
                                  To Unarchive Marketplace Account.
                                  <br />
                                  This will also show all of its Associated Data
                                </p>
                              }
                              onConfirm={() =>
                                handleArchiveAccount({
                                  data: merge(pick(item, ["disable_repricer", "repricer_type", "is_archive"])),
                                  id: item.id,
                                })
                              }
                            />
                          </RenderIf>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
          <RenderIf isTrue={marketPlaceAccounts?.length === 0}>
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Store Added</h1>
              </div>
            </div>
          </RenderIf>
          <RenderIf isTrue={marketPlaceAccounts?.length && !filterStores(marketPlaceAccounts).length}>
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Store Found</h1>
              </div>
            </div>
          </RenderIf>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Channels;

function AutoAcknowledgeOrdersCheckbox ({ handleUpdate, item }) {
  return (
    <div className="custom-control custom-switch custom-switch-lg" dir="ltr">
      <input
        type="checkbox"
        className="custom-control-input"
        checked={item.auto_acknowledge_orders}
        onChange={() => null}
      />
      <label
        onClick={() =>
          handleUpdate({
            reduxAction: updateMarketplaceAccounts,
            dialogMsg: `You want to turn Auto Acknowledge Orders ${
              item.auto_acknowledge_orders ? "Off" : "On"
            } for store ${item.name}?`,
            id: item.id,
            data: {
              ...pick(item, "repricer_type", "disable_repricer"),
              auto_acknowledge_orders: !item.auto_acknowledge_orders,
            },
          })
        }
        className="custom-control-label cursor-pointer"
      ></label>
    </div>
  );
}

function ShippingTemplatesCheckbox ({ handleUpdate, item }) {
  return (
    <div className="custom-control custom-switch custom-switch-lg" dir="ltr">
      <input
        type="checkbox"
        className="custom-control-input"
        checked={item.is_shipping_templates_enabled}
        onChange={() => null}
      />
      <label
        onClick={() =>
          handleUpdate({
            reduxAction: updateMarketplaceAccounts,
            dialogMsg: `You want to turn Shipping Templates ${
              item.is_shipping_templates_enabled ? "Off" : "On"
            } for store ${item.name}?`,
            id: item.id,
            data: {
              ...pick(item, "repricer_type", "disable_repricer"),
              is_shipping_templates_enabled: !item.is_shipping_templates_enabled,
            },
          })
        }
        className="custom-control-label cursor-pointer"
      ></label>
    </div>
  );
}

function VacationModeCheckBox ({ handleUpdate, item }) {
  return (
    <div className="custom-control custom-switch custom-switch-lg" dir="ltr">
      <input type="checkbox" className="custom-control-input" checked={item.vacation_mode} onChange={() => null} />
      <label
        onClick={() =>
          handleUpdate({
            reduxAction: updateVacationMode,
            dialogMsg: `To update vacation mode to ${item.vacation_mode ? "Off" : "On"}!`,
            vacation_mode: !item.vacation_mode,
            marketplace: item.marketplace,
            id: item.id,
          })
        }
        className="custom-control-label cursor-pointer"
      ></label>
    </div>
  );
}

function TwoStepCheckBox ({ handleUpdate, item, setIsTwoStep }) {
  return (
    <div className="custom-control custom-switch custom-switch-lg" dir="ltr">
      <input type="checkbox" className="custom-control-input" checked={item.two_step_enabled} onChange={() => null} />
      <label
        onClick={() => {
          handleUpdate({
            reduxAction: item.two_step_enabled ? updateMarketplaceAccounts : setTwoStepModal,
            dialogMsg: `To turn ${item.two_step_enabled ? "Off" : "On"} Two Step for store ${item.name}?`,
            data: {
              ...pick(item, "repricer_type", "disable_repricer"),
              two_step_enabled: !item.two_step_enabled,
              two_step_wh_id: null,
            },
            field: "two_step_enabled",
            store: item,
            id: item.id,
          });
          setIsTwoStep(!item.two_step_enabled);
        }}
        className="custom-control-label cursor-pointer"
        id={`_two_step_feature_${item.id}`}
      ></label>
      <CustomTooltip
        target={`_two_step_feature_${item.id}`}
        content={
          item.two_step_enabled
            ? "All listings will be considered as 2 step"
            : "Listing level settings will be considered"
        }
      />
    </div>
  );
}
