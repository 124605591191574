import {
  FETCH_CC_DETAILS,
  SELECT_CREDIT_CARD,
  SET_EDIT_CREDIT_CARDS_MODAL,
  SET_ADD_CREDIT_CARDS_MODAL,
  FETCH_CARD_TYPES,
  FETCH_CARD_TYPES_SUCCESS,
  ADD_CREDIT_CARDS,
  ADD_CREDIT_CARDS_SUCCESS,
  UPDATE_CREDIT_CARDS_SUCCESS,
  UPDATE_CREDIT_CARDS,
  DELETE_CREDIT_CARD,
  DELETE_CREDIT_CARD_SUCCESS,
  FETCH_FETCH_CC_DETAILS_SUCCESS,
  API_ERROR,
  SET_CREDIT_CARDS_FILTERS,
  UPDATE_CREDIT_CARDS_PRIORITY,
  UPDATE_CREDIT_CARDS_PRIORITY_SUCCESS,
} from "./actionTypes";

export const fetchCreditCardDetails = (params = {}) => ({ type: FETCH_CC_DETAILS, payload: { params } });

export const fetchCreditCardDetailsSuccess = (ccData) => ({ type: FETCH_FETCH_CC_DETAILS_SUCCESS, payload: ccData });

export const fetchCardTypes = () => ({ type: FETCH_CARD_TYPES, payload: {} });

export const fetchCardTypesSuccess = (ccTypes) => ({ type: FETCH_CARD_TYPES_SUCCESS, payload: ccTypes });

export const updateCreditCards = ({ data, id }) => ({ type: UPDATE_CREDIT_CARDS, payload: { data, id } });

export const updateCreditCardsSuccess = () => ({ type: UPDATE_CREDIT_CARDS_SUCCESS, payload: {} });

export const deleteCreditCard = (id) => ({ type: DELETE_CREDIT_CARD, payload: { id } });

export const deleteCreditCardSuccess = () => ({ type: DELETE_CREDIT_CARD_SUCCESS, payload: {} });

export const createCreditCards = ({ data }) => ({ type: ADD_CREDIT_CARDS, payload: { data } });

export const createCreditCardsSuccess = () => ({ type: ADD_CREDIT_CARDS_SUCCESS, payload: {} });

export const setSelectedCreditCard = (creditcard) => ({ type: SELECT_CREDIT_CARD, payload: creditcard });

export const setEditCreditCardsModal = (bool) => ({ type: SET_EDIT_CREDIT_CARDS_MODAL, payload: bool });

export const setCreditCardsFilter = (filters) => ({ type: SET_CREDIT_CARDS_FILTERS, payload: filters });

export const setAddCreditCardsModal = (bool) => ({ type: SET_ADD_CREDIT_CARDS_MODAL, payload: bool });

export const updateCardPriorities = (newlist) => ({ type: UPDATE_CREDIT_CARDS_PRIORITY, payload: newlist });

export const updateCardPrioritiesSuccess = (res = {}) => ({ type: UPDATE_CREDIT_CARDS_PRIORITY_SUCCESS, payload: res });

export const apiError = (error) => ({ type: API_ERROR, payload: error });
