import {
  FETCH_SHARED_LISTING_DATA,
  FETCH_SHARED_LISTING_DATA_SUCCESS,
  SEND_INVOICE_MODAL,
  SEND_INVOICE_EMAIL,
  SEND_INVOICE_EMAIL_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const fetchSharedListingData = (params) => {
  return {
    type: FETCH_SHARED_LISTING_DATA,
    payload: { params },
  };
};

export const fetchSharedListingDataSuccess = (res) => {
  return {
    type: FETCH_SHARED_LISTING_DATA_SUCCESS,
    payload: res,
  };
};

export const sendInvoiceEmail = (data) => {
  return {
    type: SEND_INVOICE_EMAIL,
    payload: data,
  };
};

export const sendInvoiceEmailSuccess = (res) => {
  return {
    type: SEND_INVOICE_EMAIL_SUCCESS,
    payload: res,
  };
};

export const setSendInvoiceModal = (data) => {
  return {
    type: SEND_INVOICE_MODAL,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
