import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Common/Modal";

import Register from "../../Authentication/Register";

// actions
import { setAddClientModal } from "../../../store/actions";

const AddClientModal = (_) => {
  const dispatch = useDispatch();
  const { addClientModalIsOpen } = useSelector((state) => state.Agency);

  const toggleModal = (_) => {
    dispatch(setAddClientModal(!addClientModalIsOpen));
  };

  return (
    <Modal size="md" isOpen={addClientModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Add a Client</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body add-client">
        <Register isAgency={true} />
      </div>
    </Modal>
  );
};

export default AddClientModal;
