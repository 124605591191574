import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Form, CardBody, Container, Alert, CardSubtitle, CardTitle } from "reactstrap";
import Dropzone from "react-dropzone";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// actions
import { updateSupplierOrderFromShipmentReport, selectFileError } from "../../store/actions";

const ShipmentReport = (props) => {
  const [selectedFile, setselectedFile] = useState([]);
  const dispatch = useDispatch();
  const ShipmentReport = useSelector((state) => state.ShipmentReport);

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function handleReportSubmit () {
    if (selectedFile.length > 0) {
      dispatch(
        updateSupplierOrderFromShipmentReport({
          file: selectedFile[0],
          params: { update_all: true },
        }),
      );
      setselectedFile([]);
    } else {
      dispatch(selectFileError());
    }
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Shipment Report" />

          {ShipmentReport?.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {ShipmentReport?.success || ""}
              </Alert>
            </div>
          )}

          {ShipmentReport?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {ShipmentReport?.error || ""}
            </Alert>
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <div className="d-flex flex-row justify-content-between">
                  <div style={{ marginLeft: 20, marginTop: 20 }}>
                    <CardTitle>Upload a Shipment Report</CardTitle>
                    <CardSubtitle className="mb-3">
                      CSV File must have these Columns with same naming conventions: Order ID, Carrier Tracking #,
                      Carrier Name
                    </CardSubtitle>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <a
                      className="mr-4 mt-2 btn btn-primary waves-effect waves-light"
                      href={"/template_file_for_shipment_report.csv"}
                      download
                    >
                      Download Sample File
                    </a>
                  </div>
                </div>
                <CardBody>
                  <Form>
                    <Dropzone
                      onDrop={(acceptedFile) => {
                        handleAcceptedFile(acceptedFile);
                      }}
                      accept=".csv"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div className="dz-message needsclick mt-2" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                            </div>
                            <h4>Drag or Click to Upload CSV File</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFile.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  {f.name}
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Form>

                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={handleReportSubmit}
                    >
                      Send File
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShipmentReport;
