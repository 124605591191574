import React from "react";
import { Button, Row, Col, Label, Alert } from "reactstrap";
import { useFormik } from "formik";
import { RenderIf } from "../../../utils/common";
import Modal from "../../../components/Common/Modal";
import { merge } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateInventoryItems } from "../../../store/actions";

const AssignOutbound = (props) => {
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);
  const validate = (values) => merge({}, !values.qty && { qty: "Field is required" });
  const { error } = useSelector((state) => state.SourceOrders);
  const {
    source_order_address: { name, address1, city, state, zipcode, country, phone },
    marketplace_account: { name: storeName, marketplace, status, valid },
  } = props?.sourceOrder;

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      warehouse_inventory_id: props?.inventory?.id,
      outbound_id: props?.outboundId || 0,
      source_order_data: {
        fulfillment_channel: props?.sourceOrder?.fulfillment_channel,
        warehouse_id: props?.sourceOrder?.wh_id || props?.inventory?.warehouse_id,
        source_order_id: props?.sourceOrder?.id,
        order_id: props?.sourceOrder?.marketplace_order_id,
        outbound_type: "inventory_fulfilled",
        customer_details: { customer_name: name, address1, city, state, country, phone, zipcode },
        marketplace_details: { store_name: storeName, marketplace, marketplace_status: status, valid },
        marketplace_status: props?.sourceOrder?.marketplace_status,
        ship_date: props?.sourceOrder?.required_shipping_date,
      },
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.assignInventory(values);
      formik.resetForm();
      dispatch(updateInventoryItems());
    },
  });

  return (
    <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Assign Inventory</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <RenderIf isTrue={error}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2" />
                {error}
              </Alert>
            </div>
          </RenderIf>
          <Row>
            <Col lg="12 mb-2">
              <Label>Qty.</Label>
              <textarea
                className="form-control"
                name="qty"
                value={formik.values.qty}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <RenderIf isTrue={formik.touched.qty && formik.errors.qty}>
                <span className="text-danger mt-1">{formik.errors.qty}</span>
              </RenderIf>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default AssignOutbound;
