import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllRestrictedItems,
  bulkUpdateRestrictedItems,
  addNewItem,
  updateItem,
  bulkAddNewItems,
  deleteItem,
} from "../../api";
// Login Redux States
import {
  FETCH_RESTRICTED_ITEMS,
  ITEMS_BULK_UPDATE,
  ADD_RESTRICTED_ITEMS,
  UPDATE_RESTRICTED_ITEMS,
  DEL_RESTRICTED_ITEMS,
  BULK_ADD_RESTRICTED_ITEMS,
} from "./actionTypes";

import {
  apiError,
  fetchRestrictedItemsSuccess,
  bulkUpdateItemsSuccess,
  setItemsBulkUpdateModal,
  setSelectedItems,
  addNewItemSuccess,
  deleteItemSuccess,
  updateItemSuccess,
  bulkAddNewItemSuccess,
  fetchRestrictedItems,
} from "./actions";

const RestrictedItems = (state) => state.RestrictedItems;

function * fetchRestrictedItemsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllRestrictedItems, params);
    yield put(fetchRestrictedItemsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateItemSaga ({ payload }) {
  try {
    const res = yield call(updateItem, payload);
    const items = yield select(RestrictedItems);
    if (res.success) {
      const index = items.restrictedItems.results.findIndex((x) => x.id === res.item.id);
      index !== undefined && (items.restrictedItems.results[index].name = res.item.name);
      items.editItemModalIsOpen = false;
    }
    yield put(updateItemSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addNewItemSaga ({ payload }) {
  try {
    const res = yield call(addNewItem, payload);
    const items = yield select(RestrictedItems);
    if (res.success) {
      items?.restrictedItems?.results &&
        !items.restrictedItems.results?.find((x) => x?.id === res?.item.id) &&
        items.restrictedItems.results.unshift({
          ...res.item,
          blacklist: true,
        });
      items.restrictedItems.results.length = Math.max(items.restrictedItems.per_page, 30);
      items.restrictedItems.per_page = Math.max(items.restrictedItems.per_page, 30);
      ++items.restrictedItems.total_count;
      items.addItemModalIsOpen = false;
    }
    yield put(addNewItemSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkAddNewItemSaga ({ payload }) {
  try {
    const res = yield call(bulkAddNewItems, payload);
    const items = yield select(RestrictedItems);
    if (res.success) {
      items.restrictedItems.results = [...items.restrictedItems.results, ...res.items].filter((x) => x.id);
      items.restrictedItems.total_count = res.total_count;
      items.restrictedItems.per_page = res.per_page;
      items.restrictedItems.page_number = res.page_number;
      items.addItemModalIsOpen = false;
    }
    yield put(bulkAddNewItemSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteItemSaga ({ payload }) {
  try {
    const res = yield call(deleteItem, payload);
    const items = yield select(RestrictedItems);
    if (res.success) {
      const itemIds = Array.isArray(res.platform_item.item_id)
        ? res.platform_item.item_id
        : [res.platform_item.item_id];
      if (items?.restrictedItems?.results) {
        if (!itemIds.length) items.restrictedItems.results = [];
        else items.restrictedItems.results = items.restrictedItems.results.filter((x) => !itemIds.includes(x.id));
        items.restrictedItems.total_count = items.restrictedItems?.total_count - itemIds.length;
        items.restrictedItems.per_page = items.restrictedItems.per_page - itemIds.length;
      }
    }
    yield put(deleteItemSuccess(res));
    yield put(
      fetchRestrictedItems({
        page_number: 1,
        per_page: 30,
        platform: payload.data.platform,
        filters_hash: { filters: [] },
      }),
    );
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkUpdateItemsSaga ({ payload: { data } }) {
  try {
    const res = yield call(bulkUpdateRestrictedItems, data);
    const items = yield select(RestrictedItems);
    if (res?.success && data.update_type === "current_page") {
      let shouldUpdateIds = [];
      if (data.item_identifiers.length === 1) shouldUpdateIds = data.item_identifiers;
      else shouldUpdateIds = items.selectedItems.map((x) => x);

      let index = "";
      shouldUpdateIds.forEach((x) => {
        index = items.restrictedItems.results?.findIndex((y) => y.id === x);
        items.restrictedItems.results[index].blacklist = JSON.parse(data.blacklist);
      });
    } else if (res?.success && data.update_type === "all_selection") {
      // Refresh the Page in the case of All Selections
      (items?.restrictedItems?.results || []).forEach((x) => (x.blacklist = JSON.parse(data.blacklist)));
    }
    yield put(setItemsBulkUpdateModal(false));
    yield put(setSelectedItems([]));
    yield put(bulkUpdateItemsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchRestrictedItemsFetch () {
  yield takeEvery(FETCH_RESTRICTED_ITEMS, fetchRestrictedItemsSaga);
}

export function * watchBulkItemsUpdate () {
  yield takeEvery(ITEMS_BULK_UPDATE, bulkUpdateItemsSaga);
}

export function * watchUpdateItemSaga () {
  yield takeEvery(UPDATE_RESTRICTED_ITEMS, updateItemSaga);
}

export function * watchAddNewItemSaga () {
  yield takeEvery(ADD_RESTRICTED_ITEMS, addNewItemSaga);
}

export function * watchBulkAddNewItemSaga () {
  yield takeEvery(BULK_ADD_RESTRICTED_ITEMS, bulkAddNewItemSaga);
}

export function * watchDeleteItemSaga () {
  yield takeEvery(DEL_RESTRICTED_ITEMS, deleteItemSaga);
}

function * restrictedItemsSaga () {
  yield all([
    fork(watchBulkAddNewItemSaga),
    fork(watchRestrictedItemsFetch),
    fork(watchBulkItemsUpdate),
    fork(watchUpdateItemSaga),
    fork(watchAddNewItemSaga),
    fork(watchDeleteItemSaga),
  ]);
}

export default restrictedItemsSaga;
