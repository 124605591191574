import request from "../restClient";
import { verifyRole, getUserInfo } from "../../utils/common";
import { omit } from "lodash";
const { REACT_APP_IP_TOKEN } = process.env;

export const getAllUsers = async ({ params }) => {
  !verifyRole("admin") && (params.account_id = getUserInfo().account_id);
  const url =
    "/users?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const createUser = async ({ data }) => {
  const url = "/users/";
  return await request({ url, method: "POST", body: data });
};

export const expireUserSession = async ({ params }) => {
  const url = `/users/expire_session/${params}`;
  return await request({ url, method: "DELETE" });
};

export const updateUser = async ({ data, id }) => {
  const url = `/users/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const updateProfileAndCompanyInfo = async ({ data, id }) => {
  const url = `/users/profile_info/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const fetchCompanyInfo = async (id) => {
  const url = `/users/company_info/${id}`;
  return await request({ url, method: "GET" });
};

export const deleteUser = async (id) => {
  const url = `/users/${id}`;
  return await request({ url, method: "DELETE" });
};

export const registerUser = async ({ data }) => {
  const url = `/users/register/${data.hash ? data.hash : ""}`;
  return await request({ url, method: "POST", body: omit(data, ["hash"]) });
};

export const inviteUser = async ({ data }) => {
  const url = "/users/invite_users";
  return await request({ url, method: "POST", body: data });
};

export const verifyUser = async ({ token }) => {
  const url = `/users/verify/${token}`;
  return await request({ url, method: "GET" });
};

export const verifyWarehouseUser = async (data) => {
  const url = "/account_warehouses/verify_user";
  try {
    const apiResponse = await request({
      url: `https://ipinfo.io?token=${REACT_APP_IP_TOKEN}`,
      method: "GET",
      baseUrl: false,
    });
    if (apiResponse?.ip) data.ip_info = apiResponse;
  } catch (err) {}
  return await request({ url, method: "POST", body: data });
};

export const sendVerificationToken = async (email) => {
  const url = `/users/send_verfication/${email}`;
  return await request({ url, method: "GET" });
};

export const onboardUser = async (data) => {
  const url = "/users/onboard";
  return await request({ url, method: "post", body: data });
};

export const forgetUser = async (email) => {
  const url = `/users/forgot_password/${email}`;
  return await request({ url, method: "get" });
};

export const resetPassword = async (data) => {
  const url = `/users/reset_password/${data.invite}`;
  delete data.invite;
  return await request({ url, method: "post", body: data });
};

export const genVerificationEmail = async () => {
  const url = "/users/verify_invite";
  return await request({ url, method: "get" });
};

export const getAllAccounts = async (params = {}) => {
  !verifyRole("admin") && (params.id = getUserInfo().account_id);
  const url =
    "/users/accounts?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const assignAgencyAccounts = async ({ data }) => {
  const url = "/users/assign_accounts";
  return await request({ url, method: "POST", body: data });
};

export const assignAgencies = async ({ data: agencyIds, id }) =>
  request({ url: `/users/assign_agencies/${id}`, method: "POST", body: { agency_ids: agencyIds } });

export const updateAccount = async ({ data, id }) =>
  request({ url: `/accounts/update_account/${id}`, method: "PUT", body: data });

export const updateTosPolicy = async () => {
  return await request({ url: "/users/update_tos", method: "PUT" });
};

export const updateAllTos = async () => {
  return await request({ url: "/users/update_all_tos", method: "PUT" });
};

export const fetchAgencyClients = () => request({ url: "/get_agency_clients", method: "GET" });
