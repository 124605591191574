import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Col, Row, Alert, Label, Button, Input } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { setPlanGroupModal, setPreloader, createPlanGroup, updatePlanGroup } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const PlanGroupModal = ({ item }) => {
  const isNew = !item;
  const dispatch = useDispatch();
  const { isPlanGroupModal, loading, error } = useSelector((state) => state.CustomPlans);
  const toggleModal = () => dispatch(setPlanGroupModal(!isPlanGroupModal));

  const initialValues = { name: item?.name || "", trial_days: item?.trial_days || 7 };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      if (values.trial_days === "") errors.trial_days = "is required";
      else if (values.trial_days < 0) errors.trial_days = "not be a negative number";

      if (!values.name) errors.name = "is required";
      else if (values.name.length < 3) errors.name = "must have at least 3 letters";
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (isNew) dispatch(createPlanGroup({ name: values.name, trial_days: values.trial_days }));
      else dispatch(updatePlanGroup(item.id, { new_name: values.name, trial_days: values.trial_days }));
    },
  });

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <Modal size="md" isOpen={isPlanGroupModal} toggle={toggleModal}>
      <div className="modal-header">
        <h4>{`${isNew ? "Add New" : "Edit"} Plan Group`}</h4>
        <button onClick={() => toggleModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x" />
        </button>
      </div>
      {error && (
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2" />
          {error}
        </Alert>
      )}
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mt-2">
              <>
                <Label className={formik.errors.name ? "text-danger" : ""}>Name</Label>
                {formik.errors.name ? <small className="text-danger m-1">{formik.errors.name}</small> : null}
                <Input
                  name="name"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </>
            </Col>
            <Col lg="12 mt-2">
              <>
                <Label className={formik.errors.trial_days ? "text-danger" : ""}>Trial Days</Label>
                {formik.errors.trial_days
                  ? (
                  <small className="text-danger m-1">{formik.errors.trial_days}</small>
                    )
                  : null}
                <Input
                  name="trial_days"
                  type="number"
                  min="0"
                  value={formik.values.trial_days}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              {`${isNew ? "Add New" : "Update"} Plan Group`}
            </Button>
          </Row>
        </form>
      </div>
    </Modal>
  );
};

export default PlanGroupModal;
