import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_FORGET_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SET_FORGET_PASS_ERROR,
} from "./actionTypes";

const initialState = {
  success: "",
  error: "",
  loading: false,
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case RESET_FORGET_PASSWORD:
      state = {
        ...state,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "Password reset successfully",
      };
      break;
    case SET_FORGET_PASS_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
