export const GET_PAYMENT_SOURCES = "GET_PAYMENT_SOURCES";
export const GET_PAYMENT_SOURCES_SUCCESS = "GET_PAYMENT_SOURCES_SUCCESS";
export const DELETE_SOURCE = "DELETE_SOURCE";
export const DELETE_SOURCE_SUCCESS = "DELETE_SOURCE_SUCCESS";
export const UPDATE_PAYMENT_SOURCE = "UPDATE_PAYMENT_SOURCE";
export const UPDATE_PAYMENT_SOURCE_SUCCESS = "UPDATE_PAYMENT_SOURCE_SUCCESS";
export const SET_ADD_CARD_MODAL = "SET_ADD_CARD_MODAL";
export const GET_CUSTOMER_HISTORY = "GET_CUSTOMER_HISTORY";
export const GET_CUSTOMER_HISTORY_SUCCESS = "GET_CUSTOMER_HISTORY_SUCCESS";
export const RETRY_WH_CHARGES = "RETRY_WH_CHARGES";
export const RETRY_WH_CHARGES_SUCCESS = "RETRY_WH_CHARGES_SUCCESS";
export const API_ERROR = "PAYMENT_API_ERROR";
