import { Alert } from "reactstrap";
import {
  assignValueToFilters,
  checkFilterValue,
  COMPARISION_FILTERS,
  FILTER_OPERATOR_KEYS,
  FILTERS_TO_SHOW,
  FILTERS_TYPES,
  getDropDownValues,
  getFiltersMetaData,
  parseFilterKey,
} from "./common";
import { RenderIf } from "../../../utils/common";
import $ from "lodash";
import FilterContext from "./FilterContext";
import PillBtn from "../../../components/Common/PillBtn";
import React, { useMemo, useState } from "react";

export default function ListingFilters ({ toggleDrawer, globalFilters, setGlobalFilters, initialOptions }) {
  const compactGlobalFilters = useMemo(
    () => $.pickBy(globalFilters, checkFilterValue), // only pick the filters which have a valid filter value
    [globalFilters],
  );

  const [filtersMetaData, dropDownValues] = useMemo(
    () => [getFiltersMetaData(initialOptions), getDropDownValues(initialOptions)],
    [initialOptions],
  );

  const [parsedGlobalFilters, parsedFilterOperators] = useMemo(() => {
    const parsedFilterOperators = {};
    const parsedGlobalFilters = $.mapKeys(compactGlobalFilters, (_, filterKey) => {
      const [_filterKey, _operator] = parseFilterKey(filterKey);
      if (_operator) $.merge(parsedFilterOperators, { [_filterKey]: _operator });
      return _filterKey;
    });
    return [parsedGlobalFilters, parsedFilterOperators];
  }, [compactGlobalFilters]);

  const filtersIntialValues = assignValueToFilters(filtersMetaData, parsedGlobalFilters);
  const filtersIntialOperators = COMPARISION_FILTERS.reduce(
    (acc, filterKey) => ({ ...acc, [filterKey]: $.get(parsedFilterOperators, filterKey, "") }),
    {},
  );
  const filtersInitialState = assignValueToFilters(filtersMetaData, parsedGlobalFilters, {
    assignValueFunc: (filter) => $.has(parsedGlobalFilters, filter),
  });

  const [localFilters, setLocalFilters] = useState({ ...filtersIntialValues });
  const [filterOperators, setFilterOperators] = useState({ ...filtersIntialOperators });
  const [addedFilters, setAddedFilters] = useState({ ...filtersInitialState });
  const [expandedFilters, setExpandedFilters] = useState({ ...filtersInitialState });

  const [canApplyFilters, setCanApplyFilters] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const checkInitialState = ({
    updatedValues = localFilters,
    updatedOperators = filterOperators,
    updatedAdded = addedFilters,
  } = {}) => {
    const areFiltersChanged =
      $.isEqual(filtersIntialValues, updatedValues) &&
      $.isEqual(filtersIntialOperators, updatedOperators) &&
      $.isEqual(filtersInitialState, updatedAdded);
    setCanApplyFilters(!areFiltersChanged);
    setShowAlert(!areFiltersChanged);
  };

  const setFilter = (filterKey, value) => {
    setLocalFilters({ ...localFilters, [filterKey]: value });
  };

  const removeFilter = (filterKey) => {
    const updatedValues = { ...$.set(localFilters, filterKey, "") };
    const updatedAdded = { ...$.set(addedFilters, filterKey, false) };

    setLocalFilters(updatedValues);
    setAddedFilters(updatedAdded);

    checkInitialState({ updatedValues, updatedAdded });
  };

  const setOperator = (filterKey, value) => {
    setFilterOperators({ ...filterOperators, [filterKey]: value });
  };

  const addFilter = (filterKey) => {
    const updatedAdded = { ...addedFilters, [filterKey]: true };

    setAddedFilters(updatedAdded);

    checkInitialState({ updatedAdded });
  };

  const resetFilters = () => {
    const updatedValues = $.mapValues(localFilters, () => "");
    const updatedOperators = $.mapValues(filterOperators, () => "");
    const updatedAdded = $.mapValues(addedFilters, () => false);

    setLocalFilters(updatedValues);
    setFilterOperators(updatedOperators);
    setAddedFilters(updatedAdded);
    setExpandedFilters($.mapValues(expandedFilters, () => false));

    checkInitialState({ updatedValues, updatedOperators, updatedAdded });
  };

  const getRemovedFilters = () =>
    $.reduce(
      compactGlobalFilters,
      (acc, filterValue, filterKey) => {
        const [parsedFilterKey] = parseFilterKey(filterKey);
        if (
          $.has(FILTERS_TO_SHOW, parsedFilterKey) &&
          checkFilterValue(filterValue) &&
          !$.get(addedFilters, parsedFilterKey)
        ) {
          return [...acc, filterKey];
        }
        return acc;
      },
      [],
    );

  const applyFilters = () => {
    const keysToOmit = getRemovedFilters();

    const filtersToApply = $.reduce(
      localFilters,
      (acc, filterValue, filterKey) => {
        if (checkFilterValue(filterValue) && $.get(addedFilters, filterKey)) {
          const newFilterKey = filterKey + $.get(filterOperators, filterKey, "");
          const filterOperatorKeys = $.get(FILTER_OPERATOR_KEYS, filterKey);

          // remove the previously applied filter comparator if it is not the same one being applied again
          if (!$.isEmpty(filterOperatorKeys) && !$.has(compactGlobalFilters, newFilterKey)) {
            const [preAppliedFilter] = $.intersection(Object.keys(compactGlobalFilters), filterOperatorKeys);
            if (preAppliedFilter) keysToOmit.push(preAppliedFilter);
          }

          return { ...acc, [newFilterKey]: filterValue };
        }
        return acc;
      },
      {},
    );

    if (!$.isEmpty(filtersToApply) || !$.isEmpty(keysToOmit)) {
      setGlobalFilters({ ...$.omit(compactGlobalFilters, keysToOmit), ...filtersToApply, page: 1, page_number: 1 });
      toggleDrawer();
    }
  };

  return (
    <>
      <div className="w-100 d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center my-2 p-3">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <i className="bx bx-sm bx-x cursor-pointer" onClick={toggleDrawer} />
            <span className="font-size-20 ml-2">Filters</span>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <PillBtn
              disabled={!$.some(addedFilters, (filter) => filter)}
              className={`px-3 ${!$.some(addedFilters, (filter) => filter) ? "cursor-not-allowed" : ""}`}
              color="primary"
              name="Reset"
              outline={true}
              onClick={resetFilters}
            />
            <PillBtn
              disabled={!canApplyFilters}
              className={`px-3 ${!canApplyFilters ? "cursor-not-allowed" : ""}`}
              color="primary"
              name="Apply"
              onClick={applyFilters}
            />
          </div>
        </div>

        <RenderIf isTrue={canApplyFilters && showAlert}>
          <Alert color="info" className="mx-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center gap-1">
                <i className="bx bx-info-circle" />
                <span>
                  Please click the <b>Apply Filters</b> button to view your desired changes!
                </span>
              </div>
              <i className="bx bx-xs bx-x cursor-pointer" onClick={() => setShowAlert(false)} />
            </div>
          </Alert>
        </RenderIf>

        <hr className="m-0" />
      </div>

      <div className="w-100 d-flex flex-column p-3 overflow-auto">
        <FilterContext.Provider
          value={{
            dropDownValues,
            localFilters,
            setLocalFilters,
            filterOperators,
            setFilterOperators,
            addedFilters,
            setAddedFilters,
            expandedFilters,
            setExpandedFilters,
            setFilter,
            removeFilter,
            setOperator,
            addFilter,
          }}
        >
          {$.map(filtersMetaData, (filterTitle, filterKey) => {
            const Component = $.get(FILTERS_TYPES, filterKey);
            return <Component key={`__${filterKey}Filter__`} filterKey={filterKey} filterTitle={filterTitle} />;
          })}
        </FilterContext.Provider>
      </div>
    </>
  );
}
