import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { uniqBy, snakeCase, reduce, omit, merge, startCase, size } from "lodash";
import Select from "react-select";
import { DATE_FORMATS, USER_ROLES } from "../../constants";
import "flatpickr/dist/themes/dark.css";
import { Button, Row, Col, Alert, Label, FormGroup } from "reactstrap";
import Flatpickr from "react-flatpickr";

// components
import { getUserInfo, RenderIf, getWarehouseName } from "../../utils/common";
import ReportDownloadMsg from "./ReportDownloadMsg";
import Modal from "./Modal";

// actions
import {
  setWHLabelReportModal,
  setPreloader,
  downloadWHLabelReport,
  fetchAllWarehouses,
  filterAllTenants,
} from "../../store/actions";

const { DATE_PICKR } = DATE_FORMATS;

export const WHLabelReportModal = (props) => {
  const fpStartDateRef = useRef(null);
  const fpEndDateRef = useRef(null);
  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;
  const { wareHouses, associatedWarehouses } = props.WareHouses;

  const fetchAccounts = () =>
    uniqBy(
      wareHouses?.data?.reduce((arr, { AccountWarehouse }) => {
        arr.push(...AccountWarehouse);
        return arr;
      }, []),
      "id",
    );

  const fetchWarehouses = (accountId) =>
    wareHouses?.data?.filter(({ AccountWarehouse }) =>
      AccountWarehouse?.find(({ account_warehouses: accWh }) => accWh?.account_id === accountId),
    );

  useEffect(() => {
    props.setPreloader(props.WareHouses.loading);
  }, [props.WareHouses.loading]);

  useEffect(() => {
    const userInfo = getUserInfo();
    if (!size(wareHouses?.data)) {
      userInfo?.account_id &&
        userInfo?.role !== USER_ROLES.readOnly &&
        props.fetchAllWarehouses(merge(!isAdmin && { associated: true }));
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      warehouseId: { label: "All Warehouses", value: "" },
      accountId: { label: "All Accounts", value: "" },
      startDate: "",
      endDate: "",
    },

    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const timeFilter = ["startDate", "endDate"];
      const params = reduce(
        omit(values, timeFilter),
        (obj, { value }, key) => merge(obj, value && { [snakeCase(key)]: value }),
        {},
      );
      !isAdmin && (params.account_id = getUserInfo().account_id);
      timeFilter.forEach((key) => values[key] && (params[snakeCase(key)] = values[key]));
      props.downloadWHLabelReport(params);
    },
  });

  const toggleModal = () => {
    props.setWHLabelReportModal(!props.WareHouses.whLabelReportModalIsOpen);
  };

  return (
    <Modal size="md" isOpen={props.WareHouses?.whLabelReportModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Download WH Label Report</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {props.WareHouses.error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {props.WareHouses.error}
          </Alert>
        )}
        <RenderIf isTrue={props.WareHouses.success}>
          <ReportDownloadMsg action={setWHLabelReportModal} />
        </RenderIf>
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Col className="my-1">
            <FormGroup className="select2-container w-100">
              <RenderIf isTrue={isAdmin}>
                <Label>Select Account</Label>
                <Select
                  name="accountId"
                  value={formik.values.accountId}
                  onChange={(e) => {
                    formik.setFieldValue("accountId", e);
                    formik.setFieldValue("warehouseId", "");
                  }}
                  options={fetchAccounts()?.reduce(
                    (arr, tenant) => {
                      arr.push({ value: tenant.id, label: tenant.email, key: tenant.id });
                      return arr;
                    },
                    [{ label: "All Accounts", value: "" }],
                  )}
                  classNamePrefix="select2-selection"
                  height={{ maxHeight: "250px" }}
                />
              </RenderIf>
              <Label className="mt-3">Select Warehouse</Label>
              <Select
                name="warehouseId"
                value={formik.values.warehouseId}
                onChange={(e) => formik.setFieldValue("warehouseId", e)}
                options={(isAdmin
                  ? fetchWarehouses(formik.values?.accountId?.value)
                  : associatedWarehouses?.data
                )?.reduce(
                  (arr, wh) => {
                    arr.push({ value: wh.id, label: getWarehouseName(wh), key: wh.id });
                    return arr;
                  },
                  [{ label: "All Warehouses", value: "" }],
                )}
                classNamePrefix="select2-selection"
                height={{ maxHeight: "150px" }}
              />
              <Label className="pt-3">Select Date Range</Label>
              <div className={"d-flex hide-arrows align-items-center"}>
                {[
                  { name: "startDate", margin_type: "l", key: "start", ref: fpStartDateRef },
                  { name: "endDate", margin_type: "r", key: "end", ref: fpEndDateRef },
                ].map((x) => (
                  <React.Fragment key={x.name}>
                    <Flatpickr
                      ref={x.ref}
                      key={x.name}
                      className={`${x.margin_type} form-control`}
                      placeholder={startCase(x.name)}
                      value={formik.values[x.name]}
                      options={merge(
                        {
                          mode: "single",
                          dateFormat: DATE_PICKR,
                        },
                        x.name === "startDate" && { maxDate: formik.values.endDate || "today" },
                        x.name === "endDate" && { minDate: formik.values.startDate, maxDate: "today" },
                      )}
                      onChange={(e) => {
                        if (e.length) formik.setFieldValue(x.name, e[0]);
                      }}
                    />
                    <i
                      key={`${x.key}`}
                      title="Reset date filter"
                      className="bx bx-sm mdi mdi-close pt-1 text-danger cursor-pointer"
                      onClick={() => {
                        formik.setFieldValue(x.name, null);
                        if (x.ref.current.flatpickr) x.ref.current.flatpickr.clear();
                      }}
                    />
                  </React.Fragment>
                ))}
              </div>
              {formik.errors.date ? <small className="text-danger m-1">{formik.errors.date}</small> : null}
            </FormGroup>
          </Col>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { WareHouses, Settings } = state;
  return { WareHouses, Settings };
};

export default connect(mapStateToProps, {
  setWHLabelReportModal,
  setPreloader,
  downloadWHLabelReport,
  fetchAllWarehouses,
  filterAllTenants,
})(WHLabelReportModal);
