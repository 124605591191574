import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Alert, FormGroup, Label, Card, CardHeader, CardBody, Collapse } from "reactstrap";
import { TRACKING_ITEM_ISSUES, USER_ROLES, WH_NOTICES_TYPES } from "../../../constants";
import { getUserInfo, RenderIf } from "../../../utils/common";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";
import moment from "moment-timezone";
import Select from "react-select";
import { orderBy, startCase } from "lodash";

// actions
import * as actions from "../../../store/actions";
const { setAddTrackingItemNoteModal, addWarehouseNote, updateWareHouseItem } = actions;

const AddWarehouseNoteModal = (props) => {
  const dispatch = useDispatch();
  const isWH = getUserInfo()?.role === USER_ROLES.whStaff;
  const { selectedItem: item } = props;
  const [issue, setIssue] = useState({
    label: item.issue_type || "Select Issue Type",
    value: item.issue_type || "",
  });
  const [isIssueSettings, setIssueSettings] = useState(false);
  const { error, warehouseNoteModalIsOpen, userWarehouses } = useSelector((state) => state.WareHouses);
  const toggleModal = () => {
    dispatch(setAddTrackingItemNoteModal(!warehouseNoteModalIsOpen));
    formik.resetForm();
  };
  const initialValues = {
    note: item.note || "",
  };

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "") errors[key] = "Field is Required";
    });
    return errors;
  };

  useEffect(() => {
    if (!userWarehouses) dispatch(actions.fetchUserWarehouses());
  }, []);

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      dispatch(
        addWarehouseNote({
          body: {
            is_warehouse: !!props.is_warehouse,
            notice_type: props.notice_type,
            note: values.note,
            author: `Warehouse ${props.warehouses[item?.warehouse_id || item?.wh_order?.warehouse_id]?.state ?? ""} ${
              getUserInfo()?.id
            }`,
          },
          id: item.id,
        }),
      );
    },
  });
  const updateItem = (data) => dispatch(updateWareHouseItem({ data, id: item.id }));

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={warehouseNoteModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">{`${isWH ? "ADD/" : ""}VIEW NOTE`}</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          )}
          {props?.notices?.length > 0 && (
            <div className="table-responsive overflow-auto" style={{ maxHeight: "220px" }}>
              <table className="table table-centered  mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Author</th>
                    <th>Added On</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {props.notices &&
                    orderBy(props.notices, "created_at", "desc").map((notice, key) => (
                      <tr key={"_notice_" + key}>
                        <td>
                          <span className="text-body font-weight-bold">{startCase(notice?.author) || ""}</span>
                        </td>
                        <td>
                          {(notice?.created_at && moment(notice?.created_at).format("MMMM Do YYYY, h:mm:ss a")) || ""}
                        </td>
                        <td style={{ maxWidth: "250px", overflowWrap: "break-word" }}>{notice?.note || ""}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          <RenderIf isTrue={isWH}>
            <form className="m-2 mt-3" onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="12 mb-2">
                  <textarea
                    className="form-control"
                    name="note"
                    maxLength="255"
                    placeholder="Note"
                    value={formik.values.note}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <small>Upto 254 Characters</small>
                  {formik.touched.note && formik.errors.note
                    ? (
                    <p className="text-danger">{formik.errors.note}</p>
                      )
                    : null}
                </Col>
              </Row>
            </form>
          </RenderIf>
          <RenderIf isTrue={props.notice_type === WH_NOTICES_TYPES.tracking_items && isWH}>
            <Col lg="12" className="mb-3">
              <Card className="mb-1 advance-option-border">
                <CardHeader>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <h5 className="m-0">
                        <span className="text-primary cursor-pointer">Issue Settings</span>
                      </h5>
                    </div>
                    <i
                      onClick={() => setIssueSettings(!isIssueSettings)}
                      className="bx bx-sm bx-chevron-down cursor-pointer"
                    ></i>
                  </div>
                </CardHeader>
                <Collapse isOpen={isIssueSettings}>
                  <Card>
                    <CardBody>
                      <Label className="mt-2">Select Issue Type</Label>
                      <FormGroup className="select2-container mb-0 ml-2" style={{ minWidth: "40%" }}>
                        <Select
                          name="issue_type"
                          value={issue}
                          onChange={(e) => {
                            if (e.value !== issue.value) {
                              setIssue(e);
                              updateItem({ issue_type: e.value });
                            }
                          }}
                          options={Object.values(TRACKING_ITEM_ISSUES).map((value) => ({
                            label: value,
                            value,
                          }))}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                      {item.issue_type && (
                        <Row className="d-flex justify-content-end mt-3">
                          <Button
                            type="button"
                            color="info"
                            className="mr-3"
                            onClick={() => updateItem({ issue_type: null })}
                          >
                            Resolve Issue
                          </Button>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Collapse>
              </Card>
            </Col>
          </RenderIf>
          <RenderIf isTrue={isWH}>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" onClick={formik.handleSubmit} color="success" className="mr-3">
                Add Note
              </Button>
            </Row>
          </RenderIf>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default AddWarehouseNoteModal;
