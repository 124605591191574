import { UPDATE_ORDER_ATTEMPT, UPDATE_ORDER_ATTEMPT_SUCCESS, API_ERROR } from "./actionTypes";

export const updateOrderAttempt = (id, params) => {
  return {
    type: UPDATE_ORDER_ATTEMPT,
    payload: { id, params },
  };
};

export const updateOrderAttemptSuccess = (params) => {
  return {
    type: UPDATE_ORDER_ATTEMPT_SUCCESS,
    payload: params,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
