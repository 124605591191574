import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, CardHeader, CardBody, Alert } from "reactstrap";
import { setPreloader, fetchAccountOrdersHistory } from "../../store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toPascalCase } from "../../utils/common";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import CustomPagination from "../../components/Common/CustomPagination";
import Flatpickr from "react-flatpickr";
import moment from "moment-timezone";
import "flatpickr/dist/themes/material_blue.css";
import getBrandImageSrc from "../../utils/brandImages";
import { DATE_FORMATS } from "../../constants";
const { DATE_PICKR, DATE_TIME } = DATE_FORMATS;

const AccountOrdersHistory = () => {
  const dispatch = useDispatch();
  const { error, loading, accountOrdersHistory: tasks, paginationData } = useSelector((state) => state.AOScreen);
  const [filters, setFilters] = useState({ page: 1, per_page: 100 });
  const [search, setSearch] = useState("");
  const [dates, setDates] = useState([null, null]);

  const tableHeaders = [
    { title: "Platform", class: "text-center" },
    { title: "Order Link" },
    { title: "Buying Account" },
    { title: "Supplier Order Id" },
    { title: "SKU" },
    { title: "Seller Info" },
    { title: "Order Total" },
    { title: "Order Date" },
    { title: "By AO" },
  ];

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }
  function fetchHistory () {
    const params = { ...filters };
    if (params.start_date) params.utc_offset = moment().format("Z");
    dispatch(fetchAccountOrdersHistory(params));
  }

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    fetchHistory();
  }, [filters]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between m-2">
            <Breadcrumbs title="AO Orders History" />
            <i className="mdi mdi-refresh ml-2 h1 cursor-pointer" onClick={() => fetchHistory()}></i>
          </div>
          {error && (
            <div className="auto-hide">
              <Alert color="danger" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {error || ""}
              </Alert>
            </div>
          )}
          <Card>
            <CardHeader>
              <div className="d-flex align-items-baseline">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!search.trim()) return;
                    setFilters({ ...filters, search: search.trim(), page: 1 });
                  }}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setFilters({ search: "", page: 1 });
                          setSearch("");
                        } else {
                          setSearch(e.target.value);
                        }
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
                <div className="ml-3" style={{ width: "200px" }}>
                  <Flatpickr
                    className="form-control rounded-pill"
                    placeholder="Select Date Range"
                    value={dates}
                    options={{
                      mode: "range",
                      dateFormat: DATE_PICKR,
                      defaultDate: dates,
                    }}
                    onClose={(val) => {
                      if (val.length > 0) {
                        setFilters({
                          ...filters,
                          page: 1,
                          start_date: moment(val[0]).format("YYYY-MM-DD"),
                          end_date: moment(val[1]).format("YYYY-MM-DD"),
                        });
                        setDates(val);
                      }
                    }}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {tasks?.length
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <th className={header.class || ""} key={`table-header ${index}`}>
                              {header.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tasks.map((item, key) => (
                          <tr key={"_listings_" + key} className="mt-3 mb-3">
                            <td className="text-center">
                              {getBrandImageSrc(item.supplier)
                                ? (
                                <img src={getBrandImageSrc(item.supplier)} alt={item.supplier} />
                                  )
                                : (
                                    toPascalCase(item.supplier)
                                  )}
                            </td>
                            <td className="d-flex align-items-center">
                              <a
                                href={`/source_orders_details/${item.store_order_id}/${item.source_order_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="bx bx-link-external ml-2 font-size-20 mt-1"></i>
                              </a>
                            </td>
                            <td>
                              {item.purchasing_account_name}
                              <CopyToClipBoard text={item.purchasing_account_name} />
                            </td>
                            <td>
                              {item.target_order_id
                                ? (
                                <>
                                  {item.target_order_id}
                                  <CopyToClipBoard text={item.target_order_id} />
                                </>
                                  )
                                : null}
                            </td>
                            <td>
                              {item.sku
                                ? (
                                <>
                                  {item.sku}
                                  <CopyToClipBoard text={item.sku} />
                                </>
                                  )
                                : null}
                            </td>
                            <td>
                              {item.seller_name}
                              {item.seller_id ? ` (${item.seller_id})` : null}
                            </td>
                            <td>{item.order_total ? <>${item.order_total}</> : null}</td>
                            <td>{item.created_at ? moment(item.created_at).format(DATE_TIME) : null}</td>
                            <td>
                              <i
                                title={item.is_ao ? "Fulfilled by AO" : "Manually Fulfilled"}
                                className={`bx bx-sm bx-${item.is_ao ? "check-circle" : "block"} text-${
                                  item.is_ao ? "success" : "danger"
                                }`}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <CustomPagination
                    total={paginationData?.count}
                    page={paginationData?.page}
                    perPage={paginationData?.per_page}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                </>
                  )
                : null}
              {tasks?.length === 0 && !loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountOrdersHistory;
