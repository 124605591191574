import {
  FETCH_MATCHES_REQUEST,
  FETCH_MATCHES_REQUEST_SUCCESS,
  FETCH_MATCHES_REQUEST_QUERY,
  FETCH_MATCHES_REQUEST_QUERY_SUCCESS,
  API_ERROR,
  CANCEL_MATCHES_REQ,
  CANCEL_MATCHES_REQ_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  matchesRequests: { data: [] },
};

const MatchesRequests = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MATCHES_REQUEST:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_MATCHES_REQUEST_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        matchesRequests: action.payload,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        success: false,
        loading: false,
      };
      break;
    case FETCH_MATCHES_REQUEST_QUERY:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_MATCHES_REQUEST_QUERY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        matchesRequests: action.payload,
      };
      break;
    case CANCEL_MATCHES_REQ:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        matchesRequests: action.payload,
      };
      break;
    case CANCEL_MATCHES_REQ_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        matchesRequests: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default MatchesRequests;
