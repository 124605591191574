import moment from "moment-timezone";
import { compact, keys, sumBy } from "lodash";
import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import SeeMoreText from "../../../components/Common/SeeMoreText";
import { DATE_FORMATS, STATUS_COLOR_CODE } from "../../../constants";
import { getDate, RenderIf, toPascalCase } from "../../../utils/common";

function ShipmentsTable ({ inboundsData }) {
  const [seeMore, setSeeMore] = useState();
  const [seeMoreAddress, setSeeMoreAddress] = useState();
  const tableHeaders = [
    { title: "Account ID" },
    { title: "Order Details" },
    { title: "Items" },
    { title: "Qty." },
    { title: "Tracking #" },
    { title: "Customer Address" },
    { title: "Type" },
    { title: "Date" },
    { title: "Expected Date" },
    { title: "Warehouse Status" },
    { title: "Tracking Status" },
  ];

  const shipmentStatusMapping = {
    complete: "badge-soft-success",
    incomplete: "badge-soft-danger",
    checked_in: "badge-soft-warning",
  };

  const orderableTypesMapping = {
    tracking_items: "2-Step",
  };

  const statusMapping = (item) => {
    if (item?.status === "incomplete" && item?.tracking_status === "delivered") return "Awaiting check-in";
    if (item?.status === "incomplete" && item?.tracking_status !== "delivered") return "Awaiting Shipment";
    if (item?.status === "complete") return "Moved To Inventory";
  };

  const isShipmentComplete = (item) => item?.status === "complete";
  const getSearchable = (item) =>
    item.line_items?.[0]?.sku || item.line_items?.[0]?.upc || item.line_items?.[0]?.title?.slice(0, 5);

  const orderDetailsMapping = {
    purchase_orders: (item) => (
      <div className="d-flex flex-column pt-3">
        <p>
          PO #: <span className="text-primary pl-1">{item?.wh_order?.order_id}</span>
        </p>
        <RenderIf isTrue={item?.wh_order?.supplier}>
          <p>
            Supplier: <span className="seemore-text">{toPascalCase(item?.wh_order?.supplier)}</span>
          </p>
        </RenderIf>
      </div>
    ),
    returns: (item) => (
      <div className="d-flex flex-column pt-3">
        <p>
          RMA #: <span className="text-primary pl-1">{item?.wh_order?.order_id}</span>
        </p>
        <RenderIf isTrue={item?.wh_order?.supplier}>
          <p>
            Supplier: <span className="seemore-text">{toPascalCase(item?.wh_order?.supplier)}</span>
          </p>
        </RenderIf>
      </div>
    ),
    tracking_items: (item) => (
      <div className="d-flex flex-column pt-3">
        <p>
          PO #: <span className="text-primary pl-1">{item?.wh_order?.order_id}</span>
        </p>
        <RenderIf isTrue={item?.wh_order?.supplier}>
          <p>
            Supplier: <span className="seemore-text">{toPascalCase(item?.wh_order?.supplier)}</span>
          </p>
        </RenderIf>
      </div>
    ),
  };

  const getItemText = (id, key, value, seeMoreOpts, length) => {
    return (
      <SeeMoreText
        id={id}
        value={value}
        length={length || 40}
        {...seeMoreOpts}
        className="text-primary"
        onHoverDisable
      />
    );
  };

  const getText = (text) => text || "N/A";

  const ItemDetail = (item, seeMore, setSeeMore) => (
    <div className="d-flex flex-column pt-3">
      <span className="d-flex">
        Item:{" "}
        <span className="seemore-text">
          {getItemText(item?.id, "Item", compact(item?.line_items?.map((x) => x?.item_title)).join(", ") || "N/A", {
            seeMore,
            setSeeMore,
          })}
        </span>
      </span>
      <span className="d-flex">
        UPC:{" "}
        <span className="seemore-text">
          {getItemText(item?.id, "Item", compact(item?.line_items?.map((x) => x?.upc)).join(", ") || "N/A", {
            seeMore,
            setSeeMore,
          })}
        </span>
      </span>
    </div>
  );

  const fieldsMapping = { "Expected Date": "required_shipping_date" };

  const getTrackingNumber = (trackingNumber) => (/(TEMP-)\w+/.test(trackingNumber) ? "N/A" : trackingNumber);

  return (
    <Card>
      <CardBody>
        <RenderIf isTrue={Array.isArray(inboundsData) && inboundsData?.length}>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  {tableHeaders.map((header, index) => (
                    <React.Fragment key={`table-header ${index}`}>
                      <th key={`table-header ${index}`}>
                        {header.title}
                        <RenderIf isTrue={keys(fieldsMapping).includes(header.title)}>
                          <div className="d-inline"></div>
                        </RenderIf>
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {inboundsData?.map((item, key) => (
                  <React.Fragment key={"_return_row_" + key}>
                    <tr key={"_return_" + key}>
                      <td>{item?.wh_order?.account_id}</td>
                      <td>{orderDetailsMapping?.[item?.wh_order?.orderable_type]?.(item)}</td>
                      <td style={{ minWidth: "398px" }}>{ItemDetail?.(item, seeMore, setSeeMore)}</td>
                      <td>{item?.total_units || sumBy(item?.line_items, "received_shipments.expected_qty")}</td>
                      <td>{getTrackingNumber(item?.tracking_number)}</td>
                      <td style={{ minWidth: "500px" }}>
                        <div className="d-flex flex-column">
                          <span>
                            <i className="bx bx-user pr-2" />
                            <span className="seemore-text">{getText(item?.customer_address?.split(",")[0])}</span>
                          </span>
                          <span className="d-flex">
                            <i className="bx bx-home pr-2" />
                            <span className="seemore-text">
                              {getItemText(
                                item?.id,
                                "Address",
                                item?.customer_address?.split(",").splice(1).join(",").trim() || "N/A",
                                { seeMore: seeMoreAddress, setSeeMore: setSeeMoreAddress },
                                50,
                              )}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td>
                        {toPascalCase(
                          orderableTypesMapping[item?.wh_order?.orderable_type] || item?.wh_order?.orderable_type,
                        )}
                      </td>
                      <td>
                        <span className="d-flex pt-2">
                          Order placed:
                          <p className="seemore-text">
                            <RenderIf isTrue={item?.wh_order?.date} fallback="N/A">
                              {moment(item?.wh_order?.date).format(DATE_FORMATS.DATE)}
                            </RenderIf>
                          </p>
                        </span>
                        <span className="d-flex">
                          Shipment created:
                          <p className="seemore-text">{moment(item?.created_at).format(DATE_FORMATS.DATE)}</p>
                        </span>
                      </td>
                      <td>
                        <span className="d-flex pt-2">
                          Expected Ship Date:
                          <p className="seemore-text">
                            <RenderIf isTrue={item?.wh_order?.required_shipping_date} fallback="N/A">
                              {moment(item?.wh_order?.required_shipping_date).format(DATE_FORMATS.DATE)}
                            </RenderIf>
                          </p>
                        </span>
                        <RenderIf isTrue={item?.wh_order?.orderable_type === "tracking_items"}>
                          <span className="d-flex justify-content-between mb-3">
                            Store Status:
                            <span
                              className={`badge-soft-${
                                STATUS_COLOR_CODE[item?.wh_order?.marketplace_status] || "custom"
                              } badge badge-pill`}
                            >
                              {getText(item?.wh_order?.marketplace_status)}
                            </span>
                          </span>
                        </RenderIf>
                      </td>
                      <td className="text-center">
                        <span
                          className={`${
                            shipmentStatusMapping?.[item?.status] || "badge-soft-custom"
                          } badge badge-pill ${isShipmentComplete(item) ? "cursor-pointer" : ""}`}
                          onClick={() =>
                            !isShipmentComplete(item)
                              ? null
                              : window.open(
                                  `/inventory-items?search=${getSearchable(item)}&account_id=${
                                    item?.wh_order?.account_id
                                  }`,
                                  "blank",
                              )
                          }
                        >
                          {toPascalCase(statusMapping(item) || item?.status) || "N/A"}
                        </span>
                      </td>
                      <td>
                        <div>
                          <RenderIf isTrue={item?.tracking_status_update_date}>
                            <span className="d-flex pt-2">
                              Last Updated:&nbsp;
                              <p className="seemore-text">
                                {getDate(item?.tracking_status_update_date, DATE_FORMATS.FULL_DATE)}
                              </p>
                            </span>
                          </RenderIf>
                          <span className="d-flex align-item-baseline mb-3">
                            Status:&nbsp;
                            <span
                              className={`badge-soft-${
                                STATUS_COLOR_CODE[item?.tracking_status] || "custom"
                              } badge badge-pill`}
                            >
                              {toPascalCase(item?.tracking_status) || "N/A"}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </RenderIf>
        <RenderIf isTrue={!inboundsData?.length}>
          <div className="d-flex justify-content-center m-2">
            <div className="d-flex flex-column">
              <h1>No Records Found</h1>
            </div>
          </div>
        </RenderIf>
      </CardBody>
    </Card>
  );
}

export default ShipmentsTable;
