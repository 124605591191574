import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { useFormik } from "formik";
import {
  uniq,
  keys,
  last,
  startCase,
  merge,
  map,
  isEmpty,
  trim,
  pick,
  capitalize,
  reduce,
  keyBy,
  omit,
  values,
  some,
} from "lodash";
import { cleanedData, getObjectforBulk, is5050Match } from "../Helpers/common";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Row, Button, Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ConfirmationAlert } from "../../../components/Common/ConfirmDialog";
import SweetAlert from "react-bootstrap-sweetalert";
import { MARKET_PLACES, USER_ROLES, BULK_SELECT_TYPES, SHIPPMENT_MAPPING, DATE_FORMATS } from "../../../constants";
import {
  toF,
  toPascalCase,
  validateSupplierUrl,
  is2StepApplicableForSupplier,
  isSelectedRow,
  filteredVariation,
  isActiveAccount,
  getVariation,
  getUserInfo,
  RenderIf,
  checkMarketPlaces as mapURL,
  jsonParse,
  isAdmin,
  getProductURL,
  formatNumber,
  getPlatformSpecificLabel,
  setSupplierURL,
} from "../../../utils/common";

import Select from "react-select";
import moment from "moment-timezone";
import queryString from "query-string";
import Modal from "../../../components/Common/Modal";
import WeightField from "./WeightField";
import UpdateSupplier from "./UpdateSupplier";
import ReactTable from "../../../components/Common/Table";
import CustomTooltip from "./../../../components/Common/CustomTooltip";
import EstShippingFeeField from "./EstShippingFeeField";

// Import Actions
import * as actions from "../../../store/actions";
const {
  fetchSuppliers,
  updateSupplier,
  createSupplier,
  deleteSupplier,
  updateAOStatus,
  setActionSupplier,
  setSelectedListings,
  bulkUpdateHandlingTime,
  setListingSuppliersCollapse,
  setSelectedLiveListing,
  assignShippingTemplate,
} = actions;

const { DATE_TIME } = DATE_FORMATS;

const LiveListing = (props) => {
  const { sku: paramSKU } = useParams();
  const queryParameters = queryString.parse(props.location.search);

  const dispatch = useDispatch();
  const ACTIONS = { add: "add", update: "update" };
  const {
    columns,
    setFilters,
    filters,
    storeFront,
    ListingIds,
    selectAllListings,
    getTableHeaders,
    setBulkSelectType,
    isSingleListing,
  } = props;

  const { users } = useSelector((state) => state.User);
  const { accounts: marketplaceAccs } = useSelector((state) => state.MarketPlaceAccount);
  const { listings, templates, selectedListings, selectedLiveListing } = useSelector((state) => state.Listings);
  const { account } = useSelector((state) => state.Settings);
  const { platforms: platformSuppliers } = useSelector((state) => state.Platform);
  const {
    suppliers,
    mp_fi: isMpFi,
    platforms,
    actionSupplier,
    listingSupplierCollapse,
  } = useSelector((state) => state.Supplier);
  const selectedSupplier = useRef(null);
  const [allStoresObj, setAllStoresObj] = useState({});
  const [handlingTime, setHandlingTime] = useState("");
  const [weightKey, setWeightKey] = useState("");
  const [shippingKey, setShippingKey] = useState("");
  const [handlingTimeTextBox, setHandlingTimeTextBox] = useState("");
  const [sortFilter, setSortFilter] = useState(undefined);
  const [templateDropDown, setTemplateDropDown] = useState("");
  const [isDeleteSupplier, setIsDeleteSupplier] = useState(false);
  const [variationModalOpen, setVariationModalOpen] = useState(false);
  const [restrictedConfirmation, setRestrictedConfirmation] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [supplierActionDropDown, setSupplierActionDropDown] = useState("");
  const [nestedVariation, setNestedVariation] = useState([{ name: "", value: "", error: [] }]);

  const MODAL_HEADER = ["Name", "Value", "Action"];
  const isWholesaler = selectedSupplier?.current?.seller_type === "wholesale";
  const [isWholeSellerSelected, setIsWholeSellerSelected] = useState("");
  const [isWHSupplier, setIsWHSupplier] = useState(false);

  const handleAddVariation = () => {
    const keyValuePairs = { name: "", value: "", error: [] };
    setNestedVariation([...nestedVariation, keyValuePairs]);
  };

  const checkMarketPlaces = (url) => mapURL(platforms, url);

  const handleRemoveVariation = (e, idx) => {
    nestedVariation.splice(idx, 1);
    setNestedVariation([...nestedVariation]);
  };

  const parseVariations = (data) => {
    if (!data?.length) return null;
    return map(data, ({ name, value }) => (
      <div key={`_${name}_`}>{[startCase(name), value?.toLocaleString()].join(": ")}</div>
    ));
  };

  const hasOwnProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj || {}, prop);

  const supplier = {
    quantity_multiplier: 1,
    platform_id: "",
    url: "",
    seller_sku: "",
    manual_price: 0,
    price_lock: false,
    manual_stock: 0,
    manual_shipping_fee: 0,
    stock_lock: false,
    shipping_fee_lock: false,
    ship_to_warehouse: { label: "Direct", value: false },
    variation: "",
    price: 0,
    shipping_fee: 0,
    stock: 0,
  };

  useEffect(() => {
    const { current: currentSup } = selectedSupplier;
    if (currentSup && actionSupplier.update) {
      Object.keys(supplier).forEach((key) => {
        formik.setFieldValue(key, currentSup[key], false);
        setNestedVariation(filteredVariation(currentSup.variation));
        if (key === "ship_to_warehouse") {
          const [label, value] = Object.entries(SHIPPMENT_MAPPING)?.find(([, value]) => value === currentSup[key]);
          formik.setFieldValue("ship_to_warehouse", { label, value }, false);
        }
      });
    }
    if (hasOwnProperty(currentSup, "is_default")) formik.setFieldValue("is_default", currentSup.is_default, false);

    if (!Array.isArray(platforms) || !currentSup || !actionSupplier.update) return;
    const label = platforms?.find((platform) => platform?.id === currentSup.platform_id)?.name;
    formik.setFieldValue("platform_id", { label: startCase(label), value: currentSup.platform_id }, false);
  }, [actionSupplier.update, platforms]);

  useEffect(() => {
    if (Array.isArray(marketplaceAccs?.data) && !keys(allStoresObj).length) {
      setAllStoresObj(marketplaceAccs.data.reduce((obj, acc) => merge(obj, { [acc?.id]: acc }), {}));
    }
  }, [marketplaceAccs]);

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "")
        !["manual_price", "manual_stock", "variation", "url", "seller_sku", "manual_shipping_fee"]?.includes(key) &&
          (errors[key] = "Field is Required");
    });

    if (!values.url && !values.seller_sku) {
      if (!values.url) errors.url = "Url is Required";
      if (!values.seller_sku) errors.seller_sku = "Seller SKU is Required";
      if (!validateSupplierUrl(values.url)) errors.url = "Invalid Url";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: supplier,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (values.platform_id.value) values.platform_id = values.platform_id.value;

      const isSafe = nestedVariation.every((x) => !x.error.length);
      if (!isSafe) return;
      values.variation = cleanedData(nestedVariation);

      values.ship_to_warehouse = is2StepApplicableForSupplier(isMpFi, allStoresObj, storeFront).length
        ? undefined
        : isWholesaler || isWholeSellerSelected === "wholesale"
          ? false
          : values.ship_to_warehouse.value;

      if (values.seller_sku) values.seller_sku = trim(values.seller_sku);

      const isWholesale =
        platforms?.find((x) => x.id === parseInt(formik.values.platform_id))?.seller_type === "wholesale";

      if (selectedSupplier.current && actionSupplier.update) {
        dispatch(
          updateSupplier({
            data: {
              ...values,
              va_id: getUserInfo()?.id,
              platform_id: isWholesale ? values.platform_id : checkMarketPlaces(values.url)?.id || values.platform_id,
              manual_shipping_fee: values.manual_shipping_fee || 0,
            },
            params: {
              id: hasOwnProperty(selectedSupplier.current, "is_default")
                ? selectedSupplier.current?.id
                : selectedSupplier.current.supplier_id,
              platform: storeFront.marketplace,
              item_id: selectedLiveListing?.item_id,
            },
          }),
        );
      } else {
        dispatch(
          createSupplier({
            data: { ...values, va_id: getUserInfo()?.id, manual_shipping_fee: values.manual_shipping_fee || 0 },
            params: merge(
              { partner_id: selectedLiveListing.storeId },
              pick(selectedLiveListing, "item_id", "sku", "platform"),
            ),
          }),
        );
      }
    },
  });

  const SortArrows = ({ arrow }) => (
    <i
      id={arrow + "-filter"}
      style={{ fontSize: "12px" }}
      className={`dripicons-arrow-thin-${arrow} cursor-pointer ${arrow === "down" ? "ml-n3" : ""}`}
    />
  );

  const StatusBadge = (color, text) => (
    <Badge className={`font-size-12 badge-soft-${color}`} color={color} pill>
      {text}
    </Badge>
  );

  function handleSupplier (item, key) {
    if ((!suppliers?.length || selectedLiveListing.key !== key) && !queryParameters?.storeId)
      dispatch(
        fetchSuppliers({
          item_id: item?.item_id,
          platform: storeFront.marketplace,
          sku: item?.sku,
          partner_id: item?.partner_id,
          is_admin: getUserInfo()?.role === USER_ROLES.admin,
        }),
      );
    if (actionSupplier) {
      dispatch(setActionSupplier(""));
      formik.handleReset();
    }
    dispatch(
      setSelectedLiveListing(
        merge({ key, platform: storeFront.marketplace, storeId: item?.partner_id }, pick(item, "sku", "item_id")),
      ),
    );
    dispatch(setListingSuppliersCollapse(!(selectedLiveListing.key === key && listingSupplierCollapse === true)));
  }

  const amountText = (text, format) => (text || text === 0 ? formatNumber(text, format) : "N/A");
  const [isFb, isShopify] = [MARKET_PLACES.facebook, MARKET_PLACES.shopify].map(
    (store) => storeFront.marketplace === store,
  );
  const liveListingHeader = [
    {
      title: "Item",
    },
    {
      title: "Status",
      sort: true,
      hide: isFb,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions: listings?.sorted_directions === "DESC" ? { published: "ASC" } : { published: "DESC" },
          },
        });
        setSortFilter({ col: "Status", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    {
      title: "Listed Price",
      sort: true,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions:
              listings?.sorted_directions === "DESC"
                ? { last_submitted_price: "ASC" }
                : { last_submitted_price: "DESC" },
          },
        });
        setSortFilter({ col: "Listed Price", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    {
      title: "Stock",
      sort: true,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions:
              listings?.sorted_directions === "DESC"
                ? { last_submitted_stock: "ASC" }
                : { last_submitted_stock: "DESC" },
          },
        });
        setSortFilter({ col: "Stock", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    {
      title: "Verified Match",
      sort: true,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions:
              listings?.sorted_directions === "DESC"
                ? { auto_ordering_enabled: "ASC" }
                : { auto_ordering_enabled: "DESC" },
          },
        });
        setSortFilter({ col: "Verified Match", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    {
      title: "Buy Box",
      hide: isFb || isShopify,
    },
    {
      title: "Handling Time",
      sort: true,
      hide: isFb || isShopify,
      for: MARKET_PLACES.amazon,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions: listings?.sorted_directions === "DESC" ? { handling_time: "ASC" } : { handling_time: "DESC" },
          },
        });
        setSortFilter({ col: "Handling Time", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    {
      title: "Shipping Template",
      hide: isFb,
      user: !(
        storeFront.marketplace === MARKET_PLACES.walmart ||
        (storeFront.marketplace === MARKET_PLACES.amazon && getUserInfo()?.account_id === 2)
      ),
    },
    {
      title: "Shipment Type",
      hide: isShopify,
    },
    {
      title: "Listing Type",
    },
    {
      title: "Weight (lbs)",
      hide: !isAdmin() || isFb,
    },
    { title: "Dimensions (in)", hide: !isAdmin() },
    {
      title: "Est.Shipping Fee",
      hide: isShopify,
      sort: true,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions:
              listings?.sorted_directions === "DESC"
                ? { estimated_shipping_fee: "ASC" }
                : { estimated_shipping_fee: "DESC" },
          },
        });
        setSortFilter({ col: "Est.Shipping Fee", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    { title: "Action" },
  ];
  useEffect(() => {
    getTableHeaders(liveListingHeader);
  }, [storeFront.marketplace]);

  useEffect(() => {
    dispatch(setSelectedLiveListing({}));
  }, [storeFront.marketplace, storeFront]);

  useEffect(() => {
    if (
      !keys(selectedLiveListing).length &&
      listings?.results.length &&
      paramSKU &&
      queryParameters?.platform &&
      queryParameters?.storeId
    )
      handleSupplier(listings.results[0], 0);
  }, [listings, selectedListings, paramSKU, queryParameters]);

  const getDimensions = (dimensions) => reduce(dimensions, (output, { value }, key) => merge(output, { [key]: toF(value) }), {});
  const isRefreshableSupplier = (supplier) =>
    keyBy(platformSuppliers?.data, "name")[supplier?.name] || supplier?.name === "warehouse";

  const SupplierUpdationDate = ({ supplier }) => {
    const isRefreshable = isRefreshableSupplier(supplier);
    return (
      <>
        <div>{isRefreshable ? "Refreshed At" : "Updated At"}</div>
        <span className="color-white">
          {(supplier?.[isRefreshable ? "refreshed_at" : "updated_at"] &&
            moment(supplier?.[isRefreshable ? "refreshed_at" : "updated_at"]).format(DATE_TIME)) ||
            "N/A"}
        </span>
      </>
    );
  };

  const Availability = ({ supplier, supKey }) => {
    const isRefreshable = isRefreshableSupplier(supplier);
    return (
      <>
        <div>Availability</div>
        <span id={`__oos_tooltip__${supKey}`} className={`${supplier?.stock > 0 ? "color-white" : "text-danger"}`}>
          {isRefreshableSupplier(supplier) ? (supplier?.stock > 0 ? "In Stock" : "Out of Stock") : "N/A"}
        </span>
        <RenderIf isTrue={supplier?.oos_reason && isRefreshable}>
          <CustomTooltip
            placement="top"
            content={toPascalCase(supplier.oos_reason)}
            target={`__oos_tooltip__${supKey}`}
          />
        </RenderIf>
      </>
    );
  };
  const showCheckboxes = some(values(omit(columns, "Check")), x => !!x);

  return (
    <>
      {isDeleteSupplier && selectedSupplier?.current && (
        <ConfirmationAlert
          onConfirm={() => {
            dispatch(
              deleteSupplier({
                data: { platform: storeFront.marketplace, va_id: getUserInfo()?.id },
                id: selectedSupplier.current?.id,
              }),
            );
            dispatch(actions.resetWHSKUInventory());
          }}
          isOpen={isDeleteSupplier}
          setIsOpen={setIsDeleteSupplier}
          msg={<p>To Delete this Supplier.</p>}
        />
      )}
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(updateAOStatus(restrictedConfirmation));
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {`to ${restrictedConfirmation?.status ? "enable" : "disable"} verified match`}
        </SweetAlert>
      </RenderIf>
      <ReactTable
        tableHeader={
          <>
            <th />
            {listings?.results.length && isActiveAccount(storeFront) && showCheckboxes
              ? (
              <th className="vw-checkbox">
                <div className="custom-control custom-checkbox change-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={ListingIds?.length > 0 && ListingIds?.length === selectedListings?.length}
                    onChange={() => false}
                  />
                  <label
                    className="custom-control-label"
                    onClick={() => {
                      if (ListingIds?.length === selectedListings?.length) {
                        setBulkSelectType(BULK_SELECT_TYPES.current_page);
                        dispatch(setSelectedListings([]));
                      } else selectAllListings();
                    }}
                  >
                    &nbsp;
                  </label>
                </div>
              </th>
                )
              : null}
            {liveListingHeader
              .filter((x) => !["Weight (lbs)", "Dimensions (in)"].includes(x.title) || (isAdmin() && !isFb))
              .map((header, index) =>
                header.for && storeFront.marketplace !== header.for
                  ? null
                  : columns[header.title] &&
                    !header.hide && (
                      <th
                        onClick={header.onClick}
                        key={`table-header ${index}`}
                        className={header.title === "Weight (lbs)" ? "text-center" : ""}
                      >
                        {header.title}
                        {header.sort
                          ? (
                          <div className="d-inline ml-1">
                            {sortFilter && sortFilter.col === header.title
                              ? (
                              <SortArrows title={header.title} arrow={sortFilter.direction} />
                                )
                              : (
                              <>
                                <SortArrows arrow={"up"} />
                                <SortArrows arrow={"down"} />{" "}
                              </>
                                )}
                          </div>
                            )
                          : null}
                      </th>
                  ),
              )}
          </>
        }
        tableBody={
          <>
            {listings?.results?.map((item, key) => (
              <React.Fragment key={"_listings_live_" + key}>
                <tr
                  className={`table-tr ${
                    listingSupplierCollapse && selectedLiveListing.key === key ? "selected-tr" : ""
                  }${isSelectedRow(selectedListings, item) ? " selected-tr-bg-clr" : ""}`}
                >
                  <td
                    className={
                      listingSupplierCollapse && selectedLiveListing.key === key ? "add-border" : "remove-border"
                    }
                  />
                  <RenderIf isTrue={isActiveAccount(storeFront) && showCheckboxes}>
                    <td>
                      <div className="custom-control change-checkbox custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={!!selectedListings?.find((x) => x?.id === item?.id)}
                          onChange={() => false}
                        />
                        <label
                          className="custom-control-label mt-2"
                          onClick={() => {
                            setBulkSelectType(BULK_SELECT_TYPES.current_page);
                            if (selectedListings?.find((x) => x?.id === item?.id)) {
                              dispatch(setSelectedListings(selectedListings?.filter((x) => item?.id !== x?.id)));
                            } else {
                              dispatch(
                                setSelectedListings([
                                  ...selectedListings,
                                  {
                                    id: item?.id,
                                    partner_id: item.partner_id,
                                    sku: item.sku,
                                  },
                                ]),
                              );
                            }
                          }}
                        />
                      </div>
                    </td>
                  </RenderIf>
                  <RenderIf isTrue={columns.Item}>
                    <td>
                      <div className="d-flex">
                        SKU <span className="color-white ml-2">{item?.sku || "N/A"}</span>
                      </div>
                      <div className="d-flex">
                        ITEM ID/ASIN&nbsp;
                        <a
                          href={getProductURL(item, storeFront.marketplace, allStoresObj[item.partner_id])}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary mb-1"
                        >
                          {item.item_id}
                        </a>
                      </div>
                      <RenderIf isTrue={isShopify && (getUserInfo()?.account_id === 2 || getUserInfo()?.role === "admin")}>
                      <div className="d-flex">
                        INVENTORY ITEM ID <span className="color-white ml-2">{item?.inventory_item_id}</span>
                      </div>
                      </RenderIf>

                    </td>
                  </RenderIf>
                  <RenderIf isTrue={columns.Status && !isFb}>
                    <td>
                      {storeFront.marketplace === MARKET_PLACES.walmart
                        ? item?.published
                          ? StatusBadge("success", "Published")
                          : StatusBadge("danger", "Unpublished")
                        : item?.published
                          ? StatusBadge("success", "Active")
                          : StatusBadge("danger", "Inactive")}
                    </td>
                  </RenderIf>
                  <RenderIf isTrue={columns["Listed Price"]}>
                    <td>
                      {item?.last_submitted_price === null || ""
                        ? "$0.00"
                        : formatNumber(item?.last_submitted_price)}
                    </td>
                  </RenderIf>
                  <RenderIf isTrue={columns.Stock}>
                    <td>{item?.last_submitted_stock}</td>
                  </RenderIf>
                  <RenderIf isTrue={columns["Verified Match"]}>
                    <td>
                      <RenderIf
                        isTrue={is5050Match(item)}
                        fallback={
                          <div className="custom-control custom-switch mb-1">
                            <input
                              type="checkbox"
                              name="auto_ordering_enabled"
                              className="custom-control-input"
                              checked={!!item?.auto_ordering_enabled}
                              onChange={() => null}
                            />
                            <label
                              onClick={() => {
                                setRestrictedConfirmation({
                                  platform: storeFront.marketplace,
                                  sku: item?.sku,
                                  status: !item?.auto_ordering_enabled,
                                  partner_id: item?.partner_id,
                                  item_id: item?.item_id,
                                });
                                setIsOpen(true);
                              }}
                              className="custom-control-label cursor-pointer"
                            />
                          </div>
                        }
                      >
                        <div className="custom-control custom-switch mb-1">
                          <input
                            type="checkbox"
                            name="auto_ordering_enabled"
                            className="custom-control-input"
                            checked={!!item?.auto_ordering_enabled}
                            disabled
                            onChange={() => null}
                          />
                          <label className="custom-control-label cursor-not-allowed" />
                        </div>
                      </RenderIf>
                    </td>
                  </RenderIf>
                  <RenderIf isTrue={columns["Buy Box"] && !isFb && !isShopify}>
                    <td>
                      <i
                        className={`bx bx-sm ${
                          item?.won_buybox ? "bx-check-circle text-success" : "bx-block text-danger"
                        }`}
                      ></i>
                    </td>
                  </RenderIf>
                  <RenderIf
                    isTrue={columns["Handling Time"] && storeFront.marketplace === MARKET_PLACES.amazon && !isFb}
                  >
                    <RenderIf
                      isTrue={!isActiveAccount(storeFront)}
                      fallback={
                        <td>
                          {key === handlingTimeTextBox && storeFront.marketplace === MARKET_PLACES.amazon
                            ? (
                            <div className="editableInput d-flex align-items-center">
                              <FormGroup className="mb-0">
                                <input
                                  type="number"
                                  value={handlingTime}
                                  className="form-control"
                                  onChange={(e) => setHandlingTime(e.target.value)}
                                ></input>
                              </FormGroup>
                              {handlingTime.length
                                ? (
                                <div className="iconBox">
                                  <i
                                    className="bx bx-sm bx-check color-primary"
                                    onClick={() => {
                                      const selectedItem = [
                                        { id: item?.id, partner_id: item.partner_id, sku: item.sku },
                                      ];
                                      dispatch(
                                        bulkUpdateHandlingTime(
                                          getObjectforBulk(
                                            storeFront.marketplace,
                                            selectedItem,
                                            ["partner_id", "skus", "handling_time"],
                                            handlingTime,
                                          ),
                                          true,
                                          isSingleListing,
                                        ),
                                      );
                                    }}
                                  />
                                  <i
                                    className="bx bx-sm bx-x danger"
                                    onClick={() => {
                                      setHandlingTimeTextBox("");
                                      setHandlingTime("");
                                    }}
                                  />
                                </div>
                                  )
                                : (
                                    ""
                                  )}
                            </div>
                              )
                            : (
                            <div
                              className="editable-input"
                              onClick={() => {
                                if (handlingTime) setHandlingTime("");
                                setHandlingTimeTextBox(key);
                              }}
                            >
                              {item?.handling_time || "N/A"}
                            </div>
                              )}
                        </td>
                      }
                    >
                      <td>
                        <span>{item?.handling_time || "N/A"}</span>
                      </td>
                    </RenderIf>
                  </RenderIf>
                  {columns["Shipping Template"] &&
                    (storeFront.marketplace === MARKET_PLACES.walmart || getUserInfo()?.account_id === 2) &&
                    !isFb && (
                      <td onClick={() => setTemplateDropDown(key)}>
                        {key === templateDropDown
                          ? (
                          <>
                            <FormGroup className="select2-container mb-0">
                              <Select
                                name="template_id"
                                onChange={(e) => {
                                  dispatch(
                                    assignShippingTemplate({
                                      va_id: getUserInfo()?.id,
                                      partner_id: item?.partner_id,
                                      skus: [item?.sku],
                                      template_id: e.value,
                                    }),
                                  );
                                }}
                                options={
                                  templates &&
                                  templates[item?.partner_id]?.map((acc, i) => ({
                                    label: acc.name || acc.shipping_template_uuid,
                                    value: acc?.id,
                                    key: i,
                                  }))
                                }
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </>
                            )
                          : (
                              templates[item?.partner_id]?.find((x) => x?.id === item?.shipping_template_id)?.name ||
                          templates[item?.partner_id]?.find((x) => x?.id === item?.shipping_template_id)
                            ?.shipping_template_uuid ||
                          "N/A"
                            )}
                      </td>
                  )}
                  <RenderIf isTrue={columns["Shipment Type"] && !isShopify}>
                    <td>{item?.mp_fi ? (storeFront.marketplace === MARKET_PLACES.walmart ? "WFS" : "FBA") : "FBM"}</td>
                  </RenderIf>
                  <RenderIf isTrue={columns["Listing Type"]}>
                    <td>{toPascalCase(item?.listing_source)}</td>
                  </RenderIf>
                  <RenderIf isTrue={columns["Weight (lbs)"] && isAdmin() && !isFb}>
                    <WeightField
                      index={key}
                      amountText={amountText}
                      item={item}
                      weightKey={weightKey}
                      setWeightKey={setWeightKey}
                      storeFront={storeFront}
                    />
                  </RenderIf>
                  <RenderIf isTrue={isAdmin() && columns["Dimensions (in)"]}>
                    <td>
                      <RenderIf isTrue={!isEmpty(item?.dimensions)} fallback={"N/A"}>
                      {Object.entries(getDimensions(item?.dimensions)).map(([prefix, count], _index) => (
                        <div className="d-flex justify-content-left align-items-center" key={"_ao_item_" + _index}>
                          <span style={{ minWidth: "42px" }}>
                          {capitalize(prefix)}</span>
                          <i style={{ width: "25px" }} className="bx bx-sm bx-right-arrow-alt text-primary"></i>
                          <span>{count?.toLocaleString()}</span>
                        </div>))}
                      </RenderIf>
                    </td>
                  </RenderIf>
                  <RenderIf isTrue={columns["Est.Shipping Fee"] && !isShopify}>
                    <RenderIf
                      isTrue={!isActiveAccount(storeFront)}
                      fallback={
                        <EstShippingFeeField
                          index={key}
                          amountText={amountText}
                          item={item}
                          shippingKey={shippingKey}
                          setShippingKey={setShippingKey}
                          storeFront={storeFront}
                        />
                      }
                    >
                      <td>
                        <span>{item?.estimated_shipping_fee || "N/A"}</span>
                      </td>
                    </RenderIf>
                  </RenderIf>
                  <RenderIf isTrue={columns.Action}>
                    <td>
                      <div onClick={() => handleSupplier(item, key)}>
                        {selectedLiveListing.key === key && listingSupplierCollapse
                          ? (
                          <span
                            onClick={(_) => dispatch(actions.resetWHSKUInventory())}
                            className="icon-topRight bx-sm cursor-pointer"
                          >
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                            )
                          : (
                          <span
                            onClick={(_) => dispatch(actions.resetWHSKUInventory())}
                            className="icon-bottomleft bx-sm cursor-pointer"
                          >
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                            )}
                      </div>
                    </td>
                  </RenderIf>
                </tr>
                <RenderIf isTrue={selectedLiveListing.key === key && listingSupplierCollapse}>
                  <>
                    <tr className={listingSupplierCollapse ? "collapse-show table-tr" : "collapse-hide"}>
                      <td colSpan={14}>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <h6 className="mb-0">Suppliers Details</h6>
                          <RenderIf isTrue={isActiveAccount(storeFront)}>
                            <span
                              className="p-2 text-primary cursor-pointer"
                              onClick={() => {
                                dispatch(setActionSupplier({ [ACTIONS.add]: true, [ACTIONS.update]: "" }));
                                setNestedVariation([{ name: "", value: "", error: [] }]);
                              }}
                            >
                              <i className="bx bx-plus" /> Add Supplier
                            </span>
                          </RenderIf>
                        </div>
                      </td>
                    </tr>
                    {actionSupplier.add && (
                      <UpdateSupplier
                        isWholeSellerSelected={isWholeSellerSelected}
                        checkMarketPlaces={checkMarketPlaces}
                        collapse={listingSupplierCollapse}
                        allStoresObj={allStoresObj}
                        storeFront={storeFront}
                        setIsWholeSellerSelected={setIsWholeSellerSelected}
                        variationModalOpen={variationModalOpen}
                        setVariationModalOpen={setVariationModalOpen}
                        setIsWHSupplier={setIsWHSupplier}
                        isWHSupplier={isWHSupplier}
                        platforms={platforms}
                        modalKey={key}
                        formik={formik}
                      />
                    )}
                    <RenderIf
                      isTrue={suppliers?.length}
                      fallback={
                        !actionSupplier[ACTIONS.add] && (
                          <tr className={listingSupplierCollapse ? "collapse-show table-tr" : "collapse-hide"}>
                            <td colSpan={14}>
                              <h4 className="text-center p-2">No Supplier Found</h4>
                            </td>
                          </tr>
                        )
                      }
                    >
                      {map(suppliers, (supplier, supKey) => (
                        <tr
                          key={`_supplier_listings_${supKey}`}
                          className={`${listingSupplierCollapse ? "collapse-show table-tr" : "collapse-hide"}${
                            supplier?.is_default ? " default-supplier" : ""
                          }`}
                        >
                          <td className={supplier?.is_default ? "border-primary" : "border-orange"} />
                          <td colSpan={14} style={{ position: "relative", paddingLeft: 0 }}>
                            <table width="100%">
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="d-flex flex-column mr-4">
                                      Suppliers
                                      <RenderIf
                                        isTrue={supplier?.url && supplier.name !== "plumberstock"}
                                        fallback={
                                          <span className="color-white">
                                            {supplier.name === "plumberstock" ? "PlumbersStock" : toPascalCase(supplier.name)}
                                          </span>
                                        }
                                      >
                                        <span>
                                          <Link
                                            to={{ pathname: setSupplierURL(supplier?.url) }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {toPascalCase(supplier?.name)}
                                          </Link>
                                        </span>
                                      </RenderIf>
                                    </div>
                                  </td>
                                  <RenderIf isTrue={supplier?.seller_type === "wholesale"} fallback={null}>
                                    <td>
                                      <div className="d-flex flex-column">
                                        {getPlatformSpecificLabel(supplier?.name)}
                                        <span className="color-white">{supplier?.seller_sku || "N/A"}</span>
                                      </div>
                                    </td>
                                  </RenderIf>
                                  <td>
                                    <div>
                                      Price{" "}
                                      {supplier?.price_lock ? <i className="text-primary bx bx-lock mr-1" /> : null}
                                    </div>
                                    <span className="color-white">
                                      {formatNumber(
                                        supplier?.price_lock ? supplier?.manual_price : supplier?.price,
                                        "currency",
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <div>
                                      Stock{" "}
                                      {supplier?.stock_lock ? <i className="text-primary bx bx-lock mr-1" /> : null}
                                    </div>
                                    <span className="color-white">
                                      {supplier?.stock_lock ? supplier?.manual_stock : supplier?.stock}
                                    </span>
                                  </td>
                                  <td>
                                    <div>
                                      Shipping($){" "}
                                      {supplier?.shipping_fee_lock_tooltip
                                        ? (
                                        <i
                                          id={`__shipping__fee__tooltip__${supKey}`}
                                          className="text-primary bx bx-lock mr-1"
                                        />
                                          )
                                        : null}
                                    </div>
                                    {supplier?.shipping_fee_lock_tooltip
                                      ? (
                                      <CustomTooltip
                                        placement="top"
                                        content={supplier?.shipping_fee_lock_tooltip}
                                        target={`__shipping__fee__tooltip__${supKey}`}
                                      />
                                        )
                                      : null}
                                     <span className="color-white">
                                      {formatNumber(supplier?.shipping_fee)}
                                    </span>
                                  </td>
                                  <td>
                                    <Availability supplier={supplier} supKey={supKey} />
                                  </td>
                                  <td>
                                    <div>Qty.Mul</div>
                                    <span className="color-white">{supplier?.quantity_multiplier}</span>
                                  </td>
                                  <td>
                                    <div>Est. Tax</div>
                                    <span className="color-white">
                                      {supplier?.tax ? formatNumber(supplier?.tax) : "$0.00"}
                                    </span>
                                  </td>
                                  <td>
                                    {getVariation(supplier?.variation) && (
                                      <CustomTooltip
                                        placement="top"
                                        content={parseVariations(jsonParse(supplier?.variation))}
                                        target={`__variations__${supKey}`}
                                      />
                                    )}
                                    <div>Variation</div>
                                    <div className="color-white variation-ellipsis" id={`__variations__${supKey}`}>
                                      {getVariation(supplier?.variation) || "N/A"}
                                    </div>
                                  </td>
                                  <td>
                                    <SupplierUpdationDate supplier={supplier} />
                                  </td>
                                  <td>
                                    <div>Added By</div>
                                    <span className="color-white">
                                      {supplier?.added_by
                                        ? (
                                            toPascalCase(users?.data?.find((x) => x?.id === supplier?.added_by)?.username)
                                          )
                                        : (
                                        <span className="color-white">N/A</span>
                                          )}
                                    </span>
                                  </td>
                                  {account?.data?.two_step_feature &&
                                  !allStoresObj[storeFront.value]?.two_step_enabled
                                    ? (
                                    <td>
                                      <div>Ship. Type</div>
                                      <span className="color-white">{toPascalCase(supplier?.ship_type || "N/A")}</span>
                                    </td>
                                      )
                                    : null}
                                  <RenderIf isTrue={isActiveAccount(storeFront)}>
                                    <td>
                                      <ButtonDropdown
                                        isOpen={supplierActionDropDown === supplier?.id}
                                        toggle={() =>
                                          setSupplierActionDropDown(
                                            supplierActionDropDown === supplier?.id ? "" : supplier?.id,
                                          )
                                        }
                                      >
                                        <DropdownToggle
                                          tag="i"
                                          className="bx bx-sm bx-dots-vertical-rounded text-primary cursor-pointer"
                                        />
                                        <DropdownMenu>
                                          <DropdownItem
                                            onClick={() => {
                                              selectedSupplier.current = supplier;
                                              dispatch(
                                                setActionSupplier({
                                                  [ACTIONS.add]: false,
                                                  [ACTIONS.update]: supplier?.id,
                                                }),
                                              );
                                            }}
                                          >
                                            <div className="align-items-center d-flex">
                                              <i className="bx bx-sm bx-edit text-warning cursor-pointer" />
                                              <div className="ml-2">Edit</div>
                                            </div>
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => {
                                              selectedSupplier.current = supplier;
                                              setIsDeleteSupplier(!isDeleteSupplier);
                                            }}
                                          >
                                            <div className="align-items-center d-flex">
                                              <i className="bx bx-sm bx-trash text-danger cursor-pointer" />
                                              <div className="ml-2">Delete</div>
                                            </div>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </ButtonDropdown>
                                    </td>
                                  </RenderIf>
                                </tr>
                                {actionSupplier.update === supplier?.id && (
                                  <UpdateSupplier
                                    setIsWHSupplier={setIsWHSupplier}
                                    isWholeSellerSelected={isWholeSellerSelected}
                                    checkMarketPlaces={checkMarketPlaces}
                                    collapse={listingSupplierCollapse}
                                    allStoresObj={allStoresObj}
                                    storeFront={storeFront}
                                    supplier={supplier}
                                    setIsWholeSellerSelected={setIsWholeSellerSelected}
                                    variationModalOpen={variationModalOpen}
                                    setVariationModalOpen={setVariationModalOpen}
                                    platforms={platforms}
                                    modalKey={key}
                                    formik={formik}
                                  />
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      ))}
                    </RenderIf>
                  </>
                </RenderIf>
              </React.Fragment>
            ))}
          </>
        }
      />

      <Modal size="md" isOpen={variationModalOpen}>
        <div className="modal-header">
          <h5 className="modal-title">Variations</h5>
          <button
            onClick={() => setVariationModalOpen(!variationModalOpen)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <p>Please enter any attributes which are needed to identify this item.</p>
          <ReactTable
            tableHeader={
              <>
                {MODAL_HEADER.map((index, key) => (
                  <th key={`__modal_header_live_listings__${key}`}>{index}</th>
                ))}
              </>
            }
            tableBody={
              <>
                {nestedVariation?.map((item, idx) => (
                  <React.Fragment key={"_dynamic_fields_supplier_variation_" + idx}>
                    <tr id={"_nested_variation_supplier_" + idx} key={"_dynamic_fields_supplier_variation_" + idx}>
                      <td className="pl-0 pb-0 border-0">
                        <input
                          type="text"
                          placeholder={"Variation Name"}
                          className="form-control"
                          value={item.name}
                          onChange={(e) => {
                            !e.target.value && item.value
                              ? !item.error.some((x) => x?.includes("Name")) &&
                                item.error.push("Variation Name should not be empty.")
                              : (item.error = item.error?.filter((x) => !x?.includes("Name")));

                            e.target.value &&
                            nestedVariation.some((x) => x.name.toLowerCase() === e.target.value.toLowerCase())
                              ? item.error.push("Duplicate Variation Name.")
                              : (item.error = item.error?.filter((x) => !x?.includes("Duplicate")));

                            e.target.value && !/.*\S.*/.test(e.target.value)
                              ? item.error.push("Spaces are not allowed.")
                              : (item.error = item.error?.filter((x) => !x?.includes("Spaces")));
                            nestedVariation[idx].name = e.target.value;
                            setNestedVariation([...nestedVariation]);
                          }}
                        />
                      </td>
                      <td className="pl-0 pb-0 border-0">
                        <input
                          type="text"
                          className="form-control"
                          disabled={item?.error?.includes("Duplicate Variation Name.")}
                          placeholder={"Variation Value"}
                          value={item.value}
                          onChange={(e) => {
                            item.name && !e.target.value
                              ? !item?.error?.some((x) => x?.includes("Value")) &&
                                item.error.push("Variation Value should not be empty")
                              : (item.error = item.error?.filter((x) => !x?.includes("Value")));

                            e.target.value && !/.*\S.*/.test(e.target.value)
                              ? item.error.push("Spaces are not allowed.")
                              : (item.error = item.error?.filter((x) => !x?.includes("Spaces")));

                            !item.name && e.target.value
                              ? !item?.error?.some((x) => x?.includes("Name")) &&
                                item.error.push("Variation Name should not be empty")
                              : (item.error = item.error?.filter((x) => !x?.includes("Name")));
                            nestedVariation[idx].value = e.target.value;
                            setNestedVariation([...nestedVariation]);
                          }}
                        />
                      </td>
                      <td className="text-align-center pb-0 border-0">
                        <i
                          onClick={(e) => {
                            handleRemoveVariation(e, idx);
                          }}
                          className="bx bx-sm bx-x-circle danger cursor-pointer"
                        />
                      </td>
                    </tr>
                    <tr>
                      {item?.error?.length
                        ? (
                        <td className="p-0 border-0">
                          <small className="text-danger">{uniq(item.error).map((x) => `${x}${" "}`)}</small>
                        </td>
                          )
                        : null}
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <td className="pl-0 pb-0 border-0">
                    <Button
                      disabled={
                        nestedVariation?.some((x) => x?.error?.length) ||
                        (nestedVariation?.length > 0 && (!last(nestedVariation)?.name || !last(nestedVariation)?.value))
                      }
                      onClick={() => handleAddVariation()}
                      color="success"
                      className="mt-2"
                    >
                      Add Variation
                    </Button>
                  </td>
                </tr>
              </>
            }
          />
        </div>
        <div className="modal-footer">
          <Row className="d-flex justify-content-end mt-3">
            <Button type="reset" color="secondary" className="mr-3" onClick={() => setVariationModalOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={
                nestedVariation?.some((x) => x.error?.length) ||
                (nestedVariation?.length > 0 && (!last(nestedVariation)?.name || !last(nestedVariation)?.value))
              }
              onClick={() => {
                if (nestedVariation?.every((x) => x.error?.length === 0)) setVariationModalOpen(!variationModalOpen);
              }}
              color="primary"
              className="mr-3"
            >
              Save
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(LiveListing);
