import { map, filter } from "lodash";
import { getUserInfo } from "../../../utils/common";

const getObjectforBulk = (platform, listings, skuFields, status) => ({
  platform,
  ...(skuFields.includes("verified_status") && { verified_status: status }),
  ...(skuFields.includes("handling_time") && { handling_time: status || null }),
  va_id: getUserInfo().id,
  skus_data: listings.reduce((acc, listing) => {
    const index = acc.findIndex((x) => x.partner_id === listing.partner_id);
    if (index !== -1) {
      skuFields.includes("skus") && acc[index].skus.push(listing.sku);
    } else {
      acc.push({
        ...(skuFields.includes("partner_id") && {
          partner_id: listing.partner_id,
        }),
        ...(skuFields.includes("skus") && { skus: [listing.sku] }),
        ...(skuFields.includes("status") && { status }),
      });
    }
    return acc;
  }, []),
});

const is5050Match = (item) => item?.listing_source === "shared_listings";
const getFixedProfit = (account, item) =>
  (+item.price * +item.quantity_multiplier + (+item.shipping_fee || 0) + (+item.tax || 0) + account.fix_profit) /
  (1 - account.marketplace_commission_percentage * 0.01);
const getPercentageProfit = (account, item) =>
  ((+item.price * +item.quantity_multiplier + (+item.shipping_fee || 0) + (+item.tax || 0)) *
    (1 + account.profit_percentage * 0.01)) /
  (1 - account.marketplace_commission_percentage * 0.01);
const cleanedData = (nestedVariation) =>
  JSON.stringify(
    nestedVariation.reduce((acc, x) => {
      if (x.name) {
        delete x.error;
        acc.push(x);
      }
      return acc;
    }, []),
  );

const calculateMinListedPrice = (account, item) => {
  const fixedProfit = getFixedProfit(account, item);
  const percentageProfit = getPercentageProfit(account, item);

  if (account.type === "fixed_profit") return fixedProfit.toFixed(2);
  else if (account.type === "percentage_profit") return percentageProfit.toFixed(2);
  else return Math.max(fixedProfit, percentageProfit).toFixed(2);
};

const isDisableBulkAction = (selected, listings) => {
  const retiredSKUs = map(
    filter(listings, (x) => x.listing_status === "retired"),
    (y) => y.sku,
  );
  const selectedSKUs = map(selected, (x) => x.sku);
  return !selectedSKUs.some((sku) => retiredSKUs.includes(sku));
};

const getSingleListingProps = (isSingleListing, supListingsPage, pageType) =>
  isSingleListing && supListingsPage === pageType ? { isSingleListing: true } : {};

export {
  getObjectforBulk,
  is5050Match,
  calculateMinListedPrice,
  getFixedProfit,
  getPercentageProfit,
  cleanedData,
  isDisableBulkAction,
  getSingleListingProps,
};
