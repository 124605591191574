import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Alert, CardBody, Card } from "reactstrap";
import { setPreloader, fetchInvoiceDetail } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SeeMoreText from "../../../components/Common/SeeMoreText";
import { amountText } from "../../../utils/common";
import { isEmpty, map, startCase } from "lodash";
import moment from "moment";
import { DATE_FORMATS } from "../../../constants";

const StorageInvoice = (_) => {
  const dispatch = useDispatch();
  const { invoiceId } = useParams() || {};
  const [seeMore, setSeeMore] = useState("");
  const { invoiceDetail, loading, chargesLoading, success, error } = useSelector((state) => state.WareHouses);

  useEffect(() => {
    dispatch(setPreloader(loading || chargesLoading));
  }, [loading, chargesLoading]);

  useEffect(() => {
    if (invoiceId) dispatch(fetchInvoiceDetail(invoiceId));
  }, []);

  const Text = ({ heading, val }) => (
    <div className="d-flex">
      <span>{startCase(heading.replace("customer_", ""))}&nbsp;</span>
      <span className="inventory-color">{amountText(val)}</span>
    </div>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        {success && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}
        <Container fluid>
          <Breadcrumbs title="Invoice Detail" />
          {!isEmpty(invoiceDetail)
            ? (
            <Card>
              <CardBody>
                <h4 className="text-center">WH Storage Invoice</h4>
                <div className="d-flex flex-column">
                  <Text val={moment(invoiceDetail.created_at).format(DATE_FORMATS.FULL_DATE)} heading="Created" />
                  <Text val={invoiceDetail.total} heading="Total" />
                </div>
                <h5 className="mb-3 mt-2">Invoice Summary</h5>
                <div className="table-responsive" style={{ minHeight: "170px", maxHeight: "900px" }}>
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {[
                          "No.",
                          "Item",
                          "SKU",
                          "Tracking Number",
                          "Qty",
                          "No of Weeks Charged",
                          "Charged Per Week",
                          "Total",
                        ].map((title, key) => (
                          <th key={"___header___" + key}>
                            <b>{title}</b>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {map(invoiceDetail.wh_invoice_items, (invoiceItem, key) => (
                        <tr key={"___invoice_item___" + key}>
                          <td>{key + 1}</td>
                          <td>
                            <SeeMoreText
                              id={key}
                              value={invoiceItem.metadata?.item}
                              length={70}
                              seeMore={seeMore}
                              setSeeMore={setSeeMore}
                              onHoverDisable={true}
                            />
                          </td>
                          <td>{invoiceItem.metadata?.sku}</td>
                          <td>{invoiceItem.metadata?.tracking_number}</td>
                          <td>{invoiceItem.qty_charged}</td>
                          <td>{invoiceItem.weeks_charged}</td>
                          <td>{amountText(invoiceItem.unit_amount)}</td>
                          <td>{amountText(invoiceItem.total_charged)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
              )
            : (
            <h2 className="d-flex justify-content-center">No Record Found</h2>
              )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StorageInvoice;
