import React, { useEffect } from "react";
import { Button, Row, Col, Alert, Label, Input } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Common/Modal";

// actions
import { keys } from "lodash";
import { RenderIf } from "../../../utils/common";
import { resetSuccessErr, deleteInventoryItem } from "../../../store/actions";

const DeleteInventoryModal = (props) => {
  const { error, success, loading } = useSelector((state) => state.WareHouses);
  const dispatch = useDispatch();
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);
  const validate = (values) => {
    const errors = {};
    keys(values).forEach(
      (key) => [undefined, ""].some((x) => values[key] === x) && (errors[key] = "Field is Required"),
    );

    return errors;
  };

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        dispatch(resetSuccessErr());
        toggleModal();
      }
    }, 1000);
  }, [success]);

  useEffect(() => {
    if (success || error) dispatch(resetSuccessErr());
  }, []);

  const formik = useFormik({
    initialValues: {
      tracking_number: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      dispatch(deleteInventoryItem(values.tracking_number));
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Delete Warehouse Inventory</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <RenderIf isTrue={error}>
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </RenderIf>
          <RenderIf isTrue={success}>
            <Alert color="success">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </RenderIf>
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mb-2 hide-arrows">
                <Label>Tracking Number</Label>
                <Input
                  name="tracking_number"
                  type="text"
                  value={formik.values.tracking_number}
                  onChange={(e) => formik.handleChange(e)}
                />
                <RenderIf isTrue={formik.touched.tracking_number && formik.errors.tracking_number}>
                  <small className="text-danger mt-1">{formik.errors.tracking_number}</small>
                </RenderIf>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3" disabled={loading}>
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteInventoryModal;
