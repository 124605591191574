import { API_ERROR, FETCH_ALL_AO_STATS, FETCH_ALL_AO_STATS_SUCCESS } from "./actionTypes";

export const fetchAllAOStats = (params) => ({
  type: FETCH_ALL_AO_STATS,
  payload: params,
});

export const fetchAllAOStatsSuccess = (res) => ({
  type: FETCH_ALL_AO_STATS_SUCCESS,
  payload: res,
});

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
