import * as types from "./actionTypes";

export const sendErrorEmail = (payload) => ({ type: types.SEND_ERROR_EMAIL, payload });

export const sendErrorEmailSuccess = (data) => ({ type: types.SEND_ERROR_EMAIL_SUCCESS, payload: data });

export const resetSuccessState = () => ({ type: types.RESET_SUCCESS });

export const fetchMarketplaceAccounts = (params, openModal) => ({
  type: types.FETCH_MARKETPLACE_ACCOUNTS,
  payload: { params, openModal },
});

export const fetchMarketplaceAccountsSuccess = (data) => ({
  type: types.FETCH_MARKETPLACE_ACCOUNTS_SUCCESS,
  payload: data,
});

export const setIsAmz = (bool) => ({ type: types.SET_AMZ_ONBOARDING_MODAL, payload: bool });

export const setIsShopify = (bool) => ({ type: types.SET_SHOPIFY_ONBOARDING_MODAL, payload: bool });

export const setFormData = (data) => ({
  type: types.SET_AMZ_ONBOARDING_FORM_DATA,
  payload: data,
});

export const fetchSpApiRefreshToken = (data) => ({
  type: types.FETCH_SP_API_REFRESH_TOKEN,
  payload: data,
});

export const fetchSpApiRefreshTokenSuccess = (data) => ({
  type: types.FETCH_SP_API_REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const fetchShopifyRefreshToken = (data) => ({
  type: types.FETCH_SHOPIFY_REFRESH_TOKEN,
  payload: data,
});

export const fetchShopifyRefreshTokenSuccess = (data) => ({
  type: types.FETCH_SHOPIFY_REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const setRefreshToken = (data) => ({
  type: types.SET_REFRESH_TOKEN,
  payload: data,
});

export const setShopifyRefreshToken = (data) => ({
  type: types.SET_SHOPIFY_REFRESH_TOKEN,
  payload: data,
});

export const setSPAPIErr = (data) => ({ type: types.SET_SP_API_ERR, payload: data });

export const changeStoreState = (payload) => ({ type: types.CHANGE_STORE_STATE, payload });

export const checkStore = (payload) => ({ type: types.CHECK_STORE, payload });

export const checkStoreSuccess = (payload) => ({ type: types.CHECK_STORE_SUCCESS, payload });

export const fetchUserMarketplaceAccount = (params) => ({
  type: types.FETCH_USER_MARKETPLACE_ACCOUNT,
  payload: { params },
});

export const fetchUserMarketplaceAccountSuccess = (data) => ({
  type: types.FETCH_USER_MARKETPLACE_ACCOUNT_SUCCESS,
  payload: data,
});

export const setTwoStepPopUp = (bool) => ({
  type: types.SET_TWO_STEP_MODAL,
  payload: bool,
});
export const updateMarketplaceAccounts = (data) => ({
  type: types.UPDATE_MARKETPLACE_ACCOUNT,
  payload: data,
});

export const updateMarketplaceAccountsSuccess = (data) => ({
  type: types.UPDATE_MARKETPLACE_ACCOUNT_SUCCESS,
  payload: data,
});

export const updateRepricer = (data) => ({
  type: types.UPDATE_REPRICER,
  payload: data,
});

export const updateRepricerSuccess = (data) => ({
  type: types.UPDATE_REPRICER_SUCCESS,
  payload: data,
});

export const deleteMarketPlaceAccount = (id) => ({
  type: types.DELETE_MARKETPLACE_ACCOUNT,
  payload: { id },
});

export const deleteMarketPlaceAccountSuccess = () => ({
  type: types.DELETE_MARKETPLACE_ACCOUNT_SUCCESS,
  payload: {},
});

export const setEditModalMarketplaceAccount = (bool) => ({
  type: types.SET_EDIT_MODAL_MARKETPLACE_ACCOUNT,
  payload: bool,
});

export const setAddModalMarketplaceAccount = (bool) => ({
  type: types.SET_ADD_MODAL_MARKETPLACE_ACCOUNT,
  payload: bool,
});

export const setSelectedMarketplaceAccount = (account) => ({
  type: types.SET_SELECTED_MARKETPLACE_ACCOUNT,
  payload: account,
});

export const createMarketplaceAccount = (data, params = {}) => ({
  type: types.CREATE_MARKETPLACE_ACCOUNTS,
  payload: { data, params },
});

export const createMarketplaceAccountSuccess = (account) => ({
  type: types.CREATE_MARKETPLACE_ACCOUNTS_SUCCESS,
  payload: account,
});

export const setSettingsModalMarketplaceAccount = (bool) => ({
  type: types.SET_SETTINGS_MODAL_MARKETPLACE_ACCOUNT,
  payload: bool,
});

export const updateVacationMode = (data) => ({
  type: types.UPDATE_VACATION_MODE,
  payload: data,
});

export const setRepricerIntegrationModal = (bool) => ({
  type: types.SET_REPRICER_INTEGRATION_MODAL,
  payload: bool,
});

export const checkStoreName = (payload) => ({ type: types.CHECK_STORE_NAME, payload });

export const checkStoreNameSuccess = (payload) => ({ type: types.CHECK_STORE_NAME_SUCCESS, payload });

export const resetStoreName = () => ({ type: types.RESET_STORE_NAME, payload: {} });

export const resetMarketplaceErr = () => ({ type: types.RESET_MARKETPLACE_ACC_ERR, payload: {} });

export const apiError = (error) => ({
  type: types.API_ERROR,
  payload: error,
});
