import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  fetchPaymentSources,
  deletePaymentSource,
  updatePaymentSource,
  getCustomerHistory,
  whRetryCharges,
} from "../../api";
// Login Redux States
import {
  GET_PAYMENT_SOURCES,
  DELETE_SOURCE,
  UPDATE_PAYMENT_SOURCE,
  GET_CUSTOMER_HISTORY,
  RETRY_WH_CHARGES,
} from "./actionTypes";
import {
  apiError,
  getPaymentSourcesSuccess,
  retryWhChargesSuccess,
  deleteSourceSuccess,
  updatePaymentSourceSuccess,
  getCustomerHistorySuccess,
} from "./actions";

const StripeStore = (state) => state.Stripe;

function * getPaymentSourcesSaga () {
  try {
    const res = yield call(fetchPaymentSources);
    yield put(getPaymentSourcesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * deletePaymentSourceSaga ({ payload: { token } }) {
  try {
    const res = yield call(deletePaymentSource, { token });
    if (res.success) {
      const stripe = yield select(StripeStore);
      stripe.sources = (stripe.sources || []).filter((x) => x.id !== res.deleted.id);
    }
    yield put(deleteSourceSuccess());
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updatePaymentSourceSaga ({ payload: { token } }) {
  try {
    const res = yield call(updatePaymentSource, { token });
    if (res.success) {
      const stripe = yield select(StripeStore);
      stripe.sources = res.sources;
    }
    yield put(updatePaymentSourceSuccess());
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * getCustomerHistorySaga ({ payload: params }) {
  try {
    const res = yield call(getCustomerHistory, params);
    if (res.success) {
      const stripe = yield select(StripeStore);
      yield put(
        getCustomerHistorySuccess({
          ...res,
          addData: stripe.chargeHistory.length && !(params.startDate || params.endDate),
        }),
      );
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * watchRetryWHChargesSaga ({ payload: { params, displaySuccess } }) {
  try {
    const res = yield call(whRetryCharges, params);
    yield put(retryWhChargesSuccess(res, displaySuccess));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchPaymentSourcesFetch () {
  yield takeEvery(GET_PAYMENT_SOURCES, getPaymentSourcesSaga);
}

export function * watchPaymentSourcesDelete () {
  yield takeEvery(DELETE_SOURCE, deletePaymentSourceSaga);
}

export function * watchUpdatePaymentSource () {
  yield takeEvery(UPDATE_PAYMENT_SOURCE, updatePaymentSourceSaga);
}

export function * watchRetryWHCharges () {
  yield takeEvery(RETRY_WH_CHARGES, watchRetryWHChargesSaga);
}

export function * watchGetCustomerHistory () {
  yield takeEvery(GET_CUSTOMER_HISTORY, getCustomerHistorySaga);
}

function * Stripe () {
  yield all([
    fork(watchPaymentSourcesFetch),
    fork(watchPaymentSourcesDelete),
    fork(watchRetryWHCharges),
    fork(watchUpdatePaymentSource),
    fork(watchGetCustomerHistory),
  ]);
}

export default Stripe;
