import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomPagination from "../../components/Common/CustomPagination";
import PillBtn from "../../components/Common/PillBtn";
import { fetchFreeCredits, setPreloader } from "../../store/actions";
import moment from "moment-timezone";
import { DATE_FORMATS } from "../../constants/index";
import { downloadFile } from "../../utils/common";
import { pick } from "lodash";
import { DatePicker } from "../../components/Common/DatePickr";

const calculateTotal = (arr, col = "last_used_credits") => arr.reduce((sum, item) => sum + item[col], 0);

function FreeCredits () {
  const tableHeaders = ["Email", "Credit Type", "Last Used Credits", "Reward Credits", "Reward Time"];
  const [searchStr, setSearchStr] = useState("");
  const [filters, setFilters] = useState({ page: 1, per_page: 100 });

  const dispatch = useDispatch();
  const { creditsHistory, loading } = useSelector((state) => state.FreeCredits);

  useEffect(() => {
    dispatch(fetchFreeCredits(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);
  function filterResults (arr) {
    return searchStr ? arr.filter((row) => row.email && row.email.toLowerCase().includes(searchStr)) : arr;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Free Credits History"
            children={
              <PillBtn
                color="primary"
                title="Export to CSV"
                name="Export to CSV"
                icon="bx bx-xs bx-cloud-download ml-1"
                onClick={() => {
                  const allData = creditsHistory?.data.map((x) =>
                    pick(x, ["email", "last_used_credits", "last_rewarded"]),
                  );
                  allData.push({
                    email: "Total",
                    last_used_credits: calculateTotal(creditsHistory?.data),
                    last_rewarded: "",
                  });
                  downloadFile(allData, "_credits_invoice");
                }}
              />
            }
          />
          <Card>
            <CardHeader>
              <div className="row d-flex justify-content-start">
                <form className="app-search d-none d-md-block ml-3">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => setSearchStr(e.target.value.toLowerCase())}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
                <div className="d-flex align-items-center ml-3" style={{ width: "250px" }}>
                  <DatePicker onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })} />
                </div>
                {Array.isArray(creditsHistory?.data)
                  ? (
                  <div className="d-flex align-items-center ml-3">
                    <h5>Total Used Credits: {calculateTotal(creditsHistory?.data)?.toLocaleString()}</h5>
                    <h5 className="ml-5 mr-3">
                      Total Reward Credits: {calculateTotal(creditsHistory?.data, "credits")?.toLocaleString()}
                    </h5>
                  </div>
                    )
                  : null}
              </div>
            </CardHeader>
            <CardBody>
              {Array.isArray(creditsHistory?.data) && creditsHistory.total
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((title, key) => (
                            <th key={key}>{title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(creditsHistory?.data)
                          ? filterResults(creditsHistory.data).map((item, key) => (
                              <tr key={"_history_" + key}>
                                <td>{item.email}</td>
                                <td>{item.credit_type}</td>
                                <td>{item.last_used_credits.toLocaleString()}</td>
                                <td>{item.credits.toLocaleString()}</td>
                                <td>{moment(item?.last_rewarded).format(DATE_FORMATS.FULL_DATE) || ""}</td>
                              </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <CustomPagination
                    total={creditsHistory.total}
                    pageOptions={[20, 50, 100]}
                    page={creditsHistory.page}
                    perPage={creditsHistory.perPage}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                </>
                  )
                : null}
              {creditsHistory?.total === 0 && !loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default FreeCredits;
