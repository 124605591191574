import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  getAllGraphs,
  getAllTenantGraphs,
  getOrdersGraph,
  getAllTenantOrdersGraph,
  getSalesGraph,
  getAllTenantSalesGraph,
  getProfitGraph,
  getAllTenantProfitGraph,
  getSharedProfitGraph,
  getSharedOrderStatus,
  getExpensesGraph,
  getAllTenantExpensesGraph,
  getDailyReportData,
  getSalesRevenueData,
  getReturnsGraph,
  getAllTenantReturnsGraph,
  getUsedCreditsGraph,
  getAllTenantUsedCreditsGraph,
  getIssueStats,
  getListingStats,
  getBuyBoxStats,
  getErrorStats,
  getTopSellingItems,
  getGlobalTopSellingItems,
  getLeastSellingItems,
  getClientStats,
  getFeesDetails,
  getBestSellersStats,
  getProductsByCategory,
  getProductsBySales,
} from "../../api";
// Login Redux States

import * as actionTypes from "./actionTypes";

import {
  apiError,
  fetchAllGraphsSuccess,
  fetchAllTenantGraphsSuccess,
  fetchOrderGraphSuccess,
  fetchAllTenantOrderGraphSuccess,
  fetchSalesGraphSuccess,
  fetchAllTenantSalesGraphSuccess,
  fetchProfitGraphSuccess,
  fetchAllTenantProfitGraphSuccess,
  fetchSharedProfitGraphSuccess,
  fetchSharedOrderStatusSuccess,
  fetchDailyReportSuccess,
  fetchSalesRevenueStatsSuccess,
  fetchExpensesGraphSuccess,
  fetchAllTenantExpensesGraphSuccess,
  fetchReturnsGraphSuccess,
  fetchAllTenantReturnsGraphSuccess,
  fetchUsedCreditsGraphSuccess,
  fetchAllTenantUsedCreditsGraphSuccess,
  fetchIssueDetailsSuccess,
  fetchListingStatsSuccess,
  fetchBuyBoxStatsSuccess,
  fetchErrorStatsSuccess,
  fetchTopSellingItemsSuccess,
  fetchGlobalTopSellingItemsSuccess,
  fetchLeastSellingItemsSuccess,
  fetchClientStatsSuccess,
  fetchFeesDetailsSuccess,
  fetchBestSellersDataSuccess,
  fetchProductsbySalesSuccess,
  fetchProductsbyCategorySuccess,
} from "./actions";

const {
  FETCH_ALL_GRAPHS,
  FETCH_ALL_TENANT_GRAPHS,
  FETCH_ORDER_GRAPH_DETAILS,
  FETCH_ALL_TENANT_ORDER_GRAPH,
  FETCH_SALES_GRAPH_DETAILS,
  FETCH_ALL_TENANT_SALES_GRAPH,
  FETCH_PROFIT_GRAPH_DETAILS,
  FETCH_ALL_TENANT_PROFIT_GRAPH,
  FETCH_SHARED_PROFIT_GRAPH_DETAILS,
  FETCH_SHARED_ORDER_STATUS,
  FETCH_DAILY_REPORT,
  FETCH_SALES_REVENUE_STATS,
  FETCH_EXPENSES_GRAPH_DETAILS,
  FETCH_ALL_TENANT_EXPENSE_GRAPH,
  FETCH_RETURNS_GRAPH_DETAILS,
  FETCH_ALL_TENANT_RETURNS_GRAPH,
  FETCH_USED_CREDITS_DETAILS,
  FETCH_ALL_TENANT_USED_CREDITS_DETAILS,
  FETCH_ISSUE_DETAILS,
  FETCH_LISTING_DETAILS,
  FETCH_BUYBOX_DETAILS,
  FETCH_TOP_SELLING_ITEMS,
  FETCH_GLOBAL_TOP_SELLING_ITEMS,
  FETCH_ERROR_STATS,
  FETCH_LEAST_SELLING_ITEMS,
  FETCH_CLIENTS_STATS,
  FETCH_FEES_DETAILS,
  FETCH_BEST_SELLERS_DATA,
} = actionTypes;

function * fetchLeastSellingItemsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getLeastSellingItems, params);
    yield put(fetchLeastSellingItemsSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}
function * fetchAllGraphsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllGraphs, params);
    yield put(fetchAllGraphsSuccess(res));
  } catch (error) {
    yield put(fetchAllGraphsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchFeesDetailsSaga ({ payload: params }) {
  try {
    const res = yield call(getFeesDetails, params);
    yield put(fetchFeesDetailsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchAllTenantGraphsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllTenantGraphs, params);
    yield put(fetchAllTenantGraphsSuccess(res));
  } catch (error) {
    yield put(fetchAllTenantGraphsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchOrdersGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getOrdersGraph, params);
    yield put(fetchOrderGraphSuccess(res));
  } catch (error) {
    yield put(fetchOrderGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchAllTenantOrdersGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllTenantOrdersGraph, params);
    yield put(fetchAllTenantOrderGraphSuccess(res));
  } catch (error) {
    yield put(fetchAllTenantOrderGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchClientStatsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getClientStats, params);
    yield put(fetchClientStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

function * fetchSalesGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getSalesGraph, params);
    yield put(fetchSalesGraphSuccess(res));
  } catch (error) {
    yield put(fetchSalesGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchAllTenantSalesGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllTenantSalesGraph, params);
    yield put(fetchAllTenantSalesGraphSuccess(res));
  } catch (error) {
    yield put(fetchAllTenantSalesGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchProductsbySalesSaga ({ payload: params }) {
  try {
    const res = yield call(getProductsBySales, params);
    yield put(fetchProductsbySalesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchProductsbyCategorySaga ({ payload: params }) {
  try {
    const res = yield call(getProductsByCategory, params);
    yield put(fetchProductsbyCategorySuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchExpensesGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getExpensesGraph, params);
    yield put(fetchExpensesGraphSuccess(res));
  } catch (error) {
    yield put(fetchExpensesGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchAllTenantExpensesGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllTenantExpensesGraph, params);
    yield put(fetchAllTenantExpensesGraphSuccess(res));
  } catch (error) {
    yield put(fetchAllTenantExpensesGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchProfitGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getProfitGraph, params);
    yield put(fetchProfitGraphSuccess(res));
  } catch (error) {
    yield put(fetchProfitGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchAllTenantProfitGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllTenantProfitGraph, params);
    yield put(fetchAllTenantProfitGraphSuccess(res));
  } catch (error) {
    yield put(fetchAllTenantProfitGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchSharedProfitGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getSharedProfitGraph, params);
    yield put(fetchSharedProfitGraphSuccess(res));
  } catch (error) {
    yield put(fetchSharedProfitGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchDailyReportSaga ({ payload: { params } }) {
  try {
    const res = yield call(getDailyReportData, params);
    yield put(fetchDailyReportSuccess(res));
  } catch (error) {
    yield put(fetchDailyReportSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchSalesRevenueStatsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getSalesRevenueData, params);
    yield put(fetchSalesRevenueStatsSuccess(res));
  } catch (error) {
    yield put(fetchSalesRevenueStatsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchSharedOrderStatusSaga ({ payload: { params } }) {
  try {
    const res = yield call(getSharedOrderStatus, params);
    yield put(fetchSharedOrderStatusSuccess(res));
  } catch (error) {
    yield put(fetchSharedOrderStatusSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchReturnsGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getReturnsGraph, params);
    yield put(fetchReturnsGraphSuccess(res));
  } catch (error) {
    yield put(fetchReturnsGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchAllTenantReturnsGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllTenantReturnsGraph, params);
    yield put(fetchAllTenantReturnsGraphSuccess(res));
  } catch (error) {
    yield put(fetchAllTenantReturnsGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchUsedCreditsGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getUsedCreditsGraph, params);
    yield put(fetchUsedCreditsGraphSuccess(res));
  } catch (error) {
    yield put(fetchUsedCreditsGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchAllTenantUsedCreditsGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllTenantUsedCreditsGraph, params);
    yield put(fetchAllTenantUsedCreditsGraphSuccess(res));
  } catch (error) {
    yield put(fetchAllTenantUsedCreditsGraphSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchIssueDetails ({ payload: { params } }) {
  try {
    const res = yield call(getIssueStats, params);
    yield put(fetchIssueDetailsSuccess(res));
  } catch (error) {
    yield put(fetchIssueDetailsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchListingDetails ({ payload: { params } }) {
  try {
    const res = yield call(getListingStats, params);
    yield put(fetchListingStatsSuccess(res));
  } catch (error) {
    yield put(fetchListingStatsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchBuyBoxDetails ({ payload: { params } }) {
  try {
    const res = yield call(getBuyBoxStats, params);
    yield put(fetchBuyBoxStatsSuccess(res));
  } catch (error) {
    yield put(fetchBuyBoxStatsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchErrorDetails ({ payload: { params } }) {
  try {
    const res = yield call(getErrorStats, params);
    yield put(fetchErrorStatsSuccess(res));
  } catch (error) {
    yield put(fetchErrorStatsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchTopSellingItemsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getTopSellingItems, params);
    if (res.success && !res?.category) {
      res.current_pricing.forEach(
        (item) => res.skus[item.sku] && (res.skus[item.sku].current_pricing = item.last_submitted_price),
      );
    }
    yield put(fetchTopSellingItemsSuccess(res));
  } catch (error) {
    yield put(fetchTopSellingItemsSuccess({}));
    yield put(apiError(error));
  }
}

function * fetchBestSellersDataSaga ({ payload: params }) {
  try {
    const res = yield call(getBestSellersStats, params);
    yield put(fetchBestSellersDataSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchGlobalTopSellingItemsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getGlobalTopSellingItems, params);
    yield put(fetchGlobalTopSellingItemsSuccess(res));
  } catch (error) {
    yield put(fetchGlobalTopSellingItemsSuccess({}));
    yield put(apiError(error));
  }
}

export function * watchAllGraphsSaga () {
  yield takeEvery(FETCH_ALL_GRAPHS, fetchAllGraphsSaga);
}

export function * watchFeesDetails () {
  yield takeEvery(FETCH_FEES_DETAILS, fetchFeesDetailsSaga);
}

export function * watchfetchClientStatsSaga () {
  yield takeEvery(FETCH_CLIENTS_STATS, fetchClientStatsSaga);
}

export function * watchAllTenantGraphsSaga () {
  yield takeEvery(FETCH_ALL_TENANT_GRAPHS, fetchAllTenantGraphsSaga);
}

export function * watchOrdersGraphSaga () {
  yield takeEvery(FETCH_ORDER_GRAPH_DETAILS, fetchOrdersGraphSaga);
}

export function * watchAllTenantOrdersGraphSaga () {
  yield takeEvery(FETCH_ALL_TENANT_ORDER_GRAPH, fetchAllTenantOrdersGraphSaga);
}

export function * watchSalesGraphSaga () {
  yield takeEvery(FETCH_SALES_GRAPH_DETAILS, fetchSalesGraphSaga);
}

export function * watchAllTenantSalesGraphSaga () {
  yield takeEvery(FETCH_ALL_TENANT_SALES_GRAPH, fetchAllTenantSalesGraphSaga);
}

export function * watchExpensesGraphSaga () {
  yield takeEvery(FETCH_EXPENSES_GRAPH_DETAILS, fetchExpensesGraphSaga);
}

export function * watchProductsbySales () {
  yield takeEvery(actionTypes.FETCH_PRODUCTS_BY_SALES, fetchProductsbySalesSaga);
}

export function * watchProductsbyCategory () {
  yield takeEvery(actionTypes.FETCH_PRODUCTS_BY_CATEGORY, fetchProductsbyCategorySaga);
}

export function * watchAllTenantExpensesGraphSaga () {
  yield takeEvery(FETCH_ALL_TENANT_EXPENSE_GRAPH, fetchAllTenantExpensesGraphSaga);
}

export function * watchProfitGraphSaga () {
  yield takeEvery(FETCH_PROFIT_GRAPH_DETAILS, fetchProfitGraphSaga);
}

export function * watchAllTenantProfitGraphSaga () {
  yield takeEvery(FETCH_ALL_TENANT_PROFIT_GRAPH, fetchAllTenantProfitGraphSaga);
}

export function * watchSharedProfitGraphSaga () {
  yield takeEvery(FETCH_SHARED_PROFIT_GRAPH_DETAILS, fetchSharedProfitGraphSaga);
}

export function * watchDailReportSaga () {
  yield takeEvery(FETCH_DAILY_REPORT, fetchDailyReportSaga);
}

export function * watchSalesRevenueStatsSaga () {
  yield takeEvery(FETCH_SALES_REVENUE_STATS, fetchSalesRevenueStatsSaga);
}

export function * watchSharedOrderStatusSaga () {
  yield takeEvery(FETCH_SHARED_ORDER_STATUS, fetchSharedOrderStatusSaga);
}

export function * watchReturnsGraphSaga () {
  yield takeEvery(FETCH_RETURNS_GRAPH_DETAILS, fetchReturnsGraphSaga);
}

export function * watchAllTenantReturnsGraphSaga () {
  yield takeEvery(FETCH_ALL_TENANT_RETURNS_GRAPH, fetchAllTenantReturnsGraphSaga);
}

export function * watchUsedCreditsGraphSaga () {
  yield takeEvery(FETCH_USED_CREDITS_DETAILS, fetchUsedCreditsGraphSaga);
}

export function * watchAllTenantUsedCreditsGraphSaga () {
  yield takeEvery(FETCH_ALL_TENANT_USED_CREDITS_DETAILS, fetchAllTenantUsedCreditsGraphSaga);
}

export function * watchIssueDetails () {
  yield takeEvery(FETCH_ISSUE_DETAILS, fetchIssueDetails);
}

export function * watchListingDetails () {
  yield takeEvery(FETCH_LISTING_DETAILS, fetchListingDetails);
}

export function * watchBuyBoxDetails () {
  yield takeEvery(FETCH_BUYBOX_DETAILS, fetchBuyBoxDetails);
}

export function * watchErrorDetails () {
  yield takeEvery(FETCH_ERROR_STATS, fetchErrorDetails);
}

export function * watchTopSellingItemsSaga () {
  yield takeEvery(FETCH_TOP_SELLING_ITEMS, fetchTopSellingItemsSaga);
}

export function * watchBestSellersDataOrders () {
  yield takeEvery(FETCH_BEST_SELLERS_DATA, fetchBestSellersDataSaga);
}

export function * watchGlobalTopSellingItemsSaga () {
  yield takeEvery(FETCH_GLOBAL_TOP_SELLING_ITEMS, fetchGlobalTopSellingItemsSaga);
}

export function * watchLeastSellingItemsSaga () {
  yield takeEvery(FETCH_LEAST_SELLING_ITEMS, fetchLeastSellingItemsSaga);
}
function * graphSaga () {
  yield all([
    fork(watchfetchClientStatsSaga),
    fork(watchFeesDetails),
    fork(watchAllGraphsSaga),
    fork(watchAllTenantGraphsSaga),
    fork(watchOrdersGraphSaga),
    fork(watchAllTenantOrdersGraphSaga),
    fork(watchSalesGraphSaga),
    fork(watchAllTenantSalesGraphSaga),
    fork(watchExpensesGraphSaga),
    fork(watchProductsbySales),
    fork(watchProductsbyCategory),
    fork(watchAllTenantExpensesGraphSaga),
    fork(watchProfitGraphSaga),
    fork(watchAllTenantProfitGraphSaga),
    fork(watchSharedProfitGraphSaga),
    fork(watchDailReportSaga),
    fork(watchSalesRevenueStatsSaga),
    fork(watchSharedOrderStatusSaga),
    fork(watchReturnsGraphSaga),
    fork(watchAllTenantReturnsGraphSaga),
    fork(watchUsedCreditsGraphSaga),
    fork(watchAllTenantUsedCreditsGraphSaga),
    fork(watchIssueDetails),
    fork(watchListingDetails),
    fork(watchBuyBoxDetails),
    fork(watchErrorDetails),
    fork(watchTopSellingItemsSaga),
    fork(watchBestSellersDataOrders),
    fork(watchGlobalTopSellingItemsSaga),
    fork(watchLeastSellingItemsSaga),
  ]);
}

export default graphSaga;
