import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import { findDefault } from "../../utils/profitCalculator";
import queryString from "query-string";
import {
  createSupplier,
  getAllSuppliers,
  updateAOStatusOnMathing,
  getAOStatus,
  updateSupplier,
  deleteSupplier,
  updateStock,
  createOrderLog,
  setSkuAsMismatched,
  fetchRefreshersStats,
} from "../../api";
// Login Redux States
import {
  CREATE_SUPPLIER,
  FETCH_SUPPLIERS,
  UPDATE_AO_STATUS,
  DELETE_SUPPLIER,
  FETCH_AO_STATUS,
  UPDATE_SUPPLIER,
  UPDATE_STOCK,
  SET_SKU_AS_MISMATCH,
  FETCH_REFRESHERS,
} from "./actionTypes";
import {
  createSupplierSuccess,
  fetchSuppliersSuccess,
  updateAOStatusSuccess,
  deleteSupplierSuccess,
  fetchAOStatusSuccess,
  updateSupplierSuccess,
  updateStockSuccess,
  setSkuAsMismatchSuccess,
  fetchListingsSuccess,
  setActionSupplier,
  updateSearchedListing,
} from "../actions";
import { apiError, fetchRefreshersSuccess } from "./actions";
import { merge } from "lodash";
import { MARKET_PLACES } from "../../constants";

const Supplier = (state) => state.Supplier;
const Listings = (state) => state.Listings;
const SourceOrders = (state) => state.SourceOrders;

function * createSupplierSaga ({ payload: { data, params } }) {
  try {
    const res = yield call(createSupplier, { data, params });
    if (res?.supplier) {
      const supplier = yield select(Supplier);
      supplier.suppliers = [...supplier.suppliers, res?.supplier];
      yield put(createSupplierSuccess());
      yield put(setActionSupplier(""));
    }
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * fetchSuppliersSaga ({ payload }) {
  try {
    const { href, search } = window.location;
    const parsed = queryString.parse(search) || {};
    const overrideListing = parsed.storeId && href?.includes("/suppliers");
    const params = overrideListing
      ? {
          show_listing: true,
          partner_id: parsed.storeId,
          platform: parsed.platform,
        }
      : {};
    const res = yield call(getAllSuppliers, merge(payload, params));
    yield put(
      fetchSuppliersSuccess({
        mp_fi: res.mp_fi,
        listing: res.listing,
        platforms: res.platforms || [],
        suppliers: res.suppliers,
        listings_pages: res.listings_pages,
        listings_page: res.listings_page,
        resolvable_error_types: res.resolvable_error_types,
      }),
    );
    if (overrideListing) yield put(updateSearchedListing(res.listing));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * updateAOSaga ({ payload: { data, id } }) {
  try {
    yield call(updateAOStatusOnMathing, data);
    const isShopify = data?.platform === MARKET_PLACES.shopify;
    const logBody = {
      data: {
        source_order_id: id,
        note: `${isShopify ? "ItemId" : "SKU"}: ${isShopify ? data.item_id : data.sku} AO Turned: ${
          data.status ? "On" : "Off"
        }`,
      },
    };
    const key = isShopify ? "item_id" : "sku";
    const changePredicate = (row) => {
      if (row[key] !== data[key]) return false;
      row.auto_ordering_enabled = data.status;
      return true;
    };
    if (id) {
      const orderLog = yield call(createOrderLog, logBody);
      if (orderLog?.data) {
        const { sourceOrderDetail, sourceOrders } = yield select(SourceOrders);
        const supplier = findDefault(sourceOrders?.skuSupplier?.[data?.[key]], data?.partner_id);
        supplier && (supplier.auto_ordering_enabled = data?.status);
        sourceOrderDetail?.data?.order_logs?.push(orderLog.data);
      }
      const { aoStatuses } = yield select(Supplier);
      if (aoStatuses?.find(changePredicate)) yield put(fetchAOStatusSuccess(aoStatuses));
    } else {
      const { listings } = yield select(Listings);
      if (listings.results?.find(changePredicate)) yield put(fetchListingsSuccess(listings));
    }
    yield put(updateAOStatusSuccess());
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * fetchAOSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAOStatus, { params });
    yield put(fetchAOStatusSuccess(res.results));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * updateSupplierSaga ({ payload: { data, params } }) {
  try {
    const res = yield call(updateSupplier, { data, params });
    if (res?.supplier) {
      const supplier = yield select(Supplier);
      const index = supplier.suppliers.findIndex((x) => x.id === res.supplier.id);
      supplier.suppliers[index] = res.supplier;
      if (res?.supplier.is_default)
        supplier.suppliers.forEach((sup) => sup.id !== res?.supplier.id && (sup.is_default = false));
      yield put(updateSupplierSuccess());
      yield put(setActionSupplier(""));
    }
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * updateStockSaga ({ payload: { data } }) {
  try {
    yield call(updateStock, { data });
    yield put(updateStockSuccess());
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * deleteSupplierSaga ({ payload }) {
  try {
    const { supplier } = yield call(deleteSupplier, payload);
    if (supplier) {
      const supplierStore = yield select(Supplier);
      supplierStore.suppliers = supplierStore.suppliers.filter((sup) => sup.id !== supplier.id);
    }
    yield put(deleteSupplierSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * setSkuAsMismatchSaga ({ payload: { data, id } }) {
  try {
    yield call(setSkuAsMismatched, { data, id });
    if (id) {
      yield call(createOrderLog, {
        data: {
          source_order_id: id,
          created_by: "SYSTEM",
          note: `SKU: ${data.sku} set as mismatch.`,
        },
      });
    }
    yield put(setSkuAsMismatchSuccess());
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * fetchRefreshersSaga ({ payload: data }) {
  try {
    const res = yield call(fetchRefreshersStats, data);
    yield put(fetchRefreshersSuccess(res));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

export function * watchCreateSupplier () {
  yield takeEvery(CREATE_SUPPLIER, createSupplierSaga);
}

export function * watchFetchSupplier () {
  yield takeEvery(FETCH_SUPPLIERS, fetchSuppliersSaga);
}

export function * watchUpdateAO () {
  yield takeEvery(UPDATE_AO_STATUS, updateAOSaga);
}

export function * watchFetchAO () {
  yield takeEvery(FETCH_AO_STATUS, fetchAOSaga);
}

export function * watchUpdateSupplier () {
  yield takeEvery(UPDATE_SUPPLIER, updateSupplierSaga);
}

export function * watchStockUpdate () {
  yield takeEvery(UPDATE_STOCK, updateStockSaga);
}

export function * watchDeleteSupplier () {
  yield takeEvery(DELETE_SUPPLIER, deleteSupplierSaga);
}

export function * watchSkuMismatchUpdate () {
  yield takeEvery(SET_SKU_AS_MISMATCH, setSkuAsMismatchSaga);
}

export function * watchRefresherStats () {
  yield takeEvery(FETCH_REFRESHERS, fetchRefreshersSaga);
}

function * SuppliersSaga () {
  yield all([
    fork(watchCreateSupplier),
    fork(watchFetchSupplier),
    fork(watchUpdateAO),
    fork(watchFetchAO),
    fork(watchUpdateSupplier),
    fork(watchStockUpdate),
    fork(watchDeleteSupplier),
    fork(watchSkuMismatchUpdate),
    fork(watchRefresherStats),
  ]);
}

export default SuppliersSaga;
