import { compact, forEach, sumBy, uniqueId, values as binValues, omit, keys, isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FieldArray, getIn, Field } from "formik";
import { Alert, Col, Container, Input, Label, Row, UncontrolledPopover } from "reactstrap";
import { object, array, string, number, addMethod } from "yup";

import Modal from "../../../components/Common/Modal";
import PillBtn from "../../../components/Common/PillBtn";
import ReactTable from "../../../components/Common/Table";
import { RenderIf, toPascalCase } from "../../../utils/common";
import { receivedShipment, setReceivedShipmentModel } from "../../../store/actions";
import VerticalLine from "../../../components/Common/VerticalLine";
import { SHIPMENT_LINE_STATUSES, MARKETPLACE_STATUSES, ORDERABLE_TYPES, STATUS_COLOR_CODE } from "../../../constants";
import SeeMoreText from "../../../components/Common/SeeMoreText";
import CheckBox from "../../../components/Common/CheckBox";

addMethod(array, "unique", function (message, propertyName, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    const set = [...new Set(compact(list.map(mapper)))];
    if (compact(list.map(mapper)).length !== set.length) {
      const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
      throw this.createError({
        path: `${this.path}.${idx}.${propertyName}`,
        message,
      });
    } else return true;
  });
});

const getText = (text) => text || "N/A";

const getTrackingNumber = (trackingNumber) => (/(TEMP-)\w+/.test(trackingNumber) ? "N/A" : trackingNumber);

const getItemText = (id, key, value, seeMoreOpts, length) => (
  <SeeMoreText id={id} value={value} length={length || 40} {...seeMoreOpts} className="text-primary" onHoverDisable />
);

const InfoLine = ({ length }) => {
  return (
    <span className="font-italic">
      This shipment is <span className="text-warning">1</span> of <span className="text-primary"> {length} </span>{" "}
      shipments of this Order
    </span>
  );
};

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <span className="text-danger mt-1 text-overflow-wrap">{error}</span> : null;
    }}
  />
);

const getBinLength = (lineItems) => lineItems.bin_locations.filter((x) => x?.bin_number && x?.qty)?.length || 0;

const shipmentStatusMapping = {
  complete: "badge-soft-success",
  incomplete: "badge-soft-danger",
  checked_in: "badge-soft-warning",
};

const ReceivedShipmentModal = ({ shipment, filters }) => {
  const dispatch = useDispatch();
  const { appTheme } = useSelector((state) => state.Layout);
  const { receivedShipmentModel, error, success } = useSelector((state) => state.WareHouses);
  const toggleReceivedShipmentModal = () => {
    dispatch(setReceivedShipmentModel(!receivedShipmentModel));
  };

  const [binError, setBinError] = useState({});
  const [binLocation, setBinLocation] = useState({});
  const resetBin = (id) => setBinLocation((binLocation) => omit(binLocation, id));
  const [isPopoverOpen, setIsPopoverOpen] = useState({});
  const [isRedirectable, setIsRedirectable] = useState(false);
  const [seeMore, setSeeMore] = useState();
  const [seeMoreUPC, setSeeMoreUPC] = useState();
  const [totalAddedBuffer, setTotalAddedBuffer] = useState(0);
  const [checkValidation, setCheckValidation] = useState(true);
  const lineItems = shipment?.line_items?.length ? shipment?.line_items : shipment?.wh_order?.line_items;
  const shipmentStatusIncomplete = shipment?.status !== SHIPMENT_LINE_STATUSES.complete;
  const isMarketplaceStatusCancelled = [MARKETPLACE_STATUSES.Canceled, MARKETPLACE_STATUSES.Cancelled].includes(
    shipment?.wh_order?.marketplace_status,
  );
  const lineItem = () => ({
    id: uniqueId("item_"),
    item_id: null,
    item_title: "",
    upc: null,
    received_qty: "",
    expected_qty: 0,
    qty_multiplier: 1,
    damaged_qty: 0,
    bin_locations: [],
    oversized: false,
  });
  const shipmentHeaders = ["Tracking Number", "Tracking Status", "Expected Quantity", "Received Quantity", "Status"];
  const tableHeaders = compact([
    "Title",
    "UPC",
    "Expected Qty.",
    "Received Qty.",
    "Damaged Qty.",
    "Bin#/Qty",
    shipmentStatusIncomplete && "Action",
  ]);

  const handleChange = (item, push) => {
    const totalAdded = item?.bin_locations?.reduce((x, y) => x + (+y?.qty || 0), 0) + binLocation?.[item?.id]?.qty;
    const receivedQty = +item?.received_qty;
    if (totalAdded && receivedQty && receivedQty < totalAdded) {
      setBinError({ ...binError, [item.id]: "Cannot add more than receive quantity." });
      return;
    }
    if (!binValues(binLocation?.[item?.id]).every(Boolean)) return;
    push(binLocation?.[item?.id]);
    setTotalAddedBuffer(totalAdded);
    resetBin(item?.id);
    setBinError({ ...binError, [item.id]: "" });
  };

  const getExpectedQty = (item) =>
    +item?.received_shipments?.expected_qty * +item?.received_shipments?.qty_multiplier || 0;

  const isTrackingItem = shipment?.wh_order?.orderable_type === ORDERABLE_TYPES.tracking_items;
  const isReturn = shipment?.wh_order?.orderable_type === ORDERABLE_TYPES.returns;

  useEffect(() => {
    !shipment?.id && toggleReceivedShipmentModal();
  }, [shipment]);

  useEffect(() => {
    if (success && !isTrackingItem) toggleReceivedShipmentModal();
    if (success && isTrackingItem) setIsRedirectable(true);
  }, [success]);

  useEffect(() => {
    setCheckValidation(!!compact(binValues(binError)).length);
  }, [binError]);

  const binQtyInputValidation = (itemId) => {
    const bin = binLocation?.[itemId];
    return checkValidation || !bin?.qty || bin.qty > bin.maxQty;
  };

  const getItemBinQty = (item) => {
    return item?.bin_locations?.reduce((x, y) => x + (+y?.qty || 0), 0);
  };
  const checkBin = (binLocation) => (isEmpty(binLocation) ? false : !!binLocation?.bin_number);
  const submitReceiveShipemt = (values) => {
    const compactBin = compact(
      values.items.map((x, i) => (checkBin(binLocation[x?.id]) || x?.bin_locations.length ? false : x?.id)),
    );
    if (compactBin.length) {
      const newObject = {};
      compactBin.forEach((key) => {
        newObject[key] = "Add missing Bin#/Qty.";
      });
      setBinError(newObject);
    } else {
      forEach(values.items, (item, i) => {
        const addedBins = item.bin_locations.filter((x) => x.bin_number && x.qty);
        const addedBinsBuffer = addedBins.map((j) => omit(j, "maxQty"));
        if (addedBins.length >= 1) values.items[i].bin_locations = addedBinsBuffer;
      });
      if (binValues(binLocation).length === 1 && !values.items[0].bin_locations.length) {
        const binLocationBuffer = binValues(binLocation).map((j) => omit(j, "maxQty"));
        values.items[0].bin_locations = binLocationBuffer.filter((x) => x?.bin_number && x?.qty);
      } else {
        const binLocationKeys = keys(binLocation);
        binLocationKeys.forEach((e, i) => {
          const getItem = values.items.find((x) => x.id === e);
          getItem.bin_locations.push(omit(binLocation[e], "maxQty"));
        });
      }
      // Delete Extra helping IDs
      forEach(values.items, (item) => {
        delete item.received_shipments;
        delete item.id;
      });
      // Check total items length with added bins
      values?.items.length === compact(values?.items.map((x) => x.bin_locations.length)).length
        ? dispatch(receivedShipment(values, filters))
        : setCheckValidation("Add missing Bin#/Qty.");
    }
  };
  return (
    <Modal size="xl" isOpen={receivedShipmentModel}>
      <div className="modal-header">
        <h5 className="modal-title align-items mt-1">Receiving</h5>
        <button
          onClick={toggleReceivedShipmentModal}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <RenderIf
        isTrue={!isRedirectable}
        fallback={
          <span className="d-flex flex-column justify-content-center align-items-center">
            <RenderIf
              isTrue={isMarketplaceStatusCancelled}
              fallback={<h6>There is an Outbound created for this order. Do you want to ship this order?</h6>}
            >
              <h6>This order has been canceled and items moved to inventory.</h6>
            </RenderIf>
            <div className="modal-footer">
              <span className="d-flex">
                <PillBtn
                  title={`${isMarketplaceStatusCancelled ? "Ok" : "No"}`}
                  name={`${isMarketplaceStatusCancelled ? "Ok" : "No"}`}
                  style={{ width: "80px" }}
                  onClick={toggleReceivedShipmentModal}
                  color="primary"
                />
                <RenderIf isTrue={!isMarketplaceStatusCancelled}>
                  <div className="px-2" />
                  <PillBtn
                    title="Yes"
                    name="Yes"
                    style={{ width: "80px" }}
                    onClick={() =>
                      window.open(`/outbound-items?page=1&per_page=30&search=${shipment?.wh_order?.order_id}`, "blank")
                    }
                    color="success"
                  />
                </RenderIf>
              </span>
            </div>
          </span>
        }
      >
        <Formik
          initialValues={{
            shipment_line_id: shipment?.id,
            items:
              lineItems?.map((item) => ({
                id: uniqueId(),
                item_id: item.id || "",
                item_title: item.item_title || "",
                upc: item?.upc || "",
                sku: item?.sku || "",
                cost_per_item: item?.cost_per_item || 0,
                shipping_per_item: item?.shipping_per_item || 0,
                expected_qty: getExpectedQty(item),
                received_shipments: item?.received_shipments,
                received_qty: item?.received_qty || getExpectedQty(item),
                qty_multiplier: item?.qty_multiplier || 1,
                damaged_qty: +item?.received_shipments?.damaged_qty || 0,
                bin_locations: [],
                oversized: false,
              })) || [],
          }}
          validationSchema={object({
            items: array(
              object({
                item_title: string().when("item_id", (itemId) => {
                  if (!itemId) return string().trim().ensure().required("Title is required");
                }),
                received_qty: number()
                  .required("Received Qty also required.")
                  .min(0, "Must be greater than or equal to 0"),
                damaged_qty: number()
                  .required("Damaged Qty is required")
                  .min(0, "Must be greater than or equal to 0")
                  .when("received_qty", (receivedQty, _, { parent }) => {
                    if (parent?.damaged_qty > receivedQty) {
                      return number()
                        .required("Damaged Qty is required")
                        .min(0, "Must be greater than or equal to 0")
                        .max(parent?.received_qty, "Qty. should be <= to Received Qty.");
                    }
                  }),
                bin_locations: array(
                  object({
                    bin_number: string().trim(),
                    qty: number().min(1, "Must be greater than 0"),
                  }),
                ),
              }),
            ).unique("Duplicate Title", "item_title", (a) => a.item_title),
          })}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <>
              <div className="modal-body">
                  <RenderIf isTrue={shipment?.wh_order?.account?.is_liquidated}>
                     <Alert color="danger" className="my-1 mb-2">
                       <i className="bx bx-info-circle pr-2"></i>
                        This customer has canceled their account. Please liquidate this item instead of adding to inventory.
                    </Alert>
                 </RenderIf>

                <RenderIf isTrue={error && receivedShipmentModel}>
                  <div className="auto-hide">
                    <Alert color="danger" className="my-1">
                      <i className="bx bx-info-circle pr-2"></i>
                      {error}
                    </Alert>
                  </div>
                </RenderIf>
                <RenderIf isTrue={isReturn}>
                  <Alert color="info" className="my-1">
                    <i className="bx bx-info-circle pr-2"></i>
                    An outbound will be created, but it will only appear on the outbound page once the return label has
                    been uploaded.
                  </Alert>
                </RenderIf>
                <span className="d-flex justify-content-between">
                  <span className="d-flex pb-3">
                    <span className="font-weight-bold">Tracking Number: </span>
                    <span className="pl-1 text-primary">{getTrackingNumber(shipment?.tracking_number)}</span>
                    <span
                      className={`ml-2 ${
                        shipmentStatusMapping?.[shipment?.status] || "badge-soft-custom"
                      } badge badge-pill`}
                    >
                      {toPascalCase(shipment?.status) || "N/A"}
                    </span>
                  </span>
                  <span className="d-flex flex-column">
                    <span className="d-flex">
                      Store Status:&nbsp;
                      <span
                        className={`badge-soft-${
                          STATUS_COLOR_CODE[shipment?.wh_order?.marketplace_status] || "custom"
                        } badge badge-pill`}
                      >
                        {getText(shipment?.wh_order?.marketplace_status)}
                      </span>
                    </span>
                    <RenderIf
                      isTrue={isMarketplaceStatusCancelled && shipmentStatusIncomplete}
                      children={<small className="warning">Item(s) will be moved to inventory.</small>}
                    />
                  </span>
                </span>
                <FieldArray name="items" validateOnChange={false}>
                  {({ push, remove }) => (
                    <Container className="p-0">
                      <RenderIf isTrue={shipmentStatusIncomplete}>
                        <p className="text-primary cursor-pointer text-right mt-2" onClick={() => push(lineItem())}>
                          <i className="bx bx-plus"></i>
                          Add Item
                        </p>
                      </RenderIf>
                      <ReactTable
                        tableHeader={
                          <>
                            {tableHeaders.map((heading, i) => (
                              <th key={`__shipmentReceivedItemHeaders${i}__`}>{heading}</th>
                            ))}
                          </>
                        }
                        tableBody={
                          <>
                            {values?.items?.map((item, key) => (
                              <tr key={`__shipmentReceivedItemRow${key}__`}>
                                <td>
                                  <RenderIf
                                    isTrue={key < lineItems?.length}
                                    fallback={
                                      <div className="min-height-55">
                                        <Input
                                          type="text"
                                          name={`items.${key}.item_title`}
                                          style={{ minWidth: "100px" }}
                                          onChange={(e) => setFieldValue(e.target.name, e.target.value, true)}
                                        />
                                        <ErrorMessage name={`items.${key}.item_title`} />
                                      </div>
                                    }
                                  >
                                    <div style={{ minWidth: "100px", minHeight: "40px" }}>
                                      {getItemText(
                                        item?.id,
                                        "ShipmentItem",
                                        item?.item_title,
                                        { seeMore, setSeeMore },
                                        20,
                                      )}
                                    </div>
                                  </RenderIf>
                                </td>
                                <td>
                                  <div style={{ minWidth: "100px", minHeight: "40px" }}>
                                    {getItemText(
                                      item?.id,
                                      "ShipmentItem",
                                      item?.upc || "N/A",
                                      { seeMore: seeMoreUPC, setSeeMore: setSeeMoreUPC },
                                      12,
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <RenderIf
                                    isTrue={key < lineItems?.length}
                                    fallback={
                                      <div className="min-height-55">
                                        <Input
                                          type="number"
                                          disabled
                                          min={0}
                                          name={`items.${key}.expected_qty`}
                                          value={item?.expected_qty}
                                        />
                                      </div>
                                    }
                                  >
                                    <div className="text-center" style={{ minHeight: "40px" }}>
                                      {getText(+item?.expected_qty || "0")}
                                    </div>
                                  </RenderIf>
                                </td>
                                <td>
                                  <RenderIf
                                    isTrue={shipmentStatusIncomplete}
                                    fallback={
                                      <div style={{ minHeight: "40px" }}>
                                        {getText(item?.received_shipments?.received_qty || "0")}
                                      </div>
                                    }
                                  >
                                    <div className="min-height-55">
                                      <Input
                                        type="number"
                                        min={0}
                                        name={`items.${key}.received_qty`}
                                        value={item.received_qty}
                                        style={{ minWidth: "100px" }}
                                        onChange={(e) => {
                                          setFieldValue(e.target.name, e.target.value, true);
                                          const qty =
                                            +item?.received_shipments?.expected_qty *
                                            +item?.received_shipments?.qty_multiplier;
                                          if ((!isNaN(qty) && !qty) || !item?.item_id) {
                                            setFieldValue(`items.${key}.expected_qty`, e.target.value);
                                          }
                                        }}
                                      />
                                      <ErrorMessage name={`items.${key}.received_qty`} />
                                    </div>
                                  </RenderIf>
                                </td>
                                <td>
                                  <RenderIf
                                    isTrue={shipmentStatusIncomplete}
                                    fallback={
                                      <div style={{ minHeight: "40px" }}>
                                        {getText(item?.received_shipments?.damaged_qty || "0")}
                                      </div>
                                    }
                                  >
                                    <div className="min-height-55">
                                      <Input
                                        name={`items.${key}.damaged_qty`}
                                        min={0}
                                        value={item.damaged_qty}
                                        type="number"
                                        style={{ minWidth: "100px" }}
                                        onChange={(e) => setFieldValue(e.target.name, +e.target.value, true)}
                                      />
                                      <ErrorMessage name={`items.${key}.damaged_qty`} />
                                    </div>
                                  </RenderIf>
                                </td>
                                {/* BIN LOCATIONS */}
                                <td>
                                  <span className="position-relative d-block min-height-55">
                                    <FieldArray name={`items.${key}.bin_locations`}>
                                      {({ push, remove }) => (
                                        <>
                                          <div className="d-flex align-items-center">
                                            <div
                                              className="d-flex align-items-center position-relative mr-2 rounded"
                                              onClick={() => {
                                                setBinLocation({
                                                  ...binLocation,
                                                  [item?.id]: {
                                                    ...binLocation?.[item?.id],
                                                    maxQty: item.received_qty,
                                                  },
                                                });
                                              }}
                                              style={{
                                                minWidth: "100px",
                                                border: `1px solid ${
                                                  appTheme === "light"
                                                    ? binError[item?.id]
                                                      ? "rgb(122 82 82)"
                                                      : "#ced4da"
                                                    : binError[item?.id]
                                                    ? "rgb(122 82 82)"
                                                    : "#32394e"
                                                }`,
                                              }}
                                            >
                                              <Input
                                                value={binLocation?.[item?.id]?.bin_number || ""}
                                                className="rounded-left"
                                                type="text"
                                                disabled={
                                                  shipment?.status === "complete" ||
                                                  !item?.received_qty ||
                                                  getItemBinQty(item) >= item.received_qty
                                                }
                                                placeholder="Bin #"
                                                style={{ border: "none", width: "50%" }}
                                                onChange={(e) => {
                                                  setBinLocation({
                                                    ...binLocation,
                                                    [item?.id]: {
                                                      ...binLocation?.[item?.id],
                                                      bin_number: e.target.value,
                                                    },
                                                  });
                                                  if (!e.target.value && binLocation?.[item?.id]?.qty)
                                                    setBinError({ ...binError, [item.id]: "Bin# is Required." });
                                                  else if (e.target.value && !binLocation?.[item?.id]?.qty) {
                                                    setBinError({
                                                      ...binError,
                                                      [item.id]: binLocation?.[item?.id]?.qty
                                                        ? ""
                                                        : "Qty also Required.",
                                                    });
                                                  } else setBinError({ ...binError, [item.id]: "" });
                                                }}
                                              />
                                              <VerticalLine
                                                key={`__bin_no_divider_line_${key}`}
                                                style={{
                                                  marginTop: "0px",
                                                  height: "24px",
                                                  borderRightWidth: "1.5px",
                                                  color: `${appTheme === "light" ? "#ced4da" : "#32394e"}`,
                                                }}
                                              />
                                              <Input
                                                value={binLocation?.[item?.id]?.qty || ""}
                                                className="rounded-right"
                                                disabled={
                                                  shipment?.status === "complete" ||
                                                  !item?.received_qty ||
                                                  getItemBinQty(item) >= item.received_qty
                                                }
                                                type="number"
                                                min={1}
                                                placeholder="Qty."
                                                style={{ border: "none", width: "50%" }}
                                                onChange={(e) => {
                                                  setBinLocation({
                                                    ...binLocation,
                                                    [item?.id]: {
                                                      ...binLocation?.[item?.id],
                                                      qty: +e.target.value,
                                                    },
                                                  });
                                                  if (!e.target.value && !binLocation?.[item?.id]?.bin_number) {
                                                    setBinError({
                                                      ...binError,
                                                      [item.id]: "",
                                                    });
                                                    return;
                                                  }
                                                  if (!e.target.value && binLocation?.[item?.id]?.bin_number) {
                                                    setBinError({
                                                      ...binError,
                                                      [item.id]: "Qty is required.",
                                                    });
                                                  } else if (
                                                    e.target.value <= 0 &&
                                                    binLocation?.[item?.id]?.bin_number
                                                  ) {
                                                    setBinError({
                                                      ...binError,
                                                      [item.id]: "Qty must be greater than 0.",
                                                    });
                                                  } else {
                                                    const totalAdded = item?.bin_locations?.reduce(
                                                      (x, y) => x + (+y?.qty || 0),
                                                      0,
                                                    );
                                                    const maxQty = binLocation?.[item?.id]?.maxQty;
                                                    const binNumber = binLocation?.[item?.id]?.bin_number;

                                                    const error = binNumber
                                                      ? maxQty - totalAdded < e.target.value
                                                        ? `Qty must be <= Received Qty (${
                                                            maxQty - totalAdded
                                                          } Remained)`
                                                        : ""
                                                      : "Bin# also Required.";
                                                    setBinError({ ...binError, [item.id]: error });
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="align-items-center d-flex justify-space-between">
                                              <span
                                                className="d-flex align-items-center cursor-pointer mr-2"
                                                onClick={() =>
                                                  !binQtyInputValidation(item?.id) && handleChange(item, push)
                                                }
                                              >
                                                <i
                                                  className={`bx bx-plus fa-lg ${
                                                    binQtyInputValidation(item?.id) ? "" : "color-primary"
                                                  }
                                                    }`}
                                                />
                                                <span
                                                  className={`${
                                                    binQtyInputValidation(item?.id) ? "" : "color-primary"
                                                  }`}
                                                >
                                                  Add Bin#/Qty
                                                </span>
                                              </span>
                                              <RenderIf
                                                isTrue={getBinLength(item)}
                                                fallback={
                                                  <Label
                                                    id={`bin_location_${key}`}
                                                    className="rounded-circle mb-0 bin-count extra-bin"
                                                  >
                                                    <RenderIf
                                                      isTrue={getBinLength(item) >= 100}
                                                      fallback={String(getBinLength(item))}
                                                    >
                                                      +99
                                                    </RenderIf>
                                                  </Label>
                                                }
                                              >
                                                <Label
                                                  id={`bin_location_${key}`}
                                                  className="rounded-circle mb-0 bin-count extra-bin cursor-pointer"
                                                >
                                                  <RenderIf
                                                    isTrue={getBinLength(item) >= 100}
                                                    fallback={<p className="text-primary">{getBinLength(item)}</p>}
                                                  >
                                                    +99
                                                  </RenderIf>
                                                </Label>
                                                <UncontrolledPopover
                                                  placementPrefix="bs-popover"
                                                  placement="bottom-start"
                                                  isOpen={isPopoverOpen?.[key]}
                                                  toggle={() => setIsPopoverOpen({ [key]: !isPopoverOpen?.[key] })}
                                                  hideArrow={true}
                                                  positionFixed={true}
                                                  trigger="legacy"
                                                  target={`bin_location_${key}`}
                                                  style={{
                                                    backgroundColor: appTheme === "light" ? "#f8f9fa" : "#32394e",
                                                    top: "18%",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <div className="py-3 px-2">
                                                    <div className="d-flex align-items-center justify-content-between" style={{ color: appTheme === "light" ? "#495057" : "white" }}>
                                                      <span className="p-1">Bin#</span>
                                                      <span>Qty</span>
                                                      <span />
                                                    </div>
                                                    {item?.bin_locations
                                                      ?.filter((y) => y?.bin_number && y?.qty)
                                                      ?.map((x, k) => (
                                                        <React.Fragment key={k}>
                                                          <div className="d-flex align-items-center">
                                                            <div
                                                              className="d-flex align-items-center position-relative mr-2 rounded"
                                                              style={{
                                                                border: `1px solid ${
                                                                  appTheme === "light" ? "#ced4da" : "#32394e"
                                                                }`,
                                                              }}
                                                            >
                                                              <input
                                                                value={x?.bin_number}
                                                                className="p-2 rounded-left"
                                                                type="text"
                                                                placeholder="Bin #"
                                                                style={{ border: "none", width: "50%", color: appTheme === "light" ? "#495057" : "white", backgroundColor: appTheme !== "light" ? "#2c3245" : "white" }}
                                                                disabled
                                                              />
                                                              <VerticalLine
                                                                key={`__bin_no_divider_line_${key}`}
                                                                style={{
                                                                  marginTop: "0px",
                                                                  height: "24px",
                                                                  borderRightWidth: "1.5px",
                                                                  color: `${
                                                                    appTheme === "light" ? "#ced4da" : "#32394e"
                                                                  }`,
                                                                }}
                                                              />
                                                              <input
                                                                value={x?.qty}
                                                                className="p-2 rounded-right"
                                                                type="text"
                                                                placeholder="Qty."
                                                                style={{ border: "none", width: "50%", color: appTheme === "light" ? "#495057" : "white", backgroundColor: appTheme !== "light" ? "#2c3245" : "white" }}
                                                                disabled
                                                              />
                                                            </div>
                                                            <i
                                                              className=" text-danger mdi-sm mdi mdi-close-thick"
                                                              onClick={() => remove(k)}
                                                            />
                                                          </div>
                                                        </React.Fragment>
                                                      ))}
                                                  </div>
                                                </UncontrolledPopover>
                                              </RenderIf>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </FieldArray>
                                    <RenderIf isTrue={binError?.[item?.id]}>
                                      <span className="text-danger cursor-pointer mt-1 text-wrap">
                                        {binError?.[item?.id]}
                                      </span>
                                    </RenderIf>
                                  </span>
                                </td>
                                <RenderIf isTrue={shipmentStatusIncomplete}>
                                  <td>
                                    <span className="position-relative d-block min-height-55">
                                      <CheckBox
                                        label="Oversized"
                                        key={`__check_box___${key}`}
                                        name={`__check_box__${key}`}
                                        state={item.oversized}
                                        className="min-height-55 mt-3"
                                        setState={() => setFieldValue(`items.${key}.oversized`, !item.oversized, true)}
                                      />
                                    </span>
                                  </td>
                                  <td>
                                    <RenderIf isTrue={key > lineItems?.length - 1}>
                                      <div className="min-height-55 ml-2" style={{ marginTop: "18px" }}>
                                        <i
                                          onClick={() => remove(key)}
                                          className=" text-danger mdi-sm mdi mdi-close-thick"
                                        />
                                      </div>
                                    </RenderIf>
                                  </td>
                                </RenderIf>
                              </tr>
                            ))}
                          </>
                        }
                      />
                    </Container>
                  )}
                </FieldArray>
                <RenderIf isTrue={shipmentStatusIncomplete}>
                  <div className="text-right">
                    <PillBtn
                      title="Receive Shipment"
                      name="Receive Shipment"
                      onClick={() => submitReceiveShipemt(values)}
                      color="primary"
                      disabled={[!totalAddedBuffer, checkValidation].every(Boolean)}
                    />
                    <p className="text-danger mt-1 ">{checkValidation}</p>
                  </div>
                </RenderIf>
                <RenderIf isTrue={shipment?.wh_order?.shipment_lines?.length > 1}>
                  <Row className="align-items-center mb-3 mt-5">
                    <Col md={12}>
                      <div className="d-flex">
                        <h6 className="mb-0">Other Shipments</h6>
                        <div className="ml-3">
                          <i className="bx bx-info-circle pr-2 text-primary" />
                          <InfoLine length={shipment?.wh_order?.shipment_lines?.length} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <ReactTable
                    tableHeader={
                      <>
                        {shipmentHeaders.map((heading, i) => (
                          <th
                            key={`__shipmentHeadersKey${i}__`}
                            className={heading !== "Tracking Number" ? "text-center" : ""}
                          >
                            {heading}
                          </th>
                        ))}
                      </>
                    }
                    tableBody={
                      <>
                        {shipment?.wh_order?.shipment_lines
                          .filter((x) => x.tracking_number !== shipment?.tracking_number)
                          .map((shipmentLine, i) => (
                            <tr key={`__shipmentsDataRowKey${i}__`}>
                              <td>{getTrackingNumber(shipmentLine?.tracking_number)}</td>
                              <td className="text-center">
                                <span
                                  className={`${
                                    shipmentStatusMapping?.[shipmentLine?.tracking_status] || "badge-soft-custom"
                                  } badge badge-pill`}
                                >
                                  {toPascalCase(shipmentLine?.tracking_status) || "N/A"}
                                </span>
                              </td>
                              <td className="text-center">
                                {getText(sumBy(shipmentLine?.line_items, "received_shipments.expected_qty") || "0")}
                              </td>
                              <td className="text-center">
                                {getText(sumBy(shipmentLine?.line_items, "received_shipments.received_qty") || "0")}
                              </td>
                              <td className="text-center">
                                <span
                                  className={`${
                                    shipmentStatusMapping?.[shipmentLine?.status] || "badge-soft-custom"
                                  } badge badge-pill`}
                                >
                                  {toPascalCase(shipmentLine?.status) || "N/A"}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </>
                    }
                  />
                </RenderIf>
              </div>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
};

export default ReceivedShipmentModal;
