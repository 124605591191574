import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import { Container, CardBody, Card, Alert } from "reactstrap";
import {
  fetchPlanGroups,
  setViewPlansModal,
  setAddPlanModal,
  setPlanGroupModal,
  deleteGroup,
  setPreloader,
} from "../../store/actions";
import AddPlanModal from "./components/AddPlanModal";
import PlansModal from "./components/PlansModal";
import PlanGroupModal from "./components/PlanGroupModal";

const CustomPlans = (_) => {
  const [group, setGroup] = useState();
  const {
    error,
    loading,
    newPlan,
    newGroup,
    editGroup,
    deletedPlan,
    deletedGroup,
    groups,
    updatePlanMessage,
    newPlanMessage,
    newGroupMessage,
    updateGroupMessage,
    deletedPlanMessage,
    deletedGroupMessage,
    viewPlansModal,
    isPlanGroupModal,
    addPlanModal,
    editablePlan,
  } = useSelector((state) => state.CustomPlans);
  const dispatch = useDispatch();

  function fetchGroups () {
    dispatch(fetchPlanGroups());
  }

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (newPlan || newGroup || editGroup || deletedPlan || deletedGroup) fetchGroups();
  }, [newPlan, newGroup, editGroup, deletedPlan, deletedGroup]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between m-2">
          <Breadcrumbs title="Plan Groups" />
          <button
            className="btn btn-success mb-2"
            onClick={(_) => {
              setGroup(null);
              dispatch(setPlanGroupModal(true));
            }}
          >
            Add New Group
          </button>
        </div>
        <Card>
          {error && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          )}
          {(newPlanMessage ||
            newGroupMessage ||
            updateGroupMessage ||
            deletedPlanMessage ||
            deletedGroupMessage ||
            updatePlanMessage) && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {newPlanMessage ||
                  newGroupMessage ||
                  updateGroupMessage ||
                  deletedPlanMessage ||
                  deletedGroupMessage ||
                  updatePlanMessage}
              </Alert>
            </div>
          )}
          <CardBody>
            {Array.isArray(groups)
              ? (
              <>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>Name</th>
                        <th className="text-center">Show Plans</th>
                        <th className="text-center">Add Plan</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groups.map((item, _ind) => (
                        <tr key={"_top_" + _ind}>
                          <td>{item.name}</td>
                          <td className="text-center">
                            <i
                              title="Show Plans"
                              onClick={(_) => {
                                setGroup(item);
                                dispatch(setViewPlansModal(true));
                              }}
                              className="bx bx-sm bx-dollar-circle cursor-pointer"
                            ></i>
                          </td>
                          <td className="text-center">
                            <i
                              title="Add New Plan"
                              onClick={(_) => {
                                setGroup(item);
                                dispatch(setAddPlanModal(true));
                              }}
                              className="bx bx-sm bx-plus-circle cursor-pointer"
                            ></i>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <i
                                title="Edit Plan Group"
                                onClick={(_) => {
                                  setGroup(item);
                                  dispatch(setPlanGroupModal(true));
                                }}
                                className="bx bx-sm bxs-pencil cursor-pointer"
                              ></i>
                              <ConfirmDialogIcon
                                icon={{ className: "fa fa-lg fa-trash-alt ml-2", title: "Delete Plan Group" }}
                                msg={`Are you sure to delete this plan group "${item.name}"?`}
                                onConfirm={() => dispatch(deleteGroup(item.id))}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
                )
              : null}
          </CardBody>
        </Card>
        {viewPlansModal ? <PlansModal item={group} /> : null}
        {addPlanModal ? <AddPlanModal item={group} setEditablePlan={editablePlan} /> : null}
        {isPlanGroupModal ? <PlanGroupModal item={group} /> : null}
      </Container>
    </div>
  );
};
export default CustomPlans;
