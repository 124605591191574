import React, { useMemo } from "react";
import * as $ from "lodash";
import RepricerListingCards from "./RepricerListingCards";
import { Row } from "reactstrap";
import RepricerListingComparisonCard from "./RepricerListingComparisonCard";
import { isUserHaveFullAccess } from "../../../utils/common";
import RepricerListingStatCard from "./RepricerListingStatCard";
import { MARKET_PLACES } from "../../../constants";
import { INVENTORY_AGE_FILTERS, WALMART_INVENTORY_AGE_FILTERS } from "./common";

export default ({ listingsStats, isProfitCalculationAllowed, filters, setFilters, storeFront }) => {
  const setFilter = (filter) => setFilters({ ...filters, filter, page: 1, page_number: 1 });

  const platformWiseKey = {
    amazon: { published: "active", unpublished: "inactive" },
    walmart: { published: "published", unpublished: "unpublished" },
  };
  const platformKeys = $.result(platformWiseKey, storeFront?.marketplace, {});
  const stats = $.compact([
    {
      title: "BuyBox",
      value: listingsStats?.has_buybox,
      icon: "bx-cube",
      onClick: () => setFilter("has_buybox"),
    },
    isProfitCalculationAllowed &&
      listingsStats?.danger_missing_cost && {
      title: "Missing COGS",
      value: listingsStats?.danger_missing_cost,
      icon: "bx-dollar",
      onClick: () => setFilter("danger_missing_cost"),
    },
    {
      title: "Total Listings",
      value: listingsStats?.total_listings,
      icon: "bx-menu",
    },
    isUserHaveFullAccess("repricer") &&
      listingsStats?.warning_missing_strategy && {
      title: "Missing Strategy",
      value: listingsStats?.warning_missing_strategy,
      icon: "bx-pie-chart-alt",
      onClick: () => setFilter("warning_missing_strategy"),
    },
    {
      title: "Competition Below Min Price",
      value: listingsStats?.competition_below_min_price,
      icon: "bx-dollar",
      onClick: () => setFilter("competition_below_min_price"),
    },
  ]);

  const comparisonStats = [
    {
      title: "Published vs Unpublished",
      labelLeft: "Published",
      labelRight: "Unpublished",
      valueLeft: listingsStats?.[platformKeys.published],
      valueRight: listingsStats?.[platformKeys.unpublished],
      icon: "bx-file",
      onClickLeft: () => setFilter(platformKeys.published),
      onClickRight: () => setFilter(platformKeys.unpublished),
    },
    {
      title: "In Stock vs Out of Stock",
      labelLeft: "In Stock",
      labelRight: "Out of Stock",
      valueLeft: listingsStats?.in_stock,
      valueRight: listingsStats?.out_of_stock,
      icon: "bx-cube",
      onClickLeft: () => setFilter("in_stock"),
      onClickRight: () => setFilter("out_of_stock"),
    },
  ];

  const newStats = useMemo(() => {
    const getValue = (path) => {
      const value = $.get(listingsStats, path);
      return $.isNil(value) ? "N/A" : value;
    };
    const isWalmart = storeFront?.marketplace === MARKET_PLACES.walmart;

    return [
      {
        title: "Inventory Age",
        subStats: $.map(
          isWalmart ? WALMART_INVENTORY_AGE_FILTERS : INVENTORY_AGE_FILTERS,
          ({ title, textColor }, key) => ({
            title,
            value: getValue(key),
            valueColorClass: textColor,
            onClick: () => setFilter(key),
          }),
        ),
      },
    ];
  }, [listingsStats, storeFront]);

  return (
    <Row>
      <div className="col-md-12">
        <Row>
          {$.map(comparisonStats, (stat, ind) => (
            <RepricerListingComparisonCard
              key={`${stat?.title}__${ind}`}
              title={stat?.title}
              labelLeft={stat?.labelLeft}
              labelRight={stat?.labelRight}
              valueLeft={stat?.valueLeft ?? "N/A"}
              valueRight={stat?.valueRight ?? "N/A"}
              icon={stat?.icon}
              onClickLeft={stat.onClickLeft}
              onClickRight={stat.onClickRight}
            />
          ))}
          {$.map(stats, (stat, ind) => (
            <RepricerListingCards
              key={`__${stat?.title}-${ind}__`}
              title={stat?.title}
              value={stat?.value ?? "N/A"}
              icon={stat?.icon}
              onClick={stat?.onClick}
            />
          ))}
          {newStats.map((data, index) => (
            <RepricerListingStatCard key={`__stat__${index}`} data={data} />
          ))}
        </Row>
      </div>
    </Row>
  );
};
