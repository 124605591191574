import request from "../restClient";

export const getAllEmails = async (data) => {
  const url = "/emails";
  return await request({ url, method: "POST", body: data });
};

export const getAllEmailUpdates = async (params) => {
  const url =
    "/orders_updates" +
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const getAllS3Objects = async (data) => {
  const url = "/s3_objects";
  return await request({ url, method: "POST", body: data });
};

export const updateS3Object = async (id, body) => {
  const url = `/s3_objects/${id}`;
  return await request({ url, method: "PUT", body });
};

export const getS3Email = async (params) => {
  const url =
    "/aws_utils/get_emails" +
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};
