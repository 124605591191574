import { FETCH_AUTH_TOKEN_EMAIL, FETCH_AUTH_TOKEN_EMAIL_SUCCESS, API_ERROR } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  otp: null,
};

const AuthTokens = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTH_TOKEN_EMAIL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_AUTH_TOKEN_EMAIL_SUCCESS:
      state = {
        ...state,
        success: "Otp fetched successfully",
        error: "",
        loading: false,
        otp: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AuthTokens;
