import React from "react";
import { Card, CardBody, Table } from "reactstrap";
const ReactTable = ({ tableHeader, tableBody, style = {}, height, invSection, className = "" }) => {
  return (
    <Card className={invSection ? "mb-0" : ""}>
      <CardBody className="p-0">
        <div className="table-responsive" style={height}>
          <Table className={`table mb-0 table-centered table-nowrap ${className}`} style={style}>
            {tableHeader && (
              <thead className="thead-light">
                <tr className="z-index-2">{tableHeader}</tr>
              </thead>
            )}
            {tableBody && <tbody>{tableBody}</tbody>}
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};
export default ReactTable;
