import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

// actions
import { fetchUsedCreditsGraph, fetchAllTenantUsedCreditsGraph } from "../../../store/actions";
import { DATE_FORMATS } from "../../../constants";
import DateRange from "../../../components/Common/DateRange";

const { DATE, GRAPH_DATE } = DATE_FORMATS;

const UsedCreditsChart = (props) => {
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const store = props.marketplace;
  const timezone = props.timezone;
  const { usedCreditsGraph, usedCreditLoader } = useSelector((state) => state.Graph);

  const currentDate = moment().startOf("day");
  const oneWeekBefore = moment().subtract(6, "days").startOf("day");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);
  const fetchGraphDetails = ({ startDate, endDate }) => {
    const sharedParams = { start_date: startDate, end_date: endDate };
    if (props.type === "global") dispatch(fetchAllTenantUsedCreditsGraph({ ...sharedParams, store_name: store }));
    else {
      !usedCreditLoader &&
        dispatch(fetchUsedCreditsGraph({ start_date: startDate, end_date: endDate, utc_offset: moment().format("Z") }));
    }
  };

  useEffect(() => {
    if (firstUpdate.current && props.type === "global") {
      firstUpdate.current = false;
      return;
    }
    fetchGraphDetails({
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
    });
  }, [accountId, timezone, dates[0], dates[1]]);

  const series = [
    {
      name: "AO",
      data: (usedCreditsGraph?.data?.length &&
        usedCreditsGraph.data.filter((x) => x.credit_type === "AO").map((y) => [y?.date, y?.count])) || [
        [dates[0]],
        [currentDate],
      ],
    },
    {
      name: "BCE",
      data: (usedCreditsGraph?.data?.length &&
        usedCreditsGraph.data.filter((x) => x.credit_type === "BCE").map((x) => [x?.date, x?.count])) || [
        [dates[0]],
        [currentDate],
      ],
    },
    {
      name: "FedEx",
      data: (usedCreditsGraph?.data?.length &&
        usedCreditsGraph.data.filter((x) => x.credit_type === "FedEx").map((x) => [x?.date, x?.count])) || [
        [dates[0]],
        [currentDate],
      ],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No Data for given date range",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#34c38f", "#ed5c5c", "#556ee6", "#f46a6a", "#f4c469", "#fdc4f0"],
    xaxis: {
      type: "datetime",
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE) }, // Timestamp is ALWAYS UTC
    },
    legend: {
      show: true,
      showForNullSeries: false,
      showForZeroSeries: false,
    },
    yaxis: [
      {
        title: {
          text: "Used Credits",
        },
      },
    ],
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: GRAPH_DATE,
      },
    },
  };

  return (
    <Card id="used-credits">
      <CardBody>
        <CardTitle className="mb-4 float-sm-left">
          <div className="d-flex flex-column">
            <h4 className="card-title">{usedCreditLoader ? "Loading..." : "Total Used Credits"}</h4>
            <span>For {moment(dates[1]).diff(moment(dates[0]), "days") + 1} Days</span>
          </div>
        </CardTitle>
        <div className="d-flex flex-row float-sm-right">
          <DateRange dates={dates} setDates={setDates} loader={usedCreditLoader} timePeriods={[7, 30, 365]} />
        </div>
        <div className="clearfix"></div>
        <ReactApexChart options={options} series={series} type="line" height="350" />
      </CardBody>
    </Card>
  );
};

export default UsedCreditsChart;
