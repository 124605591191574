import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { keys, reduce, pick, merge, values, isEmpty } from "lodash";
import { Card, CardBody, Row, Col, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { getUserInfo, RenderIf, getWarehouseName } from "../../../utils/common";
import { LinkPreview } from "@dhaiwat10/react-link-preview";

import CheckBox from "../../../components/Common/CheckBox";
import getBrandImageSrc from "../../../utils/brandImages";
import { WH_OPTS_MAPPING, MP_ACC_STATUSES, TWO_STEP_NON_SUPPORTED_STORES } from "../../../constants";

// actions
import { createWareHouse, fetchAllWarehouses, setSelectOnBoardStore } from "../../../store/actions";
import Preloader from "../../../components/Common/Preloader";
import { getWHAddress } from "../../../components/utils/getWHAddress";

const SelectWHOnBoard = ({ whOpts, formik, progress, initialValues, setProgress, toggleModal }) => {
  const dispatch = useDispatch();
  const { selectedExisitingWareHouse, wareHouses } = useSelector((state) => state.WareHouses);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const initialState = reduce(
    accounts?.data,
    (acc, x) => {
      if (x.enabled && x.valid && x.status === MP_ACC_STATUSES.active)
        acc[x.id] = { name: x.name, marketplace: x.marketplace, state: false, whId: x.two_step_wh_id };
      return acc;
    },
    {},
  );
  const hasAccount = !isEmpty(initialState);
  const [selectedAccounts, setSelectedAccounts] = useState(initialState);
  const [optsData, setOptsData] = useState({ [WH_OPTS_MAPPING.fulfillment]: [], [WH_OPTS_MAPPING.returns]: [] });
  const [tab, setTab] = useState(WH_OPTS_MAPPING.fulfillment);
  const isBothOptsEnabled = keys(pick(whOpts, ["fulfillment", "returns"])).every((x) => whOpts[x]);
  const [selectOptsErr, setSelectOptsErr] = useState("");

  const handleAttachAccountsToWH = () => {
    const accData = {};
    if (optsData[WH_OPTS_MAPPING.fulfillment].length) accData.two_step_ids = optsData[WH_OPTS_MAPPING.fulfillment];
    if (optsData[WH_OPTS_MAPPING.returns].length) accData.return_store_ids = optsData[WH_OPTS_MAPPING.returns];

    selectedExisitingWareHouse.is_private = !!selectedExisitingWareHouse.is_private;
    selectedExisitingWareHouse.is_enabled = !!selectedExisitingWareHouse.is_enabled;

    if (!selectOptsErr) {
      !whOpts.existingWh
        ? dispatch(
          createWareHouse({
            data: {
              ...formik.values,
              ...accData,
              state: formik.values.state.value,
              country: formik.values.country.value,
              creator_id: getUserInfo()?.account_id,
            },
          }),
        )
        : dispatch(
          createWareHouse({
            data: merge(pick(selectedExisitingWareHouse, keys(initialValues), "creator_id", "is_enabled"), accData),
            params: { is_associated: true },
            accData,
          }),
        );
      setProgress(100);
    }
  };

  const isValidOpts = (tab) => {
    if (whOpts[tab] && !optsData[tab].length) {
      setSelectOptsErr("Please select options to proceed");
      return false;
    } else return true;
  };

  const handlePrevBtn = () => {
    if (tab === WH_OPTS_MAPPING.returns && isBothOptsEnabled) setTab(WH_OPTS_MAPPING.fulfillment);
    else dispatch(setSelectOnBoardStore(false));
    setOptsData({ [WH_OPTS_MAPPING.fulfillment]: [], [WH_OPTS_MAPPING.returns]: [] });
  };

  const handleNextBtn = () => {
    isValidOpts(tab) &&
      (isBothOptsEnabled && tab === WH_OPTS_MAPPING.fulfillment
        ? setTab(WH_OPTS_MAPPING.returns)
        : handleAttachAccountsToWH());
  };

  useEffect(() => {
    if (whOpts.returns && !whOpts.fulfillment) setTab(WH_OPTS_MAPPING.returns);
  }, [whOpts]);

  useEffect(() => {
    if (progress !== 66) setProgress(66);
    setSelectedAccounts(initialState);
  }, [tab]);

  return (
    <Card>
      <CardBody>
        {selectOptsErr && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {selectOptsErr || ""}
          </Alert>
        )}
        <Row className="p-1 justify-content-between">
          <div className="d-flex flex-column">
            <span>Warehouse Name</span>
            <RenderIf
              isTrue={keys(selectedExisitingWareHouse).length}
              fallback={<span className="inventory-color">N/A</span>}
            >
              <h4>
                Warehouse{" "}
                {`${selectedExisitingWareHouse.id ? `# ${selectedExisitingWareHouse.id}` : ""}-${
                  selectedExisitingWareHouse.state
                }`}
              </h4>
            </RenderIf>
          </div>
          <div className="d-flex flex-column w-25">
            <span className="d-flex align-items-center">
              Location <i className="bx bx-xs bxs-map text-primary" />
            </span>
            <div className="inventory-color">
              <RenderIf
                isTrue={keys(selectedExisitingWareHouse).length}
                fallback={<span className="inventory-color">N/A</span>}
              >
                {getWHAddress(selectedExisitingWareHouse, undefined, 1, true)}
                {/* {`${selectedExisitingWareHouse.address1} ${selectedExisitingWareHouse.city}, ${selectedExisitingWareHouse.state}, ${selectedExisitingWareHouse.zipcode}`} */}
              </RenderIf>
            </div>
          </div>
        </Row>
        <RenderIf
          isTrue={hasAccount}
          fallback={
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
                Please add a storefront before adding a return Warehouse
            </Alert>
          }
        >
        <span className="inventory-color">{`Select Stores to enable ${
          tab === WH_OPTS_MAPPING.fulfillment ? WH_OPTS_MAPPING.fulfillment : WH_OPTS_MAPPING.returns
        } to this Warehouse`}</span>
          <Row className="mt-3 d-flex ">
            <Col md={6}>
              <div className="d-flex flex-column">
                <br />
                <h5 className="m-2">Stores</h5>
                {keys(selectedAccounts)
                  ?.filter((x) => !values(TWO_STEP_NON_SUPPORTED_STORES).includes(selectedAccounts[x].marketplace))
                  ?.map((acc, key) => {
                    const whName = getWarehouseName(wareHouses?.data?.find((x) => x.id === selectedAccounts[acc]?.whId));
                    return (
                      <CheckBox
                        key={`__selected__account___${key}`}
                        name={`__account__${acc}__${key}`}
                        state={selectedAccounts[acc].state || false}
                        setState={(state) => {
                          selectedAccounts[acc].state = state;
                          setSelectedAccounts({ ...selectedAccounts });

                          const optObj =
                            tab === WH_OPTS_MAPPING.fulfillment
                              ? [WH_OPTS_MAPPING.fulfillment]
                              : [WH_OPTS_MAPPING.returns];
                          if (state) !optsData[optObj].includes(+acc) && optsData[optObj].push(+acc);
                          else optsData[optObj] = optsData[optObj].filter((x) => x !== +acc);

                          setOptsData(optsData);
                          setSelectOptsErr("");
                        }}
                        className="m-2"
                        label={
                          <div>
                            <img
                              src={getBrandImageSrc(selectedAccounts[acc].marketplace)}
                              alt={selectedAccounts[acc].marketplace}
                              style={{ width: "22px" }}
                            />
                            {`${selectedAccounts[acc].name} ${whName ? `( ${whName} )` : ""}`}
                          </div>
                        }
                      />
                    );
                  })}
              </div>
            </Col>
          </Row>
        </RenderIf>
        <RenderIf isTrue={tab === WH_OPTS_MAPPING.returns}>
          <ReturnsGuide />
        </RenderIf>
        <Row className="d-flex card-footer bg-transparent border-top justify-content-between mt-4 mb-2">
          <Col md={4}>
            <Button outline type="reset" color="primary" className="mr-3" onClick={handlePrevBtn}>
              Previous
            </Button>
          </Col>
          <Col md={8} className="justify-content-end align-items-baseline d-flex">
            <Link
              to="#"
              className="mr-3"
              onClick={() => {
                dispatch(fetchAllWarehouses({ page: 1, per_page: 50 }));
                toggleModal();
              }}
            >
              Skip
            </Link>
            <Button type="submit" onClick={handleNextBtn} color="primary" className="w-25" disabled={!hasAccount}>
              Next
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ReturnsGuide = () => (
  <div className="d-flex justify-content-between mt-4 p-4 card-layout rounded">
    <div className="d-flex flex-column">
      <h5>Add return address</h5>
      <span>Here is a guide to help you how to set return address on your marketplace account.</span>
      <Link
        to={{
          pathname: "http://docs.ecomcircles.com/en/articles/6339926-how-to-add-a-return-address-to-the-marketplace",
        }}
        target="_blank"
      >
        Read More
      </Link>
    </div>
    <LinkPreview
      url="http://docs.ecomcircles.com/en/articles/6339926-how-to-add-a-return-address-to-the-marketplace"
      width="115%"
      height="123px"
      textAlign="left"
      showLoader="false"
      customLoader={<Preloader />}
      descriptionLength="0"
    />
  </div>
);

export default SelectWHOnBoard;
