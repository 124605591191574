import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import { Container, Row, Col, Alert } from "reactstrap";
import SupplierSettings from "./components/SupplierSettings";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import SupplierCard from "./components/SupplierCard";
// actions
import { fetchPlatforms, updatePlatform, setPreloader, fetchMarketplaceAccounts } from "../../store/actions";

import { orderBy } from "lodash";

const Suppliers = () => {
  const { platforms, platformEditModalIsOpen, selectedPlatform, error, success, loading } = useSelector(
    (state) => state.Platform,
  );
  const { accounts: marketplaceAccounts } = useSelector((state) => state.MarketPlaceAccount);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();

  const data = filterSuppliers(search);

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }

  function fetchAllPlatforms () {
    dispatch(fetchPlatforms(filters));
  }

  function handleUpdate (data) {
    setUpdateData(data);
    setConfirmDialog(true);
  }

  function filterSuppliers (Suppliername) {
    if (!Suppliername) return orderBy(platforms?.data, "name");

    return platforms?.data?.filter((subData) => {
      return subData?.name?.toLowerCase().includes(Suppliername?.toLowerCase());
    });
  }

  useEffect(() => {
    !marketplaceAccounts && dispatch(fetchMarketplaceAccounts({}));
  }, [loading]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    fetchAllPlatforms();
  }, [filters]);

  // Data Array Split
  const result = [[], [], []];
  const wordsPerLine = Math.ceil(data?.length / 3);
  for (let line = 0; line < 3; line++) {
    for (let i = 0; i < wordsPerLine; i++) {
      const value = data[i + line * wordsPerLine];
      if (!value) continue;
      result[line].push(value);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Suppliers" />
          <div className="d-flex align-items-baseline justify-content-between mb-2">
            <div className="d-flex">
              <form
                className="app-search d-none d-lg-block"
                onSubmit={(e) => {
                  e.preventDefault();
                  setFilters({
                    ...filters,
                    name: search,
                  });
                }}
              >
                <div className="position-relative" style={{ width: "225px" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by supplier name"
                    value={search}
                    onChange={(e) => {
                      if (!e.target.value) {
                        delete filters.name;
                        setSearch("");
                      } else {
                        setSearch(e.target.value.trim());
                      }
                    }}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form>
            </div>
            {selectedPlatform && platformEditModalIsOpen && <SupplierSettings account={marketplaceAccounts} />}
          </div>
          {confirmDialog && (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                dispatch(updatePlatform(updateData));
                setConfirmDialog(false);
              }}
              onCancel={() => setConfirmDialog(false)}
            >
              To update supplier settings!
            </SweetAlert>
          )}
          {selectedPlatform && platformEditModalIsOpen && <SupplierSettings account={marketplaceAccounts} />}

          {success && !platformEditModalIsOpen && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {success}
              </Alert>
            </div>
          )}
          {error && !platformEditModalIsOpen && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          )}
          <Row className="m-0">
            <Col xl="4" sm="12" md="6" className="p-0">
              {result[0]?.map((item, key) => (
                <div key={"_sup_card1_" + key}>
                  <SupplierCard item={item} handleUpdate={handleUpdate} account={marketplaceAccounts} />
                </div>
              ))}
            </Col>
            <Col xl="4" sm="12" md="6" className="p-0">
              {result[1]?.map((item, key) => (
                <div key={"_sup_card2_" + key}>
                  <SupplierCard item={item} handleUpdate={handleUpdate} account={marketplaceAccounts} />
                </div>
              ))}
            </Col>
            <Col xl="4" sm="12" md="6" className="p-0">
              {result[2]?.map((item, key) => (
                <div key={"_sup_card3_" + key}>
                  <SupplierCard item={item} handleUpdate={handleUpdate} account={marketplaceAccounts} />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Suppliers;
