export const FETCH_MARKETPLACE_ACCOUNTS = "FETCH_MARKETPLACE_ACCOUNTS";
export const FETCH_MARKETPLACE_ACCOUNTS_SUCCESS = "FETCH_MARKETPLACE_ACCOUNTS_SUCCESS";
export const FETCH_USER_MARKETPLACE_ACCOUNT = "FETCH_USER_MARKETPLACE_ACCOUNT";
export const FETCH_USER_MARKETPLACE_ACCOUNT_SUCCESS = "FETCH_USER_MARKETPLACE_ACCOUNT_SUCCESS";
export const UPDATE_MARKETPLACE_ACCOUNT = "UPDATE_MARKETPLACE_ACCOUNT";
export const UPDATE_MARKETPLACE_ACCOUNT_SUCCESS = "UPDATE_MARKETPLACE_ACCOUNT_SUCCESS";
export const DELETE_MARKETPLACE_ACCOUNT = "DELETE_MARKETPLACE_ACCOUNT";
export const DELETE_MARKETPLACE_ACCOUNT_SUCCESS = "DELETE_MARKETPLACE_ACCOUNT_SUCCESS";
export const API_ERROR = "MARKETPLACE_ACCOUNTS_ERROR";
export const SET_EDIT_MODAL_MARKETPLACE_ACCOUNT = "SET_EDIT_MODAL_MARKETPLACE_ACCOUNT";
export const SET_ADD_MODAL_MARKETPLACE_ACCOUNT = "SET_ADD_MODAL_MARKETPLACE_ACCOUNT";
export const SET_SELECTED_MARKETPLACE_ACCOUNT = "SET_SELECTED_MARKETPLACE_ACCOUNT";
export const CREATE_MARKETPLACE_ACCOUNTS = "CREATE_MARKETPLACE_ACCOUNTS";
export const CREATE_MARKETPLACE_ACCOUNTS_SUCCESS = "CREATE_MARKETPLACE_ACCOUNTS_SUCCESS";
export const CHECK_STORE = "CHECK_STORE";
export const CHECK_STORE_SUCCESS = "CHECK_STORE_SUCCESS";
export const CHANGE_STORE_STATE = "CHANGE_STORE_STATE";
export const SET_SETTINGS_MODAL_MARKETPLACE_ACCOUNT = "SET_SETTINGS_MODAL_MARKETPLACE_ACCOUNT";
export const UPDATE_VACATION_MODE = "UPDATE_VACATION_MODE";
export const SET_REPRICER_INTEGRATION_MODAL = "SET_REPRICER_INTEGRATION_MODAL";
export const UPDATE_REPRICER = "UPDATE_REPRICER";
export const SET_TWO_STEP_MODAL = "SET_TWO_STEP_MODAL";
export const SET_AMZ_ONBOARDING_MODAL = "SET_AMZ_ONBOARDING_MODAL";
export const SET_SHOPIFY_ONBOARDING_MODAL = "SET_SHOPIFY_ONBOARDING_MODAL";
export const SET_AMZ_ONBOARDING_FORM_DATA = "SET_AMZ_ONBOARDING_FORM_DATA";
export const FETCH_SP_API_REFRESH_TOKEN = "FETCH_SP_API_REFRESH_TOKEN";
export const FETCH_SP_API_REFRESH_TOKEN_SUCCESS = "FETCH_SP_API_REFRESH_TOKEN_SUCCESS";
export const FETCH_SHOPIFY_REFRESH_TOKEN = "FETCH_SHOPIFY_REFRESH_TOKEN";
export const FETCH_SHOPIFY_REFRESH_TOKEN_SUCCESS = "FETCH_SHOPIFY_REFRESH_TOKEN_SUCCESS";
export const UPDATE_REPRICER_SUCCESS = "UPDATE_REPRICER_SUCCESS";
export const CHECK_STORE_NAME = "CHECK_STORE_NAME";
export const CHECK_STORE_NAME_SUCCESS = "CHECK_STORE_NAME_SUCCESS";
export const RESET_STORE_NAME = "RESET_STORE_NAME";
export const RESET_MARKETPLACE_ACC_ERR = "RESET_MARKETPLACE_ACC_ERR";
export const SET_SP_API_ERR = "SET_SP_API_ERR";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_SHOPIFY_REFRESH_TOKEN = "SET_SHOPIFY_REFRESH_TOKEN";
export const SEND_ERROR_EMAIL = "SEND_ERROR_EMAIL";
export const SEND_ERROR_EMAIL_SUCCESS = "SEND_ERROR_EMAIL_SUCCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";
