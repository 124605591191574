import React from "react";
import { CardBody, Col } from "reactstrap";

const StrategyMinMaxPriceCard = ({ icon, title, description, note, onClick, cardType, value }) => {
  return (
    <Col md={3} sm={6}>
      <CardBody
        onClick={onClick}
        className="cursor-pointer p-4 rounded card-layout my-3 position-relative"
        style={{ height: "90%", border: value === cardType ? "1px solid royalblue" : "none" }}
      >
        <div className="d-flex align-items-center justify-content-left">
          <div className="mx-1">{icon}</div>
          <div className="card-text font-size-13 inventory-color font-weight-semibold">{title}</div>
        </div>
        <hr />
        <div>
          <p className="font-size-12">{description}</p>
          <p className="font-size-12 text-primary">{note}</p>
        </div>
      </CardBody>
    </Col>
  );
};

export default StrategyMinMaxPriceCard;
