import {
  FETCH_RESTRICTED_ITEMS,
  FETCH_RESTRICTED_ITEMS_SUCCESS,
  SET_SELECTED_ITEMS,
  SET_ITEMS_BULK_UPDATE_MODAL,
  ITEMS_BULK_UPDATE,
  ITEMS_BULK_UPDATE_SUCCESS,
  ADD_RESTRICTED_ITEMS,
  ADD_RESTRICTED_ITEMS_SUCCESS,
  DEL_RESTRICTED_ITEMS,
  DEL_RESTRICTED_ITEMS_SUCCESS,
  UPDATE_RESTRICTED_ITEMS,
  UPDATE_RESTRICTED_ITEMS_SUCCESS,
  SET_ITEM_ADD_MODAL,
  SET_ITEM_BULK_ADD_MODAL,
  SET_ITEM_EDIT_MODAL,
  API_ERROR,
  BULK_ADD_RESTRICTED_ITEMS,
  BULK_ADD_RESTRICTED_ITEMS_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  restrictedItems: undefined,
  selectedItems: [],
  addItemModalIsOpen: false,
  editItemModalIsOpen: false,
  itemsBulkUpdateModalIsOpen: false,
  bulkAddItemModalIsOpen: false,
  success: "",
};

const RestrictedItems = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESTRICTED_ITEMS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_RESTRICTED_ITEMS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        restrictedItems: action.payload,
        loading: false,
      };
      break;
    case SET_SELECTED_ITEMS:
      state = {
        ...state,
        selectedItems: action.payload,
      };
      break;
    case SET_ITEMS_BULK_UPDATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        itemsBulkUpdateModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case ITEMS_BULK_UPDATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ITEMS_BULK_UPDATE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Item have been Updated Successfully",
        loading: false,
      };
      break;
    case SET_ITEM_ADD_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        addItemModalIsOpen: action.payload,
      };
      break;
    case SET_ITEM_BULK_ADD_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        bulkAddItemModalIsOpen: action.payload,
      };
      break;
    case SET_ITEM_EDIT_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        editItemModalIsOpen: action.payload,
      };
      break;
    case ADD_RESTRICTED_ITEMS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_RESTRICTED_ITEMS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Item added Successfully!",
        loading: false,
      };
      break;
    case BULK_ADD_RESTRICTED_ITEMS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case BULK_ADD_RESTRICTED_ITEMS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Bulk items added Successfully!",
        loading: false,
      };
      break;
    case DEL_RESTRICTED_ITEMS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DEL_RESTRICTED_ITEMS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Item Deleted Successfully!",
        loading: false,
      };
      break;
    case UPDATE_RESTRICTED_ITEMS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_RESTRICTED_ITEMS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Item Updated Successfully!",
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RestrictedItems;
