import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Card, CardBody, Badge } from "reactstrap";
import Row from "reactstrap/lib/Row";
import { fetchAllReturnLabelHistory, setPreloader } from "../../store/actions";
import CustomPagination from "../../components/Common/CustomPagination";
import CustomTooltip from "../../components/Common/CustomTooltip";
import moment from "moment-timezone";
import { toPascalCase } from "../../utils/common";
import { Link } from "react-router-dom";
import { DATE_FORMATS } from "../../constants";
const { FULL_DATE } = DATE_FORMATS;
function CustomLink (props) {
  if (!props.source_order_id) return <span>{props.id}</span>;
  return (
    <Link
      to={`/source_orders_details/${props.id}/${props.source_order_id}`}
      className="text-primary mb-1"
      target="_blank"
    >
      {props.id}
    </Link>
  );
}

const ReturnLabel = () => {
  const dispatch = useDispatch();
  const { returnLabels, loading } = useSelector((state) => state.SupplierOrders);
  const [filters, setFilters] = useState({ page: 1, per_page: 20 });
  const tableHeaders = ["Task ID", "Order ID", "Return Address", "Status", "Attempt Time"].map((title) => ({ title }));
  const STATUS_COLOR_CODE = { pending: "primary", failed: "danger", done: "success" };

  useEffect(() => {
    dispatch(fetchAllReturnLabelHistory(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="ml-1">
            <Breadcrumbs title="Retrun Labels" />
          </Row>
          <Card>
            {Array.isArray(returnLabels?.data) && returnLabels?.data?.length
              ? (
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {tableHeaders.map((header, index) => (
                          <th key={`table-header ${index}`}>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {returnLabels.data.map((item, key) => (
                        <tr key={"return_label_" + key}>
                          <td>{item.task_id}</td>
                          <td>
                            <CustomLink id={item.order_id} source_order_id={item.source_order_id} />
                          </td>
                          <td>
                            {Object.values(item.return_address)
                              .filter((x) => x)
                              .join(", ")}
                          </td>
                          <td>
                            <Badge
                              pill
                              id={`_return_label_status_${key}`}
                              className={`font-size-12 badge-soft-${STATUS_COLOR_CODE[item.status]} mr-1`}
                            >
                              {toPascalCase(item.status)}
                            </Badge>
                            {item.note && item.status !== "done"
                              ? (
                              <CustomTooltip
                                placement="left"
                                target={`_return_label_status_${key}`}
                                content={item.note}
                              />
                                )
                              : null}
                          </td>
                          <td>{moment(item?.created_at).format(FULL_DATE) || ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {returnLabels?.data.length
                  ? (
                  <CustomPagination
                    total={returnLabels.count}
                    pageOptions={[20, 50, 100]}
                    page={returnLabels.page}
                    perPage={returnLabels.perPage}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                    )
                  : null}
              </CardBody>
                )
              : null}
            {returnLabels?.data.length === 0 && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReturnLabel;
