import { UPDATE_ORDER_ATTEMPT, UPDATE_ORDER_ATTEMPT_SUCCESS, API_ERROR } from "./actionTypes";

const orderAttempt = (state, action) => {
  switch (action.type) {
    case UPDATE_ORDER_ATTEMPT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_ORDER_ATTEMPT_SUCCESS:
      state = {
        ...state,
        orderAttempt: action.payload,
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default orderAttempt;
