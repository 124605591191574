import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const OffCanvas = (props) => {
  const { open, onClose, className, children, size } = props;
  return (
    <>
      <Drawer open={open} onClose={onClose} direction="right" size={size} className={className} zIndex="99999">
        {children}
      </Drawer>
    </>
  );
};

export default OffCanvas;
