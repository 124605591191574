import { Ability } from "@casl/ability";
import { USER_ROLES, READ_ONLY_RESOURCES } from "../constants/index";

const visitAccess = () =>
  Object.values(READ_ONLY_RESOURCES).reduce(
    (acc, resource) => {
      if (!["dashboard", ""].includes(resource)) acc.push({ inverted: true, action: "visit", subject: resource });
      return acc;
    },
    [
      { action: "visit", subject: "dashboard" },
      { action: "visit", subject: "" },
    ],
  );

const giveAbilitiesForRole = (role) => {
  const allAbilities = ["visit"].map((action) => ({ action, subject: "all" }));
  if (role === USER_ROLES.admin) return allAbilities;
  else if (role === USER_ROLES.service) return allAbilities;
  else if (role === USER_ROLES.manager) return allAbilities;
  else if (role === USER_ROLES.dev) return allAbilities;
  else if (role === USER_ROLES.owner) return allAbilities;
  else if (role === USER_ROLES.va) return allAbilities;
  else return visitAccess();
};

export const abilitiesFor = (role) => new Ability(giveAbilitiesForRole(role));
