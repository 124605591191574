import * as types from "./actionTypes";
import { merge } from "lodash";
const {
  FETCH_ALL_WAREHOUSES,
  FETCH_ALL_WAREHOUSES_SUCCESS,
  FETCH_ALL_WAREHOUSE_ITEMS,
  FETCH_ALL_WAREHOUSE_ITEMS_SUCCESS,
  FETCH_WAREHOUSE_ACCOUNTS,
  FETCH_WAREHOUSE_ACCOUNTS_SUCCESS,
  CREATE_WAREHOUSE,
  CREATE_WAREHOUSE_SUCCESS,
  SET_SELECT_ON_BOARD_STORE,
  ASSIGN_USER_WAREHOUSE,
  ASSIGN_USER_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE_ITEM,
  UPDATE_WAREHOUSE_ITEM_SUCCESS,
  DELETE_WAREHOUSE_USER,
  DELETE_WAREHOUSE_USER_SUCCESS,
  UPDATE_WAREHOUSE,
  UPDATE_WAREHOUSE_SUCCESS,
  SET_ADD_WAREHOUSE_MODAL,
  SET_VIEW_WAREHOUSE_USERS_MODAL,
  SET_ASSIGN_USER_WAREHOUSE_MODAL,
  SELECT_EXISTING_WAREHOUSE,
  SET_SELECTED_WAREHOUSE,
  SET_SELECTED_WAREHOUSE_ITEM,
  ADD_TRACKING_ITEM_NOTE_MODAL,
  ADD_WAREHOUSE_NOTE,
  ADD_WAREHOUSE_NOTE_SUCCES,
  RESOLVE_STRANDED_ITEM,
  RESOLVE_STRANDED_ITEM_SUCCESS,
  FETCH_INVENTORY_ITEMS,
  FETCH_INVENTORY_ITEMS_SUCCESS,
  UPDATE_INVENTORY_ITEM_STATUS,
  UPDATE_INVENTORY_ITEM_STATUS_SUCCESS,
  SET_UPLOAD_RETURN_LABEL_MODAL,
  SET_TRACKING_STATUS_MODAL,
  SET_SELECTED_INVENTORY_ITEM,
  UPLOAD_RETURN_LABEL_FILE,
  UPLOAD_RETURN_LABEL_FILE_SUCCESS,
  DOWNLOAD_RETURN_LABEL,
  API_ERROR,
  DOWNLOAD_RETURN_LABEL_SUCCESS,
  ASSIGN_AGENCY_TO_WAREHOUSE,
  ASSIGN_AGENCY_TO_WAREHOUSE_SUCCESS,
  SET_VIEW_AGENCY_WAREHOUSE_MODEL,
  DELETE_WH_ACCOUNT,
  DELETE_WH_ACCOUNT_SUCCESS,
  DELETE_WAREHOUSE,
  DELETE_WAREHOUSE_SUCCESS,
  UPDATE_WH_ACCOUNT_SUCCESS,
  UPDATE_WH_ACCOUNT,
  UPDATE_BULK_ITEMS,
  UPDATE_BULK_ITEMS_SUCCESS,
  MARK_NOT_RECEIVED,
  MARK_NOT_RECEIVED_SUCCESS,
  SET_DOWNLOAD_RETURN_LABEL_MODAL,
  DELETE_RETURN_LABEL,
  DELETE_RETURN_LABEL_SUCCESS,
  FETCH_USER_WAREHOUSES,
  FETCH_USER_WAREHOUSES_SUCCESS,
  DOWNLOAD_INVENTORY_REPORT,
  DOWNLOAD_INVENTORY_REPORT_SUCCESS,
  FETCH_ALL_SHIPPING_RATES,
  FETCH_ALL_SHIPPING_RATES_SUCCESS,
  GENERATE_LABEL,
  GENERATE_LABEL_SUCCESS,
  FETCH_ALL_SHIPMENTS,
  FETCH_ALL_SHIPMENTS_SUCCESS,
  FETCH_ALL_OUTBOUND_SHIPMENTS,
  FETCH_ALL_OUTBOUND_SHIPMENTS_SUCCESS,
  REFUND_SHIPMENT_LABEL,
  REFUND_SHIPMENT_LABEL_SUCCESS,
  DOWNLOAD_SHIPPING_LABEL,
  DOWNLOAD_SHIPPING_LABEL_SUCCESS,
  SET_BIN_NO_MODAL,
  SET_ADD_INVENTORY_MODAL,
  RESET_FETCH_RATES_ERR,
  RESET_RATES,
  ADD_INVENTORY_ITEM,
  ADD_INVENTORY_ITEM_SUCCESS,
  UPDATE_INVENTORY_ITEM_BINS,
  UPDATE_INVENTORY_ITEM_BINS_SUCCESS,
  DELETE_INVENTORY_ITEM_BIN,
  DELETE_INVENTORY_ITEM_BIN_SUCCESS,
  FETCH_PURCHASE_ORDERS,
  FETCH_PURCHASE_ORDERS_SUCCESS,
  FETCH_PURCHASE_ORDERS_METADATA,
  FETCH_PURCHASE_ORDERS_METADATA_SUCCESS,
  FETCH_PO_LOGS,
  FETCH_PO_LOGS_SUCCESS,
  FETCH_PO_STATS,
  FETCH_PO_STATS_SUCCESS,
  FETCH_PO_SUPPLIERS,
  FETCH_PO_SUPPLIERS_SUCCESS,
  CREATE_PURCHASE_ORDERS,
  CREATE_PURCHASE_ORDERS_SUCCESS,
  CREATE_LINE_ITEM,
  CREATE_LINE_ITEM_SUCCESS,
  CREATE_PO_SHIPMENT,
  CREATE_PO_SHIPMENT_SUCCESS,
  DELETE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM_SUCCESS,
  DELETE_PURCHASE_ORDER_SHIPMENT,
  DELETE_PURCHASE_ORDER_SHIPMENT_SUCCESS,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM_SUCCESS,
  UPDATE_PURCHASE_ORDER_SHIPMENT,
  UPDATE_PURCHASE_ORDER_SHIPMENT_SUCCESS,
  SET_ADJUSTMENT_MODAL,
  SET_SHIPMENT_MODAL,
  SET_PO_LOGS_MODAL,
  SET_UPDATE_TENANT_MODAL,
  SET_INVENTORY_CHECKBOXES,
  BULK_UPDATE_INVENTORY_ITEM,
  BULK_UPDATE_INVENTORY_ITEM_SUCCESS,
  FETCH_INBOUND_ITEMS,
  FETCH_INBOUND_ITEMS_SUCCESS,
  FETCH_WH_INBOUND_STATS,
  FETCH_WH_INBOUND_STATS_SUCCESS,
  SET_ADD_SHIPMENT_MODEL,
  SEARCH_ORDER,
  SEARCH_ORDER_SUCCESS,
  UPDATE_TRACKING_ITEM_SHIPPPING_STATUS,
  UPDATE_TRACKING_ITEM_SHIPPPING_STATUS_SUCCESS,
  SET_RECEIVED_SHIPMENT_MODEL,
  SET_STORE_BINS_MODEL,
  ADD_SHIPMENT,
  ADD_SHIPMENT_SUCCESS,
  SHIPMENT_CHECKED_IN,
  SHIPMENT_CHECKED_IN_SUCCESS,
  DOWNLOAD_WH_ITEMS_REPORT,
  DOWNLOAD_WH_ITEMS_REPORT_SUCCESS,
  FETCH_WH_INVENTORY,
  FETCH_WH_INVENTORY_SUCCESS,
  FETCH_WH_INVENTORY_STATS,
  FETCH_WH_INVENTORY_STATS_SUCCESS,
  FETCH_WH_OUTBOUNDS,
  FETCH_WH_OUTBOUNDS_SUCCESS,
  GENERATE_SHIPPING_LABEL_TOGGLE,
  FETCH_WH_OUTBOUND_STATS,
  FETCH_WH_OUTBOUND_STATS_SUCCESS,
  SET_BIN_DETAIL_MODAL,
  RECEIVE_SHIPMENT,
  RECEIVE_SHIPMENT_SUCCESS,
  SET_INBOUND_SHIPMENT,
  DOWNLOAD_WH_LABEL_REPORT,
  DOWNLOAD_WH_LABEL_REPORT_SUCCESS,
  SET_WH_LABEL_REPORT_MODAL,
  FETCH_LABEL_USERS_AND_COUNT,
  FETCH_LABEL_USERS_AND_COUNT_SUCCESS,
} = types;

const initialState = {
  error: "",
  success: "",
  loading: false,
  isLiquidated: false,
  loadingPlatforms: false,
  chargesLoading: false,
  wareHouses: { data: [] },
  associatedWarehousesObj: {},
  wareHouseItems: null,
  wareHouseAccounts: [],
  selectedWarehouse: null,
  selectedWarehouseItem: null,
  addWareHouseModalIsOpen: false,
  selectedExisitingWareHouse: {},
  warehouseNoteModalIsOpen: false,
  trackingStatusModalIsOpen: false,
  inventoryItems: { data: [] },
  isSelectOnBoardStore: false,
  chargeHistories: { data: [] },
  uploadReturnLabelModalIsOpen: false,
  assignUserWareHouseModalIsOpen: false,
  viewWareHouseAgencyModalIsOpen: false,
  downloadLabelModalIsOpen: false,
  getAllShippingRates: {},
  WHChargeStatusFilterStats: {},
  allShipments: {},
  allOutboundShipments: {},
  stampsCreds: [],
  addInventoryModalIsOpen: false,
  binNoModalIsOpen: false,
  adjustmentModalIsOpen: false,
  shipmentModalIsOpen: false,
  POLogsModalIsOpen: false,
  purchaseOrders: { data: [] },
  purchaseOrdersMetadata: {},
  purchaseOrdersStats: "",
  purchaseOrdersSuppliers: [],
  loadingPOMetadat: false,
  inventoryCheckBoxes: {},
  fileUrl: "",
  addShipmentModal: false,
  receivedShipmentModel: false,
  setBinDetailModalIsOpen: false,
  setItemActionsModalIsOpen: false,
  storeBinsModel: false,
  shipments: { data: [] },
  generateShippingLabelToggleIsOpen: false,
  labelGenerationStats: null,
  scanforms: [],
  scanformModalIsOpen: false,
  scanformShipments: [],
  orderId: "",
  strandedCount: {},
  filePath: "",
};

const WareHouses = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_WAREHOUSES:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case DOWNLOAD_WH_LABEL_REPORT:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case DOWNLOAD_WH_LABEL_REPORT_SUCCESS:
      state = {
        ...state,
        success: action.payload?.message,
        error: "",
        loading: false,
      };
      break;
    case SET_WH_LABEL_REPORT_MODAL:
      state = {
        ...state,
        whLabelReportModalIsOpen: action.payload.modal,
        currentReports: undefined,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case FETCH_ALL_WAREHOUSES_SUCCESS:
      state[action.payload.is_associated ? "associatedWarehouses" : "wareHouses"] = action.payload;
      state = {
        ...state,
        loading: false,
        error: "",
        associatedWarehousesObj: state.associatedWarehouses?.data?.reduce((obj, wh) => merge(obj, { [wh.id]: wh }), {}),
      };
      break;
    case FETCH_ALL_WAREHOUSE_ITEMS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_ALL_WAREHOUSE_ITEMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        wareHouseItems: action.payload,
      };
      break;
    case FETCH_WAREHOUSE_ACCOUNTS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_WAREHOUSE_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        wareHouseAccounts: action.payload,
      };
      break;
    case CREATE_WAREHOUSE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case CREATE_WAREHOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
      break;
    case ASSIGN_USER_WAREHOUSE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case ASSIGN_USER_WAREHOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: action.payload?.message || "User Added",
      };
      break;
    case UPDATE_WAREHOUSE_ITEM:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_WAREHOUSE_ITEM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        warehouseNoteModalIsOpen: false,
        trackingStatusModalIsOpen: false,
        success: action.payload.message || "Update Item Successfully",
      };
      break;
    case UPDATE_INVENTORY_ITEM_STATUS:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case UPDATE_INVENTORY_ITEM_STATUS_SUCCESS:
      state = { ...state, success: "Product Updated Successfully", error: "", loading: false };
      break;
    case types.UPDATE_INVENTORY_ITEMS:
      state.whInvetoryData.inventoryItems = [];
      state = { ...state, success: "Array empty successfully", error: "", loading: false };
      break;
    case UPDATE_WAREHOUSE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_WAREHOUSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Warehouse Updated Successfully",
      };
      break;
    case RESOLVE_STRANDED_ITEM:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case RESOLVE_STRANDED_ITEM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        binNoModalIsOpen: false,
        success: action.payload.message || "Item Added to Inventory Successfully",
      };
      break;
    case ADD_WAREHOUSE_NOTE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case ADD_WAREHOUSE_NOTE_SUCCES:
      state = {
        ...state,
        loading: false,
        error: "",
        warehouseNoteModalIsOpen: false,
        success: action.payload.message,
      };
      break;
    case ADD_TRACKING_ITEM_NOTE_MODAL:
      state.warehouseNoteModalIsOpen = action.payload;
      break;
    case SET_BIN_NO_MODAL:
      state = { ...state, success: "", error: "", binNoModalIsOpen: action.payload };
      break;
    case SET_ADD_INVENTORY_MODAL:
      state = { ...state, error: "", success: "", addInventoryModalIsOpen: action.payload };
      break;
    case ADD_INVENTORY_ITEM:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case ADD_INVENTORY_ITEM_SUCCESS:
      state = { ...state, success: action.payload, addInventoryModalIsOpen: false, loading: false };
      break;
    case SET_UPDATE_TENANT_MODAL:
      state = { ...state, updateTenantModalIsOpen: action.payload };
      break;
    case SET_INVENTORY_CHECKBOXES:
      state = { ...state, inventoryCheckBoxes: action.payload };
      break;
    case SET_TRACKING_STATUS_MODAL:
      state = { ...state, error: "", trackingStatusModalIsOpen: action.payload };
      break;
    case SET_SELECT_ON_BOARD_STORE:
      state = { ...state, isSelectOnBoardStore: action.payload };
      break;
    case DELETE_WAREHOUSE_USER:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case DELETE_WAREHOUSE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        viewWareHouseUserModalIsOpen: false,
        success: action.payload.message || "User Deleted Successfully",
      };
      if (action.payload.wareHouses) state.wareHouses = action.payload.wareHouses;
      break;
    case SET_ADD_WAREHOUSE_MODAL:
      state = {
        ...state,
        error: "",
        addWareHouseModalIsOpen: action.payload,
      };
      break;
    case SET_VIEW_WAREHOUSE_USERS_MODAL:
      state = {
        ...state,
        error: "",
        viewWareHouseUserModalIsOpen: action.payload.state,
        viewWareHouseData: action.payload.data,
      };
      break;
    case SET_ASSIGN_USER_WAREHOUSE_MODAL:
      state = {
        ...state,
        error: "",
        assignUserWareHouseModalIsOpen: action.payload,
      };
      break;
    case SELECT_EXISTING_WAREHOUSE:
      state = {
        ...state,
        selectedExisitingWareHouse: action.payload,
      };
      break;
    case SET_SELECTED_WAREHOUSE:
      state = {
        ...state,
        selectedWarehouse: action.payload,
      };
      break;
    case SET_SELECTED_WAREHOUSE_ITEM:
      state = {
        ...state,
        selectedWarehouseItem: action.payload,
      };
      break;
    case FETCH_INVENTORY_ITEMS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_INVENTORY_ITEMS_SUCCESS:
      state[action.payload.isAlertData ? "alertData" : "warehouseInventory"] = action.payload.res;
      state = { ...state, loading: false, success: "", error: "" };
      break;
    case SET_UPLOAD_RETURN_LABEL_MODAL:
      state = { ...state, success: "", error: "", uploadReturnLabelModalIsOpen: action.payload };
      break;
    case SET_SELECTED_INVENTORY_ITEM:
      state = { ...state, selectedInventoryItem: action.payload };
      break;
    case UPLOAD_RETURN_LABEL_FILE:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DOWNLOAD_RETURN_LABEL:
      state = { ...state, success: "", error: "" };
      break;
    case DOWNLOAD_RETURN_LABEL_SUCCESS:
      state = { ...state, filePath: action.payload?.url };
      break;
    case DELETE_RETURN_LABEL:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_RETURN_LABEL_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case UPLOAD_RETURN_LABEL_FILE_SUCCESS:
      state = { ...state, loading: false, uploadReturnLabelModalIsOpen: false, success: "File Uploaded Successfully" };
      break;
    case ASSIGN_AGENCY_TO_WAREHOUSE:
      state = { ...state, success: "", loading: false };
      break;
    case ASSIGN_AGENCY_TO_WAREHOUSE_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case SET_VIEW_AGENCY_WAREHOUSE_MODEL:
      state = {
        ...state,
        viewWareHouseAgencyModalIsOpen: action.payload.state,
        viewWareHouseData: action.payload.data,
      };
      break;
    case DELETE_WH_ACCOUNT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_WH_ACCOUNT_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message, viewWareHouseAgencyModalIsOpen: false };
      break;
    case DELETE_WAREHOUSE:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_WAREHOUSE_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case UPDATE_WH_ACCOUNT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPDATE_WH_ACCOUNT_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case UPDATE_BULK_ITEMS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPDATE_BULK_ITEMS_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case MARK_NOT_RECEIVED:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case MARK_NOT_RECEIVED_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case SET_DOWNLOAD_RETURN_LABEL_MODAL:
      state = { ...state, loading: false, downloadLabelModalIsOpen: action.payload };
      break;
    case FETCH_USER_WAREHOUSES:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_USER_WAREHOUSES_SUCCESS:
      state = { ...state, loading: false, userWarehouses: action.payload };
      break;
    case DOWNLOAD_INVENTORY_REPORT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DOWNLOAD_INVENTORY_REPORT_SUCCESS:
      state = { ...state, loading: false };
      break;
    case DOWNLOAD_WH_ITEMS_REPORT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DOWNLOAD_WH_ITEMS_REPORT_SUCCESS:
      state = { ...state, loading: false };
      break;
    case FETCH_ALL_SHIPPING_RATES:
      state = { ...state, loading: true, ratesLoading: true, success: "", error: "" };
      break;
    case FETCH_ALL_SHIPPING_RATES_SUCCESS:
      state = { ...state, loading: false, ratesLoading: false, getAllShippingRates: action.payload };
      break;
    case GENERATE_LABEL:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case GENERATE_LABEL_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message, generateShippingLabelToggleIsOpen: false };
      break;
    case FETCH_ALL_SHIPMENTS:
      state = { ...state, success: "", error: "" };
      break;
    case FETCH_ALL_SHIPMENTS_SUCCESS:
      state = { ...state, allShipments: action.payload?.data };
      break;
    case FETCH_ALL_OUTBOUND_SHIPMENTS:
      state = { ...state, success: "", error: "" };
      break;
    case FETCH_ALL_OUTBOUND_SHIPMENTS_SUCCESS:
      state = { ...state, allOutboundShipments: action.payload?.data, stampsCreds: action.payload?.stamps_creds };
      break;
    case REFUND_SHIPMENT_LABEL:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case REFUND_SHIPMENT_LABEL_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case DOWNLOAD_SHIPPING_LABEL:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DOWNLOAD_SHIPPING_LABEL_SUCCESS:
      state = {
        ...state,
        fileUrl: action.payload.url,
        loading: false,
        success: !action.payload.url && "Label downloaded successfully",
      };
      break;
    case types.DELETE_OUTBOUND_ITEM:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.DELETE_OUTBOUND_ITEM_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    // WH Charges
    case types.FETCH_WH_CHARGE_HISTORIES:
      state = { ...state, chargesLoading: true, success: "", error: "" };
      break;
    case types.FETCH_WH_CHARGE_HISTORIES_SUCCESS:
      state = { ...state, chargesLoading: false, success: "", error: "", chargeHistories: action.payload };
      break;
    case types.UPDATE_WH_CHARGE_HISTORY:
      state = {
        ...state,
        success: "",
        error: "",
        chargesLoading: true,
      };
      break;
    case types.UPDATE_WH_CHARGE_HISTORY_SUCCESS:
      state = {
        ...state,
        success: action.payload.message,
        error: "",
        chargesLoading: false,
      };
      break;
    case types.FETCH_INVOICE_DETAIL:
      state = { ...state, chargesLoading: true, success: "", error: "" };
      break;
    case types.FETCH_INVOICE_DETAIL_SUCCESS:
      state = { ...state, chargesLoading: false, success: "", error: "", invoiceDetail: action.payload };
      break;
    case types.FETCH_WH_CHARGES_STATUS_STATS:
      state = { ...state, success: "", error: "" };
      break;
    case types.FETCH_WH_CHARGES_STATUS_STATS_SUCCESS:
      state = { ...state, WHChargeStatusFilterStats: action.payload?.data };
      break;
    case types.DOWNLOAD_CHARGES_REPORT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.DOWNLOAD_CHARGES_REPORT_SUCCESS:
      state = { ...state, loading: false };
      break;

    // Purchase Orders
    case FETCH_PURCHASE_ORDERS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_PURCHASE_ORDERS_SUCCESS:
      state = { ...state, loading: false, success: "", error: "", purchaseOrders: action.payload };
      break;
    case FETCH_PURCHASE_ORDERS_METADATA:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_PURCHASE_ORDERS_METADATA_SUCCESS:
      state = { ...state, loading: false, error: "", purchaseOrdersMetadata: action?.payload?.res?.data };
      break;
    case FETCH_PO_LOGS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_PO_LOGS_SUCCESS:
      state = { ...state, loading: false, success: "", error: "", purchaseOrderslogs: action?.payload?.res?.data };
      break;
    case FETCH_PO_STATS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_PO_STATS_SUCCESS:
      state = { ...state, loading: false, success: "", error: "", purchaseOrdersStats: action?.payload?.res?.data };
      break;
    case FETCH_PO_SUPPLIERS:
      state = { ...state, loadingPlatforms: true, success: "", error: "" };
      break;
    case FETCH_PO_SUPPLIERS_SUCCESS:
      state = {
        ...state,
        loadingPlatforms: false,
        success: "",
        error: "",
        purchaseOrdersSuppliers: action?.payload?.res?.data,
      };
      break;
    case CREATE_PURCHASE_ORDERS:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case CREATE_PURCHASE_ORDERS_SUCCESS:
      state = { ...state, loading: false, error: "", success: action.payload.msg, adjustmentModalIsOpen: false };
      break;
    case CREATE_LINE_ITEM:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case CREATE_LINE_ITEM_SUCCESS:
      state = { ...state, loading: false, error: "", success: action.payload.msg };
      break;
    case CREATE_PO_SHIPMENT:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case CREATE_PO_SHIPMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: action.payload.msg,
        shipmentModalIsOpen: false,
      };
      break;
    case DELETE_PURCHASE_ORDER:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_PURCHASE_ORDER_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case UPDATE_PURCHASE_ORDER:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPDATE_PURCHASE_ORDER_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case UPDATE_PURCHASE_ORDER_ITEM:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPDATE_PURCHASE_ORDER_ITEM_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case UPDATE_PURCHASE_ORDER_SHIPMENT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPDATE_PURCHASE_ORDER_SHIPMENT_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message, shipmentModalIsOpen: false };
      break;
    case DELETE_PURCHASE_ORDER_ITEM:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_PURCHASE_ORDER_ITEM_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case DELETE_PURCHASE_ORDER_SHIPMENT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_PURCHASE_ORDER_SHIPMENT_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case SET_ADJUSTMENT_MODAL:
      state = { ...state, success: "", error: "", adjustmentModalIsOpen: action.payload };
      break;
    case SET_SHIPMENT_MODAL:
      state = { ...state, success: "", error: "", shipmentModalIsOpen: action.payload };
      break;
    case SET_PO_LOGS_MODAL:
      state = { ...state, success: "", error: "", POLogsModalIsOpen: action.payload };
      break;

    // Inbounds
    case FETCH_INBOUND_ITEMS:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case FETCH_INBOUND_ITEMS_SUCCESS:
      state = { ...state, success: action.payload.message, loading: false, inboundsData: action.payload };
      break;
    case SET_ADD_SHIPMENT_MODEL:
      state = { ...state, addShipmentModal: action.payload, error: "" };
      break;
    case SET_RECEIVED_SHIPMENT_MODEL:
      state = { ...state, receivedShipmentModel: action.payload, error: "" };
      break;
    case SET_STORE_BINS_MODEL:
      state = { ...state, storeBinsModel: action.payload };
      break;
    case ADD_SHIPMENT:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case ADD_SHIPMENT_SUCCESS:
      state = {
        ...state,
        success: action.payload.success,
        addShipmentModal: !action.payload.success,
        loading: false,
        message: action.payload.message,
        shipment: action.payload.data,
        isLiquidated: action.payload.is_liquidated,
      };
      break;
    case types.FETCH_INBOUND_IS_LIQUATED:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case types.FETCH_INBOUND_IS_LIQUATED_SUCCESS:
      state = { ...state, success: action.payload.message, loading: false, isLiquidated: action.payload.data?.is_liquidated };
      break;
    case types.RESET_INBOUND_IS_LIQUATED:
      state = { ...state, isLiquidated: false };
      break;
    case SHIPMENT_CHECKED_IN:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case SHIPMENT_CHECKED_IN_SUCCESS:
      state = {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
        error: "",
        loading: false,
        data: action.payload,
      };
      break;
    case RECEIVE_SHIPMENT:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case RECEIVE_SHIPMENT_SUCCESS:
      state = {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
        error: "",
        loading: false,
        receivedShipment: action.payload.data,
      };
      break;
    case SET_INBOUND_SHIPMENT:
      state = { ...state, inboundShipment: action.payload };
      break;
    case FETCH_WH_INBOUND_STATS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_WH_INBOUND_STATS_SUCCESS:
      state = { ...state, loading: false, success: "", error: "", whInboundStats: action?.payload };
      break;
    case RESET_FETCH_RATES_ERR:
      state.error = "";
      break;
    case RESET_RATES:
      state.getAllShippingRates = {};
      break;
    case UPDATE_INVENTORY_ITEM_BINS:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case UPDATE_INVENTORY_ITEM_BINS_SUCCESS:
      state = { ...state, success: action.payload.message, error: "", loading: false, binNoModalIsOpen: false };
      break;
    case DELETE_INVENTORY_ITEM_BIN:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case DELETE_INVENTORY_ITEM_BIN_SUCCESS:
      state = { ...state, success: action.payload.message, error: "", loading: false };
      break;
    case BULK_UPDATE_INVENTORY_ITEM:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case BULK_UPDATE_INVENTORY_ITEM_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case SEARCH_ORDER:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case SEARCH_ORDER_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message, shipments: action.payload };
      break;

    case UPDATE_TRACKING_ITEM_SHIPPPING_STATUS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPDATE_TRACKING_ITEM_SHIPPPING_STATUS_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;

    // Warehouse Invetory items
    case FETCH_WH_INVENTORY:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case FETCH_WH_INVENTORY_SUCCESS:
      state = { ...state, success: action.payload.message, loading: false, whInvetoryData: action.payload };
      break;
    case types.FETCH_INVENTORY_SHIPMENT_WISE:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case types.FETCH_INVENTORY_SHIPMENT_WISE_SUCCESS:
      state = { ...state, success: action.payload.message, loading: false, whInvetoryData: action.payload };
      break;
    case FETCH_WH_INVENTORY_STATS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_WH_INVENTORY_STATS_SUCCESS:
      state = { ...state, loading: false, success: "", error: "", whInventoryStats: action?.payload?.data };
      break;
    case SET_BIN_DETAIL_MODAL:
      state = { ...state, success: "", error: "", setBinDetailModalIsOpen: action.payload };
      break;
    case types.SET_ITEM_ACTIONS_MODAL:
      state = { ...state, success: "", error: "", setItemActionsModalIsOpen: action.payload };
      break;
    case types.FETCH_WH_INVENTORY_ACTIONS:
      state = { ...state, loading: true, success: "", error: "", orderId: "" };
      break;
    case types.FETCH_WH_INVENTORY_ACTIONS_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message, setItemActionsModalIsOpen: false, orderId: action.payload.order_id };
      break;
    case types.UPDATE_INVENTORY_ITEM_PRICE:
      state = { ...state, inventoryPriceUpdateloading: true, success: "", error: "" };
      break;
    case types.UPDATE_INVENTORY_ITEM_PRICE_SUCCESS: {
      const bufferItem = state.whInvetoryData.inventoryItems?.find((x) => x.id === action.payload.inventory.id);
      merge(bufferItem, action.payload.inventory);
      state = { ...state, inventoryPriceUpdateloading: false, error: "", success: action.payload.message };
      break;
    }
    case types.UPDATE_INVENTORY_OVERSIZED:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.UPDATE_INVENTORY_OVERSIZED_SUCCESS:
      state = { ...state, success: "Updated Successfully", error: "", loading: false };
      break;
    case types.UPDATE_WH_INVENTORY_BIN:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.UPDATE_WH_INVENTORY_BIN_SUCCESS:
      state = { ...state, error: "", success: action.payload.message, loading: false };
      break;
    case types.DELETE_WH_INVENTORY_BIN:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.DELETE_WH_INVENTORY_BIN_SUCCESS:
      state = { ...state, error: "", success: action.payload.message, loading: false };
      break;
    case types.CREATE_WH_INVENTORY_BIN:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.CREATE_WH_INVENTORY_BIN_SUCCESS:
      state = { ...state, error: "", success: action.payload.message, loading: false };
      break;
    case types.DELETE_INVENTORY_ITEM:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.DELETE_INVENTORY_ITEM_SUCCESS:
      state = { ...state, error: "", success: action.payload.message, loading: false };
      break;
    case types.FETCH_STRANDED_COUNT:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case types.FETCH_STRANDED_COUNT_SUCCESS:
      state = { ...state, success: action.payload.message, loading: false, strandedCount: action.payload };
      break;
    // Warehouse Outbound items
    case FETCH_WH_OUTBOUNDS:
      state = { ...state, success: "", error: "", loading: true, data: action.payload };
      break;
    case FETCH_WH_OUTBOUNDS_SUCCESS:
      state = { ...state, success: action.payload.message, loading: false, whOutboundData: action.payload };
      break;
    case GENERATE_SHIPPING_LABEL_TOGGLE:
      state = { ...state, error: "", generateShippingLabelToggleIsOpen: action.payload, getAllShippingRates: {} };
      break;
    case FETCH_WH_OUTBOUND_STATS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_WH_OUTBOUND_STATS_SUCCESS:
      state = { ...state, loading: false, success: "", error: "", whOutboundStats: action?.payload?.data };
      break;
    case FETCH_LABEL_USERS_AND_COUNT:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case FETCH_LABEL_USERS_AND_COUNT_SUCCESS:
      state = { ...state, loading: false, labelGenerationStats: action.payload };
      break;
    case types.MARK_AS_COMPLETE_ITEM:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.MARK_AS_COMPLETE_ITEM_SUCCESS:
      state = { ...state, loading: false, success: action.payload.msg };
      break;
    case types.BULK_MARK_AS_COMPLETE:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.BULK_MARK_AS_COMPLETE_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case types.RESET_SUCCESS_ERROR:
      state = { ...state, success: "", error: "", orderId: "" };
      break;
    case types.DOWNLOAD_OUTBOUND_LABEL:
      state = { ...state, success: "", error: "" };
      break;
    case types.DOWNLOAD_OUTBOUND_LABEL_SUCCESS:
      state = { ...state, filePath: action.payload?.url };
      break;
    case types.UPDATE_RETURNS_OUTBOUND:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case types.UPDATE_RETURNS_OUTBOUND_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.message,
      };
      break;
    // Scanforms
    case types.FETCH_SHIPPING_SCANFORMS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.FETCH_SHIPPING_SCANFORMS_SUCCESS:
      state = { ...state, loading: false, scanforms: action.payload };
      break;
    case types.GENERATE_SCANFORM:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.GENERATE_SCANFORM_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message, scanformModalIsOpen: false };
      break;
    case types.SET_GENERATE_SCANFORM_MODAL:
      state = { ...state, error: "", success: "", loading: false, scanformModalIsOpen: action.payload };
      break;
    case types.DOWNLOAD_FORM_FILE:
      state = { ...state, error: "", success: "" };
      break;
    case types.DOWNLOAD_FORM_FILE_SUCCESS:
      state = { ...state, success: action.payload?.message || "", filePath: action.payload?.url };
      break;
    case types.EMPTY_DOWNLOAD_FORM_FILE:
      state = { ...state, filePath: "" };
      break;
    case types.FETCH_SCANFORM_SHIPMENTS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.FETCH_SCANFORM_SHIPMENTS_SUCCESS:
      state = { ...state, loading: false, scanformShipments: action.payload };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        chargesLoading: false,
        inventoryPriceUpdateloading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default WareHouses;
