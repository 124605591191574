import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, Row, Col } from "reactstrap";
import { currentUser, capitalize, getUserInfo } from "../../../utils/common";
import Countdown from "react-countdown";

// redux actions
import { logoutUser, getVerificationLink, resetRegReducer, setPreloader } from "../../../store/actions";

// import images
import logoLight from "../../../assets/svg/logo.svg";
import logoDark from "../../../assets/svg/logoDark.svg";

const Welcome = (props) => {
  const dispatch = useDispatch();
  const [isTimerFinished, setIsTimerFinished] = useState(true);

  const { verifyLinkApiSuccess, verifyLinkApiError, loading } = useSelector((state) => state.Account);

  function genVerificationEmail () {
    setIsTimerFinished(false);
    dispatch(getVerificationLink());
  }

  useEffect(() => {
    dispatch(resetRegReducer());
  }, []);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  const logo = localStorage.getItem("THEME") === "light" ? logoLight : logoDark;
  return (
    <React.Fragment>
      <Card className="mb-0">
        <Row className="px-4 pt-4">
          <Col md={12}>
            <div>
              <img src={logo} alt={"Ecom Circles"} height="31"></img>
              <h6 className="pt-3">Welcome to Ecom Circles, {currentUser() && capitalize(currentUser())}!</h6>
            </div>
            {props.account?.data?.onboard?.confirm_email
              ? (
              <>
                <div className="d-flex align-items-center mb-4">
                  <i className="bx bx-sm pr-2 bxs-check-circle text-success"></i>
                  <small className="text-muted">Verfied</small>
                </div>
              </>
                )
              : (
              <>
                <div className="d-flex flex-column">
                  <small className="text-muted">
                    Please verify your account via verfication link sent to your email.
                  </small>
                  {verifyLinkApiSuccess && <h6 className="py-1">Verification email sent to {getUserInfo()?.email}</h6>}
                  {verifyLinkApiError && <h6 className="text-danger py-1">{verifyLinkApiError}!</h6>}
                </div>
                <div className="float-right">
                  <button
                    onClick={() => dispatch(logoutUser(props.history))}
                    className="btn btn-primary w-md btn-sm m-2 waves-effect waves-light btn btn-primary"
                  >
                    Log Out
                  </button>

                  {isTimerFinished
                    ? (
                    <button
                      onClick={genVerificationEmail}
                      className="btn btn-primary w-md btn-sm m-2 waves-effect waves-light btn btn-primary"
                    >
                      Send Email
                    </button>
                      )
                    : (
                    <Countdown date={Date.now() + 300000} onComplete={() => setIsTimerFinished(true)} />
                      )}
                </div>
              </>
                )}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(Welcome);

Welcome.propTypes = {
  setActiveStep: PropTypes.func,
};
