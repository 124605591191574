import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Alert, Card } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import Dropzone from "react-dropzone";
import "flatpickr/dist/themes/dark.css";

// actions
import { setPreloader, setUploadProxyModal, uploadProxyCsv } from "../../../store/actions";

const UploadProxyModal = (props) => {
  const { error, setUpLoadProxyModalIsOpen, success, loading } = useSelector((state) => state.ProxyUsers);

  const toggleModal = () => dispatch(setUploadProxyModal(!setUpLoadProxyModalIsOpen));

  const [selectedFile, setselectedFile] = useState([]);

  const dispatch = useDispatch();

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  //! UseEffects
  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  const uploadFile = () => {
    dispatch(uploadProxyCsv({ data: selectedFile[0] }));
    setselectedFile([]);
  };

  return (
    <React.Fragment>
      <Modal size="md" isOpen={setUpLoadProxyModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Upload Return Label File</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          )}

          {success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {"Proxy File successfully uploaded" || ""}
              </Alert>
            </div>
          )}
          {/* Form Start */}
          <form className="m-2">
            <Col className="my-1">
              {selectedFile.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFile) => {
                    handleAcceptedFile(acceptedFile);
                  }}
                  accept=".csv"
                  maxFiles="1"
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div className="dz-message needsclick mt-2" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                        </div>
                        <h4>Drag or Click to Upload CSV File</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFile.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col>
                            {f.name}
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                            <p className="mb-0"></p>
                          </Col>
                          <i
                            title="Remove File"
                            className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                            onClick={() => setselectedFile([])}
                          ></i>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
              {selectedFile.length !== 0 && (
                <div className="text-center mt-4">
                  <button type="button" className="btn btn-primary waves-effect waves-light" onClick={uploadFile}>
                    Upload File
                  </button>
                </div>
              )}
            </Col>
          </form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UploadProxyModal;
