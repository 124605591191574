import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Container, Card, CardBody, CardHeader, Input, Alert, Badge } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomPagination from "../../components/Common/CustomPagination";
import SendInvoiceModal from "./components/SendInvoiceModal";
import PillBtn from "../../components/Common/PillBtn";
import { fetchSharedListingData, setPreloader, setSendInvoiceModal } from "../../store/actions";
import { pick, startCase } from "lodash";
import moment from "moment";
import { toPascalCase, downloadFile } from "../../utils/common";

function calculateTotal (arr, col = "profit") {
  return arr.reduce((sum, item) => sum + item[col], 0);
}

const STATUS_COLOR_CODE = { pending: "primary", exported: "success" };

function FiftyFiftyProfit (props) {
  const [pagination, setPagination] = useState({ page: 1, per_page: 100 });
  const [date, setDate] = useState(moment().format("YYYY-MM"));
  const dispatch = useDispatch();
  const { sharedListings, loading } = useSelector((state) => state.FiftyFiftyProfit);

  useEffect(() => {
    dispatch(fetchSharedListingData({ ...pagination, date }));
  }, [pagination, date]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <React.Fragment>
      {props.FiftyFiftyProfit.sendInvoiceModalIsOpen && (
        <SendInvoiceModal
          isOpen={props.FiftyFiftyProfit.sendInvoiceModalIsOpen}
          date={date}
          toggleIsOpen={props.setSendInvoiceModal}
        />
      )}
      <div className="page-content">
        <Container fluid>
          {props.FiftyFiftyProfit?.success && !props.isLoading && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.FiftyFiftyProfit?.success || ""}
              </Alert>
            </div>
          )}
          <Breadcrumbs title="50/50 Orders Matches Report" />
          <Card>
            <CardHeader>
              <div className="row d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <div className="d-flex align-items-center ml-3" style={{ width: "200px" }}>
                    <Input
                      type="month"
                      defaultValue={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="form-control"
                      placeholder="Select Month"
                    />
                  </div>
                </div>
                <div>
                  {Array.isArray(sharedListings?.data)
                    ? (
                    <h4 className="ml-3 mt-1">Total Profit: {calculateTotal(sharedListings?.data).toLocaleString()}</h4>
                      )
                    : null}
                </div>
                <div className="d-flex justify-content-end">
                  <PillBtn
                    className="ml-3"
                    color="primary"
                    title="Send Invoice"
                    name="Send Invoice"
                    icon="bx bx bxs-send ml-1"
                    onClick={() => {
                      props.setSendInvoiceModal(true);
                    }}
                  />
                  <PillBtn
                    className="mx-3"
                    color="primary"
                    title="Export to CSV"
                    name="Export to CSV"
                    icon="bx bx-xs bx-cloud-download ml-1"
                    onClick={() => {
                      const allData = sharedListings?.data.map((x) =>
                        pick(x, ["marketplace_order_id", "cost", "price", "profit"]),
                      );
                      allData.push({
                        email: "Total",
                        last_used_credits: calculateTotal(sharedListings?.data),
                        last_rewarded: "",
                      });
                      downloadFile(allData, "_credits_invoice");
                    }}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {Array.isArray(sharedListings?.data) && sharedListings.total
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {Object.keys(sharedListings?.data[0]).map((title, key) =>
                            !/source_order/i.test(title) ? <th key={key}>{startCase(title)}</th> : null,
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(sharedListings?.data)
                          ? sharedListings.data.map((item, key) => (
                              <tr key={"_history_" + key}>
                                <td>{item.marketplace_order_id}</td>
                                <td>{item.cost.toLocaleString()}</td>
                                <td>{item.price.toLocaleString()}</td>
                                <td>{item.profit.toLocaleString()}</td>
                                <td>
                                  <Badge
                                    className={"font-size-14 badge-soft-" + STATUS_COLOR_CODE[item.status]}
                                    color={STATUS_COLOR_CODE[item.status]}
                                    pill
                                  >
                                    {toPascalCase(item.status)}
                                  </Badge>
                                </td>
                              </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <CustomPagination
                    total={sharedListings.total}
                    pageOptions={[20, 50, 100]}
                    page={sharedListings.page}
                    perPage={sharedListings.perPage}
                    tabsFilter={pagination}
                    setTabFilter={(e) =>
                      setPagination({
                        ...pagination,
                        page: pagination.per_page !== e.per_page ? 1 : e.page,
                        per_page: e.per_page,
                      })
                    }
                  />
                </>
                  )
                : null}
              {sharedListings?.total === 0 && !loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { FiftyFiftyProfit } = state;
  return { FiftyFiftyProfit };
};

export default connect(mapStatetoProps, {
  setPreloader,
  setSendInvoiceModal,
})(FiftyFiftyProfit);
