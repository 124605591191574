import React from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";

const ReportDownloadMsg = ({ action }) => {
  const dispatch = useDispatch();
  return (
    <Alert color="success">
      <i className="bx bx-info-circle pr-2"></i>
      <span>
        We are processing your report request. The report will be available soon for downloading{" "}
        <a
          href="/exported_reports"
          onClick={() => {
            if (!action) return null;
            setTimeout(() => dispatch(action(false)), 2000);
          }}
        >
          here
        </a>
      </span>
    </Alert>
  );
};

export default ReportDownloadMsg;
