import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Row, Col, Button } from "reactstrap";
import "react-toggle/style.css";
import WarehouseImg from "../../../assets/svg/warehousellustration.svg";
import Modal from "../../../components/Common/Modal";

// actions
import { updateMarketplaceAccounts, setTwoStepModal, fetchAllWarehouses } from "../../../store/actions";

const TwoStepPopUp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { associatedWarehouses } = useSelector((state) => state.WareHouses);

  const toggleModal = () => {
    dispatch(setTwoStepModal(false));
    dispatch(updateMarketplaceAccounts(props.data));
  };

  const LineBreaks = ({ num }) => [...Array(num)].map((_, i) => <br key={`__line_breaks_${i}`} />);

  useEffect(() => {
    !associatedWarehouses?.data?.length && dispatch(fetchAllWarehouses({ associated: true }));
  }, []);

  return (
    <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">You've Just Enabled 2-Step!</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12 mb-2">
            <img src={WarehouseImg} alt="warehouse-img" />
            <LineBreaks num={3} />
            <p>
              <span>
                From now on, the system will expect that your orders will ship to a warehouse first and then from that
                warehouse to the customer.
              </span>
              <LineBreaks num={2} />
              <span>
                Your next step is to select one of our warehouses to receive your items and ship them out (or you can
                add your own address if you will ship from your house or your own warehouse).
              </span>
              <LineBreaks num={2} />
              You can select a warehouse or add an address.
            </p>
          </Col>
        </Row>
        {!associatedWarehouses?.data?.length && (
          <Row className="d-flex justify-content-center mt-3">
            <Button type="reset" color="primary" className="mr-3" onClick={() => history.push("/warehouse")}>
              Setup Warehouse
            </Button>
          </Row>
        )}
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default TwoStepPopUp;
