import { concat, merge, pick } from "lodash";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Label, FormGroup, Spinner } from "reactstrap";
import { getUserInfo, RenderIf } from "../../../utils/common";
import renderErrors from "./RenderErrors";
import Select, { components } from "react-select";
import getBrandImageSrc from "../../../utils/brandImages";
import { getAvailableSettlementReports } from "../../../store/actions";
import { DATE_FORMATS, MARKET_PLACES } from "../../../constants";

const makeStoreOptions = (props) => {
  const isAgency = getUserInfo()?.is_agency;
  const reducer = (arr, acc, i) =>
    acc.valid && [MARKET_PLACES.amazon, MARKET_PLACES.walmart].includes(acc.marketplace)
      ? concat(arr, { label: acc.name, value: acc.id, marketplace: acc.marketplace, key: i })
      : arr;
  return props[isAgency ? "agencyAccounts" : "accounts"]?.data.reduce(reducer, []);
};

const toDate = (row) => row.date;
const formatDate = (date) => moment(date, "MMDDYYYY").format(DATE_FORMATS.DATE);
const sortDates = (dates, toDate) =>
  dates.sort((date1, date2) => moment(toDate(date2), "MMDDYYYY") - moment(toDate(date1), "MMDDYYYY"));

const DropdownItem = (props) => (
  <components.Option {...props}>
    <RenderIf isTrue={getBrandImageSrc(props.data.marketplace)}>
      <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
    </RenderIf>
    {props.data.label}
  </components.Option>
);

const SettlementReportForm = (props) => {
  const { formik } = props;
  const isAmz = formik.values?.store?.marketplace === MARKET_PLACES.amazon;

  useEffect(() => {
    formik.values.store?.value && props.getAvailableSettlementReports({ storeId: formik.values.store?.value });
  }, [formik.values.store]);

  return (
    <Col className="my-1">
      <RenderIf
        isTrue={makeStoreOptions(props)?.length}
        fallback={
          <div className="d-flex justify-content-center m-2">
            <div className="d-flex flex-column">
              <h5>{"Kindly add a valid store to use this feature"}</h5>
            </div>
          </div>
        }
      >
        <Label>
          Marketplace Account
          <RenderIf
            isTrue={props.reportsLoading}
            children={<Spinner color="secondary" style={{ width: "20px", height: "20px" }} className="ml-3" />}
          />
          {renderErrors(formik, ["store"])}
        </Label>
        <FormGroup className="select2-container w-100">
          <Select
            name="storeId"
            value={formik.values.store}
            onChange={(e) => formik.setFieldValue("store", e)}
            options={makeStoreOptions(props)}
            classNamePrefix="select2-selection"
            height={{ maxHeight: "250px" }}
            components={{ Option: DropdownItem }}
          />
        </FormGroup>
      </RenderIf>
      <RenderIf isTrue={formik.values.store?.value && props.settlementReports?.length}>
        <Label>Select Report {renderErrors(formik, ["reportId"])}</Label>
        <FormGroup className="select2-container w-100">
          <Select
            name="reportId"
            value={formik.values.reportId}
            onChange={(e) => formik.setFieldValue("reportId", e)}
            options={sortDates(props.settlementReports, toDate).map(
              (row, i) => ({ label: formatDate(toDate(row)), value: row[isAmz ? "reportId" : "date"], key: i }),
              [],
            )}
            classNamePrefix="select2-selection"
          />
        </FormGroup>
        {renderErrors(formik, ["storeOrderId"])}
      </RenderIf>
    </Col>
  );
};

const mapStateToProps = (state) =>
  merge(
    {},
    pick(state.MarketPlaceAccount, "accounts", "agencyAccounts"),
    pick(state.Report, "reportsLoading", "settlementReports"),
  );

export default withRouter(connect(mapStateToProps, { getAvailableSettlementReports })(SettlementReportForm));
