import React, { useState } from "react";
import Modal from "../../../components/Common/Modal";
import { FormGroup } from "reactstrap";
import { useSelector } from "react-redux";
import {
  ACCOUNT_STATUSES,
} from "../../../constants/index";
import Select from "react-select";
import { map, size, sortBy } from "lodash";

export default function SelectTenantsModel ({ open, setOpen, loadDump }) {
  const { allTenants, account } = useSelector((state) => state.Settings);
  const [selectedOptions, setSelectedOptions] = useState([]);

  function getUsername (tenant) {
    if (Array.isArray(tenant?.users)) return tenant?.users?.find((x) => x.email === tenant.email)?.username;
    else if (Array.isArray(tenant.AccountUser) && tenant.AccountUser[0].username) return tenant.AccountUser[0].username;
    return "";
  }

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  function makeOpts (allTenants) {
    return sortBy(allTenants, (a) => a.status.length).reduce((acc, tenant, i) => {
      if (account?.data?.agency_id) {
        const isActive = [ACCOUNT_STATUSES.active, ACCOUNT_STATUSES.payment_failed].includes(tenant.status);
        const isValidInActive =
          [ACCOUNT_STATUSES.payment_failed].includes(tenant.status) &&
          (tenant.onboard?.store_added || tenant.stores_count > 0 || tenant.credentials_count > 0);
        if (!isActive && isValidInActive) return acc;
      }

      acc.push({
        label: tenant.email,
        value: tenant.id,
        username: getUsername(tenant),
        status: tenant.status,
        aoStatus: tenant.ao_enabled,
        key: i,
      });
      return acc;
    }, []);
  }

  const handleSubmit = () => {
    loadDump(map(selectedOptions, "value"));
  };
  return (
    <Modal
      size="md"
      className="p-5"
      isOpen={open}
      toggle={(_) => {
        setOpen(!open);
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Select Tenant
        </h5>
        <button onClick={() => setOpen(!open)} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x" />
        </button>
      </div>
      <div className="modal-body">
        {allTenants?.length && (
          <>
            <FormGroup className="select2-container min-width-150">
              <Select
                classNamePrefix="select2-selection"
                isMulti
                options={makeOpts(allTenants)}
                value={selectedOptions}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <button
              className="p-2 float-right btn btn-success"
              disabled={!size(selectedOptions)}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}
