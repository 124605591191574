import React, { useState, useEffect } from "react";
import { Container, Alert } from "reactstrap";
import Countdown from "react-countdown";
// Redux
import { connect } from "react-redux";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";

// utils
import { clearLocalStorage, decode, encode, isBase64Encoded } from "../../utils/common";

// svg icons
import confirmEmail from "../../assets/svg/confirmEmail.svg";

// action
import { sendVerifyToken, setVerifyError, verifyUser, verifyAgency } from "../../store/actions";

const EmailVerification = (props) => {
  const history = useHistory();
  const { isAgency } = props;
  const { email, token } = useParams();
  const decodedEmail = email ? isBase64Encoded(email) ? decode(email) : email : "";
  const [isTimerFinished, setIsTimerFinished] = useState(true);

  const { exp, email: tokenEmail, type } = parseToken(token);
  const isExpired = exp && new Date(token.exp) < new Date();

  function handleSendNewToken () {
    setIsTimerFinished(false);
    decodedEmail && props.sendVerifyToken(decodedEmail);
  }

  function parseToken (token) {
    try {
      return JSON.parse(decode(token || "", 3) || "{}");
    } catch (error) {
      !props.verifyError && props.setVerifyError("Link invalid.");
      return {};
    }
  }

  useEffect(() => {
    props.setVerifyError("");
    if (exp && isExpired) props.setVerifyError("Verification link expired.");
    if (token && !exp) props.setVerifyError("Link invalid.");

    if (exp && !isExpired && type?.includes("Agency")) props.verifyAgency(token);
    else if (exp && !isExpired) props.verifyUser(token, history);
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container className="text-center">
          <div className="p-2">
            <div className="mt-2">
              {props.verifyError
                ? (
                <>
                  <Alert color="danger" style={{ marginTop: "13px" }}>
                    {props.verifyError}
                  </Alert>
                  {tokenEmail && (
                    <span>
                      <small>
                        <Link
                          to={`/notification/${encode(tokenEmail)}`}
                          onClick={handleSendNewToken}
                          className="font-weight-medium text-primary"
                        >
                          Send a new one!
                        </Link>
                      </small>
                    </span>
                  )}
                </>
                  )
                : null}
              {props.verifySuccess
                ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {props.verifySuccess}
                </Alert>
                  )
                : null}
            </div>
          </div>
          {((exp && isExpired) || decodedEmail) && (
            <>
              <img src={confirmEmail} alt="Confirm Email" />
              <h1 className="font-weight-bold">You haven't verified your email yet.</h1>
              <h6 className="font-weight-bold">{decodedEmail}</h6>
              <span className="text-muted">
                Please check the email we have sent to you for confirmation, If not found please check spam folder.
              </span>
              <div className="mt-2">
                <span>
                  <small>
                    <strong>Did not receive email?</strong>{" "}
                    {isTimerFinished
                      ? (
                      <Link to="#" onClick={handleSendNewToken} className="font-weight-medium text-primary">
                        Send it again!
                      </Link>
                        )
                      : (
                      <Countdown date={Date.now() + 300000} onComplete={() => setIsTimerFinished(true)} />
                        )}
                  </small>
                </span>
              </div>
            </>
          )}
          {((exp && isExpired) || type?.includes("Agency")) && (
            <>
              <img src={confirmEmail} alt="Confirm Email" />
              {type === "addAgency"
                ? (
                <h1 className="font-weight-bold">You account has been successfully linked to Agency</h1>
                  )
                : (
                <h1 className="font-weight-bold">You successfully linked account to your Agency</h1>
                  )}
            </>
          )}
          <div className="mt-4">
            <p>
              Already Validated?{" "}
              <Link to="/forgot_pass/:token" className="font-weight-medium text-primary">
                Reset Password
              </Link>
            </p>
          </div>
          {!isAgency && (
            <div className="mt-2 text-center font-weight-bold">
              <p>
                <Link to="/login" className="text-primary" onClick={() => clearLocalStorage()}>
                  {" "}
                  Sign in
                </Link>{" "}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user, verifyError, verifySuccess } = state.Account;
  return { user, verifyError, verifySuccess };
};

export default withRouter(
  connect(mapStateToProps, {
    sendVerifyToken,
    setVerifyError,
    verifyUser,
    verifyAgency,
  })(EmailVerification),
);
