import React from "react";
import { Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

// Components
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Actions
import { updatePaymentSourceSettings } from "../../../store/Agency/actions";

// Constants
import StorageCheckbox from "./StorageCheckBox";

const StorageChargesSettings = (_) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.Settings);
  return (
    <React.Fragment>
      <Breadcrumbs title="Storage Charges Settings" />
      <Card>
        <CardBody>
          <StorageCheckbox
            msg="all the upcoming clients of this agency"
            className="pb-2"
            val={account?.data?.is_storage_exempt}
            onConfirm={(val) =>
              dispatch(updatePaymentSourceSettings({ data: { isAll: true, is_storage_exempt: val } }))
            }
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default StorageChargesSettings;
