import * as types from "./actionTypes";
import { omit, filter } from "lodash";
const {
  FETCH_AO_ECS_TASKS,
  FETCH_AO_ECS_TASKS_SUCCESS,
  FETCH_ACCOUNTS_ORDERS_HISTORY,
  FETCH_ACCOUNTS_ORDERS_HISTORY_SUCCESS,
  API_ERROR,
} = types;

const AOScreen = (state, action) => {
  switch (action.type) {
    case FETCH_AO_ECS_TASKS:
      state = { ...state, loading: true, taskId: "" };
      break;
    case FETCH_AO_ECS_TASKS_SUCCESS:
      state = { ...state, tasks: action.payload.data, loading: false };
      break;
    case FETCH_ACCOUNTS_ORDERS_HISTORY:
      state = { ...state, loading: true };
      break;
    case FETCH_ACCOUNTS_ORDERS_HISTORY_SUCCESS:
      state = {
        ...state,
        accountOrdersHistory: action.payload.data,
        paginationData: omit(action.payload, "data"),
        loading: false,
      };
      break;
    case types.STOP_ECS_TASK:
      state = { ...state, taskId: action.payload };
      break;
    case types.STOP_ECS_TASK_SUCCESS:
      state = {
        ...state,
        taskId: "",
        message: "Task stopped successfully.",
        tasks: filter(state.tasks, (task) => task.taskArn.split("/").pop() !== action.payload),
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, taskId: "" };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AOScreen;
