import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, Input, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import "flatpickr/dist/themes/dark.css";
import Modal from "../../../components/Common/Modal";
import Select from "react-select";
// actions
import {
  setReturnAddressModal,
  updateAccountSettings,
  setSettingMessage,
  updateTrackingInfo,
  setPreloader,
  fetchReturnLabelHistory,
} from "../../../store/actions";
import { State } from "country-state-city";
import { getUserInfo } from "../../../utils/common";
import { values as vals, keys, isEqual, pick } from "lodash";
import CheckBox from "../../../components/Common/CheckBox";
import PillBtn from "../../../components/Common/PillBtn";

const getState = (country, address) => State.getStatesOfCountry(country)?.find((x) => x.isoCode === address.state);
const reqiredCols = ["name", "address1", "city", "phone", "zipcode"];

function ShowAlert ({ history }) {
  const mapping = {
    pending: { color: "success", content: "Return Label Generation is in progress" },
    failed: { color: "danger", content: "Return Label cannot be generated" },
  };
  if (!history?.status || !mapping[history.status]) return <></>;
  const alert = mapping[history.status];
  const content = history.status === "failed" && history.note ? history.note : alert.content;
  return <Alert color={alert.color}>{content}</Alert>;
}

const ReturnAddressModal = (props) => {
  const toggleModal = () => props.setReturnAddressModal(!props.Settings.returnAddressModal);

  const defaultAddress = props.Settings.account?.data?.return_address;
  const [useDefautlAddress, setUseDefaultAddress] = useState(false);

  const { order, customerAddress } = props;
  let { returnAddress } = props;

  let isAddModal = vals(returnAddress).filter((x) => x).length < 6;
  if (order?.return_url && isAddModal) {
    returnAddress = customerAddress;
    isAddModal = false;
  }

  const disableField = !isAddModal && order;

  const country = !returnAddress.country || returnAddress.country === "USA" ? "US" : returnAddress.country;
  const selectedState = getState(country, returnAddress);
  const initialValues = {
    name: returnAddress.name || "",
    address1: returnAddress.address1 || "",
    address2: returnAddress.address2 || "",
    city: returnAddress.city || "",
    state: { label: selectedState?.name || "Select State", value: selectedState?.isoCode || "" },
    country,
    zipcode: returnAddress.zipcode || "",
    phone: returnAddress.phone || "",
  };
  if (order) initialValues.default = false;

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      if (useDefautlAddress) return errors;
      reqiredCols.forEach((x) => !values[x] && (errors[x] = "is required"));
      !values.state.value && (errors.state = "is required");
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const { default: isDefault } = values;
      delete values.default;
      const data = { ...values };
      data.state = data.state.value;
      if (isEqual(data, returnAddress) && props.returnLabelHistory?.status !== "failed") {
        props.setSettingMessage({ warning: "No Changes Made" });
        toggleModal();
        return;
      }
      const accountId = props.Settings.account?.data?.id || getUserInfo()?.account_id;
      if (order) {
        props.updateTrackingInfo({
          data: { return_address: data, retry: props.returnLabelHistory?.status === "failed" },
          targetOrderId: order.target_order_id,
        });
      }
      if (isDefault || !order) props.updateAccountSettings({ return_address: data }, accountId);
    },
  });
  const { values } = formik;

  function CLabel (props) {
    const { children, _key } = props;
    const error = formik.touched[_key] ? formik.errors[_key] : "";
    return (
      <>
        <Label className={error ? "text-danger" : ""}>{children}</Label>
        {error ? <small className="text-danger m-1">{error}</small> : null}
      </>
    );
  }

  const cols = {
    name: "Name",
    phone: "Phone",
    address1: "Address 1",
    address2: "Address 2",
    state: "State",
    city: "City",
    zipcode: "Zip",
  };

  useEffect(() => {
    props.setPreloader(props.Settings.loading);
  }, [props.Settings.loading]);

  useEffect(() => {
    props.setPreloader(props.orderLoading);
  }, [props.orderLoading]);

  useEffect(() => {
    order?.target_order_id &&
      !order?.return_url &&
      disableField &&
      props.fetchReturnLabelHistory(order.target_order_id);
  }, []);

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={props.Settings.returnAddressModal} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">{isAddModal ? "Add" : !disableField ? "Edit" : ""} Return Address</h5>
          {order?.return_url
            ? (
            <div className="m-auto">
              <a className="btn btn-primary" href={order.return_url} target="_blank" rel="noopener noreferrer" download>
                Download Return Label
              </a>
            </div>
              )
            : null}
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        {!order?.return_url && disableField ? <ShowAlert history={props.returnLabelHistory} /> : null}
        {!disableField && order
          ? (
          <Row className="d-flex justify-content-around mt-3">
            {defaultAddress
              ? (
              <PillBtn
                color="success"
                title="Default Address"
                name="Default Address"
                className="ml-1"
                onClick={() => {
                  const data = { ...defaultAddress };
                  setUseDefaultAddress(true);
                  data.state = { label: getState(country, data)?.name, value: data.state };
                  keys(data).forEach((col) => formik.setFieldValue(col, data[col] || ""));
                }}
              />
                )
              : null}
            {customerAddress
              ? (
              <PillBtn
                color="success"
                title="Customer Address"
                name="Customer Address"
                className="ml-1"
                onClick={() => {
                  const data = { ...pick(customerAddress, ...keys(initialValues)) };
                  setUseDefaultAddress(false);
                  data.state = { label: getState(country, data)?.name, value: data.state };
                  keys(data).forEach((col) => formik.setFieldValue(col, data[col] || ""));
                }}
              />
                )
              : null}
            <PillBtn
              color="primary"
              title="Add New Address"
              name="Add New Address"
              className="ml-1"
              onClick={() => {
                setUseDefaultAddress(false);
                keys(initialValues).forEach((col) => formik.setFieldValue(col, initialValues[col] || ""));
              }}
            />
          </Row>
            )
          : null}
        <div className="modal-body">
          {props.Settings.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.Settings.error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              {keys(cols).map((col, _i) => (
                <Col lg="6 mt-2" key={_i}>
                  <CLabel _key={col}>{cols[col]}</CLabel>
                  {col === "state"
                    ? (
                    <FormGroup className="select2-container">
                      {disableField
                        ? (
                        <Input
                          name={col}
                          value={getState(country, { state: formik.values[col]?.value })?.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={disableField}
                        />
                          )
                        : (
                        <Select
                          name={col}
                          value={formik.values[col]}
                          onChange={(option) => {
                            formik.setFieldValue(col, option);
                          }}
                          options={State.getStatesOfCountry(values.country).map((x) => ({
                            label: x.name,
                            value: x.isoCode,
                          }))}
                          classNamePrefix="select2-selection"
                        />
                          )}
                    </FormGroup>
                      )
                    : (
                    <Input
                      name={col}
                      value={formik.values[col]}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      disabled={disableField}
                    />
                      )}
                </Col>
              ))}
              {!useDefautlAddress && !disableField && order
                ? (
                <Col>
                  <CheckBox
                    name="default"
                    state={formik.values.default}
                    setState={(state) => formik.setFieldValue("default", state)}
                    isSwitch={true}
                    className="pt-5"
                    label="Save As Default"
                  />
                </Col>
                  )
                : null}
            </Row>
            {!disableField
              ? (
              <Row className="d-flex justify-content-end mt-3">
                <Button
                  type="reset"
                  color="secondary"
                  className="mr-3"
                  onClick={() => {
                    formik.handleReset();
                    toggleModal();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" color="success" className="mr-3">
                  Generate Return Label
                </Button>
              </Row>
                )
              : null}
            {props.returnLabelHistory?.status === "failed"
              ? (
              <div className="d-flex justify-content-end mt-3">
                <Button type="submit" color="warning" size="lg" className="mr-3">
                  <i className="bx bx-revision" /> Retry
                </Button>
              </div>
                )
              : null}
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    Settings,
    SupplierOrders: { loading: orderLoading, returnLabelHistory },
  } = state;
  return { Settings, orderLoading, returnLabelHistory };
};

export default connect(mapStateToProps, {
  setReturnAddressModal,
  updateAccountSettings,
  setSettingMessage,
  updateTrackingInfo,
  setPreloader,
  fetchReturnLabelHistory,
})(ReturnAddressModal);
