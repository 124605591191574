import { merge } from "lodash";
import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import * as api from "../../api";
import * as $ from "lodash";

// Login Redux States
import * as actions from "./actions";
import * as types from "./actionTypes";

const Repricer = (state) => state.Repricer;

function * fetchListingsSaga ({ payload: params }) {
  try {
    const res = yield call(api.getListings, params);
    yield put(actions.fetchListingsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchUploadFileHistorySaga ({ payload: params }) {
  try {
    const res = yield call(api.getFeedsDetails, params);
    yield put(actions.fetchUploadFileHistorySuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchListingsFormulasSaga ({ payload: { selectedPlatform, params } }) {
  try {
    const res = yield call(api.getListingsFormulas, selectedPlatform, params);
    yield put(actions.fetchListingsFormulasSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchListingDetailsSaga ({ payload: { selectedPlatform, id, mpAccountId } }) {
  try {
    const res = yield call(api.getListingDetails, selectedPlatform, id, mpAccountId);
    yield put(actions.fetchRepricerListingDetailsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchPriceChangeHistories ({ payload }) {
  try {
    const res = yield call(api.getPriceChangeHistories, payload);
    yield put(actions.fetchRepricerPriceChangeHistoriesSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchProductLogs ({ payload }) {
  try {
    const res = yield call(api.getProductLogs, payload);
    yield put(actions.fetchRepricerProductLogsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}
function * fetchHistoryLogs ({ payload, id }) {
  try {
    const res = yield call(api.getHistoryLogs, payload, id);
    yield put(actions.fetchHistoryLogsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * createListingsFormulasSaga ({ payload: { selectedPlatform, params } }) {
  try {
    const res = yield call(api.createListingsFormulas, selectedPlatform, params);
    yield put(actions.createListingsFormulasSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * updateListingsFormulasSaga ({ payload: { selectedPlatform, id, params } }) {
  try {
    const { listingsFormulas } = yield select(Repricer);
    const res = yield call(api.updateListingsFormulas, selectedPlatform, id, params);
    const updatedResults = listingsFormulas?.results.map(item => item.id === res.id ? res : item);
    yield put(actions.updateListingsFormulasSuccess(updatedResults));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * deleteListingsFormulaSaga ({ payload: { selectedPlatform, id } }) {
  try {
    const { listingsFormulas } = yield select(Repricer);
    yield call(api.deleteListingsFormula, selectedPlatform, id);
    const newFormulas = listingsFormulas?.results.filter(item => item.id !== id);
    merge(listingsFormulas, { count: newFormulas.length, results: newFormulas });
    yield put(actions.deleteListingsFormulaSuccess());
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchBuyBoxStatsSaga ({ payload: params }) {
  try {
    const res = yield call(api.getBuyboxStats, params);
    yield put(actions.getBuyboxStatsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * uploadCSVListingsSaga ({ payload: data }) {
  try {
    const res = yield call(api.uploadCSVListings, data);
    yield put(actions.uploadCSVListingsSuccess(res.message));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * FetchListingsStatsSaga ({ payload: data }) {
  try {
    const res = yield call(api.fetchListingsStats, data);
    yield put(actions.fetchListingsStatsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * updateListingSaga ({ payload: data }) {
  try {
    const res = yield call(api.updateListing, data);
    if (res) {
      const { listings } = yield select(Repricer);
      const idx = listings?.results?.findIndex((x) => x.id === res.product.id);
      if (idx !== -1) listings.results[idx] = res.product;
      yield put(actions.updateListingSuccess(data.key === "listed_price" ? "We've received your request and are working on it. The listed price will be updated shortly" : "Product Updated Successfully"));
    }
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * createAndUpdateListingTagSaga ({ payload: { account, name, key, productId } }) {
  try {
    const createdTag = yield call(api.createTag, account.marketplace, { name });
    if (createdTag) {
      const { tags } = yield select(Repricer);
      tags.results.push(createdTag);
      tags.count = tags.results.length;
      yield call(updateListingSaga, { payload: { key, product_id: productId, value: createdTag.id, account } });
    }
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * updateMapPriceListingSaga ({ payload: data }) {
  try {
    const res = yield call(api.updateListingsMapPrice, data);
    if (res) {
      const { listings } = yield select(Repricer);
      const idx = listings?.results?.findIndex((x) => x.id === res.id);
      if (idx !== -1) listings.results[idx] = res;
      yield put(actions.updateMapPriceListingSuccess("Product Updated Successfully"));
    }
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * getTagsSaga ({ payload: { selectedPlatform, params } }) {
  try {
    const res = yield call(api.getTags, selectedPlatform, params);
    yield put(actions.getTagsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * createListingsTagsSaga ({ payload: { selectedPlatform, params, resetFilters = () => null } }) {
  try {
    const res = yield call(api.createTag, selectedPlatform, params);
    yield put(actions.createListingsTagsSuccess(res));
    if (!res.error) resetFilters();
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * updateListingsTagsSaga ({ payload: { selectedPlatform, id, params } }) {
  try {
    const { tags } = yield select(Repricer);
    const res = yield call(api.updateTag, selectedPlatform, id, params);
    const updatedResults = tags?.results.map(item => item.id === res.id ? res : item);
    yield put(actions.updateListingsTagsSuccess(updatedResults));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * deleteListingsTagSaga ({ payload: { selectedPlatform, id, resetFilters = () => null } }) {
  try {
    const res = yield call(api.deleteTag, selectedPlatform, id);
    yield put(actions.deleteListingsTagSuccess());
    if (!res.error) resetFilters();
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * deleteAllTagsSaga ({ payload: { selectedPlatform, params, resetFilters = () => null } }) {
  try {
    const res = yield call(api.deleteAllTags, selectedPlatform, params);
    yield put(actions.deleteAllTagsSuccess(res.message));
    if (!res.error) resetFilters();
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * assignStrategySaga ({ payload: { selectedPlatform, data } }) {
  try {
    const res = yield call(api.assignStrategy, selectedPlatform, data);
    yield res?.msg && put(actions.getStrategiesStats(selectedPlatform));
    yield put(actions.assignStrategySuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * assignListingsStrategySaga ({ payload: { data, params } }) {
  try {
    const extraKeys = ["limit", "offset", "page", "perPage"];
    const filteredParams = $.omit(params, data?.product_ids ? extraKeys : extraKeys.concat("account"));
    const res = yield call(api.assignListingsStrategy, data, filteredParams);
    yield res?.message && fetchListingsSaga({ payload: $.omit(params, "platform") });
    yield put(actions.assignListingsStrategySuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * getAllRepricerAccountsSaga ({ payload: { selectedPlatform, data } }) {
  try {
    const res = yield call(api.getAllRepricerAccounts, selectedPlatform, data);
    yield put(actions.getAllRepricerAccountsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * getStrategiesSaga ({ payload: { selectedPlatform, params: data } }) {
  try {
    const res = yield call(api.getStrategies, selectedPlatform, data);
    yield put(actions.getStrategiesSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * getStrategiesStatsSaga ({ payload: { selectedPlatform, data } }) {
  try {
    const res = yield call(api.getStrategiesStats, selectedPlatform, data);
    yield put(actions.getStrategiesStatsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * createStrategySaga ({ payload: { selectedPlatform, data } }) {
  try {
    const res = yield call(api.createStrategy, selectedPlatform, data);
    yield put(actions.createStrategySuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * updateStrategySaga ({ payload: { selectedPlatform, id, params } }) {
  try {
    const { strategies } = yield select(Repricer);
    const res = yield call(api.updateStrategy, selectedPlatform, id, params);
    const updatedResults = strategies.map(item => item.id === res.id ? res : item);
    yield put(actions.updateStrategySuccess(updatedResults));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * removeStrategySaga ({ payload: { selectedPlatform, id } }) {
  try {
    const { strategies } = yield select(Repricer);
    yield call(api.removeStrategy, selectedPlatform, id);
    const newStrategies = strategies.filter(item => item.id !== id);
    yield put(actions.removeStrategySuccess(newStrategies));
  } catch (error) {
    yield put(actions.removeStrategyError(error));
  }
}

function * removeStrategyAccountSaga ({ payload: { selectedPlatform, id } }) {
  try {
    const res = yield call(api.removeStrategyAccount, selectedPlatform, id);
    yield res?.msg && put(actions.getStrategiesStats(selectedPlatform));
    yield put(actions.removeStrategyAccountSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchFetchBuyBoxStats () {
  yield takeEvery(types.BUYBOX_STATS, fetchBuyBoxStatsSaga);
}

export function * watchFetchListings () {
  yield takeEvery(types.FETCH_REPRICER_LISTINGS, fetchListingsSaga);
}

export function * watchFetchUploadFileHistory () {
  yield takeEvery(types.FETCH_UPLOAD_FILE_HISTORY, fetchUploadFileHistorySaga);
}

export function * watchFetchListingsFormulas () {
  yield takeEvery(types.FETCH_REPRICER_LISTINGS_FORMULAS, fetchListingsFormulasSaga);
}

export function * watchFetchListingDetails () {
  yield takeEvery(types.FETCH_REPRICER_LISTING_DETAILS, fetchListingDetailsSaga);
}

export function * watchFetchPriceChangeHistories () {
  yield takeEvery(types.FETCH_REPRICER_PRICE_CHANGE_HISTORIES, fetchPriceChangeHistories);
}

export function * watchCreateListingsFormulas () {
  yield takeEvery(types.CREATE_REPRICER_LISTINGS_FORMULAS, createListingsFormulasSaga);
}

export function * watchUpdateListingsFormulas () {
  yield takeEvery(types.UPDATE_REPRICER_LISTINGS_FORMULAS, updateListingsFormulasSaga);
}

export function * watchAssignStrategySaga () {
  yield takeEvery(types.ASSIGN_STRATEGY, assignStrategySaga);
}

export function * watchAssignListingsStrategySaga () {
  yield takeEvery(types.ASSIGN_LISTINGS_STRATEGY, assignListingsStrategySaga);
}

export function * watchDeleteListingsFormula () {
  yield takeEvery(types.DELETE_REPRICER_LISTINGS_FORMULA, deleteListingsFormulaSaga);
}

export function * watchUploadCSVListings () {
  yield takeEvery(types.BULK_UPDATE_CSV_LISTINGS, uploadCSVListingsSaga);
}

export function * watchFetchListingsStats () {
  yield takeEvery(types.FETCH_LISTINGS_STATS, FetchListingsStatsSaga);
}

export function * watchUpdateListingSaga () {
  yield takeEvery(types.UPDATE_REPRICER_LISTING, updateListingSaga);
}

export function * watchFetchProductLogsSaga () {
  yield takeEvery(types.FETCH_REPRICER_PRODUCT_LOGS, fetchProductLogs);
}

export function * watchFetchHistoryLogsSaga () {
  yield takeEvery(types.GET_HISTORY_LOGS, fetchHistoryLogs);
}

export function * watchCreateAndUpdateListingTagSaga () {
  yield takeEvery(types.CREATE_AND_UPDATE_TAG, createAndUpdateListingTagSaga);
}

export function * watchUpdateMapPriceListingSaga () {
  yield takeEvery(types.UPDATE_LISTING_MAP_PRICE, updateMapPriceListingSaga);
}

export function * watchGetTagsSaga () {
  yield takeEvery(types.GET_TAGS, getTagsSaga);
}
export function * watchCreateTagsSaga () {
  yield takeEvery(types.CREATE_REPRICER_LISTINGS_TAGS, createListingsTagsSaga);
}
export function * watchUpdateTagsSaga () {
  yield takeEvery(types.UPDATE_REPRICER_LISTINGS_TAGS, updateListingsTagsSaga);
}
export function * watchDeleteTagsSaga () {
  yield takeEvery(types.DELETE_REPRICER_LISTINGS_TAG, deleteListingsTagSaga);
}
export function * watchDeleteAllTagsSaga () {
  yield takeEvery(types.DELETE_ALL_TAGS, deleteAllTagsSaga);
}

export function * watchGetAllRepricerAccountsSaga () {
  yield takeEvery(types.GET_ALL_REPRICER_ACCOUNTS, getAllRepricerAccountsSaga);
}

export function * watchGetStrategiesSaga () {
  yield takeEvery(types.GET_STRATEGIES, getStrategiesSaga);
}

export function * watchGetStrategiesStatsSaga () {
  yield takeEvery(types.GET_STRATEGIES_STATS, getStrategiesStatsSaga);
}

export function * watchCreateStrategySaga () {
  yield takeEvery(types.CREATE_STRATEGY, createStrategySaga);
}

export function * watchUpdateStrategySaga () {
  yield takeEvery(types.UPDATE_STRATEGY, updateStrategySaga);
}

export function * watchRemoveStrategySaga () {
  yield takeEvery(types.REMOVE_STRATEGY, removeStrategySaga);
}

export function * watchRemoveStrategyAccountSaga () {
  yield takeEvery(types.REMOVE_STRATEGY_ACCOUNT, removeStrategyAccountSaga);
}

function * RepricerSaga () {
  yield all(
    [
      watchFetchPriceChangeHistories,
      watchFetchBuyBoxStats,
      watchFetchListings,
      watchFetchUploadFileHistory,
      watchFetchListingsFormulas,
      watchFetchListingDetails,
      watchCreateListingsFormulas,
      watchUpdateListingsFormulas,
      watchDeleteListingsFormula,
      watchUploadCSVListings,
      watchFetchListingsStats,
      watchUpdateListingSaga,
      watchCreateAndUpdateListingTagSaga,
      watchUpdateMapPriceListingSaga,
      watchAssignStrategySaga,
      watchAssignListingsStrategySaga,
      watchGetTagsSaga,
      watchCreateTagsSaga,
      watchUpdateTagsSaga,
      watchDeleteTagsSaga,
      watchDeleteAllTagsSaga,
      watchGetStrategiesSaga,
      watchGetAllRepricerAccountsSaga,
      watchGetStrategiesStatsSaga,
      watchCreateStrategySaga,
      watchUpdateStrategySaga,
      watchRemoveStrategySaga,
      watchRemoveStrategyAccountSaga,
      watchFetchProductLogsSaga,
      watchFetchHistoryLogsSaga,
    ].map((func) => fork(func)),
  );
}

export default RepricerSaga;
