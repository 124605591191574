import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PillBtn from "./PillBtn";

export const ConfirmationAlert = ({ onConfirm, isOpen, setIsOpen, msg }) =>
  isOpen
    ? (
    <SweetAlert
      title="Are you sure?"
      warning
      showCancel
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={() => {
        onConfirm();
        setIsOpen(false);
      }}
      onCancel={() => setIsOpen(false)}
    >
      {msg}
    </SweetAlert>
      )
    : null;

export const ConfirmDialogBtn = ({
  button: { title, color, icon, tooltip, disabled, className = "mx-1" },
  onConfirm,
  msg,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <PillBtn
        title={tooltip || title}
        name={title}
        color={color}
        disabled={disabled}
        icon={icon || "bx bx-xs bx-info-circle"}
        onClick={() => setIsOpen(true)}
        className={className}
      />
      <ConfirmationAlert onConfirm={onConfirm} isOpen={isOpen} setIsOpen={setIsOpen} msg={msg} />
    </>
  );
};

export const ConfirmDialogIcon = ({ icon: { className, color, title }, img = {}, onConfirm, msg, text = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {img.src
        ? (
        <img src={img.src} title={img.heading} alt={img.alt} onClick={() => setIsOpen(true)} />
          )
        : (
        <span
          title={title}
          color={color}
          className={className + ` text-${color} cursor-pointer pr-2`}
          onClick={() => setIsOpen(true)}
        >
          {text}
        </span>
          )}
      <ConfirmationAlert onConfirm={onConfirm} isOpen={isOpen} setIsOpen={setIsOpen} msg={msg} />
    </>
  );
};

export const ConfirmDialogImage = ({ img = {}, onConfirm, msg }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {img?.src && (
        <img src={img?.src} title={img?.heading} alt={img.alt} className={img?.class} onClick={() => setIsOpen(true)} />
      )}
      <ConfirmationAlert onConfirm={onConfirm} isOpen={isOpen} setIsOpen={setIsOpen} msg={msg} />
    </>
  );
};
