import request from "../restClient";
import { makeQP } from "../../utils/common";

const insights = "/insights";

export const warehouseInsightChargesStatsAPI = async (params) =>
  await request({ url: `${insights}/warehouse_charge_stats` + makeQP(params), method: "GET" });

export const warehouseInsightInboundStatsAPI = async (params) =>
  await request({ url: `${insights}/inbound_stats` + makeQP(params), method: "GET" });

export const warehouseInsightStorageStatsAPI = async (params) =>
  await request({ url: `${insights}/inv_stats` + makeQP(params), method: "GET" });

export const warehouseInsightOutboundStatsAPI = async (params) =>
  await request({ url: `${insights}/outbound_stats` + makeQP(params), method: "GET" });

export const warehouseInsightChargesAPI = async (params) =>
  await request({ url: `${insights}/warehouse_storage` + makeQP(params), method: "GET" });

export const fetchWarehouseInsightLabelGraphAPI = async (params) =>
  await request({ url: `${insights}/label_stats` + makeQP(params), method: "GET" });
