import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, Input } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";

// actions
import { submitUnverifiedMatchesRequest } from "../../../store/actions";

const SubmitBulkUnverifiedMatchesModal = (props) => {
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);

  const validate = (values) => {
    const errors = {};
    !values.requested && (errors.requested = "Field is Required");
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      requested: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const data = {
        ...props.data,
        requested: values.requested,
      };
      if (!props?.category) delete data.category;
      if (!props?.sales_rank) delete data.sales_rank;
      props.submitUnverifiedMatchesRequest({
        ...data,
        requested: values.requested,
      });
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            REQUEST FOR MATCHES
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.UnpublishedListings?.error && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {props.UnpublishedListings?.error}
              </Alert>
            </div>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mb-2">
                <Label>Requested Matches</Label>
                <Input
                  name="requested"
                  type="number"
                  min={1}
                  max={50000}
                  placeholder="1 - 50000"
                  value={formik.values.requested}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.requested && formik.errors.requested
                  ? (
                  <span className="text-danger mt-1">{formik.errors.requested}</span>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { UnpublishedListings } = state;
  return { UnpublishedListings };
};

export default connect(mapStateToProps, {
  submitUnverifiedMatchesRequest,
})(SubmitBulkUnverifiedMatchesModal);
