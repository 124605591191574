import React, { useState, useEffect } from "react";
import * as actions from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Common/Modal";
import PillBtn from "../../../components/Common/PillBtn";
import { FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import * as $ from "lodash";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { RenderIf, toF } from "../../../utils/common";
import { useFormik } from "formik";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import NumericField from "../../../components/Common/NumericField";

const operatorLabels = {
  "+": "+ Add",
  "-": "- Subtract",
  "/": "/ Division",
  "*": "X Multiplication",
};

const typeLabels = { percentage: "Percentage", value: "Fixed Value" };

const RepricerFormulasModal = ({ title, buttonTitle, formulasId, currentFormulaData, selectedPlatform }) => {
  const dispatch = useDispatch();
  const { repricerFormulasModalIsOpen } = useSelector((state) => state.Repricer);
  const toggleProfitTrialModal = () => dispatch(actions.setRepricerFormulasModal(!repricerFormulasModalIsOpen));
  const [testValue, setTestValue] = useState(100);
  const [updateErrorCheck, setUpdateErrorCheck] = useState(false);
  const [formulaStatement, setFormulaStatement] = useState(0);
  const values = currentFormulaData?.raw?.map((input) => ({
    ...$.pick(input, "value"),
    type: { label: typeLabels[input.type], value: input.type },
    operator: { label: operatorLabels[input.operator], value: input.operator },
  }));
  const [inputList, setInputList] = useState(
    formulasId && title !== "Delete Formula"
      ? [...values]
      : [{ operator: { label: "+ Add", value: "+" }, type: { label: "Percentage", value: "percentage" }, value: "0" }],
  );

  const convertListFn = (input) => ({
    ...$.pick(input, "value"),
    type: input.type.value,
    operator: input.operator.value,
  });

  const handleInputChange = (value, index, fieldName) => {
    const list = [...inputList];
    list[index][fieldName] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    handleUpdate(list, 0);
  };

  const handleAddClick = () =>
    setInputList([
      ...inputList,
      { operator: { label: "+ Add", value: "+" }, type: { label: "Percentage", value: "percentage" }, value: "0" },
    ]);

  const handleUpdate = (inputList, timeout = 1000) =>
    setTimeout(() => {
      if (isValuesUpdated(getStatement(inputList))) setUpdateErrorCheck(false);
    }, timeout);

  const validate = (values) => {
    const errors = {};
    if (!values.formulaNameField) errors.formulaNameField = "Required";
    if (!values.testValueField) errors.testValueField = "Required";

    handleUpdate();
    return errors;
  };

  const getStatement = (updatedList) => $.map(updatedList || inputList, convertListFn);

  const isValuesUpdated = (statement) => {
    const isStatementUpdated = !statement.length || !$.isEqual(statement, currentFormulaData?.raw);
    return formik.values.formulaNameField !== currentFormulaData?.name || isStatementUpdated;
  };

  const formik = useFormik({
    initialValues: {
      formulaNameField: formulasId ? currentFormulaData?.name : "",
      testValueField: 100,
    },
    validate,
    onSubmit: (values) => {
      const statement = getStatement();
      if (formulasId) {
        if (isValuesUpdated(statement)) {
          dispatch(
            actions.updateListingsFormulas(selectedPlatform, formulasId, { name: values?.formulaNameField, statement }),
          );
          setTestValue(100);
          setInputList([
            {
              operator: { label: "+ Add", value: "+" },
              type: { label: "Percentage", value: "percentage" },
              value: "0",
            },
          ]);
          setUpdateErrorCheck(false);
          toggleProfitTrialModal();
        } else setUpdateErrorCheck(true);
      } else {
        dispatch(actions.createListingsFormulas(selectedPlatform, { name: values?.formulaNameField, statement }));
        setTestValue(100);
        setInputList([
          { operator: { label: "+ Add", value: "+" }, type: { label: "Percentage", value: "percentage" }, value: "0" },
        ]);
        toggleProfitTrialModal();
      }
    },
  });

  useEffect(() => {
    let sum = testValue;
    const _list = inputList?.map(convertListFn);
    for (let step = 0; step < _list?.length; step++) {
      const _value = _list[step].value ? _list[step].value : 0;
      const valueAfterType =
        _list[step].type !== "value"
          ? _list[step].operator === "+" || _list[step].operator === "-"
            ? (testValue * _value) / 100
            : _value / 100
          : _list[step].value;
      // eslint-disable-next-line no-eval
      if (valueAfterType) sum = eval(sum + _list[step].operator + valueAfterType);
      setFormulaStatement(sum / 0.85);
    }
  }, [inputList, testValue]);

  return (
    <Modal style={{ maxWidth: "650px" }} size="lg" isOpen={repricerFormulasModalIsOpen}>
      <RenderIf isTrue={title !== "Delete Formula"}>
        <div className="modal-header card-text font-size-20 p-3 let-modal">
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className="font-weight-bold">{title}</span>
            <i className="bx bx-sm bx-x inventory-color cursor-pointer" onClick={toggleProfitTrialModal} />
          </div>
        </div>
      </RenderIf>
      <div className="modal-body p-3 let-modal">
        <RenderIf
          isTrue={title !== "Delete Formula"}
          fallback={
            <div className="p-3">
              <i
                className="bx bx-sm bx-x inventory-color cursor-pointer"
                style={{ position: "absolute", right: "24px", top: "24px" }}
                onClick={toggleProfitTrialModal}
              />
              <div className="mt-3 d-flex justify-content-center align-items-center">
                <i style={{ fontSize: "100px" }} className="text-danger bx bx-error" />
              </div>
              <div className="mt-3 d-flex justify-content-center align-items-center font-weight-semibold inventory-color font-size-20">
                Are you sure you want to proceed?
              </div>
              <div className="mt-3 d-flex justify-content-center align-items-center font-weight-medium font-size-13">
                This formula will be deleted.
              </div>
              <div className="mt-3 d-flex justify-content-center align-items-center">
                <PillBtn
                  className="mr-2"
                  title="Cancel"
                  name="Cancel"
                  color="primary"
                  outline={true}
                  onClick={toggleProfitTrialModal}
                />
                <PillBtn
                  className="ml-2"
                  title={buttonTitle}
                  name={buttonTitle}
                  color="primary"
                  onClick={() => {
                    dispatch(actions.deleteListingsFormula(selectedPlatform, formulasId));
                    toggleProfitTrialModal();
                  }}
                />
              </div>
            </div>
          }
        >
          <FormGroup className="select2-container">
            <div>
              <Label>
                Formula Name<span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Formula Name Here"
                onChange={(e) => {
                  formik.setFieldTouched("formulaNameField");
                  formik.setFieldValue("formulaNameField", e.target.value);
                  setUpdateErrorCheck(false);
                }}
                value={formik.values.formulaNameField}
              />
              <RenderIf isTrue={formik.touched.formulaNameField && formik.errors.formulaNameField}>
                <div className="text-danger">&nbsp;&nbsp;{formik.errors.formulaNameField}</div>
              </RenderIf>
            </div>
            <div className="mt-3 d-flex align-items-center justify-content-between">
              <div style={{ width: "48%" }}>
                <Label>Formula Option</Label>
                <InputGroup className="align-items-center">
                  <Input
                    type="number"
                    step="0.01"
                    min="0.01"
                    className="cursor-not-allowed form-control"
                    placeholder="Cost"
                    disabled={true}
                  />
                  <InputGroupAddon addonType="append" style={{ position: "relative", left: "-25px" }}>
                    <i className="bx bx-lock-alt" />
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div className="no-spinners" style={{ width: "48%" }}>
                <Label>Test Value</Label>
                <input
                  type="number"
                  className="form-control"
                  step="0.01"
                  min="0.01"
                  onKeyPress={(e) => {
                    if (["-", "e", "E"].includes(e.key)) e.preventDefault();
                  }}
                  onChange={(e) => {
                    setTestValue(e.target.value);
                    formik.setFieldTouched("testValueField");
                    formik.setFieldValue("testValueField", e.target.value);
                  }}
                  value={formik.values.testValueField}
                />
                <RenderIf isTrue={formik.touched.testValueField && formik.errors.testValueField}>
                  <div className="text-danger" style={{ position: "absolute" }}>
                    &nbsp;&nbsp;{formik.errors.testValueField}
                  </div>
                </RenderIf>
              </div>
            </div>
            {inputList?.map((x, i) => {
              return (
                <div key={"__input__list" + i} className="mt-3 d-flex justify-content-between align-items-center">
                  <div style={{ width: "28%" }}>
                    <SimpleSelect
                      className="m-0"
                      label={"Operators"}
                      name={"Operators"}
                      value={x.operator}
                      onChange={(e) => {
                        setUpdateErrorCheck(false);
                        handleInputChange(e, i, "operator");
                      }}
                      options={[
                        { label: "+ Add", value: "+" },
                        { label: "- Subtract", value: "-" },
                        { label: "/ Division", value: "/" },
                        { label: "X Multiplication", value: "*" },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div style={{ width: "35%" }}>
                    <SimpleSelect
                      className="m-0"
                      label={"Type"}
                      name={"Type"}
                      value={x.type}
                      onChange={(e) => {
                        setUpdateErrorCheck(false);
                        handleInputChange(e, i, "type");
                      }}
                      options={[
                        { label: "Percentage", value: "percentage" },
                        { label: "Fixed Value", value: "value" },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div style={{ width: "25%" }} className="no-spinners">
                    <Label>Input Values</Label>
                    <NumericField
                      step="0.01"
                      min="0.01"
                      className="form-control"
                      value={x.value}
                      onChange={(e) => {
                        setUpdateErrorCheck(false);
                        handleInputChange(e.target.value, i, "value");
                      }}
                    />
                  </div>
                  <div style={{ width: "7%" }} className="d-flex">
                    <RenderIf isTrue={inputList.length !== 1}>
                      <i
                        className="mt-4 cursor-pointer bx bx-sm bx-trash-alt text-danger"
                        onClick={() => handleRemoveClick(i)}
                      />
                    </RenderIf>
                    <RenderIf isTrue={inputList.length - 1 === i}>
                      <i
                        className="mt-4 cursor-pointer bx bx-sm bx-plus-circle text-primary"
                        onClick={() => handleAddClick()}
                      />
                    </RenderIf>
                  </div>
                </div>
              );
            })}

            <CustomTooltip
              style={{ maxWidth: "386px" }}
              placement="top"
              content="We are estimating 15% for commissions. Depending on the category of your item, this could be different. We use the actual category-based commissions when repricing."
              target={"formula-equation-tooltip"}
            />
            <div className="mt-3">
              <Label>Formula Equation</Label>
              <div id="formula-equation-tooltip" className="w-100 p-3 mt-2 mb-10 formulas-equation-bg">
                <>
                  <b>(</b>
                  <b className="text-primary">Test Value</b>
                  <b style={{ color: "#1565D8" }}> + Percentage</b>
                  <b>)</b>
                  <b> /</b>
                  <b className="text-info"> Marketplace Fees</b>
                  <b> = </b>
                  <b className="text-success"> Listed Price</b>
                </>
                <br />
                <>
                  <b>(</b>
                  <b className="text-primary">{testValue || 0}</b>
                  {inputList &&
                    inputList?.map(convertListFn)?.map((name, index) => (
                      <span key={index}>
                        <b style={{ color: "#1565D8" }}>&nbsp;{name.operator}&nbsp;</b>
                        <b style={{ color: "#1565D8" }}>
                          {name.type === "percentage"
                            ? name.operator === "+" || name.operator === "-"
                              ? `(${name.value / 100} * ${testValue || 0})`
                              : `(${name.value / 100})`
                            : name.value}
                        </b>
                      </span>
                    ))}
                  <b>)</b>
                  <b> /</b>
                  <b className="text-info">.85</b>
                  <b> = </b>
                  <b className="text-success">{toF(formulaStatement)}</b>
                </>
              </div>
            </div>
            <div className="text-danger mt-2 d-flex align-items-center font-size-13">
              <i className="font-size-16 bx bx-info-circle mr-1" />
              <i className="font-weight-medium">Note: Add formula without including platform's commission.</i>
            </div>
            <RenderIf isTrue={updateErrorCheck}>
              <div className="text-warning mt-2 d-flex align-items-top font-size-13">
                <i className="font-size-16 bx bx-info-circle mr-1" />
                <i className="font-weight-medium">
                  Note: Please Update "Formula Name" or "Formula Equation" to enable "Update" button.
                </i>
              </div>
            </RenderIf>
          </FormGroup>
        </RenderIf>
      </div>
      <RenderIf isTrue={title !== "Delete Formula"}>
        <div className="modal-footer p-3 let-modal">
          <PillBtn
            className="mr-2"
            title="Cancel"
            name="Cancel"
            color="primary"
            outline={true}
            onClick={toggleProfitTrialModal}
          />
          <div type="submit">
            <PillBtn
              disabled={buttonTitle === "Update" && updateErrorCheck}
              className={`${buttonTitle === "Update" && updateErrorCheck ? "cursor-not-allowed" : ""} ml-2`}
              title={buttonTitle}
              name={buttonTitle}
              color="primary"
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </RenderIf>
    </Modal>
  );
};

export default RepricerFormulasModal;
