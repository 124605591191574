import { isProfitCalculationAllowed, isUserHaveFullAccess } from "../../../utils/common";
import * as $ from "lodash";
import ComparisionDropDown from "./ComparisonDropdown";
import DropDown from "./DropDown";

export const FILTERS_METADATA = {
  publish_status: "Status", // for Walmart
  status: "Status", // for Amazon
  stock: "Stock",
  enabled: "Repricing",
  strategy_id: "Strategy",
  has_buybox: "Buybox",
  buybox_price: "Buybox Price",
  listed_price: "Listed Price",
  tag_id: "Tag",
  no_of_competitors: "Total Sellers",
  cost: "COGS",
  minimum_price: "Min Price",
  maximum_price: "Max Price",
  profit: "Profit",
  margin: "Margin",
  return_on_investment: "ROI",
  shipping_fee: "Est. Shipping Fee",
  fulfillment_type: "Fulfillment Type",
  buybox_changed_timestamp: "Buybox Changed At",
  last_repriced_at: "Last Repriced At",
  filter: "Filter",
};

export const HIDDEN_FILTERS = {
  publish_status: false,
  status: false,
  stock: false,
  enabled: !isUserHaveFullAccess("repricer"),
  strategy_id: !isUserHaveFullAccess("repricer"),
  has_buybox: false,
  buybox_price: false,
  listed_price: false,
  tag_id: false,
  no_of_competitors: !isProfitCalculationAllowed(),
  cost: !isProfitCalculationAllowed(),
  minimum_price: !isUserHaveFullAccess("repricer"),
  maximum_price: !isUserHaveFullAccess("repricer"),
  profit: true,
  margin: true,
  return_on_investment: true,
  shipping_fee: true,
  fulfillment_type: false,
  buybox_changed_timestamp: false,
  last_repriced_at: !isUserHaveFullAccess("repricer"),
  filter: true,
};

export const FILTERS_TYPES = {
  publish_status: DropDown,
  status: DropDown,
  stock: ComparisionDropDown,
  enabled: DropDown,
  strategy_id: DropDown,
  has_buybox: DropDown,
  buybox_price: ComparisionDropDown,
  listed_price: ComparisionDropDown,
  tag_id: DropDown,
  no_of_competitors: ComparisionDropDown,
  cost: ComparisionDropDown,
  minimum_price: ComparisionDropDown,
  maximum_price: ComparisionDropDown,
  profit: ComparisionDropDown,
  margin: ComparisionDropDown,
  return_on_investment: ComparisionDropDown,
  shipping_fee: ComparisionDropDown,
  fulfillment_type: DropDown,
  buybox_changed_timestamp: DropDown,
  last_repriced_at: DropDown,
  filter: DropDown,
};

export const FILTERS_TO_SHOW = $.omit(
  FILTERS_METADATA,
  $.reduce(HIDDEN_FILTERS, (acc, isHidden, filterKey) => (isHidden ? [...acc, filterKey] : acc), []),
);

export const COMPARISION_FILTERS = Object.keys(FILTERS_METADATA).filter(
  (filterKey) => $.get(FILTERS_TYPES, filterKey) === ComparisionDropDown,
);

export const DATE_FILTERS = ["buybox_changed_timestamp", "last_repriced_at"];

export const COMPARISON_OPERATORS = {
  __lt: "<",
  __lte: "<=",
  "": "=",
  __gt: ">",
  __gte: ">=",
};

export const FILTER_OPERATOR_KEYS = COMPARISION_FILTERS.reduce(
  (acc, filterKey) => ({ ...acc, [filterKey]: Object.keys(COMPARISON_OPERATORS).map((op) => filterKey + op) }),
  {},
);

export const COMMON_FULFILLMENT_CHANNELS = { FBM: "FBM" };

export const AMAZON_FULFILLMENT_CHANNELS = { FBA: "FBA", ...COMMON_FULFILLMENT_CHANNELS };

export const WALMART_FULFILLMENT_CHANNELS = { WFS: "WFS", ...COMMON_FULFILLMENT_CHANNELS };

export const BOOLEAN_DROPDOWN = { true: "On", false: "Off" };

export const TIME_DROPDOWN = {
  10: "Last 10 Minutes",
  30: "Last 30 Minutes",
  60: "Last 1 Hour",
  180: "Last 3 Hours",
  720: "Last 12 Hours",
  1440: "Last 24 Hours",
  9999: "Before 24 Hours",
};

export const INVENTORY_AGE_FILTERS = {
  inv_age_0_to_30_days: {
    title: "<= 30 days",
    textColor: "text-success-emphasis",
    chipText: "Inventory Age Less Than 30 Days",
  },
  inv_age_31_to_60_days: {
    title: "31-60 days",
    textColor: "text-success",
    chipText: "Inventory Age 31 To 60 Days",
  },
  inv_age_61_to_90_days: {
    title: "61-90 days",
    textColor: "text-warning",
    chipText: "Inventory Age 61 To 90 Days",
  },
  inv_age_91_to_180_days: {
    title: "91-180 days",
    textColor: "text-warning-emphasis",
    chipText: "Inventory Age 91 To 180 Days",
  },
  inv_age_180_plus_days: {
    title: "180+ days",
    textColor: "text-danger",
    chipText: "Inventory Age More Than 180 Days",
  },
};

export const WALMART_INVENTORY_AGE_FILTERS = {
  inv_age_0_to_90_days: {
    title: "<= 90 days",
    textColor: "text-success-emphasis",
    chipText: "Inventory Age Less Than 90 Days",
  },
  inv_age_91_to_180_days: {
    title: "91-180 days",
    textColor: "text-warning-emphasis",
    chipText: "Inventory Age 91 To 180 Days",
  },
  inv_age_180_plus_days: {
    title: "180+ days",
    textColor: "text-danger",
    chipText: "Inventory Age More Than 180 Days",
  },
};

export const DROPDOWN_VALUES = {
  publish_status: { PUBLISHED: "Published", UNPUBLISHED: "Unpublished" },
  status: { ACTIVE: "Published", INACTIVE: "Unpublished" },
  enabled: BOOLEAN_DROPDOWN,
  has_buybox: BOOLEAN_DROPDOWN,
  buybox_changed_timestamp: TIME_DROPDOWN,
  last_repriced_at: TIME_DROPDOWN,
  filter: {
    published: "Published",
    unpublished: "Unpublished",
    active: "Active",
    inactive: "Inactive",
    in_stock: "In Stock",
    out_of_stock: "Out of Stock",
    has_buybox: "Has BuyBox",
    danger_missing_cost: "Missing COGS",
    warning_missing_strategy: "Missing Strategy",
    competition_below_min_price: "Competition Below Min Price",
    ...$.mapValues(INVENTORY_AGE_FILTERS, "chipText"),
    ...$.mapValues(WALMART_INVENTORY_AGE_FILTERS, "chipText"),
  },
};

export const COMPARISON_DROPDOWN = {
  __lt: "Less than",
  __lte: "Less than or Equal to",
  "": "Equal to",
  __gt: "Greater than",
  __gte: "Greater than or Equal to",
};

export const COMPARISION_DROPDOWN_OPTIONS = $.reduce(
  COMPARISON_DROPDOWN,
  (acc, label, value) => [...acc, { label, value }],
  [],
);

export const getFiltersMetaData = ({ isWalmart, showProfitMargin }) => {
  const keysToOmit = [isWalmart ? "status" : "publish_status", showProfitMargin ? "return_on_investment" : "margin"];
  return $.omit(FILTERS_TO_SHOW, keysToOmit);
};

export const getDropDownValues = ({ isWalmart, strategies, tags }) => ({
  ...DROPDOWN_VALUES,
  strategy_id: strategies?.reduce((acc, { name, id }) => ({ ...acc, [id]: name }), { no_strategy: "No Strategy" }),
  tag_id: tags?.results?.reduce((acc, { name, id }) => ({ ...acc, [id]: name }), {}),
  fulfillment_type: isWalmart ? WALMART_FULFILLMENT_CHANNELS : AMAZON_FULFILLMENT_CHANNELS,
});

export const getFilterTitle = (filterKey) => $.get(FILTERS_METADATA, filterKey);

export const getFilterValueLabel = (dropDownValues, filterKey, filterValue, defaultValue = filterValue) =>
  $.get(dropDownValues, `${filterKey}.${filterValue}`, defaultValue);

export const checkFilterValue = (value) => value !== "";

export const parseFilterKey = (filterKey) => {
  const [key, op] = filterKey.split("__");
  return op ? [key, "__" + op] : [key];
};

export const assignValueToFilters = (filtersMetaData, globalFilters, { value = "", assignValueFunc } = {}) => {
  return Object.keys(filtersMetaData).reduce(
    (acc, filter) => ({
      ...acc,
      [filter]: assignValueFunc ? assignValueFunc(filter) : $.get(globalFilters, filter, value),
    }),
    {},
  );
};

export const getFilters = (filters) =>
  $.reduce(
    $.pick(filters, $.uniq([...Object.keys(FILTERS_METADATA), ...$.flatten(Object.values(FILTER_OPERATOR_KEYS))])),
    (acc, filterValue, filterKey) => (checkFilterValue(filterValue) ? { ...acc, [filterKey]: filterValue } : acc),
    {},
  );
