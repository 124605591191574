import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Row,
  Alert,
  NavLink,
  NavItem,
  Nav,
  Form,
  FormGroup,
  Input,
  Button,
  UncontrolledPopover,
  Spinner,
} from "reactstrap";
import { DatePicker } from "../../../components/Common/DatePickr";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import PillBtn from "../../../components/Common/PillBtn";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ReactTable from "../../../components/Common/Table";
import classnames from "classnames";
import Select from "react-select";

import { compact, startCase, sumBy, omit, entries, map, merge, pick, size, isEmpty } from "lodash";
import {
  RenderIf,
  getUserInfo,
  getDate,
  isAdmin,
  getWarehouseName,
  isWHStaff,
  verifyRole,
  isUserHaveFullAccess,
  formatNumber,
} from "../../../utils/common";
import queryString from "query-string";

// Actions
import * as actions from "../../../store/actions";

// Modal
import ShowBinLocationsModal from "./inventoryModals/ShowBinLocationsModal";
import ViewNotesIcon from "../../Returns/components/Common/ViewNotesIcon";
import ItemActionsModal from "./inventoryModals/ItemActionsModal";
import SeeMoreText from "../../../components/Common/SeeMoreText";
import TableFieldEditabel from "./inventoryModals/TableFieldEditabel";

import {
  INVENTORY_PRODUCTS_STATUSES,
  SHOW_OVERSIZED_COL,
  DELETE_OUTBOUND_ITEM,
  STATUS_COLOR_CODE,
  DATE_FORMATS,
  ORDERABLE_TYPES,
} from "../../../constants/index";
import StorageCheckbox from "../../Dashboard/AgencyComponents/StorageCheckBox";
import DeleteInventoryModal from "../../SourceOrders/components/DeleteInventoryModal";
import ReportModal from "../../../components/Common/ReportModal";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
const getText = (text) => text || "N/A";

const {
  fetchWHInventory,
  fetchInventoryShipmentWise,
  fetchWHInventoryStats,
  setPreloader,
  setBinDetailModal,
  fetchWarehouseAccounts,
  setItemActionsModal,
  updateInventoryOversized,
} = actions;

const { fba, wfs, discard_initiated: preDiscarded, donate_initiated: preDonated, liquidation_initiated: preLiquidation } = INVENTORY_PRODUCTS_STATUSES;

const InventryItems = () => {
  const dispatch = useDispatch();

  // Selectors
  const {
    whInvetoryData,
    loading,
    whInventoryStats,
    setBinDetailModalIsOpen,
    setItemActionsModalIsOpen,
    wareHouseAccounts,
    success,
    error,
    inventoryPriceUpdateloading,
    wareHouses,
    orderId,
  } = useSelector((state) => state.WareHouses);

  const { loading: reportLoading, error: reportErr } = useSelector((state) => state.Report);

  const isDeleteInventoryAccess = DELETE_OUTBOUND_ITEM.includes(getUserInfo()?.email);
  const toggleBinDetailModal = () => dispatch(setBinDetailModal(!setBinDetailModalIsOpen));
  const toggleItemActionsModal = () => dispatch(setItemActionsModal(!setItemActionsModalIsOpen));
  const getItemText = (id, key, value, seeMoreOpts, length) => (
    <SeeMoreText id={id} value={value} length={length || 40} {...seeMoreOpts} className="text-primary" onHoverDisable />
  );

  const getBinName = (rs) => rs?.find((x) => x.WhInventoryLocation?.length)?.WhInventoryLocation?.[0]?.bin_no || "N/A";

  const getReportData = () => dispatch(actions.genInventoryReport({ query: { is_download: true } }));
  const isShowSubType = (x) => x?.shipment_line?.wh_order?.orderable_type === ORDERABLE_TYPES.purchase_orders && x?.shipment_line?.wh_order?.po_type !== ORDERABLE_TYPES.purchase_orders;
  // get user Account status tenant base
  const isWH = getUserInfo()?.isWH;
  const isOversizeAccess = SHOW_OVERSIZED_COL.includes(getUserInfo()?.email);

  // States for filter
  const [dropDownIsOpen, setDropDownIsOpen] = useState({ isOpen: false, key: 0 });
  const [delInvModal, setDelInvModal] = useState(false);
  const [binLocationDetail, setBinLocationDetail] = useState([]);
  const [itemDetail, setItemDetail] = useState(false);
  const [itemKey, setItemKey] = useState("");
  const [itemType, setItemType] = useState(null);
  const [seeMoreUPC, setSeeMoreUPC] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [mainState, setMainState] = useState({
    editingIndex: -1,
    upc: "",
    title: "",
    prevUpc: "",
    prevTitle: "",
    isUPCError: false,
    UPCErrorMessage: "",
    isTitleError: false,
    titleErrorMessage: "",
  });

  const [seeMore, setSeeMore] = useState();
  const [loaderKey, setLoaderKey] = useState({});
  const [showReportModal, setShowReportModal] = useState(false);

  const query = queryString.parse(document.location.search);
  const [search, setSearch] = useState(query.search || "");
  const initialFilterValue = merge(
    { page: 1, per_page: 30, return_type: "" },
    query.search && { search: query.search },
    query.account_id && { account_id: query.account_id },
  );
  const [filters, setFilters] = useState(initialFilterValue);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [remainingQtyError, setRemainingQtyError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newMinValue = minValue;
    let newMaxValue = maxValue;
    let error = "";
    if (parseFloat(value) <= 0 || parseFloat(minValue) <= 0 || parseFloat(maxValue) <= 0) {
      error = "Please enter value greater than 0";
    } else if (name === "minValue" && maxValue && value && parseFloat(value) > parseFloat(maxValue)) {
      error = "Minimum value should be less than or equal to maximum value";
    } else if (name === "maxValue" && minValue && value && parseFloat(value) < parseFloat(minValue)) {
      error = "Maximum value should be greater than or equal to minimum value";
    } else {
      error = "";
    }
    setRemainingQtyError(error);
    if (name === "minValue") {
      newMinValue = value;
    } else if (name === "maxValue") {
      newMaxValue = value;
    }

    setMinValue(newMinValue);
    setMaxValue(newMaxValue);
  };

  const ORDERABLE_TYPES_MAPPING = {
    returns: "Returns",
    tracking_items: "2-Step",
    purchase_orders: "Purchase Orders",
  };
  // Use Effects
  useEffect(() => {
    setBinDetailModalIsOpen && toggleBinDetailModal();
    dispatch(fetchWHInventoryStats());
    !wareHouseAccounts.length && dispatch(fetchWarehouseAccounts());
  }, []);

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }

  useEffect(() => {
    setLoading(loading || reportLoading);
  }, [loading, reportLoading]);

  useEffect(() => {
    if (orderId) {
      setTimeout(() => {
        dispatch(actions.resetSuccessErr());
      }, 5000);
    }
  }, [orderId]);

  useEffect(() => {
    if (!inventoryPriceUpdateloading) setLoaderKey({});
  }, [inventoryPriceUpdateloading]);

  const resetRemainingQty = () => {
    setMinValue("");
    setMaxValue("");
  };
  const initialFilter = { page: 1, per_page: 20, search: "" };
  // Tab manipulation
  const tabs = [
    { title: "Product Wise", id: 1 },
    { title: "Shipment Wise", id: 2 },
  ];
  const [activeTab, setActiveTab] = useState(isWHStaff() ? tabs[1].id : tabs[0].id);
  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilters(initialFilter);
      setSearch("");
      resetRemainingQty();
      setSearchType({ label: "All", value: searchTypeOptions.All });
    }
  };
  const isProductWiseTab = (tab) => tab === 1;

  useEffect(() => {
    isProductWiseTab(activeTab) ? dispatch(fetchWHInventory(filters)) : dispatch(fetchInventoryShipmentWise(filters));
  }, [activeTab, filters]);

  const [searchMenu, setSearchMenu] = useState(false);
  const toggleSearch = () => setSearchMenu(!searchMenu);

  const getBins = (obj) => {
    const binsInObj = obj?.map((obj) => ({ bin_no: obj.bin_no, qty: obj.wh_inventory_locations.qty }));
    const binSize = size(obj);
    return { binsInObj, binSize };
  };

  const headers = compact([
    { title: "Account Details", tab: "both", user: "both" },
    { title: "Title", tab: "both", user: "both" },
    { title: "Warehouse", tab: "both", user: !isWH },
    { title: "SKU", tab: "productWise", user: "both" },
    { title: "Total Qty", tab: "productWise", user: "both" },
    { title: "Damage Items", tab: "productWise", user: "both" },
    { title: "Price", tab: "productWise", user: !isWH },
    { title: "UPC", tab: "both", user: "both" },
    { title: "Qty Details", tab: "shipmentWise", user: "both" },
    { title: "Shipment Details", tab: "shipmentWise", user: "both" },
    { title: "Received On", tab: "shipmentWise", user: "both" },
    { title: "Bin Details", tab: "shipmentWise", user: "both" },
    { title: "Location", tab: "productWise", user: isWH },
    { title: "Type", tab: "productWise", user: "both" },
    isAdmin() && { title: "Storage Charges Exemption", tab: "productWise", user: !isWH },
    { title: "Oversized", tab: "productWise", user: "both" },
    !(!isProductWiseTab(activeTab) && isWH) && { title: "Actions", tab: "both", user: "both" },
  ]);
  const inventryUserHeader = headers.filter((x) =>
    isProductWiseTab(activeTab) ? x.tab !== "shipmentWise" && x.user : x.tab !== "productWise" && x.user,
  );
  const filteredTabs = map(inventryUserHeader, "title");
  const showCol = (name) => filteredTabs?.includes(name);

  const mapping = {
    [fba]: "Move to FBA",
    [wfs]: "Move to WFS",
    returns: "Initiate a Return",
    [preDiscarded]: "Initiate Discard",
    [preDonated]: "Initiate Donate",
    [preLiquidation]: "Initiate Liquidation",
  };

  const amountText = (text) => formatNumber(text);

  const getMappedStatus = (status) => mapping[status] || startCase(status);
  const showEditOpt = [4010].includes(getUserInfo()?.id) || DELETE_OUTBOUND_ITEM.includes(getUserInfo()?.user_email);
  const searchTypeOptions = {
    All: "",
    "Bin#": "bin",
    UPC: "upc",
    ...(!isProductWiseTab(activeTab) && { "Account ID": "account_id" }),
  };
  const [searchType, setSearchType] = useState({ label: "All", value: searchTypeOptions.All });
  const { appTheme } = useSelector((state) => state.Layout);

  const handleSubmit = () => {
    setFilters({
      ...omit(filters, "orderable_type", "min_qty", "max_qty", "start_date", "end_date", "utc_offset"),
      search,
      search_column: searchType?.value,
      page: 1,
    });
    resetRemainingQty();
  };

  const updateData = (id) => {
    if (mainState?.title !== mainState?.prevTitle || mainState?.upc !== mainState?.prevUpc) {
      dispatch(
        actions.updateInventoryItemPrice(id, {
          title: mainState?.title.length > 255 ? mainState?.title?.slice(0, 255) : mainState.title,
          upc: mainState?.upc,
        }),
      );
    }
    setMainState({ ...mainState, editingIndex: -1 });
  };

  const checkUPC = (val) => {
    const UPC_REGEX = /^\s*\d+(?:\s*,\s*\d+)*\s*,?\s*$/;
    const trimmedStr = val.trim();
    if (trimmedStr && !UPC_REGEX.test(trimmedStr)) {
      setMainState({ ...mainState, isUPCError: true, UPCErrorMessage: "Incorrect UPC" });
    } else {
      setMainState({ ...mainState, isUPCError: false, UPCErrorMessage: "", upc: trimmedStr });
    }
  };
  const ActionButtons = ({ item }) => {
    const menuButtons = ["discard_initiated", "donate_initiated", "returns", "fba", "wfs"];
    if (isAdmin() || getUserInfo()?.isAgencySwitch) menuButtons.unshift("liquidation_initiated");

    return (
      <div className="d-flex align-items-center">
        <ViewNotesIcon item={item} notices={item?.warehouse_notices} />
        <RenderIf isTrue={showEditOpt && isProductWiseTab(activeTab)}>
          <td className="text-center">
            <i
              title="Edit"
              className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
              onClick={() => {
                setMainState({
                  ...mainState,
                  editingIndex: item?.id,
                  upc: item?.upc,
                  title: item?.title,
                  prevUpc: item?.upc,
                  prevTitle: item?.title,
                  isUPCError: false,
                  UPCErrorMessage: "",
                });
              }}
            />
          </td>
        </RenderIf>
        <RenderIf isTrue={menuButtons.length}>
          <Dropdown
            isOpen={dropDownIsOpen.isOpen && dropDownIsOpen.key === item.id}
            toggle={() => setDropDownIsOpen({ isOpen: !dropDownIsOpen.isOpen, key: item.id })}
          >
            <DropdownToggle
              tag="i"
              className="bx bx-sm bx-dots-vertical-rounded cursor-pointer text-primary pt-1"
            ></DropdownToggle>
            <DropdownMenu>
              {menuButtons.map((status, key) => (
                <DropdownItem
                  key={"__item___" + key}
                  onClick={() => {
                    setIsOpen(true);
                    setItemDetail(item);
                    setItemType(status);
                    toggleItemActionsModal();
                  }}
                >
                  {getMappedStatus(status)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </RenderIf>
        <RenderIf isTrue={isProductWiseTab(activeTab)}>
          <i
            title="Redirect To add listing"
            className="bx bx-right-arrow-alt bx-sm text-primary cursor-pointer px-1"
            onClick={() => window.open(`/listings/new/${item?.sku}`, "blank")}
          />
        </RenderIf>
      </div>
    );
  };

  const handleOnClick = (x, key) =>
    dispatch(updateInventoryOversized({ id: x.id, oversized: !x.oversized, index: key }));

  const getWHName = (id) => wareHouses?.data?.find((el) => el.id === id);

  const renderWarehouseName = (x) => {
    const warehouseId = x?.warehouse_id || x?.shipment_line?.wh_order?.warehouse_id;
    const warehouseName = getWHName(warehouseId) && getWarehouseName(getWHName(warehouseId));
    return <span className="w-100 justify-content-center d-flex">{warehouseName || (!loading ? "N/A" : "...")}</span>;
  };

  const renderBinNoEditOption = (x, section) => {
    return (
      <>
        <span className="mr-2 inner-caption">
          Bin#
          <h6>
            {section === "fallback" ? "N/A" : x?.WhInventoryLocation?.[0]?.bin_no}
            {isWH || showEditOpt ? (
              <i
                className="ml-1 bx bx-pencil cursor-pointer"
                onClick={(_) => {
                  setBinLocationDetail([x]);
                  toggleBinDetailModal();
                }}
              />
            ) : null}
          </h6>
        </span>
      </>
    );
  };

  const updateShowReportModal = (value) => setShowReportModal(value);

  const renderPopover = (el, key, section) => (
    <RenderIf isTrue={section === "shipment_wise" ? size(el?.WhInventoryLocation) > 1 : size(el?.WhInventoryLocation)}>
      <span
        className="counter-circle"
        style={{
          backgroundColor: "rgba(186, 186, 186, 0.15)",
          color: "#a4a3a3",
        }}
        id={`_bin_locations_${section}_${el?.id}_`}
      >
        {getBins(el?.WhInventoryLocation)?.binSize || 0}
      </span>
      <UncontrolledPopover
        placementPrefix="bs-popover"
        placement="bottom-start"
        hideArrow={true}
        positionFixed={true}
        className={appTheme === "light" ? "light" : "dark"}
        trigger="legacy"
        target={`_bin_locations_${section}_${el?.id}_`}
      >
        <div className="p-2">
          <ReactTable
            invSection={true}
            tableHeader={
              <>
                <th className="miw-160">Bin#</th>
                <th className="miw-160">Qty</th>
              </>
            }
            tableBody={
              <>
                {getBins(el?.WhInventoryLocation)?.binsInObj?.map((b, i) => (
                  <tr key={`__binDetailsRow${i}__`}>
                    <td>{b?.bin_no}</td>
                    <td>{b?.qty}</td>
                  </tr>
                ))}
              </>
            }
          />
        </div>
      </UncontrolledPopover>
    </RenderIf>
  );
  const checkOrderIdSubString = (str) => {
    const substring = "WH-";
    return str.includes(substring);
  };

  return (
    <>
      <RenderIf isTrue={delInvModal && isDeleteInventoryAccess}>
        <DeleteInventoryModal isOpen={delInvModal} toggleIsOpen={setDelInvModal} />
      </RenderIf>
      <RenderIf isTrue={isOpen && setItemActionsModalIsOpen}>
        <ItemActionsModal itemDetail={itemDetail} actionType={itemType} tab={activeTab} statusMapping={mapping} />
      </RenderIf>
      <Row>
        <RenderIf isTrue={!setBinDetailModalIsOpen}>
          <Col md={12} lg={12}>
            {success && (
              <div className="auto-hide">
                <Alert color="success">
                  <span className="d-flex align-items-center">
                    <i className="bx bx-info-circle pr-2"></i>
                    {checkOrderIdSubString(orderId) ? (
                      <>
                        {success} Auto generated order id: ${orderId} <CopyToClipBoard padding={1} text={orderId} />
                      </>
                    ) : (
                      success
                    )}
                  </span>
                </Alert>
              </div>
            )}
            {(reportErr || error) && (
              <div className="auto-hide">
                <Alert color="danger">
                  <i className="bx bx-info-circle pr-2"></i>
                  {reportErr === "No records for given date range" ? "No records found" : reportErr || error}
                </Alert>
              </div>
            )}
          </Col>
        </RenderIf>
      </Row>
      <div className="page-content purchase-order">
        <Container fluid>
          <Breadcrumbs title="INVENTORY ITEMS" />
          <div>
            <RenderIf isTrue={isWH}>
              <div className="d-flex border-radius-sm">
                <Card className="mini-stats-wid purchase-order-widgets mr-3  w-100">
                  <CardBody className="d-flex justify-content-between">
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between mb-2">
                        <div>
                          <p className="font-weight-medium">Units Awaiting Check-In</p>
                        </div>
                        <div className="d-flex pr-2">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-alarm font-size-20" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <h4 className="mb-0">{whInventoryStats?.awaitingCheckIn}</h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mini-stats-wid purchase-order-widgets mr-3  w-100">
                  <CardBody className="d-flex justify-content-between">
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between mb-2">
                        <div>
                          <p className="font-weight-medium">Units Awaiting Actions</p>
                        </div>
                        <div className="d-flex pr-2">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-package font-size-20" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <h4 className="mb-0">{whInventoryStats?.awaitingActions}</h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mini-stats-wid purchase-order-widgets  w-100">
                  <CardBody className="d-flex justify-content-between">
                    <CustomTooltip
                      placement="top"
                      target="tenantEmail"
                      content="Units incur storage fees after 14 days.
                                    Take action on these items to avoid
                                    storage charges."
                    />
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between mb-2">
                        <div>
                          <p className="font-weight-medium">
                            Units in Storage{" "}
                            <i id="tenantEmail" className="bx bx-error-circle cursor-pointer text-primary" />{" "}
                          </p>
                        </div>
                        <div className="d-flex pr-2">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-cart-alt font-size-20" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex">
                          <span className="d-flex align-items-center">
                            {" "}
                            <span>&#60;</span> 1 Week:{" "}
                            <h4 className="ml-2 mb-0 text-success">{whInventoryStats?.lessThanWeek || 0}</h4>
                          </span>
                          <div className="card-heading-seperator"></div>
                        </div>
                        <div className="d-flex ml-2">
                          <span className="d-flex align-items-center">
                            {" "}
                            7-30 days: <h4 className="ml-2 mb-0 text-warning">{whInventoryStats?.sevenToFourteen || 0}</h4>
                          </span>
                          <div className="card-heading-seperator"></div>
                        </div>
                        <div className="d-flex ml-2">
                          <span className="d-flex align-items-center">
                            {" "}
                            <span>&#62;</span>30 days:{" "}
                            <h4 className="ml-2 mb-0 text-danger">{whInventoryStats?.graterThanFourteen || 0}</h4>
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </RenderIf>
            <Nav tabs className="nav-tabs-custom nav-justified" style={{ height: "50px" }}>
              {tabs.map((tab) => (
                <NavItem key={`email_tab-${tab.id}`}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === tab.id,
                    })}
                    onClick={() => {
                      toggleActiveTab(tab.id);
                    }}
                  >
                    <span className="d-none d-sm-block">{tab.title}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <div>
              <Card>
                <CardHeader>
                  <div className="d-flex flex-wrap justify-content-between">
                    <form
                      className="app-search d-lg-block"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (search.trim()) {
                          resetRemainingQty();
                          setFilters({
                            ...pick(filters, "per_page", "status", "tracking_status"),
                            page: 1,
                            search: search.trim(),
                            search_column: searchType?.value,
                          });
                        }
                      }}
                    >
                      <input type="submit" hidden />
                      <div className={`d-flex ${!(!isWH && isProductWiseTab(activeTab)) && "inputs-group-inbounds"}`}>
                        <RenderIf isTrue={!(!isWH && isProductWiseTab(activeTab))}>
                          <>
                            <Select
                              name="select_type"
                              value={searchType}
                              style={{ width: "200px" }}
                              className="select2-container mb-0 form-group"
                              onChange={(e) => {
                                setSearch("");
                                setFilters(initialFilterValue);
                                resetRemainingQty();
                                setSearchType({ label: e.label, value: e.value });
                              }}
                              options={map(entries(searchTypeOptions), ([l, v]) => ({ label: l, value: v }))}
                              classNamePrefix="select2-selection"
                            />
                            <div className="vertical-line2" />
                          </>
                        </RenderIf>
                        <div className="position-relative">
                          <i
                            onClick={handleSubmit}
                            className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                          />
                          <input
                            id="search_field"
                            type="text"
                            className={`form-control search-input ${
                              !isWH && isProductWiseTab(activeTab) && "miw-search-input"
                            }`}
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => {
                              if (!e.target.value.trim()) {
                                setSearch("");
                                setFilters({ ...merge(omit(filters, "search"), { page: 1 }) });
                              } else {
                                setSearch(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="d-flex align-items-center">
                      <RenderIf
                        isTrue={isProductWiseTab(activeTab)}
                        fallback={
                          <div className="d-flex flex-wrap align-items-center">
                            <Dropdown isOpen={searchMenu} toggle={toggleSearch} className="inventory-dropdown">
                              <DropdownToggle tag="i" className="btn nav-btn dropdown-toggle" type="button">
                                <Card className="overflow-hidden py-1">
                                  <div
                                    className="d-flex align-items-center justify-content-center "
                                    style={{ padding: "1px 10px" }}
                                  >
                                    <span className="mr-2" style={{ minWidth: "200px" }}>
                                      {!minValue && !maxValue && (
                                        <span className="mr-2">Select Remaining Qty Range</span>
                                      )}
                                      <span>{`${minValue && "Min Qty: "}${minValue}  `}</span>
                                      <span>{`${maxValue && "Max Qty: "}${maxValue}`}</span>
                                    </span>
                                    {(minValue || maxValue) && (
                                      <i
                                        className="text-danger bx font-size-24 bx-x cursor-pointer"
                                        onClick={() => {
                                          setFilters({ ...omit(filters, "min_qty", "max_qty") });
                                          resetRemainingQty();
                                        }}
                                      />
                                    )}
                                    <i className="font-size-20 bx bx-package p-1 text-primary"></i>
                                  </div>
                                </Card>
                              </DropdownToggle>
                              <DropdownMenu right className="dropdown-menu-md" style={{ minWidth: "280px" }}>
                                <Form
                                  className="p-3"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    setFilters({ ...filters, min_qty: minValue, max_qty: maxValue, page: 1 });
                                    toggleSearch();
                                  }}
                                >
                                  <FormGroup className="m-0">
                                    <p>Remaining Qty Range</p>
                                    <div className="d-flex align-items-center mb-2">
                                      <Input
                                        type="number"
                                        className="form-control"
                                        placeholder="Min"
                                        name="minValue"
                                        value={minValue}
                                        onChange={handleInputChange}
                                      />
                                      <span className="mx-2">-</span>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        placeholder="Max"
                                        name="maxValue"
                                        value={maxValue}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                    <span className="text-danger">{remainingQtyError}</span>
                                    <hr />
                                    <div className="d-flex align-items-center" style={{ justifyContent: "flex-end" }}>
                                      <Button
                                        className="mr-2"
                                        onClick={() => {
                                          if (filters.min_qty !== minValue) setMinValue(filters.min_qty || "");
                                          if (filters.max_qty !== maxValue) setMaxValue(filters.max_qty || "");
                                          toggleSearch();
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button color="primary" disabled={!!remainingQtyError}>
                                        Apply
                                      </Button>
                                    </div>
                                  </FormGroup>
                                </Form>
                              </DropdownMenu>
                            </Dropdown>

                            <div className="d-flex align-items-center inbound-date-range">
                              <DatePicker
                                icon
                                style={{ width: "260px", border: "none" }}
                                placeholder="Select Date Range for order placed"
                                className="mx-1 my-2"
                                pickrClass="pl-2"
                                filters={filters}
                                onClose={(dates) => {
                                  setFilters({ ...filters, ...dates, page: 1 });
                                }}
                              />
                              <i className="bx font-size-16 bx-calendar text-primary mx-3" />
                            </div>

                            <div>
                              <SimpleSelect
                                name="shipment_type"
                                placeholder="All Types"
                                value={{
                                  value: filters.orderable_type || "",
                                  label: ORDERABLE_TYPES_MAPPING[filters.orderable_type] || "All Types",
                                }}
                                className="col mb-0"
                                onChange={(acc) => {
                                  setFilters({ ...filters, orderable_type: acc.value, page: 1 });
                                }}
                                options={[{ value: "", label: "All Types" }].concat(
                                  entries(ORDERABLE_TYPES_MAPPING)
                                    .filter(([value]) => (isUserHaveFullAccess() ? true : value === "returns"))
                                    .map(([value, label], i) => ({
                                      value,
                                      label,
                                      key: i,
                                    })),
                                )}
                                formStyle={{ minWidth: "190px", marginBottom: 0 }}
                              />
                            </div>

                            <RenderIf
                              isTrue={isWHStaff()}
                              fallback={
                                <RenderIf isTrue={verifyRole("admin", "owner")}>
                                  <div style={{ marginLeft: "12px" }}>
                                    <PillBtn
                                      id="inv-report-btn"
                                      color="primary"
                                      icon="bx bx-xs bx-cloud-download ml-1"
                                      onClick={() => setShowReportModal(true)}
                                    />
                                    <CustomTooltip placement="top" content="Export Items" target="inv-report-btn" />
                                  </div>
                                </RenderIf>
                              }
                            >
                              <div style={{ marginLeft: "12px" }}>
                                <PillBtn
                                  id="inv-report-btn"
                                  color="primary"
                                  icon="bx bx-xs bx-cloud-download ml-1"
                                  onClick={() => getReportData()}
                                />
                                <CustomTooltip placement="top" content="Export Items" target="inv-report-btn" />
                              </div>
                            </RenderIf>
                          </div>
                        }
                      >
                        <RenderIf isTrue={wareHouseAccounts?.length}>
                          <div style={{ marginTop: "-0.125rem" }}>
                            <SimpleSelect
                              name="account_id"
                              placeholder="All Accounts"
                              className="col mb-0"
                              onChange={(acc) => {
                                const newF = omit(
                                  { ...filters, account_id: acc.value, page: 1 },
                                  acc.value ? "" : "account_id",
                                );
                                setFilters(newF);
                              }}
                              options={[{ value: "", label: "All Accounts" }].concat(
                                wareHouseAccounts?.map((x) => ({ value: x.id, label: `${x.id}: ${x.email}` })),
                              )}
                              formStyle={{ minWidth: "270px", marginBottom: 0 }}
                            />
                          </div>
                          <RenderIf isTrue={isDeleteInventoryAccess}>
                            <PillBtn
                              style={{ height: "38px" }}
                              title="Delete Inventory"
                              name="Delete Inventory"
                              icon="bx bx-trash"
                              color="danger"
                              onClick={() => setDelInvModal(true)}
                            />
                          </RenderIf>
                        </RenderIf>
                      </RenderIf>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </div>
          </div>
          <Card>
            <CardBody>
              <ReactTable
                style={{ minHeight: whInvetoryData?.inventoryItems?.length && "200px" }}
                tableHeader={
                  <>
                    {inventryUserHeader.map((header, index) => (
                      <th onClick={header.onClick} key={`table-header ${index}`} className={header.className}>
                        {header.title}
                      </th>
                    ))}
                  </>
                }
                tableBody={
                  <>
                    {whInvetoryData?.inventoryItems?.map((x, key) => (
                      <tr key={x?.id}>
                        <RenderIf
                          isTrue={mainState.editingIndex === x?.id}
                          fallback={
                            <>
                              <RenderIf isTrue={showCol("Account Details")}>
                                <td>
                                  <div className="overflow-add-modal">
                                    <span className="d-flex align-items-center">
                                      Account ID:{" "}
                                      <h6 className="mb-0 pl-2">
                                        {x.account_id || x?.warehouse_inventory?.account_id}
                                      </h6>
                                    </span>
                                  </div>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Title")}>
                                <td>
                                  {isProductWiseTab(activeTab) ? (
                                    <div className="d-flex align-items-center">
                                      <div className="pl-3">
                                        {getItemText(
                                          x?.id,
                                          "InventoryItem",
                                          x?.title || x?.warehouse_inventory?.title || "N/A",
                                          { seeMore, setSeeMore },
                                          40,
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="d-flex">
                                        Name:
                                        <span className="seemore-text">
                                          {" "}
                                          <div className="pl-3">
                                            {getItemText(
                                              x?.id,
                                              "InventoryItem",
                                              x?.title || x?.warehouse_inventory?.title || "N/A",
                                              { seeMore, setSeeMore },
                                              40,
                                            )}
                                          </div>
                                        </span>
                                      </div>
                                      <div className="d-flex">
                                        SKU:
                                        <span className="seemore-text">{x?.warehouse_inventory?.sku}</span>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Warehouse")}>
                                <td>{renderWarehouseName(x)}</td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("SKU")}>
                                <td>{x.sku}</td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Total Qty")}>
                                <td className="text-center">{x.qty}</td>
                              </RenderIf>

                              <RenderIf isTrue={showCol("Damage Items")}>
                                <td className="text-center">{sumBy(x?.received_shipments, "damaged_qty") || 0}</td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Price")}>
                                <TableFieldEditabel
                                  index={key}
                                  amountText={amountText}
                                  item={x}
                                  itemKey={itemKey}
                                  setItemKey={setItemKey}
                                  loaderKey={loaderKey}
                                  setLoaderKey={setLoaderKey}
                                />
                              </RenderIf>
                              <RenderIf isTrue={showCol("UPC")}>
                                <td>
                                  <div>
                                    {getItemText(
                                      x?.id,
                                      "InventoryItem",
                                      x?.upc || x?.warehouse_inventory?.upc || "N/A",
                                      { seeMore: seeMoreUPC, setSeeMore: setSeeMoreUPC },
                                      12,
                                    )}
                                  </div>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Qty Details")}>
                                <td>
                                  <div className="d-flex">
                                    Received:
                                    <span className="seemore-text">{x?.received_qty}</span>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    Remaining: <span className="seemore-text">{x.remaining_qty}</span>
                                  </div>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Shipment Details")}>
                                <td>
                                  <div className="d-flex">
                                    Tracking#:
                                    <span className="seemore-text">{x?.shipment_line?.tracking_number}</span>
                                  </div>
                                  <div className="d-flex">
                                    Type:
                                    <span
                                      className={`ml-1 mt-1 badge-soft-${
                                        STATUS_COLOR_CODE[x?.shipment_line?.wh_order?.orderable_type] || "N"
                                      } badge badge-pill`}
                                    >
                                      {getText(ORDERABLE_TYPES_MAPPING[x?.shipment_line?.wh_order?.orderable_type])}
                                    </span>
                                  </div>
                                  <RenderIf isTrue={isShowSubType(x)}>
                                    <div className="d-flex">
                                      SubType:
                                      <span
                                        className={`ml-1 mt-1 badge-soft-${
                                          STATUS_COLOR_CODE[x?.shipment_line?.wh_order?.po_type] || "N/A"
                                        } badge badge-pill`}
                                      >
                                        {getText(ORDERABLE_TYPES_MAPPING[x?.shipment_line?.wh_order?.po_type])}
                                      </span>
                                    </div>
                                  </RenderIf>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Received On")}>
                                <td>
                                  <div>{getText(getDate(x?.shipment_line?.received_at, DATE_FORMATS.FULL_DATE))}</div>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Bin Details")}>
                                <td>
                                  <RenderIf
                                    isTrue={x?.WhInventoryLocation?.length}
                                    fallback={
                                      <div className="d-flex align-items-center">
                                        {renderBinNoEditOption(x, "fallback")}
                                      </div>
                                    }
                                  >
                                    <div className="d-flex align-items-center">
                                      {renderBinNoEditOption(x, "")}
                                      <div className="separation-line-listing" />
                                      <span className="overflow-add-modal d-flex align-items-center">
                                        <span className=" inner-caption ml-2">
                                          Qty
                                          <h6>{x?.WhInventoryLocation?.[0]?.wh_inventory_locations?.qty}</h6>
                                        </span>
                                        {renderPopover(x, key, "shipment_wise")}
                                      </span>
                                    </div>
                                  </RenderIf>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Location")}>
                                <td>
                                  <div className="overflow-add-modal">
                                    <span className="d-flex align-items-center">
                                      Bin #: <h6 className="mb-0 pl-2">{getBinName(x?.received_shipments)} </h6>
                                      {renderPopover(x?.received_shipments?.[0], key, "product_wise")}
                                    </span>
                                  </div>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Type")}>
                                <td>
                                  <span
                                    className={`font-size-12 badge-soft-success  badge badge-pill ${
                                      x.qty_multiplier > 1 ? "badge-soft-warning" : "badge-warning"
                                    }`}
                                  >
                                    {x.qty_multiplier > 1 ? "Bundle" : "Single Item"}
                                  </span>
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={isAdmin() && showCol("Storage Charges Exemption")}>
                                <td>
                                  {inventoryPriceUpdateloading &&
                                  loaderKey.type === "storage" &&
                                  loaderKey.id === x.id ? (
                                    <span className="w-100 justify-content-center d-flex">
                                      <Spinner color="primary" style={{ width: "20px", height: "20px" }} />
                                    </span>
                                      ) : (
                                    <StorageCheckbox
                                      disabled={!isEmpty(loaderKey)}
                                      msg="this inventory item"
                                      className="w-100 justify-content-center d-flex"
                                      val={x.is_storage_exempt}
                                      onConfirm={(val) => {
                                        setLoaderKey({ type: "storage", id: x.id });
                                        dispatch(
                                          actions.updateInventoryItemPrice(x.id, {
                                            is_storage_exempt: val,
                                            sku: x.sku,
                                          }),
                                        );
                                      }}
                                      isShowLabel={false}
                                    />
                                      )}
                                </td>
                              </RenderIf>
                              <RenderIf isTrue={showCol("Oversized")}>
                                <td>
                                  <RenderIf
                                    isTrue={isOversizeAccess}
                                    fallback={
                                      <span
                                        className={`font-size-12 badge-soft-success  badge badge-pill ${
                                          x.oversized ? "badge-success" : "badge-danger badge-customization"
                                        }`}
                                      >
                                        {x.oversized ? "Yes" : "No"}
                                      </span>
                                    }
                                  >
                                    <div className="custom-control custom-switch custom-switch-lg" dir="ltr">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={x.oversized}
                                        onChange={() => null}
                                      />
                                      <label
                                        className="custom-control-label cursor-pointer"
                                        onClick={() => handleOnClick(x, key)}
                                      ></label>
                                    </div>
                                  </RenderIf>
                                </td>
                              </RenderIf>
                              <RenderIf
                                isTrue={isWH && showCol("Actions")}
                                fallback={
                                  <RenderIf isTrue={showCol("Actions")}>
                                    <td>
                                      <ActionButtons item={x} />
                                    </td>
                                  </RenderIf>
                                }
                              >
                               <RenderIf isTrue={isProductWiseTab(activeTab) && isWH}>
                                  <td className="text-center">
                                    <i
                                      title="Edit"
                                      className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                                      onClick={() => {
                                        setMainState({
                                          ...mainState,
                                          editingIndex: x?.id,
                                          upc: x?.upc,
                                          title: x?.title,
                                          prevUpc: x?.upc,
                                          prevTitle: x?.title,
                                          isUPCError: false,
                                          UPCErrorMessage: "",
                                        });
                                      }}
                                    />
                                  </td>
                                </RenderIf>
                              </RenderIf>
                            </>
                          }
                        >
                          <>
                            <RenderIf isTrue={showCol("Account Details")}>
                              <td>
                                <div className="overflow-add-modal">
                                  <span className="d-flex align-items-center">
                                    Account ID: <h6 className="mb-0 pl-2">{x.account_id}</h6>
                                  </span>
                                </div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Title")}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="position-relative d-block min-height-55 mt-4">
                                    <input
                                      className="form-control"
                                      style={{ width: "400px" }}
                                      id={`title-${key}`}
                                      type="text"
                                      placeholder="title"
                                      defaultValue={x?.title}
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        setMainState({
                                          ...mainState,
                                          title: val,
                                          titleErrorMessage: val.length > 255 ? "should be <=255" : "",
                                          isTitleError: val.length > 255,
                                        });
                                      }}
                                    />
                                    <RenderIf isTrue={mainState.titleErrorMessage}>
                                      <p className="text-danger postiton-absolute mb-0">
                                        {mainState.titleErrorMessage}
                                      </p>
                                    </RenderIf>
                                  </span>
                                </div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={isAdmin() && showCol("Warehouse")}>
                                <td>{renderWarehouseName(x)}</td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("SKU")}>
                              <td>{x.sku}</td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Total Qty")}>
                              <td className="text-center">{x.qty}</td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Damage Items")}>
                              <td className="text-center">{sumBy(x?.received_shipments, "damaged_qty") || 0}</td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Price")}>
                              <TableFieldEditabel
                                index={key}
                                amountText={amountText}
                                item={x}
                                itemKey={itemKey}
                                setItemKey={setItemKey}
                                loaderKey={loaderKey}
                                setLoaderKey={setLoaderKey}
                              />
                            </RenderIf>
                            <RenderIf isTrue={showCol("UPC")}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="position-relative d-block min-height-55 mt-4">
                                    <input
                                      id={`upc-${key}`}
                                      className="form-control"
                                      style={{ minWidth: 100 }}
                                      type="text"
                                      defaultValue={x?.upc}
                                      placeholder="upc"
                                      onChange={(e) => {
                                        checkUPC(e.target.value);
                                      }}
                                    />
                                    <RenderIf isTrue={mainState.UPCErrorMessage}>
                                      <p className="text-danger postiton-absolute mb-0">{mainState.UPCErrorMessage}</p>
                                    </RenderIf>
                                  </span>
                                </div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Qty Details")}>
                              <td>
                                <div>Qty Details?</div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Shipment Details")}>
                              <td>
                                <div>Shipment Details</div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Received On")}>
                              <td>
                                <div>Received On</div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Bin Details")}>
                              <td>
                                <div className="overflow-add-modal">
                                  <span className="d-flex align-items-center">
                                    Bin #:{" "}
                                    <h6 className="mb-0 pl-2">
                                      {getBinName(x?.received_shipments)}{" "}
                                      <RenderIf isTrue={x.qty}>
                                        <i
                                          className="ml-1 bx bx-pencil cursor-pointer"
                                          onClick={(_) => {
                                            setBinLocationDetail(x?.received_shipments);
                                            toggleBinDetailModal();
                                          }}
                                        />
                                      </RenderIf>
                                    </h6>
                                  </span>
                                </div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Location")}>
                              <td>
                                <div>Location</div>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={showCol("Type")}>
                              <td>
                                <span
                                  className={`font-size-12 badge-soft-success  badge badge-pill ${
                                    x.qty_multiplier > 1 ? "badge-soft-warning" : "badge-warning"
                                  }`}
                                >
                                  {x.qty_multiplier > 1 ? "Bundle" : "Single Item"}
                                </span>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={isAdmin() && showCol("Storage Charges Exemption")}>
                                <td>
                                  {inventoryPriceUpdateloading &&
                                  loaderKey.type === "storage" &&
                                  loaderKey.id === x.id ? (
                                    <span className="w-100 justify-content-center d-flex">
                                      <Spinner color="primary" style={{ width: "20px", height: "20px" }} />
                                    </span>
                                      ) : (
                                    <StorageCheckbox
                                      disabled={!isEmpty(loaderKey)}
                                      msg="this inventory item"
                                      className="w-100 justify-content-center d-flex"
                                      val={x.is_storage_exempt}
                                      onConfirm={(val) => {
                                        setLoaderKey({ type: "storage", id: x.id });
                                        dispatch(
                                          actions.updateInventoryItemPrice(x.id, {
                                            is_storage_exempt: val,
                                            sku: x.sku,
                                          }),
                                        );
                                      }}
                                      isShowLabel={false}
                                    />
                                      )}
                                </td>
                              </RenderIf>
                            <RenderIf isTrue={showCol("Oversized")}>
                              <td>
                                <RenderIf
                                  isTrue={isOversizeAccess}
                                  fallback={
                                    <span
                                      className={`font-size-12 badge-soft-success  badge badge-pill ${
                                        x.oversized ? "badge-success" : "badge-warning"
                                      }`}
                                    >
                                      {x.oversized ? "Yes" : "No"}
                                    </span>
                                  }
                                >
                                  <div className="custom-control custom-switch custom-switch-lg" dir="ltr">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={x.oversized}
                                      onChange={() => null}
                                    />
                                    <label
                                      className="custom-control-label cursor-pointer"
                                      onClick={() => handleOnClick(x, key)}
                                    ></label>
                                  </div>
                                </RenderIf>
                              </td>
                            </RenderIf>
                            <RenderIf isTrue={(isWH || showEditOpt) && showCol("Actions")}>
                              <td>
                                <i
                                  className="text-success bx bx-md bx-check cursor-pointer"
                                  onClick={() => {
                                    if (!mainState.isTitleError && !mainState.isUPCError) {
                                      updateData(x?.id);
                                    }
                                  }}
                                />
                                <i
                                  className="text-danger bx bx-md bx-x cursor-pointer"
                                  onClick={() =>
                                    setMainState({
                                      ...mainState,
                                      editingIndex: -1,
                                      upc: "",
                                      title: "",
                                      isUPCError: false,
                                      UPCErrorMessage: "",
                                    })
                                  }
                                />
                              </td>
                            </RenderIf>
                          </>
                        </RenderIf>
                      </tr>
                    ))}
                  </>
                }
              />
              <div className="tent-pagination">
                <CustomPagination
                  pageOptions={[30, 50, 100]}
                  total={whInvetoryData?.count}
                  page={whInvetoryData?.page}
                  perPage={whInvetoryData?.per_page}
                  filters={filters}
                  tabsFilter={filters}
                  setTabFilter={(e) => setFilters(filters.per_page !== e.per_page ? { ...e, page: 1 } : e)}
                />
              </div>
            </CardBody>
            <RenderIf isTrue={!whInvetoryData?.inventoryItems?.length}>
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
            </RenderIf>
          </Card>
        </Container>
        {setBinDetailModalIsOpen && (
          <ShowBinLocationsModal
            success={success}
            error={error}
            isShipmentWise={+activeTab === 2}
            binLocationDetail={binLocationDetail}
          />
        )}
        {showReportModal && (
          <ReportModal updateShowReportModal={updateShowReportModal} reportTitle="Inventory Report" />
        )}
      </div>
    </>
  );
};

export default InventryItems;
