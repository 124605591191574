import {
  API_ERROR,
  BANNER_NOTIFICATION_UPDATE,
  CREATE_NOTIFICATION_EMAILS,
  CREATE_NOTIFICATION_EMAILS_SUCCESS,
  DELETE_NOTIFICATION_EMAIL,
  DELETE_NOTIFICATION_EMAIL_SUCCESS,
  FETCH_NOTIFICATIONS_SETTINGS,
  FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
  SET_EMAIL_TYPE_ENABLED,
  SET_EMAIL_TYPE_ENABLED_SUCCESS,
} from "./actionTypes";

export const deleteNotificationEmail = (id) => {
  return {
    type: DELETE_NOTIFICATION_EMAIL,
    payload: id,
  };
};

export const deleteNotificationEmailSuccess = (data) => {
  return {
    type: DELETE_NOTIFICATION_EMAIL_SUCCESS,
    payload: data,
  };
};

export const updateBannerNotification = (data) => ({
  type: BANNER_NOTIFICATION_UPDATE,
  payload: data,
});

export const createNotificationEmails = (nestedVariation, type) => {
  return {
    type: CREATE_NOTIFICATION_EMAILS,
    payload: { nestedVariation, type },
  };
};

export const createNotificationEmailsSuccess = (data) => {
  return {
    type: CREATE_NOTIFICATION_EMAILS_SUCCESS,
    payload: data,
  };
};

export const fetchNotificationsSettings = () => ({
  type: FETCH_NOTIFICATIONS_SETTINGS,
  payload: {},
});

export const fetchNotificationsSettingsSuccess = (data) => ({
  type: FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
  payload: data,
});

export const setEmailTypeEnabled = (data) => ({
  type: SET_EMAIL_TYPE_ENABLED,
  payload: data,
});

export const setEmailTypeEnabledSuccess = (data) => ({
  type: SET_EMAIL_TYPE_ENABLED_SUCCESS,
  payload: data,
});

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
