import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import Modal from "./Modal";
import "flatpickr/dist/themes/dark.css";
import Select, { components } from "react-select";
import { sortBy, concat, map } from "lodash";
// actions
import {
  requestAvailablePaymentReports,
  requestPaymentReport,
  setPaymentReportModal,
  setPreloader,
} from "../../store/actions";
import getBrandImageSrc from "../../utils/brandImages";
import ReportDownloadMsg from "./ReportDownloadMsg";
import CheckBox from "./CheckBox";
import { getUserInfo, RenderIf } from "../../utils/common";
import { DATE_FORMATS } from "../../constants";
const formatDate = (date) => moment(date, "MMDDYYYY").format(DATE_FORMATS.DATE);
const sortDates = (dates, toDate) =>
  sortBy(dates, (date1, date2) => moment(toDate(date2), "MMDDYYYY") - moment(toDate(date1), "MMDDYYYY"));
const makeStoreOptions = (props) => {
  const reducer = (arr, acc, i) =>
    acc.valid ? concat(arr, { label: acc.name, value: acc.id, marketplace: acc.marketplace, key: i }) : arr;
  return props.MarketPlaceAccount?.[props?.agency ? "agencyAccounts" : "accounts"]?.data?.reduce(reducer, []);
};

const PaymentReportExportModal = (props) => {
  const [isAmz, setIsAmz] = useState(false);
  const [allCols, setAllCols] = useState(false);
  const toDate = (date) => (isAmz ? date?.date : date);

  const makeExtraCols = (values) => (allCols ? props.SourceOrders.extraCols : map(values.extraCols, "label"));

  useEffect(() => {
    props.setPreloader(props.SourceOrders.loading);
  }, [props.SourceOrders.loading]);

  const formik = useFormik({
    initialValues: {
      storeId: "",
      reportDate: "",
      extraCols: [],
    },
    validate: (values) => {
      const errors = {};
      props.SourceOrders?.currentReports?.length &&
        !values.reportDate &&
        (errors.reportDate = "Report selection is required");
      props.MarketPlaceAccount?.[props?.agency ? "agencyAccounts" : "accounts"]?.data?.length &&
        !values.storeId &&
        (errors.storeId = "Account selection is required");
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (!values.storeId || !values.reportDate) return;
      props.requestPaymentReport({
        storeId: values.storeId.value,
        [isAmz ? "reportId" : "reportDate"]: values.reportDate.value,
        agencyId: props?.agency?.id,
        agencyProfit: props?.agency?.agency_profit,
        extraCols: makeExtraCols(values),
      });
    },
  });

  function setStore (store) {
    formik.setFieldValue("storeId", store);
    setIsAmz(store.marketplace === "amazon");
    props.requestAvailablePaymentReports({
      storeId: store.value || "",
      isAmz: store.marketplace === "amazon",
      agencyId: props?.agency?.id,
    });
  }

  useEffect(() => {
    formik.resetForm();
    if (!props.SourceOrders.paymentReportModalIsOpen || makeStoreOptions(props)?.length !== 1) return;
    const [store] = makeStoreOptions(props);
    setStore(store);
  }, [props.SourceOrders.paymentReportModalIsOpen]);

  const toggleModal = () => {
    props.setPaymentReportModal(!props.SourceOrders.paymentReportModalIsOpen);
  };

  const storeOptions = {
    Option: (props) => (
      <components.Option {...props}>
        {getBrandImageSrc(props.data.marketplace)
          ? (
          <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
            )
          : null}
        {props.data.label}
      </components.Option>
    ),
  };

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.SourceOrders.paymentReportModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Download Payment Report</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders.error}
            </Alert>
          )}
          <RenderIf isTrue={props.SourceOrders.success}>
            <ReportDownloadMsg action={setPaymentReportModal} />
          </RenderIf>
          {formik.errors.reportDate ? <small className="text-danger m-1">{formik.errors.reportDate}</small> : null}
          {formik.errors.storeId ? <small className="text-danger m-1">{formik.errors.storeId}</small> : null}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Col className="my-1">
              <RenderIf
                isTrue={makeStoreOptions(props)?.length}
                fallback={
                  <div className="d-flex justify-content-center m-2">
                    <div className="d-flex flex-column">
                      <h5>{"Kindly add a valid store to use this feature"}</h5>
                    </div>
                  </div>
                }
              >
                <Label>Marketplace Account</Label>
                <RenderIf
                  isTrue={makeStoreOptions(props)?.length !== 1}
                  fallback={<Input disabled value={formik.values.storeId?.label || ""} className="form-group" />}
                >
                  <FormGroup className="select2-container w-100">
                    <Select
                      name="storeId"
                      value={formik.values.storeId}
                      onChange={(e) => setStore(e)}
                      options={makeStoreOptions(props)}
                      classNamePrefix="select2-selection"
                      height={{ maxHeight: "250px" }}
                      components={storeOptions}
                    />
                  </FormGroup>
                </RenderIf>
              </RenderIf>
              {formik.values.storeId && props.SourceOrders?.currentReports && (
                <>
                  <Label>Select Report</Label>
                  <FormGroup className="select2-container w-100">
                    <Select
                      name="reportDate"
                      value={formik.values.reportDate}
                      onChange={(e) => formik.setFieldValue("reportDate", e)}
                      options={sortDates(props.SourceOrders?.currentReports, toDate).reduce((init, row, i) => {
                        init.push({ label: formatDate(toDate(row)), value: isAmz ? row.reportId : row, key: i });
                        return init;
                      }, [])}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </>
              )}
              {formik.values.storeId && props.SourceOrders?.extraCols?.length
                ? (
                <>
                  <RenderIf isTrue={[5335, 298].includes(getUserInfo()?.id)}>
                    <CheckBox
                      name="all_cols"
                      state={allCols}
                      setState={(e) => setAllCols(e)}
                      isSwitch={true}
                      label="Complete Report"
                      className="mb-2"
                    />
                  </RenderIf>
                  <RenderIf isTrue={!allCols}>
                    <Label>Extra Columns</Label>
                    <FormGroup className="select2-container w-100">
                      <Select
                        name="extraCols"
                        value={formik.values.extraCols}
                        isMulti={true}
                        onChange={(e) => formik.setFieldValue("extraCols", e)}
                        options={props.SourceOrders.extraCols.map((x, i) => ({ label: x, value: i }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </RenderIf>
                </>
                  )
                : null}
            </Col>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              {makeStoreOptions(props)?.length
                ? (
                <Button type="submit" color="success" className="mr-3">
                  Submit
                </Button>
                  )
                : null}
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders } = state;
  return { SourceOrders };
};

export default connect(mapStateToProps, {
  requestAvailablePaymentReports,
  setPaymentReportModal,
  requestPaymentReport,
  setPreloader,
})(PaymentReportExportModal);
