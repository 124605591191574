import {
  CREATE_SUPPLIER_ORDER,
  CREATE_SUPPLIER_ORDER_SUCCESS,
  RESET_STATE,
  UPDATE_TRACKING_INFO,
  UPDATE_TRACKING_INFO_SUCCESS,
  SET_TRACKING_INFO_MODAL,
  API_ERROR,
  MARK_AS_IGNORE,
  MARK_AS_IGNORE_SUCCESS,
  DELETE_SUPPLIER_ORDER,
  DELETE_SUPPLIER_ORDER_SUCCESS,
  SET_EDIT_SUPPLIER_ORDER_MODAL,
  UPDATE_SUPPLIER_ORDER,
  UPDATE_SUPPLIER_ORDER_SUCCESS,
  FETCH_ALL_RETURN_LABEL_HISTORY,
  FETCH_ALL_RETURN_LABEL_HISTORY_SUCCESS,
  FETCH_RETURN_LABEL_HISTORY,
  FETCH_RETURN_LABEL_HISTORY_SUCCESS,
} from "./actionTypes";

export const createSupplierOrders = (data) => {
  return {
    type: CREATE_SUPPLIER_ORDER,
    payload: { data },
  };
};

export const createSupplierOrdersSuccess = () => {
  return {
    type: CREATE_SUPPLIER_ORDER_SUCCESS,
    payload: {},
  };
};

export const fetchAllReturnLabelHistory = (params) => ({
  type: FETCH_ALL_RETURN_LABEL_HISTORY,
  payload: params,
});

export const fetchAllReturnLabelHistorySuccess = (res) => ({
  type: FETCH_ALL_RETURN_LABEL_HISTORY_SUCCESS,
  payload: res,
});

export const fetchReturnLabelHistory = (orderId) => ({
  type: FETCH_RETURN_LABEL_HISTORY,
  payload: orderId,
});

export const fetchReturnLabelHistorySuccess = (res) => ({
  type: FETCH_RETURN_LABEL_HISTORY_SUCCESS,
  payload: res,
});

export const markAsIgoreStatus = (items, sourceOrderId) => {
  return {
    type: MARK_AS_IGNORE,
    payload: { data: { items: [items] }, sourceOrderId },
  };
};

export const markAsIgoreSuccess = () => {
  return {
    type: MARK_AS_IGNORE_SUCCESS,
    payload: {},
  };
};

export const updateTrackingInfo = ({ data, targetOrderId }) => {
  return {
    type: UPDATE_TRACKING_INFO,
    payload: { data, targetOrderId },
  };
};

export const updateTrackingInfoSuccess = () => {
  return {
    type: UPDATE_TRACKING_INFO_SUCCESS,
    payload: {},
  };
};

export const deleteSupplierOrder = (id) => {
  return {
    type: DELETE_SUPPLIER_ORDER,
    payload: id,
  };
};

export const deleteSupplierOrderSuccess = () => {
  return {
    type: DELETE_SUPPLIER_ORDER_SUCCESS,
    payload: {},
  };
};

export const setTrackingInfoModal = (bool) => {
  return {
    type: SET_TRACKING_INFO_MODAL,
    payload: bool,
  };
};

export const updateSupplierOrder = ({ data, sourceOrderId }) => {
  return {
    type: UPDATE_SUPPLIER_ORDER,
    payload: { data: { items: [data] }, sourceOrderId },
  };
};

export const updateSupplierOrderSuccess = () => {
  return {
    type: UPDATE_SUPPLIER_ORDER_SUCCESS,
    payload: {},
  };
};

export const setEditSupplierOrderModal = (bool) => {
  return {
    type: SET_EDIT_SUPPLIER_ORDER_MODAL,
    payload: bool,
  };
};

export const resetSupplierOrderState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
