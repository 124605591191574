import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Common/Modal";
import PillBtn from "../../../components/Common/PillBtn";
import * as actions from "../../../store/actions";

const RemoveStrategyModal = ({ platform, strategyId }) => {
  const dispatch = useDispatch();
  const { removeStrategyModalIsOpen } = useSelector((state) => state.Repricer);
  const toggleRemoveStrategyModal = () => dispatch(actions.setRemoveStrategyModal(!removeStrategyModalIsOpen));
  return (
    <Modal size="md" isOpen={removeStrategyModalIsOpen}>
      <div className="p-3">
        <i
          className="bx bx-sm bx-x inventory-color cursor-pointer"
          style={{ position: "absolute", right: "24px", top: "24px" }}
          onClick={toggleRemoveStrategyModal}
        />
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <i style={{ fontSize: "100px" }} className="text-danger bx bx-error" />
        </div>
        <div className="mt-3 d-flex justify-content-center align-items-center font-weight-semibold inventory-color font-size-20">
          Are you sure you want to proceed?
        </div>
        <div className="mt-3 d-flex justify-content-center align-items-center font-weight-medium font-size-13">
          This Strategy will be deleted.
        </div>
        <div className="mt-3 mb-2 d-flex justify-content-center align-items-center">
          <PillBtn
            className="mr-2"
            title="Cancel"
            name="Cancel"
            color="primary"
            outline={true}
            onClick={toggleRemoveStrategyModal}
          />
          <PillBtn
            className="ml-2"
            title="Confirm"
            name="Confirm"
            color="primary"
            onClick={() => {
              dispatch(actions.removeStrategy(platform, strategyId));
              toggleRemoveStrategyModal();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RemoveStrategyModal;
