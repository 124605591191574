import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// Login Redux States
import {
  LOGIN_USER,
  LOGIN_WAREHOUSE_USER,
  LOGOUT_USER,
  GET_OAUTH_APP_CREDS,
  CHECK_TOKEN_EXPIRY,
  INTEGRATE_WITH_REPICER,
  GENERATE_OTP,
  VERIFY_OTP,
} from "./actionTypes";
import {
  loginSuccess,
  logoutUserSuccess,
  loginWareHouseUserSuccess,
  getOAuthAppCredsSuccess,
  checkTokenExpirySuccess,
  integrateWithRepricerSuccess,
  apiError,
  verifyOtpSuccess,
  generateOtpSuccess,
} from "./actions";
import {
  login,
  logout,
  loginWareHouse,
  loginOAuth,
  getClientInfo,
  checkTokenExpiry,
  getUserInfoApi,
  sendDataToRepricer,
  generateOtp,
  verifyOtp,
} from "../../../api";
import {
  clearLocalStorage,
  deleteTokenFromCookie,
  encode,
  getUserInfo,
  decodeJwtToken as jwtDecode,
  makeQP,
  setAuthTokenInBrowser,
} from "../../../utils/common";
import { fetchMarketplaceAccounts } from "../../marketPlaceAccounts/actions";
import { fetchAccountSettings } from "../../settings/actions";
import * as actions from "../../actions";

function * changeTheme (theme = "dark") {
  localStorage.setItem("THEME", theme);
  yield put(actions.switchTheme(theme));
  yield put(actions.changeSidebarTheme(theme));
  yield put(actions.changeTopbarTheme(theme === "light" ? "dark" : "light"));
};

function * loginUser ({ payload: { user, history, queryParams } }) {
  try {
    if (Object.keys(queryParams).length > 2) {
      yield handleOAuth({ user, queryParams });
    } else {
      const resp = yield call(login, user);
      localStorage.removeItem("I6fiFamLVmL");
      setAuthTokenInBrowser(resp.authTokenJwt || "");
      if (resp.authTokenJwt && jwtDecode(resp.authTokenJwt)?.role === "admin") {
        const payload = jwtDecode(resp.authTokenJwt);
        localStorage.setItem(
          "I6fiFamLVmL",
          encode(
            JSON.stringify({
              username: payload.username,
              email: payload.email,
            }),
            10,
          ),
        );
      }
      yield put(loginSuccess(user));
      yield changeTheme();
      if (localStorage.getItem("authTokenJwt") && jwtDecode(resp.authTokenJwt)?.status === "pending_confirm") {
        history.push("/email_verification/" + jwtDecode(resp.authTokenJwt)?.user_email ? encode(jwtDecode(resp.authTokenJwt)?.user_email) : "");
      } else if (jwtDecode(resp.authTokenJwt)?.is_two_fa_enabled)
        history.push("/two_factor_verification", { from: "/login" });
      else if (jwtDecode(resp.authTokenJwt)?.agency_id) {
        if (localStorage.getItem("shopifyParams")) {
          const shopifyParams = JSON.parse(localStorage.getItem("shopifyParams"));
          const shopifyUrl = "/settings" + makeQP(shopifyParams);
          window.location.href = shopifyUrl;
          localStorage.removeItem("shopifyParams");
        } else history.push("/");
      } else history.push("/agency");
    }
  } catch (error) {
    yield put(apiError(error.message || "Invalid Credentials!"));
  }
}

function * loginWareHouseUser ({ payload: { user, history } }) {
  try {
    const resp = yield call(loginWareHouse, user);
    localStorage.removeItem("I6fiFamLVmL");
    setAuthTokenInBrowser(resp.authTokenJwt || "");
    yield put(loginWareHouseUserSuccess(user));
    history.push("/warehouse_dashboard");
  } catch (error) {
    yield put(apiError(error.message || "Invalid Credentials!"));
  }
}

function * handleOAuth ({ user, queryParams }) {
  try {
    const oauthRes = yield call(loginOAuth, { user, queryParams });
    if (oauthRes?.error) {
      throw new Error("Invalid Credentials");
    }
    const sendDataRes = yield call(getUserInfoApi, user);
    if (sendDataRes?.success) {
      document.location.href =
        queryParams?.redirect_url +
        "?" +
        Object.keys(sendDataRes?.data)
          .map((key) =>
            Array.isArray(sendDataRes?.data[key])
              ? `${key}=${encodeURIComponent(JSON.stringify(sendDataRes?.data[key]))}`
              : `${key}=${sendDataRes?.data[key]}`,
          )
          .join("&");
    }
  } catch (error) {
    yield put(
      apiError(["invalid_client", "invalid_grant"].some((x) => x === error.message) ? "Access Denied" : error.message),
    );
  }
}

function * getOAuthAppCredsSaga ({ payload: { clientId, redirectUrl } }) {
  try {
    const res = yield call(getClientInfo, clientId);
    if (res?.success) {
      yield call(checkTokenExpirySaga, {
        queryParams: {
          client_id: clientId,
          app_url: res.data?.redirect_url,
          secret_key: res.data?.secret_key,
          redirect_url: redirectUrl,
        },
      });
      yield put(getOAuthAppCredsSuccess(res.data));
    } else throw new Error("Not an authentic user");
  } catch (error) {
    yield put(apiError(error.message || "Invalid Credentials!"));
  }
}

function * integrateWithRepricerSaga ({ payload }) {
  try {
    const res = yield call(sendDataToRepricer, payload);
    if (res?.success) {
      yield put(integrateWithRepricerSuccess(res?.success, res?.errors));
      yield put(fetchMarketplaceAccounts({}));
      yield put(fetchAccountSettings(false));
    } else throw new Error("Failed to integrate with repricer");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * checkTokenExpirySaga ({ queryParams }) {
  try {
    const res = yield call(checkTokenExpiry, localStorage.getItem("authTokenJwt") || "");
    if (res.success) {
      if (!res?.isExpired) {
        yield handleOAuth({
          user: { username: res?.data?.user_email },
          queryParams: { ...queryParams },
        });
      } else yield put(checkTokenExpirySuccess());
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * logoutUser ({ payload: { history } }) {
  try {
    const userInfo = getUserInfo();
    if (userInfo?.id) logout(userInfo.id);
    yield clearLocalStorage();
    deleteTokenFromCookie();
    yield put(logoutUserSuccess());
    history.push(userInfo?.isWH ? "/login_warehouse" : "/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function * generateOtpSaga () {
  try {
    const res = yield call(generateOtp);
    yield put(generateOtpSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * verifyOtpSaga ({ payload: { data, history } }) {
  try {
    const res = yield call(verifyOtp, data);
    if (res.success) {
      if (getUserInfo()?.agency_id) history.push("/");
      else history.push("/agency");
    }
    yield put(verifyOtpSuccess());
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchUserLogin () {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function * watchWareHouseUserLogin () {
  yield takeEvery(LOGIN_WAREHOUSE_USER, loginWareHouseUser);
}

export function * watchUserLogout () {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function * watchOAuthAppCreds () {
  yield takeEvery(GET_OAUTH_APP_CREDS, getOAuthAppCredsSaga);
}

export function * watchCheckTokenExpiry () {
  yield takeEvery(CHECK_TOKEN_EXPIRY, checkTokenExpirySaga);
}

export function * watchIntegrateWithRepricer () {
  yield takeEvery(INTEGRATE_WITH_REPICER, integrateWithRepricerSaga);
}

export function * watchGenerateOtp () {
  yield takeEvery(GENERATE_OTP, generateOtpSaga);
}

export function * watchVerifyOtp () {
  yield takeEvery(VERIFY_OTP, verifyOtpSaga);
}

function * authSaga () {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchWareHouseUserLogin),
    fork(watchOAuthAppCreds),
    fork(watchIntegrateWithRepricer),
    fork(watchCheckTokenExpiry),
    fork(watchVerifyOtp),
    fork(watchGenerateOtp),
  ]);
}

export default authSaga;
