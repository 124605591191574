import React from "react";
import { RenderIf } from "../../utils/common";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import CustomTooltip from "../../components/Common/CustomTooltip";

const ShowUpc = ({ index, upc = "", className }) => (
  <div className={className} id={`t_${index}_t`}>
    <RenderIf isTrue={upc} fallback={"N/A"}>
      {upc?.slice(0, 20)} {upc?.length > 20 ? "..." : ""}
      <CopyToClipBoard text={upc} />
      <CustomTooltip placement="left" content={upc} target={`t_${index}_t`} />
    </RenderIf>
  </div>
);

export default ShowUpc;
