import { makeQP } from "../../utils/common";
import request from "../restClient";

const resource = "/marketplace_accounts";

export const sendEmailErrorApi = body => request({ url: "/users/send_email", method: "POST", body });

export const getMarketPlaceAccounts = async (params) => {
  const url =
    `${resource}${params.id ? `/${params.id}` : ""}` + makeQP({ all: true, ...params });
  return await request({ url, method: "GET" });
};

export const bulkUpdateMarketplace = (body) => {
  return request({ url: `${resource}/update_stores_wh`, method: "PUT", body });
};

export const updateMarketPlaceAccounts = async ({ data, id, params = {} }) => {
  const url =
    `${resource}/${id}?` +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "PUT", body: data });
};

export const fetchSpApiRefreshToken = async (body) => {
  const url = "/sp_api/gen_ref_token";
  return await request({ url, method: "POST", body });
};

export const fetShopifyRefreshToken = async (body) => request({ url: "/shopify/gen_ref_token", method: "POST", body });

export const createMarketPlaceAccounts = async ({ data, params }) => {
  const url =
    `${resource}?` +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "POST", body: data });
};

export const updateRepricer = async (data) => {
  const url = `${resource}/repricer`;
  return await request({ url, method: "PATCH", body: data });
};

export const checkStore = async (body) => {
  const url = `${resource}/scratchpad`;
  return await request({ url, method: "POST", body });
};

export const deleteMarketPlaceAccounts = async ({ id }) => {
  const url = `${resource}/${id}`;
  return await request({ url, method: "DELETE" });
};

export const setMarketPlaceAccountVacationMode = async (data) => {
  const url = `${resource}/vacation_mode`;
  return await request({ url, method: "POST", body: data });
};

export const checkStoreNameApi = async (storeName) =>
  request({ url: `${resource}/checkShop/${storeName}`, method: "GET" });
