import request from "../restClient";

export const getAllAmazonSellers = async (params) => {
  const url = Object.keys(params).reduce(
    (url, key, _i) => url + (_i > 0 ? "&" : "") + `${key}=${encodeURIComponent(params[key])}`,
    "/amazon_sellers/getAll?",
  );
  return await request({ url, method: "GET" });
};

export const setAmazonSellersBlackList = async ({ data }) => {
  const url = "/amazon_sellers/mark_as_blacklist";
  return await request({ url, method: "POST", body: data });
};

export const createAmazonSellers = async ({ data }) => {
  const url = "/amazon_sellers";
  return await request({ url, method: "POST", body: data });
};

export const updateAmazonSeller = async ({ data }) => {
  const url = `/amazon_sellers/${data?.seller_id}`;
  return await request({ url, method: "PUT", body: data });
};
