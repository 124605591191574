import {
  CALL_SP_API,
  CALL_SP_API_SUCCESS,
  CHECK_WALMART_OFFERS,
  CHECK_WALMART_OFFERS_SUCCESS,
  RESET_CHECK_OFFERS_STATE,
  API_ERROR,
} from "./actionTypes";

export const callSPAPI = (body) => {
  return {
    type: CALL_SP_API,
    payload: { body },
  };
};

export const callSPAPISuccess = (response) => {
  return {
    type: CALL_SP_API_SUCCESS,
    payload: response,
  };
};

export const checkWalmartOffers = (body) => {
  return {
    type: CHECK_WALMART_OFFERS,
    payload: { body },
  };
};

export const checkWalmartOffersSuccess = (response) => {
  return {
    type: CHECK_WALMART_OFFERS_SUCCESS,
    payload: response,
  };
};

export const resetCheckOffersState = () => {
  return {
    type: RESET_CHECK_OFFERS_STATE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
