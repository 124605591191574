import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, CardBody, Row, Col, Alert, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { RenderIf } from "../../utils/common";
import {
  retryWhCharges,
  updatePaymentSource,
  setAddCardModal,
  getPaymentSources,
  deleteSource,
} from "../../store/actions";
import AddCard from "../Settings/components/AddCard";

const promise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const PaymentMethods = (_) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { sources, loading, success, error, addCardModalIsOpen } = useSelector((state) => state.Stripe);
  const { account } = useSelector((state) => state.Settings);

  function CloseAddCardModal (e) {
    dispatch(setAddCardModal(!addCardModalIsOpen));
    !e && dispatch(getPaymentSources());
  }

  useEffect(() => {
    dispatch(getPaymentSources());
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        {account?.data && addCardModalIsOpen && (
          <Elements stripe={promise}>
            <AddCard isOpen={addCardModalIsOpen} toggleOpen={CloseAddCardModal} />
          </Elements>
        )}
        <RenderIf isTrue={isOpen}>
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              dispatch(updatePaymentSource(formData));
              setIsOpen(false);
            }}
            onCancel={() => setIsOpen(false)}
          >
            To update your default payment method!
          </SweetAlert>
        </RenderIf>
        <Row>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-4 ml-3" style={{ fontWeight: 600, fontSize: 16, textTransform: "uppercase" }}>
                Payment Methods{" "}
                {loading
                  ? (
                  <Spinner color="primary" style={{ width: "20px", height: "20px" }} className="ml-3" />
                    )
                  : null}
              </h4>
            </div>
            <div className="page-title-right">
              {[success, error].map((msg, i) => (
                <RenderIf isTrue={msg} key={i}>
                  <div className="auto-hide ml-2" style={{ height: "inherit" }}>
                    <Alert color={i ? "danger" : "success"}>
                      <i className="bx bx-info-circle pr-2"></i>
                      {msg}
                    </Alert>
                  </div>
                </RenderIf>
              ))}
            </div>
          </div>
        </Row>
        <Card>
          <CardBody>
            <RenderIf isTrue={loading && !sources?.length}>
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>Fetching cards...</h1>
                </div>
              </div>
            </RenderIf>

            <Row>
              <RenderIf isTrue={Array.isArray(sources) && sources?.length}>
                {sources?.map((item, key) => (
                  <Col md={4} key={key}>
                    <Card className={`payment-card ${item.failure_message ? "red" : ""}`} key={key}>
                      <CardBody>
                        <Row>
                          <div className="col-md-5">
                            <div className="d-flex flex-direction-column row-gap-10">
                              <span>Card Number</span>
                              <h6>**** **** **** {item.last4}</h6>
                            </div>

                            <div className="d-flex flex-direction-column row-gap-10">
                              <span>Card holder name</span>
                              <h6>{item?.name}</h6>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="d-flex align-items-end flex-direction-column">
                              <div className="d-flex flex-direction-column row-gap-10">
                                <div className="d-flex grid-column-gap-5 icon-portion">
                                  {item.failure_message && (
                                    <div className="d-flex">
                                      <span className="text-danger" style={{ fontSize: "12px", fontFamily: "Poppins" }}>
                                        <i id={`warning-${key}`} className="dripicons-warning text-danger"></i>
                                        {item.failure_message}
                                      </span>
                                    </div>
                                  )}
                                  {!item.default_source && (
                                    <i
                                      title="Make this card to default"
                                      className="bx bx-xs bx-log-in text-primary cursor-pointer"
                                      onClick={() => {
                                        setFormData(item.id);
                                        setIsOpen(true);
                                      }}
                                    />
                                  )}
                                  <i
                                    title="Retry WH Charges"
                                    className="bx font-size-16 bx-reset text-primary cursor-pointer"
                                    onClick={() => dispatch(retryWhCharges({ retry: true, id: item.id }))}
                                  ></i>
                                  {item.default_source && (
                                    <i className="bx bxs-check-circle font-size-16 cursor-pointer text-success"></i>
                                  )}
                                </div>
                                <i
                                  className={`bx fs-30 card-type bxl-${item.brand?.toLowerCase()} text-primary cursor-pointer`}
                                ></i>
                              </div>
                              <span className="d-flex flex-direction-column row-gap-10">
                                <span>Exp</span>
                                <h6>{`${item.exp_month}/${item.exp_year}`}</h6>
                              </span>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                      <RenderIf isTrue={!item.default_source}>
                        <div className="text-center p-3 bg-color-remove" onClick={() => setIsConfirmOpen(true)}>
                          <h6 className="text-center pt-1">Remove Card</h6>
                          <RenderIf isTrue={isConfirmOpen}>
                            <SweetAlert
                              title="Are you sure?"
                              warning
                              showCancel
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => {
                                dispatch(deleteSource(item.id));
                                setIsConfirmOpen(false);
                              }}
                              onCancel={() => setIsConfirmOpen(false)}
                            >
                              To Delete this Payment Method
                            </SweetAlert>
                          </RenderIf>
                        </div>
                      </RenderIf>
                    </Card>
                  </Col>
                ))}
              </RenderIf>
              <RenderIf isTrue={!loading || sources?.length}>
                <Col md={4}>
                  <Card className="add-card" onClick={() => dispatch(setAddCardModal(true))}>
                    <div className="text-center">
                      <i className="bx bx-plus fs-30" />
                      <h6>Add {sources?.length ? "Other" : "New"} Card</h6>
                    </div>
                  </Card>
                </Col>
              </RenderIf>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default PaymentMethods;
