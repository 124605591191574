import React from "react";
const BookCall = () => {
  return (
    <>
      <div className="d-flex  calendar-box align-items-center grid-column-gap-15">
        <i className="bx bx bx-calendar-event" />
        <div className="book-call d-flex flex-column">
          <h6>Book a call with us!</h6>
          <p className="mb-1">
            Book your 1:1 call with our team member to help you get off to a fast start with Ecom Circles!
          </p>
        </div>
        <a
          className="yellow-outline blue-outline text-center"
          href="https://api.leadconnectorhq.com/widget/bookings/ecomscirclessupport"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a call
        </a>
      </div>
    </>
  );
};

export default BookCall;
