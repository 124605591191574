import React from "react";
import { pick, values } from "lodash";
import { Col, Label } from "reactstrap";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import renderErrors from "./RenderErrors";
import { adjustDate } from "../../../utils/common";

const TransactionReportForm = ({ formik }) => {
  const cols = ["start_date", "end_date"];
  return (
    <Col className="my-1">
      <Label>Select Date Range</Label>
      <div className="w-100">
        <Flatpickr
          className="form-control"
          placeholder="Select Date Range"
          value={values(pick(formik.values, cols))}
          options={{ mode: "range" }}
          onClose={(val) => {
            const newDates = val.map(adjustDate);
            if (newDates.length > 1) {
              formik.setFieldValue("start_date", newDates[0]);
              formik.setFieldValue("end_date", newDates[1]);
            }
          }}
        />
      </div>
      {renderErrors(formik, cols)}
    </Col>
  );
};

export default TransactionReportForm;
