import { useFormik } from "formik";
import { keys, omitBy, startCase } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Label, Row, FormGroup } from "reactstrap";
import Select from "react-select";
import Modal from "../../../../components/Common/Modal";
import PillBtn from "../../../../components/Common/PillBtn";
import { addItemToInventory, setAddReturnModal } from "../../../../store/actions";
import { getUserInfo, getWarehouseName, isAdmin, RenderIf } from "../../../../utils/common";

function AddReturnModal () {
  const dispatch = useDispatch();
  const { addReturnModal } = useSelector((state) => state.Returns);
  const { userWarehouses } = useSelector((state) => state.WareHouses);
  const toggleModal = () => dispatch(setAddReturnModal(false));

  const initialValues = {
    warehouse_id: "",
    tracking_number: "",
    account_id: "",
    bin_no: "",
    sku: "",
    customer_name: "",
    upc: "",
    qty: 1,
    price: 0,
    shipping: 0,
    order_id: "",
    type: { label: "Return", value: "return" },
    wh_status: { label: "Completed", value: "completed" },
  };

  const validate = (values) => {
    const errors = {};
    if (isAdmin() && !values.warehouse_id) errors.warehouse_id = "Warehouse is required.";
    if (!values.tracking_number) errors.tracking_number = "Tracking number is required.";
    if (!values.account_id) errors.account_id = "Account ID is required and must be positive.";
    if (!values.bin_no) errors.bin_no = "Bin # is required.";
    if (!values.sku) errors.sku = "SKU is required.";
    if (!values.customer_name) errors.customer_name = "Customer name is required.";
    if (values.qty <= 0) errors.qty = "Value must be a positive number.";
    ["account_id", "shipping", "price"].forEach(
      (x) => values[x] < 0 && (errors[x] = "Value must be a positive number."),
    );
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      values.warehouse_id = values.warehouse_id.value || getUserInfo()?.warehouse_id;
      values.type = values.type.value;
      values.wh_status = values.wh_status.value;
      const data = omitBy(values, (v) => v === "");
      dispatch(addItemToInventory(data));
      formik.resetForm();
      toggleModal();
    },
  });

  const adminAdd = (x) => isAdmin() && x === "warehouse_id";
  const MyLabel = ({ field }) => (
    <Label className="d-flex">
      {field === "bin_no" ? "Bin #" : startCase(field)}{" "}
      {["tracking_number", "sku", "account_id", "warehouse_id", "bin_no", "customer_name"].includes(field) && (
        <p className="danger mb-0"> *</p>
      )}
    </Label>
  );

  return (
    <Modal size="md" isOpen={addReturnModal} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Add Item to Inventory</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            {keys(initialValues)
              .slice(0, -2)
              .map((x, i) => (
                <React.Fragment key={i}>
                  <Col lg="12 mb-2">
                    <RenderIf
                      isTrue={adminAdd(x)}
                      fallback={
                        <RenderIf isTrue={x !== "warehouse_id"}>
                          <MyLabel field={x} />
                          <Input
                            name={x}
                            placeholder={x === "bin_no" ? "Bin #" : startCase(x)}
                            type={["account_id", "shipping", "qty", "price"].includes(x) ? "number" : "text"}
                            value={formik.values[x]}
                            min={0}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </RenderIf>
                      }
                    >
                      <MyLabel field={x} />
                      <FormGroup className="select2-container w-100">
                        <Select
                          name="wh_status"
                          value={formik.values.warehouse_id}
                          onChange={(e) => {
                            formik.setFieldValue("warehouse_id", e);
                          }}
                          options={Object.keys(userWarehouses || {}).map((x) => ({
                            label: getWarehouseName(userWarehouses[x]),
                            value: userWarehouses[x].id,
                          }))}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    </RenderIf>
                    {formik.touched[x] && formik.errors[x]
                      ? (
                      <span className="text-danger mt-1">{formik.errors[x]}</span>
                        )
                      : null}
                  </Col>
                </React.Fragment>
              ))}
            <Col lg="12">
              <Label>Type</Label>
              <FormGroup className="select2-container w-100">
                <Select
                  name="type"
                  value={formik.values.type}
                  onChange={(e) => {
                    formik.setFieldValue("type", e);
                  }}
                  options={[
                    { label: "Return", value: "return" },
                    { label: "2-step", value: "2 Step" },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
            <Col lg="12 mb-2">
              <Label>WH Status</Label>
              <FormGroup className="select2-container w-100">
                <Select
                  name="wh_status"
                  value={formik.values.wh_status}
                  onChange={(e) => {
                    formik.setFieldValue("wh_status", e);
                  }}
                  options={[
                    { label: "Incomplete", value: "incomplete" },
                    { label: "Completed", value: "completed" },
                    { label: "Blocked", value: "blocked" },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <PillBtn name="Submit" type="submit" color="success" className="mr-3" />
          </Row>
        </form>
      </div>
    </Modal>
  );
}

export default AddReturnModal;
