import React, { useEffect } from "react";
import { Badge } from "reactstrap";
import {
  toPascalCase,
  getUserInfo,
  isSelectedRow,
  RenderIf,
  isActiveAccount,
  getProductURL,
} from "../../../utils/common";
import { useSelector, useDispatch } from "react-redux";
import { BULK_SELECT_TYPES, DATE_FORMATS } from "../../../constants";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";

import ReactTable from "../../../components/Common/Table";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import moment from "moment-timezone";

// Import Actions
import {
  setSelectedListings,
  bulkDeleteListings,
  relistRetiredListings,
  setFilteredListings,
} from "../../../store/actions";

const { DATE_TIME } = DATE_FORMATS;

const RetiredListing = (props) => {
  const dispatch = useDispatch();
  const { storeFront, ListingIds, selectAllListings, setBulkSelectType, listingsPage, isSingleListing, allStoresObj } = props;
  const { listings, selectedListings, filteredListings } = useSelector((state) => state.Listings);
  const { users } = useSelector((state) => state.User);
  const { listing } = useSelector((state) => state.Supplier);

  const RetriedListingHeader = ["SKU", "Item ID/ASIN", "Status", "Retired/Deleted At", "Retired/Deleted By", "Action"];

  function handleDeleteListing ({ partner_id: partnerId, sku }, isDelete) {
    dispatch(
      (isDelete ? bulkDeleteListings : relistRetiredListings)({
        [isDelete ? "delete_type" : "select_type"]: "current_page",
        platform: storeFront.marketplace,
        listings_page: listingsPage.value,
        skus_data: [{ partner_id: partnerId, skus: [sku] }],
        va_id: getUserInfo()?.id,
      }),
    );
  }

  useEffect(() => {
    if (isSingleListing) dispatch(setFilteredListings([listing]));
    else dispatch(setFilteredListings(listings?.results));
  }, [isSingleListing, listings?.results, listing]);

  return (
    <>
      <ReactTable
        tableHeader={
          <>
            <th />
            <RenderIf isTrue={filteredListings?.length && isActiveAccount(storeFront)}>
              <th className="vw-checkbox">
                <div className="custom-control custom-checkbox change-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={ListingIds?.length > 0 && ListingIds?.length === selectedListings?.length}
                    onChange={() => false}
                  />
                  <label
                    className="custom-control-label"
                    onClick={() => {
                      if (ListingIds?.length === selectedListings?.length) {
                        setBulkSelectType(BULK_SELECT_TYPES.current_page);
                        dispatch(setSelectedListings([]));
                      } else selectAllListings();
                    }}
                  >
                    &nbsp;
                  </label>
                </div>
              </th>
            </RenderIf>
            {RetriedListingHeader.filter((header) => (isActiveAccount(storeFront) ? true : header !== "Action")).map(
              (header, index) => (
                <th key={`table-header ${index}`}>{header}</th>
              ),
            )}
          </>
        }
        tableBody={
          <>
            {filteredListings?.map((item, key) => (
              <tr
                key={"_listings_retired_" + key}
                className={`mt-3 mb-3${isSelectedRow(selectedListings, item) ? " selected-tr-bg-clr" : ""}`}
              >
                <td />
                <RenderIf isTrue={isActiveAccount(storeFront)}>
                  <td>
                    <div className="custom-control change-checkbox custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={!!selectedListings?.find((x) => x.id === item?.id)}
                        onChange={() => false}
                      />
                      <label
                        className="custom-control-label mt-2"
                        onClick={() => {
                          setBulkSelectType(BULK_SELECT_TYPES.current_page);
                          if (selectedListings?.find((x) => x.id === item?.id)) {
                            dispatch(setSelectedListings(selectedListings?.filter((x) => item.id !== x.id)));
                          } else {
                            dispatch(
                              setSelectedListings([
                                ...selectedListings,
                                {
                                  id: item.id,
                                  partner_id: item.partner_id,
                                  sku: item.sku,
                                },
                              ]),
                            );
                          }
                        }}
                      />
                    </div>
                  </td>
                </RenderIf>
                <td>
                  {item?.sku}
                  <CopyToClipBoard text={item?.sku} />
                </td>
                <td>
                  <a
                    href={getProductURL(item, storeFront.marketplace, allStoresObj[item.partner_id])}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary mb-1"
                  >
                    {item.item_id}
                  </a>
                  <CopyToClipBoard text={item?.item_id} />
                </td>
                <td>
                  {" "}
                  <Badge
                    className={`font-size-12 ${
                      item?.listing_status === "retired" ? "badge-warning" : "badge-soft-warning"
                    }`}
                    pill
                  >
                    {toPascalCase(item?.listing_status?.includes("retired") ? "Retired/Deleted" : item?.listing_status)}
                  </Badge>
                </td>
                <td>{(item?.retired_at && moment(item?.retired_at).format(DATE_TIME)) || ""}</td>
                <td>
                  {toPascalCase(users?.data?.find((x) => x.id === item.retired_by)?.username) ||
                    (+item.retired_by === -1 ? "System" : "N/A")}
                </td>
                <RenderIf isTrue={item?.listing_status === "retired" && isActiveAccount(storeFront)}>
                  <td>
                    <ConfirmDialogIcon
                      icon={{
                        title: "Delete Listing",
                        className: "bx bx-sm text-danger bx-trash",
                      }}
                      msg={<p>To Delete this Listing. This action cannot be undone</p>}
                      onConfirm={() => handleDeleteListing(item, true)}
                    />
                    <ConfirmDialogIcon
                      icon={{
                        title: "Relist Listing",
                        className: "icon-inventory bx-sm",
                      }}
                      msg={<p>To Relist this Listing. This action cannot be undone</p>}
                      onConfirm={() => handleDeleteListing(item, false)}
                    />
                  </td>
                </RenderIf>
              </tr>
            ))}
          </>
        }
      />
    </>
  );
};

export default RetiredListing;
