import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Pagination, PaginationItem, PaginationLink, FormGroup } from "reactstrap";
import Select from "react-select";
import { animateScroll as scroll } from "react-scroll";

const CustomPagination = (props) => {
  const queryParameters = queryString.parse(props.location.search);
  const [recordsStart, setRecordsStart] = useState(1);
  const pageOpts = props.pageOptions?.length ? props.pageOptions : [100, 250, 500, 1000];
  const [recordsEnd, setRecordsEnd] = useState(
    parseInt(props.perPage) > parseInt(props.total) ? parseInt(props.total) : parseInt(props.perPage),
  );

  if (props.matching) {
    window.onpopstate = () => {
      props.setTabFilter({
        ...props.tabsFilter,
        page_number: queryParameters.page,
      });
    };
  } else {
    window.onpopstate = () => {
      props.setTabFilter({
        ...props.tabsFilter,
        page: queryParameters.page,
      });
    };
  }

  const totalPages = Math.ceil(parseInt(props.total) / parseInt(props.perPage));

  useEffect(() => {
    if (parseInt(props.page) === 1) {
      setRecordsEnd(parseInt(props.perPage) > parseInt(props.total) ? parseInt(props.total) : parseInt(props.perPage));
      setRecordsStart(1);
    }
  }, [props.page, props.perPage, props.total]);

  useEffect(() => {
    !props.inAgencyComponent && scroll.scrollToTop();
  }, [props.page]);

  const handlePageChange = (newPage) => {
    if (props.page < totalPages && newPage > props.page) {
      setRecordsStart(parseInt(props.perPage) * (parseInt(newPage) - 1) + 1);

      const upperLimit = parseInt(props.perPage) * (parseInt(newPage) - 1) + parseInt(props.perPage);

      setRecordsEnd(
        upperLimit > parseInt(props.total) ? upperLimit - (upperLimit - parseInt(props.total)) : upperLimit,
      );

      props.setTabFilter({
        ...props.tabsFilter,
        page: newPage,
        per_page: props.perPage,
        page_number: newPage,
      });
      props.history.push(`?page=${newPage}&per_page=${props.perPage}`);
    } else if (props.page > 1 && newPage < props.page) {
      setRecordsStart(parseInt(props.perPage) * (parseInt(newPage) - 1) + 1);

      const upperLimit = parseInt(props.perPage) * (parseInt(newPage) - 1) + parseInt(props.perPage);

      setRecordsEnd(
        upperLimit > parseInt(props.total) ? upperLimit - (upperLimit - parseInt(props.total)) : upperLimit,
      );

      props.setTabFilter({
        ...props.tabsFilter,
        page: newPage,
        per_page: props.perPage,
        page_number: newPage,
      });
      props.history.push(`?page=${newPage}&per_page=${props.perPage}`);
    }
  };

  function showCount (total) {
    try {
      return total.toLocaleString();
    } catch (error) {
      return total;
    }
  }

  return (
    <div className="d-flex justify-content-between m-2 flex-wrap">
      {props.total
        ? (
        <>
          <div className="d-flex flex-column flex-wrap">
            <h6>{`Total Records: ${showCount(props.total)}`}</h6>
            <h6>{`Showing From ${recordsStart} to ${recordsEnd}`}</h6>
          </div>
          <div className="d-flex flex-row">
            {props.hidePerPage
              ? null
              : (
              <div className="form-group mb-0 d-flex mr-3">
                <FormGroup className="select2-container min-width-150">
                  <Select
                    name="page_options"
                    menuPlacement={props.menuPlacement || "bottom"}
                    value={{ value: props.perPage, label: props.perPage }}
                    onChange={(e) => {
                      props.setTabFilter({
                        ...props.tabsFilter,
                        page: 1,
                        ...(props?.tabsFilter?.page_number && { page_number: 1 }),
                        per_page: parseInt(e.value),
                      });
                      if (props.repricer) props.history.push(`?page=${props.page}&per_page=${parseInt(e.value)}`);
                      else props.history.replace(props.history.location.pathname);
                    }}
                    options={pageOpts.reduce(
                      (init, opt, i) => {
                        opt !== props.perPage &&
                          init.push({
                            label: opt,
                            value: opt,
                            key: "_page_opts_" + i,
                          });
                        return init;
                      },
                      [{ value: props.perPage, label: props.perPage }],
                    )}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </div>
                )}
            {props.hidePagination ? null : (
              <Pagination className="pagination pagination-table justify-content-end mb-2">
              <PaginationItem disabled={parseInt(props.page) === 1}>
                <PaginationLink title="First Page" first onClick={() => handlePageChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={parseInt(props.page) === 1}>
                <PaginationLink previous onClick={() => handlePageChange(props.page - 1)} />
              </PaginationItem>
              {Array.from(Array(2).keys())
                .reverse()
                .map(
                  (item, key) =>
                    key + props.page > 2 && (
                      <PaginationItem key={"_pagination_id_" + key}>
                        <PaginationLink onClick={() => handlePageChange(props.page - item - 1)}>
                          {props.page - item - 1}
                        </PaginationLink>
                      </PaginationItem>
                    ),
                )}
              <PaginationItem active>
                <PaginationLink>{props.page}</PaginationLink>
              </PaginationItem>
              {Array.from(Array(2).keys()).map(
                (item, key) =>
                  key + props.page < totalPages && (
                    <PaginationItem key={"_pagination_id_" + key}>
                      <PaginationLink onClick={() => handlePageChange(props.page + item + 1)}>
                        {props.page + item + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ),
              )}
              <PaginationItem disabled={parseInt(props.page) === totalPages}>
                <PaginationLink next onClick={() => handlePageChange(props.page + 1)} />
              </PaginationItem>
              <PaginationItem disabled={parseInt(props.page) === totalPages}>
                <PaginationLink title="Last Page" last onClick={() => handlePageChange(totalPages)} />
              </PaginationItem>
            </Pagination>
            )}
          </div>
        </>
          )
        : null}
    </div>
  );
};

export default withRouter(CustomPagination);
