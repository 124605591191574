import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderIf } from "../../utils/common";
import { Alert, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { drop } from "lodash";
import CustomTooltip from "../../components/Common/CustomTooltip";
import { getPaymentSources } from "../../store/actions";

const AllTimeAlerts = () => {
  const dispatch = useDispatch();
  const [vacationModeAccount, setVacationModeAccount] = useState({});
  const [vacationModeAccountLength, setVacationModeAccountLength] = useState(0);
  const { accounts, success } = useSelector((state) => state.MarketPlaceAccount);
  const { sources, loading } = useSelector((state) => state.Stripe);

  useEffect(() => {
    if (accounts?.data) {
      const bufferAccountData = countItems(accounts?.data);
      setVacationModeAccount(bufferAccountData);
      setVacationModeAccountLength(bufferAccountData?.vacationMode?.length);
    }
  }, [accounts?.data, success]);

  useEffect(() => {
    if (!sources && !loading) dispatch(getPaymentSources());
  }, [sources]);

  const customBadgeStyle = {
    backgroundColor: "#7f3737",
    color: "#ffffff",
  };
  const showAccountsLength = 2;
  const countItems = (data) => {
    const isAllOnVacationMode = data?.length > 2 && data?.every((item) => item.vacation_mode);
    const vacationMode = data?.filter((item) => item.vacation_mode);
    const accountName = isAllOnVacationMode
      ? "All "
      : vacationMode
        ?.slice(0, showAccountsLength)
        ?.map((obj) => `${obj.name} on ${obj.marketplace}`)
        .join(", ");
    return { accountName, vacationMode };
  };

  const isNotAllAccountsOnVacation = (accountNameString) => accountNameString !== "All ";
  const checkDefaultCardFailureMsg = () => sources?.find((card) => card.failure_message && card.default_source)?.failure_message || "";

  return (
    <>
      <RenderIf isTrue={vacationModeAccountLength}>
        <Alert color="danger" className="d-flex align-items-center">
          <i className="bx bx-info-circle pr-2"></i>
          <span className="mb-0">
            {isNotAllAccountsOnVacation(vacationModeAccount?.accountName) && vacationModeAccount?.accountName}
            <RenderIf isTrue={vacationModeAccountLength > showAccountsLength}>
              {isNotAllAccountsOnVacation(vacationModeAccount?.accountName) && " and "}
              <Badge id="_all_stores" style={customBadgeStyle} className="p-1 mx-1 cursor-pointer alert-custom-badge-m">
                {isNotAllAccountsOnVacation(vacationModeAccount?.accountName)
                  ? `${vacationModeAccountLength - showAccountsLength} more`
                  : "All Accounts"}
              </Badge>
              <CustomTooltip
                placement="top"
                target={"_all_stores"}
                content={drop(
                  vacationModeAccount?.vacationMode?.map((obj) => `${obj.name} on ${obj.marketplace}`),
                  showAccountsLength,
                ).join(", ")}
              />
            </RenderIf>
            {vacationModeAccountLength > 1 ? " are " : " is "} currently set to vacation mode through Ecom Circles. All
            stock is being set to 0.
            <Link to="/settings" className="ml-1">
              Change this here
            </Link>
          </span>
        </Alert>
      </RenderIf>
      <RenderIf isTrue={checkDefaultCardFailureMsg()}>
        <Alert color="danger" className="d-flex align-items-center">
          <i className="bx bx-info-circle pr-2"></i>
          <span className="mb-0">
            We were unable to charge your card for your subscription, so your subscription is paused. Please
            <Link to="/payment_methods" className="ml-1">
              click here
            </Link>{" "}
            to resolve the payment issue.
          </span>
        </Alert>
      </RenderIf>
    </>
  );
};

export default AllTimeAlerts;
