import request from "../restClient";
import { makeQP } from "../../utils/common";

const resourceWareHouse = "/warehouses";
const resourceItems = "/tracking_items";
const charges = "/charge_histories";
const easyPost = "/easy_post";
const purchaseOrders = "/purchase_orders";
const inbounds = "/inbounds";
const inventory = "/inventory";
const outbound = "/outbound";
const reports = "/reports";

export const getAllWarehouses = async (params) => {
  const url = `${resourceWareHouse}` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const updateReturnsOutbounds = async ({ id, data }) => {
  const url = `${outbound}/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const getAllWarehouseItems = async (params) => {
  const url = resourceItems + makeQP(params);
  return await request({ url, method: "GET" });
};

export const fetchWarehouseAccounts = async (_) => {
  return await request({ url: "/account_warehouses/warehouse_tenants", method: "GET" });
};

export const deleteWarehouseUser = async (id) => {
  return await request({ url: `/warehouse_users/${id}`, method: "DELETE" });
};

export const createWareHouse = async (data, params) => {
  return await request({ url: resourceWareHouse + makeQP(params), method: "POST", body: data });
};

export const assignUserWarehouse = async (data) => {
  return await request({ url: "/account_warehouses/add_user", method: "POST", body: data });
};

export const addWarehouseNote = async ({ body, id }) => {
  return await request({ url: `${resourceWareHouse}/${id}/add_notice`, method: "POST", body });
};

export const resolveStrandedItem = ({ id, data }) =>
  request({ url: `${resourceItems}/${id}/add_inventory`, method: "POST", body: data });

export const fetchInventoryItems = (params) =>
  request({ url: `${resourceItems}/fetch_inventory_items` + makeQP(params), method: "GET" });

export const fetchWHChargeHistories = (params) => request({ url: charges + makeQP(params), method: "GET" });

export const updateWHChargeHistory = ({ id, body }) => request({ url: `${charges}/${id}`, method: "PUT", body });

export const updateWarehouseItem = async ({ data, id }) => {
  return await request({ url: `${resourceItems}/${id}`, method: "PUT", body: data });
};

export const markNotReceived = (id) => request({ url: `${resourceItems}/${id}/mark_not_received`, method: "PUT" });

export const updateInventoryItemStatus = async ({ data, id }) =>
  request({ url: `${resourceItems}/inventory/update/${id}`, method: "PUT", body: data });

export const uploadReturnLabelFile = async ({ data, id, params }) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return await request({
    url: `${resourceItems}/inventory/upload_return_label_file/${id}${makeQP(params)}`,
    method: "POST",
    formData,
  });
};

export const downloadReturnLabel = ({ id, params }) =>
  request({
    url: `${resourceItems}/inventory/download_return_label/${id}` + makeQP(params),
    method: "GET",
  });

export const deleteReturnLabel = async ({ id, params }) =>
  request({ url: `${resourceItems}/inventory/delete_return_label/${id}` + makeQP(params), method: "DELETE" });

export const updateBulkWarehouseItems = ({ ids, data }) =>
  request({ url: `${resourceItems}/bulkUpdate`, method: "PUT", body: { ids, data } });

export const updateWarehouse = async ({ data, id }) => {
  return await request({ url: `${resourceWareHouse}/${id}`, method: "PUT", body: data });
};

export const assignAgencyToWarehouse = async ({ wh_id: whId, agency_id: data }) => {
  return await request({ url: `${resourceWareHouse}/${whId}`, method: "POST", body: { agency_id: data[0] } });
};

export const deleteWHAccount = async ({ wh_id: whId, email }) => {
  return await request({ url: `${resourceWareHouse}/delete_account/${whId}`, method: "DELETE", body: { email } });
};

export const updateWHAccount = (id, body) => {
  return request({ url: `${resourceWareHouse}/update_account/${id}`, method: "PUT", body });
};

export const deleteWarehouse = (id) => {
  return request({ url: `${resourceWareHouse}/${id}`, method: "DELETE" });
};

export const fetchUserWarehouses = (params) =>
  request({ url: `${resourceItems}/fetch_user_warehouses` + makeQP(params), method: "GET" });

export const downloadChargesReport = async ({ params }) =>
  request({ url: `${charges}/download_report` + makeQP(params), method: "GET", download: true });

export const downloadInventoryReport = ({ params }) =>
  request({
    url: `${resourceItems}/inventory/download_report` + makeQP(params),
    download: true,
  });

export const downloadWarehouseItemsReport = (params) =>
  request({
    url: `${resourceItems}/wh_items/download_report` + makeQP(params),
    download: true,
  });

export const addInventoryItem = (body) => request({ url: `${resourceItems}/inventory/add_item`, method: "POST", body });

export const fetchAllShippingRatesAPI = ({ data }) =>
  request({ url: `${easyPost}/get_all_shipping_rates`, method: "POST", body: data });

export const generateLabel = ({ data, id }) =>
  request({ url: `${easyPost}/buy_shipment/${id}`, method: "POST", body: data });

export const fetchWHStatusStats = (params) => request({ url: `${charges}/stats` + makeQP(params), method: "GET" });

export const fetchInvoiceDetail = (param) => request({ url: `${charges}/storage_invoice/${param}`, method: "GET" });

export const fetchAllShipmentsAPI = () => request({ url: `${easyPost}/get_all_shipments`, method: "GET" });

export const fetchAllOutboundShipmentsAPI = (params) =>
  request({ url: `${easyPost}/all_outbound_shipments` + makeQP({ outbound_ids: params }), method: "GET" });

export const refundLabelAPI = ({ id, params }) =>
  request({ url: `${easyPost}/refund_shipment/${id}`, body: params, method: "POST" });

export const downloadShippingLabel = async ({ id, params }) =>
  request({
    url: `${easyPost}/download_shipping_label/${id}` + makeQP(params),
    method: "GET",
    ...(!params.is_preview ? { download: true } : {}),
  });

export const updateInventoryItemBins = ({ data, id }) =>
  request({ url: `${resourceItems}/inventory/${id}/bins`, method: "PUT", body: data });

export const deleteInventoryItemBin = ({ params, id }) =>
  request({ url: `${resourceItems}/inventory/${id}/bins` + makeQP(params), method: "DELETE" });
export const fetchPurchaseOrderAPI = (params) =>
  request({ url: `${purchaseOrders}/get_all_po` + makeQP(params), method: "GET" });

export const fetchPurchaseOrderMetadataAPI = (params) => request({ url: `${purchaseOrders}/${params}`, method: "GET" });

export const fetchPurchaseOrderLogsAPI = (params) =>
  request({ url: `${purchaseOrders}/get_po_logs/${params}`, method: "GET" });

export const fetchPurchaseOrderStatsAPI = () => request({ url: `${purchaseOrders}/get_po_stats`, method: "GET" });

export const fetchPurchaseOrderSuppliersAPI = () =>
  request({ url: `${purchaseOrders}/get_po_suppliers`, method: "GET" });

export const createPurchaseOrderAPI = (id, data) =>
  request({ url: `${purchaseOrders}/${id}`, method: "POST", body: data });

export const createLineItemAPI = (data) =>
  request({ url: `${purchaseOrders}/create_line_items`, method: "POST", body: data });
export const createPOShipmentAPI = (data) =>
  request({ url: `${purchaseOrders}/create_shipment_lines`, method: "POST", body: data });

export const deletePurchaseOrderAPI = (id) => request({ url: `${purchaseOrders}/${id}`, method: "DELETE" });

export const updatePurchaseOrderAPI = ({ data, id }) =>
  request({ url: `${purchaseOrders}/${id}`, method: "PUT", body: data });

export const updatePurchaseOrderItemAPI = ({ dataForAPI, id }) =>
  request({ url: `${purchaseOrders}/update_item/${id}`, method: "PUT", body: dataForAPI });

export const updatePurchaseOrderShipmentAPI = ({ updateShipmentObj, id }) =>
  request({ url: `${purchaseOrders}/update_shipment/${id}`, method: "PUT", body: updateShipmentObj });

export const deletePurchaseOrderItemAPI = (itemInfo) =>
  request({
    url: `${purchaseOrders}/item/${itemInfo?.ItemId}`,
    method: "DELETE",
    body: { po_id: itemInfo?.POid, wh_order_id: itemInfo?.WhOrderId },
  });
export const deletePurchaseOrderShipmentAPI = (shipmentInfo) =>
  request({
    url: `${purchaseOrders}/shipment/${shipmentInfo?.shipmentId}`,
    method: "DELETE",
    body: { po_id: shipmentInfo?.POid },
  });
export const updateInventoryItemsApi = ({ data, ids }) =>
  request({ url: `${resourceItems}/inventory/bulk_update`, method: "PUT", body: { ids, data } });
export const downloadWHLabelReport = (params) =>
  request({ url: `${reports}/wh_label_report` + makeQP(params), method: "GET" });

// Warehouse Dashboard
export const updateTrackingItemShippingStatusApi = ({ id }) =>
  request({ url: `${resourceItems}/${id}/force_upload_tracking`, method: "PUT" });

// Inbounds
export const fetchInboundsApi = (params) => request({ url: `${inbounds}/${makeQP(params)}` });
export const addShipmentApi = (data) => request({ url: `${inbounds}/create`, method: "POST", body: data });
export const shipmentsCheckedInApi = (data) => request({ url: `${inbounds}/checked-in`, method: "POST", body: data });
export const receiveShipmentApi = (body) => request({ url: `${inbounds}/receive_shipment`, method: "POST", body });
export const fetchInboundsStatsApi = () => request({ url: `${inbounds}/stats` });
export const fetchInboundsIsLiquidated = (params) => request({ url: `${inbounds}/is_liquidated${makeQP(params)}` });

// Warehouse Invetory items
export const fetchWHInventoryApi = (params) => request({ url: `${inventory}/get_all_inventory/${makeQP(params)}` });
export const fetchInventoryShipmentWiseApi = (params) => request({ url: `${inventory}/inventory_shipment_wise/${makeQP(params)}` });
export const fetchWHInventoryStatsApi = () => request({ url: `${inventory}/get_inventory_stats` });
export const fetchStrandedItemsCount = () => request({ url: `${inventory}/stranded_count` });
export const fetchWHInventoryActionApi = async ({ itemID, data }) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, key === "file" ? data[key].file_name : data[key]));
  return await request({
    url: `${inventory}/${itemID}`,
    method: "POST",
    formData,
  });
};
export const updateItemOversizedApi = ({ id, oversized }) =>
  request({ url: `${inventory}/oversized/${id}`, method: "PUT", body: { oversized } });
export const updateInventoryItemPriceAPI = ({ id, body }) =>
  request({ url: `${inventory}/price/${id}`, method: "PUT", body });
export const deleteInventoryBinApi = (params) => request({ url: `${inventory}/bin/${makeQP(params)}`, method: "DELETE" });
export const updateInventoryBinApi = ({ id, body }) => request({ url: `${inventory}/bin/${id}`, method: "PUT", body });
export const createInventoryBinApi = (body) => request({ url: `${inventory}/bin`, method: "POST", body });
export const deleteInventoryItem = (body) => request({ url: `${inventory}/delete`, method: "DELETE", body });

// Warehouse Outbound items
export const fetchWHOutboundApi = (params) => request({ url: `${outbound}/${makeQP(params)}` });
export const fetchWhOutboundStatsApi = (params) => request({ url: `${outbound}/outbound_stats/${makeQP(params)}` });
export const markAsCompleteItemAPI = (itemId) =>
  request({ url: `${outbound}/mark_complete/${itemId}`, method: "POST" });
export const bulkMarkAsCompleteAPI = ({ ids }) => request({ url: `${outbound}/bulk_mark_complete`, method: "PUT", body: { ids } });
export const deleteOutboundItemApi = ({ id }) =>
  request({ url: `${outbound}/outbound_item/${id}`, method: "DELETE" });

export const downloadOutboundLabel = async ({ id, params }) =>
  request({
    url: `${outbound}/download_outbound_label/${id}` + makeQP(params),
    method: "GET",
  });

// Label Generation Stats
export const fetchLabelGenerationStats = (params) => request({ url: `${easyPost}/per_user_labels${makeQP(params)}` });

// Scanforms
export const fetchShippingScanforms = (params) => request({ url: `${easyPost}/scanforms${makeQP(params)}` });
export const generateScanforms = (body) => request({ url: `${easyPost}/scanforms`, method: "POST", body });
export const downloadFormFile = (param) =>
  request({ url: `${easyPost}/scanforms/download_file${makeQP(param)}` });
export const fetchScanformShipmentsAPI = (params) =>
  request({ url: `${easyPost}/scanform_shipments${makeQP(params)}` });

export const updateShipmentQty = (shipmentId, qty) =>
  request({ url: `${inventory}/qty/${shipmentId}`, method: "PUT", body: { qty } });
