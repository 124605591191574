import React from "react";
import { Alert } from "reactstrap";
import { RenderIf, twoStepWarningFilters } from "../../utils/common";

const SetupWhBanner = ({ associatedWarehouses, stores }) =>
  ["two_step_wh_id", "return_wh_id"].map((key) => (
    <RenderIf isTrue={twoStepWarningFilters(key, associatedWarehouses, stores)?.length} key={`__warning__${key}`}>
      <div>
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2"></i>
          {`Please setup ${key === "two_step_wh_id" ? "two-step" : "return"} warehouse for ${twoStepWarningFilters(
            key,
            associatedWarehouses,
            stores,
          )
            ?.map((x) => x.name)
            ?.join(", ")}`}
        </Alert>
      </div>
    </RenderIf>
  ));

export default SetupWhBanner;
