import {
  FETCH_RETURNS,
  FETCH_RETURNS_SUCCESS,
  UPDATE_RETURN,
  UPDATE_RETURN_SUCCESS,
  API_ERROR,
  SET_SELECTED_RETURN_ITEM,
  MOVE_TO_INVENTORY,
  MOVE_TO_INVENTORY_SUCCESS,
  SET_UPLOAD_IMAGES_MODAL,
  UPLOAD_ISSUE_IMAGES,
  UPLOAD_ISSUE_IMAGES_SUCCESS,
  DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  GET_IMAGES_URL,
  GET_IMAGES_URL_SUCCESS,
  SET_FILTER_BAR,
  UPDATE_BULK_RETURNS,
  UPDATE_BULK_RETURNS_SUCCESS,
  SET_ADD_RETURN_MODAL,
  ADD_ITEM_TO_INVENTORY,
  ADD_ITEM_TO_INVENTORY_SUCCESS,
  RESET_SUCCESS_ERROR_STATE,
} from "./actionTypes";

export const fetchReturns = (params) => ({ type: FETCH_RETURNS, payload: { params } });
export const fetchReturnsSuccess = (returns) => ({ type: FETCH_RETURNS_SUCCESS, payload: returns });
export const updateReturn = (id, data, params) => ({ type: UPDATE_RETURN, payload: { id, data, params } });
export const updateReturnSuccess = (res) => ({ type: UPDATE_RETURN_SUCCESS, payload: res });
export const setSelectedReturnItem = (data) => ({ type: SET_SELECTED_RETURN_ITEM, payload: data });
export const setUploadImagesModal = (bool) => ({ type: SET_UPLOAD_IMAGES_MODAL, payload: bool });
export const setFilterSidebar = (bool) => ({ type: SET_FILTER_BAR, payload: bool });
export const moveToInventory = (data) => ({ type: MOVE_TO_INVENTORY, payload: data });
export const moveToInventorySuccess = (data) => ({ type: MOVE_TO_INVENTORY_SUCCESS, payload: data });
export const uploadIssueImages = ({ data, id }) => ({ type: UPLOAD_ISSUE_IMAGES, payload: { data, id } });
export const uploadIssueImagesSuccess = (message) => ({ type: UPLOAD_ISSUE_IMAGES_SUCCESS, payload: message });
export const getImagesUrl = (id) => ({ type: GET_IMAGES_URL, payload: { id } });
export const getImagesUrlSuccess = (data) => ({ type: GET_IMAGES_URL_SUCCESS, payload: data });
export const deleteImage = (id, params) => ({ type: DELETE_IMAGE, payload: { params, id } });
export const deleteImageSuccess = (message) => ({ type: DELETE_IMAGE_SUCCESS, payload: message });
export const updateBulkReturns = (ids, data, params) => ({ type: UPDATE_BULK_RETURNS, payload: { ids, data, params } });
export const updateBulkReturnsSuccess = (res) => ({ type: UPDATE_BULK_RETURNS_SUCCESS, payload: res });
export const setAddReturnModal = (bool) => ({ type: SET_ADD_RETURN_MODAL, payload: bool });
export const addItemToInventory = (data) => ({ type: ADD_ITEM_TO_INVENTORY, payload: data });
export const addItemToInventorySuccess = (data) => ({ type: ADD_ITEM_TO_INVENTORY_SUCCESS, payload: data });
export const resetErrorSuccessStates = () => ({ type: RESET_SUCCESS_ERROR_STATE });
export const apiError = (error) => ({ type: API_ERROR, payload: error });
