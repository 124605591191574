import { makeQP } from "../../utils/common";
import request from "../restClient";

const reportsUrl = "/reports";
const reportV2Url = "/reports/v2/";

export const fetchReports = ({ params }) => request({ url: `${reportsUrl}/` + makeQP(params) });

export const downloadReport = ({ id, qP }) =>
  request({ url: `${reportsUrl}/download_report/${id}` + makeQP(qP) });

export const genOrderDetailsReport = ({ storeOrderId, query }) =>
  request({
    url: `${reportV2Url}order_details/${storeOrderId}` + makeQP(query),
    method: "GET",
    download: !!query?.is_download,
  });

export const genTransactionReport = ({ query }) =>
  request({ url: reportV2Url + "transactional" + makeQP(query), method: "GET", download: !!query?.is_download });

export const genSettlementReport = ({ query }) =>
  request({ url: reportV2Url + "settlement" + makeQP(query), method: "GET", download: !!query?.is_download });

export const genOrdersReport = ({ query }) =>
  request({ url: reportV2Url + "orders" + makeQP(query), method: "GET", download: !!query?.is_download });

export const genInventoryReport = ({ query }) =>
  request({ url: reportV2Url + "inventory" + makeQP(query), method: "GET", download: !!query?.is_download });

export const genInventoryInsight = ({ query }) =>
  request({ url: reportV2Url + "inventory_insights" + makeQP(query), method: "GET", download: !!query?.is_download });

export const genReturnsReport = ({ query }) =>
  request({ url: reportV2Url + "returns" + makeQP(query), method: "GET", download: !!query?.is_download });

export const genWhLabelReport = ({ query }) =>
  request({ url: reportV2Url + "warehouse_label" + makeQP(query), method: "GET" });

export const getAvailableSettlementReports = (payload) => {
  const url = `${reportV2Url}settlement_reports/${payload.storeId}` + makeQP({ agencyId: payload.agencyId });
  return request({ url, method: "GET" });
};

export const downloadFile = (param) => request({ url: `${reportsUrl}/download_file${makeQP(param)}`, download: true });
