import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import EcomCircleDark from "../../assets/images/resource/ecom_dark.svg";
import EcomCircleWhite from "../../assets/images/resource/ecom_light.svg";
import Finance from "../../assets/images/resource/finance.svg";
import Community from "../../assets/images/resource/feedback.svg";
import EcomScanner from "../../assets/images/resource/ecom_scanner.svg";
import Repricer from "../../assets/images/resource/ecom_repricer.svg";
import EcomRankerBlack from "../../assets/images/resource/ecom_ranker_black.svg";
import EcomRankerWhite from "../../assets/images/resource/ecom_repricer_dark.svg";
import WfsCalculator from "../../assets/images/resource/wfs_calculator.svg";
import WfsCalculatorDark from "../../assets/images/resource/wfs_calculator_dark.svg";
import WmSeller from "../../assets/images/resource/wm_seller_tools.svg";
import TaxJarBlack from "../../assets/images/resource/tax_jar_black.svg";
import TaxJar from "../../assets/images/resource/tax_jar.svg";
import QuickBook from "../../assets/images/resource/quick_books.svg";
import Teamwork from "../../assets/images/resource/team_work.svg";
import TeamworkLight from "../../assets/images/resource/team_work_light.svg";
import { HELP_DOCS_LINK } from "../../constants";
import { RenderIf } from "../../utils/common";

const Resources = () => {
  const isDark = localStorage.getItem("THEME") === "dark";
  const community = [
    {
      image: isDark ? EcomCircleDark : EcomCircleWhite,
      title: "Ecom Circles Knowledge Base",
      description:
        "Check out our knowledge base with over 200 articles and walk-throughs to help you set up and scale your business!",
      linkText: "Ecom Circles Knowledge Base",
      href: HELP_DOCS_LINK,
    },
    {
      image: Community,
      title: "Community",
      description:
        "Join our Facebook community of sellers and vendors who work together to grow large businesses across multiple platforms!",
      linkText: "Join Facebook Group",
      href: "https://www.facebook.com/groups/ecomcircles",
    },
    {
      image: Finance,
      title: "Financing",
      description:
        "Need to scale up? We recommend the American Express Plum card which gives you 60 days to pay or get 1.5% cash back. Click to learn more!",
      linkText: "American Express Plum",
      href: "http://refer.amex.us/MATTHH4Flj?xl=cpys",
    },

    {
      image: EcomScanner,
      title: "Ecom Scanner",
      description:
        "Bulk-scan product lists to quickly determine profitability. Works for Walmart and Amazon both and includes calculations for WFS and FBA, respectively.",
      href: "https://scanner.ecomcircles.com/",
    },
    {
      image: Repricer,
      title: "Ecom Repricer",
      description: "Reprice items effortlessly with our robust repricer. Works for Amazon and Walmart both!",
      href: "https://repricer.ecomcircles.com/",
    },
    {
      image: isDark ? EcomRankerWhite : EcomRankerBlack,
      title: "Ecom Ranker",
      description: "Track the rank of your items on Walmart to see how you rank based on keywords and categories",
      href: null,
    },
    {
      image: isDark ? WfsCalculatorDark : WfsCalculator,
      title: "WFS Calculator",
      description: "Easily calculate WFS fees right from the Walmart product page with this free chrome extension",
      href: "https://chrome.google.com/webstore/detail/walmart-wfs-profit-calcul/boecmgggeigllcdocgioijmleimjbfkg",
    },
    {
      image: WmSeller,
      title: "WM Seller Tools ",
      description: "Get historical price, stock data and other insights with this chrome extension for Walmart sellers",
      href: "https://www.wmsellertools.com/",
    },
    {
      image: isDark ? TaxJar : TaxJarBlack,
      title: "TaxJar",
      description:
        "TaxJar is reimagining how businesses manage sales tax compliance. Our cloud-based platform automates the entire sales tax life cycle across all of your sales channel",
      href: "https://taxjar.grsm.io/matthall5185",
    },
    {
      image: QuickBook,
      title: "Quickbooks",
      description: "Track expenses, customize invoices, run reports and even more all from one place.",
      href: "https://quickbooks.grsm.io/matthall5185",
    },
    {
      image: isDark ? Teamwork : TeamworkLight,
      title: "Teamwork",
      description:
        "Teamwork Desk is a great tool for handling your customer support. We've used it for years and we love it!",
      href: "https://www.teamwork.com/partner/g3sudg2zxx",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content resources">
        <h5>Resources</h5>
        <Row>
          {community.slice(0, 3).map((resources, key) => {
            return (
              <Col key={`__resources__${key}`} lg={4} md={4} sm={12}>
                <div className="community-box">
                  <img src={resources.image} className="w-100" alt="no-resources" />
                  <div className="content-box">
                    <span>{resources.title}</span>
                    <p>{resources.description}</p>
                    <Link to={{ pathname: resources.href }} target="_blank">
                      {resources.linkText}
                    </Link>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        <div className="services_tool">
          <div className="mb-20">
            <h6 className="mb-10">Other Tool and Services</h6>
          </div>
          <Row>
            {community.slice(3, 11).map((resources, key) => {
              return (
                <Col key={`__resources__community__${key}`} lg={4} md={4} sm={12}>
                  <div className="mb-40 d-flex align-items-start grid-column-gap-10">
                    <div className="image-box">
                      <img src={resources.image} alt="no-resources" />
                    </div>
                    <div className="services_description">
                      <div className="d-flex justify-content-between">
                        <span>{resources.title}</span>
                        <RenderIf
                          isTrue={resources.href}
                          fallback={<span className="text-danger font-size-10">Coming Soon</span>}
                        >
                          <Link
                            to={{ pathname: resources.href }}
                            target="_blank"
                            className="bx bx-right-arrow-alt arrow_icon"
                          />
                        </RenderIf>
                      </div>
                      <p>{resources.description}</p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Resources;
