import React from "react";

const Text = ({ heading, data, divClass = "d-flex" }) =>
  data
    ? (
    <span className={divClass}>
      <span>{heading}&nbsp;</span>
      <span className="inventory-color pr-1">{data}</span>
    </span>
      )
    : null;

export default Text;
