import React, { useState } from "react";
import { Button, FormGroup, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import { numericOptions, strOptions, numericValidation, requiredValidation } from "../common";
import PopOver from "../../../components/Common/PopOver";

const ColumnLevelFilter = (props) => {
  const { isOpen, toggle, filterTitle, onApply, targetId, header } = props;
  const { name, title, isNumeric = true } = header;

  const handleApply = (e, v) => {
    onApply(name, selectedFilter?.operator, v.filterValue, title, selectedFilter?.label);
    toggle(targetId);
  };

  const options = isNumeric ? numericOptions : strOptions;
  const validationRules = isNumeric ? numericValidation : requiredValidation;
  const [selectedFilter, setSelectedFilter] = useState(options[0]);

  return (
    <>
      <PopOver
        placement="bottom"
        target={targetId}
        trigger="legacy"
        isOpen={isOpen}
        toggle={() => toggle(targetId)}
        style={{ width: "280px" }}
      >
        <AvForm
          className="form-horizontal p-3 modal-content border"
          onValidSubmit={(e, v) => {
            handleApply(e, v);
          }}
        >
          <div className="">
            <h5 className="">{filterTitle}</h5>
          </div>
          <div className="">
            <FormGroup className="select2-container">
              <Label for="filterSelect">Select Filter</Label>
              <Select
                name={`${targetId}-select`}
                value={selectedFilter}
                options={options}
                onChange={(e) => {
                  setSelectedFilter(e);
                }}
                classNamePrefix="select2-selection"
              />
            </FormGroup>
            <FormGroup>
              <AvField
                name="filterValue"
                label="Value"
                value=""
                className="form-control"
                placeholder="Value"
                validate={validationRules}
              />
            </FormGroup>
          </div>
          <Row className="align-items-center d-flex justify-content-end">
            <Button
              onClick={() => toggle(targetId)}
              style={{ fontSize: "12px", fontColor: "#9299B8" }}
              className="py-1"
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" style={{ fontSize: "12px" }} className="py-1 mx-2">
              Apply
            </Button>
          </Row>
        </AvForm>
      </PopOver>
    </>
  );
};

export default ColumnLevelFilter;
