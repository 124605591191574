import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, Input } from "reactstrap";
import Modal from "../../../components/Common/Modal";

import { useFormik } from "formik";
import { BULK_LISTINGS_VERIFIED_MATCH_STATUSES, MARKET_PLACES, LISTING_TYPES } from "../../../constants/index";
import { getObjectforBulk } from "../Helpers/common";
import { toPascalCase } from "../../../utils/common";
import { omit } from "lodash";
// actions
import {
  bulkUpdateListings,
  setListingsBulkUpdateModal,
  fetchShippingTemplate,
  assignShippingTemplate,
  setShippingTemplateUpdateModal,
  bulkUpdateHandlingTime,
  setTwoStepUpdateModal,
  setTwoStepModal,
} from "../../../store/actions";

const BulkListingsUpdateModal = (props) => {
  const stateVariable = (props) => {
    if (props.platform === MARKET_PLACES.walmart && props.type === "bulk listing") return "update_verified_status";
    else if (props.type === "2-step") return "update_two_step_template";
    else if (props.type === "template") return "update_shipping_template";
  };
  const [updateType, setUpdateType] = useState(stateVariable(props));
  const validate = (values) => {
    const errors = {};
    switch (updateType) {
      case "update_handling_time":
        values.handling_time === "" && (errors.handling_time = "Handling time cannot be empty");
        break;
      case "update_verified_status":
        !values.status && (errors.status = "Status is requried");
        break;
      case "update_shipping_template":
        !values.template_id && (errors.template_id = "Template is requried");
        break;
      case "update_two_step_template":
        !values.two_step && (errors.two_step = "Two step listing option is required");
        break;
      default:
        break;
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      status: "",
      handling_time: "",
      template_id: "",
      two_step: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      switch (updateType) {
        case "update_handling_time":
          props.bulkUpdateHandlingTime(
            getObjectforBulk(
              props.platform,
              props.Listings.selectedListings,
              ["partner_id", "skus", "handling_time"],
              values.handling_time,
            ),
            false,
          );
          break;
        case "update_verified_status":
          props.bulkUpdateListings(
            getObjectforBulk(
              props.platform,
              props.Listings.selectedListings,
              ["partner_id", "skus", "verified_status"],
              values.status,
            ),
          );
          break;
        case "update_shipping_template": {
          let params = {};
          let data = { select_type: props.selectType };
          if (props.selectType === "current_page") {
            data = {
              ...data,
              select_type: "current_page",
              partner_id: props.storeFront,
              skus: props.Listings.selectedListings.reduce((acc, listing) => {
                acc.push(listing.sku);
                return acc;
              }, []),
              template_id: values.template_id,
            };
          } else {
            params = omit(props.filters, ["column_index", "page_number", "per_page"]);
            data = { ...data, template_id: values.template_id };
          }
          props.assignShippingTemplate(data, params);
          break;
        }
        case "update_two_step_template": {
          const shipWareHouse = values.two_step !== "normal";
          const twoStepData = {
            select_type: props.selectType,
            platform: props.platform,
            partner_id: props.storeFront,
            ship_to_warehouse: shipWareHouse,
          };
          props.setTwoStepUpdateModal(twoStepData);
          break;
        }
        default:
          break;
      }
    },
  });

  const toggleModal = () => {
    if (props.type === "template" || props.type === "2-step")
      props.setShippingTemplateUpdateModal(!props.Listings.shippingTemplateUpdateModalIsOpen);
    else {
      props.setListingsBulkUpdateModal(!props.Listings.listingsBulkUpdateModalIsOpen);
    }
    formik.resetForm();
  };

  const setModalHeaders = (props) => {
    if (props.type === "template") return "Update Template";
    else if (props.type === "2-step") return "Bulk Update Listings";
    else return "Update Listings";
  };

  useEffect(() => {
    if (props.type === "template" && !props.Listings.templates) props.fetchShippingTemplate();
  }, []);

  return (
    <React.Fragment>
      <Modal
        size="sm"
        isOpen={
          props.Listings.listingsBulkUpdateModalIsOpen ||
          props.Listings.shippingTemplateUpdateModalIsOpen ||
          props.Listings.twoStepModalIsOpen
        }
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">{setModalHeaders(props)}</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.Listings.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.Listings.error}
            </Alert>
          )}
          {props.platform === MARKET_PLACES.amazon &&
            props.type !== "2-step" &&
            updateType !== "update_shipping_template" && (
              <Row>
                <Col lg="12">
                  <Label>Select Update Type</Label>
                  <div className="form-group">
                    <select
                      value={updateType}
                      onChange={(e) => setUpdateType(e.target.value)}
                      className="custom-select"
                    >
                      <option value="">Select Status</option>
                      <option value="update_verified_status">Update Verified Status</option>
                      <option value="update_handling_time">Update Handling Time</option>
                    </select>
                  </div>
                </Col>
              </Row>
          )}

          <form onSubmit={formik.handleSubmit}>
            {updateType === "update_verified_status" && (
              <Row>
                <Col lg="12">
                  <Label>Status</Label>
                  <div className="form-group">
                    <select
                      name="status"
                      value={formik.values.status}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className="custom-select"
                    >
                      <option value="">Select Status</option>
                      {Object.keys(BULK_LISTINGS_VERIFIED_MATCH_STATUSES).map((key, index) => (
                        <option
                          key={"_bulk_source_order_status" + index}
                          value={BULK_LISTINGS_VERIFIED_MATCH_STATUSES[key]}
                        >
                          {toPascalCase(key)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {formik.touched.status && formik.errors.status
                    ? (
                    <small className="text-danger">{formik.errors.status}</small>
                      )
                    : null}
                </Col>
              </Row>
            )}
            {updateType === "update_shipping_template" && (
              <Row>
                <Col lg="12">
                  <Label>Template</Label>
                  <div className="form-group">
                    <select
                      name="template_id"
                      value={formik.values.template_id}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className="custom-select"
                    >
                      <option value="">Select Template</option>
                      {props.Listings.templates &&
                        props.Listings?.templates[props.storeFront]?.map((key, index) => (
                          <option key={"_shipping_template_" + index} value={key.id}>
                            {key.name || key.shipping_template_uuid}
                          </option>
                        ))}
                    </select>
                  </div>
                  {formik.touched.template_id && formik.errors.template_id
                    ? (
                    <small className="text-danger">{formik.errors.template_id}</small>
                      )
                    : null}
                </Col>
              </Row>
            )}
            {updateType === "update_handling_time" && (
              <Row>
                <Col lg="12">
                  <Label>Handling Time</Label>
                  <div className="form-group">
                    <Input
                      min="0"
                      type="number"
                      step="1"
                      name="handling_time"
                      value={formik.values.handling_time}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    ></Input>
                  </div>
                  {formik.touched.handling_time && formik.errors.handling_time
                    ? (
                    <small className="text-danger">{formik.errors.handling_time}</small>
                      )
                    : null}
                </Col>
              </Row>
            )}
            {updateType === "update_two_step_template" && (
              <Row>
                <Col lg="12">
                  <Label>2 STEP LISTINGS</Label>
                  <div className="form-group">
                    <select
                      name="two_step"
                      value={formik.values.two_step}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className="custom-select"
                    >
                      <option value="">Select 2 Step Option</option>
                      {Object.keys(LISTING_TYPES).map((key, index) => (
                        <option
                          key={"_listing_type_" + index}
                          label={LISTING_TYPES[key]}
                          value={LISTING_TYPES[key]}
                        ></option>
                      ))}
                      )
                    </select>
                  </div>
                  {formik.touched.two_step && formik.errors.two_step
                    ? (
                    <small className="text-danger">{formik.errors.two_step}</small>
                      )
                    : null}
                </Col>
              </Row>
            )}
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              {updateType && (
                <Button type="submit" color="success" className="mr-3">
                  Submit
                </Button>
              )}
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { Listings } = state;
  return { Listings };
};

export default connect(mapStateToProps, {
  bulkUpdateListings,
  setListingsBulkUpdateModal,
  fetchShippingTemplate,
  assignShippingTemplate,
  setShippingTemplateUpdateModal,
  bulkUpdateHandlingTime,
  setTwoStepUpdateModal,
  setTwoStepModal,
})(BulkListingsUpdateModal);
