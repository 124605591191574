import React from "react";
import { Card, CardBody, Media } from "reactstrap";
import { RenderIf } from "../../../utils/common";

const RepricerListingCards = ({ title, value, icon, onClick }) => {
  return (
    <>
      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 d-flex align-items-stretch">
        <Card className="mini-stats-wid w-100">
          <CardBody className="h-100">
            <Media>
              <Media body className="w-75">
                <p className="d-flex align-items-center text-muted font-size-13 font-weight-medium">
                  {title}
                  <RenderIf isTrue={onClick}>
                    <i
                      className="mx-2 bx font-size-18 bx-right-arrow-circle text-primary cursor-pointer"
                      onClick={onClick}
                    />
                  </RenderIf>
                </p>
                <h4
                  className={`mb-0 font-size-20 font-weight-semibold inventory-color ellipse ${
                    title === "Missing COGS" ? "text-danger" : ""
                  }`}
                  title={value}
                >
                  {value}
                </h4>
              </Media>
              <div className="d-flex flex-column">
                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                  <span className="avatar-title">
                    <i className={"bx " + icon + " font-size-24"}></i>
                  </span>
                </div>
              </div>
            </Media>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default RepricerListingCards;
