import React, { useEffect, useState, useRef, Suspense } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

// actions
import { fetchOrderGraph, fetchAllTenantOrderGraph } from "../../../store/actions";
import { humanize, RenderIf } from "../../../utils/common";
import { DATE_FORMATS } from "../../../constants";

const { DATE, GRAPH_DATE } = DATE_FORMATS;

const DateRange = React.lazy(() => import("../../../components/Common/DateRange"));
const ChartName = React.lazy(() => import("./ChartName"));

const TotalOrderGraph = (props) => {
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const store = props.marketplace;
  const { orderGraph, totalOrdersLoader } = useSelector((state) => state.Graph);

  const currentDate = moment().startOf("day");
  const oneWeekBefore = moment().subtract(6, "days").startOf("day");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);

  const fetchGraphDetails = ({ startDate, endDate }) => {
    const sharedParams = { start_date: startDate, end_date: endDate };
    if (props.type === "global") dispatch(fetchAllTenantOrderGraph({ ...sharedParams, store_name: store }));
    else dispatch(fetchOrderGraph({ ...sharedParams, marketplace_id: accountId, utc_offset: moment().format("Z") }));
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    !totalOrdersLoader &&
      fetchGraphDetails({
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
      });
  }, [dates[0], dates[1], store]);

  const series = [
    {
      name: "Orders",
      data: (orderGraph?.data?.length && orderGraph.data.map((x) => [x?.date, parseFloat(x.orders).toFixed(2)])) || [
        [dates[0]],
        [currentDate],
      ],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No Returns Data for given date range",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#556ee6"],
    xaxis: {
      type: "datetime",
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE) }, // Timestamp is ALWAYS UTC
    },
    yaxis: { labels: { formatter: (x) => humanize(x) } },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: GRAPH_DATE,
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <div className="mb-4 d-flex justify-content-between">
          <Suspense fallback={<></>}>
            <RenderIf isTrue={!totalOrdersLoader} fallback={<h4 className="card-title">Loading...</h4>}>
              <ChartName
                name="Total Orders"
                dates={dates}
                totalOrders={humanize(orderGraph?.data?.reduce((acc, x) => acc + parseFloat(x.orders), 0) || 0)}
              />
            </RenderIf>
            <DateRange dates={dates} setDates={setDates} loader={totalOrdersLoader} timePeriods={[7, 30, 365]} />
          </Suspense>
        </div>
        <div className="clearfix"></div>
        <ReactApexChart options={options} series={series} type="line" height="350" />
      </CardBody>
    </Card>
  );
};

export default TotalOrderGraph;
