import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, FormGroup, Container, Label, Button, Input, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useFormik } from "formik";
import { City, State } from "country-state-city";
import { connect } from "react-redux";
import { updateUser, setChangePasswordModel, updateProfileAndCompanyInfo, fetchCompanyInfo } from "../../store/actions";
import { getUserInfo, isUserHaveFullAccess, RenderIf } from "../../utils/common";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import PillBtn from "../../components/Common/PillBtn";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ChangePasswordModel from "./components/ChangePasswordModel";
import { first, find } from "lodash";

const getState = (props) => {
  const state = State.getStateByCodeAndCountry(props.User.company_info?.state, "US");
  return { label: state?.name || "", value: state?.isoCode || "" };
};
const getCity = (props) => ({ label: props.User.company_info?.city || "", value: props.User.company_info?.city || "" });
function CustomLabel (props) {
  return <Label className="mt-3">{props.children}</Label>;
}
const getFirstCityOfState = (state) => ({
  label: first(City.getCitiesOfState("US", state))?.name,
  value: first(City.getCitiesOfState("US", state))?.name,
});
const getFirstStateOfCountry = (country) => ({
  label: first(State.getStatesOfCountry(country))?.name,
  value: first(State.getStatesOfCountry(country))?.isoCode,
});
const ProfileSettings = (props) => {
  const zipRe = /^[0-9]{5}(?:-[0-9]{4})?$/;
  const [localPhoneNumberError, setLocalPhoneNumberError] = useState("");
  const [phone, setPhone] = useState();
  const [currEmail, setCurrEmail] = useState();
  const isLoggedInUser = currEmail !== getUserInfo()?.user_email;
  const initialValues = {
    username: props.User?.user_info?.username || "",
    email: props.User?.user_info?.email || "",
    first_name: "",
    last_name: "",
    phone: props.User?.user_info || "",
    account_id: getUserInfo()?.account_id || "",
    company_name: props.User.company_info?.company_name || "",
    street_address: props.User.company_info?.street_address || "",
    appartment: props.User.company_info?.appartment || "",
    city: getCity(props).value ? getCity(props) : getFirstCityOfState(getFirstStateOfCountry("US").value),
    state: getState(props)?.value ? getState(props) : getFirstStateOfCountry("US"),
    country: "US",
    zip: props.User.company_info?.zip || "",
  };

  const finder = (user) => user.id === getUserInfo()?.id;
  const acc = find(props.User?.accounts, (acc) => find(acc.users, finder));
  const loggedInUser = find(acc?.users, finder) || {};
  const dataUser =
    loggedInUser?.account_id === getUserInfo()?.account_id
      ? loggedInUser
      : find(props.User?.accounts, (acc) => acc.id === getUserInfo()?.account_id)?.users?.find(
        (x) => x.role === "owner",
      );

  const validate = (values) => {
    const errors = {};
    if (!values?.email) {
      errors.email = "Field is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
      errors.email = "Invalid email address";
    }
    if (!phone) errors.phone = "Phone number required";
    if (localPhoneNumberError) errors.phone = localPhoneNumberError;
    (!values.username?.length && (errors.username = "Field is Required")) ||
      (!/^[A-Za-z0-9]+(?:[ &*$-_+@!#][A-Za-z0-9]+)*$/.test(values.username) &&
        (errors.username = "User name must contains alpha numeric (*special character)."));
    !values.first_name?.length && (errors.first_name = "Field is Required");
    !values.last_name?.length && (errors.last_name = "Field is Required");
    if (values.company_name && (values.company_name?.length < 5 || values.company_name?.length > 100))
      errors.company_name = "Company name must be at least 5 characters and not more than 100 characters";
    if (values.company_name && !/^[a-zA-Z .-]+[a-zA-Z]+$/.test(values.company_name))
      errors.company_name =
        "Company name shouldn't contain any number or special character other than dash or apostrophes.";
    if (values.street_address && (values.street_address?.length < 5 || values.street_address?.length > 50))
      errors.street_address = "Street address must be at least 5 characters and not more than 50 characters";
    if (values.street_address && !/^[#.0-9a-zA-Z\s,-]+$/.test(values.street_address))
      errors.street_address = "Wrong address format";
    if (values.appartment && (values.appartment?.length > 50 || !/^[0-9]*/.test(values.appartment)))
      errors.appartment = "Appartment suite must 5 characters and in numberic value";
    if (values.zip && (values.zip?.length < 5 || values.zip?.length > 10))
      errors.zip = "Zip code must be at least 5 characters. ";
    if (values.zip && !String(values.zip).match(zipRe)) errors.zip_code_format = "Wrong zip code format!";
    return errors;
  };

  const setFormikValues = (dataUser) => {
    const { first_name: fName, last_name: lName, email, phone, username } = dataUser;
    formik.setFieldValue("email", email);
    formik.setFieldValue("first_name", fName);
    formik.setFieldValue("last_name", lName);
    formik.setFieldValue("phone", phone);
    formik.setFieldValue("username", username);
    setPhone(phone);
    setCurrEmail(email);
  };
  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const data = {
        username: values.username,
        account_id: values.account_id,
        appartment: values.appartment,
        city: values.city.label,
        company_name: values.company_name,
        email: values.email,
        state: values.state.value,
        street_address: values.street_address,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        zip: values.zip,
      };
      props.updateProfileAndCompanyInfo({ id: getUserInfo()?.id, data });
    },
  });

  useEffect(() => {
    isUserHaveFullAccess() && props.fetchCompanyInfo(getUserInfo()?.account_id);
  }, []);

  useEffect(() => {
    if (dataUser && getUserInfo()?.role === "admin") {
      setFormikValues(dataUser);
    }
  }, [dataUser]);

  useEffect(() => {
    if (props.User?.user_info && getUserInfo()?.role !== "admin") {
      setFormikValues(props.User?.user_info);
    }
  }, [props.User?.user_info]);

  useEffect(() => {
    Object.entries(formik.values).every(([key, val]) => {
      if (["country", "id"].includes(key)) return true;
      if (["state", "city"].includes(key)) return val?.value === initialValues[key]?.value;
      return val === initialValues[key];
    });
  }, [formik.values]);

  return (
    <React.Fragment>
      {props.User.changePasswordModalIsOpen && (
        <ChangePasswordModel
          isOpen={props.User.changePasswordModalIsOpen}
          toggleIsOpen={props.setChangePasswordModel}
          accountId={getUserInfo()?.id}
          updateUser={props.updateUser}
          userState={props.User}
        />
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Profile Settings" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {props.User?.success && (
                    <div className="auto-hide">
                      <UncontrolledAlert
                        fade={true}
                        color="success"
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        <i className="mdi mdi-check-all mr-2" />
                        {props.User?.success}
                      </UncontrolledAlert>
                    </div>
                  )}
                  {props.User?.error && (
                    <div className="auto-hide">
                      <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                        {props.User?.error}
                      </UncontrolledAlert>
                    </div>
                  )}
                  <div className="d-flex justify-content-between w-100">
                    <h4 className="card-title">ACCOUNT INFO</h4>
                    <PillBtn
                      color="success"
                      title="Change Password"
                      name="Change Password"
                      disabled={isLoggedInUser}
                      onClick={() => props.setChangePasswordModel(true)}
                    />
                  </div>
                  <AvForm onSubmit={() => formik.handleSubmit()}>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Row className="d-flex justify-content-end mt-3">
                            <Col md={6}>
                              <Label>Email</Label>
                              <Input
                                name="email"
                                placeholder="Enter Email"
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                readOnly={true}
                                disabled={isLoggedInUser}
                              />
                              {formik.touched.email && formik.errors.email
                                ? (
                                <small className="text-danger mt-1">{formik.errors.email}</small>
                                  )
                                : null}
                              <Col className="pl-0 pr-0">
                                <Label>Username</Label>
                                <Input
                                  name="username"
                                  placeholder="Enter Username"
                                  value={formik.values.username}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  disabled={isLoggedInUser}
                                />
                                {formik.touched.username && formik.errors.username
                                  ? (
                                  <small className="text-danger mt-1">{formik.errors.username}</small>
                                    )
                                  : null}
                              </Col>
                            </Col>
                            <Col md={6}>
                              <Label>Phone</Label>
                              <PhoneInput
                                placeholder="Enter phone number"
                                name="phone"
                                defaultCountry="US"
                                international={true}
                                value={phone || ""}
                                countryCallingCodeEditable={false}
                                disabled={isLoggedInUser}
                                onBlur={() => formik.setFieldValue("phone", phone)}
                                onChange={(phone) => {
                                  const isPhonePossible = phone
                                    ? isPossiblePhoneNumber(phone)
                                      ? setLocalPhoneNumberError()
                                      : "Invalid phone number"
                                    : "Phone number required";
                                  if (isPhonePossible) {
                                    setLocalPhoneNumberError(isPhonePossible);
                                  }
                                  setPhone(phone);
                                  formik.errors.phone = "";
                                }}
                              />
                              {localPhoneNumberError && formik.errors?.phone
                                ? (
                                <small className="text-danger mt-1">
                                  {localPhoneNumberError || formik.errors?.phone}
                                </small>
                                  )
                                : null}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-end mt-3">
                            <Col md={6}>
                              <Label>First name</Label>
                              <Input
                                name="first_name"
                                placeholder="Enter First Name"
                                value={formik.values.first_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                disabled={isLoggedInUser}
                              />
                              {formik.touched.first_name && formik.errors.first_name
                                ? (
                                <small className="text-danger mt-1">{formik.errors.first_name}</small>
                                  )
                                : null}
                            </Col>
                            <Col md={6}>
                              <Label>Last name</Label>
                              <Input
                                name="last_name"
                                placeholder="Enter Last Name"
                                value={formik.values.last_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                disabled={isLoggedInUser}
                              />
                              {formik.touched.last_name && formik.errors.last_name
                                ? (
                                <small className="text-danger mt-1">{formik.errors.last_name}</small>
                                  )
                                : null}
                            </Col>
                          </Row>
                        </FormGroup>
                        <br />
                        <RenderIf isTrue={false}>
                          <h4 className="card-title">COMPANY INFO</h4>
                          <br />
                          <FormGroup className="select2-container">
                            <Row>
                              <Col>
                                <CustomLabel>Company Name</CustomLabel>
                                <Input
                                  name="company_name"
                                  value={formik.values.company_name}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                {formik.touched.company_name && formik.errors.company_name
                                  ? (
                                  <small className="text-danger mt-1">{formik.errors.company_name}</small>
                                    )
                                  : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CustomLabel>Street Address</CustomLabel>
                                <Input
                                  name="street_address"
                                  value={formik.values.street_address}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.street_address
                                  ? (
                                  <small className="text-danger mt-1">{formik.errors.street_address}</small>
                                    )
                                  : null}
                              </Col>
                              <Col>
                                <CustomLabel>Appartment / Suite</CustomLabel>
                                <Input
                                  name="appartment"
                                  value={formik.values.appartment}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.appartment
                                  ? (
                                  <small className="text-danger mt-1">{formik.errors.appartment}</small>
                                    )
                                  : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CustomLabel>State / Province / Region</CustomLabel>
                                <Select
                                  name="state"
                                  value={formik.values?.state}
                                  onChange={(option) => {
                                    formik.setFieldValue("state", option);
                                    formik.setFieldValue("city", getFirstCityOfState(option.value));
                                  }}
                                  options={State.getStatesOfCountry("US")
                                    ?.filter((x) => City.getCitiesOfState("US", x.isoCode).length)
                                    ?.map((x) => ({
                                      label: x.name,
                                      value: x.isoCode,
                                    }))}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col>
                                <CustomLabel>City</CustomLabel>
                                <Select
                                  name="city"
                                  value={formik.values?.city || ""}
                                  onChange={(option) => {
                                    formik.setFieldValue("city", option);
                                  }}
                                  options={City.getCitiesOfState(
                                    formik.values?.country,
                                    formik.values?.state?.value,
                                  ).map((x) => ({
                                    label: x.name,
                                    value: x.name,
                                  }))}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CustomLabel>Country</CustomLabel>
                                <AvField
                                  readOnly
                                  placeholder="US"
                                  name="country"
                                  type="text"
                                  className="form-control"
                                />
                              </Col>
                              <Col>
                                <CustomLabel>ZIP/Postal Code</CustomLabel>
                                <Input
                                  name="zip"
                                  placeholder="12345 OR 12345-1234"
                                  value={formik.values.zip}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.zip
                                  ? (
                                  <small className="text-danger mt-1">{formik.errors.zip}</small>
                                    )
                                  : null}
                                {formik.errors.zip_code_format
                                  ? (
                                  <small className="text-danger mt-1">{formik.errors.zip_code_format}</small>
                                    )
                                  : null}
                              </Col>
                            </Row>
                          </FormGroup>
                        </RenderIf>
                      </Col>
                    </Row>
                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light float-right mr-3"
                      disabled={isLoggedInUser}
                    >
                      Update
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { User } = state;
  return { User };
};

export default connect(mapStateToProps, {
  fetchCompanyInfo,
  updateProfileAndCompanyInfo,
  updateUser,
  setChangePasswordModel,
})(ProfileSettings);
