import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Card, CardBody } from "reactstrap";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import CustomTooltip from "../../components/Common/CustomTooltip";
import HiddenField from "../../components/Common/HiddenField";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import Knob from "../../components/Common/KnobChart";
import { decode, isManualTeam, toPascalCase, getUserInfo } from "../../utils/common";
import CredentialsModal from "./CredentialsModal";
import CCTypesModal from "./CCTypesModal";
import {
  fetchTotp,
  setEditCredentialsModal,
  setSelectedCredentials,
  deleteCredential,
  setCreditCardTypesModal,
  setSelectedIds,
  fetchCreditCardDetails,
  unlockCredential,
  updateCredentials,
} from "../../store/actions";
import { authenticator } from "otplib";
import Row from "reactstrap/lib/Row";
import { startCase } from "lodash";
import moment from "moment";
import { OTP_ACCESS_USER_IDS } from "../../constants";

const isSpecialAccess = (_) =>
  OTP_ACCESS_USER_IDS.includes(getUserInfo()?.id) ||
  ([5335, 2062].includes(getUserInfo()?.id) && (getUserInfo()?.account_id === 2 || getUserInfo()?.agency_id === 3382));

const AccountCredentials = (props) => {
  const [selectedRecord, setSelectedRecord] = useState({
    id: "",
    email: "",
  });
  const [otpTime, setOtpTime] = useState(0);
  const tableHeaders = [
    { title: "Site" },
    { title: "Password" },
    { title: "Card Type" },
    { title: "OTP" },
    { title: "Locked", class: "text-center" },
    { title: "Enabled", class: "text-center" },
    { title: "Blocked", class: "text-center" },
  ];

  const pickTotp = (_) => {
    const { totp, time } = props.Credential.totps?.[decode(props.email)] || {};
    if (!time || !totp || moment().isAfter(time)) return;
    return totp;
  };

  function handleEditCredential (credential) {
    props.setSelectedCredentials({
      ...credential,
      password: decode(credential.password),
    });
    props.setEditCredentialsModal(true);
  }

  function handleUnlockCredential (credential) {
    props.unlockCredential({
      email: decode(props.email),
      site: credential.site,
    });
  }

  function handleErrorFixCredential (credential) {
    props.updateCredentials({
      data: { error: null },
      id: credential.id,
    });
  }

  function handleDeleteCredential (credential) {
    props.deleteCredential(credential.id);
  }

  useEffect(() => {
    if (getUserInfo()?.role !== "admin" || isSpecialAccess() || isManualTeam()) {
      refreshOtps();
      const interval = setInterval(() => refreshOtps(), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [props.credentials]);

  function refreshOtps () {
    const cred = props.credentials?.find((x) => x.site === "amazon" && x.has_otp_secret);
    if (!cred) return;
    const remainingTime = authenticator.timeRemaining();
    setOtpTime(remainingTime);
    if (props.Credential?.totpLoading || (remainingTime !== 30 && pickTotp())) return;
    props.fetchTotp({ username: decode(props.email), service: "amazon" });
  }

  return (
    <React.Fragment>
      {props.Credential.editCredentialModalIsOpen && props.Credential.selectedCredentials && (
        <CredentialsModal type="edit" />
      )}
      {props.Credential.createCreditCardTypesModalIsOpen && props.Credential.creditCardTypesModalType === "card" && (
        <CCTypesModal selected={selectedRecord} />
      )}
      <Container fluid>
        <Card>
          <CardBody>
            {!props.credentials?.length ? (
              <h2 className="text-center">No Credentials Found</h2>
            ) : (
              <div className="table-responsive">
                <table className="table table-centered table-nowrap inner-table mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      <th></th>
                      {tableHeaders.map((header, index) => {
                        return header.title === "OTP"
                          ? (
                          <th className="pl-5" key={`table-header ${index}`}>
                            {header.title}
                          </th>
                            )
                          : (
                          <th key={`table-header ${index}`} className={header.class || ""}>
                            {" "}
                            {header.title}
                          </th>
                            );
                      })}
                      {props.credentials?.find((x) => x.error) ? <th>Error</th> : null}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {props.credentials?.map((item, key) => (
                      <tr
                        title={item.error}
                        key={"_credentials_" + key}
                        className={item.error ? "mt-3 mb-3 row-danger" : "mt-3 mb-3"}
                      >
                        <td>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={props.Credential?.selectedIds.includes(item.id)}
                              onChange={() => false}
                            />
                            <label
                              className="custom-control-label mt-2"
                              onClick={() => {
                                if (props.Credential?.selectedIds.includes(item.id)) {
                                  props.setSelectedIds(props.Credential.selectedIds.filter((id) => item.id !== id));
                                } else {
                                  props.setSelectedIds([...props.Credential.selectedIds, item.id]);
                                }
                              }}
                            ></label>
                          </div>
                        </td>
                        <td>
                          {toPascalCase(item?.site)}{" "}
                          {item?.acc_type && item.acc_type !== "any" && (
                            <span className="text-primary">{startCase(item.acc_type)}</span>
                          )}
                        </td>
                        <td>
                          {item?.password && (
                            <div className="d-flex align-items-center">
                              <HiddenField value={decode(item?.password)} />
                              <CopyToClipBoard text={decode(item?.password)} />
                            </div>
                          )}
                        </td>
                        <td>{props.ccTypes?.find((x) => x.id === item.card_type_id)?.name}</td>
                        <td>
                          {item.has_otp_secret
                            ? (
                            <Row className="d-flex align-items-center">
                              <Knob
                                className="mr-1"
                                lineCap="round"
                                max={30}
                                min={0}
                                font="10px"
                                value={otpTime}
                                fgColor="#5468da"
                                thickness={0.16}
                                readOnly={true}
                                height={30}
                                width={30}
                                onChange={() => null}
                              />
                              <div className="d-flex align-items-center ml-2">
                                {props.Credential?.totpLoading
                                  ? "Refreshing"
                                  : pickTotp() && (
                                      <>
                                        {pickTotp()} <CopyToClipBoard text={pickTotp()} padding={1} />
                                      </>
                                  )}
                              </div>
                            </Row>
                              )
                            : (
                                "No 2Fa Hash"
                              )}
                        </td>
                        <td className="text-center">
                          {item?.locked
                            ? (
                            <>
                              <ConfirmDialogIcon
                                icon={{
                                  title: "Unlock For BOT",
                                  className: "bx bx-sm text-info bx-lock-open",
                                }}
                                msg={`To Unlock ${decode(props.email)} Credentials`}
                                onConfirm={() => handleUnlockCredential(item)}
                              />
                              {item?.order_url && isManualTeam()
                                ? (
                                <a
                                  href={`/source_orders_details${item.order_url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="bx bx-sm bx-link-external"></i>
                                </a>
                                  )
                                : null}
                            </>
                              )
                            : null}
                        </td>
                        <td className="text-center">
                          {item?.enabled && (
                            <i title="Account Enabled" className="bx bx-sm bx-check-circle text-success"></i>
                          )}
                        </td>
                        <td className="text-center">
                          {item?.blocked && <i title="Blocked" className="bx bx-sm bx-block text-danger"></i>}
                        </td>
                        {props.credentials?.find((x) => x.error)
                          ? (
                          <td>
                            <div id={`credError${key}`} className="text-overflow-wrap">
                              {item.error}
                              <CustomTooltip target={`credError${key}`} content={item.error} />
                            </div>
                          </td>
                            )
                          : null}
                        <td>
                          {item?.error && (
                            <ConfirmDialogIcon
                              icon={{
                                title: "Resolve Issue",
                                className: "bx bx-sm text-info bx-info-square",
                              }}
                              msg={`To Resolve Error for ${decode(props.email)}`}
                              onConfirm={() => handleErrorFixCredential(item)}
                            />
                          )}
                          <i
                            title="Edit Credential"
                            className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                            onClick={() => handleEditCredential(item)}
                          />
                          {getUserInfo()?.account_id === 2 && (
                            <i
                              title="Add Credit Card(s)"
                              className="bx bx-sm bx-credit-card p-1 text-primary cursor-pointer"
                              onClick={() => {
                                props.fetchCreditCardDetails();
                                setSelectedRecord({
                                  id: item?.id,
                                  email: decode(props.email),
                                  cc_details: item?.cc_details,
                                });
                                props.setCreditCardTypesModal(true, "card");
                              }}
                            ></i>
                          )}
                          <ConfirmDialogIcon
                            icon={{
                              title: "Delete Credential",
                              className: "bx bx-sm text-danger bx-trash",
                            }}
                            msg={`To Delete ${item?.site} Credentials`}
                            onConfirm={() => handleDeleteCredential(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { Credential } = state;
  return { Credential };
};

const mapDispatchToProps = {
  setEditCredentialsModal,
  setSelectedCredentials,
  deleteCredential,
  setCreditCardTypesModal,
  setSelectedIds,
  fetchCreditCardDetails,
  unlockCredential,
  updateCredentials,
  fetchTotp,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountCredentials);
