import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Row, Col, Alert, FormGroup } from "reactstrap";
import Select from "react-select";
import queryString from "query-string";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomPagination from "../../components/Common/CustomPagination";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import { CREDIT_TYPES, DATE_FORMATS, HELP_DOCS_LINK } from "../../constants/index";
import "flatpickr/dist/themes/dark.css";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// actions
import { fetchCreditLogs, setPreloader, consumeCredit, deleteCreditLog } from "../../store/actions";
import { getDate, isAODev } from "../../utils/common";
import { mapTrackingUrl } from "../../utils/trackingUrlMap";
import { compact } from "lodash";
import { DatePicker } from "../../components/Common/DatePickr";

const Listings = (props) => {
  const queryParameters = queryString.parse(props.location.search);
  const [creditType, setCreditType] = useState({ label: "All", value: "" });
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    page: queryParameters?.page || 1,
    per_page: 100,
    sort_direction: "desc",
    sort_by: "created_at",
  });

  const resetFilter = () => {
    document.querySelectorAll("#up-filter").forEach((element) => {
      element.style.visibility = "initial";
    });
    document.querySelectorAll("#down-filter").forEach((element) => {
      element.style.visibility = "initial";
    });
  };

  const tableHeaders = [
    {
      title: "Id",
      isAODev: true,
    },
    {
      title: "Order Id",
    },
    {
      title: "Tracking Number",
    },
    {
      title: "Credit Type",
    },
    {
      title: "Created At",
      sort: true,
      onClick: () =>
        setFilters({
          ...filters,
          sort_by: "created_at",
          sort_direction: filters.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 0,
        }),
    },
    {
      title: "Action",
      isAODev: true,
    },
  ];

  useEffect(() => {
    props.setPreloader(props.CreditLog.loading);
  }, [props.CreditLog.loading]);

  useEffect(() => {
    props.fetchCreditLogs(filters);
    resetFilter();
    if (JSON.stringify(filters.sort_direction).includes("DESC")) {
      document.getElementsByClassName("dripicons-arrow-thin-up")[filters.column_index].style.visibility = "hidden";
      document.getElementsByClassName("dripicons-arrow-thin-down")[filters.column_index].style.visibility = "initial";
    } else if (JSON.stringify(filters.sort_direction).includes("ASC")) {
      document.getElementsByClassName("dripicons-arrow-thin-down")[filters.column_index].style.visibility = "hidden";
      document.getElementsByClassName("dripicons-arrow-thin-up")[filters.column_index].style.visibility = "initial";
    }
  }, [filters]);

  function hasProxy (item) {
    return item.proxy_number && item.proxy_number !== "0";
  }

  const typeSelectOptions = { value: "AO", label: "AO" };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={
              <>
                Credit Log{" "}
                {
                  <a href={HELP_DOCS_LINK} rel="noopener noreferrer" target="_blank">
                    <i className="bx bx-info-circle"></i>
                  </a>
                }
              </>
            }
          />
          {isAODev()
            ? (
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  if (!props.CreditLog.loading) {
                    props.consumeCredit({
                      orderIds: [v.orderId],
                      type: v.type,
                    });
                  }
                }}
              >
                {props.CreditLog.creditConsumed && (
                  <div className="auto-hide">
                    <Alert color="success">
                      <i className="bx bx-info-circle pr-2"></i>
                      {props.CreditLog.creditConsumed?.message}
                    </Alert>
                  </div>
                )}
                <Row className="d-flex align-items-center">
                  <Col md="4">
                    <AvField
                      name="orderId"
                      label="Order ID*"
                      value=""
                      className="form-control"
                      placeholder="Order ID"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Order Id is required",
                        },
                        pattern: {
                          value: /^\d{3}-\d{7}-\d{7}$/,
                          errorMessage: "Order ID must be in format XXX-XXXXXXX-XXXXXXX",
                        },
                      }}
                    />
                  </Col>
                  <Col>
                    <FormGroup className="select2-container mt-4">
                      <Select
                        name="type"
                        value={typeSelectOptions}
                        options={[typeSelectOptions]}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <button className="mt-1 btn btn-primary btn-block waves-effect waves-light" type="submit">
                      Consume Credit
                    </button>
                  </Col>
                </Row>
              </AvForm>
            </div>
              )
            : null}
          {props.CreditLog.creditDeleted && (
            <div className="auto-hide">
              <Alert color="danger" className="my-2 ">
                <i className="bx bx-info-circle pr-2"></i>
                {props.CreditLog.creditDeleted?.message}
              </Alert>
            </div>
          )}
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-start">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setFilters({ ...filters, search, page: 1 });
                  }}
                >
                  <div className="position-relative" style={{ display: "inline-flex" }}>
                    <input
                      id="search_field"
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value.trim()) {
                          setSearch("");
                          setFilters({ ...filters, search: "" });
                        } else {
                          setSearch(e.target.value.trim());
                        }
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
                <DatePicker className="ml-3" onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })} />

                <FormGroup className="select2-container ml-3 mt-3" style={{ width: "200px" }}>
                  {(props.CreditLog.creditLogs.bce || props.CreditLog.creditLogs.fedex) && (
                    <Select
                      name="marketplace_id"
                      value={{
                        value: creditType.value,
                        label: creditType.label,
                      }}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          filter: e.value,
                          page: 1,
                        });
                        setCreditType({ value: e.value, label: e.label });
                      }}
                      options={compact([
                        CREDIT_TYPES[0],
                        props.CreditLog.creditLogs.bce && CREDIT_TYPES[1],
                        props.CreditLog.creditLogs.fedex && CREDIT_TYPES[2],
                      ]).reduce(
                        (acc, type, i) => {
                          acc.push({
                            label: type,
                            value: type,
                            key: i,
                          });
                          return acc;
                        },
                        [{ value: "", label: "All" }],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  )}
                </FormGroup>
              </div>
            </CardHeader>
            <CardBody>
              {props.CreditLog.creditLogs.data?.length ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {/* table headers */}
                          {tableHeaders.map((header, index) =>
                            !header.isAODev || (header.isAODev && isAODev())
                              ? (
                              <th
                                key={`table-header ${index}`}
                                onClick={header.onClick}
                                className={/credit type|created at/i.test(header.title) ? "text-center" : ""}
                              >
                                {header.title}
                                {header.sort
                                  ? (
                                  <div className="d-inline ml-1">
                                    <i id="up-filter" className="dripicons-arrow-thin-up cursor-pointer"></i>
                                    <i id="down-filter" className="dripicons-arrow-thin-down cursor-pointer"></i>
                                  </div>
                                    )
                                  : (
                                      ""
                                    )}
                              </th>
                                )
                              : null,
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {/* iterating data */}
                        {props.CreditLog.creditLogs.data.map((item, key) => (
                          <tr
                            key={"_credit_log_" + key}
                            className={
                              item.order_id === props.CreditLog.proxyBceRes.target_order_id
                                ? "mt-3 mb-3 row-success"
                                : "mt-3 mb-3"
                            }
                          >
                            {isAODev() ? <td>{item.id}</td> : null}
                            <td>
                              {item.supplier_order?.source_order_id && item.order_id
                                ? (
                                <>
                                  <a
                                    href={`/source_orders_details/${item.supplier_order.source_order.marketplace_order_id}/${item.supplier_order.source_order_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.order_id}
                                  </a>
                                  <CopyToClipBoard text={item.order_id} />
                                </>
                                  )
                                : item.order_id
                                  ? (
                                <>
                                  {item.order_id}
                                  <CopyToClipBoard text={item.order_id} />
                                </>
                                    )
                                  : null}
                            </td>
                            <td>
                              {hasProxy(item)
                                ? (
                                <>
                                  <a
                                    href={mapTrackingUrl(item?.credit_type, item?.proxy_number, "#")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.proxy_number}
                                  </a>

                                  <CopyToClipBoard text={item.proxy_number} />
                                </>
                                  )
                                : null}
                            </td>
                            <td className="text-center">
                              {item.credit_type}
                              {item.fifty_fifty ? " (Free Credit)" : ""}
                            </td>
                            <td className="text-center">{getDate(item.created_at, DATE_FORMATS.FULL_DATE)}</td>
                            {isAODev()
                              ? (
                              <td>
                                <ConfirmDialogIcon
                                  icon={{
                                    className: "fa fa-lg fa-trash-alt ml-3",
                                  }}
                                  title="Delete Credit Log"
                                  msg={`to delete this order log ${item?.order_id}`}
                                  onConfirm={() => {
                                    window.scrollTo(0, 0);
                                    props.deleteCreditLog(item.id);
                                  }}
                                />
                              </td>
                                )
                              : null}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {props.CreditLog?.creditLogs && (
                    <CustomPagination
                      total={props.CreditLog.creditLogs.total}
                      page={props.CreditLog.creditLogs.page}
                      perPage={props.CreditLog.creditLogs.perPage}
                      tabsFilter={filters}
                      setTabFilter={setFilters}
                      pageOptions={[30, 100, 500]}
                    />
                  )}
                </>
              ) : null}

              {props.CreditLog.creditLogs.data?.length === 0 && !props.CreditLog.loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { CreditLog } = state;
  return { CreditLog };
};

export default connect(mapStatetoProps, {
  fetchCreditLogs,
  setPreloader,
  consumeCredit,
  deleteCreditLog,
})(Listings);
