import React from "react";
import { Button } from "reactstrap";
import { RenderIf } from "../../utils/common";

const PillBtn = (props) => {
  return (
    <Button
      id={props.id}
      title={props.title}
      className={props.className}
      color={props.color}
      onClick={props.onClick}
      outline={props.outline}
      disabled={props.disabled}
      style={props.style}
      size={props.size}
    >
      <div className="d-flex align-items-center justify-content-center">
        <RenderIf isTrue={props.icon}>
          <i className={`mr-2 ${props.icon}`}></i>
        </RenderIf>
        <RenderIf isTrue={props.img}>
          <img alt="icon" src={props.img} className="mr-2" />
        </RenderIf>

        <span>{props.name}</span>
      </div>
    </Button>
  );
};

export default PillBtn;
