import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ReactTable from "../../../../components/Common/Table";
import Modal from "../../../../components/Common/Modal";

import moment from "moment-timezone";
import { startCase } from "lodash";

// Actions
import { setPOLogsModal } from "../../../../store/actions";

const PurchaseOrderLog = () => {
  const dispatch = useDispatch();
  const { POLogsModalIsOpen, purchaseOrderslogs } = useSelector((state) => state.WareHouses);
  const togglePOLogsModal = () => dispatch(setPOLogsModal(!POLogsModalIsOpen));

  const [POlogs, setPOlogs] = useState([]);

  useEffect(() => {
    if (purchaseOrderslogs) setPOlogs(purchaseOrderslogs);
  }, [purchaseOrderslogs]);

  return (
    <div>
      <Modal size="xl" isOpen={POLogsModalIsOpen} toggle={togglePOLogsModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Activity Log
          </h5>
          <button
            onClick={() => togglePOLogsModal()}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <ReactTable
            height={{ maxHeight: "400px" }}
            tableHeader={
              <>
                <th>Event</th>
                <th>Date</th>
                <th>Author</th>
                <th>Abstract</th>
              </>
            }
            tableBody={
              <>
                {POlogs.length &&
                  POlogs.map((x, i) => (
                    <tr key={i}>
                      <td>{startCase(x.event)}</td>
                      <td>{moment(x.created_at).tz(moment.tz.guess()).format("ll")}</td>
                      <td>{x.author}</td>
                      <td>{x.abstract}</td>
                    </tr>
                  ))}
              </>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseOrderLog;
