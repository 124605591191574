import * as types from "./actionTypes";

export const fetchMatches = (params) => ({ type: types.FETCH_MATCHES, payload: params });
export const fetchMatchesSuccess = (res) => ({ type: types.FETCH_MATCHES_SUCCESS, payload: res });

export const setFilter = (filter) => ({ type: types.SET_FILTER, payload: filter });
export const setSavedFilter = (savedFilter) => ({ type: types.SET_SAVED_FILTERS, payload: savedFilter });
export const setMarketplace = (marketplace) => ({ type: types.SET_MARKETPLACE, payload: marketplace });

export const addNewSavedFilter = (payload) => ({ type: types.ADD_NEW_SAVED_FILTER, payload });
export const addNewSavedFilterSuccess = (payload) => ({ type: types.ADD_NEW_SAVED_FILTER_SUCCESS, payload });
export const updateSavedFilter = (params, payload) => ({
  type: types.UPDATE_SAVED_FILTER,
  params,
  payload,
});
export const updateSavedFilterSuccess = (payload) => ({ type: types.UPDATE_SAVED_FILTER_SUCCESS, payload });

export const fetchSavedFilters = (payload) => ({ type: types.FETCH_SAVED_FILTERS, payload });
export const fetchSavedFiltersSuccess = (payload) => ({ type: types.FETCH_SAVED_FILTERS_SUCCESS, payload });

export const addColumnFilter = (payload) => ({ type: types.ADD_COLUMN_FILTER, payload });
export const removeColumnFilter = (payload) => ({ type: types.REMOVE_COLUMN_FILTER, payload });

export const addMismatchProduct = (payload) => ({ type: types.ADD_MISMATCH_PRODUCT, payload });
export const addMismatchProductSuccess = (payload) => ({ type: types.ADD_MISMATCH_PRODUCT_SUCCESS, payload });

export const addListingProduct = (payload) => ({ type: types.ADD_LISTING_PRODUCT, payload });
export const addListingProductSuccess = (payload) => ({ type: types.ADD_LISTING_PRODUCT_SUCCESS, payload });

export const checkProductEligibility = (params, payload) => ({
  type: types.CHECK_PRODUCT_ELIGIBILITY,
  params,
  payload,
});
export const checkProductEligibilitySuccess = (payload) => ({
  type: types.CHECK_PRODUCT_ELIGIBILITY_SUCCESS,
  payload,
});

export const setHideRow = (payload) => ({ type: types.SET_HIDE_ROW, payload });

export const setSalesRank = (payload) => ({ type: types.SET_SALES_RANK, payload });

export const setPageNumber = (payload) => ({ type: types.SET_PAGE_NUMBER, payload });

export const apiError = (error) => ({ type: types.API_ERROR, payload: error });
