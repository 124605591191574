import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_FORGET_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SET_FORGET_PASS_ERROR,
} from "./actionTypes";

export const userForgetPassword = ({ email }) => {
  return {
    type: FORGET_PASSWORD,
    payload: { email },
  };
};

export const userForgetPasswordSuccess = (message) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userForgetPasswordError = (message) => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  };
};

export const resetPassword = (data) => {
  return {
    type: RESET_PASSWORD,
    payload: { data },
  };
};

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: {},
  };
};

export const resetForgotPassword = () => {
  return {
    type: RESET_FORGET_PASSWORD,
    payload: {},
  };
};

export const setResetPassErr = (err) => {
  return {
    type: SET_FORGET_PASS_ERROR,
    payload: err,
  };
};
