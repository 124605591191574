import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardBody, Progress, FormGroup, Label, InputGroup, CardTitle } from "reactstrap";
import { adjustDate, getRandomInt, humanize, RenderIf } from "../../../utils/common";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import * as _ from "lodash";
// actions
import { fetchSharedOrderStatus } from "../../../store/actions";
import { DATE_FORMATS } from "../../../constants";
const { GRAPH_DATE, BACKEND } = DATE_FORMATS;
const FILTERED = ["cancelled", "completed", "refund", "in_queue", "pending_cancellation", "wh_shipped", "wh_delivered"];
const colors = ["primary", "success", "danger", "secondary", "warning"];

const OrderStatus = (props) => {
  const isShared = props.type === "shared";
  const dispatch = useDispatch();
  const { sharedOrderStatus, orderStatusLoader } = useSelector((state) => state.Graph);
  const [data, setData] = useState(props.statuses);
  const { archived } = useSelector((state) => state.Agency);
  const userId = props.userId;
  const store = props.marketplace;

  const [date, setDate] = useState(new Date(Date.now() - 86400000));
  const [statusFilter, setStatusFilter] = useState("Merchant Fulfilled");
  const isMF = (item) => ["WFS", "AFN"].includes(item?.fulfillment_channel); // Is Marketplace Fulfilled

  const getOrdersByFulfillChannel = (data, isMFChannel = false) => {
    const groupedData = _.groupBy(
      data?.filter((item) => isMF(item) === isMFChannel),
      "status",
    );
    return _.entries(groupedData).map(([status, orders]) => ({ status, count: orders.length }));
  };

  const fetchStats = (date, archived) => {
    dispatch(
      fetchSharedOrderStatus({
        date,
        user_id: userId,
        ...(store && { store_name: store }),
        archived,
      }),
    );
  };

  useEffect(() => {
    !orderStatusLoader && isShared && fetchStats(moment(adjustDate(date)).format(BACKEND), archived);
  }, [userId, store, date, archived]);

  useEffect(() => {
    sharedOrderStatus.data?.stats && setData(sharedOrderStatus.data?.stats);
    props.statuses && setData(props.statuses);
  }, [sharedOrderStatus, props.statuses]);

  const [merchantFulfilledOrders, mpFulfilledOrders] = [false, true].map((val) => getOrdersByFulfillChannel(data, val));

  const retriesCount = data?.reduce((acc, s) => {
    if (["checking", "delayed"].includes(s?.status)) acc += s.count;
    return acc;
  }, 0);

  const countOrders = (orders) => _.sumBy(orders, "count");

  const ordersData = [
    { type: "Marketplace Fulfilled", length: countOrders(mpFulfilledOrders), orders: mpFulfilledOrders },
    { type: "Merchant Fulfilled", length: countOrders(merchantFulfilledOrders), orders: merchantFulfilledOrders },
  ];

  const groupStatusesByTab = ordersData.reduce(
    (typeObj, data) =>
      _.merge(typeObj, {
        [data.type]: data.orders
          ?.filter((item) => !FILTERED.includes(item.status))
          .reduce((obj, item) => _.merge(obj, item?.count && { [item.status]: item }), {}),
      }),
    {},
  );

  const filteredStatuses = {
    ...groupStatusesByTab[statusFilter],
    retries_scheduled: { status: "retries_scheduled", count: retriesCount },
  };

  const total = ordersData.find((x) => x.type === statusFilter)?.orders?.reduce((acc, s) => acc + s.count, 0);

  const exclude = _.sumBy(
    ordersData.find((x) => x.type === statusFilter)?.orders?.filter((item) => FILTERED.includes(item.status)),
    "count",
  );

  const statusSortOrder = {
    new: "New",
    processing: "Processing",
    manual_fulfillment: "Manual Fulfillment",
    errored: "Error",
    partially_processed: "Partially Processed",
    processed: "Processed",
    retries_scheduled: "Retries Scheduled",
    marked_not_shipped: "Marked But Not Shipped",
    shipped: "Shipped",
    shipped_with_tba: "Shipped With TBA",
    delivered_with_tba: "Delivered With TBA",
  };

  return (
    <React.Fragment>
      <Card className="db-card-50-50">
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">
            <div className="d-flex flex-column">
              <h4 className="card-title">{orderStatusLoader ? "Loading..." : "Order Statuses"}</h4>
            </div>
          </CardTitle>
          <div className="d-flex flex-row float-sm-right">
            <RenderIf
              isTrue={
                ordersData.length && !isShared && _.values(groupStatusesByTab).filter((x) => !_.isEmpty(x)).length > 1
              }
            >
              <div className="mb-1 pl-5">
                <div className="btn-group btn-group-toggle">
                  {ordersData
                    .filter((data) => _.keys(groupStatusesByTab[data.type]).length)
                    .map((x, i) => (
                      <label
                        key={"__order__data" + i}
                        className={`btn btn-outline-primary group-btn-color ${
                          statusFilter === x.type ? "active" : ""
                        }  `}
                      >
                        <input type="radio" onClick={() => setStatusFilter(x.type)} />
                        {x.type}
                      </label>
                    ))}
                </div>
              </div>
            </RenderIf>
          </div>
          <RenderIf isTrue={isShared}>
            <div className="d-flex flex-row float-sm-right">
              <FormGroup className="mx-2">
                <Label>Date</Label>
                <InputGroup>
                  <DatePicker
                    dateFormat={GRAPH_DATE}
                    selected={date}
                    onChange={(date) => setDate(date)}
                    selectsEnd
                    className="form-control"
                    popperPlacement="bottom-left"
                  />
                </InputGroup>
              </FormGroup>
            </div>
          </RenderIf>
          <div className="table-responsive mt-4">
            <h3 className="text-center mb-3">Open Orders {humanize(total - exclude || 0)}</h3>
            <table className="table table-centered table-nowrap mb-2">
              <tbody>
                {filteredStatuses &&
                  Object.keys(statusSortOrder)?.map((key, index) => (
                    <RenderIf key={"__status__" + key} isTrue={filteredStatuses[key]?.count}>
                      <tr className="order-row-h" key={index + "_order_status"}>
                        <td style={{ width: "30%" }}>
                          <p className="mb-0">{statusSortOrder[key]}</p>
                        </td>
                        <td style={{ width: "25%" }}>
                          <h5 className="mb-0">{humanize(filteredStatuses[key]?.count || 0)}</h5>
                        </td>
                        <td>
                          <Progress
                            value={Math.ceil((filteredStatuses[key]?.count / (total - exclude)) * 100) || 0}
                            color={colors[getRandomInt(5)]}
                            size="sm"
                          />
                        </td>
                      </tr>
                    </RenderIf>
                  ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default OrderStatus;
