import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../components/Common/Modal";
import PillBtn from "../../../components/Common/PillBtn";
import * as actions from "../../../store/actions";
import * as $ from "lodash";
import { RenderIf, amountText, estCostPredicate, toF } from "../../../utils/common";
import { Alert, Input } from "reactstrap";

const UpdateCOGSModal = ({ order }) => {
  const dispatch = useDispatch();

  const [updatedItems, setUpdatedItems] = useState($.cloneDeep(order?.source_items || []));
  const { updateCOGSModalIsOpen } = useSelector((state) => state.Repricer);
  const { error } = useSelector((state) => state.SourceItems);
  const toggleStrategyModal = () => dispatch(actions.setUpdateCOGSModal(!updateCOGSModalIsOpen));

  const handleChange = (index, fieldName, event) => {
    const { value } = event.target;
    const list = [...updatedItems];
    list[index][fieldName] = toF(+value, 2, true);
    setUpdatedItems(list);
  };

  const allowedAttrs = ["sup_cost", "sup_shipping", "sup_tax"];

  const isValueUpdated = updatedItems.some((item, idx) =>
    allowedAttrs.some((attr) => item[attr] !== order?.source_items?.[idx]?.[attr]),
  );

  const updateItem = () => {
    const data = $.map(updatedItems, (item) => $.pick(item, "id", ...allowedAttrs));
    dispatch(actions.bulkUpdateSourceItem({ data }));
  };

  const UnsignedNumericInput = ({
    value,
    step = "1",
    min = "0",
    name = "",
    index,
    inputKey,
    isFloat = true,
    readOnly,
  }) => {
    const inValidCharacters = isFloat ? ["-", "e", "E"] : ["-", "e", "E", "."];
    return (
      <Input
        readOnly={readOnly}
        type="number"
        value={value}
        step={step}
        min={min}
        name={name}
        className={`form-control w-auto ${readOnly ? "cursor-not-allowed" : ""}`}
        onChange={(e) => {
          const value = e.target.value;
          if (/^0(?!\.)/.test(value) && value.length > 1) e.target.value = value.slice(1);
          handleChange(index, inputKey, e);
        }}
        onKeyPress={(e) => inValidCharacters.includes(e.key) && e.preventDefault()}
      />
    );
  };

  const updateCOGSHeader = [
    { title: "SKU", Content: (item) => <div>{item.sku}</div> },
    {
      title: "Qty",
      Content: (item, index) => (
        <div>
          {UnsignedNumericInput({
            value: item?.qty,
            step: "1",
            min: "0",
            name: `qty-${index}`,
            index,
            inputKey: "qty",
            isFloat: false,
            readOnly: true,
          })}
        </div>
      ),
    },
    {
      title: "Item Cost",
      Content: (item, index) => (
        <div>
          {UnsignedNumericInput({
            value: toF(+item?.sup_cost, 2, true),
            step: "0.01",
            min: "0.00",
            name: `sup_cost-${index}`,
            index,
            inputKey: "sup_cost",
          })}
        </div>
      ),
    },
    {
      title: "Shipping Fee",
      Content: (item, index) => (
        <div>
          {UnsignedNumericInput({
            value: toF(+item?.sup_shipping, 2, true),
            step: "0.01",
            min: "0.00",
            name: `sup_shipping-${index}`,
            index,
            inputKey: "sup_shipping",
          })}
        </div>
      ),
    },
    {
      title: "Tax Fee",
      Content: (item, index) => (
        <div>
          {UnsignedNumericInput({
            value: toF(+item?.sup_tax, 2, true),
            step: "0.01",
            min: "0.00",
            name: `sup_tax-${index}`,
            index,
            inputKey: "sup_tax",
          })}
        </div>
      ),
    },
  ];

  return (
    <Modal size="lg" isOpen={updateCOGSModalIsOpen}>
      <div className="modal-header card-text font-size-20 p-3 let-modal">
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="font-weight-bold">Update COGS</span>
          <i className="bx bx-sm bx-x inventory-color cursor-pointer" onClick={toggleStrategyModal} />
        </div>
      </div>
      <div className="modal-body p-4 let-modal">
        <RenderIf isTrue={error}>
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        </RenderIf>
        <div className="d-flex align-items-center text-primary font-size-13 mb-4">
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  {$.map(updateCOGSHeader, (header, index) => (
                    <th key={`order-items-column-${index}`} className={header.isAlignmentDefault ? "" : "text-center"}>
                      {header.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {$.map(updatedItems, (item, index) => (
                  <tr key={`_tr_${index}`}>
                    {$.map(updateCOGSHeader, (header, colIndex) => (
                      <td key={`table-col-${colIndex}`}>{header.Content(item, index)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr className="m-0 mb-3 w-100" />
        <div className="font-weight-bold font-size-16 d-flex justify-content-end gap-1 inventory-color">
          Total:
          <span className="text-success">{amountText($.sumBy(updatedItems, estCostPredicate))}</span>
        </div>
      </div>
      <div className="modal-footer p-3 let-modal">
        <PillBtn
          className="mr-2"
          title="Cancel"
          name="Cancel"
          color="primary"
          outline={true}
          onClick={toggleStrategyModal}
        />
        <PillBtn
          className="mr-2"
          disabled={!isValueUpdated}
          title="Submit"
          name="Submit"
          color="primary"
          onClick={updateItem}
        />
      </div>
    </Modal>
  );
};

export default UpdateCOGSModal;
