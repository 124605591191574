export const FETCH_ALL_GRAPHS = "FETCH_ALL_GRAPHS";
export const FETCH_ALL_GRAPHS_SUCCESS = "FETCH_ALL_GRAPHS_SUCCESS";
export const FETCH_FEES_DETAILS = "FETCH_FEES_DETAILS";
export const FETCH_FEES_DETAILS_SUCCESS = "FETCH_FEES_DETAILS_SUCCESS";
export const FETCH_ALL_TENANT_GRAPHS = "FETCH_ALL_TENANT_GRAPHS";
export const FETCH_ALL_TENANT_GRAPHS_SUCCESS = "FETCH_ALL_TENANT_GRAPHS_SUCCESS";
export const FETCH_PRODUCTS_BY_SALES = "FETCH_PRODUCTS_BY_SALES";
export const FETCH_PRODUCTS_BY_SALES_SUCCESS = "FETCH_PRODUCTS_BY_SALES_SUCCESS";
export const FETCH_PRODUCTS_BY_CATEGORY = "FETCH_PRODUCTS_BY_CATEGORY";
export const FETCH_PRODUCTS_BY_CATEGORY_SUCCESS = "FETCH_PRODUCTS_BY_CATEGORY_SUCCESS";
export const FETCH_ORDER_GRAPH_DETAILS = "FETCH_ORDER_GRAPH_DETAILS";
export const FETCH_ORDER_GRAPH_DETAILS_SUCCESS = "FETCH_ORDER_GRAPH_DETAILS_SUCCESS";
export const FETCH_ALL_TENANT_ORDER_GRAPH = "FETCH_ALL_TENANT_ORDER_GRAPH";
export const FETCH_ALL_TENANT_ORDER_GRAPH_SUCCESS = "FETCH_ALL_TENANT_ORDER_GRAPH_SUCCESS";
export const FETCH_SALES_GRAPH_DETAILS = "FETCH_SALES_GRAPH_DETAILS";
export const FETCH_SALES_GRAPH_DETAILS_SUCCESS = "FETCH_SALES_GRAPH_DETAILS_SUCCESS";
export const FETCH_ALL_TENANT_SALES_GRAPH = "FETCH_ALL_TENANT_SALES_GRAPH";
export const FETCH_ALL_TENANT_SALES_GRAPH_SUCCESS = "FETCH_ALL_TENANT_SALES_GRAPH_SUCCESS";
export const FETCH_PROFIT_GRAPH_DETAILS = "FETCH_PROFIT_GRAPH_DETAILS";
export const FETCH_PROFIT_GRAPH_DETAILS_SUCCESS = "FETCH_PROFIT_GRAPH_DETAILS_SUCCESS";
export const FETCH_ALL_TENANT_PROFIT_GRAPH = "FETCH_ALL_TENANT_PROFIT_GRAPH";
export const FETCH_ALL_TENANT_PROFIT_GRAPH_SUCCESS = "FETCH_ALL_TENANT_PROFIT_GRAPH_SUCCESS";
export const FETCH_SHARED_PROFIT_GRAPH_DETAILS = "FETCH_SHARED_PROFIT_GRAPH_DETAILS";
export const FETCH_SHARED_PROFIT_GRAPH_DETAILS_SUCCESS = "FETCH_SHARED_PROFIT_GRAPH_DETAILS_SUCCESS";
export const FETCH_SHARED_ORDER_STATUS = "FETCH_SHARED_ORDER_STATUS";
export const FETCH_SHARED_ORDER_STATUS_SUCCESS = "FETCH_SHARED_ORDER_STATUS_SUCCESS";
export const FETCH_DAILY_REPORT = "FETCH_DAILY_REPORT";
export const FETCH_DAILY_REPORT_SUCCESS = "FETCH_DAILY_REPORT_SUCCESS";
export const FETCH_SALES_REVENUE_STATS = "FETCH_SALES_REVENUE_STATS";
export const FETCH_SALES_REVENUE_STATS_SUCCESS = "FETCH_SALES_REVENUE_STATS_SUCCESS";
export const API_ERROR = "GRAPH_DETAILS_ERROR";
export const FETCH_EXPENSES_GRAPH_DETAILS = "FETCH_EXPENSES_GRAPH_DETAILS";
export const FETCH_EXPENSES_GRAPH_DETAILS_SUCCESS = "FETCH_EXPENSES_GRAPH_DETAILS_SUCCESS";
export const FETCH_ALL_TENANT_EXPENSE_GRAPH = "FETCH_ALL_TENANT_EXPENSE_GRAPH";
export const FETCH_ALL_TENANT_EXPENSE_GRAPH_SUCCESS = "FETCH_ALL_TENANT_EXPENSE_GRAPH_SUCCESS";
export const FETCH_RETURNS_GRAPH_DETAILS = "FETCH_RETURNS_GRAPH_DETAILS";
export const FETCH_RETURNS_GRAPH_DETAILS_SUCCESS = "FETCH_RETURNS_GRAPH_DETAILS_SUCCESS";
export const FETCH_ALL_TENANT_RETURNS_GRAPH = "FETCH_ALL_TENANT_RETURNS_GRAPH";
export const FETCH_ALL_TENANT_RETURNS_GRAPH_SUCCESS = "FETCH_ALL_TENANT_RETURNS_GRAPH_SUCCESS";
export const FETCH_USED_CREDITS_DETAILS = "FETCH_USED_CREDITS_DETAILS";
export const FETCH_USED_CREDITS_DETAILS_SUCCESS = "FETCH_USED_CREDITS_DETAILS_SUCCESS";
export const FETCH_ALL_TENANT_USED_CREDITS_DETAILS = "FETCH_ALL_TENANT_USED_CREDITS_DETAILS";
export const FETCH_ALL_TENANT_USED_CREDITS_DETAILS_SUCCESS = "FETCH_ALL_TENANT_USED_CREDITS_DETAILS_SUCCESS";
export const FETCH_ISSUE_DETAILS = "FETCH_ISSUE_DETAILS";
export const FETCH_ISSUE_DETAILS_SUCCESS = "FETCH_ISSUE_DETAILS_SUCCESS";
export const FETCH_BEST_SELLERS_DATA = "FETCH_BEST_SELLERS_DATA";
export const FETCH_BEST_SELLERS_DATA_SUCCESS = "FETCH_BEST_SELLERS_DATA_SUCCESS";
export const FETCH_LISTING_DETAILS = "FETCH_LISTING_DETAILS";
export const FETCH_LISTING_DETAILS_SUCCESS = "FETCH_LISTING_DETAILS_SUCCESS";
export const FETCH_BUYBOX_DETAILS = "FETCH_BUYBOX_DETAILS";
export const FETCH_BUYBOX_DETAILS_SUCCESS = "FETCH_BUYBOX_DETAILS_SUCCESS";
export const FETCH_ERROR_STATS = "FETCH_ERROR_STATS";
export const FETCH_ERROR_STATS_SUCCESS = "FETCH_ERROR_STATS_SUCCESS";
export const FETCH_TOP_SELLING_ITEMS = "FETCH_TOP_SELLING_ITEMS";
export const FETCH_TOP_SELLING_ITEMS_SUCCESS = "FETCH_TOP_SELLING_ITEMS_SUCCESS";
export const FETCH_GLOBAL_TOP_SELLING_ITEMS = "FETCH_GLOBAL_TOP_SELLING_ITEMS";
export const FETCH_GLOBAL_TOP_SELLING_ITEMS_SUCCESS = "FETCH_GLOBAL_TOP_SELLING_ITEMS_SUCCESS";
export const FETCH_LEAST_SELLING_ITEMS = "FETCH_LEAST_SELLING_ITEMS";
export const FETCH_LEAST_SELLING_ITEMS_SUCCESS = "FETCH_LEAST_SELLING_ITEMS_SUCCESS";
export const FETCH_CLIENTS_STATS = "FETCH_CLIENTS_STATS";
export const FETCH_CLIENTS_STATS_SUCCESS = "FETCH_CLIENTS_STATS_SUCCESS";
