import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderIf } from "../../../utils/common";
import { Spinner } from "reactstrap";

// Actions
import { updateAgencyName } from "../../../store/actions";

const mw100 = { width: "150px" };
const StringEditor = ({ id, string, isEditable, setIsEditable, hideString, setAgencyNameIndex, type }) => {
  const dispatch = useDispatch();
  const { success, acgencyNameUpdateloading } = useSelector((state) => state.Settings);
  const [itemPrice, setItemPrice] = useState("");

  useEffect(() => {
    if (success) {
      setIsEditable(false);
      type && setAgencyNameIndex(undefined);
    }
  }, [success]);

  const updateAgency = (_) => {
    dispatch(updateAgencyName(id, { agency_name: itemPrice }, type));
  };
  return (
    <React.Fragment>
      <RenderIf
        isTrue={isEditable}
        fallback={
          <span style={mw100}>
            {!hideString && string}
            <i
              className={`bx bx-pencil cursor-pointer ${!type && "ml-3"}`}
              onClick={(_) => {
                setIsEditable(true);
                setItemPrice(string);
                type && setAgencyNameIndex(id);
              }}
            ></i>
          </span>
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="text"
            value={itemPrice}
            className="form-control"
            onChange={(e) => setItemPrice(e.target.value)}
          ></input>
          <RenderIf
            isTrue={acgencyNameUpdateloading}
            fallback={
              <div className="iconBox">
                <i className="bx bx-sm bx-check color-primary cursor-pointer" onClick={updateAgency} />
                <i
                  className="bx bx-sm bx-x danger cursor-pointer"
                  onClick={() => {
                    setItemPrice("");
                    setIsEditable(false);
                    type && setAgencyNameIndex(0);
                  }}
                />
              </div>
            }
          >
            <Spinner color="primary" style={{ width: "20px", height: "20px" }} />
          </RenderIf>
        </div>
      </RenderIf>
    </React.Fragment>
  );
};

export default StringEditor;
