import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_WAREHOUSE_USER,
  LOGIN_WAREHOUSE_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  GET_OAUTH_APP_CREDS,
  GET_OAUTH_APP_CREDS_SUCCESS,
  CHECK_TOKEN_EXPIRY,
  CHECK_TOKEN_EXPIRY_SUCCESS,
  INTEGRATE_WITH_REPICER,
  INTEGRATE_WITH_REPRICER_SUCCESS,
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const loginUser = (user, history, queryParams) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, queryParams },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginWareHouseUser = (user, history) => {
  return {
    type: LOGIN_WAREHOUSE_USER,
    payload: { user, history },
  };
};

export const loginWareHouseUserSuccess = (user) => {
  return {
    type: LOGIN_WAREHOUSE_USER_SUCCESS,
    payload: user,
  };
};

export const getOAuthAppCreds = (clientId, redirectUrl) => {
  return {
    type: GET_OAUTH_APP_CREDS,
    payload: { clientId, redirectUrl },
  };
};

export const getOAuthAppCredsSuccess = (appCreds) => {
  return {
    type: GET_OAUTH_APP_CREDS_SUCCESS,
    payload: appCreds,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const checkTokenExpiry = () => {
  return {
    type: CHECK_TOKEN_EXPIRY,
  };
};

export const checkTokenExpirySuccess = () => {
  return {
    type: CHECK_TOKEN_EXPIRY_SUCCESS,
  };
};

export const integrateWithRepricer = (data) => {
  return {
    type: INTEGRATE_WITH_REPICER,
    payload: data,
  };
};

export const integrateWithRepricerSuccess = (bool, errors) => {
  return {
    type: INTEGRATE_WITH_REPRICER_SUCCESS,
    payload: { bool, errors },
  };
};

export const generateOtp = () => {
  return { type: GENERATE_OTP };
};

export const generateOtpSuccess = (res) => {
  return { type: GENERATE_OTP_SUCCESS, payload: res };
};

export const verifyOtp = (data, history) => {
  return { type: VERIFY_OTP, payload: { data, history } };
};

export const verifyOtpSuccess = () => {
  return { type: VERIFY_OTP_SUCCESS };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
