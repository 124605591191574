import React, { useEffect } from "react";
import moment from "moment-timezone";
import queryString from "query-string";
import Helmet from "react-helmet";
import { Switch, Redirect, BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
// import Theme from "./Theme";

import withClearCache from "./clearCache";

// User Abilities for Visiting URL's
import { callCanonical, getUrlPrefix, getUserInfo, isShowWHPages } from "./utils/common";
import { abilitiesFor } from "./utils/abilities";
import "./assets/scss/common.scss";
import { informSlack } from "./api/NotificationEmails";
import { pick, map, startCase, isEmpty, flatten, compact, keyBy } from "lodash";
import Page500 from "./components/page-500";
import { DEFAULT_TZ } from "./constants";
import { switchTheme } from "./store/layout/actions";

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError (error) {
    console.log("error:", error);
    return { hasError: true };
  }

  componentDidCatch (err, info) {
    const isErr = (...arr) => arr.some((x) => String(err).includes(x));
    const rawObj = getUserInfo();
    const obj = pick(rawObj, "account_id", "role");
    if (isEmpty(obj)) {
      const username = document.querySelector("input[name=\"username\"]");
      if (username) obj.username = username.value;
    }
    const logs = [
      `*Error*: ${err}`,
      "*Stack*:",
      info.componentStack.split("\n").slice(0, 3),
      `<${window.location.href}|Error Page URL>`,
      "\n`UserInfo`:",
      rawObj?.user_email ? `<mailto:${rawObj.user_email}|User Email>` : "",
      map(obj, (data, key) => `*${startCase(key)}*: ${data}`),
    ];
    if (
      isErr("ChunkLoadError", "Failed to execute 'removeChild' on 'Node':") ||
      (info.componentStack.includes("Intercom") &&
        isErr("cancelAnimationFrame", "Cannot read properties of null (reading 'document')"))
    ) {
      return;
    }
    informSlack({ text: compact(flatten(logs)).join("\n"), username: "Frontend Alert" });
  }

  render () {
    if (this.state.hasError) return <Page500 />;
    return this.props.children;
  }
}

const mappedRoutes = keyBy(authRoutes, ({ path }) => getUrlPrefix(path));

const App = (props) => {
  useEffect(() => {
    const currentUrl = getUrlPrefix(document.location.pathname);
    if (mappedRoutes[currentUrl] && props.theme !== "light") {
      localStorage.setItem("THEME", "light");
      props.switchTheme("light");
    }
  }, [document.location.pathname]);
  const queryParameters = queryString.parse(document.location.search);
  const history = useHistory();

  const userAbility = abilitiesFor(getUserInfo()?.role);
  moment.tz.setDefault(DEFAULT_TZ);
  const Layout = VerticalLayout;
  const NonAuthmiddleware = ({ component: Component, layout: Layout, path }) => (
    <Route
      path={path}
      render={(props) => {
        if (
          path.includes("/two_factor_verification") &&
          !getUserInfo()?.is_two_fa_enabled &&
          !path.includes(props.location.state?.from?.includes("/login"))
        )
          return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />;
        else if (
          localStorage.getItem("authTokenJwt") &&
          moment.unix(getUserInfo()?.exp).isAfter(moment()) &&
          document.location.href.includes("/login") &&
          Object.keys(queryParameters || {}).length !== 2
        ) {
          return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />;
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  useEffect(() => {
    history.listen(() => history.action === "POP" && callCanonical());
  }, []);

  return (
    <ErrorBoundary>
      <div className={props.theme}>
        <Helmet
          bodyAttributes={
            props.theme === "light" ? { style: "background-color : #fff" } : { style: "background-color : #222736" }
          }
        ></Helmet>
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <NonAuthmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx} />
            ))}
            {userRoutes
              .filter((x) => userAbility.can("visit", x.path.split("/")[1]))
              .map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  title={route.title}
                  showLayout={route.showLayout}
                  component={route.component}
                  key={idx}
                  roles={route.roles}
                  allowUsers={route.allowUsers}
                  allowedUserIds={route.allowedUserIds}
                  isTwoStep={props.isTwoStep}
                  isShowReturn={props.isShowReturn}
                />
              ))}
          </Switch>
        </Router>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  const isTwoStep = state.Settings.account?.data?.two_step_feature;
  const mpAccounts = state.MarketPlaceAccount.accounts?.data;
  return {
    layout: state.Layout,
    theme: state.Layout.appTheme,
    isTwoStep: isTwoStep?.constructor === Boolean ? isTwoStep : true,
    planMetaData: state.Settings.account?.data?.plan_metadata?.metadata,
    isShowReturn: Array.isArray(mpAccounts) ? isShowWHPages(mpAccounts, "return_wh_id") : true,
  };
};

const ClearCacheComponent = process.env.NODE_ENV === "production" ? withClearCache(App) : App;

export default connect(mapStateToProps, { switchTheme })(ClearCacheComponent);
