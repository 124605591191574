import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderIf } from "../../../../utils/common";
import { Spinner } from "reactstrap";

// Actions
import { updateInventoryItemPrice } from "../../../../store/actions";

const toF = (num) => parseFloat(parseFloat(num).toFixed(2));
const mw100 = { width: "150px" };
const TableFieldEditabel = ({ amountText, item, itemKey, setItemKey, loaderKey, setLoaderKey }) => {
  const dispatch = useDispatch();
  const { success, inventoryPriceUpdateloading } = useSelector((state) => state.WareHouses);
  const [itemPrice, setItemPrice] = useState("");

  useEffect(() => {
    if (success) setItemKey("");
  }, [success]);

  const updatePrice = (_) => {
    setLoaderKey({ type: "price", id: itemKey });
    dispatch(updateInventoryItemPrice(itemKey, { price: itemPrice, sku: item.sku, account_id: item.account_id }));
  };
  return (
    <td className="text-center" style={mw100}>
      <RenderIf
        isTrue={itemKey === item?.id}
        fallback={
          <span style={mw100}>
            {amountText(item?.price)}
            <i
              className="ml-3 bx bx-pencil cursor-pointer"
              onClick={(_) => {
                setItemKey(item.id);
                setItemPrice(item.price);
                setLoaderKey({});
              }}
            ></i>
          </span>
        }
      >
        <div className="weightInput d-flex align-items-center justify-content-center">
          <input
            type="number"
            value={itemPrice}
            step="0.01"
            min="0.01"
            className="form-control"
            onChange={(e) => setItemPrice(toF(e.target.value))}
          ></input>
          <RenderIf
            isTrue={inventoryPriceUpdateloading && loaderKey.type === "price" && loaderKey.id === itemKey}
            fallback={
              <div className="iconBox">
                <i className="bx bx-sm bx-check color-primary cursor-pointer" onClick={updatePrice} />
                <i
                  className="bx bx-sm bx-x danger cursor-pointer"
                  onClick={() => {
                    setItemPrice("");
                    setItemKey("");
                    setLoaderKey({});
                  }}
                />
              </div>
            }
          >
            <Spinner color="primary" style={{ width: "20px", height: "20px" }} />
          </RenderIf>
        </div>
      </RenderIf>
    </td>
  );
};

export default TableFieldEditabel;
