import React from "react";
import * as $ from "lodash";
import logoLight from "../../../assets/svg/logo.svg";
import LeftSideImg from "../../../assets/images/left-side-img.svg";
import queryString from "query-string";
import { RenderIf, isSignUpFromExtension, isSignUpFromSubApp, isSignUpFromSubApps } from "../../../utils/common";
import { SIGN_UP_SERVICES } from "../../../constants";

const StatsInfo = ({ value, title }) => (
  <div className=" seller-info d-flex flex-column align-items-center p-2">
    <span className="text-success">{value}</span>
    <span>{title}</span>
  </div>
);

export default function StatsPanel () {
  const queryParams = queryString.parse(document.location.search);
  const isAgencyRegistration = queryParams?.type === "agency";
  const isSignUpOnRepricer = isSignUpFromSubApp(queryParams, SIGN_UP_SERVICES.repricer);
  const getExtensionName = () => {
    const [prefix, suffix] = $.split(SIGN_UP_SERVICES[queryParams?.signup_on], "_");
    return [$.upperCase(prefix), $.startCase(suffix)].join(" ");
  };
  return (
    <div className={`col-md-6 left-side-overlay ${isSignUpOnRepricer ? "d-flex flex-column align-items-center" : ""}`}>
      <span className="logo-lg">
        <a href="https://ecomcircles.com/" target="_blank" rel="noreferrer">
          <img id="logo_image" src={logoLight} alt="EC logo" height="45" title="EcomCircles" />
        </a>
      </span>
      <h3 className="mt-3">
        <RenderIf
          isTrue={isSignUpFromSubApps(queryParams) || isAgencyRegistration}
          fallback={
            <>
              <span>We help </span>
              <span className="text-primary">Amazon, Walmart, & Shopify Sellers</span> start &amp; grow their{" "}
              <span className="text-primary">Ecommerce Businesses</span>
            </>
          }
        >
          <RenderIf
            isTrue={isSignUpFromExtension(queryParams) || isAgencyRegistration}
            fallback={
              <span>
                Sign Up to <span className="text-primary">Start</span> Your 14-Day Trial
              </span>
            }
          >
            <span>
              {isAgencyRegistration ? (
                <span>
                  Thanks for using Ecom<span className="text-primary">Circles</span> for your Agency.
                </span>
              ) : (
                "Thanks for installing our "
              )}
              <RenderIf
                isTrue={isSignUpFromSubApp(queryParams, SIGN_UP_SERVICES.extension)}
                fallback={!isAgencyRegistration ? <span className="text-primary">{getExtensionName()}! </span> : null}
              >
                Ecom<span className="text-primary">Circles</span> Extension!{" "}
              </RenderIf>
              <div className="mt-3">Please complete your free registration to finish the extension set up.</div>
            </span>
          </RenderIf>
        </RenderIf>
      </h3>
      <span className={`create-account-text card-title font-size-16 mt-3 ${isSignUpOnRepricer ? "text-center" : ""}`}>
        Create a free account on the world's leading all-in-one Ecommerce Management Software & Services provider.
      </span>
      <div className="stats-info-wrapper d-flex justify-content-center card-text font-size-16">
        <StatsInfo value="4K +" title="Sellers Using Our Tools" />
        <StatsInfo value="1.63B" title="Listings Price Changes" />
        <StatsInfo value="430K" title="Orders Processed" />
        <StatsInfo value="$41.31M" title="Member Sales" />
      </div>
      <h3 className="d-flex join-us-text justify-content-center">
        <span>Join&nbsp;</span>
        <span className="text-primary">Thousands of Sellers&nbsp;</span>
        <span>in our Community</span>
      </h3>
      <img className="d-flex justify-content-center left-side-img" src={LeftSideImg} />
    </div>
  );
}
