import { useFormik } from "formik";
import { withRouter } from "react-router-dom";
import { entries, fromPairs, map, merge, pick, startCase, forEach, size, values } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Button, FormGroup, Row, Spinner } from "reactstrap";
import Select from "react-select";
import "flatpickr/dist/themes/dark.css";
import { RenderIf, getUserInfo, hasFullAccessOfAnyPlan, isUserHaveFullAccess } from "../../utils/common";
import { DATE_FORMATS, REPORT_TYPES, USER_ROLES } from "../../constants";
import {
  genTransactionReport,
  genOrderDetailsReport,
  genSettlementReport,
  genOrdersReport,
  genInventoryReport,
  genInventoryInsight,
  genReturnsReport,
  genWhLabelReport,
  toggleReportModal as toggle,
} from "../../store/actions";
import { resetState } from "../../store/reports/actions";

import Modal from "./Modal";
import ReportDownloadMsg from "./ReportDownloadMsg";
import TransactionReportForm from "./ReportForms/Transaction";
import OrderDetailReportForm from "./ReportForms/OrderDetails";
import SettlementReportForm from "./ReportForms/Settlement";
import OrdersReportForm from "./ReportForms/Orders";
import InventoryReportForm from "./ReportForms/Inventory";
import WarehouseLabelReportForm from "./ReportForms/WarehouseLabel";
const initReportType = { label: "Select a report", value: "" };
const _mapDates = (values) =>
  fromPairs(
    map(pick(values, "start_date", "end_date"), (date, key) => [key, moment(date).format(DATE_FORMATS.BACKEND)]),
  );

const genInitialValues = (_) => ({
  start_date: moment().subtract(6, "days").startOf("days").toDate(),
  end_date: moment().startOf("days").toDate(),
  storeOrderId: "",
  reportId: "",
  store: "",
  marketplace: "",
  lost_orders: false,
  warehouse: "",
  account: "",
});

const ReportModal = (props) => {
  const [reportType, setReportType] = useState(initReportType);
  const [state, setState] = useState({});

  const isTransactional = reportType.label === REPORT_TYPES.transaction;
  const isInsightReport = reportType.label === REPORT_TYPES.inventory_insights;
  const isOrderDetails = reportType.label === REPORT_TYPES.order_detail;
  const isSettlement = reportType.label === REPORT_TYPES.settlement;
  const isOrders = [REPORT_TYPES.store_front, REPORT_TYPES.marketplace, REPORT_TYPES.orders].includes(reportType.label);
  const isInventory = !!state.reportType || reportType.label === REPORT_TYPES.inventory;
  const isReturns = reportType.label === REPORT_TYPES.returns;
  const isWhLabel = reportType.label === REPORT_TYPES.wh_label;

  const isFullAccess = isUserHaveFullAccess();
  const isFullAccessOfAnyPlan = hasFullAccessOfAnyPlan();

  const paidNonDSReports = values(pick(REPORT_TYPES, ["order_detail", "orders", "store_front", "marketplace"]));

  const formik = useFormik({
    initialValues: genInitialValues(),
    validate: (values) => {
      const errors = {};
      if (isTransactional) {
        const field = ["start_date", "end_date"].find((key) => !values[key]);
        if (field) errors[field] = `${startCase(field)} is required`;
      } else if (isOrderDetails) {
        if (!values.storeOrderId) errors.storeOrderId = "This field is required";
      } else if (isSettlement) {
        if (!values.reportId?.value) errors.reportId = "Report selection is required";
        if (!values.store?.value) errors.store = "Store selection is required";
      } else if (isOrders) {
        const field = ["start_date", "end_date"].find((key) => !values[key]);
        if (field) errors[field] = `${startCase(field)} is required`;
        if (reportType.label === REPORT_TYPES.store_front && !values.store?.value) {
          errors.store = "Store selection is required";
        } else if (reportType.label === REPORT_TYPES.marketplace && !values.marketplace?.value) {
          errors.marketplace = "Marketplace selection is required";
        }
      } else if (isInventory || isReturns) {
        const field = ["start_date", "end_date"].find((key) => !values[key]);
        if (field) errors[field] = `${startCase(field)} is required`;
        if (!values.warehouse?.value) errors.warehouse = "Warehouse selection is required";
      } else if (isWhLabel) {
        const field = ["start_date", "end_date"].find((key) => !values[key]);
        if (field) errors[field] = `${startCase(field)} is required`;
        if (!values.warehouse?.value) errors.warehouse = "Warehouse selection is required";
        if (!values.account?.value) errors.account = "Account selection is required";
      } else if (isInsightReport) {
        if (!values.warehouse?.value) errors.warehouse = "Warehouse selection is required";
      }
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (isTransactional) {
        props.genTransactionReport({ query: _mapDates(values) });
      } else if (isOrderDetails) {
        props.genOrderDetailsReport({ storeOrderId: values.storeOrderId });
      } else if (isSettlement) {
        props.genSettlementReport({ query: { report_id: values.reportId?.value, partner_id: values.store?.value } });
      } else if (isOrders) {
        const query = merge({}, pick(values, "lost_orders"), _mapDates(values));
        if (reportType.label === REPORT_TYPES.store_front) query.partner_id = values.store?.value;
        else if (reportType.label === REPORT_TYPES.marketplace) query.marketplace = values.marketplace?.value;
        props.genOrdersReport({ query });
      } else if (isInventory) {
        state.func && state.func(false);
        props.genInventoryReport({ query: merge({ warehouse_id: values.warehouse?.value, is_download: !!size(state) }, !size(state) && _mapDates(values)) });
      } else if (isReturns) {
        props.genReturnsReport({ query: merge({ warehouse_id: values.warehouse?.value }, _mapDates(values)) });
      } else if (isWhLabel) {
        props.genWhLabelReport({
          query: merge({ warehouse_id: values.warehouse?.value, account_id: values.account?.value }, _mapDates(values)),
        });
      } else if (isInsightReport) {
        props.genInventoryInsight({
          query: { warehouse_id: values.warehouse?.value },
        });
      }
    },
  });

  useEffect(() => () => setState({}), []);

  useEffect(() => {
    forEach(genInitialValues(), (val, key) => {
      formik.setFieldValue(key, val);
    });
  }, [reportType]);

  useEffect(() => {
    if (props.reportTitle === REPORT_TYPES.inventory) {
      setState({
        reportType: props.reportTitle,
        func: props.updateShowReportModal,
        isOpen: true,
      });
    }
  }, [props.reportTitle]);

  const toggle = () => {
    if (state?.func) return state.func(false);
    props.toggle();
    setReportType(initReportType);
    formik.resetForm();
  };

  return (
    <Modal size={props.size || "md"} isOpen={state.isOpen || props.isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">{!size(state) ? "Generate Report" : "Inventory Report"}</h5>
        <button onClick={toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>

      <div className="modal-body">
        <RenderIf isTrue={props.error && !size(state)}>
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {props.error}
          </Alert>
        </RenderIf>

        <RenderIf isTrue={props.success && !size(state)}>
          <ReportDownloadMsg action={toggle} />
        </RenderIf>

        <RenderIf isTrue={!props.success || size(state)}>
          <div className="m-2">
            <RenderIf isTrue={!size(state)}>
              <FormGroup className="select2-container mt-3" style={{ minWidth: "300px" }} id="switch_users">
                <Select
                  value={reportType}
                  onChange={(state) => setReportType(state)}
                  options={entries(REPORT_TYPES)
                    .filter(([, report]) => {
                      if (!isFullAccess && isFullAccessOfAnyPlan) return paidNonDSReports.includes(report);
                      else
                        return report !== REPORT_TYPES.inventory_insights || getUserInfo()?.role === USER_ROLES.admin;
                    })
                    .map(([value, label]) => ({ value, label }))}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </RenderIf>
            <RenderIf
              isTrue={isInsightReport}
              children={<InventoryReportForm data={state} formik={formik} isInsight={isInsightReport} />}
            />
            <RenderIf isTrue={isTransactional} children={<TransactionReportForm formik={formik} />} />
            <RenderIf isTrue={isOrderDetails} children={<OrderDetailReportForm formik={formik} />} />
            <RenderIf isTrue={isSettlement} children={<SettlementReportForm formik={formik} />} />
            <RenderIf isTrue={isWhLabel} children={<WarehouseLabelReportForm formik={formik} />} />
            <RenderIf
              isTrue={[isReturns, isInventory].some((x) => x)}
              children={<InventoryReportForm data={state} formik={formik} isReturns={isReturns} />}
            />
            <RenderIf isTrue={isOrders} children={<OrdersReportForm formik={formik} reportType={reportType.label} />} />
          </div>
        </RenderIf>

        <Row className="d-flex justify-content-end mt-3">
          <RenderIf isTrue={props.success && reportType?.value}>
            <Button
              color="primary"
              className="mr-3 d-flex"
              onClick={(_) => {
                setReportType(initReportType);
                formik.resetForm();
                props.resetState();
              }}
            >
              Generate Again
            </Button>
          </RenderIf>
          <RenderIf isTrue={!props.success || size(state)}>
            <Button
              type="submit"
              color="success"
              className="mr-3 d-flex"
              onClick={formik.handleSubmit}
              disabled={!reportType?.value && !size(state)}
            >
              Submit
              <RenderIf
                isTrue={props.loading}
                children={<Spinner color="secondary" style={{ width: "20px", height: "20px" }} className="ml-3" />}
              />
            </Button>
          </RenderIf>
        </Row>
      </div>
    </Modal>
  );
};

const mapStatetoProps = (state) => {
  const { downloadModal } = state.Report;
  return merge({}, pick(state.Report, "success", "error", "loading"), { isOpen: downloadModal });
};

export default withRouter(
  connect(mapStatetoProps, {
    genTransactionReport,
    genOrderDetailsReport,
    genSettlementReport,
    resetState,
    genOrdersReport,
    genInventoryReport,
    genInventoryInsight,
    genReturnsReport,
    genWhLabelReport,
    toggle,
  })(ReportModal),
);
