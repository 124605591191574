import request from "../restClient";
import { makeQP } from "../../utils/common";

const resource = "/payment";

export const stripeChargeCustomer = async (data) => {
  const url = resource + "/charge";
  return await request({ url, method: "POST", body: data });
};

export const getStripeProducts = async () => {
  const url = resource + "/product_list";
  return await request({ url, method: "GET" });
};

export const getCustomerHistory = async ({ startingAfter, startDate, endDate }) => {
  const url =
    resource +
    `/charge_history?starting_after=${encodeURIComponent(startingAfter)}&start_date=${encodeURIComponent(
      startDate,
    )}&end_date=${encodeURIComponent(endDate)}`;
  return await request({ url, method: "GET" });
};

export const updatePaymentSource = async (body) => {
  const url = resource + "/sources";
  return await request({ url, method: "PUT", body });
};

export const deletePaymentSource = async (body) => {
  const url = resource + "/sources";
  return await request({ url, method: "DELETE", body });
};

export const addNewPaymentSource = async (body) => {
  const url = resource + "/sources";
  return await request({ url, method: "POST", body });
};

export const fetchPaymentSources = async (params) => {
  const url = resource + "/sources" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const fetchSubscriptionPlans = async () => {
  const url = resource + "/subscription/plans";
  return await request({ url, method: "GET" });
};

export const createSubscription = async (id) => {
  const url = resource + `/subscription/${id}`;
  return await request({ url, method: "POST" });
};

export const cancelSubscription = async (id) => {
  const url = resource + "/subscription";
  return await request({ url, method: "DELETE" });
};

export const updateSubscription = async (id, body) => {
  const url = resource + `/subscription/${id}`;
  return await request({ url, method: "PUT", body });
};

export const payByInvoice = async (invoice) => {
  const url = resource + `/pay_by_invoice/${invoice}`;
  return await request({ url, method: "POST" });
};
