import { isAgency, httpEncode } from "../../utils/common";
import request from "../restClient";
const resource = "/items";
const agenctResource = "/agency_items";

export const getAllRestrictedItems = async (params = {}) => {
  const url =
    (isAgency() ? agenctResource : resource) +
    "?" +
    Object.keys(params)
      .map(
        (key) => `${key}=${typeof params[key] === "object" ? httpEncode(params[key], true) : httpEncode(params[key])}`,
      )
      .join("&");
  return await request({ url, method: "GET" });
};

export const bulkUpdateRestrictedItems = async (data) => {
  const url = `${isAgency() ? agenctResource : resource}/bulk_update_restricted_status`;
  return await request({ url, method: "POST", body: data });
};

export const addNewItem = async (data) => {
  const url = `${isAgency() ? agenctResource : resource}`;
  return await request({ url, method: "POST", body: data });
};

export const bulkAddNewItems = async (data) => {
  const url = `${agenctResource}/bulk_add_restricted_items/${data.platform}`;
  const formData = new FormData();
  formData.append("csvFile", data.file, "csvFile.csv");
  return await request({ url, method: "POST", formData });
};

export const updateItem = async ({ data, id }) => {
  const url = `${isAgency() ? agenctResource : resource}/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const deleteItem = async ({ data, id }) => {
  const url = isAgency() ? agenctResource : `${resource}/${id}`;
  if (isAgency()) data.id = id;
  if (isAgency() && !id?.length) data.delete_all = true;
  return await request({ url, method: "DELETE", body: data });
};
