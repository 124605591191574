import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Button, Row, Col, Alert, Tooltip } from "reactstrap";
import {
  currentUser,
  capitalize,
  getUserInfo,
  removeURLQueryParams,
  RenderIf,
  isUserHaveFullAccess,
} from "../../../utils/common";
import { AvForm } from "availity-reactstrap-validation";
import Facebook from "../../../assets/svg/Facebook-icon.svg";
import profileImg from "../../../assets/images/profile-img.webp";
import Amazon from "../../../assets/svg/amazon.svg";
import Walmart from "../../../assets/svg/walmart-single.svg";
import AmazonBlack from "../../../assets/svg/amazon-black.svg";
import Shopify from "../../../assets/svg/shopify.svg";
// redux actions
import {
  logoutUser,
  updateOnboardingSettings,
  setPreloader,
  setIsAmz,
  setSPAPIErr,
  fetchToken,
  setIsShopify,
} from "../../../store/actions";

// import images
import logoLight from "../../../assets/svg/logo.svg";
import logoDark from "../../../assets/svg/logoDark.svg";
import Label from "reactstrap/lib/Label";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import { onboardingStepsIds, TERMS_OF_CONDITION_URL, PRIVACY_URL } from "../../../constants";
import CheckBox from "../../../components/Common/CheckBox";

const Welcome = (props) => {
  const dispatch = useDispatch();
  const { loading, tenantsData, addRepricerStore } = useSelector((state) => state.Settings);
  const { accounts, isAmz } = useSelector((state) => state.MarketPlaceAccount);
  const logo = localStorage.getItem("THEME") === "light" ? logoLight : logoDark;
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function handleSubmitAmazon () {
    props.setActiveStep(onboardingStepsIds.amazonSetUp);
    if (!isAmz) dispatch(setIsAmz(true));
    removeURLQueryParams();
    dispatch(setSPAPIErr(""));
  }
  function fetchTenantToken (email, params) {
    dispatch(fetchToken(email, {}, params));
  }

  function handleSubmitWalmart () {
    props.setActiveStep(onboardingStepsIds.walmartsetUp);
  }

  function handleSubmitShopify () {
    props.setActiveStep(onboardingStepsIds.shopifysetUp);
    dispatch(setIsShopify(true));
    removeURLQueryParams();
  }

  function handleSubmitFacebook () {
    props.setActiveStep(onboardingStepsIds.facebooksetUp);
  }

  function handleTOSCheck () {
    dispatch(updateOnboardingSettings({ tos_accepted: true }));
  }

  useEffect(() => {
    setPreloader(loading);
  }, [loading]);

  const userInfo = getUserInfo();
  const parentAgency = tenantsData[userInfo?.agency_id];

  const DoItLaterBtn = ({ onClick }) => (
    <div className="text-center">
      <Button color="primary" className="mt-4 m-1 w-25" onClick={() => {
        if (props?.account?.data?.payment_source_added || !isUserHaveFullAccess()) {
          props.setIsOpen(false);
          dispatch(setIsAmz(false));
        } else props.setActiveStep(onboardingStepsIds.payment);

        onClick && onClick();
      }}>
        Do it Later
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      <Card className="mb-0">
        <Row className="welcome-modal">
          <Col>
            <div className="p-4 d-flex justify-content-between">
              <img src={logo} alt={"Ecom Circles"} height="31"></img>
              <RenderIf isTrue={!isUserHaveFullAccess()}>
                <i
                  className="bx bx-x cross-icon"
                  onClick={() => {
                    if (props?.account?.data?.payment_source_added || !isUserHaveFullAccess()) {
                      props.setIsOpen(false);
                      dispatch(setIsAmz(false));
                    } else props.setActiveStep(onboardingStepsIds.payment);
                  }}
                ></i>
              </RenderIf>
            </div>
            <hr />
            <div className="p-4">
              <div className="welcome-banner p-3 d-flex justify-content-between">
                <div>
                  <h1>Welcome to Ecom Circles, {currentUser() && capitalize(currentUser())}!</h1>
                  <p>Let's start by connecting your first storefront! Choose a channel to connect with.</p>
                </div>
                <img src={profileImg} alt="no-user" />
              </div>
              {!props?.account?.data?.onboard?.tos_accepted && (
                <AvForm className="needs-validation" onValidSubmit={handleTOSCheck}>
                  <Row className="pt-1">
                    <Col lg={12}>
                      <AvGroup>
                        <CheckBox
                          name="condition"
                          label={
                            <Label>
                              Agree with
                              <a target="_blank" rel="noopener noreferrer" href={TERMS_OF_CONDITION_URL}>
                                {" "}
                                Terms of Condition{" "}
                              </a>
                              and
                              <a target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
                                {" "}
                                Privacy Policy{" "}
                              </a>
                            </Label>
                          }
                          state={termsAgreed}
                          setState={setTermsAgreed}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Button
                        id={"terms-submission"}
                        color="primary"
                        className={`mt-4 m-1 ${!termsAgreed && "cursor-not-allowed"}`}
                        type="submit"
                        disabled={!termsAgreed}
                      >
                        Let"s Get Started
                      </Button>
                      <Tooltip
                        isOpen={tooltipOpen}
                        placement="top"
                        target={"terms-submission"}
                        toggle={(e) => e.target.disabled && setTooltipOpen((prevState) => !prevState)}
                      >
                        You must agree to continue.
                      </Tooltip>
                      {props.onBoarding === undefined && (
                        <Button
                          color="primary"
                          className="mt-4 m-1"
                          onClick={() => dispatch(logoutUser(props.history))}
                        >
                          Log Out
                        </Button>
                      )}
                    </Col>
                  </Row>
                </AvForm>
              )}
              {props?.account?.data?.onboard?.tos_accepted && (
                <>
                  <Row>
                    <Col lg={12}>
                      <h5 className="pt-3">Select a platform to connect to Ecom Circles</h5>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center mb-3 mt-2">
                    <div className="d-flex align-items-center flex-wrap justify-content-center gap-4 w-100">
                      <div className="selected-store mb-2" onClick={handleSubmitAmazon}>
                        <img src={localStorage.getItem("THEME") === "light" ? AmazonBlack : Amazon} alt="no-store" />
                        <span>Amazon</span>
                      </div>
                      <div className="selected-store mb-2" onClick={handleSubmitWalmart}>
                        <img src={Walmart} alt="no-store" />
                        <span>Walmart</span>
                      </div>
                      <RenderIf isTrue={isUserHaveFullAccess()}>
                        <div className="selected-store mb-2" onClick={handleSubmitShopify}>
                          <img src={Shopify} alt="no-store" />
                          <span>Shopify</span>
                        </div>
                      </RenderIf>
                      <RenderIf isTrue={userInfo?.account_id === 2 && !addRepricerStore}>
                        <div className="selected-store mb-2" onClick={handleSubmitFacebook}>
                          <img src={Facebook} alt="no-store" />
                          <span>Facebook</span>
                        </div>
                      </RenderIf>
                    </div>
                  </div>
                  <div className="text-center">
                    <RenderIf
                      isTrue={
                        !(accounts?.data || accounts?.data?.length > 0) &&
                        !userInfo?.isAgencySwitch &&
                        isUserHaveFullAccess()
                      }
                    >
                      <Button
                        color="primary"
                        className="mt-4 m-1 w-25"
                        onClick={() => dispatch(logoutUser(props.history))}
                      >
                        Log Out
                      </Button>
                    </RenderIf>
                  </div>

                  <RenderIf isTrue={userInfo?.isAgencySwitch && parentAgency?.email}>
                    <RenderIf
                      isTrue={userInfo?.isAgencySwitch && parentAgency && props?.account?.data?.payment_source_added}
                    >
                      <Alert color="primary">
                        <i className="bx bx-info-circle pr-2"></i>
                        {"Payment source is added. Please add the store."}
                      </Alert>
                    </RenderIf>
                    <div className="text-center">
                      <Button
                        color="primary"
                        className="mt-4 m-1 w-25"
                        onClick={() =>
                          fetchTenantToken(parentAgency?.email, {
                            isAgencySwitch: true,
                          })
                        }
                      >
                        Back to Agency
                      </Button>
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={userInfo?.isAgencySwitch && parentAgency}>
                    <DoItLaterBtn />
                  </RenderIf>
                  <RenderIf
                    isTrue={!userInfo?.isAgencySwitch && (accounts?.data?.length > 0 || !isUserHaveFullAccess())}
                  >
                    <DoItLaterBtn />
                  </RenderIf>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(Welcome);

Welcome.propTypes = {
  setActiveStep: PropTypes.func,
  allSteps: PropTypes.array,
};
