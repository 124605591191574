import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button, FormGroup } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import "react-toggle/style.css";
import Select from "react-select";
import { getUserInfo } from "../../../utils/common";
// actions
import { setAgencyAccountModal, assignAgencyAccountToUsers } from "../../../store/actions";

const AgencyAccountModal = (props) => {
  const dispatch = useDispatch();
  const { selectedUser, users, error, loading, agencyAccountModalIsOpen } = useSelector((state) => state.User);
  const { allTenants } = useSelector((state) => state.Settings);

  const [agencyAccount, setAgencyAccount] = useState([]);
  const [selectMultipleAgencyAccount, setSelectMultipleAgencyAccount] = useState([]);

  function handleAccount (selectMultipleAgencyAccount) {
    setSelectMultipleAgencyAccount(selectMultipleAgencyAccount);
  }

  const initialValues = {
    user_id: selectedUser.id,
    agency_acc: "",
  };

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || values[key] === []) errors[key] = "Field is Required";
    });
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      dispatch(
        assignAgencyAccountToUsers({
          data: {
            user_id: selectedUser.id,
            agency_acc: selectMultipleAgencyAccount?.map((x) => x.value) || [],
          },
        }),
      );
    },
  });

  useEffect(() => {
    if (!loading) {
      setAgencyAccount(
        allTenants
          .filter((x) => x.id !== getUserInfo().account_id)
          .reduce((acc, tenant, i) => {
            const selected = users.data
              ?.find((x) => x.id === selectedUser.id)
              .AccountUser?.map((y) => y.id)
              .includes(tenant.id);
            if (selected) {
              setSelectMultipleAgencyAccount([
                ...selectMultipleAgencyAccount,
                {
                  label: tenant.email,
                  value: tenant.id,
                },
              ]);
            }
            acc.push({
              label: tenant.email,
              value: tenant.id,
              key: i,
            });
            return acc;
          }, []),
      );

      setSelectMultipleAgencyAccount(
        allTenants
          .filter((x) => x.id !== getUserInfo().account_id)
          .reduce((acc, tenant, i) => {
            const selected = users.data
              ?.find((x) => x.id === selectedUser.id)
              .AccountUser?.map((x) => x.id)
              .includes(tenant.id);
            if (selected) {
              acc.push({
                label: tenant.email,
                value: tenant.id,
                key: i,
              });
            }
            return acc;
          }, []),
      );
    }
  }, [loading]);

  useEffect(() => {
    selectMultipleAgencyAccount && formik.setFieldValue("agency_acc", selectMultipleAgencyAccount.value, false);
  }, [selectMultipleAgencyAccount]);

  const toggleModal = () => {
    dispatch(setAgencyAccountModal(!agencyAccountModalIsOpen));
  };

  return (
    <Modal size="md" isOpen={agencyAccountModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          ADD AGENCY ACCOUNT
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <Label>UserName</Label>
              <Input value={selectedUser.username} readOnly />
            </Col>
            {
              <Col lg="12 mb-2">
                <FormGroup className="select2-container">
                  <label className="control-label">Select Account(s)</label>
                  <Select
                    name="agency_acc"
                    value={selectMultipleAgencyAccount}
                    isMulti={true}
                    onChange={handleAccount}
                    options={agencyAccount}
                    classNamePrefix="select2-selection"
                  />
                  {formik.touched.agency_acc && formik.errors.agency_acc
                    ? (
                    <span className="text-danger mt-1">{formik.errors.agency_acc}</span>
                      )
                    : null}
                </FormGroup>
              </Col>
            }
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default AgencyAccountModal;
