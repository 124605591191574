import React from "react";
import { Col, Row } from "reactstrap";
import StatusBadge from "../../../components/Common/StatusBadge";
import StrategyCard from "./StrategyCard";
import PillBtn from "../../../components/Common/PillBtn";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/repricer/actions";
import $ from "lodash";
import { RenderIf, toF } from "../../../utils/common";

const dict = {
  FIXED_VALUE: ["minimumValue", "maximumValue"],
  FIXED_PROFIT: ["minimumPercentage", "maximumPercentage"],
  CHOOSE_MAX: ["minimumHybridValue", "maximumHybridValue", "maximumHybridPercentage", "maximumHybridPercentage"],
  FORMULA_BASED: ["minPriceFormula", "maxPriceFormula"],
};

const Step3 = ({
  stepCounter,
  setStepCounter,
  step1InitialData,
  step2InitialData,
  setStep1InitialData,
  setStep2InitialData,
  strategyId,
  setStrategyId,
  setOperation,
  setStrategyOperation,
  formik,
  selectedPlatform,
}) => {
  const dispatch = useDispatch();
  let data = {};
  if (step2InitialData.minMaxType === "FIXED_VALUE") {
    data = {
      min_value: step2InitialData.minimumValue,
      max_value: step2InitialData.maximumValue,
    };
  } else if (step2InitialData.minMaxType === "FIXED_PROFIT") {
    data = {
      min_profit: step2InitialData.minimumPercentage,
      max_profit: step2InitialData.maximumPercentage,
    };
  } else if (step2InitialData.minMaxType === "CHOOSE_MAX") {
    data = {
      min_value: step2InitialData.minimumHybridValue,
      max_value: step2InitialData.maximumHybridValue,
      min_profit: step2InitialData.minimumHybridPercentage,
      max_profit: step2InitialData.maximumHybridPercentage,
    };
  } else if (step2InitialData.minMaxType === "FORMULA_BASED") {
    data = {
      min_price_formula: step2InitialData.minPriceFormula,
      max_price_formula: step2InitialData.maxPriceFormula,
    };
  }
  const prePopulatedDataStep1 = $.pick(formik.values, $.keys(step1InitialData));
  const changedDataStep1 = $.pick(step1InitialData, $.keys(step1InitialData));
  const paramsUpdateCheck1 = $.isEqual(prePopulatedDataStep1, changedDataStep1);
  const prePopulatedDataStep2 = $.pick(formik.values, dict[step2InitialData.minMaxType]);
  const changedDataStep2 = $.pick(step2InitialData, dict[step2InitialData.minMaxType]);
  const paramsUpdateCheck2 = $.isEqual(prePopulatedDataStep2, changedDataStep2);
  const cardParams = $.merge(
    {
      title: step1InitialData.strategyNameField,
      beatByCompetitor: step1InitialData.matchingTypeAmount,
    },
    $.pick(step1InitialData, [
      "beat_by_competition_when_gt_listed_price",
      "competitionAbove",
      "competitionBelow",
      "matchCompetition",
      "noCompetition",
      "haveBuyBox",
      "excludeSellerRating",
      "whenBuyBoxIsSuppressed",
    ]),
    $.pick(step2InitialData, [
      "minimumValue",
      "maximumValue",
      "minimumPercentage",
      "maximumPercentage",
      "minimumHybridValue",
      "maximumHybridValue",
      "minimumHybridPercentage",
      "maximumHybridPercentage",
      "minMaxType",
      "minPriceFormula",
      "maxPriceFormula",
    ]),
  );
  return (
    <Col md={12}>
      <div
        className="strategy-card-header font-size-15 font-weight-semibold inventory-color"
        style={{ marginBottom: "24px" }}
      >
        <div className="d-flex justify-content-between">
          <span>{stepCounter}. Review and Create Strategy</span>
          <StatusBadge status={"Strategy: Get BuyBox"} colorsMapping={{ "Strategy: Get BuyBox": "orange" }} />
        </div>
      </div>
      <Row className="font-size-12 font-weight-medium">
        <Col md={12}>
          <StrategyCard
            cardParams={cardParams}
            headerTitle="Strategy Basic Information"
            formik={formik}
            setStrategyId={setStrategyId}
            setStrategyOperation={setStrategyOperation}
            setOperation={setOperation}
            darkBackground={true}
          />
        </Col>
      </Row>
      <RenderIf isTrue={strategyId && paramsUpdateCheck1 && paramsUpdateCheck2}>
        <Row className="font-size-11 font-weight-medium mt-3">
          <Col md={12}>
            <div className="d-flex align-items-center text-warning">
              <i className="bx bx-info-circle mx-1" />
              Note: Kindly update a field to enable "confirm" button.
            </div>
          </Col>
        </Row>
      </RenderIf>
      <Row className="font-size-13 font-weight-medium">
        <Col md={12}>
          <div style={{ padding: "10px 0px" }}>
            <hr className="m-0" />
          </div>
          <div className="d-flex justify-content-between" style={{ padding: "10px 0px" }}>
            <PillBtn
              className="ml-2"
              title={"Back"}
              name={"Back"}
              color={"primary"}
              outline={true}
              onClick={() => {
                setStepCounter(stepCounter - 1);
              }}
            />
            <PillBtn
              disabled={strategyId && paramsUpdateCheck1 && paramsUpdateCheck2}
              className={`${strategyId && paramsUpdateCheck1 && paramsUpdateCheck2 ? "cursor-not-allowed" : ""} ml-2`}
              title={"Confirm"}
              name={"Confirm"}
              color={"primary"}
              onClick={() => {
                const payloadBody = $.merge(
                  {
                    ...data,
                    name: step1InitialData.strategyNameField,
                    competition_type: "ALL",
                    beat_by_competitor: +toF(+step1InitialData.matchingTypeAmount),
                    competition_below_min_price: step1InitialData.competitionBelow,
                    competition_above_listed_price: step1InitialData.competitionAbove,
                    competition_matches_min_price: step1InitialData.matchCompetition,
                    maximise_profit: step1InitialData.haveBuyBox,
                    no_competition: step1InitialData.noCompetition,
                    min_max_type: step2InitialData.minMaxType,
                  },
                  step1InitialData.excludeSellerRating && {
                    exclude_seller_rating: step1InitialData.excludeSellerRating,
                  },
                  step1InitialData.whenBuyBoxIsSuppressed && {
                    when_buybox_is_suppressed: step1InitialData.whenBuyBoxIsSuppressed,
                  },
                  step1InitialData.competitionAbove === "reduce_value_from_listed_price"
                    ? {
                        beat_by_competition_when_gt_listed_price: +toF(
                          +step1InitialData.beat_by_competition_when_gt_listed_price,
                        ),
                      }
                    : {},
                );
                strategyId
                  ? dispatch(actions.updateStrategy(selectedPlatform, strategyId, payloadBody))
                  : dispatch(actions.createStrategy(selectedPlatform, payloadBody));
                setStepCounter(1);
                setStrategyId(null);
                setStrategyOperation(false);
                formik.setValues({
                  strategyNameField: "",
                  matchingPriceType: "price_below",
                  matchingType: "ALL",
                  matchingTypeAmount: "",
                  noCompetition: "",
                  competitionBelow: "",
                  competitionAbove: "",
                  matchCompetition: "",
                  haveBuyBox: "",
                  excludeSellerRating: "",
                  whenBuyBoxIsSuppressed: "",
                });
                setStep1InitialData({});
                setStep2InitialData({});
              }}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Step3;
