import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button } from "reactstrap";
import Modal from "../../components/Common/Modal";
import "react-toggle/style.css";
import { connect } from "react-redux";
// actions
import {
  createPurchasingAccounts,
  updatePurchasingAccounts,
  setAddPurchasingAccountsModal,
  setEditPurchasingAccountsModal,
} from "../../store/actions";

const AccountsModal = (props) => {
  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "")
        !["rds_username", "rds_password", "proxy_ip", "proxy", "proxy_url", "whitelist_ip"].includes(key) &&
          (errors[key] = "Field is Required");
    });
    return errors;
  };

  const initialValues = {
    email: "",
    proxy: "",
    rds_username: "",
    rds_password: "",
    proxy_ip: "",
    whitelist_ip: "",
    proxy_url: "",
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      delete values.proxy_url;
      if (props.type === "edit" && props.Credential.selectedAccounts) {
        props.updatePurchasingAccounts({
          data: values,
          id: props.Credential.selectedAccounts.id,
        });
      } else {
        props.createPurchasingAccounts({
          data: values,
        });
      }
    },
  });

  useEffect(() => {
    props.Credential.selectedAccounts &&
      props.type === "edit" &&
      Object.keys(initialValues).forEach((key) => {
        props.Credential.selectedAccounts[key] !== "" &&
          props.Credential.selectedAccounts[key] !== undefined &&
          props.Credential.selectedAccounts[key] !== null &&
          formik.setFieldValue(key, props.Credential.selectedAccounts[key], false);
      });
  }, [props.Credential.selectedAccounts]);

  useEffect(() => {
    props.Credential.success && !props.Credential.loading && toggleModal();
  }, [props.Credential.success, props.Credential.loading]);

  const toggleModal = () => {
    props.type === "edit"
      ? props.setEditPurchasingAccountsModal(!props.Credential.editAccountModalIsOpen)
      : props.setAddPurchasingAccountsModal(!props.Credential.addAccountModalIsOpen);
    formik.resetForm();
  };

  return (
    <Modal
      size="md"
      isOpen={props.Credential.addAccountModalIsOpen || props.Credential.editAccountModalIsOpen}
      toggle={toggleModal}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.type === "edit" ? "EDIT EMAIL ACCOUNT" : "ADD EMAIL ACCOUNT"}
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {props.Credential.error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {"Credentials: " + props.Credential.error}
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="6 mb-2">
              <Label>Email*</Label>
              <Input
                name="email"
                type="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email
                ? (
                <span className="text-danger mt-1">{formik.errors.email}</span>
                  )
                : null}
            </Col>
            <Col lg="6 mb-2">
              <Label>WhiteList IP Address</Label>
              <Input
                name="whitelist_ip"
                placeholder="Whitelist IP"
                value={formik.values.whitelist_ip}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6 mb-2">
              <Label>Computer Username</Label>
              <Input
                name="rds_username"
                placeholder="Remote Desktop Username"
                value={formik.values.rds_username}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
            <Col lg="6 mb-2">
              <Label>Computer Password</Label>
              <Input
                name="rds_password"
                value={formik.values.rds_password}
                placeholder="Remote Desktop Password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
            {props.type === "edit" && (
              <>
                <Col lg="3 mb-2">
                  <div className="d-flex flex-column">
                    <Label>Proxy IP</Label>
                    <h6>{formik.values.proxy_ip || formik.values.proxy_url?.split(":")?.shift()}</h6>
                  </div>
                </Col>
                <Col lg="3 mb-2">
                  <div className="d-flex flex-column">
                    <Label>Port</Label>
                    <h6>{formik.values.proxy}</h6>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { Credential } = state;
  return { Credential };
};

export default connect(mapStateToProps, {
  createPurchasingAccounts,
  updatePurchasingAccounts,
  setAddPurchasingAccountsModal,
  setEditPurchasingAccountsModal,
})(AccountsModal);
