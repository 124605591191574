import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Alert, Spinner, Nav, NavItem, NavLink } from "reactstrap";
import { RenderIf, amountText, adjustDate } from "../../../utils/common";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as actions from "../../../store/repricer/actions";
import { Link } from "react-router-dom";
import PriceChangeHistory from "./PriceChangeHistoryGraph";
import ProductLogs from "./ProductLogs";
import { get, size } from "lodash";
import { DATE_FORMATS, MARKET_PLACES } from "../../../constants";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";

const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";

const DaysButtonWrapper = ({ days, setDaysLimit, daysLimit, setDateRange }) => {
  return (
    <Nav pills className="navtab-bg nav-justified dashboard-navabr mb-2 mb-md-0">
      <NavItem>
        <NavLink
          style={{ cursor: "pointer", minWidth: "96px", height: "30px" }}
          className={classnames("nav-link", { active: daysLimit === days })}
          onClick={() => {
            setDaysLimit(days);
            setDateRange([moment().subtract(days, "days").format(dateFormat), moment().format(dateFormat)]);
          }}
        >
          {days} Days
        </NavLink>
      </NavItem>
    </Nav>
  );
};

function ProductDetails () {
  const { platform, itemId, mpAccountId } = useParams();
  const { error, listingDetails } = useSelector((state) => state.Repricer);
  const dispatch = useDispatch();

  const [daysLimit, setDaysLimit] = useState(7);
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, "days").format(dateFormat),
    moment().format(dateFormat),
  ]);

  const formatDate = (field) => {
    const date = get(listingDetails, field);
    return date ? moment(date).format(DATE_FORMATS.FULL_DATE) : "N/A";
  };
  const [isAmazon, dataColumns] = useMemo(() => {
    const isAmazon = platform === MARKET_PLACES.amazon;
    return [
      isAmazon,
      [
        {
          title: "Brand",
          content: listingDetails.brand || "N/A",
        },
        {
          title: isAmazon ? "ASIN" : "Item ID",
          content: isAmazon ? listingDetails.asin : listingDetails.marketplace_item_id || "N/A",
        },
        {
          title: "SKU",
          content: listingDetails.sku || "N/A",
        },
        {
          title: "COGS",
          content: amountText(listingDetails.cost),
        },
        {
          title: "Value of Current Stock",
          content: amountText(listingDetails.listed_price * listingDetails.stock) || 0,
        },
        {
          title: "Est. Profit at Listed Price",
          content: amountText(listingDetails.profit?.profit),
        },
        ...(isAmazon ? [{ title: "Competitors", content: +listingDetails.no_of_competitors }] : []),
        {
          title: "EC Imported At",
          content: formatDate("created_at"),
        },
        {
          title: "Marketplace Created At",
          content: formatDate("marketplace_created_at"),
        },
      ],
    ];
  }, [platform, listingDetails]);

  useEffect(() => {
    dispatch(actions.fetchRepricerListingDetails(isAmazon && platform, itemId, mpAccountId));
  }, [platform, itemId]);

  return (
    <>
      <RenderIf isTrue={error}>
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2"></i>
          {error}
        </Alert>
      </RenderIf>

      <div className="d-flex flex-column w-100 h-100">
        <div className="d-inline-flex flex-row align-items-center py-4">
          <Link to="/listings_new">
            <i title="Back" className="bx bx-sm bx-arrow-back text-primary mr-2 cursor-pointer" />
          </Link>
          <h1 className="mt-2 font-size-18 text-uppercase">Product Details</h1>
        </div>

        <Card>
          <CardBody
            className={`d-flex flex-column flex-md-row align-items-center ${
              !size(listingDetails) && "justify-content-center"
            } gap-3`}
          >
            <RenderIf
              isTrue={size(listingDetails)}
              fallback={<Spinner color="primary" style={{ width: "36px", height: "36px" }} />}
            >
              <div className="w-100 d-flex flex-column align-items-start">
                <CardTitle className="font-size-18 text-white mb-4">{listingDetails.listing_title}</CardTitle>
                <div className="w-100 row pl-2">
                  {dataColumns.map((item, index) => (
                    <div key={index} className="col-3 p-2">
                      <CardSubtitle className="mb-2">{item.title}</CardSubtitle>
                      <span className="inventory-color">{item.content}</span>
                    </div>
                  ))}
                </div>
              </div>
            </RenderIf>
          </CardBody>
        </Card>

        <div className="d-flex flex-column flex-md-row align-items-center justify-content-end my-4" style={{ gap: "10px" }}>
          {[365, 180, 30, 7].map((day) => (
            <DaysButtonWrapper
              key={day}
              days={day}
              setDaysLimit={setDaysLimit}
              daysLimit={daysLimit}
              setDateRange={setDateRange}
            />
          ))}
          <Flatpickr
            className="w-230 form-control"
            placeholder="Custom Range"
            value={dateRange}
            options={{ maxDate: new Date(), mode: "range" }}
            onChange={(dates) => {
              if (dates.length < 2) return;

              const startdate = moment(adjustDate(dates[0]));
              let endDate = moment(adjustDate(dates[1]));

              if (startdate.isSame(endDate)) {
                endDate = endDate.endOf("day");
              }

              setDateRange([startdate.format(dateFormat), endDate.format(dateFormat)]);
              setDaysLimit(moment(endDate).diff(startdate, "days"));
            }}
          />
        </div>
        <PriceChangeHistory dateRange={dateRange} />
        <ProductLogs dateRange={dateRange} />
      </div>
    </>
  );
}

export default ProductDetails;
