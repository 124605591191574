import React from "react";
import { Container, Card, CardBody } from "reactstrap";
import { startCase, keys } from "lodash";

const CardsUsed = (props) => {
  return (
    <Container fluid>
      <Card>
        <CardBody>
          {Array.isArray(props?.cards)
            ? (
            <>
              <div className="table-responsive">
                <table className="table table-centered table-wrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {keys(props.cards[0]).map((header, index) => (
                        <th key={`table-header ${index}`}>{startCase(header === "count (id)" ? "Count" : header)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.cards.map((item, key) => (
                      <tr key={"_top_" + key}>
                        {keys(item).map((keyName, _ind) => (
                          <td key={"_row_" + _ind}>{item[keyName]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr></hr>
              </div>
            </>
              )
            : null}
          {props?.cards.length === 0
            ? (
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Records Found</h1>
              </div>
            </div>
              )
            : null}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CardsUsed;
