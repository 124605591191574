import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, Input } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";
import { SHIPPING_CARRIER } from "../../../constants";
import { toPascalCase, allowedTrackingStatus } from "../../../utils/common";

// actions
import { refreshSourceOrderDetail, updateTrackingInfo, setPreloader } from "../../../store/actions";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import CustomTooltip from "../../../components/Common/CustomTooltip";

const getStore = (props) => props.stores?.find((store) => store.id === props.partnerId) || {};

const TrackingInfoModal = (props) => {
  const trackingInfo = {
    amazon_tracking_num: "",
    tracking_url: "",
    bce_tracking_num: "",
    tracking_status: "",
    tracking_carrier: {
      value: "",
      label: "",
    },
    proxy_carrier: {
      value: "",
      label: "",
    },
  };
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);

  const validate = (values) => {
    const errors = {};
    if (!["cancelled", "refund", "unshipped", "lost", "undeliverable"].includes(values.tracking_status)) {
      if (values.amazon_tracking_num.trim() !== "" && values.tracking_carrier.value.trim() === "") {
        errors.tracking_carrier = "Tracking Carrier Rquired";
      }

      if (values.bce_tracking_num.trim() !== "" && values.proxy_carrier.value.trim() === "") {
        errors.proxy_carrier = "Proxy Tracking Carrier Rquired";
      }

      if (values.bce_tracking_num.trim() === "" && values.amazon_tracking_num.trim() === "") {
        errors.bce_tracking_num = "Atleast One Tracking number required";
        errors.amazon_tracking_num = "Atleast One Tracking number required";
      }
      if (/^(TBA)/g.test(values.bce_tracking_num) && values.bce_tracking_num.trim()) {
        errors.bce_tracking_num = "Should not be a TBA number";
      }
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: trackingInfo,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.supplierOrder &&
        props.updateTrackingInfo({
          data: {
            ...values,
            tracking_carrier: values.tracking_carrier.value,
            proxy_carrier: values.proxy_carrier.value,
            prevent_store_push: getStore(props).valid === false || getStore(props).status !== "active",
          },
          targetOrderId: props.supplierOrder.target_order_id,
        });
    },
  });

  useEffect(() => {
    if (props.supplierOrder) {
      Object.keys(trackingInfo).forEach((key) => {
        formik.setFieldValue(key, props.supplierOrder[key] || "", false);
      });
      formik.setFieldValue("amazon_tracking_num", props.supplierOrder.tracking_num || "", false);
      formik.setFieldValue("bce_tracking_num", props.supplierOrder.proxy_tracking_number || "", false);
      formik.setFieldValue(
        "tracking_carrier",
        {
          label: props.supplierOrder.tracking_carrier || "",
          value: props.supplierOrder.tracking_carrier || "",
        },
        false,
      );
      formik.setFieldValue(
        "proxy_carrier",
        {
          label: props.supplierOrder.proxy_carrier || "",
          value: props.supplierOrder.proxy_carrier || "",
        },
        false,
      );
    }
  }, [props.supplierOrder]);

  useEffect(() => {
    props.setPreloader(props.SupplierOrders.loading);
    if (!props.SupplierOrders.loading && props.SupplierOrders.success) {
      props.refreshSourceOrderDetail();
      formik.resetForm();
      toggleModal();
    }
  }, [props.SupplierOrders.loading]);

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Tracking Information</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SupplierOrders?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SupplierOrders?.error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mb-2">
                <Label>Tracking Url</Label>
                <Input
                  className="form-control"
                  name="tracking_url"
                  value={formik.values.tracking_url}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.tracking_url
                  ? (
                  <span className="text-danger mt-1">{formik.errors.tracking_url}</span>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2">
                <Label>Tracking Num</Label>
                <Input
                  name="amazon_tracking_num"
                  type="text"
                  value={formik.values.amazon_tracking_num}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.amazon_tracking_num && formik.errors.amazon_tracking_num
                  ? (
                  <span className="text-danger mt-1">{formik.errors.amazon_tracking_num}</span>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2">
                <SimpleSelect
                  id="tracking_carrier"
                  value={formik.values.tracking_carrier}
                  onChange={(val) => {
                    formik.setFieldValue("tracking_carrier", val);
                  }}
                  placeholder="Select carrier for proxy tracking"
                  onBlur={formik.handleBlur}
                  name="tracking_carrier"
                  options={SHIPPING_CARRIER.map((val, i) => ({
                    label: val,
                    value: val,
                    key: i,
                  }))}
                  formStyle={{ minWidth: "250px" }}
                  label="Tracking Carrier*"
                />
                {formik.touched.tracking_carrier && formik.errors.tracking_carrier
                  ? (
                  <span className="text-danger mt-1">{formik.errors.tracking_carrier}</span>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2">
                <Label>Proxy Tracking Number</Label>
                <Input
                  className="form-control"
                  name="bce_tracking_num"
                  value={formik.values.bce_tracking_num}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.bce_tracking_num
                  ? (
                  <span className="text-danger mt-1">{formik.errors.bce_tracking_num}</span>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2">
                <SimpleSelect
                  value={formik.values.proxy_carrier}
                  onChange={(val) => {
                    formik.setFieldValue("proxy_carrier", val);
                  }}
                  placeholder="Select carrier for proxy tracking"
                  onBlur={formik.handleBlur}
                  name="proxy_carrier"
                  options={SHIPPING_CARRIER.map((val, i) => ({
                    label: val,
                    value: val,
                    key: i,
                  }))}
                  label="Proxy Tracking Carrier"
                  formStyle={{ minWidth: "250px" }}
                />
                {formik.touched.proxy_carrier && formik.errors.proxy_carrier
                  ? (
                  <span className="text-danger mt-1">{formik.errors.proxy_carrier}</span>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2">
                <Label>Tracking Status*</Label>
                <div className="form-group">
                  <select
                    name="tracking_status"
                    value={formik.values.tracking_status}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="custom-select"
                  >
                    <option value="">Select Tracking Status</option>
                    {allowedTrackingStatus(formik.values.tracking_status).map((val, index) => (
                      <option key={"tracking_status_" + index} value={val}>
                        {toPascalCase(val)}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.tracking_status && formik.errors.tracking_status
                  ? (
                  <span className="text-danger mt-1">{formik.errors.tracking_status}</span>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button id="order_tracking_submit" type="submit" color="success" className="mr-3">
                Submit
              </Button>
              {getStore(props).valid === false || getStore(props).status !== "active"
                ? (
                <CustomTooltip
                  target="order_tracking_submit"
                  content={`Tracking can't be pushed because ${
                    getStore(props).valid === false ? "store creds are invalid" : `store is ${getStore(props).status}`
                  }`}
                />
                  )
                : null}
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SupplierOrders, MarketPlaceAccount } = state;
  return { SupplierOrders, stores: MarketPlaceAccount.accounts?.data };
};

export default connect(mapStateToProps, {
  refreshSourceOrderDetail,
  updateTrackingInfo,
  setPreloader,
})(TrackingInfoModal);
