export const FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS = "FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS";
export const FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS_SUCCESS = "FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS_SUCCESS";
export const FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS = "FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS";
export const FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS_SUCCESS = "FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS_SUCCESS";
export const FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS = "FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS";
export const FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS_SUCCESS = "FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS_SUCCESS";
export const FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS = "FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS";
export const FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS_SUCCESS = "FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS_SUCCESS";
export const FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH = "FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH";
export const FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH_SUCCESS = "FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH_SUCCESS";
export const FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH = "FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH";
export const FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH_SUCCESS = "FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH_SUCCESS";
export const API_ERROR = "WAREHOUSE_ERROR";
