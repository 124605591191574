import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ReactTable from "../../../../components/Common/Table";
import Modal from "../../../../components/Common/Modal";
import { Button, Row, Col, Input } from "reactstrap";

import { RenderIf } from "../../../../utils/common";
import { pick, assign, groupBy, cloneDeep, sum, concat, merge, startCase } from "lodash";

// Actions
import {
  setShipmentModal,
  createPOShipment,
  updatePurchaseOrderShipment,
  setPreloader,
} from "../../../../store/actions";

const ItemsStatusMapping = {
  complete: "badge-soft-success",
  not_receive: "badge-soft-danger",
};

const ShipmentModal = ({ itemsDetail, type, trackingNum, shipmentItemsDetail, existingPoShipments }) => {
  const bufferShipmentItemsDetail = shipmentItemsDetail ? groupBy(shipmentItemsDetail, "id") : null;
  const dispatch = useDispatch();
  const { shipmentModalIsOpen } = useSelector((state) => state.WareHouses);

  const [ItemsDetailObj, setItemsDetailObj] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [remainingQty, setRemainingQty] = useState(0);
  const [currentShipment, setCurrentShipment] = useState([]);
  const [itemIndex, setItemIndex] = useState(-1);
  const [dynamicShipmentObj, setDynamicShipmentObj] = useState([{ tracking_number: "", items: itemsDetail }]);

  const [checkTrackingStatus, setCheckTrackingStatus] = useState(
    trackingNum?.tracking_number && type !== "create" ? null : "Tracking Number is required.",
  );

  const getCurrentShipmentFun = () => setCurrentShipment(dynamicShipmentObj.map((z) => z?.tracking_number));
  const addOtherShipment = () => {
    setDynamicShipmentObj(concat(dynamicShipmentObj, { tracking_number: "", items: itemsDetail }));
  };

  useEffect(() => {
    const buffer = itemsDetail?.map((v) =>
      assign(v, {
        expected_qty:
          type !== "create"
            ? (bufferShipmentItemsDetail[v.id]?.length &&
                bufferShipmentItemsDetail[v.id][0]?.received_shipments?.expected_qty) ||
              0
            : 0,
        line_item_id: v.id,
      }),
    );
    setItemsDetailObj(buffer);
    type !== "create" ? setTrackingNumber(trackingNum?.tracking_number) : setTrackingNumber();
  }, [itemsDetail]);

  const toggleShipmentModal = () => dispatch(setShipmentModal(!shipmentModalIsOpen));

  const submitForm = () => {
    const dynamicShipmentBuffer = dynamicShipmentObj.map((x) => ({
      tracking_number: x?.tracking_number,
      items: x.items.map((x) => {
        return x?.expected_qty
          ? pick(x, ["line_item_id", "expected_qty"])
          : merge(pick(x, ["line_item_id"]), { expected_qty: 0 });
      }),
    }));
    const shipmentBuffer = {
      po_id: itemsDetail[0]?.po_id,
      shipment_lines: dynamicShipmentBuffer,
    };
    dispatch(setPreloader(true));
    dispatch(createPOShipment(shipmentBuffer));
  };

  const updateForm = () => {
    const extractSpecificKeys = ItemsDetailObj.map((x) =>
      x.expected_qty
        ? pick(x, ["line_item_id", "expected_qty", "id"])
        : merge(pick(x, ["line_item_id"]), { expected_qty: 0 }),
    );
    const deleteItemsArr = extractSpecificKeys.map((x) =>
      typeof x.expected_qty === "string" && parseInt(x.expected_qty) === 0 ? x.id : x,
    );

    const updateShipmentBuffer = {
      po_id: itemsDetail[0]?.po_id,
      wh_order_id: shipmentItemsDetail.length ? shipmentItemsDetail[0]?.wh_order_id : itemsDetail[0]?.wh_order_id,
      delete_ids: deleteItemsArr?.filter(Number),
      tracking_number: trackingNumber,
      shipment_items: deleteItemsArr?.filter((x) => typeof x === "object"),
    };
    dispatch(setPreloader(true));
    dispatch(updatePurchaseOrderShipment(updateShipmentBuffer, trackingNum?.id));
  };

  const updateFieldChangedFun = (e, i) => {
    const { name, value } = e.target;
    const newArr = [...dynamicShipmentObj];
    newArr[i][name] = value;
    const currentShipmentBuffer = [...currentShipment];
    if (currentShipment[i]) {
      currentShipmentBuffer[i] = value;
      setCurrentShipment(currentShipmentBuffer);
    }
    if (value) {
      const allPoShipment = [...currentShipment, ...existingPoShipments];
      if (allPoShipment.includes(value)) {
        newArr[i].tracking_validation = "Tracking No. already exist.";
      } else {
        dynamicShipmentObj.forEach((_, index) => (newArr[index].tracking_validation = ""));
        newArr[i].tracking_validation = "";
      }
    } else {
      newArr[i].tracking_validation = "Tracking Number is required.";
    }
    setDynamicShipmentObj(newArr);
  };

  const updateExpectedItemFun = (list, shipmentLineIndex, name, value, i) => {
    const newArr = [...dynamicShipmentObj];
    newArr[shipmentLineIndex].items = [...list];
    const sumAllShipments = sum(dynamicShipmentObj?.map((x) => +x?.items[itemIndex]?.expected_qty));
    const getRmainItemWise = +list[i].remaining_qty - +sumAllShipments;
    if (value) {
      if (value < 0) list[i].error = { [name]: "Enter positive number." };
      else if (list[i].remaining_qty < sumAllShipments)
        list[i].error = { [name]: `Exceed from Qty by ${Math.abs(getRmainItemWise)}` };
      else list[i].error = { [name]: "" };
    } else {
      list[i].error = { [name]: "" };
    }
    setDynamicShipmentObj(newArr);
  };

  // handle input change for create
  const handleInputChangeQtyAtCreate = (e, i, shipmentLineIndex) => {
    const { name, value } = e.target;
    const list = cloneDeep(dynamicShipmentObj[shipmentLineIndex]?.items);
    list[i][name] = value;
    updateExpectedItemFun(list, shipmentLineIndex, name, value, i);
  };

  const removeSpecificShipmentFun = (i) => {
    const dynamicShipmentObjBuffer = [...dynamicShipmentObj];
    dynamicShipmentObjBuffer.splice(i, 1);
    setDynamicShipmentObj(dynamicShipmentObjBuffer);
  };

  // handle input change for update
  const handleInputChangeQtyAtUpdate = (e, index) => {
    const { name, value } = e.target;
    const list = [...ItemsDetailObj];
    list[index][name] = value;
    if (value) {
      if (value < 0) list[index].error = { [name]: "Enter positive number." };
      else if (remainingQty < value) list[index].error = { [name]: `Remaining Qty <= ${remainingQty}` };
      else list[index].error = { [name]: "" };
    }
    setItemsDetailObj(list);
  };

  return (
    <div>
      <Modal size="xl" isOpen={shipmentModalIsOpen} toggle={toggleShipmentModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Shipments
          </h5>
          <button
            onClick={() => toggleShipmentModal()}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {dynamicShipmentObj.map((x, i) => (
            <React.Fragment key={i}>
              <RenderIf isTrue={i > 0}>
                <hr />
              </RenderIf>
              <Row className="align-items-center mb-3">
                <Col md={3}>
                  <span className="position-relative d-block min-height-55">
                    <span>Tracking Number:</span>
                  </span>
                </Col>
                <Col md={5}>
                  <RenderIf
                    isTrue={type === "create"}
                    fallback={
                      <span className="position-relative d-block min-height-55">
                        <Input
                          type="text"
                          className="inner form-control"
                          placeholder="Tracking Number"
                          value={trackingNumber}
                          onChange={(e) => {
                            setTrackingNumber(e.target.value);
                            if (e.target.value) {
                              if (existingPoShipments.includes(e.target.value)) {
                                setCheckTrackingStatus("Tracking No. already exist.");
                              } else {
                                setCheckTrackingStatus("");
                              }
                            } else {
                              setCheckTrackingStatus("Tracking Number is required.");
                            }
                          }}
                          disabled={type === "read"}
                        />
                        {<span className="text-danger mt-1">{checkTrackingStatus}</span>}
                      </span>
                    }
                  >
                    <span className="position-relative d-block min-height-55">
                      <Input
                        type="text"
                        className="inner form-control"
                        placeholder="Tracking Number"
                        name="tracking_number"
                        value={dynamicShipmentObj[i]?.tracking_number}
                        onBlur={() => getCurrentShipmentFun()}
                        onChange={(e) => {
                          setTrackingNumber(e.target.value);
                          updateFieldChangedFun(e, i);
                        }}
                        disabled={type === "read"}
                      />
                      {<span className="text-danger mt-1">{dynamicShipmentObj[i]?.tracking_validation}</span>}
                    </span>
                  </RenderIf>
                </Col>
                <Col md={4}>
                  {dynamicShipmentObj?.length > 1 && (
                    <span
                      className="position-relative text-danger d-flex justify-content-center cursor-pointer min-height-55 float-right"
                      onClick={() => removeSpecificShipmentFun(i)}
                    >
                      <i className="bx bx-x text-danger bx-sm cursor-pointer pl-2"></i>
                      <p className=" pt-1">Remove Shipment</p>
                    </span>
                  )}
                </Col>
              </Row>
              <ReactTable
                tableHeader={
                  <>
                    <th>Item</th>
                    <th>SKU</th>
                    <RenderIf isTrue={type === "read"}>
                      <th>Status</th>
                    </RenderIf>
                    <th>Total Qty</th>
                    <th>Total Received</th>
                    <th>Quantity Multiplier</th>
                    <RenderIf isTrue={type !== "read"}>
                      <th>Remain Qty</th>
                    </RenderIf>
                    <th>Qty</th>
                  </>
                }
                tableBody={
                  <RenderIf
                    isTrue={type === "create"}
                    fallback={
                      <>
                        {ItemsDetailObj?.map((x, i) => (
                          <tr key={i}>
                            <td>
                              <span className="position-relative d-block min-height-55 pt-2">
                                {x.item_title || "N/A"}
                              </span>
                            </td>
                            <td>
                              <span className="position-relative d-block min-height-55 pt-2">{x.sku || "N/A"}</span>
                            </td>
                            <RenderIf isTrue={type === "read"}>
                              <td>
                                <span className="position-relative d-block min-height-55 pt-2">
                                  <span
                                    className={`font-size-12 badge badge-pill ${
                                      ItemsStatusMapping?.[x?.received_shipments?.status] || "badge-soft-warning"
                                    }`}
                                  >
                                    {startCase(x?.received_shipments?.status) || "N/A"}
                                  </span>
                                </span>
                              </td>
                            </RenderIf>
                            <td>
                              <span className="position-relative d-block  min-height-55 ">
                                <Input type="text" className="inner form-control" value={x.quantity || 0} disabled />
                              </span>
                            </td>
                            <td>
                              <span className="position-relative d-block min-height-55">
                                <Input
                                  type="text"
                                  className="inner form-control"
                                  value={
                                    type === "read" ? x?.received_shipments?.received_qty || 0 : x.received_qty || 0
                                  }
                                  disabled
                                />
                              </span>
                            </td>
                            <td>
                              <span className="position-relative d-block min-height-55">
                                <Input
                                  type="text"
                                  className="inner form-control"
                                  value={x.qty_multiplier || 0}
                                  disabled
                                />
                              </span>
                            </td>
                            <RenderIf isTrue={type !== "read"}>
                              <td>
                                <span className="position-relative d-block min-height-55">
                                  <Input
                                    type="text"
                                    className="inner form-control"
                                    value={x?.remaining_qty || 0}
                                    disabled
                                  />
                                </span>
                              </td>
                            </RenderIf>
                            <td className="min-width-150">
                              <span className="position-relative d-block min-height-55">
                                <Input
                                  type="number"
                                  className="inner form-control"
                                  placeholder="Enter Qty"
                                  name="expected_qty"
                                  value={type === "read" ? x?.received_shipments?.expected_qty : x?.expected_qty}
                                  onFocus={() => setRemainingQty(x?.remaining_qty + x?.expected_qty)}
                                  onClick={() => setRemainingQty(x?.remaining_qty + x?.expected_qty)}
                                  onChange={(e) => handleInputChangeQtyAtUpdate(e, i)}
                                  disabled={type === "read"}
                                />
                                {x?.error?.expected_qty
                                  ? (
                                  <span className="text-danger mt-1">{x?.error?.expected_qty}</span>
                                    )
                                  : null}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                  >
                    <>
                      {x.items?.map((y, index) => (
                        <tr key={index}>
                          <td>
                            <span className="position-relative d-block min-height-55 pt-2">
                              {y?.item_title || "N/A"}
                            </span>
                          </td>
                          <td>
                            <span className="position-relative d-block min-height-55 pt-2">{y?.sku || "N/A"}</span>
                          </td>
                          <td>
                            <span className="position-relative d-block  min-height-55 ">
                              <Input type="text" className="inner form-control" value={y?.quantity || 0} disabled />
                            </span>
                          </td>
                          <td>
                            <span className="position-relative d-block min-height-55">
                              <Input type="text" className="inner form-control" value={y?.received_qty || 0} disabled />
                            </span>
                          </td>
                          <td>
                            <span className="position-relative d-block min-height-55">
                              <Input
                                type="text"
                                className="inner form-control"
                                value={y?.qty_multiplier || 0}
                                disabled
                              />
                            </span>
                          </td>
                          <RenderIf isTrue={type !== "read"}>
                            <td>
                              <span className="position-relative d-block min-height-55">
                                <Input
                                  type="text"
                                  className="inner form-control"
                                  value={y?.remaining_qty || 0}
                                  disabled
                                />
                              </span>
                            </td>
                          </RenderIf>
                          <td className="min-width-150">
                            <span className="position-relative d-block min-height-55">
                              <Input
                                type="number"
                                className="inner form-control"
                                placeholder="Enter Qty"
                                name="expected_qty"
                                value={type === "read" ? y?.received_shipments?.expected_qty : y?.expected_qty}
                                onFocus={() => setItemIndex(index)}
                                onClick={() => setItemIndex(index)}
                                onChange={(e) => handleInputChangeQtyAtCreate(e, index, i)}
                                disabled={type === "read"}
                              />
                              <RenderIf isTrue={y?.error?.expected_qty}>
                                <span className="text-danger mt-1">{y?.error?.expected_qty}</span>
                              </RenderIf>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </>
                  </RenderIf>
                }
              />
            </React.Fragment>
          ))}
          <RenderIf isTrue={type === "create"}>
            <>
              <hr />
              <span
                className="text-primary d-flex align-items-center cursor-pointer"
                style={{ width: "20%" }}
                onClick={() => addOtherShipment()}
              >
                <i className="bx bx-xs bx-plus pr-2 "></i>Add another shipment
              </span>
            </>
          </RenderIf>
          <RenderIf isTrue={type !== "read"}>
            <Row className="d-flex justify-content-end mt-3">
              <button
                className="waves-effect waves-light btn btn-outline-primary mr-3"
                onClick={() => {
                  setItemsDetailObj();
                  toggleShipmentModal();
                  setRemainingQty(0);
                }}
              >
                Cancel
              </button>
              <Button
                onClick={() => (type === "create" ? submitForm() : updateForm())}
                disabled={
                  type === "create"
                    ? dynamicShipmentObj.some((x) => !x?.tracking_number || x?.tracking_validation)
                    : checkTrackingStatus
                }
                type="submit"
                color="primary"
                className="mr-3"
              >
                {type === "create" ? "Submit" : "Update"}
              </Button>
            </Row>
          </RenderIf>
        </div>
      </Modal>
    </div>
  );
};

export default ShipmentModal;
