import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Alert, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import repricerIcon from "../../assets/svg/repricerIcon.svg";

// components
import EditModal from "./components/EditModal";

// utils
import { toPascalCase, getKeyByValue, RenderIf, getUserInfo, getValidStores } from "../../utils/common";
import { REPRICER_PROFIT_TYPES, REPRICER_TYPES, HELP_DOCS_LINK, MP_ACC_STATUSES, MARKET_PLACES } from "../../constants";

// actions
import {
  fetchMarketplaceAccounts,
  setEditModalMarketplaceAccount,
  setSelectedMarketplaceAccount,
  updateMarketplaceAccounts,
  setPreloader,
  setRepricerIntegrationModal,
} from "../../store/actions";
import RepricerIntergrationModal from "./components/RepricerIntergrationModal";
import getBrandImageSrc from "../../utils/brandImages";
import { merge, startCase } from "lodash";
import CustomTooltip from "../../components/Common/CustomTooltip";
import ArchiveBadge from "../../components/Common/ArchiveBadge";

const Repricer = () => {
  const { accounts, selectedAccount, error, loading, editModalIsOpen, repricerIntegrationModalIsOpen, success } =
    useSelector((state) => state.MarketPlaceAccount);
  const {
    success: loginSuccess,
    error: loginError,
    loading: loginLoading,
    repricer_integrated: repricerIntegrated,
  } = useSelector((state) => state.Login);
  const { account } = useSelector((state) => state.Settings);
  const [filters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [update, setUpdate] = useState(null);

  const dispatch = useDispatch();
  const tableHeaders = [
    { title: "Name" },
    { title: "Repricer Name" },
    { title: "Repricer Enabled", class: "text-center" },
    { title: "Actions" },
  ];

  const validStores = getValidStores(accounts, { forRepricer: true, isValid: true });

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }

  function fetchAllAccounts () {
    dispatch(fetchMarketplaceAccounts(filters));
  }

  function handleEditAcc (acc) {
    dispatch(setSelectedMarketplaceAccount(acc));
    dispatch(setEditModalMarketplaceAccount(true));
  }

  function handleUpdate () {
    if (update) {
      dispatch(updateMarketplaceAccounts(merge({ params: { repricer_setting: true } }, update)));
    }
    setUpdate(null);
  }

  useEffect(() => {
    setLoading(loading || loginLoading);
  }, [loading, loginLoading]);

  useEffect(() => {
    fetchAllAccounts();
  }, [filters]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={
              <>
                Repricer Settings{" "}
                {
                  <a href={HELP_DOCS_LINK} target="_blank" rel="noreferrer">
                    <i className="bx bx-info-circle"></i>
                  </a>
                }
              </>
            }
            children={
              <div>
                {["admin", "owner", "manager", "va"].includes(getUserInfo()?.role) &&
                  !getUserInfo()?.api_key_repricer &&
                  !accounts?.repricer_integrated && (
                    <Button
                      className="col"
                      color="info"
                      onClick={() => dispatch(setRepricerIntegrationModal(!repricerIntegrationModalIsOpen))}
                    >
                      Integrate with new EC Repricer
                    </Button>
                )}
                {["admin", "owner", "va", "manager"].includes(getUserInfo()?.role) && accounts?.repricer_integrated && (
                  <a
                    href={`https://repricer.ecomcircles.com/web-auth/ecomcircles?email=${encodeURIComponent(
                      getUserInfo()?.email,
                    )}&first_name=${
                      account?.data?.users?.find((user) => user.email === getUserInfo()?.email)?.first_name || ""
                    }&last_name=&oauth_uuid=${account?.data?.uuid}&oauth_provider=ecom_circles`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light d-flex align-items-center"
                    >
                      <img src={repricerIcon} alt="repricer svg" width="30" />{" "}
                      <p className="mb-0">Login To EC Repricer</p>
                    </button>
                  </a>
                )}
              </div>
            }
          />
          {selectedAccount && editModalIsOpen && <EditModal type="edit" />}
          {repricerIntegrationModalIsOpen && <RepricerIntergrationModal />}
          {isOpen && (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                handleUpdate();
                setIsOpen(false);
              }}
              onCancel={() => setIsOpen(false)}
            >
              To update your account setting!
            </SweetAlert>
          )}
          {error && !editModalIsOpen && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          )}
          {loginError && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {loginError}
              </Alert>
            </div>
          )}
          {repricerIntegrated?.errors?.length > 0 && (
            <div>
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {repricerIntegrated?.errors.join(", ")}
              </Alert>
            </div>
          )}
          {loginSuccess && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {loginSuccess}
              </Alert>
            </div>
          )}
          {success && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {success}
              </Alert>
            </div>
          )}
          <Card>
            <CardBody>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {tableHeaders.map((header, index) => (
                        <th key={`table-header ${index}`} className={header.class || ""}>
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    <RenderIf
                      isTrue={validStores?.length}
                      fallback={
                        <tr>
                          <td colSpan="100%">
                            <h2 className="text-center">No Records Found</h2>
                          </td>
                        </tr>
                      }
                    >
                      {validStores?.map((item, key) => (
                        <tr key={"repricer_setting_" + key}>
                          <td>
                            <div className="left-0">
                              {getBrandImageSrc(item.marketplace) && (
                                <img title={item.name} src={getBrandImageSrc(item.marketplace)} alt="" />
                              )}
                              {toPascalCase(item.name)}
                              {item.is_archive && ArchiveBadge()}
                            </div>
                          </td>
                          <td>
                            {item.repricer_type === REPRICER_TYPES.informed ? (
                              <>
                                <a
                                  href="https://www.informed.co/?ref=matthall4&utm_campaign=Affiliate+Program"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Informed
                                </a>{" "}
                                repricer
                              </>
                            ) : item.repricer_type === REPRICER_TYPES.internal ? (
                              getKeyByValue(REPRICER_PROFIT_TYPES, item.profit_type)
                            ) : (
                              toPascalCase(getKeyByValue(REPRICER_TYPES, item.repricer_type)) || "N/A"
                            )}
                          </td>
                          <td className="text-center">
                            <RenderIf isTrue={item?.valid && item?.status !== MP_ACC_STATUSES.terminated}>
                              <div
                                className="custom-control custom-switch custom-switch-lg my-3"
                                id={`bce_enabled_${item?.id}`}
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="bce_enabled"
                                  checked={!item.disable_repricer}
                                  onChange={() => null}
                                />
                                <label
                                  onClick={() => {
                                    if (item?.repricer_type) {
                                      setUpdate({
                                        id: item.id,
                                        data: {
                                          repricer_type: item.repricer_type,
                                          disable_repricer: !item.disable_repricer,
                                        },
                                      });
                                      setIsOpen(true);
                                    }
                                  }}
                                  className="custom-control-label"
                                ></label>
                              </div>
                              {!item?.repricer_type ? (
                                <CustomTooltip
                                  target={`bce_enabled_${item?.id}`}
                                  content={"Select the repricer type first"}
                                  placement="top"
                                />
                              ) : null}
                            </RenderIf>
                          </td>
                          <td>
                            <RenderIf
                              isTrue={
                                (!item?.disable_repricer ||
                                  [MARKET_PLACES.amazon, MARKET_PLACES.walmart].includes(item.marketplace)) &&
                                item?.valid &&
                                item?.status !== MP_ACC_STATUSES.terminated
                              }
                              fallback={
                                item?.status !== MP_ACC_STATUSES.terminated
                                  ? item?.valid
                                    ? "Repricer Disabled"
                                    : "Invalid Credentials"
                                  : startCase(MP_ACC_STATUSES.terminated)
                              }
                            >
                              <i
                                title="Edit"
                                className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                                onClick={() => handleEditAcc(item)}
                              />
                            </RenderIf>
                          </td>
                        </tr>
                      ))}
                    </RenderIf>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Repricer;
