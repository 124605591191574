import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import { Container, Card, CardHeader, CardBody, FormGroup } from "reactstrap";
import Select from "react-select";
import { setPreloader, fetchRefreshers } from "../../store/actions";
import { REFRESHERS_SUPPLIERS, ACCOUNT_STATUSES, DATE_FORMATS } from "../../constants";
import { toPascalCase } from "../../utils/common";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { omitBy, sortBy } from "lodash";
import { DatePicker } from "../../components/Common/DatePickr";

const tableCols = [
  { title: "Supplier" },
  { title: "Total Listings" },
  { title: "In Stock" },
  { title: "Out of Stock" },
  { title: "Status Stats" },
  { title: "Refreshed At" },
];

const keyMapping = {
  offer_found: "Offer found",
  shipping_days: "Shipping days",
  feedback_setting: "Feedback setting",
  offer_not_present: "Offer not present",
  invalid_url: "Invalid Url",
  no_offer_match: "No offer match",
  review_setting: "Review setting",
};

const getStatusStats = (data) => {
  const transformedStrings = [];
  for (const key in keyMapping) {
    if (data[key]) {
      const label = keyMapping[key];
      const count = data[key];
      transformedStrings.push(`${label}: ${count}`);
    }
  }

  return transformedStrings;
};

const RefreshersView = (_) => {
  const dispatch = useDispatch();
  const { refresherStats, loading } = useSelector((state) => state.Supplier);
  const { allTenants } = useSelector((state) => state.Settings);

  const [supplier, setSupplier] = useState({
    value: "all",
    label: "Select Supplier",
  });
  const [user, setUserFilter] = useState({ label: "Select Tenant", value: "" });
  const [filters, setFilters] = useState({
    email: user.value,
    supplier: supplier.value,
    utc_offset: moment().format("Z"),
  });
  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(fetchRefreshers(omitBy(filters, (x) => x === null))); // Remove dates if they're null
  }, [filters]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Refreshers Insights" />
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <div className="d-flex grid-column-gap-10">
                  <div className="d-flex align-items-center" style={{ width: "250px" }}>
                    <DatePicker onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })} />
                  </div>
                  <FormGroup className="select2-container mt-3 mr-2" style={{ minWidth: "200px" }}>
                    <Select
                      name="supplier"
                      value={{
                        value: supplier.value,
                        label: supplier.label,
                      }}
                      onChange={(e) => {
                        setSupplier({ value: e.value, label: e.value === "" ? "Select Supplier" : e.label });
                        setFilters({ ...filters, supplier: e.value });
                      }}
                      options={Object.values(REFRESHERS_SUPPLIERS).map((x) => ({
                        label: toPascalCase(x),
                        value: x,
                      }))}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <FormGroup className="select2-container mt-3 mr-2" style={{ minWidth: "200px" }}>
                    <Select
                      name="accounts"
                      value={user}
                      onChange={(e) => {
                        setUserFilter(e);
                        setFilters({ ...filters, email: e.value });
                      }}
                      options={sortBy(allTenants, (a) => a.status.length).reduce(
                        (acc, tenant, i) => {
                          (tenant.status === ACCOUNT_STATUSES.active ||
                            (tenant.status === ACCOUNT_STATUSES.in_active && tenant.stores_count > 0)) &&
                            acc.push({
                              label: tenant.email,
                              value: tenant.email,
                              key: i,
                            });
                          return acc;
                        },
                        [{ value: "", label: "All Tenants" }],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </div>
              </div>
            </CardHeader>
            {refresherStats?.length
              ? (
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {tableCols.map((header, index) => (
                          <th key={`table-header ${index}`}>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {refresherStats?.map((item, key) => (
                        <tr key={"_refresher_items_" + key}>
                          <td>{toPascalCase(item?.supplier)}</td>
                          <td>{item?.total_listing}</td>
                          <td>{item?.in_stock}</td>
                          <td>{item?.out_of_stock}</td>
                          <td>
                            {getStatusStats(item.status_stats).join(", ")}
                          </td>
                          <td>{item?.refreshed_at ? moment(item?.refreshed_at).format(DATE_FORMATS.DATE_TIME) : ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
                )
              : null}
            {refresherStats.length === 0 && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RefreshersView;
