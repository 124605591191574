import React from "react";
import { Row, Col, Container } from "reactstrap";

const SettingAOInstructions = () => {
  const Instructions = [
    {
      title: (
        <h6 className="mb-3">Here is the checklist of what you should verify in your account before you turn it on:</h6>
      ),
      content: [
        {
          description: (
            <li className="mb-1">
              Set up Supplier settings for Amazon using the edit icon on the{" "}
              <a target="_blank" rel="noreferrer" href="/suppliers">
                Suppliers page
              </a>{" "}
              so AO knows if you want to use FBA offers, FBM offers, or some combination.
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              Turn AO on (
              <a target="_blank" rel="noreferrer" href="/suppliers">
                Suppliers page
              </a>{" "}
              for Amazon)
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              Add all of the buying accounts you want to use to the{" "}
              <a target="_blank" rel="noreferrer" href="/credentials">
                Credentials page
              </a>{" "}
              and then make sure that you have "AO Enabled" turned ON for the accounts you want to use with AO. Also
              make sure that you have the "Prime" settings on/off correctly based on if your buying accounts are prime
              or not. We recommend that you add at least 2 accounts to help your accounts last longer and to give you
              some redundancy in case Amazon locks an account.
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              You need to have added the credit cards to the{" "}
              <a target="_blank" rel="noreferrer" href="/credit_cards">
                Credit Cards section
              </a>{" "}
              which are the cards used in the AO Enabled accounts. You need to add the entire credit card number in case
              we need to fix any payment declines. Please note that these cards must be present in your buying accounts
              for AO to work.
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              Turn AO on from the Settings page under the{" "}
              <a target="_blank" rel="noreferrer" href="/settings?account=true">
                Account Settings tab
              </a>
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              Clear out your old orders from the{" "}
              <a target="_blank" rel="noreferrer" href="/source_orders/new">
                New tab
              </a>
              , so they don't get double-processed. You can either select them and then bulk-edit the internal status
              (select the ones you want to edit and a button will appear for you to change the status) OR you can add
              all of your supplier order details for all of your orders into the system which is preferred since then
              you'll have better metrics on your dashboards.
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              Make sure your account has AO credits by checking the{" "}
              <a target="_blank" rel="noreferrer" href="/settings?account=true">
                Account Settings tab of the Settings page
              </a>{" "}
              (you get 100 free credits on us!)
            </li>
          ),
        },
      ],
    },
    {
      title: (
        <h6 className="mb-3">
          <strong>Please Note!!</strong> AO will only process an order if...
        </h6>
      ),
      content: [
        {
          description: (
            <li className="mb-1">
              Verified Match is on for that supplier link (so you can get started having your team turn them all on
              after they verify the match, or if you're sure they are all matches, you can do this in bulk)
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              Order is in New or Error status (orders which have an error due to losing money, being OOS, etc. will be
              retried every few hours for as long as they are in the Error status. However, your team can process these
              manually which will stop AO from trying again).
            </li>
          ),
        },
        {
          description: (
            <li className="mb-1">
              Amazon supplier link is present and set to default ("default setting" only matters if there is {">"} 1
              supplier link)
            </li>
          ),
        },
      ],
    },
    {
      title: (
        <p>
          Before trying to use AO, we HIGHLY recommend that you and your team login to each account through a single IP
          or VPS based in the US. This will greatly reduce the number of password resets and other account issues. If
          you have questions about this, please contact Support at{" "}
          <a href="mailto:support@ecomcircles.com">support@ecomcircles.com</a>
        </p>
      ),
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="text-center mb-5">
              <h4>INSTRUCTIONS FOR SETTING UP AUTO ORDERING</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="justify-content-center align-items-center">
              {Instructions.map((i) => (
                <>
                  {i.title}
                  {i.content && <ul>{i.content.map((ins) => ins.description)}</ul>}
                </>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SettingAOInstructions;
