import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Document, Packer, Paragraph, TextRun, TableRow, TableCell, Table, HeadingLevel } from "docx";
// components
import { Card, CardBody, Container, CardHeader, Alert, Badge, FormGroup, Row, Col, Button } from "reactstrap";
import moment from "moment-timezone";
import Modal from "../../components/Common/Modal";
import Select, { components } from "react-select";
import { startCase, values, groupBy, pick, last, orderBy, isEmpty, keys, compact, map, find, merge } from "lodash";
import CustomPagination from "../../components/Common/CustomPagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EditModal from "./components/EditModal";
import EditPlanGroupModal from "./components/EditPlanGroupModal";
import RequestFiftyModal from "./components/RequestFiftyModal";
import AddBalanceModal from "./components/AddBalanceModal";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import CustomTooltip from "../../components/Common/CustomTooltip";
import PillBtn from "../../components/Common/PillBtn";
import { ConfirmDialogBtn } from "../../components/Common/ConfirmDialog";
import StringEditor from "../Dashboard/components/StringEditor";
import {
  toPascalCase,
  getUserInfo,
  RenderIf,
  parseErr,
  downloadFile,
  formatNumber,
  isSuperAdmin,
} from "../../utils/common";
import {
  ACCOUNT_STATUSES_DROPDOWN,
  ACCOUNT_STATUSES,
  DEV_IDS,
  MARKET_PLACES,
  USER_ROLES,
  MATT_IDS,
  DATE_FORMATS,
  TENANTS_ACTIONS_ACCESS,
} from "../../constants";
// actions
import {
  setPreloader,
  setSelectedTenantAcc,
  setEditTenantAccModal,
  fetchToken,
  setAddAgencyModal,
  setEditPlanGroupModal,
  fetchUserMarketplaceAccount,
  fetchCancelledSubscriptions,
  fetchPlanGroups,
  setAddBalanceModal,
  fetchTenantsStats,
  fetchDisputeLogs,
  updateTosForAll,
  filterAllTenants,
  getPaymentFailedUsers,
  getCanceledUsers,
  getStatusesCount,
  getAgencyDropDown,
} from "../../store/actions";
import getBrandImageSrc from "../../utils/brandImages";

const { FULL_DATE } = DATE_FORMATS;

const STATUS_COLOR_CODE = {
  "Payment Failed": "danger",
  Canceled: "danger",
  Subscribed: "success",
  Trial: "success",
  Onboarding: "primary",
  Agency: "success",
};

const isAssumeRole = (item) => values(ACCOUNT_STATUSES).includes(item.status) && item.stores_count > 0;

const AgenciesModal = (props) => {
  const {
    clientsModal: { isOpen, item: agent },
    setClientsModal,
    allTenants,
  } = props;
  const agencies = allTenants.filter((x) => x.agency_id === agent.id);

  return (
    <Modal size="md" isOpen={isOpen} toggle={() => setClientsModal({ isOpen: false, item: {} })}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Agencies ({agencies.length})
        </h5>
        <div className="d-flex align-items-center">
          <span>Copy All</span>
          <CopyToClipBoard text={agencies.map((x) => x.email).join(", ")} />
        </div>
      </div>
      <div className="px-4" style={{ height: 300, overflow: "auto" }}>
        <table className="table">
          <tbody>
            {agencies.map((x, i) => (
              <tr key={i}>
                <td>
                  {x.email}
                  <CopyToClipBoard text={x.email} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

const isTrailExpired = (acc) => !!acc.trial_end && moment(acc.trial_end).isSameOrBefore(moment());
const isNoSub = (acc) => !acc.agency_stripe_subscription_id && !acc.stripe_subscription_id;
const checkers = {
  normal_user: (acc) => !acc.is_agency || acc.agency_id === acc.id,
  in_trial: (acc) => !!acc.trial_end && moment(acc.trial_end).isAfter(moment()),
  subscribed: (acc) =>
    (acc.agency_stripe_subscription_id || acc.stripe_subscription_id) &&
    (isTrailExpired(acc) || !acc.trial_end) &&
    acc.status !== ACCOUNT_STATUSES_DROPDOWN.payment_failed,
  cancelled: (acc) =>
    isTrailExpired(acc) &&
    (acc.sub_cancelled_at || isNoSub(acc)) &&
    acc.status !== ACCOUNT_STATUSES_DROPDOWN.payment_failed,
  onbaording: (acc) =>
    (!acc.onboard?.payment || !acc.onboard?.store_added || !acc?.payment_source_added) &&
    isNoSub(acc) &&
    !acc.trial_end,
};
const statuses = {
  trial: (x) => checkers.in_trial(x) && checkers.normal_user(x),
  cancelled: (x) => checkers.cancelled(x) && checkers.normal_user(x),
  subscribed: (x) => checkers.subscribed(x) && checkers.normal_user(x) && !x.sub_cancelled_at,
  paymentFailed: (x) => x.status === ACCOUNT_STATUSES_DROPDOWN.payment_failed,
  onBoarding: (x) => checkers.onbaording(x) && checkers.normal_user(x),
  agency: (x) => !x.agency_id || x.is_agency,
};

const getAccStatus = (x) => {
  if (statuses.trial(x)) return "Trial";
  else if (statuses.paymentFailed(x)) return "Payment Failed";
  else if (statuses.cancelled(x)) return "Canceled";
  else if (statuses.onBoarding(x)) return "Onboarding";
  else if (statuses.subscribed(x)) return "Subscribed";
  else if (statuses.agency(x)) return "Agency";
};

const TenantAccounts = () => {
  const {
    allTenants,
    tenantsData,
    success,
    error,
    loading,
    filteredTenants,
    canceledUsers,
    paymentFailed,
    statusesCountData,
    isAddAgencyModalOpen,
    selectedTenantAcc,
    cancelledSubscriptions,
    isEditTenantModalOpen,
    agencyDropDownData,
  } = useSelector((state) => state.Settings);
  const {
    isPlanGroupModalOpen,
    groups,
    updateGroupMessage,
    loading: planLoading,
  } = useSelector((state) => state.CustomPlans);
  const { userAccounts } = useSelector((state) => state.MarketPlaceAccount);
  const {
    addBalanceModalIsOpen,
    loading: userLoading,
    success: userSuccess,
    disputeData,
  } = useSelector((state) => state.User);
  const { agencyStats } = useSelector((state) => state.Agency);
  const [clientsModal, setClientsModal] = useState({ isOpen: false, item: {} });
  const [agencies, setAgencies] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [agencyNameIndex, setAgencyNameIndex] = useState(undefined);
  let search = "";
  const [account, setAccount] = useState();
  const [curAegency, setCurAegency] = useState({ value: "", label: "Select an agency" });
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 30,
    agencyInfo: "",
    groupInfo: "",
    statusInfo: "",
    searchStr: "",
    is_archived: false,
  });
  const [curGroup, setCurGroup] = useState({ value: "", label: "Select Plan Group" });
  const [curStatus, setCurStatus] = useState({ value: "", label: "Select Status" });
  const [sortFilter, setSortFilter] = useState({ sort_by: "created_at", sort_direction: "desc" });
  const counts = statusesCountData?.data;
  const dispatch = useDispatch();
  const tableHeaders = [
    { title: "Name" },
    { title: "Email" },
    { title: "Status" },
    { title: "Store" },
    { title: "Credits (AO)" },
    { title: "Phone" },
    { title: "Agency" },
    { title: "Plan Group" },
    { title: "Plan Amount" },
    { title: "Renewal Date" },
    { title: "Account Creation Date", sortCol: "created_at" },
    { title: "Trial End Date" },
    { title: "Actions" },
  ];
  const cancelledHeaders = [
    { title: "Name" },
    { title: "Email" },
    { title: "Phone" },
    { title: "Agency" },
    { title: "Subscription Cancelled At" },
  ];
  const agencyHeaders = [
    { title: "Agency Name" },
    { title: "Email" },
    { title: "Active Accounts" },
    { title: "Inactive Accounts" },
    { title: "30 Days Changes" },
    { title: "Average Store Profit" },
    { title: "Total  Active Stores" },
    { title: "Total Inactive Stores" },
  ];
  const paymentHeader = [
    { title: "Name" },
    { title: "Email" },
    { title: "Phone" },
    { title: "Agency" },
    { title: "Failed Date" },
  ];
  function setThirtyDaysStats (item) {
    const activeCount = item?.prevActive;
    const inactiveCount = item?.prevInactive;
    if (activeCount > inactiveCount)
      return (
        <div className="d-flex align-items-center">
          {Math.abs(activeCount - inactiveCount)} <i className="bx bx-up-arrow-alt text-success fs-16"></i>
        </div>
      );
    else if (inactiveCount > activeCount)
      return (
        <div className="d-flex align-items-center">
          {Math.abs(inactiveCount - activeCount)} <i className="bx bx-down-arrow-alt text-danger fs-16"></i>
        </div>
      );
    else return <div>No change</div>;
  }

  function fetchTenantToken (email) {
    if (getUserInfo()?.role === USER_ROLES.whStaff) return;
    dispatch(fetchToken(email));
  }

  const SortArrows = ({ col, direction, arrow }) => (
    <i
      id={arrow + "-filter"}
      style={{ fontSize: "10px" }}
      className={`dripicons-arrow-thin-${arrow} cursor-pointer`}
      onClick={() => setSortFilter({ sort_by: col, sort_direction: direction })}
    ></i>
  );

  const getText = (text, opts = { bold: true, break: 2 }) => new TextRun({ text, ...opts });
  const getData = (text) => getText(text, {});
  const getHeading = (text, size) => getText(text, { heading: HeadingLevel.HEADING_1, bold: true, size, break: 2 });
  const searchBar = document.getElementById("__searchForm__");
  function fetchDisputeDoc () {
    const user = last?.(disputeData?.account?.users);
    const disputeDataArr = [
      { heading: "Email: ", text: `${getAgencyEmail(disputeData?.account?.id)}` },
      { heading: "User Name:  ", text: `${user?.username}` },
      {
        heading: "Name:  ",
        text: `${user?.first_name && user?.last_name ? user?.first_name + " " + user?.last_name : "N/A"} `,
      },
      { heading: "IP address:  ", text: `${user?.ip}` || "N/A" },
      { heading: "TOS acceptance date:  ", text: `${disputeData?.account?.created_at}` },
      { heading: "Account creation date:  ", text: `${moment(disputeData?.account?.trial_end).subtract(7, "days")}` },
    ];
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                getHeading("Dispute Document", 40),
                getHeading("Personal Information", 30),
                ...disputeDataArr.map((x) => [getText(x.heading), getData(x.text)]).flat(),
              ],
            }),
            new Paragraph({
              children: [getHeading("Invoices", 30)],
            }),
            getTable(disputeData?.inoviceData, "amount", "last_four", "charge_date", "zip_code"),
            new Paragraph({
              children: [getHeading("Activity Logs", 30)],
            }),
            getTable(disputeData?.logs, "created_at", "author", "note"),
          ],
        },
      ],
    });
    Packer.toBuffer(doc).then((buffer) => {
      const blob = new Blob([buffer], { type: "application/msword;charset=utf-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "DisputeLogs.docx";
      link.click();
    });
  }

  function getTable (item, ...attr) {
    const createTableRow = (row) =>
      keys(row).map(
        (x) =>
          new TableCell({
            children: [
              new Paragraph({
                text: `${toPascalCase(x)}: ${row[x]}`,
              }),
            ],
            columnSpan: 5,
          }),
      );

    const table = new Table({
      columns: attr.length,
      width: 0,
      columnWidths: [1100, 1100, 1100, 1100],
      rows: item?.map(
        (x) =>
          new TableRow({
            children: createTableRow(pick(x, attr)),
          }),
      ),
    });
    return table;
  }

  function fetchSubscriptions () {
    dispatch(fetchCancelledSubscriptions());
  }

  function handleEdit (acc) {
    dispatch(setEditTenantAccModal(true));
    dispatch(setSelectedTenantAcc(acc));
  }

  function handleRequestFiftyForm (acc) {
    dispatch(setAddAgencyModal(!isAddAgencyModalOpen));
    dispatch(setSelectedTenantAcc(acc));
    if (userAccounts?.tenant !== acc.id) dispatch(fetchUserMarketplaceAccount({ id: acc.id }));
  }

  const accountsCount = (currItemId) =>
    agencyDropDownData?.data?.reduce((acc, x) => (x.agency_id === currItemId ? acc + 1 : acc), 0);

  function getAgencyName (tenantId) {
    const tenant = tenantsData?.[tenantId];
    return tenant?.agency_name;
  }

  function makeOpts (agenciesDropdown) {
    return (agenciesDropdown || [])?.reduce(
      (opts, tenant, _i) => {
        if (agencies[tenant.id])
          opts.push({
            label: tenant?.email?.concat("    (" + tenant?.username + ")"),
            value: tenant.id,
            status: tenant.status,
            key: _i,
          });
        return opts;
      },
      curAegency.label === "Select an agency" ? [] : [{ value: "", label: "All Tenants" }],
    );
  }
  const getImageAndCount = (amazonCount, walmartCount, key) => {
    return (
      <>
        <span key={"_store_amazon_" + key}>
          <img src={getBrandImageSrc(MARKET_PLACES?.amazon)} alt="marketplace" />{" "}
          {`${toPascalCase(MARKET_PLACES?.amazon)} ${amazonCount}`}
        </span>
        <span key={"_store_walmart_" + key}>
          <img src={getBrandImageSrc(MARKET_PLACES?.walmart)} alt="marketplace" />{" "}
          {`${toPascalCase(MARKET_PLACES?.walmart)} ${walmartCount}`}
        </span>
      </>
    );
  };

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div className="d-flex flex-row align-items-center">
        {props.data?.label !== "All Tenants" && (
          <i
            className={`mr-2 bx bx-sm ${
              props.data?.status === "active" ? "bx-check text-success" : "bx-x text-warning"
            }`}
          ></i>
        )}
        {props.data?.label}
      </div>
    </Option>
  );

  useEffect(() => {
    !Array.isArray(groups) && dispatch(fetchPlanGroups());
  }, [groups]);

  useEffect(() => {
    !isEmpty(disputeData) && fetchDisputeDoc();
  }, [disputeData]);

  useEffect(() => {
    if (Array.isArray(agencyDropDownData?.data)) {
      setAgencies(
        groupBy(
          agencyDropDownData?.data.filter((acc) => !acc.agency_id || acc.is_agency),
          (acc) => acc.id,
        ),
      );
    }
  }, [agencyDropDownData?.data]);

  useEffect(() => {
    !loading && !cancelledSubscriptions && fetchSubscriptions();
    dispatch(fetchTenantsStats());
  }, []);

  useEffect(() => {
    dispatch(filterAllTenants({ ...filters, ...sortFilter }));
  }, [filters, sortFilter]);

  useEffect(() => {
    dispatch(setPreloader(planLoading || userLoading || loading));
  }, [planLoading, userLoading, loading]);

  useEffect(() => {
    dispatch(getPaymentFailedUsers());
    dispatch(getCanceledUsers());
    dispatch(getStatusesCount());
    dispatch(getAgencyDropDown());
  }, []);

  const paymentFailedUsers = paymentFailed?.data?.paymentFailedData;
  const canceledUsersData = canceledUsers?.data?.canceledData;
  const getUser = (users, email) => users?.find((x) => x.email === email);

  function getName (users, email) {
    const user = (Array.isArray(users) ? users.find((x) => x?.email === email) : users) || {};
    return compact([user.first_name, user.last_name]).join(" ") || user.username;
  }

  function getActualAgency (acc, isEmpty) {
    if (!acc.agency_id || acc.id === acc.agency_id) return isEmpty ? "" : "N/A";
    const users = tenantsData?.[acc.agency_id]?.users || [];
    return getName(find(users, (user) => user?.role === "owner"));
  }

  function getAgencyEmail (accId) {
    const acc = tenantsData?.[accId] || {};
    if (acc.is_agency && acc.agency_id === null) return acc.email;
    return tenantsData?.[acc.agency_id]?.email;
  }

  const exportCsvPredicate = (acc) => ({
    Name: getName(acc?.users, acc?.email),
    Email: acc.email,
    Status: toPascalCase(getAccStatus(acc)),
    Stores: acc?.store_names?.split("< ")?.join(", ") || "",
    "AO Credits": acc.ao_credits,
    Phone: getUser(acc?.users, acc?.email)?.phone || "",
    Agency: getActualAgency(acc, true),
    "Plan Group": find(groups, (grp) => grp.id === acc?.plan_group_id)?.name || "",
    "Current Plan": acc?.current_plan && acc?.stripe_subscription_id ? formatNumber(acc.current_plan, "currency") : "",
    "Trial End": acc?.trial_end ? moment(acc?.trial_end) : "",
    "Current Plan Ends": acc?.stripe_subscription_id && acc?.last_sub_end ? moment(acc.last_sub_end) : "",
  });

  const allTenantsData = filteredTenants?.data;

  const getStoreNames = (storeNames, id) => {
    if (!storeNames) return "No Store Added";
    storeNames = storeNames.split("< ").join(", ");
    return (
      <>
        <div id={`store_name_${id}`}>{storeNames.substring(0, 14) + "..."}</div>
        <CustomTooltip placement="left" target={`store_name_${id}`} content={storeNames} />
      </>
    );
  };

  return (
    <React.Fragment>
      {isEditTenantModalOpen && selectedTenantAcc && <EditModal />}
      {isAddAgencyModalOpen && <RequestFiftyModal />}
      {addBalanceModalIsOpen && <AddBalanceModal account={account} />}

      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between flex-wrap">
            <h4 className="tentent-h5">Tenant Accounts</h4>
            <div className="d-flex align-items-center mb-3">
              <div className="custom-control custom-checkbox mr-16 mt-1" style={{ width: "150px" }}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="blocked"
                  checked={filters.is_archived}
                />
                <label
                  onClick={() => setFilters({ ...filters, is_archived: !filters.is_archived, page: 1, per_page: 30 })}
                  className="custom-control-label"
                >
                  Include archived
                </label>
              </div>
              <ConfirmDialogBtn
                button={{
                  color: "success",
                  title: "Update TOS",
                  icon: "bx bx-edit-alt",
                }}
                msg={
                  <>
                    <p>You want users to accept terms of services again?</p>
                  </>
                }
                onConfirm={() => dispatch(updateTosForAll())}
              />
              <PillBtn
                color="primary"
                title="Export to CSV"
                name="Export to CSV"
                icon="bx bx-xs bx-cloud-download ml-1"
                onClick={() => downloadFile(map(filteredTenants?.csvData, exportCsvPredicate), "_tenants_exported")}
              />
            </div>
          </div>
          <div className="d-flex grid-colum-gap-5 flex-wrap justify-content-end width-100-sm">
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>New Users Today</span>
              <h6 className="mb-0">{counts?.newUsersCount}</h6>
            </Card>
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>Onboarding</span>
              <h6 className="mb-0">{counts?.onboardingCount}</h6>
            </Card>
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>Trial</span>
              <h6 className="mb-0">{counts?.trialCount}</h6>
            </Card>
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>Subscribed</span>
              <h6 className="mb-0">{counts?.subscribedCount}</h6>
            </Card>
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>Canceled</span>
              <h6 className="mb-0">{counts?.canceledCount}</h6>
            </Card>
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>Payment Failed</span>
              <h6 className="mb-0">{counts?.payemntFailedCount}</h6>
            </Card>
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>Agencies</span>
              <h6 className="mb-0">{counts?.agenciesCount}</h6>
            </Card>
            <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
              <span>Agencies Active Accounts</span>
              <h6 className="mb-0">{counts?.agencyUsersCount}</h6>
            </Card>
            {filters.is_archived && (
              <Card className="d-flex flex-direction-row align-items-center grid-colum-gap-5 p-2">
                <span>Archived Accounts</span>
                <h6 className="mb-0">{counts?.archivedCount}</h6>
              </Card>
            )}
          </div>
          {(success || updateGroupMessage || userSuccess) && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {success || updateGroupMessage || userSuccess}
              </Alert>
            </div>
          )}
          {error && !selectedTenantAcc && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {parseErr(error)}
              </Alert>
            </div>
          )}
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col md={3} sm={12}>
                  <div className="d-flex align-items-center">
                    <form
                      className="app-search  d-lg-block w-100"
                      id="__searchForm__"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (search.trim()) {
                          setFilters(
                            merge(
                              { page: 1, per_page: 30, search: search.trim() },
                              !!filters.is_archived && { is_archived: filters.is_archived },
                            ),
                          );
                          setCurGroup({ value: "", label: "Select Plan Group" });
                          setCurStatus({ value: "", label: "Select Status" });
                          setCurAegency({ value: "", label: "Select an agency" });
                        }
                      }}
                    >
                      <div className="position-relative pr-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            if (!e.target.value) {
                              delete filters.search;
                              search = "";
                              setFilters({ ...filters, page: 1, per_page: 30 });
                            } else search = e.target.value;
                          }}
                        />
                        <span className="bx bx-search-alt"></span>
                      </div>
                    </form>
                    <div className="vertical-line-2" />
                  </div>
                </Col>
                <Col md={9} sm={12}>
                  <div className="d-flex grid-column-gap-10 select-accounts">
                    <FormGroup className="select2-container">
                      <Select
                        name="site"
                        value={{
                          value: curAegency?.value || "",
                          label: curAegency?.label || "",
                        }}
                        onChange={(e) => {
                          delete filters.search;
                          searchBar.reset();
                          setCurAegency({ label: e.value === "" ? "Select an agency" : e.label });
                          setFilters({ ...filters, page: 1, per_page: 30, agencyInfo: +e.value });
                        }}
                        options={makeOpts(agencyDropDownData?.data)}
                        classNamePrefix="select2-selection"
                        components={{ Option: IconOption }}
                      />
                    </FormGroup>
                    <FormGroup className="select2-container">
                      <Select
                        name="status filter"
                        value={{
                          value: curStatus?.value || "",
                          label: curStatus?.label || "",
                        }}
                        onChange={(e) => {
                          delete filters.search;
                          searchBar.reset();
                          setCurStatus({ label: e.value === "" ? "Select Status" : e.label });
                          setFilters({ ...filters, page: 1, per_page: 30, statusInfo: e.value });
                        }}
                        options={Object.values(ACCOUNT_STATUSES_DROPDOWN).reduce(
                          (x, y) => {
                            x.push({ label: startCase(y), value: y });
                            return x;
                          },
                          curStatus.label === "Select Status" ? [] : [{ value: "", label: "All Statuses" }],
                        )}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>

                    {groups && (
                      <FormGroup className="select2-container">
                        <Select
                          name="Group filter"
                          value={{
                            value: curGroup?.value || "",
                            label: curGroup?.label || "",
                          }}
                          onChange={(e) => {
                            delete filters.search;
                            searchBar.reset();
                            setCurGroup({ label: e.value === "" ? "Select Plan Group" : e.label });
                            setFilters({ ...filters, page: 1, per_page: 30, groupInfo: +e.value });
                          }}
                          options={groups.reduce(
                            (x, y) => {
                              x.push({ label: y.name, value: y.id });
                              return x;
                            },
                            curGroup.label === "Select Plan Group" ? [] : [{ value: "", label: "All Groups" }],
                          )}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    )}
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf
                isTrue={Array.isArray(allTenantsData) && allTenantsData?.length && !loading}
                fallback={<h4 className="text-center">Loading...</h4>}
              >
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <th
                              className={
                                /status|trial end date|actions|completed trial/i.test(header.title) ? "text-center" : ""
                              }
                              key={`table-header ${index}`}
                            >
                              {header.title}
                              {header.sortCol
                                ? (
                                <div className="d-inline">
                                  <SortArrows col={header.sortCol} direction={"asc"} arrow={"up"} />
                                  <SortArrows col={header.sortCol} direction={"desc"} arrow={"down"} />
                                </div>
                                  )
                                : null}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allTenantsData?.map((item, key) => (
                          <tr key={"_listings_" + key} className="mt-3 mb-3">
                            <td>{getName(item?.users, item?.email)}</td>
                            <td>
                              {DEV_IDS.concat(MATT_IDS).includes(getUserInfo().id) && `${item.id}: `}
                              {item?.email?.toLowerCase()}
                              <CopyToClipBoard text={item?.email?.toLowerCase()} />
                              {item?.id && agencies[item.id]
                                ? (
                                <i
                                  title="View Agencies"
                                  className="px-1 bx bx-xs bx-detail cursor-pointer"
                                  onClick={() => setClientsModal({ item, isOpen: true })}
                                ></i>
                                  )
                                : null}
                            </td>
                            <td className="text-center">
                              <Badge
                                className={"font-size-12 badge-soft-" + STATUS_COLOR_CODE[getAccStatus(item)]}
                                color={STATUS_COLOR_CODE[getAccStatus(item)]}
                                pill
                              >
                                {toPascalCase(getAccStatus(item))}
                              </Badge>
                            </td>
                            <td>{getStoreNames(item?.store_names, item?.id)}</td>
                            <td className="text-center">{item.ao_credits}</td>
                            <td>{getUser(item?.users, item?.email)?.phone || "N/A"}</td>
                            <td>{item?.agency_name || "N/A"}</td>
                            <td>{groups?.find((x) => x.id === item?.plan_group_id)?.name || ""}</td>
                            <td>
                              {item?.current_plan &&
                                item?.stripe_subscription_id &&
                                formatNumber(item.current_plan)}
                            </td>
                            <td>
                              {(item?.stripe_subscription_id &&
                                item?.last_sub_end &&
                                moment(item.last_sub_end).format(FULL_DATE)) ||
                                ""}
                            </td>
                            <td className="text-center">
                              {(item?.created_at && moment(item?.created_at).format(FULL_DATE)) || ""}
                            </td>
                            <td className="text-center">
                              {(item?.trial_end && moment(item?.trial_end).format(FULL_DATE)) || ""}
                            </td>
                            <td className="d-flex align-items-center">
                              <RenderIf
                                isTrue={TENANTS_ACTIONS_ACCESS.filter((x) => x !== 27).includes(getUserInfo()?.id)}
                              >
                                <i
                                  title="Edit"
                                  className="bx bx-sm bx-edit p-1 text-primary cursor-pointer"
                                  onClick={() => handleEdit(item)}
                                ></i>
                              </RenderIf>
                              <i
                                title={`${isAssumeRole(item) ? "" : "Cannot "}Assume Role`}
                                className={`bx bx-sm bx-log-in p-1 text-${
                                  isAssumeRole(item) ? "primary" : "gray"
                                } cursor-${isAssumeRole(item) ? "pointer" : "not-allowed"}`}
                                onClick={() => isAssumeRole(item) && fetchTenantToken(item.email)}
                                disabled={true}
                              ></i>
                              <RenderIf isTrue={item?.store_names}>
                                <i
                                  title="Request Matches"
                                  className="bx bx-sm bxs-add-to-queue p-1 text-primary cursor-pointer"
                                  onClick={() => handleRequestFiftyForm(item)}
                                ></i>
                              </RenderIf>
                              <RenderIf isTrue={isSuperAdmin()}>
                                <i
                                  title="Change Plan/Group"
                                  className="bx bx-sm bx-dollar-circle text-primary  p-1 cursor-pointer"
                                  onClick={() => {
                                    setAccount(item);
                                    dispatch(setEditPlanGroupModal(true));
                                  }}
                                ></i>
                              </RenderIf>
                              <i
                                title="Add Alternative Balance"
                                className="bx bx-sm bx-purchase-tag p-1 text-primary cursor-pointer"
                                onClick={() => {
                                  setAccount(item);
                                  dispatch(setAddBalanceModal(true));
                                }}
                              ></i>
                              <i
                                title="Dispute Document"
                                className="mdi bx-sm mdi-clipboard-alert-outline p-1 text-primary cursor-pointer"
                                onClick={() => dispatch(fetchDisputeLogs(item.id))}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {isPlanGroupModalOpen ? <EditPlanGroupModal account={account} /> : null}
                    {allTenants
                      ? (
                      <AgenciesModal
                        allTenants={allTenants}
                        setClientsModal={setClientsModal}
                        clientsModal={clientsModal}
                      />
                        )
                      : null}
                  </div>
                  <div className="tent-pagination">
                    <CustomPagination
                      pageOptions={[30, 50, 100]}
                      total={filteredTenants?.count}
                      page={filteredTenants?.page}
                      perPage={filteredTenants?.per_page}
                      tabsFilter={filters}
                      setTabFilter={(e) => setFilters(filters.per_page !== e.per_page ? { ...e, page: 1 } : e)}
                      inAgencyComponent={true}
                    />
                  </div>
                </>
              </RenderIf>
              <RenderIf isTrue={allTenantsData?.length === 0 && !loading}>
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
              </RenderIf>
            </CardBody>
          </Card>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-4">
                <h5 className="tentent-h5">Payment Failed Users</h5>
              </div>
              <Card>
                {paymentFailedUsers?.length
                  ? (
                  <>
                    <div className="table-responsive mx-h-500">
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="thead-light">
                          <tr>
                            {paymentHeader.map((header, index) => (
                              <React.Fragment key={`table-header ${index}`}>
                                <th>{header.title}</th>
                              </React.Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {orderBy(paymentFailedUsers, ["payment_failed_date"], ["desc"])?.map((item, key) => (
                            <tr key={"_payment_failed_" + key} className="mt-3 mb-3">
                              <td>{getName(item?.users, item?.email)}</td>
                              <td>
                                {DEV_IDS.includes(getUserInfo().id) && `${item.id}: `}
                                <span id={`payment_failed_name_${item.id}`}>{item?.email}</span>
                                <CopyToClipBoard text={item?.email?.toLowerCase()} />
                              </td>
                              <td>{getUser(item?.users, item?.email)?.phone || "N/A"}</td>
                              <td>{getActualAgency(item)}</td>
                              <td>
                                {(item?.payment_failed_date
                                  ? moment(item?.payment_failed_date).format(FULL_DATE)
                                  : "N/A") || ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                    )
                  : null}
                {paymentFailedUsers?.length === 0
                  ? (
                  <div className="d-flex justify-content-center m-2">
                    <div className="d-flex flex-column">
                      <h1>No Records Found</h1>
                    </div>
                  </div>
                    )
                  : null}
              </Card>
            </div>

            <div className="col-md-6">
              <div className="mb-4">
                <h5 className="tentent-h5">RECENTLY CANCELLED USERS</h5>
              </div>
              <Card>
                {canceledUsersData?.length
                  ? (
                  <>
                    <div className="table-responsive mx-h-500">
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="thead-light">
                          <tr>
                            {cancelledHeaders?.map((header, index) => (
                              <React.Fragment key={`table-header ${index}`}>
                                <th>
                                  {header.title === "Full Name"
                                    ? (
                                    <>
                                      <span>{header.title}</span>
                                    </>
                                      )
                                    : (
                                        header.title
                                      )}
                                </th>
                              </React.Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {canceledUsersData?.map((item, key) => (
                            <tr key={"_cancelled_" + key} className="mt-3 mb-3">
                              <td>{getName(item?.users, item?.email)}</td>
                              <td>
                                {DEV_IDS.includes(getUserInfo().id) && `${item.id}: `}
                                <span id={`cancelled_name_${item.id}`}>{item?.email}</span>
                                <CopyToClipBoard text={item?.email?.toLowerCase()} />
                              </td>
                              <td>{getUser(item?.users, item?.email)?.phone || "N/A"}</td>
                              <td>{getActualAgency(item)}</td>
                              <td>{moment(item?.sub_cancelled_at).format(FULL_DATE) || " "}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                    )
                  : null}
                {canceledUsersData?.length === 0
                  ? (
                  <div className="d-flex justify-content-center m-2">
                    <div className="d-flex flex-column">
                      <h1>No Records Found</h1>
                    </div>
                  </div>
                    )
                  : null}
              </Card>
            </div>
          </div>
          <Breadcrumbs title="Agency accounts" />
          <Card>
            <CardBody>
              {agencyStats?.result?.length
                ? (
                <>
                  <div className="table-responsive mx-h-500">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {agencyHeaders.map((header, index) => (
                            <th key={`table-header ${index}`}>
                              {header.title === "30 Days Changes"
                                ? (
                                <>
                                  {header.title} <i id="tenantEmail" className="bx bx-info-circle" />
                                  <CustomTooltip
                                    placement="top"
                                    target="tenantEmail"
                                    content="How many accounts this agency added or lost for past 30 days"
                                  ></CustomTooltip>
                                </>
                                  )
                                : (
                                    header.title
                                  )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(tenantsData)?.length > 1 &&
                          orderBy(
                            agencyAccountsStats(agencyStats, getAgencyName, accountsCount),
                            ["active_accounts"],
                            ["desc"],
                          ).map((item, key) => (
                            <tr key={"_listings_" + key} className="mt-3 mb-3">
                              <td>
                                <RenderIf isTrue={agencyNameIndex !== item.id}>
                                <Button
                                  tag="i"
                                  style={{ textDecoration: "none", fontStyle: "normal" }}
                                  onClick={() => {
                                    const email = tenantsData?.[item.id]?.email;
                                    if (getUserInfo()?.role === USER_ROLES.whStaff) return;
                                    dispatch(fetchToken(email, {}));
                                  }}
                                  color="link"
                                >
                                  {item.agency_name}
                                </Button>
                                </RenderIf>
                                <RenderIf isTrue={!agencyNameIndex || agencyNameIndex === item.id}>
                                    <StringEditor
                                      index={key}
                                      id={item?.id}
                                      string={item.agency_name}
                                      isEditable={isEditable}
                                      setIsEditable={setIsEditable}
                                      setAgencyNameIndex={setAgencyNameIndex}
                                      hideString={true}
                                      type={"allTenantPage"}
                                    />
                                  </RenderIf>
                              </td>
                              <td>
                                {DEV_IDS.includes(getUserInfo().id) && `${item.id}: `}
                                <span> {tenantsData?.[item.id]?.email}</span>
                                <CopyToClipBoard text={tenantsData?.[item.id]?.email?.toLowerCase()} />
                              </td>
                              <td>{item?.active_accounts}</td>
                              <td>{item?.account_count - item?.active_accounts}</td>
                              <td>{setThirtyDaysStats(item)}</td>
                              <td>{item?.avg_active_profit?.toFixed(2) || 0}</td>
                              <td>
                                {+item?.active_amazon + +item?.active_walmart} ={" "}
                                {getImageAndCount(+item?.active_amazon, +item?.active_walmart, key)}
                              </td>
                              <td>
                                {+item?.amazon - +item?.active_amazon + (+item?.walmart - +item?.active_walmart)} ={" "}
                                {getImageAndCount(
                                  +item?.amazon - +item?.active_amazon,
                                  +item?.walmart - +item?.active_walmart,
                                  key,
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
                  )
                : null}
              {agencyStats?.result?.length === 0
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

function agencyAccountsStats (agencyStats, getAgencyName, accountsCount) {
  return agencyStats?.result?.map((item) => {
    return {
      ...item,
      agency_name: toPascalCase(getAgencyName(item?.id)),
      account_count: accountsCount(item?.id),
      active_accounts: +item?.activeAccounts,
    };
  });
}

export default TenantAccounts;
