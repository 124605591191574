import { makeQP } from "../../utils/common";
import request from "../restClient";
const resource = "/api/v1/auto_orders";

export const getAllListings = async (params) => {
  const url =
    resource +
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${typeof params[key] === "object" ? JSON.stringify(params[key]) : params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const fetchMarketplaceProductInfo = async (params) => {
  const url =
    "/matches/fetch_marketplace_product_info?" +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const fetchShippingTemplate = async (params) => {
  const url = "/api/v1/shipping_templates" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const updateListingWeight = async (body) => {
  const url = "/api/v1/auto_orders/update_weight";
  return await request({ url, method: "PUT", body });
};

export const syncShippingTemplate = async (params) => {
  const url = "/api/v1/shipping_templates/sync_shipping_templates" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const fetchMatchingLogs = async (params) => {
  const url =
    "/matching_logs?" +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const assignShippingTemplate = async ({ data, params }) => {
  const url =
    "/api/v1/shipping_templates/assign_shipping_templates?" +
    Object.keys(params)
      .map((key) => `${key}=${typeof params[key] === "object" ? JSON.stringify(params[key]) : params[key]}`)
      .join("&");
  return await request({ url, method: "POST", body: data });
};

export const updateShippingTemplate = async ({ data, params }) => {
  const url = `/api/v1/shipping_templates/${params}`;
  return await request({ url, method: "PATCH", body: data });
};

export const updateUPC = async (data) => {
  const url = "/api/v1/suppliers/update_upc_for_listings";
  return await request({ url, method: "POST", body: data });
};

export const checkListingOnStore = async (params) => {
  const url = "/warehouse_inventories/check_listing_on_store" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const changeProductVariation = async (params) => {
  const url =
    "/matches/change_product_variation?" +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const changeQuantityMultiplier = async (params) => {
  const url =
    "/matches/change_quantity_multiplier?" +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const setupManualMatches = (params) =>
  request({ url: `/matches/setup_manual_matches${makeQP(params)}`, method: "POST" });

export const addInventoryListing = (body) => request({ url: "/warehouse_inventories/listing", method: "POST", body });

export const addCSVListings = async ({ data, id }) => {
  const url = `/api/v1/auto_orders/upload_listing_suppliers?partner_id=${id}`;
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return await request({ url, method: "POST", formData });
};

export const uploadFacebookListing = async ({ data, id }) => {
  const url = `/api/v1/auto_orders/upload_facebook_listings?partner_id=${id}`;
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return await request({ url, method: "POST", formData });
};

export const downloadListings = async (params) => {
  const url =
    "/api/v1/auto_orders/download_listings?" +
    Object.keys(params)
      .map((key) => `${key}=${typeof params[key] === "object" ? JSON.stringify(params[key]) : params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const getAllUploadedFiles = async (params) => {
  const url =
    "/accounts/uploaded_files?" +
    Object.keys(params)
      .map((key) => `${key}=${typeof params[key] === "object" ? JSON.stringify(params[key]) : params[key]}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const bulkUpdateListings = async (data) => {
  const url = "/api/v1/suppliers/bulk_update_auto_ordering_status";
  return await request({ url, method: "POST", body: data });
};

export const deleteBulkListings = async ({ data, params }) => {
  const url =
    `${resource}/remove_listings?` +
    Object.keys(params)
      .map((key) => `${key}=${typeof params[key] === "object" ? JSON.stringify(params[key]) : params[key]}`)
      .join("&");
  return await request({ url, method: "DELETE", body: data });
};

export const relistRetiredListings = async (data) => {
  const url = `${resource}/re_list_listings`;
  return await request({ url, method: "POST", body: data });
};

export const updateMapOrHandlingTime = async (data) => {
  const url = `${resource}/update_listing`;
  return await request({ url, method: "POST", body: data });
};

export const bulkUpdateHandlingTime = async (data) => {
  const url = `${resource}/update_handling_time`;
  return await request({ url, method: "POST", body: data });
};

export const fetchMatchingPlatforms = async () => {
  const url = "/platforms";
  return await request({ url, method: "GET" });
};

export const addMatchingPlatforms = async (data) => {
  const url = "/platforms";
  return await request({ url, method: "POST", body: data });
};

export const getListingsCount = async () => {
  const url = "/api/v1/auto_orders/refreshable_listings_count";
  return await request({ url, method: "GET" });
};

export const bulkUpdateTwoStepListing = async (data) => {
  const url = "/api/v1/suppliers/bulk_update_2_step";
  return await request({ url, method: "POST", body: data });
};

export const deleteTemplateAPI = async (templateId) => {
  return await request({
    url: `/api/v1/shipping_templates/${templateId}`,
    method: "DELETE",
  });
};

export const createTemplateAPI = async (body) => {
  return await request({
    url: "/api/v1/shipping_templates",
    method: "POST",
    body,
  });
};

export const fetchInventoryItemInfoAPI = (sku) => request({ url: `/inventory/${sku}`, method: "GET" });
