import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllSourceOrders,
  getAllSourceOrderDetail,
  importOrderManually,
  uploadOrdersFile,
  updateSourceOrderDetail,
  updateAddressBySourceOrderId,
  isOrderable,
  bulkUpdateOrder,
  cancelMarketplaceOrder,
  getNextSourceOrderByStatus,
  getLockStatus,
  fetchAmazonStatus,
  getSuppliersForSku,
  dowloadSourceOrderReport,
  downloadVAPerformanceReport,
  downloadOrderProcessorReport,
  getFeedStatus,
  getAvailablePaymentReports,
  downloadPaymentReport,
  addOrderNote,
  fetchCategory,
  uploadTrackingDetails,
  updateStatusToError,
  changeOrderType,
  searchAllOrders,
  generateShippingLabel,
  getShippingRates,
  getItemsDimensions,
  assignOutboundItem,
  createSourceItemLabel,
  genOrdersReport,
} from "../../api";
// Login Redux States
import * as types from "./actionTypes";
import * as actions from "./actions";
import * as api from "../../api/SourceOrders";
import { flatten, merge } from "lodash";
import { fetchReports } from "../actions";
const {
  FETCH_SOURCE_ORDERS,
  CREATE_ORDER_NOTE,
  ORDERS_BULK_UPDATE,
  FETCH_SOURCE_ORDER_DETAIL,
  UPLOAD_TRACKING_DETAILS,
  UPDATE_SOURCE_ORDERS,
  UPDATE_SOURCE_ADDRESS,
  FETCH_MARKETPLACE_STATUS,
  GET_FEED_STATUS,
  CANCEL_MARKETPLACE_ORDER,
  FETCH_NEXT_ORDER,
  FETCH_LOCK_STATUS,
  REQUEST_SOURCE_ORDER_REPORT,
  REQUEST_VA_PERFORMANCE_REPORT,
  REQUEST_ORDER_PROCESSOR_REPORT,
  REQUEST_AVAILABLE_PAYMENT_REPORTS,
  REQUEST_PAYMENT_REPORT,
  IMPORT_ORDER_MANUALLY,
  UPLOAD_ORDER_MANUALLY,
  UPDATE_SOURCE_ORDER_STATUS,
  CHANGE_ORDER_TYPE,
  SEARCH_ALL_ORDERS,
  GENERATE_SHIPPING_LABEL,
  GET_SHIPPING_RATES,
  GET_ITEMS_DIMENSIONS,
  ASSIGN_INVENTORY_TO_ORDER,
  UPLOAD_SOURCE_ITEMS_LABEL,
} = types;
const {
  apiError,
  fetchSourceOrdersSuccess,
  fetchSourceOrderDetailSuccess,
  bulkUpdateOrdersSuccess,
  setSelectedOrders,
  updateSourceOrderSuccess,
  updateAddressSuccess,
  fetchMarketPlaceStatusSuccess,
  fetchNextOrderSuccess,
  refreshSourceOrderDetail,
  setItemsForProcessing,
  cancelMarketplaceOrderSuccess,
  getFeedStatusSuccess,
  fetchLockStatusSuccess,
  requestSourceOrderReportSuccess,
  requestVAPerformanceReportSuccess,
  requestOrderProcessorReportSuccess,
  requestAvailablePaymentReportsSuccess,
  requestPaymentReportSuccess,
  importOrderManuallySuccess,
  uploadOrdersFileSuccess,
  setOrdersImportModal,
  createOrderNoteSuccess,
  setTrackingUploadSuccess,
  setOrderLogModal,
  updateSourceOrderStatusSuccess,
  changeOrderTypeSuccess,
  searchAllOrdersSuccess,
  generateShippingLabelSuccess,
  getShippingRatesSuccess,
  getItemsDimensionsSuccess,
  assignInventorySuccess,
  fetchSourceOrderDetail,
  uploadSourceItemsLabelSuccess,
} = actions;

const SourceOrders = (state) => state.SourceOrders;

function * handleReportResponse () {
  yield put(fetchReports({}));
}

function * uploadSourceItemsLabelSaga ({ payload }) {
  try {
    const res = yield call(createSourceItemLabel, payload);
    const {
      SourceOrders: { sourceOrderDetail },
    } = yield select();
    merge(sourceOrderDetail.data, res.order, { outbound: res.data });
    yield put(uploadSourceItemsLabelSuccess(res));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * fetchSourceOrdersSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllSourceOrders, params);
    if (res) {
      const supplierSkus = { walmart: [], amazon: [], shopify: [] };
      let temp;
      res.data.forEach((item) => {
        switch (item.store_name) {
          case "amazon":
            temp = item?.source_items.map((data) => supplierSkus.amazon.push(data.sku));
            break;
          case "walmart":
            temp = item?.source_items.map((data) => supplierSkus.walmart.push(data.sku));
            break;
          case "shopify":
            temp = item?.source_items.map((data) => supplierSkus.shopify.push(data.sku));
            break;
        }
      });
      try {
        temp = flatten(Object.values(supplierSkus));
        const allCategories = yield call(fetchCategory, temp);
        res.productCategories = allCategories.data;
      } catch (error) {
        res.productCategories = [];
      }
      try {
        const skus = yield call(getSuppliersForSku, supplierSkus);
        res.skuSupplier = Object.keys(skus.results).reduce((newObj, sku) => {
          newObj[sku.trim()] = skus.results[sku];
          return newObj;
        }, {});
      } catch (err) {
        res.skuSupplier = [];
      }
      yield put(fetchSourceOrdersSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchSourceOrderDetailSaga ({ payload: { id } }) {
  try {
    const res = yield call(getAllSourceOrderDetail, id);
    const allSkus = res?.data?.source_items.map((x) => x.sku);
    const allCategories = yield call(fetchCategory, allSkus);
    res.productCategories = allCategories.data;
    yield put(fetchSourceOrderDetailSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * searchAllOrdersSaga ({ payload: { params } }) {
  try {
    const res = yield call(searchAllOrders, params);
    yield put(searchAllOrdersSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * importOrderManuallySaga ({ payload: { orderId, storeId } }) {
  try {
    const res = yield call(importOrderManually, orderId, storeId);
    yield put(importOrderManuallySuccess(res));
    yield put(setOrdersImportModal(false));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * uploadOrdersFileSaga ({ payload: { data } }) {
  try {
    const res = yield call(uploadOrdersFile, data);
    yield put(uploadOrdersFileSuccess(res.success));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchNextSourceOrderSaga ({ payload: { id } }) {
  try {
    const { data } = yield call(getNextSourceOrderByStatus, id);
    yield put(fetchNextOrderSuccess(data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateSourceOrdersSaga ({ payload: { id, data, showSuccess } }) {
  try {
    const res = yield call(updateSourceOrderDetail, { id, data });
    if (res?.data) {
      let order;
      const isManual = data.status === "manual_fulfillment" && res.data.status === "manual_fulfillment";
      if (isManual) {
        const { sourceOrderDetail } = yield select(SourceOrders);
        order = { ...sourceOrderDetail?.data };
      }
      yield put(refreshSourceOrderDetail());
      yield put(updateSourceOrderSuccess({ ...res, showSuccess }));
      if (isManual) {
        yield put(setItemsForProcessing(order?.source_items));
      }
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateAddressSaga ({ payload: { id, data } }) {
  try {
    yield call(updateAddressBySourceOrderId, { id, data });
    yield put(refreshSourceOrderDetail());
    yield put(updateAddressSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkUpdateOrdersSaga ({ payload: { data } }) {
  try {
    yield call(bulkUpdateOrder, data);
    yield put(bulkUpdateOrdersSuccess());
    yield put(setSelectedOrders([]));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchMarketPlaceStatusSaga ({ payload: { id, storeName } }) {
  try {
    let res;
    if (storeName.toLowerCase() === "amazon") {
      res = yield call(fetchAmazonStatus, id);
    }
    if (storeName.toLowerCase() === "walmart") {
      res = yield call(isOrderable, id);
    }
    if (storeName.toLowerCase() === "shopify") {
      res = yield call(api.fetchShopifyStatus, id);
    }
    yield put(refreshSourceOrderDetail());
    yield put(fetchMarketPlaceStatusSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * cancelMarketplaceOrderSaga ({ payload: { id, cancel_reason: cancelReason } }) {
  try {
    const res = yield call(cancelMarketplaceOrder, { id, cancel_reason: cancelReason });
    yield put(refreshSourceOrderDetail());
    yield put(cancelMarketplaceOrderSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchLockStatusSaga ({ payload: { id } }) {
  try {
    const res = yield call(getLockStatus, id);
    yield put(fetchLockStatusSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * dowloadSourceOrderReportSaga ({ payload }) {
  try {
    let apiFunc = dowloadSourceOrderReport;

    if (payload.report_type === "order") {
      apiFunc = genOrdersReport;
      payload = { query: payload };
    }

    const res = yield call(apiFunc, payload);
    if (res?.report) yield call(handleReportResponse, res);
    yield put(requestSourceOrderReportSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadVAPerformanceReportSaga ({ payload }) {
  try {
    const res = yield call(downloadVAPerformanceReport, payload);
    yield put(requestVAPerformanceReportSuccess({ data: res?.data, isExport: payload.is_export }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * downloadOrderProcessorReportSaga ({ payload }) {
  try {
    const res = yield call(downloadOrderProcessorReport, payload);
    yield put(
      requestOrderProcessorReportSuccess({
        data: res.data?.filter((x) => x.placed_by !== "Unknown"),
        isExport: payload.is_export,
      }),
    );
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getFeedStatusSaga ({ payload }) {
  try {
    const res = yield call(getFeedStatus, payload);
    if (res.success) yield put(refreshSourceOrderDetail());
    yield put(getFeedStatusSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getAvailablePaymentReportsSaga ({ payload }) {
  try {
    const res = yield call(getAvailablePaymentReports, payload);
    yield put(requestAvailablePaymentReportsSuccess(res.dates, res.extraCols, res.reports));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getPaymentReportSaga ({ payload }) {
  try {
    const res = yield call(downloadPaymentReport, payload);
    if (res.report) yield call(handleReportResponse, res);
    yield put(requestPaymentReportSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function * createOrderNoteSaga ({ payload: { data } }) {
  try {
    const res = yield call(addOrderNote, { data });
    if (res?.data) {
      const { sourceOrders } = yield select(SourceOrders);
      const index = sourceOrders?.data?.findIndex((order) => order?.id === res.data.id);
      if (index !== -1 && sourceOrders) sourceOrders.data[index].note = res?.data.note;
    }
    yield put(createOrderNoteSuccess(res));
    yield put(setOrderLogModal(false));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchUploadTrackingSaga ({ payload }) {
  try {
    const res = yield call(uploadTrackingDetails, payload);
    yield put(refreshSourceOrderDetail());
    yield put(setTrackingUploadSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * assignInventorySaga ({ payload }) {
  try {
    const res = yield call(assignOutboundItem, payload);
    yield put(assignInventorySuccess(res));
    yield put(fetchSourceOrderDetail(payload?.source_order_data?.source_order_id));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateSourceOrderStatusSaga ({ payload: { id, data } }) {
  try {
    const res = yield call(updateStatusToError, { id, data });
    if (res?.data) {
      const { sourceOrders, sourceOrderDetail } = yield select(SourceOrders);
      const index = sourceOrders?.data?.findIndex((order) => order?.id === res.data.id);
      if (index !== -1 && sourceOrders) sourceOrders.data[index].status = res?.data.status;
      sourceOrderDetail.data.status = res?.data.status;
      yield put(updateSourceOrderStatusSuccess(res.message));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * changeOrderTypeSaga ({ payload: { id, data } }) {
  try {
    const res = yield call(changeOrderType, { id, data });
    yield put(changeOrderTypeSuccess(res.message));
    yield put(refreshSourceOrderDetail());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * generateShippingLabelSaga ({ payload: data }) {
  try {
    yield call(generateShippingLabel, data);
    yield put(generateShippingLabelSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getShippingRatesSaga ({ payload: data }) {
  try {
    const res = yield call(getShippingRates, data);
    if (res?.success) yield put(getShippingRatesSuccess(res?.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getItemsDimensionsSaga ({ payload: { orderId } }) {
  try {
    const res = yield call(getItemsDimensions, { orderId });
    yield put(getItemsDimensionsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchOrderNoteCreate () {
  yield takeEvery(CREATE_ORDER_NOTE, createOrderNoteSaga);
}

export function * watchSourceOrderFetch () {
  yield takeEvery(FETCH_SOURCE_ORDERS, fetchSourceOrdersSaga);
}
export function * watchSearchAllOrders () {
  yield takeEvery(SEARCH_ALL_ORDERS, searchAllOrdersSaga);
}
export function * watchSourceOrderDetailFetch () {
  yield takeEvery(FETCH_SOURCE_ORDER_DETAIL, fetchSourceOrderDetailSaga);
}
export function * watchImportOrderManually () {
  yield takeEvery(IMPORT_ORDER_MANUALLY, importOrderManuallySaga);
}
export function * watchUploadOrdersFile () {
  yield takeEvery(UPLOAD_ORDER_MANUALLY, uploadOrdersFileSaga);
}
export function * watchSourceOrderUpdate () {
  yield takeEvery(UPDATE_SOURCE_ORDERS, updateSourceOrdersSaga);
}
export function * watchShippingDetailUpdate () {
  yield takeEvery(UPDATE_SOURCE_ADDRESS, updateAddressSaga);
}
export function * watchBulkUpdateOrders () {
  yield takeEvery(ORDERS_BULK_UPDATE, bulkUpdateOrdersSaga);
}
export function * watchFetchMarketPlaceStatus () {
  yield takeEvery(FETCH_MARKETPLACE_STATUS, fetchMarketPlaceStatusSaga);
}
export function * watchGetFeedStatus () {
  yield takeEvery(GET_FEED_STATUS, getFeedStatusSaga);
}

export function * watchMarketplaceOrderCancel () {
  yield takeEvery(CANCEL_MARKETPLACE_ORDER, cancelMarketplaceOrderSaga);
}

export function * watchFetchNextSourceOrder () {
  yield takeEvery(FETCH_NEXT_ORDER, fetchNextSourceOrderSaga);
}

export function * watchLockStatus () {
  yield takeEvery(FETCH_LOCK_STATUS, fetchLockStatusSaga);
}

export function * watchDowloadSourceOrderReportSaga () {
  yield takeEvery(REQUEST_SOURCE_ORDER_REPORT, dowloadSourceOrderReportSaga);
}

export function * watchDowloadVAPerformanceReportSaga () {
  yield takeEvery(REQUEST_VA_PERFORMANCE_REPORT, downloadVAPerformanceReportSaga);
}

export function * watchDowloadOrderProcessorSaga () {
  yield takeEvery(REQUEST_ORDER_PROCESSOR_REPORT, downloadOrderProcessorReportSaga);
}

export function * watchRequestAvailablePaymentReportsSaga () {
  yield takeEvery(REQUEST_AVAILABLE_PAYMENT_REPORTS, getAvailablePaymentReportsSaga);
}

export function * watchRequestPaymentReportSaga () {
  yield takeEvery(REQUEST_PAYMENT_REPORT, getPaymentReportSaga);
}
export function * watchUploadTrackingDetailsSaga () {
  yield takeEvery(UPLOAD_TRACKING_DETAILS, fetchUploadTrackingSaga);
}

export function * watchUpdateSourceOrderStatusSaga () {
  yield takeEvery(UPDATE_SOURCE_ORDER_STATUS, updateSourceOrderStatusSaga);
}

export function * watchChangeOrderTypeSaga () {
  yield takeEvery(CHANGE_ORDER_TYPE, changeOrderTypeSaga);
}

export function * watchGenerateShippingLabelSaga () {
  yield takeEvery(GENERATE_SHIPPING_LABEL, generateShippingLabelSaga);
}

export function * watchGetShippingRatesSaga () {
  yield takeEvery(GET_SHIPPING_RATES, getShippingRatesSaga);
}

export function * watchGetItemsDimensionsSaga () {
  yield takeEvery(GET_ITEMS_DIMENSIONS, getItemsDimensionsSaga);
}

export function * watchAssignInventorySaga () {
  yield takeEvery(ASSIGN_INVENTORY_TO_ORDER, assignInventorySaga);
}

function * createPriceEventSaga ({ payload }) {
  try {
    const res = yield call(api.createPriceEvent, payload);
    yield put(actions.createPriceEventSuccess(res));
    yield put(actions.syncPriceEvent(res.data, { isNew: true }));
    yield put(actions.refreshSourceOrderDetail());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchCreatePriceEventSaga () {
  yield takeEvery(types.CREATE_PRICE_EVENT, createPriceEventSaga);
}

function * updatePriceEventSaga ({ payload }) {
  try {
    const res = yield call(api.updatePriceEvent, payload);
    yield put(actions.updatePriceEventSuccess(res));
    yield put(actions.syncPriceEvent(res.data));
    yield put(actions.refreshSourceOrderDetail());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * acknowledgeOrderSaga ({ payload }) {
  try {
    const res = yield call(api.acknowledgeOrder, payload);
    if (res?.data) {
      const { sourceOrders, sourceOrderDetail } = yield select(SourceOrders);
      const order = sourceOrders?.data?.find((order) => order?.id === res.data?.id);
      merge(order, res.data);
      merge(sourceOrderDetail.data, res.data);
      if (res?.orderLogs) sourceOrderDetail.data.order_logs = res.orderLogs;
      yield put(actions.acknowledgeOrderSuccess(res.message));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchAcknowledgeOrderSaga () {
  yield takeEvery(types.ACKNOWLEDGE_ORDER, acknowledgeOrderSaga);
}

export function * watchUpdatePriceEventSaga () {
  yield takeEvery(types.UPDATE_PRICE_EVENT, updatePriceEventSaga);
}

function * deletePriceEventSaga ({ payload }) {
  try {
    const res = yield call(api.deletePriceEvent, payload);
    yield put(actions.deletePriceEventSuccess(res));
    yield put(actions.syncPriceEvent(res.data, { isDeleted: true }));
    yield put(actions.refreshSourceOrderDetail());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchDeletePriceEventSaga () {
  yield takeEvery(types.DELETE_PRICE_EVENT, deletePriceEventSaga);
}

export function * watchUploadSourceItemLabel () {
  yield takeEvery(UPLOAD_SOURCE_ITEMS_LABEL, uploadSourceItemsLabelSaga);
}

function * orderSaga () {
  yield all([
    fork(watchSourceOrderFetch),
    fork(watchSearchAllOrders),
    fork(watchSourceOrderDetailFetch),
    fork(watchOrderNoteCreate),
    fork(watchSourceOrderUpdate),
    fork(watchBulkUpdateOrders),
    fork(watchShippingDetailUpdate),
    fork(watchFetchMarketPlaceStatus),
    fork(watchMarketplaceOrderCancel),
    fork(watchGetFeedStatus),
    fork(watchFetchNextSourceOrder),
    fork(watchLockStatus),
    fork(watchDowloadSourceOrderReportSaga),
    fork(watchDowloadVAPerformanceReportSaga),
    fork(watchDowloadOrderProcessorSaga),
    fork(watchRequestAvailablePaymentReportsSaga),
    fork(watchRequestPaymentReportSaga),
    fork(watchImportOrderManually),
    fork(watchUploadOrdersFile),
    fork(watchUploadTrackingDetailsSaga),
    fork(watchUpdateSourceOrderStatusSaga),
    fork(watchChangeOrderTypeSaga),
    fork(watchGenerateShippingLabelSaga),
    fork(watchGetShippingRatesSaga),
    fork(watchGetItemsDimensionsSaga),
    fork(watchAssignInventorySaga),
    fork(watchCreatePriceEventSaga),
    fork(watchUpdatePriceEventSaga),
    fork(watchDeletePriceEventSaga),
    fork(watchUploadSourceItemLabel),
    fork(watchAcknowledgeOrderSaga),
  ]);
}

export default orderSaga;
