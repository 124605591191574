import React from "react";
import { FormGroup, Col, Row, Label, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { values, entries, sortBy, first, last, pick } from "lodash";
import moment from "moment-timezone";

// Store Actions
import {
  showCustomFiltersAction,
  setCustomFiltersAction,
  setSelectedLiveListing,
  setListingSuppliersCollapse,
} from "../../store/actions";

// SimpleBar
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

import { adjustDate, getUserInfo, isAdmin, RenderIf, toPascalCase, verifyRole } from "../../utils/common";
import { CUSTOM_FILTERS, DATE_FORMATS, LISTING_SEARCH_TYPES, MARKET_PLACES } from "../../constants";
import CheckBox from "./CheckBox";
const { GRAPH_DATE, BACKEND } = DATE_FORMATS;

const Filters = ({
  activePlatform,
  filters,
  setFilters,
  showCustomFiltersAction,
  setSelectedLiveListing,
  setCustomFiltersAction,
  setListingSuppliersCollapse,
  vaIds,
  User,
  Listings,
  Supplier,
  is5050,
  tenant,
  oosReasons,
  marketplace,
}) => {
  const isAllowed = verifyRole("admin", "owner");
  const {
    user,
    platform,
    published,
    mp_fi: mpFI,
    won_buybox: wonBuybox,
    created_at: createdAt,
    stock_lock: stockLock,
    offer_status: offerStatus,
    seller_type: sellerType,
    listing_source: listingSource,
    oos_since: oosSince,
    refreshed_at: refreshedAt,
    ship_to_warehouse: shipToWarehouse,
    auto_ordering_enabled: aoEnabled,
    min_weight: minWeight,
    max_weight: maxWeight,
    min_shipping: minShipping,
    max_shipping: maxShipping,
    missing_dimensions: missingDimension,
    missing_estimates: missingEstimate,
  } = Listings.custom_filters;
  const handleFormSubmit = (state) => {
    const {
      user,
      platform,
      published,
      mp_fi: mpFi,
      won_buybox: buyBox,
      created_at: createdAt,
      oos_since: outOfStockDate,
      stock_lock: stockLock,
      offer_status: oosReason,
      seller_type: sellerType,
      listing_source: listingSource,
      refreshed_at: refreshedAt,
      ship_to_warehouse: shipToWarehouse,
      auto_ordering_enabled: autoOrderingEnabled,
      min_weight: minWeight,
      max_weight: maxWeight,
      min_shipping: minShipping,
      max_shipping: maxShipping,
      missing_dimensions: missingDimension,
      missing_estimates: missingEstimate,
    } = state;
    const customFilters = filters.filters_hash.filters.filter((x) => values(LISTING_SEARCH_TYPES).includes(x.field));
    if (shipToWarehouse.value !== "")
      customFilters.push({
        field: "ship_to_warehouse",
        operator: "=",
        value: shipToWarehouse.value,
        label: shipToWarehouse.label,
      });
    if (sellerType.value !== "")
      customFilters.push({ field: "seller_type", operator: "=", value: sellerType.value, label: sellerType.label });
    if (buyBox.value !== "")
      customFilters.push({ field: "won_buybox", operator: "=", value: buyBox.value, label: buyBox.label });
    if (published.value !== "")
      customFilters.push({ field: "published", operator: "=", value: published.value, label: published.label });
    if (mpFi.value !== "") customFilters.push({ field: "mp_fi", operator: "=", value: mpFi.value, label: mpFi.label });
    if (oosReason.value !== "")
      customFilters.push({ field: "offer_status", operator: "=", value: oosReason.value, label: oosReason.label });
    if (refreshedAt !== "")
      customFilters.push({
        field: "refreshed_at",
        operator: "<",
        value: moment(adjustDate(refreshedAt)).format(BACKEND),
      });
    if (createdAt !== "")
      customFilters.push({
        field: "created_at",
        operator: "=",
        value: moment(adjustDate(createdAt)).format(BACKEND),
      });
    if (outOfStockDate) {
      customFilters.push({
        field: "oos_since",
        operator: "<=",
        value: moment(adjustDate(outOfStockDate)).format(BACKEND),
      });
    }
    if (platform.value !== "")
      customFilters.push({
        field: "platform_id",
        operator: "=",
        value: platform.value,
        label: encodeURIComponent(platform.label),
      });
    if (autoOrderingEnabled.value !== "")
      customFilters.push({
        field: "auto_ordering_enabled",
        operator: "=",
        value: autoOrderingEnabled.value,
        label: autoOrderingEnabled.label,
      });
    if (stockLock.value !== "")
      customFilters.push({
        field: "stock_lock",
        operator: "=",
        value: stockLock.value,
        label: stockLock.label,
      });
    if (listingSource.value !== "")
      customFilters.push({
        field: "listing_source",
        operator: "=",
        value: listingSource.value,
        label: listingSource.label,
      });
    if (maxWeight.value !== "") {
      customFilters.push({
        field: "weight",
        operator: "<=",
        key: "max_weight",
        apply_label: true,
        value: maxWeight.value,
        label: "Max Weight",
      });
    }
    if (minWeight.value !== "")
      customFilters.push({
        field: "weight",
        operator: ">=",
        key: "min_weight",
        apply_label: true,
        value: minWeight.value,
        label: "Min Weight",
      });
    if (maxShipping.value !== "") {
      customFilters.push({
        field: "estimated_shipping_fee",
        operator: "<=",
        key: "max_shipping",
        apply_label: true,
        value: maxShipping.value,
        label: "Max Shipping",
      });
    }
    if (minShipping.value !== "")
      customFilters.push({
        field: "estimated_shipping_fee",
        operator: ">=",
        key: "min_shipping",
        apply_label: true,
        value: minShipping.value,
        label: "Min Shipping",
      });
    if (missingDimension)
      customFilters.push({
        field: "dimensions",
        operator: "=",
        value: null,
        key: "missing_dimensions",
        label: "Missing Dimensions",
        apply_label: true,
      });
    if (missingEstimate)
      customFilters.push({
        field: "estimated_shipping_fee",
        operator: "=",
        value: null,
        key: "missing_estimates",
        label: "Missing Shipping Estimates",
        apply_label: true,
      });
    if (user.value !== "") customFilters.push({ field: "va_id", operator: "=", value: user.value, label: user.label });
    showCustomFiltersAction(false);

    setFilters({
      ...filters,
      page_number: 1,
      filters_hash: { ...filters.filters_hash, filters: customFilters },
    });

    if (Supplier.listingSupplierCollapse) {
      setListingSuppliersCollapse(false);
      setSelectedLiveListing({});
    }
  };

  function isVAExist () {
    return User.users?.data?.filter((x) => vaIds?.includes(x.id)).length > 0;
  }

  const isWeightInvalid = minWeight.value && maxWeight.value && +maxWeight.value < +minWeight.value;
  const isShippingInvalid = minShipping.value && maxShipping.value && +maxShipping.value < +minShipping.value;

  return (
    <React.Fragment>
      <div className="custom-filter">
        <SimpleBar>
          <div data-simplebar className="h-100 overflow-auto">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  showCustomFiltersAction(false);
                }}
                className="custom-filter-toggle float-right"
              >
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
              <h5 className="m-0">Custom Filters</h5>
            </div>

            <hr className="my-0" />

            <div className="p-3">
              <AvForm onValidSubmit={() => handleFormSubmit(Listings.custom_filters)}>
                <Row className="height-calc">
                  {[MARKET_PLACES.amazon, MARKET_PLACES.walmart].includes(marketplace) && (
                    <Col lg="12" className="d-flex">
                      <RadioGroup
                        label="BuyBox"
                        trueId="buybox"
                        falseId="without_buybox"
                        allId="all_buybox"
                        fields={["With BuyBox", "Without BuyBox"]}
                        filter={{ won_buybox: wonBuybox }}
                        setfilters={setCustomFiltersAction}
                      />
                    </Col>
                  )}
                  {[MARKET_PLACES.amazon, MARKET_PLACES.walmart, MARKET_PLACES.shopify].includes(marketplace) && (
                    <Col lg="12" className="d-flex">
                      <RadioGroup
                        label="Marketplace Status"
                        trueId="published"
                        falseId="unpublished"
                        allId="all_status"
                        fields={{
                          walmart: ["Published", "Unpublished"],
                          amazon: ["Active", "Inactive"],
                          shopify: ["Published", "Unpublished"],
                        }}
                        filter={{ published }}
                        setfilters={setCustomFiltersAction}
                        activePlatform={activePlatform}
                      />
                    </Col>
                  )}
                  <Col lg="12" className="d-flex">
                    <RadioGroup
                      label="Verified Match"
                      trueId="enabled"
                      falseId="disabled"
                      allId="all_match"
                      fields={["Enabled", "Disabled"]}
                      filter={{ auto_ordering_enabled: aoEnabled }}
                      setfilters={setCustomFiltersAction}
                    />
                  </Col>
                  <Col lg="12" className="d-flex">
                    <RadioGroup
                      label="Stock Lock"
                      trueId="stock_lock"
                      falseId="without_stock_lock"
                      allId="all_stock_lock"
                      fields={["Enabled", "Disabled"]}
                      filter={{ stock_lock: stockLock }}
                      setfilters={setCustomFiltersAction}
                    />
                  </Col>
                  <Col lg="12" className="d-flex">
                    <RadioGroup
                      label="Seller Type"
                      trueId="seller_type_wholeseller"
                      falseId="seller_type_normal"
                      allId="all_seller_type"
                      fields={["WholeSeller", "Normal"]}
                      filter={{ seller_type: sellerType }}
                      setfilters={setCustomFiltersAction}
                    />
                  </Col>
                  <Col lg="12" className="d-flex">
                    <ListingTypeRadioGroup
                      label="Listing Type"
                      options={[
                        { title: "Inventory", value: 4 },
                        { title: "System", value: 2 },
                        { title: "Normal", value: 0 },
                        { title: "All", value: "" },
                      ]}
                      filter={{ listing_source: listingSource }}
                      setFilters={setCustomFiltersAction}
                    />
                  </Col>
                  {tenant?.two_step_feature && marketplace !== MARKET_PLACES.shopify ? (
                    <Col lg="12" className="d-flex">
                      <RadioGroup
                        label="Ship To WareHouse"
                        trueId="2_step"
                        falseId="without_2_step"
                        allId="all_ship_to_warehouse"
                        fields={["2Step", "Normal"]}
                        filter={{ ship_to_warehouse: shipToWarehouse }}
                        setfilters={setCustomFiltersAction}
                      />
                    </Col>
                  ) : null}
                  {[MARKET_PLACES.amazon, MARKET_PLACES.walmart].includes(marketplace) && (
                    <Col lg="12" className="d-flex">
                      <RadioGroup
                        label="Marketplace Items Fulfillment"
                        trueId="enabled_mpfi"
                        falseId="disabled_mpfi"
                        allId="all_mpfi"
                        fields={{ walmart: ["WFS", "Merchant Fulfilled"], amazon: ["FBA", "Merchant Fulfilled"] }}
                        filter={{ mp_fi: mpFI }}
                        setfilters={setCustomFiltersAction}
                        activePlatform={activePlatform}
                      />
                    </Col>
                  )}
                  <Col lg="12">
                    <FormGroup className="select2-container">
                      <Label>OOS Reason</Label>
                      <Select
                        name="oos_reason"
                        value={offerStatus}
                        onChange={(e) => {
                          setCustomFiltersAction({
                            offer_status: { value: e.value, label: e.label },
                          });
                        }}
                        options={entries(oosReasons).map(([reason, id], i) => ({
                          label: toPascalCase(reason),
                          value: id,
                          key: i,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="date-picker">
                      <Label>Refreshed Before This Date</Label>
                      <DatePicker
                        name="refreshed_at"
                        dateFormat={GRAPH_DATE}
                        className="form-control"
                        selected={refreshedAt}
                        placeholderText="Select a Date"
                        onChange={(date) => setCustomFiltersAction({ refreshed_at: date ?? "" })}
                        autoComplete="off"
                        popperPlacement="bottom-left"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="date-picker">
                      <Label>Created At</Label>
                      <DatePicker
                        name="created_at"
                        dateFormat={GRAPH_DATE}
                        className="form-control"
                        selected={createdAt}
                        placeholderText="Select a Date"
                        onChange={(date) => setCustomFiltersAction({ created_at: date ?? "" })}
                        autoComplete="off"
                        popperPlacement="bottom-left"
                      />
                    </FormGroup>
                  </Col>
                  <RenderIf isTrue={getUserInfo()?.account_id === 2}>
                    <Col lg="12">
                      <FormGroup className="date-picker">
                        <Label>Out of Stock Since or Before</Label>
                        <DatePicker
                          name="oos_since"
                          dateFormat={GRAPH_DATE}
                          className="form-control"
                          selected={oosSince}
                          placeholderText="Select a Date"
                          onChange={(date) => setCustomFiltersAction({ oos_since: date ?? "" })}
                          autoComplete="off"
                          popperPlacement="bottom-left"
                        />
                      </FormGroup>
                    </Col>
                  </RenderIf>
                  <Col lg="12">
                    <FormGroup className="select2-container">
                      <Label>Supplier</Label>
                      <Select
                        name="platform_id"
                        value={platform}
                        onChange={(e) => {
                          setCustomFiltersAction({
                            platform: { value: e.value, label: e.label },
                          });
                        }}
                        options={sortBy(Listings.listings?.platforms, "title").map((platform, i) => ({
                          label: platform?.title,
                          value: platform?.id,
                          key: i,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  {isAllowed && isVAExist() && (
                    <Col lg={12}>
                      <FormGroup className="select2-container">
                        <Label>User</Label>
                        <Select
                          name="va_id"
                          value={user}
                          onChange={(e) => {
                            setCustomFiltersAction({
                              user: { value: e.value, label: e.label },
                            });
                          }}
                          options={User.users?.data
                            ?.filter((x) => vaIds.includes(x.id))
                            .map((user, i) => ({
                              label: toPascalCase(user?.username),
                              value: user?.id,
                              key: i,
                            }))}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col lg="12" className="d-flex flex-column mb-3">
                    <CheckBox
                      name="missing_estimates"
                      state={missingEstimate}
                      setState={(_) => setCustomFiltersAction({ missing_estimates: !missingEstimate, ...pick(CUSTOM_FILTERS, "min_shipping", "max_shipping") })}
                      isSwitch={true}
                      label="Missing Shipping Estimates"
                    />
                  </Col>
                  <Col lg="12" className="d-flex flex-column mb-3">
                    <CheckBox
                      name="dimensions"
                      state={missingDimension}
                      setState={(_) => setCustomFiltersAction({ missing_dimensions: !missingDimension })}
                      isSwitch={true}
                      label="Missing Dimensions"
                    />
                  </Col>
                  <RenderIf isTrue={isAdmin()}>
                    <Col lg="12" className="d-flex flex-column mb-3">
                      <Label>{"Weight(lbs)"}</Label>
                      <div className="d-flex hide-arrows align-items-center">
                        {[
                          { name: "min", label: "Min", margin_type: "r", value: minWeight },
                          { name: "max", label: "Max", margin_type: "l", value: maxWeight },
                        ]
                          .map((x, key) => (
                            <Input
                              key={"__input_weight__" + key}
                              type="number"
                              placeholder={x.label}
                              className={`m${x.margin_type}-2`}
                              name={`${x.name}_weight`}
                              min="0"
                              value={x.value.value}
                              onKeyPress={(e) => {
                                if (e.key === "-") e.preventDefault();
                                if (+e.target.value >= 100000) e.preventDefault();
                              }}
                              onChange={(e) =>
                                setCustomFiltersAction({
                                  [`${x.name}_weight`]: { value: e.target.value, label: `${x.label} Weight` },
                                })
                              }
                            />
                          ))
                          .reduce((prev, curr, key) => [prev, <span key={"__span__" + key}>{"-"}</span>, curr])}
                      </div>
                      <RenderIf isTrue={isWeightInvalid}>
                        <small className="text-danger m-1">Min Weight must be less than Max Weight</small>
                      </RenderIf>
                    </Col>
                  </RenderIf>
                  <RenderIf isTrue={!missingEstimate}>
                    <Col lg="12" className="d-flex flex-column mb-3">
                      <Label>Est.Shipping Fee</Label>
                      <div className="d-flex hide-arrows align-items-center">
                        {[
                          { name: "min", label: "Min", margin_type: "r", value: minShipping },
                          { name: "max", label: "Max", margin_type: "l", value: maxShipping },
                        ]
                          .map((x, key) => (
                            <Input
                              key={"__input_est_shipping__" + key}
                              type="number"
                              placeholder={x.label}
                              className={`m${x.margin_type}-2`}
                              name={`${x.name}_estimated_shipping_fee`}
                              min="0"
                              value={x.value.value}
                              onKeyPress={(e) => {
                                if (e.key === "-") e.preventDefault();
                                if (+e.target.value >= 100000) e.preventDefault();
                              }}
                              onChange={(e) => {
                                setCustomFiltersAction({
                                  [`${x.name}_shipping`]: {
                                    value: e.target.value,
                                    label: `${x.label} Est.Shipping fee`,
                                  },
                                });
                              }}
                            />
                          ))
                          .reduce((prev, curr, key) => [prev, <span key={"__span__" + key}>{"-"}</span>, curr])}
                      </div>
                      <RenderIf isTrue={isShippingInvalid}>
                        <small className="text-danger m-1">Min Shipping must be less than Max Shipping</small>
                      </RenderIf>
                    </Col>
                  </RenderIf>
                </Row>
                <FormGroup className="d-flex justify-content-start pb-4 pt-2">
                  <div>
                    <Button
                      type="reset"
                      color="secondary"
                      className="mr-2"
                      onClick={() => {
                        setCustomFiltersAction(CUSTOM_FILTERS);
                        showCustomFiltersAction(false);
                        handleFormSubmit(CUSTOM_FILTERS);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      disabled={[isWeightInvalid, isShippingInvalid].some((x) => x) || false}
                      color="success"
                    >
                      Apply
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </div>
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

const RadioGroup = ({ label, trueId, falseId, allId, fields, filter, setfilters, activePlatform = null }) => (
  <div className="mb-3">
    <Label>{label}</Label>
    <div className="form-check mb-1">
      <input
        className="form-check-input"
        type="radio"
        id={trueId}
        checked={Object.values(filter).pop().value === true}
        onChange={() => {
          setfilters({
            [Object.keys(filter).toString()]: {
              value: true,
              label: getLabelsForRadioGroup(fields, true, activePlatform),
            },
          });
        }}
      />
      <label className="form-check-label" htmlFor={trueId}>
        {getLabelsForRadioGroup(fields, true, activePlatform)}
      </label>
    </div>
    <div className="form-check mb-1">
      <input
        className="form-check-input"
        type="radio"
        id={falseId}
        checked={Object.values(filter).pop().value === false}
        onChange={() =>
          setfilters({
            [Object.keys(filter).toString()]: {
              value: false,
              label: getLabelsForRadioGroup(fields, false, activePlatform),
            },
          })
        }
      />
      <label className="form-check-label" htmlFor={falseId}>
        {getLabelsForRadioGroup(fields, false, activePlatform)}
      </label>
    </div>
    <div className="form-check mb-1">
      <input
        className="form-check-input"
        type="radio"
        id={allId}
        checked={Object.values(filter).pop().value === ""}
        onChange={() => setfilters({ [Object.keys(filter).toString()]: { value: "" } })}
      />
      <label className="form-check-label" htmlFor={allId}>
        All
      </label>
    </div>
  </div>
);
const ListingTypeRadioGroup = ({ label, options, filter, setFilters }) => (
  <div className="mb-3">
    <Label>{label}</Label>
    {options.map(({ title, value }, key) => (
      <div key={"__" + title + key} className="form-check mb-1">
        <input
          className="form-check-input"
          type="radio"
          id={title}
          checked={Object.values(filter).pop().value === value}
          onChange={() => setFilters({ [Object.keys(filter).toString()]: { value, label: title } })}
        />
        <label className="form-check-label" htmlFor={title}>
          {title}
        </label>
      </div>
    ))}
  </div>
);

const getLabelsForRadioGroup = (fields, active, platform) => {
  if (Array.isArray(fields)) return active ? first(fields) : last(fields);
  else return active ? first(fields[platform]) : last(fields[platform]);
};

const mapStatetoProps = (state) => {
  const { Listings, User, Supplier } = state;
  return { Listings, User, Supplier };
};

export default connect(mapStatetoProps, {
  showCustomFiltersAction,
  setCustomFiltersAction,
  setSelectedLiveListing,
  setListingSuppliersCollapse,
})(Filters);
