import {
  FETCH_UNVERIFIED_MATCHES,
  FETCH_UNVERIFIED_MATCHES_SUCCESS,
  FETCH_MARKET_PLACE_CATEGORIES,
  FETCH_MARKET_PLACE_CATEGORIES_SUCCESS,
  FETCH_MARKET_PLACE_STORES,
  FETCH_MARKET_PLACE_STORES_SUCCESS,
  SET_SELECTED_UNPUBLISHED_LISTINGS,
  LIST_UNVERIFIED_MATCHES,
  LIST_UNVERIFIED_MATCHES_SUCCESS,
  SUBMIT_UNVERIFIED_MATCHES_REQUEST,
  SUBMIT_UNVERIFIED_MATCHES_REQUEST_SUCCESS,
  UPDATE_LISTINGS_DATA_SUCCESS,
  SET_BULK_UNVERIFIED_MATCHES_MODAL,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  categoryLoading: false,
  listingsData: undefined,
  selectedListings: [],
  marketPlaceCategories: "",
  bulkUnverifiedMatchesModal: false,
  success: "",
};

const unpublishedListings = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNVERIFIED_MATCHES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_UNVERIFIED_MATCHES_SUCCESS:
      state = {
        ...state,
        listingsData: action.payload,
        loading: false,
      };
      break;
    case FETCH_MARKET_PLACE_CATEGORIES:
      state = {
        ...state,
        marketPlaceCategories: "",
        listingsData: undefined,
        categoryLoading: true,
      };
      break;
    case SET_BULK_UNVERIFIED_MATCHES_MODAL:
      state = {
        ...state,
        bulkUnverifiedMatchesModal: action.payload,
      };
      break;
    case UPDATE_LISTINGS_DATA_SUCCESS:
      state = {
        ...state,
        listingsData: action.payload,
      };
      break;
    case FETCH_MARKET_PLACE_CATEGORIES_SUCCESS:
      state = {
        ...state,
        marketPlaceCategories: action.payload,
        loading: false,
      };
      break;
    case FETCH_MARKET_PLACE_STORES:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_MARKET_PLACE_STORES_SUCCESS:
      state = {
        ...state,
        marketPlaceStores: action.payload,
        loading: false,
      };
      break;
    case LIST_UNVERIFIED_MATCHES:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case LIST_UNVERIFIED_MATCHES_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loading: false,
      };
      state = state.selectedListings.length > 0 ? (state = { ...state, selectedListings: [] }) : { ...state };
      break;
    case SUBMIT_UNVERIFIED_MATCHES_REQUEST:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case SUBMIT_UNVERIFIED_MATCHES_REQUEST_SUCCESS:
      state = {
        ...state,
        loading: false,
        bulkUnverifiedMatchesModal: false,
        success: "Matches Request Submitted",
      };
      break;
    case SET_SELECTED_UNPUBLISHED_LISTINGS:
      state = {
        ...state,
        selectedListings: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      break;
  }
  return state;
};

export default unpublishedListings;
