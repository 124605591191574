import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, CardBody, Alert, Row, Col, Media } from "reactstrap";
import {
  fetchWHChargeHistories,
  fetchWarehouseAccounts,
  setPreloader,
  fetchUserWarehouses,
  downloadChargesReport,
  fetchWHStatusStats,
  updateWHChargeHistory,
} from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CustomPagination from "../../../components/Common/CustomPagination";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import {
  getUserInfo,
  toPascalCase,
  humanize,
  getWarehouseName,
  getDate,
  amountText,
  RenderIf,
  isWHStaff,
  isUserHaveFullAccess,
} from "../../../utils/common";
import { DATE_FORMATS, USER_ROLES, WH_CHARGES_TYPES, WH_CHARGE_HISTORY_STATUSES } from "../../../constants";
import { groupBy, map, merge, omit, startCase, keys, pick, keyBy, some } from "lodash";
import { DatePicker } from "../../../components/Common/DatePickr";
import { Link } from "react-router-dom";
const statsButtonLabels = merge(omit(WH_CHARGE_HISTORY_STATUSES, "pre_paid"), { all: "all" });
const icons = {
  warehouse_fee: "mdi mdi-warehouse",
  shipping_fee: "mdi mdi-van-utility",
  warehouse_charge: "bx bx-dollar",
  warehouse_shipping: "mdi mdi-clock-outline",
  profit: "bx bx-dollar",
};

const WHChargeHistories = (_) => {
  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;
  const [statusFilter, setStatusFilter] = useState({ label: "all", value: "" });

  const dispatch = useDispatch();
  const {
    chargeHistories,
    loading,
    chargesLoading,
    success,
    error,
    wareHouseAccounts,
    userWarehouses,
    WHChargeStatusFilterStats,
  } = useSelector((state) => state.WareHouses);
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20,
    charge_type: isUserHaveFullAccess() ? WH_CHARGES_TYPES.shipping : WH_CHARGES_TYPES.return,
  });
  const jwtToken = useRef(getUserInfo());
  const [search, setSearch] = useState("");
  const isWH = getUserInfo()?.isWH;
  const [wareHouseFilter, setWareHouseFilter] = useState({ label: "All Warehouses", value: "" });
  const [chargeTypeFilter, setChargeTypeFilter] = useState(
    isUserHaveFullAccess() ? { label: "Shipping", value: "shipping" } : { label: "Return", value: "return" },
  );
  const [isStorage, isShipping] = ["storage", "shipping"].map(
    (type) => chargeTypeFilter.value === WH_CHARGES_TYPES[type],
  );
  const isShowShippingCols = !chargeTypeFilter.value || isShipping;
  const showNotBilledActions = some(chargeHistories.data, ["status", "not_billed"]);
  const tableHeaders = [
    { title: "Order Number", col: "order_id", both: true, show: !isStorage },
    { title: "Tracking Number", col: "tracking_number", both: true, show: !isStorage },
    { title: `Account ${isAdmin ? "Email" : "ID"}`, isWH: true },
    { title: "Warehouse", isUser: true },
    { title: "Charge Status", isUser: !statusFilter.value },
    { title: "Charge Type", isUser: true },
    { title: "WH Fee to CX", col: "warehouse_fee", isAmount: true, isUser: true, key: "warehouse_fee" },
    { title: "Shipping to CX", isUser: true, key: "shipping_fee", show: isShowShippingCols },
    { title: "WH Fee to EC", col: "warehouse_cost", isAmount: true, isWH: true, key: "warehouse_charge" },
    {
      title: "Shipping to EC",
      col: "shipping_cost",
      isAmount: true,
      isWH: true,
      show: isShowShippingCols,
      key: "warehouse_shipping",
    },
    { title: "Total Charged to CX", isUser: true },
    { title: "Total Fee to EC", isWH: true },
    { title: "Stripe Fees", col: "stripe_fee", isAmount: true, both: false },
    { title: "Total Profit", col: "profit", isAmount: true, both: false },
    { title: "Date", both: true },
    {
      title: "Action",
      isUser: true,
      show:
        !isWHStaff() &&
        (!chargeTypeFilter.value || chargeTypeFilter.value === WH_CHARGES_TYPES.storage || showNotBilledActions),
    },
  ];

  const groupedHeaders = groupBy(tableHeaders, "key");
  const cols = keyBy(tableHeaders, "title");

  const getCost = ({ shipping_cost: cost, additional_charges: charges }) => (isWH ? cost : cost * (charges + 1));
  const getText = (text) => text || "N/A";

  const { stats } = chargeHistories;

  const isDisplay = (stat) => {
    if (stat === "profit" && isAdmin) return true;
    const header = groupedHeaders[stat]?.[0];
    if (!header) return false;
    return isAdmin || header.both ? true : isWH ? !header.isUser && header.isWH : header.isUser && !header.isWH;
  };

  const isVisbile = (x) => {
    if (!x) return false;
    if (x.show === undefined) x.show = true;
    return isAdmin || x.both ? x.show : isWH ? !x.isUser && x.isWH && x.show : x.isUser && !x.isWH && x.show;
  };

  const showCol = (name) => isVisbile(cols[name]);

  useEffect(() => {}, [jwtToken.current]);

  useEffect(() => {
    dispatch(setPreloader(loading || chargesLoading));
  }, [loading, chargesLoading]);

  useEffect(() => {
    !isWH && dispatch(fetchWHStatusStats(pick(getOtherFilters(), "account_id", "warehouse_id")));
  }, [filters.account_id, filters.warehouse_id]);

  useEffect(() => {
    !isWH && dispatch(fetchUserWarehouses());
  }, []);

  const getOtherFilters = () => (isAdmin ? filters : { ...filters, account_id: getUserInfo()?.account_id });

  useEffect(() => {
    !chargesLoading && dispatch(fetchWHChargeHistories(getOtherFilters()));
    !wareHouseAccounts.length && isAdmin && dispatch(fetchWarehouseAccounts());
  }, [filters]);

  const Render = ({ col, value }) => (
    <RenderIf isTrue={showCol(col)}>
      <td>{value}</td>
    </RenderIf>
  );

  const RenderCols = ({ charge, headers }) =>
    map(pick(cols, headers), (obj, title) => (
      <Render
        key={"__table_col__" + title}
        col={title}
        value={obj.isAmount ? amountText(charge[obj.col]) : getText(charge[obj.col])}
      />
    ));

  const markManuallyPaid = (id) =>
    dispatch(
      updateWHChargeHistory({
        id,
        body: { status: "done", manually_paid: true },
        getOtherFilters,
      }),
    );

  return (
    <React.Fragment>
      <div className="page-content">
        {success && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}
        <Container fluid>
          <Breadcrumbs
            title="Warehouse Charges"
            children={
              <div className="d-flex align-items-center">
                {wareHouseAccounts?.length > 0 && isAdmin && (
                  <div className="pl-2" style={{ marginTop: "12px" }}>
                    <SimpleSelect
                      name="account_id"
                      placeholder="All Accounts"
                      className="col"
                      onChange={(acc) => {
                        const newF = omit({ ...filters, account_id: acc.value }, acc.value ? "" : "account_id");
                        setFilters(newF);
                      }}
                      options={wareHouseAccounts.reduce(
                        (acc, x) => {
                          acc.push({ value: x.id, label: x.email });
                          return acc;
                        },
                        [{ value: "", label: "All Accounts" }],
                      )}
                      formStyle={{ minWidth: "250px" }}
                    />
                  </div>
                )}
                {chargeHistories?.count ? (
                  <i
                    title="Export to XLSX"
                    className="fas fa-file-export bx-sm pl-2 text-primary cursor-pointer"
                    onClick={() => dispatch(downloadChargesReport(getOtherFilters()))}
                  ></i>
                ) : null}
              </div>
            }
          />

          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-2 gap-3">
            <div className="d-flex align-items-start" style={{ width: "100%", minWidth: "115px", maxWidth: "300px" }}>
              <form
                className="app-search d-flex"
                style={{ marginTop: "-20px", width: "100%" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  if (search.trim()) {
                    setFilters({ ...omit(filters, "charge_type"), page: 1, search: search.trim() });
                  }
                }}
              >
                <div className="position-relative mr-2" style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Order #, Tracking #"
                    maxLength="40"
                    value={search}
                    onChange={(e) => {
                      if (!e.target.value) {
                        delete filters.search;
                        setSearch("");
                        setFilters({ ...filters, page: 1, charge_type: WH_CHARGES_TYPES.shipping });
                        setChargeTypeFilter({ label: "Shipping", value: "shipping" });
                      } else setSearch(e.target.value);
                    }}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form>
            </div>
            {!isWH && (
              <div className="pl-2 mb-1">
                <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ minWidth: "300px" }}>
                  {keys(statsButtonLabels).map((x, i) => (
                    <label
                      className={`btn btn-outline-primary group-btn-color ${statusFilter.label === x ? "active" : ""}`}
                      key={i}
                    >
                      <input
                        type="radio"
                        label={x}
                        value={x === "all" ? "" : x}
                        onClick={(e) => {
                          setStatusFilter({
                            label: x,
                            value: e.target?.value,
                          });
                          if (e.target?.value !== filters.status) {
                            setFilters({
                              ...filters,
                              status: e.target?.value,
                              page: 1,
                            });
                          }
                        }}
                      />
                      <b>{humanize(WHChargeStatusFilterStats?.[x] || 0)}</b>
                      <br />
                      {startCase(statsButtonLabels[x])}
                    </label>
                  ))}
                </div>
              </div>
            )}
            <div className="d-flex flex-column flex-lg-row">
              <div className="pl-2 responsive-div">
                <SimpleSelect
                  name="chargetype_id"
                  value={{ value: chargeTypeFilter.value, label: chargeTypeFilter.label }}
                  onChange={(e) => {
                    setChargeTypeFilter({ label: e.label, value: e.value });
                    if (e.value !== filters.charge_type) {
                      setFilters({ ...filters, charge_type: e.value, page: 1 });
                    }
                  }}
                  options={Object.keys(WH_CHARGES_TYPES)
                    .filter((key) => (isUserHaveFullAccess() ? true : key === "return"))
                    .reduce(
                      (acc, x, i) => {
                        acc.push({
                          label: startCase(WH_CHARGES_TYPES[x]),
                          value: x,
                          key: i,
                        });
                        return acc;
                      },
                      [{ value: "", label: "All Charges" }],
                    )}
                  classNamePrefix="select2-selection"
                />
              </div>
              {!isWH && (
                <div className="pl-2 responsive-div">
                  <SimpleSelect
                    name="warehouse_id"
                    value={{
                      value: wareHouseFilter.value,
                      label: wareHouseFilter.label,
                    }}
                    onChange={(e) => {
                      setWareHouseFilter({ label: e.label, value: e.value });
                      if (e.value !== filters.warehouse_id) setFilters({ ...filters, warehouse_id: e.value });
                    }}
                    options={
                      userWarehouses &&
                      Object.keys(userWarehouses).reduce(
                        (acc, x, i) => {
                          acc.push({
                            label: getWarehouseName(userWarehouses[x]),
                            value: userWarehouses[x].id,
                            key: i,
                          });
                          return acc;
                        },
                        [{ value: "", label: "Select Warehouse" }],
                      )
                    }
                    classNamePrefix="select2-selection"
                  />
                </div>
              )}
              <DatePicker className="pl-2" onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })} />
            </div>
          </div>
          <Row>
            <Col className="p-1">
              <Card>
                <CardBody style={{ minHeight: "6rem", maxHeight: "6rem" }} className="d-flex justify-content-start">
                  {map(
                    stats,
                    (amount, title) =>
                      isDisplay(title) && (
                        <Media body className="d-flex" key={`_${title}_`} style={{ maxWidth: "350px" }}>
                          <div className="d-flex">
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-items-center d-flex justify-content-center">
                              <i className={"bx " + icons[title] + " font-size-24"} style={{ color: "white" }}></i>
                            </div>
                          </div>

                          <Media className="d-flex flex-column wh-card-media">
                            <span className="card-text">{"Total " + toPascalCase(title)}</span>
                            {title === "profit" ? (
                              <div className="pt-2">
                                <span className="text-success font-size-12">
                                  {amountText(stats.shipping_fee + stats.warehouse_fee)}
                                </span>
                                {" - "}
                                <span className="text-danger font-size-12">
                                  {amountText(stats.warehouse_shipping + stats.warehouse_charge + stats.stripe_fee)}
                                </span>
                                <h5>
                                  {" = "} <span>{amountText(stats.profit)}</span>
                                </h5>
                              </div>
                            ) : (
                              <h5 className="pt-2">{amountText(amount)}</h5>
                            )}
                          </Media>
                          {title !== "profit" ? <div className="media-seperator"></div> : ""}
                        </Media>
                      ),
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            {Array.isArray(chargeHistories?.data) && chargeHistories?.data?.length && jwtToken.current ? (
              <CardBody>
                <div className="table-responsive" style={{ minHeight: "170px" }}>
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {tableHeaders
                          .filter((x) => isVisbile(x))
                          .map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {chargeHistories.data.map((charge, key) => (
                        <tr key={"__wh_charge__" + key}>
                          <RenderCols charge={charge} headers={["Order Number", "Tracking Number"]} />
                          <Render
                            col={`Account ${isAdmin ? "Email" : "ID"}`}
                            value={getText(isAdmin ? charge.account_email : charge.account_id)}
                          />
                          <Render col="Warehouse" value={getText(getWarehouseName(charge.warehouse))} />
                          <Render
                            col="Charge Status"
                            value={getText(startCase(charge.status))}
                          />
                          <Render col="Charge Type" value={getText(startCase(charge.charge_type))} />
                          <RenderCols charge={charge} headers={["WH Fee to CX"]} />
                          <RenderIf isTrue={showCol("Shipping to CX")}>
                            <td>{amountText(getCost(charge))}</td>
                          </RenderIf>
                          <RenderCols charge={charge} headers={["WH Fee to EC", "Shipping to EC"]} />
                          <Render
                            col="Total Charged to CX"
                            value={amountText(getCost(charge) + charge.warehouse_fee)}
                          />
                          <Render
                            col="Total Fee to EC"
                            value={amountText(charge.warehouse_cost + charge.shipping_cost)}
                          />
                          <RenderCols charge={charge} headers={["Stripe Fees", "Total Profit"]} />
                          <Render col="Date" value={getDate(charge.created_at, DATE_FORMATS.FULL_DATE)} />
                          <Render
                            col="Action"
                            value={
                              <>
                                <RenderIf isTrue={charge.charge_type === WH_CHARGES_TYPES.storage}>
                                  <Link to={`/storage_invoice/${charge.id}`} target="_blank">
                                    <i
                                      className="bx bx-sm bx-receipt text-primary cursor-pointer"
                                      title="View receipt"
                                    />
                                  </Link>
                                </RenderIf>
                                <RenderIf isTrue={charge.status === WH_CHARGE_HISTORY_STATUSES.not_billed}>
                                  <i
                                    className="bx bx-sm bx-check text-primary cursor-pointer"
                                    title="Mark manually paid"
                                    onClick={() => markManuallyPaid(charge.id)}
                                  />
                                </RenderIf>
                              </>
                            }
                          />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {chargeHistories?.data?.length ? (
                  <CustomPagination
                    total={chargeHistories.count}
                    pageOptions={[20, 50, 100]}
                    page={filters.page}
                    perPage={filters.per_page}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                ) : null}
              </CardBody>
            ) : null}
            {chargeHistories?.data?.length === 0 && !loading ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
            ) : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WHChargeHistories;
