export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";

export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";

export const GEN_ORDER_DETAILS_REPORT = "GEN_ORDER_DETAILS_REPORT";
export const GEN_ORDER_DETAILS_REPORT_SUCCESS = "GEN_ORDER_DETAILS_REPORT_SUCCESS";

export const GEN_TRANSACTIONAL_REPORT = "GEN_TRANSACTIONAL_REPORT";
export const GEN_TRANSACTIONAL_REPORT_SUCCESS = "GEN_TRANSACTIONAL_REPORT_SUCCESS";

export const GEN_SETTLEMENT_REPORT = "GEN_SETTLEMENT_REPORT";
export const GEN_SETTLEMENT_REPORT_SUCCESS = "GEN_SETTLEMENT_REPORT_SUCCESS";

export const GEN_ORDERS_REPORT = "GEN_ORDERS_REPORT";
export const GEN_ORDERS_REPORT_SUCCESS = "GEN_ORDERS_REPORT_SUCCESS";

export const GEN_INVENTORY_REPORT = "GEN_INVENTORY_REPORT";
export const GEN_INVENTORY_REPORT_SUCCESS = "GEN_INVENTORY_REPORT_SUCCESS";

export const GEN_INVENTORY_INSIGHT = "GEN_INVENTORY_INSIGHT";
export const GEN_INVENTORY_INSIGHT_SUCCESS = "GEN_INVENTORY_INSIGHT_SUCCESS";

export const GEN_RETURNS_REPORT = "GEN_RETURNS_REPORT";
export const GEN_RETURNS_REPORT_SUCCESS = "GEN_RETURNS_REPORT_SUCCESS";

export const GEN_WH_LABEL_REPORT = "GEN_WH_LABEL_REPORT";
export const GEN_WH_LABEL_REPORT_SUCCESS = "GEN_WH_LABEL_REPORT_SUCCESS";

export const GET_AVAILABLE_SETTLEMENT_REPORTS = "GET_AVAILABLE_SETTLEMENT_REPORTS";
export const GET_AVAILABLE_SETTLEMENT_REPORTS_SUCCESS = "GET_AVAILABLE_SETTLEMENT_REPORTS_SUCCESS";

export const RESET_STATE = "RESET_STATE";
export const TOGGLE_REPORT_MODAL = "TOGGLE_REPORT_MODAL";

export const RESET_URL_DOWNLOAD_FILE_REPORTS = "RESET_URL_DOWNLOAD_FILE_REPORTS";

export const API_ERROR = "USER_API_ERROR";
