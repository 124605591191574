import React from "react";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import { useDispatch } from "react-redux";
import { Container, Card, CardBody } from "reactstrap";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import { deleteWareHouseUser } from "../../../store/actions";

const WareHouseUsers = (props) => {
  const dispatch = useDispatch();
  const tableHeaders = ["Email", "Action"].map((title) => ({ title }));

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            {!props.users?.length ? (
              <h2 className="text-center">No Users Found</h2>
            ) : (
              <div className="table-responsive">
                <table className="table table-centered table-nowrap inner-table mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {tableHeaders.map((header, index) => (
                        <th key={`table-header ${index}`}>{header.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {props.users?.map((item, key) => (
                      <tr key={"_warehouse_users_" + key}>
                        <td>
                          {item.email}
                          <CopyToClipBoard text={item.email || ""} />
                        </td>
                        <td>
                          <ConfirmDialogIcon
                            icon={{
                              title: "Delete User",
                              className: "bx bx-sm text-danger bx-trash",
                            }}
                            msg={`To Delete ${item?.email}`}
                            onConfirm={() => dispatch(deleteWareHouseUser(item.id))}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default WareHouseUsers;
