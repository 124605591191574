import {
  CALL_SP_API,
  CALL_SP_API_SUCCESS,
  CHECK_WALMART_OFFERS,
  CHECK_WALMART_OFFERS_SUCCESS,
  RESET_CHECK_OFFERS_STATE,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  apiResponse: undefined,
  loading: false,
  success: "",
  error: "",
};

const CheckOffers = (state = initialState, action) => {
  switch (action.type) {
    case CALL_SP_API:
      state = {
        ...state,
        apiResponse: undefined,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case CALL_SP_API_SUCCESS:
      state = {
        ...state,
        apiResponse: action.payload,
        loading: false,
        success: "Called SP-API Successfully!",
        error: "",
      };
      break;
    case CHECK_WALMART_OFFERS:
      state = {
        ...state,
        apiResponse: undefined,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case CHECK_WALMART_OFFERS_SUCCESS:
      state = {
        ...state,
        apiResponse: action.payload,
        loading: false,
        success: "Called Walmart API Successfully!",
        error: "",
      };
      break;
    case RESET_CHECK_OFFERS_STATE:
      state = { ...initialState };
      break;
    case API_ERROR:
      state = {
        ...state,
        apiResponse: undefined,
        loading: false,
        success: "",
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default CheckOffers;
