import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
// actions
import { fetchListingStats } from "../../../store/actions";
import { humanize } from "../../../utils/common";
import { DATE_FORMATS } from "../../../constants";

const { DATE } = DATE_FORMATS;

const ListingStats = (props) => {
  const [enable5050, setEnable5050] = useState(false);
  const endDate = new Date();
  const startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000);
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const platform = props.platform;
  const { listingStats, refresherLoader } = useSelector((state) => state.Graph);

  const getDate = (date) => moment(new Date(date)).toDate();

  const fetchDetails = () => {
    !refresherLoader &&
      dispatch(
        fetchListingStats({
          partner_id: accountId,
          platform: platform || "",
        }),
      );
  };

  useEffect(() => {
    fetchDetails(accountId);
    setEnable5050(false);
  }, [accountId]);

  const series = [
    {
      name: "Published",
      data: (listingStats?.data?.length &&
        listingStats.data.map((listing) => [getDate(listing?.date).toString(), listing?.published])) || [
        [startDate],
        [endDate],
      ],
    },
    // {
    //   name: "Buy Boxed",
    //   data:
    //     listingStats?.data?.map((listing) => [
    //       listing?.date,
    //       listing?.buy_boxed,
    //     ]) || [],
    // },
    {
      name: "Un Published",
      data: (listingStats?.data?.length &&
        listingStats.data.map((listing) => [getDate(listing?.date).toString(), listing?.un_published])) || [
        [startDate],
        [endDate],
      ],
    },
    {
      name: "Errored",
      data: (listingStats?.data?.length &&
        listingStats.data.map((listing) => [getDate(listing?.date).toString(), listing?.errored])) || [
        [startDate],
        [endDate],
      ],
    },
    {
      name: "In Progress",
      data: (listingStats?.data?.length &&
        listingStats.data.map((listing) => [getDate(listing?.date).toString(), listing?.in_progress])) || [
        [startDate],
        [endDate],
      ],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#f4c469", "#fdc4f0"],
    xaxis: {
      type: "datetime",
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE) }, // Timestamp is ALWAYS UTC
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return humanize(val) + " Items";
        },
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="card-title mb-2">{refresherLoader ? "Loading..." : "Listing Stats"}</h4>
            {listingStats?.have_shared_listings && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  name="shared_listings"
                  className="custom-control-input"
                  checked={enable5050}
                  onChange={() => null}
                />
                <label
                  onClick={() => {
                    if (!enable5050) {
                      dispatch(
                        fetchListingStats({
                          partner_id: accountId,
                          platform: platform || "",
                          listing_source: "shared_listings",
                        }),
                      );
                    } else fetchDetails();
                    setEnable5050(!enable5050);
                  }}
                  className="custom-control-label"
                >
                  50/50 Matches
                </label>
              </div>
            )}
          </div>
          {listingStats?.data?.length
            ? (
            <div className="d-flex">
              <div className="d-flex flex-column mr-2">
                <h6>Published</h6>
                <strong className="text-center black-color">
                  {humanize(listingStats.data[listingStats.data.length - 1]?.published || 0)}
                </strong>
              </div>
              <div className="d-flex flex-column mr-2">
                <h6>Unpublished</h6>
                <strong className="text-center black-color">
                  {humanize(listingStats.data[listingStats.data.length - 1]?.un_published || 0)}
                </strong>
              </div>
              <div className="d-flex flex-column mr-2">
                <h6>Errored</h6>
                <strong className="text-center black-color">
                  {humanize(listingStats.data[listingStats.data.length - 1]?.errored || 0)}
                </strong>
              </div>
              <div className="d-flex flex-column mr-2">
                <h6>In Progress</h6>
                <strong className="text-center black-color">
                  {humanize(listingStats.data[listingStats.data.length - 1]?.in_progress || 0)}
                </strong>
              </div>
            </div>
              )
            : null}
        </div>
        <div className="clearfix"></div>
        <ReactApexChart options={options} series={series} type="line" height="350" />
      </CardBody>
    </Card>
  );
};

export default ListingStats;
