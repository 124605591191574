import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Simple bar
import SimpleBar from "simplebar-react";
// i18n
import { withNamespaces } from "react-i18next";
import SidebarContent from "./SidebarContent";
import {
  toggleReportModal,
  resetState,
} from "../../store/actions";
import { verifyRole } from "../../utils/common";

const Sidebar = (props) => {
  const isReadOnly = verifyRole("readOnly");

  const SidebarWrapper = () => (
    <SidebarContent
      resetState={props.resetState}
      toggleReportModal={props.toggleReportModal}
    />
  );

  return (
    <React.Fragment>
      {!isReadOnly && (
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {props.type !== "condensed"
              ? (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <SidebarWrapper />
              </SimpleBar>
                )
              : (
              <SidebarWrapper />
                )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect((state) => ({ layout: state.Layout }), {
  toggleReportModal,
  resetState,
})(withRouter(withNamespaces()(Sidebar)));
