import React, { useEffect, useState } from "react";
import { Card, Container, CardBody, CardHeader, Col, Row, FormGroup } from "reactstrap";
import { connect, useSelector } from "react-redux";
import queryString from "query-string";
import moment from "moment-timezone";
import Select from "react-select";
// actions
import { fetchUsers, fetchUploadedFiles, setPreloader, downloadFile } from "../../../store/actions";
import { toPascalCase, getUserInfo, RenderIf } from "../../../utils/common";
import CustomPagination from "../../../components/Common/CustomPagination";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { DATE_FORMATS } from "../../../constants";
import { cloneDeep, startCase } from "lodash";

const UploadedFiles = (props) => {
  const queryParameters = queryString.parse(props.location.search);
  const { accounts, loading: AccountLoading } = useSelector((state) => state.MarketPlaceAccount);

  const isAccountsAvailable = accounts?.data.filter((x) => x.enabled && x.valid);

  const tableHeaders = [
    { title: "File Type" },
    { title: "User" },
    { title: "Total" },
    { title: "Items Processed" },
    { title: "Items Failed" },
    { title: "Status" },
    { title: "Created At" },
  ];

  const [filters, setFilters] = useState({
    page_number: queryParameters?.page || 1,
    per_page: 30,
    partner_id: { label: "All", value: "" },
  });

  useEffect(() => {
    if (!props.User.users?.data && !props.User.loading)
      props.fetchUsers({
        account_id: getUserInfo().account_id,
        pagination: false,
      });
    const updatedFilters = cloneDeep(filters);
    updatedFilters.partner_id = updatedFilters.partner_id.value;

    props.fetchUploadedFiles({ ...updatedFilters });
  }, [filters]);

  useEffect(() => {
    props.setPreloader(props.Listings.loading || AccountLoading);
  }, [props.Listings.loading, AccountLoading]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Uploaded Files" />
          <RenderIf isTrue={isAccountsAvailable?.length} fallback={
            <RenderIf isTrue={!AccountLoading && !props.Listings.loading}>
              <h2 className="text-center">No Account Found</h2>
            </RenderIf>
          }>
            <Card>
              <CardHeader>
                <Row>
                  <Col md={3} className="m-2">
                    <div>
                    <FormGroup className="select2-container">
                      <Select
                        name="marketplace_id"
                        value={filters.partner_id}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            partner_id: e,
                          });
                        }}
                        options={accounts?.data?.map((acc, key) => ({
                          value: acc.id,
                          label: [startCase(acc.marketplace), acc.name].join(": "),
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {!props.Listings.uploadedFiles?.results?.length && !props.Listings.loading && (
                  <h2 className="text-center">No Records Found</h2>
                )}
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <RenderIf isTrue={props.Listings.uploadedFiles?.results?.length}>
                      <thead className="thead-light">
                        <tr>
                          {/* table headers */}
                          {tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                        </tr>
                      </thead>
                    </RenderIf>
                    <tbody>
                      {/* iterating data */}
                      {props.Listings.uploadedFiles?.results?.map((item, key) => (
                        <tr key={"_parse_email" + key} className="mt-3 mb-3">
                          <td>
                            <RenderIf isTrue={item?.s3_url} fallback={"N/A"}>
                              <div className="d-flex">
                                <div className="mt-1">{toPascalCase(item.csv_type)}</div>
                                <div>
                                  <i
                                    className="bx bx-sm bx-cloud-download text-primary cursor-pointer ml-2"
                                    onClick={() => props.downloadFile(item.s3_url)}
                                  />
                                </div>
                              </div>
                            </RenderIf>
                          </td>
                          <td>{props.User.users?.data?.find((x) => x.id === +item.va_id)?.username || "N/A"}</td>
                          <td>{item.items_received}</td>
                          <td>{item.csv_type.includes("export") ? "N/A" : item.items_processed}</td>
                          <td>
                            <RenderIf isTrue={!item.csv_type.includes("export")} fallback="N/A">
                              <div className="d-flex">
                                <div className="mt-1">{item.items_failed}</div>
                                <RenderIf isTrue={item.items_failed && item.failed_items_url}>
                                  <div className="ml-2">
                                    <i
                                      className="bx bx-sm bx-cloud-download text-primary cursor-pointer"
                                      onClick={() => props.downloadFile(item.failed_items_url)}
                                    />
                                  </div>
                                </RenderIf>
                              </div>
                            </RenderIf>
                          </td>
                          <td>{toPascalCase(item.status)}</td>
                          <td>{moment(item?.file_created_at).format(DATE_FORMATS.FULL_DATE) || ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {props.Listings.uploadedFiles?.results?.length > 0
                  ? (
                    <CustomPagination
                      total={props.Listings.uploadedFiles.total_count}
                      page={props.Listings.uploadedFiles.page_number}
                      perPage={props.Listings.uploadedFiles.per_page}
                      tabsFilter={filters}
                      setTabFilter={setFilters}
                      pageOptions={[30, 100]}
                      matching={true}
                    />
                    )
                  : null}
              </CardBody>
            </Card>
          </RenderIf>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { Listings, MarketPlaceAccount, User } = state;
  return { Listings, MarketPlaceAccount, User };
};

export default connect(mapStatetoProps, {
  fetchUsers,
  fetchUploadedFiles,
  setPreloader,
  downloadFile,
})(UploadedFiles);
