import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import queryString from "query-string";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Alert,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  fetchReturns,
  setPreloader,
  setSelectedReturnItem,
  setUploadImagesModal,
  setFilterSidebar,
  updateReturn,
  fetchUserWarehouses,
  updateBulkReturns,
  setUploadReturnLabelModal,
  setDownloadLabelModal,
  setAddShipmentModal,
} from "../../store/actions";
import SimpleSelect from "../../components/Common/SimpleSelect";
import {
  ORDER_TRACKING_STATUSES,
  LABEL_UPLOAD_TYPES,
  RETURNED_ITEM_ISSUES,
  RETURN_INTERNAL_STATUSES,
  USER_ROLES,
  WH_NOTICES_TYPES,
  DATE_FORMATS,
} from "../../constants";
import { map, merge, pick, startCase, values, omit, keys, isEmpty } from "lodash";
import UploadImagesModal from "./components/UploadImagesModal";
import Text from "./components/Common/Text";
import {
  getDate,
  getKeyByValue,
  getMenuPlacement,
  getUserInfo,
  getWarehouseName,
  isWHStaff,
  RenderIf,
} from "../../utils/common";
import ShowUpc from "../../components/Common/ShowUpc";
import Suspense from "../../components/Common/Suspense";
import ViewNotesIcon from "./components/Common/ViewNotesIcon";
import CheckBox from "../../components/Common/CheckBox";
import PillBtn from "../../components/Common/PillBtn";
import CustomTooltip from "../../components/Common/CustomTooltip";
import { Link } from "react-router-dom";
import UploadReturnLabelModal from "../WareHouse/Components/UploadReturnLabel";
import DownloadReturnLabelModal from "../WareHouse/Components/DownloadReturnLabelModal";
import AddBinNoModal from "../WareHouse/Components/AddBinNo";
import { DatePicker } from "../../components/Common/DatePickr";
import SweetAlert from "react-bootstrap-sweetalert";
import AddShipmentModal from "../Inbounds/components/AddShipmentModal";
const CustomFilter = React.lazy(() => import("./components/Filters"));
const CustomPagination = React.lazy(() => import("../../components/Common/CustomPagination"));
const ReturnNoticeModal = React.lazy(() => import("../WareHouse/Components/AddWarehouseNoteModal"));
const StatusBadge = React.lazy(() => import("../../components/Common/StatusBadge"));
const TrackingDetails = React.lazy(() => import("./components/Common/TrackingDetails"));
const SeeMoreText = React.lazy(() => import("../../components/Common/SeeMoreText"));
const { DATE } = DATE_FORMATS;
const { open, closed } = RETURN_INTERNAL_STATUSES;
const internalStatusMapping = { [open]: true, [closed]: false };

const Returns = () => {
  const { search: querySearch } = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const isWH = getUserInfo()?.role === USER_ROLES.whStaff;
  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;
  const { returns, loading, selectedReturnItem, uploadImagesModal, success, error, filtersSideBarIsOpen } = useSelector(
    (state) => state.Returns,
  );
  const {
    userWarehouses,
    warehouseNoteModalIsOpen,
    uploadReturnLabelModalIsOpen,
    downloadLabelModalIsOpen,
    binNoModalIsOpen,
    success: warehouseSuccess,
    loading: warehouseLoading,
    message: warehouseMessage,
    addShipmentModal,
  } = useSelector((state) => state.WareHouses);
  const { tenantsData } = useSelector((state) => state.Settings);
  const [search, setSearch] = useState("");
  const [checkBox, setCheckBox] = useState({});
  const [seeMore, setSeeMore] = useState();
  const [selectColumnsDropDown, setSelectColumnsDropDown] = useState(false);
  const [customFilters, setCustomFilters] = useState({});
  const [isOpenToggleConfirmation, setIsOpenToggleConfirmation] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const isDataExists = Array.isArray(returns?.data) && returns?.data?.length;
  const returnIssues = returns?.returnIssues;

  const tableHeaders = [
    { title: `Account ${isWH ? "Id" : "Email"}`, isWH: true, show: isAdmin || isWH },
    { title: "Item Details", both: true, show: isWH || isAdmin },
    { title: "Date Initiated", both: true, show: false },
    { title: "Delivered Date", both: true, show: true },
    { title: "Marketplace", isUser: false, show: false },
    { title: "Order Details", both: true, show: true },
    { title: "Tracking Details", both: true, show: true },
    { title: "Warehouse", isUser: true, show: true },
    { title: "Return #", isUser: true, show: false },
    { title: "Return Status", isUser: true, show: true },
    { title: "Refund Status", isUser: true, show: true },
    { title: "Warehouse Status", isUser: true, show: true },
    { title: "Return Reason", isUser: true, show: true },
    { title: "Is Open", isUser: true, show: true },
    { title: "Actions", both: true, show: true },
    { title: "Issue Type", both: true, show: true },
  ];

  const isVisbile = (x, isShow = true) => {
    if (!x) return false;
    if (!x.show && isShow) return false;
    return isAdmin || x.both ? true : isWH ? !x.isUser && x.isWH : x.isUser && !x.isWH;
  };
  const WAREHOUSE_STATUS_OPTIONS = {
    returns_without_label: "returns_without_label",
    returns_with_label: "returns_with_label",
  };

  const toolTipContent = (x) => {
    const inbound = x?.wh_order?.shipment_lines?.[0];
    if (x?.outbound?.status === "complete") {
      return `Sent to supplier on ${moment(x?.outbound?.updated_at).format("YYYY-MM-DD")}`;
    } else if (inbound?.status === "incomplete") return "Warehouse is waiting to receive this item";
    else if (inbound?.status === "complete" && inbound?.received_at) {
      return `Received on ${moment(inbound?.received_at).format("YYYY-MM-DD")}`;
    } else if (inbound?.status === "complete") return "Received at warehouse";
  };

  const [cols, setCols] = useState(tableHeaders.reduce((obj, x) => merge(obj, { [x.title]: x }), {}));
  const getText = (text) => startCase(text) || "N/A";
  const humanizeStr = (x) => getText(startCase(x?.toLowerCase()));
  const getItemText = (id, key, value, className = "") => {
    if (key !== "Item") return value;
    return (
      <Suspense>
        <SeeMoreText
          id={id}
          value={value}
          length={isWH ? 80 : 45}
          className={className}
          seeMore={seeMore}
          setSeeMore={setSeeMore}
        />
      </Suspense>
    );
  };

  const showCol = (name) => isVisbile(cols[name]);

  const [filters, setFilters] = useState({ page: 1, per_page: 20, internal_status: open });

  useEffect(() => {
    if (querySearch) {
      dispatch(
        fetchReturns(merge(pick(filters, "per_page", "internal_status"), { page: 1, search: querySearch?.trim() })),
      );
    } else dispatch(fetchReturns(filters));
  }, [filters, querySearch]);

  useEffect(() => {
    dispatch(fetchUserWarehouses());
  }, []);

  useEffect(() => {
    setCheckBox({});
  }, [filters.page]);

  useEffect(() => {
    dispatch(setPreloader(loading || warehouseLoading));
  }, [loading, warehouseLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error, success, warehouseSuccess]);

  const ActionButtons = ({ item }) => (
    <td>
      <div className="d-flex align-items-center">
        <ViewNotesIcon item={item} notices={item.warehouse_notices} setSelected={setSelectedReturnItem} />
        <RenderIf isTrue={item.issue_type === RETURNED_ITEM_ISSUES.damaged}>
          <i
            title={`View ${isWH ? "or Upload " : ""}Images`}
            className="bx bx-photo-album bx-sm text-primary cursor-pointer ml-2"
            onClick={() => {
              dispatch(setSelectedReturnItem(item));
              dispatch(setUploadImagesModal(true));
            }}
          ></i>
        </RenderIf>
        <RenderIf isTrue={item.warehouse_id && !isWH}>
          <i
            title="Upload Return Label"
            className="bx bx-sm bx-upload text-primary cursor-pointer pl-2"
            onClick={() => {
              dispatch(setSelectedReturnItem(item));
              dispatch(setUploadReturnLabelModal(true));
            }}
          ></i>
        </RenderIf>
        <RenderIf isTrue={item.labels}>
          <i
            title="Download Return Label"
            className="bx bx-sm bx bx-download pl-2 text-primary cursor-pointer"
            onClick={() => {
              dispatch(setSelectedReturnItem(item));
              dispatch(setDownloadLabelModal(true));
            }}
          ></i>
        </RenderIf>
      </div>
    </td>
  );

  return (
    <React.Fragment>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            const { id, status, filters } = isOpenToggleConfirmation;
            dispatch(updateReturn(id, status, filters));
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {`to change status to ${isOpenToggleConfirmation?.status?.internal_status}`}
        </SweetAlert>
      </RenderIf>
      <RenderIf isTrue={addShipmentModal}>
        <AddShipmentModal />
      </RenderIf>
      {warehouseNoteModalIsOpen && (
        <Suspense>
          <ReturnNoticeModal
            notice_type={WH_NOTICES_TYPES.returns}
            notices={selectedReturnItem?.warehouse_notices}
            selectedItem={selectedReturnItem}
            warehouses={userWarehouses}
          />
        </Suspense>
      )}
      {binNoModalIsOpen && <AddBinNoModal selectedItem={selectedReturnItem} type={WH_NOTICES_TYPES.returns} />}
      {uploadReturnLabelModalIsOpen && (
        <UploadReturnLabelModal item={selectedReturnItem} type={LABEL_UPLOAD_TYPES.returns} />
      )}
      {downloadLabelModalIsOpen && (
        <DownloadReturnLabelModal item={selectedReturnItem} type={LABEL_UPLOAD_TYPES.returns} />
      )}
      {uploadImagesModal && <UploadImagesModal returnItem={selectedReturnItem} />}
      <div className="page-content" onClick={() => (filtersSideBarIsOpen ? dispatch(setFilterSidebar(false)) : null)}>
        {(success || warehouseSuccess) && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {warehouseMessage || success || warehouseSuccess}
            </Alert>
          </div>
        )}
        {error && (
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}
        <RenderIf isTrue={returnIssues}>
          <Alert color="danger" className="my-1  mb-4">
            <i className="bx bx-info-circle pr-2"></i>
            {`Total Return(s) that ${returnIssues === 1 ? "has" : "have"} issues: ${returnIssues?.toLocaleString()}`}
          </Alert>
        </RenderIf>

        <Container fluid>
          <Breadcrumbs
            title="Returns"
            children={
              <>
                <RenderIf isTrue={isWHStaff() || isAdmin}>
                  <PillBtn
                    className="m-2 mt-3"
                    title="Add Shipment"
                    name="Add Shipment"
                    icon="bx bx-plus"
                    color="primary"
                    onClick={() => dispatch(setAddShipmentModal(true))}
                  />
                </RenderIf>
                {keys(checkBox).length > 0 && (
                  <PillBtn
                    className="m-2 mt-3"
                    color="primary"
                    title="Mark As Closed"
                    name="Mark As Closed"
                    onClick={() => {
                      dispatch(
                        updateBulkReturns(
                          keys(checkBox).map((x) => +x),
                          { internal_status: closed },
                          filters,
                        ),
                      );
                      setCheckBox({});
                    }}
                  />
                )}
              </>
            }
          />
          <Card>
            <CardHeader>
              <div className="row d-flex justify-content-between align-items-center">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (search.trim()) {
                      setFilters({ ...pick(filters, "per_page", "internal_status"), page: 1, search: search.trim() });
                      setCustomFilters({});
                    }
                  }}
                >
                  <div className="position-relative mr-2" style={{ width: "400px" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Tracking #, ${isWH ? "Customer Id" : "Return #"}, Order Id, Item Name, UPC`}
                      maxLength="40"
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          delete filters.search;
                          setSearch("");
                          setFilters({ ...filters });
                        } else setSearch(e.target.value);
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>

                <div className="mr-2">
                  <div className="row">
                    {isAdmin && (
                      <SimpleSelect
                        name="account_id"
                        value={{
                          value: customFilters.account_id?.value || "",
                          label: customFilters.account_id?.label || "Select Account",
                        }}
                        onChange={(e) => {
                          setFilters({ ...filters, account_id: e.value });
                          setCustomFilters({ ...customFilters, account_id: { value: e.value, label: e.label } });
                        }}
                        options={Object.entries(tenantsData || {}).reduce(
                          (acc, [accId, data]) => {
                            acc.push({ value: accId, label: data.email });
                            return acc;
                          },
                          filters.account_id ? [{ value: "", label: "All Account" }] : [],
                        )}
                        formStyle={{
                          minWidth: "250px",
                          marginBottom: 0,
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                    )}
                    <SimpleSelect
                      name="label_option"
                      value={{
                        value: customFilters.label_option?.value || "",
                        label: customFilters.label_option?.label || "Select Label Option",
                      }}
                      onChange={(e) => {
                        setFilters({ ...filters, label_option: e.value });
                        setCustomFilters({ ...customFilters, label_option: { value: e.value, label: e.label } });
                      }}
                      options={values(WAREHOUSE_STATUS_OPTIONS).reduce(
                        (acc, x) => {
                          acc.push({ value: x, label: startCase(x) });
                          return acc;
                        },
                        filters.label_option ? [{ value: "", label: "All" }] : [],
                      )}
                      formStyle={{ minWidth: "250px", marginBottom: 0, marginRight: "0.5rem", marginLeft: "0.5rem" }}
                    />
                    <SimpleSelect
                      name="tracking_status"
                      value={{
                        value: customFilters.tracking_status?.value || "",
                        label: customFilters.tracking_status?.label || "Tracking Status",
                      }}
                      onChange={(e) => {
                        setFilters({ ...filters, tracking_status: e.value });
                        setCustomFilters({ ...customFilters, tracking_status: { value: e.value, label: e.label } });
                      }}
                      options={values(ORDER_TRACKING_STATUSES).reduce(
                        (acc, x) => {
                          acc.push({ value: x, label: startCase(x) });
                          return acc;
                        },
                        filters.tracking_status ? [{ value: "", label: "All Statuses" }] : [],
                      )}
                      formStyle={{ minWidth: "250px", marginBottom: 0, marginRight: "0.5rem", marginLeft: "0.5rem" }}
                    />
                  </div>
                  {/* ////////////////////////////////////////// */}
                  <div className="row float-right mt-3">
                    {!isWH && (
                      <CheckBox
                        className="mt-2 mr-3"
                        label="Include Closed Returns"
                        state={!filters.internal_status}
                        setState={(e) => setFilters({ ...filters, internal_status: e ? undefined : open, page: 1 })}
                        isSwitch={true}
                      />
                    )}
                    <DatePicker
                      className="ml-1 mr-1"
                      onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })}
                    />
                    <ButtonDropdown
                      disabled={!isDataExists}
                      isOpen={selectColumnsDropDown}
                      toggle={() => setSelectColumnsDropDown(!selectColumnsDropDown)}
                      className="mr-1"
                    >
                      <DropdownToggle tag="i" className="bx bx-sm bx-columns text-primary mt-1 cursor-pointer" />
                      <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }}>
                        {tableHeaders
                          .filter((x) => isVisbile(x, false))
                          .map((header, key) => (
                            <DropdownItem key={`_select_columns_+ ${key}`}>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={cols[header?.title]?.show}
                                  onChange={() => false}
                                />
                                <label
                                  className="custom-control-label"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    cols[header?.title] = merge(cols[header?.title], {
                                      show: !cols[header?.title]?.show,
                                    });
                                    setCols({ ...cols });
                                  }}
                                >
                                  {header.title}
                                </label>
                              </div>
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                    <i
                      className="bx bx-sm bx-filter-alt text-primary mr-1 mt-1 cursor-pointer"
                      onClick={() => dispatch(setFilterSidebar(!filtersSideBarIsOpen))}
                    />
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
          <Card>
            {Array.isArray(returns?.data) && returns?.data?.length ? (
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-centered select table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>
                          <CheckBox
                            state={keys(checkBox).length === returns?.data?.length}
                            setState={(e) => {
                              const { data } = returns;
                              if (keys(checkBox).length === data.length) setCheckBox({});
                              else setCheckBox(data?.reduce((acc, cur) => merge(acc, { [cur.id]: true }), {}));
                            }}
                          />
                        </th>
                        {Object.values(cols)
                          .filter((x) => isVisbile(x, true))
                          .map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {returns.data.map((x, key) => (
                        <React.Fragment key={"_return_row_" + key}>
                          <tr key={"_return_" + key} className={x.issue_type ? "mt-3 mb-3 row-danger" : "mt-3 mb-3 "}>
                            <td>
                              <CheckBox
                                state={checkBox[x.id] || false}
                                setState={(e) => {
                                  if (checkBox[x.id]) delete checkBox[x.id];
                                  else checkBox[x.id] = true;
                                  setCheckBox({ ...checkBox });
                                }}
                                isSwitch={false}
                              />
                            </td>
                            {showCol(`Account ${isWH ? "Id" : "Email"}`) && (
                              <td>{(isAdmin ? tenantsData?.[x.account_id]?.email || "" : x.account_id) || "N/A"}</td>
                            )}
                            {showCol("Item Details") && (
                              <td>
                                {map(omit(x?.item_details, "Qty", "UPC"), (value, heading) => (
                                  <Text
                                    key={"__item_detail" + heading}
                                    heading={getText(heading)}
                                    data={getItemText(x.id, heading, value, "text-primary")}
                                  />
                                ))}
                                <div className="d-flex">
                                  <React.Fragment key={"__item_detail Qty"}>
                                    <Text heading={"Qty"} data={getItemText(key, "Qty", x?.item_details?.Qty)} />
                                    &nbsp;&nbsp;
                                  </React.Fragment>
                                  <RenderIf isTrue={x?.item_details?.UPC}>
                                    UPC: <ShowUpc index={key} upc={x.item_details.UPC} className={"ml-1"} />
                                  </RenderIf>
                                </div>
                              </td>
                            )}
                            {showCol("Date Initiated") && <td>{getDate(x.return_date, DATE)}</td>}
                            {showCol("Delivered Date") && <td>{getDate(x.delivered_date, DATE)}</td>}
                            {showCol("Marketplace") && <td>{getText(x.marketplace)}</td>}
                            {showCol("Order Details") && (
                              <td>
                                <div className="d-flex flex-column">
                                  {isWH ? (
                                    x.order_id || "N/A"
                                  ) : (
                                    <Link
                                      to={`/source_orders_details/${x.order_id}/${x.source_order_id}`}
                                      target="_blank"
                                    >
                                      {x.order_id}
                                    </Link>
                                  )}
                                  <Text heading="Item #" data={getText(x.marketplace_item_id)} />
                                </div>
                              </td>
                            )}
                            {showCol("Tracking Details") && (
                              <Suspense>
                                <TrackingDetails
                                  trackingNum={x.tracking_number}
                                  carrier={x.carrier}
                                  status={x.tracking_status}
                                  inventoryId={x.inventory_id}
                                />
                              </Suspense>
                            )}
                            {showCol("Warehouse") && (
                              <td>
                                <SimpleSelect
                                  value={{
                                    label: getWarehouseName(userWarehouses?.[x.warehouse_id]) || "Select Warehouse",
                                    value: x.issue_type || "",
                                  }}
                                  menuPlacement={getMenuPlacement(returns.data, key)}
                                  onChange={(e) => {
                                    if (e.value !== x.warehouse_id)
                                      dispatch(updateReturn(x.id, { warehouse_id: e.value }));
                                  }}
                                  name={`__warehouse_id__${x.id}`}
                                  options={Object.keys(userWarehouses || {}).map((x) => ({
                                    label: getWarehouseName(userWarehouses[x]),
                                    value: userWarehouses[x].id,
                                  }))}
                                  formStyle={{ minWidth: "180px", marginBottom: "0" }}
                                />
                              </td>
                            )}
                            {showCol("Return #") && <td>{getText(x.return_number)}</td>}
                            {showCol("Return Status") && (
                              <td>
                                {
                                  <Suspense>
                                    <StatusBadge status={x.return_status} />
                                  </Suspense>
                                }
                              </td>
                            )}
                            {showCol("Refund Status") && <td>{humanizeStr(x.refund_status)}</td>}
                            {showCol("Warehouse Status") && (
                              <td>
                                <RenderIf isTrue={x?.id}>
                                  {(x?.wh_order?.shipment_lines?.length && x?.wh_order?.shipment_lines?.[0]?.status) ||
                                  !isEmpty(x?.outbound) ? (
                                    <span id={`return_warehouse_status_${x?.id}`}>
                                      <Suspense>
                                        <StatusBadge status={x.warehouse_status} />
                                      </Suspense>
                                      <CustomTooltip
                                        target={`return_warehouse_status_${x?.id}`}
                                        content={toolTipContent(x)}
                                        placement={"top"}
                                      />
                                    </span>
                                      ) : (
                                    <>
                                      <span id={`return_warehouse_status_fallback_${x?.id}`}> N/A </span>
                                      <CustomTooltip
                                        target={`return_warehouse_status_fallback_${x?.id}`}
                                        content={"Item is not shipped to warehouse."}
                                        placement={"top"}
                                      />
                                    </>
                                      )}
                                  </RenderIf>
                              </td>
                            )}
                            {showCol("Return Reason") && <td>{humanizeStr(x.return_reason)}</td>}
                            {showCol("Is Open") && (
                              <td>
                                <CheckBox
                                  state={internalStatusMapping[x.internal_status]}
                                  setState={(e) => {
                                    setIsOpenToggleConfirmation({
                                      id: x.id,
                                      status: { internal_status: getKeyByValue(internalStatusMapping, e) },
                                      filters,
                                    });
                                    setIsOpen(true);
                                  }}
                                  isSwitch={true}
                                />
                              </td>
                            )}
                            {showCol("Actions") && <ActionButtons item={x} index={key} />}
                            {showCol("Issue Type") && (
                              <td>
                                {isWH ? (
                                  <SimpleSelect
                                    value={{
                                      label: startCase(x.issue_type) || "Select Issue Type",
                                      value: x.issue_type || "",
                                    }}
                                    menuPlacement={getMenuPlacement(returns.data, key)}
                                    onChange={(e) => {
                                      if (e.value !== x.issue_type)
                                        dispatch(updateReturn(x.id, { issue_type: e.value }));
                                    }}
                                    name={`__issue_type__${x.id}`}
                                    options={Object.values(RETURNED_ITEM_ISSUES).map((value) => ({
                                      label: startCase(value),
                                      value,
                                    }))}
                                    formStyle={{ minWidth: "180px", marginBottom: "0" }}
                                  />
                                ) : (
                                  startCase(x.issue_type) || "N/A"
                                )}
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                {returns?.data.length ? (
                  <Suspense>
                    <CustomPagination
                      total={returns.count}
                      pageOptions={[20, 50, 100]}
                      page={returns.page}
                      perPage={returns.per_page}
                      tabsFilter={filters}
                      setTabFilter={setFilters}
                    />
                  </Suspense>
                ) : null}
              </CardBody>
            ) : null}
            {returns?.data.length === 0 && !loading ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
            ) : null}
          </Card>
        </Container>
      </div>
      {filtersSideBarIsOpen ? (
        <Suspense>
          <CustomFilter
            filters={filters}
            customFilters={customFilters}
            setFilters={setFilters}
            setCustomFilters={setCustomFilters}
            humanizeStr={humanizeStr}
          />
        </Suspense>
      ) : null}
    </React.Fragment>
  );
};

export default Returns;
