import {
  FETCH_ALL_PLATFORM,
  FETCH_ALL_PLATFORM_SUCCESS,
  UPDATE_PLATFORM,
  UPDATE_PLATFORM_SUCCESS,
  SET_EDIT_PLATFORM_MODAL,
  SET_SELECTED_PLATFORM,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  platforms: { data: [] },
  platformEditModalIsOpen: false,
  selectedPlatform: null,
};

const Platforms = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PLATFORM: {
      const { fromSupplierModel } = action.payload;
      state = {
        ...state,
        success: "",
        error: "",
        loading: !fromSupplierModel,
      };
      break;
    }
    case FETCH_ALL_PLATFORM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        platforms: action.payload,
      };
      break;
    case UPDATE_PLATFORM:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_PLATFORM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Update Successfull",
      };
      break;
    case SET_EDIT_PLATFORM_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        platformEditModalIsOpen: action.payload,
      };
      break;
    case SET_SELECTED_PLATFORM:
      state = {
        ...state,
        error: "",
        success: "",
        selectedPlatform: action.payload,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        success: false,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Platforms;
