import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Container, Alert } from "reactstrap";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EditCreditCardsModal from "./Components/EditCreditCardModal";
import CustomPagination from "../../components/Common/CustomPagination";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import PillBtn from "../../components/Common/PillBtn";
import { HELP_DOCS_LINK, USER_ROLES } from "../../../src/constants";
import dragImg from "../../assets/svg/drag.svg";

// actions
import {
  fetchCreditCardDetails,
  deleteCreditCard,
  setPreloader,
  setSelectedCreditCard,
  setAddCreditCardsModal,
  setEditCreditCardsModal,
  setCreditCardsFilter,
  fetchCardTypes,
  updateCardPriorities,
} from "../../store/actions";
import { decode, getUserInfo, isManualTeam } from "../../utils/common";

const CreditCards = (props) => {
  const [search, setSearch] = useState("");
  const [ccTypes, setCcTypes] = useState({});
  const [error, setError] = useState("");
  const timeOut = useRef(false);

  const tableHeaders = [
    { title: "Set Priority" },
    { title: "Card Holder Name" },
    { title: "Last Four Digits" },
    { title: "Active" },
    { title: "Primary" },
    { title: "Type" },
    { title: "Action" },
  ];

  function handleAddCreditCard () {
    props.setAddCreditCardsModal(true);
  }

  const filters = props.CreditCards.filters;
  const setFilters = (filters) => props.setCreditCardsFilter(filters);
  const data = filterCredentials(search);

  function handleEditCreditCard (creditcard) {
    props.setSelectedCreditCard({
      ...creditcard,
      id: creditcard.id,
      card_holder_name: decode(creditcard.card_holder_name),
      card_no: decode(creditcard.card_no),
      cvv: decode(creditcard.cvv),
    });
    props.setEditCreditCardsModal(true);
  }

  function handleDeleteCreditCard (card) {
    props.deleteCreditCard(card.id);
  }

  function filterCredentials (cardDetails) {
    if (!cardDetails) return props.CreditCards.ccData?.data;

    return props.CreditCards?.ccData?.data.filter((subData) => {
      return (
        decode(subData?.card_holder_name).toLowerCase().includes(cardDetails?.toLowerCase()) ||
        subData.last_four?.toLowerCase()?.includes(cardDetails?.toLowerCase()) ||
        subData.card_type?.toLowerCase()?.includes(cardDetails?.toLowerCase())
      );
    });
  }

  useEffect(() => {
    props.fetchCreditCardDetails(filters);
    !props.CreditCards.ccTypes.length && props.fetchCardTypes({});
  }, [filters]);

  useEffect(() => {
    props.CreditCards.ccTypes?.length &&
      setCcTypes(
        props.CreditCards.ccTypes.reduce((acc, x) => {
          acc[x.id] = x.name;
          return acc;
        }, []),
      );
  }, [props.CreditCards.ccTypes]);

  useEffect(() => {
    props.setPreloader(props.CreditCards.loading);
  }, [props.CreditCards.loading]);

  useEffect(() => {
    timeOut.current = setTimeout(() => error && setError(""), 3000);
    return () => timeOut.current && clearTimeout(timeOut.current);
  }, [error]);

  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  };

  const isOrderCorrect = (cards) => {
    for (let i = 0; i < cards.length - 1; i++) {
      if (cards[i].is_blocked && !cards[i + 1].is_blocked) return false;
    }
    return true;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const oldArray = props?.CreditCards?.ccData?.data;
    const getNewArray = (data) => arrayMove(data, oldIndex, newIndex);
    const newArray = getNewArray(props?.CreditCards?.ccData?.data);
    const isChanged = newArray.some((card, i) => card.id !== props?.CreditCards?.ccData?.data[i].id);
    if (!isChanged) return;
    if (!isOrderCorrect(newArray)) {
      props.CreditCards.ccData.data = oldArray;
      setError("Blocked card cannot have a higher priority than non-Blocked card");
      return;
    }
    props.updateCardPriorities({ data: newArray });
  };

  const DragHandle = sortableHandle(() => (
    <td>
      <img
        style={{ height: "1.5rem", width: "1.5rem", marginLeft: "25px", cursor: "grab" }}
        src={dragImg}
        alt="Drag Card to set priority"
      ></img>
    </td>
  ));

  const SortableCont = SortableContainer(({ children }) => <tbody id="sortableTable">{children}</tbody>);

  const SortableItem = SortableElement((props) => <TableRow {...props} />);

  const isAllowed =
    getUserInfo()?.role !== "admin" ||
    isManualTeam() ||
    [23, 27, 298, 4010, 4593, 5655, 7552].includes(getUserInfo()?.id) ||
    ([3382].includes(getUserInfo()?.agency_id) && getUserInfo()?.role === USER_ROLES.owner); // these are users ids to access edit card info

  const TableRow = ({ item, index }) => {
    return (
      <tr key={"_credit_cards_" + index} className="mt-3 mb-3">
        {isAllowed && <DragHandle />}
        <td>{item?.card_holder_name && decode(item.card_holder_name)}</td>
        <td className="text-center">{item?.last_four} </td>
        <td className="text-center">
          <i
            title={item?.is_blocked ? "Blocked" : "Not blocked"}
            className={`bx bx-sm ${item?.is_blocked ? "bx-block text-danger" : "bx-check-circle text-success"}`}
          ></i>
        </td>
        <td className="text-center">
          {item?.primary
            ? (
            <i className="bx bx-sm bx-check-circle text-success"></i>
              )
            : (
            <i className="bx bx-sm bx-block text-danger"></i>
              )}
        </td>
        <td className="text-center">{ccTypes[item?.card_type_id] || ""}</td>
        {isAllowed && (
          <td className="text-center">
            <i
              title="Edit"
              className="bx bx-sm bx-edit p-1 text-primary cursor-pointer"
              onClick={() => handleEditCreditCard(item)}
            ></i>
            <ConfirmDialogIcon
              icon={{
                title: "Delete Credit Card",
                className: "bx bx-sm text-danger bx-trash",
              }}
              msg={<p>To Delete this Credit Card</p>}
              onConfirm={() => handleDeleteCreditCard(item)}
            />
          </td>
        )}
      </tr>
    );
  };

  return (
    <React.Fragment>
      {props.CreditCards.editCreditCardModalIsOpen && props.CreditCards.selectedCreditCard && (
        <EditCreditCardsModal type="edit" />
      )}
      {props.CreditCards.addCreditCardModalIsOpen && <EditCreditCardsModal type="add" />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={
              <>
                Credit Cards{" "}
                {
                  <a href={HELP_DOCS_LINK} rel="noopener noreferrer" target="_blank">
                    <i className="bx bx-info-circle"></i>
                  </a>
                }
              </>
            }
            children={
              <PillBtn
                color="success"
                title="Add Credit Card"
                name="Add Credit Card"
                icon="bx bx-xs bx-plus"
                className="col ml-2"
                onClick={handleAddCreditCard}
              />
            }
          />

          {props.CreditCards?.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.CreditCards.success || ""}
              </Alert>
            </div>
          )}

          {(props.CreditCards?.error || error) && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.CreditCards.error || error || ""}
            </Alert>
          )}

          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value?.trim() || "")}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
              </div>
            </CardHeader>
            <CardBody>
              {data?.length
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((header, index) =>
                            !isAllowed && [/set priority/i, /action/i].some((x) => x.test(header.title))
                              ? null
                              : (
                              <th className={index > 1 ? "text-center" : ""} key={`table-header ${index}`}>
                                {header.title}
                              </th>
                                ),
                          )}
                        </tr>
                      </thead>
                      <SortableCont
                        onSortEnd={onSortEnd}
                        axis="y"
                        lockAxis="y"
                        lockToContainerEdges={true}
                        lockOffset={["30%", "50%"]}
                        helperClass="sortable-helper"
                        helperContainer={() => document.getElementById("sortableTable")}
                        useDragHandle={true}
                      >
                        {data?.map((item, index) => (
                          <SortableItem key={`__sortableItem${index}__`} index={index} item={item} />
                        ))}
                      </SortableCont>
                    </table>
                  </div>
                  {props.CreditCards?.ccData?.count && (
                    <CustomPagination
                      total={props.CreditCards?.ccData?.count}
                      page={props.CreditCards?.ccData?.page}
                      perPage={props.CreditCards?.ccData?.per_page}
                      tabsFilter={filters}
                      setTabFilter={setFilters}
                    />
                  )}
                </>
                  )
                : null}
              {data?.length === 0 && !props.CreditCards.loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { CreditCards } = state;
  return { CreditCards };
};

export default connect(mapStatetoProps, {
  fetchCreditCardDetails,
  deleteCreditCard,
  setPreloader,
  setSelectedCreditCard,
  setAddCreditCardsModal,
  setEditCreditCardsModal,
  setCreditCardsFilter,
  fetchCardTypes,
  updateCardPriorities,
})(CreditCards);
