export const FETCH_AMAZON_SELLERS = "FETCH_AMAZON_SELLERS";
export const FETCH_AMAZON_SELLERS_SUCCESS = "FETCH_AMAZON_SELLERS_SUCCESS";
export const SET_AMAZON_SELLERS_FILTERS = "SET_AMAZON_SELLERS_FILTERS";
export const CREATE_AMAZON_SELLER = "CREATE_AMAZON_SELLER";
export const CREATE_AMAZON_SELLER_SUCCESS = "CREATE_AMAZON_SELLER_SUCCESS";
export const UPDATE_AMAZON_SELLER = "UPDATE_AMAZON_SELLER";
export const UPDATE_AMAZON_SELLER_SUCCESS = "UPDATE_AMAZON_SELLER_SUCCESS";
export const SET_ADD_AMAZON_SELLER_MODAL = "SET_ADD_AMAZON_SELLER_MODAL";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const API_ERROR = "SELLER_API_ERROR";
