import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
  RenderIf,
  callCanonical,
  clearLocalStorage,
  decode,
  encode,
  isInviteUrl,
  isSignUpFromExtension,
  isSignUpFromSubApps,
  makeQP,
} from "../../../utils/common";
import StatsPanel from "./StatsPanel";
import SupportedMarketplaces from "./SupportedMarketplaces";
import * as $ from "lodash";
import PillBtn from "../../../components/Common/PillBtn";
import { ENV, FIRST_LAST_NAME_REGEX, PASSWORD_REGEX, PRIVACY_URL, TERMS_OF_CONDITION_URL } from "../../../constants";
import { Alert, Input, Label, Spinner } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import * as actions from "../../../store/actions";
import { apiError } from "../../../store/auth/login/actions";
import CheckBox from "../../../components/Common/CheckBox";

const SIGN_UP_ON_MAPPING = {
  wfs_calculator: "wfs_extension",
};

const SigninSignUpForm = (props) => {
  const { type, isWHLogin } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryString.parse(document.location.search);
  const { token, hash } = useParams();
  const { exp, email } = parseToken(token);
  const isExpired = exp && new Date(token.exp) < new Date();
  const { error, appCreds, loading } = useSelector((state) => state.Login);
  const {
    success: resetPasswordSucces,
    loading: resetPasswordLoading,
    error: resetPasswordError,
  } = useSelector((state) => state.ForgetPassword);
  const { success: signUpSuccess, loading: signUpLoading, error: signUpError } = useSelector((state) => state.Account);

  const [isRegister, isSignIn, isForgotPassword, isResetPassword] = [
    "register",
    "sign_in",
    "forgot_password",
    "reset_password",
  ].map((x) => x === type);
  const totalSteps = isRegister ? 2 : 1;
  const [stepNo, setStepNo] = useState(
    isSignIn || isForgotPassword || isResetPassword || isInviteUrl() || isSignUpFromSubApps(queryParams) ? 2 : 1,
  );
  const [isShownPass, setIsShownPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
  const [isAgency, setIsAgency] = useState();
  const isWHToken = useRef(false);
  if (document.location.pathname.includes("verify_wh_invite")) isWHToken.current = true;

  const labelColor = "#A7B0CF";
  const buttonTextMapping = {
    register: "Create Account",
    sign_in: "Sign In",
    forgot_password: "Send Email",
    reset_password: "Reset Password",
  };
  const loginTextMapping = {
    true: { title: "Sign In as EcomCircles User", url: "/login" },
    false: { title: "Sign In as Warehouse Staff", url: "/login_warehouse" },
  };

  // getting queries params from url
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const isAgencyRegistration = params?.type === "agency";
  // get specific fpr value from params and store it in cookies
  if (params.fpr) document.cookie = "promotion-value-fp = " + params.fpr;

  const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

  function parseToken (token) {
    try {
      return JSON.parse(decode(token || "", 3) || "{}");
    } catch (error) {
      actions.setRegError("Token invalid.");
      return {};
    }
  }

  const nextStep = () => setStepNo(stepNo + 1);
  const prevStep = () => setStepNo(stepNo - 1);

  useEffect(() => {
    if (queryParams && queryParams.client_id && queryParams.redirect_url) {
      dispatch(actions.getOAuthAppCreds(queryParams?.client_id, queryParams?.redirect_url));
    } else if ($.size(queryParams) > 1 && !window.location.href.includes("register")) document.location.href = ENV.REACT_APP_URL;
  }, []);

  useEffect(() => {
    if (isExpired) actions.setRegError("Invite link expired ask account owner to generate a new one.");
    if (props.user?.email && !isAgency) {
      if (props.match?.path === "/invite/:token") props.history.push("/login");
      else props.history.push(`/notification/${encode(props.user?.email)}`);
    }
  }, []);

  useEffect(() => {
    if (signUpSuccess && formik.values.email) {
      if (isInviteUrl()) history.push("/");
      else window.open(`https://ecomcircles.com/software-email-verification${makeQP(queryParams)}`, "_self");
    } else if (resetPasswordSucces) formik.resetForm({ values: { ...initialValues, email: "" } });
  }, [signUpSuccess, resetPasswordSucces]);

  const initialValues = {
    name: "",
    email: email || "",
    password: "",
    password_confirm: "",
    is_agency: "false",
    tos_accepted: false,
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      let validationKeys = ["email"];
      if (isRegister) validationKeys = validationKeys.concat("password", "is_agency", "name", "tos_accepted");
      else if (isSignIn) validationKeys = validationKeys.concat("password");

      validationKeys.forEach((key) => !values[key] && (errors[key] = "Field is required"));
      if (errors.tos_accepted) errors.tos_accepted = "Agree before continue.";
      if (isResetPassword && !errors.password_confirm && values.password !== values.password_confirm)
        errors.password_confirm = "Password does not match.";

      const regexFields = {
        name: {
          regex: FIRST_LAST_NAME_REGEX,
          msg: "Name should only contain letters, spaces, hyphens, and apostrophes.",
        },
        password: {
          regex: isSignIn ? "^.*$" : PASSWORD_REGEX,
          msg: "Password must contain at least one uppercase letter, one number, one special character and must be of 8 characters minimum.",
        },
      };

      Object.entries(regexFields).forEach(
        ([field, desc]) => values[field] && !RegExp(desc.regex).test(values[field]) && (errors[field] = desc.msg),
      );
      return errors;
    },
    onChange: (name, value, { props }) => props.handleFormChange(name, value),
    onSubmit: (values) => {
      let vals = $.cloneDeep(values);
      if (!isResetPassword) vals = $.omit(vals, "password_confirm");

      switch (true) {
        case isRegister: {
          vals.is_agency = vals.is_agency === "true" || isAgencyRegistration;
          token && (vals = { ...vals, invite: token });
          hash && (vals = { ...vals, hash });
          getCookieValue("promotion-value-fp") && (vals = { ...vals, fpr: getCookieValue("promotion-value-fp") });
          const [firstName, ...lastName] = vals.name.split(" ");
          $.merge(
            vals,
            {
              first_name: firstName,
              last_name: lastName.join(" "),
              username: vals.email.split("@").shift() + "-" + moment().unix(),
            },
            $.pick(queryParams, "signup_on", "plan"),
          );
          const signUpOn = $.get(vals, "signup_on");
          if (signUpOn) $.set(vals, "signup_on", $.get(SIGN_UP_ON_MAPPING, signUpOn, signUpOn));
          dispatch(actions.registerUser($.omit(vals, "name", "password_confirm")));
          break;
        }
        case isSignIn && !isWHLogin:
          if (Object.keys(queryParams).length === 2) {
            $.merge(queryParams, { secret_key: appCreds?.secret_key, app_url: appCreds?.redirect_url });
          }
          vals.username = vals.email;
          dispatch(actions.loginUser($.omit(vals, "email"), history, queryParams));
          break;
        case isSignIn && isWHLogin:
          dispatch(actions.loginWareHouseUser(vals, history));
          break;
        case isForgotPassword:
          dispatch(actions.userForgetPassword(vals));
          break;
        case isResetPassword:
          vals = $.pick(vals, "password", "password_confirm");
          if (isWHToken.current && token) dispatch(actions.verifyWareHouseUser({ ...vals, token }, history));
          else {
            token && (vals = { ...vals, invite: token });
            dispatch(actions.resetPassword(vals));
          }
          break;
      }
    },
  });

  const Redirect = ({ to, onClick, children }) => (
    <Link
      to={to}
      className="ml-1 text-primary"
      onClick={() => {
        if (onClick) onClick();
        [actions.setResetPassErr, actions.resetRegReducer, actions.userForgetPasswordSuccess, apiError].forEach(
          (func) => dispatch(func("")),
        );
      }}
    >
      {children}
    </Link>
  );

  const Option = ({ icon, text, value }) => {
    const isSelected = formik.values.is_agency === value;
    const setValue = () => formik.setFieldValue("is_agency", value);
    return (
      <div className={`border-outline ${isSelected ? "outline-radio" : ""}`} htmlFor={value} onClick={setValue}>
        <input
          id={"is_agency__" + value}
          type="radio"
          name="is_agency"
          className="mb-1 mr-2"
          checked={isSelected}
          onChange={setValue}
        />
        <Label htmlFor={value}>
          <div className="d-flex align-items-center " style={{ color: labelColor }}>
            <i className={`bx bx-sm ${icon} ${isSelected ? "text-primary" : ""}`} />
            <div className={`ml-2 font-size-16 ${isSelected ? "text-primary" : ""}`}>{text}</div>
          </div>
        </Label>
      </div>
    );
  };

  const TextField = ({ label, name, regex, type, placeholder, isShownPass, setIsShownPass }) => (
    <div className="mt-3">
      <Label className="font-size-14 label-wrapper">{$.startCase(label || name)}</Label>
      <div className="d-flex">
        <Input
          name={name}
          placeholder={placeholder || `Enter ${$.startCase(name)}`}
          type={type || "text"}
          invalid={formik.touched[name] && regex && !RegExp(regex).test(formik.values[name])}
          value={formik.values[name]}
          onChange={formik.handleChange}
          readOnly={!!initialValues[name]}
        />
        <RenderIf isTrue={name.includes("password")}>
          <span className="input-group-append">
            <span
              style={{ borderLeftStyle: "none", backgroundColor: "#FFFFFF" }}
              className="input-group-text colorpicker-input-addon rounded-right"
              onClick={() => setIsShownPass(!isShownPass)}
            >
              <i
                className={`fa fa-eye${isShownPass ? "-slash" : ""} mx-1 cursor-pointer`}
                style={{ fontWeight: "100" }}
              />
            </span>
          </span>
        </RenderIf>
      </div>

      <RenderIf isTrue={formik.touched[name] && formik.errors[name]}>
        <small className="text-danger m-1">{formik.errors[name]}</small>{" "}
      </RenderIf>
    </div>
  );

  return (
    <div
      className="registration-form row white-bg position-relative"
      style={{ position: "relative", height: "100vh", paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="row" style={{ maxWidth: "1087px", margin: "0 auto" }}>
        <StatsPanel />
        <div className="col-md-6">
          <div className="d-flex border-shadow-outline flex-column p-4 white-bg">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <RenderIf
                isTrue={isSignUpFromSubApps(queryParams) || isAgencyRegistration}
                fallback={
                  <>
                    <h3 className="d-flex card-text font-size-24 align-items-center card-over-wrapper">
                      <RenderIf isTrue={totalSteps > 1 && stepNo > 1}>
                        <i
                          className="cursor-pointer bx bx-sm bx-arrow-back mr-2 text-primary"
                          onClick={() => {
                            prevStep();
                            setIsAgency(undefined);
                          }}
                        />
                      </RenderIf>
                      <span>{`${$.startCase(type)} for Ecom`}</span>
                      <span className="text-primary circle-text">Circles</span>
                      <RenderIf isTrue={isWHLogin}>
                        <span>&nbsp;Warehouse</span>
                      </RenderIf>
                    </h3>
                    <RenderIf isTrue={stepNo && totalSteps > 1}>
                      <div>
                        <em className="font-size-16 fw-bold">
                          <span className="card-text">Step&nbsp;</span>
                          <span className="text-primary">{`${stepNo} of ${totalSteps}`}</span>
                        </em>
                      </div>
                    </RenderIf>
                  </>
                }
              >
                <h3 className="d-flex card-text font-size-24 align-items-center card-over-wrapper">
                  <RenderIf
                    isTrue={isSignUpFromExtension(queryParams) || isAgencyRegistration}
                    fallback={
                      <span>
                        Ecom Circles Repricer <span className="text-primary">14-Day Trial</span>
                      </span>
                    }
                  >
                    <span>Finish Registration</span>
                  </RenderIf>
                </h3>
              </RenderIf>
            </div>
            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
              <RenderIf isTrue={error || resetPasswordError || signUpError}>
                <div className="auto-hide">
                  <Alert color="danger">{error || resetPasswordError || signUpError}</Alert>
                </div>
              </RenderIf>
              <RenderIf isTrue={resetPasswordSucces || signUpSuccess}>
                <div className="auto-hide">
                  <Alert color="success" style={{ marginTop: "13px" }}>
                    {resetPasswordSucces || signUpSuccess}
                  </Alert>
                </div>
              </RenderIf>
              <RenderIf isTrue={isRegister && stepNo === 1 && !isAgencyRegistration}>
                <div className="radio-select mt-3">
                  <span className="font-size-14 card-text-2">
                    Select an option below and click Continue for your free registration
                  </span>
                  <div>
                    <Option icon="bx-user" text="I am running my own account" value="false" />
                    {/* We're going to show it in future so for now I'm commenting it */}
                    {/* <Option icon="mdi mdi-bank" text="I am an agency running accounts for clients" value="true" /> */}
                  </div>

                  <PillBtn
                    disabled={formik.values.is_agency === undefined}
                    className="w-100 mt-4 rounded font-weight-bold"
                    color="primary"
                    title="Continue"
                    name="Continue"
                    onClick={nextStep}
                  />
                </div>
              </RenderIf>
              <RenderIf isTrue={stepNo === 2 || isAgencyRegistration}>
                <div className="mt-3">
                  <RenderIf isTrue={isRegister}>{TextField({ name: "name", regex: FIRST_LAST_NAME_REGEX })}</RenderIf>
                  <RenderIf isTrue={!isResetPassword}>
                    {TextField({ name: "email", type: "email", value: email || "" })}
                  </RenderIf>
                  <RenderIf isTrue={isRegister || isSignIn || isResetPassword}>
                    {TextField({
                      name: "password",
                      type: isShownPass ? "text" : "password",
                      placeholder: isRegister ? "Choose Password" : null,
                      isShownPass,
                      setIsShownPass,
                    })}
                  </RenderIf>
                  <RenderIf isTrue={isResetPassword}>
                    {TextField({
                      label: "Re-type Password",
                      name: "password_confirm",
                      type: isShowConfirmPass ? "text" : "password",
                      placeholder: "Re-type Password",
                      isShownPass: isShowConfirmPass,
                      setIsShownPass: setIsShowConfirmPass,
                    })}
                  </RenderIf>
                  <RenderIf isTrue={isSignIn}>
                    <div className="text-right mt-3">
                      <Redirect to="/forgot-password" onClick={() => dispatch(actions.resetForgotPassword())}>
                        <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                      </Redirect>
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={isRegister}>
                    <div className="form-group">
                      <CheckBox
                        name="tos_accepted"
                        label={
                          <Label
                            className={formik.touched.tos_accepted && formik.errors.tos_accepted ? "text-danger" : ""}
                          >
                            Agree with
                            <a target="_blank" rel="noopener noreferrer" href={TERMS_OF_CONDITION_URL}>
                              {" "}
                              Terms of Condition{" "}
                            </a>
                            and
                            <a target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
                              {" "}
                              Privacy Policy{" "}
                            </a>
                          </Label>
                        }
                        className="mt-2"
                        state={formik.values.tos_accepted}
                        setState={(state) => formik.setFieldValue("tos_accepted", state)}
                      />
                      <RenderIf isTrue={formik.touched.tos_accepted && formik.errors.tos_accepted}>
                        <small className="text-danger m-1">{formik.errors.tos_accepted}</small>{" "}
                      </RenderIf>
                    </div>
                  </RenderIf>
                  <button className="btn btn-primary w-100 mt-3 rounded submit-btn-wrapper" type="submit">
                    {buttonTextMapping[type]}{" "}
                    <RenderIf
                      isTrue={loading || signUpLoading || resetPasswordLoading}
                      children={<Spinner style={{ width: "20px", height: "20px" }} className="ml-3" />}
                    />
                  </button>
                  <RenderIf isTrue={isForgotPassword || isResetPassword}>
                    <div className="d-flex justify-content-center mt-4">
                      Go back to&nbsp;
                      <Redirect to="/login" onClick={() => clearLocalStorage()}>
                        Login
                      </Redirect>{" "}
                    </div>
                  </RenderIf>
                </div>
              </RenderIf>
              <RenderIf isTrue={isRegister}>
                <div className="d-flex justify-content-center mt-4 dont-have-account-wrapper">
                  Already have an account?&nbsp;
                  <Redirect
                    to="/login"
                    onClick={() => {
                      clearLocalStorage();
                      callCanonical();
                      setTimeout(() => dispatch(actions.resetRegReducer()), 500);
                    }}
                  >
                    Sign In
                  </Redirect>{" "}
                </div>
              </RenderIf>
              <RenderIf isTrue={isSignIn}>
                <RenderIf isTrue={!isWHLogin}>
                  <div className="d-flex justify-content-center mt-4 dont-have-account-wrapper">
                    Don't have an account?&nbsp;
                    <Redirect
                      to="/register"
                      onClick={() => {
                        callCanonical();
                        setTimeout(() => dispatch(actions.resetRegReducer()), 500);
                      }}
                    >
                      Sign Up
                    </Redirect>{" "}
                  </div>
                </RenderIf>
                <RenderIf isTrue={loginTextMapping[isWHLogin]}>
                  <div className="d-none justify-content-center mt-4 footer-text-wrapper">
                    <Redirect to={loginTextMapping[isWHLogin]?.url}>{loginTextMapping[isWHLogin]?.title}</Redirect>
                  </div>
                </RenderIf>
              </RenderIf>
            </form>
          </div>
          <SupportedMarketplaces />
        </div>
      </div>
    </div>
  );
};

export default SigninSignUpForm;
