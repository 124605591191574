import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Label, Input, Alert } from "reactstrap";
import CheckBox from "../../../components/Common/CheckBox";
import { useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "../../../components/Common/Modal";

// actions
import { setTemplateModal, createTemplate } from "../../../store/actions";
import { isEmpty } from "lodash";

const AddAmazonTemplateModal = (platform) => {
  const dispatch = useDispatch();
  const { templates, templateModal, error } = useSelector((state) => state.Listings);

  let templateNames = [];
  if (templates && platform) {
    templateNames = !isEmpty(templates[platform?.platformId])
      ? templates[platform?.platformId].map(({ shipping_template_uuid: uuId }) => uuId.toLowerCase())
      : [];
  }

  const initialValues = { shipping_template_uuid: "", is_default: false };
  const toggleModal = () => {
    dispatch(setTemplateModal(!templateModal));
    formik.resetForm();
  };

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "") errors[key] = "Field is Required";
    });
    if (values.shipping_template_uuid && templateNames.includes(values.shipping_template_uuid.toLocaleLowerCase()))
      errors.shipping_template_uuid = "Template already exist!";
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      dispatch(createTemplate({ partner_id: platform?.platformId, ...values }));
      formik.handleReset();
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={templateModal} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Add sample template</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mt-2">
                <Label>Name</Label>
                <Input
                  name="shipping_template_uuid"
                  value={formik.values.shipping_template_uuid}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.shipping_template_uuid && formik.errors.shipping_template_uuid
                  ? (
                  <small className="text-danger">{formik.errors.shipping_template_uuid}</small>
                    )
                  : null}
              </Col>
            </Row>
            <Row>
              <Col lg="12 mt-2">
                <div className="d-flex align-items-center">
                  <Label className="mt-2 mr-3 ">
                    <span>Default</span>
                  </Label>
                  <CheckBox
                    name="is_default"
                    state={Boolean(formik.values.is_default)}
                    isSwitch={true}
                    setState={() => formik.setFieldValue("is_default", !formik.values.is_default)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" className="mr-3">
                Create Template
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default AddAmazonTemplateModal;
