import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Label, Button, FormGroup, Input } from "reactstrap";
import Modal from "../../components/Common/Modal";
import { useFormik } from "formik";
import "react-toggle/style.css";
import { connect } from "react-redux";
import Select from "react-select";
import { decode } from "../../utils/common";
// actions
import { bulkUpdateCredentials, setSelectedIds, setCredentialsBulkActionModal } from "../../store/actions";

const CredentialBulkActionModal = (props) => {
  const [isCustomType, setIsCustomType] = useState(false);
  const selectedUsers = props.Credential?.accounts?.reduce((acc, account) => {
    const sites = account.credentials.reduce((sites, credential) => {
      props?.Credential?.selectedIds?.includes(credential.id) && sites.push(credential.site);
      return sites;
    }, []);
    sites.length &&
      acc.push({
        email: decode(account.email),
        sites,
      });
    return acc;
  }, []);

  const validate = (values) => {
    const errors = {};
    if (values.prefer_card_type === "") errors.prefer_card_type = "Field is Required";
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      prefer_card_type: { label: "Select a Preffered Card Type", value: "" },
      is_custom: false,
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const items = props.Credential?.selectedIds?.map((id) => ({
        id,
        prefer_card_type: values.prefer_card_type.value || values.prefer_card_type,
        is_custom: values.is_custom,
      }));
      props.bulkUpdateCredentials({ data: { items } });
      props.setSelectedIds([]);
    },
  });

  useEffect(() => {
    props.Credential.success && !props.Credential.loading && toggleModal();
  }, [props.Credential.success, props.Credential.loading]);

  const toggleModal = () => {
    props.setCredentialsBulkActionModal(!props.Credential.credentialBulkActionModalIsOpen);
    props.setSelectedIds([]);
  };

  return (
    <Modal size="md" isOpen={props.Credential.credentialBulkActionModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Bulk Update Credit Card Type</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {props.Credential.success && (
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Credential.success}
          </Alert>
        )}
        {props.Credential.error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {"Credentials: " + props.Credential.error}
          </Alert>
        )}
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-5">
              <div className="d-flex flex-column">
                <Label>Selected Accounts</Label>
                <div className="table-responsive overflow-auto" style={{ maxHeight: "200px" }}>
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>Email</th>
                        <th>Platform</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedUsers &&
                        selectedUsers.map((detailUsers, key) => (
                          <tr key={"_detail_users_" + key}>
                            <td>{detailUsers.email || ""}</td>
                            <td>{detailUsers.sites.join(" ") || ""}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12 mb-2">
              <FormGroup className="select2-container">
                <div className="d-flex justify-content-between">
                  <Label>Prefer Card Type</Label>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="is_custom"
                      onChange={() => false}
                      checked={isCustomType}
                    />
                    <label
                      className="custom-control-label"
                      onClick={() => {
                        setIsCustomType(!isCustomType);
                        formik.setFieldValue("is_custom", !isCustomType);
                        formik.setFieldValue("prefer_card_type", {
                          value: "",
                          label: "",
                        });
                      }}
                    >
                      Add New
                    </label>
                  </div>
                </div>
                {!isCustomType
                  ? (
                  <Select
                    name="prefer_card_type"
                    value={formik.values.prefer_card_type || ""}
                    onChange={(option) => {
                      formik.setFieldValue("prefer_card_type", option);
                    }}
                    options={props.ccTypes?.map((type) => ({
                      label: type.name,
                      value: type.id,
                    }))}
                    classNamePrefix="select2-selection"
                  />
                    )
                  : (
                  <Input
                    name="prefer_card_type"
                    value={formik.values.prefer_card_type.value}
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue("prefer_card_type", {
                        label: "",
                        value: e.target.value,
                      })
                    }
                  />
                    )}
              </FormGroup>
              {formik.touched.prefer_card_type && formik.errors.prefer_card_type
                ? (
                <span className="text-danger mt-1">{formik.errors.prefer_card_type}</span>
                  )
                : null}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { Credential } = state;
  return { Credential };
};

export default connect(mapStateToProps, {
  bulkUpdateCredentials,
  setSelectedIds,
  setCredentialsBulkActionModal,
})(CredentialBulkActionModal);
