import amazonLogo from "../assets/svg/brands/amazon-logo.svg";
import boscovLogo from "../assets/svg/brands/boscov-logo.svg";
import bestbuyLogo from "../assets/svg/brands/bestbuy-logo.svg";
import costcoLogo from "../assets/svg/brands/costco-logo.svg";
import dswLogo from "../assets/svg/brands/dsw-logo.svg";
import ebayLogo from "../assets/svg/brands/ebay-logo.svg";
import fleetFarmLogo from "../assets/svg/brands/fleetfarm-logo.svg";
import farmAndFleetLog from "../assets/svg/brands/farmandfleet-logo.svg";
import homeDepotLogo from "../assets/svg/brands/home-depot.svg";
import ifulfillLogo from "../assets/svg/brands/ifulfill.svg";
import lowesLogo from "../assets/svg/brands/lowes-logo.svg";
import luckyVitaminLogo from "../assets/svg/brands/lucky-vitamin-logo.svg";
import overstockLogo from "../assets/svg/brands/overstock-logo.svg";
import samsClubLogo from "../assets/svg/brands/sams-club.svg";
import topDawgLogo from "../assets/svg/brands/topdawg-logo.svg";
import targetLogo from "../assets/svg/brands/target-logo.svg";
import vitacostLogo from "../assets/svg/brands/vitacost-logo.svg";
import wayfairLogo from "../assets/svg/brands/wayfair-logo.svg";
import walmartLogo from "../assets/svg/brands/walmart-logo.svg";
import walgreenLogo from "../assets/svg/brands/walgreen-logo.svg";
import zapposLogo from "../assets/svg/brands/zappos-logo.svg";
import zoroLogo from "../assets/svg/brands/zoro-logo.svg";
import coolPetStuffLogo from "../assets/svg/brands/cool-pet-stuff-logo.svg";
import stullerLogo from "../assets/svg/brands/stuller-logo.svg";
import azImporterLogo from "../assets/svg/brands/az-importer-logo.svg";
import newEggLogo from "../assets/svg/brands/newegg.logo.svg";
import bjsLogo from "../assets/svg/brands/bjs-logo.svg";
import sixPmLogo from "../assets/svg/brands/sixpm.svg";
import faceBookLogo from "../assets/svg/brands/facebook-logo.svg";
import costWayLogo from "../assets/svg/brands/costway-logo.svg";
import menardsLogo from "../assets/svg/brands/menards-logo.svg";
import shopifyLogo from "../assets/svg/brands/shopify-logo.svg";
import warehouseLogo from "../assets/svg/brands/warehouse-logo.svg";
import fulfillerLogo from "../assets/svg/brands/the-fulfiller.svg";
import plumbersStockLogo from "../assets/svg/brands/plumbersStock.webp";
import { matchString } from "./common";

const logoMapping = {
  amazon: amazonLogo,
  boscov: boscovLogo,
  bestbuy: bestbuyLogo,
  costco: costcoLogo,
  dsw: dswLogo,
  ebay: ebayLogo,
  fleetfarm: fleetFarmLogo,
  farmandflee: farmAndFleetLog,
  homedepot: homeDepotLogo,
  "home depot": homeDepotLogo,
  ifulfil: ifulfillLogo,
  lowes: lowesLogo,
  luckyvitamin: luckyVitaminLogo,
  overstock: overstockLogo,
  samsandclub: samsClubLogo,
  samsclub: samsClubLogo,
  topdawg: topDawgLogo,
  target: targetLogo,
  vitacost: vitacostLogo,
  wayfair: wayfairLogo,
  walmart: walmartLogo,
  shopify: shopifyLogo,
  walgreen: walgreenLogo,
  zappos: zapposLogo,
  zoro: zoroLogo,
  stuller: stullerLogo,
  coolpetstuff: coolPetStuffLogo,
  azimporter: azImporterLogo,
  newegg: newEggLogo,
  bjs: bjsLogo,
  sixpm: sixPmLogo,
  facebook: faceBookLogo,
  costway: costWayLogo,
  menards: menardsLogo,
  warehouse: warehouseLogo,
  thefulfiller: fulfillerLogo,
  plumberstock: plumbersStockLogo,
};

const getBrandImageSrc = (name) => {
  for (const [platform, logo] of Object.entries(logoMapping)) {
    if (matchString(String(name), platform)) return logo;
  }
  return "";
};

export default getBrandImageSrc;
