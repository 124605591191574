import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Spinner } from "reactstrap";
import { amountText, RenderIf } from "../../../utils/common";

const EditableField = ({
  identifier,
  value,
  isLoading,
  onClick,
  selected,
  setSelected,
  type,
  loaderKey,
  setLoaderKey,
  min = 0,
}) => {
  const { loader } = useSelector((state) => state.Repricer);
  const [item, setItem] = useState(value);

  useEffect(() => {
    if (!loader) setSelected({});
  }, [loader]);

  const validateItem = +item >= min;
  const checkUpdateItem = +value === +item;
  return (
    <>
      <RenderIf
        isTrue={identifier !== selected}
        fallback={
          <div className="no-spinners weightInput d-flex align-items-center justify-content-center">
            <Input
              style={{ width: "75px" }}
              type="number"
              value={item}
              step="0.01"
              min="0.01"
              className="form-control"
              onChange={(e) => setItem(e.target.value)}
              onKeyPress={(e) => {
                if (["-", "e", "E"].includes(e.key)) e.preventDefault();
              }}
            />
            <RenderIf
              isTrue={isLoading && loaderKey.type === type && loaderKey.id === identifier}
              fallback={
                <div className="iconBox">
                  <i
                    className={`bx bx-sm bx-check color-${validateItem ? "primary" : "secondary"} cursor-${validateItem ? "pointer" : "not-allowed"}`}
                    onClick={() => {
                      setLoaderKey({ type, id: identifier });
                      checkUpdateItem && setSelected("");
                      return validateItem && (checkUpdateItem ? null : onClick(item));
                    }}
                  />
                  <i
                    className="bx bx-sm bx-x danger cursor-pointer"
                    onClick={() => {
                      setItem("");
                      setSelected("");
                      setLoaderKey({});
                    }}
                  />
                </div>
              }
            >
              <Spinner className="mx-1" color="primary" style={{ width: "20px", height: "20px" }} />
            </RenderIf>
          </div>
        }
      >
        <span>
          {amountText(value)}
          <i
            className="ml-2 bx bx-pencil text-primary cursor-pointer"
            onClick={() => {
              setSelected(identifier);
              setItem(value);
            }}
          />
        </span>
      </RenderIf>
    </>
  );
};
export default EditableField;
