export const FETCH_PURCHASING_ACCOUNTS = "FETCH_PURCHASING_ACCOUNTS";
export const FETCH_PURCHASING_ACCOUNTS_SUCCESS = "FETCH_PURCHASING_ACCOUNTS_SUCCESS";
export const FETCH_BUYING_EMAILS = "FETCH_BUYING_EMAILS";
export const FETCH_BUYING_EMAILS_SUCCESS = "FETCH_BUYING_EMAILS_SUCCESS";
export const SET_ADD_PURCHASING_ACCOUNTS_MODAL = "SET_ADD_PURCHASING_ACCOUNTS_MODAL";
export const SET_EDIT_PURCHASING_ACCOUNTS_MODAL = "SET_EDIT_PURCHASING_ACCOUNTS_MODAL";
export const CREATE_PURCHASING_ACCOUNTS = "CREATE_PURCHASING_ACCOUNTS";
export const CREATE_PURCHASING_ACCOUNTS_SUCCESS = "CREATE_PURCHASING_ACCOUNTS_SUCCESS";
export const SELECT_PURCHASING_ACCOUNTS = "SELECT_PURCHASING_ACCOUNTS";
export const UPDATE_PURCHASING_ACCOUNTS = "UPDATE_PURCHASING_ACCOUNTS";
export const UPDATE_PURCHASING_ACCOUNTS_SUCCESS = "UPDATE_PURCHASING_ACCOUNTS_SUCCESS";
export const DELETE_PURCHASING_ACCOUNT = "DELETE_PURCHASING_ACCOUNT";
export const DELETE_PURCHASING_ACCOUNT_SUCCESS = "DELETE_PURCHASING_ACCOUNT_SUCCESS";
export const ADD_USERS_PURCHASING_ACCOUNT = "ADD_USERS_PURCHASING_ACCOUNT";
export const ADD_USERS_PURCHASING_ACCOUNT_SUCCESS = "ADD_USERS_PURCHASING_ACCOUNT_SUCCESS";
export const SET_CREATE_CREDIT_CARD_TYPES_MODAL = "SET_CREATE_CREDIT_CARD_TYPES_MODAL";
export const CREATE_CREDIT_CARD_TYPES = "CREATE_CREDIT_CARD_TYPES";
export const CREATE_CREDIT_CARD_TYPES_SUCCESS = "CREATE_CREDIT_CARD_TYPES_SUCCESS";
export const SET_ADD_CREDENTIALS_MODAL = "SET_ADD_CREDENTIALS_MODAL";
export const SET_EDIT_CREDENTIALS_MODAL = "SET_EDIT_CREDENTIALS_MODAL";
export const CREATE_CREDENTIALS = "CREATE_CREDENTIALS";
export const CREATE_CREDENTIALS_SUCCESS = "CREATE_CREDENTIALS_SUCCESS";
export const UPDATE_CREDENTIALS = "UPDATE_CREDENTIALS";
export const UPDATE_CREDENTIALS_SUCCESS = "UPDATE_CREDENTIALS_SUCCESS";
export const SELECT_CREDENTIALS = "SELECT_CREDENTIALS";
export const UNLOCK_CREDENTIAL = "UNLOCK_CREDENTIAL";
export const UNLOCK_CREDENTIAL_SUCCESS = "UNLOCK_CREDENTIAL_SUCCESS";
export const DELETE_CREDENTIAL = "DELETE_CREDENTIAL";
export const DELETE_CREDENTIAL_SUCCESS = "DELETE_CREDENTIAL_SUCCESS";
export const API_ERROR = "CREDENTIAL_API_ERROR";
export const SET_CREDENTIALS_FILTERS = "SET_CREDENTIALS_FILTERS";
export const SET_SELECTED_IDS = "SET_SELECTED_IDS";
export const SET_CREDENTIAL_BULK_UPDATE_MODAL = "SET_CREDENTIAL_BULK_UPDATE_MODAL";
export const CREDENTIAL_BULK_UPDATE = "CREDENTIAL_BULK_UPDATE";
export const CREDENTIAL_BULK_UPDATE_SUCCESS = "CREDENTIAL_BULK_UPDATE_SUCCESS";
export const ADD_PROXY_MANUAL = "ADD_PROXY_MANUAL";
export const ADD_PROXY_MANUAL_SUCCESS = "ADD_PROXY_MANUAL_SUCCESS";
export const REFRESH_ACCOUNT_PROXY = "REFRESH_ACCOUNT_PROXY";
export const REFRESH_ACCOUNT_PROXY_SUCCESS = "REFRESH_ACCOUNT_PROXY_SUCCESS";
export const FETCH_TOTP = "FETCH_TOTP";
export const FETCH_TOTP_SUCCESS = "FETCH_TOTP_SUCCESS";
