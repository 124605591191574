import React from "react";
import SimpleSelect from "./SimpleSelect";

const timeZoneOptions = [
  { value: "-08:00", label: "PST (UTC -08:00)" },
  { value: "-07:30", label: "PST (UTC -07:30)" },
  { value: "-07:00", label: "PST (UTC -07:00)" },
  { value: "-06:00", label: "CST (UTC -06:00)" },
  { value: "-05:00", label: "EST (UTC -05:00)" },
  { value: "-04:00", label: "EST (UTC -04:00)" },
  { value: "+00:00", label: "UTC (UTC +00:00)" },
];

const UTCSelect = ({
  id,
  name,
  value,
  onChange,
  placeholder = "Select Time Zone",
  onBlur,
  formStyle,
  label,
  className,
  maxMenuHeight,
  menuPlacement,
  disabled,
}) => {
  return (
    <SimpleSelect
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      options={timeZoneOptions}
      placeholder={placeholder}
      onBlur={onBlur}
      label={label}
      className={className}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={menuPlacement}
      disabled={disabled}
      formStyle={{ width: "200px", ...formStyle }}
    />
  );
};

export default UTCSelect;
