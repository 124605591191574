export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_WAREHOUSE_USER = "LOGIN_WAREHOUSE_USER";
export const LOGIN_WAREHOUSE_USER_SUCCESS = "LOGIN_WAREHOUSE_USER_SUCCESS";
export const GET_OAUTH_APP_CREDS = "GET_OAUTH_APP_CREDS";
export const GET_OAUTH_APP_CREDS_SUCCESS = "GET_OAUTH_APP_CREDS_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const CHECK_TOKEN_EXPIRY = "CHECK_TOKEN_EXPIRY";
export const CHECK_TOKEN_EXPIRY_SUCCESS = "CHECK_TOKEN_EXPIRY_SUCCESS";
export const INTEGRATE_WITH_REPICER = "INTEGRATE_WITH_REPRICER";
export const INTEGRATE_WITH_REPRICER_SUCCESS = "INTEGRATE_WITH_REPRICER_SUCCESS";
export const GENERATE_OTP = "GENERATE_OTP";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";
