import React from "react";
import { Badge } from "reactstrap";

export default function ArchiveBadge () {
  return (
    <Badge
      className="font-size-10 badge-primary text-white ml-2"
      style={{ margin: 0 }}
      color="primary"
      pill
      children="Archived"
    />
  );
}
