import { LABEL_SERVICES } from "../../constants";

const isEasypost = (label) => label === LABEL_SERVICES.easypost;

const carrierOptions = [
  { label: "All Carriers", value: "" },
  { label: "UPS", value: "UPS" },
  { label: "USPS", value: "USPS" },
  { label: "UPSDAP", value: "UPSDAP" },
];

const serivceOptions = [
  { label: "All Services", value: "" },
  { label: "EasyPost", value: "easypost" },
  { label: "Stamps", value: "ecom_circles_shipping" },
];

export { carrierOptions, serivceOptions, isEasypost };
