import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SeeMoreText from "../../../components/Common/SeeMoreText";
import { Button, Row, Col, Input, Label, Card, Alert } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { RenderIf } from "../../../utils/common";
import Dropzone from "react-dropzone";
import { setItemActionsModal, uploadSourceItemsLabel } from "../../../store/actions";
import * as $ from "lodash";
import ReactTable from "../../../components/Common/Table";

const UploadLabelModel = ({ isOpen, setIsOpen, sourceOrder }) => {
  const dispatch = useDispatch();
  const { setItemActionsModalIsOpen } = useSelector((state) => state.WareHouses);

  const toggleItemActionsModal = () => dispatch(setItemActionsModal(!setItemActionsModalIsOpen));
  const [selectedItems, setSelectedItems] = useState([]);
  const [trackingData, setTrackingData] = useState({});
  const [seeMore, setSeeMore] = useState("");
  const [errors, setErrors] = useState({});

  const maxFileSize = 1024 * 1024 * 2;
  const [uploadError, setUploadError] = useState("");
  const [selectedFile, setselectedFile] = useState([]);
  const acceptedFileTypes = ".pdf";

  function triggerAlert (msg) {
    if (uploadError) setUploadError("");
    setUploadError(msg);
    setTimeout(() => setUploadError(""), 5000);
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  const getItemText = (id, key, value, length) => (
    <SeeMoreText
      id={id}
      value={value}
      length={length || 50}
      seeMore={seeMore}
      setSeeMore={setSeeMore}
      className="text-primary"
      onHoverDisable
    />
  );

  const handleSubmit = () => {
    const sourceItems = $.filter(sourceOrder?.source_items, (x) => selectedItems.includes(x.id));
    const file = selectedFile;
    const {
      source_order_address: { name, address1, city, state, zipcode, country, phone },
      marketplace_account: { name: storeName, marketplace, status, valid },
    } = sourceOrder;

    const sourceOrderData = {
      warehouse_id: sourceOrder.wh_id,
      source_order_id: sourceOrder.id,
      order_id: sourceOrder.marketplace_order_id,
      outbound_type: "tracking_items",
      customer_details: { customer_name: name, address1, city, state, country, phone, zipcode },
      marketplace_details: { store_name: storeName, marketplace, marketplace_status: status, valid },
      marketplace_status: sourceOrder.marketplace_status,
      ship_date: sourceOrder.required_shipping_date,
      outbound_id: sourceOrder.outbound?.id,
    };
    const formData = new FormData();
    formData.append("tracking_data", JSON.stringify(trackingData));
    formData.append("source_items", JSON.stringify(sourceItems));
    formData.append("file_name", file[0]);
    formData.append("file_type", file);
    formData.append("source_order_data", JSON.stringify(sourceOrderData));

    dispatch(uploadSourceItemsLabel(formData));
  };

  const handleChange = (value, name) => {
    setTrackingData({ ...trackingData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const invSkus = $.keyBy(
    $.map(sourceOrder.outbound?.outbound_items, (x) => ({ sku: x.warehouse_inventory.sku, label: x.label })),
    "sku",
  );

  return (
    <div>
      <Modal size="lg" isOpen={isOpen} toggle={toggleItemActionsModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Upload Label
          </h5>
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <RenderIf isTrue={uploadError}>
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {uploadError}
            </Alert>
          </RenderIf>
          <ReactTable
            tableHeader={
              <>
                <th className="vw-checkbox">
                  <div className="custom-control custom-checkbox change-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={$.size(sourceOrder?.source_items) === $.size(selectedItems)}
                      onChange={() => false}
                    />
                    <label
                      className="custom-control-label"
                      onClick={() => {
                        if ($.size(sourceOrder?.source_items) === $.size(selectedItems)) {
                          setSelectedItems([]);
                        } else setSelectedItems($.map(sourceOrder?.source_items, "id"));
                      }}
                    >
                      &nbsp;
                    </label>
                  </div>
                </th>
                <th key={"table-header-title"}>Title</th>
                <th key={"table-header-quantity"}>Quantity</th>
              </>
            }
            tableBody={
              <>
                {(sourceOrder?.source_items || []).map((el) => {
                  return !invSkus[el.sku]?.label ? (
                    <React.Fragment key={`_listings_live_${el.id}`}>
                      <tr className={`"table-tr" ${selectedItems?.find((x) => x === el?.id) && "selected-tr"}`}>
                        <td>
                          <div className="custom-control change-checkbox custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={!!selectedItems?.find((x) => x === el?.id)}
                              onChange={() => false}
                            />
                            <label
                              className="custom-control-label mt-2"
                              onClick={() => {
                                if (selectedItems?.find((x) => x === el?.id)) {
                                  setSelectedItems($.filter(selectedItems, (x) => el.id !== x));
                                } else {
                                  setSelectedItems([...selectedItems, el.id]);
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td>{getItemText(el.id, el.id, el.name)}</td>
                        <td>{el.qty}</td>
                      </tr>
                    </React.Fragment>
                  ) : null;
                })}
              </>
            }
          />

          <Row className="mt-1 mx-1">
            <Col md={6}>
              <div className="mb-3">
                <Label>Tracking Number*</Label>
                <Input
                  className="inner form-control"
                  placeholder="Enter tracking number"
                  name="trackingNumber"
                  onChange={(e) => {
                    const { value, name } = e.target;
                    if (!value) return setErrors({ trackingNumber: "Tracking number is required" });
                    handleChange(value, name);
                  }}
                />
                {errors.trackingNumber && <small className="text-danger m-1">{errors.trackingNumber}</small>}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label>Tracking Carrier*</Label>
                <Input
                  className="inner form-control"
                  placeholder="Enter tracking carrier"
                  name="trackingCarrier"
                  onChange={(e) => {
                    const { value, name } = e.target;
                    if (!value) return setErrors({ trackingCarrier: "Tracking carrier is required" });
                    handleChange(value, name);
                  }}
                />
                {errors.trackingCarrier && <small className="text-danger m-1">{errors.trackingCarrier}</small>}
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <form className="">
                <Col className="mx-1">
                  {selectedFile.length === 0 && (
                    <Dropzone
                      onDrop={(acceptedFile, fileRejections) => {
                        if (fileRejections.length) {
                          const [file] = fileRejections;
                          if (file.type !== ".png") triggerAlert("Invalid File type. It must be a PDF file");
                          if (file.size > maxFileSize)
                            triggerAlert(`Max allowed file size is ${formatBytes(maxFileSize)}`);
                        }
                        handleAcceptedFile(acceptedFile);
                      }}
                      accept={acceptedFileTypes}
                      maxFiles="1"
                      maxSize={maxFileSize}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div className="dz-message needsclick mt-2" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                            </div>
                            <h4>Drag or Click to Upload PDF File</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  <RenderIf isTrue={selectedFile.length}>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFile.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  {f.name}
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                  <p className="mb-0"></p>
                                </Col>
                                <i
                                  title="Remove File"
                                  className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                                  onClick={() => {
                                    setselectedFile([]);
                                  }}
                                ></i>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </RenderIf>
                </Col>
              </form>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button type="reset" color="secondary" className="mr-3" onClick={() => setIsOpen(!isOpen)}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="success"
              className="mr-3"
              disabled={
                !$.size(selectedItems) ||
                !$.size(selectedFile) ||
                !trackingData.trackingNumber ||
                !trackingData.trackingCarrier
              }
              onClick={() => {
                handleSubmit();
                setIsOpen(!isOpen);
              }}
            >
              Submit
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default UploadLabelModel;
