import {
  GET_PAYMENT_SOURCES,
  GET_PAYMENT_SOURCES_SUCCESS,
  DELETE_SOURCE,
  DELETE_SOURCE_SUCCESS,
  UPDATE_PAYMENT_SOURCE,
  UPDATE_PAYMENT_SOURCE_SUCCESS,
  SET_ADD_CARD_MODAL,
  GET_CUSTOMER_HISTORY,
  GET_CUSTOMER_HISTORY_SUCCESS,
  RETRY_WH_CHARGES,
  RETRY_WH_CHARGES_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const getPaymentSources = (_) => {
  return {
    type: GET_PAYMENT_SOURCES,
    payload: {},
  };
};

export const getPaymentSourcesSuccess = (sources) => {
  return {
    type: GET_PAYMENT_SOURCES_SUCCESS,
    payload: sources,
  };
};

export const deleteSource = (token) => {
  return {
    type: DELETE_SOURCE,
    payload: { token },
  };
};

export const deleteSourceSuccess = (_) => {
  return {
    type: DELETE_SOURCE_SUCCESS,
    payload: {},
  };
};

export const setAddCardModal = (bool) => {
  return {
    type: SET_ADD_CARD_MODAL,
    payload: bool,
  };
};

export const updatePaymentSource = (token) => {
  return {
    type: UPDATE_PAYMENT_SOURCE,
    payload: { token },
  };
};

export const retryWhCharges = (params, displaySuccess = true) => ({
  type: RETRY_WH_CHARGES,
  payload: { params, displaySuccess },
});

export const retryWhChargesSuccess = (res, displaySuccess = true) => ({
  type: RETRY_WH_CHARGES_SUCCESS,
  payload: { res, displaySuccess },
});

export const updatePaymentSourceSuccess = (_) => ({ type: UPDATE_PAYMENT_SOURCE_SUCCESS });

export const getCustomerHistory = (params) => ({ type: GET_CUSTOMER_HISTORY, payload: params });

export const getCustomerHistorySuccess = (res) => ({ type: GET_CUSTOMER_HISTORY_SUCCESS, payload: res });

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
