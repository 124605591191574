import { makeQP } from "../../utils/common";
import request from "../restClient";

export const getAllSuppliers = async (params) => {
  const url =
    "/api/v1/suppliers?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const createSupplier = async ({ params, data }) => {
  const url =
    "/api/v1/suppliers?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "POST", body: data });
};

export const updateSupplier = async ({ params, data }) => {
  const url = "/api/v1/suppliers/" + params.id + `?platform=${params.platform}`;
  return await request({ url, method: "PATCH", body: data });
};

export const deleteSupplier = async ({ data, id }) => {
  const url = "/api/v1/suppliers/" + id;
  return await request({ url, method: "DELETE", body: data });
};

export const updateAOStatusOnMathing = async (data) => {
  const url = "/api/v1/auto_orders/enable_auto_ordering";
  return await request({ url, method: "POST", body: data });
};

export const updateBrandRestriction = async ({ data, params }) => {
  const url = `/api/v1/auto_orders/update_brand_restriction${makeQP(params)}`;
  return await request({ url, method: "POST", body: data });
};

export const updateErroredListing = async (data) => {
  const url = "/api/v1/auto_orders/resolve_listing_error";
  return await request({ url, method: "PUT", body: data });
};

export const getAOStatus = async ({ params }) => {
  const url =
    "/api/v1/auto_orders/auto_ordering_enabled?" +
    `platform=${params.platform}&partner_id=${params.partner_id}&` +
    params.skus.map((sku) => `skus[]=${encodeURIComponent(sku)}`).join("&");
  return await request({ url, method: "GET" });
};

export const setSkuAsMismatched = async ({ data }) => {
  const url = "/api/v1/suppliers/change_match_status";
  return await request({ url, method: "POST", body: data });
};

export const updateStock = async ({ data }) => {
  const url = "/api/v1/suppliers/reset_stock_on_wm";
  return await request({ url, method: "POST", body: data });
};

export const getSuppliersForSku = async (data) => {
  if (!Object.values(data).flat().length) return {};
  const url = "/api/v1/suppliers/get_suppliers_for_skus";
  return await request({ url, method: "POST", body: data });
};

export const fetchRefreshersStats = async (data) => {
  const url = "/refreshing_stats/supplier_stats";
  return await request({ url, method: "POST", body: data });
};
