import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_WAREHOUSE_USER,
  LOGIN_WAREHOUSE_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  GET_OAUTH_APP_CREDS,
  GET_OAUTH_APP_CREDS_SUCCESS,
  CHECK_TOKEN_EXPIRY,
  CHECK_TOKEN_EXPIRY_SUCCESS,
  INTEGRATE_WITH_REPICER,
  INTEGRATE_WITH_REPRICER_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  username: "",
  success: "",
  repricer_integrated: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        username: action.payload.username,
        loading: false,
      };
      break;
    case LOGIN_WAREHOUSE_USER:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case LOGIN_WAREHOUSE_USER_SUCCESS:
      state = {
        ...state,
        username: action.payload.email.split("@").shift(),
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, loading: true };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, loading: false };
      break;
    case GET_OAUTH_APP_CREDS:
      state = { ...state, loading: true };
      break;
    case GET_OAUTH_APP_CREDS_SUCCESS:
      state = { ...state, appCreds: action.payload, loading: false };
      break;
    case CHECK_TOKEN_EXPIRY:
      state = { ...state, loading: true };
      break;
    case CHECK_TOKEN_EXPIRY_SUCCESS:
      state = { ...state, loading: false };
      break;
    case INTEGRATE_WITH_REPICER:
      state = { ...state, loading: true, success: "" };
      break;
    case INTEGRATE_WITH_REPRICER_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "Repricer Integrated Successfully",
        repricer_integrated: action.payload,
      };
      break;
    case VERIFY_OTP:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case VERIFY_OTP_SUCCESS:
      state = { ...state, loading: false };
      break;
    case GENERATE_OTP:
      state = { ...state, success: "", loading: true };
      break;
    case GENERATE_OTP_SUCCESS:
      state = { ...state, success: action.payload.message, loading: false };
      break;
    case API_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;
    default:
      break;
  }
  return state;
};

export default login;
