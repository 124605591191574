import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  fetchCustomPlans,
  createPlan,
  createPlanGroup,
  updatePlanGroup,
  deletePlan,
  deleteGroup,
  updateGroupAndPlan,
  updatePlan,
} from "../../api";
import {
  fetchPlanGroupsSuccess,
  createPlanSuccess,
  createPlanGroupSuccess,
  updatePlanGroupSuccess,
  updateGroupAndPlanSuccess,
  deletePlanSuccess,
  deleteGroupSuccess,
  setViewPlansModal,
  apiError,
  updatePlanSuccess,
  setEditablePlan,
} from "./actions";
import {
  FETCH_PLAN_GROUPS,
  CREATE_PLAN,
  CREATE_PLAN_GROUP,
  UPDATE_PLAN_GROUP,
  DELETE_PLAN,
  DELETE_GROUP,
  UPDATE_GROUP_AND_PLAN,
  UPDATE_PLAN,
} from "./actionTypes";

const Settings = (state) => state.Settings;

function * fetchCustomPlansSaga ({ payload: { bool } }) {
  try {
    const res = yield call(fetchCustomPlans, bool);
    yield put(fetchPlanGroupsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * createPlanSaga ({ payload: { data } }) {
  try {
    const res = yield call(createPlan, data);
    yield put(createPlanSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updatePlanSaga ({ payload: { id, data } }) {
  try {
    const res = yield call(updatePlan, id, data);
    if (res?.success) {
      yield put(setEditablePlan(undefined));
      yield put(updatePlanSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * createPlanGroupSaga ({ payload }) {
  try {
    const res = yield call(createPlanGroup, payload);
    yield put(createPlanGroupSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updatePlanGroupSaga ({ payload: { id, data } }) {
  try {
    const res = yield call(updatePlanGroup, id, data);
    yield put(updatePlanGroupSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updateGroupAndPlanSaga ({ payload: { id, body } }) {
  try {
    const { filteredTenants } = yield select(Settings);
    const res = yield call(updateGroupAndPlan, id, body);

    const index = filteredTenants.data.findIndex((x) => x.id === id);
    filteredTenants.data[index].plan_group_id = res.group.id;

    yield put(updateGroupAndPlanSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * deletePlanSaga ({ payload: { planId } }) {
  try {
    const res = yield call(deletePlan, planId);
    yield put(deletePlanSuccess(res));
  } catch (error) {
    yield put(setViewPlansModal(false));
    yield put(apiError(error.message || error));
  }
}

function * deleteGroupSaga ({ payload: { groupId } }) {
  try {
    const res = yield call(deleteGroup, groupId);
    yield put(deleteGroupSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchFetchCustomPlans () {
  yield takeEvery(FETCH_PLAN_GROUPS, fetchCustomPlansSaga);
}

export function * watchCreatePlan () {
  yield takeEvery(CREATE_PLAN, createPlanSaga);
}

export function * watchUpdatePlan () {
  yield takeEvery(UPDATE_PLAN, updatePlanSaga);
}

export function * watchCreatePlanGroup () {
  yield takeEvery(CREATE_PLAN_GROUP, createPlanGroupSaga);
}

export function * watchUpdatePlanGroup () {
  yield takeEvery(UPDATE_PLAN_GROUP, updatePlanGroupSaga);
}

export function * watchUpdateGroupAndPlan () {
  yield takeEvery(UPDATE_GROUP_AND_PLAN, updateGroupAndPlanSaga);
}

export function * watchDeletePlan () {
  yield takeEvery(DELETE_PLAN, deletePlanSaga);
}

export function * watchDeleteGroup () {
  yield takeEvery(DELETE_GROUP, deleteGroupSaga);
}

function * CustomPlansSaga () {
  yield all([fork(watchFetchCustomPlans)]);
  yield all([fork(watchCreatePlan)]);
  yield all([fork(watchUpdatePlan)]);
  yield all([fork(watchCreatePlanGroup)]);
  yield all([fork(watchUpdatePlanGroup)]);
  yield all([fork(watchDeletePlan)]);
  yield all([fork(watchDeleteGroup)]);
  yield all([fork(watchUpdateGroupAndPlan)]);
}

export default CustomPlansSaga;
