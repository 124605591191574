import { merge } from "lodash";
import * as types from "./actionTypes";
const {
  FETCH_SOURCE_ORDERS_SUCCESS,
  FETCH_SOURCE_ORDERS,
  FETCH_SOURCE_ORDER_DETAIL,
  FETCH_SOURCE_ORDER_DETAIL_SUCCESS,
  SET_PROCESSING_ITEMS,
  REFRESH_SOURCE_ORDER,
  SET_MARK_ISSUE_MODAL,
  SET_CANCEL_MARKET_PLACE_ORDER_MODAL,
  SET_CHANGED_WAREHOUSE,
  UPDATE_SOURCE_ORDERS,
  UPDATE_SOURCE_ORDERS_SUCCESS,
  SET_ORDER_LOG_MODAL,
  SET_ASSIGN_OUTBOUND_MODAL,
  ASSIGN_INVENTORY_TO_ORDER,
  ASSIGN_INVENTORY_TO_ORDER_SUCCESS,
  SET_SHIPPING_DETAIL_MODAL,
  API_ERROR,
  UPDATE_SOURCE_ADDRESS,
  UPDATE_SOURCE_ADDRESS_SUCCESS,
  SET_ORDER_STATUS_MODAL,
  FETCH_MARKETPLACE_STATUS,
  FETCH_MARKETPLACE_STATUS_SUCCESS,
  FETCH_NEXT_ORDER,
  FETCH_NEXT_ORDER_SUCCESS,
  CANCEL_MARKETPLACE_ORDER,
  CANCEL_MARKETPLACE_ORDER_SUCCESS,
  SET_SELECTED_ORDERS,
  SET_ORDERS_BULK_UPDATE_MODAL,
  ORDERS_BULK_UPDATE,
  ORDERS_BULK_UPDATE_SUCCESS,
  FETCH_LOCK_STATUS,
  FETCH_LOCK_STATUS_SUCCESS,
  SET_EXPORT_ORDER_MODAL,
  REQUEST_SOURCE_ORDER_REPORT,
  REQUEST_SOURCE_ORDER_REPORT_SUCCESS,
  REQUEST_VA_PERFORMANCE_REPORT,
  REQUEST_VA_PERFORMANCE_REPORT_SUCCESS,
  REQUEST_ORDER_PROCESSOR_REPORT,
  REQUEST_ORDER_PROCESSOR_REPORT_SUCCESS,
  SET_CURRENT_USERS,
  SET_PAYMENT_REPORT_MODAL,
  REQUEST_AVAILABLE_PAYMENT_REPORTS,
  REQUEST_AVAILABLE_PAYMENT_REPORTS_SUCCESS,
  REQUEST_PAYMENT_REPORT,
  REQUEST_PAYMENT_REPORT_SUCCESS,
  SET_ORDER_IMPORT_MODAL,
  SET_GENERATE_SHIPPING_LABEL_MODAL,
  SET_CHANGE_WAREHOUSE_MODAL,
  SET_ORDER_UPLOAD_MODAL,
  IMPORT_ORDER_MANUALLY,
  GET_FEED_STATUS,
  GET_FEED_STATUS_SUCCESS,
  IMPORT_ORDER_MANUALLY_SUCCESS,
  UPLOAD_ORDER_MANUALLY,
  UPLOAD_ORDER_MANUALLY_SUCCESS,
  UPLOAD_ORDER_MANUALLY_ERROR,
  CREATE_ORDER_NOTE,
  CREATE_ORDER_NOTE_SUCCESS,
  FETCH_PRODUCT_CATEGORY,
  FETCH_PRODUCT_CATEGORY_SUCCESS,
  UPLOAD_TRACKING_DETAILS,
  UPLOAD_TRACKING_DETAILS_SUCCESS,
  UPDATE_SOURCE_ORDER_STATUS,
  UPDATE_SOURCE_ORDER_STATUS_SUCCESS,
  CHANGE_ORDER_TYPE,
  CHANGE_ORDER_TYPE_SUCCESS,
  SET_SELECT_WAREHOUSE_MODAL,
  SEARCH_ALL_ORDERS,
  SEARCH_ALL_ORDERS_SUCCESS,
  GENERATE_SHIPPING_LABEL,
  GENERATE_SHIPPING_LABEL_SUCCESS,
  GET_ITEMS_DIMENSIONS,
  GET_ITEMS_DIMENSIONS_SUCCESS,
  GET_SHIPPING_RATES,
  GET_SHIPPING_RATES_SUCCESS,
  MANUAL_FULFILLMENT_COLLAPSED,
  SET_EDIT_WH_COST_MODAL,
  SET_PL_DATA,
  SET_ASSIGN_INVENTORY_MODAL,
} = types;

const initialState = {
  error: "",
  loading: false,
  sourceOrders: undefined,
  sourceOrderDetail: undefined,
  nextOrder: undefined,
  itemsForProcessing: [],
  selectedOrders: [],
  changedWareHouseId: null,
  ordersBulkUpdateModalIsOpen: false,
  manualOrderModalIsOpen: false,
  processingItemModalIsOpen: false,
  markIssueModalIsOpen: false,
  cancelMarketPlaceOrderModalIsOpen: false,
  orderLogModalIsOpen: false,
  assignInventroyModalIsOpen: false,
  outboundModalIsOpen: false,
  changeWareHouseModalIsOpen: false,
  selectWarehouseModalIsOpen: false,
  shippingDetailModalIsOpen: false,
  generateShippingLabelModal: false,
  orderStatusModalIsOpen: false,
  orderExportModalIsOpen: false,
  paymentReportModalIsOpen: false,
  editWHCostModalIsOpen: false,
  success: "",
  marketPlaceStatus: undefined,
  vaReport: undefined,
  lockStatus: false,
  currentUsers: {},
  manualFulfillmentCollapse: false,
  isPriceEventsModalOpen: false,
  isPriceEventEditModalOpen: false,
  pAndLData: {},
};

const sourceOrders = (state = initialState, action) => {
  const priceEventCreateUpdateSuccess = (_) =>
    (state = {
      ...state,
      loading: false,
      event_success: action.payload,
      isPriceEventEditModalOpen: false,
      priceEvent: undefined,
    });
  const priceEventCRUD = (_) => (state = { ...state, loading: true, event_success: "", error: "" });

  switch (action.type) {
    case types.UPLOAD_SOURCE_ITEMS_LABEL:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case types.UPLOAD_SOURCE_ITEMS_LABEL_SUCCESS:
      state = {
        ...state,
        success: action.payload.message,
        sourceOrderDetail: action.payload.sourceOrderDetail,
        loading: false,
      };
      break;
    case FETCH_SOURCE_ORDERS:
      state = {
        ...state,
        success: "",
        loading: true,
      };
      break;
    case FETCH_SOURCE_ORDERS_SUCCESS:
      state = {
        ...state,
        sourceOrders: action.payload,
        sourceOrderDetail: undefined,
        loading: false,
      };
      break;
    case FETCH_SOURCE_ORDER_DETAIL:
      state = {
        ...state,
        loading: true,
        isOrderNotFound: false,
        error: "",
      };
      break;
    case FETCH_SOURCE_ORDER_DETAIL_SUCCESS:
      state = {
        ...state,
        success: "",
        sourceOrderDetail: action.payload,
        loading: false,
      };
      break;
    case CHANGE_ORDER_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_ORDER_TYPE_SUCCESS:
      state = {
        ...state,
        selectWarehouseModalIsOpen: false,
        success: "Order Type changed successfully",
        loading: false,
      };
      break;
    case SET_SELECTED_ORDERS:
      state = {
        ...state,
        selectedOrders: action.payload,
      };
      break;
    case SET_ORDERS_BULK_UPDATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        ordersBulkUpdateModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_ORDER_IMPORT_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        ordersImportModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_ORDER_UPLOAD_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        uploadOrdersModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_CHANGED_WAREHOUSE:
      state = {
        ...state,
        changedWareHouseId: action.payload,
      };
      break;
    case IMPORT_ORDER_MANUALLY:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case IMPORT_ORDER_MANUALLY_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        importOrderRes: action.payload,
      };
      break;
    case UPLOAD_ORDER_MANUALLY:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPLOAD_ORDER_MANUALLY_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload,
        loading: false,
      };
      break;
    case UPLOAD_ORDER_MANUALLY_ERROR:
      state = {
        ...state,
        error: action.payload || "Error Gen",
      };
      break;

    case ORDERS_BULK_UPDATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ORDERS_BULK_UPDATE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Orders has been Updated Successfully",
        loading: false,
      };
      break;
    case SET_PROCESSING_ITEMS:
      state = {
        ...state,
        itemsForProcessing: action.payload,
      };
      break;
    case MANUAL_FULFILLMENT_COLLAPSED:
      state = {
        ...state,
        manualFulfillmentCollapse: action.payload,
      };
      break;
    case GET_FEED_STATUS:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_FEED_STATUS_SUCCESS:
      state = {
        ...state,
        feedStatus: action.payload,
        loading: false,
      };
      break;
    case REFRESH_SOURCE_ORDER:
      state = {
        ...state,
        error: "",
        loading: false,
        sourceOrderDetail: undefined,
        itemsForProcessing: [],
        manualOrderModalIsOpen: false,
        processingItemModalIsOpen: false,
        markIssueModalIsOpen: false,
        success: "",
      };
      break;
    case SET_MARK_ISSUE_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        markIssueModalIsOpen: action.payload,
      };
      break;
    case SET_CANCEL_MARKET_PLACE_ORDER_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        cancelMarketPlaceOrderModalIsOpen: action.payload,
      };
      break;
    case UPDATE_SOURCE_ORDERS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_SOURCE_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        editWHCostModalIsOpen: false,
        selectWarehouseModalIsOpen: false,
        success: action.payload.showSuccess === false ? "" : "Source Order Updated",
      };
      break;
    case SET_ORDER_LOG_MODAL:
      state = {
        ...state,
        orderLogModalIsOpen: action.payload,
      };
      if (action.payload) state.error = "";
      break;
    case SET_ASSIGN_INVENTORY_MODAL:
      state = {
        ...state,
        assignInventroyModalIsOpen: action.payload,
      };
      break;
    case SET_ASSIGN_OUTBOUND_MODAL:
      state = {
        ...state,
        outboundModalIsOpen: action.payload,
      };
      break;
    case ASSIGN_INVENTORY_TO_ORDER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ASSIGN_INVENTORY_TO_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        assignInventroyModalIsOpen: false,
        outboundModalIsOpen: false,
        success: "Inventory assigned successfully",
      };
      break;
    case SET_CHANGE_WAREHOUSE_MODAL:
      state = {
        ...state,
        changeWareHouseModalIsOpen: action.payload,
      };
      break;
    case SET_SHIPPING_DETAIL_MODAL:
      state = {
        ...state,
        shippingDetailModalIsOpen: action.payload,
      };
      break;
    case UPDATE_SOURCE_ADDRESS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_SOURCE_ADDRESS_SUCCESS:
      state = {
        ...state,
        loading: false,
        shippingDetailModalIsOpen: false,
        success: "Shipping Detail Updated",
      };
      break;
    case SET_ORDER_STATUS_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        orderStatusModalIsOpen: action.payload,
      };
      break;
    case FETCH_MARKETPLACE_STATUS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_MARKETPLACE_STATUS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        marketPlaceStatus: action.payload,
        loading: false,
      };
      break;
    case FETCH_NEXT_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_NEXT_ORDER_SUCCESS:
      state = {
        ...state,
        nextOrder: action.payload,
        loading: false,
      };
      break;
    case CANCEL_MARKETPLACE_ORDER:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case CANCEL_MARKETPLACE_ORDER_SUCCESS:
      state = {
        ...state,
        success: "Order Cancelled on Marketplace Successfully.",
        cancelMarketPlaceOrderModalIsOpen: false,
        error: "",
        loading: false,
      };
      break;
    case FETCH_LOCK_STATUS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_LOCK_STATUS_SUCCESS:
      state = {
        ...state,
        lockStatus: action.payload,
        loading: false,
      };
      break;
    case SET_EXPORT_ORDER_MODAL:
      state = {
        ...state,
        orderExportModalIsOpen: action.payload.bool,
        orderExportModalType: action.payload.type,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case SET_PAYMENT_REPORT_MODAL:
      state = {
        ...state,
        paymentReportModalIsOpen: action.payload.modal,
        currentReports: undefined,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case REQUEST_SOURCE_ORDER_REPORT:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case REQUEST_SOURCE_ORDER_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "Report exported successfully",
        error: "",
      };
      break;
    case REQUEST_VA_PERFORMANCE_REPORT:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case REQUEST_VA_PERFORMANCE_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.isExport ? "Report exported successfully" : "",
        error: "",
      };
      !action.payload.isExport && (state.vaReport = action.payload.data);
      break;
    case REQUEST_ORDER_PROCESSOR_REPORT:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case REQUEST_ORDER_PROCESSOR_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.isExport ? "Report exported successfully" : "",
        error: "",
      };
      !action.payload.isExport && (state.vaReport = action.payload.data);
      break;
    case REQUEST_AVAILABLE_PAYMENT_REPORTS:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      delete state.extraCols;
      break;
    case REQUEST_AVAILABLE_PAYMENT_REPORTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "",
        error: "",
        currentReports: action.payload.dates || action.payload.reports,
      };
      if (action.payload.extraCols) state.extraCols = action.payload.extraCols;
      break;
    case SET_PL_DATA:
      state.pAndLData = action.payload;
      break;
    case REQUEST_PAYMENT_REPORT:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case REQUEST_PAYMENT_REPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload?.message || "",
        error: "",
      };
      if (action.payload?.data?.marketplaceFee) state.pAndLData = action.payload.data;
      break;
    case types.TOGGLE_PRICE_EVENTS_MODAL:
      state = { ...state, isPriceEventsModalOpen: !state.isPriceEventsModalOpen };
      break;
    case types.TOGGLE_PRICE_EVENTS_EDIT_MODAL:
      state = { ...state, priceEvent: action.payload, isPriceEventEditModalOpen: !state.isPriceEventEditModalOpen };
      break;
    case types.CREATE_PRICE_EVENT:
      priceEventCRUD();
      break;
    case types.CREATE_PRICE_EVENT_SUCCESS:
      priceEventCreateUpdateSuccess();
      break;
    case types.UPDATE_PRICE_EVENT:
      priceEventCRUD();
      break;
    case types.UPDATE_PRICE_EVENT_SUCCESS:
      priceEventCreateUpdateSuccess();
      break;
    case types.DELETE_PRICE_EVENT:
      priceEventCRUD();
      break;
    case types.SYNC_PRICE_EVENT: {
      const order = state.sourceOrderDetail?.data;
      let events = order?.price_events;
      const { payload } = action;
      if (Array.isArray(events)) {
        if (payload.isNew) events.unshift(payload.event);
        else if (payload.isDeleted) {
          events = events.filter((_event) => _event.event_id !== payload.event.event_id);
        } else {
          const _i = events.findIndex((_event) => _event.event_id === payload.event.event_id);
          if (_i !== -1) events[_i] = payload.event;
        }
        delete order.price_events;
        state = {
          ...state,
          sourceOrderDetail: merge({}, state.sourceOrderDetail, { data: merge(order, { price_events: events }) }),
        };
      }
      break;
    }
    case types.DELETE_PRICE_EVENT_SUCCESS:
      state = { ...state, loading: false, event_success: action.payload };
      break;
    case SET_CURRENT_USERS:
      state = {
        ...state,
        currentUsers: action.payload,
      };
      break;
    case CREATE_ORDER_NOTE:
      state = {
        ...state,
        loading: action.payload.loading,
      };
      break;
    case CREATE_ORDER_NOTE_SUCCESS:
      state = {
        ...state,
        success: "Note Added to Order",
        error: "",
        loading: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        isOrderNotFound: /no order found/i.test(action.payload),
        loading: false,
      };
      break;
    case FETCH_PRODUCT_CATEGORY:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_PRODUCT_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        category: action.payload,
        success: "",
        error: "",
      };
      break;
    case UPLOAD_TRACKING_DETAILS:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case UPLOAD_TRACKING_DETAILS_SUCCESS:
      state = {
        ...state,
        success: action.payload.success,
        error: "",
        loading: false,
      };
      break;
    case UPDATE_SOURCE_ORDER_STATUS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
        manualFulfillmentCollapse: false,
      };
      break;
    case UPDATE_SOURCE_ORDER_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case SEARCH_ALL_ORDERS:
      state = { ...state, error: "", success: "", loading: true, allOrders: undefined };
      break;
    case SEARCH_ALL_ORDERS_SUCCESS:
      state = { ...state, loading: false, allOrders: action.payload };
      break;
    case SET_SELECT_WAREHOUSE_MODAL:
      state = {
        ...state,
        selectWarehouseModalIsOpen: action.payload,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case SET_GENERATE_SHIPPING_LABEL_MODAL:
      state = {
        ...state,
        generateShippingLabelModal: action.payload,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case types.ACKNOWLEDGE_ORDER:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case types.ACKNOWLEDGE_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case GENERATE_SHIPPING_LABEL:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case GENERATE_SHIPPING_LABEL_SUCCESS:
      state = { ...state, loading: false, generateShippingLabelModal: false };
      break;
    case GET_ITEMS_DIMENSIONS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case GET_ITEMS_DIMENSIONS_SUCCESS:
      state = { ...state, loading: false, dimensions: action.payload };
      break;
    case GET_SHIPPING_RATES:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case GET_SHIPPING_RATES_SUCCESS:
      state = { ...state, loading: false, shippingRates: action.payload };
      break;
    case SET_EDIT_WH_COST_MODAL:
      state = { ...state, loading: false, editWHCostModalIsOpen: action.payload };
      break;
    default:
      break;
  }
  return state;
};

export default sourceOrders;
