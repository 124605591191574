import * as types from "./actionTypes";
const {
  FETCH_AGENCY_STATS,
  FETCH_AGENCY_STATS_SUCCESS,
  FETCH_AGENCY_CLIENTS,
  FETCH_AGENCY_CLIENTS_SUCCESS,
  FETCH_AGENCY_CLIENTS_PROFIT,
  FETCH_AGENCY_CLIENTS_PROFIT_SUCCESS,
  UPDATE_PAYMENT_SOURCE_SETTINGS,
  UPDATE_PAYMENT_SOURCE_SETTINGS_SUCCESS,
  SET_ADD_CLIENT_MODAL,
  SET_EDIT_CLIENT_MODAL,
  REMOVE_CLIENT,
  REMOVE_CLIENT_SUCCESS,
  API_ERROR,
  FETCH_TENANTS_STATS_SUCCESS,
  FETCH_TENANTS_STATS,
  FETCH_STOREFRONT_HEALTH_MATRICS,
  FETCH_STOREFRONT_HEALTH_MATRICS_SUCCESS,
} = types;

export const fetchAgencyStats = (_) => {
  return {
    type: FETCH_AGENCY_STATS,
    payload: {},
  };
};

export const fetchAgencyStatsSuccess = (stats) => {
  return {
    type: FETCH_AGENCY_STATS_SUCCESS,
    payload: stats,
  };
};

export const fetchAgencyClients = (params) => {
  return {
    type: FETCH_AGENCY_CLIENTS,
    payload: { params },
  };
};

export const fetchAgencyClientsSuccess = (clients) => {
  return {
    type: FETCH_AGENCY_CLIENTS_SUCCESS,
    payload: clients,
  };
};

export const fetchAgencyClientsProfit = (_) => {
  return {
    type: FETCH_AGENCY_CLIENTS_PROFIT,
    payload: {},
  };
};

export const fetchAgencyClientsProfitSuccess = (profits) => {
  return {
    type: FETCH_AGENCY_CLIENTS_PROFIT_SUCCESS,
    payload: profits,
  };
};

export const updatePaymentSourceSettings = (body) => {
  return {
    type: UPDATE_PAYMENT_SOURCE_SETTINGS,
    payload: body,
  };
};

export const updatePaymentSourceSettingsSuccess = (res) => {
  return {
    type: UPDATE_PAYMENT_SOURCE_SETTINGS_SUCCESS,
    payload: res,
  };
};

export const setAddClientModal = (bool) => {
  return {
    type: SET_ADD_CLIENT_MODAL,
    payload: bool,
  };
};

export const setEditClientModal = (isOpen, client) => {
  return {
    type: SET_EDIT_CLIENT_MODAL,
    payload: { isOpen, client },
  };
};

export const removeClient = (data) => {
  return {
    type: REMOVE_CLIENT,
    payload: data,
  };
};

export const removeClientSuccess = (res) => {
  return {
    type: REMOVE_CLIENT_SUCCESS,
    payload: res,
  };
};

export const setArchived = (payload) => ({ type: types.SET_ARCHIVED, payload });

export const archiveClient = (payload) => ({ type: types.ARCHIVE_CLIENT, payload });

export const archiveClientSuccess = (payload) => ({ type: types.ARCHIVE_CLIENT_SUCCESS, payload });

export const updateMessage = (payload) => ({ type: types.UPDATE_MESSAGE, payload });

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const fetchTenantsStats = (_) => {
  return {
    type: FETCH_TENANTS_STATS,
    payload: {},
  };
};

export const fetchTenantsStatsSuccess = (data) => {
  return {
    type: FETCH_TENANTS_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchStoreFrontHealthMatrics = (email) => {
  return {
    type: FETCH_STOREFRONT_HEALTH_MATRICS,
    payload: { email },
  };
};

export const fetchStoreFrontHealthMatricsSuccess = (data) => {
  return {
    type: FETCH_STOREFRONT_HEALTH_MATRICS_SUCCESS,
    payload: data,
  };
};
