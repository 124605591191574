import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Alert, CardTitle, Row, Col, Label, Input, Button, FormGroup } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import CheckBox from "../../../components/Common/CheckBox";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  createSupplier,
  fetchPlatforms,
  resetSupplierState,
  updateSupplier,
  fetchSuppliers,
  fetchInventoryItemInfo,
  resetListingState,
} from "../../../store/actions";

import {
  validateSupplierUrl,
  matchString,
  checkURL,
  checkMarketPlaces,
  getVariation,
  getUserInfo,
  RenderIf,
} from "../../../utils/common";
import { groupBy, reduce, last, values, orderBy, concat, size } from "lodash";
import Select from "react-select";
import { cleanedData } from "../../Listings/Helpers/common";
import NestedVariationModal from "./NestedVariationModal";
import { USER_ROLES } from "../../../constants";

const SupplierModal = (props) => {
  const { platforms: allPlatforms } = useSelector((state) => state.Platform);
  const { platforms: platformsList } = useSelector((state) => state.Supplier);
  const [errorSupplier, setErrorSupplier] = useState(false);
  const [variationModalOpen, setVariationModalOpen] = useState(false);
  const [nestedVariation, setNestedVariation] = useState([{ name: "", value: "", error: [] }]);
  const { whItemData } = useSelector((state) => state.Listings);
  const {
    error: supplierError,
    success: supplierSuccess,
    platforms: supplierPlatforms,
  } = props.Supplier;
  const { error: listingError, success: listingSuccess } = props.Listings;
  const sourceOrderDetail = props.SourceOrders?.sourceOrderDetail?.data;

  const dispatch = useDispatch();
  const { platforms } = reduce(
    supplierPlatforms || [],
    (obj, option) => {
      if (option.name === sourceOrderDetail?.store_name) return obj;
      obj.platforms.push(option);
      if (option.name === "other") obj.otherPId = option.id;
      return obj;
    },
    { platforms: [], otherPId: 0 },
  );

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      const arr = ["name", "quantity_multiplier"];
      if (notWholesaleCheck) arr.push("url");
      else arr.push("seller_sku");
      if (priceLock) arr.push("manual_price");
      if (shippingFeeLock) arr.push("manual_shipping_fee");
      if (values[key] === "") arr.includes(key) && (errors[key] = "Field is Required");
    });

    values.url && checkSupplierError(values.url);
    if (!values.url && !values.seller_sku) {
      if (!values.url) errors.url = "Url is Required";
      if (!values.seller_sku) errors.seller_sku = "Seller SKU is Required";
      if (!validateSupplierUrl(values.url)) errors.url = "Invalid Url";
    }
    return errors;
  };

  const initialValues = {
    name: "",
    url: "",
    platform_id: "",
    quantity_multiplier: "1",
    variation: "",
    seller_sku: "",
    manual_price: "",
    price_lock: false,
    shipping_fee_lock: false,
    manual_shipping_fee: "",
    seller_type: "",
    is_default: false,
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (values.platform_id.value) values.platform_id = values.platform_id.value;
      const isSafe = nestedVariation.every((x) => !x.error.length);
      if (!isSafe) return;
      values.variation = cleanedData(nestedVariation);
      if (props.type === "edit" && props.supplier) {
        props.updateSupplier({
          data: {
            ...values,
          },
          params: {
            id: props.supplier.id,
            platform: sourceOrderDetail?.store_name,
          },
        });
      } else {
        props.createSupplier({
          data: {
            ...values,
          },
          params: {
            sku: props.sourceItem?.[0]?.sku,
            platform: sourceOrderDetail?.store_name,
            partner_id: props.storeId,
          },
        });
      }
    },
  });

  const {
    name,
    url,
    platform_id: platformId,
    quantity_multiplier: quantityMultiplier,
    variation,
    seller_sku: sellerSku,
    manual_price: manualPrice,
    price_lock: priceLock,
    shipping_fee_lock: shippingFeeLock,
    manual_shipping_fee: manualShippingFee,
    is_default: isDefault,
  } = formik.values;

  const {
    url: urlError,
    platform_id: platformIdError,
    quantity_multiplier: quantityMultiplierError,
    seller_sku: sellerSkuError,
    manual_price: manualPriceError,
    manual_shipping_fee: manualShippingFeeError,
  } = formik.errors;

  const {
    url: urlTouched,
    platform_id: platformIdTouched,
    quantity_multiplier: quantityMultiplierTouched,
    seller_sku: sellerSkuTouched,
    manual_price: manualPriceTouched,
    manual_shipping_fee: manualShippingFeeTouched,
  } = formik.touched;

  const notWholesaleCheck =
    platformsList?.find((x) => x.id === parseInt(platformId?.value))?.seller_type !== "wholesale";
  const initialSupplier = { value: "", label: "Select a Platform" };

  const platformGroup = groupBy(platforms, "id");

  function fetchAllPlatforms () {
    !allPlatforms && props.fetchPlatforms({ fromSupplierModel: true });
    dispatch(
      fetchSuppliers({
        platform: sourceOrderDetail?.store_name,
        sku: sourceOrderDetail?.source_items[0]?.sku,
        partner_id: props.storeId,
        is_admin: getUserInfo()?.role === USER_ROLES.admin,
      }),
    );
  }

  useEffect(fetchAllPlatforms, []);

  useEffect(() => {
    props.supplier &&
      props.type === "edit" &&
      Object.keys(initialValues).forEach((key) => {
        !["", undefined, null].includes(props.supplier[key]) && formik.setFieldValue(key, props.supplier[key], false);
      });
  }, [props.supplier]);

  useEffect(() => {
    if (((supplierSuccess && !supplierError) || (listingSuccess && !listingError)) && props.isOpen) {
      toggleModal();
    }
  }, [supplierSuccess, supplierError, listingSuccess, listingError]);

  useEffect(() => {
    if (size(variation) && props.type === "edit") setNestedVariation([{ ...JSON.parse(`${variation}`)[0], error: [] }]);
  }, [formik.values]);

  const toggleModal = () => {
    props.toggleIsOpen(!props.isOpen);
    props.setSelectedSupplier();
    setNestedVariation([{ name: "", value: "", error: [] }]);
    props.resetSupplierState();
    setErrorSupplier();
    formik.resetForm();
    dispatch(resetListingState());
  };

  function checkSupplierError (url) {
    const platforms = allPlatforms?.data?.map((x) => x.name);
    if (platforms?.find((platform) => matchString(String(url), platform))) {
      if (errorSupplier) setErrorSupplier(false);
    } else setErrorSupplier(true);
  }

  const supplierDataOpts = () => {
    const { other, allOpts } = groupBy(values(platformsList), (x) => (x.title === "Other" ? "other" : "allOpts"));
    return concat(orderBy(allOpts, "name"), other).reduce(
      (init, acc, i) => {
        acc?.title && init.push({ label: acc?.title, value: acc?.id, key: i, seller_type: acc?.seller_type });
        return init;
      },
      [{ value: "", label: "Select a Platform" }],
    );
  };

  const getSupplierValue = () => {
    if (["label", "value"].every((key) => platformId[key])) return platformId;
    const marketplace = checkMarketPlaces(platformsList, url);
    let value = initialSupplier;
    if (marketplace && platformId) {
      value = { label: marketplace.title, value: marketplace.id };
    } else if (platformId && sellerSku) {
      formik.setFieldValue("platform_id", { label: name, value: platformId }, true);
    }
    return value;
  };

  return (
    <>
      <Modal size="xl" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            {props.type === "edit" ? "EDIT SUPPLIER" : "ADD SUPPLIER"}
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <RenderIf isTrue={supplierSuccess}>
            <Alert color="success">
              <i className="bx bx-info-circle pr-2"></i>
              {supplierSuccess}
            </Alert>
          </RenderIf>
          <RenderIf isTrue={supplierError}>
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {"Supplier: " + supplierError}
            </Alert>
          </RenderIf>
          <RenderIf isTrue={listingSuccess}>
            <Alert color="success">
              <i className="bx bx-info-circle pr-2"></i>
              {listingSuccess}
            </Alert>
          </RenderIf>
          <RenderIf isTrue={listingError}>
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {"Listings: " + listingError}
            </Alert>
          </RenderIf>
          <RenderIf isTrue={errorSupplier}>
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              We do not support this supplier yet so you will need to monitor it manually for now
            </Alert>
          </RenderIf>
          <div className="m-2">
            <CardTitle className="text-primary">{props.sourceItem?.[0]?.name}</CardTitle>
          </div>
          <div className="d-flex justify-content-between m-2">
            <div className="d-flex flex-column">
              <span>SKU</span>
              <h6>{props.sourceItem?.[0]?.sku}</h6>
            </div>
            <div className="d-flex flex-column">
              <span>Total</span>
              <h6>
                $
                {(props.sourceItem?.[0]?.price + props.sourceItem?.[0]?.shipping + props.sourceItem?.[0]?.tax)?.toFixed(
                  2,
                )}
              </h6>
            </div>
            <div className="d-flex flex-column">
              <span>Price</span>
              <h6>${props.sourceItem?.[0]?.price}</h6>
            </div>
            <div className="d-flex flex-column">
              <span>Shipping</span>
              <h6>${props.sourceItem?.[0]?.shipping}</h6>
            </div>
          </div>

          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <RenderIf
                isTrue={notWholesaleCheck}
                fallback={
                  <Col lg="6 mb-2">
                    <Label>{last?.(platformGroup[platformId])?.name === "topdawg" ? "Topdawg Id" : "Seller SKU"}</Label>
                    <Input
                      className="form-control"
                      name="seller_sku"
                      type="text"
                      value={sellerSku || ""}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        if (
                          platformsList?.find((x) => x.id === parseInt(platformId?.value)).is_warehouse &&
                          sellerSku &&
                          (!whItemData?.inventory || whItemData?.inventory?.sku !== sellerSku)
                        ) {
                          dispatch(fetchInventoryItemInfo(sellerSku));
                        }
                      }}
                      onChange={formik.handleChange}
                    />
                    {sellerSkuTouched && sellerSkuError ? (
                      <span className="text-danger mt-1">{sellerSkuError}</span>
                    ) : null}
                  </Col>
                }
              >
                <Col lg="6 mb-2">
                  <Label>Supplier Url</Label>
                  <Input
                    className="form-control"
                    name="url"
                    type="text"
                    value={url}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      let { value } = e.target;
                      if (value !== "") {
                        value = checkURL(value);
                        formik.setFieldValue("url", value);
                        const marketplace = checkMarketPlaces(platformsList, value);
                        if (marketplace) formik.setFieldValue("name", marketplace);
                      }
                    }}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value) {
                        const marketplace = checkMarketPlaces(platformsList, value);
                        if (marketplace) formik.setFieldValue("name", marketplace);
                        marketplace
                          ? formik.setFieldValue(
                            "platform_id",
                            { label: marketplace.title, value: marketplace.id },
                            true,
                          )
                          : formik.setFieldValue("platform_id", initialSupplier, true);
                        formik.setFieldValue("url", value, true);
                      }
                    }}
                  />
                  {urlTouched && urlError ? <span className="text-danger mt-1">{urlError}</span> : null}
                </Col>
              </RenderIf>
              <Col lg="3">
                <Label>Select Supplier</Label>
                <FormGroup className="select2-container w-100 mb-0" style={{ minWidth: "170px" }}>
                  <Select
                    name="platform_id"
                    value={getSupplierValue()}
                    onChange={(e) => {
                      formik.setFieldValue("platform_id", e);
                      const isWHSelected = find(platformsList || [], (x) => x.id === parseInt(e?.value))?.is_warehouse;
                      if (isWHSelected) formik.setFieldValue("seller_sku", "");
                      formik.setFieldValue("name", e?.label);
                    }}
                    options={supplierDataOpts()}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                {platformIdTouched && platformIdError ? (
                  <span className="text-danger mt-1">{platformIdError}</span>
                ) : null}
              </Col>
              <Col lg="3">
                <div className="d-flex">
                  <Label>Override Price</Label>
                  <CheckBox
                    isSwitch={true}
                    name="price_lock"
                    className="ml-2"
                    state={!!priceLock}
                    setState={() => formik.setFieldValue("price_lock", !priceLock)}
                  />
                </div>
                <Input
                  name="manual_price"
                  type="number"
                  min="0"
                  step="0.01"
                  disabled={!priceLock}
                  value={manualPrice}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {manualPriceTouched && manualPriceError ? (
                  <small className="text-danger mt-1 position-absolute">{manualPriceError}</small>
                ) : null}
              </Col>
              <Col lg="3">
                <div className="d-flex">
                  <Label>Override Shipping</Label>
                  <CheckBox
                    isSwitch={true}
                    name="shipping_fee_lock"
                    className="ml-2"
                    state={!!shippingFeeLock}
                    setState={() => formik.setFieldValue("shipping_fee_lock", !shippingFeeLock)}
                  />
                </div>
                <Input
                  className="form-control"
                  name="manual_shipping_fee"
                  type="number"
                  min="0"
                  step="0.01"
                  value={manualShippingFee}
                  disabled={!shippingFeeLock}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {manualShippingFeeTouched && manualShippingFeeError ? (
                  <small className="text-danger mt-1 position-absolute">{manualShippingFeeError}</small>
                ) : null}
              </Col>
              <Col lg="3">
                <Label>Quantity Multiplier</Label>
                <Input
                  name="quantity_multiplier"
                  type="number"
                  min="1"
                  value={quantityMultiplier}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {quantityMultiplierTouched && quantityMultiplierError ? (
                  <span className="text-danger mt-1">{quantityMultiplierError}</span>
                ) : null}
              </Col>
              <Col lg="3">
                <div className="d-flex min-height-60 mt-1  align-items-start  grid-row-gap-9 flex-column">
                  <div className="d-flex align-items-start">
                    <Label>Variation</Label>
                    <i
                      title="Edit"
                      className="ml-2 mt-0 bx bx-xs bx-edit text-primary cursor-pointer"
                      onClick={() => setVariationModalOpen(!variationModalOpen)}
                    />
                  </div>
                  <span className="color-white variation-ellipsis">{getVariation(nestedVariation) || "N/A"}</span>
                </div>
              </Col>
              <Col lg="3" className="d-flex align-items-center mt-3">
                <CheckBox
                  key="is_default_col"
                  name="is_default"
                  label="Is Default Supplier"
                  state={!!isDefault}
                  isSwitch={true}
                  setState={(e) => formik.setFieldValue("is_default", e)}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button disabled={supplierError || listingError} type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
      <NestedVariationModal
        setNestedVariation={setNestedVariation}
        nestedVariation={nestedVariation}
        variationModalOpen={variationModalOpen}
        setVariationModalOpen={setVariationModalOpen}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders, Supplier, Listings } = state;
  return { SourceOrders, Supplier, Listings };
};

export default connect(mapStateToProps, {
  fetchSuppliers,
  createSupplier,
  fetchPlatforms,
  resetSupplierState,
  updateSupplier,
  fetchInventoryItemInfo,
  resetListingState,
})(SupplierModal);
