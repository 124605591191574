import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";
import {
  SOURCE_ORDER_STATUSES,
  BULK_SOURCE_ORDER_STATUSES,
  ORDER_STATUS_MAPPING,
  ERR_PREFIXES,
  DEV_IDS,
} from "../../../constants/index";
// actions
import { updateSourceOrder, bulkUpdateOrders, fetchSourceOrders } from "../../../store/actions";
import CheckBox from "../../../components/Common/CheckBox";
import { toPascalCase, getUserInfo, isManualTeam } from "../../../utils/common";
import { startCase, isEqual, merge, concat } from "lodash";
const inQueueCols = ["rating", "reviews", "shipping", "blacklist"];
const stateData = {
  ...inQueueCols.reduce((obj, col) => ({ ...obj, [col]: false }), {}),
  seller_id: "",
  loss_margin: 0,
};

const OrderStatusUpdate = (props) => {
  const filteredStatuses = concat(
    ["checking", "shipped_with_tba", "delivered_with_tba", "processing"],
    !DEV_IDS.includes(getUserInfo()?.id) && ["pending_cancellation", "in_queue"],
  );
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);
  const inQueueCols = ["rating", "reviews", "shipping", "blacklist"];
  const [aoExceptionObj, setAoExceptionObj] = useState(stateData);

  const validate = (values) => {
    const errors = {};
    !values.status && (errors.status = "Field is Required");
    if (values.status === SOURCE_ORDER_STATUSES.errored) {
      values.error_prefix === "" && (errors.error_prefix = "Field is Required");
      if (values.error_prefix === "Other" && !values.error_description) {
        errors.error_description = "Error description requierd";
      }
    }
    if (aoExceptionObj.seller_id) {
      const { seller_id: sellerId } = aoExceptionObj;
      if (!/^[A-Z0-9]+$/.test(sellerId)) {
        errors.seller_id = "Seller Id should only contains AlphaNumeric UpperCase Characters";
      }
      if (!errors.seller_id && (sellerId.length < 10 || sellerId.length > 21)) {
        errors.seller_id = "Seller Id must be between 10 to 22 characters";
      }
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      status: "",
      error_prefix: "",
      error_description: "",
      seller_id: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const allValues = { ...values };
      delete allValues.seller_id;
      if (props.type === "single") {
        const data = isEqual(aoExceptionObj, stateData)
          ? allValues
          : merge({ ao_order_exception: aoExceptionObj }, allValues);
        props.updateSourceOrder({ id: props.sourceOrder.id, data });
        formik.resetForm();
        toggleModal();
      } else {
        props.bulkUpdateOrders({
          items: props.SourceOrders?.selectedOrders,
          status: allValues.status,
        });
      }
    },
  });

  useEffect(() => {
    props.sourceOrder && formik.setFieldValue("status", props.sourceOrder.status || "", false);
  }, []);

  useEffect(() => {
    if (props.SourceOrders.success && !props.SourceOrders.loading) {
      toggleModal();
      props.refresh && props.refresh();
    }
  }, [props.SourceOrders.success]);

  return (
    <React.Fragment>
      <Modal size="sm" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Order Status</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders?.error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12">
                <Label>Status</Label>
                <div className="form-group">
                  <select
                    name="status"
                    value={formik.values.status}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="custom-select"
                  >
                    <option value="">Select Status</option>
                    {props.type === "single"
                      ? Object.keys(SOURCE_ORDER_STATUSES)
                        .filter((x) => !filteredStatuses.includes(x))
                        .map((key, index) => (
                            <option value={SOURCE_ORDER_STATUSES[key]} key={"_source_order_status" + index}>
                              {toPascalCase(
                                ORDER_STATUS_MAPPING[SOURCE_ORDER_STATUSES[key]] || SOURCE_ORDER_STATUSES[key],
                              )}
                            </option>
                        ))
                      : Object.keys(BULK_SOURCE_ORDER_STATUSES).map(
                        (key, index) =>
                          ((key === "new" && DEV_IDS.includes(getUserInfo()?.id)) || key !== "new") && (
                              <option value={BULK_SOURCE_ORDER_STATUSES[key]} key={"_bulk_source_order_status" + index}>
                                {toPascalCase(BULK_SOURCE_ORDER_STATUSES[key])}
                              </option>
                          ),
                      )}
                  </select>
                </div>
                {formik.touched.status && formik.errors.status
                  ? (
                  <span className="text-danger">{formik.errors.status}</span>
                    )
                  : null}
              </Col>
              {formik.values.status === SOURCE_ORDER_STATUSES.errored && (
                <>
                  <Col lg="12 mb-2">
                    <Label>Reason</Label>
                    <select
                      name="error_prefix"
                      value={formik.values.error_prefix}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className="custom-select"
                    >
                      <option value="">Select Status</option>
                      {ERR_PREFIXES.map((key, index) => (
                        <option value={key} key={"error_prefix_" + index}>
                          {key}
                        </option>
                      ))}
                    </select>
                    {formik.touched.error_prefix && formik.errors.error_prefix
                      ? (
                      <small className="text-danger mt-1">{formik.errors.error_prefix}</small>
                        )
                      : null}
                  </Col>
                  <Col lg="12 mb-2">
                    <Label>Description</Label>
                    <textarea
                      className="form-control"
                      rows=""
                      name="error_description"
                      value={formik.values.error_description}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.error_description && formik.errors.error_description
                      ? (
                      <small className="text-danger mt-1">{formik.errors.error_description}</small>
                        )
                      : null}
                  </Col>
                </>
              )}
              {props.type === "single" && isManualTeam() && formik.values.status === SOURCE_ORDER_STATUSES.in_queue
                ? (
                <>
                  {inQueueCols.map((col, key) => (
                    <CheckBox
                      className="ml-3"
                      key={key + "_key"}
                      name={col}
                      label={`Ignore ${startCase(col)}`}
                      state={aoExceptionObj[col]}
                      isSwitch={true}
                      setState={(e) => setAoExceptionObj({ ...aoExceptionObj, [col]: e })}
                    />
                  ))}
                  <Col lg="12 mt-2 ml-2">
                    <Label>Loss Margin</Label>
                    <input
                      type="number"
                      className="form-control"
                      step="0.01"
                      max={9}
                      min={0}
                      value={+aoExceptionObj.loss_margin}
                      maxLength={1}
                      placeholder="Enter $"
                      onChange={(e) => setAoExceptionObj({ ...aoExceptionObj, loss_margin: +e.target.value || 0 })}
                    />
                  </Col>
                  <Col lg="12 mt-2 ml-2">
                    <Label>Only Seller</Label>
                    <input
                      type="text"
                      className="form-control"
                      value={aoExceptionObj.seller_id}
                      placeholder="Enter Amazon Seller Id"
                      onChange={(e) => {
                        formik.setFieldValue("seller_id", e.target.value || "");
                        setAoExceptionObj({ ...aoExceptionObj, seller_id: e.target.value || "" });
                      }}
                    />
                    {formik.errors.seller_id
                      ? (
                      <small className="text-danger mt-1">{formik.errors.seller_id}</small>
                        )
                      : null}
                  </Col>
                </>
                  )
                : null}
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders } = state;
  return { SourceOrders };
};

export default connect(mapStateToProps, {
  updateSourceOrder,
  bulkUpdateOrders,
  fetchSourceOrders,
})(OrderStatusUpdate);
