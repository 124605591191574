import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useFormik } from "formik";
import { RETURNED_ITEM_ISSUES } from "../../constants/index";
import Modal from "./Modal";
import SimpleSelect from "../../components/Common/SimpleSelect";

// actions
import { updateReturnOutbounds } from "../../store/actions";
import { startCase } from "lodash";

const MarkIssueModal = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const toggleModal = () => setOpen(!isOpen);
  const toggleConfirmModal = () => setIsConfirmOpen(!isConfirmOpen);

  const markAsResoved = () => {
    props.updateReturnOutbounds({
      id: props.outboundId,
      data: {
        issue_reason: null,
      },
      filters: props.filters,
    });
    toggleConfirmModal();
  };

  const validate = (values) => {
    const errors = {};
    !values.issue_reason && (errors.issue_reason = "Field is Required");
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      issue_reason: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.updateReturnOutbounds({
        id: props.outboundId,
        data: { issue_reason: values.issue_reason.value },
        filters: props.filters,
      });
      toggleModal();
    },
  });

  useEffect(() => {
    if ((isOpen || isConfirmOpen) && props.SourceOrders.success && !props.SourceOrders.loading) {
      props.refresh();
      setOpen(false);
      setIsConfirmOpen(false);
      formik.resetForm();
    }
  }, [props.SourceOrders.success]);

  return (
    <React.Fragment>
      {props.issue
        ? (
        <>
          <i
            title="Mark as Resolved"
            className="bx bx-sm bx-info-square text-warning cursor-pointer"
            onClick={() => setIsConfirmOpen(true)}
          ></i>
          {isConfirmOpen && (
            <SweetAlert
              style={{ backgroundColor: "#363e54", color: "whitesmoke" }}
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={markAsResoved}
              onCancel={() => setIsConfirmOpen(false)}
            >
              <p>To Unmark Issue</p>
            </SweetAlert>
          )}
        </>
          )
        : (
        <>
          <i title="Mark as Issue" className="bx bx-sm bx-info-square cursor-pointer" onClick={toggleModal}></i>
          <Modal size="md" isOpen={isOpen} toggle={toggleModal}>
            <div className="modal-header">
              <h5 className="modal-title">Mark As Issue</h5>
              <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
              </button>
            </div>
            <div className="modal-body">
              {props.SourceOrders?.error && (
                <Alert color="danger">
                  <i className="bx bx-info-circle pr-2"></i>
                  {props.SourceOrders?.error}
                </Alert>
              )}
              <form className="m-2" onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg="12">
                    <Label>Select a Reason</Label>
                    <div className="form-group">
                    <div className="form-group">
                      <SimpleSelect
                        value={formik.values.issue_reason}
                        onChange={(val) => formik.setFieldValue("issue_reason", val)}
                        name="issue_reason"
                        onBlur={formik.handleBlur}
                        options={Object.values(RETURNED_ITEM_ISSUES).map((opt, key) => ({
                          label: startCase(opt),
                          value: opt,
                        }))}
                      />
                    </div>
                    </div>
                    {formik.touched.issue_reason && formik.errors.issue_reason
                      ? (
                      <span className="text-danger mt-1">{formik.errors.issue_reason}</span>
                        )
                      : null}
                  </Col>
                </Row>
                <Row className="d-flex justify-content-end mt-3">
                  <Button
                    type="reset"
                    color="secondary"
                    className="mr-3"
                    onClick={() => {
                      formik.handleReset();
                      toggleModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="success" className="mr-3">
                    Submit
                  </Button>
                </Row>
              </form>
            </div>
            <div className="modal-footer"></div>
          </Modal>
        </>
          )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders } = state;
  return { SourceOrders };
};

export default connect(mapStateToProps, {
  updateReturnOutbounds,
})(MarkIssueModal);
