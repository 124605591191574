import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, CardBody, Row, Col, Nav, NavItem, NavLink, Button, Collapse, Card } from "reactstrap";
import { fetchAllAOStats, setPreloader } from "../../store/actions";
import CustomPagination from "../../components/Common/CustomPagination";
import { keys, startCase, orderBy } from "lodash";
import classnames from "classnames";
import AOStats from "./AoChart";
import LastFourCards from "./components/LastFourCards";
import moment from "moment-timezone";

const AOLogs = () => {
  const { loading, aoStats } = useSelector((state) => state.AOStats);

  const [filters, setFilters] = useState({ search: "", page: 1, per_page: 20 });
  const [selectedReport, setSelectedReport] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    key: "",
    id: "",
  });

  const dispatch = useDispatch();
  const reports = [
    "Top AO Credits",
    "Average AO Order Time",
    "Average AO Attempts",
    "AO Failure",
    "Accounts Failure Reasons",
    "Most Used Cards",
    "AO Usage Graph",
  ];

  const params = {
    0: "credit_count",
    1: "order_avg_time",
    2: "avg_attempt_to_place_order",
    3: "ao_failure",
    4: "ao_account_fail_reason",
    5: "most_used_cards",
    6: "credit_count",
  };
  const convertTime = (secs) =>
    ["hours", "minutes", "seconds"]
      .reduce((arr, x) => {
        const time = moment.duration(secs, "seconds")[x]();
        if (time) arr.push(`${time} ${x}`);
        return arr;
      }, [])
      .join(" : ");

  useEffect(() => {
    dispatch(fetchAllAOStats({ ...filters, type: params[selectedReport] }));
  }, [selectedReport, filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between m-2">
          <Breadcrumbs title="AO Stats" />
        </div>
        <Row>
          <Col lg={12}>
            <Nav pills className="navtab-bg nav-justified">
              {reports.map((report, key) => (
                <NavItem key={"_col_" + key}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: key === selectedReport })}
                    onClick={() => {
                      setSelectedReport(key);
                      setFilters({
                        ...filters,
                        page: 1,
                        per_page: 20,
                      });
                    }}
                  >
                    {report}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
        <Card>
          <CardBody>
            {Array.isArray(aoStats?.data)
              ? (
              <>
                {selectedReport < 6
                  ? (
                  <div className="table-responsive">
                    <table className="table table-centered table-wrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {keys(aoStats.data[0]).map((header, index) => (
                            <th key={`table-header ${index}`}>{startCase(header).replace("Ao", "AO")}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {aoStats.data.map((item, key) => (
                          <tr key={"_top_" + key}>
                            {keys(item).map((keyName, _ind) => {
                              if (keyName === "most_used_cards") {
                                return (
                                  <td key={"_item_" + _ind}>
                                    <div>
                                      <Button
                                        color="primary"
                                        onClick={() => {
                                          const status = !(selectedAccount.key === key && isOpen === true);
                                          setSelectedAccount({
                                            ...selectedAccount,
                                            key,
                                          });
                                          setIsOpen(status);
                                        }}
                                      >
                                        View Last Four Cards
                                      </Button>
                                      {selectedAccount.key === key && isOpen && (
                                        <Collapse isOpen={isOpen}>
                                          <Card>
                                            <LastFourCards cards={item?.most_used_cards} />
                                          </Card>
                                        </Collapse>
                                      )}
                                    </div>
                                  </td>
                                );
                              } else if (keyName === "ao_account_fail_reason") {
                                return (
                                  <td key={"_item_" + _ind}>
                                    {orderBy(Object.entries(item[keyName]), ([, count]) => -count).map(
                                      ([prefix, count], _index) => (
                                        <div className="d-flex align-items-center" key={"_ao_item_" + _index}>
                                          <span>{prefix}</span>
                                          <i className="bx bx-sm bx-right-arrow-alt text-primary" />
                                          <span>{count.toLocaleString()}</span>
                                        </div>
                                      ),
                                    )}
                                  </td>
                                );
                              } else if (keyName === "ao_order_avg_time") {
                                return <td key={"_item_" + _ind}>{convertTime(item[keyName])}</td>;
                              } else
                                return (
                                  <td key={"_item_" + _ind}>
                                    <span>
                                      {typeof item[keyName] === "number"
                                        ? item[keyName].toLocaleString()
                                        : item[keyName]}
                                    </span>
                                  </td>
                                );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <hr></hr>
                  </div>
                    )
                  : (
                  <div>
                    <AOStats aoStats={aoStats?.data} />
                  </div>
                    )}
                <div>
                  <CustomPagination
                    total={aoStats?.total}
                    pageOptions={[20, 50, 100]}
                    page={+aoStats?.page}
                    perPage={+aoStats?.per_page}
                    tabsFilter={filters}
                    setTabFilter={(e) => setFilters(filters.per_page !== e.per_page ? { ...e, page: 1 } : e)}
                  />
                </div>
              </>
                )
              : null}
            {aoStats?.data?.length === 0 && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AOLogs;
