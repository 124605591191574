import React, { useState } from "react";
import Modal from "../../../components/Common/Modal";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { RenderIf } from "../../../utils/common";
import PillBtn from "../../../components/Common/PillBtn";
import CheckBox from "../../../components/Common/CheckBox";
import $ from "lodash";
import getBrandImageSrc from "../../../utils/brandImages";
import { Spinner } from "reactstrap";
import {
  getInitialStates,
  getMatchedIds,
  getSelectedFilteredAccounts,
  getSelectedUniqueKeys,
  platFormUniquekeys,
} from "./common";

const StrategyModal = ({ title, strategyId, platform, assignedAccounts }) => {
  const dispatch = useDispatch();
  const { strategyModalIsOpen, allRepricerAccounts } = useSelector((state) => state.Repricer);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const toggleStrategyModal = () => dispatch(actions.setStrategyModal(!strategyModalIsOpen));
  const isShowStoresInfo = title === "Stores Information";
  const assignedECAccounts = accounts?.data.filter((x) =>
    $.keys(assignedAccounts).includes(x?.[platFormUniquekeys?.[platform]?.ec]),
  );
  const assignedRepricerAccount = $.values(assignedAccounts);
  const initialState = getInitialStates(platform, accounts);
  const matchedIds = getMatchedIds(allRepricerAccounts, assignedRepricerAccount);
  const selectedUniqueKeys = getSelectedUniqueKeys(matchedIds, platform);
  const selectedFilteredAccounts = getSelectedFilteredAccounts(initialState, selectedUniqueKeys);
  const [selectedAccounts, setSelectedAccounts] = useState(selectedFilteredAccounts);
  const selectedAccountIds = $.keys(selectedAccounts)?.filter((x) => selectedAccounts[x].state && x);
  const selectedAccountsData = selectedAccountIds?.map((x) => selectedAccounts[x].uniqueId);
  const selectedAccountsKeys = $.reduce(
    allRepricerAccounts,
    (acc, x) => {
      if (selectedAccountsData.includes(x?.[platFormUniquekeys?.[platform]?.repricer])) acc.push(x.id);
      return acc;
    },
    [],
  );
  const errorCheck = $.isEmpty(selectedAccountsKeys);

  return (
    <Modal size="md" isOpen={strategyModalIsOpen}>
      <RenderIf isTrue={title}>
        <div className="modal-header card-text font-size-20 p-3 let-modal">
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className="font-weight-bold">{isShowStoresInfo ? "Stores" : title}</span>
            <i className="bx bx-sm bx-x inventory-color cursor-pointer" onClick={toggleStrategyModal} />
          </div>
        </div>
      </RenderIf>
      <RenderIf isTrue={title}>
        <RenderIf
          isTrue={!isShowStoresInfo}
          fallback={
            <div className="p-3 d-flex flex-wrap align-items-center" style={{ gap: "10px" }}>
              <RenderIf
                isTrue={!$.isEmpty(assignedECAccounts)}
                fallback={
                  <div
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{ minHeight: "100px" }}
                  >
                    <Spinner color="primary" style={{ width: "30px", height: "30px" }} />
                  </div>
                }
              >
                {assignedECAccounts?.map((x, ind) => (
                  <div
                    key={ind}
                    style={{
                      border: "1px solid $blue",
                      backgroundColor: "#556EE626",
                      borderRadius: "50px",
                      whiteSpace: "nowrap",
                    }}
                    className="inventory-color p-2 pr-5 align-items-center position-relative"
                  >
                    <RenderIf isTrue={platform}>
                      <img className="mx-1" src={getBrandImageSrc(platform)} alt={platform} style={{ width: "22px" }} />
                    </RenderIf>
                    {x.name}
                    <i
                      style={{
                        fontSize: "18px",
                        borderRadius: "50px",
                        right: "10px",
                        top: "10px",
                        color: "#f46a6a",
                      }}
                      className="bx bx-x-circle cursor-pointer position-absolute"
                      onClick={() => {
                        const idToRemove = (allRepricerAccounts || [])?.find(
                          (account) =>
                            account?.[platFormUniquekeys?.[platform]?.repricer] ===
                            x?.[platFormUniquekeys?.[platform]?.ec],
                        )?.id;
                        dispatch(actions.removeStrategyAccount(platform, idToRemove));
                        assignedECAccounts.length === 2 && toggleStrategyModal();
                      }}
                    />
                  </div>
                ))}
              </RenderIf>
            </div>
          }
        >
          <div className="modal-body p-4 let-modal">
            <RenderIf isTrue={errorCheck}>
              <div className="d-flex align-items-center text-primary font-size-13 mb-2">
                <i className="bx bx-sm bx-info-circle mx-1" />
                Note: Select at least one account to assign strategy.
              </div>
            </RenderIf>
            <div className="d-flex align-items-center inventory-color font-weight-bold font-size-13">
              Select Accounts:
            </div>
            {$.keys(selectedAccounts)?.map((acc, key) => {
              return (
                <div className="mb-2" key={`__selected-account___${key}`}>
                  <CheckBox
                    name={`__account-${acc}__${key}`}
                    state={selectedAccounts[acc].state || false}
                    setState={(state) => {
                      selectedAccounts[acc].state = state;
                      setSelectedAccounts({ ...selectedAccounts });
                    }}
                    className="m-2"
                    label={
                      <div className="font-size-12px font-weight-medium">
                        <img
                          src={getBrandImageSrc(selectedAccounts[acc].marketplace)}
                          alt={selectedAccounts[acc].marketplace}
                          style={{ width: "22px" }}
                        />
                        {`${selectedAccounts[acc].name}`}
                      </div>
                    }
                  />
                </div>
              );
            })}
          </div>
        </RenderIf>
      </RenderIf>
      <div className="modal-footer p-3 let-modal">
        <RenderIf
          isTrue={!isShowStoresInfo}
          fallback={
            <PillBtn className="mr-2" title="Close" name="Close" color="primary" onClick={toggleStrategyModal} />
          }
        >
          <PillBtn
            className="mr-2"
            title="Cancel"
            name="Cancel"
            color="primary"
            outline={true}
            onClick={toggleStrategyModal}
          />
          <div type="submit">
            <PillBtn
              disabled={errorCheck}
              className={`${errorCheck ? "cursor-not-allowed" : ""} ml-2`}
              title="Assign"
              name="Assign"
              color="primary"
              onClick={() => {
                dispatch(
                  actions.assignStrategy(platform, {
                    strategy_id: strategyId,
                    accounts: selectedAccountsKeys,
                  }),
                );
                toggleStrategyModal();
              }}
            />
          </div>
        </RenderIf>
      </div>
    </Modal>
  );
};

export default StrategyModal;
