import { startCase } from "lodash";
import {
  FETCH_PLAN_GROUPS,
  FETCH_PLAN_GROUPS_SUCCESS,
  CREATE_PLAN,
  CREATE_PLAN_SUCCESS,
  CREATE_PLAN_GROUP,
  CREATE_PLAN_GROUP_SUCCESS,
  SET_ADD_PLAN_MODAL,
  SET_PLAN_GROUP_MODAL,
  SET_VIEW_PLANS_MODAL,
  API_ERROR,
  UPDATE_PLAN_GROUP,
  UPDATE_PLAN_GROUP_SUCCESS,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  UPDATE_GROUP_AND_PLAN,
  UPDATE_GROUP_AND_PLAN_SUCCESS,
  SET_EDIT_PLAN_GROUP_MODAL,
  SET_EDITABLE_PLAN,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
};
const resetState = (state, loading = true) => ({
  ...state,
  loading,
  error: "",
  success: "",
  newPlanMessage: "",
  updatePlanMessage: "",
});

const CustomPlans = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAN_GROUPS:
      state = resetState(state);
      break;
    case FETCH_PLAN_GROUPS_SUCCESS:
      ["newGroup", "newPlan", "editGroup", "deletedPlan", "deletedGroup"].forEach((key) => delete state[key]);
      state = { ...resetState(state, false), groups: action.payload.data };
      break;
    case CREATE_PLAN:
      state = resetState(state);
      break;
    case CREATE_PLAN_SUCCESS:
      state = {
        ...resetState(state, false),
        newPlan: action.payload.plan,
        addPlanModal: false,
        newPlanMessage: "Plan added successfully",
      };
      break;
    case UPDATE_PLAN:
      state = resetState(state);
      break;
    case UPDATE_PLAN_SUCCESS:
      state = {
        ...resetState(state, false),
        groups: action.payload.data,
        addPlanModal: false,
        viewPlansModal: false,
        updatePlanMessage: "Plan updated successfully",
      };
      break;
    case CREATE_PLAN_GROUP:
      state = resetState(state);
      break;
    case CREATE_PLAN_GROUP_SUCCESS:
      state = {
        ...resetState(state, false),
        newGroup: action.payload.group,
        isPlanGroupModal: false,
        newGroupMessage: "Group added successfully",
      };
      break;
    case SET_EDIT_PLAN_GROUP_MODAL:
      state = { ...resetState(state, false), updateGroupMessage: "", isPlanGroupModalOpen: action.payload };
      break;
    case SET_EDITABLE_PLAN:
      state = { ...resetState(state, false), editablePlan: action.payload };
      break;
    case UPDATE_PLAN_GROUP:
      state = resetState(state);
      break;
    case UPDATE_PLAN_GROUP_SUCCESS:
      state = {
        ...resetState(state, false),
        editGroup: action.payload.group,
        isPlanGroupModal: false,
        updateGroupMessage: "Group updated successfully",
      };
      break;
    case UPDATE_GROUP_AND_PLAN:
      state = resetState(state);
      break;
    case UPDATE_GROUP_AND_PLAN_SUCCESS:
      state = {
        ...resetState(state, false),
        editGroup: action.payload.group,
        isPlanGroupModalOpen: false,
        updateGroupMessage: action.payload.message,
      };
      break;
    case DELETE_PLAN:
      state = resetState(state);
      break;
    case DELETE_PLAN_SUCCESS:
      state = {
        ...resetState(state, false),
        viewPlansModal: false,
        deletedPlan: action.payload.plan,
        deletedPlanMessage: "Plan removed successfully",
      };
      break;
    case DELETE_GROUP:
      state = resetState(state);
      break;
    case DELETE_GROUP_SUCCESS:
      state = {
        ...resetState(state, false),
        deletedGroup: action.payload.group,
        deletedGroupMessage: "Group removed successfully",
      };
      break;
    case SET_ADD_PLAN_MODAL:
      state = { ...resetState(state, false), addPlanModal: action.payload };
      break;
    case SET_PLAN_GROUP_MODAL:
      state = { ...resetState(state, false), isPlanGroupModal: action.payload };
      break;
    case SET_VIEW_PLANS_MODAL:
      state = { ...resetState(state, false), viewPlansModal: action.payload };
      break;
    case API_ERROR:
      state = { ...state, error: getErrorMsg(action.payload), loading: false };
      break;
    default:
      break;
  }
  return state;
};

const getErrorMsg = (error) => {
  if (!error.includes("data.")) return error;
  const newError = error.match(/(data.)\w+/g);
  return startCase(newError[0].split(".")[1]) + error.split(newError)[1].replace(/\W+|_/g, " ");
};

export default CustomPlans;
