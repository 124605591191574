import React from "react";
import { useParams } from "react-router-dom";
import { SOURCE_ORDER_STATUSES, MARKET_PLACES, MARKETPLACE_STATUSES, MARK_ORDER_BUTTON } from "../../../constants";
import { ConfirmDialogBtn } from "../../../components/Common/ConfirmDialog";
import PillBtn from "../../../components/Common/PillBtn";
import { Button } from "reactstrap";
import { isManualTeam, getUserInfo, RenderIf, checkoutErrorManualFulfillment, toUpper } from "../../../utils/common";
import { acknowledgeOrder, changeOrderType, manualFulfillmentCollapseIsOpen } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { pick, values } from "lodash";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import moment from "moment";
import UploadLabelModel from "./UploadLabelModal";

function ActionButtons (props) {
  const isMarkBtnAccess = MARK_ORDER_BUTTON.includes(getUserInfo()?.user_email);
  const { id } = useParams();
  const { isFullAccess, show } = props;
  const isTwoStep = props.Settings.account?.data?.two_step_feature;
  const sourceOrder = props?.SourceOrders?.sourceOrderDetail?.data;
  const dispatch = useDispatch();
  if (!sourceOrder) return <div className="mb-5"></div>;

  const handleAcknowledgeOrder = () => dispatch(acknowledgeOrder({ id }));

  const markAsResoved = () =>
    props.updateSourceOrder({
      id: sourceOrder?.id,
      data: { issue_reason: null, issue_note: null },
    });
  function isManualEligible (props) {
    const sourceOrder = props?.SourceOrders?.sourceOrderDetail?.data;
    const supplierOrders = sourceOrder?.supplier_orders?.filter((o) => o.status !== "ignored");
    const { status, marketplace_status: storeStatus } = sourceOrder;
    if (!status || ["in_queue"].includes(status)) return false;
    const forbiddenStatuses = [
      "processing",
      "in_queue",
      "checking",
      "delayed",
      "shipped_with_tba",
      "delivered_with_tba",
      "manual_fulfillment",
      "processed",
      "completed",
      "shipped",
      "cancelled",
      SOURCE_ORDER_STATUSES.wh_shipped,
      SOURCE_ORDER_STATUSES.wh_delivered,
    ];
    const sourceQty = sourceOrder?.source_items?.reduce((sum, item) => sum + item.qty, 0);
    const supQty = supplierOrders?.reduce((sum, order) => sum + order.qty, 0);
    return (
      !props.isStoreFulfilled(props) &&
      !props.isIFulfil(sourceOrder) &&
      ((!forbiddenStatuses.includes(status) && storeStatus?.toLowerCase() !== "cancelled" && !supplierOrders?.length) ||
        (!props.isStoreFulfilled(props) && sourceQty - supQty > 0))
    );
  }
  const isSameUser = sourceOrder?.order_logs.some(
    (x) =>
      (toUpper(getUserInfo()?.username).includes(x.created_by) && x.note.includes("manual_fulfillment")) ||
      getUserInfo()?.id === 27,
  );

  const changeStatus = (order, status) => props.updateSourceOrderStatus({ id: order?.id, data: { status } });
  const getManualFulfillmentEnablingTime = (attempts) =>
    `You can manually fulfill the order after ${moment(
      attempts.find((x) => x.error_prefix === "Checkout Error")?.ended_at,
    )
      .add(4, "hours")
      .format("YYYY-MM-DD HH:mm:ss")}`;

  return (
    <div className="d-flex justify-content-end my-2">
      <div className="button-items d-flex flex-column flex-md-row align-items-center">
        {isFullAccess &&
          show &&
          sourceOrder?.feed_data?.id &&
          sourceOrder?.status === SOURCE_ORDER_STATUSES.pending_cancellation && (
            <i
              title="Refresh Feed Status"
              className="bx bx-sm bx-refresh px-2 cursor-pointer"
              onClick={() => props.getFeedStatus(sourceOrder?.id)}
            ></i>
        )}
        {show &&
          !props.isStoreFulfilled(props) &&
          !props.isIFulfil(sourceOrder) &&
          [MARKET_PLACES.walmart, MARKET_PLACES.amazon].includes(sourceOrder?.store_name) &&
          !values(pick(MARKETPLACE_STATUSES, ["Cancelled", "Canceled"])).includes(sourceOrder?.marketplace_status) &&
          sourceOrder?.status !== SOURCE_ORDER_STATUSES.pending_cancellation && (
            <PillBtn
              color="danger"
              title="Cancel Order"
              name="Cancel Order"
              icon="bx bx-xs bx-x-circle"
              className="ml-1"
              onClick={() => props.setCancelMarketPlaceOrderModal(true)}
            />
        )}
        {sourceOrder.store_name === MARKET_PLACES.walmart &&
          sourceOrder.marketplace_status === MARKETPLACE_STATUSES.Created && (
            <ConfirmDialogBtn
              button={{
                color: "success",
                title: "Acknowledge Order",
                icon: "bx bx-xs bx-check",
                className: "ml-1",
              }}
              msg="You want to acknowledge this order?"
              onConfirm={handleAcknowledgeOrder}
            />
        )}
        <RenderIf isTrue={isFullAccess}>
          <>
            {(show || sourceOrder?.status === SOURCE_ORDER_STATUSES.processing) && sourceOrder?.issue_reason ? (
              <ConfirmDialogBtn
                button={{
                  color: "warning",
                  title: "Mark As Resolved",
                  className: "ml-1",
                }}
                msg="To unmark the issue."
                onConfirm={markAsResoved}
              />
            ) : null}
            {show && !sourceOrder?.issue_reason ? (
              <PillBtn
                title="Mark As Issue"
                name="Mark As Issue"
                color="warning"
                icon="bx bx-xs bx-info-circle"
                className="ml-1"
                onClick={() => props.setMarkIssueModal(true)}
              />
            ) : null}
            {[
              SOURCE_ORDER_STATUSES.delayed,
              SOURCE_ORDER_STATUSES.in_queue,
              SOURCE_ORDER_STATUSES.checking,
              SOURCE_ORDER_STATUSES.processing,
            ].includes(sourceOrder?.status) ? (
              <Button
                type="reset"
                color="danger"
                className="ml-1"
                onClick={() => changeStatus(sourceOrder, SOURCE_ORDER_STATUSES.errored)}
              >
                Release Order From AO Queue
              </Button>
                ) : null}
            {show &&
            !props.manualFulfillmentCollapse &&
            (isSameUser || sourceOrder?.status !== SOURCE_ORDER_STATUSES.manual_fulfillment) &&
            isManualEligible(props) ? (
              <>
                <RenderIf isTrue={checkoutErrorManualFulfillment(sourceOrder.order_attempts)}>
                  <CustomTooltip
                    placement="top"
                    content={getManualFulfillmentEnablingTime(sourceOrder.order_attempts)}
                    target={"manual_fulfulment"}
                  />
                </RenderIf>
                <PillBtn
                  id="manual_fulfulment"
                  title="Manual Fulfillment"
                  name={`${
                    sourceOrder?.status === SOURCE_ORDER_STATUSES.manual_fulfillment
                      ? "Process Order"
                      : "Manual Fulfillment"
                  }`}
                  color="primary"
                  icon="bx bxs-cart-add"
                  className={`ml-1  ${checkoutErrorManualFulfillment(sourceOrder.order_attempts) ? "disabled" : null}`}
                  onClick={
                    checkoutErrorManualFulfillment(sourceOrder.order_attempts)
                      ? null
                      : () => {
                          const { status } = sourceOrder;
                          ![
                            SOURCE_ORDER_STATUSES.manual_fulfillment,
                            SOURCE_ORDER_STATUSES.partially_processed,
                          ].includes(status) &&
                            props.updateSourceOrder({
                              id: props.SourceOrders.sourceOrderDetail.data?.id,
                              data: { status: SOURCE_ORDER_STATUSES.manual_fulfillment },
                            });
                          dispatch(manualFulfillmentCollapseIsOpen(!props.manualFulfillmentCollapse));
                        }
                  }
                />
              </>
                ) : null}
            <PillBtn
              title="Add Order Log"
              name="Add Manual Log"
              color="info"
              className="ml-1"
              icon="bx bx-xs bx-plus-circle"
              onClick={() => props.setOrderLogModal(true)}
            />
            {!props.isStoreFulfilled(props) && isTwoStep && (getUserInfo()?.role === "admin" || isMarkBtnAccess) && (
              <ConfirmDialogBtn
                button={{
                  icon: "bx bx-xs bx-",
                  color: "success",
                  title: `Mark As ${props.isWH() ? "Direct" : "Two Step"}`,
                }}
                msg={`Mark As ${props.isWH() ? "Direct" : "Two Step"}`}
                onConfirm={() => {
                  if (props.isWH()) props.changeOrderType({ type: "non_two_step" }, id);
                  else {
                    props.orderAction.current = {
                      action: changeOrderType,
                      args: { data: { type: "two_step" }, id: sourceOrder?.id },
                      order: sourceOrder,
                    };
                    props.setSelectWareHouseModal(true);
                  }
                  props.setActiveAddressTab(0);
                }}
              />
            )}
            {isManualTeam() ? (
              <i
                title="Refresh order details"
                className="mdi mdi-refresh ml-2 h1 cursor-pointer"
                onClick={() => props.fetchSourceOrderDetail(sourceOrder.id)}
              ></i>
            ) : null}
          </>
        </RenderIf>
      </div>
      {isFullAccess && props.isUploadLabelModelOpen && (
        <UploadLabelModel
          sourceOrder={props.sourceOrder}
          setIsOpen={props.setIsUploadLabelModelOpen}
          isOpen={props.isUploadLabelModelOpen}
        />
      )}
    </div>
  );
}

export default ActionButtons;
