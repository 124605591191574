import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllReturns,
  updateReturn,
  moveToInventory,
  uploadImages,
  deleteImage,
  getImagesUrl,
  updateBulkReturns,
  addItems,
} from "../../api";
// Login Redux States
import {
  DELETE_IMAGE,
  FETCH_RETURNS,
  MOVE_TO_INVENTORY,
  UPDATE_RETURN,
  UPLOAD_ISSUE_IMAGES,
  GET_IMAGES_URL,
  UPDATE_BULK_RETURNS,
  ADD_ITEM_TO_INVENTORY,
} from "./actionTypes";

import {
  addItemToInventorySuccess,
  apiError,
  deleteImageSuccess,
  fetchReturnsSuccess,
  getImagesUrlSuccess,
  moveToInventorySuccess,
  updateBulkReturnsSuccess,
  updateReturnSuccess,
  uploadIssueImagesSuccess,
} from "./actions";
import { merge } from "lodash";
import { setAddBinNoModal } from "../actions";
import { RETURN_INTERNAL_STATUSES } from "../../constants";

const { open, closed } = RETURN_INTERNAL_STATUSES;
const Returns = (state) => state.Returns;
const WareHouses = (state) => state.WareHouses;

function * fetchReturnsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllReturns, params);
    yield put(fetchReturnsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateReturnSaga ({ payload: { id, data, params } }) {
  try {
    const res = yield call(updateReturn, { id, data, params });
    if (res?.data) {
      const { returns } = yield select(Returns);
      const returnData = returns?.data?.find((x) => x.id === id);
      merge(returnData, res?.data);
      if (params?.internal_status === open && data?.internal_status === closed)
        yield call(fetchReturnsSaga, { payload: { params } });
    }
    yield put(updateReturnSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * moveToInventorySaga ({ payload: { id, data } }) {
  try {
    const res = yield call(moveToInventory, { id, data });
    if (res?.data) {
      const { returns } = yield select(Returns);
      const returnData = returns?.data?.find((x) => x.id === id);
      merge(returnData, res?.data);
      yield put(setAddBinNoModal(false));
    }
    yield put(moveToInventorySuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteImageSaga ({ payload: { id, params } }) {
  try {
    const res = yield call(deleteImage, { id, params });
    if (res?.success) {
      let { returns, imagesUrls } = yield select(Returns);
      const data = returns?.data?.find((x) => x.id === id);
      imagesUrls = imagesUrls.filter((x) => x.name !== params.file_name);
      merge(data, res.data);
      yield put(deleteImageSuccess({ res, isOpen: !!data.images_files?.length, imagesUrls }));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addItemToInventory ({ payload }) {
  try {
    const res = yield call(addItems, payload);
    const { warehouseInventory } = yield select(WareHouses);
    if (res?.success && warehouseInventory) {
      warehouseInventory.count += 1;
      warehouseInventory.data.unshift(res?.data);
      warehouseInventory.data.length = warehouseInventory.per_page;
    }
    yield put(addItemToInventorySuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * uploadImagesSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(uploadImages, { data, id });
    if (res?.success) {
      const { returns } = yield select(Returns);
      const data = returns?.data?.find((x) => x.id === id);
      merge(data, res.data);
      yield put(uploadIssueImagesSuccess(res.success));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateBulkReturnsSaga ({ payload: { data, ids, params } }) {
  try {
    const res = yield call(updateBulkReturns, { data, ids });
    if (res?.success) {
      yield call(fetchReturnsSaga, { payload: { params } });
      yield put(updateBulkReturnsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getImagesURLSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(getImagesUrl, { data, id });
    if (res?.success) {
      yield put(getImagesUrlSuccess(res?.data));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchAddItemToInventory () {
  yield takeEvery(ADD_ITEM_TO_INVENTORY, addItemToInventory);
}

export function * watchFetchReturns () {
  yield takeEvery(FETCH_RETURNS, fetchReturnsSaga);
}

export function * watchUpdateReturn () {
  yield takeEvery(UPDATE_RETURN, updateReturnSaga);
}

export function * watchMoveToInventory () {
  yield takeEvery(MOVE_TO_INVENTORY, moveToInventorySaga);
}

export function * watchUploadImages () {
  yield takeEvery(UPLOAD_ISSUE_IMAGES, uploadImagesSaga);
}

export function * watchDeleteImages () {
  yield takeEvery(DELETE_IMAGE, deleteImageSaga);
}

export function * watchGetImagesUrl () {
  yield takeEvery(GET_IMAGES_URL, getImagesURLSaga);
}

export function * watchUpdateBulkReturns () {
  yield takeEvery(UPDATE_BULK_RETURNS, updateBulkReturnsSaga);
}

function * returnsSaga () {
  yield all([
    fork(watchFetchReturns),
    fork(watchUpdateReturn),
    fork(watchMoveToInventory),
    fork(watchUploadImages),
    fork(watchDeleteImages),
    fork(watchGetImagesUrl),
    fork(watchUpdateBulkReturns),
    fork(watchAddItemToInventory),
  ]);
}

export default returnsSaga;
