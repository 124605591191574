import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row } from "reactstrap";
import { integrateWithRepricer, setRepricerIntegrationModal } from "../../../store/actions";
import getBrandImageSrc from "../../../utils/brandImages";
import { getUserInfo, toPascalCase } from "../../../utils/common";
import Modal from "../../../components/Common/Modal";

const RepricerIntergrationModal = () => {
  const dispatch = useDispatch();
  const { accounts, repricerIntegrationModalIsOpen } = useSelector((state) => state.MarketPlaceAccount);
  const [enabled, setEnabled] = useState();

  const toggleModal = () => dispatch(setRepricerIntegrationModal(!repricerIntegrationModalIsOpen));

  const marketplaceAccounts = accounts?.data;

  useEffect(() => {
    marketplaceAccounts &&
      setEnabled(
        marketplaceAccounts.reduce((acc, cumm) => {
          acc[cumm.name] = true;
          return acc;
        }, {}),
      );
  }, []);

  return (
    <Modal isOpen={repricerIntegrationModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          INTEGRATE ACCOUNTS WITH NEW EC REPRICER
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <div className="table-responsive overflow-auto" style={{ maxHeight: "400px" }}>
          <table className="table table-centered table-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                <th>Account Name</th>
                <th className="text-center">Enabled</th>
              </tr>
            </thead>
            <tbody>
              {marketplaceAccounts &&
                enabled &&
                marketplaceAccounts?.map((item, key) => (
                  <tr key={"_new_repricer_integration_" + key}>
                    <td>
                      <div className="left-0">
                        {getBrandImageSrc(item.marketplace) && (
                          <img title={item.name} src={getBrandImageSrc(item.marketplace)} alt="" />
                        )}
                        {toPascalCase(item.name)}
                      </div>
                    </td>
                    <td>
                      <div className="custom-control custom-switch custom-switch-lg mb-3 text-center">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={item.name}
                          checked={enabled[item?.name]}
                          onChange={() => null}
                        />
                        <label
                          onClick={() => setEnabled({ ...enabled, [item.name]: !enabled[item.name] })}
                          className="custom-control-label"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <Row className="d-flex justify-content-end mt-3">
          <Button
            type="reset"
            color="secondary"
            className="mr-3"
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="success"
            className="mr-3"
            onClick={() => {
              dispatch(integrateWithRepricer({ email: getUserInfo()?.email, marketplaces: enabled }));
              toggleModal();
            }}
          >
            Submit
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default RepricerIntergrationModal;
