import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
const AOStats = (props) => {
  const graphData = props?.aoStats?.reduce(
    (acc, curr) => {
      const [email, count] = [curr?.tenant_email, curr?.ao_credit_count];
      if (email) acc[0].push(email);
      if (count) acc[1].push(count);
      return acc;
    },
    [[], []],
  );

  const AvgAo = [
    {
      name: "averge Ao",
      data: graphData ? graphData[1] : [],
    },
  ];
  const users = {
    chart: { toolbar: { show: false } },
    plotOptions: { bar: { horizontal: false, columnWidth: "45%" } },
    dataLabels: { enabled: false },
    stroke: {
      show: true,
      width: 2,
      colors: ["#34c38f", "#f46a6a", "#556ee6"],
    },
    colors: ["#34c38f", "#f46a6a", "#556ee6"],
    xaxis: {
      labels: {
        rotate: -65,
        hideOverlappingLabels: false,
        maxHeight: 200,
        style: { fontSize: "10px" },
        rotateAlways: true,
      },
      categories: graphData ? graphData[0] : [],
    },
    grid: { borderColor: "#f1f1f1" },
    fill: { opacity: 1 },
  };

  return (
    <Card>
      <CardBody>
        <ReactApexChart options={users} series={AvgAo} type="bar" height="500" />
      </CardBody>
    </Card>
  );
};

export default AOStats;
