import React from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, Row, Col } from "reactstrap";

// redux actions
import { updateOnboardingSettings } from "../../../store/actions";
// import images
import logoLight from "../../../assets/svg/logo.svg";
import logoDark from "../../../assets/svg/logoDark.svg";

const Lister = (props) => {
  const dispatch = useDispatch();
  function handleForwarderSetup () {
    dispatch(updateOnboardingSettings({ lister_visited: true }));
    if ([undefined, false].includes(props.onBoarding)) props.setIsOpen(false);
  }
  const logo = localStorage.getItem("THEME") === "light" ? logoLight : logoDark;
  return (
    <React.Fragment>
      <Card className="mb-0">
        <Row className="px-4 pt-4">
          <Col>
            <div>
              <img src={logo} alt={"Ecom Circles"} height="31"></img>
              <h6 className="pt-3">Great!</h6>
              <h6>You have setup your account successfully. Now add your first product via following tools.</h6>
            </div>
            <ul className="p-3">
              <li>
                <Link to="/listings/new" onClick={handleForwarderSetup}>
                  I do not have any listings on a marketplace yet
                </Link>
              </li>
              <li>
                <Link to="/listings_csv" onClick={handleForwarderSetup}>
                  I have have listings to add in bulk
                </Link>
              </li>
              <li>
                <Link to="/listings_csv" onClick={handleForwarderSetup}>
                  I am migrating from a different software
                </Link>
              </li>
            </ul>
            <button
              onClick={() => {
                if ([undefined, false].includes(props.onBoarding)) props.setIsOpen(false);
              }}
              className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-primary"
            >
              Skip
            </button>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(Lister);

Lister.propTypes = {
  setActiveStep: PropTypes.func,
};
