/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap";
import { RenderIf } from "../../../utils/common";
import { useSelector } from "react-redux";
import RepricerFormulasModal from "../../Formulas/Components/RepricerFormulasModal";
import ellipseDots from "../../../assets/svg/ellipseDots.svg";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const CreateRepricerStrategiesForm = ({ title, setStrategyOperation, setOperation, formik, strategyId, setStrategyId, stepCounter, setStepCounter, selectedPlatform }) => {
  const [step1InitialData, setStep1InitialData] = useState(null);
  const [step2InitialData, setStep2InitialData] = useState(null);

  const { repricerFormulasModalIsOpen } = useSelector((state) => state.Repricer);
  return (
    <>
      <RenderIf isTrue={repricerFormulasModalIsOpen}>
        <RepricerFormulasModal title="Create Formula" buttonTitle="Create" selectedPlatform={selectedPlatform} />
      </RenderIf>
      <Breadcrumb
        title={
          <span className="d-flex align-items-center font-weight-bold font-size-16">
            <i
              className="text-primary bx bx-sm bx-left-arrow-alt cursor-pointer mr-2"
              onClick={() => {
                if (stepCounter === 1) {
                  setStrategyOperation(false);
                  formik.setValues({
                    strategyNameField: "",
                    matchingPriceType: "price_below",
                    matchingType: "ALL",
                    matchingTypeAmount: "",
                    beat_by_competition_when_gt_listed_price: "",
                    noCompetition: "",
                    competitionBelow: "",
                    competitionAbove: "",
                    matchCompetition: "",
                    haveBuyBox: "",
                    excludeSellerRating: "",
                    whenBuyBoxIsSuppressed: "",
                  });
                  setStep1InitialData({});
                  setStep2InitialData({});
                  setStrategyId(null);
                }
                if ([2, 3].includes(stepCounter)) {
                  setStepCounter(stepCounter - 1);
                }
              }}
            />
            {`${title} Strategies`}
          </span>
        }
        children={
          <div className="d-flex align-items-center text-warning font-size-15">
            <i className="bx bx-sm bx-info-circle mx-1" />
            Note: Enter all the fields to enable Save & Next button
          </div>
        }
      />
      <Card className="resources p-3">
        <CardBody>
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginBottom: "2.5rem", width: "70%" }}
              >
                <div style={{ fontSize: "40px" }} className="d-flex align-items-center justify-content-center mx-2">
                  <div className={`d-flex flex-column justify-content-center ${stepCounter ? "text-primary" : null}`}>
                    <i className="text-center bx bx-file" />
                    <span style={{ marginTop: "12px" }} className="text-center font-size-13">
                      Strategies
                    </span>
                  </div>
                </div>
                <img
                  className="mx-4 strategy-ellipse-dots"
                  src={ellipseDots}
                  alt="ellipseDots"
                  style={{ maxWidth: "100%", height: "auto", width: "auto" }}
                />
                <div style={{ fontSize: "40px" }} className="d-flex justify-content-center mx-2">
                  <div
                    className={`d-flex flex-column justify-content-center ${stepCounter >= 2 ? "text-primary" : null}`}
                  >
                    <i className="text-center bx bx-dollar" />
                    <span style={{ marginTop: "12px" }} className="text-center font-size-13">
                      Min or Max Price
                    </span>
                  </div>
                </div>
                <img
                  className="mx-4 strategy-ellipse-dots"
                  src={ellipseDots}
                  alt="ellipseDots"
                  style={{ maxWidth: "100%", height: "auto", width: "auto" }}
                />
                <div style={{ fontSize: "40px" }} className="d-flex justify-content-center mx-2">
                  <div
                    className={`d-flex flex-column justify-content-center ${stepCounter >= 3 ? "text-primary" : null}`}
                  >
                    <i className="text-center bx bx-task" />
                    <span style={{ marginTop: "12px" }} className="text-center font-size-13">
                      Review Strategy
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <RenderIf isTrue={stepCounter === 1}>
              <Step1
                stepCounter={1}
                step1InitialData={step1InitialData}
                setStep1InitialData={setStep1InitialData}
                setStepCounter={setStepCounter}
                formikValues={formik.values}
                selectedPlatform={selectedPlatform}
              />
            </RenderIf>
            <RenderIf isTrue={stepCounter === 2}>
              <Step2
                stepCounter={2}
                step2InitialData={step2InitialData}
                setStep2InitialData={setStep2InitialData}
                setStepCounter={setStepCounter}
                formikValues={formik.values}
              />
            </RenderIf>
            <RenderIf isTrue={stepCounter === 3}>
              <Step3
                stepCounter={3}
                step1InitialData={step1InitialData}
                setStep1InitialData={setStep1InitialData}
                setStep2InitialData={setStep2InitialData}
                step2InitialData={step2InitialData}
                setStepCounter={setStepCounter}
                strategyId={strategyId}
                setStrategyId={setStrategyId}
                setOperation={setOperation}
                setStrategyOperation={setStrategyOperation}
                formik={formik}
                selectedPlatform={selectedPlatform}
              />
            </RenderIf>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CreateRepricerStrategiesForm;
