import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import queryString from "query-string";
import papa from "papaparse";
import * as $ from "lodash";
import * as actions from "../store/actions";
import {
  ORDER_TRACKING_STATUSES,
  MARKET_PLACES,
  DEV_IDS,
  PASSWORD_REGEX,
  TRACKING_ITEM_FILTERS,
  MARKETPLACE_STATUSES,
  INVENTORY_PRODUCTS_STATUSES,
  NON_STORE_USERS,
  ONBOARD_FLAGS,
  onboardingStepsIds,
  skuChars,
  regions,
  ACCOUNT_STATUSES,
  ENV,
  AO_DEV_IDS,
  USER_ROLES,
  SOURCE_ORDER_STATUSES,
  DATE_FORMATS,
  MP_ACC_STATUSES,
  TWO_STEP_NON_SUPPORTED_STORES,
  GEN_REPORT_USER_IDS,
  SIGN_UP_SERVICES,
  TOKEN_REFRESHED_AT_KEY,
  TENANTS_ACTIONS_ACCESS,
  WAREHOUSE_ADMINS,
} from "../constants";
import moment from "moment-timezone";
const { omit, keys, omitBy, uniq, reduce, sumBy, intersection, merge, split, values, pick } = $;

const asinRegex = /dp\/(.{10})/;
const capitalize = (str) => {
  return !str ? str : `${str?.[0].toUpperCase()}${str?.slice(1).toLowerCase()}`;
};

const addStoreFront = (dispatch) => {
  dispatch(actions.setIsAmz({ isOpen: true }));
  dispatch(actions.setAddRepricerStore(true));
  dispatch(actions.setOnboardingFlow(false));
  dispatch(actions.setOnboardingStep(onboardingStepsIds.welcom));
  dispatch(actions.setShowOnboarding(true));
  removeURLQueryParams();
};

const hasFullAccessOfAnyPlan = () => {
  const accessSpecifiers = getUserInfo()?.access_specifiers;
  return $.isEmpty(accessSpecifiers) || $.some(accessSpecifiers, (specifier) => specifier?.access === "full");
};

const isUserHaveFullAccess = (key = "ecom") =>
  getUserInfo()?.access_specifiers?.[key] ? getUserInfo()?.access_specifiers?.[key]?.access === "full" : true;

const isProfitCalculationAllowed = () => isUserHaveFullAccess("repricer") || isUserHaveFullAccess("extension");

const isAppInServices = (services, queryParams) => $.keys(services).some((app) => isSignUpFromSubApp(queryParams, app));
export const isSignUpFromSubApp = (queryParams, appName) => queryParams?.signup_on === appName;
export const isSignUpFromSubApps = (queryParams) => isAppInServices(SIGN_UP_SERVICES, queryParams);
export const isSignUpFromExtension = (queryParams) =>
  isAppInServices($.pick(SIGN_UP_SERVICES, "extension", "wfs_calculator"), queryParams);

export const parseString = string => string.replace(/[^a-zA-Z0-9 _-]/g, "");

const amountText = (text, isLocaleNum) => {
  if (!text && text !== 0) return "N/A";

  if (text.constructor === String) {
    if (isNaN(+text.replace("$", ""))) return text;
    text = +text.replace("$", "");
  }
  return `${text < 0 ? "-" : ""}$${
    isLocaleNum ? localeNum(+humanize(Math.abs(text), toF)) : humanize(Math.abs(text), toF)
  }`;
};

const formatter = num => humanize(num.toFixed(2), parseFloat);

const formatNumber = (text, type = "currency") => {
  if (!text && text !== 0) return "N/A";
  const num = typeof text === "string" ? parseFloat(text) : text;
  return type === "currency" ? `$${formatter(num)}` : type === "float" ? `${formatter(num)}` : humanize(num);
};

const humanize = (str, func = parseInt) =>
  func(str)?.toLocaleString("en-US", merge(func.name === "parseFloat" && { minimumFractionDigits: 2 }));

const toF = (num, decimalPlaces = 2, isCast = false) => {
  const str = (num || 0).toFixed(decimalPlaces);
  return isCast ? parseFloat(str) : str;
};

export const isInviteUrl = _ => document.location.pathname.includes("/invite/");

const delaySettingForAnHour = () => localStorage.setItem("futureHour", moment().add(1, "hours"));

const matchString = (str, matchingString) => {
  return str.toLowerCase().replace(/-|_/g, "").includes(matchingString.replace(/-|_/g, ""));
};

const checkMarketPlaces = (platforms, url) => {
  if (!url) return;
  url = url.replace(/.+\/\/|www.|\..+/g, "");
  return platforms?.find((x) => new RegExp(x.name.replace(/_/g, ""), "i").test(url.replace(/-|_/g, ""))) || false;
};

const downloadFile = (allData, name) => {
  const csv = papa.unparse(allData);
  const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  let csvURL = null;
  if (navigator.msSaveBlob) csvURL = navigator.msSaveBlob(csvData, "download.csv");
  else csvURL = window.URL.createObjectURL(csvData);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", `${moment().format("YYYY_MM_DD_HH_mm_ss")}${name}`);
  tempLink.click();
};
const clearLocalStorage = () =>
  Object.keys(localStorage).forEach(
    (x) => !["shopifyParams", "INSIGHT DETAIL"].includes(x) && localStorage.removeItem(x),
  );
const isAmazon = (storeName) => matchString(String(storeName), "amazon");
const isWalmart = (storeName) => matchString(String(storeName), "walmart");

const last = (arr) => arr[arr.length - 1];
const localeNum = (num) => {
  try {
    return num.toLocaleString();
  } catch (error) {
    return num;
  }
};

export const setSupplierURL = (URL) => {
  if (!/amazon/i.test(URL)) return URL;

  return URL.includes("th=") ? URL : URL.includes("?") ? (URL += "&th=1&psc=1") : (URL += "?th=1&psc=1");
};

export const daysLeftInTrial = (account) =>
  account?.trial_end ? moment(account.trial_end, "YYYY-MM-DD").diff(moment(moment().format("YYYY-MM-DD")), "days") : 0;

export const isSPAPIURL = () => {
  const queryParameters = queryString.parse(document.location.search);
  const requiredParams = ["selling_partner_id", "spapi_oauth_code", "state"];
  return intersection(requiredParams, Object.keys(queryParameters)).length === requiredParams.length;
};

export const isShopifyAPIURL = (queryParameters) => {
  const requiredParams = ["code", "hmac", "shop", "state", "timestamp", "host"];
  return intersection(requiredParams, Object.keys(queryParameters)).length === requiredParams.length;
};

export const setTokenRefreshedAt = () => localStorage.setItem(TOKEN_REFRESHED_AT_KEY, moment().format());
export const getTokenRefreshedAt = () => localStorage.getItem(TOKEN_REFRESHED_AT_KEY);

const checkPlanAvailability = (data) => {
  if (data.plan_metadata) {
    const { currentListingCount, metadata } = data.plan_metadata;
    if (!parseInt(metadata?.listings)) return true;
    if (currentListingCount > parseInt(metadata.listings)) {
      return false;
    }
  }
  return true;
};

export const callCanonical = () => setTimeout(window.setCanonical, 100);

function filterSteps (account, steps) {
  const onBoardSetting = account?.onboard;
  const ids = Object.keys(onBoardSetting || {})
    .reduce((acc, key) => {
      if (key === "payment") {
        if (!account.payment_source_added) acc.push(ONBOARD_FLAGS[key]);
        return acc;
      }
      if (!onBoardSetting[key]) acc.push(ONBOARD_FLAGS[key]);
      if (!onBoardSetting.store_added) {
        acc.push(onboardingStepsIds.welcom);
        if (isSPAPIURL()) acc.push(onboardingStepsIds.amazonSetUp);
        acc.push(onboardingStepsIds.walmartsetUp);
        acc.push(onboardingStepsIds.facebooksetUp);
        acc.push(onboardingStepsIds.shopifysetUp);
      }
      return acc;
    }, [])
    .sort((a, b) => a - b)
    .filter((x) => ![onboardingStepsIds.emailForwarder, onboardingStepsIds.lister].includes(x));
  if (NON_STORE_USERS.includes(account?.id)) return steps.filter((x) => !account.payment_source_added && x.id === 3);
  return steps.filter((x) => ids.includes(x.id));
}
const decode = (str, rounds = 1) =>
  [...Array(rounds)].reduce((token) => Buffer.from(token, "base64").toString("utf8"), str || "");
const encode = (str, rounds = 1) =>
  [...Array(rounds)].reduce((token) => Buffer.from(token).toString("base64"), str || "");
const jsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return false;
  }
};

const setAuthTokenInCookie = (token) => {
  const userInfo = getUserInfo() || {};
  if (!userInfo) return;

  document.cookie = `authTokenJwt=${token}; expires=${moment.unix(userInfo.exp).toDate().toUTCString()}; path=/`;
};

const deleteTokenFromCookie = () =>
  (document.cookie = `authTokenJwt=; expires=${moment().subtract(1, "day").toDate().toUTCString()}; path=/`);

const setAuthTokenInBrowser = (token) => {
  localStorage.setItem("authTokenJwt", token);
  setAuthTokenInCookie(token);
  setTokenRefreshedAt();
};

const setLoader = (loading) => {
  if (loading) {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "block");
    document.getElementById("status") && (document.getElementById("status").style.display = "block");
  } else {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "none");
    document.getElementById("status") && (document.getElementById("status").style.display = "none");
  }
};

export const partialReversedMarketplaceFee = (sourceItems) => {
  if (!sourceItems?.find((item) => item.refund)) return false;
  const totalFee = marketplaceFee(sourceItems, false) - marketplaceFee(sourceItems);
  return humanize(totalFee, parseFloat);
};

export const getFulfillmentFee = item => $.result(item, "fulfillment_fee", 0);

export const marketplaceFee = (sourceItems, isRefundAdded = true) =>
  sourceItems?.reduce(
    (acc, item) =>
      acc +
      (item.price + item.shipping + (isRefundAdded ? (item.refund ? item.refund + item.tax : item.refund) : 0)) *
        item.qty *
        (item.comission || 0.15),
    0,
  );
export const marketplaceTax = (sourceItems) => sourceItems.reduce((x, y) => x + y.tax, 0);

const isOrderCancelled = (order) => order.status === SOURCE_ORDER_STATUSES.cancelled;

const pricePredicate = (acc, x, isProfit) =>
  acc +
  (x.price * x.qty * (isProfit ? (x.comission ? 1 - x.comission : 0.85) : 1) +
    x.shipping +
    (isProfit ? 0 : x.tax) +
    (isProfit ? x.refund : 0));
const costPredicate = (acc, x) =>
  acc + (x.cost * (x.qty || 1) + x.tax + x.shipping_cost - x.promotions - x.adjustments);

export const getTotalPriceAdj = (order) => toF(sumBy(order?.price_events || [], "event_amount"), 2, true);

export const estCostPredicate = item => (item.sup_cost * (item.qty || 1) + item.sup_tax + item.sup_shipping);
export const calculateEstCost = (order) => sumBy(order.source_items, estCostPredicate);

const calculateCost = (order) => reduce(order.supplier_orders, costPredicate, 0);
const calculateSale = (order, isProfit = true) => {
  if (isProfit && isOrderCancelled(order)) return 0;
  const totalPaid = toF(
    reduce(order?.source_items, (acc, curr) => pricePredicate(acc, curr), 0),
    2,
    true,
  );
  const totalRefund = isProfit ? sumBy(order.source_items, "refund") : 0;

  const total = totalPaid + totalRefund;
  if (isProfit) {
    if (!total) return total;
    return total - sumBy(order.source_items, "tax") - marketplaceFee(order.source_items);
  }
  return total;
};
const warehouseCost = (order, isTwoStep = true) =>
  order?.warehouse_shipping + order?.warehouse_fee + (isTwoStep ? 0 : order.return_charges);

const buildState = (formData, isUpdate) => {
  const tenantEmail = getUserInfo()?.email;
  const { name: storeName, id, seller_id: sellerId, for_repricer: forRepricer } = formData;
  return encode(
    JSON.stringify({ id, storeName, sellerId, forRepricer, tenantEmail, type: isUpdate ? "update" : "new" }),
  );
};
const isAODev = () => AO_DEV_IDS.includes(getUserInfo()?.id);

export const getRepricerAccountData = (store) => merge(pick(store, "identifier", "marketplace"));

export const decodeState = (queryParameters) => queryParameters?.state && JSON.parse(decode(queryParameters?.state));

const currentUser = () => {
  const { full_name: fullName, username } = decodeJwtToken(localStorage.getItem("authTokenJwt"));
  return (fullName || username)?.toUpperCase();
};

const verifyRole = (...role) => {
  const user = getUserInfo();
  return role.includes(user?.role) && user;
};

const getDate = (date, formatter = "MMMM Do YYYY, h:mm:ss a") => (date ? moment(date).format(formatter) : "");

const getInventoryStuckItems = (data) =>
  data?.filter(
    (x) => x.status === INVENTORY_PRODUCTS_STATUSES.stranded && moment() > moment(x.created_at).add(7, "days"),
  );

const getWarehouseName = (WH) => (WH ? `Warehouse#${WH.id}-${WH.state}` : "");

const getWHStatus = ({ wh_tracking_status: whStatus, tracking_status: status, marketplace_status: mpStatus }) => {
  if ([ORDER_TRACKING_STATUSES.shipped, ORDER_TRACKING_STATUSES.in_transit].includes(whStatus))
    return TRACKING_ITEM_FILTERS.shipped;
  if ([ORDER_TRACKING_STATUSES.shipped, ORDER_TRACKING_STATUSES.in_transit].includes(status))
    return TRACKING_ITEM_FILTERS.inbound;
  if (
    status === ORDER_TRACKING_STATUSES.delivered &&
    [MARKETPLACE_STATUSES.Cancelled, MARKETPLACE_STATUSES.Canceled].includes(mpStatus)
  )
    return TRACKING_ITEM_FILTERS.stranded;
  if (status === ORDER_TRACKING_STATUSES.refund) return TRACKING_ITEM_FILTERS.refund;
  if (status === ORDER_TRACKING_STATUSES.delivered && !whStatus) return TRACKING_ITEM_FILTERS.at_warehouse;
  return whStatus === ORDER_TRACKING_STATUSES.delivered ? "completed" : status;
};

const decodeJwtToken = (token) => {
  if (!token) return {};
  const encodedUrl = token.split(".")[1];
  const code = atob(encodedUrl?.replace(/-/g, "+")?.replace(/_/g, "/") || "");
  const payload = code.split("").map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2));

  return JSON.parse(decodeURIComponent(payload.join("")) || "{}");
};

const getUserInfo = () => {
  const token = localStorage.getItem("authTokenJwt");
  return token && decodeJwtToken(token);
};

const getInventoryItem = (whInventoryData, recShipId) => {
  const inventoryItem = whInventoryData?.inventoryItems.find((item) =>
    !item?.received_shipments ? item.id === recShipId : item?.received_shipments?.some((rec) => rec.id === recShipId),
  );
  const recIndex =
    inventoryItem?.received_shipments && inventoryItem?.received_shipments.findIndex((rec) => rec.id === recShipId);
  return { recIndex, inventoryItem };
};

const getWarehouseInventoryLocation = (x, action, id) => {
  if (action === "update" || action === "create") return x.find((loc) => loc?.wh_inventory_locations?.id === id);
  else return x.filter((loc) => loc?.wh_inventory_locations?.id !== id);
};

const isAdmin = () => verifyRole(USER_ROLES.admin);

const sleep = (secs = 1) => new Promise((resolve) => setTimeout(resolve, secs * 1000));

const isWHStaff = (_) => getUserInfo()?.isWH || false;
const parseIssueNote = (order) => {
  if (order?.issue_reason !== "Critical Order") return order?.issue_note || "";

  const shipDate = moment(order.required_shipping_date);
  if (shipDate.isSame(moment(), "day")) return "Order Due Today";
  if (shipDate.isBetween(moment(), moment().add(1, "day"))) return "Order Due Tomorrow";

  let diffDays = moment().diff(shipDate, "days");
  const text = diffDays < 0 ? "future" : "past";
  if (diffDays < 0) diffDays *= -1;
  return `${capitalize(text)} Due Order (${diffDays} day${diffDays > 1 ? "s" : ""} ${text} ESD)`;
};

const isManualTeam = () => DEV_IDS.concat([4010]).includes(getUserInfo()?.id);
const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));
const makeAmzOfferLink = (link) => `https://www.amazon.com/gp/offer-listing/${link.match(asinRegex)[1]}`;
const makeTopDawgOfferLink = (upc) => `https://topdawg.com/drop-shipping/wholesale-products/${upc || ""}`;
const toPascalCase = (s) =>
  s?.replace(/_/g, " ").replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });

function parseAOAttemptDate ({ started_at: startAt, ended_at: endAt }) {
  try {
    let dates = [startAt, endAt].map((x) => moment(x)).filter((x) => x.isValid());
    const days = uniq(dates.map((x) => x.format(DATE_FORMATS.DATE)));
    dates = dates.map((x) => x.format("h:mm A"));
    dates.splice(1, 0, "-");
    return `${days.shift()} [${dates.join(" ")}]`;
  } catch (error) {
    return null;
  }
}

const allowedTrackingStatus = (status) => {
  let statuses = {};
  switch (status) {
    case ORDER_TRACKING_STATUSES.shipped:
      statuses = omit(ORDER_TRACKING_STATUSES, ["unshipped"]);
      break;
    case ORDER_TRACKING_STATUSES.in_transit:
      statuses = omit(ORDER_TRACKING_STATUSES, ["unshipped", "shipped"]);
      break;
    case ORDER_TRACKING_STATUSES.delivered:
      statuses = omit(ORDER_TRACKING_STATUSES, ["shipped", "unshipped", "in_transit"]);
      break;
    default:
      statuses = ORDER_TRACKING_STATUSES;
      break;
  }
  return Object.values(statuses);
};

const httpEncode = (str, isJSON = false) => encodeURIComponent(isJSON ? JSON.stringify(str) : str);

const comparatorFuntion = (val) => ($.isFinite(val) ? false : !val);

const makeQP = (params) =>
  keys(params).length
    ? keys(omitBy(params, comparatorFuntion)).reduce((str, key, i) => {
      if (Array.isArray(params[key]))
        str += `${i ? "&" : ""}${params[key].map((val) => `${key}[]=${val}`).join("&")}`;
      else if (params[key]?.constructor === Object) str += `${key}=${httpEncode(params[key], true)}`;
      else str += `${i ? "&" : ""}${key}=${httpEncode(params[key])}`;
      return str;
    }, "?")
    : "";

export const parseQP = (url) => {
  const _i = url.indexOf("?");
  return split(url.slice(_i + 1), "&").reduce(
    (obj, param) => merge(obj, { [split(param, "=").shift()]: decodeURIComponent(split(param, "=").pop()) }),
    { baseUrl: url.slice(0, _i) },
  );
};

function getKeyByValue (object, value) {
  return Object.keys(object)?.find((key) => object[key] === value);
}

const getMenuPlacement = (data, index, handleLessRecords = true) =>
  (handleLessRecords && data?.length <= 2) || index <= data?.length - 3 ? "bottom" : "top";

export const removeURLQueryParams = () => {
  const url = document.location.href;
  window.history.replaceState(null, "", url?.replace(document.location.search, ""));
};

function formatMarketplaceName (name) {
  return toPascalCase(name);
}

function checkURL (url) {
  if (!url) return url;
  if (url.includes("https://www.")) return url;
  else if (url.includes("https") && !url.includes("www.")) return url.replace("https://", "https://www.");
  else if (url.includes("http") && !url.includes("www.")) return url.replace("http://", "https://www.");
  else if (!url.includes("www.")) return "https://www." + url;
  else if (!url.includes("http") && !url.includes("https")) return "https://" + url;
  else if (url.includes("http://")) return url.replace("http://", "https://");
  else return url;
}

export function isGenReportUser () {
  return GEN_REPORT_USER_IDS.includes(getUserInfo()?.id);
}

function filteredVariation (variation) {
  try {
    const data = jsonParse(variation) || variation;
    return data.reduce((acc, x) => {
      acc.push({ ...x, error: [] });
      return acc;
    }, []);
  } catch (error) {
    return [{ name: "", value: "", error: [] }];
  }
}

function getVariation (variation) {
  return (
    variation &&
    filteredVariation(variation)
      .map((obj) => (obj?.name && obj?.value) && `${obj.name}: ${obj.value}`)
      .join(", ")
  );
}

function validateSupplierUrl (url) {
  let isValid = false;
  if (!url) return isValid;
  url = url.toLowerCase();
  if (
    ["amazon", "walmart", "homedepot", "luckyvitamin", "zoro", "wayfair", "overstock", "zappos"].every(
      (p) => !url.includes(p),
    )
  ) {
    return true;
  }
  if (isAmazon(url)) {
    const variation = ["/dp/", "/dp/product/", "/gp/product/", "/gp/offer-listing/", "/exec/obidos/asin/"];
    isValid = variation.some((v) => isAmzUrlVariation(v, url));
  } else if (url.includes("walmart.com/ip/")) {
    isValid = Boolean(url.split("?")?.shift()?.split("/")?.pop()?.split("#")?.shift()?.trim());
  } else if (url.includes("homedepot.com/p/")) {
    isValid = url.split("/")?.pop()?.split("?")?.shift()?.trim();
  } else if (url.includes("luckyvitamin.com/p-")) {
    isValid = url.split("luckyvitamin.com/p-")?.pop()?.split("-")?.shift()?.trim();
  } else if (url.includes("zoro.com")) {
    isValid = url
      .split("?")
      ?.shift()
      ?.split("/")
      ?.filter((x) => x)
      ?.pop()
      ?.split("#")
      ?.shift()
      ?.trim();
  } else if (url.includes("wayfair.com")) {
    isValid = url.split("-")?.pop()?.split(".")?.shift()?.trim();
  } else if (url.includes("overstock.com")) {
    isValid = /[0-9]/.test(url.split("/product.html")?.shift()?.split("/")?.pop()?.trim());
  } else if (url.includes("costco.com")) {
    isValid = url.split("product.").pop().split(".")[0];
  } else if (url.includes("zappos.com") && url.includes("/product/")) {
    if (url.includes("/p/product/")) {
      isValid = /[0-9]/.test(url.split("/p/product/")?.pop()?.split("/")?.shift()?.trim());
    } else {
      isValid = /[0-9]/.test(url.split("/product/")?.pop()?.split("/")?.shift()?.trim());
    }
  }
  return Boolean(isValid);
}

function isAmzUrlVariation (variation, url) {
  return (
    url.includes(variation) &&
    url.includes(variation) &&
    /[a-z0-9]{10}/.test(url.split(variation)?.[1]?.split("/")?.[0])
  );
}

const randStr = (len = 6) => {
  const hash = [
    [...Array(26)].map((x, i) => String.fromCharCode(65 + i)),
    [...Array(26)].map((x, i) => String.fromCharCode(97 + i)),
  ].flat();
  return [...Array(len)].reduce((str) => str + hash[parseInt(Math.random() * hash.length)], "");
};

const isUserHasExpirePrevilige = () => [298, 17, 6].includes(getUserInfo()?.id);
const sanitizeObj = (obj = {}) =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key]) acc[key] = obj[key];
    return acc;
  }, {});

const isStrongPassword = (password) => String(password).match(new RegExp(PASSWORD_REGEX));

const toUpper = (s) => (s ? s.toUpperCase() : "");

const encodeSKU = (item) =>
  skuChars.reduce(
    (sku, arg, ascii) => sku.replace(new RegExp(arg, "g"), String.fromCharCode(ascii + 161)),
    item?.sku || "",
  );

const decodeSKU = (_sku) =>
  skuChars.reduce((sku, arg, ascii) => sku.replace(new RegExp(String.fromCharCode(ascii + 161), "g"), arg), _sku || "");

const getAllStates = (countryCode) => Object.entries(regions[countryCode]?.states || {});

const getAllCities = (countryCode, stateCode) => regions[countryCode]?.states?.[stateCode]?.cities || [];

const isSelectedRow = (listings, item) => listings.some((listing) => listing.id === item.id);

export const shoudPaymentRedirect = (acc) => {
  if (acc?.id === 2) return false;
  if ([ACCOUNT_STATUSES.payment_failed, ACCOUNT_STATUSES.hold].includes(acc?.status)) return true;
  const isTrialExpired =
    moment(acc?.trial_end) < moment() ||
    (moment(acc?.trial_end) > moment() && moment(acc?.last_error_dismissed).add(24, "hours") < moment());
  return acc?.status === ACCOUNT_STATUSES.active && isTrialExpired;
};

export const isPastDate = (date) => moment(date).isBefore(moment());

export const formatNoteText = (note) => {
  const sentences = note.split(". ");
  const formattedSentences = $.map(sentences, (sentence) => {
    if (!$.size(sentence)) return sentence;
    const capitalizedSentence = $.capitalize(sentence);
    return capitalizedSentence.includes("\n") ? capitalizedSentence.replaceAll("\n", "<br/>") : capitalizedSentence;
  });
  return formattedSentences.join(". ");
};

export const getRedirectionUrl = (tenant, showMessage) => {
  const sendResp = (arr = []) => (showMessage ? arr : arr.shift());
  if (!shoudPaymentRedirect(tenant)) return sendResp();

  const paths = ["/dashboard", "/settings"];
  const result = [];
  if (tenant?.status === "on_hold" && !paths.includes(document.location.pathname)) {
    result.push("/dashboard", "Account is on hold.");
  } else if (
    tenant?.status === ACCOUNT_STATUSES.payment_failed &&
    moment().isSameOrAfter(moment(tenant?.payment_failed_date).add(3, "days")) &&
    !JSON.parse(ENV.REACT_APP_SUPPORT_ACCOUNTS || "[]").includes(getUserInfo().user_email) &&
    getUserInfo()?.role !== "admin"
  ) {
    result.push("/settings?billing=true", "Your last subscription payment was failed.");
  } else if (
    isPastDate(tenant?.trial_end) &&
    !tenant?.stripe_subscription_id &&
    !tenant?.agency_stripe_subscription_id &&
    (!tenant?.last_sub_end || isPastDate(tenant?.last_sub_end))
  ) {
    result.push("/settings?billing=true", "You currently have no active subscription to use the app.");
  }
  return sendResp(result);
};

const is2StepApplicableForSupplier = (mpFi, allStores, store) => {
  if (mpFi) return "Item is fulfilled by marketplace";
  else if (allStores[store.value]?.two_step_enabled) {
    return "Supplier is considered as 2 step because it's enabled at store level";
  }
  return "";
};

const isActiveAccount = ({ valid, enabled }) => valid && enabled;
const checkoutErrorManualFulfillment = (attempts) =>
  attempts.some(
    (x) => x.error_prefix === "Checkout Error" && moment().subtract(4, "hours").isBefore(moment(x.ended_at)),
  );

const RenderIf = ({ children, isTrue, fallback }) => (isTrue ? children : fallback || null);

export const parseErr = (err) => {
  if (!err || ["string", "boolean"].includes(typeof err)) return err;
  if (getUserInfo()?.role === USER_ROLES.admin) return JSON.stringify(err);
  return "Internal Server Error";
};
const isAgency = () => !getUserInfo()?.agency_id;

export const downloadReportPDF = (dispatch, setDashboardPdfOpts) => {
  const htmlWidth = document.getElementById("content")?.offsetWidth;
  const htmlHeight = document.getElementById("content")?.offsetHeight;
  const topLeftMargin = 15;
  const pdfWidth = htmlWidth + topLeftMargin * 2;
  const canvasImageWidth = htmlWidth;
  const canvasImageHeight = htmlHeight;

  html2canvas(document.querySelector("#content")).then((canvas) => {
    canvas.getContext("2d");
    const imgData = canvas.toDataURL("image/jpeg", 1.0);
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF("p", "pt", [pdfWidth, 1750]);
    pdf.addImage(imgData, "JPG", topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight);
    pdf.save(`Profit & Loss Report ${moment().format("YYYY-MM-DD")}.pdf`);
  });
  dispatch(setDashboardPdfOpts({ pdfLoader: false, isPLModal: false, pdfDivStyle: "none" }));
};

export const twoStepWarningFilters = (key, associatedWarehousesObj, accounts) =>
  accounts?.filter(
    (acc) =>
      acc.valid &&
      acc.enabled &&
      !values(TWO_STEP_NON_SUPPORTED_STORES).includes(acc.marketplace) &&
      acc.status === MP_ACC_STATUSES.active &&
      !acc[key] &&
      (key === "two_step_wh_id" ? !keys(associatedWarehousesObj).length && acc.two_step_enabled : true),
  );

export const isShowWHPages = (accounts, field) => accounts?.some((x) => x[field]);

export const connectToAMZStores = (accounts, func = "some") =>
  accounts?.[func]((acc) => acc.marketplace === MARKET_PLACES.amazon && !(acc.valid && acc.refresh_token));

export const getEstDate = (date) => {
  // Date: timestamp / string / date / moment
  const toAdd = moment().tz(moment.tz.guess()).utcOffset();
  return moment(date).add(-300 - toAdd, "minutes");
};

const addMinutes = (date, mins) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() + mins);
  return result;
};

export const adjustDate = (date) => {
  // Only date object should be given
  if (date?.constructor !== Date) return date;
  const offset = date.getTimezoneOffset();
  date.setHours(0, 0, 0, 0);
  return addMinutes(date, -offset + 300);
};

export const scrollToTop = (delay = 500) =>
  setTimeout(() => document.querySelector("body").scrollIntoView({ behavior: "smooth" }), delay);

export const getProductURL = (item, marketplace, store) => {
  switch (marketplace) {
    case MARKET_PLACES.walmart:
      return `https://www.walmart.com/ip/${item.item_id}?selected=true`;
    case MARKET_PLACES.amazon:
      return `https://www.amazon.com/dp/${item.item_id}?th=1&psc=1`;
    case MARKET_PLACES.shopify:
      return `https://${store?.name}.myshopify.com/products/${item.parent_handle}?variant=${item.item_id}`;
    default:
      return "#";
  }
};

export const isBase64Encoded = (str) => /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(str);

export const replaceUnderscores = (str) => str?.replace("_", "");

export const getValidStores = (
  accounts,
  { forRepricer, isValid, includeArchive = false, marketplaces = [MARKET_PLACES.amazon, MARKET_PLACES.walmart] },
) => {
  const isValidStore = (x) => {
    let shouldIncluded = !isValid || (x.valid && x.enabled);
    if (!includeArchive && x.is_archive) shouldIncluded = false;

    return shouldIncluded && marketplaces.includes(x.marketplace);
  };
  return forRepricer ? $.filter(accounts?.data, isValidStore) : accounts?.data || [];
};

export const downloadFileClientSide = filePath => {
  if (!filePath) return;
  const anchor = document.createElement("a");
  anchor.href = filePath;
  anchor.style.display = "none";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
export const getUrlPrefix = (url) => url.match(/^\/[^/]+/)?.[0] || url;
export const makeSubscriptionsUrls = (type, trial, addPayment = false) =>
  addPayment
    ? "/settings?payment_methods=true&upgrade=true"
    : `/settings?billing=true&plan_type=${type}&trial=${trial}`;

export const getPlatformSpecificLabel = (platform) => {
  const platformMappings = {
    topdawg: "Topdawg Id",
    thefulfiller: "TheFulfiller Id",
    plumberstock: "PlumbersStock Id",
    default: "Seller SKU",
  };

  return platformMappings[platform.toLowerCase()] || platformMappings.default;
};

export const getSupplierName = (name) => {
  const nameMappings = {
    thefulfiller: "the_fulfiller",
    plumberstock: "plumbers_stock",
  };

  return nameMappings[name.toLowerCase()] || name;
};

export const isJSON = (data) => {
  try {
    return JSON.parse(data?.message || data);
  } catch (error) {
    return false;
  }
};

export const isMarketplaceFulfilled = (channel) => ["AFN", "WFS"].includes(channel);

export const isSuperAdmin = () => TENANTS_ACTIONS_ACCESS.includes(getUserInfo()?.id);

export const isWarehouseAdmin = () => WAREHOUSE_ADMINS.includes(getUserInfo()?.email);

export {
  getWarehouseInventoryLocation,
  getInventoryItem,
  httpEncode,
  sleep,
  isAgency,
  capitalize,
  isAmazon,
  isWalmart,
  isStrongPassword,
  decode,
  currentUser,
  verifyRole,
  getRandomInt,
  toPascalCase,
  isManualTeam,
  makeAmzOfferLink,
  encode,
  getUserInfo,
  last,
  jsonParse,
  is2StepApplicableForSupplier,
  allowedTrackingStatus,
  getKeyByValue,
  formatMarketplaceName,
  validateSupplierUrl,
  randStr,
  isUserHasExpirePrevilige,
  sanitizeObj,
  isAODev,
  isWHStaff,
  parseIssueNote,
  clearLocalStorage,
  makeQP,
  localeNum,
  parseAOAttemptDate,
  costPredicate,
  pricePredicate,
  calculateCost,
  calculateSale,
  warehouseCost,
  downloadFile,
  toUpper,
  getWarehouseName,
  getVariation,
  isSelectedRow,
  filteredVariation,
  delaySettingForAnHour,
  getWHStatus,
  makeTopDawgOfferLink,
  setLoader,
  checkURL,
  checkPlanAvailability,
  humanize,
  toF,
  encodeSKU,
  decodeSKU,
  matchString,
  getInventoryStuckItems,
  buildState,
  getAllStates,
  getAllCities,
  filterSteps,
  decodeJwtToken,
  RenderIf,
  checkMarketPlaces,
  getMenuPlacement,
  isActiveAccount,
  getDate,
  isAdmin,
  amountText,
  formatNumber,
  checkoutErrorManualFulfillment,
  setAuthTokenInCookie,
  deleteTokenFromCookie,
  setAuthTokenInBrowser,
  isUserHaveFullAccess,
  hasFullAccessOfAnyPlan,
  isProfitCalculationAllowed,
  addStoreFront,
};
