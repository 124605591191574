export const CREATE_NOTIFICATION_EMAILS = "CREATE_NOTIFICATION_EMAILS";
export const CREATE_NOTIFICATION_EMAILS_SUCCESS = "CREATE_NOTIFICATION_EMAILS_SUCCESS";
export const FETCH_NOTIFICATION_EMAILS = "FETCH_NOTIFICATION_EMAILS";
export const FETCH_NOTIFICATION_EMAILS_SUCCESS = "FETCH_NOTIFICATION_EMAILS_SUCCESS";
export const DELETE_NOTIFICATION_EMAIL = "DELETE_NOTIFICATION_EMAIL";
export const DELETE_NOTIFICATION_EMAIL_SUCCESS = "DELETE_NOTIFICATION_EMAIL_SUCCESS";
export const FETCH_NOTIFICATIONS_SETTINGS = "FETCH_NOTIFICATIONS_SETTINGS";
export const FETCH_NOTIFICATIONS_SETTINGS_SUCCESS = "FETCH_NOTIFICATIONS_SETTINGS_SUCCESS";
export const SET_EMAIL_TYPE_ENABLED = "SET_EMAIL_TYPE_ENABLED";
export const SET_EMAIL_TYPE_ENABLED_SUCCESS = "SET_EMAIL_TYPE_ENABLED_SUCCESS";
export const API_ERROR = "NOTIFICATION_EMAILS_API_ERROR";
export const BANNER_NOTIFICATION_UPDATE = "BANNER_NOTIFICATION_UPDATE";
