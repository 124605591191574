export const FETCH_AO_ECS_TASKS = "FETCH_AO_ECS_TASKS";
export const FETCH_AO_ECS_TASKS_SUCCESS = "FETCH_AO_ECS_TASKS_SUCCESS";

export const FETCH_ACCOUNTS_ORDERS_HISTORY = "FETCH_ACCOUNTS_ORDERS_HISTORY";
export const FETCH_ACCOUNTS_ORDERS_HISTORY_SUCCESS = "FETCH_ACCOUNTS_ORDERS_HISTORY_SUCCESS";

export const STOP_ECS_TASK = "STOP_ECS_TASK";
export const STOP_ECS_TASK_SUCCESS = "STOP_ECS_TASK_SUCCESS";

export const API_ERROR = "FETCH_AO_ECS_TASKS_SUCCESS_ERROR";
