import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Badge } from "reactstrap";
import { connect } from "react-redux";
import CustomPagination from "../../components/Common/CustomPagination";
import moment from "moment-timezone";
import "flatpickr/dist/themes/dark.css";

// actions
import { fetchS3Objects, setPreloader, updateS3Object } from "../../store/actions";
import { toPascalCase } from "../../utils/common";
import { DATE_FORMATS } from "../../constants";
import { DatePicker } from "../../components/Common/DatePickr";
const { FULL_DATE } = DATE_FORMATS;
const emailLinks = (str) =>
  String(str)
    .split(", ")
    .map((item, key) => (
      <a key={"_email_links" + key} className="d-block" href={`mailto:${item}`}>
        {item}
      </a>
    ));

const STATUS_COLOR_CODE = {
  pending: "primary",
  unaccounted: "warning",
  done: "success",
};

const S3Objects = (props) => {
  const [search, setSearch] = useState("");
  const [tabsFilter, setTabFilter] = useState({
    page: 1,
    per_page: 100,
    start_date: "",
    end_date: "",
    sort_by: "email_date",
    sort_direction: "desc",
  });

  const tableHeaders = [
    { title: "Subject" },
    { title: "Email Date" },
    { title: "Status" },
    { title: "From" },
    { title: "To" },
    { title: "Retry" },
  ];

  useEffect(() => {
    props.fetchS3Objects(tabsFilter);
  }, [tabsFilter]);

  useEffect(() => {
    props.setPreloader(props.loading);
  }, [props.loading]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col lg={2}>
              <form
                className="app-search d-none d-lg-block"
                onSubmit={(e) => {
                  e.preventDefault();
                  setTabFilter({ ...tabsFilter, search: search.trim(), page: 1 });
                }}
              >
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => {
                      if (!e.target.value) {
                        tabsFilter.search && delete tabsFilter.search;
                        setSearch("");
                      } else {
                        setSearch(e.target.value);
                      }
                      setTabFilter({ ...tabsFilter, search: e.target.value, page: 1 });
                    }}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form>
            </Col>
            <Col lg={3} className="mt-3">
              <DatePicker onClose={(dates) => setTabFilter({ ...tabsFilter, ...dates, page: 1 })} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {props.s3Objects?.data?.length ? (
            <>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {tableHeaders.map((header, index) => (
                        <th
                          className={/status|email date|retry/i.test(header.title) ? "text-center" : ""}
                          key={`table-header ${index}`}
                        >
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {props.s3Objects?.data?.map((item, key) => (
                      <tr key={"_parse_email" + key} className="mt-3 mb-3">
                        <td>
                          <a href={"/email/view/" + item?.s3_key} target="_blank" rel="noopener noreferrer">
                            {item.subject?.substring(0, 50)}
                          </a>
                        </td>
                        <td className="text-center">{moment(item?.email_date).format(FULL_DATE) || ""}</td>
                        <td className="text-center">
                          <Badge
                            className={"font-size-12 badge-soft-" + STATUS_COLOR_CODE[item.status]}
                            color={STATUS_COLOR_CODE[item.status]}
                            pill
                          >
                            {toPascalCase(item.status)}
                          </Badge>
                        </td>
                        <td>
                          <a href={`mailto:${item.email_from}`}>{item.email_from}</a>
                        </td>
                        <td>{emailLinks(item.email_to)}</td>
                        <td className="text-center">
                          <i
                            className="bx bx-sm bx-reset cursor-pointer ml-3"
                            onClick={() => props.updateS3Object(item.s3_key, { status: "pending" })}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {props.s3Objects && (
                <CustomPagination
                  total={props.s3Objects.count}
                  page={props.s3Objects.page}
                  perPage={props.s3Objects.perPage}
                  tabsFilter={tabsFilter}
                  setTabFilter={setTabFilter}
                />
              )}
            </>
          ) : null}

          {props.s3Objects?.data?.length === 0 && !props.Emails?.loading
            ? (
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Records Found</h1>
              </div>
            </div>
              )
            : null}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { Emails } = state;
  return Emails;
};

export default connect(mapStatetoProps, {
  fetchS3Objects,
  setPreloader,
  updateS3Object,
})(S3Objects);
