import {
  FETCH_CREDIT_LOG,
  FETCH_CREDIT_LOG_SUCCESS,
  CONSUME_CREDIT,
  CONSUME_CREDIT_SUCCESS,
  DELETE_CREDIT_LOG,
  DELETE_CREDIT_LOG_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  creditLogs: { data: [] },
  proxyBceSuccess: "",
  proxyBceError: "",
  proxyBceRes: {},
  proxyNonBceSuccess: "",
  proxyNonBceError: "",
  proxyNonBceRes: {},
  proxyNonBCEAvailableCities: [],
  proxyNonBCEAvailableStates: [],
  proxyNonBCEAvailableCountries: [],
};

const CreditLogs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREDIT_LOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_CREDIT_LOG_SUCCESS:
      state = {
        ...state,
        creditLogs: action.payload,
        loading: false,
      };
      break;
    case CONSUME_CREDIT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONSUME_CREDIT_SUCCESS:
      state = {
        ...state,
        creditConsumed: action.payload,
        loading: false,
      };
      break;
    case DELETE_CREDIT_LOG:
      state = {
        ...state,
        loading: true,
        creditDeleted: "",
      };
      break;
    case DELETE_CREDIT_LOG_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload.msg,
        creditDeleted: action.payload,
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CreditLogs;
