import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Card, CardBody, CardHeader, Container, Alert, Collapse, FormGroup, Tooltip } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import CustomTooltip from "../../components/Common/CustomTooltip";
import HiddenField from "../../components/Common/HiddenField";
import CustomPagination from "../../components/Common/CustomPagination";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import AccountsModal from "./AccountsModal";
import PillBtn from "../../components/Common/PillBtn";
import CredentialsModal from "./CredentialsModal";
import CredentialBulkActionModal from "./CredentialBulkActionModal";
import AccountCredentials from "./AccountCredentials";
import CCTypesModal from "./CCTypesModal";
import { orderBy, startCase } from "lodash";
import { RenderIf, toPascalCase, decode, getUserInfo, isBase64Encoded } from "../../utils/common";
// actions
import {
  fetchCardTypes,
  fetchPurchasingAccounts,
  fetchUsers,
  setPreloader,
  setAddPurchasingAccountsModal,
  setAddCredentialsModal,
  setEditPurchasingAccountsModal,
  setSelectedCredentials,
  setCreditCardTypesModal,
  setSelectedIds,
  setSelectedPurchasingAccounts,
  deletePurchasingAccount,
  setCredentialsFilter,
  setCredentialsBulkActionModal,
  fetchMatchingPlatforms,
  addProxyManual,
  refreshAccountProxy,
} from "../../store/actions";
import { PRIME_ACC_TYPES, HELP_DOCS_LINK } from "../../constants";

const Credentials = (props) => {
  const { username } = useParams();
  const decodedUsername = username ? isBase64Encoded(username) ? decode(username) : username : "";
  const [selectedRecord, setSelectedRecord] = useState({
    id: "",
    email: "",
    selected_accounts: [],
  });
  const [platforms, setPlatforms] = useState([]);
  const [pageSearch, setPageSearch] = useState(decodedUsername || "");
  const [supplier, setSupplier] = useState({
    value: "",
    label: "Select a Supplier",
  });
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [accType, setAccType] = useState("");
  const [chkboxBlocked, setChkboxBlocked] = useState(false);
  const [chkboxLocked, setChkboxLocked] = useState(false);
  const [chkboxEnabled, setChkboxEnabled] = useState(false);
  const [chkboxAODisable, setChkboxAODisable] = useState(false);
  const [chkboxInvalid, setChkboxInvalid] = useState(false);
  const [chkboxPrime, setChkboxPrime] = useState(false);
  const [credentialsCollapse, setCredentialsCollapse] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    key: "",
    id: "",
    supplier: "",
  });
  const [toolTip, setToolTip] = useState({
    blocked: false,
    locked: false,
    enabled: false,
    ao_disabled: false,
    prime: false,
    invalid: false,
  });

  const filters = props.Credential.filters;
  const setFilters = (filters) => props.setCredentialsFilter(filters);

  const accounts = filterCredentials(pageSearch);
  const credentialIds = filterCredentialIds(accounts);

  const tableHeaders = [
    { title: "Email" },
    { title: "Computer Credentials" },
    { title: "Proxy", class: "text-center" },
    { title: "Action", class: "text-center" },
    { title: "Amazon Tracking Fetch Info" },
    { title: "Email Forwarding", class: "text-center" },
    { title: "Credentials", class: "text-center" },
  ];

  function handleAddAccount () {
    props.setAddPurchasingAccountsModal(true);
  }

  function handleAddCredential (id) {
    setSelectedAccount({
      ...selectedAccount,
      id,
    });
    props.setAddCredentialsModal(true);
  }

  function handleBulkUpdateCredential () {
    props.setCredentialsBulkActionModal(true);
  }

  function filterCredentials (searchEmail) {
    if (!searchEmail && !selectedSupplier) return props.Credential?.data?.data;
    return props.Credential?.data?.data?.filter(
      (account) =>
        (searchEmail
          ? decode(account.email)?.toLowerCase().includes(searchEmail.toLowerCase()) ||
            (account.rds_username ? account.rds_username.includes(searchEmail) : false)
          : true) && (selectedSupplier ? account.credentials?.some((x) => x.site.includes(selectedSupplier)) : true),
    );
  }

  function filterCredentialIds (accounts) {
    return accounts?.reduce((acc, account) => {
      return acc.concat(account?.credentials?.map((credential) => credential.id));
    }, []);
  }

  function handleEditAccount (account) {
    props.setSelectedPurchasingAccounts({
      ...account,
      email: decode(account.email),
      proxy: decode(account.proxy),
      proxy_ip: decode(account.proxy_ip || ""),
    });
    props.setEditPurchasingAccountsModal(true);
  }

  function handleDeleteAccount (account) {
    props.deletePurchasingAccount(account.id);
  }

  function isOnPageSearch () {
    return pageSearch || selectedSupplier;
  }

  useEffect(() => {
    props.fetchPurchasingAccounts();
  }, [filters]);

  useEffect(() => {
    (pageSearch || decodedUsername) &&
      accounts?.length === 0 &&
      !props.Credential.loading &&
      setFilters({ ...filters, email: pageSearch || decodedUsername, page: 1 });
  }, [pageSearch, accounts?.length]);

  useEffect(() => {
    props.setPreloader(props.Credential.loading);
  }, [props.Credential.loading]);

  useEffect(() => {
    !props.CreditCards.ccTypes.length && props.fetchCardTypes({});
    if (!props.Listings.matchingPlatforms) props.fetchMatchingPlatforms({});
    setPlatforms(orderBy(props.Listings?.matchingPlatforms, (o) => o?.name?.toLowerCase()));
  }, [props.Listings.matchingPlatforms]);

  return (
    <React.Fragment>
      {props.Credential.addAccountModalIsOpen && <AccountsModal type="add" />}
      {props.Credential.editAccountModalIsOpen && props.Credential.selectedAccounts && <AccountsModal type="edit" />}
      {props.Credential.addCredentialModalIsOpen && (
        <CredentialsModal type="add" accountId={selectedAccount.id} platforms={platforms} />
      )}
      {props.Credential.credentialBulkActionModalIsOpen && (
        <CredentialBulkActionModal ccTypes={props.CreditCards?.ccTypes} />
      )}
      {props.Credential.createCreditCardTypesModalIsOpen && props.Credential.creditCardTypesModalType === "user" && (
        <CCTypesModal selected={selectedRecord} />
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={
              <>
                Credentials{" "}
                {
                  <a href={HELP_DOCS_LINK} rel="noopener noreferrer" target="_blank">
                    <i className="bx bx-info-circle"></i>
                  </a>
                }
              </>
            }
            children={
              <PillBtn
                color="success"
                title="Add Email Account"
                name="Add Email Account"
                icon="bx bx-xs bx-plus"
                className="col ml-2"
                onClick={handleAddAccount}
              />
            }
          />
          {props.Credential?.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.Credential?.success || ""}
              </Alert>
            </div>
          )}

          {props.Credential?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.Credential?.error || ""}
            </Alert>
          )}

          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setFilters({
                      ...filters,
                      email: pageSearch,
                    });
                  }}
                >
                  <div className="position-relative" style={{ display: "inline-flex" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={pageSearch}
                      onChange={(e) => {
                        setCredentialsCollapse(false);
                        if (!e.target.value) {
                          setPageSearch("");
                          delete filters.email;
                          setFilters({ ...filters });
                        } else {
                          setPageSearch(e.target.value.trim());
                        }
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                    <div className="custom-control custom-checkbox ml-2 mt-2" id="BlockedTooltip">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="blocked"
                        checked={chkboxBlocked}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          setFilters({
                            ...filters,
                            blocked: chkboxBlocked === true ? "" : true,
                            page: 1,
                          });
                          setChkboxBlocked(!chkboxBlocked);
                        }}
                        className="custom-control-label"
                      >
                        Blocked
                      </label>
                      <Tooltip
                        placement="top"
                        isOpen={toolTip.blocked}
                        target="BlockedTooltip"
                        toggle={() => setToolTip({ blocked: !toolTip.blocked })}
                      >
                        Credentials that AO has not used anymore due to consecutive failures/errors
                      </Tooltip>
                    </div>
                    <div className="custom-control custom-checkbox ml-2 mt-2" id="LockedTooltip">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="locked"
                        checked={chkboxLocked}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          setFilters({
                            ...filters,
                            locked: chkboxLocked === true ? "" : true,
                            page: 1,
                          });
                          setChkboxLocked(!chkboxLocked);
                        }}
                        className="custom-control-label"
                      >
                        In&nbsp;Use
                      </label>
                      <Tooltip
                        placement="top"
                        isOpen={toolTip.locked}
                        target="LockedTooltip"
                        toggle={() => setToolTip({ locked: !toolTip.locked })}
                      >
                        Currently in use by AO
                      </Tooltip>
                    </div>
                    <div className="custom-control custom-checkbox ml-2 mt-2" id="EnabledTooltip">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="enabled"
                        checked={chkboxEnabled}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          setFilters({
                            ...filters,
                            enabled: chkboxEnabled === true ? "" : true,
                            page: 1,
                          });
                          setChkboxEnabled(!chkboxEnabled);
                        }}
                        className="custom-control-label"
                      >
                        AO&nbsp;Enabled
                      </label>
                      <Tooltip
                        placement="top"
                        isOpen={toolTip.enabled}
                        target="EnabledTooltip"
                        toggle={() => setToolTip({ enabled: !toolTip.enabled })}
                      >
                        Enabled for AO
                      </Tooltip>
                    </div>
                    <div className="custom-control custom-checkbox ml-2 mt-2" id="AODisableTooltip">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="ao_disabled"
                        checked={chkboxAODisable}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          setFilters({
                            ...filters,
                            ao_disabled: chkboxAODisable === true ? "" : true,
                            page: 1,
                          });
                          setChkboxAODisable(!chkboxAODisable);
                        }}
                        className="custom-control-label"
                      >
                        AO&nbsp;Disabled
                      </label>
                      <Tooltip
                        placement="top"
                        isOpen={toolTip.ao_disabled}
                        target="AODisableTooltip"
                        toggle={() => setToolTip({ ao_disabled: !toolTip.ao_disabled })}
                      >
                        Credentials which have AO disabled
                      </Tooltip>
                    </div>
                    <div className="custom-control custom-checkbox ml-2 mt-2" id="PrimeTooltip">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="prime"
                        checked={chkboxPrime}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          setFilters({
                            ...filters,
                            prime: chkboxPrime === true ? "" : true,
                            acc_type: "",
                            page: 1,
                          });
                          setAccType("");
                          setChkboxPrime(!chkboxPrime);
                        }}
                        className="custom-control-label"
                      >
                        Prime
                      </label>
                      <Tooltip
                        placement="top"
                        isOpen={toolTip.prime}
                        target="PrimeTooltip"
                        toggle={() => setToolTip({ prime: !toolTip.prime })}
                      >
                        Amazon Prime
                      </Tooltip>
                    </div>
                    <div className="custom-control custom-checkbox ml-2 mt-2" id="InvalidTooltip">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="invalid"
                        checked={chkboxInvalid}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          setFilters({
                            ...filters,
                            invalid: chkboxInvalid === true ? "" : true,
                            page: 1,
                          });
                          setChkboxInvalid(!chkboxInvalid);
                        }}
                        className="custom-control-label"
                      >
                        Invalid
                      </label>
                      <Tooltip
                        placement="top"
                        isOpen={toolTip.invalid}
                        target="InvalidTooltip"
                        toggle={() => setToolTip({ invalid: !toolTip.invalid })}
                      >
                        Credentials which have any type of error like Invalid OTP, Reset Password Required etc.
                      </Tooltip>
                    </div>
                  </div>
                </form>
                <div className="d-flex mb-3" style={{ maxHeight: "5vh" }}>
                  {props.Credential?.selectedIds?.length > 0 && (
                    <PillBtn
                      color="warning"
                      title="Edit Card Type"
                      name="Edit Card Type"
                      icon="bx bx-xs bx-edit"
                      className="col mr-2"
                      onClick={handleBulkUpdateCredential}
                    />
                  )}
                  <FormGroup className="select2-container mr-2" style={{ minWidth: "180px" }}>
                    <Select
                      name="acc_type"
                      value={{
                        value: accType || "",
                        label: startCase(accType || "Select a type"),
                      }}
                      onChange={(e) => {
                        setAccType(e.value);
                        setFilters({ ...filters, acc_type: e.value });
                      }}
                      options={PRIME_ACC_TYPES.filter((x) => (chkboxPrime ? x.includes("prime") : x)).reduce(
                        (acc, type) => {
                          acc.push({ label: startCase(type), value: type });
                          return acc;
                        },
                        accType === "" ? [] : [{ value: "", label: "All Types" }],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <FormGroup className="select2-container" style={{ minWidth: "180px" }}>
                    <Select
                      name="site"
                      value={{
                        value: supplier.value,
                        label: supplier.label,
                      }}
                      onChange={(e) => {
                        setFilters({ ...filters, supplier: e.value });
                        setSelectedSupplier(e.value);
                        setSupplier({ value: e.value, label: e.value === "" ? "Select a Supplier" : e.label });
                      }}
                      options={platforms?.reduce(
                        (acc, platform) => {
                          if (platform?.name?.toLowerCase() !== "unknown")
                            acc.push({
                              label: toPascalCase(platform?.name),
                              value: platform?.name,
                            });
                          return acc;
                        },
                        supplier.label === "Select a Supplier" ? [] : [{ value: "", label: "All Suppliers" }],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </div>
              </div>
            </CardHeader>

            <CardBody>
              {accounts?.length ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={
                                  credentialIds?.length > 0 &&
                                  credentialIds?.length === props.Credential?.selectedIds?.length
                                }
                                onChange={() => false}
                              />
                              <label
                                className="custom-control-label"
                                onClick={(e) => {
                                  if (credentialIds?.length === props.Credential?.selectedIds?.length) {
                                    props.setSelectedIds([]);
                                  } else {
                                    props.setSelectedIds(credentialIds?.map((item) => item));
                                  }
                                }}
                              >
                                &nbsp;
                              </label>
                            </div>
                          </th>
                          {/* table headers */}
                          {tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`} className={header.class || ""}>
                              {header.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {/* iterating data */}
                        {accounts?.map((item, key) => (
                          <React.Fragment key={"_account_credentials_" + key}>
                            <tr
                              title={item?.credentials?.find((x) => x.error) ? "Credential marked as issue " : ""}
                              key={"_purchasing_accounts_" + key}
                              className={item?.credentials?.find((x) => x.error) ? "mt-3 mb-3 row-danger" : "mt-3 mb-3"}
                            >
                              <td></td>
                              <td>
                                {decode(item?.email)}
                                <CopyToClipBoard text={decode(item?.email)?.toLowerCase()} />
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  {item?.rds_username && (
                                    <div className="d-flex align-items-center">
                                      {item?.rds_username}
                                      <CopyToClipBoard text={item?.rds_username} />
                                    </div>
                                  )}
                                  {item?.rds_password && (
                                    <div className="d-flex align-items-center mt-2">
                                      <HiddenField value={item?.rds_password} />
                                      <CopyToClipBoard text={item?.rds_password} />
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className="text-center">{item.proxy_url}</td>
                              <td className="text-center">
                                <i
                                  title="Add Account"
                                  className="bx bx-sm bx-user-plus p-1 text-primary cursor-pointer"
                                  onClick={() => handleAddCredential(item?.id)}
                                ></i>

                                {!item?.proxy && (
                                  <ConfirmDialogIcon
                                    icon={{
                                      title: "Add Proxy",
                                      className: "bx bx-sm bx-lock-open text-warning ml-1",
                                    }}
                                    msg={<p>This will add proxy to account</p>}
                                    onConfirm={() => props.addProxyManual(item?.id)}
                                  />
                                )}
                                <RenderIf
                                  isTrue={
                                    (getUserInfo()?.role === "admin" ||
                                      getUserInfo()?.account_id === 2 ||
                                      getUserInfo()?.agency_id === 3307) &&
                                    item?.proxy &&
                                    parseInt(decode(item?.proxy))
                                  }
                                >
                                  <ConfirmDialogIcon
                                    icon={{
                                      title: "Refresh Proxy",
                                      className: "bx bx-sm bx-refresh text-warning ml-1",
                                    }}
                                    msg={<p>This will add a new proxy to account</p>}
                                    onConfirm={() => props.refreshAccountProxy(item?.id)}
                                  />
                                </RenderIf>
                                <i
                                  title="Edit Account"
                                  className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                                  onClick={() => handleEditAccount(item)}
                                ></i>
                                <ConfirmDialogIcon
                                  icon={{
                                    title: "Delete Account",
                                    className: "bx bx-sm text-danger bx-trash",
                                  }}
                                  msg={
                                    <p>
                                      To Delete {decode(item?.email)} Account.
                                      <br />
                                      This will also remove all of its Associated Credentials
                                    </p>
                                  }
                                  onConfirm={() => handleDeleteAccount(item)}
                                />
                                {["owner", "admin"].includes(getUserInfo()?.role) && (
                                  <i
                                    title="Assign Account To User"
                                    className="bx bx-sm bx-user-pin text-primary cursor-pointer"
                                    onClick={() => {
                                      props.fetchUsers({
                                        account_id: getUserInfo().account_id,
                                        pagination: false,
                                      });
                                      setSelectedRecord({
                                        id: item?.id,
                                        email: decode(item.email),
                                        selected_accounts: item.purchasing_accounts_users,
                                      });
                                      props.setCreditCardTypesModal(true, "user");
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td>
                                {item.bce_bot_status
                                  ? (
                                  <div id={`bceBotStatus${key}`} className="d-block text-overflow-wrap">
                                    {item.bce_bot_status}
                                    {!/^done|blocked$/i.test(item.bce_bot_status)
                                      ? (
                                      <CustomTooltip target={`bceBotStatus${key}`} content={item.bce_bot_status} />
                                        )
                                      : null}
                                  </div>
                                    )
                                  : null}
                                {item.last_run_at
                                  ? (
                                  <div className="d-block">{moment(item.last_run_at).fromNow()}</div>
                                    )
                                  : null}
                              </td>
                              <td className="text-center">
                                {item.email_forwarding
                                  ? (
                                  <i
                                    title="Email Forwarding Enabled"
                                    className="bx bx-sm bx-check-circle text-success"
                                  ></i>
                                    )
                                  : (
                                  <i title="Email Forwarding Disbaled" className="bx bx-sm bx-block text-danger"></i>
                                    )}
                              </td>
                              <td>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                  <i
                                    onClick={() => {
                                      const status = !(selectedAccount.key === key && credentialsCollapse === true);
                                      setSelectedAccount({
                                        ...selectedAccount,
                                        key,
                                      });
                                      setCredentialsCollapse(status);
                                    }}
                                    className={`bx bx-sm cursor-pointer bx-chevron-${
                                      selectedAccount.key === key && credentialsCollapse ? "up" : "down"
                                    }-circle`}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                            {selectedAccount.key === key && credentialsCollapse && (
                              <tr key={"_account_credentials_collapse_" + key}>
                                <td align="center" colSpan="12">
                                  <Collapse isOpen={credentialsCollapse}>
                                    <AccountCredentials
                                      credentials={item?.credentials}
                                      email={item?.email}
                                      ccTypes={props.CreditCards?.ccTypes}
                                    />
                                  </Collapse>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {props.Credential?.data?.count && (
                    <CustomPagination
                      total={isOnPageSearch() ? accounts?.length : props.Credential?.data?.count}
                      page={props.Credential?.data?.page}
                      perPage={props.Credential?.data?.perPage}
                      tabsFilter={filters}
                      setTabFilter={setFilters}
                    />
                  )}
                </>
              ) : null}
              {accounts?.length === 0 && !props.Credential.loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { Credential, CreditCards, Listings } = state;
  return { Credential, CreditCards, Listings };
};

export default connect(mapStatetoProps, {
  fetchCardTypes,
  fetchPurchasingAccounts,
  fetchUsers,
  setPreloader,
  setAddPurchasingAccountsModal,
  setAddCredentialsModal,
  setEditPurchasingAccountsModal,
  setSelectedCredentials,
  setCreditCardTypesModal,
  setSelectedIds,
  setSelectedPurchasingAccounts,
  deletePurchasingAccount,
  setCredentialsFilter,
  setCredentialsBulkActionModal,
  fetchMatchingPlatforms,
  addProxyManual,
  refreshAccountProxy,
})(Credentials);
