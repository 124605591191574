import { Badge } from "reactstrap";
import { COMPARISON_OPERATORS } from "./common";
import $ from "lodash";
import React from "react";

export default function FilterChip ({ filterTitle, filterValueLabel, operator, removeFilter }) {
  return (
    <Badge className="mb-2 px-3 py-2 inventory-color font-size-12 filter-chip-bg" pill>
      <div className="d-flex justify-content-between align-items-center">
        <span>
          {filterTitle}&nbsp;
          {$.get(COMPARISON_OPERATORS, operator, "=")}&nbsp;
          {$.isNumber(filterValueLabel) ? filterValueLabel : $.startCase(filterValueLabel)}
        </span>
        <i className="bx bx-x-circle mx-1 text-danger cursor-pointer" onClick={removeFilter} />
      </div>
    </Badge>
  );
}
