import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import * as _ from "lodash";
import { getAllAmazonSellers, createAmazonSellers, updateAmazonSeller } from "../../api";
// Login Redux States
import { FETCH_AMAZON_SELLERS, CREATE_AMAZON_SELLER, UPDATE_AMAZON_SELLER } from "./actionTypes";
import {
  apiError,
  fetchAmazonSellersSuccess,
  setAddAmazonSellerModal,
  createAmazonSellerSuccess,
  updateAmazonSellerSuccess,
} from "./actions";

const AmazonSellers = (state) => state.AmazonSellers;

function * fetchAmazonSellersSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllAmazonSellers, params);
    yield put(fetchAmazonSellersSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

function * createAmazonSellerSaga ({ payload: { data } }) {
  try {
    const res = yield call(createAmazonSellers, { data });
    if (res?.data) {
      const { sellers } = yield select(AmazonSellers);
      sellers.data = [...sellers.data, res?.data];
      yield put(setAddAmazonSellerModal(false));
    }
    yield put(createAmazonSellerSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function * updateAmazonSellerSaga ({ payload: { data } }) {
  try {
    const res = yield call(updateAmazonSeller, { data });
    if (res?.data) {
      const { sellers } = yield select(AmazonSellers);
      const index = _.findIndex(sellers?.data, (seller) => seller?.id === data.seller_id);
      if (index !== -1 && sellers) {
        sellers.data[index].name = data.name;
        sellers.data[index].is_blacklisted = data.is_blacklisted;
      }
      yield put(setAddAmazonSellerModal(false));
    }
    yield put(updateAmazonSellerSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchAmazonSellersFetch () {
  yield takeEvery(FETCH_AMAZON_SELLERS, fetchAmazonSellersSaga);
}

export function * watchCreateAmazonSellers () {
  yield takeEvery(CREATE_AMAZON_SELLER, createAmazonSellerSaga);
}

export function * watchUpdateAmazonSellers () {
  yield takeEvery(UPDATE_AMAZON_SELLER, updateAmazonSellerSaga);
}

function * amazonSellersSaga () {
  yield all([fork(watchAmazonSellersFetch), fork(watchCreateAmazonSellers), fork(watchUpdateAmazonSellers)]);
}

export default amazonSellersSaga;
