import React from "react";
import { FormGroup, Label } from "reactstrap";
import Select, { createFilter } from "react-select";

const filterConfig = { ignoreCase: true, ignoreAccents: true, trim: true };

const SimpleSelect = ({
  id,
  name,
  value,
  onChange,
  options,
  components,
  placeholder,
  onBlur,
  formStyle,
  label,
  className,
  maxMenuHeight,
  menuPlacement = "bottom",
  disabled = false,
}) => {
  return (
    <FormGroup className={`select2-container ${className || ""}`} style={formStyle}>
      {label && <Label>{label}</Label>}
      <Select
        id={id}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        name={name}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        filterOption={createFilter(filterConfig)}
        components={components}
        classNamePrefix="select2-selection"
        isDisabled={disabled}
      />
    </FormGroup>
  );
};

export default SimpleSelect;
