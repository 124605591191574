import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { fetchFreeCreditsHistory } from "../../api";

import { FETCH_FREE_CREDITS } from "./actionTypes";
import { apiError, fetchFreeCreditsSuccess } from "./actions";

function * fetchCreditsHistorySaga ({ payload }) {
  try {
    const res = yield call(fetchFreeCreditsHistory, payload);
    yield put(fetchFreeCreditsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchFetchCreditsHistory () {
  yield takeEvery(FETCH_FREE_CREDITS, fetchCreditsHistorySaga);
}

function * FreeCreditsSaga () {
  yield all([fork(watchFetchCreditsHistory)]);
}

export default FreeCreditsSaga;
