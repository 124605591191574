import React from "react";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button } from "reactstrap";
import "react-toggle/style.css";
import { connect } from "react-redux";
import Modal from "../../../components/Common/Modal";

// actions
import { setAddAgencyModal, inviteToAgency } from "../../../store/actions";

const AddAgencyModal = (props) => {
  const validate = (values) => {
    const errors = {};
    if (values.email === "") errors.email = "Field is Required";
    return errors;
  };

  const initialValues = { email: "" };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.inviteToAgency({
        data: values,
      });
    },
  });

  const toggleModal = () => {
    props.setAddAgencyModal(!props.Settings.isAddAgencyModalOpen);
    formik.resetForm();
  };

  return (
    <Modal size="md" isOpen={props.Settings.isAddAgencyModalOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Invite to Agency
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {props.Settings.error && (
          <div className="auto-hide">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.Settings.error}
            </Alert>
          </div>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <p>Enter the email address of the agency that you want to manage your account</p>
              <Label>Email*</Label>
              <Input
                name="email"
                type="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email
                ? (
                <span className="text-danger mt-1">{formik.errors.email}</span>
                  )
                : null}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Invite
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { Settings } = state;
  return { Settings };
};

export default connect(mapStateToProps, {
  setAddAgencyModal,
  inviteToAgency,
})(AddAgencyModal);
