import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button, FormGroup } from "reactstrap";

// actions
import { setUpdateLpmProxyModal, updateLpmProxy } from "../../../store/actions";

const EditProxyModal = (_) => {
  const dispatch = useDispatch();
  const { error, setUpdateLpmProxyModalIsOpen, selectedProxy } = useSelector((state) => state.ProxyUsers);

  function handleUpdate (data) {
    dispatch(updateLpmProxy(data, selectedProxy.wrapper_port));
  }

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "") errors[key] = "Field is Required";
    });
    return errors;
  };

  const initialValues = {
    internal_name: selectedProxy?.internal_name,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const toggleModal = () => {
    dispatch(setUpdateLpmProxyModal(!setUpdateLpmProxyModalIsOpen));
    formik.resetForm();
  };

  return (
    <Modal size="lg" isOpen={setUpdateLpmProxyModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {"Edit Proxy Modal"}
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mt-2">
              <FormGroup className="select2-container mb-0">
                <Label>Internal Name</Label>
                <Input
                  name="internal_name"
                  value={formik.values.internal_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </FormGroup>
              {formik.touched.internal_name && formik.errors.internal_name
                ? (
                <span className="text-danger mt-1">{formik.errors.internal_name}</span>
                  )
                : null}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default EditProxyModal;
