import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { getUserInfo, RenderIf } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { updateListingWeight, resetWeightErr } from "../../../store/actions";
import CustomTooltip from "../../../components/Common/CustomTooltip";

const toF = (num) => parseFloat(parseFloat(num).toFixed(2));
const mw100 = { width: "150px" };

const EstShippingFeeField = ({ amountText, item, shippingKey, setShippingKey, storeFront, index }) => {
  const [shippingFee, setShippingFee] = useState("");
  const { resetState, weightLoader, weightErr } = useSelector((state) => state.Listings);
  const dispatch = useDispatch();

  useEffect(() => {
    !weightLoader && shippingKey && setShippingKey("");
  }, [resetState]);

  const updateWeight = (_) => {
    dispatch(
      updateListingWeight(index, {
        platform: storeFront.marketplace,
        partner_id: storeFront.value,
        sku: item?.sku,
        estimated_shipping_fee: shippingFee,
        estimated_shipping_fee_lock: true,
        va_id: getUserInfo()?.id,
      }),
    );
  };
  return (
    <td className="text-center" style={mw100}>
      <RenderIf
        isTrue={shippingKey === item?.id}
        fallback={
          <span style={mw100}>
            {amountText(item?.estimated_shipping_fee)}
            <i
              className="ml-3 bx bx-pencil cursor-pointer"
              onClick={(_) => {
                setShippingKey(item?.id);
                setShippingFee(item?.estimated_shipping_fee);
                dispatch(resetWeightErr());
              }}
            ></i>
          </span>
        }
      >
        <div className="weightInput d-flex align-items-center justify-content-center">
          <input
            type="number"
            value={shippingFee}
            step="0.01"
            min="0.01"
            className="form-control"
            onChange={(e) => setShippingFee(toF(e.target.value))}
          ></input>
          <RenderIf
            isTrue={!weightLoader}
            fallback={<Spinner color="primary" style={{ width: "20px", height: "20px" }} />}
          >
            <div className="iconBox">
              <RenderIf
                isTrue={weightErr}
                fallback={<i className="bx bx-sm bx-check color-primary cursor-pointer" onClick={updateWeight} />}
              >
                <i
                  className="bx bx-info-circle danger cursor-pointer"
                  style={{ fontSize: "20px" }}
                  id={`tooltip-weight-${index}`}
                />
                <CustomTooltip placement="top" content={weightErr} target={`tooltip-weight-${index}`} />
              </RenderIf>
              <i
                className="bx bx-sm bx-x danger cursor-pointer"
                onClick={() => {
                  setShippingFee("");
                  setShippingKey("");
                  dispatch(resetWeightErr());
                }}
              />
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </td>
  );
};

export default EstShippingFeeField;
