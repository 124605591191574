import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Card, CardBody, NavLink, NavItem, Nav, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import UnaccountedOrder from "./UnaccountedOrders";
import S3Objects from "./S3Objects";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Row from "reactstrap/lib/Row";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import { getUserInfo } from "../../utils/common";

const Emails = () => {
  const { account } = useSelector((state) => state.Settings);

  const tabs = [
    {
      title: "Un Accounted Emails",
      id: 2,
      component: UnaccountedOrder,
    },
  ];

  if (getUserInfo()?.role !== "va" || getUserInfo()?.agency_id !== 3382) {
    tabs.unshift({ title: "All Emails", id: 1, component: S3Objects });
  }

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row className="ml-1">
            <Breadcrumbs title="Emails" />
            {account?.data?.forwarder_email && (
              <span className="ml-3 black-color">
                ({account.data.forwarder_email})
                <CopyToClipBoard text={account.data.forwarder_email} />
              </span>
            )}
          </Row>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs-custom nav-justified">
                {tabs.map((tab) => (
                  <NavItem key={`email_tab-${tab.id}`}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === tab.id,
                      })}
                      onClick={() => {
                        toggleActiveTab(tab.id);
                      }}
                    >
                      <span className="d-none d-sm-block">{tab.title}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={activeTab}>
                {tabs.map((tab) => (
                  <TabPane key={"tab_pane" + tab.id} tabId={tab.id} className="pt-3" children={<tab.component />} />
                ))}
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Emails;
