import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardBody, Alert } from "reactstrap";

import moment from "moment-timezone";
import { toF } from "../../../utils/common";
// actions
import { setPreloader, getCustomerHistory } from "../../../store/actions";

import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import { DATE_FORMATS } from "../../../constants";

const { DATE_TIME, DATE_PICKR } = DATE_FORMATS;

const ChargeHistory = (props) => {
  const { chargeHistory, loading, error, has_more: hasMore } = useSelector((state) => state.Stripe);
  const [dates, setDates] = useState([null, null]);
  const tableHeaders = [{ title: "Amount" }, { title: "Date" }, { title: "Description" }, { title: "Invoice URL" }];

  const dispatch = useDispatch();

  function setLoader (bool) {
    dispatch(setPreloader(bool));
  }

  const getAmount = (amount) => toF(amount / 100);

  const Refund = ({ amount }) => (
    <div className="d-flex ">
      <span className="text-success">${getAmount(amount)}</span>
      <span>&nbsp;{"(refunded)"}</span>
    </div>
  );

  const Amount = ({ charge: { amount_refunded: refunded, amount_captured: captured } }) => {
    if (refunded === 0) return <span className="text-danger">${getAmount(captured)}</span>;
    if (refunded === captured) return <Refund amount={refunded} />;
    return (
      <div className="d-flex flex-column">
        <span className="text-danger">${getAmount(captured - refunded)}</span>
        <Refund amount={refunded} />
      </div>
    );
  };

  const getChargeHistory = (startingAfter = "", startDate = "", endDate = "") =>
    dispatch(getCustomerHistory({ startingAfter, startDate, endDate }));

  useEffect(() => {
    getChargeHistory();
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <React.Fragment>
      {error && (
        <div className="auto-hide">
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        </div>
      )}

      <Card>
        {chargeHistory?.length
          ? (
          <CardHeader>
            <div className="row d-flex align-items-center justify-content-start">
              <div className="ml-3" style={{ width: "250px" }}>
                <Flatpickr
                  className="form-control rounded-pill"
                  placeholder="Select Date Range"
                  value={dates}
                  options={{
                    mode: "range",
                    dateFormat: DATE_PICKR,
                    defaultDate: dates,
                  }}
                  onClose={(val) => {
                    if (val.length > 0) {
                      getChargeHistory("", moment.utc(new Date(val[0])).unix(), moment.utc(new Date(val[1])).unix());
                      setDates(val);
                    }
                  }}
                />
              </div>
            </div>
          </CardHeader>
            )
          : null}
        <CardBody>
          {chargeHistory?.length ? (
            <>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {tableHeaders.map((header, index) => (
                        <th className={index ? "text-center" : ""} key={`header-charge-history ${index}`}>
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {chargeHistory?.map((item, key) => (
                      <tr key={"_charge_history_customer_" + key} className="mt-3 mb-3">
                        <td>
                          <Amount charge={item} />
                        </td>
                        <td className="text-center">
                          {moment(moment.utc(item?.created * 1000))
                            .utcOffset(moment().utcOffset() / 60)
                            .format(DATE_TIME)}
                        </td>
                        <td className="text-center">
                          {item?.description}
                          {item?.failure_message
                            ? (
                            <span className="ml-2">
                              {"("}
                              <span id={`error-${key}`} style={{ color: "red" }}>
                                Failure
                              </span>
                              {")"}
                              <CustomTooltip target={`error-${key}`} content={item.failure_message} />
                            </span>
                              )
                            : null}
                        </td>
                        <td className="text-center">
                          {item?.receipt_url && (
                            <a href={item.receipt_url} target="_blank" rel="noopener noreferrer">
                              <i className="bx bx-xs bx-link-external"></i>
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {hasMore && (
                <button
                  className="mt-2 btn btn-primary btn-block"
                  type="submit"
                  onClick={() => getChargeHistory(chargeHistory[chargeHistory.length - 1].id)}
                >
                  Load More
                </button>
              )}
            </>
          ) : null}
          {chargeHistory?.length === 0 && !loading
            ? (
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Records Found</h1>
              </div>
            </div>
              )
            : null}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ChargeHistory;
