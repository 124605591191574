import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";
import "flatpickr/dist/themes/dark.css";
// actions
import { setAgencyProfitModal, setPreloader, updateAgencyProfit } from "../../../store/actions";

const EditAgencyProfitModal = (props) => {
  const toggleModal = () => props.setAgencyProfitModal(!props.Settings.agencyProfitModalIsOpen);
  useEffect(() => {
    props.setPreloader(props.Settings.loading);
  }, [props.Settings.loading]);
  const formik = useFormik({
    initialValues: {
      agencyProfit: props?.agency?.agency_profit || "",
    },
    validate: (values) => {
      const errors = {};
      if (!parseInt(values.agencyProfit)) errors.agencyProfit = "Not a valid value for agency profit";
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (values.agencyProfit !== "" && values.agencyProfit !== props?.agency?.agency_profit)
        props.updateAgencyProfit({ data: { agency_profit: values.agencyProfit }, id: props.agency?.id });
      else toggleModal();
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.Settings.agencyProfitModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Edit Agency Profit</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.Settings.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.Settings.error}
            </Alert>
          )}
          {formik.errors.agencyProfit ? <small className="text-danger m-1">{formik.errors.agencyProfit}</small> : null}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Col className="my-1">
              <Label>Agency Profit Percentage</Label>
              <FormGroup className="select2-container w-100">
                <Input
                  name="agencyProfit"
                  type="number"
                  value={formik.values.agencyProfit}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Col>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { Settings } = state;
  return { Settings };
};

export default connect(mapStateToProps, {
  setAgencyProfitModal,
  setPreloader,
  updateAgencyProfit,
})(EditAgencyProfitModal);
