import React, { useEffect } from "react";
import { connect } from "react-redux";
import { pick, values, uniqBy, merge } from "lodash";
import { Col, Label, FormGroup } from "reactstrap";
import Select from "react-select";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import renderErrors from "./RenderErrors";
import { getUserInfo, RenderIf, getWarehouseName, adjustDate } from "../../../utils/common";
import { USER_ROLES } from "../../../constants";
import { fetchAllWarehouses } from "../../../store/actions";

const WarehouseLabelReportForm = (props) => {
  const { formik } = props;
  const cols = ["start_date", "end_date"];
  const { wareHouses, associatedWarehouses } = props.WareHouses;
  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;

  const fetchAccounts = () =>
    uniqBy(
      wareHouses?.data?.reduce((arr, { AccountWarehouse }) => {
        arr.push(...AccountWarehouse);
        return arr;
      }, []),
      "id",
    );

  const fetchWarehouses = (accountId) =>
    wareHouses?.data?.filter(({ AccountWarehouse }) =>
      AccountWarehouse?.find(({ account_warehouses: accWh }) => accWh.account_id === accountId),
    );

  useEffect(() => {
    getUserInfo()?.account_id && props.fetchAllWarehouses(merge(!isAdmin && { associated: true }));
  }, []);

  return (
    <>
      <FormGroup className="select2-container w-100">
        <Col className="my-1">
          <RenderIf isTrue={isAdmin}>
            <Label>Select Account {renderErrors(formik, ["account"])}</Label>
            <Select
              name="account"
              value={formik.values.account}
              onChange={(e) => {
                formik.setFieldValue("account", e);
                formik.setFieldValue("warehouse", "");
              }}
              options={fetchAccounts()?.map(
                (tenant) => ({ value: tenant.id, label: tenant.email, key: tenant.id }),
                [{ label: "All Accounts", value: "" }],
              )}
              classNamePrefix="select2-selection"
              height={{ maxHeight: "250px" }}
            />
          </RenderIf>
        </Col>
        <Col className="my-1">
          <Label className="mt-3">Select Warehouse {renderErrors(formik, ["warehouse"])}</Label>
          <Select
            name="warehouse"
            value={formik.values.warehouse}
            onChange={(e) => formik.setFieldValue("warehouse", e)}
            options={(isAdmin ? fetchWarehouses(formik.values?.account?.value) : associatedWarehouses?.data)?.map(
              (wh) => ({ value: wh.id, label: getWarehouseName(wh), key: wh.id }),
              [{ label: "All Warehouses", value: "" }],
            )}
            classNamePrefix="select2-selection"
            height={{ maxHeight: "150px" }}
          />
        </Col>
      </FormGroup>
      <Col className="my-1">
        <Label>Select Date Range</Label>
        <div className="w-100">
          <Flatpickr
            className="form-control"
            placeholder="Select Date Range"
            value={values(pick(formik.values, cols))}
            options={{ mode: "range" }}
            onClose={(val) => {
              const newDates = val.map(adjustDate);
              if (newDates.length > 1) {
                formik.setFieldValue("start_date", newDates[0]);
                formik.setFieldValue("end_date", newDates[1]);
              }
            }}
          />
        </div>
        {renderErrors(formik, cols)}
      </Col>
    </>
  );
};

const mapStateToProps = (state) => {
  const { WareHouses, Settings } = state;
  return { WareHouses, Settings };
};

export default connect(mapStateToProps, { fetchAllWarehouses })(WarehouseLabelReportForm);
