export const CREATE_SUPPLIER_ORDER = "CREATE_SUPPLIER_ORDER";
export const CREATE_SUPPLIER_ORDER_SUCCESS = "CREATE_SUPPLIER_ORDER_SUCCESS";
export const API_ERROR = "SUPPLIER_ORDER_ERROR";
export const UPDATE_TRACKING_INFO = "UPDATE_TRACKING_INFO";
export const UPDATE_TRACKING_INFO_SUCCESS = "UPDATE_TRACKING_INFO_SUCCESS";
export const SET_TRACKING_INFO_MODAL = "SET_TRACKING_INFO_MODAL";
export const RESET_STATE = "RESET_SUPPLIER_ORDER_STATE";
export const DELETE_SUPPLIER_ORDER = "DELETE_SUPPLIER_ORDER";
export const DELETE_SUPPLIER_ORDER_SUCCESS = "DELETE_SUPPLIER_ORDER_SUCCESS";
export const MARK_AS_IGNORE = "MARK_AS_IGNORE";
export const MARK_AS_IGNORE_SUCCESS = "MARK_AS_IGNORE_SUCCESS";
export const SET_EDIT_SUPPLIER_ORDER_MODAL = "SET_EDIT_SUPPLIER_ORDER_MODAL";
export const UPDATE_SUPPLIER_ORDER = "UPDATE_SUPPLIER_ORDER";
export const UPDATE_SUPPLIER_ORDER_SUCCESS = "UPDATE_SUPPLIER_ORDER_SUCCESS";
export const FETCH_ALL_RETURN_LABEL_HISTORY = "FETCH_ALL_RETURN_LABEL_HISTORY";
export const FETCH_ALL_RETURN_LABEL_HISTORY_SUCCESS = "FETCH_ALL_RETURN_LABEL_HISTORY_SUCCESS";
export const FETCH_RETURN_LABEL_HISTORY = "FETCH_RETURN_LABEL_HISTORY";
export const FETCH_RETURN_LABEL_HISTORY_SUCCESS = "FETCH_RETURN_LABEL_HISTORY_SUCCESS";
