import { API_ERROR, FETCH_AO_TASKS, FETCH_AO_TASKS_SUCCESS } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
};

const AOTasks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AO_TASKS:
      state = { ...state, loading: true, success: "", error: " ", aoTasks: undefined, aoGraph: undefined };
      break;
    case FETCH_AO_TASKS_SUCCESS:
      state = { ...state, loading: false };
      state[action.payload.type] = action.payload.data;
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      break;
  }
  return state;
};

export default AOTasks;
