import * as types from "./actionTypes";
const {
  FETCH_SOURCE_ORDERS_SUCCESS,
  FETCH_SOURCE_ORDERS,
  FETCH_SOURCE_ORDER_DETAIL,
  FETCH_SOURCE_ORDER_DETAIL_SUCCESS,
  SET_PROCESSING_ITEMS,
  REFRESH_SOURCE_ORDER,
  SET_SELECTED_ORDERS,
  SET_ORDERS_BULK_UPDATE_MODAL,
  ORDERS_BULK_UPDATE,
  SET_CHANGED_WAREHOUSE,
  ORDERS_BULK_UPDATE_SUCCESS,
  SET_MARK_ISSUE_MODAL,
  SET_CANCEL_MARKET_PLACE_ORDER_MODAL,
  UPDATE_SOURCE_ORDERS,
  UPDATE_SOURCE_ORDERS_SUCCESS,
  SET_ORDER_LOG_MODAL,
  SET_ASSIGN_OUTBOUND_MODAL,
  SET_ASSIGN_INVENTORY_MODAL,
  ASSIGN_INVENTORY_TO_ORDER,
  ASSIGN_INVENTORY_TO_ORDER_SUCCESS,
  API_ERROR,
  UPDATE_SOURCE_ADDRESS,
  UPDATE_SOURCE_ADDRESS_SUCCESS,
  SET_SHIPPING_DETAIL_MODAL,
  SET_ORDER_STATUS_MODAL,
  SET_CHANGE_WAREHOUSE_MODAL,
  FETCH_MARKETPLACE_STATUS,
  FETCH_MARKETPLACE_STATUS_SUCCESS,
  FETCH_NEXT_ORDER,
  FETCH_NEXT_ORDER_SUCCESS,
  CANCEL_MARKETPLACE_ORDER,
  CANCEL_MARKETPLACE_ORDER_SUCCESS,
  FETCH_LOCK_STATUS,
  FETCH_LOCK_STATUS_SUCCESS,
  REQUEST_SOURCE_ORDER_REPORT,
  REQUEST_SOURCE_ORDER_REPORT_SUCCESS,
  REQUEST_VA_PERFORMANCE_REPORT,
  REQUEST_VA_PERFORMANCE_REPORT_SUCCESS,
  SET_EXPORT_ORDER_MODAL,
  SET_CURRENT_USERS,
  REQUEST_ORDER_PROCESSOR_REPORT,
  REQUEST_ORDER_PROCESSOR_REPORT_SUCCESS,
  SET_PAYMENT_REPORT_MODAL,
  REQUEST_AVAILABLE_PAYMENT_REPORTS,
  REQUEST_AVAILABLE_PAYMENT_REPORTS_SUCCESS,
  REQUEST_PAYMENT_REPORT,
  REQUEST_PAYMENT_REPORT_SUCCESS,
  SET_ORDER_IMPORT_MODAL,
  SET_ORDER_UPLOAD_MODAL,
  IMPORT_ORDER_MANUALLY,
  IMPORT_ORDER_MANUALLY_SUCCESS,
  UPLOAD_ORDER_MANUALLY,
  GET_FEED_STATUS,
  GET_FEED_STATUS_SUCCESS,
  UPLOAD_ORDER_MANUALLY_SUCCESS,
  UPLOAD_ORDER_MANUALLY_ERROR,
  CREATE_ORDER_NOTE,
  CREATE_ORDER_NOTE_SUCCESS,
  FETCH_PRODUCT_CATEGORY,
  FETCH_PRODUCT_CATEGORY_SUCCESS,
  UPLOAD_TRACKING_DETAILS,
  UPLOAD_TRACKING_DETAILS_SUCCESS,
  UPDATE_SOURCE_ORDER_STATUS,
  UPDATE_SOURCE_ORDER_STATUS_SUCCESS,
  CHANGE_ORDER_TYPE,
  CHANGE_ORDER_TYPE_SUCCESS,
  SET_SELECT_WAREHOUSE_MODAL,
  SEARCH_ALL_ORDERS,
  SEARCH_ALL_ORDERS_SUCCESS,
  SET_GENERATE_SHIPPING_LABEL_MODAL,
  GENERATE_SHIPPING_LABEL,
  GET_ITEMS_DIMENSIONS,
  GET_ITEMS_DIMENSIONS_SUCCESS,
  GET_SHIPPING_RATES,
  GET_SHIPPING_RATES_SUCCESS,
  GENERATE_SHIPPING_LABEL_SUCCESS,
  MANUAL_FULFILLMENT_COLLAPSED,
  UPLOAD_SOURCE_ITEMS_LABEL,
  UPLOAD_SOURCE_ITEMS_LABEL_SUCCESS,
  SET_EDIT_WH_COST_MODAL,
  SET_PL_DATA,
} = types;

export const setPlData = (payload) => ({ type: SET_PL_DATA, payload });

export const fetchSourceOrders = (params) => {
  return {
    type: FETCH_SOURCE_ORDERS,
    payload: { params },
  };
};

export const setCurrentUsers = (data) => {
  return {
    type: SET_CURRENT_USERS,
    payload: data,
  };
};

export const fetchSourceOrderDetail = (id) => {
  return {
    type: FETCH_SOURCE_ORDER_DETAIL,
    payload: { id },
  };
};

export const fetchSourceOrderDetailSuccess = (sourceOrder) => {
  return {
    type: FETCH_SOURCE_ORDER_DETAIL_SUCCESS,
    payload: sourceOrder,
  };
};

export const setTrackingUpload = (data) => {
  return {
    type: UPLOAD_TRACKING_DETAILS,
    payload: data,
  };
};

export const setTrackingUploadSuccess = (data) => {
  return {
    type: UPLOAD_TRACKING_DETAILS_SUCCESS,
    payload: data,
  };
};

export const setChangedWareHouse = (id) => {
  return {
    type: SET_CHANGED_WAREHOUSE,
    payload: id,
  };
};

export const fetchNextOrder = (id) => {
  return {
    type: FETCH_NEXT_ORDER,
    payload: { id },
  };
};

export const fetchNextOrderSuccess = (sourceOrder) => {
  return {
    type: FETCH_NEXT_ORDER_SUCCESS,
    payload: sourceOrder,
  };
};

export const fetchSourceOrdersSuccess = (orders) => {
  return {
    type: FETCH_SOURCE_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const setItemsForProcessing = (items) => {
  return {
    type: SET_PROCESSING_ITEMS,
    payload: items,
  };
};

export const changeOrderType = (data, id) => {
  return {
    type: CHANGE_ORDER_TYPE,
    payload: { data, id },
  };
};

export const changeOrderTypeSuccess = (res) => {
  return {
    type: CHANGE_ORDER_TYPE_SUCCESS,
    payload: res,
  };
};

export const setSelectWareHouseModal = (bool) => {
  return {
    type: SET_SELECT_WAREHOUSE_MODAL,
    payload: bool,
  };
};

export const manualFulfillmentCollapseIsOpen = (bool) => {
  return {
    type: MANUAL_FULFILLMENT_COLLAPSED,
    payload: bool,
  };
};

export const uploadSourceItemsLabel = (data) => {
  return {
    type: UPLOAD_SOURCE_ITEMS_LABEL,
    payload: data,
  };
};

export const uploadSourceItemsLabelSuccess = (res) => {
  return {
    type: UPLOAD_SOURCE_ITEMS_LABEL_SUCCESS,
    payload: res,
  };
};

export const setSelectedOrders = (id) => {
  return {
    type: SET_SELECTED_ORDERS,
    payload: id,
  };
};

export const setOrdersBulkUpdateModal = (bool) => {
  return {
    type: SET_ORDERS_BULK_UPDATE_MODAL,
    payload: bool,
  };
};

export const setOrdersImportModal = (bool) => {
  return {
    type: SET_ORDER_IMPORT_MODAL,
    payload: bool,
  };
};

export const setChangeWareHouseModal = (bool) => {
  return {
    type: SET_CHANGE_WAREHOUSE_MODAL,
    payload: bool,
  };
};

export const setUploadOrdersModal = (bool) => {
  return {
    type: SET_ORDER_UPLOAD_MODAL,
    payload: bool,
  };
};

export const importOrderManually = (orderId, storeId) => {
  return {
    type: IMPORT_ORDER_MANUALLY,
    payload: { orderId, storeId },
  };
};

export const importOrderManuallySuccess = (data) => {
  return {
    type: IMPORT_ORDER_MANUALLY_SUCCESS,
    payload: data,
  };
};

export const uploadOrdersFile = (data) => {
  return {
    type: UPLOAD_ORDER_MANUALLY,
    payload: data,
  };
};

export const uploadOrdersFileSuccess = (message) => {
  return {
    type: UPLOAD_ORDER_MANUALLY_SUCCESS,
    payload: message,
  };
};

export const uploadOrdersFileError = (err) => {
  return {
    type: UPLOAD_ORDER_MANUALLY_ERROR,
    payload: err,
  };
};

export const bulkUpdateOrders = (data) => {
  return {
    type: ORDERS_BULK_UPDATE,
    payload: { data },
  };
};

export const bulkUpdateOrdersSuccess = () => {
  return {
    type: ORDERS_BULK_UPDATE_SUCCESS,
    payload: {},
  };
};

export const refreshSourceOrderDetail = () => {
  return {
    type: REFRESH_SOURCE_ORDER,
    payload: {},
  };
};

export const generateShippingLabel = (params) => {
  return {
    type: GENERATE_SHIPPING_LABEL,
    payload: params,
  };
};

export const generateShippingLabelSuccess = () => {
  return { type: GENERATE_SHIPPING_LABEL_SUCCESS };
};

export const getShippingRates = (data) => {
  return {
    type: GET_SHIPPING_RATES,
    payload: data,
  };
};

export const getShippingRatesSuccess = (res) => {
  return { type: GET_SHIPPING_RATES_SUCCESS, payload: res };
};

export const getItemsDimensions = ({ dimensions }) => {
  return { type: GET_ITEMS_DIMENSIONS, payload: { dimensions } };
};

export const getItemsDimensionsSuccess = (res) => {
  return { type: GET_ITEMS_DIMENSIONS_SUCCESS, payload: res };
};

export const setMarkIssueModal = (bool) => {
  return {
    type: SET_MARK_ISSUE_MODAL,
    payload: bool,
  };
};

export const setCancelMarketPlaceOrderModal = (bool) => {
  return {
    type: SET_CANCEL_MARKET_PLACE_ORDER_MODAL,
    payload: bool,
  };
};

export const updateSourceOrder = ({ id, data, showSuccess }) => {
  return {
    type: UPDATE_SOURCE_ORDERS,
    payload: { id, data, showSuccess },
  };
};

export const updateSourceOrderSuccess = (res) => {
  return {
    type: UPDATE_SOURCE_ORDERS_SUCCESS,
    payload: res,
  };
};

export const setOrderLogModal = (bool) => {
  return {
    type: SET_ORDER_LOG_MODAL,
    payload: bool,
  };
};

export const setAssignInventoryModal = (bool) => {
  return {
    type: SET_ASSIGN_INVENTORY_MODAL,
    payload: bool,
  };
};

export const setAssignOutboundModal = (bool) => {
  return {
    type: SET_ASSIGN_OUTBOUND_MODAL,
    payload: bool,
  };
};

export const assignInventory = (data) => {
  return {
    type: ASSIGN_INVENTORY_TO_ORDER,
    payload: data,
  };
};

export const assignInventorySuccess = () => {
  return { type: ASSIGN_INVENTORY_TO_ORDER_SUCCESS };
};

export const setGenerateShippingLabelModal = (bool) => {
  return {
    type: SET_GENERATE_SHIPPING_LABEL_MODAL,
    payload: bool,
  };
};

export const updateAddress = ({ id, data }) => {
  return {
    type: UPDATE_SOURCE_ADDRESS,
    payload: { id, data },
  };
};

export const updateAddressSuccess = () => {
  return {
    type: UPDATE_SOURCE_ADDRESS_SUCCESS,
    payload: {},
  };
};

export const setShippingDetailModal = (bool) => {
  return {
    type: SET_SHIPPING_DETAIL_MODAL,
    payload: bool,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const setOrderStatusModal = (bool) => {
  return {
    type: SET_ORDER_STATUS_MODAL,
    payload: bool,
  };
};

export const fetchMarketPlaceStatus = ({ id, storeName }) => {
  return {
    type: FETCH_MARKETPLACE_STATUS,
    payload: { id, storeName },
  };
};

export const fetchMarketPlaceStatusSuccess = () => {
  return {
    type: FETCH_MARKETPLACE_STATUS_SUCCESS,
    payload: {},
  };
};

export const cancelMarketplaceOrder = ({ id, cancel_reason: cancelReason }) => {
  return {
    type: CANCEL_MARKETPLACE_ORDER,
    payload: { id, cancel_reason: cancelReason },
  };
};

export const cancelMarketplaceOrderSuccess = (res) => {
  return {
    type: CANCEL_MARKETPLACE_ORDER_SUCCESS,
    payload: res,
  };
};

export const getFeedStatus = (orderId) => {
  return {
    type: GET_FEED_STATUS,
    payload: { orderId },
  };
};

export const getFeedStatusSuccess = (res) => {
  return {
    type: GET_FEED_STATUS_SUCCESS,
    payload: res,
  };
};

export const fetchLockStatus = (id) => {
  return {
    type: FETCH_LOCK_STATUS,
    payload: { id },
  };
};

export const fetchLockStatusSuccess = (status) => {
  return {
    type: FETCH_LOCK_STATUS_SUCCESS,
    payload: status,
  };
};

export const requestSourceOrderReport = (params) => {
  return {
    type: REQUEST_SOURCE_ORDER_REPORT,
    payload: params,
  };
};

export const requestSourceOrderReportSuccess = () => {
  return {
    type: REQUEST_SOURCE_ORDER_REPORT_SUCCESS,
    payload: {},
  };
};

export const requestVAPerformanceReport = (params) => {
  return {
    type: REQUEST_VA_PERFORMANCE_REPORT,
    payload: params,
  };
};

export const requestVAPerformanceReportSuccess = (data) => {
  return {
    type: REQUEST_VA_PERFORMANCE_REPORT_SUCCESS,
    payload: data,
  };
};

export const requestOrderProcessorReport = (params) => {
  return {
    type: REQUEST_ORDER_PROCESSOR_REPORT,
    payload: params,
  };
};

export const requestOrderProcessorReportSuccess = (data) => {
  return {
    type: REQUEST_ORDER_PROCESSOR_REPORT_SUCCESS,
    payload: data,
  };
};

export const requestAvailablePaymentReports = (params) => {
  return {
    type: REQUEST_AVAILABLE_PAYMENT_REPORTS,
    payload: params,
  };
};

export const requestAvailablePaymentReportsSuccess = (dates, extraCols, reports) => {
  return {
    type: REQUEST_AVAILABLE_PAYMENT_REPORTS_SUCCESS,
    payload: { dates, extraCols, reports },
  };
};

export const requestPaymentReport = (params) => {
  return {
    type: REQUEST_PAYMENT_REPORT,
    payload: params,
  };
};

export const requestPaymentReportSuccess = (data) => {
  return {
    type: REQUEST_PAYMENT_REPORT_SUCCESS,
    payload: data,
  };
};

export const setExportOrderModal = (bool, type) => {
  return {
    type: SET_EXPORT_ORDER_MODAL,
    payload: { bool, type },
  };
};

export const setPaymentReportModal = (bool) => {
  return {
    type: SET_PAYMENT_REPORT_MODAL,
    payload: { modal: bool },
  };
};

export const createOrderNote = ({ data, loading }) => {
  return {
    type: CREATE_ORDER_NOTE,
    payload: { data, loading },
  };
};

export const createOrderNoteSuccess = (res) => {
  return {
    type: CREATE_ORDER_NOTE_SUCCESS,
    payload: res,
  };
};

export const fetchProductCategory = (params) => {
  return {
    type: FETCH_PRODUCT_CATEGORY,
    payload: params,
  };
};

export const fetchProductCategorySuccess = (data) => {
  return {
    type: FETCH_PRODUCT_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const updateSourceOrderStatus = ({ id, data }) => {
  return {
    type: UPDATE_SOURCE_ORDER_STATUS,
    payload: { id, data },
  };
};

export const searchAllOrders = (params) => {
  return {
    type: SEARCH_ALL_ORDERS,
    payload: { params },
  };
};

export const searchAllOrdersSuccess = (res) => {
  return {
    type: SEARCH_ALL_ORDERS_SUCCESS,
    payload: res,
  };
};

export const updateSourceOrderStatusSuccess = (res) => {
  return {
    type: UPDATE_SOURCE_ORDER_STATUS_SUCCESS,
    payload: res,
  };
};

export const setEditWHCostModal = (res) => {
  return {
    type: SET_EDIT_WH_COST_MODAL,
    payload: res,
  };
};

export const togglePriceEventModal = () => ({ type: types.TOGGLE_PRICE_EVENTS_MODAL });
export const togglePriceEventEditModal = (payload) => ({ type: types.TOGGLE_PRICE_EVENTS_EDIT_MODAL, payload });

export const createPriceEvent = (payload) => ({ type: types.CREATE_PRICE_EVENT, payload });
export const createPriceEventSuccess = (payload) => ({ type: types.CREATE_PRICE_EVENT_SUCCESS, payload });

export const updatePriceEvent = (payload) => ({ type: types.UPDATE_PRICE_EVENT, payload });
export const updatePriceEventSuccess = (payload) => ({ type: types.UPDATE_PRICE_EVENT_SUCCESS, payload });

export const deletePriceEvent = (payload) => ({ type: types.DELETE_PRICE_EVENT, payload });
export const deletePriceEventSuccess = (payload) => ({ type: types.DELETE_PRICE_EVENT_SUCCESS, payload });

export const syncPriceEvent = (event, { isNew, isDeleted }) => ({
  type: types.SYNC_PRICE_EVENT,
  payload: { event, isNew, isDeleted },
});

export const acknowledgeOrder = (data) => ({
  type: types.ACKNOWLEDGE_ORDER,
  payload: data,
});

export const acknowledgeOrderSuccess = (res) => ({
  type: types.ACKNOWLEDGE_ORDER_SUCCESS,
  payload: res,
});
