import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Alert, Button, Col, Input, Label, Row } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { setAddProxyUserModel, createProxyUser, updateProxyUser, setPreloader } from "../../../store/actions";
import { isStrongPassword } from "../../../utils/common";
import CheckBox from "../../../components/Common/CheckBox";

const ProxyUserModel = (props) => {
  const [isShownPass, setIsShownPass] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const { setAddProxyUserModelIsOpen, error, loading } = useSelector((state) => state.ProxyUsers);
  const toggleModal = () => {
    props.setAddProxyUserModel(!setAddProxyUserModelIsOpen);
  };

  const initialValues = {
    email: props.type?.email || "",
    password: "",
    total_credits: props.type?.total_credits || "",
  };

  useEffect(() => {
    const isNew = props.type.modelType === "add";
    setIsNewPassword(isNew);
    setIsShownPass(false);
    Object.keys(initialValues).forEach((key) => {
      formik.setFieldTouched(key, false);
      if (isNew) formik.setFieldValue(key, "");
    });
    if (isNew) return;

    formik.setFieldValue("password", "");
    if (props.type.email) formik.setFieldValue("email", props.type.email);
    if (props.type.total_credits || props.type.total_credits === 0) {
      formik.setFieldValue("total_credits", props.type.total_credits);
    }
  }, [props.type]);

  useEffect(() => {
    props.setPreloader(loading);
  }, [loading]);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Field is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (isNewPassword) {
      if (!values.password) errors.password = "Field is Required";
      else if (!isStrongPassword(values.password))
        errors.password =
          "Password must contain at least one uppercase letter, one number, one special character and must be of 8 characters minimum";
    }

    if (values.total_credits === "" || isNaN(values.total_credits)) errors.total_credits = "Credit must be a number";

    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const data = { ...values };
      if (!isNewPassword) delete data.password;
      props[props.type.modelType === "add" ? "createProxyUser" : "updateProxyUser"](data);
    },
  });

  return (
    <Modal size="md" isOpen={setAddProxyUserModelIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.type.modelType === "add" ? "Add New Proxy User" : "Update Proxy User"}
        </h5>
        <button onClick={() => toggleModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      {error && (
        <div className="auto-hide">
          <Alert color="danger" role="alert">
            {/validation/i.test(error) ? "Email Already Exist!" : error}
          </Alert>
        </div>
      )}
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <Label>Proxy User Email*</Label>
              <div className="d-flex justify-content-between">
                <Input
                  name="email"
                  placeholder="Enter Email"
                  value={formik.values.email}
                  disabled={props.type.modelType === "update"}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.touched.email && formik.errors.email
                ? (
                <span className="text-danger mt-1">{formik.errors.email}</span>
                  )
                : null}
            </Col>
            <Col lg="12 mb-2">
              <Label>Enter Credits*</Label>
              <div className="d-flex justify-content-between">
                <Input
                  name="total_credits"
                  type="number"
                  placeholder="Enter Credits"
                  value={formik.values.total_credits}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.touched.total_credits && formik.errors.total_credits
                ? (
                <span className="text-danger mt-1">{formik.errors.total_credits}</span>
                  )
                : null}
            </Col>
            {props.type.modelType !== "add"
              ? (
              <Col lg="12 mb-2">
                <CheckBox
                  state={isNewPassword}
                  setState={setIsNewPassword}
                  isSwitch={true}
                  className="mt-2"
                  label="Update Password"
                />
              </Col>
                )
              : null}
            {isNewPassword
              ? (
              <Col lg="12 mb-2">
                <Label>Enter New Password*</Label>
                <div className="d-flex justify-content-between">
                  <Input
                    name="password"
                    type={isShownPass ? "text" : "password"}
                    placeholder="Enter new Password"
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <span className="input-group-append">
                    <span
                      className="input-group-text colorpicker-input-addon rounded-right"
                      onClick={() => {
                        setIsShownPass(!isShownPass);
                      }}
                    >
                      <i className="bx bx-xs bx-show mx-1 cursor-pointer"></i>
                    </span>
                  </span>
                </div>
                {formik.touched.password && formik.errors.password
                  ? (
                  <span className="text-danger mt-1">{formik.errors.password}</span>
                    )
                  : null}
              </Col>
                )
              : null}
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
    </Modal>
  );
};

const mapStatetoProps = (state) => {
  const { ProxyUsers } = state;
  return ProxyUsers;
};

export default connect(mapStatetoProps, {
  setAddProxyUserModel,
  createProxyUser,
  updateProxyUser,
  setPreloader,
})(ProxyUserModel);
