import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { chunk } from "lodash";

import avatar1 from "../../../assets/images/users/user.svg";
import profileImg from "../../../assets/images/profile-img.webp";

const WelcomeCard = ({ stats }) => {
  const chunks = chunk(stats, 3);
  return (
    <React.Fragment>
      <Card className="overflow-hidden mh-493">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="p-3 mt-4">
                <h5>Welcome Back !</h5>
                <p style={{ color: "#f6f6f6" }}>Ecom Circles</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  style={{ backgroundColor: "#f6f6f6" }}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <div>
                {chunks.map((chunk, key) => (
                  <Row key={key} className="py-2">
                    {chunk.map((item, key) => (
                      <Col xs="4" key={key} className="mb-4">
                        <h5 className="font-size-15">{item.description}</h5>
                        <p className="text-muted font-size-12 mb-0">{item.title}</p>
                      </Col>
                    ))}
                  </Row>
                ))}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeCard;
