import React from "react";
import "react-toggle/style.css";
import { connect } from "react-redux";
import { Alert } from "bootstrap";

const redirectToSettingsPageModal = (props) => {
  return (
    <>
      <div>
        <Alert color="success">
          <i className="bx bx-info-circle pr-2"></i>
          {"Redirecting..."}
        </Alert>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { Settings } = state;
  return { Settings };
};

export default connect(mapStateToProps, {})(redirectToSettingsPageModal);
