import request from "../restClient";
import { keys, merge, omitBy } from "lodash";
import { makeQP } from "../../utils/common";

const resource = "/users/accounts";
const uspsUrl = "/usps";

export const fetchAccountSettings = (params) =>
  request({ url: `${resource}` + makeQP(merge(params, { account_only: true })) });

export const fetchAccounts = () => request({ url: resource + makeQP({ fetch_group: true }) });

export const fetchDisputeLogs = (id) => request({ url: `/users/get_dispute_logs/${id}` });

export const whRetryCharges = (params) => request({ url: "/accounts/retry_wh_charges" + makeQP(params) });

export const fetchAbilities = () => request({ url: "/users/getAbilities" });

export const fetchFreeProxies = () => request({ url: "/all_proxies?free=true" });

export const fetchTenantToken = ({ email, qP }) => request({ url: `/tenant/assume_role/${email}` + makeQP(qP) });
export const refreshAuthToken = () => request({ url: "/users/refresh_token" });

export const updateAccountSettings = ({ data, id }) =>
  request({ url: id ? resource + "/" + id : resource, method: "PUT", body: data });

export const fetchAOBotStatusApi = () => request({ url: "/get_ao_bot_status" });

export const fetchCancelledSubscriptionsApi = () => request({ url: "/payment/subscriptions/canceled" });

export const fetchAgencies = () => request({ url: "/users/get_agencies" });

export const addAgencyChild = ({ data }) => request({ url: "/users/add_agency_client", method: "POST", body: data });

export const inviteToAgencyAPI = ({ data }) => request({ url: "/users/invite_to_agency", method: "POST", body: data });

export const addStampsCredsAPI = ({ data }) =>
  request({ url: `${uspsUrl}/validate_account`, method: "POST", body: data });

export const fetchStampsCredsAPI = () => request({ url: `${uspsUrl}/get_stamps_creds` });

export const deleteStampsCredsAPI = () => request({ url: `${uspsUrl}/delete_stamps_creds`, method: "DELETE" });

export const enableStampsCredsAPI = (payload) =>
  request({ url: `${uspsUrl}/enable_stamps_creds`, method: "PUT", body: payload });

export const unlinkAgency = ({ id }) => request({ url: `/users/unlink_agency/${id}`, method: "DELETE" });

export const verifyAgency = ({ token }) => request({ url: `/users/verify_agency/${token}` });

export const checkAOServiceEligibility = (data) =>
  request({ url: "/accounts/ao_service_eligibility", method: "PUT", body: data });

export const submitCancelFeedback = (data) => request({ url: "/submit_cancel_feedback", method: "POST", body: data });

export const fetchAvailableMatchesCount = async (params) => {
  const url =
    "/api/walmart_matches/available_matches_count?" +
    Object.keys(params)
      .map((key) =>
        Array.isArray(params[key]) || params[key]?.constructor === Object
          ? `${key}=${encodeURIComponent(JSON.stringify(params[key]))}`
          : `${key}=${params[key]}`,
      )
      .join("&");
  return await request({ url });
};

export const requestMatches = (data) => request({ url: "/api/matches_requests", method: "POST", body: data });

export const fetchActivityLogs = async (params) => {
  const queryParams = keys(params).length
    ? keys(omitBy(params, (val) => !val)).reduce(
      (str, key, i) => str + `${i ? "&" : ""}${key}=${encodeURIComponent(params[key])}`,
      "?",
    )
    : "";
  const url = "/logs" + queryParams;
  return await request({ url });
};

export const fetchAllReturnLabels = async (params) => {
  const url =
    "/return_label_history" +
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url });
};

export const fetchNotifications = ({ id, offset }) => request({ url: `/notifications/${id}?offset=${offset}` });

export const createNotification = (body) => request({ url: "/notifications/", method: "POST", body });

export const deleteNotification = (id) => request({ url: `/notifications/${id}`, method: "DELETE" });

export const fetchStoreWiseStats = (params) => request({ url: "/top_store_wise_stats" + makeQP(params) });

export const fetchTenantWiseStats = (params) => request({ url: "/top_tenant_wise_stats" + makeQP(params) });

export const updateAgencyProfit = ({ data, id }) =>
  request({ url: `/users/agency_profit/${id}`, method: "PUT", body: data });

export const filterAllTenants = (params) => request({ url: "/get_tenants_data" + makeQP(params) });

export const getPaymentFailedUsers = () => request({ url: "/payment_failed_users" });

export const getCanceledUsers = () => request({ url: "/get_canceled_users" });

export const getStatusesCount = () => request({ url: "/get_statuses_count" });

export const getDropDownData = (params) => request({ url: "/get_tenants_dropdown" + makeQP(params) });

export const getAgencyDropdown = () => request({ url: "/get_agency_dropdown" });

export const fetchAgencyClients = () => request({ url: "/get_agency_clients" });

export const updateAgencyNameAPI = ({ id, body }) =>
  request({ url: `/account/agency_name/${id}`, method: "PUT", body });

export const checkWalmartOffers = (body) => request({ url: "/check_walmart_offers", method: "POST", body });
