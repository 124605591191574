import React, { useRef, useEffect } from "react";
import { values, keys } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserInfo,
  toPascalCase,
  isSelectedRow,
  isActiveAccount,
  RenderIf,
  getProductURL,
} from "../../../utils/common";
import { BULK_SELECT_TYPES, DATE_FORMATS } from "../../../constants";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";

// Import Actions
import {
  bulkDeleteListings,
  setSelectedListings,
  setFilteredListings,
  setErroredListingsUpdateModal,
} from "../../../store/actions";

import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import ReactTable from "../../../components/Common/Table";
import moment from "moment-timezone";

import ResolveErrorModal from "./ResolveErrorModal";
import CustomTooltip from "../../../components/Common/CustomTooltip";

const { DATE_TIME } = DATE_FORMATS;

const ErroredListing = (props) => {
  const dispatch = useDispatch();
  const listing = useRef(null);

  const { listingsPage, storeFront, ListingIds, selectAllListings, setBulkSelectType, isSingleListing, allStoresObj } = props;
  const { listings, selectedListings, filteredListings, erroredListingsUpdateModalIsOpen } = useSelector(
    (state) => state.Listings,
  );
  const { listing: singleListing, resolvable_error_types: types } = useSelector((state) => state.Supplier);

  const ErroredHeader = ["SKU", "Item ID/ASIN", "Error Type", "Brand", "Created At", "Action"];
  const RESOLVE_ERROR_TYPES = keys(isSingleListing ? types : listings?.resolvable_error_types).reduce((acc, x) => {
    acc[x] = x;
    return acc;
  }, {});

  function handleDeleteListing ({ partner_id: partnerId, sku }) {
    dispatch(
      bulkDeleteListings({
        ...(isSingleListing ? { singleListing: true } : {}),
        delete_type: "current_page",
        platform: storeFront.marketplace,
        listings_page: listingsPage.value,
        skus_data: [{ partner_id: partnerId, skus: [sku] }],
        va_id: getUserInfo()?.id,
      }),
    );
  }

  useEffect(() => {
    if (isSingleListing) dispatch(setFilteredListings(keys(singleListing).length ? [singleListing] : []));
    else dispatch(setFilteredListings(listings?.results));
  }, [isSingleListing, listings?.results, singleListing]);

  return (
    <React.Fragment>
      {erroredListingsUpdateModalIsOpen && (
        <ResolveErrorModal resolveErrorTypes={RESOLVE_ERROR_TYPES} listing={listing.current} storeFront={storeFront} />
      )}
      {filteredListings?.length
        ? (
        <ReactTable
          tableHeader={
            <>
              <th />
              <RenderIf isTrue={isActiveAccount(storeFront)}>
                <th className="vw-checkbox">
                  <div className="custom-control custom-checkbox change-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={ListingIds?.length > 0 && ListingIds?.length === selectedListings?.length}
                      onChange={() => false}
                    />
                    <label
                      className="custom-control-label"
                      onClick={() => {
                        if (ListingIds?.length === selectedListings?.length) {
                          setBulkSelectType(BULK_SELECT_TYPES.current_page);
                          dispatch(setSelectedListings([]));
                        } else selectAllListings();
                      }}
                    >
                      &nbsp;
                    </label>
                  </div>
                </th>
              </RenderIf>
              {ErroredHeader.filter((header) => isActiveAccount(storeFront) || header !== "Action").map(
                (header, index) => (
                  <th key={`table-header-errored ${index}`}>{header}</th>
                ),
              )}
            </>
          }
          tableBody={
            <>
              {filteredListings?.map((item, key) => (
                <tr
                  key={"_listings_pending_" + key}
                  className={`mt-3 mb-3${isSelectedRow(selectedListings, item) ? " selected-tr-bg-clr" : ""}`}
                >
                  <td />
                  <RenderIf isTrue={isActiveAccount(storeFront)}>
                    <td>
                      <div className="custom-control custom-checkbox change-checkbox mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={!!selectedListings?.find((x) => x.id === item?.id)}
                          onChange={() => false}
                        />
                        <label
                          className="custom-control-label mt-2"
                          onClick={() => {
                            setBulkSelectType(BULK_SELECT_TYPES.current_page);
                            if (selectedListings?.find((x) => x.id === item?.id)) {
                              dispatch(setSelectedListings(selectedListings?.filter((x) => item.id !== x.id)));
                            } else {
                              dispatch(
                                setSelectedListings([
                                  ...selectedListings,
                                  {
                                    id: item.id,
                                    partner_id: item.partner_id,
                                    sku: item.sku,
                                  },
                                ]),
                              );
                            }
                          }}
                        />
                      </div>
                    </td>
                  </RenderIf>
                  <td>
                    {item?.sku}
                    <CopyToClipBoard text={item?.sku} />
                  </td>
                  <td>
                    <a
                      href={getProductURL(item, storeFront.marketplace, allStoresObj[item.partner_id])}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary mb-1"
                    >
                      {item.item_id}
                    </a>
                    <CopyToClipBoard text={item?.item_id} />
                  </td>
                  <td>
                    {item?.error_type
                      ? (
                      <div className="d-flex align-items-center">
                        <i id={`__errored_listing_log__${key}`} className="bx bx-info-circle mr-1 danger" />
                        {item?.error_log
                          ? (
                          <CustomTooltip
                            placement="top"
                            content={item?.error_log}
                            target={`__errored_listing_log__${key}`}
                          />
                            )
                          : null}
                        {toPascalCase(item.error_type)}
                        <RenderIf
                          isTrue={
                            values(RESOLVE_ERROR_TYPES).some((type) => item.error_type?.includes(type)) &&
                            isActiveAccount(storeFront)
                          }
                        >
                          <span
                            className="ml-1 text-primary cursor-pointer"
                            onClick={() => {
                              dispatch(setErroredListingsUpdateModal(!erroredListingsUpdateModalIsOpen));
                              listing.current = item;
                            }}
                          >
                            {" "}
                            Resolve
                          </span>
                        </RenderIf>
                      </div>
                        )
                      : null}
                  </td>
                  <td>{item?.brand ? toPascalCase(item?.brand) : "N/A"}</td>
                  <td>{(item?.created_at && moment(item?.created_at).format(DATE_TIME)) || ""}</td>
                  <RenderIf isTrue={isActiveAccount(storeFront)}>
                    <td>
                      <ConfirmDialogIcon
                        icon={{
                          title: "Delete Listing",
                          className: "bx bx-sm text-danger bx-trash",
                        }}
                        msg={<p>To Delete this Listing. This action cannot be undone</p>}
                        onConfirm={() => handleDeleteListing(item)}
                      />
                    </td>
                  </RenderIf>
                </tr>
              ))}
            </>
          }
        />
          )
        : (
        <h2 className="text-center">No Records Found</h2>
          )}
    </React.Fragment>
  );
};
export default ErroredListing;
