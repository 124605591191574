import {
  FETCH_PLAN_GROUPS,
  FETCH_PLAN_GROUPS_SUCCESS,
  CREATE_PLAN,
  CREATE_PLAN_SUCCESS,
  CREATE_PLAN_GROUP,
  CREATE_PLAN_GROUP_SUCCESS,
  SET_ADD_PLAN_MODAL,
  SET_PLAN_GROUP_MODAL,
  SET_VIEW_PLANS_MODAL,
  API_ERROR,
  UPDATE_PLAN_GROUP,
  UPDATE_PLAN_GROUP_SUCCESS,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  UPDATE_GROUP_AND_PLAN,
  UPDATE_GROUP_AND_PLAN_SUCCESS,
  SET_EDIT_PLAN_GROUP_MODAL,
  SET_EDITABLE_PLAN,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
} from "./actionTypes";

export const fetchPlanGroups = (bool) => ({
  type: FETCH_PLAN_GROUPS,
  payload: { bool },
});

export const fetchPlanGroupsSuccess = (res) => ({
  type: FETCH_PLAN_GROUPS_SUCCESS,
  payload: res,
});

export const createPlan = (data) => ({
  type: CREATE_PLAN,
  payload: { data },
});

export const createPlanSuccess = (res) => ({
  type: CREATE_PLAN_SUCCESS,
  payload: res,
});

export const updatePlan = (id, data) => ({
  type: UPDATE_PLAN,
  payload: { id, data },
});

export const updatePlanSuccess = (data) => ({
  type: UPDATE_PLAN_SUCCESS,
  payload: data,
});

export const createPlanGroup = (data) => ({
  type: CREATE_PLAN_GROUP,
  payload: data,
});

export const createPlanGroupSuccess = (res) => ({
  type: CREATE_PLAN_GROUP_SUCCESS,
  payload: res,
});

export const updatePlanGroup = (id, data) => ({
  type: UPDATE_PLAN_GROUP,
  payload: { id, data },
});

export const updatePlanGroupSuccess = (res) => ({
  type: UPDATE_PLAN_GROUP_SUCCESS,
  payload: res,
});

export const updateGroupAndPlan = (id, body) => ({
  type: UPDATE_GROUP_AND_PLAN,
  payload: { id, body },
});

export const setEditPlanGroupModal = (bool) => ({
  type: SET_EDIT_PLAN_GROUP_MODAL,
  payload: bool,
});

export const updateGroupAndPlanSuccess = (res) => ({
  type: UPDATE_GROUP_AND_PLAN_SUCCESS,
  payload: res,
});

export const setAddPlanModal = (bool) => {
  return {
    type: SET_ADD_PLAN_MODAL,
    payload: bool,
  };
};

export const setEditablePlan = (item) => {
  return {
    type: SET_EDITABLE_PLAN,
    payload: item,
  };
};

export const setPlanGroupModal = (bool) => {
  return {
    type: SET_PLAN_GROUP_MODAL,
    payload: bool,
  };
};

export const setViewPlansModal = (bool) => {
  return {
    type: SET_VIEW_PLANS_MODAL,
    payload: bool,
  };
};

export const deletePlan = (planId) => {
  return {
    type: DELETE_PLAN,
    payload: { planId },
  };
};

export const deletePlanSuccess = (res) => ({
  type: DELETE_PLAN_SUCCESS,
  payload: res,
});

export const deleteGroup = (groupId) => {
  return {
    type: DELETE_GROUP,
    payload: { groupId },
  };
};

export const deleteGroupSuccess = (res) => ({
  type: DELETE_GROUP_SUCCESS,
  payload: res,
});

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
