export const FETCH_MATCHES = "FETCH_MATCHES";
export const FETCH_MATCHES_SUCCESS = "FETCH_MATCHES_SUCCESS";
export const FETCH_MATCHES_ERROR = "FETCH_MATCHES_ERROR";

export const FETCH_FILTERS = "FETCH_FILTERS";
export const SET_FILTER = "SET_FILTER";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";

export const FETCH_SAVED_FILTERS = "FETCH_SAVED_FILTERS";
export const FETCH_SAVED_FILTERS_SUCCESS = "FETCH_SAVED_FILTERS_SUCCESS";
export const SET_SAVED_FILTERS = "SET_SAVED_FILTERS";
export const ADD_NEW_SAVED_FILTER = "ADD_NEW_SAVED_FILTER";
export const ADD_NEW_SAVED_FILTER_SUCCESS = "ADD_NEW_SAVED_FILTER_SUCCESS";
export const UPDATE_SAVED_FILTER = "UPDATE_SAVED_FILTER";
export const UPDATE_SAVED_FILTER_SUCCESS = "UPDATE_SAVED_FILTER_SUCCESS";

export const ADD_MISMATCH_PRODUCT = "ADD_MISMATCH_PRODUCT";
export const ADD_MISMATCH_PRODUCT_SUCCESS = "ADD_MISMATCH_PRODUCT_SUCCESS";

export const ADD_LISTING_PRODUCT = "ADD_LISTING_PRODUCT";
export const ADD_LISTING_PRODUCT_SUCCESS = "ADD_LISTING_PRODUCT_SUCCESS";

export const CHECK_PRODUCT_ELIGIBILITY = "CHECK_PRODUCT_ELIGIBILITY";
export const CHECK_PRODUCT_ELIGIBILITY_SUCCESS = "CHECK_PRODUCT_ELIGIBILITY_SUCCESS";

export const SET_HIDE_ROW = "SET_HIDE_ROW";

export const SET_SALES_RANK = "SET_SALES_RANK";

export const ADD_COLUMN_FILTER = "ADD_COLUMN_FILTER";
export const REMOVE_COLUMN_FILTER = "REMOVE_COLUMN_FILTER";

export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

export const SET_MARKETPLACE = "SET_MARKETPLACE";

export const API_ERROR = "API_ERROR";
