import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SeeMoreText from "../../../../components/Common/SeeMoreText";
import { Button, Row, Col, Input, Label, Card, Alert } from "reactstrap";
import Modal from "../../../../components/Common/Modal";
import { RenderIf } from "../../../../utils/common";
import Dropzone from "react-dropzone";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

// Actions
import { setItemActionsModal, fetchWHInventoryAction } from "../../../../store/actions";
import { keys, merge } from "lodash";
import { DATE_FORMATS } from "../../../../constants";

const getOnlyDate = (date) => {
  if (!date) date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

const { DATE_PICKR } = DATE_FORMATS;
const ItemActionsModal = ({ itemDetail, actionType, tab, statusMapping }) => {
  const dispatch = useDispatch();
  const { setItemActionsModalIsOpen } = useSelector((state) => state.WareHouses);
  const isShipmentWiseTab = tab === 2;

  const toggleItemActionsModal = () => dispatch(setItemActionsModal(!setItemActionsModalIsOpen));
  const [selectedActionFilterOption, setSelectedActionFilterOption] = useState(null);
  const [damageQtyCheckBox, setDamageQtyCheckBox] = useState(false);
  const [bufferItemDetail, setBufferItemDetail] = useState([]);
  const [qtyValidation, setQtyValidation] = useState("");
  const [seeMoreUPC, setSeeMoreUPC] = useState();
  const [bufferQty, setBufferQty] = useState(0);
  const [seeMore, setSeeMore] = useState();
  const [expDate, setExpDate] = useState();

  const maxFileSize = 1024 * 1024 * 2;
  const [uploadError, setUploadError] = useState("");
  const [selectedFile, setselectedFile] = useState([]);
  const [file] = useState("Return_label_upload");
  const acceptedFileTypes = ".pdf";

  function triggerAlert (msg) {
    if (uploadError) setUploadError("");
    setUploadError(msg);
    setTimeout(() => setUploadError(""), 5000);
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  const getLabelValue = (action) => ({ label: statusMapping[action], value: action });

  useEffect(() => {
    if (itemDetail) {
      setBufferItemDetail(itemDetail);
      setSelectedActionFilterOption(getLabelValue(actionType));
      setDamageQtyCheckBox(false);
    }
  }, [itemDetail]);

  useEffect(() => {
    if (isShipmentWiseTab) {
      setBufferQty(damageQtyCheckBox ? itemDetail?.damaged_qty : itemDetail?.remaining_qty);
    } else {
      setBufferQty(damageQtyCheckBox ? itemDetail?.received_shipments[0]?.damaged_qty : itemDetail?.qty);
    }
  }, [damageQtyCheckBox]);

  const getItemText = (id, key, value, seeMoreOpts, length) => (
    <SeeMoreText id={id} value={value} length={length || 50} {...seeMoreOpts} className="text-primary" onHoverDisable />
  );

  const handleCehckQty = (getQty, qty) => {
    if (getQty < +qty) setQtyValidation(`Enter <= ${getQty}`);
    else if (+qty < 0) setQtyValidation("Enter positive number.");
    else if (+qty === 0) setQtyValidation("Enter > 0");
    else setQtyValidation("");
  };

  const handleQty = (qty) => {
    setBufferQty(qty);
    if (damageQtyCheckBox) {
      const getDamageQty = bufferItemDetail?.received_shipments[0]?.damaged_qty || bufferItemDetail?.damaged_qty || 0;
      handleCehckQty(getDamageQty, qty);
    } else {
      const getQty = bufferItemDetail?.qty || bufferItemDetail?.remaining_qty;
      handleCehckQty(getQty, qty);
    }
  };

  const handleSubmit = () => {
    const itemID = isShipmentWiseTab ? bufferItemDetail?.warehouse_inventory_id : bufferItemDetail?.id;
    const data = merge(
      {
        qty: bufferQty,
        isDamageQty: damageQtyCheckBox,
        sku: bufferItemDetail?.sku || bufferItemDetail?.warehouse_inventory?.sku,
        type: selectedActionFilterOption?.value,
      },
      selectedActionFilterOption?.value === "returns" && {
        file: { file_name: selectedFile[0], file_type: file },
        expiry_date: getOnlyDate(expDate),
      },
      isShipmentWiseTab && { recv_shipment_id: bufferItemDetail?.id },
    );
    dispatch(fetchWHInventoryAction({ itemID, data }));
  };

  return (
    <div>
      <Modal size="lg" isOpen={setItemActionsModalIsOpen} toggle={toggleItemActionsModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Item Actions
          </h5>
          <button
            onClick={() => toggleItemActionsModal()}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <Row className="d-flex">
            <Col sm={6}>
              <p>Title</p>
              <h6>
                {getItemText(
                  bufferItemDetail?.id,
                  "InventoryItem",
                  bufferItemDetail?.title || bufferItemDetail?.warehouse_inventory?.title || "N/A",
                  { seeMore, setSeeMore },
                  50,
                )}
              </h6>
            </Col>
            <Col sm={3}>
              <p>SKU</p>
              <h6>{bufferItemDetail?.sku || bufferItemDetail?.warehouse_inventory?.sku}</h6>
            </Col>
            <Col sm={3}>
              <p>UPC</p>
              <h6>
                {getItemText(
                  bufferItemDetail?.id,
                  "InventoryItem",
                  bufferItemDetail?.upc || bufferItemDetail?.warehouse_inventory?.upc || "N/A",
                  { seeMore: seeMoreUPC, setSeeMore: setSeeMoreUPC },
                  12,
                )}
              </h6>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <div className="mb-3">
                <Label>{damageQtyCheckBox ? "Damage Quantity" : "Quantity"}</Label>
                <Input
                  type="number"
                  min={0.001}
                  className="inner form-control"
                  placeholder="Total Order"
                  value={bufferQty}
                  onChange={(e) => handleQty(e.target.value)}
                />
                <p className="text-danger">{qtyValidation}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label>Actions</Label>
                <Select
                  className="select2-container"
                  classNamePrefix="select2-selection"
                  name="PO_filter"
                  defaultValue={selectedActionFilterOption}
                  placeholder="Select PO type"
                  options={keys(statusMapping).map(key => getLabelValue(key))}
                  formStyle={{ Width: "100%" }}
                  onChange={(option) => {
                    setSelectedActionFilterOption(option);
                  }}
                />
              </div>
            </Col>
          </Row>
          <RenderIf isTrue={selectedActionFilterOption?.value === "returns"}>
            <Row className="mt-4">
              <Col md={6}>
                <Label>Label Expiry Date</Label>
                <div className="d-flex align-items-center inbound-date-range">
                  <Flatpickr
                    key="returnPicker"
                    className="form-control"
                    placeholder="Select Date"
                    value={expDate}
                    options={{
                      mode: "single",
                      dateFormat: DATE_PICKR,
                      minDate: getOnlyDate(),
                    }}
                    onChange={(e) => setExpDate(e[0])}
                  />
                  <i className="bx font-size-16 bx-calendar text-primary mx-3" />
                </div>
              </Col>
            </Row>
          </RenderIf>

          <RenderIf isTrue={selectedActionFilterOption?.value === "returns"}>
            <Row className="mt-4">
              <Col md={12}>
                {/* Form Start */}
                <form className="m-2">
                  <RenderIf isTrue={uploadError}>
                    <Alert color="danger">
                      <i className="bx bx-info-circle pr-2"></i>
                      {uploadError}
                    </Alert>
                  </RenderIf>
                  <Col className="my-1 ">
                    {selectedFile.length === 0 && (
                      <Dropzone
                        onDrop={(acceptedFile, fileRejections) => {
                          if (fileRejections.length) {
                            const [file] = fileRejections;
                            if (file.type !== ".png") triggerAlert("Invalid File type. It must be a PDF file");
                            if (file.size > maxFileSize)
                              triggerAlert(`Max allowed file size is ${formatBytes(maxFileSize)}`);
                          }
                          handleAcceptedFile(acceptedFile);
                        }}
                        accept={acceptedFileTypes}
                        maxFiles="1"
                        maxSize={maxFileSize}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Drag or Click to Upload PDF File</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    )}
                    <RenderIf isTrue={selectedFile.length}>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedFile.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col>
                                    {f.name}
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                    <p className="mb-0"></p>
                                  </Col>
                                  <i
                                    title="Remove File"
                                    className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                                    onClick={() => {
                                      setselectedFile([]);
                                    }}
                                  ></i>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </RenderIf>
                  </Col>
                </form>
                {/* Form End */}
              </Col>
            </Row>
          </RenderIf>
          <Row className="d-flex justify-content-end mt-3">
            <Button type="reset" color="secondary" className="mr-3" onClick={() => toggleItemActionsModal()}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="success"
              className="mr-3"
              disabled={
                selectedActionFilterOption?.value === "returns"
                  ? !(!!selectedFile?.length && !qtyValidation && expDate)
                  : !!qtyValidation
              }
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ItemActionsModal;
