import React from "react";
import { Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import BusinessCard from "./BusinessCard";
import businessCardIcon from "../../../assets/svg/businessCardIcon.svg";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { RenderIf, isUserHaveFullAccess, makeSubscriptionsUrls } from "../../../utils/common";
import * as $ from "lodash";

const businessCardsData = (sources) => [
  {
    iconSrc: businessCardIcon,
    title: "Product Research Extension",
    description:
      "The Pro version of our chrome extension show stock and price history for both Walmart and Amazon as well as other useful insights.",
    btnTitle: "Upgrade Now",
    btnColor: "primary",
    link: sources?.length ? makeSubscriptionsUrls("basic", false) : makeSubscriptionsUrls("basic", false, true),
  },
  // Will add it later on
  // {
  //   iconSrc: businessCardIcon,
  //   title: "Try Our Profit Analyzer",
  //   description:
  //     "Need to quickly scan a wholesale list? We've got you covered. Upload a list once a month completely free!",
  //   btnTitle: "Check It Out",
  //   btnColor: "primary",
  //   link: "https://scanner.ecomcircles.com/",
  // },
  {
    iconSrc: businessCardIcon,
    title: "Warehouse Services",
    description:
      "In just a few clicks you can be done with handling products yourself for-ever! Just connect to one of our warehouses.",
    btnTitle: "Get Started",
    btnColor: "primary",
    link: "/warehouse",
  },
  {
    iconSrc: businessCardIcon,
    title: "Repricer to Maximize Profit",
    description:
      "Competition is fierce these days! Connect to our repricer to auto-matically reprice your items based on configurable strategies.",
    btnTitle: "Start 14-Day Free Trial",
    btnColor: "primary",
    link: makeSubscriptionsUrls("repricer", true),
  },
];

const accessMapping = { extension: "Product Research Extension", repricer: "Repricer to Maximize Profit" };

export default function GrowBusiness () {
  const { sources } = useSelector((state) => state.Stripe);
  const { associatedWarehouses } = useSelector((state) => state.WareHouses);
  const filteredCards = businessCardsData(sources).filter((card) => {
    if (card.title === "Warehouse Services") return !$.size(associatedWarehouses?.data);
    return !$.entries(accessMapping).some(([app, title]) => card.title === title && isUserHaveFullAccess(app));
  });
  return (
    <RenderIf isTrue={filteredCards.length}>
      <Card className="resources p-3">
        <CardBody>
          <Breadcrumb
            isCapitalize={true}
            title="Grow Your Business"
            children={<span className="d-none cursor-pointer">See All</span>}
          />
          <div className="row mb-3">
            {filteredCards.map((cardData, index) => (
              <BusinessCard
                key={"__business__card__" + index}
                iconSrc={cardData.iconSrc}
                title={cardData.title}
                description={cardData.description}
                btnTitle={cardData.btnTitle}
                btnColor={cardData.btnColor}
                link={cardData.link}
              />
            ))}
          </div>
        </CardBody>
      </Card>
    </RenderIf>
  );
}
