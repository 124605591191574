import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, NavLink, NavItem, Nav, TabContent, TabPane, Alert, Col, Input, FormGroup } from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Channels from "./tabs/Channels";
import AccountSettings from "./tabs/AccountSettings";
import Plans from "./tabs/Plans";
import PaymentMethod from "./tabs/PaymentMethod";
import ChargeHistory from "./tabs/ChargeHistory";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Row from "reactstrap/lib/Row";
import queryString from "query-string";
import { apiError, updateAccountSettings } from "../../store/settings/actions";
import SetupWhBanner from "../../components/Common/SetupWhBanner";
import { values, pick, keys, size, get } from "lodash";
import {
  setShowOnboarding as _showOnboarding,
  setOnboardingStep as _onboardingStep,
  setOnboardingFlow as _onboardingFlow,
  setIsAmz as _setIsAmz,
  setFormData as _setFormData,
  setPreloader,
} from "../../store/actions";
import {
  apiError as mpApiError,
  fetchShopifyRefreshToken,
  fetchSpApiRefreshToken,
  setIsShopify,
  setSPAPIErr,
  updateMarketplaceAccounts,
  sendErrorEmail,
  resetSuccessState,
} from "../../store/marketPlaceAccounts/actions";
import {
  connectToAMZStores,
  decode,
  decodeState,
  getRedirectionUrl,
  isSPAPIURL,
  removeURLQueryParams,
  isShopifyAPIURL,
  RenderIf,
  isUserHaveFullAccess,
  getUserInfo,
  isAdmin,
  isSuperAdmin,
} from "../../utils/common";
import {
  onboardingStepsIds,
  AMZ_REGION,
  HELP_DOCS_LINK,
  SEND_ERROR_EMAIL,
} from "../../constants";
import Select from "react-select";
import PillBtn from "../../components/Common/PillBtn";
import moment from "moment";
import StorageCheckbox from "../Dashboard/AgencyComponents/StorageCheckBox";
import ManualBillingCheckBox from "../Dashboard/AgencyComponents/ManualBillingCheckBox";
import AutoUpgradeCheckBox from "../Dashboard/AgencyComponents/AutoUpgradeCheckBox";

const Settings = (props) => {
  const dispatch = useDispatch();
  // Settings
  const queryParameters = queryString.parse(props.location.search);
  const [disableSendEmail, setDisableSendEmail] = useState(false);
  const [activeTab, setActiveTab] = useState({ settings: 0, payment: 0 });
  const [marketplaceAccount, setMarketplaceAccount] = useState();
  const [service, setService] = useState({ value: "", label: "Select service" });
  const {
    accounts,
    loading,
    refTokenLoading,
    spApiErr,
    spApiRefToken,
    spApiMsg,
    shopifyErr,
    shopifyRefToken,
    shopifySuccess,
    success: emailSuccess,
  } = useSelector((state) => state.MarketPlaceAccount);
  const { associatedWarehousesObj } = useSelector((state) => state.WareHouses);
  const { account } = useSelector((state) => state.Settings);
  const isFullAccess = isUserHaveFullAccess();
  const setShowOnboarding = (...args) => dispatch(_showOnboarding(...args));
  const setOnboardingStep = (...args) => dispatch(_onboardingStep(...args));
  const setOnboardingFlow = (...args) => dispatch(_onboardingFlow(...args));
  const setIsAmz = (...args) => dispatch(_setIsAmz(...args));
  const setFormData = (...args) => dispatch(_setFormData(...args));
  const openBox = () => {
    const { storeName } = parseStoreName(queryParameters.state);
    if (decodeState(queryParameters)?.type === "update") {
      const isCodeExist = queryParameters && queryParameters.spapi_oauth_code;
      const sellerId = queryParameters && parseStoreName(queryParameters.state)?.sellerId;
      if (sellerId && sellerId !== queryParameters.selling_partner_id) {
        dispatch(
          setSPAPIErr(
            "The account you are updating doesn't match with the amazon account you want to authorize, kindly re-authorize with the correct store",
          ),
        );
      } else if (isCodeExist && !spApiRefToken && !refTokenLoading) {
        dispatch(
          fetchSpApiRefreshToken({
            selling_partner_id: queryParameters.selling_partner_id,
            mws_auth_token: queryParameters.mws_auth_token,
            spapi_oauth_code: queryParameters.spapi_oauth_code,
          }),
        );
      }
    } else {
      setFormData({
        name: storeName,
        marketplaces: AMZ_REGION[0].countries[0].id,
        access_id: queryParameters.selling_partner_id,
        access_secret: queryParameters.mws_auth_token,
      });

      setIsAmz({ isOpen: true, isUpdate: true });
      setOnboardingFlow(false);
      setOnboardingStep(onboardingStepsIds.amazonSetUp);
      setShowOnboarding(true);
    }
  };

  const openShopifyBox = () => {
    dispatch(
      fetchShopifyRefreshToken({
        code: queryParameters.code,
        shop: queryParameters.shop,
      }),
    );
    if (!queryParameters?.state?.includes("update")) {
      setIsAmz({ isOpen: true, isUpdate: true });
      dispatch(setIsShopify(true));
      setFormData({
        name: queryParameters.shop?.replace(".myshopify.com", ""),
      });
      setOnboardingFlow(false);
      setOnboardingStep(onboardingStepsIds.shopifysetUp);
      setShowOnboarding(true);
    }
  };

  // Settings
  const tabs = [
    {
      title: "Channels",
      id: 0,
      component: Channels,
    },
    {
      title: "Account Settings",
      id: 1,
      component: AccountSettings,
    },
  ];

  const filteredtabs = isFullAccess ? tabs : tabs.filter((x) => !x.id);

  // Payments
  const paymentTabs = [
    {
      title: "Billing Plans",
      id: 0,
      component: Plans,
    },
    {
      title: "Payment Methods",
      id: 1,
      component: PaymentMethod,
    },
    {
      title: "Charge History",
      id: 2,
      component: ChargeHistory,
    },
  ];

  // Settings
  const toggleActiveTab = (tab) => {
    switch (tab) {
      case 0:
        props.history.push("/settings?channels=true");
        break;
      case 1:
        props.history.push("/settings?account=true");
        break;
      default:
        break;
    }
  };

  const parseStoreName = (state) => {
    try {
      return JSON.parse(decode(state));
    } catch (error) {
      return {};
    }
  };

  // Payments
  const toggleActiveTabPayment = (tab) => {
    switch (tab) {
      case 0:
        props.history.push("/settings?billing=true");
        break;
      case 1:
        props.history.push("/settings?payment_methods=true");
        break;
      case 2:
        props.history.push("/settings?charge_history=true");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (spApiRefToken && decodeState(queryParameters)?.type === "update" && !spApiErr && accounts?.data) {
      dispatch(
        updateMarketplaceAccounts({
          data: { refresh_token: spApiRefToken, access_secret: queryParameters.mws_auth_token },
          id: decodeState(queryParameters)?.id,
        }),
      );
      removeURLQueryParams();
    } else if (shopifyRefToken && queryParameters?.state?.includes("update") && !shopifyErr && accounts?.data) {
      dispatch(
        updateMarketplaceAccounts({
          data: { refresh_token: shopifyRefToken },
          id: queryParameters.state.split("_update_id_")[1],
        }),
      );
      removeURLQueryParams();
    }
  }, [spApiRefToken, shopifyRefToken, accounts?.data]);

  useEffect(() => {
    if (queryParameters.channels) setActiveTab({ ...activeTab, settings: 0 });
    else if (queryParameters.account) setActiveTab({ ...activeTab, settings: 1 });
    else if (queryParameters.billing) setActiveTab({ ...activeTab, payment: 0 });
    else if (queryParameters.payment_methods) setActiveTab({ ...activeTab, payment: 1 });
    else if (queryParameters.charge_history) setActiveTab({ ...activeTab, payment: 2 });
    dispatch(apiError(""));
  }, [keys(queryParameters || {}).join("")]);

  useEffect(() => {
    setPreloader(refTokenLoading || loading);
  }, [refTokenLoading, loading]);

  useEffect(() => {
    if (isSPAPIURL()) openBox();
    if (isShopifyAPIURL(queryParameters)) openShopifyBox();
  }, []);

  useEffect(() => {
    dispatch(apiError(""));
    dispatch(mpApiError(""));
  }, [activeTab]);

  useEffect(() => {
    if (emailSuccess) {
      setTimeout(() => {
        setDisableSendEmail(false);
        dispatch(resetSuccessState());
      }, 3000);
    }
  }, [emailSuccess]);

  const [route, msg] = getRedirectionUrl(account?.data || {}, true);
  const redirectMessage = route === values(pick(props.history.location, "pathname", "search")).join("") ? msg : "";
  const handleSendErrorEmail = (data) => {
    setDisableSendEmail(true);
    dispatch(sendErrorEmail(data));
  };
  const isEmailAllowed = SEND_ERROR_EMAIL.includes(getUserInfo()?.user_email);
  const hasSearchInUrl = pick(props.history.location, "search")?.search;
  const renderSendErrorEmail = isEmailAllowed && (!hasSearchInUrl || hasSearchInUrl?.includes("channels"));

  const updateSettings = (key) => {
    const data = { [key]: !get(account?.data, key) };
    dispatch(updateAccountSettings(data));
  };

  const SuperAdminCard = () => {
    const [isUserAdmin, trialEnded, isUserSuperAdmin] = [
      isAdmin(),
      moment() > moment(account?.data?.trial_end),
      isSuperAdmin(),
    ];
    return (
      <RenderIf isTrue={isUserSuperAdmin}>
        <Card className="p-1">
          <CardBody>
            <h1 style={{ fontWeight: 600, fontSize: "16px" }}>Super Admin Settings</h1>
            <p style={{ fontWeight: 400, fontSize: "14px" }}>
              Settings for storage charges exemption, auto upgrade and manual billing.
            </p>
            <div>
              <RenderIf isTrue={isUserAdmin}>
                <StorageCheckbox
                  isShowConfirmation={true}
                  className="pb-3"
                  msg="all the inventory of this user"
                  val={account?.data?.is_storage_exempt}
                  onConfirm={() => updateSettings("is_storage_exempt")}
                />
              </RenderIf>
              <RenderIf isTrue={trialEnded}>
                <AutoUpgradeCheckBox
                  checked={account?.data?.auto_subscription_handler}
                  onConfirm={() => updateSettings("auto_subscription_handler")}
                />
              </RenderIf>
              <RenderIf isTrue={isUserSuperAdmin}>
                <ManualBillingCheckBox
                  value={account?.data?.is_manual_billing}
                  onConfirm={() => updateSettings("is_manual_billing")}
                />
              </RenderIf>
            </div>
          </CardBody>
        </Card>
      </RenderIf>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {accounts?.data?.find((acc) => !acc.valid && acc.enabled) && (
          <div>
            <Alert color="danger" style={{ marginTop: "10px" }}>
              <i className="bx bx-info-circle pr-2"></i>
              Credentials are no longer working for{" "}
              {accounts?.data
                ?.filter((acc) => !acc.valid && acc.enabled)
                .map((x) => x.name)
                .join(", ")}
              . Please update the credentials or disable this channel.
            </Alert>
          </div>
        )}
        <SetupWhBanner associatedWarehouses={associatedWarehousesObj} stores={accounts?.data} />
        {connectToAMZStores(accounts?.data) && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            As required by Amazon, it is mandatory to switch from MWS to SP-API. This will require you to reauthorize or
            reconnect your these stores{" "}
            {connectToAMZStores(accounts?.data, "filter")
              .map((x, key) => <b key={"__name_" + key}>{x.name}</b>)
              .reduce((prev, curr, key) => [prev, <span key={"__span__" + key}>{", "}</span>, curr])}{" "}
            to Ecom Circles. For help regarding re-authorization you can follow this{" "}
            {
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${HELP_DOCS_LINK}complete-guide/how-to-re-authorize-your-amazon-account`}
              >
                article
              </a>
            }
            {". "}
            Feel free to contact support if you need help.
          </Alert>
        )}
        <RenderIf isTrue={spApiErr || shopifyErr}>
          <div className="auto-hide-10">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {spApiErr || shopifyErr}
            </Alert>
          </div>
        </RenderIf>
        <RenderIf isTrue={spApiMsg || shopifySuccess}>
          <div className="auto-hide-10">
            <Alert color="success">
              <i className="bx bx-info-circle pr-2"></i>
              {spApiMsg || shopifySuccess}
            </Alert>
          </div>
        </RenderIf>

        <Container fluid>
          {/* Render Breadcrumb */}
          <Row className="ml-1">
            <Breadcrumbs
              title={
                <>
                  Settings{" "}
                  {
                    <a href={HELP_DOCS_LINK} rel="noopener noreferrer" target="_blank">
                      <i className="bx bx-info-circle"></i>
                    </a>
                  }
                </>
              }
            />
          </Row>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs-custom nav-justified">
                {filteredtabs.map((tab) => (
                  <NavItem key={`email_tab-${tab.id}`}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab.settings === tab.id,
                      })}
                      onClick={() => {
                        toggleActiveTab(tab.id);
                      }}
                    >
                      <span className="d-none d-sm-block">{tab.title}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={activeTab.settings}>
                {filteredtabs.map((tab) => (
                  <TabPane key={"tab_pane" + tab.id} tabId={tab.id} className="pt-3" children={<tab.component />} />
                ))}
              </TabContent>
              <hr />
              <RenderIf isTrue={renderSendErrorEmail}>
                <Row>
                  <Col>
                    <Input style={{ cursor: "not-allowed" }} name="Email" value={account.data?.email} disabled />
                  </Col>
                  <Col>
                    <FormGroup className="select2-container">
                      <Select
                        placeholder="Select marketplace account"
                        name="marketplace accounts"
                        style={{ color: "black" }}
                        menuPlacement={"bottom"}
                        value={marketplaceAccount}
                        onChange={(e) => {
                          setMarketplaceAccount([e]);
                        }}
                        options={accounts?.data ? accounts.data.map((account) => ({ value: account.id, label: account.name })) : []}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="select2-container">
                      <Select
                        name="service"
                        style={{ color: "black" }}
                        menuPlacement={"bottom"}
                        value={service}
                        onChange={(e) => setService(e)}
                        options={[
                          { value: "order", label: "Fetch order" },
                          { value: "return", label: "Fetch return" },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={1.5}>
                    <PillBtn
                      disabled={!size(marketplaceAccount) || !size(service.value) || disableSendEmail}
                      style={
                        !size(marketplaceAccount) || (size(marketplaceAccount) && emailSuccess)
                          ? { cursor: "not-allowed" }
                          : {}
                      }
                      color="primary"
                      title="Send Error Email"
                      name="Send Error Email"
                      icon="bx bx-xs bx-envelope"
                      className="ml-1"
                      onClick={() =>
                        handleSendErrorEmail({
                          account: account?.data,
                          store: marketplaceAccount?.[0],
                          service: service.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </RenderIf>
            </CardBody>
          </Card>
          <SuperAdminCard />
          <Row className="ml-1">
            <Breadcrumbs title="Payments" />
          </Row>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs-custom nav-justified">
                {paymentTabs.map((tab) => (
                  <NavItem key={`Ppyments_tab-${tab.id}`}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab.payment === tab.id,
                      })}
                      onClick={() => {
                        toggleActiveTabPayment(tab.id);
                      }}
                    >
                      <span className="d-none d-sm-block">{tab.title}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={activeTab.payment}>
                {account &&
                  paymentTabs.map((tab) => (
                    <TabPane
                      key={"tab_pane" + tab.id}
                      tabId={tab.id}
                      className="pt-3"
                      children={
                        <React.Fragment>
                          {redirectMessage ? (
                            <div>
                              <Alert color="danger">{redirectMessage}</Alert>
                            </div>
                          ) : null}
                          <tab.component account={account} accounts={accounts} />
                        </React.Fragment>
                      }
                    />
                  ))}
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
