import request from "../restClient";
const resource = "/api/matches_requests";

export const fetchMatchesRequests = async () => {
  return await request({
    url: resource,
    method: "GET",
  });
};

export const cancelMatchesReq = async ({ id }) => {
  return await request({
    url: `${resource}/${id}`,
    method: "PUT",
    body: { request: { status: "cancelled" } },
  });
};

export const fetchMatchesRequestsByQuery = async (params) => {
  const queryParams = Object.keys(params)
    .map((key) =>
      Array.isArray(params[key])
        ? `${key}=${encodeURIComponent(JSON.stringify(params[key]))}`
        : `${key}=${params[key]}`,
    )
    .join("&");
  const url = "/api/matches_requests?" + queryParams;
  return await request({ url, method: "GET" });
};

export const matchesRequestService = async () => {
  return await request({
    url: "/api/services_status/fulfill_matches_requests",
    method: "GET",
  });
};
