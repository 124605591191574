import request from "../restClient";
import { makeQP } from "../../utils/common";

const resource = "/source_orders";
const category = "/source_items";

export const getAllSourceOrders = (params) => request({ url: resource + makeQP(params), method: "GET" });

export const searchAllOrders = (params) => request({ url: `${resource}/search_all${makeQP(params)}`, method: "GET" });

export const getAllSourceOrderDetail = async (id) => {
  const url = `${resource}/${id}`;
  return await request({ url, method: "GET" });
};

export const generateShippingLabel = async ({ id, data }) =>
  request({ url: `${resource}/generate_shipping_label/${id}`, method: "POST", body: data, download: true });

export const getItemsDimensions = async ({ orderId }) =>
  request({ url: `${resource}/get_items_dimensions/${orderId}`, method: "GET" });

export const getShippingRates = async (data) =>
  request({ url: `${resource}/get_shipping_rates/`, method: "POST", body: data });

export const updateStatusToError = async ({ id, data }) => {
  const url = `${resource}/update_status/${id}?status=${data.status}`;
  return await request({ url, method: "PUT", body: data });
};

export const changeOrderType = async ({ id, data }) => {
  const url = `${resource}/update_order_type/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const importOrderManually = async (orderId, storeId) => {
  const url = `${resource}/import_order/${orderId}?storeId=${storeId}`;
  return await request({ url, method: "GET" });
};

export const getNextSourceOrderByStatus = async (id) => {
  const url = `${resource}/get_next_by_status/${id}`;
  return await request({ url, method: "GET" });
};

export const getLockStatus = async (id) => {
  const url = `${resource}/${id}/get-lock-status`;
  return await request({ url, method: "GET" });
};

export const updateSourceOrderDetail = async ({ id, data }) => {
  const url = `${resource}/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const getFeedStatus = async ({ orderId }) => {
  const url = `${resource}/get_feed_status/${orderId}`;
  return await request({ url, method: "GET" });
};

export const uploadOrdersFile = async (data) => {
  const url = `${resource}/uploadOrdersFileData`;
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return await request({ url, method: "POST", formData });
};

export const bulkUpdateOrder = async (data) => {
  const url = `${resource}`;
  return await request({ url, method: "PUT", body: data });
};

export const updateAddressBySourceOrderId = async ({ id, data }) => {
  const url = `${resource}/${id}/address`;
  return await request({ url, method: "PUT", body: data });
};

export const isOrderable = async (id) => {
  const url = `${resource}/${id}/is_orderable`;
  return await request({ url, method: "GET" });
};

export const generateProxyBCE = async ({ id, params }) => {
  const url = `${resource}/${id}/create_proxy_bce` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const cancelMarketplaceOrder = async ({ id, cancel_reason: cancelReason }) => {
  const url = `${resource}/${id}/cancel_on_marketplace`;
  return await request({ url, method: "POST", body: { cancel_reason: cancelReason } });
};

export const fetchAmazonStatus = async (id) => {
  const url = `${resource}/${id}/amazon_marketplace_status`;
  return await request({ url, method: "GET" });
};

export const fetchShopifyStatus = async (id) => {
  const url = `${resource}/${id}/shopify_marketplace_status`;
  return await request({ url, method: "GET" });
};

export const fetchNextOrder = async () => {
  const url = `${resource}/get_next`;
  return await request({ url, method: "GET" });
};

export const dowloadSourceOrderReport = (params = {}) =>
  request({ url: `${resource}/export_orders` + makeQP(params), method: "GET" });

export const downloadVAPerformanceReport = (params = {}) =>
  request({ url: "/reports/va_performance" + makeQP(params), method: "GET" });

export const downloadOrderProcessorReport = (params = {}) =>
  request({ url: "/reports/order_processor" + makeQP(params), method: "GET" });

export const getAvailablePaymentReports = (payload) => {
  const url = `/reports/payment_report_dates${payload.isAmz ? "_amazon" : ""}/${payload.storeId}${
    payload.agencyId ? `?agencyId=${payload.agencyId}` : ""
  }`;
  return request({ url, method: "GET" });
};

export const downloadPaymentReport = ({ storeId, reportId, reportDate, agencyId, agencyProfit, extraCols, isPDF }) => {
  const body = { storeId, reportDate, agencyId, agencyProfit, reportId };
  if (extraCols?.length) body.extraCols = extraCols;
  if (isPDF) body.isPDF = isPDF;
  return request({ url: "/reports/payment_report", method: "POST", body });
};

export const addOrderNote = async ({ data }) => {
  const url = `${resource}/${data.source_order_id}/add_note`;
  return await request({ url, method: "PUT", body: { note: data.note } });
};

export const uploadTrackingDetails = async ({ data }) => {
  const url = "/supplier_orders/upload_tracking_details";
  return await request({ url, method: "POST", body: data });
};

export const fetchCategory = async (skus) => {
  if (!skus.length) return { data: [] };
  const url = `${category}/get_category`;
  return await request({ url, method: "POST", body: { skus } });
};

export const assignOutboundItem = async (body) => {
  const url = `${resource}/assign_outbound_item`;
  return await request({ url, method: "POST", body });
};

export const createPriceEvent = (body) => request({ url: "/price_events", method: "POST", body });

export const updatePriceEvent = ({ id, body }) => request({ url: `/price_events/${id}`, method: "PUT", body });

export const deletePriceEvent = (id) => request({ url: `/price_events/${id}`, method: "DELETE" });

export const acknowledgeOrder = ({ id }) => request({ url: `${resource}/acknowledge_order/${id}`, method: "POST" });

export const createSourceItemLabel = async (formData) => {
  return await request({
    url: "/source_orders/upload_label",
    method: "POST",
    formData,
  });
};
