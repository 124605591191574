import React from "react";
import moment from "moment-timezone";
import Modal from "../../../components/Common/Modal";
import { orderBy } from "lodash";
import { formatNoteText } from "../../../utils/common";

const OrderLogs = (props) => {
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);
  return (
    <Modal size="lg" isOpen={props.isOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Order Logs</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <div className="table-responsive overflow-auto">
          <table className="table table-centered table-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                <th>Created By</th>
                <th>Added On</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {props.orderLogs &&
                orderBy(props.orderLogs, "created_at", "desc").map((log, key) => (
                  <tr key={"_log_" + key}>
                    <td>
                      <span className="text-body font-weight-bold">{log?.created_by || ""}</span>
                    </td>
                    <td>{(log?.created_at && moment(log?.created_at).format("MMM Do YYYY, h:mm:ss a")) || ""}</td>
                    <td
                      className="wrap-text-to-paragraph"
                      dangerouslySetInnerHTML={{
                        __html: formatNoteText(log?.note) || "",
                      }}
                    ></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default OrderLogs;
