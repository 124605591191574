import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

import { getUserInfo, humanize } from "../../../utils/common";

const ListingStats = () => {
  const endDate = new Date();
  const startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000);
  const { listingStats, refresherLoader } = useSelector((state) => state.Graph);

  const getDate = (date) => moment(new Date(date)).toDate();

  useEffect(() => {}, [listingStats]);

  const seriesStock = [
    ...(getUserInfo()?.account_id === 2
      ? [
          {
            name: "Offer found",
            data: (listingStats?.refresher_stats?.length &&
              listingStats.refresher_stats.map((refresher) => [
                getDate(refresher?.date_hour).toString(),
                refresher?.offer_found,
              ])) || [[startDate], [endDate]],
          },
          {
            name: "Offer not present",
            data: (listingStats?.refresher_stats?.length &&
              listingStats.refresher_stats.map((refresher) => [
                getDate(refresher?.date_hour).toString(),
                refresher?.offer_not_present,
              ])) || [[startDate], [endDate]],
          },
          {
            name: "Offer not viable",
            data: (listingStats?.refresher_stats?.length &&
              listingStats.refresher_stats.map((refresher) => [
                getDate(refresher?.date_hour).toString(),
                refresher?.offer_not_viable,
              ])) || [[startDate], [endDate]],
          },
          {
            name: "Page not scraped",
            data: (listingStats?.refresher_stats?.length &&
              listingStats.refresher_stats.map((refresher) => [
                getDate(refresher?.date_hour).toString(),
                refresher?.page_not_scraped,
              ])) || [[startDate], [endDate]],
          },
        ]
      : []),
    {
      name: "In stock",
      data: (listingStats?.refresher_stats?.length &&
        listingStats.refresher_stats.map((refresher) => [
          getDate(refresher?.date_hour).toString(),
          refresher?.in_stock,
        ])) || [[startDate], [endDate]],
    },
    {
      name: "Out of stock",
      data: (listingStats?.refresher_stats?.length &&
        listingStats.refresher_stats.map((refresher) => [
          getDate(refresher?.date_hour).toString(),
          refresher?.out_of_stock,
        ])) || [[startDate], [endDate]],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2.3,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tickAmount: 3,
      labels: {
        formatter: function (timestamp) {
          return moment(timestamp).format("MMM-DD HH:MM"); // The formatter function overrides format property
        },
      },
    },
    yaxis: { labels: { formatter: (x) => humanize(x) } },
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">{refresherLoader ? "Loading..." : "Refresher stats"}</h4>
        <div className="clearfix"></div>
        <ReactApexChart options={options} series={seriesStock} type="line" height="350" />
      </CardBody>
    </Card>
  );
};

export default ListingStats;
