import React, { useEffect, useState } from "react";
import { forEach, isEmpty, keys, values as binValues, concat, compact, cloneDeep as cp, cloneDeep, startCase } from "lodash";
import { Alert, Col, Input, Label, Row, UncontrolledPopover } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CheckBox from "../../../components/Common/CheckBox";
import Modal from "../../../components/Common/Modal";
import PillBtn from "../../../components/Common/PillBtn";

// Actions
import { addShipment, setAddShipmentModal, fetchInboundIsLiquated, resetIsLiquated } from "../../../store/actions";
import { RenderIf } from "../../../utils/common";
import VerticalLine from "../../../components/Common/VerticalLine";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { ORDERABLE_TYPES } from "../../../constants";

function AddShipmentModal () {
  const dispatch = useDispatch();
  const { appTheme } = useSelector((state) => state.Layout);
  const { addShipmentModal, error, isLiquidated, success } = useSelector((state) => state.WareHouses);
  const toggleModal = () => dispatch(setAddShipmentModal(false));

  const [binLocation, setBinLocation] = useState({});
  const [orderableType, setOrderableType] = useState({
    value: ORDERABLE_TYPES.purchase_orders,
    label: startCase(ORDERABLE_TYPES.purchase_orders),
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState({});
  const [binError, setBinError] = useState({});
  const [initialValues, setInitialValues] = useState({
    tracking_number: "",
    purchase_order_id: "",
    suit_number: "",
    line_items: [
      {
        item_title: "",
        quantity: 1,
        qty_multiplier: "",
        damaged_qty: 0,
        bin_locations: [{ bin_number: "", qty: "" }],
        oversized: false,
      },
    ],
  });

  const [errors, setErrors] = useState({
    tracking_number: "",
    purchase_order_id: "",
    suit_number: "",
    line_items: [
      {
        item_title: "",
        quantity: "",
        qty_multiplier: "",
        damaged_qty: 0,
        bin_locations: [{ bin_number: "", qty: "" }],
        oversized: false,
      },
    ],
  });

  const [touched, setTouched] = useState({
    tracking_number: "",
    purchase_order_id: "",
    suit_number: "",
    line_items: [
      {
        item_title: "",
        quantity: "",
        qty_multiplier: "",
        damaged_qty: 0,
        bin_locations: [{ bin_number: "", qty: "" }],
        oversized: false,
      },
    ],
  });

  // Util Function
  const addNewItem = () => {
    setInitialValues({
      ...initialValues,
      line_items: [
        ...initialValues.line_items,
        {
          item_title: "",
          quantity: 1,
          qty_multiplier: 1,
          damaged_qty: 0,
          bin_locations: [{ bin_number: "", qty: "" }],
          oversized: false,
        },
      ],
    });
    setTouched({
      ...touched,
      line_items: [
        ...touched.line_items,
        {
          item_title: "",
          quantity: "",
          qty_multiplier: "",
          damaged_qty: 0,
          bin_locations: [{ bin_number: "", qty: "" }],
          oversized: false,
        },
      ],
    });
  };

  const removeItem = (key) => {
    if (!key && initialValues.line_items.length === 1) return;
    const binLocationBuffer = cp(binLocation);
    delete binLocationBuffer[key];
    const obj = {};
    keys(binLocationBuffer).forEach((x, i) => (obj[i] = binLocationBuffer[x]));
    setBinLocation(obj);
    initialValues.line_items.splice(key, 1);
    setInitialValues({
      ...initialValues,
      line_items: [...initialValues.line_items],
    });
    touched.line_items.splice(key, 1);
    setTouched({
      ...touched,
      line_items: [...touched.line_items],
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values?.tracking_number) errors.tracking_number = "Tracking Number is required.";
    if (!values?.suit_number) errors.suit_number = "Suit # is required.";
    errors.line_items = values?.line_items.map((x) => ({
      item_title: "",
      quantity: "",
      qty_multiplier: "",
      damaged_qty: "",
    }));
    forEach(values?.line_items, (x, key) => {
      if (!x.item_title) errors.line_items[key].item_title = "Item is required.";
      if (!x.quantity) errors.line_items[key].quantity = "Quantity is required.";
      if (x.quantity && x.quantity <= 0) errors.line_items[key].quantity = "Quantity must be greater than 0.";
      if (!x.qty_multiplier) errors.line_items[key].qty_multiplier = "Qty. Multiplier is required.";
      if (x.qty_multiplier && x.qty_multiplier <= 0) {
        errors.line_items[key].qty_multiplier = "Qty. Multiplier must be greater than 0.";
      }
      if (x.damaged_qty === "") errors.line_items[key].damaged_qty = "Must be >= 0";
    });
    errors.line_items.every((x) => !x.item_title && !x.qty_multiplier && !x.quantity && !x.damaged_qty) &&
      delete errors.line_items;
    return errors;
  };

  const onSubmit = (values) => {
    let checkValidation = false;
    const payload = cloneDeep(values);
    payload.po_type = orderableType.value;
    touchedAll();
    const errors = validate(payload);
    if (!isEmpty(errors)) {
      setErrors(errors);
      return;
    }
    forEach(payload.line_items, (item, i) => {
      const binQty = binValues(binLocation?.[i]).every(Boolean) ? [binLocation?.[i]] : [];
      const setBinLocations = binError[i] ? [] : compact(binQty);
      const checkAddedBins = item.bin_locations.filter((x) => x.bin_number && x.qty);
      const binLocationBuffer = concat(checkAddedBins, setBinLocations);
      const binLocationBufferQty = binLocationBuffer.reduce((acc, item) => acc + parseInt(item.qty), 0);
      const totalQty = item.quantity * item.qty_multiplier;
      if (binLocationBuffer.length) {
        if (binLocationBufferQty > totalQty) {
          checkValidation = false;
          setBinError((binError) => ({ ...binError, [i]: "Qty exceeded than received Qty." }));
        } else {
          checkValidation = true;
          payload.line_items[i].bin_locations = binLocationBuffer;
        }
      } else {
        checkValidation = false;
        setBinError((binError) => ({ ...binError, [i]: "Bin#/Qty is required." }));
      }
    });
    if (checkValidation) dispatch(addShipment(payload));
  };

  const touchedAll = () => {
    const { line_items: lineItems, ...rest } = touched;
    keys(rest).forEach((x) => (rest[x] = true));
    lineItems.forEach((x) => keys(x).forEach((y) => (x[y] = true)));
    setTouched({ ...rest, line_items: lineItems });
  };

  const handleFocus = (e) => {
    setTouched({ ...touched, [e.target.name]: true });
    handleBlur();
  };
  const handleDynaimcFocus = (e) => {
    const [, key, name] = e.target.name.split(".");
    touched.line_items[key][name] = true;
    setTouched({ ...touched, line_items: [...touched.line_items] });
  };

  const handleChange = (e) => {
    setInitialValues({ ...initialValues, [e.target.name]: e.target.value });
    handleBlur();
  };

  const fetchIsLiquated = (e) => {
    handleBlur();
    if (e.target.value) dispatch(fetchInboundIsLiquated({ account_id: e.target.value }));
  };

  const handleDynamicChange = (e) => {
    const [, key, name] = e.target.name.split(".");
    initialValues.line_items[key][name] = e.target.value;
    setInitialValues({ ...initialValues, line_items: [...initialValues.line_items] });
    handleBlur();
  };
  const handleBlur = () => setErrors(validate(initialValues));

  const getBinLength = (key) => {
    return initialValues.line_items?.[key]?.bin_locations.filter((x) => x?.bin_number && x?.qty)?.length || 0;
  };

  const handleAddBin = (key) => {
    if (binValues(binLocation?.[key]).some((x) => !x)) return;
    initialValues.line_items[key].bin_locations.push(binLocation[key] || []);
    const totalQty = +initialValues.line_items[key]?.quantity * (+initialValues.line_items[key]?.qty_multiplier || 1);
    if (totalQty < initialValues.line_items[key]?.bin_locations.reduce((x, y) => x + +y?.qty, 0)) {
      setBinError({ ...binError, [key]: "Cannot add more than receive quantity." });
      initialValues.line_items[key].bin_locations.pop();
      return;
    }
    setBinError("");
    setInitialValues({ ...initialValues, line_items: [...initialValues.line_items] });
    setBinLocation({
      ...binLocation,
      [key]: { bin_number: "", qty: "" },
    });
  };

  const checkInitialQtyValidation = (key, value, validateBy) => {
    const binsQty = initialValues.line_items[key]?.bin_locations.reduce((x, y) => x + +y?.qty, 0);
    const quantity = validateBy !== "quantity" ? +initialValues.line_items[key]?.quantity || 0 : value || 0;
    const quantityMultiplier =
      validateBy !== "qtyMultiplier" ? +initialValues.line_items[key]?.qty_multiplier || 0 : value || 0;
    const totalQty = quantity * quantityMultiplier;

    if (validateBy !== "binQty") {
      setBinLocation({
        ...binLocation,
        [key]: {
          ...binLocation[key],
          qty: 0,
          bin_number: binLocation[key]?.bin_number,
        },
      });
    }
    const result = value + binsQty > totalQty;
    setBinError({ ...binError, [key]: result ? `Bin Qty must be less than ${totalQty - binsQty}` : "" });
    return result;
  };

  const removeBin = (key, k) => {
    initialValues.line_items[key].bin_locations.splice(k, 1);
    setInitialValues({ ...initialValues, line_items: [...initialValues.line_items] });
    !getBinLength(key) && setIsPopoverOpen({ [key]: !isPopoverOpen?.[key] });
  };

  const orderableTypesMapping = {
    tracking_items: "2-Step",
  };

  const orderableTypeOptions = () =>
    binValues(ORDERABLE_TYPES).map((x) => ({
      value: x,
      label: startCase(orderableTypesMapping?.[x] || x),
    }));
  // Use Effect
  useEffect(() => {
    setInitialValues({
      tracking_number: "",
      purchase_order_id: "",
      suit_number: "",
      line_items: [
        {
          item_title: "",
          quantity: 1,
          qty_multiplier: 1,
          damaged_qty: 0,
          bin_locations: [{ bin_number: "", qty: "" }],
          oversized: false,
        },
      ],
    });
  }, []);

  useEffect(() => {
    dispatch(resetIsLiquated());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        dispatch(resetIsLiquated());
      }
    }, 2000);
  }, [success]);

  const checkValue = (value, forValidation, key, from) => {
    let err = "";
    if (from === "fromBin") {
      err =
        (!value && "Enter Bin Number") ||
        (!forValidation && "Qty also required") ||
        (forValidation < 0 && "Qty must be positive numbers") ||
        "";
    } else {
      err =
        (!value && "Enter Qty") ||
        (!forValidation && "Bin number also required") ||
        (value < 0 && "Qty must be positive numbers") ||
        "";
    }
    setBinError({ ...binError, [key]: err });
    if (err) return true;
  };

  return (
    <Modal size="xl" backdrop="static" isOpen={addShipmentModal} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Shipments</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <RenderIf isTrue={error && addShipmentModal}>
        <div className="auto-hide">
          <Alert color="danger" className="my-1">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        </div>
      </RenderIf>
      <div className="modal-body">
        <form
          className="m-2"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(initialValues);
          }}
        >
          <RenderIf isTrue={isLiquidated}>
            <div className="auto-hide">
              <Alert color="danger" className="my-1 mb-2">
                <i className="bx bx-info-circle pr-2"></i>
                This customer has canceled their account. Please liquidate this item instead of adding to inventory.
              </Alert>
            </div>
          </RenderIf>
          <Row>
            <Col>
              <Label className="d-flex">
                Tracking Number
                <p className="danger mb-0"> *</p>
              </Label>
              <Input
                name="tracking_number"
                placeholder="Tracking Number"
                type="text"
                value={initialValues.tracking_number}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <RenderIf isTrue={touched.tracking_number && errors.tracking_number}>
                <span className="text-danger mt-1">{errors.tracking_number}</span>
              </RenderIf>
            </Col>
            <Col>
              <Label className="d-flex" id="po-label">
                Purchase Order Number
                <i className="bx bx-info-circle pr-2"></i>
                <CustomTooltip
                  placement="top"
                  target="po-label"
                  content="Random purchase order id will be generated if it is not added."
                />
              </Label>
              <Input
                name="purchase_order_id"
                placeholder="Purchase Order Number"
                type="text"
                value={initialValues.purchase_order_id}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <RenderIf isTrue={touched.purchase_order_id && errors.purchase_order_id}>
                <span className="text-danger mt-1">{errors.purchase_order_id}</span>
              </RenderIf>
            </Col>
            <Col>
              <Label className="d-flex">
                Suit#
                <p className="danger mb-0"> *</p>
              </Label>
              <Input
                name="suit_number"
                placeholder="Suit#"
                type="number"
                min={0}
                value={initialValues.suit_number}
                onChange={handleChange}
                onBlur={fetchIsLiquated}
                onFocus={handleFocus}
              />
              <RenderIf isTrue={touched.suit_number && errors.suit_number}>
                <span className="text-danger mt-1">{errors.suit_number}</span>
              </RenderIf>
            </Col>
            <Col>
              <Label className="d-flex">PO Type</Label>
              <SimpleSelect
                name="orderable_type"
                value={orderableType}
                onChange={setOrderableType}
                options={orderableTypeOptions()}
                formStyle={{ minWidth: "250px", marginBottom: 0, marginRight: "0.5rem", marginLeft: "0.5rem" }}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <p className="text-primary float-right mb-1 cursor-pointer" onClick={addNewItem}>
                + Add Item
              </p>
              <div className="table-responsive">
                <table className="table table-centered select table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {["Item", "Total Received", "Qty. Multiplier", "Damaged Qty.", "Bin#/Qty", "Action"].map(
                        (x, index) => (
                          <th className={x === "Item" ? "tr-w" : ""} key={`table-header ${index}`}>
                            {x}
                          </th>
                        ),
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {initialValues.line_items.map((_, key) => (
                      <tr key={"_lineItems_" + key} className="mt-3 mb-3">
                        <td className="tr-w">
                          <span className="position-relative d-block min-height-55">
                            <Input
                              name={`line_items.${[key]}.item_title`}
                              placeholder="Item Title"
                              type="text"
                              value={initialValues.line_items?.[key]?.item_title}
                              onBlur={handleBlur}
                              onChange={handleDynamicChange}
                              onFocus={handleDynaimcFocus}
                            />
                            <RenderIf
                              isTrue={touched.line_items?.[key]?.item_title && errors.line_items?.[key]?.item_title}
                            >
                              <span className="text-danger mt-1">{errors.line_items?.[key]?.item_title}</span>
                            </RenderIf>
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              name={`line_items.${[key]}.quantity`}
                              placeholder="Total Received"
                              type="number"
                              min={1}
                              value={initialValues.line_items?.[key]?.quantity}
                              onBlur={handleBlur}
                              onChange={handleDynamicChange}
                            />
                            <RenderIf
                              isTrue={touched.line_items?.[key]?.quantity && errors.line_items?.[key]?.quantity}
                            >
                              <span className="text-danger mt-1">{errors.line_items?.[key]?.quantity}</span>
                            </RenderIf>
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              name={`line_items.${[key]}.qty_multiplier`}
                              placeholder="Qty. Multiplier"
                              type="number"
                              min={1}
                              value={initialValues.line_items?.[key]?.qty_multiplier}
                              onBlur={handleBlur}
                              onChange={handleDynamicChange}
                            />
                            <RenderIf
                              isTrue={
                                touched.line_items?.[key]?.qty_multiplier && errors.line_items?.[key]?.qty_multiplier
                              }
                            >
                              <span className="text-danger mt-1">{errors.line_items?.[key]?.qty_multiplier}</span>
                            </RenderIf>
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              name={`line_items.${[key]}.damaged_qty`}
                              placeholder="Damaged Qty."
                              type="number"
                              min={0}
                              value={initialValues.line_items?.[key]?.damaged_qty}
                              onBlur={handleBlur}
                              onChange={handleDynamicChange}
                            />
                            <RenderIf
                              isTrue={touched.line_items?.[key]?.damaged_qty && errors.line_items?.[key]?.damaged_qty}
                            >
                              <span className="text-danger cursor-pointer mt-1 text-wrap">
                                {errors.line_items?.[key]?.damaged_qty}
                              </span>
                            </RenderIf>
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <div className="d-flex align-items-center">
                              <div
                                className="d-flex align-items-center position-relative mr-2 rounded"
                                style={{ border: `1px solid ${appTheme === "light" ? "#ced4da" : "#32394e"}` }}
                              >
                                <Input
                                  value={binLocation?.[key]?.bin_number || ""}
                                  className="rounded-left"
                                  type="text"
                                  placeholder="Bin #"
                                  style={{ border: "none", width: "50%" }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const qty = binLocation?.[key]?.qty;
                                    checkValue(value, qty, key, "fromBin");
                                    setBinLocation((prevBinLocation) => ({
                                      ...prevBinLocation,
                                      [key]: {
                                        ...prevBinLocation[key],
                                        bin_number: value,
                                        qty,
                                      },
                                    }));
                                  }}
                                />
                                <VerticalLine
                                  key={`__bin_no_divider_line_${key}`}
                                  style={{
                                    marginTop: "0px",
                                    height: "24px",
                                    borderRightWidth: "1.5px",
                                    color: `${appTheme === "light" ? "#ced4da" : "#32394e"}`,
                                  }}
                                />
                                <Input
                                  value={binLocation?.[key]?.qty || ""}
                                  className="rounded-right"
                                  type="number"
                                  min={1}
                                  placeholder="Qty."
                                  style={{ border: "none", width: "50%" }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const binNumber = binLocation?.[key]?.bin_number;
                                    const errorBuffer = checkValue(value, binNumber, key, "fromQty");
                                    setBinLocation((prevBinLocation) => ({
                                      ...prevBinLocation,
                                      [key]: {
                                        ...prevBinLocation[key],
                                        qty: value,
                                        bin_number: binNumber,
                                      },
                                    }));
                                    if (errorBuffer) return;
                                    checkInitialQtyValidation(key, +value, "binQty");
                                  }}
                                />
                              </div>
                              <div className="align-items-center d-flex justify-space-between">
                                <RenderIf
                                  isTrue={getBinLength(key)}
                                  fallback={
                                    <Label
                                      id={`bin_location_${key}`}
                                      className="rounded-circle mb-0 bin-count extra-bin"
                                    >
                                      <RenderIf isTrue={getBinLength(key) >= 100} fallback={String(getBinLength(key))}>
                                        +99
                                      </RenderIf>
                                    </Label>
                                  }
                                >
                                  <Label
                                    id={`bin_location_${key}`}
                                    className="rounded-circle mb-0 bin-count extra-bin cursor-pointer"
                                  >
                                    <RenderIf isTrue={getBinLength(key) >= 100} fallback={getBinLength(key)}>
                                      +99
                                    </RenderIf>
                                  </Label>
                                  <UncontrolledPopover
                                    placementPrefix="bs-popover"
                                    placement="bottom-start"
                                    isOpen={isPopoverOpen?.[key]}
                                    toggle={() => setIsPopoverOpen({ [key]: !isPopoverOpen?.[key] })}
                                    hideArrow={true}
                                    positionFixed={true}
                                    trigger="legacy"
                                    target={`bin_location_${key}`}
                                    style={{
                                      backgroundColor: appTheme === "light" ? "#f8f9fa" : "#32394e",
                                      top: "18%",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="py-3 px-2">
                                    <div className="d-flex align-items-center justify-content-between" style={{ color: appTheme === "light" ? "#495057" : "white" }}>
                                        <span className="p-1">Bin#</span>
                                        <span>Qty</span>
                                        <span />
                                      </div>
                                      {initialValues?.line_items?.[key]?.bin_locations
                                        ?.filter((y) => y?.bin_number && y?.qty)
                                        ?.map((x, k) => (
                                          <React.Fragment key={k}>
                                            <div className="d-flex align-items-center">
                                              <div
                                                className="d-flex align-items-center position-relative mr-2 rounded"
                                                style={{
                                                  border: `1px solid ${appTheme === "light" ? "#ced4da" : "#32394e"}`,
                                                }}
                                              >
                                                <input
                                                  value={x?.bin_number}
                                                  className="p-2 rounded-left"
                                                  type="text"
                                                  placeholder="Bin #"
                                                  style={{ border: "none", width: "50%", color: appTheme === "light" ? "#495057" : "white", backgroundColor: appTheme !== "light" ? "#2c3245" : "white" }}
                                                  disabled
                                                />
                                                <VerticalLine
                                                  key={`__bin_no_divider_line_${key}`}
                                                  style={{
                                                    marginTop: "0px",
                                                    height: "24px",
                                                    borderRightWidth: "1.5px",
                                                    color: `${appTheme === "light" ? "#ced4da" : "#32394e"}`,
                                                  }}
                                                />
                                                <input
                                                  value={x?.qty}
                                                  className="p-2 rounded-right"
                                                  type="text"
                                                  placeholder="Qty."
                                                  style={{ border: "none", width: "50%", color: appTheme === "light" ? "#495057" : "white", backgroundColor: appTheme !== "light" ? "#2c3245" : "white" }}
                                                  disabled
                                                />
                                              </div>
                                              <i
                                                className=" text-danger mdi-sm mdi mdi-close-thick"
                                                onClick={() => removeBin(key, k + 1)}
                                              />
                                            </div>
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </UncontrolledPopover>
                                </RenderIf>
                                <i
                                  className="bx bx-plus color-primary fa-lg cursor-pointer px-1"
                                  onClick={() => handleAddBin(key)}
                                />
                              </div>
                            </div>
                            <RenderIf isTrue={binError?.[key]}>
                              <span className="text-danger cursor-pointer mt-1 text-wrap">{binError?.[key]}</span>
                            </RenderIf>
                          </span>
                        </td>
                        <td className="text-center">
                          <CheckBox
                            label="Oversized"
                            key={`__check_box___${key}`}
                            name={`__check_box__${key}`}
                            state={initialValues?.line_items?.[key]?.oversized}
                            className="min-height-55 mt-3"
                            setState={() => {
                              const updatedInitialValues = cp(initialValues);
                              const updatedLineItems = cp(updatedInitialValues.line_items);
                              const updatedLineItem = cp(updatedLineItems[key]);

                              updatedLineItem.oversized = !updatedLineItem.oversized;
                              updatedLineItems[key] = updatedLineItem;
                              updatedInitialValues.line_items = updatedLineItems;
                              setInitialValues(updatedInitialValues);
                            }}
                          />
                        </td>
                        <td>
                          <RenderIf isTrue={initialValues?.line_items?.length > 1}>
                            <span className="position-relative cursor-pointer d-block min-height-55 pt-2">
                              <i className=" text-danger mdi-sm mdi mdi-close-thick" onClick={() => removeItem(key)} />
                            </span>
                          </RenderIf>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <div className="model-footer">
            <Row className="d-flex justify-content-end mt-3">
              <PillBtn name="Cancel" color="primary" className="mr-3" onClick={toggleModal} outline />
              <PillBtn name="Confirm" type="submit" color="primary" className="mr-3" />
            </Row>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddShipmentModal;
