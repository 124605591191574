import { Card, CardBody, Collapse } from "reactstrap";
import FilterContext from "./FilterContext";
import React, { useContext } from "react";

export default function FilterCard ({ filterKey, filterTitle, children }) {
  const { expandedFilters, setExpandedFilters } = useContext(FilterContext);
  const expanded = expandedFilters[filterKey];
  return (
    <Card
      className="inventory-color filter-accordian-background"
      style={{ border: "1px solid #939CB91A", borderRadius: "5px" }}
    >
      <div
        className="d-flex justify-content-between align-items-center p-3 cursor-pointer"
        onClick={() => setExpandedFilters({ ...expandedFilters, [filterKey]: !expanded })}
      >
        <span>{filterTitle}</span>
        <i className={`text-primary bx bx-sm bx-chevron-${expanded ? "up" : "down"}`} />
      </div>
      <CardBody className="p-0">
        <Collapse isOpen={expanded}>
          <div
            className="d-flex flex-column justify-content-between align-items-start gap-3 p-3 card-text"
            style={{ borderTop: "1px solid #A6B0CF33" }}
          >
            {children}
          </div>
        </Collapse>
      </CardBody>
    </Card>
  );
}
