import React, { useState } from "react";
import { keys, map, omit } from "lodash";
import { Collapse } from "reactstrap";
import { USER_ROLES } from "../../../../constants";
import SeeMoreText from "../../../../components/Common/SeeMoreText";
import { getUserInfo } from "../../../../utils/common";

const getItemText = (id, key, value, seeMoreOpts) => {
  if (key !== "Item") return value;
  const length = getUserInfo()?.role === USER_ROLES.whStaff ? 150 : 100;
  return <SeeMoreText id={id} value={value} length={length} {...seeMoreOpts} />;
};

const ItemDetails = ({ data, collapse, isCollapse = true, omitFields = [] }) => {
  return isCollapse
    ? (
    <Collapse isOpen={collapse === data.id}>
      <Table data={data} omitFields={omitFields} />
    </Collapse>
      )
    : (
    <Table data={data} />
      );
};

const Table = ({ data, omitFields }) => {
  const [seeMore, setSeeMore] = useState();
  const tableData = omit(data?.item_details, omitFields);
  return (
    <table className="table table-centered table-nowrap inner-table mb-0">
      <tbody>
        <tr style={{ fontWeight: "750" }}>
          {keys(tableData).map((header, index) => (
            <th key={`collapse_${index}_header`}>{header}</th>
          ))}
        </tr>
      </tbody>
      <tbody>
        <tr>
          {map(tableData, (value, key) => (
            <td key={`collapse_${key}_col`}>{getItemText(data.id, key, value, { seeMore, setSeeMore })}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default ItemDetails;
