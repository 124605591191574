import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Row, Col, Label } from "reactstrap";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";
import Modal from "../../../components/Common/Modal";
import SweetAlert from "react-bootstrap-sweetalert";
// actions
import { updatePlatform, setSelectedPlatform, setPlatformEditModal } from "../../../store/actions";

// constants
import {
  MARKET_PLACES,
  OFFER_PRIORITY,
  ALLOW_ZIP_SETTING,
  SKIP_DELIVERY_DAYS_SUPPLIERS,
  SKIP_ZIP_CODE_SUPPLIERS,
  WALMART_OFFER_TYPE,
  THIRD_PARTY_SELLERS,
  THRESHOLD_STOCK_SUPPLIERS,
  MATCHING_SYSTEM_SUPPLIERS,
  CUSTOMER_TYPE,
} from "../../../constants";
import { toPascalCase, getUserInfo, RenderIf } from "../../../utils/common";
import { merge, startCase, map } from "lodash";

const allOfferPriority = (name) => {
  const obj = name ? WALMART_OFFER_TYPE : OFFER_PRIORITY;
  if (name) return obj;
  if (getUserInfo()?.account_id === 2) obj["Amazon Only"] = "amazon";
  return obj;
};
const hideDeliveryDaysSetting = ["zappos", "lucky_vitamin"].concat(SKIP_DELIVERY_DAYS_SUPPLIERS);
const enableShippingCheck = ["ifulfill_htls", "ifulfill_grid_iron", "azimporter", "coolpetstuff", "stuller", "topdawg", "thefulfiller"];
const optionKeys = ["allow_truck_delivery"];

const PlatformSupplierSettingModal = ({ account }) => {
  const dispatch = useDispatch();
  const { selectedPlatform, error, platformEditModalIsOpen } = useSelector((state) => state.Platform);
  const [isFbmOnly, setIsFbmOnly] = useState(false);
  const [statusConfirmationCogsTax, setStatusConfirmationCogsTax] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [handlingTime, setHandlingTime] = useState({
    isSendTaxToRepricer: Boolean(selectedPlatform.send_tax_to_repricer),
    time: selectedPlatform.handling_time,
  });
  const [shippingFee, setShippingFee] = useState({
    isFixedShipping: Boolean(selectedPlatform.shipping_fee_lock),
    supShipping: selectedPlatform.shipping_fee,
  });
  const toggleModal = () => {
    dispatch(setPlatformEditModal(!platformEditModalIsOpen));
  };

  const [isWalmart, isFulfiller, isPlumberstock] = ["walmart", "thefulfiller", "plumberstock"].map((supplier) => selectedPlatform.name === supplier);

  return (
    <Modal size="lg" isOpen={platformEditModalIsOpen} toggle={toggleModal}>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setHandlingTime(statusConfirmationCogsTax);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {`to ${statusConfirmationCogsTax?.isSendTaxToRepricer ? "Include" : "Exclude"} Tax in COGS sent to repricer`}
        </SweetAlert>
      </RenderIf>
      <div className="modal-header">
        <h5 className="modal-title">
          {toPascalCase(isFulfiller ? "The Fulfiller" : isPlumberstock ? "Plumbers Stock" : selectedPlatform.name)} Supplier Settings
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <RenderIf isTrue={error}>
          <div className="auto-hide-10">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          </div>
        </RenderIf>
        <div className="p-2">
          <AvForm
            onValidSubmit={(e, v) => {
              Object.keys(v).forEach((key) => v[key] === "" && (v[key] = null));
              optionKeys.forEach((key) => delete v[key]);
              dispatch(
                updatePlatform({
                  data: {
                    ...v,
                    options: selectedPlatform.options || {},
                    shipping_fee_lock: shippingFee.isFixedShipping,
                    shipping_fee: shippingFee.isFixedShipping ? shippingFee.supShipping || 0 : 0,
                    send_tax_to_repricer: handlingTime.isSendTaxToRepricer,
                    is_national_prime: selectedPlatform.is_national_prime,
                  },
                  id: selectedPlatform.id,
                }),
              );
            }}
          >
            <Row>
              <RenderIf
                isTrue={
                  !hideDeliveryDaysSetting.concat(enableShippingCheck).includes(selectedPlatform.name) && !isWalmart
                }
              >
                <Col md={6}>
                  <AvField
                    name="delivery_days"
                    label="Delivery days"
                    className="form-control"
                    placeholder="Delivery days limit"
                    type="number"
                    value={selectedPlatform.delivery_days}
                    min="1"
                    required
                    errorMessage="Delivery days are required"
                    helpMessage="If the supplier will deliver after this many days, we will consider it OOS"
                  />
                </Col>
              </RenderIf>
              <RenderIf isTrue={account?.data?.some((x) => x?.marketplace?.includes(MARKET_PLACES.amazon))}>
                <Col md={6}>
                  <Label className="d-flex justify-content-between">
                    <span className={handlingTime.time ? "" : "text-danger"}>Amazon Handling Time</span>
                  </Label>
                  <AvField
                    name="handling_time"
                    label=""
                    className="form-control"
                    placeholder="Handling time in days"
                    type="number"
                    onChange={(e) => setHandlingTime({ ...handlingTime, time: e.target.value })}
                    value={selectedPlatform.handling_time || ""}
                    min="0"
                    helpMessage={
                      <>
                        <RenderIf isTrue={handlingTime.isDynamicTime} fallback={""}>
                          <>
                            {`Select max from [Shipping Days - ${handlingTime.time}, 5]`}
                            <br />
                          </>
                        </RenderIf>
                        <small className="text-warning">
                          Handling time does not work when selling on Walmart Marketplace.
                        </small>
                      </>
                    }
                    required
                    errorMessage="Handling time is required"
                  />
                </Col>
              </RenderIf>
              <RenderIf
                isTrue={
                  ALLOW_ZIP_SETTING.includes(selectedPlatform.name) &&
                  !SKIP_ZIP_CODE_SUPPLIERS.includes(selectedPlatform.name) &&
                  !isWalmart
                }
              >
                <Col md={6}>
                  <AvField
                    name="zip_code"
                    label="Zip Code"
                    className="form-control"
                    placeholder="Zip Code"
                    helpMessage={
                      <>
                        {"For delivery days to be applicable zip code is required"}
                        <br />
                        <small className="text-warning">
                          This supplier sometimes shows different stock or price based on zip code. Please enter the zip
                          code that you want our system to use. If you do not enter one, we will use 48917
                        </small>
                      </>
                    }
                    type="text"
                    value={selectedPlatform.zip_code || ""}
                  />
                </Col>
              </RenderIf>
              <RenderIf isTrue={THRESHOLD_STOCK_SUPPLIERS.includes(selectedPlatform.name)}>
                <Col md={6}>
                  <AvField
                    name="threshold_stock"
                    label="Threshold Stock"
                    className="form-control"
                    placeholder="Threshold stock"
                    helpMessage="We will consider the stock amount we get from the supplier less than Threshold Stock amount."
                    type="number"
                    value={selectedPlatform.threshold_stock}
                    min="0"
                    required
                    errorMessage="Threshold stock is required"
                  />
                </Col>
              </RenderIf>
              <RenderIf
                isTrue={
                  [MATCHING_SYSTEM_SUPPLIERS.newegg, MATCHING_SYSTEM_SUPPLIERS.bjs].includes(selectedPlatform.name) ||
                  (!selectedPlatform.is_wholesaler && !THRESHOLD_STOCK_SUPPLIERS.includes(selectedPlatform.name))
                }
              >
                <Col md={6}>
                  <AvField
                    name="default_stock"
                    label="Default Stock"
                    className="form-control"
                    placeholder="Default stock"
                    helpMessage={"Units of stock pushed to the supplier when the supplier is \"in stock\"."}
                    type="number"
                    value={selectedPlatform.default_stock}
                    min="1"
                    required
                    errorMessage="Default stock is required"
                  />
                </Col>
              </RenderIf>
              <Col md={6}>
                <Label className="d-flex justify-content-between">
                  <span className={shippingFee.supShipping}>Shipping Override</span>
                  <AvGroup check className="mx-1">
                    <Label id="fixed_shipping_checkbox" check>
                      <div className="custom-control custom-switch custom-switch-lg">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="shipping_fee_lock"
                          checked={shippingFee.isFixedShipping}
                          onChange={() => null}
                        />
                        <label
                          onClick={() => {
                            setShippingFee({
                              ...shippingFee,
                              isFixedShipping: !shippingFee.isFixedShipping,
                            });
                          }}
                          className="custom-control-label"
                        >
                          Fixed Shipping Fee
                        </label>
                      </div>
                    </Label>
                  </AvGroup>
                </Label>
                <RenderIf isTrue={!shippingFee.isFixedShipping}>
                  <small>Using supplier shipping fee.</small>
                </RenderIf>
                <RenderIf isTrue={shippingFee.isFixedShipping}>
                  <AvField
                    label=""
                    name="shipping_fee"
                    className="form-control"
                    placeholder="Fixed Shipping Fee"
                    type="number"
                    errorMessage="Shipping Fee is required"
                    onChange={(e) => setShippingFee({ ...shippingFee, supShipping: e.target.value })}
                    value={shippingFee.supShipping || "0"}
                    min="0"
                    helpMessage="The amount will be used instead of any shipping which we get at the supplier."
                  />
                </RenderIf>
              </Col>
              <Col md={6}>
                <Label className="d-flex justify-content-between">
                  <AvGroup className="mx-1">
                    <div className="custom-control custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="is_dynamic_time"
                        checked={handlingTime.isSendTaxToRepricer}
                        onChange={() => null}
                      />
                      <label
                        onClick={() => {
                          setStatusConfirmationCogsTax({
                            ...handlingTime,
                            isSendTaxToRepricer: !handlingTime.isSendTaxToRepricer,
                          });
                          setIsOpen(true);
                        }}
                        className="custom-control-label"
                      >
                        Include Tax in COGS sent to repricer
                      </label>
                    </div>
                  </AvGroup>
                </Label>
                <AvField
                  name="tax"
                  label="Estimated Tax Percentage"
                  className="form-control"
                  placeholder="Default tax percentage"
                  helpMessage="This tax setting will affect estimated profit calculations in Ecom Circles only,
                  it will not affect repricing. If you want to consider tax while repricing,
                  please do it inside of the Repricer software."
                  type="number"
                  value={selectedPlatform.tax}
                  min="0"
                  max="99"
                />
              </Col>
              <RenderIf isTrue={[MARKET_PLACES.amazon].includes(selectedPlatform.name)}>
                <Col md={6}>
                  <AvField
                    name="refresher_offer_priority"
                    label="Refreshers"
                    className="form-control"
                    type="select"
                    required
                    value={selectedPlatform.refresher_offer_priority}
                    helpMessage={
                      <RenderIf
                        isTrue={
                          selectedPlatform.refresher_offer_priority === WALMART_OFFER_TYPE["Vetted 3rd Party Sellers"]
                        }
                        fallback={""}
                      >
                        <p>({THIRD_PARTY_SELLERS.map((x) => startCase(x)).join(", ")})</p>
                      </RenderIf>
                    }
                    onChange={(e) => {
                      if (
                        e.target.value === allOfferPriority(selectedPlatform.name === MARKET_PLACES.walmart)["FBM Only"]
                      )
                        setIsFbmOnly(true);
                      else setIsFbmOnly(false);
                      dispatch(
                        setSelectedPlatform({
                          ...selectedPlatform,
                          refresher_offer_priority: e.target.value,
                        }),
                      );
                    }}
                  >
                    <option value="">Select an option</option>
                    {Object.keys(allOfferPriority(selectedPlatform.name === MARKET_PLACES.walmart)).map(
                      (key, index) => (
                        <option
                          key={"_role_opt_" + index}
                          value={allOfferPriority(selectedPlatform.name === MARKET_PLACES.walmart)[key]}
                        >
                          {key}
                        </option>
                      ),
                    )}
                  </AvField>
                </Col>
              </RenderIf>
              <RenderIf
                isTrue={
                  selectedPlatform.name === MARKET_PLACES.amazon &&
                  (isFbmOnly || selectedPlatform.refresher_offer_priority !== allOfferPriority()["FBA Only"])
                }
              >
                <>
                  <Col md={3}>
                    <AvField
                      name="min_reviews"
                      label="Minimum Reviews"
                      className="form-control"
                      placeholder="# of feedback"
                      type="number"
                      min="1"
                      value={selectedPlatform.min_reviews}
                      required
                    />
                  </Col>
                  <Col md={3}>
                    <AvField
                      name="min_feedback"
                      label="Minimum Feedback %"
                      className="form-control"
                      placeholder="feedback %"
                      type="number"
                      value={selectedPlatform.min_feedback}
                      min="1"
                      max="100"
                      required
                    />
                  </Col>
                </>
              </RenderIf>
              <RenderIf isTrue={selectedPlatform.name === MARKET_PLACES.lowes}>
                <Col md={6}>
                  <AvGroup check className="mx-1">
                    <Label check>
                      <AvInput
                        type="checkbox"
                        name="allow_truck_delivery"
                        value={!!selectedPlatform.options?.allow_truck_delivery}
                        onChange={() => {
                          setSelectedPlatform({
                            ...selectedPlatform,
                            options: merge(selectedPlatform.options || {}, {
                              allow_truck_delivery: !selectedPlatform.options?.allow_truck_delivery,
                            }),
                          });
                        }}
                      />
                      Allow Truck Delivery
                    </Label>
                  </AvGroup>
                </Col>
              </RenderIf>
              <RenderIf isTrue={selectedPlatform.name === MARKET_PLACES.amazon}>
                <div className="d-flex align-items-center">
                <Col md={6}>
                  <AvField
                    name="customer_type"
                    label="Customer Type"
                    className="form-control"
                    type="select"
                    required
                    value={selectedPlatform.customer_type}
                    onChange={(e) => {
                      dispatch(
                        setSelectedPlatform({
                          ...selectedPlatform,
                          customer_type: e.target.value,
                        }),
                      );
                    }}
                  >
                    {map(CUSTOMER_TYPE, (value, key) => (<option
                          key={"customer_type" + key}
                          value={value}
                        >
                          {startCase(key)}
                        </option>))}
                  </AvField>
                </Col>
                <Col md={7}>
                <AvGroup check>
                    <Label id="national_prime" check>
                      <div className="custom-control custom-switch custom-switch-sm">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="is_national_prime"
                          checked={selectedPlatform.is_national_prime}
                          onChange={() => null}
                        />
                        <label
                          onClick={() => {
                            dispatch(setSelectedPlatform({
                              ...selectedPlatform,
                              is_national_prime: !selectedPlatform.is_national_prime,
                            }));
                          }}
                          className="custom-control-label"
                        >
                          National Prime
                        </label>
                      </div>
                    </Label>
                  </AvGroup>
                </Col>
                </div>
              </RenderIf>
            </Row>
            <div className="mt-3">
              <Row className="float-right">
                <button onClick={toggleModal} className="btn btn-outline-primary waves-effect waves-light m-1">
                  Cancel
                </button>
                <button className="btn btn-primary waves-effect waves-light m-1" type="submit">
                  Update
                </button>
              </Row>
            </div>
          </AvForm>
        </div>
      </div>
    </Modal>
  );
};

export default PlatformSupplierSettingModal;
