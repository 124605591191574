export const FETCH_UNVERIFIED_MATCHES = "FETCH_UNVERIFIED_MATCHES";
export const FETCH_UNVERIFIED_MATCHES_SUCCESS = "FETCH_UNVERIFIED_MATCHES_SUCCESS";
export const FETCH_MARKET_PLACE_CATEGORIES = "FETCH_MARKET_PLACE_CATEGORIES";
export const FETCH_MARKET_PLACE_CATEGORIES_SUCCESS = "FETCH_MARKET_PLACE_CATEGORIES_SUCCESS";
export const FETCH_MARKET_PLACE_STORES = "FETCH_MARKET_PLACE_STORES";
export const FETCH_MARKET_PLACE_STORES_SUCCESS = "FETCH_MARKET_PLACE_CATEGORIES_STORES";
export const SET_SELECTED_UNPUBLISHED_LISTINGS = "SET_SELECTED_UNPUBLISHED_LISTINGS";
export const LIST_UNVERIFIED_MATCHES = "LIST_UNVERIFIED_MATCHES";
export const LIST_UNVERIFIED_MATCHES_SUCCESS = "LIST_UNVERIFIED_MATCHES_SUCCESS";
export const UPDATE_LISTINGS_DATA_SUCCESS = "UPDATE_LISTINGS_DATA_SUCCESS";
export const SET_BULK_UNVERIFIED_MATCHES_MODAL = "SET_BULK_UNVERIFIED_MATCHES_MODAL";
export const SUBMIT_UNVERIFIED_MATCHES_REQUEST = "SUBMIT_UNVERIFIED_MATCHES_REQUEST";
export const SUBMIT_UNVERIFIED_MATCHES_REQUEST_SUCCESS = "SUBMIT_UNVERIFIED_MATCHES_REQUEST_SUCCESS";
export const API_ERROR = "FETCH_UNPUBLISHED_LISTINGS_ERROR";
