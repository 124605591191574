import moment from "moment";
import { DATE_FORMATS } from "../../constants";
import { humanize } from "../../utils/common";
import $ from "lodash";

export const options = {
  chart: {
    toolbar: { show: false },
  },
  dataLabels: { enabled: false },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#556ee6", "#34c38f"],
  xaxis: {
    type: "datetime",
    tickAmount: 3,
    labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE_FORMATS.DATE) }, // Timestamp is ALWAYS UTC
  },
  yaxis: { labels: { formatter: (x) => humanize(x) } },
  grid: { borderColor: "#f1f1f1" },
};

export const filtersMapping = { 7: "week", 30: "month", 365: "year" };
export const colors = ["primary", "success", "danger", "secondary", "warning"];

export const getDateParamObj = (obj) => {
  const [startDate, endDate] = obj.dates;

  return {
    start_date: startDate.format("YYYY-MM-DD"),
    end_date: endDate.format("YYYY-MM-DD"),
    utc_offset: obj.utcOffset || moment().format("Z"),
    ...$.omit(obj, ["dates", "utcOffset"]),
  };
};

export const fetchPaginatedRecords = (data, filter) =>
  $.slice(data, ...[1, 0].map((value) => (filter.page - value) * filter.per_page));
