import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from "../components/AddCard";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

// components
import { Alert, Card, CardBody, Row, Col } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import PillBtn from "../../../components/Common/PillBtn";
import BarePay from "../../../components/Common/BarePay";
import CustomTooltip from "../../../components/Common/CustomTooltip";

// libs
import moment from "moment-timezone";

// actions
import {
  setPreloader,
  retryWhCharges,
  getPaymentSources,
  deleteSource,
  setAddCardModal,
  updatePaymentSource,
} from "../../../store/actions";

import { RenderIf } from "../../../utils/common";

const promise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Payment = (props) => {
  const queryParams = queryString.parse(document.location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const { sources, loading, success, error, addCardModalIsOpen } = useSelector((state) => state.Stripe);
  const { appTheme } = useSelector((state) => state.Layout);
  const { account } = props;
  const [cardError, setCardError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  function CloseAddCardModal (e) {
    dispatch(setAddCardModal(!addCardModalIsOpen));
    !e && dispatch(getPaymentSources());
  }

  function paymentMethodRequired (acc) {
    return (
      !acc.payment_source_added &&
      (moment(acc?.trial_end) < moment() ||
        (moment(acc?.trial_end) > moment() && moment(acc.last_error_dismissed).add(24, "hours") < moment()))
    );
  }

  useEffect(() => {
    if (!sources) return;
    if (queryParams.upgrade && !sources.length) {
      setCardError("Please add card first to upgrade your plan");
      dispatch(setAddCardModal(true));
    } else if (queryParams.upgrade && sources.length) {
      history.push("/settings?billing=true&plan_type=basic&trial=false");
    }
  }, [queryParams.upgrade, sources]);

  useEffect(() => {
    setPreloader(loading);
  }, [loading]);

  useEffect(() => {
    dispatch(getPaymentSources());
  }, []);

  useEffect(() => {
    account?.data && setAddCardModal(paymentMethodRequired(account?.data));
  }, [account]);

  return (
    <React.Fragment>
      {sources?.some((x) => x.default_source && x.failure_message) && <BarePay />}
      {account?.data && addCardModalIsOpen && (
        <Elements stripe={promise}>
          <AddCard isOpen={addCardModalIsOpen} toggleOpen={CloseAddCardModal} />
        </Elements>
      )}
      {success && (
        <div className="auto-hide">
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {success}
          </Alert>
        </div>
      )}
      {(error || cardError) && (
        <div className="auto-hide">
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error || cardError}
          </Alert>
        </div>
      )}

      {isOpen && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(updatePaymentSource(formData));
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          To update your default payment method!
        </SweetAlert>
      )}

      <Breadcrumb
        title={document.location.href.includes("agency") ? "Cards" : undefined}
        children={
          !sources?.length && (
            <div className="d-flex justify-content-end">
              <PillBtn
                color="primary"
                title="Add Card"
                name="Add Card"
                icon="bx bx-xs bx-plus"
                onClick={() => dispatch(setAddCardModal(true))}
              />
            </div>
          )
        }
      />

      <Card>
        <CardBody>
          {sources?.length ? (
            <Row>
              {sources?.map((item, key) => (
                <Col md={4} key={key}>
                  <Card
                    className={`payment-card${appTheme === "light" ? "-light" : ""} ${
                      item.failure_message ? "red" : ""
                    }`}
                    key={key}
                  >
                    <CardBody>
                      <div className="row">
                        <div className="col-md-5">
                          <div className="d-flex flex-direction-column row-gap-10">
                            <span>Card Number</span>
                            <h6>**** **** **** {item.last4}</h6>
                          </div>

                          <div className="d-flex flex-direction-column row-gap-10">
                            <span>Card holder name</span>
                            <h6>{item?.name}</h6>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex align-items-end flex-direction-column">
                            <div className="d-flex flex-direction-column row-gap-10">
                              <div className="d-flex grid-column-gap-5 icon-portion">
                                <RenderIf isTrue={item.failure_message}>
                                  <div className="d-flex">
                                    <RenderIf isTrue={item.error_detail}>
                                      <CustomTooltip
                                        target={`warning-${key}`}
                                        content={item.error_detail}
                                        placement="top"
                                      />
                                    </RenderIf>
                                    <span
                                      id={`warning-${key}`}
                                      className="text-danger"
                                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                                    >
                                      <i className="dripicons-warning text-danger" />
                                      {item.failure_message}
                                    </span>
                                  </div>
                                </RenderIf>
                                {!item.default_source && (
                                  <i
                                    title="Make this card to default"
                                    className="bx bx-xs bx-log-in text-primary cursor-pointer"
                                    onClick={() => {
                                      setFormData(item.id);
                                      setIsOpen(true);
                                    }}
                                  />
                                )}
                                <i
                                  title="Retry WH Charges"
                                  className="bx font-size-16 bx-reset text-primary cursor-pointer"
                                  onClick={() => dispatch(retryWhCharges({ retry: true, id: item.id }))}
                                ></i>
                                {item.default_source && (
                                  <i className="bx bxs-check-circle font-size-16 cursor-pointer text-success"></i>
                                )}
                              </div>
                              <i
                                className={`bx fs-30 card-type bxl-${item.brand?.toLowerCase()} text-primary cursor-pointer`}
                              ></i>
                            </div>
                            <span className="d-flex flex-direction-column row-gap-10">
                              <span>Exp</span>
                              <h6>{`${item.exp_month}/${item.exp_year}`}</h6>
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                    {!item.default_source && (
                      <div className="text-center p-3 bg-color-remove" onClick={() => setIsConfirmOpen(true)}>
                        <h6 className="text-center pt-1">Remove Card</h6>
                        {isConfirmOpen && (
                          <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                              dispatch(deleteSource(item.id));
                              setIsConfirmOpen(false);
                            }}
                            onCancel={() => setIsConfirmOpen(false)}
                          >
                            To Delete this Payment Method
                          </SweetAlert>
                        )}
                      </div>
                    )}
                  </Card>
                </Col>
              ))}
              <Col md={4}>
                <Card className="add-card" onClick={() => dispatch(setAddCardModal(true))}>
                  <div className="text-center">
                    <i className="bx bx-plus fs-30" />
                    <h6>Add Other Card</h6>
                  </div>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Records Found</h1>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Payment;
