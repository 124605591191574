import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Card } from "reactstrap";

// redux actions
import { setPreloader, updateOnboardingSettings, fetchAuthTokenEmail } from "../../../store/actions";

import gmailLogo from "../../../assets/svg/onboard/emailForwarder/gmail/logo.svg";
import outlookLogo from "../../../assets/svg/onboard/emailForwarder/outlook/logo.svg";
import yahooLogo from "../../../assets/svg/onboard/emailForwarder/yahoo/logo.svg";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";

const EmailForwarder = (props) => {
  const dispatch = useDispatch();
  const { account, loading } = useSelector((state) => state.Settings);
  const { otp, loading: authTokeLoading } = useSelector((state) => state.AuthToken);

  function handleForwarderSetup () {
    dispatch(updateOnboardingSettings({ forwarder_setting_visited: true }));
  }

  useEffect(() => {
    setPreloader(loading, authTokeLoading);
  }, [loading, authTokeLoading]);

  return (
    <React.Fragment>
      <Card className="mb-0">
        <h3 className="card-title m-3 text-center">Email Forwarding</h3>
        <br />
        <h3 className="card-title text-center">
          Please click the following images for instructions to set up email forwarding
        </h3>
        <div className="d-flex flex-column align-items-center">
          <Row>
            <Col>
              <a
                href="https://ecomcircles.helpdocs.com/auto-order/how-to-set-up-email-forwarding-for-gmail"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="img-fluid cursor-pointer" src={gmailLogo} alt="Gmail" />
              </a>
            </Col>
            <Col>
              <a
                href="https://ecomcircles.helpdocs.com/auto-order/how-to-set-up-email-forwarding-for-yahoo?preview=true"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="img-fluid cursor-pointer" src={yahooLogo} alt="Yahoo" />
              </a>
            </Col>
            <Col>
              <a
                href="https://ecomcircles.helpdocs.com/auto-order/how-to-set-up-email-forwarding-for-outlook?preview=true"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="img-fluid cursor-pointer" src={outlookLogo} alt="Outlook" />
              </a>
            </Col>
          </Row>
          <br />
          <div>Please enable forwarding to following email</div>
          <br />
          <div>
            {account.data.forwarder_email
              ? (
              <>
                {account.data.forwarder_email}
                <CopyToClipBoard text={account.data.forwarder_email} />
              </>
                )
              : null}
          </div>
          <br />
          {props.onBoarding === false && (
            <div>
              <a href="/emails" target="_blank" rel="noopener noreferrer">
                Visit Parsed Email Section
              </a>
            </div>
          )}
          <br />
          <div className="py-2 d-flex flex-row align-items-center">
            <button
              onClick={() =>
                account?.data?.forwarder_email && dispatch(fetchAuthTokenEmail(account.data.forwarder_email))
              }
              type="button"
              className="btn btn-primary waves-effect waves-light mx-3"
            >
              <i className="bx bx-reset font-size-12 align-middle mr-1"></i> Get
            </button>
            <div>OTP for Email Setup: {otp?.auth_code}</div>
          </div>
          <br />
          <div className="px-4 py- row justify-content-end">
            <button
              className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-primary"
              onClick={() => {
                if ([undefined, false].includes(props.onBoarding)) props.setIsOpen(false);
                else props.history.push("/");
              }}
            >
              Skip
            </button>
            <button
              onClick={() => {
                handleForwarderSetup();
                if ([undefined, false].includes(props.onBoarding)) props.setIsOpen(false);
                else props.history.push("/");
              }}
              className="m-1 w-sm waves-effect waves-light btn btn-sm btn-primary"
            >
              Mark as Completed
            </button>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default EmailForwarder;

EmailForwarder.propTypes = {
  setActiveStep: PropTypes.func,
};
