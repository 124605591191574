import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { entries, values as _values } from "lodash";
import { Row, Col, Label, Input, Button } from "reactstrap";
import Modal from "../../../components/Common/Modal";

// actions
import { submitCancelFeedback, setCancelFeedbackModal } from "../../../store/actions";

const CancelFeedback = (props) => {
  const dispatch = useDispatch();
  const { isCancelFeedbackModalOpen: cancelModal } = useSelector((state) => state.Settings);
  const other = "Other";

  const mcqs = [
    {
      question: "Overall, how satisfied were you with the subscription / service?",
      answers: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
    },
    {
      question: "What was the primary reason for canceling your subscription / service?",
      answers: [
        "No longer needed it",
        "It didn't meet my needs",
        "Found an alternative",
        "Quality was less than expected",
        "Ease of use was less than expected",
        "Access to the service was less than expected",
        "Customer service was less than expected",
        other,
      ],
    },
    {
      question: "Would you use the product / service in the future?",
      answers: ["Definitely", "Probably", "Not Sure", "Probably Not", "Definitely Not"],
    },
    {
      question: "Would you recommend our product / service to colleagues or contacts within your industry?",
      answers: ["Definitely", "Probably", "Not Sure", "Probably Not", "Definitely Not"],
    },
    {
      question: "What could we do to improve our subscription / service?",
      answers: false,
    },
  ];

  const validate = (values) => {
    const errors = {};
    entries(values).forEach(([key, value]) => {
      if ((value.answer === other && !value.description) || value === "" || value.answer === "")
        errors[key] = "Field is Required";
    });
    return errors;
  };

  const initialValues = {
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onChange: (name, value, { props }) => props.handleFormChange(name, value),
    onSubmit: (values) => {
      dispatch(
        submitCancelFeedback(
          _values(values).reduce((acc, value) => {
            if (value.answer === other) value.answer = value?.description || "";
            delete value?.description;
            acc.push(value);
            return acc;
          }, []),
        ),
      );
    },
  });

  const toggleModal = () => dispatch(setCancelFeedbackModal(!cancelModal));

  return (
    <Modal size="lg" isOpen={cancelModal} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Feedback Form
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row className="align-items-end">
            {mcqs.map((questionnaire, index) => (
              <Col lg="12 mb-2" key={`_ques_${index}`}>
                <Label>
                  {`${index + 1}. `}
                  {questionnaire.question}
                </Label>
                <div className="d-flex flex-column">
                  {Array.isArray(questionnaire.answers)
                    ? (
                        questionnaire.answers.map((answer, i) => (
                      <React.Fragment key={`__fragmentCancelFeedback${i}__`}>
                        <div className="custom-control custom-radio custom-control-inline mb-2" key={i + "_option_"}>
                          <Input
                            type="radio"
                            id={`${index}_q_${i}_a`}
                            value={i}
                            name={`question${index + 1}`}
                            onChange={(e) =>
                              formik.setFieldValue(`question${index + 1}`, {
                                question: questionnaire.question,
                                answer,
                              })
                            }
                            className="custom-control-input"
                          />
                          <Label className="custom-control-label" htmlFor={`${index}_q_${i}_a`}>
                            {answer}
                          </Label>
                        </div>
                        {formik.values[`question${index + 1}`].answer === other && answer === other && (
                          <>
                            <Label>If Other, Please Specify</Label>
                            <textarea
                              className="form-control"
                              id="question2_description"
                              rows="5"
                              name={`question${index + 1}`}
                              onChange={(e) => {
                                formik.setFieldValue(`question${index + 1}`, {
                                  ...formik.values[`question${index + 1}`],
                                  description: e.target.value,
                                });
                              }}
                            ></textarea>
                          </>
                        )}
                      </React.Fragment>
                        ))
                      )
                    : (
                    <textarea
                      className="form-control"
                      id="question5"
                      rows="5"
                      name="question5"
                      onChange={(e) =>
                        formik.setFieldValue("question5", {
                          question: questionnaire.question,
                          answer: e.target.value,
                        })
                      }
                    ></textarea>
                      )}
                  {formik.touched[`question${index + 1}`] && formik.errors[`question${index + 1}`]
                    ? (
                    <span className="text-danger mt-1">{formik.errors[`question${index + 1}`]}</span>
                      )
                    : null}
                </div>
              </Col>
            ))}
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Skip
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default CancelFeedback;
