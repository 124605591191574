import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  createSupplierOrder,
  fetchNextOrder,
  updateSupplierOrderByTargetOrderId,
  bulkUpdateSupplierOrders,
  deleteSupplierOrderIdById,
  createOrderLog,
  fetchAllReturnLabels,
  fetchReturnLabelHistory,
} from "../../api";
// Login Redux States
import {
  CREATE_SUPPLIER_ORDER,
  UPDATE_TRACKING_INFO,
  DELETE_SUPPLIER_ORDER,
  MARK_AS_IGNORE,
  UPDATE_SUPPLIER_ORDER,
  FETCH_ALL_RETURN_LABEL_HISTORY,
  FETCH_RETURN_LABEL_HISTORY,
} from "./actionTypes";

import {
  apiError,
  createSupplierOrdersSuccess,
  updateTrackingInfoSuccess,
  deleteSupplierOrderSuccess,
  fetchAllReturnLabelHistorySuccess,
  markAsIgoreSuccess,
  updateSupplierOrderSuccess,
  fetchReturnLabelHistorySuccess,
} from "./actions";

import { setReturnAddressModal, manualFulfillmentCollapseIsOpen } from "../actions";

function * createSupplierOrderSaga ({ payload: { data } }) {
  try {
    const { items } = data;
    const res = yield call(createSupplierOrder, { items });
    if (res.success) {
      if (data.go_next) {
        const { data: result } = yield call(fetchNextOrder);
        data.history.push(`/source_orders_details/${result[0].marketplace_order_id}/${result[0].id}`);
        data.history.go();
      }
      yield put(createSupplierOrdersSuccess(res));
      yield put(manualFulfillmentCollapseIsOpen(false));
    } else yield put(apiError(res?.msg || res?.message));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * markAsIgnoreSaga ({ payload: { data, sourceOrderId } }) {
  try {
    const res = yield call(bulkUpdateSupplierOrders, { data });
    yield call(createOrderLog, {
      data: {
        source_order_id: sourceOrderId,
        created_by: "SYSTEM",
        note: "SupplierOrder Has Been Marked As Ignored",
      },
    });
    yield put(markAsIgoreSuccess(res));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * updateTrackingInfoSaga ({ payload: { data, targetOrderId } }) {
  try {
    const res = yield call(updateSupplierOrderByTargetOrderId, {
      data,
      targetOrderId,
    });
    yield put(updateTrackingInfoSuccess(res));
    yield put(setReturnAddressModal(false));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * deleteSupplierOrderSaga ({ payload: { data } }) {
  try {
    const { id, source_order_id: sourceOrderId, target_order_id: targetOrderId, sku } = data;
    const res = yield call(deleteSupplierOrderIdById, id);
    let note = `SupplierOrder has been deleted. Details targetOrderId: ${targetOrderId}`;
    if (sku) note += `, sku: ${sku}`;
    yield call(createOrderLog, {
      data: {
        source_order_id: sourceOrderId,
        created_by: "SYSTEM",
        note,
      },
    });
    yield put(deleteSupplierOrderSuccess(res));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * updateSupplierOrderSaga ({ payload: { data } }) {
  try {
    const res = yield call(bulkUpdateSupplierOrders, { data });
    yield put(updateSupplierOrderSuccess(res));
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * fetchAllReturnLabelHistorySaga ({ payload }) {
  try {
    const res = yield call(fetchAllReturnLabels, payload);
    yield put(fetchAllReturnLabelHistorySuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchReturnLabelHistorySaga ({ payload }) {
  try {
    const res = yield call(fetchReturnLabelHistory, payload);
    yield put(fetchReturnLabelHistorySuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchFetchReturnLabels () {
  yield takeEvery(FETCH_ALL_RETURN_LABEL_HISTORY, fetchAllReturnLabelHistorySaga);
}

export function * watchFetchReturnLabelHistory () {
  yield takeEvery(FETCH_RETURN_LABEL_HISTORY, fetchReturnLabelHistorySaga);
}

export function * watchCreateSupplierOrders () {
  yield takeEvery(CREATE_SUPPLIER_ORDER, createSupplierOrderSaga);
}

export function * watchUpdateTrackingInfo () {
  yield takeEvery(UPDATE_TRACKING_INFO, updateTrackingInfoSaga);
}

export function * watchDeleteSupplierOrder () {
  yield takeEvery(DELETE_SUPPLIER_ORDER, deleteSupplierOrderSaga);
}

export function * watchMarkAsIgnored () {
  yield takeEvery(MARK_AS_IGNORE, markAsIgnoreSaga);
}

export function * watchUpdateSupplierOrder () {
  yield takeEvery(UPDATE_SUPPLIER_ORDER, updateSupplierOrderSaga);
}

function * SupplierOrdersSaga () {
  yield all([
    fork(watchFetchReturnLabels),
    fork(watchFetchReturnLabelHistory),
    fork(watchCreateSupplierOrders),
    fork(watchUpdateTrackingInfo),
    fork(watchDeleteSupplierOrder),
    fork(watchMarkAsIgnored),
    fork(watchUpdateSupplierOrder),
  ]);
}

export default SupplierOrdersSaga;
