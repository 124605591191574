import React, { useState, useEffect } from "react";
import { TabContent, TabPane } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

// utils
import { encode, getUserInfo, isShopifyAPIURL, isSPAPIURL } from "../../utils/common";
import { onboardingStepsIds } from "../../constants";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";

// components
import Modal from "../../components/Common/Modal";

import Welcome from "./components/welcome";
import AmazonsetUp from "./components/amazonsetUp";
import WalmartsetUp from "./components/walmartsetUp";
import FacebooksetUp from "./components/facebooksetUp";
import ShopifySetUp from "./components/shopifysetUp";
import EmailForwarder from "./components/emailForwarder";
import VerifyUser from "./components/VerifyUser";
import Payment from "./components/Payment";
import Lister from "./components/Lister";

import { withRouter } from "react-router-dom";
import { setIsAmz } from "../../store/actions";
import BookCall from "../../components/Common/BookCall";

const promise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const onboardingSteps = [
  { id: onboardingStepsIds.verifyUser, component: VerifyUser },
  { id: onboardingStepsIds.welcom, component: Welcome },
  { id: onboardingStepsIds.amazonSetUp, component: AmazonsetUp },
  { id: onboardingStepsIds.walmartsetUp, component: WalmartsetUp },
  { id: onboardingStepsIds.facebooksetUp, component: FacebooksetUp },
  { id: onboardingStepsIds.shopifysetUp, component: ShopifySetUp },
  {
    id: onboardingStepsIds.payment,
    component: (props) => (
      <Elements stripe={promise}>
        <Payment {...props} />
      </Elements>
    ),
  },
  { id: onboardingStepsIds.emailForwarder, component: EmailForwarder },
  { id: onboardingStepsIds.lister, component: Lister },
];

const OnboardUser = (props) => {
  const dispatch = useDispatch();
  const { account, onBoardingStep, OnboardingFlow } = useSelector((state) => state.Settings);
  const [tenantAcc, setTenantAcc] = useState({});
  const user = getUserInfo();
  const [isOpen, setIsOpen] = useState(props.active || true);

  const [activeStep, setActiveStep] = useState(onBoardingStep || 0);

  const [steps, setSteps] = useState([]);

  function toggle (bool) {
    setIsOpen(bool);
    props.setActive(bool);
  }

  function chooseActiveStep () {
    let filteredSteps = onboardingSteps;

    if (props.onBoarding !== false) filteredSteps = onboardingSteps.filter((x) => props.steps.includes(x.id));
    setSteps(filteredSteps);
    if (filteredSteps.length && props.onBoarding !== false) {
      let _index = filteredSteps.findIndex((step) => step.id === onBoardingStep);
      if (_index === -1) _index = 0;
      const queryParameters = queryString.parse(props.location.search);
      if (filteredSteps[_index].id === onboardingStepsIds.welcom && isSPAPIURL() && isShopifyAPIURL(queryParameters)) {
        setActiveStep(filteredSteps[1]?.id || filteredSteps[_index]?.id);
      } else setActiveStep(filteredSteps[_index].id);
    } else if (props.onBoarding !== false) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    setTenantAcc(account?.data?.onboard || {});
  }, [account?.data]);

  useEffect(() => {
    if (activeStep === onboardingStepsIds.amazonSetUp && props.isOnboarding) dispatch(setIsAmz(true));
    else if (props.isOnboarding) dispatch(setIsAmz(false));
  }, [activeStep]);

  useEffect(() => {
    account?.data && chooseActiveStep();
  }, [tenantAcc.payment, tenantAcc.store_added]);

  return (
    <React.Fragment>
      <Modal
        size="lg"
        backdrop="static"
        isOpen={isOpen}
        toggle={() => props.setActive && props.setActive(true)}
        className="border-0"
      >
        <TabContent activeTab={activeStep}>
          {["pending_confirm", "onboarding"].includes(user?.status) &&
            account?.data &&
            !account?.data?.onboard?.confirm_email &&
            props.history.replace("/email_verification/" + encode(user?.email))}
          {(["create_schema", "setup_forwarder"].includes(user?.status) || props.active || true) &&
            steps.map((s, index, arr) => (
              <TabPane key={"_onboard_step" + index} tabId={s.id}>
                <s.component
                  setActiveStep={setActiveStep}
                  setIsOpen={toggle}
                  onBoarding={props.isChannel ? OnboardingFlow : props.onBoarding}
                  history={props.history}
                  account={account}
                  allSteps={arr}
                />
                <div className="p-4">
                  <hr />
                  <BookCall />
                </div>
              </TabPane>
            ))}
        </TabContent>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(OnboardUser);
