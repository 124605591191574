import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Button,
  Col,
  Label,
  Input,
  TabContent,
  TabPane,
  NavLink,
  Nav,
  NavItem,
  Collapse,
  Alert,
  FormGroup,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import CheckBox from "../../../components/Common/CheckBox";
import { keys, size } from "lodash";
import Select from "react-select";
// actions
import {
  setPreloader,
  fetchMarketplaceAccounts,
  fetchMarketplaceProductInfo,
  changeProductVariation,
  setupManualMatches,
  createInventoryListing,
  resetManualMatchState,
  fetchInventoryItemInfo,
  fetchRestrictedBrands,
  checkListingOnStore,
} from "../../../store/actions";

import { apiError } from "../../../store/listings/actions";
// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Carousel from "../../../components/Common/Carousel";
import SimpleAlert from "../../../components/Common/SimpleAlert";

// Component
import DynamicFields from "../../../components/Common/DynamicFields";

// Common and Constants
import { listingPlatforms } from "../../../constants/index";
import { cleanedData } from "../Helpers/common";
import {
  toPascalCase,
  getUserInfo,
  checkPlanAvailability,
  matchString,
  RenderIf,
  formatNumber,
} from "../../../utils/common";

const AddNewListing = (props) => {
  const dispatch = useDispatch();
  const { sku } = useParams();
  const isInventoryListing = !!sku;

  useEffect(() => {
    dispatch(fetchRestrictedBrands());
    if (sku) dispatch(fetchInventoryItemInfo(sku));
  }, []);

  const [noStoreError, setNoStoreError] = useState("");
  const [errorSupplier, setErrorSupplier] = useState(false);
  const [listInventory, setListInventory] = useState(false);
  const [warningSameURL, setWarningSameURL] = useState({
    value: "",
    same: false,
  });
  const [nestedVariation, setNestedVariation] = useState([{ name: "", value: "", error: [] }]);
  const [collapse, setCollapse] = useState(false);
  const [tab, setTab] = useState(0);
  const [platform, setPlatform] = useState("walmart");
  const amazonRegex = new RegExp(
    /(https?:\/\/)?(www\.)?amazon\.[a-zA-Z]{2,6}\b\/(gp\/(product|offer-listing)|([-0-9A-Za-z%]+\/)?dp(\/product)?|(exec\/obidos\/asin))\/[0-9A-Za-z]{10}\b(\/[-a-zA-Z0-9()@:%_+.~#?&/=]*)?/,
  );
  const walmartRegex = new RegExp(
    /(https?:\/\/)?(www\.)?walmart\.[a-zA-Z]{2,6}\b\/(ip(\/seort)?|product)\/([-0-9A-Za-z%]+\/)?\d{1,14}([-a-zA-Z0-9@:%_+~#?&/="+.]+)/,
  );
  const urlRegex = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?(?:[-a-zA-Z0-9@:%_+~#=]{1,90}\.)+[a-z]{2,6}\b((?:[-a-zA-Z0-9@:%_+~#?&//="]+\.)*[-a-zA-Z0-9@:%_+~#?&//="]+)?/,
  );
  const { accounts, loading: loadingMarketplace } = useSelector((state) => state.MarketPlaceAccount);

  const {
    showInputForm,
    success,
    error,
    loading,
    supplier,
    marketplace,
    profit,
    brand_restricted: brandRestricted,
    whItemData,
  } = useSelector((state) => state.Listings);

  const whItemDataBuffer = whItemData?.inventory;

  const { account } = useSelector((state) => state.Settings);

  const [formData, setFormData] = useState({
    marketplace_shipping: profit,
    quantity_multiplier: 1,
    stock_lock: false,
    consumer_id: { value: "", label: "Select a store", platform },
  });

  useEffect(() => {
    !accounts && dispatch(fetchMarketplaceAccounts({}));
  }, []);

  useEffect(() => {
    if (!size(keys(whItemData))) return;
    setListInventory(true);
    setFormData({
      ...formData,
      sku: whItemDataBuffer?.sku,
      custom_sku: whItemDataBuffer?.sku,
      supplier_stock: whItemDataBuffer?.qty,
      supplier_price: whItemDataBuffer?.price,
      supplier_shipping: whItemDataBuffer?.shipping,
    });
  }, [whItemData]);

  const marketplaceAccounts = [...new Set(accounts?.data.map((account) => account.marketplace))];

  useEffect(() => {
    dispatch(setPreloader(loading || loadingMarketplace));
  }, [loading, loadingMarketplace]);

  useEffect(() => {
    if (marketplaceAccounts.length === 1 && marketplaceAccounts[0] === "amazon" && !loading) {
      changeTab(1);
    }
  }, [marketplaceAccounts]);

  useEffect(() => {
    if (sku && formData.consumer_id && formData.marketplace_id && formData.custom_sku) {
      dispatch(
        checkListingOnStore({
          platform,
          consumer_id: formData.consumer_id.value,
          marketplace_id: formData.marketplace_id,
          sku: formData.custom_sku,
        }),
      );
    }
  }, [formData.consumer_id, formData.marketplace_id, formData.custom_sku]);

  const changeTab = (tabIndex) => {
    if (tab !== tabIndex) {
      setTab(tabIndex);
      setFormData({
        marketplace_shipping: profit,
        quantity_multiplier: 1,
        ...formData,
      });
      tabIndex === 0 && setPlatform("walmart");
      tabIndex === 1 && setPlatform("amazon");
      dispatch(resetManualMatchState());
    }
  };

  const calcProfit = ({ sourcePrice, sourceShipping, destinationPrice, quantityMultiplier }) => {
    const comission = 15;
    const breakEvenPrice =
      (parseFloat(sourcePrice || 0) * parseInt(quantityMultiplier || 1) + parseFloat(sourceShipping || 0)) /
      ((100 - comission) * 0.01);
    const p = parseFloat(destinationPrice || 0) - breakEvenPrice;
    return p.toFixed(2);
  };

  const getPlaformLabel = (label) => {
    switch (String(label).toLocaleLowerCase()) {
      case "amazon":
        label = "ASIN or URL";
        break;
      case "walmart":
        label = "Product Id or URL";
        break;
      default:
        label = "Marketplace Id";
        break;
    }
    return label;
  };

  const checkSupplierError = (url) => {
    if (
      listingPlatforms?.find((platform) => matchString(String(url), platform)) ||
      String(url).toLowerCase().includes(".bjs.")
    )
      setErrorSupplier(false);
    else setErrorSupplier(true);
  };

  const checkSamePlatform = (form) => {
    if (!form.marketplace_id.includes(".com")) setWarningSameURL({ ...warningSameURL, same: false });
    else {
      if (
        form.supplier_url.split(".com").shift().split(".").pop() ===
        form.marketplace_id.split(".com").shift().split(".").pop()
      ) {
        setWarningSameURL({
          ...warningSameURL,
          value: form.supplier_url.split(".com").shift().split(".").pop(),
          same: true,
        });
      } else setWarningSameURL({ ...warningSameURL, same: false });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {success && <SimpleAlert className="my-1" msg={success} />}

          {account?.data && !checkPlanAvailability(account?.data) && (
            <SimpleAlert color="warning" msg="You exceeded your current plan" />
          )}

          {error && <SimpleAlert color="danger" className="my-1" msg={error} />}

          {errorSupplier && (
            <SimpleAlert
              color="danger"
              className="my-1"
              msg="We do not support this supplier yet so you will need to monitor it manually for now"
            />
          )}

          {warningSameURL.same && (
            <SimpleAlert
              color="warning"
              className="my-1"
              msg={`You want to list an ${toPascalCase(warningSameURL.value)} supplier for the ${toPascalCase(
                warningSameURL.value,
              )} marketplace.`}
            />
          )}

          {/* Render Breadcrumb */}
          <Breadcrumbs title="Product Suppliers" />
          <CardBody className="p-0">
            <Nav tabs className="nav-tabs-custom nav-justified">
              {marketplaceAccounts.includes("walmart") && (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: tab === 0,
                    })}
                    onClick={() => {
                      changeTab(0);
                    }}
                  >
                    <span className="d-none d-sm-block">Walmart</span>
                  </NavLink>
                </NavItem>
              )}
              {marketplaceAccounts.includes("amazon") && (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: tab === 1,
                    })}
                    onClick={() => {
                      changeTab(1);
                    }}
                  >
                    <span className="d-none d-sm-block">Amazon</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            <TabContent activeTab={0}>
              <TabPane tabId={0} className="py-3">
                <div>
                  <AvForm
                    onValidSubmit={(_, val) => {
                      val.platform = formData.consumer_id.platform;
                      if (!val.platform) return setNoStoreError("No Store Selected!");
                      checkSamePlatform(val);
                      dispatch(fetchMarketplaceProductInfo(val));
                    }}
                  >
                    <Row>
                      <Col>
                        <Label>Store</Label>
                        <FormGroup className="select2-container">
                          <Select
                            name="platform"
                            placeholder="Select store"
                            value={formData.consumer_id}
                            onFocus={() => setNoStoreError("")}
                            onChange={(e) => setFormData({ ...formData, consumer_id: e })}
                            options={accounts?.data?.reduce((list, acc) => {
                              if (acc.valid && acc.enabled && acc.marketplace === platform) {
                                list.push({
                                  value: `${acc.access_id}`,
                                  label: `${acc.name} (${acc.marketplace})`,
                                  platform,
                                });
                              }
                              return list;
                            }, [])}
                            classNamePrefix="select2-selection"
                          />
                          <RenderIf isTrue={noStoreError}>
                            <small className="text-danger">{noStoreError}</small>
                          </RenderIf>
                        </FormGroup>
                      </Col>
                      <RenderIf
                        isTrue={!isInventoryListing}
                        fallback={
                          <Col>
                            <Label>Inventory SKU</Label>
                            <AvField
                              name="inventory_sku"
                              disabled={isInventoryListing}
                              onBlur={(e) => e.target.value && checkSupplierError(e.target.value)}
                              value={sku}
                              validate={{
                                pattern: {
                                  value: /^[0-9a-zA-Z\-_]+$/,
                                  errorMessage:
                                    "SKU should contain only alphanumeric characters with separated by dashes.",
                                },
                              }}
                              className="form-control"
                              placeholder="Enter inventory sku here"
                              type="text"
                              required
                            />
                          </Col>
                        }
                      >
                        <Col>
                          <Label>Supplier URL</Label>
                          <AvField
                            name="supplier_url"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                supplier_url: e.target.value,
                              })
                            }
                            onBlur={(e) => e.target.value && checkSupplierError(e.target.value)}
                            value=""
                            validate={{
                              pattern: {
                                value: urlRegex,
                                errorMessage: "Invalid Supplier URL",
                              },
                            }}
                            className="form-control"
                            placeholder="Enter url here"
                            type="text"
                            required
                          />
                        </Col>
                      </RenderIf>
                    </Row>
                    <Label>{getPlaformLabel(platform)}</Label>
                    <Row className="">
                      <Col md={6}>
                        <AvField
                          name="marketplace_id"
                          value=""
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              marketplace_id: e.target.value,
                            });
                          }}
                          validate={{
                            async: (value) => {
                              const urlTest = (platform === "walmart" ? walmartRegex : amazonRegex).test(value);
                              if (!urlTest) return /^[0-9A-Za-z]+$/.test(value);
                              return urlTest;
                            },
                          }}
                          className="form-control"
                          placeholder={"Enter " + getPlaformLabel(platform)}
                          type="text"
                          required
                        />
                      </Col>
                      {account?.data && (
                        <RenderIf
                          isTrue={!isInventoryListing}
                          fallback={
                            <Col md={3} className="my-1">
                              <CheckBox
                                isSwitch={true}
                                name="permanent"
                                label="List item from inventory"
                                className="mt-2"
                                state={listInventory}
                                setState={(state) => {
                                  setListInventory(state);
                                  dispatch(apiError(""));
                                  if (!state) props.history.push("/listings/new");
                                }}
                              />
                            </Col>
                          }
                        >
                          <Col md={3}>
                            <Button
                              color="success"
                              className="w-100"
                              disabled={!checkPlanAvailability(account?.data)}
                              type="submit"
                            >
                              Next
                            </Button>
                          </Col>
                        </RenderIf>
                      )}
                    </Row>
                  </AvForm>

                  {(supplier || marketplace) && (
                    <Alert color="warning">
                      <i className="bx bx-info-circle pr-2"></i>
                      <strong>Please Note: </strong>
                      When you list this item, our system will get the latest prices and then list this item at a
                      profitable price. From there the repricer will take over so there is no need to add a price before
                      listing.
                    </Alert>
                  )}

                  {brandRestricted && (
                    <Alert color="danger">
                      <i className="bx bx-info-circle pr-2"></i>
                      This brand is known to have IP-related issues. You can list it anyway, but we recommend that you
                      don't.
                    </Alert>
                  )}

                  {supplier?.all_variations && (
                    <Row className="align-items-center">
                      <Col md={12}>
                        <Label>Variations</Label>
                        <Input
                          readOnly
                          className="cursor-pointer"
                          value={Object.values(supplier?.selected_variation || {}).join(",")}
                          onClick={() => setCollapse(!collapse)}
                        />
                        <Collapse isOpen={collapse}>
                          <Card>
                            <CardBody>
                              <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>Variation Type</th>
                                      <th>Variations</th>
                                      <th>Url</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {supplier.all_variations.map((v, index) => (
                                      <tr
                                        className="cursor-pointer"
                                        key={index}
                                        onClick={() => {
                                          dispatch(
                                            changeProductVariation({
                                              url: v.url,
                                              quantity_multiplier: formData.quantity_multiplier,
                                              shipping_fee: formData.marketplace_shipping || marketplace.shipping_fee,
                                              price: formData.marketplace_price || marketplace.price,
                                              platform,
                                            }),
                                          );
                                          setFormData({
                                            ...formData,
                                            supplier_url: v.url,
                                          });
                                          setCollapse(false);
                                        }}
                                      >
                                        <td>
                                          <small>
                                            {supplier.variation_names &&
                                              toPascalCase(supplier.variation_names.join(", "))}
                                          </small>
                                        </td>
                                        <td>
                                          <small>{v.variation && toPascalCase(v.variation)}</small>
                                        </td>
                                        <td>
                                          <small>
                                            <a href={v.url} target="_blank" rel="noopener noreferrer">
                                              {v.url}
                                            </a>
                                          </small>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </Collapse>
                      </Col>
                    </Row>
                  )}
                  <AvForm
                    onValidSubmit={(e, values) => {
                      if (!loading)
                        setTimeout(() => document.querySelector("body").scrollIntoView({ behavior: "smooth" }), 500);
                      const isSafe = nestedVariation.every((x) => !x.error?.length);
                      if (!isSafe) return;
                      const variation = cleanedData(nestedVariation);
                      if (!formData.marketplace_id || !formData.consumer_id.value)
                        return dispatch(apiError("Please select store and enter product id or url"));
                      if (isInventoryListing && !error)
                        dispatch(
                          createInventoryListing({
                            history: props.history,
                            data: {
                              ...values,
                              platform,
                              variation,
                              va_id: getUserInfo().id,
                              seller_sku: formData.custom_sku,
                              marketplace_id: formData.marketplace_id,
                              consumer_id: formData.consumer_id.value,
                            },
                          }),
                        );
                      else
                        !isInventoryListing &&
                          dispatch(
                            setupManualMatches({
                              ...values,
                              ...formData,
                              consumer_id: formData.consumer_id.value,
                              platform,
                              variation,
                              marketplace_price: marketplace?.price,
                              marketplace_shipping: marketplace?.shipping_fee,
                              va_id: getUserInfo().id,
                              supplier_variation: Object.values(supplier?.selected_variation || {}).join(","),
                              profit:
                                calcProfit({
                                  destinationPrice: formData?.marketplace_price || marketplace?.price,
                                  sourcePrice: supplier?.price,
                                  sourceShipping: supplier?.shipping_fee,
                                  quantityMultiplier: formData.quantity_multiplier,
                                }) || profit,
                            }),
                          );
                    }}
                  >
                    <Row className="pt-3">
                      {marketplace || showInputForm || (keys(whItemDataBuffer)?.length && isInventoryListing) ? (
                        <>
                          <RenderIf
                            isTrue={(keys(whItemDataBuffer)?.length && isInventoryListing) || showInputForm}
                            fallback={
                              <Col>
                                <Row>
                                  <Col className="align-self-end">
                                    {marketplace?.image_urls ? (
                                      <Carousel
                                        items={marketplace.image_urls.map((x, i) => (
                                          <img
                                            src={x}
                                            key={i}
                                            className="d-block img-thumbnail img-fluid img-responsive"
                                            style={{ height: "30vh" }}
                                            alt="MarketPlace Product"
                                          />
                                        ))}
                                      />
                                    ) : null}
                                  </Col>

                                  <Col className="align-self-end">
                                    <h3>Marketplace</h3>
                                    <h6 className="font-weight-bold mb-2">{marketplace?.title}</h6>
                                    <h6>Current Marketplace Price: ${marketplace?.price}</h6>
                                  </Col>
                                </Row>
                              </Col>
                            }
                          >
                            <Col />
                          </RenderIf>
                          <Col>
                            <RenderIf isTrue={!keys(whItemDataBuffer)?.length && supplier}>
                              <Row>
                                <Col className="align-self-end">
                                  <h3>Supplier</h3>
                                  <h6 className="font-weight-bold">{supplier?.title}</h6>
                                  <h6>Supplier Price: ${supplier?.price}</h6>
                                  <h6>Supplier Shipping: ${supplier?.shipping_fee}</h6>
                                </Col>
                              </Row>
                            </RenderIf>
                            <Row>
                              <Col md="6">
                                <AvField
                                  label="Quantity Multiplier"
                                  name="quantity_multiplier"
                                  value={formData.quantity_multiplier}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      quantity_multiplier: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Quantity Multiplier"
                                  type="number"
                                  min="1"
                                />
                                <AvField
                                  label="Add a custom SKU here (optional)"
                                  name="sku"
                                  value={formData.custom_sku || ""}
                                  onChange={(e) => {
                                    setFormData({ ...formData, custom_sku: e.target.value });
                                  }}
                                  className="form-control"
                                  placeholder="Sku for listing product"
                                  type="text"
                                  validate={{
                                    pattern: {
                                      value: /^[0-9a-zA-Z\-_]+$/,
                                      errorMessage:
                                        "SKU should contain only alphanumneric characters with seprated by dashes.",
                                    },
                                  }}
                                />
                                <div className="d-flex flex-row">
                                  <div>
                                    <Label>Supplier Stock</Label>
                                  </div>
                                  <div className="d-flex ml-5">
                                    <Label>Stock Lock</Label>
                                    <AvInput
                                      type="checkbox"
                                      name="stock_lock"
                                      value={formData.stock_lock}
                                      onChange={() =>
                                        setFormData({
                                          ...formData,
                                          stock_lock: !formData.stock_lock,
                                          supplier_stock: formData.stock_lock ? "" : formData.supplier_stock,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <AvField
                                  name="supplier_stock"
                                  value={formData.supplier_stock ?? supplier?.stock ?? 0}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      supplier_stock: e.target.value,
                                    })
                                  }
                                  className={`${formData.stock_lock ? "" : "cursor-not-allowed"} form-control`}
                                  placeholder="Supplier Stock"
                                  type="number"
                                  min="1"
                                  disabled={!formData.stock_lock}
                                  required={formData.stock_lock}
                                />
                                <AvField
                                  label="Supplier Price"
                                  name="supplier_price"
                                  value={formData.supplier_price ?? supplier?.price ?? ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      supplier_price: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Supplier Price"
                                  type="number"
                                  min="0.1"
                                  step="0.01"
                                  required
                                />
                                <AvField
                                  label="Supplier Shipping"
                                  name="supplier_shipping"
                                  value={formData.supplier_shipping ?? supplier?.supplier_shipping ?? ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      supplier_shipping: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Supplier Shipping"
                                  type="number"
                                  min="0"
                                  step="0.01"
                                />
                                <AvField
                                  label="List Price"
                                  name="list_price"
                                  value=""
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      list_price: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="List Price"
                                  type="number"
                                  min="0.1"
                                  step="0.01"
                                />
                                <DynamicFields
                                  title="Variation"
                                  isMulti={true}
                                  nestedFields={nestedVariation}
                                  setNestedFields={setNestedVariation}
                                />
                              </Col>
                              <Col md="6">
                                {supplier?.image_urls && (
                                  <Carousel
                                    items={supplier.image_urls.map((x, i) => (
                                      <img
                                        src={x}
                                        key={i}
                                        className="d-block img-thumbnail img-fluid img-responsive"
                                        style={{ height: "30vh" }}
                                        alt="Supplier Img"
                                      />
                                    ))}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ) : null}
                    </Row>

                    {marketplace?.offers && platform === "amazon" && (
                      <Row className="py-4">
                        <Col md={6}>
                          <h6 className="font-weight-bold">Amazon Offers:</h6>
                          <Card>
                            <CardBody>
                              <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>Profit</th>
                                      <th>Price</th>
                                      <th>Shipping</th>
                                      <th>Shipping Days</th>
                                      <th>Seller Name</th>
                                      <th>Seller Rating</th>
                                      <th>No of Ratings</th>
                                      <th>Won Buybox</th>
                                      <th>FBA</th>
                                      <th>Prime offer</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {marketplace.offers.map((item, key) => (
                                      <tr key={"_offer_" + key}>
                                        <td>{formatNumber(item?.profit || "0")}</td>
                                        <td>{formatNumber(item?.offer_price || "0")}</td>
                                        <td>{formatNumber(item?.offer_shipping || "0")}</td>
                                        <td>{item?.shipping_days || "N/A"}</td>
                                        <td>{item?.seller_name}</td>
                                        <td>{"%" + item?.percentage_rating}</td>
                                        <td>{item?.total_ratings}</td>
                                        <td>{String(item?.won_buybox)}</td>
                                        <td>{String(item?.fulfilled_by_amazon)}</td>
                                        <td>{String(item?.prime_offer)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}

                    {marketplace?.offers && platform === "walmart" && marketplace.offers?.length ? (
                      <Row className="py-4">
                        <Col md={6}>
                          <h6 className="font-weight-bold">Walmart Offers:</h6>
                          <Card>
                            <CardBody>
                              <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>Profit</th>
                                      <th>Price</th>
                                      <th>Shipping</th>
                                      <th>Seller Name</th>
                                      <th>Seller Id</th>
                                      <th>Shipping Days</th>
                                      <th>Won Buybox</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {marketplace.offers.map((item, index) => (
                                      <tr key={"_wm_offer_" + index}>
                                        <td>{formatNumber(item?.profit || "0")}</td>
                                        <td>{formatNumber(item?.offer_price || "0")}</td>
                                        <td>{formatNumber(item?.offer_shipping || "0")}</td>
                                        <td>{item?.seller_name}</td>
                                        <td>{item?.seller_id}</td>
                                        <td>{item?.shipping_days}</td>
                                        <td>{String(item?.won_buybox)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : null}

                    {(supplier || isInventoryListing || showInputForm) && (
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <Button color="success" className="w-md" type="submit">
                            Add Match
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </AvForm>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddNewListing;
