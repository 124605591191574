import request from "../restClient";
import { makeQP } from "../../utils/common";

export const getAllUnverifiedMatches = async (params) => {
  return await request({ url: "/unverified_matches" + makeQP(params), method: "GET" });
};

export const submitMatchesRequest = async (body) => {
  return await request({ url: "/matches_requests", method: "POST", body });
};

export const listUnverifiedMatches = async (body) => {
  return await request({ url: "/unverified_matches/list_unverified_matches", method: "POST", body });
};

export const getMarketPlaceCategories = async ({ marketplace }) => {
  return await request({ url: `/unverified_matches/categories?marketplace=${marketplace}`, method: "GET" });
};

export const getMarketPlaceStores = async ({ marketplace }) => {
  return await request({ url: `/matches/get_stores?marketplace=${marketplace}`, method: "GET" });
};
