import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import * as actions from "../../../../store/actions";
import Modal from "../../../../components/Common/Modal";
import PillBtn from "../../../../components/Common/PillBtn";
import { RenderIf } from "../../../../utils/common";

const TagsModal = ({
  title,
  buttonTitle,
  tagId,
  currentTagData,
  selectedPlatform,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const { repricerTagsModalIsOpen } = useSelector((state) => state.Repricer);
  const resetFilters = () => setFilters({ ...filters, page: 1 });
  const toggleTagsModal = () => dispatch(actions.setRepricerTagsModal(!repricerTagsModalIsOpen));
  const [updateErrorCheck, setUpdateErrorCheck] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.tagNameField) errors.tagNameField = "Required";
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      tagNameField: tagId ? currentTagData?.name : "",
    },
    validate,
    onSubmit: (values) => {
      if (tagId) {
        if (formik.values.tagNameField !== currentTagData?.name) {
          dispatch(actions.updateListingsTags(selectedPlatform, tagId, { name: values?.tagNameField }));
          setUpdateErrorCheck(false);
          toggleTagsModal();
        } else setUpdateErrorCheck(true);
      } else {
        dispatch(actions.createListingsTags(selectedPlatform, { name: values?.tagNameField }, resetFilters));
        toggleTagsModal();
      }
    },
  });
  return (
    <Modal style={{ maxWidth: "526px" }} size="lg" isOpen={repricerTagsModalIsOpen}>
      <RenderIf isTrue={title !== "Delete Tag"}>
        <div className="modal-header card-text font-size-20 p-3 let-modal">
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className="font-weight-bold">{title}</span>
            <i className="bx bx-sm bx-x inventory-color cursor-pointer" onClick={toggleTagsModal} />
          </div>
        </div>
      </RenderIf>
      <div className="modal-body p-3 let-modal">
        <RenderIf
          isTrue={title !== "Delete Tag"}
          fallback={
            <div className="p-3">
              <i
                className="bx bx-sm bx-x inventory-color cursor-pointer"
                style={{ position: "absolute", right: "24px", top: "24px" }}
                onClick={toggleTagsModal}
              />
              <div className="mt-3 d-flex justify-content-center align-items-center">
                <i style={{ fontSize: "100px" }} className="text-danger bx bx-error" />
              </div>
              <div className="mt-3 d-flex justify-content-center align-items-center font-weight-semibold inventory-color font-size-20">
                Are you sure you want to proceed?
              </div>
              <div className="mt-3 d-flex justify-content-center align-items-center font-weight-medium font-size-13">
                This tag will be deleted.
              </div>
              <div className="mt-3 d-flex justify-content-center align-items-center">
                <PillBtn
                  className="mr-2"
                  title="Cancel"
                  name="Cancel"
                  color="primary"
                  outline={true}
                  onClick={toggleTagsModal}
                />
                <PillBtn
                  className="ml-2"
                  title={buttonTitle}
                  name={buttonTitle}
                  color="primary"
                  onClick={() => {
                    dispatch(actions.deleteListingsTag(selectedPlatform, tagId, resetFilters));
                    toggleTagsModal();
                  }}
                />
              </div>
            </div>
          }
        >
          <FormGroup className="select2-container">
            <div>
              <Label>
                Tag Name<span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Tag Name Here"
                onChange={(e) => {
                  formik.setFieldTouched("tagNameField");
                  formik.setFieldValue("tagNameField", e.target.value);
                  setUpdateErrorCheck(false);
                }}
                value={formik.values.tagNameField}
              />
              <RenderIf isTrue={formik.touched.tagNameField && formik.errors.tagNameField}>
                <div className="text-danger">&nbsp;&nbsp;{formik.errors.tagNameField}</div>
              </RenderIf>
            </div>
            <RenderIf isTrue={updateErrorCheck}>
              <div className="text-warning mt-2 d-flex align-items-top font-size-13">
                <i className="font-size-16 bx bx-info-circle mr-1" />
                <i className="font-weight-medium">Note: Please Update "Tag Name" to enable "Confirm" button.</i>
              </div>
            </RenderIf>
          </FormGroup>
        </RenderIf>
      </div>
      <RenderIf isTrue={title !== "Delete Tag"}>
        <div className="modal-footer p-3 let-modal">
          <PillBtn
            className="mr-2"
            title="Cancel"
            name="Cancel"
            color="primary"
            outline={true}
            onClick={toggleTagsModal}
          />
          <div type="submit">
            <PillBtn
              disabled={title === "Edit Tag" && updateErrorCheck}
              className={`${title === "Edit Tag" && updateErrorCheck ? "cursor-not-allowed" : ""} ml-2`}
              title={buttonTitle}
              name={buttonTitle}
              color="primary"
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </RenderIf>
    </Modal>
  );
};

export default TagsModal;
