import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import PillBtn from "../../components/Common/PillBtn";
import { uniq, map, pick } from "lodash";
import papa from "papaparse";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import CustomPagination from "../../components/Common/CustomPagination";
import { Container, Card, CardHeader, CardBody, Alert, FormGroup } from "reactstrap";
import {
  setPreloader,
  fetchLuminatiProxies,
  fetchPorxyByPort,
  swapPorxyByPort,
  setUpdateLpmProxyModal,
  setSelectedProxy,
  setUploadProxyModal,
} from "../../store/actions";
import EditProxyModal from "./Components/EditProxyModal";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UploadProxyModal from "./Components/UploadCsvModal";

const tableCols = [
  { title: "Username" },
  { title: "Password" },
  { title: "Ip" },
  { title: "Port" },
  { title: "Internal Name" },
  { title: "Customer" },
  { title: "LPM Port" },
  { title: "Source" },
  { title: "Proxy Actions" },
];

const LuminaticProxies = (_) => {
  const dispatch = useDispatch();
  const { proxiesData, loading, error, success, setUpLoadProxyModalIsOpen } = useSelector((state) => state.ProxyUsers);
  const [search, setSearch] = useState("");
  const [pageFilters, setPageFilters] = useState({ page: 1, per_page: 30 });
  const [internalName, setInternalName] = useState({
    value: "all",
    label: "Select Internal Name",
  });
  const [source, setSource] = useState({
    value: "all",
    label: "Select Source",
  });
  const [filters, setFilters] = useState({ search: "", internalName: internalName.value, source: source.value });
  const filterData = filterAllData(filters);
  const internalNames = uniq(map(proxiesData, "internal_name")).filter((x) => x);
  const proxyProvider = uniq(map(proxiesData, "proxy_provider")).filter((x) => x);
  const data = filterData?.slice(
    (pageFilters.page - 1) * pageFilters.per_page,
    pageFilters.page * pageFilters.per_page,
  );
  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    !filters.search && dispatch(fetchLuminatiProxies());
    if (!filters.search || filters.search === 0) return;
    dispatch(fetchPorxyByPort(filters.search));
  }, [filters]);

  function swapProxy (port) {
    dispatch(swapPorxyByPort(port));
  }

  function setEditModal (proxy) {
    dispatch(setUpdateLpmProxyModal(true));
    dispatch(setSelectedProxy(proxy));
  }
  function filterAllData (allData) {
    if (allData.internalName === "all" && allData.source === "all") return proxiesData;
    if (allData.internalName !== "all" && allData.source === "all")
      return proxiesData?.filter((x) => x.internal_name === allData.internalName);
    if (allData.source !== "all" && allData.internalName === "all")
      return proxiesData?.filter((x) => x.proxy_provider === allData.source);
    if (allData.internalName !== "all" && allData.source !== "all")
      return proxiesData?.filter(
        (x) => x.proxy_provider === allData.source && x.internal_name === allData.internalName,
      );
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <EditProxyModal />
        {setUpLoadProxyModalIsOpen && <UploadProxyModal />}
        {error && (
          <div className="auto-hide">
            <Alert color="text-danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}
        {success && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </div>
        )}
        <Container fluid>
          <Breadcrumbs title="Proxy Manager" />
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <div className="d-flex grid-column-gap-10">
                  <form
                    className="app-search d-none d-lg-block"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (search.trim()) setFilters({ ...filters, search: search.trim() });
                    }}
                  >
                    <div className="position-relative" style={{ display: "inline-flex" }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => {
                          if (!e.target.value) {
                            delete filters.search;
                            setSearch("");
                            setFilters({ ...filters });
                          } else setSearch(e.target.value);
                        }}
                      />
                      <span className="bx bx-search-alt"></span>
                    </div>
                  </form>
                  <FormGroup className="select2-container mt-3 mr-2" style={{ minWidth: "200px" }}>
                    <Select
                      name="source"
                      value={{
                        value: source.value,
                        label: source.label,
                      }}
                      onChange={(e) => {
                        setSource({ value: e.value, label: e.value === "" ? "Select Source Name" : e.label });
                        setFilters({ ...filters, source: e.value });
                      }}
                      options={proxyProvider.reduce(
                        (x, y) => {
                          x.push({ label: y, value: y });
                          return x;
                        },
                        [{ label: "All", value: "all" }],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <FormGroup className="select2-container mt-3 mr-2" style={{ minWidth: "300px" }}>
                    <Select
                      name="internal_name"
                      value={{
                        value: internalName.value,
                        label: internalName.label,
                      }}
                      onChange={(e) => {
                        setInternalName({ value: e.value, label: e.value === " " ? "Select Internal Name" : e.label });
                        setFilters({ ...filters, internalName: e.value });
                      }}
                      options={internalNames?.reduce(
                        (x, y) => {
                          x.push({ label: y, value: y });
                          return x;
                        },
                        [{ label: "All", value: "all" }],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </div>
                <div className="d-flex grid-column-gap-10 ">
                  <PillBtn
                    className="ml-50"
                    color="success"
                    title="Export to CSV"
                    name="Export to CSV"
                    icon="bx bx-xs bx-cloud-download ml-1"
                    onClick={() => {
                      const csv = papa.unparse(
                        filterData.map((x) => ({
                          ...pick(x, "ip", "username", "port", "internal_name", "proxy_proxider"),
                        })),
                      );
                      const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                      let csvURL = null;
                      if (navigator.msSaveBlob) csvURL = navigator.msSaveBlob(csvData, "download.csv");
                      else csvURL = window.URL.createObjectURL(csvData);

                      const tempLink = document.createElement("a");
                      tempLink.href = csvURL;
                      tempLink.setAttribute("download", `${Date.now()}_proxy_exported`);
                      tempLink.click();
                    }}
                  />
                  <PillBtn
                    color="primary"
                    title="Upload CSV"
                    name="Upload CSV"
                    icon="bx bx-upload"
                    onClick={() => {
                      dispatch(setUploadProxyModal(true));
                    }}
                  ></PillBtn>
                </div>
              </div>
            </CardHeader>
            {filterData?.length
              ? (
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {tableCols.map((header, index) => (
                          <th key={`table-header ${index}`}>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, key) => (
                        <tr key={"_refresher_items_" + key}>
                          <td>{item?.username}</td>
                          <td>{item?.password}</td>
                          <td>{item?.ip}</td>
                          <td>{item?.port}</td>
                          <td>{item?.internal_name}</td>
                          <td>{item?.customer}</td>
                          <td>{item?.wrapper_port}</td>
                          <td>{item?.proxy_provider}</td>
                          <td className="d-flex align-items-center">
                            <i
                              title="Edit"
                              className="bx bx-sm bx-edit p-1 text-primary cursor-pointer"
                              onClick={() => setEditModal(item)}
                            ></i>
                            <ConfirmDialogIcon
                              icon={{
                                title: "Swap Proxy",
                                className: "bx-sm fas fa-exchange-alt text-primary cursor-pointer",
                              }}
                              msg={`Proxy with port ${item?.wrapper_port} will be updated`}
                              onConfirm={() => swapProxy(item?.wrapper_port)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
                )
              : null}
            <div className="tent-pagination">
              <CustomPagination
                pageOptions={[30, 50, 100]}
                total={filterData.length}
                page={pageFilters.page}
                perPage={pageFilters.per_page}
                tabsFilter={pageFilters}
                setTabFilter={(e) => setPageFilters(pageFilters.per_page !== e.per_page ? { ...e, page: 1 } : e)}
              />
            </div>
            {filterData?.length === 0 && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LuminaticProxies;
