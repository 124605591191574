import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Alert, Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import "react-toggle/style.css";
import Select from "react-select";
import { getUserInfo, toPascalCase } from "../../../utils/common";
import concat from "lodash/concat";
// actions
import { setManageAgenciesModal, assignAgencies } from "../../../store/actions";

const ManageAgenciesModal = (props) => {
  const dispatch = useDispatch();
  const { agencies } = useSelector((state) => state.Settings);
  const { selectedUser, manageAgenciesModalIsOpen, error } = useSelector((state) => state.User);
  const [agencyOpts, setAgencyOpts] = useState([]);
  const [selectMultipleAgency, setSelectMultipleAgency] = useState([]);

  const initialValues = { agencies: "" };
  const validate = (_) => {};

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => props.handleFormChange(name, value),
    onSubmit: (_) =>
      dispatch(
        assignAgencies({
          data: selectMultipleAgency?.map((x) => x.value) || [],
          id: selectedUser.id,
        }),
      ),
  });

  useEffect(() => {
    const selectedAgencies = [];
    if (agencies?.data) {
      setAgencyOpts(
        agencies?.data
          .filter((x) => x.id !== getUserInfo()?.account_id)
          .reduce((acc, data) => {
            const selected = (selectedUser.agency_info || []).map((x) => x).includes(data.id);
            if (selected) selectedAgencies.push({ label: toPascalCase(data?.email), value: data?.id });
            acc.push({
              label: toPascalCase(data?.email),
              value: data?.id,
            });
            return acc;
          }, []),
      );
      setSelectMultipleAgency(concat(selectMultipleAgency, selectedAgencies));
    }
  }, [agencies]);

  useEffect(() => {
    selectMultipleAgency && formik.setFieldValue("agencies", selectMultipleAgency.value, false);
  }, [selectMultipleAgency]);

  const toggleModal = () => {
    dispatch(setManageAgenciesModal(!manageAgenciesModalIsOpen));
    formik.resetForm();
  };

  return (
    <Modal size="md" isOpen={manageAgenciesModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          ASSIGN AGENCIES TO USERS
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <Label>Email</Label>
              <Input name="email" value={selectedUser.email} readOnly />
            </Col>
            <Col lg="12 mb-2">
              <FormGroup className="select2-container">
                <label className="control-label">Select Agencies(s)</label>
                <Select
                  name="agencies"
                  value={selectMultipleAgency}
                  isMulti={true}
                  onChange={(e) => {
                    setSelectMultipleAgency(e);
                    formik.setFieldValue("agencies", e);
                  }}
                  options={agencyOpts}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default ManageAgenciesModal;
