import React from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Table, Button, Row, Alert } from "reactstrap";
import { State } from "country-state-city";

// Simple bar
import SimpleBar from "simplebar-react";

// Import Icon
import whIcon from "../../../assets/svg/warehouse/warehouse_icon.svg";

// Actions
import { selectExistingWareHouse } from "../../../store/actions";
import { getUserInfo, isUserHaveFullAccess, RenderIf } from "../../../utils/common";

function ExistingWareHouse (props) {
  const dispatch = useDispatch();
  const {
    formik,
    toggleModal,
    selectedWH,
    setSelectedWH,
    wareHouses: { data },
    whOpts,
    setWHOpts,
  } = props;
  const getWareHouseName = (data) => {
    const state = State.getStatesOfCountry(data.country)?.find((x) => x.isoCode === data.state) || "";
    return `${state.name}, ${data.city}`;
  };
  return (
    <Card>
      {(formik.errors.existingWh || formik.errors.enabledOpts) && (
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2"></i>
          {formik.errors.existingWh || formik.errors.enabledOpts}
        </Alert>
      )}
      <span className="text-white ml-2 my-3">Select from a list of warehouse which we setup:</span>
      <CardBody className="p-0">
        <SimpleBar style={{ maxHeight: "250px" }}>
          <Table className="table table-nowrap table-centered table-hover mb-0">
            <tbody>
              {data
                .filter((x) => !x.AccountWarehouse.some((y) => y.id === getUserInfo()?.account_id))
                ?.map((x, i) => (
                  <tr key={`_wh_${i}`}>
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input cursor-pointer"
                          type="radio"
                          name="wareHouse"
                          id={`_radio_warehouse_${x.id}`}
                          checked={selectedWH === x.id}
                          onChange={() => setSelectedWH(x.id)}
                        />
                        <label className="form-check-label cursor-pointer" htmlFor={`_radio_warehouse_${x.id}`}>
                          <img className="mr-2" src={whIcon} alt={"Warehouse Icon"} />
                          {getWareHouseName(x)}
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </SimpleBar>
      </CardBody>

      <RenderIf isTrue={isUserHaveFullAccess()}>
        <div className="divider-line">
          <span className="inner-text inventory-color">OR</span>
        </div>

        <div className="d-flex flex-column">
          <div className="text-center mt-4">
            <Button
              color="primary"
              onClick={() => {
                setWHOpts({ ...whOpts, existingWh: !whOpts.existingWh });
                setSelectedWH("");
                dispatch(selectExistingWareHouse({}));
                formik.resetForm();
              }}
              className="mr-3"
            >
              Add Personal Warehouse
            </Button>
          </div>
          <div className="text-center card-text mt-4">
            <b>Note:</b> Warehouses added in this way will not have any integration with Ecom Circles. To learn more
            about integrating your warehouse with Ecom Circles, go here.
          </div>
        </div>
      </RenderIf>
      <Row className="d-flex justify-content-between card-footer bg-transparent border-top pt-4 mt-4">
        <Button
          outline
          type="reset"
          color="primary"
          className="ml-4 existing-wh-btn-width"
          onClick={() => {
            formik.handleReset();
            toggleModal();
          }}
        >
          Skip
        </Button>
        <Button
          color="primary"
          className="mr-3 existing-wh-btn-width"
          onClick={() => {
            formik.handleSubmit();
            dispatch(selectExistingWareHouse({ ...data?.find((wh) => wh.id === selectedWH) }));
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
}

export default ExistingWareHouse;
