import React from "react";

function CheckBox ({ name, state, disabled = false, setState, label, isSwitch, className = "", extraStyle, onClick }) {
  return (
    <div
      className={`custom-control ${isSwitch ? "custom-switch " : "custom-checkbox "}${className}`}
      style={extraStyle || {}}
    >
      <input
        name={name}
        type="checkbox"
        className="custom-control-input"
        checked={state || false}
        onChange={() => null}
      />
      <label
        disabled={disabled}
        onClick={(e) => (disabled ? null : onClick?.(e) || setState(!state))}
        className={`custom-control-label cursor-${disabled ? "not-allowed" : "pointer"}`}
      >
        {label}
      </label>
    </div>
  );
}

export default CheckBox;
