import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllCreditCards,
  getAllCardTypes,
  updateCreditCards,
  deleteCreditCards,
  createCreditCards,
  setCardPriorities,
} from "../../api";
// Login Redux States
import {
  FETCH_CC_DETAILS,
  FETCH_CARD_TYPES,
  UPDATE_CREDIT_CARDS,
  DELETE_CREDIT_CARD,
  ADD_CREDIT_CARDS,
  UPDATE_CREDIT_CARDS_PRIORITY,
} from "./actionTypes";
import {
  apiError,
  fetchCreditCardDetailsSuccess,
  fetchCardTypesSuccess,
  createCreditCardsSuccess,
  updateCreditCardsSuccess,
  deleteCreditCardSuccess,
  setAddCreditCardsModal,
  setEditCreditCardsModal,
  updateCardPrioritiesSuccess,
  fetchCreditCardDetails,
} from "./actions";

const CreditCards = (state) => state.CreditCards;

function * fetchCreditCardsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllCreditCards, params);
    yield put(fetchCreditCardDetailsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchCardTypesSaga () {
  try {
    const { data } = yield call(getAllCardTypes);
    yield put(fetchCardTypesSuccess(data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createCreditCardsSaga ({ payload: { data } }) {
  try {
    const res = yield call(createCreditCards, { data });
    if (res?.data) {
      yield put(fetchCreditCardDetails());
      yield put(setAddCreditCardsModal(false));
    }
    if (data.is_custom) yield call(fetchCardTypesSaga);
    yield put(createCreditCardsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateCreditCardsSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateCreditCards, { data, id });
    if (res?.data) {
      yield put(fetchCreditCardDetails());
      yield put(setEditCreditCardsModal(false));
    }
    if (data.is_custom) yield call(fetchCardTypesSaga);
    yield put(updateCreditCardsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteCreditCardsSaga ({ payload: { id } }) {
  try {
    const { data } = yield call(deleteCreditCards, { id });
    if (data) {
      const creditcards = yield select(CreditCards);
      creditcards.ccData.data = creditcards.ccData.data.filter((card) => card.id !== data.id);
    }
    yield put(deleteCreditCardSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateCardsPrioritySaga ({ payload: { data } }) {
  try {
    const res = yield call(
      setCardPriorities,
      data.map((x) => x.id),
    );
    if (res?.success) yield put(updateCardPrioritiesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchCreditCardFetch () {
  yield takeEvery(FETCH_CC_DETAILS, fetchCreditCardsSaga);
}

export function * watchCardTypesFetch () {
  yield takeEvery(FETCH_CARD_TYPES, fetchCardTypesSaga);
}

export function * watchCreateCreditCard () {
  yield takeEvery(ADD_CREDIT_CARDS, createCreditCardsSaga);
}

export function * watchUpdateCreditCard () {
  yield takeEvery(UPDATE_CREDIT_CARDS, updateCreditCardsSaga);
}

export function * watchDeleteCreditCard () {
  yield takeEvery(DELETE_CREDIT_CARD, deleteCreditCardsSaga);
}

export function * watchUpdateCardsPriority () {
  yield takeEvery(UPDATE_CREDIT_CARDS_PRIORITY, updateCardsPrioritySaga);
}

function * creditCardSaga () {
  yield all([
    fork(watchCreditCardFetch),
    fork(watchCardTypesFetch),
    fork(watchUpdateCreditCard),
    fork(watchCreateCreditCard),
    fork(watchDeleteCreditCard),
    fork(watchUpdateCardsPriority),
  ]);
}

export default creditCardSaga;
