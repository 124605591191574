import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, Card, CardSubtitle, CardBody } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";

// actions
import {
  bulkUpdateBrands,
  setSelectedBrands,
  setAddBrandModal,
  bulkAddNewBrand,
  setEditAddBrandModal,
  setBulkAddBrandModal,
  updateBrand,
  addNewBrand,
  selectFileError,
} from "../../../store/actions";
import { getUserInfo, RenderIf, toPascalCase, isAgency } from "../../../utils/common";
import Dropzone from "react-dropzone";

const RestictedBulkBrandsModal = (props) => {
  const validate = (values) => {
    const errors = {};
    !values.name && (errors.name = "Brand name is requried");
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: props?.selectedBrand?.name || "",
      restricted_reason: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (props.type === "add") {
        props.addNewBrand({
          va_id: getUserInfo().id,
          platform: props.platform,
          name: values.name,
          ...(isAgency ? { reason: values.restricted_reason } : {}),
        });
      } else if (props.type === "edit") {
        props.updateBrand({
          data: {
            va_id: getUserInfo().id,
            platform: props.platform,
            name: values.name,
          },
          id: props.selectedBrand.id,
        });
      }
    },
  });

  const toggleModal = () => {
    if (props.type === "add") {
      props.setAddBrandModal(false);
    } else if (props.type === "bulkAdd") {
      props.setBulkAddBrandModal(false);
    } else {
      props.setEditAddBrandModal(false);
    }
    formik.resetForm();
  };

  return (
    <React.Fragment>
      <Modal
        size={props.type === "bulkAdd" ? "md" : "sm"}
        isOpen={
          props.RestrictedBrands.addBrandModalIsOpen ||
          props.RestrictedBrands.editBrandModalIsOpen ||
          props.RestrictedBrands.bulkAddBrandModalIsOpen
        }
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {props.type === "add"
              ? "Add Restricted Brand"
              : props.type === "bulkAdd"
                ? `Add Bulk Restricted Brands (${props.platform})`
                : "Update Restricted Brand"}
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.RestrictedBrands.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.RestrictedBrands.error}
            </Alert>
          )}

          <RenderIf isTrue={props.type === "bulkAdd"}>
            <UploadFile props={props} toggleModal={toggleModal} />
          </RenderIf>

          <RenderIf isTrue={props.type !== "bulkAdd"}>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <RenderIf isTrue={props.type === "add"}>
                  <Col lg="12" className="py-1">
                    <Label>Platform</Label>
                    <input disabled={true} type="text" value={toPascalCase(props.platform)} className="form-control" />
                    {formik.touched.name && formik.errors.name
                      ? (
                      <small className="text-danger">{formik.errors.name}</small>
                        )
                      : null}
                  </Col>
                </RenderIf>
                <Col lg="12" className="py-1">
                  <Label>Name</Label>
                  <input
                    name="name"
                    placeholder="Brand name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="form-control"
                  />
                  {formik.touched.name && formik.errors.name
                    ? (
                    <small className="text-danger">{formik.errors.name}</small>
                      )
                    : null}
                </Col>
                <Col lg="12">
                  <Label>Reason</Label>
                  <input
                    name="restricted_reason"
                    placeholder="Reason to mark brand as restricted"
                    maxLength="255"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.restricted_reason}
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-end mt-3">
                <Button
                  type="reset"
                  color="secondary"
                  className="mr-3"
                  onClick={() => {
                    formik.handleReset();
                    toggleModal();
                  }}
                >
                  Cancel
                </Button>
                {
                  <Button type="submit" color="success" className="mr-3">
                    Submit
                  </Button>
                }
              </Row>
            </form>
          </RenderIf>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const UploadFile = ({ props, toggleModal }) => {
  const [selectedFile, setselectedFile] = useState([]);
  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function handleReportSubmit () {
    if (selectedFile.length > 0) {
      props.bulkAddNewBrand({
        va_id: getUserInfo().id,
        platform: props.platform,
        file: selectedFile[0],
      });
      toggleModal();
      setselectedFile([]);
    } else {
      props.selectFileError();
    }
  }

  return (
    <Card>
      <div style={{ marginLeft: 20, marginTop: 20 }}>
        <CardSubtitle className="mb-3">
          {" "}
          CSV File must have these Columns with same naming conventions: Name, Restricted Reason
        </CardSubtitle>
      </div>
      <CardBody>
        <form className="m-2">
          <Col className="my-1">
            {selectedFile.length === 0 && (
              <Dropzone
                onDrop={(acceptedFile) => {
                  handleAcceptedFile(acceptedFile);
                }}
                accept=".csv"
                maxFiles="1"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                      </div>
                      <h4>Drag or Click to Upload CSV File</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            )}
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFile.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col>
                          {f.name}
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                          <p className="mb-0"></p>
                        </Col>
                        <i
                          title="Remove File"
                          className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                          onClick={() => setselectedFile([])}
                        ></i>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
            {selectedFile.length !== 0 && (
              <div className="text-center mt-4">
                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={handleReportSubmit}>
                  Upload File
                </button>
              </div>
            )}
          </Col>
        </form>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { RestrictedBrands } = state;
  return { RestrictedBrands };
};

export default connect(mapStateToProps, {
  bulkUpdateBrands,
  selectFileError,
  setSelectedBrands,
  bulkAddNewBrand,
  setAddBrandModal,
  setEditAddBrandModal,
  setBulkAddBrandModal,
  updateBrand,
  addNewBrand,
})(RestictedBulkBrandsModal);
