import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Alert, Col, Label } from "reactstrap";
import first from "lodash/first";

import Modal from "../../components/Common/Modal";
import PillBtn from "../../components/Common/PillBtn";

import * as actions from "../../store/actions";

import { RenderIf } from "../../utils/common";
import { carrierOptions, isEasypost, serivceOptions } from "./common";
import { LABEL_SERVICES } from "../../constants";

export const GenerateScanformModal = (props) => {
  const initialBody = { carrier: "UPS", label_service: LABEL_SERVICES.easypost };
  const [apiBody, setApiBody] = useState(initialBody);

  const dispatch = useDispatch();
  const { loading, error: warehouseError, scanformModalIsOpen } = useSelector((state) => state.WareHouses);

  useEffect(() => {
    dispatch(actions.setPreloader(loading));
  }, [loading]);

  return (
    <Modal size="md" isOpen={scanformModalIsOpen} toggle={props.toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Generate Scanforms</h5>
        <button onClick={props.toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <RenderIf isTrue={warehouseError}>
          <div className="auto-hide">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {warehouseError}
            </Alert>
          </div>
        </RenderIf>
        <div className="m-2">
          <Col className="my-1">
            <Label>Select Label Service</Label>
            <Select
              className="select2-container"
              classNamePrefix="select2-selection"
              name="label_service"
              value={serivceOptions.slice(1).find((opt) => opt.value === apiBody.label_service)}
              options={serivceOptions.slice(1)}
              formStyle={{ Width: "100%" }}
              onChange={(e) =>
                setApiBody({
                  label_service: e.value,
                  carrier: isEasypost(e.value) ? first(carrierOptions.slice(1)).value : "usps",
                })
              }
            />
          </Col>
          {(() => {
            const options = carrierOptions.slice(1).filter((opt) => opt.value !== "UPSDAP"); // UPSDAP is no longer being supported when creating scanforms
            if (!isEasypost(apiBody.label_service)) return null;
            return (
              <Col className="my-1">
                <Label>Select Carrier</Label>
                <Select
                  className="select2-container"
                  classNamePrefix="select2-selection"
                  name="carrier"
                  value={options.find((opt) => opt.value === apiBody.carrier)}
                  options={options}
                  formStyle={{ Width: "100%" }}
                  onChange={(e) => setApiBody({ ...apiBody, carrier: e.value })}
                />
              </Col>
            );
          })()}
          <Col className="my-3">
            <PillBtn className="mr-2" color="secondary" title="Cancel" name="Cancel" onClick={props.toggleModal} />
            <PillBtn
              className="mr-2"
              color="primary"
              title="Submit"
              name="Submit"
              onClick={() => dispatch(actions.generateScanform(apiBody))}
            />
          </Col>
        </div>
      </div>
    </Modal>
  );
};
