import { FETCH_MARKETPLACE_FEED, FETCH_MARKETPLACE_FEED_SUCCESS, API_ERROR } from "./actionTypes";

export const fetchMarketplaceFeed = (params) => {
  return {
    type: FETCH_MARKETPLACE_FEED,
    payload: { params },
  };
};

export const fetchMarketplaceFeedSuccess = (feeds) => {
  return {
    type: FETCH_MARKETPLACE_FEED_SUCCESS,
    payload: feeds,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
