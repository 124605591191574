import request from "../restClient";

export const fetchCustomPlans = async (bool) => {
  return await request({
    url: `/fetch_all_plan_groups${bool ? "?hide_plans=true" : ""}`,
    method: "GET",
  });
};

export const createPlan = async (body) => {
  return await request({
    url: "/create_plan",
    method: "POST",
    body,
  });
};

export const updatePlan = async (id, body) => {
  return await request({
    url: `/update_plan/${id}`,
    method: "PUT",
    body,
  });
};

export const createPlanGroup = async (body) => {
  return await request({
    url: "/create_plan_group",
    method: "POST",
    body,
  });
};

export const updatePlanGroup = async (id, body) => {
  return await request({
    url: `/update_plan_group/${id}`,
    method: "PUT",
    body,
  });
};

export const updateGroupAndPlan = async (id, body) => {
  return await request({
    url: `/update_account_plan/${id}`,
    method: "PUT",
    body,
  });
};

export const deletePlan = async (planId) => {
  return await request({
    url: `/delete_plan/${planId}`,
    method: "DELETE",
  });
};

export const deleteGroup = async (groupId) => {
  return await request({
    url: `/delete_group/${groupId}`,
    method: "DELETE",
  });
};
