import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

// actions
import { fetchErrorStats } from "../../../store/actions";

const IssueStats = (props) => {
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const { errorStats, erroStatsLoader } = useSelector((state) => state.Graph);

  const fetchDetails = () => {
    dispatch(
      fetchErrorStats({
        marketplace_id: accountId,
      }),
    );
  };

  useEffect(() => {
    !erroStatsLoader && fetchDetails();
  }, [accountId]);

  const data = {
    series: (errorStats?.data && Object.keys(errorStats?.data).map((key) => errorStats?.data[key].length)) || [],
    options: {
      labels: (errorStats?.data && Object.keys(errorStats?.data).map((key) => key?.split("(")[0])) || [],
      legend: { show: false, position: "bottom", horizontalAlign: "left" },
      noData: {
        text: "No Errored Orders",
      },
      colors: ["#34c38f", "#556ee6", "#f46a6a", "#34f48f", "#546fe6", "#f26a4b", "#34d39f", "#55dec6", "#f43a62"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
          expandOnClick: true,
        },
      },
      chart: {
        ...(props.abilities.can("visit", "source_orders") && {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              window.open(
                `/source_orders/errored?error_prefix=${config.w.config.labels[config.dataPointIndex]}`,
                "_blank",
              );
            },
          },
        }),
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title">{erroStatsLoader ? "Loading..." : "Total Errors"}</h4>
        <div>
          <div id="donut-chart" className="apex-charts">
            <ReactApexChart options={data.options} series={data.series} type="donut" height="250" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default withRouter(IssueStats);
