import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getAuthTokenEmail } from "../../api";
// Login Redux States
import { FETCH_AUTH_TOKEN_EMAIL } from "./actionTypes";
import { apiError, fetchAuthTokenEmailSuccess } from "./actions";

function * fetchAuthTokenEmailSaga ({ payload: { email } }) {
  try {
    const res = yield call(getAuthTokenEmail, email);
    yield put(fetchAuthTokenEmailSuccess(res.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchFetchAuthTokenEmail () {
  yield takeEvery(FETCH_AUTH_TOKEN_EMAIL, fetchAuthTokenEmailSaga);
}

function * authTokenSaga () {
  yield all([fork(watchFetchAuthTokenEmail)]);
}

export default authTokenSaga;
