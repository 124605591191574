import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { RenderIf } from "../../../utils/common";
import { Card, CardBody } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";
import AssignOutbound from "./AssignOutboundModal";
// actions
import { fetchWHInventory } from "../../../store/actions";
import { merge } from "lodash";

const SearchInventoryModal = (props) => {
  const [isSearched, setIsSearched] = useState(false);
  const [inventory, setInventory] = useState({});
  const [whInvetoryData, setWhInvetoryData] = useState([]);

  useEffect(() => {
    setWhInvetoryData(props?.WareHouses?.whInvetoryData);
  }, [props?.WareHouses?.whInvetoryData]);

  const validate = (values) => merge({}, !values.search && { qty: "Field is required" });

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.fetchWHInventory(values);
      formik.resetForm();
    },
  });

  const tableHeaders = ["Title", "Master Sku", "Total Qty.", "Actions"].map((title) => ({ title }));
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);
  const getText = (text) => text || "N/A";
  return (
    <React.Fragment>
      <RenderIf isTrue={props.isAssignOutboundOpen}>
        <AssignOutbound
          isOpen={props.isAssignOutboundOpen}
          toggleIsOpen={props.assignOutbound}
          outboundId={props.outboundData?.id}
          inventory={inventory}
          assignInventory={props.assignInventory}
          sourceOrder={props.sourceOrderData}
        />
      </RenderIf>
      <Modal size="xl" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Search Inventory</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <form className="app-search d-none d-lg-block" onSubmit={formik.handleSubmit}>
            <div className="position-relative mr-2">
              <input
                className="form-control"
                placeholder="Search by Sku, Item title or UPC..."
                maxLength="80"
                value={formik.values.search}
                onChange={(e) => {
                  if (!e.target.value) {
                    delete formik.values.search;
                    formik.setFieldValue("search", e.target.value);
                    setIsSearched(false);
                  } else {
                    formik.setFieldValue("search", e.target.value);
                    setIsSearched(true);
                  }
                }}
              />
              <span className="bx bx-search-alt"></span>
            </div>
          </form>
        </div>
        <div className="modal-footer"></div>
        <Card>
          <RenderIf isTrue={whInvetoryData?.inventoryItems?.length}>
            <CardBody>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {tableHeaders.map((header, index) => (
                        <th key={`table-header-${index}`}>{header.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {whInvetoryData?.inventoryItems?.map((item, key) => (
                      <tr key={"_return_" + key}>
                        <td>{getText(item?.title)}</td>
                        <td>{item?.sku}</td>
                        <td>{item?.qty}</td>
                        <td>
                          <i
                            title="Assign Inventory"
                            onClick={() => {
                              props.assignOutbound(true);
                              setInventory(item);
                            }}
                            className="bx bx-sm bx-add-to-queue pr-1 text-primary cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </RenderIf>
          <RenderIf isTrue={!whInvetoryData?.count && isSearched}>
            <CardBody>
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
            </CardBody>
          </RenderIf>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { WareHouses } = state;
  return { WareHouses };
};

export default connect(mapStateToProps, {
  fetchWHInventory,
})(SearchInventoryModal);
