import { CUSTOM_FILTERS } from "../../constants";
import * as types from "./actionTypes";
const {
  FETCH_LISTINGS,
  FETCH_LISTINGS_SUCCESS,
  SET_SUPPLIER_MODAL,
  SET_SUPPLIER_ADD_MODAL,
  SET_ERRORED_LISTINGS_UPDATE_MODAL,
  FETCH_MARKETPLACE_PRODUCT_INFO,
  FETCH_MARKETPLACE_PRODUCT_INFO_SUCCESS,
  FETCH_SHIPPING_TEMPLATE,
  FETCH_SHIPPING_TEMPLATE_SUCCESS,
  SYNC_SHIPPING_TEMPLATE,
  SYNC_SHIPPING_TEMPLATE_SUCCESS,
  FETCH_MATCHING_LOGS,
  FETCH_MATCHING_LOGS_SUCCESS,
  ASSIGN_SHIPPING_TEMPLATE,
  ASSIGN_SHIPPING_TEMPLATE_SUCCESS,
  UPDATE_UPC,
  UPDATE_UPC_SUCCESS,
  RESET_FETCH_ERROR,
  UPDATE_ERRORED_LISTING,
  UPDATE_ERRORED_LISTING_SUCCESS,
  CHANGE_PRODUCT_VARIATION,
  CHANGE_PRODUCT_VARIATION_SUCCESS,
  CHANGE_QUANTITY_MULTIPLIER,
  CHANGE_QUANTITY_MULTIPLIER_SUCCESS,
  SETUP_MANUAL_MATCHES,
  SETUP_MANUAL_MATCHES_SUCCESS,
  RESET_MANUAL_MATCH_STATE,
  ADD_CSV_LISTINGS,
  ADD_CSV_LISTINGS_SUCCESS,
  DOWNLOAD_LISTINGS,
  DOWNLOAD_LISTINGS_SUCCESS,
  FETCH_UPLOADED_FILES,
  FETCH_UPLOADED_FILES_SUCCESS,
  SET_SELECTED_LISTINGS,
  SET_SELECTED_LIVE_LISTING,
  SET_FILTERED_LISTINGS,
  SET_LISTINGS_BULK_UPDATE_MODAL,
  SET_SHIPPING_TEMPLATE_UPDATE_MODAL,
  LISTINGS_BULK_UPDATE,
  LISTINGS_BULK_UPDATE_SUCCESS,
  LISTINGS_BULK_DELETE,
  LISTINGS_BULK_DELETE_SUCCESS,
  UPDATE_BRAND_RESTRICTION,
  UPDATE_BRAND_RESTRICTION_SUCCESS,
  UPDATE_BRAND_RESTRICTION_BULK,
  UPDATE_BRAND_RESTRICTION_BULK_SUCCESS,
  SHOW_CUSTOM_FILTERS,
  SELECT_FILE_ERROR,
  SET_CUSTOM_FILTERS,
  RESET_CUSTOM_FILTERS,
  UPDATE_MAP_AND_HANDLING_TIME_SUCCESS,
  UPDATE_MAP_AND_HANDLING_TIME,
  BULK_UPDATE_HANDLING_TIME_SUCCESS,
  BULK_UPDATE_HANDLING_TIME,
  SET_MA_PRICE_MODAL,
  FETCH_MATCHING_PLATFORMS_SUCCESS,
  FETCH_MATCHING_PLATFORMS,
  ADD_MATCHING_PLATFORM_SUCCESS,
  ADD_MATCHING_PLATFORM,
  API_ERROR,
  UPDATE_SHIPPING_TEMPLATE,
  UPDATE_SHIPPING_TEMPLATE_SUCCESS,
  RELIST_RETIRED_LISTINGS,
  RELIST_RETIRED_LISTINGS_SUCCESS,
  BULK_UPDATE_TWO_STEP_MODAL,
  SET_TWO_STEP_MODAL,
  BULK_UPDATE_TWO_STEP_MODAL_SUCCESS,
  UPDATE_SEARCHED_LISTING,
  SET_TEMPLATE_MODAL,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESS,
  UPLOAD_FACEBOOK_LISTINGS,
  UPLOAD_FACEBOOK_LISTINGS_SUCCESS,
  UPDATE_LISTING_WEIGHT,
  UPDATE_LISTING_WEIGHT_SUCCESS,
  RESET_WEIGHT_ERR,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  RESET_LISTING_STATE,
} = types;

const initialState = {
  error: "",
  loading: false,
  shippingTemplateLoading: false,
  listings: undefined,
  supplierModalIsOpen: false,
  supplierAddModalIsOpen: false,
  uploadedFiles: undefined,
  templates: undefined,
  selectedListings: [],
  selectedLiveListing: {},
  brandRestriction: [],
  filteredListings: [],
  fetchError: "",
  listingsBulkUpdateModalIsOpen: false,
  shippingTemplateUpdateModalIsOpen: false,
  erroredListingsUpdateModalIsOpen: false,
  twoStepModalIsOpen: false,
  showCustomFilters: false,
  supplier: null,
  marketplace: null,
  brand_restricted: false,
  variations: null,
  quantityMultiplierInfo: null,
  manualMatch: null,
  success: "",
  matchingPlatforms: undefined,
  profit: 0,
  custom_filters: CUSTOM_FILTERS,
  maPriceModalIsOpen: false,
  templateModal: false,
};

const listings = (state = initialState, action) => {
  switch (action.type) {
    case RESET_LISTING_STATE:
      state = {
        ...state,
        error: "",
        success: "",
      };
      break;
    case FETCH_LISTINGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_LISTINGS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        listings: action.payload,
        loading: false,
      };
      break;
    case UPDATE_SEARCHED_LISTING:
      if (!state.listings) state.listings = {};
      state.listings.results = [action.payload];
      state.selectedLiveListing = { key: 0, ...action.payload };
      break;
    case SET_SUPPLIER_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        supplierModalIsOpen: action.payload,
      };
      break;
    case SET_SUPPLIER_ADD_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        supplierAddModalIsOpen: action.payload,
      };
      break;
    case SET_ERRORED_LISTINGS_UPDATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        erroredListingsUpdateModalIsOpen: action.payload,
      };
      break;
    case UPDATE_LISTING_WEIGHT:
      state = { ...state, weightLoader: true, weightErr: null, resetState: false };
      break;
    case UPDATE_LISTING_WEIGHT_SUCCESS:
      state = {
        ...state,
        weightLoader: false,
        weightErr: null,
        message: action.payload?.response || action.payload,
        resetState: action.payload?.resetState,
      };
      break;
    case FETCH_MARKETPLACE_PRODUCT_INFO:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_MARKETPLACE_PRODUCT_INFO_SUCCESS:
      if (!action.payload) {
        state = { ...state, showInputForm: true, loading: false };
        return state;
      }
      state = {
        ...state,
        loading: false,
        supplier: action.payload.supplier,
        marketplace: action.payload.marketplace,
        profit: action.payload.profit,
        brand_restricted: action.payload.brand_restricted,
      };
      break;
    case types.CHECK_LISTING_IN_STORE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case types.CHECK_LISTING_IN_STORE_SUCCESS:
      state = {
        ...state,
        success: "",
        error: action.payload.success ? "" : action.payload.message,
        loading: false,
      };
      break;
    case UPDATE_SHIPPING_TEMPLATE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_SHIPPING_TEMPLATE_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        updateShippingData: action.payload,
      };
      break;
    case FETCH_MATCHING_LOGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_MATCHING_LOGS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        logs: action.payload,
      };
      break;
    case FETCH_SHIPPING_TEMPLATE:
      state = {
        ...state,
        success: "",
        error: "",
        shippingTemplateLoading: true,
      };
      break;
    case FETCH_SHIPPING_TEMPLATE_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        templates: action.payload,
        shippingTemplateLoading: false,
      };
      break;
    case SYNC_SHIPPING_TEMPLATE:
      state = {
        ...state,
        success: "",
        error: "",
        shippingTemplateLoading: true,
      };
      break;
    case SYNC_SHIPPING_TEMPLATE_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        templates: action.payload,
        shippingTemplateLoading: false,
      };
      break;
    case ASSIGN_SHIPPING_TEMPLATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ASSIGN_SHIPPING_TEMPLATE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Template Updated Successfully",
        loading: false,
      };
      break;
    case UPDATE_UPC:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_UPC_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "UPC Updated Successfully",
        loading: false,
      };
      break;
    case RESET_FETCH_ERROR:
      state = { ...state, fetchError: "" };
      break;
    case UPDATE_ERRORED_LISTING:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_ERRORED_LISTING_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Errored Listing Updated Successfully",
        loading: false,
      };
      break;
    case CHANGE_PRODUCT_VARIATION:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case CHANGE_PRODUCT_VARIATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "",
        error: "",
        supplier: action.payload.results,
        profit: action.payload.profit,
      };
      break;
    case CHANGE_QUANTITY_MULTIPLIER:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case CHANGE_QUANTITY_MULTIPLIER_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        quantityMultiplierInfo: action.payload,
      };
      break;
    case SETUP_MANUAL_MATCHES:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case SETUP_MANUAL_MATCHES_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Match Added Successfully",
        loading: false,
        manualMatch: action.payload,
      };
      break;
    case types.CREATE_INVENTORY_LISTING:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case types.CREATE_INVENTORY_LISTING_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload.message,
        loading: false,
      };
      break;
    case RESET_MANUAL_MATCH_STATE:
      state = {
        ...state,
        error: "",
        success: "",
        supplier: null,
        marketplace: null,
        variations: null,
        quantityMultiplierInfo: null,
        manualMatch: null,
      };
      break;
    case ADD_CSV_LISTINGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case ADD_CSV_LISTINGS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        error: "",
        loading: false,
      };
      break;
    case UPLOAD_FACEBOOK_LISTINGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPLOAD_FACEBOOK_LISTINGS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        error: "",
        loading: false,
      };
      break;
    case DOWNLOAD_LISTINGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case DOWNLOAD_LISTINGS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        error: "",
        loading: false,
      };
      break;
    case FETCH_UPLOADED_FILES:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_UPLOADED_FILES_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        uploadedFiles: action.payload,
        loading: false,
      };
      break;
    case SET_SELECTED_LISTINGS:
      state = {
        ...state,
        selectedListings: action.payload,
      };
      break;
    case SET_SELECTED_LIVE_LISTING:
      state = {
        ...state,
        selectedLiveListing: action.payload,
      };
      break;
    case SET_FILTERED_LISTINGS:
      state = {
        ...state,
        filteredListings: action.payload,
      };
      break;
    case SET_LISTINGS_BULK_UPDATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        listingsBulkUpdateModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_SHIPPING_TEMPLATE_UPDATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        shippingTemplateUpdateModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case LISTINGS_BULK_UPDATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case LISTINGS_BULK_UPDATE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Listings have been Updated Successfully",
        loading: false,
      };
      break;
    case LISTINGS_BULK_DELETE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case LISTINGS_BULK_DELETE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "SKUs removed successfully. Will be Retired/Deleted shortly.",
        loading: false,
      };
      break;
    case RELIST_RETIRED_LISTINGS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case RELIST_RETIRED_LISTINGS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Only Retired/Deleted Items Relist Successfully",
        loading: false,
      };
      break;
    case UPDATE_BRAND_RESTRICTION:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_BRAND_RESTRICTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Skipped Brand Restriction Update Successfully",
        brandRestriction: action.payload,
      };
      break;
    case UPDATE_BRAND_RESTRICTION_BULK:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_BRAND_RESTRICTION_BULK_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Skipped Brand Restriction to true Successfully",
        loading: false,
      };
      break;
    case SHOW_CUSTOM_FILTERS:
      state = {
        ...state,
        showCustomFilters: action.payload,
      };
      break;
    case SET_CUSTOM_FILTERS:
      state = {
        ...state,
        custom_filters: {
          ...state.custom_filters,
          ...action.payload,
        },
      };
      break;
    case RESET_CUSTOM_FILTERS:
      state = {
        ...state,
        custom_filters: CUSTOM_FILTERS,
      };
      break;
    case SELECT_FILE_ERROR:
      state = {
        ...state,
        error: action.payload || "You have to select one CSV file, file type and Marketplace account",
      };
      break;
    case UPDATE_MAP_AND_HANDLING_TIME_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "Updated successfully",
      };
      break;
    case UPDATE_MAP_AND_HANDLING_TIME:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case BULK_UPDATE_HANDLING_TIME_SUCCESS:
      state = {
        ...state,
        success: "Handling time updated successfully",
        error: "",
        loading: false,
      };
      break;
    case BULK_UPDATE_HANDLING_TIME:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case SET_MA_PRICE_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        maPriceModalIsOpen: action.payload,
      };
      break;

    case FETCH_MATCHING_PLATFORMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "",
        error: "",
        matchingPlatforms: action.payload,
      };
      break;

    case FETCH_MATCHING_PLATFORMS:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case ADD_MATCHING_PLATFORM_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case ADD_MATCHING_PLATFORM:
      state = {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
      break;
    case BULK_UPDATE_TWO_STEP_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case BULK_UPDATE_TWO_STEP_MODAL_SUCCESS:
      state = {
        ...state,
        loading: false,
        shippingTemplateUpdateModalIsOpen: false,
        success: action.payload.message,
        error: "",
      };
      break;
    case SET_TWO_STEP_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        twoStepModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_TEMPLATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        templateModal: action.payload,
        loading: false,
      };
      break;
    case DELETE_TEMPLATE:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_TEMPLATE_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message, updateShippingData: action.payload };
      break;
    case CREATE_TEMPLATE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case CREATE_TEMPLATE_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        error: "",
        loading: false,
        updateShippingData: action.payload,
        templateModal: false,
      };
      break;
    case RESET_WEIGHT_ERR:
      state.weightErr = null;
      break;
    case DOWNLOAD_FILE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DOWNLOAD_FILE_SUCCESS:
      state = {
        ...state,
        success: action.payload?.message || "",
        loading: false,
      };
      break;
    case types.FETCH_INVENTORY_ITEM_INFO:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.FETCH_INVENTORY_ITEM_INFO_SUCCESS:
      state = { ...state, loading: false, whItemData: action.payload };
      break;
    case types.RESET_WH_SKU_INVENTORY:
      state = { ...state, whItemData: {} };
      break;
    case API_ERROR: {
      const { error, isFetchErr, isWeightErr } = action.payload;
      state = {
        ...state,
        whItemData: {},
        error: !isFetchErr && !isWeightErr ? error : "",
        weightErr: isWeightErr ? error : "",
        loading: false,
        shippingTemplateLoading: false,
        weightLoader: false,
        fetchError: isFetchErr ? error : "",
      };
      break;
    }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default listings;
