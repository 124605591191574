import { concat } from "lodash";
import {
  GET_PAYMENT_SOURCES,
  GET_PAYMENT_SOURCES_SUCCESS,
  DELETE_SOURCE,
  DELETE_SOURCE_SUCCESS,
  UPDATE_PAYMENT_SOURCE,
  UPDATE_PAYMENT_SOURCE_SUCCESS,
  SET_ADD_CARD_MODAL,
  GET_CUSTOMER_HISTORY,
  GET_CUSTOMER_HISTORY_SUCCESS,
  RETRY_WH_CHARGES,
  RETRY_WH_CHARGES_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  isWHEnabled: false,
  chargeHistory: [],
  loading: false,
  addCardModalIsOpen: false,
};

const Stripe = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_SOURCES:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case GET_PAYMENT_SOURCES_SUCCESS:
      state = {
        ...state,
        sources: action.payload.sources,
        loading: false,
      };
      break;
    case DELETE_SOURCE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DELETE_SOURCE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Payment Method Deleted Successfully",
        loading: false,
      };
      break;
    case RETRY_WH_CHARGES:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case RETRY_WH_CHARGES_SUCCESS:
      state = {
        ...state,
        success: action.payload.displaySuccess ? action.payload.res.message : state.success,
        error: "",
        loading: false,
        isWHEnabled: action.payload.res.whEnabled,
      };
      break;
    case UPDATE_PAYMENT_SOURCE:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case UPDATE_PAYMENT_SOURCE_SUCCESS:
      state = { ...state, success: "Card Updated Successfully", loading: false };
      break;
    case SET_ADD_CARD_MODAL:
      state = {
        ...state,
        addCardModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case GET_CUSTOMER_HISTORY:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case GET_CUSTOMER_HISTORY_SUCCESS: {
      const { data, has_more: more, addData } = action.payload;
      state = {
        ...state,
        loading: false,
        chargeHistory: addData ? concat(state.chargeHistory, data) : data,
        has_more: more,
      };
      break;
    }
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      break;
  }
  return state;
};

export default Stripe;
