import React, { useState, useEffect } from "react";
import { Card, CardBody, Badge, Alert, Tooltip, Button, Row, Col, Label, CardHeader } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo, isAdmin, RenderIf, toPascalCase } from "../../../utils/common";
import { pick, startCase, orderBy, merge, compact } from "lodash";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import moment from "moment";
// Import Components
import AddClientModal from "./AddClientModal";
import EditClientModal from "./EditClientModal";
import PaymentReportModal from "../../../components/Common/PaymentReportModal";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PillBtn from "../../../components/Common/PillBtn";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import CustomPagination from "../../../components/Common/CustomPagination";

// Import Actions
import {
  setAddClientModal,
  updatePaymentSourceSettings,
  setPaymentReportModal,
  setEditClientModal,
  removeClient,
  fetchStoreFrontHealthMatrics,
} from "../../../store/actions";
import * as actions from "../../../store/Agency/actions";

// Constants
import {
  PAYMENT_SOURCE,
  UN_PROCESSED_STATUSES,
  PROCESSED_STATUSES,
  ORDER_STATUS_MAPPING,
  USER_ROLES,
  ACCOUNT_STATUSES,
} from "../../../constants";
import { Link } from "react-router-dom";
import DateRange from "../../../components/Common/DateRange";
import StorageCheckbox from "./StorageCheckBox";
const isAllowed = [USER_ROLES.owner, USER_ROLES.admin].includes(getUserInfo()?.role);
const STATUS_COLOR_CODE = {
  active: "success",
  in_active: "warning",
  payment_failed: "danger",
};

const getTooltipText = (statuses, status) =>
  (status === "total_processed" ? PROCESSED_STATUSES : UN_PROCESSED_STATUSES)
    .map((status, key) =>
      statuses[status]
        ? (
        <div key={key}>
          {`${ORDER_STATUS_MAPPING[status] || startCase(status)}: ${statuses[status]}`}
          <br />
        </div>
          )
        : null,
    )
    .filter((x) => x);

const AgencyClients = () => {
  const [filters, setFilters] = useState({ page: 1, per_page: 20, email: "" });
  const dispatch = useDispatch();
  const [accType, setAccType] = useState("");

  const {
    clientLoader,
    clients,
    totalCount,
    perPage,
    page,
    clientsProfit,
    addClientModalIsOpen,
    clientPaymentSuccess,
    editClientModalIsOpen,
    fetchClientLoader,
    successMessage,
    archived,
  } = useSelector((state) => state.Agency);
  const currentDate = moment();
  const oneWeekBefore = moment().subtract(6, "days");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);
  const [client, setClient] = useState({});
  const [search, setSearch] = useState("");
  const { success: newClientSuccess } = useSelector((state) => state.Account);
  const { paymentReportModalIsOpen } = useSelector((state) => state.SourceOrders);
  const { account, success: existingClientSuccess } = useSelector((state) => state.Settings);
  const [toolTipOrder, setToolTipOrder] = useState({ isOpen: false, key: 0 });
  const [displayedData, setDisplayedData] = useState([]);
  const [clientTab, setClientTab] = useState([]);
  const [tabsFilter, setTabFilter] = useState({
    sort_by: "client_profit",
    sort_direction: "desc",
  });

  const fetchClientsDate = ({ startDate, endDate, page, perPage, email, status, archived }) => {
    const sharedParams = { startDate, endDate, page, per_page: perPage, email, archived };
    if (status !== "All") merge(sharedParams, { status });
    dispatch(actions.fetchAgencyClients({ ...sharedParams }));
  };

  useEffect(() => {
    if (isAllowed) {
      !clientLoader &&
        fetchClientsDate({
          startDate: dates[0].startOf("day").format("YYYY-MM-DD"),
          endDate: dates[1].startOf("day").format("YYYY-MM-DD"),
          page: filters.page,
          perPage: filters.per_page,
          email: filters.email,
          status: filters.status,
          archived: filters.archived,
        });
    }
  }, [dates[0], dates[1], filters]);

  useEffect(() => {
    setTimeout(() => dispatch(actions.updateMessage("")), 2500);
  }, [successMessage]);

  useEffect(() => {
    if (clients.length && clientsProfit) {
      clients.forEach((x) => {
        x.client_profit = clientsProfit[+x?.id] || 0;
      });
      setClientTab(orderBy(clients, "client_profit", tabsFilter.sort_direction));
    }
  }, [clients, clientsProfit, tabsFilter]);

  useEffect(() => {
    if (clientTab?.length) {
      const filterData = clientTab;
      setDisplayedData(filterData);
    }
  }, [filters, clientTab, search]);

  const tableHeaders = compact([
    "Client Info",
    "Storefronts",
    "Status",
    "Profit Percentage",
    "Orders",
    "Profit",
    "Total Client Profit",
    "Subscription Source",
    "Credits Source",
    "WH Charges Source",
    isAdmin() && "Storage Charges Exemption",
    "Actions",
  ]).map((title) => {
    if (title === "Total Client Profit") {
      return {
        title,
        sort: true,
        onClick: () =>
          setTabFilter({
            ...tabsFilter,
            sort_by: "sales",
            sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
            filter_index: 0,
          }),
      };
    } else return { title };
  });

  const settingCols = ["subscription_source", "credits_source", "wh_charges_source"];
  const isAgencyPaymentAdded = account?.data?.payment_source_added;
  const handleAgencyClient = (_) => dispatch(setAddClientModal(!addClientModalIsOpen));

  return (
    <React.Fragment>
      {paymentReportModalIsOpen && (
        <PaymentReportModal
          isOpen={paymentReportModalIsOpen}
          setIsOpen={setPaymentReportModal}
          agency={{ id: client.id }}
          MarketPlaceAccount={{ agencyAccounts: { data: client.marketplaces } }}
        />
      )}
      {addClientModalIsOpen && <AddClientModal />}
      {editClientModalIsOpen && <EditClientModal />}
      {/* Render Breadcrumb */}
      <Breadcrumbs title="Clients" />
      <CardHeader>
        <div className="d-flex flex-column mr-2">
          <Row className="justify-content-end">
            <Col lg={10} md={11} sm={12}>
              <div className="d-flex align-items-center justify-content-end grid-column-gap-10">
                <Label className="mb-0 mt-2">Specify Date Range for Profit</Label>
                <DateRange
                  align="float-right"
                  dates={dates}
                  setDates={setDates}
                  timePeriods={[7, 30, 180, 365]}
                  loader={clientLoader}
                  isInLine
                />
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col>
              <form
                className="ml-2 app-search d-lg-block"
                onSubmit={(e) => {
                  e.preventDefault();
                  setFilters({ ...filters, email: search, page: 1 });
                }}
              >
                <div className="position-relative mr-2" style={{ width: "225px" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by email"
                    value={search}
                    onChange={(e) => {
                      if (!e.target.value) {
                        delete filters.email;
                        setSearch("");
                      } else setSearch(e.target.value.trim());
                    }}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form>
            </Col>
            <Col className="mt-2 form-inline d-flex justify-content-end">
              <RenderIf isTrue={accType !== ACCOUNT_STATUSES.in_active}>
                <div className="custom-control custom-switch custom-switch-lg mr-4">
                  <input type="checkbox" className="custom-control-input" id="account_status" checked={archived} />
                  <label
                    onClick={() => {
                      setFilters({ ...filters, archived: !archived, page: 1 });
                      dispatch(actions.setArchived(!archived));
                    }}
                    className="custom-control-label cursor-pointer"
                  >
                    Include Archived
                  </label>
                </div>
              </RenderIf>
              <SimpleSelect
                className="mb-0 agency-clients-dropdown"
                name="status"
                value={{
                  value: accType || "",
                  label: startCase(accType || "Account statuses"),
                }}
                formStyle={{ minWidth: "220px" }}
                onChange={(e) => {
                  setAccType(e.value);
                  setFilters({ ...filters, status: e.value, page: 1 });
                }}
                options={["All", ACCOUNT_STATUSES.active, ACCOUNT_STATUSES.in_active].map((type) => ({
                  label: startCase(type),
                  value: type,
                }))}
              />
              <PillBtn
                color="primary"
                title="Add Client"
                name="Add Client"
                icon="bx bx-xs bx-plus"
                onClick={handleAgencyClient}
              />
            </Col>
          </Row>
        </div>
      </CardHeader>
      <RenderIf isTrue={newClientSuccess || existingClientSuccess || clientPaymentSuccess || successMessage}>
        <div className="auto-hide">
          <Alert color="success" className="my-1">
            <i className="bx bx-info-circle pr-2"></i>
            {newClientSuccess || existingClientSuccess || clientPaymentSuccess || successMessage}
          </Alert>
        </div>
      </RenderIf>
      <Card>
        {clients?.length || fetchClientLoader
          ? (
          <CardBody>
            <RenderIf
              isTrue={!fetchClientLoader}
              fallback={
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>Loading...</h1>
                  </div>
                </div>
              }
            >
              <div className="table-responsive">
                <table className="table table-for-select table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {tableHeaders.map((header, index) => (
                        <th key={`table-header ${index}`} onClick={header.onClick}>
                          {header.title}
                          <RenderIf isTrue={header.sort}>
                            <div className="d-inline">
                              <i
                                id="up-filter"
                                style={{ fontSize: "10px" }}
                                className={`dripicons-arrow-thin-up cursor-pointer ${
                                  tabsFilter.sort_direction === "asc" ? true : "d-none"
                                }`}
                              ></i>
                              <i
                                id="down-filter"
                                style={{ fontSize: "10px" }}
                                className={`dripicons-arrow-thin-down cursor-pointer ${
                                  tabsFilter.sort_direction === "desc" ? "" : "d-none"
                                }`}
                              ></i>
                            </div>
                        </RenderIf>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {displayedData.map((client, key) => (
                      <tr key={"_agency_clients_" + key} className="mt-3 mb-3">
                        <td>
                          <div className="d-flex flex-column mt-20">
                            <span>{toPascalCase(client.username || client.users?.[0]?.username)}</span>
                            <span className="d-flex">
                              <Button
                                style={{ textAlign: "left" }}
                                className="p-0"
                                color="link"
                                onClick={() => dispatch(fetchStoreFrontHealthMatrics(client.email))}
                              >
                                {client.email}
                              </Button>
                              <CopyToClipBoard text={client.email} />
                            </span>
                          </div>
                        </td>
                        <td>
                          <p className=" mt-30">
                            {client.marketplaces?.length ? client.marketplaces.map((x) => `${x.name} `) : "N/A"}
                          </p>
                        </td>
                        <td>
                          <Badge
                            className={"font-size-13  mt-30 badge-soft-" + STATUS_COLOR_CODE[client.status]}
                            color={STATUS_COLOR_CODE[client.status]}
                            pill
                          >
                            {toPascalCase(client.status === "in_active" ? "inactive" : client.status)}
                          </Badge>
                        </td>
                        <td>
                          <p className=" mt-30">{client?.agency_profit ? client?.agency_profit : 0}</p>
                        </td>
                        <td>
                          <div className="mt-20">
                            {client.orders
                              ? Object.entries(pick(client.orders, "total_unprocessed", "total_processed")).map(
                                ([k, v], i) => (
                                    <div key={"__orders__" + i} id={`status_${i}_${key}`}>
                                      <span className="d-flex" key={"_orders_data_" + i}>
                                        {toPascalCase(k.split("_")?.[1])}:&nbsp;
                                        <h6 className="mt1px">{v.toLocaleString()}</h6>
                                      </span>
                                      {getTooltipText(client.orders, k)?.length !== 0 && (
                                        <Tooltip
                                          placement="top"
                                          isOpen={toolTipOrder.isOpen && toolTipOrder.key === `status_${i}_${key}`}
                                          target={`status_${i}_${key}`}
                                          toggle={() =>
                                            setToolTipOrder({
                                              isOpen: !toolTipOrder.isOpen,
                                              key: `status_${i}_${key}`,
                                              text: getTooltipText(client.orders, k),
                                            })
                                          }
                                        >
                                          {toolTipOrder.text || ""}
                                        </Tooltip>
                                      )}
                                    </div>
                                ),
                              )
                              : "N/A"}
                          </div>
                        </td>
                        <td>
                          <div className="mt-30">
                            <span className="d-flex">
                              <h6 className="mt1px text-info">{client.lastDayProfit}</h6>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="mt-30">${client?.client_profit || "0"}</div>
                        </td>
                        {settingCols.map((col, key) => (
                          <td key={`__setting_cols__${key}`}>
                            <SimpleSelect
                              value={{
                                label: PAYMENT_SOURCE[client[col]],
                                value: client[col],
                              }}
                              onChange={(source) => {
                                if (isAgencyPaymentAdded) {
                                  client[col] !== source.value &&
                                    dispatch(
                                      updatePaymentSourceSettings({ data: { [col]: source.value }, id: client.id }),
                                    );
                                } else dispatch(actions.apiError("Please Add your Payment Source"));
                              }}
                              placeholder={toPascalCase(col)}
                              name={col}
                              className="mt-3"
                              options={Object.keys(PAYMENT_SOURCE).map((key, i) => ({
                                label: PAYMENT_SOURCE[key],
                                value: key,
                                key: i,
                              }))}
                              formStyle={{ minWidth: "220px" }}
                            />
                          </td>
                        ))}
                        <RenderIf isTrue={isAdmin()}>
                          <td>
                            <StorageCheckbox
                              msg="all the inventory of this client"
                              className="mt-4 w-100 justify-content-center d-flex"
                              val={client.is_storage_exempt}
                              onConfirm={val => dispatch(updatePaymentSourceSettings({ data: { is_storage_exempt: val }, id: client.id }))}
                              isShowLabel={false}
                            />
                          </td>
                        </RenderIf>
                        <td>
                          <div className="mt-20 d-flex align-items-center">
                            <RenderIf isTrue={client.status === ACCOUNT_STATUSES.active}>
                              <div className="custom-control custom-switch custom-switch-lg">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="account_status"
                                  checked={client.archived}
                                />
                                <label
                                  title="Archive User"
                                  onClick={() =>
                                    dispatch(actions.archiveClient({ id: client.id, archived: !client.archived }))
                                  }
                                  className="custom-control-label cursor-pointer"
                                ></label>
                              </div>
                            </RenderIf>
                            <i
                              className="bx bx-sm mdi mdi-close-thick pl-2 text-secondary cursor-pointer"
                              title="Remove Client"
                              onClick={() => dispatch(removeClient(client.id))}
                            ></i>
                            <i
                              className="bx bx-sm bx-edit pl-2 text-warning cursor-pointer"
                              title="Edit Client Settings"
                              onClick={() => dispatch(setEditClientModal(true, client))}
                            ></i>
                            <i
                              className="bx bx-sm bx-cloud-download pl-2 cursor-pointer"
                              title={"Download Payment Report"}
                              onClick={() => {
                                setClient({ id: client.id, marketplaces: client.marketplaces });
                                dispatch(setPaymentReportModal(true));
                              }}
                            ></i>
                            <Link to={`/exported_reports?email=${client?.email}`} target="_blank">
                              <i
                                className="bx bx-sm bxs-file text-secondary cursor-pointer"
                                title="View Exported Reports"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </RenderIf>
            {clientTab?.length
              ? (
              <CustomPagination
                total={totalCount}
                pageOptions={[20, 50, 100]}
                page={page}
                perPage={perPage}
                tabsFilter={filters}
                setTabFilter={setFilters}
                inAgencyComponent={true}
              />
                )
              : null}
          </CardBody>
            )
          : null}

        {clients?.length === 0 && !fetchClientLoader
          ? (
          <div className="d-flex justify-content-center m-2">
            <div className="d-flex flex-column">
              <h1>No Clients Found</h1>
            </div>
          </div>
            )
          : null}
      </Card>
    </React.Fragment>
  );
};

export default AgencyClients;
