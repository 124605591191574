import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
// actions

import { formatMarketplaceName, humanize } from "../../../utils/common";
import { entries, shuffle, startCase } from "lodash";

const oosReasonsColors = [
  "#FFC000",
  "#FFD700",
  "#C9B30A",
  "#FFEA00",
  "#FFBF00",
  "#E30B5C",
  "#F88379",
  "#D2042D",
  "#E97451",
  "#CC5500",
  "#AA4A44",
  "#EE4B2B",
  "#FDA50F", // Orange Color Shades below
  "#FFFF00",
  "#FFAA33",
  "#FF7417",
  "#CC8D08",
  "#FF4433",
  "#FF5F15",
  "#EC5800",
  "#FF9900",
  "#F4C430",
  "#FFAC1C",
  "#FA5F55",
  "#EB9605",
  "#BF200D",
];

const ListingStats = (props) => {
  const { listingStats, refresherLoader } = useSelector((state) => state.Graph);
  const colors = ["#34C38F", "#FEB019", "#775DD0", "#008FFB", "#FF4560"].concat(shuffle(oosReasonsColors));

  useEffect(() => {}, [!refresherLoader && listingStats]);

  const assignKey = (obj, reason, value) => {
    if (!obj[reason]) obj[reason] = { name: startCase(reason), data: [] };
    obj[reason].data.push(value);
  };

  const refreshableSuppliers = Object.values(
    listingStats?.refreshable_suppliers_stats?.reduce(
      (acc, x) => {
        if (!x.total) return acc;
        acc.in_stock.data.push(x.in_stock);
        entries(x.oos_reasons).forEach(([reason, count]) => assignKey(acc, reason, count));
        return acc;
      },
      { in_stock: { name: "In Stock", data: [] } },
    ) || {},
  ).filter((x) => x.data?.some((x) => x));

  const refreshableSuppliersOpts = {
    chart: { stacked: true, toolbar: { show: false } },
    plotOptions: { bar: { horizontal: false, columnWidth: "45%" } },
    dataLabels: { enabled: false },
    legend: { markers: { width: 12, height: 12, radius: 12 } },
    stroke: { show: true, width: 2, colors },
    colors,
    xaxis: {
      categories:
        listingStats?.refreshable_suppliers_stats?.reduce((acc, x) => {
          x.total && acc.push(formatMarketplaceName(x.name));
          return acc;
        }, []) || [],
      crosshairs: { show: false },
    },
    grid: { borderColor: "#f1f1f1" },
    fill: { opacity: 1 },
    yaxis: { labels: { formatter: (x) => humanize(x) } },
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between">
          <h4 className="card-title mb-4">{refresherLoader ? "Loading..." : "Stock by Supplier"}</h4>
          {!refresherLoader && (
            <div className="d-flex">
              <div className="d-flex flex-column mr-2">
                {props.abilities.can("visit", "listings")
                  ? (
                  <Link to="/listings" target="_blank">
                    With Suppliers
                  </Link>
                    )
                  : (
                  <h6>With Suppliers</h6>
                    )}
                <strong className="text-center black-color">
                  {humanize(listingStats?.known_suppliers_count || 0)}
                </strong>
              </div>
              <div className="d-flex flex-column">
                {props.abilities.can("visit", "listings")
                  ? (
                  <Link to="/listings?filter=missing_suppliers" target="_blank">
                    Without Suppliers
                  </Link>
                    )
                  : (
                  <h6>Without Suppliers</h6>
                    )}
                <strong className="text-center black-color">
                  {humanize(listingStats?.unknown_suppliers_count || 0)}
                </strong>
              </div>
            </div>
          )}
        </div>
        <div className="clearfix"></div>
        <ReactApexChart options={refreshableSuppliersOpts} series={refreshableSuppliers} type="bar" height="350" />
      </CardBody>
    </Card>
  );
};

export default ListingStats;
