import React from "react";
import { Button, Row, Col, Alert, Label, Input } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";

// actions
import { keys, startCase } from "lodash";

const EditWHCostModal = (props) => {
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);

  const { warehouse_shipping: whShipping, warehouse_fee: whFee } = props.sourceOrderDetail?.data || {};

  const validate = (values) => {
    const errors = {};
    keys(values).forEach(
      (key) => [undefined, ""].some((x) => values[key] === x) && (errors[key] = "Field is Required"),
    );

    if (keys(errors).length) return errors;
    ["warehouse_shipping", "warehouse_fee"].forEach(
      (key) => values[key] < 0 && (errors[key] = `${startCase(key)} must be a positive number`),
    );
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      warehouse_shipping: whShipping === (undefined || "") ? "" : whShipping,
      warehouse_fee: whFee === (undefined || "") ? "" : whFee,
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (["warehouse_shipping", "warehouse_fee"].some((key) => values[key] !== props.sourceOrderDetail?.data?.[key])) {
        props.updateOrder({ id: props?.sourceOrderDetail?.data?.id, data: values, showSuccess: true });
      } else toggleModal();
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Warehouse Shipping Charges</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders?.error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mb-2 hide-arrows">
                <Label>Warehouse Shipping</Label>
                <Input
                  name="warehouse_shipping"
                  type="number"
                  step="0.01"
                  invalid={
                    formik.touched.warehouse_shipping &&
                    !/^[+]?([0-9]+(?:[\\.][0-9]*)?|\.[0-9]+)$/.test(formik.values.warehouse_shipping)
                  }
                  min={0}
                  value={formik.values.warehouse_shipping}
                  onChange={(e) => formik.handleChange(e)}
                  onKeyPress={(e) => {
                    if (e.key === "-") e.preventDefault();
                  }}
                />
                {formik.touched.warehouse_shipping && formik.errors.warehouse_shipping
                  ? (
                  <small className="text-danger mt-1">{formik.errors.warehouse_shipping}</small>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2 hide-arrows">
                <Label>Warehouse Fee</Label>
                <Input
                  name="warehouse_fee"
                  type="number"
                  step="0.01"
                  invalid={
                    formik.touched.warehouse_fee &&
                    !/^[+]?([0-9]+(?:[\\.][0-9]*)?|\.[0-9]+)$/.test(formik.values.warehouse_fee)
                  }
                  min={0}
                  value={formik.values.warehouse_fee}
                  onChange={(e) => formik.handleChange(e)}
                  onKeyPress={(e) => {
                    if (e.key === "-") e.preventDefault();
                  }}
                />
                {formik.touched.warehouse_fee && formik.errors.warehouse_fee
                  ? (
                  <small className="text-danger mt-1">{formik.errors.warehouse_fee}</small>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default EditWHCostModal;
