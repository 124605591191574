import request from "../restClient";
import { makeQP } from "../../utils/common";

export const getAllSharedListings = async (params) => {
  return await request({ url: "/shared_listings" + makeQP(params), method: "GET" });
};

export const sendInvoiceEmail = async (data) => {
  return await request({ url: "/shared_listings/send_email/", method: "POST", body: data });
};
