import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logoWhite from "../../../assets/svg/logoWhite.svg";
import { formatNumber, last, toF } from "../../../utils/common";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Container } from "reactstrap";
import { first } from "lodash";
import { DATE_FORMATS } from "../../../constants";

const DownloadPDF = (props) => {
  const { clientsStats } = props;
  const { account } = useSelector((state) => state.Settings);

  const [data, setData] = useState({
    "Total Marketplace Fee": "",
    "Total COGS": "",
    "Total Returns": "",
    Software: "",
    "WH S&H": "",
  });
  const [totalExpenses, setTotalExpense] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [agencyFee, setAgencyFee] = useState();
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (!clientsStats || !account) return;
    const totalSales = clientsStats?.data?.sales.reduce((x, y) => x + y.total, 0);
    const totalCost = clientsStats?.data?.cost.reduce((x, y) => x + y.total, 0);
    const profit = totalSales - totalCost;
    data["Total Marketplace Fee"] = toF(clientsStats?.data?.marketplaceFee.reduce((x, y) => x + y.fee, 0));
    data["Total COGS"] = toF(totalCost);
    data["Total Returns"] = toF(clientsStats?.data?.returns.reduce((x, y) => x + y.total, 0));
    data.Software = toF(account?.data?.current_plan || 0);
    data["WH S&H"] = toF(clientsStats?.data?.warehouseSandH.reduce((x, y) => x + y.charges, 0));
    setData(data);
    setAgencyFee(toF(profit * (account?.data?.agency_profit / 100)));
    setTotalExpense(toF(Object.keys(data).reduce((x, y) => x + +data[y], 0)));
    setTotalRevenue(toF(clientsStats?.data?.sales.reduce((x, y) => x + y.total, 0)));
    setDates([moment(first(clientsStats?.data?.sales)?.date), moment(last(clientsStats?.data?.sales)?.date)]);
  }, [clientsStats, account]);

  return (
    <React.Fragment>
      <Container>
        <div id="content" style={props.style}>
          <div className="pdf-content" style={{ backgroundColor: "#fff" }}>
            <div className="pdf-header" style={{ backgroundColor: "#556fe6" }}>
              <div className="d-flex justify-content-between p-5">
                <div className="d-flex flex-column">
                  <img src={logoWhite} alt={"Ecom Circles"}></img>
                  <h3 style={{ color: "#fff" }}>Profit & Loss Statement</h3>
                </div>
                <div className="d-flex flex-column align-items-end justify-content-end">
                  <p style={{ color: "#fff" }}>Generated Date: {moment().utc().format("LL")}</p>
                  <p style={{ color: "#fff" }}>
                    Date range: {dates[0]?.utc().format("DD/MM/YYYY")} - {dates[1]?.utc().format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <div className="pfd-body m-4">
              <OrdersChart orders={clientsStats?.data?.orders} dates={dates} />{" "}
              <div className="mt-4 mx-4">
                <h6 style={{ color: "#556fe6", fontSize: 15 }}>
                  <b>REVENUES</b>
                </h6>
              </div>
              <div
                className="d-flex justify-content-between mx-3 p-2 align-items-center"
                style={{ backgroundColor: "#F9F9FA", borderBottom: "0.25px solid rgba(147, 156, 185, 0.3)" }}
              >
                <p className="mb-0" style={{ color: "#70757B", fontSize: 10 }}>
                  Total Revenue
                </p>
                <p className="mb-0">
                  <b style={{ color: "#32394E", fontSize: 10 }}>${totalRevenue?.toLocaleString()}</b>
                </p>
              </div>
              <div className="m-4">
                <h6 className="mt-5" style={{ color: "#556fe6", fontSize: 15 }}>
                  <b>EXPENSES</b>
                </h6>
                {Object.keys(data).map((x) => (
                  <div
                    key={x}
                    className="d-flex justify-content-between py-2 align-items-center"
                    style={{ borderBottom: "0.25px solid rgba(147, 156, 185, 0.3)" }}
                  >
                    <p className="mb-0" style={{ color: "#70757B", fontSize: 10 }}>
                      {x}
                    </p>
                    <p className="mb-0" style={{ fontSize: 10 }}>
                      <b style={{ color: "#32394E", fontSize: 10 }}>${data[x]?.toLocaleString()}</b>
                      {x === "Agency Fee" && ` (${account?.data?.agency_profit}%)`}
                    </p>
                  </div>
                ))}
              </div>
              <div
                className="d-flex justify-content-between mx-3 p-2 align-items-center"
                style={{ backgroundColor: "#F9F9FA", borderBottom: "0.25px solid rgba(147, 156, 185, 0.3)" }}
              >
                <p className="mb-0" style={{ color: "#70757B", fontSize: 10 }}>
                  Total Expenses
                </p>
                <p className="mb-0">
                  <b style={{ color: "#32394E", fontSize: 10 }}>${totalExpenses?.toLocaleString()}</b>
                </p>
              </div>
              <div className="mt-4 mx-4">
                <h6 className="mt-5" style={{ color: "#556fe6", fontSize: 15 }}>
                  <b>Net Profit</b>
                </h6>
              </div>
              <div
                className="d-flex justify-content-between mx-3 p-2 align-items-center"
                style={{ borderBottom: "0.25px solid rgba(147, 156, 185, 0.3)" }}
              >
                <p className="mb-0" style={{ color: "#70757B", fontSize: 10 }}>
                  Total Profit
                </p>
                <p className="mb-0" style={{ fontSize: 10 }}>
                  <b style={{ color: "#32394E", fontSize: 10 }}>
                    {formatNumber(totalRevenue - totalExpenses)}
                  </b>
                </p>
              </div>
              <div
                className="d-flex justify-content-between mx-3 p-2 align-items-center"
                style={{ borderBottom: "0.25px solid rgba(147, 156, 185, 0.3)" }}
              >
                <p className="mb-0" style={{ color: "#70757B", fontSize: 10 }}>
                  Agency Fee
                </p>
                <p className="mb-0" style={{ fontSize: 10 }}>
                  <b style={{ color: "#32394E", fontSize: 10 }}>${agencyFee?.toLocaleString()}</b>
                  {` (${account?.data?.agency_profit}%)`}
                </p>
              </div>
              <div
                className="d-flex justify-content-between mx-3 p-2 mb-4 align-items-center"
                style={{ backgroundColor: "#F9F9FA", borderBottom: "0.25px solid rgba(147, 156, 185, 0.3)" }}
              >
                <p className="mb-0" style={{ color: "#70757B", fontSize: 10 }}>
                  Profit
                </p>
                <p className="mb-0">
                  <b style={{ color: "#32394E", fontSize: 10 }}>
                    {formatNumber(totalRevenue - totalExpenses - agencyFee)}
                  </b>
                </p>
              </div>
            </div>
            <div
              className="pdf-footer"
              style={{
                height: "8px",
                top: "1107px",
                background: "#556EE6",
              }}
            ></div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

const OrdersChart = (props) => {
  const series = [
    {
      name: "Orders",
      data: (props?.orders && props?.orders.map((x) => parseFloat(x.orders)?.toFixed(2))) || [0],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No Data for given date range",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6"],
    xaxis: {
      type: "datetime",
      categories: props?.orders ? props?.orders.map((x) => moment(x?.date).format("YYYY-MM-DD")) : [props.dates[1]],
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE_FORMATS.DATE) }, // Timestamp is ALWAYS UTC
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <Card className="pdf-graph">
      <CardBody>
        <div className="mb-4 d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="card-title">Orders</h4>
          </div>
        </div>
        <div className="clearfix"></div>
        <ReactApexChart options={options} series={series} type="area" height="350" />
      </CardBody>
    </Card>
  );
};

export default DownloadPDF;
