import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import { createOrderLog } from "../../api";
import { concat } from "lodash";
// Login Redux States
import { CREATE_ORDER_LOG } from "./actionTypes";
import { apiError, createOrderLogSuccess, setOrderLogModal, resetSuccessErrMsg } from "../actions";

const SourceOrders = (state) => state.SourceOrders;

function * createOrderLogSaga ({ payload: { data } }) {
  try {
    const res = yield call(createOrderLog, { data });
    const { sourceOrderDetail } = yield select(SourceOrders);
    sourceOrderDetail.data.order_logs = concat([res.data], sourceOrderDetail?.data?.order_logs);
    yield put(resetSuccessErrMsg());
    yield put(setOrderLogModal(false));
    yield put(createOrderLogSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchOrderLogCreate () {
  yield takeEvery(CREATE_ORDER_LOG, createOrderLogSaga);
}

function * OrderLog () {
  yield all([fork(watchOrderLogCreate)]);
}

export default OrderLog;
