import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { Nav, NavItem, NavLink } from "reactstrap";
import moment from "moment-timezone";
import { DATE_FORMATS } from "../../constants";
import { adjustDate, getEstDate } from "../../utils/common";

const { DATE_PICKR, DATE } = DATE_FORMATS;

const DateRange = ({ dates, setDates, loader, isInLine, timePeriods, noMargin, align }) => {
  const components = [DatePicker];
  timePeriods && components.push(TimePeriodNav);

  if (isInLine) components.reverse();
  return (
    <div
      className={`d-flex ${isInLine ? "datepick-heading-w" : "flex-column"} ${
        align || "align-items-right justify-content-end align-items-baseline"
      }`}
    >
      {components.map((Component, key) => (
        <React.Fragment key={key}>{Component({ dates, setDates, loader, timePeriods, noMargin })}</React.Fragment>
      ))}
    </div>
  );
};

function DatePicker ({ dates, setDates, loader, noMargin }) {
  let date = "";
  return (
    <Flatpickr
      className={`form-control rounded-pill w-230 ml-${noMargin ? "0" : "3"}`}
      placeholder="Select Date Range"
      value={dates.map((x) => x.format(DATE))}
      disabled={loader}
      options={{
        maxDate: getEstDate().endOf("day").toDate(),
        mode: "range",
        dateFormat: DATE_PICKR,
        defaultDate: dates,
      }}
      onClose={(val, dateStr) => {
        const newDates = val.map(adjustDate);
        if (date && date === dateStr) return;
        date = dateStr;
        if (val.length > 0)
          !val[1] ? setDates([moment(newDates[0]), moment()]) : setDates(newDates.map((x) => moment(x)));
      }}
    />
  );
}

function TimePeriodNav ({ dates, timePeriods, setDates }) {
  const [timePeriod, setTimePeriod] = useState(0);
  useEffect(() => {
    if (!timePeriods.includes(1 + dates[1].diff(dates[0], "days"))) setTimePeriod(-1);
  }, [dates]);
  return (
    <Nav pills className="navtab-bg nav-justified dashboard-navabr mt-2">
      {timePeriods.map((item, key) => (
        <NavItem key={"_col_" + key}>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: key === timePeriod })}
            onClick={() => {
              setTimePeriod(key);
              setDates([moment().subtract(item - 1, "days"), moment()]);
            }}
          >
            {item} Days
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}

export default DateRange;
