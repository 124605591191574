import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Alert, Card, Label } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import Dropzone from "react-dropzone";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
// actions
import { setPreloader, setUploadReturnLabelModal, uploadReturnLabelFile } from "../../../store/actions";
import { RenderIf } from "../../../utils/common";
import CheckBox from "../../../components/Common/CheckBox";
import { DATE_FORMATS } from "../../../constants";
const { DATE_PICKR } = DATE_FORMATS;

const UploadReturnLabelModal = (props) => {
  const { loading, success, error, uploadReturnLabelModalIsOpen } = useSelector((state) => state.WareHouses);
  const toggleModal = () => dispatch(setUploadReturnLabelModal(!uploadReturnLabelModalIsOpen));

  const maxFileSize = 1024 * 1024 * 2;
  const [uploadError, setUploadError] = useState("");
  const [selectedFile, setselectedFile] = useState([]);
  const [isReturnPickup, setIsReturnPickup] = useState(false);
  const [expDate, setExpDate] = useState();
  const [file] = useState("Return_label_upload");

  const dispatch = useDispatch();

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function triggerAlert (msg) {
    if (uploadError) setUploadError("");
    setUploadError(msg);
    setTimeout(() => setUploadError(""), 5000);
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  //! UseEffects
  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  const uploadFile = () => {
    setUploadError("");
    dispatch(
      uploadReturnLabelFile({
        data: { file_name: selectedFile[0], file_type: file, expiry_date: expDate },
        id: props.item?.id,
        params: { item_type: props.type, is_return_pickup: isReturnPickup },
      }),
    );
    setselectedFile([]);
  };

  return (
    <React.Fragment>
      <Modal size="md" isOpen={uploadReturnLabelModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Upload Return Label File</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {(error || uploadError) && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || uploadError}
            </Alert>
          )}

          {success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {"Return Label successfully uploaded" || ""}
              </Alert>
            </div>
          )}
          {/* Form Start */}
          <form className="m-2">
            <Label>Label Expiry Date</Label>
            <div className="d-flex align-items-center inbound-date-range mb-3">
              <Flatpickr
                key="returnPicker"
                className="form-control"
                placeholder="Select Date"
                value={expDate}
                options={{
                  mode: "single",
                  dateFormat: DATE_PICKR,
                  minDate: new Date(),
                }}
                onChange={(e) => setExpDate(e)}
              />
              <i className="bx font-size-16 bx-calendar text-primary mx-3" />
            </div>
            <Col className="my-1">
              {selectedFile.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFile, fileRejections) => {
                    if (fileRejections.length) {
                      const [file] = fileRejections;
                      if (file.type !== ".pdf") triggerAlert("Invalid File type. It must be a PDF file");
                      if (file.size > maxFileSize) triggerAlert(`Max allowed file size is ${formatBytes(maxFileSize)}`);
                    }
                    handleAcceptedFile(acceptedFile);
                  }}
                  accept=".pdf"
                  maxFiles="1"
                  maxSize={maxFileSize}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div className="dz-message needsclick mt-2" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                        </div>
                        <h4>Drag or Click to Upload PDF File</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              <RenderIf isTrue={selectedFile.length}>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFile.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col>
                              {f.name}
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                              <p className="mb-0"></p>
                            </Col>
                            <i
                              title="Remove File"
                              className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                              onClick={() => {
                                setselectedFile([]);
                                setIsReturnPickup(false);
                              }}
                            ></i>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </div>
                <div>
                  <CheckBox
                    className="mt-2"
                    label="Is this a Return Pickup?"
                    state={isReturnPickup}
                    setState={(_) => setIsReturnPickup(!isReturnPickup)}
                  />
                </div>
                <RenderIf isTrue={expDate}>
                  <div className="text-center mt-4">
                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={uploadFile}>
                      Upload File
                    </button>
                  </div>
                </RenderIf>
              </RenderIf>
            </Col>
          </form>
          {/* Form End */}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UploadReturnLabelModal;
