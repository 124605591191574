import {
  FETCH_EMAILS,
  FETCH_EMAILS_SUCCESS,
  FETCH_ORDER_UPDATES,
  FETCH_ORDER_UPDATES_SUCCESS,
  API_ERROR,
  FETCH_S3_OBJECTS,
  FETCH_S3_OBJECTS_SUCCESS,
  UPDATE_S3_OBJECT,
  UPDATE_S3_OBJECT_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  emails: undefined,
  emailsUpdates: undefined,
  s3Objects: undefined,
};

const Emails = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_EMAILS_SUCCESS:
      state = {
        ...state,
        emails: action.payload,
        loading: false,
      };
      break;
    case FETCH_S3_OBJECTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_S3_OBJECTS_SUCCESS:
      state = {
        ...state,
        s3Objects: action.payload,
        loading: false,
      };
      break;
    case UPDATE_S3_OBJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_S3_OBJECT_SUCCESS:
      state = {
        ...state,
        updatedStatus: action.payload,
        loading: false,
      };
      break;
    case FETCH_ORDER_UPDATES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_ORDER_UPDATES_SUCCESS:
      state = {
        ...state,
        emailsUpdates: action.payload,
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Emails;
