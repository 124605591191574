import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { sumBy } from "lodash";

// actions
import { fetchReturnsGraph, fetchAllTenantReturnsGraph } from "../../../store/actions";
import { formatNumber, humanize } from "../../../utils/common";
import { DATE_FORMATS } from "../../../constants";
import DateRange from "../../../components/Common/DateRange";

const { DATE, GRAPH_DATE } = DATE_FORMATS;

const ReturnsGraph = (props) => {
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const isGlobal = props.type === "global";
  const accountId = props.accountId;
  const store = props.marketplace;
  const timezone = props.timezone;
  const { returnsGraph, returnsLoader } = useSelector((state) => state.Graph);

  const currentDate = moment().startOf("day");
  const [dates, setDates] = useState([moment().subtract(59, "days").startOf("day"), currentDate]);

  const fetchGraphDetails = ({ startDate, endDate }) => {
    const sharedParams = { start_date: startDate, end_date: endDate };
    if (isGlobal) dispatch(fetchAllTenantReturnsGraph({ ...sharedParams, store_name: store }));
    else {
      dispatch(
        fetchReturnsGraph({
          ...sharedParams,
          marketplace_id: accountId,
          utc_offset: moment().format("Z"),
        }),
      );
    }
  };

  useEffect(() => {
    if (firstUpdate.current && isGlobal) {
      firstUpdate.current = false;
      return;
    }
    !returnsLoader &&
      fetchGraphDetails({
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
      });
  }, [accountId, timezone, dates[0], dates[1], store]);

  const series = [
    {
      name: "Returns",
      data: (returnsGraph?.data?.length &&
        returnsGraph.data.map((x) => [x?.date, parseFloat(x.total * -1).toFixed(2)])) || [[dates[0]], [currentDate]],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No Returns Data for given date range",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#ed5c5c"],
    xaxis: {
      type: "datetime",
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE) }, // Timestamp is ALWAYS UTC
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatNumber(value);
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: GRAPH_DATE,
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4 float-sm-left">
          <div className="d-flex flex-column">
            <h4 className="card-title">
              {returnsLoader
                ? "Loading..."
                : `Total Returns ${`(${humanize(sumBy(returnsGraph?.data, "total_returns") || 0)})`}`}
            </h4>
            {formatNumber(sumBy(returnsGraph?.data, (x) => parseFloat(x.total * -1)) || 0)}
            <span>For {moment(dates[1]).diff(moment(dates[0]), "days") + 1} Days</span>
          </div>
        </CardTitle>
        <div className="d-flex flex-row float-sm-right">
          <DateRange dates={dates} setDates={setDates} loader={returnsLoader} timePeriods={[7, 30, 365]} />
        </div>
        <div className="clearfix"></div>
        <ReactApexChart options={options} series={series} type="line" height="350" />
      </CardBody>
    </Card>
  );
};

export default ReturnsGraph;
