import React from "react";
import Modal from "../Common/Modal";
import BookCall from "../Common/BookCall";
import gmailLogo from "../../assets/svg/onboard/emailForwarder/gmail/logo.svg";
import outlookLogo from "../../assets/svg/onboard/emailForwarder/outlook/logo.svg";
import yahooLogo from "../../assets/svg/onboard/emailForwarder/yahoo/logo.svg";
import { Row, Col } from "reactstrap";
import { keys, map } from "lodash";

const DisableEmailForwarder = (props) => {
  const toggleModal = () => props.setIsOpen(!props.isOpen);
  const services = { yahoo: yahooLogo, gmail: gmailLogo, outlook: outlookLogo };

  return (
    <Modal size="lg" isOpen={props.isOpen} toggle={toggleModal}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title pl-4">
          Please click the following images for instructions to disable email forwarding
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body p-4">
        <div className="d-flex flex-column align-items-center mb-3">
          <Row>
            {map(keys(services), (service, key) => (
              <Col key={key}>
                <a
                  href={`https://ecomcircles.helpdocs.com/settings-and-billings/how-to-disable-email-forwarding-for-${service}?preview=true`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img className="img-fluid cursor-pointer" src={services[service]} alt={service} />
                </a>
              </Col>
            ))}
          </Row>
        </div>
        <BookCall />
      </div>
    </Modal>
  );
};

export default DisableEmailForwarder;
