import React from "react";
import { RenderIf } from "../../../../utils/common";
import * as $ from "lodash";
import { Alert } from "reactstrap";

const DataProcessingMsg = (props) => (
  <RenderIf isTrue={$.isEmpty(props.data) && props.store} fallback={props.children}>
    <Alert className="col" color="info">
      <i className="bx bx-info-circle pr-2"></i>
      We are populating this data from your account. This could take up to 4 hours initially but then should be updated
      in close-to-real time.
    </Alert>
  </RenderIf>
);
export default DataProcessingMsg;
