const trackingUrlsMethods = {
  DPD: (trackingNumber) => `https://track.dpd.co.uk/parcels/${trackingNumber}*19322`,
  DHL: (trackingNumber) => `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}`,
  DHLGlobalMail: (trackingNumber) => `https://www.dhl.com/global-en/home/tracking.html?tracking-id=${trackingNumber}`,
  DHLCanada: (trackingNumber) => `https://www.dhl.com/ca-en/home/tracking.html?tracking-id=${trackingNumber}`,
  IMEX: (trackingNumber) => `https://www.tracking-status.com/imex-tracking-tazas/?tn=${trackingNumber}`,
  Globegistics: (trackingNumber) => `https://a1.asendiausa.com/tracking/?trackingnumber=${trackingNumber}`,
  AustraliaPost: (trackingNumber) =>
    `https://digitalapi.auspost.com.au/shipmentsgatewayapi/watchlist/shipments?trackingIds=${trackingNumber}`,
  Asendia: (trackingNumber) => `https://tracking.asendia.com/tracking/${trackingNumber}`,
  BrokersWorldWide: (trackingNumber) =>
    `https://www.tracking-status.com/brokers-worldwide-tracking-page/?tn=${trackingNumber}`,
  APC: (trackingNumber) => `https://www.apc-direct.co.uk/tracking/${trackingNumber}`,
  Newgistics: (trackingNumber) => `http://tracking.newgistics.com/?trackingvalue=${trackingNumber}`,
  LoneStar: (trackingNumber) => `https://www2.lso.com/access/trackingresult.aspx?airbillnos=${trackingNumber}`,
  Dynamex: (trackingNumber) => `https://track.firstmile.com/detail.php?n=${trackingNumber}`,
  Lasership: (trackingNumber) => `https://www.lasership.com/track.php?track_number_input=${trackingNumber}`,
  FedEx: (trackingNumber) => `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${trackingNumber}`,
  OnTrac: (trackingNumber) => `https://www.ontrac.com/trackingresults.asp?tracking_number=${trackingNumber}`,
  ZnLogic: (trackingNumber) => `https://tracking.link/tracking?tracking_id=${trackingNumber}`,
  OSM: (trackingNumber) => `https://www.osmworldwide.com/tracking/?trackingNumbers=${trackingNumber}`,
  Roadie: (trackingNumber) => `https://track.roadie.com/id/${trackingNumber}`,
  CEVA: (trackingNumber) => `https://www.cevalogistics.com/en/ceva-trak?codes=${trackingNumber}&transport_mode=all`,
  Track17: (trackingNumber) => `https://m.17track.net/en/track#nums=${trackingNumber}`,
  ParcelPool: (trackingNumber) => `https://www.myib.com/trackpackage?number=${trackingNumber}`,
  Pilot: (trackingNumber) => `https://www.pilotdelivers.com/technology/?currentTab=consumer&number=${trackingNumber}`,
  ABF: (trackingNumber) => `https://arcb.com/tools/tracking.html#/${trackingNumber}`,
  NSF: (trackingNumber) => `https://tracking.shipnsd.com/?t=${trackingNumber}`,
  EMSY: (trackingNumber) => `https://www.ontrac.com/trackingresults.asp?tracking_number=${trackingNumber}`,
  YunExpress: (trackingNumber) => `https://www.yuntrack.com/Track/Detail/${trackingNumber}`,
  StapleFleet: (ordernumber, zipcode) =>
    `https://www.staples.com/ptd/orderdetails?langId=en_US&orderNumber=${ordernumber}&zipCode=${zipcode}`,
  FirstMile: (trackingNumber) => `https://track.firstmile.com/detail.php?n=${trackingNumber}`,
  ChinaPost: (trackingNumber) => `https://track-chinapost.com/result_china.php?order_no=${trackingNumber}`,
  CourierExpress: (trackingNumber) => `http://soz.vng.mybluehost.me/system/tracking.php?track=${trackingNumber}`,
  BlueCare: (trackingNumber) => `https://bluecare.express/Tracking?trackingReference=${trackingNumber}`,
  BlueCareExpress: (trackingNumber) => `https://bluecare.express/Tracking?trackingReference=${trackingNumber}`,
  RoyalMail: (trackingNumber) => `https://www3.royalmail.com/track-your-item#/tracking-results/${trackingNumber}`,
  CanadaPost: (trackingNumber) => `https://www.canadapost.ca/trackweb/en#/details/${trackingNumber}`,
  USPS: (trackingNumber) => `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
  UPS: (trackingNumber) => `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}&requester=WT/trackdetails`,
  Nonstopdelivery: (trackingNumber, zip = "zip") => `https://tracking.shipnsd.com/?t=${trackingNumber}&z=${zip}`,
  AaaCooper: (trackingNumber) =>
    `http://www.aaacooper.com/Transit/ProTrackResults.aspx?ProNum=${trackingNumber}&AllAccounts=true`,
  NonStopDelivery: (trackingNumber, zip = "zip") => `https://tracking.shipnsd.com/?t=${trackingNumber}&z=${zip}`,
};

const carrierMapping = {
  // AAA Cooper
  aaa_cooper: "AaaCooper",

  // Non Stop Delivery
  nonstopdelivery: "NonStopDelivery",

  // Lone Star Over Night
  lso: "LoneStar",
  lone_star: "LoneStar",
  lone_star_overnight: "LoneStar",
  lonestar: "LoneStar",

  // China Post
  chinapost: "ChinaPost",
  china_post: "ChinaPost",

  // Blue Care Express
  bluecare: "BlueCareExpress",
  blue_care: "BlueCareExpress",
  blue_care_express: "BlueCareExpress",
  bluecare_express: "BlueCareExpress",
  bce: "BlueCareExpress",

  // DPD UK
  dpd: "DPD",
  dpdgroup: "DPD",
  dpd_group: "DPD",

  // DHL
  dhl: "DHL",
  dhl_global_mail: "DHLGlobalMail",
  dhlglobalmail: "DHLGlobalMail",
  dhl_canada: "DHLCanada",
  dhlcanada: "DHLCanada",

  // Dynamex
  dynamex: "Dynamex",
  "dynamex,_inc.": "Dynamex",

  // CEVA Logistics
  ceva: "CEVA",
  ceva_logistics: "CEVA",

  // Roadie
  roadie: "Roadie",

  newgistics: "Newgistics",
  new_gistics: "Newgistics",
  brokersworldwide: "BrokersWorldWide",
  brokers_world_wide: "BrokersWorldWide",
  brokers_worldwide: "BrokersWorldWide",

  globegistics: "Globegistics",
  globe_gistics: "Globegistics",

  asendia: "Asendia",
  apc: "APC",
  australiapost: "AustraliaPost",
  australia_post: "AustraliaPost",
  imex: "IMEX",

  // 17 Track
  "17_track": "Track17",
  "17track": "Track17",

  // ParcelPool
  parcelpool: "ParcelPool",
  parcel_pool: "ParcelPool",

  // OSM
  osm: "OSM",
  osmworldwide: "OSM",
  osm_worldwide: "OSM",
  osm_world_wide: "OSM",

  // Yun Express
  yun: "YunExpress",
  yun_express: "YunExpress",
  yunexpress: "YunExpress",

  // Zn Logistics
  zn_logic: "ZnLogic",
  znlogic: "ZnLogic",
  znlogistics: "ZnLogic",
  zn_logistics: "ZnLogic",

  // ABF Freight System
  abf: "ABF",
  abf_freight_system: "ABF",
  abf_freight: "ABF",

  // First Mile
  firstmile: "FirstMile",
  first_mile: "FirstMile",

  // Emsy Tracking
  emsy: "EMSY",
  emsy_tracking: "EMSY",
  emsytracking: "EMSY",

  // Royal Mail
  royalmail: "RoyalMail",
  royal_mail: "RoyalMail",
  royal_mail_group: "RoyalMail",

  // Canada Post
  canada_post: "CanadaPost",
  canadapost: "CanadaPost",

  // USPS
  usps: "USPS",
  usps_first_class_mail: "USPS",

  // UPS
  ups: "UPS",
  ups_mail_innovations: "UPS",
  upsn: "UPS",
  upsm: "UPS",
  upsmi: "UPS",
  upsdap: "UPS",

  // fedEx
  fedex: "FedEx",
  FedEx: "FedEx",
  fed_ex: "FedEx",
  fedex_satdel: "FedEx",
  federal_express_corporation: "FedEx",
  fedex_smartpost: "FedEx",
  fedex_express: "FedEx",
  fedex_ground: "FedEx",
  fdxgnd: "FedEx",
  fdeg: "FedEx",
  fdxexp: "FedEx",
  fedexinternationalmailservice: "FedEx",
  fedex_international_mail_service: "FedEx",
  fedexcanada: "FedEx",
  fedex_canada: "FedEx",

  // lasership
  lasership: "Lasership",
  ls: "Lasership",

  // OnTrac
  ontrack: "onTrac",
  ontrac: "onTrac",
  on_trac: "onTrac",
  otcl: "OnTrac",

  // Pilot
  pilot: "pilot",
};
export const mapTrackingUrl = (carrier, trackingNum, trackingUrl, address) => {
  if (!carrier || !trackingNum) return trackingUrl;
  const key = String(carrier).toLowerCase().split(" ").join("_");
  if (!carrierMapping[key]) return trackingUrl;
  if (!trackingUrlsMethods[carrierMapping[key]]) return "";
  return trackingUrlsMethods[carrierMapping[key]](trackingNum, address?.zipcode?.split("-")?.shift() || "zip");
};
