import React from "react";
import Modal from "../../../components/Common/Modal";
import ReactTable from "../../../components/Common/Table";
import { Button, Row } from "reactstrap";
import { last, uniq } from "lodash";
import { RenderIf } from "../../../utils/common";
const MODAL_HEADER = ["Name", "Value", "Action"];

export default function NestedVariationModal ({
  nestedVariation,
  setNestedVariation,
  variationModalOpen,
  setVariationModalOpen,
}) {
  const handleAddVariation = () => {
    const keyValuePairs = { name: "", value: "", error: [] };
    setNestedVariation([...nestedVariation, keyValuePairs]);
  };

  const handleRemoveVariation = (e, idx) => {
    nestedVariation.splice(idx, 1);
    setNestedVariation([...nestedVariation]);
  };

  return (
    <Modal size="md" isOpen={variationModalOpen}>
      <div className="modal-header">
        <h5 className="modal-title">Variations</h5>
        <button
          onClick={() => setVariationModalOpen(!variationModalOpen)}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <p>Please enter any attributes which are needed to identify this item.</p>
        <ReactTable
          tableHeader={MODAL_HEADER.map((index, key) => (
            <th key={`__modal_header_live_listings__${key}`}>{index}</th>
          ))}
          tableBody={
            <>
              {nestedVariation?.map((item, idx) => (
                <React.Fragment key={"_dynamic_fields_supplier_variation_" + idx}>
                  <tr id={"_nested_variation_supplier_" + idx} key={"_dynamic_fields_supplier_variation_" + idx}>
                    <td className="pl-0 pb-0 border-0">
                      <input
                        type="text"
                        placeholder={"Variation Name"}
                        className="form-control"
                        value={item.name}
                        onChange={(e) => {
                          !e.target.value && item.value
                            ? !item.error.some((x) => x?.includes("Name")) &&
                              item.error.push("Variation Name should not be empty.")
                            : (item.error = item.error?.filter((x) => !x?.includes("Name")));

                          e.target.value &&
                          nestedVariation.some((x) => x.name.toLowerCase() === e.target.value.toLowerCase())
                            ? item.error.push("Duplicate Variation Name.")
                            : (item.error = item.error?.filter((x) => !x?.includes("Duplicate")));

                          e.target.value && !/.*\S.*/.test(e.target.value)
                            ? item.error.push("Spaces are not allowed.")
                            : (item.error = item.error?.filter((x) => !x?.includes("Spaces")));
                          nestedVariation[idx].name = e.target.value;
                          setNestedVariation([...nestedVariation]);
                        }}
                      />
                    </td>
                    <td className="pl-0 pb-0 border-0">
                      <input
                        type="text"
                        className="form-control"
                        disabled={item?.error?.includes("Duplicate Variation Name.")}
                        placeholder={"Variation Value"}
                        value={item.value}
                        onChange={(e) => {
                          item.name && !e.target.value
                            ? !item?.error?.some((x) => x?.includes("Value")) &&
                              item.error.push("Variation Value should not be empty")
                            : (item.error = item.error?.filter((x) => !x?.includes("Value")));

                          e.target.value && !/.*\S.*/.test(e.target.value)
                            ? item.error.push("Spaces are not allowed.")
                            : (item.error = item.error?.filter((x) => !x?.includes("Spaces")));

                          !item.name && e.target.value
                            ? !item?.error?.some((x) => x?.includes("Name")) &&
                              item.error.push("Variation Name should not be empty")
                            : (item.error = item.error?.filter((x) => !x?.includes("Name")));
                          nestedVariation[idx].value = e.target.value;
                          setNestedVariation([...nestedVariation]);
                        }}
                      />
                    </td>
                    <td className="text-align-center pb-0 border-0">
                      <i
                        onClick={(e) => {
                          handleRemoveVariation(e, idx);
                        }}
                        className="bx bx-sm bx-x-circle danger cursor-pointer"
                      />
                    </td>
                  </tr>
                  <tr>
                    <RenderIf isTrue={item?.error?.length}>
                      <td className="p-0 border-0">
                        <small className="text-danger">{uniq(item.error).map((x) => `${x}${" "}`)}</small>
                      </td>
                    </RenderIf>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <td className="pl-0 pb-0 border-0">
                  <Button
                    disabled={
                      nestedVariation?.some((x) => x?.error?.length) ||
                      (nestedVariation?.length > 0 && (!last(nestedVariation)?.name || !last(nestedVariation)?.value))
                    }
                    onClick={() => handleAddVariation()}
                    color="success"
                    className="mt-2"
                  >
                    Add Variation
                  </Button>
                </td>
              </tr>
            </>
          }
        />
      </div>
      <div className="modal-footer">
        <Row className="d-flex justify-content-end mt-3">
          <Button type="reset" color="secondary" className="mr-3" onClick={() => setVariationModalOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={
              nestedVariation?.some((x) => x.error?.length) ||
              (nestedVariation?.length > 0 && (!last(nestedVariation)?.name || !last(nestedVariation)?.value))
            }
            onClick={() => {
              if (nestedVariation?.every((x) => x.error?.length === 0)) setVariationModalOpen(!variationModalOpen);
            }}
            color="primary"
            className="mr-3"
          >
            Save
          </Button>
        </Row>
      </div>
    </Modal>
  );
}
