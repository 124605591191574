import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as $ from "lodash";
import { isProfitCalculationAllowed, RenderIf, amountText, hasFullAccessOfAnyPlan } from "../../../utils/common";
import { Card, CardBody, Media, Spinner } from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import moment from "moment";
import { fetchDailyReport } from "../../../store/actions";
import FeeDetailsCard from "../components/FeeDetailsCard";
import PillBtn from "../../../components/Common/PillBtn";
import CustomTooltip from "../../../components/Common/CustomTooltip";

export default function Insights ({ storeId, utcOffset }) {
  const dispatch = useDispatch();
  const userAccess = hasFullAccessOfAnyPlan();
  const { dailyReport, dailyReportLoading, feesDetailsLoader } = useSelector((state) => state.Graph);
  const reportData = dailyReport?.data;
  const [isShowDetailsAllowed, setIsShowDetailsAllowed] = useState(
    localStorage.getItem("INSIGHT DETAIL") ? localStorage.getItem("INSIGHT DETAIL") === "Less" : true,
  );
  const buttonTitle = `Show ${isShowDetailsAllowed ? "Less" : "More"} Details`;

  const insightCardsRow = {
    no_of_orders: {
      title: "Total Orders Today",
      color: "inventory-color",
      icon: "bx-package",
      data: $.result(reportData, "orders.orders", 0),
      showAsterisk: true,
    },
    sales: {
      title: "Today's Revenue",
      color: "text-success",
      icon: "bx-dollar",
      data: $.result(reportData, "sales.total", 0),
      avg: $.result(reportData, "avgSales", 0),
    },
    profit: {
      title: "Today's Profit",
      color: "text-success",
      icon: "bx-dollar",
      data: $.result(reportData, "profits.total", 0),
      avg: $.result(reportData, "avgProfit", 0),
    },
    due_orders: {
      title: "Orders due today",
      color: "inventory-color",
      icon: "bx-calendar-exclamation",
      data: $.result(reportData, "ordersDueToday", 0),
    },
  };

  useEffect(() => {
    if (!storeId) return;

    dispatch(
      fetchDailyReport({
        marketplace_id: storeId,
        date: moment().format("YYYY-MM-DD"),
        utc_offset: utcOffset,
        newDashboard: true,
      }),
    );
  }, [storeId, utcOffset]);

  const InsightCard = ({ title, icon, data, color, showAsterisk, avg }) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
      <Card className="mini-stats-wid rounded mb-4 card-layout" style={{ height: "78%" }}>
        <CardBody>
          <Media>
            <Media body>
              <p className="text-muted font-weight-medium font-size-12">
                {title}{" "}
                <RenderIf isTrue={showAsterisk}>
                  <span id="pending-orders-tooltip" className="text-danger cursor-pointer">
                    *
                  </span>
                  <CustomTooltip
                    placement="top"
                    content={<div>{$.result(reportData, "pendingOrders", 0)}&nbsp;Pending</div>}
                    target="pending-orders-tooltip"
                  />
                </RenderIf>
              </p>
              <h4 className={`mb-0 ${color}`}>
                <div className="position-relative d-inline font-size-18 font-weight-semibold">
                  <RenderIf isTrue={title === "Today's Profit" && !isProfitCalculationAllowed()}>
                    <div
                      className="inventory-color position-absolute d-flex justify-content-center align-items-center w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.1)", backdropFilter: "blur(3px)" }}
                    >
                      <i className="bx bx-lock" />
                    </div>
                  </RenderIf>
                  {title.includes("Orders") ? data || 0 : amountText(data)}
                  <RenderIf isTrue={!$.isNil(avg)}>
                    <div className="text-muted font-size-13 mt-1">Avg.&nbsp;{amountText(avg)}</div>
                  </RenderIf>
                </div>
              </h4>
            </Media>
            <div className="d-flex flex-column">
              <div
                className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                style={{ width: "50px", height: "50px" }}
              >
                <span className="avatar-title">
                  <i className={"bx " + icon + " font-size-18"}></i>
                </span>
              </div>
            </div>
          </Media>
        </CardBody>
      </Card>
    </div>
  );

  return (
    <>
      <Breadcrumb
        isCapitalize={true}
        title="Quick Insights"
        children={
          <RenderIf isTrue={userAccess && storeId}>
            <div className="d-flex flex-row float-sm-right">
              <PillBtn
                disabled={dailyReportLoading || feesDetailsLoader}
                style={dailyReportLoading || feesDetailsLoader ? { cursor: "not-allowed" } : {}}
                className="mr-2"
                title={buttonTitle}
                name={buttonTitle}
                color={isShowDetailsAllowed ? "info" : "primary"}
                onClick={() => {
                  setIsShowDetailsAllowed(!isShowDetailsAllowed);
                  localStorage.setItem("INSIGHT DETAIL", !isShowDetailsAllowed ? "Less" : "More");
                }}
              />
            </div>
          </RenderIf>
        }
      />
      <div className={`row ${dailyReportLoading ? "justify-content-center" : ""}`}>
        <RenderIf
          isTrue={!dailyReportLoading}
          fallback={<Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />}
        >
          <div className="col">
            <div className={`row ${dailyReportLoading ? "justify-content-center" : ""}`}>
              <RenderIf
                isTrue={!dailyReportLoading}
                fallback={<Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />}
              >
                {$.entries(insightCardsRow).map(([statType, attrs]) => (
                  <InsightCard key={"___" + statType + "___"} {...attrs} />
                ))}
              </RenderIf>
            </div>
            <RenderIf isTrue={userAccess && isShowDetailsAllowed && storeId}>
              <div className="row">
                <div className="col-md-12">
                  <Card className="mini-stats-wid rounded mb-4 card-layout h-100">
                    <CardBody>
                      <FeeDetailsCard storeId={storeId} utcOffset={utcOffset} />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </RenderIf>
          </div>
        </RenderIf>
      </div>
    </>
  );
}
