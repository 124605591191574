import React, { useEffect } from "react";
import { Col, Row, Input, Label, Button, FormGroup, Alert } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";
import { keys, forEach, concat, startCase, pick, every } from "lodash";
import CheckBox from "../../../components/Common/CheckBox";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { createPlan, setAddPlanModal, setEditablePlan, setPreloader, updatePlan } from "../../../store/actions";

const createObj = (arr, defV = false) => arr.reduce((obj, key) => ({ ...obj, [key]: defV }), {});

const AddPlanModal = (props) => {
  const metadata = ["listings", "va_accounts", "sales_channels", "buying_accounts"];
  const freeCols = ["ao_free_credits"];
  const whFeeCols = ["2_step_handling_fees", "storage_per_unit_fees", "return_handling_fees", "oversized_handling_fees"];
  const { loading, error, addPlanModal, editablePlan } = useSelector((state) => state.CustomPlans);

  const dispatch = useDispatch();
  const toggleModal = () => {
    dispatch(setEditablePlan(undefined));
    dispatch(setAddPlanModal(!addPlanModal));
  };
  const { item } = props;

  const initialValues = {
    type: { label: "New", value: "new" },
    amount: 0,
    ...createObj(metadata, 0),
    ...createObj(freeCols, 100),
    ...createObj(whFeeCols, 0),
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      forEach(concat(metadata, freeCols), (key) => {
        if ((!isNaN(values[key]) && values[key] < 0) || !values[key]) errors[key] = "must be positive number";
        else if (metadata.includes(key) && every(metadata, (_key) => !values[_key])) {
          errors[key] = "(Atleast one is required)";
        }
      });
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },

    onSubmit: (values) => {
      const data = {
        name: values.name,
        amount: values.amount * 100,
        data: pick(values, ...concat(metadata, freeCols, whFeeCols), ""),
        ids: { groupId: item.id, productId: item.product_id },
        type: values.type.value,
      };
      dispatch(editablePlan ? updatePlan(editablePlan.stripe_price.id, data) : createPlan(data));
    },
  });

  useEffect(() => {
    if (!editablePlan) return;
    formik.setFieldValue("amount", editablePlan.amount, false);
    Object.keys(editablePlan?.stripe_price?.data).forEach((key) => {
      formik.setFieldValue(key, editablePlan?.stripe_price?.data?.[key] || 0, false);
    });
  }, []);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  const cols = {
    amount: "Amount",
    listings: "Listings",
    va_accounts: "VA Accounts",
    sales_channels: "Sales Channels",
    buying_accounts: "Buying Accounts",
    ao_free_credits: "AO Free Credits",
    "2_step_handling_fees": "2 Step Handling Fees",
    storage_per_unit_fees: "Storage Per Unit Fees",
    return_handling_fees: "Return Handling Fees",
    oversized_handling_fees: "Oversized Handling Fees",
  };

  function CLabel (props) {
    const { children, _key } = props;
    const error = formik.errors[_key];
    return (
      <>
        <Label className={error ? "text-danger" : ""}>{children}</Label>
        {error ? <small className="text-danger m-1">{error}</small> : null}
      </>
    );
  }

  return (
    <Modal size="md" isOpen={addPlanModal} toggle={toggleModal}>
      <div className="modal-header">
        <h4>{editablePlan ? "Edit" : "Add New"} Plan</h4>
        <button
          onClick={() => {
            formik.handleReset();
            toggleModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      {error && (
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2"></i>
          {error}
        </Alert>
      )}
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mt-2">
              <CLabel _key="type">Plan Type</CLabel>
              <FormGroup className="select2-container">
                <Select
                  name="type"
                  value={formik.values.type}
                  onChange={(option) => formik.setFieldValue("type", option)}
                  options={["co", "new"].map((x) => ({ label: startCase(x), value: x }))}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
            {keys(cols).map((col, _i) => (
              <React.Fragment key={_i}>
                <Col lg={`${metadata.includes(col) ? "9" : "12"} mt-2`}>
                  <CLabel _key={col}>{cols[col]}</CLabel>
                  <Input
                    name={col}
                    type={col === "name" ? "text" : "number"}
                    value={formik.values[col]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={formik.values[col] === "unlimited" || (editablePlan && col === "amount")}
                  />
                </Col>
                {metadata.includes(col)
                  ? (
                  <Col lg="3 mt-2">
                    <CheckBox
                      name={col}
                      state={formik.values[col] === "unlimited"}
                      setState={(state) => formik.setFieldValue(col, state ? "unlimited" : 0, true)}
                      isSwitch={true}
                      className="mt-3 pt-3"
                      label="Unlimited"
                    />
                  </Col>
                    )
                  : null}
              </React.Fragment>
            ))}
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              {editablePlan ? "Save" : "Add"} Plan
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default AddPlanModal;
