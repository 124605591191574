import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { FETCH_AO_TASKS } from "./actionTypes";
import { fetchAOTasksSuccess, apiError } from "./actions";
import { getAOTasks } from "../../api/AOTasks.js";

function * fetchAOTasksSaga ({ payload }) {
  try {
    const res = yield call(getAOTasks, payload);
    const type = payload.type === "AO_orders_stats" ? "aoTasks" : "aoGraph";
    yield put(fetchAOTasksSuccess({ data: res, type }));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchFetchAOTasks () {
  yield takeEvery(FETCH_AO_TASKS, fetchAOTasksSaga);
}

function * AOTasksSaga () {
  yield all([fork(watchFetchAOTasks)]);
}

export default AOTasksSaga;
