import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import CustomPagination from "../../components/Common/CustomPagination";
import { setPreloader, fetchAOTasks } from "../../store/actions";
import { map, values, startCase } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AOReasonsGraph from "./components/AOReasonsGraph";
import { RenderIf } from "../../utils/common";

function AOTasks () {
  const statsTypes = { 0: "AO_orders_stats", 1: "AO_orders_graph" };
  const [selectedStatType, setSelectedStatType] = useState(0);
  const [filters, setFilters] = useState({ per_page: 30, page: 1 });
  const { loading, aoTasks, aoGraph } = useSelector((state) => state.AOTasks);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(fetchAOTasks({ ...filters, type: statsTypes[selectedStatType] }));
  }, [filters]);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between m-2">
          <Breadcrumbs title="AO Tasks" />
        </div>
      </Container>
      <Row>
        <Col lg={12}>
          <Nav pills className="navtab-bg nav-justified p-1">
            {values(statsTypes).map((stats, key) => (
              <NavItem key={"_nav_" + key}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: key === selectedStatType })}
                  onClick={() => {
                    setSelectedStatType(key);
                    setFilters({
                      ...filters,
                      page: 1,
                      per_page: 20,
                    });
                  }}
                >
                  {startCase(stats)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
          <RenderIf isTrue={aoTasks?.data?.length > 0}>
            <>
              <div className="table-responsive p-3">
                <table className="table table-centered table-wrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Order Id</th>
                      <th>Marketplace</th>
                      <th>Reasons</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(aoTasks?.data || [], (row, index) => (
                      <tr key={"_task_" + index}>
                        <td>{row["Order Id"]}</td>
                        <td>{row.Marketplace}</td>
                        <td>
                          {Object.values(row.Reasons).map((value, _index) => (
                            <div className="d-flex align-items-center" key={"_ao_item_" + _index}>
                              <span>{startCase(value?.reason)}</span>
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <CustomPagination
                  total={aoTasks?.total}
                  pageOptions={[20, 50, 100]}
                  page={aoTasks?.page}
                  perPage={aoTasks?.perPage}
                  tabsFilter={filters}
                  setTabFilter={(e) => setFilters(filters.per_page !== e.per_page ? { ...e, page: 1 } : e)}
                />
              </div>
            </>
          </RenderIf>
          <RenderIf isTrue={Array.isArray(aoGraph?.data)}>
            <AOReasonsGraph aoGraph={aoGraph} />
          </RenderIf>
          <RenderIf
            isTrue={
              ([aoTasks?.data, aoGraph?.data].every((x) => !x) ||
                aoTasks?.data?.length === 0 ||
                aoGraph?.data?.length === 0) &&
              !loading
            }
          >
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Records Found</h1>
              </div>
            </div>
          </RenderIf>
      </Row>
    </div>
  );
}

export default AOTasks;
