import * as types from "./actionTypes";
const {
  API_ERROR,
  UPDATE_SOURCE_ITEM,
  UPDATE_SOURCE_ITEM_SUCCESS,
  GET_FIFTY_FIFTY_MATCHES_COUNT,
  GET_FIFTY_FIFTY_MATCHES_COUNT_SUCCESS,
} = types;

export const updateSourceItem = ({ id, data }) => ({
  type: UPDATE_SOURCE_ITEM,
  payload: { id, data },
});

export const updateSourceItemSuccess = () => ({
  type: UPDATE_SOURCE_ITEM_SUCCESS,
  payload: {},
});

export const bulkUpdateSourceItem = ({ data }) => ({
  type: types.BULK_UPDATE_SOURCE_ITEM,
  payload: { data },
});

export const bulkUpdateSourceItemSuccess = (payload) => ({ type: types.BULK_UPDATE_SOURCE_ITEM_SUCCESS, payload });

export const getFiftyFiftyCount = () => ({
  type: GET_FIFTY_FIFTY_MATCHES_COUNT,
});

export const getFiftyFiftyCountSucess = (data) => ({
  type: GET_FIFTY_FIFTY_MATCHES_COUNT_SUCCESS,
  payload: { data },
});

export const resetItemsState = () => ({ type: types.RESET_ITEMS_STATE });

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
