import { FETCH_AUTH_TOKEN_EMAIL, FETCH_AUTH_TOKEN_EMAIL_SUCCESS, API_ERROR } from "./actionTypes";

export const fetchAuthTokenEmail = (email) => {
  return {
    type: FETCH_AUTH_TOKEN_EMAIL,
    payload: { email },
  };
};

export const fetchAuthTokenEmailSuccess = (token) => {
  return {
    type: FETCH_AUTH_TOKEN_EMAIL_SUCCESS,
    payload: token,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
