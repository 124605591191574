import React from "react";
import ReactApexChart from "react-apexcharts";

const App = (props) => {
  const { series, color } = props;
  const chartOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      colors: [color],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: series[0]?.date,
      tickAmount: 7,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      markers: {
        fillColors: [color],
        radius: 2,
      },
    },
    fill: {
      colors: [color],
    },
  };
  return (
    <div>
      <ReactApexChart options={chartOptions} series={series} type="area" height={400} />
    </div>
  );
};

export default App;
