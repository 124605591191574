import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, CardHeader, CardBody, Badge, Alert } from "reactstrap";
import moment from "moment-timezone";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ShipAddress from "../../../components/Common/ShipAddress";

// // actions
import { searchAllOrders, setPreloader } from "../../../store/actions";

import { STATUS_COLOR_CODE, ORDER_STATUS_MAPPING, DATE_FORMATS } from "../../../constants";
import { toPascalCase, getWHStatus } from "../../../utils/common";

import { orderBy } from "lodash";
import CustomPagination from "../../../components/Common/CustomPagination";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import SeeMoreText from "../../../components/Common/SeeMoreText";

const { DATE_TIME } = DATE_FORMATS;

const SearchOrders = (_) => {
  const dispatch = useDispatch();
  const { allOrders, loading, success, error } = useSelector((state) => state.SourceOrders);
  const [filters, setFilters] = useState({ page: 1, per_page: 20 });
  const [search, setSearch] = useState("");
  const [seeMore, setSeeMore] = useState();
  const headers = [
    "Email",
    "Order Id",
    "Order Date",
    "Internal Status",
    "Marketplace Status",
    "Item Description",
    "Ship Name & Address",
    "Supplier Orders",
    "Warehouse Details",
  ];

  useEffect(() => {
    if (filters.search) dispatch(searchAllOrders(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <React.Fragment>
      <div className="page-content">
        {success && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </div>
        )}
        {error && (
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}
        <Container fluid>
          <Breadcrumbs title="Search Orders" />
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <form
                    className="app-search d-none d-lg-block"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (search.trim()) setFilters({ ...filters, search: search.trim() });
                    }}
                  >
                    <div className="position-relative mr-2 d-flex" style={{ width: "250px" }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        maxLength="100"
                        value={search}
                        onChange={(e) => {
                          if (!e.target.value) {
                            delete filters.search;
                            setSearch("");
                            setFilters({ ...filters });
                          } else setSearch(e.target.value);
                        }}
                      />
                      <span className="bx bx-search-alt"></span>
                      <i id="tooltip-search" style={{ fontSize: "1.5rem" }} className="bx bx-info-circle ml-2 mt-2 text-primary"></i>
                      <CustomTooltip
                        placement="top"
                        content="Search Order #, Item Name, Supplier Order #, UPC, Tracking Number, Warehouse Tracking Number, Customer Name"
                        target="tooltip-search"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </CardHeader>
            {Array.isArray(allOrders?.data) && allOrders?.data?.length
              ? (
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {headers.map((header, index) => (
                          <th key={`table-header ${index}`}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {orderBy(allOrders.data, "order_date", "desc").map((order, key) => (
                        <tr key={"_order_details_" + key}>
                          <td>{order.email}</td>
                          <td>{order.marketplace_order_id}</td>
                          <td>
                            <span className="text-body mb-1">
                              {(order.order_date && moment(order.order_date).format(DATE_TIME)) || ""}
                            </span>
                          </td>
                          <td>
                            <StatusBadge status={order.status} />
                          </td>
                          <td>
                            <StatusBadge status={order.marketplace_status} />
                          </td>
                          <td>
                            {order.source_items.map((item, key) => (
                              <div key={"__item__" + key} className="d-flex flex-column">
                                <span>
                                  {item.name.slice(0, 50)}
                                  {item.name?.length > 50 ? "..." : ""}
                                </span>
                                <span>
                                  <SeeMoreText
                                    id={key}
                                    value={item.upc}
                                    length={45}
                                    seeMore={seeMore}
                                    setSeeMore={setSeeMore}
                                    onHoverDisable={true}
                                  />
                                </span>
                              </div>
                            ))}
                          </td>
                          <ShipAddress address={order?.source_order_address} buyer_name={order?.buyer_name} />
                          <td>
                            <div className="d-flex flex-column">
                              {order?.supplier_orders?.map((spOrder, index) => (
                                <div key={`supplier_order_${index}`} className="d-flex flex-column mb-2">
                                  <span>{"Id: " + spOrder.target_order_id}</span>
                                  <span>
                                    <StatusBadge status={spOrder.status} />
                                  </span>
                                  {spOrder.tracking_num && (
                                    <span>
                                      Track:{" "}
                                      {spOrder.tracking_url
                                        ? (
                                        <a href={spOrder.tracking_url} target="_blank" rel="noopener noreferrer">
                                          {spOrder.tracking_num}
                                        </a>
                                          )
                                        : (
                                            spOrder?.tracking_num
                                          )}
                                    </span>
                                  )}
                                  {spOrder.tracking_status && (
                                    <span>
                                      Tracking Status: <StatusBadge status={spOrder.tracking_status} />
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              {order.tracking_items?.length
                                ? order?.tracking_items?.map((item, index) => (
                                    <div key={`tracking_item_${index}`} className="d-flex flex-column mb-2">
                                      {item.wh_tracking_number && (
                                        <span>
                                          Track:{" "}
                                          {item.wh_tracking_url
                                            ? (
                                            <a href={item.wh_tracking_url} target="_blank" rel="noopener noreferrer">
                                              {item.wh_tracking_number}
                                            </a>
                                              )
                                            : (
                                                item.wh_tracking_number
                                              )}
                                        </span>
                                      )}
                                      {item.wh_tracking_status && (
                                        <span>
                                          Tracking Status: <StatusBadge status={getWHStatus(item)} />
                                        </span>
                                      )}
                                    </div>
                                ))
                                : "N/A"}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {allOrders?.data && (
                  <CustomPagination
                    total={allOrders.total}
                    pageOptions={[20, 50, 100]}
                    page={filters.page}
                    perPage={filters.per_page}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                )}
              </CardBody>
                )
              : null}
            {(!allOrders?.data || allOrders?.data?.length === 0) && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const StatusBadge = ({ status }) => (
  <Badge className={"font-size-12 badge-soft-" + STATUS_COLOR_CODE[status]} color={STATUS_COLOR_CODE[status]} pill>
    {toPascalCase(ORDER_STATUS_MAPPING[status] || status)}
  </Badge>
);

export default SearchOrders;
