import React, { useState } from "react";

const HiddenField = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <input
        style={{ width: "60%" }}
        className="form-control"
        onFocus={() => setVisible(true)}
        onBlur={() => setVisible(false)}
        readOnly
        value={props.value}
        type={visible ? "text" : "password"}
      />
    </>
  );
};

export default HiddenField;
