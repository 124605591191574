export const ADD_PROXY_USER = "ADD_PROXY_USER";
export const SET_ADD_PROXY_USER_MODEL = "SET_ADD_PROXY_USER_MODEL";
export const API_ERROR = "USER_API_ERROR";
export const FETCH_PROXY_USERS = "FETCH_PROXY_USERS";
export const FETCH_PROXY_USERS_SUCCESS = "FETCH_PROXY_USERS_SUCCESS";
export const CREATE_PROXY_USER = "CREATE_PROXY_USER";
export const CREATE_PROXY_USER_SUCCESS = "CREATE_PROXY_USER_SUCCESS";
export const UPDATE_PROXY_USER = "UPDATE_PROXY_USER_PASSWORD";
export const UPDATE_PROXY_USER_SUCCESS = "UPDATE_PROXY_USER_PASSWORD_SUCCESS";
export const FETCH_ALL_PROXIES = "FETCH_ALL_PROXIES";
export const FETCH_ALL_PROXIES_SUCCESS = "FETCH_ALL_PROXIES_SUCCESS";
export const FETCH_PROXY_BY_PORT = "FETCH_PROXY_BY_PORT";
export const FETCH_PROXY_BY_PORT_SUCCESS = "FETCH_PROXY_BY_PORT_SUCCESS";
export const SWAP_PROXY_BY_PORT = "SWAP_PROXY_BY_PORT";
export const SWAP_PROXY_BY_PORT_SUCCESS = "SWAP_PROXY_BY_PORT_SUCCESS";
export const SET_UPDATE_LPM_PROXY_MODAL = "SET_UPDATE_LPM_PROXY_MODAL";
export const SET_SELECTED_PROXY = "SET_SELECTED_PROXY";
export const UPDATE_LPM_PROXY = "UPDATE_LPM_PROXY";
export const UPDATE_LPM_PROXY_SUCCESS = "UPDATE_LPM_PROXY_SUCCESS";
export const SET_UPLOAD_PROXY_MODAL = "SET_UPLOAD_PROXY_MODAL";
export const UPLOAD_PROXY_CSV = "UPLOAD_CSV_PROXY";
export const UPLOAD_PROXY_CSV_SUCCESS = " UPLOAD_CSV_PROXY_SUCCESS";
