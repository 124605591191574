import {
  FETCH_MATCHES_REQUEST,
  FETCH_MATCHES_REQUEST_SUCCESS,
  FETCH_MATCHES_REQUEST_QUERY,
  FETCH_MATCHES_REQUEST_QUERY_SUCCESS,
  API_ERROR,
  CANCEL_MATCHES_REQ,
  CANCEL_MATCHES_REQ_SUCCESS,
} from "./actionTypes";

export const fetchMatchesRequests = () => {
  return {
    type: FETCH_MATCHES_REQUEST,
    payload: {},
  };
};

export const fetchMatchesRequestsSuccess = (data) => {
  return {
    type: FETCH_MATCHES_REQUEST_SUCCESS,
    payload: data,
  };
};

export const fetchRequestsbyQueury = (params) => {
  return {
    type: FETCH_MATCHES_REQUEST_QUERY,
    payload: { params },
  };
};

export const fetchRequestsbyQueurySuccess = (matches) => {
  return {
    type: FETCH_MATCHES_REQUEST_QUERY_SUCCESS,
    payload: matches,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const cancelMatchesReq = (data) => {
  return {
    type: CANCEL_MATCHES_REQ,
    payload: data,
  };
};

export const cancelMatchesReqSuccess = (data) => {
  return {
    type: CANCEL_MATCHES_REQ_SUCCESS,
    payload: data,
  };
};
