export const FETCH_SOURCE_ORDERS = "FETCH_SOURCE_ORDERS";
export const FETCH_SOURCE_ORDERS_SUCCESS = "FETCH_SOURCE_ORDERS_SUCCESS";
export const FETCH_SOURCE_ORDER_DETAIL = "FETCH_SOURCE_ORDER_DETAIL";
export const FETCH_SOURCE_ORDER_DETAIL_SUCCESS = "FETCH_SOURCE_ORDER_DETAIL_SUCCESS";
export const API_ERROR = "FETCH_SOURCE_ORDERS_ERROR";
export const SET_PROCESSING_ITEMS = "SET_PROCESSING_ITEMS";
export const REFRESH_SOURCE_ORDER = "REFRESH_SOURCE_ORDER";
export const SET_MARK_ISSUE_MODAL = "SET_MARK_ISSUE_MODAL";
export const SET_CANCEL_MARKET_PLACE_ORDER_MODAL = "SET_CANCEL_MARKET_PLACE_ORDER_MODAL";
export const SET_ORDER_LOG_MODAL = "SET_ORDER_LOG_MODAL";
export const SET_ASSIGN_OUTBOUND_MODAL = "SET_ASSIGN_OUTBOUND_MODAL";
export const SET_ASSIGN_INVENTORY_MODAL = "SET_ASSIGN_INVENTORY_MODAL";
export const ASSIGN_INVENTORY_TO_ORDER = "ASSIGN_INVENTORY_TO_ORDER";
export const ASSIGN_INVENTORY_TO_ORDER_SUCCESS = "ASSIGN_INVENTORY_TO_ORDER_SUCCESS";
export const UPDATE_SOURCE_ORDERS = "UPDATE_SOURCE_ORDERS";
export const UPDATE_SOURCE_ORDERS_SUCCESS = "UPDATE_SOURCE_ORDERS_SUCCESS";
export const SET_SHIPPING_DETAIL_MODAL = "SET_SHIPPING_DETAIL_MODAL";
export const UPDATE_SOURCE_ADDRESS = "UPDATE_SOURCE_ADDRESS";
export const UPDATE_SOURCE_ADDRESS_SUCCESS = "UPDATE_SOURCE_ADDRESS_SUCCESS";
export const SET_ORDER_STATUS_MODAL = "SET_ORDER_STATUS_MODAL";
export const FETCH_MARKETPLACE_STATUS = "FETCH_MARKETPLACE_STATUS";
export const SET_SELECTED_ORDERS = "SET_SELECTED_ORDERS";
export const SET_CHANGED_WAREHOUSE = "SET_CHANGED_WAREHOUSE";
export const CREATE_ORDER_NOTE = "CREATE_ORDER_NOTE";
export const CREATE_ORDER_NOTE_SUCCESS = "CREATE_ORDER_NOTE_SUCCESS";
export const SET_ORDERS_BULK_UPDATE_MODAL = "SET_ORDERS_BULK_UPDATE_MODAL";
export const UPLOAD_TRACKING_DETAILS = "UPLOAD_TRACKING_DETAILS";
export const UPLOAD_TRACKING_DETAILS_SUCCESS = "UPLOAD_TRACKING_DETAILS_SUCCESS";
export const ORDERS_BULK_UPDATE = "ORDERS_BULK_UPDATE";
export const ORDERS_BULK_UPDATE_SUCCESS = "ORDERS_BULK_UPDATE_SUCCESS";
export const FETCH_MARKETPLACE_STATUS_SUCCESS = "FETCH_MARKETPLACE_STATUS_SUCCESS";
export const FETCH_NEXT_ORDER = "FETCH_NEXT_ORDER";
export const FETCH_NEXT_ORDER_SUCCESS = "FETCH_NEXT_ORDER_SUCCESS";
export const CANCEL_MARKETPLACE_ORDER = "CANCEL_MARKETPLACE_ORDER";
export const CANCEL_MARKETPLACE_ORDER_SUCCESS = "CANCEL_MARKETPLACE_ORDER_SUCCESS";
export const FETCH_LOCK_STATUS = "FETCH_LOCK_STATUS";
export const FETCH_LOCK_STATUS_SUCCESS = "FETCH_LOCK_STATUS_SUCCESS";
export const REQUEST_SOURCE_ORDER_REPORT = "REQUEST_SOURCE_ORDER_REPORT";
export const REQUEST_SOURCE_ORDER_REPORT_SUCCESS = "REQUEST_SOURCE_ORDER_REPORT_SUCCESS";
export const REQUEST_VA_PERFORMANCE_REPORT = "REQUEST_VA_PERFORMANCE_REPORT";
export const REQUEST_VA_PERFORMANCE_REPORT_SUCCESS = "REQUEST_VA_PERFORMANCE_REPORT_SUCCESS";
export const REQUEST_ORDER_PROCESSOR_REPORT = "REQUEST_ORDER_PROCESSOR_REPORT";
export const REQUEST_ORDER_PROCESSOR_REPORT_SUCCESS = "REQUEST_ORDER_PROCESSOR_REPORT_SUCCESS";
export const REQUEST_AVAILABLE_PAYMENT_REPORTS = "REQUEST_AVAILABLE_PAYMENT_REPORTS";
export const REQUEST_AVAILABLE_PAYMENT_REPORTS_SUCCESS = "REQUEST_AVAILABLE_PAYMENT_REPORTS_SUCCESS";
export const REQUEST_PAYMENT_REPORT = "REQUEST_PAYMENT_REPORT";
export const REQUEST_PAYMENT_REPORT_SUCCESS = "REQUEST_PAYMENT_REPORT_SUCCESS";
export const SET_EXPORT_ORDER_MODAL = "SET_EXPORT_ORDER_MODAL";
export const SET_PAYMENT_REPORT_MODAL = "SET_PAYMENT_REPORT_MODAL";
export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const SET_ORDER_IMPORT_MODAL = "SET_ORDER_IMPORT_MODAL";
export const SET_ORDER_UPLOAD_MODAL = "SET_ORDER_UPLOAD_MODAL";
export const SET_CHANGE_WAREHOUSE_MODAL = "SET_CHANGE_WAREHOUSE_MODAL";
export const IMPORT_ORDER_MANUALLY = "IMPORT_ORDER_MANUALLY";
export const IMPORT_ORDER_MANUALLY_SUCCESS = "IMPORT_ORDER_MANUALLY_SUCCESS";
export const GET_FEED_STATUS = "GET_FEED_STATUS";
export const GET_FEED_STATUS_SUCCESS = "GET_FEED_STATUS_SUCCESS";
export const UPLOAD_ORDER_MANUALLY = "UPLOAD_ORDER_MANUALLY";
export const UPLOAD_ORDER_MANUALLY_SUCCESS = "UPLOAD_ORDER_MANUALLY_SUCCESS";
export const UPLOAD_ORDER_MANUALLY_ERROR = "UPLOAD_ORDER_MANUALLY_ERROR";
export const FETCH_PRODUCT_CATEGORY = "FETCH_PRODUCT_CATEGORY";
export const FETCH_PRODUCT_CATEGORY_SUCCESS = "FETCH_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_SOURCE_ORDER_STATUS = "UPDATE_SOURCE_ORDER_STATUS";
export const UPDATE_SOURCE_ORDER_STATUS_SUCCESS = "UPDATE_SOURCE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_TYPE = "CHANGE_ORDER_TYPE";
export const CHANGE_ORDER_TYPE_SUCCESS = "CHANGE_ORDER_TYPE_SUCCESS";
export const SET_SELECT_WAREHOUSE_MODAL = "SET_SELECT_WAREHOUSE_MODAL";
export const SEARCH_ALL_ORDERS = "SEARCH_ALL_ORDERS";
export const SEARCH_ALL_ORDERS_SUCCESS = "SEARCH_ALL_ORDERS_SUCCESS";
export const SET_GENERATE_SHIPPING_LABEL_MODAL = "SET_GENERATE_SHIPPING_LABEL_MODAL";
export const GENERATE_SHIPPING_LABEL = "GENERATE_SHIPPING_LABEL";
export const GENERATE_SHIPPING_LABEL_SUCCESS = "GENERATE_SHIPPING_LABEL_SUCCESS";
export const GET_ITEMS_DIMENSIONS = "GET_ITEMS_DIMENSIONS";
export const GET_ITEMS_DIMENSIONS_SUCCESS = "GET_ITEMS_DIMENSIONS_SUCCESS";
export const GET_SHIPPING_RATES = "GET_SHIPPING_RATES";
export const GET_SHIPPING_RATES_SUCCESS = "GET_SHIPPING_RATES_SUCCESS";
export const MANUAL_FULFILLMENT_COLLAPSED = "MANUAL_FULFILLMENT_COLLAPS";
export const SET_EDIT_WH_COST_MODAL = "SET_EDIT_WH_COST_MODAL";
export const SET_PL_DATA = "SET_PL_DATA";
export const UPLOAD_SOURCE_ITEMS_LABEL = "UPLOAD_SOURCE_ITEMS_LABEL";
export const UPLOAD_SOURCE_ITEMS_LABEL_SUCCESS = "UPLOAD_SOURCE_ITEMS_LABEL_SUCCESS";

export const TOGGLE_PRICE_EVENTS_MODAL = "TOGGLE_PRICE_EVENTS_MODAL";
export const TOGGLE_PRICE_EVENTS_EDIT_MODAL = "TOGGLE_PRICE_EVENTS_EDIT_MODAL";

export const CREATE_PRICE_EVENT = "CREATE_PRICE_EVENT";
export const CREATE_PRICE_EVENT_SUCCESS = "CREATE_PRICE_EVENT_SUCCESS";

export const UPDATE_PRICE_EVENT = "UPDATE_PRICE_EVENT";
export const UPDATE_PRICE_EVENT_SUCCESS = "UPDATE_PRICE_EVENT_SUCCESS";

export const DELETE_PRICE_EVENT = "DELETE_PRICE_EVENT";
export const DELETE_PRICE_EVENT_SUCCESS = "DELETE_PRICE_EVENT_SUCCESS";

export const SYNC_PRICE_EVENT = "SYNC_PRICE_EVENT";

export const ACKNOWLEDGE_ORDER = "ACKNOWLEDGE_ORDER";
export const ACKNOWLEDGE_ORDER_SUCCESS = "ACKNOWLEDGE_ORDER_SUCCESS";
