import {
  API_ERROR,
  UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT,
  UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT_SUCCESS,
  SELECT_FILE_ERROR,
} from "./actionTypes";

export const updateSupplierOrderFromShipmentReport = (data) => ({
  type: UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT,
  payload: { data },
});

export const selectFileError = () => ({
  type: SELECT_FILE_ERROR,
  payload: {},
});

export const updateSupplierOrderFromShipmentReportSuccess = (res) => ({
  type: UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT_SUCCESS,
  payload: res,
});

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
