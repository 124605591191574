import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import Suspense from "../../../components/Common/Suspense";
import Modal from "../../../components/Common/Modal";
import Dropzone from "react-dropzone";
import "flatpickr/dist/themes/dark.css";

// actions
import { setUploadImagesModal, uploadIssueImages, getImagesUrl, deleteImage } from "../../../store/actions";
import { concat } from "lodash";
import { USER_ROLES } from "../../../constants";
import { getUserInfo } from "../../../utils/common";
const CustomTooltip = React.lazy(() => import("../../../components/Common/CustomTooltip"));

const UploadImagesModal = (props) => {
  const { success, error, uploadImagesModal, imagesUrls } = useSelector((state) => state.Returns);
  const [uploadError, setUploadError] = useState("");
  const toggleModal = () => dispatch(setUploadImagesModal(!uploadImagesModal));
  const [selectedFile, setselectedFile] = useState([]);
  const maxFileSize = 1024 * 1024;
  const { returnItem } = props;
  const acceptedFileTypes = "image/png,image/jpg,image/jpeg";
  const dispatch = useDispatch();
  const isWH = getUserInfo()?.role === USER_ROLES.whStaff;

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file), formattedSize: formatBytes(file.size) }),
    );
    setselectedFile(concat(selectedFile, file));
  }

  function triggerAlert (msg) {
    if (uploadError) setUploadError("");
    setUploadError(msg);
  }

  useEffect(() => {
    dispatch(getImagesUrl(returnItem?.id));
  }, []);

  const totalFiles = (imagesUrls || []).length + selectedFile.length;
  const totalAllowedFiles = 3;

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const CrossIcon = ({ onClick }) => (
    <i
      aria-hidden="true"
      className={`bx bx-sm bx-x ${!isWH ? "d-none" : ""}`}
      style={{ color: "#ff0032" }}
      onClick={onClick}
    ></i>
  );

  const uploadFile = () =>
    dispatch(uploadIssueImages({ data: selectedFile.map((file) => ({ file_name: file })), id: props.returnItem?.id }));

  return (
    <React.Fragment>
      <Modal size="md" isOpen={uploadImagesModal} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">{`View ${isWH ? "or Upload " : ""}Images`}</h5>
          {isWH && (
            <>
              <i id="allowed_files_info" className="bx bx-info-circle text-primary mt-2 ml-1"></i>
              <Suspense>
                <CustomTooltip
                  placement="right"
                  content={`You can upload upto ${totalAllowedFiles} images`}
                  target="allowed_files_info"
                />
              </Suspense>
            </>
          )}
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        {(error || uploadError) && (
          <div className="auto-hide">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || uploadError}
            </Alert>
          </div>
        )}

        {success && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </div>
        )}
        <div className="modal-body">
          {isWH && returnItem?.images_files?.length !== totalAllowedFiles && (
            <h5 className="text-center">You can upload upto 3 images</h5>
          )}
          {!isWH && !imagesUrls?.length && <h5 className="text-center">No Records Found</h5>}
          <div className="d-flex align-items-start flex-wrap grid-column-gap-10 justify-content-start upload-images">
            {imagesUrls?.length > 0 &&
              imagesUrls?.sort().map((image, _i) => {
                return image?.imgSrc
                  ? (
                  <div className="upload-img" key={"___image___" + _i}>
                    <CrossIcon onClick={() => dispatch(deleteImage(returnItem?.id, { file_name: image.name }))} />
                    <img src={image.imgSrc} alt="uploaded" />
                  </div>
                    )
                  : null;
              })}
            {selectedFile.map((f, i) => {
              return (
                <React.Fragment key={"__selected" + i}>
                  <div className="upload-img">
                    <CrossIcon onClick={() => setselectedFile(selectedFile.filter((x) => x.name !== f.name))} />
                    <img src={f.preview} alt={f.name} />
                  </div>
                </React.Fragment>
              );
            })}

            {isWH && (
              <div className="drag-image">
                {totalFiles < totalAllowedFiles && (
                  <form>
                    <Dropzone
                      onDrop={(acceptedFile, fileRejections) => {
                        if (fileRejections.length) {
                          const [file] = fileRejections;
                          if (!acceptedFileTypes.split(",").includes(file?.type))
                            triggerAlert("Invalid File type. File must be one of .jpg, .jpeg, .png");
                          if (file.size > maxFileSize)
                            triggerAlert(`Max allowed file size is ${formatBytes(maxFileSize)}`);
                        } else if (totalFiles + acceptedFile.length > totalAllowedFiles)
                          return triggerAlert("You can only upload 3 images");
                        handleAcceptedFile(acceptedFile);
                      }}
                      accept={acceptedFileTypes}
                      maxSize={maxFileSize}
                      multiple={true}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone d-flex align-items-center" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="text-center">
                            <h4>Drag or Click to Upload Image</h4>
                            <span>{`(Max ${formatBytes(maxFileSize)})`}</span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </form>
                )}
              </div>
            )}
          </div>
        </div>
        {selectedFile.length !== 0 && (
          <div className="text-center mb-2">
            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={uploadFile}>
              Upload File
            </button>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default UploadImagesModal;
