import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as types from "./actionTypes";
import * as actions from "./actions";
import * as api from "../../api/AOScreen";

function * fetchAOECSTasksSaga () {
  try {
    const res = yield call(api.getAOECSTasks);
    yield put(actions.fetchAOECSTasksSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * stopAOECSTaskSaga ({ payload }) {
  try {
    yield call(api.stopAOECSTask, payload);
    yield put(actions.stopAOECSTaskSuccess(payload));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchAccountOrdersHistorySaga ({ payload }) {
  try {
    const res = yield call(api.getAccountOrdersHistory, payload);
    yield put(actions.fetchAccountOrdersHistorySuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchFetchAOECSTasks () {
  yield takeEvery(types.FETCH_AO_ECS_TASKS, fetchAOECSTasksSaga);
}

export function * watchFetchAccountOrdersHistory () {
  yield takeEvery(types.FETCH_ACCOUNTS_ORDERS_HISTORY, fetchAccountOrdersHistorySaga);
}

export function * watchStopAOECSTask () {
  yield takeEvery(types.STOP_ECS_TASK, stopAOECSTaskSaga);
}

function * aoScreenSaga () {
  yield all([fork(watchFetchAOECSTasks), fork(watchFetchAccountOrdersHistory), fork(watchStopAOECSTask)]);
}

export default aoScreenSaga;
