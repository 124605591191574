import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo, isAdmin, RenderIf, toPascalCase } from "../../utils/common";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Card, CardBody, Alert, Row, Col, CardHeader } from "reactstrap";

import {
  fetchAllWarehouses,
  setAddWareHouseModal,
  setViewWareHouseUsersModal,
  setViewAgencyWarehouseModal,
  setPreloader,
} from "../../store/actions";

import Modal from "../../components/Common/Modal";
import PillBtn from "../../components/Common/PillBtn";

// Component
import WareHouseModal from "./Components/WareHouseModal";
import AssignUserWareHouseModal from "./Components/AssignUserWareHouseModal";
import WareHouseUsers from "./Components/WareHouseUsers";
import WareHouseCard from "./Components/WareHouseCard";
import WareHouseAgencies from "./Components/AgencyModal.js";
import { keys, map, startCase } from "lodash";

// constants
import { USER_ROLES } from "../../constants";

// Icons
import inboundIcon from "../../assets/svg/warehouse/inbound.svg";
import WHIcon from "../../assets/svg/warehouse/received.svg";
import strandedIcon from "../../assets/svg/warehouse/stranded.svg";
import addedIcon from "../../assets/svg/warehouse/account.svg";

const icons = { inboundIcon, WHIcon, strandedIcon, addedIcon };

const UsersModal = (_) => {
  const dispatch = useDispatch();
  const { viewWareHouseData, viewWareHouseUserModalIsOpen } = useSelector((state) => state.WareHouses);
  const toggleModal = () => {
    dispatch(setViewWareHouseUsersModal(!viewWareHouseUserModalIsOpen));
  };
  return (
    <React.Fragment>
      <Modal size="lg" isOpen={viewWareHouseUserModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Users ({viewWareHouseData.state})</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <WareHouseUsers users={viewWareHouseData.users} />
        </div>
      </Modal>
    </React.Fragment>
  );
};

const AgencyModal = (_) => {
  const dispatch = useDispatch();
  const { viewWareHouseData, viewWareHouseAgencyModalIsOpen } = useSelector((state) => state.WareHouses);
  const toggleModal = () => {
    dispatch(setViewAgencyWarehouseModal(!viewWareHouseAgencyModalIsOpen));
  };
  return (
    <React.Fragment>
      <Modal size="lg" isOpen={viewWareHouseAgencyModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Assigned Agencies ({viewWareHouseData.state})</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <WareHouseAgencies agencies={viewWareHouseData.AccountWarehouse} warehouseId={viewWareHouseData.id} />
        </div>
      </Modal>
    </React.Fragment>
  );
};

const WareHouse = () => {
  const dispatch = useDispatch();
  const {
    wareHouses,
    addWareHouseModalIsOpen,
    assignUserWareHouseModalIsOpen,
    loading,
    success,
    error,
    viewWareHouseUserModalIsOpen,
    viewWareHouseAgencyModalIsOpen,
  } = useSelector((state) => state.WareHouses);
  const { allTenants, account } = useSelector((state) => state.Settings);
  const { account_id: accountId } = getUserInfo();
  const [filters, setFilters] = useState({ page: 1, per_page: 50 });
  const [filteredData, setFilteredData] = useState([]);
  const [collapse, setCollapse] = useState();
  const [adminData, setAdminData] = useState([]);

  const statusMapping = { total_At_WH: "Total At Warehouse" };

  function handleAddWareHouse () {
    dispatch(setAddWareHouseModal(true));
  }

  function getFilteredResults (x, isAdmin = false) {
    const result = x.creator_id === accountId || x.AccountWarehouse.some((y) => y.id === accountId);
    return isAdmin ? !result : result;
  }

  const isAnyWhApplicable = (id) => wareHouses.data.filter((wh) => wh.id !== id).some((x) => x.is_enabled);

  const WarehouseCards = ({ data }) => (
    <Row>
      {data?.length
        ? data.map((wh, key) => (
            <WareHouseCard
              isAnyWhApplicable={isAnyWhApplicable}
              data={wh}
              key={"_wh_" + key}
              allTenants={allTenants}
              collapse={collapse}
              setCollapse={setCollapse}
            />
        ))
        : null}
    </Row>
  );

  const isNoFilter = (_) => keys(filters)?.length === 2 && ["page", "per_page"].every((x) => keys(filters).includes(x));

  useEffect(() => {
    dispatch(fetchAllWarehouses(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    if (wareHouses?.data) {
      setFilteredData(wareHouses.data.filter((x) => getFilteredResults(x)));
      setAdminData(wareHouses.data.filter((x) => getFilteredResults(x, true)));
    }
  }, [wareHouses?.data]);

  useEffect(() => {
    if (
      wareHouses?.data.length &&
      !wareHouses.data.filter((x) => getFilteredResults(x)).length &&
      !loading &&
      isNoFilter()
    )
      dispatch(setAddWareHouseModal(true));
  }, [filteredData]);

  return (
    <React.Fragment>
      {addWareHouseModalIsOpen && <WareHouseModal />}
      {assignUserWareHouseModalIsOpen && <AssignUserWareHouseModal />}
      {viewWareHouseUserModalIsOpen && <UsersModal />}
      {viewWareHouseAgencyModalIsOpen && <AgencyModal />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Warehouses"
            children={
              <div className="d-flex pr-2">
                <RenderIf isTrue={isAdmin()} fallback={<></>}>
                  <div className="d-flex pt-2">
                    {["public", "private"].map((val, key) => (
                      <div key={`__${key}_checkbox_`} className="custom-control custom-checkbox pr-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={filters[val] || false}
                          onChange={() => false}
                        />
                        <label
                          className="custom-control-label"
                          onClick={() =>
                            setFilters({ ...filters, public: false, private: false, [val]: !filters[val] })
                          }
                        >
                          {toPascalCase(val)}
                        </label>
                      </div>
                    ))}
                  </div>
                </RenderIf>

                <RenderIf
                  isTrue={account?.data?.agency_id || isAdmin()}
                >
                  <PillBtn
                    color="primary"
                    title="Add Warehouse"
                    name="Add Warehouse"
                    icon="bx bx-xs bx-plus"
                    className="col ml-2"
                    onClick={handleAddWareHouse}
                  />
                </RenderIf>
              </div>
            }
          />

          {success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {success}
              </Alert>
            </div>
          )}
          {error && (
            <div className="auto-hide">
              <Alert color="danger" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          )}
          {getUserInfo()?.role === USER_ROLES.admin && (
            <Row>
              {map(wareHouses?.overallStats, (count, status) => (
                <Col lg="3" key={`_${status}_`}>
                  <Card>
                    <CardHeader>
                      <div className="row">
                        <div className="col">
                          <span className="card-text">{statusMapping[status] || startCase(status)}</span>
                          <h3 className="pt-2">{count}</h3>
                        </div>
                        <img className="p-2" src={icons[`${status.split("_").pop()}Icon`]} alt={`${status} Icon`} />
                      </div>
                    </CardHeader>
                  </Card>
                </Col>
              ))}
            </Row>
          )}

          <WarehouseCards data={filteredData} />

          {accountId === 2 && (
            <>
              {adminData?.length > 0 && <Breadcrumbs title="Other Warehouses" />}
              <WarehouseCards data={adminData} />
            </>
          )}

          {!filteredData?.length && !adminData?.length ? (
            <Card>
              <CardBody>
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WareHouse;
