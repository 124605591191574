import React from "react";

const ShipAddress = (props) => {
  return (
    <td>
      <span className="text-body mb-1">
        {props.address?.name?.toLowerCase() === props?.buyer_name?.toLowerCase()
          ? (
          <>
            <i className="bx bx-sm bx-user mr-2"></i>
            {props.address?.name || ""}
          </>
            )
          : (
          <>
            {props?.buyer_name && (
              <>
                <i className="bx bx-sm bx-dollar mr-2"></i>
                {props?.buyer_name}
                <br />
              </>
            )}
            <i className="bx bx-sm bx-user mr-2"></i>
            {props.address?.name || ""}
          </>
            )}
      </span>
      <div className="mb-2 d-flex flex-column">
        <span>
          <i className="mdi mdi-24px mdi-home-floor-1 mr-2"></i>

          {props.address &&
            `${props.address?.address1}, ${props.address?.city || ""}, ${props.address?.state || ""} ${
              props.address?.country || ""
            }`}
        </span>
        {props.address?.address2 && (
          <span>
            <i className="mdi mdi-24px mdi-home-floor-2 mr-2"></i>
            {props.address?.address2}
          </span>
        )}
      </div>
    </td>
  );
};

export default ShipAddress;
