import {
  FETCH_CREDIT_LOG,
  FETCH_CREDIT_LOG_SUCCESS,
  CONSUME_CREDIT,
  CONSUME_CREDIT_SUCCESS,
  DELETE_CREDIT_LOG,
  DELETE_CREDIT_LOG_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const fetchCreditLogs = (params) => {
  return {
    type: FETCH_CREDIT_LOG,
    payload: { params },
  };
};

export const fetchCreditLogSuccess = (res) => {
  return {
    type: FETCH_CREDIT_LOG_SUCCESS,
    payload: res,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const consumeCredit = (data) => {
  return {
    type: CONSUME_CREDIT,
    payload: { data },
  };
};

export const consumeCreditSuccess = (data) => {
  return {
    type: CONSUME_CREDIT_SUCCESS,
    payload: data,
  };
};

export const deleteCreditLog = (id) => {
  return {
    type: DELETE_CREDIT_LOG,
    payload: { id },
  };
};

export const deleteCreditLogSuccess = (data) => {
  return {
    type: DELETE_CREDIT_LOG_SUCCESS,
    payload: data,
  };
};
