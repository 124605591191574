/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Card, CardBody, Container, FormGroup, Alert } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/dark.css";
import { setPreloader } from "../../store/actions";
import CustomPagination from "../../components/Common/CustomPagination";
import { concat, filter, keys, map, size, startCase } from "lodash";
import moment from "moment";
import { DATE_FORMATS } from "../../constants";
import { getUserInfo } from "../../utils/common";
import SelectTenantsModel from "./components/SelectTenantsModel";
const { FULL_DATE } = DATE_FORMATS;
const { REACT_APP_BASE_URL } = process.env;

const Jobs = (props) => {
  const token = getUserInfo();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({ page: 1, per_page: 20 });
  const [jobsSummary, setJobsSummary] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const tableHeaders = [
    { title: "Author" },
    { title: "Job Name" },
    { title: "Date Created" },
    { title: "Processing Status" },
  ];
  const fetchAllJobs = () => {
    dispatch(setPreloader(true));
    fetch(`${REACT_APP_BASE_URL}/jobs`, { method: "GET" })
      .then((res) => res.json())
      .then((data) => {
        setJobsSummary(data.data || []);
        dispatch(setPreloader(false));
      })
      .catch((error) => {
        setError(error);
        dispatch(setPreloader(false));
      });
  };
  const loadDump = (data) => {
    setOpen(false);
    dispatch(setPreloader(true));
    const body = JSON.stringify({ queue: "stagingDbDumper", tenantIds: [token?.account_id], author: token?.username, selectedTenants: data });
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      body,
    };
    fetch(`${REACT_APP_BASE_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch(setPreloader(false));
      })
      .catch((error) => {
        setError(error);
        dispatch(setPreloader(false));
      });
  };
  useEffect(() => {
    fetchAllJobs();
  }, []);

  const isActive = filter(jobsSummary, (x) => x.status === "active");

  return (
    <div className="page-content">
      {error && (
        <div className="auto-hide">
          <Alert color="danger" className="my-1">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        </div>
      )}
      <Container fluid>
        <Breadcrumbs
          title="Jobs Summary"
          children={
            <div className="d-flex">
              <button
                disabled={size(isActive)}
                className="btn btn-success h1 cursor-pointer"
                style={size(isActive) ? { cursor: "not-allowed" } : {}}
                onClick={() => setOpen(!open)}
                title="Refresh data"
              >
                Load Dump
              </button>
              <i
                className="mdi mdi-refresh ml-2 h1 cursor-pointer"
                onClick={() => fetchAllJobs()}
                title="Refresh data"
              ></i>
            </div>
          }
        />
        <Card>
          {size(jobsSummary) ? (
            <CardBody>
              <>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {tableHeaders.map((header, index) => (
                          <th key={`table-header ${index}`}>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {map(jobsSummary, (job, key) => (
                        <tr key={"__job" + key}>
                          <td>{startCase(job.data?.author)}</td>
                          <td>{startCase(job.queue?.name)}</td>
                          <td>{job?.timestamp ? moment(job.timestamp).format(FULL_DATE) : "N/A"}</td>
                          <td>{startCase(job?.status)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <CustomPagination
                  total={size(jobsSummary)}
                  pageOptions={[20, 50, 100]}
                  page={filters.page}
                  perPage={filters.per_page}
                  tabsFilter={filters}
                  setTabFilter={setFilters}
                  hidePerPage={true}
                  hidePagination={true}
                />
              </>
            </CardBody>
          ) : (
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Records Found</h1>
              </div>
            </div>
          )}
        </Card>
      </Container>
      {open && <SelectTenantsModel open={open} setOpen={setOpen} loadDump={loadDump} />}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { vaReport, loading } = state.SourceOrders;
  return { vaReport, loading };
};

export default connect(mapStatetoProps, {
  setPreloader,
})(Jobs);
