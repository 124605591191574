import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  SET_REG_ERROR,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_WAREHOUSE_USER,
  VERIFY_WAREHOUSE_USER_SUCCESS,
  SEND_VERIFY_TOKEN,
  SEND_VERIFY_TOKEN_SUCCESS,
  SET_VERIFY_ERROR,
  GET_VERIFICATION_LINK,
  GET_VERIFICATION_LINK_SUCCESS,
  GET_VERIFICATION_LINK_FAILED,
  RESET_AUTH_REG_REDUCER,
  API_ERROR,
} from "./actionTypes";

export const registerUser = (data) => {
  return {
    type: REGISTER_USER,
    payload: { data },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: user,
  };
};

export const registerUserFailed = (user) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  };
};

export const verifyUser = (token, history) => {
  return {
    type: VERIFY_USER,
    payload: { token, history },
  };
};

export const verifyUserSuccess = (user) => {
  return {
    type: VERIFY_USER_SUCCESS,
    payload: user,
  };
};

export const verifyWareHouseUser = (data, history) => {
  return {
    type: VERIFY_WAREHOUSE_USER,
    payload: { data, history },
  };
};

export const verifyWareHouseUserSuccess = () => {
  return {
    type: VERIFY_WAREHOUSE_USER_SUCCESS,
    payload: {},
  };
};

export const setRegError = (err) => {
  return {
    type: SET_REG_ERROR,
    payload: err,
  };
};

export const setVerifyError = (err) => {
  return {
    type: SET_VERIFY_ERROR,
    payload: err,
  };
};

export const sendVerifyToken = (email) => {
  return {
    type: SEND_VERIFY_TOKEN,
    payload: { email },
  };
};

export const sendVerifyTokenSuccess = (token) => {
  return {
    type: SEND_VERIFY_TOKEN_SUCCESS,
    payload: token,
  };
};

export const getVerificationLink = () => {
  return {
    type: GET_VERIFICATION_LINK,
    payload: {},
  };
};

export const getVerificationLinkSuccess = () => {
  return {
    type: GET_VERIFICATION_LINK_SUCCESS,
    payload: {},
  };
};

export const getVerificationLinkFail = (msg) => {
  return {
    type: GET_VERIFICATION_LINK_FAILED,
    payload: msg,
  };
};

export const resetRegReducer = () => {
  return {
    type: RESET_AUTH_REG_REDUCER,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
