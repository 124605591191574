import React from "react";
import { Card, CardBody, Row, Col, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { merge } from "lodash";

// Components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import PillBtn from "../../../components/Common/PillBtn";

// Actions
import { updatePaymentSourceSettings, apiError } from "../../../store/Agency/actions";

// Constants
import { PAYMENT_SOURCE } from "../../../constants";
import { toPascalCase } from "../../../utils/common";

const PaymentSourceSettings = (_) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.Settings);
  const { success } = useSelector((state) => state.Agency);
  const settingCols = ["subscription_source", "credits_source", "wh_charges_source"];
  const isAgencyPaymentAdded = account?.data?.payment_source_added;

  return (
    <React.Fragment>
      {success && (
        <div className="auto-hide">
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {success}
          </Alert>
        </div>
      )}

      <Breadcrumbs
        title="Payment Source Settings"
        children={
          <PillBtn
            color="primary"
            title="Apply All"
            name="Apply All"
            icon="bx bx-xs bx-user-check"
            onClick={() =>
              dispatch(
                updatePaymentSourceSettings({
                  data: merge(
                    { isAll: true },
                    settingCols.reduce((acc, col) => merge(acc, { [col]: account?.data?.[col] || "" }), {}),
                  ),
                }),
              )
            }
          />
        }
      />
      <Card>
        <CardBody>
          <Row>
            {settingCols.map((col, key) => (
              <Col key={`__payment_settings__${key}`}>
                <SimpleSelect
                  value={{
                    label: PAYMENT_SOURCE[account?.data?.[col]] || "",
                    value: account?.data?.[col] || "",
                  }}
                  onChange={(source) => {
                    if (isAgencyPaymentAdded) {
                      account.data[col] !== source.value &&
                        dispatch(updatePaymentSourceSettings({ data: { [col]: source.value } }));
                    } else dispatch(apiError("Please Add your Payment Source"));
                  }}
                  placeholder={toPascalCase(col)}
                  name={col}
                  options={Object.keys(PAYMENT_SOURCE).map((key, i) => ({
                    label: PAYMENT_SOURCE[key],
                    value: key,
                    key: i,
                  }))}
                  label={toPascalCase(col)}
                />
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PaymentSourceSettings;
