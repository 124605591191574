import { Alert, Button, Col, Container, Input, Label, Nav, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { callSPAPI, checkWalmartOffers, resetCheckOffersState } from "../../store/actions";
import { RenderIf } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as $ from "lodash";
import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";

const marketplaces = ["amazon", "walmart"];

const CodeBlock = ({ data }) => {
  const prettyJson = JSON.stringify(data, null, 4);

  return (
    <div
      className="w-100 code-block"
      style={{
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <pre style={{ margin: 0 }}>{prettyJson}</pre>
    </div>
  );
};

export default function CheckOffers () {
  const { apiResponse, loading, error } = useSelector((state) => state.CheckOffers);
  const dispatch = useDispatch();

  const [selectedMarketplace, setSelectedMarketplace] = useState(0);

  const [fieldTitle, fieldValue] = useMemo(() => {
    const fieldTitle = selectedMarketplace ? "Item ID" : "ASIN";
    return [fieldTitle, $.camelCase(fieldTitle)];
  }, [selectedMarketplace]);

  const formik = useFormik({
    initialValues: {
      asin: "",
      itemId: "",
    },
    onSubmit: (values) => {
      if (selectedMarketplace) {
        const body = { itemIds: [values.itemId] };
        dispatch(checkWalmartOffers(body));
      } else {
        const body = {
          endpoint: "productPricing",
          operation: "getItemOffers",
          path: { Asin: values.asin },
          query: { MarketplaceId: "ATVPDKIKX0DER", ItemCondition: "New" },
        };
        dispatch(callSPAPI(body));
      }
    },
  });

  useEffect(() => {
    return () => {
      // clear the redux state when the component dismounts
      dispatch(resetCheckOffersState());
    };
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <RenderIf isTrue={error}>
          <Alert color="danger" className="d-flex align-items-center gap-2">
            <i className="bx bx-info-circle" />
            <span>{error}</span>
          </Alert>
        </RenderIf>

        <Row className="my-2">
          <Col xs={12}>
            <h4 className="font-size-18">CHECK OFFERS</h4>
          </Col>
        </Row>

        <Row className="my-2">
          <Col xs={12}>
            <Nav pills className="navtab-bg nav-justified">
              {marketplaces.map((marketplace, index) => (
                <NavItem key={`${marketplace}-${index}`}>
                  <NavLink
                    className={classnames({ cursor: "pointer", active: index === selectedMarketplace })}
                    onClick={() => {
                      formik.resetForm();
                      dispatch(resetCheckOffersState());
                      setSelectedMarketplace(index);
                    }}
                  >
                    {$.startCase(marketplace)}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <form className="d-flex flex-column align-items-start" onSubmit={formik.handleSubmit}>
              <Label>{fieldTitle}</Label>
              <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-between gap-2">
                <Input
                  id={fieldValue}
                  placeholder={`Search by ${fieldTitle}`}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values[fieldValue]}
                  style={{ flexBasis: "80%" }}
                  required
                />
                <Button type="submit" color="success" style={{ width: "100%", flexBasis: "20%" }}>
                  Check Offers
                </Button>
              </div>
            </form>
          </Col>
        </Row>

        <Row className="my-4">
          <Col xs={12}>
            {loading ? (
              <div className="w-100 d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            ) : (
              <RenderIf isTrue={apiResponse}>
                <div className="w-100 d-flex flex-column align-items-start justify-content-between gap-2">
                  <h4 className="font-size-18">Offer: </h4>
                  <CodeBlock data={apiResponse} />
                </div>
              </RenderIf>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
