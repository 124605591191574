import React from "react";
import { CardBody } from "reactstrap";
import PillBtn from "../../../components/Common/PillBtn";
import { Link } from "react-router-dom";
export default function BusinessCard ({ iconSrc, title, description, btnTitle, btnColor, link }) {
  return (
    <>
      <div className="col-md-4 col-sm-6 mt-3">
        <CardBody className="p-4 rounded card-layout mb-3 h-100 position-relative">
          <div className="d-flex justify-content-left">
            <div className="mx-1">
              <img src={iconSrc} alt="Card Icon" />
            </div>
            <div className="card-text">{title}</div>
          </div>
          <hr />
          <div>
            <p style={{ minHeight: "70px", marginBottom: "2rem" }}>{description}</p>
            <div
              className="d-flex justify-content-center position-absolute"
              style={{ bottom: "18px", left: 0, right: 0 }}
            >
              <Link to={link}>
                <PillBtn
                  className="p-1 px-3"
                  color={btnColor}
                  title={btnTitle}
                  name={btnTitle}
                  icon="bx bx-xs bx-rocket"
                />
              </Link>
            </div>
          </div>
        </CardBody>
      </div>
    </>
  );
}
