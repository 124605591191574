import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import * as $ from "lodash";
// Login Redux States
import * as api from "../../api";
import * as types from "./actionTypes";
import * as actions from "./actions";
import * as globalActions from "../actions";

function * updateSourceItemSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(api.updateSourceItem, { data, id });
    yield put(actions.updateSourceItemSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * bulkUpdateSourceItemSaga ({ payload: { data } }) {
  try {
    const res = yield call(api.bulkUpdateItems, { data });
    if (res.data) {
      const { SourceOrders: { sourceOrderDetail } } = yield select();
      $.merge(sourceOrderDetail.data, {
        source_items: res.data,
        order_logs: res.orderLogs,
        ...$.get(res, "order", {}),
      });
    }
    yield put(globalActions.setUpdateCOGSModal(false));
    yield put(actions.bulkUpdateSourceItemSuccess(res.message));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

function * getFiftyFiftyCountSaga () {
  try {
    const res = yield call(api.getFiftyFiftyCount);
    yield put(actions.getFiftyFiftyCountSucess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchsourceItemFetch () {
  yield takeEvery(types.UPDATE_SOURCE_ITEM, updateSourceItemSaga);
}

export function * watchBulkUpdateItem () {
  yield takeEvery(types.BULK_UPDATE_SOURCE_ITEM, bulkUpdateSourceItemSaga);
}

export function * watchGetFiftyFiftyCount () {
  yield takeEvery(types.GET_FIFTY_FIFTY_MATCHES_COUNT, getFiftyFiftyCountSaga);
}

function * sourceItemsSaga () {
  yield all([fork(watchsourceItemFetch)]);
  yield all([fork(watchGetFiftyFiftyCount)]);
  yield all([fork(watchBulkUpdateItem)]);
}

export default sourceItemsSaga;
