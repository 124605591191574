import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import $ from "lodash";

import Breadcrumb from "../../../components/Common/Breadcrumb";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { amountText, RenderIf } from "../../../utils/common";
import { fetchPaginatedRecords, getDateParamObj } from "../common";
import ReactTable from "../../../components/Common/Table";
import * as actions from "../../../store/actions";
import CustomPagination from "../../../components/Common/CustomPagination";
import moment from "moment";
import DateRange from "../../../components/Common/DateRange";

const SalesCard = ({ storeId, title, utcOffset, isStoreAdded }) => {
  const dispatch = useDispatch();
  const { productsByBrand, productsByCategory, brandProductLoading, categoryProductLoading } = useSelector(
    (state) => state.Graph,
  );
  const isSalesByBrand = title === "ByBrand";
  const salesType = isSalesByBrand ? "brand" : "category";
  const getTypes = (type) => ({ label: type || `Select ${salesType}`, value: type });
  const [dates, setDates] = useState([moment().subtract(6, "days").startOf("days"), moment().startOf("days")]);
  const initialFilterType = getTypes(null);
  const [filterType, setFilterType] = useState(initialFilterType);
  const [filter, setFilter] = useState({ page: 1, per_page: 5 });
  const salesData = isSalesByBrand ? productsByBrand : productsByCategory;
  const getMappingLabels = (sales, salesType) =>
    sales?.reduce(
      (acc, item) => {
        acc[item[salesType]] = item[salesType];
        return acc;
      },
      { [`Select ${$.startCase(salesType)}`]: "" },
    );
  const isSalesDataNotEmpty = !$.isEmpty(salesData?.data);
  const salesMapping = isSalesDataNotEmpty && getMappingLabels(salesData?.data, salesType);
  const loader = isSalesByBrand ? brandProductLoading : categoryProductLoading;
  const dateParams = getDateParamObj({ marketplace_id: storeId, dates, utcOffset });
  const renderContent = ({ data, contentStyle = "" }) => (
    <div className={`d-flex align-items-center ${contentStyle}`}>
      <span className="mx-1 font-size-12">{data}</span>
    </div>
  );
  const tableHeaders = [
    {
      title: `${$.startCase(salesType)} Name`,
      Content: ({ data }) => renderContent({ data: isSalesByBrand ? data?.brand : data?.category }),
    },
    {
      title: "Number of Orders",
      Content: ({ data }) => renderContent({ data: data?.orders, contentStyle: "justify-content-center" }),
    },
    {
      title: "Units",
      Content: ({ data }) => renderContent({ data: data?.units_sold, contentStyle: "justify-content-center" }),
    },
    {
      title: "Sales",
      Content: ({ data }) => renderContent({ data: amountText(data?.sales), contentStyle: "justify-content-center" }),
    },
    {
      title: "Refunds",
      Content: ({ data }) => renderContent({ data: amountText(data?.refunds), contentStyle: "justify-content-center" }),
    },
    {
      title: "Profit",
      Content: ({ data }) =>
        renderContent({ data: amountText(data?.profit), contentStyle: "justify-content-center text-success" }),
    },
  ];
  const cols = $.keyBy(tableHeaders, "title");
  const filteredData = $.filter(salesData?.data, (item) => item[salesType] === filterType?.value);
  const products = fetchPaginatedRecords($.isEmpty(filteredData) ? salesData?.data : filteredData, filter);
  useEffect(() => {
    if (loader || !storeId) return;
    isSalesByBrand
      ? dispatch(actions.fetchProductsbySales(dateParams))
      : dispatch(actions.fetchProductsbyCategory(dateParams));
    setFilter({ ...filter, page: 1 });
    setFilterType(initialFilterType);
  }, [dates, storeId, utcOffset]);
  return (
    <>
      <Breadcrumb
        isCapitalize={true}
        title={isSalesByBrand ? "Sales by Brand" : "Sales by Category & Product"}
        responsive
        children={
          <RenderIf isTrue={storeId}>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
              <RenderIf isTrue={isSalesDataNotEmpty}>
                <SimpleSelect
                  disabled={loader}
                  className={`${loader ? "cursor-not-allowed" : "cursor-pointer"}`}
                  name="type_filter"
                  placeholder={`Select ${$.startCase(salesType)}`}
                  value={filterType}
                  onChange={setFilterType}
                  options={$.values(salesMapping).map(getTypes)}
                  formStyle={{ width: "200px" }}
                  classNamePrefix="select2-selection"
                />
              </RenderIf>
              <DateRange dates={dates} setDates={setDates} loader={loader} timePeriods={[7, 30, 365]} />
            </div>
          </RenderIf>
        }
      />
      <ReactTable
        className={`w-100 overflow-auto ${isStoreAdded ? "" : "blur-effect"}`}
        height={{ maxHeight: "260px" }}
        tableHeader={
          <>
            {$.values(cols).map((header, index) => (
              <th key={`table-header-${index}`} className={!index ? null : "text-center"}>
                {header.title}
              </th>
            ))}
          </>
        }
        style={!$.isEmpty(products) && !loader ? { minHeight: "160px" } : {}}
        tableBody={
          <RenderIf
            isTrue={!$.isEmpty(products) && !loader}
            fallback={
              <RenderIf
                isTrue={loader}
                fallback={
                  storeId ? (
                    <tr>
                      <td colSpan="100%">
                        <h2 className="text-center">No Records Found</h2>
                      </td>
                    </tr>
                  ) : null
                }
              >
                <tr>
                  <td colSpan="100%" className="text-center">
                    <Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />
                  </td>
                </tr>
              </RenderIf>
            }
          >
            {$.map(products, (data, key) => (
              <tr key={`table-row-${key}`}>
                {$.values(cols).map(({ Content }, colKey) => (
                  <td key={`table-col-${colKey}`}>
                    <Content data={data} index={key} />
                  </td>
                ))}
              </tr>
            ))}
          </RenderIf>
        }
      />
      <RenderIf isTrue={!$.isEmpty(products)}>
        <div className={`${loader ? "d-none" : ""}`}>
          <CustomPagination
            pageOptions={[5, 10, 50, 100]}
            total={filterType?.value ? $.size(products) : salesData?.count}
            page={filter?.page}
            perPage={filter?.per_page}
            tabsFilter={filter}
            setTabFilter={setFilter}
            inAgencyComponent={true}
          />
        </div>
      </RenderIf>
    </>
  );
};

export default SalesCard;
