import request from "../restClient";
const { REACT_APP_IP_TOKEN } = process.env;

export const login = async ({ username, password }) => {
  const url = "/authenticate";
  let ipInfo;
  try {
    const apiResponse = await request({
      url: `https://ipinfo.io?token=${REACT_APP_IP_TOKEN}`,
      method: "GET",
      baseUrl: false,
    });
    if (apiResponse?.ip) ipInfo = apiResponse;
  } catch (err) {}
  return request({ url, method: "POST", body: { username, password, ipInfo } });
};

export const loginWareHouse = async ({ email, password }) => {
  const url = "/warehouse_users/login";
  let ipInfo;
  try {
    const apiResponse = await request({
      url: `https://ipinfo.io?token=${REACT_APP_IP_TOKEN}`,
      method: "GET",
      baseUrl: false,
    });
    if (apiResponse?.ip) ipInfo = apiResponse;
  } catch (err) {}
  return request({ url, method: "POST", body: { email, password, ip_info: ipInfo } });
};

export const loginOAuth = async ({ user, queryParams }) => {
  const credentials = {
    username: user?.username,
    grant_type: queryParams?.type || "password",
    client_id: `${queryParams.client_id}`,
    client_secret: `${queryParams.secret_key}`,
  };
  if (user?.password) credentials.password = user?.password;

  const body = Object.entries(credentials)
    .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value))
    .join("&");
  return await request({
    url: "/oauth_apps/token",
    method: "POST",
    body,
    oauth: "true",
    contentType: "application/x-www-form-urlencoded",
  });
};

export const getClientInfo = async (clientId) => {
  return await request({ url: `/oauth_apps/get_client_info?client_id=${clientId}`, oauth: "true", method: "GET" });
};

export const sendDataToRepricer = async (data) => {
  return await request({ url: "/oauth_apps/send_data_to_repricer", method: "POST", oauth: "true", body: data });
};

export const getUserInfoApi = async ({ username }) => {
  return await request({ url: `/oauth_apps/get_user_info?email=${username}`, oauth: "true", method: "GET" });
};

export const checkTokenExpiry = async (token) => {
  return await request({ url: `/oauth_apps/check_token_expiry/?token=${token}`, oauth: "true", method: "GET" });
};

export const generateOtp = (_) => request({ url: "/users/generate_otp", method: "GET" });

export const verifyOtp = (data) => request({ url: "/users/validate_otp", method: "POST", body: data });

export const logout = async (userId) => {
  const url = `/logout/${userId}`;
  return request({ url, method: "POST" });
};
