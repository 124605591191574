import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTriggersModal, updateAccountSettings } from "../../../store/actions";
import { Button } from "reactstrap";
import CheckBox from "../../../components/Common/CheckBox";
import Modal from "../../../components/Common/Modal";
import { startCase, map, values, isEqual, keys } from "lodash";
import { BCE_TRIGGERS } from "../../../constants";

const BotTriggersModal = (_) => {
  const dispatch = useDispatch();
  const { account, isTriggerModal } = useSelector((state) => state.Settings);
  const [triggerStatues, setTriggerStatuses] = useState(
    values(BCE_TRIGGERS).reduce((obj, trigger) => {
      obj[trigger] = account.data.bce_triggers.includes(trigger);
      return obj;
    }, {}),
  );

  const toggleModal = () => {
    dispatch(setTriggersModal(!isTriggerModal));
  };

  const activeKeys = () => keys(triggerStatues).filter((x) => triggerStatues[x]);
  const updateTriggers = () => dispatch(updateAccountSettings({ bce_triggers: activeKeys() }, account.id));

  return (
    <Modal size="md" isOpen={isTriggerModal} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Update Tracking Bot Triggers
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {map(triggerStatues, (checked, trigger) => (
          <CheckBox
            key={trigger + "_key"}
            name={trigger}
            label={startCase(trigger)}
            state={checked}
            isSwitch={true}
            setState={(e) => setTriggerStatuses({ ...triggerStatues, [trigger]: e })}
          />
        ))}
      </div>
      <div className="modal-footer col">
        <Button
          type="submit"
          color="success"
          className="mr-3"
          onClick={() => updateTriggers()}
          disabled={isEqual(activeKeys(), account.data.bce_triggers)}
        >
          Update
        </Button>
      </div>
    </Modal>
  );
};

export default BotTriggersModal;
