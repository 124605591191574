import React, { useEffect } from "react";
import SigninSignUpForm from "./components/SignInSignupForm";
import { apiError } from "../../store/actions";
import { useDispatch } from "react-redux";

const NewSignInWH = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  return <SigninSignUpForm type="sign_in" isWHLogin={true} />;
};

export default NewSignInWH;
