import React from "react";
import PropTypes from "prop-types";

const Alert1 = (props) => {
  const { color, className, style, children } = props;
  return (
    <span className={className}>
      <p className={`alert-main-div ${color}`} style={style || {}}>
        {children}
      </p>
    </span>
  );
};

Alert1.propTypes = {
  color: PropTypes.oneOf(["success", "info", "warning", "error", "primary"]),
};

export default Alert1;
