import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { FETCH_ALL_AO_STATS } from "./actionTypes";
import { fetchAllAOStatsSuccess, apiError } from "./actions";
import { getAllAOStats } from "../../api/AOStats.js";

function * fetchAllAOStatsSaga ({ payload }) {
  try {
    const res = yield call(getAllAOStats, payload);
    yield put(fetchAllAOStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchAllAOStats () {
  yield takeEvery(FETCH_ALL_AO_STATS, fetchAllAOStatsSaga);
}

function * AOStatsSaga () {
  yield all([fork(watchAllAOStats)]);
}

export default AOStatsSaga;
