import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { Card, CardHeader, CardBody, Container, Row, Col, Button } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PillBtn from "../../../components/Common/PillBtn";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import { setPreloader, requestOrderProcessorReport } from "../../../store/actions";
import moment from "moment-timezone";
import ReportDownloadMsg from "../../../components/Common/ReportDownloadMsg";
import { adjustDate, RenderIf } from "../../../utils/common";
import { DATE_FORMATS } from "../../../constants";

const { DATE_PICKR } = DATE_FORMATS;

const OrderProcessorReport = (props) => {
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const { success } = useSelector((state) => state.SourceOrders);
  const [dates, setDates] = useState([moment().subtract(7, "days"), moment()]);

  const tableHeaders = [
    { title: "Placed By" },
    { title: "Amazon", class: "text-center" },
    { title: "Ebay", class: "text-center" },
    { title: "New", class: "text-center" },
    { title: "Errored", class: "text-center" },
    { title: "Replacements", class: "text-center" },
    { title: "Home Depot", class: "text-center" },
    { title: "Other Platforms", class: "text-center" },
    { title: "Total", class: "text-center" },
  ];

  const fetchReport = (startDate, endDate, isDownload = "") =>
    props.requestOrderProcessorReport({
      start_date: startDate?.format("YYYY-MM-DD"),
      end_date: endDate?.format("YYYY-MM-DD"),
      utc_offset: moment().format("Z"),
      is_export: isDownload,
    });

  useEffect(() => {
    props.setPreloader(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (accounts?.data.length) fetchReport(dates[0], dates[1]);
  }, [accounts?.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <RenderIf isTrue={success}>
            <ReportDownloadMsg />
          </RenderIf>
          <Breadcrumbs
            title="Order Processor Report"
            children={
              props.vaReport?.length
                ? (
                <PillBtn
                  color="primary"
                  title="Export to CSV"
                  name="Export to CSV"
                  icon="bx bx-xs bx-cloud-download ml-1"
                  onClick={() => fetchReport(dates[0], dates[1], true)}
                />
                  )
                : null
            }
          />

          <Card>
            <CardHeader>
              <Row>
                <Col lg={3} className="mt-3">
                  <Flatpickr
                    className="form-control rounded-pill"
                    placeholder="Select Date Range"
                    value={dates.map((x) => x.format(DATE_FORMATS.DATE))}
                    options={{
                      mode: "range",
                      dateFormat: DATE_PICKR,
                      defaultDate: dates,
                    }}
                    onClose={(val) => {
                      const newDates = val.map(adjustDate);
                      if (val.length > 0)
                        !val[1] ? setDates([moment(newDates[0]), moment()]) : setDates(newDates.map((x) => moment(x)));
                    }}
                  />
                </Col>
                <Col lg={2} className="mt-3">
                  <Button color="success" onClick={() => fetchReport(dates[0], dates[1])}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            {props.vaReport?.length ? (
              <CardBody>
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {/* table headers */}
                          {tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`} className={header.class || ""}>
                              {header.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {/* iterating data */}
                        {props.vaReport.map((item, key) => (
                          <tr key={"_view_report_" + key}>
                            <td>{item.placed_by}</td>
                            <td className="text-center">{item.amazon}</td>
                            <td className="text-center">{item.ebay}</td>
                            <td className="text-center">{item.new}</td>
                            <td className="text-center">{item.errored}</td>
                            <td className="text-center">{item.processed}</td>
                            <td className="text-center">{item.home_depot}</td>
                            <td className="text-center">{item.others}</td>
                            <td className="text-center">{item.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              </CardBody>
            ) : null}
            {!props.vaReport?.length && !props.loading && (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
            )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { vaReport, loading } = state.SourceOrders;
  return { vaReport, loading };
};

export default connect(mapStatetoProps, {
  setPreloader,
  requestOrderProcessorReport,
})(OrderProcessorReport);
