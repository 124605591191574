import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Label, FormGroup } from "reactstrap";
import Select from "react-select";
import Modal from "../../../components/Common/Modal";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// utils
import { toPascalCase } from "../../../utils/common";

// actions
import { fetchAccounts, setInviteUserModal, sendInviteForUser, setPreloader } from "../../../store/actions";

const InviteUserModal = (props) => {
  const [formData, setFormData] = useState({});
  const [customErrors, setCustomErrors] = useState({});
  const dispatch = useDispatch();
  const { roles, error, accounts, loading, inviteUserModalIsOpen } = useSelector((state) => state.User);

  useEffect(() => {
    if (!accounts.length || !Object.keys(roles).length) {
      dispatch(fetchAccounts());
    }
    if (accounts?.length === 1) {
      setFormData({
        ...formData,
        account_id: { label: accounts[0].email, value: accounts[0].id },
      });
    }
  }, [accounts]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  const toggleModal = () => {
    dispatch(setInviteUserModal(!inviteUserModalIsOpen));
  };

  return (
    <Modal size="md" isOpen={inviteUserModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Invite User
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}
        <div className="p-2">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              if (formData.role && formData.account_id) {
                v.role = formData.role.value;
                v.account_id = formData.account_id.value;
                dispatch(sendInviteForUser(v));
              } else {
                const error = {};
                !formData.role && (error.role = "Role is Required");
                !formData.account_id && (error.account_id = "Account is Required");
                setCustomErrors(error);
              }
            }}
          >
            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

            <div className="form-group">
              <AvField
                name="email"
                label="Email*"
                value=""
                className="form-control"
                placeholder="Enter email"
                type="email"
                required
              />
            </div>

            <Label>Role*</Label>
            <FormGroup className="select2-container">
              <Select
                name="role"
                value={{
                  value: formData.role?.value || "",
                  label: formData.role?.label || "Select a Role",
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    role: {
                      value: e.value,
                      label: e.label,
                    },
                  });
                  delete customErrors.role;
                }}
                options={Object.keys(roles).map((key) => ({
                  label: toPascalCase(key === "readOnly" ? "client" : key),
                  value: roles[key],
                }))}
                classNamePrefix="select2-selection"
              />
              {customErrors.role && <small className="text-danger">{customErrors.role}</small>}
            </FormGroup>

            {accounts?.length === 1
              ? (
              <>
                <Label>Parent Account*</Label>
                <FormGroup className="select2-container">
                  <Select
                    name="account_id"
                    value={{
                      value: accounts[0].id,
                      label: accounts[0].email,
                    }}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </>
                )
              : (
              <>
                <Label>Parent Account*</Label>
                <FormGroup className="select2-container">
                  <Select
                    name="account_id"
                    value={{
                      value: formData.account_id?.value || "",
                      label: formData.account_id?.label || "Select an Account",
                    }}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        account_id: {
                          value: e.value,
                          label: e.label,
                        },
                      });
                      delete customErrors.account_id;
                    }}
                    options={accounts?.map((acc) => ({
                      label: acc.email,
                      value: acc.id,
                    }))}
                    classNamePrefix="select2-selection"
                  />
                  {customErrors.account_id && <small className="text-danger">{customErrors.account_id}</small>}
                </FormGroup>
              </>
                )}

            <div className="mt-3">
              <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                Send
              </button>
            </div>
          </AvForm>
        </div>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default InviteUserModal;
