import React, { useEffect } from "react";
import CheckBox from "../../../components/Common/CheckBox";
import Select from "react-select";
import CustomTooltip from "./../../../components/Common/CustomTooltip";
import { groupBy, concat, orderBy, values, last, keys, size, forEach, find, isBoolean } from "lodash";
import { Input, FormGroup } from "reactstrap";
import * as actions from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

// Import Actions
import { checkURL, getPlatformSpecificLabel, getVariation, is2StepApplicableForSupplier } from "../../../utils/common";
import { apiError } from "../../../store/suppliers/actions";
import { MARKET_PLACES } from "../../../constants";

const UpdateSupplier = ({
  formik,
  collapse,
  modalKey,
  supplier,
  platforms,
  storeFront,
  allStoresObj,
  isWHSupplier,
  setIsWHSupplier,
  checkMarketPlaces,
  variationModalOpen,
  setVariationModalOpen,
  isWholeSellerSelected,
  setIsWholeSellerSelected,
}) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.Settings);
  const { actionSupplier, mp_fi: isMpFi } = useSelector((state) => state.Supplier);
  const { whItemData, error } = useSelector((state) => state.Listings);

  const ACTIONS = { add: "add", update: "update" };

  const initialSupplier = { value: "", label: "Select a Platform" };
  const supplierDataOpts = () => {
    const { other, allOpts } = groupBy(values(platforms), (x) => (x.title === "Other" ? "other" : "allOpts"));
    return concat(orderBy(allOpts, "title"), other).reduce(
      (init, acc, i) => {
        acc?.title && init.push({ label: acc?.title, value: acc?.id, key: i, seller_type: acc?.seller_type });
        return init;
      },
      [{ value: "", label: "Select a Platform" }],
    );
  };

  const isDisableSaveBtn = () => (isWHSupplier && !size(keys(whItemData?.inventory))) || (isWHSupplier && error);

  const platformGroup = groupBy(platforms, "id");

  useEffect(() => {
    formik.handleReset();
  }, [actionSupplier.add]);

  useEffect(() => {
    const data = whItemData?.inventory;
    if (!size(keys(data))) return;
    const mapping = {
      price: "price",
      quantity_multiplier: "qty_multiplier",
      stock: "qty",
      shipping_fee: "shipping",
      is_default: true,
    };
    forEach(mapping, (value, key) => {
      formik.setFieldValue(key, isBoolean(value) ? value : data[value]);
    });
  }, [whItemData?.inventory]);

  const setNumericField = (key, event) => formik.setFieldValue(key, event?.target?.value || 0);

  return (
    <tr
      key={`__suppliers__listings__add__${modalKey}`}
      className={`${collapse ? "collapse-show table-tr" : "collapse-hide"}${
        supplier?.is_default ? "edit-supplier-bg" : ""
      }`}
    >
      {actionSupplier.add && <td className="border-none" />}
      <td colSpan={14} className="p-0">
        <table className="w-100 supplier-table-height">
          <tbody>
            <tr>
              <td>
                <div className="mb-1">Suppliers*</div>
                <FormGroup className="select2-container w-100 mb-0" style={{ minWidth: "170px" }}>
                  <Select
                    name="platform_id"
                    value={formik.values.platform_id || initialSupplier}
                    onChange={(e) => {
                      setIsWholeSellerSelected(e.seller_type);
                      formik.setFieldValue("platform_id", e);
                      const isWHSelected = find(platforms || [], (x) => x.id === parseInt(e?.value))?.is_warehouse;
                      setIsWHSupplier(isWHSelected);
                      if (isWHSelected) formik.setFieldValue("seller_sku", "");
                    }}
                    options={supplierDataOpts()}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </td>
              {platforms?.find((x) => x.id === parseInt(formik.values.platform_id?.value))?.seller_type ===
              "wholesale"
                ? (
                <td>
                  <div className="position-relative">
                    <div className="mb-1">
                      {getPlatformSpecificLabel(last?.(platformGroup[formik?.values?.platform_id?.value])?.name)}
                    </div>
                    <Input
                      className="form-control"
                      name="seller_sku"
                      type="text"
                      value={formik.values.seller_sku || ""}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        if (
                          platforms?.find((x) => x.id === parseInt(formik.values.platform_id?.value)).is_warehouse &&
                          formik.values.seller_sku &&
                          (!whItemData?.inventory || whItemData?.inventory?.sku !== formik.values.seller_sku)
                        ) {
                          dispatch(actions.fetchInventoryItemInfo(formik.values.seller_sku));
                        }
                      }}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.seller_sku && formik.errors.seller_sku
                      ? (
                      <small className="text-danger mt-1 position-absolute">{formik.errors.seller_sku}</small>
                        )
                      : null}
                  </div>
                </td>
                  )
                : (
                <td>
                  <div className="position-relative">
                    <div className="mb-1">Supplier URL</div>
                    <Input
                      className="form-control"
                      name="url"
                      type="text"
                      value={formik.values.url}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        if (e.target.value !== "") {
                          e.target.value = checkURL(e.target.value);
                          formik.setFieldValue("url", e.target.value);
                          const marketplace = checkMarketPlaces(e.target.value);
                          const error = !marketplace
                            ? `We do not ${
                                formik.values.platform_id?.value === 763 ? "refresh" : "support"
                              } this supplier yet so you will need to monitor it manually for now`
                            : "";
                          dispatch(apiError(error));
                        }
                      }}
                      onChange={(...args) => {
                        formik.handleChange(...args);
                        if (args[0].target.value) {
                          const marketplace = checkMarketPlaces(args[0].target.value);
                          marketplace
                            ? formik.setFieldValue("platform_id", { label: marketplace.title, value: marketplace.id })
                            : formik.setFieldValue("platform_id", initialSupplier);
                        }
                      }}
                    />
                    {formik.touched.url && formik.errors.url
                      ? (
                      <small className="text-danger mt-1 position-absolute">{formik.errors.url}</small>
                        )
                      : null}
                  </div>
                </td>
                  )}
              <td>
                <div className="position-relative">
                  <div className="d-flex mb-1">
                    Override Price
                    <CheckBox
                      isSwitch={true}
                      name="price_lock"
                      className="ml-2"
                      state={!!formik.values.price_lock}
                      setState={() => formik.setFieldValue("price_lock", !formik.values.price_lock)}
                    />
                  </div>
                  <Input
                    className="form-control"
                    name="manual_price"
                    type="number"
                    min="0"
                    step="0.01"
                    disabled={!formik.values.price_lock}
                    value={formik.values.manual_price}
                    onBlur={formik.handleBlur}
                    onChange={(e) => setNumericField("manual_price", e)}
                  />
                  {formik.touched.manual_price && formik.errors.manual_price
                    ? (
                    <small className="text-danger mt-1 position-absolute">{formik.errors.manual_price}</small>
                      )
                    : null}
                </div>
              </td>
              <td>
                <div className="position-relative">
                  <div className="d-flex mb-1">
                    Override Shipping
                    <CheckBox
                      isSwitch={true}
                      name="shipping_fee_lock"
                      className="ml-2"
                      state={!!formik.values.shipping_fee_lock}
                      setState={() => formik.setFieldValue("shipping_fee_lock", !formik.values.shipping_fee_lock)}
                    />
                  </div>
                  <Input
                    className="form-control"
                    name="manual_shipping_fee"
                    type="number"
                    min="0"
                    value={formik.values.manual_shipping_fee}
                    disabled={!formik.values.shipping_fee_lock}
                    onBlur={formik.handleBlur}
                    onChange={(e) => setNumericField("manual_shipping_fee", e)}
                  />
                  {formik.touched.manual_shipping_fee && formik.errors.manual_shipping_fee
                    ? (
                    <small className="text-danger mt-1 position-absolute">{formik.errors.manual_shipping_fee}</small>
                      )
                    : null}
                </div>
              </td>
              <td>
                <div className="position-relative">
                  <div className="d-flex mb-1">
                    Override Stock
                    <CheckBox
                      isSwitch={true}
                      name="stock_lock"
                      className="ml-2"
                      state={!!formik.values.stock_lock}
                      setState={() => {
                        formik.setFieldValue("stock_lock", !formik.values.stock_lock);
                        !formik.values.stock_lock && formik.setFieldValue("use_custom_stock", false);
                      }}
                    />
                  </div>
                  <Input
                    className="form-control"
                    name="manual_stock"
                    type="number"
                    min="0"
                    disabled={!formik.values.stock_lock}
                    value={formik.values.manual_stock}
                    onBlur={formik.handleBlur}
                    onChange={(e) => setNumericField("manual_stock", e)}
                  />
                  {formik.touched.manual_stock && formik.errors.manual_stock
                    ? (
                    <small className="text-danger mt-1 position-absolute">{formik.errors.manual_stock}</small>
                      )
                    : null}
                </div>
              </td>
              <td>
                <div className="mb-1">Qty.Mul</div>
                <Input
                  className="form-control"
                  name="quantity_multiplier"
                  type="number"
                  min="1"
                  value={formik.values.quantity_multiplier}
                  onBlur={formik.handleBlur}
                  onChange={(e) => setNumericField("quantity_multiplier", e)}
                />
              </td>
              <td>
                <div className="d-flex min-height-60 mt-1  align-items-start  grid-row-gap-9 flex-column">
                  <div className="d-flex align-items-start">
                    Variation
                    <i
                      title="Edit"
                      className="ml-2 mt-0 bx bx-xs bx-edit text-primary cursor-pointer"
                      onClick={() => setVariationModalOpen(!variationModalOpen)}
                    />
                  </div>
                  <span className="color-white variation-ellipsis">
                    {getVariation(formik.values.variation) || "N/A"}
                  </span>
                </div>
              </td>
              <td>
                {account?.data?.two_step_feature &&
                  !allStoresObj[storeFront.value]?.two_step_enabled &&
                  allStoresObj[storeFront.value]?.marketplace !== MARKET_PLACES.shopify &&
                  isWholeSellerSelected !== "wholesale" && (
                    <div className="d-flex min-height-60 align-items-start grid-row-gap-11 flex-column">
                      <div className="d-flex align-items-center">
                        2 Step
                        {is2StepApplicableForSupplier(isMpFi, allStoresObj, storeFront).length
                          ? (
                          <i id={`__2_step_tooltip__${supplier?.id}`} className="bx bx-info-circle text-primary ml-1" />
                            )
                          : null}
                      </div>
                      {is2StepApplicableForSupplier(isMpFi, allStoresObj, storeFront).length
                        ? (
                        <CustomTooltip
                          placement="top"
                          content={is2StepApplicableForSupplier(isMpFi, allStoresObj, storeFront)}
                          target={`__2_step_tooltip__${supplier?.id}`}
                        />
                          )
                        : null}
                      <CheckBox
                        isSwitch={true}
                        disabled={is2StepApplicableForSupplier(isMpFi, allStoresObj, storeFront).length}
                        name="ship_to_warehouse"
                        className="ml-2"
                        state={!!formik.values.ship_to_warehouse?.value}
                        setState={() =>
                          formik.setFieldValue("ship_to_warehouse", {
                            ...formik.values.ship_to_warehouse,
                            value: !formik.values.ship_to_warehouse?.value,
                          })
                        }
                      />
                    </div>
                )}
              </td>
              <td>
                <div className="d-flex min-height-60 grid-row-gap-11  align-items-start flex-column">
                  Is Default
                  <CheckBox
                    isSwitch={true}
                    name="is_default"
                    className="ml-2"
                    state={!!formik.values.is_default}
                    setState={() => formik.setFieldValue("is_default", !formik.values.is_default)}
                  />
                </div>
              </td>
              <td>
                <div className="h-100 d-flex justify-content-center">
                  <i
                    onClick={() => !isDisableSaveBtn() && formik.handleSubmit()}
                    className={`bx bx-sm bx-save text-primary cursor-pointer ${
                      isDisableSaveBtn() ? "generate-label disabled" : ""
                    }`}
                  />
                  <i
                    onClick={() => {
                      dispatch(actions.setActionSupplier({ [ACTIONS.update]: false, [ACTIONS.add]: false }));
                      dispatch(actions.resetWHSKUInventory());
                      formik.handleReset();
                    }}
                    className="bx bx-sm bx-x danger cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default UpdateSupplier;
