import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Label, FormGroup, FormFeedback, Input, Alert, Progress } from "reactstrap";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "../../../components/Common/Modal";
import { State, Country } from "country-state-city";
import { omit, keys, pick } from "lodash";
import {
  CANADA_ZIP_CODE_REGEX,
  USA_ZIP_CODE_REGEX,
  US_PHONE_NUMBER_REGEX,
  WH_OPTS_MAPPING,
} from "../../../constants/index";
import { getUserInfo, isUserHaveFullAccess, RenderIf } from "../../../utils/common";

import ConfirmAction from "../../../assets/svg/confirmAction.svg";

// Components
import ExistingWareHouse from "./ExistingWareHouse";
// actions
import { setAddWareHouseModal, setSelectOnBoardStore, selectExistingWareHouse } from "../../../store/actions";
import { fetchAllWarehouses } from "../../../store/warehouse/actions";
import CheckBox from "../../../components/Common/CheckBox";
import SelectWHOnBoard from "./SelectWHOnBoard";
import ErrorMessage from "../../Onboarding/components/errorMessage";

const WareHouseModal = (props) => {
  const dispatch = useDispatch();
  const { wareHouses, isSelectOnBoardStore, addWareHouseModalIsOpen, error, success, loading } = useSelector(
    (state) => state.WareHouses,
  );
  const isFullAccess = isUserHaveFullAccess();
  const [whOpts, setWHOpts] = useState({ existingWh: true, fulfillment: false, returns: !isFullAccess });
  const [progress, setProgress] = useState(33);
  const [selectedWH, setSelectedWH] = useState("");

  const initialValues = {
    name: "",
    address1: "",
    address2: "",
    country: { label: "Select Country", value: "" },
    state: { label: "Select State", value: "" },
    city: "",
    phone: "",
    zipcode: "",
    is_private: true,
  };

  const toggleModal = () => {
    dispatch(setAddWareHouseModal(!addWareHouseModalIsOpen));
    isSelectOnBoardStore && dispatch(setSelectOnBoardStore(!setSelectOnBoardStore));
    formik.resetForm();
  };

  const validate = (values) => {
    const errors = {};
    if (!whOpts.existingWh) {
      Object.keys(omit(values, "address2")).forEach((key) => {
        if (values[key] === "" || values[key].label === "" || values[key].value === "")
          errors[key] = "Field is Required";
      });
      values.city && !/^[A-Za-z\s]+$/.test(values.city) && (errors.city = "Only alphabets are allowed");
      if (values.country && values.zipcode)
        values.country.value === "CA"
          ? !RegExp(CANADA_ZIP_CODE_REGEX).test(values.zipcode) && (errors.zipcode = "Invalid Zip Code Format")
          : !RegExp(USA_ZIP_CODE_REGEX).test(values.zipcode) && (errors.zipcode = "Invalid Zip Code Format");
      values.phone &&
        !RegExp(US_PHONE_NUMBER_REGEX).test(values.phone) &&
        (errors.phone = "Invalid Phone Number Format");
    } else {
      if (!selectedWH) errors.existingWh = "Atleast One Option is Required";
    }
    if (keys(pick(whOpts, ["fulfillment", "returns"])).every((x) => !whOpts[x]))
      errors.enabledOpts = "Atleast One Enabled Option is Required";
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      !whOpts.existingWh &&
        dispatch(
          selectExistingWareHouse({
            ...values,
            state: values.state.value,
            country: values.country.value,
            creator_id: getUserInfo()?.account_id,
          }),
        );
      dispatch(setSelectOnBoardStore(true));
    },
  });

  const existingWHProps = {
    formik,
    wareHouses,
    selectedWH,
    toggleModal,
    setSelectedWH,
    whOpts,
    setWHOpts,
  };
  const selectWHProps = { whOpts, progress, setProgress, toggleModal, formik, initialValues };
  const responseMsgProps = { success, error, loading, dispatch, toggleModal };
  const wHOptsProps = { whOpts, setWHOpts, formik, isFullAccess };

  return (
    <React.Fragment>
      <Modal size="lg" id="wh-add-modal" isOpen={addWareHouseModalIsOpen}>
        <PorgressBar progress={progress} />
        <RenderIf
          isTrue={isSelectOnBoardStore}
          fallback={
            <>
              <WHOptions {...wHOptsProps} />
              {whOpts.existingWh
                ? (
                <ExistingWareHouse {...existingWHProps} />
                  )
                : (
                <>
                  {(error || formik.errors.enabledOpts) && (
                    <Alert color="danger">
                      <i className="bx bx-info-circle pr-2"></i>
                      {error || formik.errors.enabledOpts || ""}
                    </Alert>
                  )}
                  <Alert color="warning" className="mb-0">
                    <i className="bx bx-info-circle pr-2" />
                    <strong>Please Note: </strong>
                    Add your own address. (If you add your own warehouse, you are responsible for shipping items out
                    from it.)"
                  </Alert>
                  <div className="modal-body">
                    <form className="m-2" onSubmit={formik.handleSubmit}>
                      <Row>
                        <h5 className="ml-2">New Warehouse Details</h5>
                        <Col lg="12 mt-2">
                          <Label>Name</Label>
                          <Input
                            name="name"
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.name && formik.errors.name
                            ? (
                            <small className="text-danger">{formik.errors.name}</small>
                              )
                            : null}
                        </Col>
                        <Col lg="12 mt-2">
                          <Label>Address line 1</Label>
                          <Input
                            name="address1"
                            value={formik.values.address1}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.address1 && formik.errors.address1
                            ? (
                            <small className="text-danger">{formik.errors.address1}</small>
                              )
                            : null}
                        </Col>
                        <Col lg="12 mt-2">
                          <Label>Address line 2</Label>
                          <Input
                            name="address2"
                            value={formik.values.address2}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.address2 && formik.errors.address2
                            ? (
                            <small className="text-danger">{formik.errors.address2}</small>
                              )
                            : null}
                        </Col>
                        <Col lg="12 mt-2">
                          <Label>Phone</Label>
                          <InputMask
                            name="phone"
                            mask="+1 (999) 999-9999"
                            value={formik.values.phone}
                            className="form-control input-color"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          ></InputMask>
                          <span className="font-13 text-muted">+1 (999) 999-9999</span>
                          <br />
                          {formik.touched.phone && formik.errors.phone
                            ? (
                            <small className="text-danger">{formik.errors.phone}</small>
                              )
                            : null}
                        </Col>
                        <Col lg="6 mt-2">
                          <Label>City</Label>
                          <Input
                            name="city"
                            value={formik.values.city}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.city && formik.errors.city
                            ? (
                            <small className="text-danger">{formik.errors.city}</small>
                              )
                            : null}
                        </Col>
                        <Col lg="6 mt-2">
                          <FormGroup className="select2-container mb-0">
                            <Label>Country</Label>
                            <Select
                              name="country"
                              value={formik.values.country}
                              onBlur={formik.handleBlur}
                              onChange={(option) => {
                                formik.setFieldValue("country", option);
                                formik.setFieldValue("state", "");
                              }}
                              options={Country.getAllCountries()
                                .filter((x) => ["US", "CA"].includes(x.isoCode))
                                .map((y) => ({
                                  label: y.name,
                                  value: y.isoCode,
                                }))}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                          {formik.touched.country && formik.errors.country
                            ? (
                            <small className="text-danger">{formik.errors.country}</small>
                              )
                            : null}
                        </Col>
                        <Col lg="6 mt-2">
                          <FormGroup className="select2-container mb-0">
                            <Label>State</Label>
                            <Select
                              name="state"
                              value={formik.values.state}
                              onChange={(option) => {
                                formik.setFieldValue("state", option);
                              }}
                              options={State.getStatesOfCountry(formik.values.country?.value || "").map((x) => ({
                                label: x.name,
                                value: x.isoCode,
                              }))}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                          {formik.touched.state && formik.errors.state
                            ? (
                            <small className="text-danger">{formik.errors.state}</small>
                              )
                            : null}
                        </Col>
                        <Col lg="6 mt-2">
                          <Label>Zip Code</Label>
                          <Input
                            name="zipcode"
                            maxLength={15}
                            value={formik.values.zipcode}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          <FormFeedback>Invalid Format</FormFeedback>
                          {formik.touched.zipcode && formik.errors.zipcode
                            ? (
                            <small className="text-danger">{formik.errors.zipcode}</small>
                              )
                            : null}
                        </Col>
                      </Row>
                      <Row className="d-flex card-footer bg-transparent border-top justify-content-between mt-4 mb-2">
                        <Col md={4}>
                          <Button
                            outline
                            type="reset"
                            color="primary"
                            className="mr-3"
                            onClick={() => {
                              whOpts.existingWh = !whOpts.existingWh;
                              setWHOpts(whOpts);
                              formik.handleReset();
                            }}
                          >
                            Previous
                          </Button>
                        </Col>
                        <Col md={8} className="justify-content-end align-items-baseline d-flex">
                          <Link
                            to="#"
                            className="mr-3"
                            onClick={() => {
                              formik.handleReset();
                              toggleModal();
                            }}
                          >
                            Skip
                          </Link>
                          <Button type="submit" color="primary" className="w-25">
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </>
                  )}
            </>
          }
        >
          {progress === 100 ? <ResponseMsg {...responseMsgProps} /> : <SelectWHOnBoard {...selectWHProps} />}
        </RenderIf>
      </Modal>
    </React.Fragment>
  );
};

const WHOptions = ({ whOpts, setWHOpts, formik, isFullAccess }) => {
  const data = {
    fulfillment: {
      name: "default_wh_fulfillment",
      state: whOpts[WH_OPTS_MAPPING.fulfillment] || false,
      label: "Enable WH Fulfillment",
      text: "Our warehouses can receive your inventory and ship your orders out for you (including 2-step / Drop Retail). The fee is only $3.50 per order shipped.",
      hide: !isFullAccess,
    },
    returns: {
      name: "default_returns",
      state: whOpts[WH_OPTS_MAPPING.returns] || false,
      label: "Enable Returns",
      text: "Our warehouses can receive all of your marketplace returns. Once received, your team needs to upload the shipping label to them. The cost is only $3/return.",
      hide: false,
    },
  };
  const filteredOptions = keys(data).filter(key => !data[key].hide);
  return (
    <div className={`d-flex py-2 ${!isFullAccess ? "justify-content-center" : ""}`}>
      {filteredOptions.map((key, i) => (
        <div
          key={`checkbox_wh_${i}`}
          className={`border ${whOpts[key] ? "border-primary" : "border-light"} border-2 w-50 mr-2 p-3`}
        >
          <CheckBox
            name={data[key].name}
            state={data[key].state}
            setState={(state) => {
              if (state && formik.errors.enabledOpts) formik.errors.enabledOpts = "";
              setWHOpts({ ...whOpts, [key]: state });
            }}
            className="my-2"
            label={data[key].label}
            disabled={!isFullAccess}
          />
          <span>{data[key].text}</span>
        </div>
      ))}
    </div>
  );
};

const PorgressBar = ({ progress }) => (
  <div className="twitter-bs-wizard">
    <div className="px-5 py-2">
      <Progress color="primary" value={progress} />
      <h4 className="card-title mt-3 text-center">Add/Setup Warehouse</h4>
    </div>
  </div>
);

const ResponseMsg = ({ success, error, loading, dispatch, toggleModal }) =>
  success
    ? (
    <div className="px-5">
      <div className="d-flex justify-content-center flex-column">
        <img style={{ maxHeight: "100px" }} src={ConfirmAction} alt="Confirm Action" />
        <br />
        <h4 className="card-title mb-3 text-center">Congratulations!</h4>
        <div className="text-center">The warehouse has now been attached to your account.</div>
      </div>
      <br />
      <div className="text-center">
        <button
          onClick={() => {
            dispatch(fetchAllWarehouses({ page: 1, per_page: 50 }));
            toggleModal();
          }}
          className="btn btn-primary w-25 mb-3 btn-sm waves-effect waves-light"
        >
          Done
        </button>
      </div>
    </div>
      )
    : !error && loading
        ? (
    <h4 className="card-title mb-3 text-center">Please wait a moment</h4>
          )
        : (
    <div className="text-center">
      <ErrorMessage error={error} />
      <div className="text-center">
        <button
          onClick={() => {
            dispatch(fetchAllWarehouses({ page: 1, per_page: 50 }));
            toggleModal();
          }}
          className="btn btn-primary w-25 mb-3 btn-sm waves-effect waves-light"
        >
          Done
        </button>
      </div>
    </div>
          );

export default WareHouseModal;
