import {
  FETCH_PURCHASING_ACCOUNTS,
  FETCH_PURCHASING_ACCOUNTS_SUCCESS,
  SET_ADD_PURCHASING_ACCOUNTS_MODAL,
  SET_EDIT_PURCHASING_ACCOUNTS_MODAL,
  CREATE_PURCHASING_ACCOUNTS,
  CREATE_PURCHASING_ACCOUNTS_SUCCESS,
  SELECT_PURCHASING_ACCOUNTS,
  UPDATE_PURCHASING_ACCOUNTS,
  UPDATE_PURCHASING_ACCOUNTS_SUCCESS,
  DELETE_PURCHASING_ACCOUNT,
  DELETE_PURCHASING_ACCOUNT_SUCCESS,
  ADD_USERS_PURCHASING_ACCOUNT,
  ADD_USERS_PURCHASING_ACCOUNT_SUCCESS,
  SET_CREATE_CREDIT_CARD_TYPES_MODAL,
  CREATE_CREDIT_CARD_TYPES,
  SET_ADD_CREDENTIALS_MODAL,
  SET_EDIT_CREDENTIALS_MODAL,
  CREATE_CREDENTIALS,
  CREATE_CREDIT_CARD_TYPES_SUCCESS,
  CREATE_CREDENTIALS_SUCCESS,
  UPDATE_CREDENTIALS,
  UPDATE_CREDENTIALS_SUCCESS,
  SELECT_CREDENTIALS,
  UNLOCK_CREDENTIAL,
  UNLOCK_CREDENTIAL_SUCCESS,
  DELETE_CREDENTIAL,
  DELETE_CREDENTIAL_SUCCESS,
  SET_CREDENTIALS_FILTERS,
  SET_SELECTED_IDS,
  SET_CREDENTIAL_BULK_UPDATE_MODAL,
  CREDENTIAL_BULK_UPDATE,
  CREDENTIAL_BULK_UPDATE_SUCCESS,
  API_ERROR,
  FETCH_BUYING_EMAILS,
  FETCH_BUYING_EMAILS_SUCCESS,
  ADD_PROXY_MANUAL,
  ADD_PROXY_MANUAL_SUCCESS,
  REFRESH_ACCOUNT_PROXY,
  REFRESH_ACCOUNT_PROXY_SUCCESS,
  FETCH_TOTP,
  FETCH_TOTP_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  accounts: [],
  addAccountModalIsOpen: false,
  editAccountModalIsOpen: false,
  addCredentialModalIsOpen: false,
  editCredentialModalIsOpen: false,
  createCreditCardTypesModalIsOpen: false,
  credentialBulkActionModalIsOpen: false,
  selectedCredentials: undefined,
  selectedIds: [],
  selectedAccounts: undefined,
  filters: {
    credential_screen: true,
    blocked: "",
    locked: "",
    enabled: "",
    acc_type: "",
  },
  totps: {},
};

const Credentials = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOTP:
      state = { ...state, totpLoading: true };
      break;
    case FETCH_TOTP_SUCCESS:
      state = { ...state, totpLoading: false, totps: action.payload };
      break;
    case ADD_PROXY_MANUAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_PROXY_MANUAL_SUCCESS:
      state = {
        ...state,
        success: action.payload.message,
        loading: false,
      };
      break;
    case REFRESH_ACCOUNT_PROXY:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case REFRESH_ACCOUNT_PROXY_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        success: action.payload.message,
        loading: false,
      };
      break;
    case FETCH_PURCHASING_ACCOUNTS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_PURCHASING_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        accounts: action.payload.data,
        loading: false,
      };
      break;
    case FETCH_BUYING_EMAILS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_BUYING_EMAILS_SUCCESS:
      state = {
        ...state,
        allAccounts: action.payload.data,
        loading: false,
      };
      break;
    case SELECT_CREDENTIALS:
      state = {
        ...state,
        selectedCredentials: action.payload,
      };
      break;
    case SELECT_PURCHASING_ACCOUNTS:
      state = {
        ...state,
        selectedAccounts: action.payload,
      };
      break;
    case CREATE_CREDENTIALS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case CREATE_PURCHASING_ACCOUNTS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case CREATE_CREDIT_CARD_TYPES:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UNLOCK_CREDENTIAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UNLOCK_CREDENTIAL_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credentials Unlocked",
        loading: false,
      };
      break;
    case DELETE_CREDENTIAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DELETE_CREDENTIAL_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credentials Deleted Successfully",
        loading: false,
      };
      break;
    case DELETE_PURCHASING_ACCOUNT:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DELETE_PURCHASING_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Account Deleted Successfully",
        loading: false,
      };
      break;
    case ADD_USERS_PURCHASING_ACCOUNT:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_USERS_PURCHASING_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Accounts has been Assigned to Users Successfully",
        loading: false,
      };
      break;
    case CREATE_CREDENTIALS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credentials Created Successfully",
        loading: false,
      };
      break;
    case CREATE_PURCHASING_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Account has been Created Successfully",
        loading: false,
      };
      break;
    case CREATE_CREDIT_CARD_TYPES_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credit Cards has been updated Successfully",
        loading: false,
      };
      break;
    case UPDATE_CREDENTIALS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_CREDENTIALS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credentials Updated Successfully",
        loading: false,
      };
      break;
    case UPDATE_PURCHASING_ACCOUNTS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_PURCHASING_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Account Updated Successfully",
        loading: false,
      };
      break;
    case CREDENTIAL_BULK_UPDATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case CREDENTIAL_BULK_UPDATE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Card Types has been Updated Successfully",
        loading: false,
      };
      break;
    case SET_ADD_PURCHASING_ACCOUNTS_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        addAccountModalIsOpen: action.payload,
        selectedAccounts: undefined,
        loading: false,
      };
      break;
    case SET_ADD_CREDENTIALS_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        addCredentialModalIsOpen: action.payload,
        selectedCredentials: undefined,
        loading: false,
      };
      break;
    case SET_EDIT_CREDENTIALS_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        editCredentialModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_CREATE_CREDIT_CARD_TYPES_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        createCreditCardTypesModalIsOpen: action.payload.bool,
        selectedCredentials: undefined,
        loading: false,
      };
      if (action.payload.type) state.creditCardTypesModalType = action.payload.type;
      break;
    case SET_CREDENTIAL_BULK_UPDATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        credentialBulkActionModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_EDIT_PURCHASING_ACCOUNTS_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        editAccountModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_CREDENTIALS_FILTERS:
      state = {
        ...state,
        filters: action.payload,
      };
      break;
    case SET_SELECTED_IDS:
      state = {
        ...state,
        selectedIds: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Credentials;
