import { isBoolean } from "lodash";
import * as types from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  reports: undefined,
  downloadModal: false,
  settlementReports: [],
  filePath: "",
};

const Reports = (state = initialState, action) => {
  if (
    [
      types.FETCH_REPORTS,
      types.GEN_ORDER_DETAILS_REPORT,
      types.GEN_TRANSACTIONAL_REPORT,
      types.GEN_SETTLEMENT_REPORT,
      types.GEN_ORDERS_REPORT,
      types.GEN_INVENTORY_REPORT,
      types.GEN_RETURNS_REPORT,
      types.GEN_WH_LABEL_REPORT,
      types.GEN_INVENTORY_INSIGHT,
    ].includes(action.type)
  ) {
    return (state = { ...state, loading: true, error: "", success: "" });
  } else if (
    [
      types.GEN_ORDER_DETAILS_REPORT_SUCCESS,
      types.GEN_TRANSACTIONAL_REPORT_SUCCESS,
      types.GEN_SETTLEMENT_REPORT_SUCCESS,
      types.GEN_ORDERS_REPORT_SUCCESS,
      types.GEN_INVENTORY_REPORT_SUCCESS,
      types.GEN_RETURNS_REPORT_SUCCESS,
      types.GEN_WH_LABEL_REPORT_SUCCESS,
      types.GEN_INVENTORY_INSIGHT_SUCCESS,
    ].includes(action.type)
  ) {
    if (action.payload.closeModal) state.downloadModal = false;
    return (state = { ...state, loading: false, success: action.payload.success });
  }
  switch (action.type) {
    case types.FETCH_REPORTS_SUCCESS:
      state = { ...state, loading: false, reports: action.payload };
      break;
    case types.DOWNLOAD_REPORT:
      state = { ...state, error: "", success: "" };
      break;
    case types.DOWNLOAD_REPORT_SUCCESS:
      state = { ...state, filePath: action.payload?.url };
      break;
    case types.GET_AVAILABLE_SETTLEMENT_REPORTS:
      state = { ...state, reportsLoading: true, settlementReports: [], error: "", success: "" };
      break;
    case types.GET_AVAILABLE_SETTLEMENT_REPORTS_SUCCESS:
      state = { ...state, reportsLoading: false, settlementReports: action.payload.dates };
      break;
    case types.TOGGLE_REPORT_MODAL:
      state.downloadModal = isBoolean(action.payload) ? action.payload : !state.downloadModal;
      state.success = false;
      break;
    case types.RESET_STATE: {
      const error = ["No records for given date range", "No records found"].includes(state.error) ? "" : state.error;
      state = { ...state, error, loading: false, success: "" };
      break;
    }
    case types.RESET_URL_DOWNLOAD_FILE_REPORTS:
      state = { ...state, filePath: "" };
      break;
    case types.API_ERROR:
      state = { ...state, error: action.payload, success: false, loading: false };
      break;
    default:
      break;
  }
  return state;
};

export default Reports;
