import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { concat, pick, sortBy, uniq, uniqBy } from "lodash";
import Select, { components } from "react-select";
import { RenderIf } from "../../../utils/common";
import getBrandImageSrc from "../../../utils/brandImages";
import {
  requestAvailablePaymentReports,
  requestPaymentReport,
  setPreloader,
  setDashboardPdfOpts,
  setPlData,
} from "../../../store/actions";
import { connect } from "react-redux";

const makeStoreOptions = (props) => {
  if (!Array.isArray(props?.accounts)) return [];
  const reducer = (arr, acc, i) =>
    acc.valid ? concat(arr, { label: acc.name, value: acc.id, marketplace: acc.marketplace, key: i }) : arr;
  return props.accounts.reduce(reducer, []);
};
const formatDate = (date) => moment(date, "MMDDYYYY").format("dddd, DD-MM-YYYY");
const sortDates = (dates, toDate) =>
  sortBy(dates, (date1, date2) => moment(toDate(date2), "MMDDYYYY") - moment(toDate(date1), "MMDDYYYY"));
const getDates = (props, toDate, isAmz) => {
  if (!isAmz) return uniq(sortDates(props?.SourceOrders?.currentReports, toDate));
  return uniqBy(sortDates(props?.SourceOrders?.currentReports, toDate), "date");
};

const PandLReportModal = (props) => {
  const [isAmz, setIsAmz] = useState(false);
  const toDate = (date) => (isAmz ? date?.date : date);
  const toggleModal = (_) => props.setDashboardPdfOpts({ isPLModal: !props.isOpen });
  const formik = useFormik({
    initialValues: { storeId: "", reportDate: "" },
    validate: (values) => {
      const errors = {};
      props.SourceOrders?.currentReports?.length &&
        !values.reportDate &&
        (errors.reportDate = "Report selection is required");
      props.accounts?.length && !values.storeId && (errors.storeId = "Account selection is required");
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (!values.storeId || !values.reportDate) return;
      props.requestPaymentReport({
        storeId: values.storeId.value,
        [isAmz ? "reportId" : "reportDate"]: values.reportDate.value,
        isPDF: true,
      });
    },
  });

  useEffect(() => {
    formik.resetForm();
    props.setPlData({});
  }, [props.isOpen]);

  useEffect(() => {
    props.setPreloader(props.SourceOrders.loading);
  }, [props.SourceOrders.loading]);

  useEffect(() => {
    if (!props.SourceOrders?.pAndLData?.marketplaceFee) return;
    props.setDashboardPdfOpts({ pdfDivStyle: "block", pdfLoader: true });
  }, [props.SourceOrders?.pAndLData]);

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Download P & L</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <RenderIf isTrue={formik.touched.reportDate && formik.errors.reportDate}>
          <small className="text-danger m-1">{formik.errors.reportDate}</small>
        </RenderIf>
        <RenderIf isTrue={formik.touched.storeId && formik.errors.storeId}>
          <small className="text-danger m-1">{formik.errors.storeId}</small>
        </RenderIf>
        <div className="modal-body">
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Col className="my-1">
              <RenderIf
                fallback={
                  <div className="d-flex justify-content-center m-2">
                    <div className="d-flex flex-column">
                      <h5>Kindly add a valid store to use this feature</h5>
                    </div>
                  </div>
                }
                isTrue={makeStoreOptions(props)?.length}
              >
                <Label>Marketplace Account</Label>
                <FormGroup className="select2-container w-100">
                  <Select
                    name="storeId"
                    value={formik.values.storeId}
                    onChange={(e) => {
                      formik.setFieldValue("storeId", e);
                      setIsAmz(e.marketplace === "amazon");
                      props.requestAvailablePaymentReports({
                        storeId: e.value || "",
                        isAmz: e.marketplace === "amazon",
                      });
                    }}
                    options={makeStoreOptions(props)}
                    classNamePrefix="select2-selection"
                    height={{ maxHeight: "250px" }}
                    components={{
                      Option: (props) => (
                        <components.Option {...props}>
                          <RenderIf isTrue={getBrandImageSrc(props.data.marketplace)}>
                            <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
                          </RenderIf>
                          {props.data.label}
                        </components.Option>
                      ),
                    }}
                  />
                </FormGroup>
              </RenderIf>
              <RenderIf isTrue={formik.values.storeId && props.SourceOrders?.currentReports}>
                <Label>Select Report</Label>
                <FormGroup className="select2-container w-100">
                  <Select
                    name="reportDate"
                    value={formik.values.reportDate}
                    onChange={(e) => formik.setFieldValue("reportDate", e)}
                    options={getDates(props, toDate, isAmz).reduce((init, row, i) => {
                      init.push({ label: formatDate(toDate(row)), value: isAmz ? row.reportId : row, key: i });
                      return init;
                    }, [])}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </RenderIf>
            </Col>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <RenderIf isTrue={makeStoreOptions(props).length}>
                <Button type="submit" color="success" className="mr-3">
                  Download PDF
                </Button>
              </RenderIf>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => pick(state, "SourceOrders");

export default connect(mapStateToProps, {
  requestAvailablePaymentReports,
  requestPaymentReport,
  setPreloader,
  setDashboardPdfOpts,
  setPlData,
})(PandLReportModal);
