import React from "react";
import { Input } from "reactstrap";

const NumericField = (props) => {
  const { onChange, value } = props;
  return (
    <Input
      {...props}
      type="number"
      value={value}
      onChange={onChange}
      onKeyPress={(e) => {
        if (["-", "e", "E"]?.includes(e.key)) e.preventDefault();
      }}
    />
  );
};

export default NumericField;
