import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Select from "react-select";
import Lazy from "../../../components/Common/LazyComponent";
import { getWHAddress } from "../../../components/utils/getWHAddress";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import { getUserInfo, getWarehouseName, RenderIf, formatNumber } from "../../../utils/common";
import { Card, CardHeader, Col, Badge, FormGroup, Collapse, Input } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import unlinkIcon from "../../../assets/svg/warehouse/unlink-warehouse.svg";
import {
  updateWarehouse,
  setViewWareHouseUsersModal,
  setAssignUserWareHouseModal,
  setSelectedWareHouse,
  assignAgencyToWarehouse,
  setViewAgencyWarehouseModal,
  deleteWarehouse,
  deleteWHAccount,
  updateWHAccount,
} from "../../../store/actions";

import { apiError } from "../../../store/warehouse/actions";
import { State } from "country-state-city";
import { compact, keys, map, merge, startCase } from "lodash";
import { USER_ROLES } from "../../../constants";

const FEE_OPTS = [
  {
    title: "2 Step Handling Fees",
    editCol: "twoStep",
    dbCol: "shipping_handling_cost",
    feeCol: "warehouseFee",
  },
  {
    title: "Return Handling Fees",
    editCol: "return",
    dbCol: "return_handling_cost",
    feeCol: "returnHandlingFee",
  },
  {
    title: "Storage Per Unit Fees",
    editCol: "storage",
    dbCol: "storage_per_unit_cost",
    feeCol: "storageFee",
  },
  {
    title: "Oversized Handling Fees",
    editCol: "oversized",
    dbCol: "oversized_handling_cost",
    feeCol: "oversizedFee",
  },
];

const WareHouseCard = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    country,
    state,
    zipcode,
    is_enabled: isEnabled,
    is_private: isPrivate,
    creator_id: creatorId,
    shipping_handling_cost: warehouseFee,
    storage_per_unit_cost: storageFee,
    return_handling_cost: returnHandlingFee,
    AccountWarehouse,
    stats,
  } = props.data;
  const [fee, setFee] = useState({ warehouseFee, storageFee, returnHandlingFee });
  const [isOpen, setIsOpen] = useState(false);
  const [collapseInfoOpen, setCollapseInfoOpen] = useState({ detail: true, fee: false, settings: false });
  const [confirmationDialogue, setConfirmationDialogue] = useState({ e: "", msg: "", type: "" });
  const [editWHFee, setEditWHFee] = useState({ twoStep: false, return: false, storage: false });
  const stateKey = `_state_country_${props.data.id}`;
  const stateName = country
    ? State.getStatesOfCountry(country)?.find((x) => x.isoCode.toLowerCase() === state?.toLowerCase())?.name
    : "";
  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;
  const isWHOwnByCreator = creatorId === getUserInfo()?.account_id;
  const agency = { label: "", value: "" };
  const isAgency = AccountWarehouse?.find((x) => !x.agency_id)?.id === creatorId;
  const allEmails = AccountWarehouse.reduce((acc, x) => merge(acc, { [x.id]: x.email }), {});
  const names = AccountWarehouse.reduce((acc, x) => merge(acc, { [x.id]: [x.first_name, x.last_name] }), {});
  const accountWH = AccountWarehouse?.find(
    (x) => x.account_warehouses?.account_id === getUserInfo()?.account_id,
  )?.account_warehouses;

  const AddUser = () => (
    <i
      title="Add Users"
      className="bx bx-user-plus text-primary bx-sm"
      onClick={() => {
        dispatch(setSelectedWareHouse(props.data));
        dispatch(setAssignUserWareHouseModal(true));
      }}
    ></i>
  );

  const Msg = ({ title, text }) => (
    <p>
      {title}
      <br />
      {text}
    </p>
  );

  const HorizontalLine = () => <hr style={{ borderTop: "2px solid #454c61", marginTop: "6px", marginBottom: "6px" }} />;

  const ActionButtons = () => {
    return (
      <div
        className="d-flex align-items-center justify-content-between icons-sizes p-1 cursor-pointer"
        style={{ marginTop: "0rem" }}
      >
        {accountWH && (
          <ConfirmDialogIcon
            icon={{}}
            img={{ src: unlinkIcon, alt: "Unlink from Warehouse", heading: "Unlink from Warehouse" }}
            msg={<Msg title="To Unlink Warehouse" text={"Are you sure you want to unlink from warehouse?"} />}
            onConfirm={() => dispatch(deleteWHAccount(id, allEmails[getUserInfo()?.account_id]))}
          />
        )}
        <i
          title="View Users"
          className="bx bx-group text-primary bx-sm"
          onClick={() => dispatch(setViewWareHouseUsersModal(true, props.data))}
        ></i>
        <AddUser />
        <RenderIf isTrue={![1, 9, 10].includes(id)}>
          <ConfirmDialogIcon
            icon={{ title: "Delete Warehouse", className: "bx bx-trash text-primary bx-sm" }}
            msg={<Msg title="To Delete Warehouse" text={"Are you sure you want to delete this Warehouse?"} />}
            onConfirm={() => dispatch(deleteWarehouse(id))}
          />
        </RenderIf>
      </div>
    );
  };

  const Collapseable = ({ heading, name, className = "", children }) => (
    <>
      <div className={"d-flex " + className}>
        <h6>{heading}</h6>
        <i
          style={{ fontSize: "20px", marginTop: "-6px" }}
          className={`mdi mdi-chevron-${collapseInfoOpen[name] ? "up" : "down"} cursor-pointer ml-2`}
          onClick={(_) => setCollapseInfoOpen({ ...collapseInfoOpen, [name]: !collapseInfoOpen[name] })}
        />
      </div>
      <Collapse isOpen={collapseInfoOpen[name]}>{children}</Collapse>
    </>
  );

  const FeesSettings = (_) => (
    <Collapseable heading="Fees" name="fee" className="mt-1">
      {map(FEE_OPTS, ({ feeCol, dbCol, editCol, title }) => (
        <div key={"__fee_col__" + dbCol} className="d-flex justify-content-between align-items-center">
          <p>{title}</p>
          <div className="d-flex align-items-center">
            <RenderIf isTrue={editWHFee[editCol]} fallback={<p>{formatNumber(props.data[dbCol], "float")} </p>}>
              <Input
                style={{ maxWidth: "85px" }}
                name={dbCol}
                type="number"
                autoFocus="autoFocus"
                value={fee[feeCol]}
                onKeyPress={(e) => {
                  if (e.key === "-") e.preventDefault();
                }}
                onChange={(e) => {
                  if (e.target.value && +e.target.value < 0) return e.preventDefault();
                  setFee({ ...fee, [feeCol]: +e.target.value });
                }}
              />
              <div className="d-flex flex-column">
                <i
                  className="text-success bx bx-sm bx-check cursor-pointer"
                  onClick={() => {
                    setEditWHFee({ ...editWHFee, [editCol]: !editWHFee[editCol] });
                    fee[feeCol] !== props.data[dbCol] &&
                      dispatch(updateWarehouse({ data: { [dbCol]: fee[feeCol] }, id }));
                  }}
                />
                <i
                  className="text-danger bx bx-sm bx-x cursor-pointer"
                  onClick={() => setEditWHFee({ ...editWHFee, [editCol]: !editWHFee[editCol] })}
                />
              </div>
            </RenderIf>

            <RenderIf isTrue={!editWHFee[editCol]}>
              <i
                title="Edit"
                className={`bx bx-sm bx-edit ${editWHFee[editCol] ? "" : "mb-3"} ml-1 text-primary cursor-pointer`}
                onClick={() => {
                  setEditWHFee({ [editCol]: !editWHFee[editCol] });
                  setFee({ ...fee, [feeCol]: props.data[dbCol] });
                }}
              />
            </RenderIf>
          </div>
        </div>
      ))}
    </Collapseable>
  );
  const WHSettings = (_) => (
    <Collapseable heading="Settings" name="settings" className="mt-1">
      <div className="d-flex justify-content-between mt-1">
        <p>Enable Warehouse</p>
        <Lazy
          path="./CheckBox"
          name="is_enabled"
          className="mb-3"
          label=""
          state={isEnabled}
          isSwitch={true}
          setState={(e) => {
            setConfirmationDialogue({
              e,
              msg: isEnabled ? "to disable warehouse? " : "to enable warehouse",
              type: "whStatus",
            });
            setIsOpen(true);
          }}
        />
      </div>
      <RenderIf isTrue={accountWH}>
        <div className="d-flex justify-content-between mt-1">
          <span className="d-flex">
            <p>Enable Association</p>&nbsp;
            <i id={`tooltip-association_${id}`} className="bx bx-info-circle pt-1 text-primary"></i>
            <Lazy
              path="./CustomTooltip"
              placement="top"
              content="Whether you want to use this warehouse for your orders or not"
              target={`tooltip-association_${id}`}
            />
          </span>
          <Lazy
            path="./CheckBox"
            name="enable_association"
            className="mb-3"
            label=""
            state={accountWH?.enabled}
            isSwitch={true}
            setState={(e) => {
              setConfirmationDialogue({
                e,
                msg: accountWH?.enabled ? "to disable association? " : "to enable association",
                type: "whAssociation",
              });
              setIsOpen(true);
            }}
          />
        </div>
      </RenderIf>
      <div className="d-flex justify-content-between mt-1">
        <p>Private</p>
        <Lazy
          path="./CheckBox"
          name="is_private"
          label=""
          state={isPrivate}
          isSwitch={true}
          setState={(e) => {
            setConfirmationDialogue({
              e,
              msg: `to make this warehouse ${e ? "Private" : "Public"}`,
              type: "whPrivate",
            });
            setIsOpen(true);
          }}
        />
      </div>
    </Collapseable>
  );

  const WHDetails = (_) => (
    <Collapseable heading="Details" name="detail" className="mt-1">
      <RenderIf isTrue={isAdmin && allEmails[creatorId]}>
        <div className="d-flex justify-content-between">
          <p>Creator Email</p>
          <p className="email-wrap">{allEmails[creatorId]}</p>
        </div>
      </RenderIf>
      <div className="d-flex justify-content-between mt-1">
        <p>State</p>
        <h6>
          <span id={stateKey}>{state}</span>
          {stateName ? <Lazy path="./CustomTooltip" target={stateKey} content={stateName} /> : null}
        </h6>
      </div>
      <div className="d-flex justify-content-between mt-1">
        <p>Zipcode</p>
        <h6>{zipcode}</h6>
      </div>
    </Collapseable>
  );

  const AgencySettings = () => (
    <>
      <div className="d-flex justify-content-between">
        <h6>Agency</h6>
        <u
          className="cursor-pointer text-primary"
          onClick={() => dispatch(setViewAgencyWarehouseModal(true, props.data))}
        >
          View Assigned Agencies
        </u>
      </div>
      <FormGroup className="select2-container my-3">
        <Select
          name="agency"
          value={{ label: agency.label || "Assign Agency...", value: agency.value || "" }}
          onChange={(option) => {
            dispatch(assignAgencyToWarehouse({ wh_id: props.data.id, agency_id: option.value }));
          }}
          options={props.allTenants?.reduce((acc, option) => {
            if ((!option.agency_id || option.is_agency) && !Object.values(allEmails).includes(option.email)) {
              acc.push({ label: option.email, value: [option.id, option.email] });
            }
            return acc;
          }, [])}
          classNamePrefix="select2-selection"
        />
      </FormGroup>
    </>
  );

  return (
    <React.Fragment>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          style={{ minWidth: "400px" }}
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            const { e, type } = confirmationDialogue;
            if (type === "whStatus") {
              if (state || props.isAnyWhApplicable(id)) {
                dispatch(updateWarehouse({ data: { is_enabled: e }, id }));
              } else dispatch(apiError("Atleast One Warehouse should be enabled"));
            }
            if (type === "whAssociation") dispatch(updateWHAccount(id, { enabled: e }));
            if (type === "whPrivate") dispatch(updateWarehouse({ data: { is_private: e }, id }));
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {confirmationDialogue.msg}
        </SweetAlert>
      </RenderIf>
      <Col lg="4">
        <Card>
          <CardHeader>
            <div style={{ height: "10rem" }}>
              <div className="p-2 d-flex align-items-center justify-content-between">
                <h5
                  onClick={() => {
                    props.setCollapse(props.collapse === id ? "" : id);
                  }}
                  className="mb-0 cursor-pointer"
                >
                  {getWarehouseName(props.data)}
                </h5>
                {isAgency && (
                  <span className="d-flex align-items-center ml-2">
                    <Badge className={"font-size-14 badge-primary"} color="primary" pill>
                      Agency
                    </Badge>
                  </span>
                )}

                <div className="d-flex align-items-end">
                  <RenderIf isTrue={isWHOwnByCreator && !isAdmin}>
                    <AddUser />
                  </RenderIf>
                  <i
                    className="bx bx-expand bx-sm pl-3 text-primary cursor-pointer"
                    onClick={() => props.setCollapse(props.collapse === id ? "" : id)}
                  ></i>
                </div>
              </div>
              <RenderIf isTrue={isAdmin}>
                <div className="p-2 d-flex" style={{ marginTop: "-18px" }}>
                  {compact(names[creatorId]).length > 0
                    ? (
                    <>
                      <span className="card-text">Created By</span>
                      <span className="inventory-color pl-1">{` ${names[creatorId].join(" ")}`}</span>
                    </>
                      )
                    : (
                    <span>&nbsp;</span>
                      )}
                </div>
              </RenderIf>
              <div className="p-2 d-flex inventory-color" style={{ marginTop: "-5px" }}>
                {getWHAddress(props.data, stateName, true)}
                <div className="d-flex flex-column" style={{ marginTop: "-3px" }}>
                  <i className="bx dripicons-location bx-xs pl-3 text-primary" />
                  <Lazy
                    className="pl-3 pt-2"
                    path="./CopyToClipBoard"
                    text={getWHAddress(props.data, stateName, false)}
                  />
                </div>
              </div>
            </div>
            <RenderIf isTrue={isAdmin}>
              <HorizontalLine />
              <div className="p-2 d-flex">
                {map(stats, (count, status) => (
                  <Col key={`__${status}__${id}`} className="p-0">
                    <div className="card-text">{startCase(status)}</div>
                    <h6 className="pt-1">{count}</h6>
                  </Col>
                ))}
              </div>
            </RenderIf>
            <Collapse isOpen={props.collapse === id}>
              <HorizontalLine />
              <Card>
                <CardHeader className="p-1 box-shadow-none">
                  <WHDetails heading="Details" name="detail" />
                  <RenderIf isTrue={isAdmin}>
                    <FeesSettings heading="Fees" name="fee" className="mt-1" />
                    <WHSettings heading="Settings" name="settings" className="mt-1" />
                    <AgencySettings />
                  </RenderIf>
                </CardHeader>
              </Card>
            </Collapse>
            <RenderIf isTrue={keys(stats).length}>
              <HorizontalLine />
            </RenderIf>
            <RenderIf isTrue={isAdmin}>
              <ActionButtons />
            </RenderIf>
          </CardHeader>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default WareHouseCard;
