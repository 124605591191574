import * as types from "./actionTypes";

export const fetchWarehouseInsightChargesStats = (params) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS,
    payload: { params },
  };
};

export const fetchWarehouseInsightChargesStatsSuccess = (data) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchWarehouseInsightInboundStats = (params) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS,
    payload: { params },
  };
};

export const fetchWarehouseInsightInboundStatsSuccess = (data) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchWarehouseInsightStorageStats = (params) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS,
    payload: { params },
  };
};

export const fetchWarehouseInsightStorageStatsSuccess = (data) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchWarehouseInsightOutboundStats = (params) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS,
    payload: { params },
  };
};

export const fetchWarehouseInsightOutboundStatsSuccess = (data) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS_SUCCESS,
    payload: data,
  };
};

export const fetchWarehouseInsightLabelGeneratedGraph = (params) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH,
    payload: { params },
  };
};

export const fetchWarehouseInsightLabelGeneratedGraphSuccess = (data) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH_SUCCESS,
    payload: data,
  };
};

export const fetchWarehouseInsightLabelCancelledGraph = (params) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH,
    payload: { params },
  };
};

export const fetchWarehouseInsightLabelCancelledGraphSuccess = (data) => {
  return {
    type: types.FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH_SUCCESS,
    payload: data,
  };
};

export const apiError = (error) => ({ type: types.API_ERROR, payload: error });
