import React from "react";
import { Col, Label, Input } from "reactstrap";
import renderErrors from "./RenderErrors";

const OrderDetailReportForm = ({ formik }) => {
  return (
    <Col className="my-1">
      <Label>Marketplace Order Id {renderErrors(formik, ["storeOrderId"])}</Label>
      <Input
        name="storeOrderId"
        value={formik.values.storeOrderId}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />
    </Col>
  );
};

export default OrderDetailReportForm;
