import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Button, Input, Row } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import "flatpickr/dist/themes/dark.css";

// actions
import {
  moveToInventory,
  resetErrorSuccessStates,
  resolveStrandedItem,
  setAddBinNoModal,
  updateInventoryItemBins,
} from "../../../store/actions";
import { pick, sumBy, uniq } from "lodash";
import { WH_NOTICES_TYPES } from "../../../constants/index";
import ReactTable from "../../../components/Common/Table";

const AddBinNoModal = (props) => {
  const dispatch = useDispatch();
  const { selectedItem, type, action, filters } = props;
  const timeOut = useRef(false);
  const binNoPair = { id: null, inv_loc_id: null, qty: 1, bin_no: "", error: {} };
  const qty = selectedItem?.qty || selectedItem?.item_details?.Qty || selectedItem?.quantity;
  const isEdit = action === "edit";
  let initialBins = [{ ...binNoPair, qty }];
  if (isEdit) {
    initialBins = selectedItem.InventoryLocation.map((x) => ({
      id: x?.id || null,
      inv_loc_id: x?.inventory_locations?.id || null,
      qty: x?.inventory_locations?.qty || 1,
      bin_no: x?.bin_no || "",
      error: {},
    }));
  }
  const [bins, setBins] = useState(initialBins);
  const [deletedBinIds, setDeletedBinIds] = useState([]);
  const [modalErr, setModalErr] = useState("");
  const { success, error, binNoModalIsOpen } = useSelector((state) => state.WareHouses);
  const { success: returnsSuccess, error: returnsError } = useSelector((state) => state.Returns);
  const handleAddBin = () => setBins([...bins, binNoPair]);

  const handleRemoveVariation = (idx) => {
    bins.splice(idx, 1);
    setBins([...bins]);
  };

  const toggleModal = () => dispatch(setAddBinNoModal(!binNoModalIsOpen));

  const func = {
    [WH_NOTICES_TYPES.tracking_items]: resolveStrandedItem,
    [WH_NOTICES_TYPES.returns]: moveToInventory,
    inventory: updateInventoryItemBins,
  };

  const onSubmit = () => {
    dispatch(resetErrorSuccessStates());
    const binNos = bins.filter((x) => x.bin_no);
    if (!binNos.length) return setModalErr("Bin List is empty!");

    if (sumBy(binNos, "qty") > qty)
      return setModalErr("Qty should be less than or equal to the total quantity of item");
    dispatch(
      func[type]({
        id: selectedItem.id,
        data: {
          bin_no: binNos.map((x) => pick(x, "id", "qty", "bin_no")),
          delete_ids: deletedBinIds,
        },
        filters,
      }),
    );
  };

  useEffect(() => {
    setModalErr("");
    dispatch(resetErrorSuccessStates());
  }, []);

  useEffect(() => {
    timeOut.current = setTimeout(() => modalErr && setModalErr(""), 5000);
    return () => timeOut.current && clearTimeout(timeOut.current);
  }, [modalErr]);

  return (
    <React.Fragment>
      <Modal size="md" isOpen={binNoModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">{`${isEdit ? "Edit" : "Add"} Bins #`}</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        {(error || modalErr || returnsError) && (
          <div className="auto-hide">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || modalErr || returnsError}
            </Alert>
          </div>
        )}

        {(success || returnsSuccess) && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success || returnsSuccess}
            </Alert>
          </div>
        )}
        <div className="modal-body">
          <h6>{`Total Qty (${qty || 0})`}</h6>
          <ReactTable
            tableHeader={
              <>
                {["Qty", "Bin #", "Action"].map((title, i) => (
                  <th key={"___title__" + i}>{title}</th>
                ))}
              </>
            }
            tableBody={
              <>
                {bins?.map((item, idx) => (
                  <React.Fragment key={"_bin_no_" + idx}>
                    <tr id={"_bin_no_opt_" + idx} key={"_bin_no_opt_" + idx}>
                      <td className="pl-0 pb-0 border-0">
                        <Input
                          type="number"
                          min="1"
                          placeholder="Qty"
                          className="form-control"
                          value={item.qty}
                          onKeyPress={(e) => {
                            if ([".", "-"].some((x) => e.key === x)) return e.preventDefault();
                          }}
                          onChange={(e) => {
                            const bin = bins[idx];
                            let value = e.target.value;
                            if (value.trim() === "") value = "1";
                            if (!/^[0-9]+$/.test(value)) return e.preventDefault();
                            if ([".", "-"].some((x) => value.includes(x))) return e.preventDefault();
                            e.target.value = parseFloat(value, 10) || "1";
                            bin.qty = +e.target.value;
                            setBins([...bins]);
                          }}
                        />
                      </td>
                      <td className="pl-0 pb-0 border-0">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Bin #"
                          value={item?.bin_no}
                          onChange={(e) => {
                            bins[idx].bin_no = e.target.value;
                            setBins([...bins]);
                          }}
                        />
                      </td>
                      <td className="text-align-center pb-0 border-0">
                        <i
                          onClick={(_) => {
                            if (bins.length <= 1) return;
                            if (bins[idx].id)
                              setDeletedBinIds(
                                deletedBinIds.concat([{ id: bins[idx].inv_loc_id, location_id: bins[idx].id }]),
                              );
                            handleRemoveVariation(idx);
                          }}
                          className={`bx bx-sm bx-x-circle danger cursor-pointer ${
                            bins.length > 1 ? "" : "generate-label disabled"
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      {item?.error?.length
                        ? (
                        <td className="p-0 border-0">
                          <small className="text-danger">{uniq(item.error).map((x) => `${x}${" "}`)}</small>
                        </td>
                          )
                        : null}
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <td className="pl-0 pb-0 border-0">
                    <Button onClick={handleAddBin} color="success" className="mt-2">
                      Add Bin
                    </Button>
                  </td>
                </tr>
              </>
            }
          />
        </div>
        <div className="modal-footer">
          <Row className="d-flex justify-content-end mt-3 mr-3">
            <Button
              type="reset"
              color="primary"
              onClick={toggleModal}
              outline
              className="btn btn-outline-primary waves-effect waves-light m-1"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={onSubmit}
              color="primary"
              className="btn btn-primary m-1"
              disabled={!bins.length}
            >
              Save Bins
            </Button>
          </Row>
        </div>

        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default AddBinNoModal;
