import React, { useState } from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import StatusBadge from "../../../components/Common/StatusBadge";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { amountText, RenderIf, toF, isAdmin } from "../../../utils/common";
import * as $ from "lodash";
import LogsModal from "../../Repricer/components/LogsModal";
import { competitionAboveOpts, competitionBelowOpts, competitionMatchOpts, getInitialStates, getMatchedIds, getSelectedFilteredAccounts, getSelectedUniqueKeys, hasBuxBoxOpts, noCompetitionOpts, platFormUniquekeys, REDUCE_LISTED_PRICE, whenBuyBoxSuppressedOpts } from "./common";
import getBrandImageSrc from "../../../utils/brandImages";

const StrategyCard = ({ cardParams, headerTitle = "Basic Information", setOperation, setStrategyOperation, setStrategyId, darkBackground = false, formik, selectedPlatform }) => {
  const dispatch = useDispatch();
  const { listingsFormulas, strategiesStats, strategiesStatsLoading, allRepricerAccounts } = useSelector((state) => state.Repricer);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const statsValues = strategiesStats?.strategy_statistics?.[cardParams?.id];
  const missingCosts = `Missing costs: ${statsValues?.missing_costs ?? 0}`;
  const publishedListings = `Published listings: ${statsValues?.published_listings ?? 0}`;
  const assignedListings = `Assigned listings: ${statsValues?.assigned_listings ?? 0}`;
  const assignedECAccounts = accounts?.data.filter((x) => $.keys(statsValues?.assigned_accounts).includes(x?.[platFormUniquekeys?.[selectedPlatform]?.ec]));
  const assignedRepricerAccount = $.values(statsValues?.assigned_accounts);
  const initialState = getInitialStates(selectedPlatform, accounts);
  const matchedIds = getMatchedIds(allRepricerAccounts, assignedRepricerAccount);
  const selectedUniqueKeys = getSelectedUniqueKeys(matchedIds, selectedPlatform);
  const selectedFilteredAccounts = getSelectedFilteredAccounts(initialState, selectedUniqueKeys);
  const uniqueAccountIds = $.values(initialState).map(item => item.uniqueId);
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const allRepricerAccountsKeys = (allRepricerAccounts || [])?.map((x) => (x?.[platFormUniquekeys?.[selectedPlatform]?.repricer]));
  const matchingIds = allRepricerAccountsKeys?.reduce((acc, value, key) => {
    if (uniqueAccountIds.includes(value)) acc[key] = value;
    return acc;
  }, {});

  const leftItems = [
    {
      label: "Beat by Competitor",
      value: toF(+cardParams.beatByCompetitor),
      style: { marginBottom: "12px" },
    },
    {
      label: "Competition Below",
      value: competitionBelowOpts[cardParams.competitionBelow],
      style: { marginBottom: "12px" },
    },
    {
      label: "Competition Above",
      value: competitionAboveOpts[cardParams.competitionAbove],
      listedprice:
        competitionAboveOpts[cardParams.competitionAbove] === REDUCE_LISTED_PRICE
          ? cardParams?.beat_by_competition_when_gt_listed_price
          : null,
      style: { marginBottom: "12px" },
    },
  ];

  cardParams.excludeSellerRating >= 0 &&
    leftItems.push({
      label: "Exclude Seller Rating",
      value: cardParams.excludeSellerRating,
    });

  const rightItems = [
    {
      label: "If you have the Buybox",
      value: hasBuxBoxOpts[cardParams.haveBuyBox],
      style: { marginBottom: "12px" },
    },
    {
      label: "No Competition",
      value: noCompetitionOpts[cardParams.noCompetition],
      style: { marginBottom: "12px" },
    },
    {
      label: "Match Competition",
      value: competitionMatchOpts[cardParams.matchCompetition],
      style: { marginBottom: "12px" },
    },
  ];

  cardParams.whenBuyBoxIsSuppressed &&
    rightItems.push({
      label: "Buybox Suppressed",
      value: whenBuyBoxSuppressedOpts[cardParams.whenBuyBoxIsSuppressed],
    });

  const tooltipForMinMaxPriceFormula = (key, method) => (
    <RenderIf isTrue={listingsFormulas?.results && cardParams.minMaxType === "FORMULA_BASED"}>
      <CustomTooltip style={{ minWidth: "max-content", textAlign: "left" }} placement="top"
        content={
          <span className="font-weight-medium">
            {listingsFormulas?.results
              ?.find((x) => x.id === key)
              ?.statement?.replace(/[{()}]/g, "") + " / Marketplace Fees"}
          </span>
        }
        target={`${method}-price-method-${cardParams?.id}`} />
    </RenderIf>
  );

  const RenderCardItem = ({ label, value, style, amount }) => (
    <div style={style}>
      {label}:&nbsp;
      <span className="text-primary">
        {value}
        <RenderIf isTrue={amount}>&nbsp;{`(${amountText(amount)})`}</RenderIf>
      </span>
    </div>
  );

  const CardItemsWrapper = ({ items }) => (
    <Col md={6}>
      <div className="card-text" style={{ padding: "10px 16px" }}>
        {$.map(items, (item, ind) => (
          <RenderCardItem key={`${item?.label}--${ind}`} label={item?.label} value={item?.value} style={item?.style} amount={item?.listedprice} />
        ))}
      </div>
    </Col>
  );
  const openLogsModal = () => {
    setIsLogsModalOpen(true);
    setStrategyId(cardParams?.id);
  };

  return (
    <>
      <RenderIf isTrue={isLogsModalOpen}>
        <LogsModal
          isOpen={isLogsModalOpen}
          toggleModal={() => setIsLogsModalOpen(false)}
          modelType="strategy"
          modelId={cardParams.id}
          platform={selectedPlatform}
        />
      </RenderIf>

      <Card className={`${darkBackground ? "card-layout" : ""} resources p-3`} style={{ marginBottom: "24px" }}>
        <CardBody>
          <Breadcrumb
            isCapitalize={true}
            title={cardParams.title}
            children={
              !darkBackground ? (
                <div className="d-flex align-items-baseline">
                  <RenderIf isTrue={isAdmin()}>
                    <i className="cursor-pointer fa fa-history bx-sm text-info mr-2" onClick={openLogsModal} />
                  </RenderIf>
                  <RenderIf isTrue={!($.isEmpty($.keys(matchingIds)) || $.isEmpty($.keys(selectedFilteredAccounts)))}>
                    <CustomTooltip
                      style={{ minWidth: "366px", textAlign: "left" }}
                      placement="top"
                      content={
                        <span className="font-weight-medium">
                          Assign a default strategy to store fronts. Otherwise new products won't be assigned a strategy.
                        </span>
                      }
                      target={`assign-strategy-tooltip${cardParams?.id}`}
                    />
                    <i
                      id={`assign-strategy-tooltip${cardParams?.id}`}
                      className="cursor-pointer bx bx-sm bx-clipboard text-warning"
                      onClick={() => {
                        dispatch(actions.setStrategyModal(true));
                        setOperation(cardParams.title);
                        setStrategyId(cardParams?.id);
                        setStrategyOperation("");
                      }}
                    />
                  </RenderIf>
                  <i
                    className="cursor-pointer bx bx-sm bx-pencil text-primary ml-2"
                    onClick={() => {
                      setStrategyId(cardParams?.id);
                      setStrategyOperation("Update");
                      formik.setValues({
                        ...formik.values,
                        strategyNameField: cardParams.title,
                        matchingTypeAmount: cardParams.beatByCompetitor,
                        beat_by_competition_when_gt_listed_price: cardParams.beat_by_competition_when_gt_listed_price,
                        matchingType: "ALL",
                        noCompetition: cardParams.noCompetition,
                        competitionAbove: cardParams.competitionAbove,
                        competitionBelow: cardParams.competitionBelow,
                        matchCompetition: cardParams.matchCompetition,
                        haveBuyBox: cardParams.haveBuyBox,
                        minMaxType: cardParams.minMaxType,
                        minimumValue: cardParams.minimumValue,
                        maximumValue: cardParams.maximumValue,
                        minimumPercentage: cardParams.minimumPercentage,
                        maximumPercentage: cardParams.maximumPercentage,
                        minimumHybridValue: cardParams.minimumHybridValue,
                        maximumHybridValue: cardParams.maximumHybridValue,
                        minimumHybridPercentage: cardParams.minimumHybridPercentage,
                        maximumHybridPercentage: cardParams.maximumHybridPercentage,
                        minPriceFormula: cardParams.minPriceFormula,
                        maxPriceFormula: cardParams.maxPriceFormula,
                        excludeSellerRating: cardParams.excludeSellerRating,
                        whenBuyBoxIsSuppressed: cardParams.whenBuyBoxIsSuppressed,
                      });
                    }}
                  />
                  <i
                    className="cursor-pointer bx bx-sm bx-trash text-danger ml-2"
                    onClick={() => {
                      dispatch(actions.setRemoveStrategyModal(true));
                      setStrategyId(cardParams?.id);
                      setOperation("Delete Strategy");
                    }}
                  />
                </div>
              ) : (
                <></>
              )
            }
          />
          <Row>
            <Col md={darkBackground ? 12 : 9} className="pr-4">
              <RenderIf isTrue={!$.isEmpty(assignedECAccounts)}>
                <>
                  <div className="strategy-card-header font-size-15 font-weight-semibold inventory-color">
                    Default Strategy For
                  </div>
                  <Row className="font-size-12 font-weight-medium" style={{ marginBottom: "24px" }}>
                    <Col md={12}>
                      <div className="d-flex flex-wrap align-items-center" style={{ padding: "10px 16px", gap: "10px" }}>
                        {assignedECAccounts?.slice(0, 3)?.map((x, ind) => (
                          <div
                            key={ind}
                            style={{
                              border: "1px solid $blue",
                              backgroundColor: "#556EE626",
                              borderRadius: "50px",
                              whiteSpace: "nowrap",
                            }}
                            className="inventory-color p-2 pr-5 align-items-center position-relative"
                          >
                            <RenderIf isTrue={selectedPlatform}>
                              <img
                                className="mx-1"
                                src={getBrandImageSrc(selectedPlatform)}
                                alt={selectedPlatform}
                                style={{ width: "22px" }}
                              />
                            </RenderIf>
                            {x.name}
                            <i
                              style={{
                                fontSize: "18px",
                                borderRadius: "50px",
                                right: "10px",
                                top: "10px",
                                color: "#f46a6a",
                              }}
                              className="bx bx-x-circle cursor-pointer position-absolute"
                              onClick={() => {
                                const idToRemove = (allRepricerAccounts || [])?.find(
                                  (account) =>
                                    account?.[platFormUniquekeys?.[selectedPlatform]?.repricer] ===
                                    x?.[platFormUniquekeys?.[selectedPlatform]?.ec],
                                )?.id;
                                dispatch(actions.removeStrategyAccount(selectedPlatform, idToRemove));
                              }}
                            />
                          </div>
                        ))}
                        <RenderIf isTrue={assignedECAccounts?.length > 3}>
                          <div
                            className="d-flex justify-content-center align-items-center cursor-pointer inventory-color"
                            style={{
                              border: "1px solid #556EE6",
                              backgroundColor: "#556EE6",
                              borderRadius: "50px",
                              whiteSpace: "nowrap",
                              width: "32px",
                              height: "25px",
                            }}
                            onClick={() => {
                              dispatch(actions.setStrategyModal(true));
                              setStrategyId(cardParams?.id);
                              setOperation("Stores Information");
                            }}
                          >
                            +{assignedECAccounts?.length - 3}
                          </div>
                        </RenderIf>
                      </div>
                    </Col>
                  </Row>
                </>
              </RenderIf>
              <div className="strategy-card-header font-size-15 font-weight-semibold inventory-color">{headerTitle}</div>
              <Row className="font-size-12 font-weight-medium" style={{ marginBottom: "24px" }}>
                {$.map([leftItems, rightItems], (items, ind) => (
                  <CardItemsWrapper key={`____${ind}____`} items={items} />
                ))}
              </Row>
              <div className="strategy-card-header font-size-15 font-weight-semibold inventory-color">
                Price Information{" "}
                <RenderIf isTrue={cardParams.minMaxType}>
                  <i style={{ color: "#C3CBE4" }}>({$.startCase(cardParams.minMaxType)})</i>
                </RenderIf>
              </div>
              <Row className="font-size-12 font-weight-medium">
                <Col md={6}>
                  <div style={{ padding: "10px 16px" }}>
                    {tooltipForMinMaxPriceFormula(cardParams.minPriceFormula, "min")}
                    <span className="card-text">
                      Min Price Method: &nbsp;
                      <span className="text-primary">
                        <span
                          id={`min-price-method-${cardParams?.id}`}
                          className={`ml-10 ${cardParams.minMaxType === "FORMULA_BASED" ? "cursor-pointer" : null}`}
                        >
                          {cardParams.minMaxType === "FIXED_VALUE"
                            ? `Base + $${cardParams.minimumValue}`
                            : cardParams.minMaxType === "FIXED_PROFIT"
                              ? `Base + ${cardParams.minimumPercentage}%`
                              : cardParams.minMaxType === "CHOOSE_MAX"
                                ? `MAX( Base + $${cardParams.minimumHybridValue}, Base + ${cardParams.minimumHybridPercentage}% )`
                                : cardParams.minMaxType === "FORMULA_BASED"
                                  ? listingsFormulas?.results?.find((x) => x.id === cardParams.minPriceFormula)?.name
                                  : null}
                        </span>
                      </span>
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div style={{ padding: "10px 16px" }}>
                    {tooltipForMinMaxPriceFormula(cardParams.maxPriceFormula, "max")}
                    <span className="card-text">
                      Max Price Method: &nbsp;
                      <span className="text-primary">
                        <span
                          id={`max-price-method-${cardParams?.id}`}
                          className={`ml-10 ${cardParams.minMaxType === "FORMULA_BASED" ? "cursor-pointer" : null}`}
                        >
                          {cardParams.minMaxType === "FIXED_VALUE"
                            ? `Base + $${cardParams.maximumValue}`
                            : cardParams.minMaxType === "FIXED_PROFIT"
                              ? `Base + ${cardParams.maximumPercentage}%`
                              : cardParams.minMaxType === "CHOOSE_MAX"
                                ? `MAX( Base + $${cardParams.maximumHybridValue}, Base + ${cardParams.maximumHybridPercentage}% )`
                                : cardParams.minMaxType === "FORMULA_BASED"
                                  ? listingsFormulas?.results?.find((x) => x.id === cardParams.maxPriceFormula)?.name
                                  : null}
                        </span>
                      </span>
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <RenderIf isTrue={!darkBackground}>
              <Col md={3} className="strategy-card-divider">
                <div style={{ padding: "10px 0px 10px 16px" }}>
                  {[
                    { isLoading: strategiesStatsLoading, status: missingCosts, color: "danger", bgColor: "rgba(244, 106, 106, 0.05)" },
                    { isLoading: strategiesStatsLoading, status: publishedListings, color: "warning", bgColor: "rgba(255, 229, 0, 0.05)" },
                    { isLoading: strategiesStatsLoading, status: assignedListings, color: "success", bgColor: "rgba(52, 195, 143, 0.05)" },
                  ].map(({ isLoading, status, color, bgColor }, key) => (
                    <div key={"___status__" + key} style={{ marginBottom: "16px" }}>
                      {isLoading ? (
                        <div
                          className="font-size-14 d-flex justify-content-center align-items-center"
                          style={{
                            width: "100%",
                            height: "38px",
                            textAlign: "left",
                            padding: "6px 16px",
                            backgroundColor: bgColor,
                          }}
                        >
                          <Spinner size="sm" />
                        </div>
                      ) : (
                        <StatusBadge
                          className="font-size-14"
                          key={"___status__" + key}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            padding: "6px 16px",
                            marginBottom: "16px",
                            backgroundColor: bgColor,
                          }}
                          status={status}
                          colorsMapping={{ [status]: color }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Col>
            </RenderIf>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default StrategyCard;
