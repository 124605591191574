import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Row, Col, Input, Label } from "reactstrap";
import Modal from "../../../../components/Common/Modal";

import { omit } from "lodash";

// Actions
import { setAdjustmentModal, createPurchaseOrder, setPreloader } from "../../../../store/actions";

const AdjustmentModal = ({ PODetail, itemsDetail, pageSize }) => {
  const dispatch = useDispatch();
  const { adjustmentModalIsOpen } = useSelector((state) => state.WareHouses);

  const toggleAdjustmentModal = () => dispatch(setAdjustmentModal(!adjustmentModalIsOpen));

  const submitForm = () => {
    const lineItemsBuffer = itemsDetail;
    lineItemsBuffer.forEach((x, i) => delete lineItemsBuffer[i].error);
    PODetail.line_items = lineItemsBuffer;
    dispatch(createPurchaseOrder(PODetail?.warehouse_id, omit(PODetail, ["warehouse_id", "error"]), pageSize));
    dispatch(setPreloader(true));
  };

  return (
    <>
      <Modal size="lg" isOpen={adjustmentModalIsOpen} toggle={toggleAdjustmentModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Adjustments
          </h5>
          <button
            onClick={toggleAdjustmentModal}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label>Total Order Qty</Label>
                <Input
                  type="text"
                  className="inner form-control"
                  placeholder="Total Shipped"
                  value={PODetail?.total_quantity}
                  disabled
                />
              </div>
              <div className="mb-3">
                <Label>Total Shipped</Label>
                <Input
                  type="text"
                  className="inner form-control"
                  placeholder="Total Order"
                  value={PODetail?.total_shipping}
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label>Total Cost</Label>
                <Input
                  type="text"
                  className="inner form-control"
                  placeholder="Total Cost"
                  value={PODetail?.total_cost}
                  disabled
                />
              </div>
              <div className="mb-3">
                <Label>Total Adjustment</Label>
                <Input type="text" className="inner form-control" placeholder="Total Received" value={0} disabled />
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Row className="d-flex justify-content-end mt-3">
            <button
              type="reset"
              color="secondary"
              className="waves-effect waves-light btn btn-outline-primary mr-3"
              onClick={toggleAdjustmentModal}
            >
              Cancel
            </button>
            <Button onClick={() => submitForm()} color="primary" className="mr-3">
              Submit
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AdjustmentModal;
