import React from "react";
import ReactApexChart from "react-apexcharts";
import "react-datepicker/dist/react-datepicker.css";
import { startCase } from "lodash";
import { RenderIf } from "../../../utils/common";
const AOReasonsGraph = (props) => {
  const data = props?.aoGraph?.data?.reduce(
    (acc, val) => {
      acc.reasons.push(startCase(val[0])); // reasons
      acc.count.push(val[1]); // count
      return acc;
    },
    { count: [], reasons: [] },
  );
  const series = data?.count;
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: data?.reasons,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <RenderIf isTrue={series?.length > 0}>
      <h4 className="card-title w-100 p-3">Timeseries</h4>
      <ReactApexChart options={options} series={series} type="pie" width={900} />
    </RenderIf>
  );
};

export default AOReasonsGraph;
