import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Alert, Label, Input } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";

// actions
import { updatePaymentSourceSettings, setEditClientModal } from "../../../store/actions";
const { inRange } = require("lodash");

const EditClientModal = (props) => {
  const dispatch = useDispatch();
  const { editClientModalIsOpen, selectedClient, error } = useSelector((state) => state.Agency);

  const toggleModal = () => dispatch(setEditClientModal(!editClientModalIsOpen));

  const validate = (values) => {
    const errors = {};
    !values.agency_profit && values.agency_profit !== 0 && (errors.agency_profit = "Field is Required");
    values.agency_profit &&
      !inRange(values.agency_profit, 0, 100) &&
      (errors.agency_profit = "Value must be between 0 and 100");
    return errors;
  };

  const formik = useFormik({
    initialValues: { agency_profit: selectedClient?.agency_profit || "" },
    validate,
    onChange: (name, value, { props }) => props.handleFormChange(name, value),
    onSubmit: (values) => dispatch(updatePaymentSourceSettings({ data: values, id: selectedClient.id })),
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={editClientModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Edit Client Settings</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mb-2">
                <Label>{"Agency Profit(%)"}</Label>
                <Input
                  name="agency_profit"
                  value={formik.values.agency_profit}
                  placeholder="Agency Profit(1 - 100)"
                  type="number"
                  min={0}
                  max={100}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                />
                {formik.touched.agency_profit && formik.errors.agency_profit
                  ? (
                  <span className="text-danger mt-1">{formik.errors.agency_profit}</span>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default EditClientModal;
