import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Label, FormGroup, Alert, Input } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";

// actions
import { setAddBalanceModal, updateAccount } from "../../../store/actions";

const AddBalanceModal = (props) => {
  const dispatch = useDispatch();
  const { error, addBalanceModalIsOpen } = useSelector((state) => state.User);

  const toggleModal = () => {
    dispatch(setAddBalanceModal(!addBalanceModalIsOpen));
    formik.resetForm();
  };

  const validate = (values) => {
    const errors = {};
    Object.keys(values || {}).forEach((key) => {
      if (values[key] === "") errors[key] = "Field is Required";
    });
    return errors;
  };

  const formik = useFormik({
    initialValues: { alt_payment: props.account.alt_payment || "" },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const isChanged = Object.entries(values || {}).some(([key, value]) => value !== props.account?.[key]);
      isChanged ? dispatch(updateAccount({ data: values, id: props.account.id })) : toggleModal();
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={addBalanceModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Alternative Payment</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mt-2">
                <FormGroup className="select2-container mb-0">
                  <Label>Balance</Label>
                  <Input
                    name="alt_payment"
                    type="number"
                    min={1}
                    value={formik.values.alt_payment}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
                {formik.touched.alt_payment && formik.errors.alt_payment
                  ? (
                  <small className="text-danger">{formik.errors.alt_payment}</small>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Update
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default AddBalanceModal;
