import {
  UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT_SUCCESS,
  UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT,
  API_ERROR,
  SELECT_FILE_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
};

const ShipmentReport = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT_SUCCESS:
      state = {
        ...state,
        success: `${action.payload} Records has been updated successfully`,
        error: "",
        loading: false,
      };
      break;
    case SELECT_FILE_ERROR:
      state = { ...state, error: "You have to select atleast one CSV file" };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ShipmentReport;
