/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { connect, useDispatch, useSelector } from "react-redux";
import SupplierModal from "./SupplierModal";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PillBtn from "../../../components/Common/PillBtn";
import ChangeWareHouseModal from "./ChangeWareHouseModal";
import CreatableSelect from "react-select/creatable";
import { SOURCE_ORDER_STATUSES, EMAIL_REGEX } from "../../../constants/index";
import EditCreditCardsModal from "../../CreditCards/Components/EditCreditCardModal";
import { withRouter } from "react-router-dom";
import { Button, FormGroup, Row, Col, Alert, Label, Card, CardBody } from "reactstrap";
// actions
import {
  createSupplierOrders,
  fetchCreditCardDetails,
  fetchAllWarehouses,
  refreshSourceOrderDetail,
  resetSupplierOrderState,
  setChangedWareHouse,
  setChangeWareHouseModal,
  fetchSuppliers,
  resetSupplierState,
  createSupplier,
  fetchBuyingEmails,
  updateSourceOrder,
  setAddCreditCardsModal,
  manualFulfillmentCollapseIsOpen,
} from "../../../store/actions";
import { sortBy as sortArrayByKey, merge, uniqBy, cloneDeep, isEmpty, compact } from "lodash";
import { decode } from "../../../utils/common";

const ProcessingItemModal = (props) => {
  const dispatch = useDispatch();
  const initialValues = {
    qty: "0",
    status: SOURCE_ORDER_STATUSES.processed,
    shipping_cost: "0",
    tax: "0",
    promotions: "0",
  };

  const [isLoadingNewEmail, setIsLoadingNewEmail] = useState(false);
  const [emailOpts, setEmailOpts] = useState([]);
  const [formData, setFormData] = useState(initialValues);
  const [supplierModalIsOpen, setSupplierModalIsOpen] = useState(false);
  const [supplier, setSupplier] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState(undefined);
  const [productUrl, setProductUrlField] = useState({});
  const [nextOrder, setNextOrder] = useState(false);
  const { allAccounts: accounts } = useSelector((state) => state.Credential);
  const { wareHouses, associatedWarehouses } = useSelector((state) => state.WareHouses);
  const { suppliers } = useSelector((state) => state.Supplier);
  const sourceItems = uniqBy(props.sourceItems?.data.source_items, "sku");
  const [supplierDropDown, setSupplierDropDown] = useState({
    value: "",
    label: "Select a Supplier",
  });
  const [itemForProcessing, setItemsForProcessing] = useState({
    value: "",
    label: "Select Item",
  });
  const [creditCard, setCreditCard] = useState({
    value: "",
    label: "Select Credit Card",
  });
  const [customErrors, setCustomErrors] = useState({});

  const filteredSuppliers = () => {
    const otherSupplier = props.Supplier?.platforms?.find((x) => x.title === "Other");
    let suppliers = props.Supplier?.platforms.filter((x) => x.title !== "Other").sort();
    return (suppliers = compact([...suppliers, otherSupplier]));
  };

  const reset = () => {
    setFormData({ ...initialValues, qty: 1 });
    setSupplier({});
    props.resetSupplierOrderState();
    props.setChangedWareHouse(null);
  };

  const handleAddSupplier = () => {
    props.resetSupplierState();
    setSelectedSupplier(undefined);
    setSupplierModalIsOpen(true);
  };

  const handleEditSupplier = (item) => {
    props.resetSupplierState();
    setSelectedSupplier(item);
    setSupplierModalIsOpen(true);
  };

  const handleChangeWareHouse = () => {
    props.setChangeWareHouseModal(!props.SourceOrders.changeWareHouseModalIsOpen);
  };

  const handeSupplierChange = (supplier) => {
    setSupplier(supplier);
    setFormData({
      ...formData,
      qty: (itemForProcessing.value?.qty || 0) * (supplier.quantity_multiplier || formData.qty_multiplier || 0),
      cost: supplier.price_lock ? supplier.manual_price : supplier.price || "0",
      tax: supplier.tax || "0",
      shipping_cost: supplier.shipping_fee_lock ? supplier.manual_shipping_fee : supplier.shipping_fee || "0",
      supplier: supplier.name,
      sku: itemForProcessing?.label,
      qty_multiplier: supplier.quantity_multiplier || formData.qty_multiplier,
    });
  };

  const handleAddCreditCard = () => {
    props.setAddCreditCardsModal(true);
  };

  const handleFormSubmit = () => {
    Object.keys(formData).forEach((val) => formData[val] === "" && delete formData[val]);

    const error = {};

    if (!formData.purchasing_account_name) error.email = "Email is Required";
    else error.email = "";

    if (!formData.supplier) error.supplier = "Supplier is Required";
    else error.supplier = "";
    setCustomErrors(error);

    if (Object.keys(error).every((x) => !error[x])) {
      const { changedWareHouseId, sourceOrderDetail } = props.SourceOrders;
      props.createSupplierOrders({
        items: [
          merge(
            {},
            formData,
            props.isWareHouseOrder() && { warehouse_id: changedWareHouseId || sourceOrderDetail?.data.wh_address?.id },
            {
              sku: itemForProcessing.label,
              source_order_id: props.SourceOrders.sourceOrderDetail.data?.id,
              supplier: supplier.name,
              qty_multiplier: formData.qty_multiplier || 1,
              order_total: calculateOrderTotal(formData),
            },
          ),
        ],
        history: props.history,
        go_next: nextOrder,
      });
    }
    if (productUrl.platform_id) {
      props.createSupplier({
        data: {
          sku: itemForProcessing.label,
          platform: formData.supplier,
          url: productUrl.url,
          platform_id: productUrl.platform_id,
          name: formData.supplier,
          quantity_multiplier: formData.qty_multiplier,
        },
        params: {
          platform: props.SourceOrders.sourceOrderDetail.data.store_name,
          sku: supplier?.sku,
        },
      });
      dispatch(manualFulfillmentCollapseIsOpen(!props.manualFulfillmentCollapse));
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      qty: props.order?.reduce((acc, item) => (acc += item.qty), 0),
    });
    props.fetchCreditCardDetails({ pagination: false });

    !accounts?.length && dispatch(fetchBuyingEmails());
    if (props.isWareHouseOrder() && !wareHouses.data.length) dispatch(fetchAllWarehouses());
    const sourceItems = props.SourceOrders.sourceOrderDetail?.data?.source_items;
    setItemsForProcessing({ label: sourceItems?.[0]?.sku, value: sourceItems?.[0] });
  }, []);

  useEffect(() => {
    itemForProcessing.value && props.setItemsForProcessing([itemForProcessing.value]);
    itemForProcessing.value &&
      isEmpty(supplier) &&
      props.fetchSuppliers({
        platform: props.SourceOrders.sourceOrderDetail?.data.store_name,
        sku: itemForProcessing.value?.sku,
        partner_id: props.storeId,
        item_id: itemForProcessing.value?.asin,
      });
  }, [itemForProcessing]);

  useEffect(() => {
    itemForProcessing.value &&
      isEmpty(supplier) &&
      suppliers?.length &&
      handeSupplierChange(suppliers?.find((s) => s.is_default) || suppliers[0]);
  }, [suppliers]);

  useEffect(() => {
    if (!props.SupplierOrders.error && props.SupplierOrders.success && !props.SupplierOrders.loading) {
      props.refreshSourceOrderDetail();
      reset();
    }
  }, [props.SupplierOrders.error, props.SupplierOrders.success]);

  useEffect(() => {
    if (accounts?.length)
      setEmailOpts(
        uniqBy(
          sortArrayByKey(
            accounts?.map((acc, i) => ({
              label: acc.email,
              value: acc.id,
              site: acc.site,
              key: i,
            })) || [],
            "label",
          ),
          "label",
        ),
      );
  }, [accounts]);

  const calculateOrderTotal = ({ cost, qty, tax, shipping_cost: shippingCost, promotions }) =>
    (parseFloat(cost) * parseFloat(qty) + parseFloat(tax) + parseFloat(shippingCost) - parseFloat(promotions))?.toFixed(
      2,
    );

  const handleAddNewEmail = (newEmail) => {
    setCustomErrors({ ...customErrors, email: `${!EMAIL_REGEX.test(newEmail) ? "Wrong format of email" : ""}` });
    if (EMAIL_REGEX.test(newEmail)) {
      setIsLoadingNewEmail(true);
      setTimeout(() => {
        setIsLoadingNewEmail(false);
        const newOpt = { label: newEmail, value: newEmail };
        emailOpts.push(newOpt);
        setEmailOpts(emailOpts);
        setFormData({
          ...formData,
          purchasing_account_name: newOpt.label,
        });
      }, 1000);
    }
  };

  const supplierRegexMapping = {
    amazon: "^(?:[0-9]+-?[0-9]+)+$",
    walmart: "^(?:[0-9]+-?[0-9]+)+$",
    topdawg: "^[0-9]+$",
    vitacost: "^[0-9]+$",
    bestbuy: "^[A-Z0-9\-]+$",
    costco: "^[0-9]+$",
    dsw: "^(?:[0-9]+-?[0-9]+)+$",
    ebay: "^(?:[0-9]+-?[0-9]+)+$",
    homedepot: "^[A-Z0-9]+$",
    lowes: "^[0-9]+$",
    luckyvitamin: "^[A-Z0-9]+$",
    overstock: "^[0-9]+$",
    samsclub: "^[0-9]+$",
    target: "^[0-9]+$",
    wayfair: "^[0-9]+$",
    zappos: "^(?:[0-9]+-?[0-9]+)+$",
    zoro: "^(?:[A-Z0-9]+-?[A-Z0-9]+)+$",
    farmandfleet: "^(?:[0-9]+-?[0-9]+)+$",
    fleetfarm: "^[A-Z0-9]+$",
    ifulfillgridiron: "^(?:[0-9]+-?[0-9]+)+$",
    ifulfillhtls: "^[0-9]+$",
    boscovs: "^[0-9]+$",
    walgreens: "^(?:[0-9]+-?[0-9]+)+$",
    menards: "^[0-9]+$",
    costway: "^[0-9]+$",
  };
  const validateSupplierOrderId = () => {
    return {
      required: { value: true },
      pattern: {
        value:
          supplierRegexMapping[formData?.supplier?.toLowerCase()?.replace(/[^A-Z0-9]/gi, "")] ||
          "^(?:[A-Z0-9]+-?[A-Z0-9]+)+$",
        errorMessage: "Supplier ID is not valid",
      },
    };
  };

  return (
    <React.Fragment>
      {props.CreditCards.addCreditCardModalIsOpen && <EditCreditCardsModal type="add" />}
      <SupplierModal
        sourceItem={props.order}
        setFormData={setFormData}
        formData={formData}
        setSupplier={setSupplier}
        supplier={cloneDeep(selectedSupplier)}
        isOpen={supplierModalIsOpen}
        storeId={props.storeId}
        toggleIsOpen={setSupplierModalIsOpen}
        setSelectedSupplier={setSelectedSupplier}
        type={selectedSupplier ? "edit" : "create"}
      />
      {props.SourceOrders.changeWareHouseModalIsOpen && <ChangeWareHouseModal />}

      <Card>
        <CardBody>
          <div>
            {props.SourceOrders.sourceOrderDetail?.data.wh_address &&
              wareHouses.data?.length > 1 &&
              associatedWarehouses?.data.length > 1 && (
                <div className="d-flex justify-content-end">
                  <PillBtn
                    color="warning"
                    title="Change WareHouse Address"
                    name="Change WareHouse Address"
                    icon="bx bx-xs bx-edit"
                    className="mr-1 mb-2 d-flex justify-content-end"
                    onClick={handleChangeWareHouse}
                  />
                </div>
            )}
            <Card>
              <CardBody>
                <Row className="border-bottom">
                  <Col lg={6} className="border-right">
                    <AvForm id="myAvForm" onValidSubmit={handleFormSubmit}>
                      <Row className="m-1">
                        {props.SupplierOrders.error && (
                          <Col lg="12">
                            <Alert color="danger mt-1">
                              <i className="bx bx-info-circle pr-2"></i>
                              {props.SupplierOrders.error}
                            </Alert>
                          </Col>
                        )}
                        <Col lg="6">
                          <Label>Select Item For Processing</Label>
                          <FormGroup className="select2-container">
                            {props.SourceOrders.sourceOrderDetail?.data?.source_items?.length === 1 ? (
                              itemForProcessing.label
                            ) : (
                              <Select
                                id="itemForProcessing"
                                name="itemForProcessing"
                                value={{
                                  value: itemForProcessing.value,
                                  label: itemForProcessing.label,
                                }}
                                onChange={(e) => {
                                  setSupplier({});
                                  setItemsForProcessing({ label: e.label, value: e.value });
                                }}
                                options={sourceItems.map((item, i) => ({
                                  label: item.sku,
                                  value: item,
                                  key: i,
                                }))}
                                classNamePrefix="select2-selection"
                              />
                            )}
                          </FormGroup>
                        </Col>
                        {props.Supplier?.suppliers?.length ? (
                          <Col lg="6">
                            <Label>Supplier*</Label>
                            <FormGroup className="select2-container">
                              <Select
                                name="supplier"
                                value={{
                                  value: supplierDropDown.value,
                                  label: formData.supplier || supplierDropDown.label,
                                }}
                                onChange={(e) => {
                                  const supplierOpt = props.Supplier?.suppliers?.find(
                                    (supplier) => supplier.name === e.value,
                                  );
                                  if (supplierOpt) {
                                    handeSupplierChange(supplierOpt);
                                    setProductUrlField({ display: false });
                                  } else {
                                    setProductUrlField({
                                      url: "",
                                      platform_id: e.data_id,
                                      display: true,
                                    });
                                    handeSupplierChange({ name: e.value });
                                  }
                                  setSupplierDropDown({ value: e.value, label: e.label });
                                  delete customErrors.supplier;
                                }}
                                options={filteredSuppliers().map((platform, i) => ({
                                  label: platform?.title,
                                  value: platform?.title,
                                  data_id: platform?.id,
                                  key: i,
                                }))}
                                classNamePrefix="select2-selection"
                              />
                              {customErrors.supplier && <small className="text-danger">{customErrors.supplier}</small>}
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col lg="6">
                            <AvField
                              name="supplier"
                              label="Supplier*"
                              placeholder="Supplier name e.g amazon, walmart"
                              type="text"
                              errorMessage="Field is required"
                              validate={{ required: { value: true } }}
                              value={formData.supplier || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  supplier: e.target.value,
                                })
                              }
                            />
                          </Col>
                        )}
                        {productUrl.display && (
                          <Col lg="6">
                            <AvField
                              name="url"
                              label="Product Url*"
                              placeholder="Product Url"
                              type="text"
                              errorMessage="Field is required"
                              validate={{ required: { value: true } }}
                              value={productUrl.url || ""}
                              onChange={(e) =>
                                setProductUrlField({
                                  ...productUrl,
                                  url: e.target.value,
                                })
                              }
                            />
                          </Col>
                        )}

                        {productUrl.display && (
                          <Col lg="6">
                            <AvField
                              name="qty_multiplier"
                              label="Qty Multiplier*"
                              placeholder="Product Url"
                              type="text"
                              errorMessage="Field is required"
                              validate={{ required: { value: true } }}
                              value={formData.qty_multiplier || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  qty_multiplier: e.target.value,
                                })
                              }
                            />
                          </Col>
                        )}
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Supplier Account Email*</Label>
                            <CreatableSelect
                              id="supplier_account_id"
                              isDisabled={isLoadingNewEmail}
                              isLoading={isLoadingNewEmail}
                              onChange={(option) => {
                                setFormData({
                                  ...formData,
                                  purchasing_account_name: option.label,
                                  purchasing_account_id: typeof option.value === "string" ? undefined : option.value,
                                });
                                delete customErrors.email;
                              }}
                              onCreateOption={handleAddNewEmail}
                              options={emailOpts}
                              value={{
                                value: formData.purchasing_account_id || "",
                                label: formData.purchasing_account_name || "Supplier Account Email",
                              }}
                              classNamePrefix="select2-selection"
                            />
                            {customErrors.email && <small className="text-danger">{customErrors.email}</small>}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <AvField
                            name="target_order_id"
                            label="Supplier Order Id*"
                            placeholder="Suppliers e.g Amazon's, HomeDepot's order id"
                            type="text"
                            errorMessage="Field is Required"
                            helpMessage="Order id of order placed on other marketplace for fullfilment."
                            validate={validateSupplierOrderId()}
                            value={formData.target_order_id || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                target_order_id: e.target.value,
                              })
                            }
                          />
                        </Col>
                        {props.CreditCards?.ccData?.data?.length ? (
                          <Col lg="6">
                            <div className="d-flex justify-content-between">
                              <Label>Credit Card*</Label>
                              <u className="cursor-pointer" onClick={handleAddCreditCard}>
                                Add Credit Card
                              </u>
                            </div>
                            <FormGroup className="select2-container">
                              <Select
                                name="cc_id"
                                value={{
                                  value: creditCard.value,
                                  label: creditCard.label,
                                }}
                                onChange={(e) => {
                                  setFormData({ ...formData, cc_id: e.value });
                                  setCreditCard({ value: e.value, label: e.label });
                                  delete customErrors.card;
                                }}
                                options={props.CreditCards.ccData.data.map((card, i) => ({
                                  label: `${card.last_four} - ${decode(card.card_holder_name)}`,
                                  value: card.id,
                                  key: i,
                                }))}
                                classNamePrefix="select2-selection"
                              />
                              {customErrors.card && <small className="text-danger">{customErrors.card}</small>}
                            </FormGroup>
                          </Col>
                        ) : null}
                        <Col lg="6">
                          <AvField
                            name="cost"
                            label="Cost*"
                            placeholder="Cost Per Item"
                            type="number"
                            min="0.01"
                            step="0.01"
                            errorMessage="Field is required and must be greater than or equal to 0.01."
                            validate={{ required: { value: true } }}
                            value={formData.cost || ""}
                            onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
                          />
                        </Col>
                        <Col lg="6">
                          <AvField
                            name="qty"
                            label="Qty Placed*"
                            placeholder="Quantity Placed"
                            type="number"
                            min="1"
                            errorMessage="Field is Required"
                            validate={{ required: { value: true } }}
                            value={itemForProcessing.value?.qty * formData.qty_multiplier || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                qty: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col lg="3">
                          <AvField
                            name="order_total"
                            label="Total*"
                            placeholder="Total"
                            type="number"
                            min="0"
                            readOnly
                            errorMessage="Field is Required"
                            validate={{ required: { value: true } }}
                            value={calculateOrderTotal(formData) || ""}
                          />
                        </Col>
                        <Col lg="12" className="mb-3">
                          <Row>
                            <Col lg="6">
                              <AvField
                                name="shipping_cost"
                                label="Shipping Cost*"
                                placeholder="Shipping"
                                type="number"
                                min="0"
                                step="0.01"
                                errorMessage="Field is Required"
                                validate={{ required: { value: true } }}
                                value={formData.shipping_cost || "0"}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    shipping_cost: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col lg="3">
                              <AvField
                                name="tax"
                                label="Tax*"
                                placeholder="Tax"
                                type="number"
                                min="0"
                                step="0.01"
                                errorMessage="Field is Required"
                                validate={{ required: { value: true } }}
                                value={formData.tax || "0"}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    tax: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col lg="3">
                              <AvField
                                name="promotions"
                                label="Promotions"
                                placeholder="Gift credits amount"
                                type="number"
                                min="0"
                                step="0.01"
                                value={formData.promotions || "0"}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    promotions: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </AvForm>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex align-items-start justify-content-between mb-2 mt-1">
                      <h5>Suppliers</h5>
                      <Button className="ml-3" color="secondary" onClick={handleAddSupplier}>
                        <i className="bx bx-xs bx-plus pr-2"></i>Add Supplier
                      </Button>
                    </div>
                    <div className="table-responsive overflow-auto mb-2" style={{ maxHeight: "400px" }}>
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="thead-light">
                          <tr>
                            <th>Supplier</th>
                            <th>SKU</th>
                            <th>Url</th>
                            <th>Qty Multiplier</th>
                            <th>Price</th>
                            <th>Shipping Fee</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemForProcessing.value && suppliers && Object.values(suppliers).flat()?.length > 0
                            ? Object.values(suppliers)
                              .flat()
                              ?.map((item, key) => (
                                  <tr key={"_supplier_manual_modal_" + key}>
                                    <td>
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          id={"_radio_supplier_" + item.id}
                                          name="customRadio"
                                          className="custom-control-input"
                                          checked={item.id === supplier.id}
                                          onChange={() => {
                                            handeSupplierChange(item);
                                          }}
                                        />
                                        <label className="custom-control-label" htmlFor={"_radio_supplier_" + item.id}>
                                          {item.seller_sku && (
                                            <i
                                              title="Warehouse Supplier"
                                              className="cursor-pointer fas fa-sm fa-warehouse pr-1"
                                            ></i>
                                          )}
                                          {item.name}
                                        </label>
                                      </div>
                                    </td>
                                    <td>{item.sku}</td>
                                    <td>
                                      {item.url && (
                                        <a
                                          className="text-overflow-wrap"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={item.url}
                                        >
                                          Supplier URL
                                        </a>
                                      )}
                                      {item.seller_sku && item.seller_sku}
                                    </td>
                                    <td>{item.quantity_multiplier}</td>
                                    <td>{item.price_lock ? item.manual_price : item.price}</td>
                                    <td>{item.shipping_fee_lock ? item.manual_shipping_fee : item.shipping_fee}</td>
                                    <td>
                                      <i
                                        className="bx bx-xs bx-edit text-warning px-1 cursor-pointer"
                                        onClick={() => handleEditSupplier(item)}
                                      />
                                    </td>
                                  </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end mt-3 mr-0">
                  <div className="d-flex justify-content-between grid-column-gap-10">
                    <Button type="reset" color="secondary" onClick={reset}>
                      Reset
                    </Button>
                    <Button form="myAvForm" type="submit" color="success" onClick={() => setNextOrder(false)}>
                      Process
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SupplierOrders, CreditCards, Supplier, SourceOrders } = state;
  return { SupplierOrders, CreditCards, Supplier, SourceOrders };
};

export default withRouter(
  connect(mapStateToProps, {
    createSupplierOrders,
    fetchCreditCardDetails,
    fetchAllWarehouses,
    refreshSourceOrderDetail,
    resetSupplierOrderState,
    setChangedWareHouse,
    setChangeWareHouseModal,
    fetchSuppliers,
    resetSupplierState,
    createSupplier,
    fetchBuyingEmails,
    updateSourceOrder,
    setAddCreditCardsModal,
  })(ProcessingItemModal),
);
