import * as types from "./actionTypes";
const {
  FETCH_AO_ECS_TASKS,
  FETCH_AO_ECS_TASKS_SUCCESS,
  FETCH_ACCOUNTS_ORDERS_HISTORY,
  FETCH_ACCOUNTS_ORDERS_HISTORY_SUCCESS,
  API_ERROR,
} = types;

export const fetchAOECSTasks = () => ({ type: FETCH_AO_ECS_TASKS, payload: {} });
export const fetchAOECSTasksSuccess = (payload) => ({ type: FETCH_AO_ECS_TASKS_SUCCESS, payload });

export const fetchAccountOrdersHistory = (payload) => ({ type: FETCH_ACCOUNTS_ORDERS_HISTORY, payload });
export const fetchAccountOrdersHistorySuccess = (payload) => ({ type: FETCH_ACCOUNTS_ORDERS_HISTORY_SUCCESS, payload });

export const stopAOECSTask = (payload) => ({ type: types.STOP_ECS_TASK, payload });
export const stopAOECSTaskSuccess = (payload) => ({ type: types.STOP_ECS_TASK_SUCCESS, payload });

export const apiError = (error) => ({ type: API_ERROR, payload: error });
