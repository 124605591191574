import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input } from "reactstrap";
import PillBtn from "../../../components/Common/PillBtn";
import Modal from "../../../components/Common/Modal";
import "react-toggle/style.css";
import { connect } from "react-redux";
import { RenderIf } from "../../../utils/common";
import SweetAlert from "react-bootstrap-sweetalert";
// actions
import {
  createAmazonSeller,
  updateAmazonSeller,
  setAddAmazonSellerModal,
  setSuccessMessage,
} from "../../../store/actions";

const AddSellerModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const validate = (values) => {
    const errors = {};
    const regex = RegExp("^[A-Z0-9]+$");
    Object.keys(values).forEach((key) => {
      if (values[key] === "") !["name", "is_blacklisted"].includes(key) && (errors[key] = "Field is Required");
      else if (key === "id" && (values[key].length < 10 || values[key].length > 22 || !regex.test(values[key]))) {
        errors[key] = "Length should be from 10 to 22 characters and only contain alpha-numeric uppercase characters";
      }
    });
    return errors;
  };

  const initialValues = {
    id: props.amazonSeller?.id || "",
    name: props.amazonSeller?.name || "",
    is_blacklisted: props.amazonSeller?.is_blacklisted || false,
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const data = {
        seller_id: values.id,
        name: values.name,
        is_blacklisted: values.is_blacklisted,
      };

      if (props?.amazonSeller)
        if (values.name !== props.amazonSeller?.name || values.is_blacklisted !== props.amazonSeller?.is_blacklisted)
          props.updateAmazonSeller({ data });
        else {
          props.setAddAmazonSellerModal(false);
          props.setSuccessMessage("No changes to update");
        }
      else props.createAmazonSeller({ data });
    },
  });

  useEffect(() => {
    props.AmazonSellers.success && !props.AmazonSellers.loading && toggleModal();
  }, [props.AmazonSellers.success, props.AmazonSellers.loading]);

  const toggleModal = () => {
    props.setAddAmazonSellerModal(!props.AmazonSellers.addAmazonSellerModalIsOpen);
    formik.resetForm();
  };

  return (
    <Modal size="md" isOpen={props.AmazonSellers.addAmazonSellerModalIsOpen} toggle={toggleModal}>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            formik.setFieldValue("is_blacklisted", !formik.values.is_blacklisted);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {!formik.values.is_blacklisted ? "to blacklist seller? " : "to remove seller from blacklist"}
        </SweetAlert>
      </RenderIf>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.amazonSeller ? "Edit" : "Add"} Amazon Seller
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {props.AmazonSellers.error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {"Credentials: " + props.AmazonSellers.error}
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <Label>Seller ID{props.amazonSeller ? "" : "*"}</Label>
              <Input
                name="id"
                value={formik.values.id}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="e.g. ATVPDKIKX0DER"
                readOnly={props.amazonSeller}
              />
              {formik.touched.id && formik.errors.id
                ? (
                <span className="text-danger mt-1">{formik.errors.id}</span>
                  )
                : null}
            </Col>
            <Col lg="12 mb-2">
              <Label>Seller Name*</Label>
              <Input
                name="name"
                value={formik.values.name}
                placeholder="Enter Seller Name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
            <Col lg="12 mb-2" className="d-flex">
              <div className="d-flex flex-column">
                <Label className="my-3">Blacklisted</Label>
              </div>
              <div className="custom-control custom-switch custom-switch-lg ml-2 my-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={!!formik.values.is_blacklisted}
                  onChange={() => null}
                />
                <label onClick={() => setIsOpen(true)} className="custom-control-label cursor-pointer"></label>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <PillBtn
              color="secondary"
              title="Cancel"
              name="Cancel"
              className="pr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            />
            <PillBtn
              type="submit"
              color="success"
              title={props.amazonSeller ? "Update" : "Add"}
              name="Submit"
              className="pr-3 ml-3"
            />
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { AmazonSellers } = state;
  return { AmazonSellers };
};

export default connect(mapStateToProps, {
  createAmazonSeller,
  updateAmazonSeller,
  setSuccessMessage,
  setAddAmazonSellerModal,
})(AddSellerModal);
