import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Row, Col, Alert, Card } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import Dropzone from "react-dropzone";
import "flatpickr/dist/themes/dark.css";

// actions
import { setUploadOrdersModal, uploadOrdersFile, setPreloader } from "../../../store/actions";

const UploadOrdersModal = (props) => {
  const toggleModal = () => props.setUploadOrdersModal(!props.SourceOrders.uploadOrdersModalIsOpen);

  // show success message 3 sec and then auto close model
  if (props.SourceOrders.success) {
    setTimeout(() => toggleModal(), 3000);
  }
  //! UseStates
  const [selectedFile, setselectedFile] = useState([]);
  const [file] = useState("Orders_upload");
  const { loading } = useSelector((state) => state.Listings);

  const dispatch = useDispatch();

  //  Check File Handler
  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i))?.toFixed(dm)) + " " + sizes[i];
  }

  //! UseEffects
  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  // for auto Submitting file
  useEffect(() => {
    if (selectedFile.length !== 0) {
      dispatch(
        uploadOrdersFile({
          data: {
            file_name: selectedFile[0],
            file_type: file,
          },
        }),
      );
      setselectedFile([]);
    }
  }, [selectedFile]);

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.SourceOrders.uploadOrdersModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Upload Orders File</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders.error}
            </Alert>
          )}

          {props.SourceOrders.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {"Orders successfully uploaded" || ""}
              </Alert>
            </div>
          )}
          {/* Form Start */}
          <form className="m-2">
            <Col className="my-1">
              <Dropzone
                onDrop={(acceptedFile) => {
                  handleAcceptedFile(acceptedFile);
                }}
                accept=".csv"
                maxFiles="1"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                      </div>
                      <h4>Drag or Click to Upload CSV File</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFile.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col>
                            {f.name}
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                            <p className="mb-0"></p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Col>
          </form>
          {/* Form End */}
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders } = state;
  return { SourceOrders };
};

export default connect(mapStateToProps, {
  setUploadOrdersModal,
  uploadOrdersFile,
})(UploadOrdersModal);
