import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

// actions
import { fetchIssueDetails } from "../../../store/actions";

const IssueStats = (props) => {
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const { IssueStats, issueStatsLoader } = useSelector((state) => state.Graph);

  const fetchDetails = () => {
    dispatch(
      fetchIssueDetails({
        marketplace_id: accountId,
      }),
    );
  };

  useEffect(() => {
    !issueStatsLoader && fetchDetails();
  }, [accountId]);

  const data = {
    series: IssueStats?.data?.map((issue) => issue.count) || [],
    options: {
      labels: IssueStats?.data?.map((issue) => issue.issue_reason) || [],
      legend: { show: false, position: "bottom", horizontalAlign: "left" },
      noData: {
        text: "No Issue Order",
      },
      colors: ["#34c38f", "#556ee6", "#f46a6a", "#34f48f", "#546fe6", "#f26a4b", "#34d39f", "#55dec6", "#f43a62"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
          expandOnClick: true,
        },
      },
      chart: {
        ...(props.abilities.can("visit", "source_orders") && {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              window.open(
                `/source_orders/issue?issue_reason=${config.w.config.labels[config.dataPointIndex]}`,
                "_blank",
              );
            },
          },
        }),
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title">{issueStatsLoader ? "Loading..." : "Total Issues"}</h4>
        <div>
          <div id="donut-chart" className="apex-charts">
            <ReactApexChart options={data.options} series={data.series} type="donut" height="250" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default withRouter(IssueStats);
