import { toUpper } from "lodash";
import React from "react";
import StatusBadge from "../../../../components/Common/StatusBadge";
import TrackingURL from "../../../../components/Common/TrackingUrl";
import Text from "./Text";

const TrackingDetails = ({ trackingNum, carrier, status, inventoryId }) => {
  return (
    <td>
      <div className="d-flex flex-column">
        <TrackingURL tracking_num={trackingNum} carrier={carrier} />
        <Text heading="Carrier" data={toUpper(carrier)} />
        {(status || inventoryId) && <StatusBadge status={inventoryId ? "Moved to Inventory" : status} />}
      </div>
    </td>
  );
};

export default TrackingDetails;
