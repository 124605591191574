import * as $ from "lodash";
import * as types from "./actionTypes";
const loaderKeys = [
  "buyBoxStatsLoading",
  "strategiesLoading",
  "tagsLoading",
  "loading",
  "listingStatsLoading",
  "strategiesStatsLoading",
  "logsLoading",
  "historyLogsLoading",
  "loader",
];
const initalLoadersState = loaderKeys.reduce((obj, key) => $.merge(obj, { [key]: false }), {});

const initialState = $.merge(
  {
    error: "",
    success: "",
    updateCOGSModalIsOpen: false,
    profitTrialModalIsOpen: false,
    assignStrategyModalIsOpen: false,
    repricerFormulasModalIsOpen: false,
    repricerTagsModalIsOpen: false,
    repricerListingsModalIsOpen: false,
    listingsFormulas: [],
    listingDetails: {},
    priceChangeHistories: [],
  },
  initalLoadersState,
);

const Repricer = (state = initialState, action) => {
  switch (action.type) {
    case types.BUYBOX_STATS:
      state = { ...state, success: "", error: "", buyBoxStatsLoading: true, buyboxData: undefined };
      break;
    case types.BUYBOX_STATS_SUCCESS:
      state = { ...state, buyBoxStatsLoading: false, error: "", buyboxData: action.payload };
      break;
    case types.FETCH_REPRICER_LISTINGS:
      state = { ...state, successMsg: "", success: "", error: "", listingsResults: null, loading: true };
      break;
    case types.FETCH_REPRICER_PRODUCT_LOGS:
      state = { ...state, error: "", logs: null, logsLoading: true };
      break;
    case types.FETCH_REPRICER_PRODUCT_LOGS_SUCCESS:
      state = { ...state, logsLoading: false, logs: action.payload };
      break;

    case types.FETCH_REPRICER_LISTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        listingsResults: action.payload?.results,
        listings: action.payload,
      };
      break;
    case types.FETCH_UPLOAD_FILE_HISTORY:
      state = { ...state, success: "", error: "", loading: true, filesFeedsResults: null };
      break;
    case types.FETCH_UPLOAD_FILE_HISTORY_SUCCESS:
      state = { ...state, loading: false, filesFeedsResults: action.payload?.results, filesHistory: action.payload };
      break;
    case types.FETCH_REPRICER_LISTINGS_FORMULAS:
      state = { ...state, success: "", error: "", loading: true, listingsFormulas: [] };
      break;
    case types.FETCH_REPRICER_LISTINGS_FORMULAS_SUCCESS:
      state = { ...state, loading: false, listingsFormulas: action.payload };
      break;
    case types.FETCH_REPRICER_LISTING_DETAILS:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.FETCH_REPRICER_LISTING_DETAILS_SUCCESS:
      state = { ...state, loading: false, listingDetails: action.payload };
      break;
    case types.FETCH_REPRICER_PRICE_CHANGE_HISTORIES:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.FETCH_REPRICER_PRICE_CHANGE_HISTORIES_SUCCESS:
      state = { ...state, loading: false, priceChangeHistories: action.payload };
      break;
    case types.CREATE_REPRICER_LISTINGS_FORMULAS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.GET_HISTORY_LOGS:
      state = { ...state, error: "", logs: null, historyLogsLoading: true };
      break;
    case types.GET_HISTORY_LOGS_SUCCESS:
      state = { ...state, historyLogsLoading: false, logs: action.payload };
      break;

    case types.CREATE_REPRICER_LISTINGS_FORMULAS_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "Formula Created!",
        error: action.payload.error,
        listingsFormulas: {
          ...state.listingsFormulas,
          results: state?.listingsFormulas?.results
            ? [action.payload, ...state?.listingsFormulas?.results]
            : [action.payload],
        },
      };
      break;
    case types.UPDATE_REPRICER_LISTINGS_FORMULAS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case types.UPDATE_REPRICER_LISTINGS_FORMULAS_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: "Formula Updated!",
        error: action.payload.error,
        listingsFormulas: {
          ...state.listingsFormulas,
          results: action.payload,
        },
      };
      break;
    case types.DELETE_REPRICER_LISTINGS_FORMULA:
      state = { ...state, loading: true, success: null, error: "" };
      break;
    case types.DELETE_REPRICER_LISTINGS_FORMULA_SUCCESS:
      state = { ...state, loading: false, success: "Formula Deleted!" };
      break;
    case types.CREATE_REPRICER_LISTINGS_TAGS:
      state = { ...state, tagsLoading: true, success: null };
      break;
    case types.CREATE_REPRICER_LISTINGS_TAGS_SUCCESS:
      state = {
        ...state,
        success: "Tag Created!",
        error: action.payload.error,
      };
      break;
    case types.UPDATE_REPRICER_LISTINGS_TAGS:
      state = { ...state, tagsLoading: true, success: "" };
      break;
    case types.UPDATE_REPRICER_LISTINGS_TAGS_SUCCESS:
      state = {
        ...state,
        tagsLoading: false,
        success: "Tag Updated!",
        error: action.payload.error,
        tags: {
          ...state.tags,
          results: action.payload,
        },
      };
      break;
    case types.DELETE_REPRICER_LISTINGS_TAG:
      state = { ...state, tagsLoading: true, success: null };
      break;
    case types.DELETE_REPRICER_LISTINGS_TAG_SUCCESS:
      state = { ...state, success: "Tag Deleted!" };
      break;
    case types.DELETE_ALL_TAGS:
      state = { ...state, tagsLoading: true, success: null };
      break;
    case types.DELETE_ALL_TAGS_SUCCESS:
      state = { ...state, tagsLoading: false, success: action.payload };
      break;
    case types.BULK_UPDATE_CSV_LISTINGS:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.BULK_UPDATE_CSV_LISTINGS_SUCCESS:
      state = { ...state, success: action.payload, error: "", loading: false };
      break;
    case types.FETCH_LISTINGS_STATS:
      state = { ...state, listingsStats: undefined, error: "", listingStatsLoading: true };
      break;
    case types.FETCH_LISTINGS_STATS_SUCCESS:
      state = { ...state, listingsStats: action.payload?.listing_statistics, error: "", listingStatsLoading: false };
      break;
    case types.UPDATE_REPRICER_LISTING:
      state = { ...state, successMsg: "", error: "", loader: true };
      break;
    case types.UPDATE_REPRICER_LISTING_SUCCESS:
      state = { ...state, successMsg: action.payload, error: "", loader: false };
      break;
    case types.CREATE_AND_UPDATE_TAG:
      state = { ...state, successMsg: "", error: "", loader: true };
      break;
    case types.UPDATE_LISTING_MAP_PRICE:
      state = { ...state, successMsg: "", error: "", loader: true };
      break;
    case types.UPDATE_LISTING_MAP_PRICE_SUCCESS:
      state = { ...state, successMsg: action.payload, error: "", loader: false };
      break;
    case types.GET_TAGS:
      state = { ...state, tagsLoading: true };
      break;
    case types.GET_TAGS_SUCCESS:
      state = { ...state, tags: action.payload, tagsLoading: false };
      break;
    case types.ASSIGN_STRATEGY:
      state = { ...state, success: "", error: "" };
      break;
    case types.ASSIGN_STRATEGY_SUCCESS:
      state = { ...state, success: action.payload.msg, error: action.payload.error };
      break;
    case types.ASSIGN_LISTINGS_STRATEGY:
      state = { ...state, successMsg: "", loader: true, error: "" };
      break;
    case types.ASSIGN_LISTINGS_STRATEGY_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.message,
        loader: false,
        error: action.payload.error,
      };
      break;
    case types.GET_ALL_REPRICER_ACCOUNTS:
      state = { ...state, success: "", allRepricerAccounts: "", error: "", strategiesLoading: true };
      break;
    case types.GET_ALL_REPRICER_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        success: action.payload.msg,
        allRepricerAccounts: action.payload,
        error: action.payload.error,
        strategiesLoading: false,
      };
      break;
    case types.GET_STRATEGIES_STATS:
      state = { ...state, success: "", strategiesStats: null, error: "", strategiesStatsLoading: true };
      break;
    case types.GET_STRATEGIES_STATS_SUCCESS:
      state = { ...state, strategiesStats: action.payload, error: "", strategiesStatsLoading: false };
      break;
    case types.GET_STRATEGIES:
      state = {
        ...state,
        success: "",
        removeStrategyError: "",
        strategies: undefined,
        error: "",
        strategiesLoading: true,
      };
      break;
    case types.GET_STRATEGIES_SUCCESS:
      state = { ...state, strategies: action.payload, error: "", strategiesLoading: false };
      break;
    case types.CREATE_STRATEGY:
      state = { ...state, success: "", removeStrategyError: "", error: "", strategiesLoading: true };
      break;
    case types.CREATE_STRATEGY_SUCCESS:
      state = {
        ...state,
        error: action.payload.error,
        strategiesLoading: false,
        success: "Strategy Created!",
        strategies: state?.strategies ? [action.payload, ...state.strategies] : [action.payload],
      };
      break;
    case types.UPDATE_STRATEGY:
      state = { ...state, success: "", error: "", removeStrategyError: "", strategiesLoading: true };
      break;
    case types.UPDATE_STRATEGY_SUCCESS:
      state = {
        ...state,
        error: action.payload.error,
        strategiesLoading: false,
        strategies: action.payload,
        success: "Strategy Updated!",
      };
      break;
    case types.REMOVE_STRATEGY:
      state = { ...state, success: "", error: "", removeStrategyError: "", strategiesLoading: true };
      break;
    case types.REMOVE_STRATEGY_SUCCESS:
      state = {
        ...state,
        error: "",
        strategies: action.payload,
        strategiesLoading: false,
        success: "Strategy Removed!",
      };
      break;
    case types.REMOVE_STRATEGY_ACCOUNT:
      state = { ...state, success: "", error: "", strategiesLoading: true };
      break;
    case types.REMOVE_STRATEGY_ACCOUNT_SUCCESS:
      state = { ...state, success: action.payload.msg, error: action.payload.error, strategiesLoading: false };
      break;
    case types.SET_PROFIT_DASHBOARD_TRIAL_MODAL:
      state = { ...state, profitTrialModalIsOpen: action.payload, error: "" };
      break;
    case types.SET_REPRICER_FORMULAS_MODAL:
      state = { ...state, repricerFormulasModalIsOpen: action.payload, error: "" };
      break;
    case types.SET_REPRICER_TAGS_MODAL:
      state = { ...state, repricerTagsModalIsOpen: action.payload, error: "" };
      break;
    case types.SET_REPRICER_LISTINGS_MODAL:
      state = { ...state, repricerListingsModalIsOpen: action.payload, error: "" };
      break;
    case types.SET_STRATEGY_MODAL:
      state = { ...state, strategyModalIsOpen: action.payload, error: "" };
      break;
    case types.SET_UPDATE_COGS_MODAL:
      state = { ...state, updateCOGSModalIsOpen: action.payload, error: "" };
      break;
    case types.SET_ASSIGN_STRATEGY_MODAL:
      state = { ...state, assignStrategyModalIsOpen: action.payload, error: "" };
      break;
    case types.SET_REMOVE_STRATEGY_MODAL:
      state = { ...state, removeStrategyModalIsOpen: action.payload, error: "" };
      break;
    case types.API_ERROR:
      state = { ...state, error: action.payload, success: false, ...initalLoadersState };
      break;
    case types.REMOVE_STRATEGY_ERROR:
      state = { ...state, removeStrategyError: action.payload, success: false, ...initalLoadersState };
      break;
  }
  return state;
};

export default Repricer;
