import * as types from "./actionTypes";
const {
  FETCH_ALL_WAREHOUSES,
  FETCH_ALL_WAREHOUSES_SUCCESS,
  FETCH_ALL_WAREHOUSE_ITEMS,
  FETCH_ALL_WAREHOUSE_ITEMS_SUCCESS,
  FETCH_WAREHOUSE_ACCOUNTS,
  FETCH_WAREHOUSE_ACCOUNTS_SUCCESS,
  CREATE_WAREHOUSE,
  CREATE_WAREHOUSE_SUCCESS,
  ASSIGN_USER_WAREHOUSE,
  ASSIGN_USER_WAREHOUSE_SUCCESS,
  SET_SELECT_ON_BOARD_STORE,
  UPDATE_WAREHOUSE_ITEM,
  UPDATE_WAREHOUSE_ITEM_SUCCESS,
  DELETE_WAREHOUSE_USER,
  DELETE_WAREHOUSE_USER_SUCCESS,
  SELECT_EXISTING_WAREHOUSE,
  SET_SELECTED_WAREHOUSE,
  SET_SELECTED_WAREHOUSE_ITEM,
  UPDATE_WAREHOUSE,
  UPDATE_WAREHOUSE_SUCCESS,
  SET_ADD_WAREHOUSE_MODAL,
  SET_ASSIGN_USER_WAREHOUSE_MODAL,
  API_ERROR,
  SET_VIEW_WAREHOUSE_USERS_MODAL,
  ADD_TRACKING_ITEM_NOTE_MODAL,
  ADD_WAREHOUSE_NOTE,
  ADD_WAREHOUSE_NOTE_SUCCES,
  RESOLVE_STRANDED_ITEM,
  RESOLVE_STRANDED_ITEM_SUCCESS,
  FETCH_INVENTORY_ITEMS,
  FETCH_INVENTORY_ITEMS_SUCCESS,
  SET_TRACKING_STATUS_MODAL,
  UPDATE_INVENTORY_ITEM_STATUS,
  UPDATE_INVENTORY_ITEM_STATUS_SUCCESS,
  SET_UPLOAD_RETURN_LABEL_MODAL,
  SET_SELECTED_INVENTORY_ITEM,
  UPLOAD_RETURN_LABEL_FILE,
  UPLOAD_RETURN_LABEL_FILE_SUCCESS,
  DOWNLOAD_RETURN_LABEL,
  DOWNLOAD_RETURN_LABEL_SUCCESS,
  ASSIGN_AGENCY_TO_WAREHOUSE,
  ASSIGN_AGENCY_TO_WAREHOUSE_SUCCESS,
  SET_VIEW_AGENCY_WAREHOUSE_MODEL,
  DELETE_WH_ACCOUNT,
  DELETE_WH_ACCOUNT_SUCCESS,
  DELETE_WAREHOUSE,
  DELETE_WAREHOUSE_SUCCESS,
  UPDATE_WH_ACCOUNT,
  UPDATE_WH_ACCOUNT_SUCCESS,
  UPDATE_BULK_ITEMS,
  UPDATE_BULK_ITEMS_SUCCESS,
  MARK_NOT_RECEIVED,
  MARK_NOT_RECEIVED_SUCCESS,
  SET_DOWNLOAD_RETURN_LABEL_MODAL,
  DELETE_RETURN_LABEL_SUCCESS,
  DELETE_RETURN_LABEL,
  FETCH_USER_WAREHOUSES,
  FETCH_USER_WAREHOUSES_SUCCESS,
  FETCH_ALL_SHIPPING_RATES,
  FETCH_ALL_SHIPPING_RATES_SUCCESS,
  GENERATE_LABEL,
  GENERATE_LABEL_SUCCESS,
  FETCH_ALL_SHIPMENTS,
  FETCH_ALL_SHIPMENTS_SUCCESS,
  FETCH_ALL_OUTBOUND_SHIPMENTS,
  FETCH_ALL_OUTBOUND_SHIPMENTS_SUCCESS,
  REFUND_SHIPMENT_LABEL,
  REFUND_SHIPMENT_LABEL_SUCCESS,
  DOWNLOAD_SHIPPING_LABEL,
  DOWNLOAD_SHIPPING_LABEL_SUCCESS,
  SET_BIN_NO_MODAL,
  SET_ADD_INVENTORY_MODAL,
  RESET_FETCH_RATES_ERR,
  RESET_RATES,
  DOWNLOAD_INVENTORY_REPORT,
  DOWNLOAD_INVENTORY_REPORT_SUCCESS,
  ADD_INVENTORY_ITEM,
  ADD_INVENTORY_ITEM_SUCCESS,
  UPDATE_INVENTORY_ITEM_BINS,
  UPDATE_INVENTORY_ITEM_BINS_SUCCESS,
  DELETE_INVENTORY_ITEM_BIN_SUCCESS,
  DELETE_INVENTORY_ITEM_BIN,
  FETCH_WH_INBOUND_STATS,
  FETCH_WH_INBOUND_STATS_SUCCESS,
  FETCH_PURCHASE_ORDERS,
  FETCH_PURCHASE_ORDERS_SUCCESS,
  FETCH_PURCHASE_ORDERS_METADATA,
  FETCH_PURCHASE_ORDERS_METADATA_SUCCESS,
  FETCH_PO_LOGS,
  FETCH_PO_LOGS_SUCCESS,
  FETCH_PO_STATS,
  FETCH_PO_STATS_SUCCESS,
  FETCH_PO_SUPPLIERS,
  FETCH_PO_SUPPLIERS_SUCCESS,
  CREATE_PURCHASE_ORDERS,
  CREATE_PURCHASE_ORDERS_SUCCESS,
  CREATE_LINE_ITEM,
  CREATE_LINE_ITEM_SUCCESS,
  CREATE_PO_SHIPMENT,
  CREATE_PO_SHIPMENT_SUCCESS,
  SET_ADJUSTMENT_MODAL,
  SET_SHIPMENT_MODAL,
  SET_PO_LOGS_MODAL,
  DELETE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM_SUCCESS,
  UPDATE_PURCHASE_ORDER_SHIPMENT,
  UPDATE_PURCHASE_ORDER_SHIPMENT_SUCCESS,
  DELETE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM_SUCCESS,
  DELETE_PURCHASE_ORDER_SHIPMENT,
  DELETE_PURCHASE_ORDER_SHIPMENT_SUCCESS,
  SET_UPDATE_TENANT_MODAL,
  SET_INVENTORY_CHECKBOXES,
  BULK_UPDATE_INVENTORY_ITEM,
  BULK_UPDATE_INVENTORY_ITEM_SUCCESS,
  FETCH_INBOUND_ITEMS,
  FETCH_INBOUND_ITEMS_SUCCESS,
  SET_ADD_SHIPMENT_MODEL,
  SEARCH_ORDER,
  SEARCH_ORDER_SUCCESS,
  UPDATE_TRACKING_ITEM_SHIPPPING_STATUS,
  UPDATE_TRACKING_ITEM_SHIPPPING_STATUS_SUCCESS,
  SET_RECEIVED_SHIPMENT_MODEL,
  SET_STORE_BINS_MODEL,
  ADD_SHIPMENT,
  ADD_SHIPMENT_SUCCESS,
  SHIPMENT_CHECKED_IN,
  SHIPMENT_CHECKED_IN_SUCCESS,
  DOWNLOAD_WH_ITEMS_REPORT,
  DOWNLOAD_WH_ITEMS_REPORT_SUCCESS,
  RECEIVE_SHIPMENT,
  RECEIVE_SHIPMENT_SUCCESS,
  FETCH_WH_INVENTORY,
  FETCH_WH_INVENTORY_SUCCESS,
  FETCH_WH_INVENTORY_STATS,
  FETCH_WH_INVENTORY_STATS_SUCCESS,
  FETCH_WH_OUTBOUNDS,
  FETCH_WH_OUTBOUNDS_SUCCESS,
  DOWNLOAD_WH_LABEL_REPORT,
  DOWNLOAD_WH_LABEL_REPORT_SUCCESS,
  GENERATE_SHIPPING_LABEL_TOGGLE,
  FETCH_WH_OUTBOUND_STATS,
  FETCH_WH_OUTBOUND_STATS_SUCCESS,
  SET_BIN_DETAIL_MODAL,
  SET_INBOUND_SHIPMENT,
  SET_WH_LABEL_REPORT_MODAL,
  FETCH_LABEL_USERS_AND_COUNT,
  FETCH_LABEL_USERS_AND_COUNT_SUCCESS,
} = types;

export const fetchAllWarehouses = (params = {}) => {
  return {
    type: FETCH_ALL_WAREHOUSES,
    payload: { params },
  };
};

export const fetchAllWarehousesSuccess = (data) => {
  return {
    type: FETCH_ALL_WAREHOUSES_SUCCESS,
    payload: data,
  };
};

export const fetchAllWarehouseItems = (params) => {
  return {
    type: FETCH_ALL_WAREHOUSE_ITEMS,
    payload: { params },
  };
};

export const fetchAllWarehouseItemsSuccess = (data) => {
  return {
    type: FETCH_ALL_WAREHOUSE_ITEMS_SUCCESS,
    payload: data,
  };
};

export const fetchWarehouseAccounts = (_) => {
  return {
    type: FETCH_WAREHOUSE_ACCOUNTS,
    payload: {},
  };
};

export const fetchWarehouseAccountsSuccess = (data) => {
  return {
    type: FETCH_WAREHOUSE_ACCOUNTS_SUCCESS,
    payload: data,
  };
};

export const createWareHouse = (data) => {
  return {
    type: CREATE_WAREHOUSE,
    payload: data,
  };
};

export const createWareHouseSuccess = (data) => {
  return {
    type: CREATE_WAREHOUSE_SUCCESS,
    payload: data,
  };
};

export const assignUserWareHouse = (data) => {
  return {
    type: ASSIGN_USER_WAREHOUSE,
    payload: data,
  };
};

export const assignUserWareHouseSuccess = (res) => {
  return {
    type: ASSIGN_USER_WAREHOUSE_SUCCESS,
    payload: res,
  };
};

export const updateWareHouseItem = ({ data, id }) => {
  return {
    type: UPDATE_WAREHOUSE_ITEM,
    payload: { data, id },
  };
};

export const updateWareHouseItemSuccess = (result) => {
  return {
    type: UPDATE_WAREHOUSE_ITEM_SUCCESS,
    payload: result,
  };
};

export const updateWarehouse = ({ data, id }) => {
  return {
    type: UPDATE_WAREHOUSE,
    payload: { data, id },
  };
};

export const updateWarehouseSuccess = (res) => {
  return {
    type: UPDATE_WAREHOUSE_SUCCESS,
    payload: res,
  };
};

export const resolveStrandedItem = ({ id, data = {} }) => {
  return {
    type: RESOLVE_STRANDED_ITEM,
    payload: { id, data },
  };
};

export const resolveStrandedItemSuccess = (result) => {
  return {
    type: RESOLVE_STRANDED_ITEM_SUCCESS,
    payload: result,
  };
};

export const assignAgencyToWarehouse = (data) => {
  return {
    type: ASSIGN_AGENCY_TO_WAREHOUSE,
    payload: data,
  };
};

export const assignAgencyToWarehouseSuccess = (data) => {
  return {
    type: ASSIGN_AGENCY_TO_WAREHOUSE_SUCCESS,
    payload: data,
  };
};

export const deleteWarehouse = (id) => ({ type: DELETE_WAREHOUSE, payload: id });

export const deleteWarehouseSuccess = (res) => ({ type: DELETE_WAREHOUSE_SUCCESS, payload: res });

export const updateWHAccount = (id, data) => ({ type: UPDATE_WH_ACCOUNT, payload: { data, id } });

export const updateWHAccountSuccess = (res) => ({ type: UPDATE_WH_ACCOUNT_SUCCESS, payload: res });

export const deleteWHAccount = (whId, email) => {
  return {
    type: DELETE_WH_ACCOUNT,
    payload: { wh_id: whId, email },
  };
};

export const deleteWHAccountSuccess = (data) => {
  return {
    type: DELETE_WH_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const fetchInventoryItems = (params) => {
  return { type: FETCH_INVENTORY_ITEMS, payload: { params } };
};

export const fetchInventoryItemsSuccess = (data) => ({ type: FETCH_INVENTORY_ITEMS_SUCCESS, payload: data });

export const updateInventoryItemStatus = (opts) => {
  return {
    type: UPDATE_INVENTORY_ITEM_STATUS,
    payload: opts,
  };
};

export const updateInventoryItemStatusSuccess = (res) => {
  return { type: UPDATE_INVENTORY_ITEM_STATUS_SUCCESS, payload: res };
};

export const downloadReturnLabel = (id, params) => {
  return { type: DOWNLOAD_RETURN_LABEL, payload: { id, params } };
};

export const downloadReturnLabelSuccess = (payload) => {
  return { type: DOWNLOAD_RETURN_LABEL_SUCCESS, payload };
};

export const downloadShippingLabel = (id, params) => {
  return { type: DOWNLOAD_SHIPPING_LABEL, payload: { id, params } };
};

export const downloadShippingLabelSuccess = (payload) => {
  return { type: DOWNLOAD_SHIPPING_LABEL_SUCCESS, payload };
};

export const deleteReturnLabel = (id, params, filters) => {
  return { type: DELETE_RETURN_LABEL, payload: { id, params, filters } };
};

export const deleteReturnLabelSuccess = (res) => {
  return { type: DELETE_RETURN_LABEL_SUCCESS, payload: res };
};

export const setUploadReturnLabelModal = (bool) => {
  return { type: SET_UPLOAD_RETURN_LABEL_MODAL, payload: bool };
};

export const setAddBinNoModal = (bool) => {
  return { type: SET_BIN_NO_MODAL, payload: bool };
};

export const setAddInventoryModal = (bool) => {
  return { type: SET_ADD_INVENTORY_MODAL, payload: bool };
};

export const addInventoryItem = (data) => {
  return { type: ADD_INVENTORY_ITEM, payload: data };
};

export const addInventoryItemSuccess = (res) => {
  return { type: ADD_INVENTORY_ITEM_SUCCESS, payload: res };
};
export const setUpdateTenantModal = (bool) => ({ type: SET_UPDATE_TENANT_MODAL, payload: bool });

export const setInventoryCheckBoxes = (payload) => ({ type: SET_INVENTORY_CHECKBOXES, payload });

export const setViewAgencyWarehouseModal = (bool, data) => {
  return { type: SET_VIEW_AGENCY_WAREHOUSE_MODEL, payload: { state: bool, data } };
};

export const setSelectedInventoryItem = (data) => {
  return { type: SET_SELECTED_INVENTORY_ITEM, payload: data };
};

export const uploadReturnLabelFile = ({ data, id, params }) => {
  return { type: UPLOAD_RETURN_LABEL_FILE, payload: { data, id, params } };
};

export const uploadReturnLabelFileSuccess = (message) => {
  return { type: UPLOAD_RETURN_LABEL_FILE_SUCCESS, payload: message };
};

export const deleteWareHouseUser = (id) => {
  return {
    type: DELETE_WAREHOUSE_USER,
    payload: id,
  };
};

export const deleteWareHouseUserSuccess = (data) => {
  return {
    type: DELETE_WAREHOUSE_USER_SUCCESS,
    payload: data,
  };
};

export const setAddTrackingItemNoteModal = (bool) => {
  return {
    type: ADD_TRACKING_ITEM_NOTE_MODAL,
    payload: bool,
  };
};

export const setTrackingStatusModal = (bool) => {
  return {
    type: SET_TRACKING_STATUS_MODAL,
    payload: bool,
  };
};

export const updateBulkItems = (ids, data) => {
  return {
    type: UPDATE_BULK_ITEMS,
    payload: { ids, data },
  };
};

export const updateBulkItemsSuccess = (res) => {
  return {
    type: UPDATE_BULK_ITEMS_SUCCESS,
    payload: res,
  };
};

export const addWarehouseNote = (data) => {
  return {
    type: ADD_WAREHOUSE_NOTE,
    payload: data,
  };
};

export const addWarehouseNoteSuccess = (data) => {
  return {
    type: ADD_WAREHOUSE_NOTE_SUCCES,
    payload: data,
  };
};

export const setAddWareHouseModal = (bool) => {
  return {
    type: SET_ADD_WAREHOUSE_MODAL,
    payload: bool,
  };
};

export const setSelectOnBoardStore = (bool) => {
  return {
    type: SET_SELECT_ON_BOARD_STORE,
    payload: bool,
  };
};

export const setViewWareHouseUsersModal = (bool, data) => {
  return {
    type: SET_VIEW_WAREHOUSE_USERS_MODAL,
    payload: { state: bool, data },
  };
};

export const setAssignUserWareHouseModal = (bool) => {
  return {
    type: SET_ASSIGN_USER_WAREHOUSE_MODAL,
    payload: bool,
  };
};

export const selectExistingWareHouse = (data) => {
  return {
    type: SELECT_EXISTING_WAREHOUSE,
    payload: data,
  };
};

export const setSelectedWareHouse = (data) => {
  return {
    type: SET_SELECTED_WAREHOUSE,
    payload: data,
  };
};

export const setSelectedWareHouseItem = (data) => {
  return {
    type: SET_SELECTED_WAREHOUSE_ITEM,
    payload: data,
  };
};

export const downloadWHLabelReport = (data) => {
  return {
    type: DOWNLOAD_WH_LABEL_REPORT,
    payload: data,
  };
};

export const downloadWHLabelReportSuccess = (data) => {
  return {
    type: DOWNLOAD_WH_LABEL_REPORT_SUCCESS,
    payload: data,
  };
};

export const setWHLabelReportModal = (bool) => {
  return {
    type: SET_WH_LABEL_REPORT_MODAL,
    payload: { modal: bool },
  };
};

export const markNotReceived = (id) => {
  return { type: MARK_NOT_RECEIVED, payload: id };
};

export const markNotReceivedSuccess = (res) => {
  return { type: MARK_NOT_RECEIVED_SUCCESS, payload: res };
};

export const fetchUserWarehouses = (params) => ({ type: FETCH_USER_WAREHOUSES, payload: params });

export const fetchUserWarehousesSuccess = (res) => {
  return { type: FETCH_USER_WAREHOUSES_SUCCESS, payload: res };
};

export const setDownloadLabelModal = (bool) => {
  return { type: SET_DOWNLOAD_RETURN_LABEL_MODAL, payload: bool };
};

export const downloadInventoryReport = (params) => ({ type: DOWNLOAD_INVENTORY_REPORT, payload: params });

export const downloadInventoryReprotSuccess = (payload) => ({ type: DOWNLOAD_INVENTORY_REPORT_SUCCESS, payload });

export const downloadWarehouseItemsReport = (params) => ({ type: DOWNLOAD_WH_ITEMS_REPORT, payload: params });

export const downloadWarehouseItemsReportSuccess = (payload) => ({ type: DOWNLOAD_WH_ITEMS_REPORT_SUCCESS, payload });

export const fetchAllShippingRates = ({ data }) => {
  return { type: FETCH_ALL_SHIPPING_RATES, payload: { data } };
};

export const fetchAllShippingRatesSuccess = (res) => {
  return { type: FETCH_ALL_SHIPPING_RATES_SUCCESS, payload: res };
};

export const generateLabel = (data, id) => ({ type: GENERATE_LABEL, payload: { data, id } });

export const generateLabelSuccess = (res) => {
  return { type: GENERATE_LABEL_SUCCESS, payload: res };
};

export const fetchAllShipments = () => {
  return { type: FETCH_ALL_SHIPMENTS };
};

export const fetchAllShipmentsSuccess = (data) => {
  return { type: FETCH_ALL_SHIPMENTS_SUCCESS, payload: data };
};

export const fetchAllOutboundShipments = (params) => {
  return { type: FETCH_ALL_OUTBOUND_SHIPMENTS, payload: params };
};

export const fetchAllOutboundShipmentsSuccess = (data) => {
  return { type: FETCH_ALL_OUTBOUND_SHIPMENTS_SUCCESS, payload: data };
};

export const refundShipmentLabel = (id, params) => ({ type: REFUND_SHIPMENT_LABEL, payload: { id, params } });
export const refundShipmentLabelSuccess = (res) => ({ type: REFUND_SHIPMENT_LABEL_SUCCESS, payload: res });

// WH Charges

export const downloadChargesReport = (params) => {
  return { type: types.DOWNLOAD_CHARGES_REPORT, payload: params };
};

export const downloadChargesReportSuccess = (res) => {
  return { type: types.DOWNLOAD_CHARGES_REPORT_SUCCESS, payload: res };
};

export const fetchWHChargeHistories = (params) => {
  return { type: types.FETCH_WH_CHARGE_HISTORIES, payload: { params } };
};

export const fetchWHChargeHistoriesSuccess = (data) => {
  return { type: types.FETCH_WH_CHARGE_HISTORIES_SUCCESS, payload: data };
};

export const updateWHChargeHistory = (payload) => ({
  type: types.UPDATE_WH_CHARGE_HISTORY,
  payload,
});

export const updateWHChargeHistorySuccess = (payload) => ({
  type: types.UPDATE_WH_CHARGE_HISTORY_SUCCESS,
  payload,
});

export const fetchWHStatusStats = (params) => {
  return { type: types.FETCH_WH_CHARGES_STATUS_STATS, payload: params };
};

export const fetchWHStatusStatsSuccess = (data) => {
  return { type: types.FETCH_WH_CHARGES_STATUS_STATS_SUCCESS, payload: data };
};

export const fetchInvoiceDetail = (param) => {
  return { type: types.FETCH_INVOICE_DETAIL, payload: param };
};

export const fetchInvoiceDetailSuccess = (data) => {
  return { type: types.FETCH_INVOICE_DETAIL_SUCCESS, payload: data };
};

// Purchase Orders
export const fetchPurchaseOrders = (params) => ({ type: FETCH_PURCHASE_ORDERS, payload: params });

export const fetchPurchaseOrdersSuccess = (data) => ({ type: FETCH_PURCHASE_ORDERS_SUCCESS, payload: data });

export const fetchPurchaseOrderMetadata = (id) => ({
  type: FETCH_PURCHASE_ORDERS_METADATA,
  payload: id,
});

export const fetchPurchaseOrderMetadataSuccess = (data) => ({
  type: FETCH_PURCHASE_ORDERS_METADATA_SUCCESS,
  payload: data,
});

export const fetchPurchaseOrderLogs = (id) => ({ type: FETCH_PO_LOGS, payload: id });

export const fetchPurchaseOrderLogsSuccess = (data) => ({ type: FETCH_PO_LOGS_SUCCESS, payload: data });

export const fetchPurchaseOrderStats = () => ({ type: FETCH_PO_STATS });

export const fetchPurchaseOrderStatsSuccess = (data) => ({
  type: FETCH_PO_STATS_SUCCESS,
  payload: data,
});

export const fetchPurchaseOrderSuppliers = () => ({ type: FETCH_PO_SUPPLIERS });

export const fetchPurchaseOrderSuppliersSuccess = (data) => ({
  type: FETCH_PO_SUPPLIERS_SUCCESS,
  payload: data,
});

export const createPurchaseOrder = (id, data, pageSize) => ({
  type: CREATE_PURCHASE_ORDERS,
  payload: { id, data, pageSize },
});

export const createPurchaseOrderSuccess = (res) => ({
  type: CREATE_PURCHASE_ORDERS_SUCCESS,
  payload: res,
});

export const createLineItem = (itemsObj, purchaseOrderObj, whOrderId) => ({
  type: CREATE_LINE_ITEM,
  payload: { itemsObj, purchaseOrderObj, whOrderId },
});
export const createLineItemSuccess = (res) => ({
  type: CREATE_LINE_ITEM_SUCCESS,
  payload: res,
});

export const createPOShipment = (shipmentObj) => ({
  type: CREATE_PO_SHIPMENT,
  payload: shipmentObj,
});

export const createPOShipmentSuccess = (res) => ({
  type: CREATE_PO_SHIPMENT_SUCCESS,
  payload: res,
});

export const deletePurchaseOrder = (id) => ({
  type: DELETE_PURCHASE_ORDER,
  payload: id,
});

export const deletePurchaseOrderSuccess = (data) => ({
  type: DELETE_PURCHASE_ORDER_SUCCESS,
  payload: data,
});
export const deletePOItem = (itemInfo) => ({
  type: DELETE_PURCHASE_ORDER_ITEM,
  payload: itemInfo,
});

export const deletePOItemSuccess = (data) => ({
  type: DELETE_PURCHASE_ORDER_ITEM_SUCCESS,
  payload: data,
});

export const deletePOShipment = (shipmentInfo) => ({
  type: DELETE_PURCHASE_ORDER_SHIPMENT,
  payload: shipmentInfo,
});

export const deletePOShipmentSuccess = (data) => ({
  type: DELETE_PURCHASE_ORDER_SHIPMENT_SUCCESS,
  payload: data,
});

export const updatePurchaseOrder = (data, id) => ({
  type: UPDATE_PURCHASE_ORDER,
  payload: { data, id },
});

export const updatePurchaseOrderSuccess = (result) => ({
  type: UPDATE_PURCHASE_ORDER_SUCCESS,
  payload: result,
});

export const updatePurchaseOrderItem = (data, id, purchaseOrderObj) => ({
  type: UPDATE_PURCHASE_ORDER_ITEM,
  payload: { data, id, purchaseOrderObj },
});

export const updatePurchaseOrderItemSuccess = (result) => ({
  type: UPDATE_PURCHASE_ORDER_ITEM_SUCCESS,
  payload: result,
});

export const updatePurchaseOrderShipment = (updateShipmentObj, id) => ({
  type: UPDATE_PURCHASE_ORDER_SHIPMENT,
  payload: { updateShipmentObj, id },
});

export const updatePurchaseOrderShipmentSuccess = (res) => ({
  type: UPDATE_PURCHASE_ORDER_SHIPMENT_SUCCESS,
  payload: res,
});

export const setAdjustmentModal = (bool) => {
  return { type: SET_ADJUSTMENT_MODAL, payload: bool };
};
export const setShipmentModal = (bool) => {
  return { type: SET_SHIPMENT_MODAL, payload: bool };
};
export const setPOLogsModal = (bool) => {
  return { type: SET_PO_LOGS_MODAL, payload: bool };
};

export const resetFetchRatesError = () => ({ type: RESET_FETCH_RATES_ERR, payload: {} });

export const resetRates = () => ({ type: RESET_RATES, payload: {} });

export const updateInventoryItemBins = (payload) => ({ type: UPDATE_INVENTORY_ITEM_BINS, payload });

export const updateInventoryItemBinsSuccess = (res) => ({ type: UPDATE_INVENTORY_ITEM_BINS_SUCCESS, payload: res });

export const deleteInventoryItemBin = (payload) => ({ type: DELETE_INVENTORY_ITEM_BIN, payload });

export const deleteInventoryItemBinSuccess = (res) => ({ type: DELETE_INVENTORY_ITEM_BIN_SUCCESS, payload: res });

export const updateBulkInventoryItems = (ids, data, props = { filters: { per_page: 20, page: 1 } }) => ({
  type: BULK_UPDATE_INVENTORY_ITEM,
  payload: { ids, data, props },
});

export const updateBulkInventoryItemsSuccess = (res) => ({ type: BULK_UPDATE_INVENTORY_ITEM_SUCCESS, payload: res });

// Warehouse Dashboard
export const searchOrder = (search) => ({ type: SEARCH_ORDER, payload: search });
export const searchOrderSuccess = (data) => ({ type: SEARCH_ORDER_SUCCESS, payload: data });

export const updateTrackingItemShippingStatus = (id) => ({
  type: UPDATE_TRACKING_ITEM_SHIPPPING_STATUS,
  payload: { id },
});

export const updateTrackingItemShippingStatusSuccess = (res) => ({
  type: UPDATE_TRACKING_ITEM_SHIPPPING_STATUS_SUCCESS,
  payload: res,
});

// Inbounds
export const fetchInbounds = (data = {}) => ({ type: FETCH_INBOUND_ITEMS, payload: data });
export const fetchInboundsSuccess = (data) => ({ type: FETCH_INBOUND_ITEMS_SUCCESS, payload: data });
export const setAddShipmentModal = (bool) => ({ type: SET_ADD_SHIPMENT_MODEL, payload: bool });
export const setStoreBinsShipmentModel = (bool) => ({ type: SET_STORE_BINS_MODEL, payload: bool });
export const addShipment = (data) => ({ type: ADD_SHIPMENT, payload: data });
export const addShipmentSuccess = (data) => ({ type: ADD_SHIPMENT_SUCCESS, payload: data });
export const shipmentsCheckedIn = (data, filters) => ({ type: SHIPMENT_CHECKED_IN, payload: { data, filters } });
export const shipmentsCheckedInSuccess = (data) => ({ type: SHIPMENT_CHECKED_IN_SUCCESS, payload: data });
export const setReceivedShipmentModel = (bool) => ({ type: SET_RECEIVED_SHIPMENT_MODEL, payload: bool });
export const receivedShipment = (data, filters) => ({ type: RECEIVE_SHIPMENT, payload: { data, filters } });
export const receivedShipmentSuccess = (data) => ({ type: RECEIVE_SHIPMENT_SUCCESS, payload: data });
export const setInboundShipment = (data) => ({ type: SET_INBOUND_SHIPMENT, payload: data });
export const fetchWHInboundStats = () => ({ type: FETCH_WH_INBOUND_STATS });
export const fetchWHInboundStatsSuccess = (res) => ({ type: FETCH_WH_INBOUND_STATS_SUCCESS, payload: res });
export const fetchInboundIsLiquated = (data) => ({ type: types.FETCH_INBOUND_IS_LIQUATED, payload: data });
export const fetchInboundIsLiquatedSuccess = (data) => ({ type: types.FETCH_INBOUND_IS_LIQUATED_SUCCESS, payload: data });

// Warehouse Invetory items
export const updateInventoryItems = () => ({
  type: types.UPDATE_INVENTORY_ITEMS,
  payload: {},
});
export const fetchWHInventory = (data = {}) => ({ type: FETCH_WH_INVENTORY, payload: data });
export const fetchWHInventorySuccess = (data) => ({ type: FETCH_WH_INVENTORY_SUCCESS, payload: data });
export const fetchInventoryShipmentWise = (data = {}) => ({ type: types.FETCH_INVENTORY_SHIPMENT_WISE, payload: data });
export const fetchInventoryShipmentWiseSuccess = (data) => ({ type: types.FETCH_INVENTORY_SHIPMENT_WISE_SUCCESS, payload: data });
export const fetchWHInventoryStats = () => ({ type: FETCH_WH_INVENTORY_STATS });
export const fetchWHInventoryStatsSuccess = (data) => ({ type: FETCH_WH_INVENTORY_STATS_SUCCESS, payload: data });
export const setBinDetailModal = (bool) => ({ type: SET_BIN_DETAIL_MODAL, payload: bool });
export const setItemActionsModal = (bool) => ({ type: types.SET_ITEM_ACTIONS_MODAL, payload: bool });
export const fetchWHInventoryAction = (data) => ({ type: types.FETCH_WH_INVENTORY_ACTIONS, payload: data });
export const fetchStrandedCount = (data = {}) => ({ type: types.FETCH_STRANDED_COUNT, payload: data });
export const fetchStrandedCountSuccess = (data) => ({ type: types.FETCH_STRANDED_COUNT_SUCCESS, payload: data });
export const fetchWHInventoryActionSuccess = (data) => ({
  type: types.FETCH_WH_INVENTORY_ACTIONS_SUCCESS,
  payload: data,
});
export const markAsCompleteItem = (itemId, statType) => ({ type: types.MARK_AS_COMPLETE_ITEM, payload: { itemId, statType } });
export const markAsCompleteItemSuccess = (data) => ({ type: types.MARK_AS_COMPLETE_ITEM_SUCCESS, payload: data });
export const bulkMarkAsCompleteItem = (ids, params) => ({ type: types.BULK_MARK_AS_COMPLETE, payload: { ids, params } });
export const bulkMarkAsCompleteItemSuccess = (data) => ({ type: types.BULK_MARK_AS_COMPLETE_SUCCESS, payload: data });
export const updateInventoryOversized = (payload) => ({ type: types.UPDATE_INVENTORY_OVERSIZED, payload });
export const updateInventoryOversizedSuccess = (payload) => ({
  type: types.UPDATE_INVENTORY_OVERSIZED_SUCCESS,
  payload,
});
export const updateInventoryItemPrice = (id, body) => ({
  type: types.UPDATE_INVENTORY_ITEM_PRICE,
  payload: { id, body },
});
export const updateInventoryItemPriceSuccess = (payload) => ({
  type: types.UPDATE_INVENTORY_ITEM_PRICE_SUCCESS,
  payload,
});
export const deleteInventoryBin = (id, recShipId) => ({
  type: types.DELETE_WH_INVENTORY_BIN,
  payload: { id, recShipId },
});
export const deleteInventoryBinSucess = (payload) => ({ type: types.DELETE_WH_INVENTORY_BIN_SUCCESS, payload });
export const updateInventoryBin = (id, recShipId, body, isShipmentWise) => ({
  type: types.UPDATE_WH_INVENTORY_BIN,
  payload: { id, recShipId, body, isShipmentWise },
});
export const updateInventoryBinSuccess = (payload) => ({ type: types.UPDATE_WH_INVENTORY_BIN_SUCCESS, payload });
export const createInventoryBin = (recShipId, body, isShipmentWise) => ({
  type: types.CREATE_WH_INVENTORY_BIN,
  payload: { recShipId, body, isShipmentWise },
});
export const createInventoryBinSuccess = (payload) => ({ type: types.CREATE_WH_INVENTORY_BIN_SUCCESS, payload });
export const deleteInventoryItem = (trackingNumber) => ({
  type: types.DELETE_INVENTORY_ITEM,
  payload: { body: { trackingNumber } },
});
export const deleteInventoryItemSucess = (payload) => ({ type: types.DELETE_INVENTORY_ITEM_SUCCESS, payload });

// Warehouse Outbound items
export const fetchWHOutbounds = (data = {}) => ({ type: FETCH_WH_OUTBOUNDS, payload: data });
export const fetchWHOutboundsSuccess = (data) => ({ type: FETCH_WH_OUTBOUNDS_SUCCESS, payload: data });
export const generateShippingLabelToggle = (bool) => ({ type: GENERATE_SHIPPING_LABEL_TOGGLE, payload: bool });
export const fetchWhOutboundStats = (data = {}) => ({ type: FETCH_WH_OUTBOUND_STATS, payload: data });
export const fetchWhOutboundStatsSuccess = (data) => ({ type: FETCH_WH_OUTBOUND_STATS_SUCCESS, payload: data });
export const downloadOutboundLabel = (id, params) => ({ type: types.DOWNLOAD_OUTBOUND_LABEL, payload: { id, params } });
export const downloadOutboundLabelSuccess = (payload) => ({ type: types.DOWNLOAD_OUTBOUND_LABEL_SUCCESS, payload });
export const deleteOutboundItem = (id, body) => ({ type: types.DELETE_OUTBOUND_ITEM, payload: { id, body } });

export const updateReturnOutbounds = ({ id, data, showSuccess, filters }) => {
  return {
    type: types.UPDATE_RETURNS_OUTBOUND,
    payload: { id, data, showSuccess, filters },
  };
};

export const updateReturnOutboundsSuccess = (res) => {
  return {
    type: types.UPDATE_RETURNS_OUTBOUND_SUCCESS,
    payload: res,
  };
};

export const deleteOutboundItemSucess = (payload) => ({ type: types.DELETE_OUTBOUND_ITEM_SUCCESS, payload });

// Label Generation Stats
export const fetchLabelGenerationStats = (filters = {}) => ({ type: FETCH_LABEL_USERS_AND_COUNT, payload: filters });
export const fetchLabelGenerationStatsSuccess = (res) => ({ type: FETCH_LABEL_USERS_AND_COUNT_SUCCESS, payload: res });

// Scanforms
export const fetchShippingScanforms = (filters = {}) => ({ type: types.FETCH_SHIPPING_SCANFORMS, payload: filters });
export const fetchShippingScanformsSuccess = (res) => ({ type: types.FETCH_SHIPPING_SCANFORMS_SUCCESS, payload: res });
export const generateScanform = (data) => ({ type: types.GENERATE_SCANFORM, payload: data });
export const generateScanformSuccess = (res) => ({ type: types.GENERATE_SCANFORM_SUCCESS, payload: res });
export const setGenerateScanformModal = (res) => ({ type: types.SET_GENERATE_SCANFORM_MODAL, payload: res });
export const downloadFormFile = (url, id) => ({ type: types.DOWNLOAD_FORM_FILE, payload: { url, id } });
export const downloadFormFileSuccess = (res) => ({ type: types.DOWNLOAD_FORM_FILE_SUCCESS, payload: res });
export const fetchScanformShipments = (filters = {}) => ({ type: types.FETCH_SCANFORM_SHIPMENTS, payload: filters });
export const emptyDownloadFileURL = _ => ({ type: types.EMPTY_DOWNLOAD_FORM_FILE, payload: {} });
export const fetchScanformShipmentsSuccess = (res) => ({ type: types.FETCH_SCANFORM_SHIPMENTS_SUCCESS, payload: res });

export const resetSuccessErr = () => ({ type: types.RESET_SUCCESS_ERROR });
export const resetIsLiquated = () => ({ type: types.RESET_INBOUND_IS_LIQUATED });
export const apiError = (error) => ({ type: API_ERROR, payload: error });
