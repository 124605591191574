export const SET_EDIT_CREDIT_CARDS_MODAL = "SET_EDIT_CREDIT_CARDS_MODAL";
export const SET_ADD_CREDIT_CARDS_MODAL = "SET_ADD_CREDIT_CARDS_MODAL";
export const SELECT_CREDIT_CARD = "SELECT_CREDIT_CARD";
export const FETCH_CC_DETAILS = "FETCH_CC_DETAILS";
export const FETCH_FETCH_CC_DETAILS_SUCCESS = "FETCH_FETCH_CC_DETAILS_SUCCESS";
export const FETCH_CARD_TYPES = "FETCH_CARD_TYPES";
export const FETCH_CARD_TYPES_SUCCESS = "FETCH_CARD_TYPES_SUCCESS";
export const ADD_CREDIT_CARDS = "ADD_CREDIT_CARDS";
export const ADD_CREDIT_CARDS_SUCCESS = "ADD_CREDIT_CARDS_SUCCESS";
export const UPDATE_CREDIT_CARDS = "UPDATE_CREDIT_CARDS";
export const UPDATE_CREDIT_CARDS_SUCCESS = "UPDATE_CREDIT_CARDS_SUCCESS";
export const DELETE_CREDIT_CARD = "DELETE_CREDIT_CARD";
export const DELETE_CREDIT_CARD_SUCCESS = "DELETE_CREDIT_CARD_SUCCESS";
export const API_ERROR = "FETCH_FETCH_CC_DETAILS_ERROR";
export const SET_CREDIT_CARDS_FILTERS = "SET_CREDIT_CARDS_FILTERS";
export const UPDATE_CREDIT_CARDS_PRIORITY = "UPDATE_CREDIT_CARDS_PRIORITY";
export const UPDATE_CREDIT_CARDS_PRIORITY_SUCCESS = "UPDATE_CREDIT_CARDS_PRIORITY_SUCCESS";
