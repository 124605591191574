import React, { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// actions
import { fetchAgencyStats, fetchAgencyClientsProfit, setPreloader, fetchDailyReport } from "../../store/actions";
import AgencyClients from "./AgencyComponents/AgencyClients";
import PaymentSourceSettings from "./AgencyComponents/PaymentSourceSettings";
import PaymentMethod from "../Settings/tabs/PaymentMethod";
import StoreFrontHealth from "./AgencyComponents/StoreFrontHealthMatrics";
import OrderStatusStats from "./components/OrderStatusStats";

// Import Components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { USER_ROLES } from "../../constants";
import { getUserInfo, isAdmin, RenderIf, toPascalCase, humanize } from "../../utils/common";
import WelcomeCard from "./AgencyComponents/WelcomeCard";
import RevenueProfitGraph from "./AgencyComponents/RevenueProfitGraph";
import AgencyOrders from "./AgencyComponents/AgencyOrdersGraph";
import OtherSettings from "./AgencyComponents/OtherSettings";
import StorageChargesSettings from "./AgencyComponents/StorageChargesSettings";
import StringEditor from "./components/StringEditor";

const AgencyDashboard = () => {
  const dispatch = useDispatch();
  const { loading, stats: agencyStats, error, clients, archived } = useSelector((state) => state.Agency);
  const { loading: paymentLoading } = useSelector((state) => state.Stripe);
  const {
    account,
    agencies,
    accountsLoading,
    loading: settingsLoading,
    success,
  } = useSelector((state) => state.Settings);
  const { dailyReport, dailyReportLoading } = useSelector((state) => state.Graph);
  const { timezone } = useSelector((state) => state.Layout);
  const [errorMsg, setErrorMsg] = useState(error);
  const [isEditable, setIsEditable] = useState(false);
  const isAllowed = [USER_ROLES.owner, USER_ROLES.admin].includes(getUserInfo()?.role);
  const isAgencyEmail = (x) => x.email === getUserInfo().email;
  const getAgencyData = (_) => agencies?.data?.find((x) => isAgencyEmail(x));
  const stats = [
    {
      title: "Agency Name",
      iconClass: "bx bx-crown",
      description: (
        <RenderIf isTrue={getAgencyData()?.agency_name} fallback={"N/A"}>
          <StringEditor
            index={1}
            id={getAgencyData()?.id}
            string={toPascalCase(getAgencyData()?.agency_name) || "N/A"}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            hideString={false}
          />
        </RenderIf>
      ),
    },
    {
      title: "Total Clients",
      iconClass: "bx bx-user",
      description: humanize(agencyStats?.total_clients || "0"),
    },
    {
      title: "Sub Users",
      iconClass: "bx bx-user-pin",
      description: humanize(agencyStats?.sub_users || "0"),
    },
    {
      title: "Total Stores",
      iconClass: "bx bx-cart-alt",
      description: humanize(
        clients
          ?.reduce((acc, client) => acc + client.marketplaces?.filter((x) => x.valid)?.length || 0, 0)
          .toFixed(2) || "0",
      ),
    },
    {
      title: "Average Agency %",
      iconClass: "bx bx-cart-alt",
      description: `${humanize(agencyStats?.average_agency_profit || "0")}%`,
    },
  ];

  const fetchReport = (id) => {
    dispatch(
      fetchDailyReport({
        marketplace_id: id,
        date: moment().format("YYYY-MM-DD"),
        utc_offset: moment().format("Z"),
        archived,
      }),
    );
  };

  useEffect(() => {
    if (isAllowed) {
      fetchReport();
    }
  }, [archived]);

  useEffect(() => {
    if (isAllowed) {
      dispatch(fetchAgencyStats());
      clients?.length && dispatch(fetchAgencyClientsProfit());
    }
  }, [clients]);

  useEffect(() => {
    setErrorMsg(error);
    setTimeout(() => setErrorMsg(""), 5_000);
  }, [error]);

  useEffect(() => {
    dispatch(setPreloader(loading || paymentLoading || dailyReportLoading));
  }, [loading, paymentLoading, dailyReportLoading]);

  return (
    <React.Fragment>
      <div className="page-content">
        {isAllowed ? (
          <Container fluid>
            {errorMsg && (
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {errorMsg}
              </Alert>
            )}
            {success && (
              <div className="auto-hide">
                <Alert color="success">
                  <i className="bx bx-info-circle pr-2"></i>
                  {success}
                </Alert>
              </div>
            )}
            {/* Render Breadcrumb */}
            <Breadcrumbs title="My Agency Dashboard" />{" "}
            <NewAgencyDashboard stats={stats} timezone={timezone} dailyReport={dailyReport} />
            <PaymentSourceSettings />
            <RenderIf isTrue={isAdmin()}>
              <StorageChargesSettings />
            </RenderIf>
            <PaymentMethod account={account} />
            <OtherSettings account={account} />
            <AgencyClients />
            <StoreFrontHealth />
          </Container>
        ) : (
          !loading &&
          !settingsLoading &&
          !accountsLoading && (
            <h3 className="text-center">Welcome to Ecom Circles. Please switch accounts using Header DropDown</h3>
          )
        )}
      </div>
    </React.Fragment>
  );
};

const NewAgencyDashboard = ({ stats, timezone, dailyReport }) => {
  return (
    <>
      <Row>
        <Col xl="4">
          <WelcomeCard stats={stats} />
        </Col>
        <Col xl="8">
          <RevenueProfitGraph />
        </Col>
      </Row>
      <Row>
        <Col xl="8">
          <AgencyOrders />
        </Col>
        <Col xl="4">
          <OrderStatusStats timezone={timezone} statuses={dailyReport?.data?.status} />
        </Col>
      </Row>
    </>
  );
};

export default AgencyDashboard;
