import { head } from "lodash";
import { deleteTokenFromCookie, encode, getUserInfo, setAuthTokenInBrowser } from "../utils/common";
import CustomError from "../utils/CustomError";
const { REACT_APP_BASE_URL } = process.env;

class UnauthorizedError extends Error {
  constructor (message, id) {
    super(message);
    this.id = id;
    this.name = this.constructor.name;
  }
}

const responseHandler = (x, download) => download ? downloadFile(x) : handleResponse(x);

const handleAddNewListing = async ({ fetchArgs, download }) => {
  try {
    const promise2 = new Promise((resolve) => setTimeout(resolve, 10000, false));
    return await Promise.any([fetch(...fetchArgs), promise2]).then(res => res && responseHandler(res, download));
  } catch {}
};

const Request = ({
  url,
  method,
  body,
  baseUrl = true,
  formData,
  matchingAuth,
  oauth,
  download = false,
  contentType = "application/json",
}) => {
  if (!url) throw new Error("url required");
  if (!method) method = "GET";
  const requestOptions = {
    headers: {
      Authorization: matchingAuth
        ? matchingAuthHeader()
        : oauth === "true"
          ? oauthHeader()
          : oauth === "false"
            ? ""
            : auth(),
      ...(formData ? {} : { "Content-Type": contentType }),
      Accept: "application/json",
    },
    method,
  };
  if (formData) requestOptions.body = formData;
  if (body && contentType === "application/json") requestOptions.body = JSON.stringify(body);
  else if (body) requestOptions.body = body;
  const fetchArgs = [(baseUrl ? REACT_APP_BASE_URL : "") + url, requestOptions];
  return head(url.split("?")) === "/matches/fetch_marketplace_product_info"
    ? handleAddNewListing({ fetchArgs, download })
    : fetch(...fetchArgs).then(res => responseHandler(res, download));
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    let data;
    try {
      data = text && JSON.parse(text);
    } catch (error) {}
    if ((!response.ok && !data?.success) || !response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("authTokenJwt");
        localStorage.removeItem("I6fiFamLVmL");
        deleteTokenFromCookie();
        if (!document.location.href.includes("/login")) document.location.href = "/login";
      }
      const error = data?.error?.message || data?.error || data?.message || data?.detail || data?.msg || response?.statusText;
      const customError = new CustomError(error, data);

      if (data?.id) throw new UnauthorizedError(error, data.id);
      else throw customError;
    }
    if (data?.authTokenJwt) setAuthTokenInBrowser(data?.authTokenJwt);
    return data || text;
  });
};

function auth () {
  const token = localStorage.getItem("authTokenJwt");

  if (token) {
    return "Bearer " + token;
  } else {
    return "";
  }
}

function matchingAuthHeader () {
  return "Basic " + encode(getUserInfo()?.email + ":123");
}

function oauthHeader () {
  return "Basic " + encode("oauthWithPermission:bf&mD<55L");
}

async function downloadFile (response) {
  if (response.status !== 200) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = data?.error?.message || data.error || data.message || data.msg || response.statusText;
      throw new Error(error);
    });
  }

  const fileName = response.headers.get("Content-Disposition")?.split("filename=")?.pop() || "order_report.xlsx";
  download(await response.blob(), fileName);
}

function download (blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
  }, 3000);
}

export default Request;
