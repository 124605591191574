import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Alert } from "reactstrap";
import CustomPagination from "../../components/Common/CustomPagination";
import { setAddProxyUserModel, fetchProxyUsers } from "../../store/actions";
import ProxyUserModel from "./components/ProxyUserModel";

const initPage = { page: 1, per_page: 30 };

const ProxyUsers = (props) => {
  const tableHeaders = [{ title: "Email" }, { title: "Proxy Credits" }, { title: "Action" }];
  const { data, success, message, shouldRefresh } = useSelector((state) => state.ProxyUsers);
  const [search, setSearch] = useState("");
  const [type, setType] = useState({ modelType: "", email: "", total_credits: "" });
  const [filters, setFilters] = useState(initPage);

  useEffect(() => {
    shouldRefresh && props.fetchProxyUsers(filters);
  }, [shouldRefresh]);

  useEffect(() => {
    props.fetchProxyUsers(filters);
  }, [filters]);

  return (
    <React.Fragment>
      <ProxyUserModel type={type} />
      <div className="page-content">
        <Card>
          {success && (
            <div className="auto-hide">
              <Alert color="success" role="alert">
                {message}
              </Alert>
            </div>
          )}
          <CardHeader>
            <div className="row d-flex align-items-center justify-content-between">
              <form
                className="app-search d-none d-lg-block"
                onSubmit={(e) => {
                  e.preventDefault();
                  setFilters({ ...filters, email: search });
                }}
              >
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setSearch("");
                        setFilters({ ...filters, email: search });
                      } else {
                        setSearch(e.target.value.trim());
                      }
                    }}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form>
              <div className="d-flex justify-content-end mr-10">
                <Button
                  color="primary m-1"
                  onClick={() => {
                    setType({
                      modelType: "add",
                    });
                    props.setAddProxyUserModel(true);
                  }}
                >
                  <i className="bx bx-plus align-middle mr-1"></i>
                  Add
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {data?.accounts.length ? (
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {tableHeaders.map((header, index) => (
                        <th className={/action/i.test(header.title) ? "text-center" : ""} key={`table-header ${index}`}>
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.accounts.map((item, key) => (
                      <tr key={key}>
                        <td>{item.email}</td>
                        <td>{item.total_credits}</td>
                        <td className="text-center">
                          <Button
                            color="info"
                            onClick={() => {
                              setType({
                                modelType: "update",
                                email: item.email,
                                total_credits: item.total_credits,
                              });
                              props.setAddProxyUserModel(true);
                            }}
                          >
                            Update Password
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h4 className="text-center">No Records Found</h4>
            )}
            {data?.accounts.length
              ? (
              <CustomPagination
                total={data?.total_count}
                page={data?.page}
                perPage={data?.perPage}
                tabsFilter={filters}
                setTabFilter={setFilters}
                pageOptions={[30, 100]}
              />
                )
              : null}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { ProxyUsers } = state;
  return ProxyUsers;
};

export default connect(mapStatetoProps, {
  setAddProxyUserModel,
  fetchProxyUsers,
})(ProxyUsers);
