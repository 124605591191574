import { Card, CardBody } from "reactstrap";
import { RenderIf } from "../../../utils/common";
import classnames from "classnames";
import React from "react";

const RepricerListingStatCard = ({ data: { FallbackComponent, ...data } }) => {
  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 d-flex align-items-stretch">
      <Card className="w-100">
        <CardBody style={{ padding: "0.5rem 0.75rem" }}>
          <div className="h-100 d-flex flex-column justify-content-center gap-2">
            <RenderIf isTrue={data.title}>
              <span className="text-muted font-size-14 font-weight-medium" style={{ alignSelf: "auto" }}>
                {data.title}
              </span>
            </RenderIf>
            {FallbackComponent ? (
              <FallbackComponent />
            ) : (
              <div className="d-flex align-items-start gap-3 overflow-auto">
                {data.subStats.map(({ ExtraComponent, ...subData }, index) => (
                  <div
                    key={`__sub__stat__${subData.title}__${index}`}
                    className="h-100 d-flex flex-column justify-content-between"
                  >
                    <div className="d-flex align-items-center gap-1 text-muted font-size-12 font-weight-medium">
                      <span style={{ maxWidth: "14ch" }}>{subData.title}</span>
                      <RenderIf isTrue={subData.onClick}>
                        <i
                          className="bx bx-right-arrow-circle text-primary font-size-16 cursor-pointer"
                          onClick={subData.onClick}
                        ></i>
                      </RenderIf>
                    </div>
                    <div className="d-flex align-items-end gap-2">
                      <span
                        className={classnames("font-size-18 font-weight-semibold", {
                          [subData.valueColorClass]: subData.valueColorClass,
                          "inventory-color": !subData.valueColorClass,
                        })}
                      >
                        {subData.isCurrency && "$"}
                        {subData.value}
                      </span>
                      {ExtraComponent && <ExtraComponent />}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RepricerListingStatCard;
