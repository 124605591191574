import {
  FETCH_AMAZON_SELLERS,
  FETCH_AMAZON_SELLERS_SUCCESS,
  SET_AMAZON_SELLERS_FILTERS,
  CREATE_AMAZON_SELLER,
  CREATE_AMAZON_SELLER_SUCCESS,
  UPDATE_AMAZON_SELLER,
  UPDATE_AMAZON_SELLER_SUCCESS,
  SET_ADD_AMAZON_SELLER_MODAL,
  SET_SUCCESS_MESSAGE,
  API_ERROR,
} from "./actionTypes";

export const fetchAmazonSellers = (params) => {
  return {
    type: FETCH_AMAZON_SELLERS,
    payload: { params },
  };
};

export const fetchAmazonSellersSuccess = (sellers) => {
  return {
    type: FETCH_AMAZON_SELLERS_SUCCESS,
    payload: sellers,
  };
};

export const createAmazonSeller = ({ data }) => {
  return {
    type: CREATE_AMAZON_SELLER,
    payload: { data },
  };
};

export const createAmazonSellerSuccess = () => {
  return {
    type: CREATE_AMAZON_SELLER_SUCCESS,
    payload: {},
  };
};

export const updateAmazonSeller = ({ data }) => {
  return {
    type: UPDATE_AMAZON_SELLER,
    payload: { data },
  };
};

export const updateAmazonSellerSuccess = () => {
  return {
    type: UPDATE_AMAZON_SELLER_SUCCESS,
    payload: {},
  };
};

export const setAddAmazonSellerModal = (bool) => {
  return {
    type: SET_ADD_AMAZON_SELLER_MODAL,
    payload: bool,
  };
};

export const setSuccessMessage = (message) => {
  return {
    type: SET_SUCCESS_MESSAGE,
    payload: message,
  };
};

export const setAmazonSellersFilter = (filters) => {
  return {
    type: SET_AMAZON_SELLERS_FILTERS,
    payload: filters,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
