import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Container } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DatePicker } from "../../components/Common/DatePickr";
import SimpleSelect from "../../components/Common/SimpleSelect";
import { fetchAllWarehouses, fetchLabelGenerationStats, setPreloader } from "../../store/actions";
import { getUserInfo, getWarehouseName, isAdmin, RenderIf } from "../../utils/common";

const LabelGenerationStats = () => {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const { loading, labelGenerationStats, wareHouses } = useSelector((state) => state.WareHouses);
  const isAllowed = isAdmin();

  useEffect(() => {
    dispatch(fetchLabelGenerationStats(filters));
    isAllowed && dispatch(fetchAllWarehouses());
  }, [filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  const grpWarehouses = keyBy(wareHouses?.data, "id");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Label Generation Statistics"} />
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-start">
                <div className="ml-3">
                  <DatePicker
                    onClose={(dates) => setFilters({ ...filters, ...dates })}
                    placeholder="Select Date Range"
                  />
                </div>
                <div className="ml-3 mt-3" style={{ width: "230px" }}>
                  <SimpleSelect
                    name="user"
                    placeholder="Select A Warehouse User"
                    options={labelGenerationStats?.users?.reduce(
                      (list, user) => {
                        list.push({ value: user, label: user });
                        return list;
                      },
                      [{ value: "", label: "Select A Warehouse User" }],
                    )}
                    onChange={(e) => setFilters({ ...filters, user: e.value })}
                  />
                </div>
                <RenderIf isTrue={getUserInfo()?.isWH}>
                  <div className="ml-3 mt-3">
                    <p className="badge-pill badge-soft-success">{`Warehouse # ${getUserInfo()?.warehouse_id}`}</p>
                  </div>
                </RenderIf>
              </div>
            </CardHeader>
            <CardBody>
              <>
                <div className="table-responsive">
                  <RenderIf
                    isTrue={Array.isArray(labelGenerationStats?.data) && labelGenerationStats?.data.length}
                    fallback={
                      <div className="d-flex justify-content-center m-2">
                        <div className="d-flex flex-column">
                          <h1>No Records Found</h1>
                        </div>
                      </div>
                    }
                  >
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>{"User"}</th>
                          {isAllowed && <th>{"Warehouse"}</th>}
                          <th>{"Total Labels Generated"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {labelGenerationStats?.data?.map((row, key) => (
                          <tr key={`__labelGenTRow${key}__`}>
                            <td>{row.author || ""}</td>
                            <RenderIf isTrue={isAllowed}>
                              <td>{getWarehouseName(grpWarehouses[row.warehouse_id]) || "N/A"}</td>
                            </RenderIf>
                            <td>{row.label_count || ""}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </RenderIf>
                </div>
              </>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LabelGenerationStats;
