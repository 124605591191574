import { makeQP } from "../../utils/common";
import request from "../restClient";

export const getAgencyStats = async (_) => await request({ url: "/agency/stats", method: "GET" });
export const getAgencyClients = async (params) =>
  await request({ url: "/agency/clients" + makeQP(params), method: "GET" });
export const getAgencyClientsProfit = async (_) => await request({ url: "/agency/clients_profit", method: "GET" });
export const updatePaymentSettings = async ({ data: body, id }) =>
  await request({ url: `/agency/payment_settings/${id ?? ""}`, method: "PATCH", body });
export const removeClient = async (id) => await request({ url: `/agency/clients/${id}`, method: "DELETE" });
export const getTenantsStats = async (_) => await request({ url: "/agency/agency_stats", method: "GET" });
export const getStorefrontHealth = async (email) =>
  await request({ url: `/agency/storefront_health_matrics/${email}`, method: "GET" });
export const archiveClients = (params) => request({ url: "/agency/archive_client" + makeQP(params), method: "PUT" });
