import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllUnverifiedMatches,
  getMarketPlaceCategories,
  getMarketPlaceStores,
  listUnverifiedMatches,
  submitMatchesRequest,
} from "../../api";
// Login Redux States
import {
  FETCH_UNVERIFIED_MATCHES,
  LIST_UNVERIFIED_MATCHES,
  FETCH_MARKET_PLACE_CATEGORIES,
  FETCH_MARKET_PLACE_STORES,
  SUBMIT_UNVERIFIED_MATCHES_REQUEST,
} from "./actionTypes";

import {
  apiError,
  fetchUnverifiedMatchesSuccess,
  fetchMarketPlaceCategoriesSuccess,
  listUnverifiedMatchesSuccess,
  fetchMarketPlaceStoresSuccess,
  updateListingsDataSuccess,
  submitUnverifiedMatchesRequestSuccess,
} from "./actions";

import { omit } from "lodash";

const UnpublishedListings = (state) => state.UnpublishedListings;

function * fetchUnverifiedMatchesSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllUnverifiedMatches, params);
    yield put(fetchUnverifiedMatchesSuccess(res?.matches));
  } catch (error) {
    yield put(apiError(error?.message || ""));
  }
}

function * listUnverifiedMatchesSaga ({ payload }) {
  try {
    const res = yield call(listUnverifiedMatches, omit(payload, ["category", "salesRank", "perPage"]));
    if (res.success) {
      let { listingsData } = yield select(UnpublishedListings);
      listingsData = listingsData?.filter((acc) => !payload.ids.includes(acc.id));
      yield put(updateListingsDataSuccess(listingsData));
      if (listingsData.length === 0) {
        const response = yield call(getAllUnverifiedMatches, omit(payload, ["ids"]));
        yield put(fetchUnverifiedMatchesSuccess(response?.matches));
      }
    }
    yield put(
      listUnverifiedMatchesSuccess(
        payload?.ids?.length === 1 ? "Item listed successfully" : "Items listed successfully",
      ),
    );
  } catch (error) {
    yield put(apiError(error?.message || ""));
  }
}

function * marketPlaceCategoriesSaga ({ payload }) {
  try {
    const res = yield call(getMarketPlaceCategories, payload);
    yield put(fetchMarketPlaceCategoriesSuccess(res?.categories));
  } catch (error) {
    yield put(apiError(error?.message || ""));
  }
}

function * marketPlaceStoresSaga ({ payload }) {
  try {
    const res = yield call(getMarketPlaceStores, payload);
    yield put(fetchMarketPlaceStoresSuccess(res?.data));
  } catch (error) {
    yield put(apiError(error || error?.message || ""));
  }
}

function * matchesRequestsSaga ({ payload }) {
  try {
    const res = yield call(submitMatchesRequest, payload);
    yield put(submitUnverifiedMatchesRequestSuccess(res));
  } catch (error) {
    yield put(apiError(error?.message || ""));
  }
}

export function * watchUnpublishedListingFetch () {
  yield takeEvery(FETCH_UNVERIFIED_MATCHES, fetchUnverifiedMatchesSaga);
}

export function * watchListUnverifiedMatchesSaga () {
  yield takeEvery(LIST_UNVERIFIED_MATCHES, listUnverifiedMatchesSaga);
}

export function * watchMarketPlaceCategories () {
  yield takeEvery(FETCH_MARKET_PLACE_CATEGORIES, marketPlaceCategoriesSaga);
}

export function * watchMarketPlaceStores () {
  yield takeEvery(FETCH_MARKET_PLACE_STORES, marketPlaceStoresSaga);
}

export function * watchMatchesRequests () {
  yield takeEvery(SUBMIT_UNVERIFIED_MATCHES_REQUEST, matchesRequestsSaga);
}

function * unpublishedListingSaga () {
  yield all([
    fork(watchUnpublishedListingFetch),
    fork(watchMarketPlaceCategories),
    fork(watchListUnverifiedMatchesSaga),
    fork(watchMarketPlaceStores),
    fork(watchMatchesRequests),
  ]);
}

export default unpublishedListingSaga;
