import React from "react";
import { Link } from "react-router-dom";
import { mapTrackingUrl } from "../../utils/trackingUrlMap";

const TrackingURL = ({ carrier, tracking_num: trackingNum }) => (
  <Link to={{ pathname: mapTrackingUrl(carrier, trackingNum) }} target="_blank">
    {trackingNum}
  </Link>
);

export default TrackingURL;
