import React, { useState } from "react";
import { formatNumber, getUserInfo, isAODev, isManualTeam, parseAOAttemptDate, RenderIf } from "../../../utils/common";
import {
  DEV_IDS,
  AO_DEV_IDS,
  SOURCE_ORDER_STATUSES,
  MATCHING_SYSTEM_SUPPLIERS,
  ISSUE_OPTIONS,
  DATE_FORMATS,
} from "../../../constants";
import { CardBody, Card, Collapse } from "reactstrap";
import moment from "moment-timezone";
import ImageUi from "../../../components/Common/ImageUI";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Modal from "../../../components/Common/Modal";
import { getScreenshots, getLogs } from "../../../api/aws";
import getBrandImageSrc from "../../../utils/brandImages";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import CustomTooltip from "../../../components/Common/CustomTooltip";

const { FULL_DATE } = DATE_FORMATS;

function filterAttempts (sourceOrder) {
  return (sourceOrder?.order_attempts || []).filter(
    (attempt) =>
      attempt.is_ao === true &&
      (attempt.error_prefix === "Internal Server Error" &&
      !attempt.error_description?.includes("order is errored after order confirmation")
        ? AO_DEV_IDS.concat([518, 27])?.includes(getUserInfo()?.id)
        : true),
  );
}

function isTopdawgOrderCancelled (attempts, sourceOrder) {
  if (!attempts?.length || !sourceOrder) return;
  const { errored, cancelled } = SOURCE_ORDER_STATUSES;
  return (
    attempts.some((attempt) => attempt.success && attempt.platform === MATCHING_SYSTEM_SUPPLIERS.topdawg) &&
    (sourceOrder.status === errored || sourceOrder.issue_reason === ISSUE_OPTIONS["Supplier Cancelled Order"]) &&
    sourceOrder.supplier_orders?.some((x) => x.status === cancelled)
  );
}

function shoudDisplay (sourceOrder) {
  return (
    isAODev() &&
    (sourceOrder?.order_attempts || [])?.filter((attempt) => attempt.is_ao === true && !attempt.success).length
  );
}

function shoudDisplayScreenShot (attempts) {
  return attempts.some((x) => x.screenshots_url);
}

function parseSellerName (attempt) {
  return attempt?.amazon_seller?.name || (/amazon/i.test(attempt?.seller_id) ? "Amazon" : "");
}

function AoAttemptStatus ({ attempt }) {
  let status;
  if (attempt?.in_progress) status = "Order is in Progress";
  else if (attempt?.success) status = <b>Order Placed Successfully</b>;

  if (status) return status;
  else if (!attempt?.error_prefix) return <></>;

  return (
    <div>
      <span
        className={isManualTeam() ? "cursor-pointer" : ""}
        onClick={() => (isManualTeam() ? navigator.clipboard.writeText(attempt.error_prefix) : null)}
      >
        {attempt.error_prefix}
      </span>
      <span
        onClick={(node) => {
          node.target.nextSibling.style.display = "";
          node.target.innerHTML = " : ";
        }}
      >
        {" "}
        ...
      </span>
      <span
        style={{ display: "none" }}
        onClick={(node) => {
          node.target.style.display = "none";
          node.target.previousSibling.innerHTML = " ...";
        }}
      >
        {attempt?.error_description}
      </span>
    </div>
  );
}

function LogsModal (props) {
  const {
    logs,
    logsModal: { isOpen, attempt },
    setLogsModal,
  } = props;

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => setLogsModal({ isOpen: false })}>
      {!attempt.success && attempt.error_prefix
        ? (
        <i>
          <h3 className="mt-3 ml-3 text-danger">{attempt.error_prefix}</h3>
        </i>
          )
        : null}
      {attempt.success
        ? (
        <i>
          <h3 className="mt-3 ml-3 text-success">Order Placed Successfully</h3>
        </i>
          )
        : null}
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title" id="myLargeModalLabel">
          {attempt.id}: {attempt.last_used_purchasing_account_name} ({attempt.attempt})
        </h5>
        {parseSellerName(attempt) ? <h5>{parseSellerName(attempt)}</h5> : null}
        <h5 className="modal-title" id="myLargeModalLabel">
          {parseAOAttemptDate(attempt) || ""}
        </h5>
      </div>
      <div className="px-4" style={{ height: 800, overflow: "auto" }}>
        <table className="table table-centered table-nowrap mb-0">
          <tbody>
            {logs[attempt.id].map((log, i) => (
              <tr key={i}>
                {log.map((line, _i) => (
                  <td className={_i ? "" : "font-weight-bold"} key={_i}>
                    {_i ? line : moment(line).format(FULL_DATE)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

const OrderAttempts = ({ sourceOrder, platform, aoPrice, updateOrderAttempt, updateOrder }) => {
  const [selectedAttempt, setSelectedAttempt] = useState("");
  const [screenshotsCollapse, setScreenshotsCollapse] = useState(false);
  const [imageUrls, setImageUrls] = useState({});
  const [logs, setLogs] = useState({});
  const [logsModal, setLogsModal] = useState({ isOpen: false, attempt: null });
  if (filterAttempts(sourceOrder)?.length <= 0) return <></>;

  const handleFetchScreenShots = (attempt, fetchSS = false, key) => {
    async function fetchUrls (url) {
      const urls = await getScreenshots(url);
      return setImageUrls({ ...imageUrls, [key]: urls });
    }
    fetchSS && !imageUrls[key]?.length && fetchUrls(attempt.screenshots_url);
  };
  const handleFetchLogs = (attempt) => {
    async function fetchLogs (url) {
      const logsArr = await getLogs(url);
      return setLogs({ ...logs, [attempt.id]: logsArr });
    }
    !logs[attempt.id]?.length && fetchLogs(attempt.logs_url);
  };
  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <Breadcrumbs
          title={`AO Attempts${
            DEV_IDS.includes(getUserInfo()?.id) ? `(${formatNumber(aoPrice(sourceOrder, platform?.platforms?.data))})` : ""
          }`}
        />
        <RenderIf isTrue={isTopdawgOrderCancelled(filterAttempts(sourceOrder), sourceOrder)}>
          <>
            <CustomTooltip
              placement="top"
              content="Move order status to new for other AO attempts"
              target="refresh_icon"
            ></CustomTooltip>
            <div id="refresh_icon">
              <ConfirmDialogIcon
                icon={{
                  className: "bx-sm bx bx-sync warning ml-2 black-color",
                }}
                title="Move TOP DAWG to New"
                msg="Are you sure"
                onConfirm={() => {
                  updateOrderAttempt(filterAttempts(sourceOrder)?.find((x) => x.success)?.id, {
                    success: false,
                    error_prefix: "Topdawg Error",
                    error_description: "order cancelled by supplier",
                  });
                  updateOrder({
                    id: sourceOrder.id,
                    data: { status: SOURCE_ORDER_STATUSES.new, issue_reason: null, issue_note: null },
                  });
                }}
              />
            </div>
          </>
        </RenderIf>
      </div>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  {isAODev() ? <th>Id</th> : null}
                  <th>Started - Ended</th>
                  <th>Last Used Account</th>
                  <th>Seller Name</th>
                  <th className="text-center">Attempt No</th>
                  <th>Description</th>
                  <th className="text-center">Platform</th>
                  {shoudDisplayScreenShot(sourceOrder?.order_attempts)
                    ? (
                    <th className="text-center">Screenshots</th>
                      )
                    : null}
                  {shoudDisplay(sourceOrder) ? <th className="text-center">Mark Successful</th> : null}
                </tr>
              </thead>
              <tbody>
                {filterAttempts(sourceOrder)?.map((attempt, key) => (
                  <React.Fragment key={"_order_attempts_root_" + key}>
                    <tr key={"_order_attempts_" + key} style={{ marginTop: 0, marginBottom: 0 }}>
                      {isAODev()
                        ? (
                        <td
                          onClick={
                            attempt.logs_url
                              ? () => {
                                  handleFetchLogs(attempt);
                                  setLogsModal({ isOpen: true, attempt });
                                }
                              : () => null
                          }
                          className={attempt.logs_url ? "cursor-pointer" : ""}
                        >
                          {attempt.id}
                        </td>
                          )
                        : null}
                      <td>
                        {parseAOAttemptDate(attempt)
                          ? (
                          <span
                            className={isManualTeam() ? "cursor-pointer" : ""}
                            onClick={() =>
                              isManualTeam() ? navigator.clipboard.writeText(parseAOAttemptDate(attempt)) : null
                            }
                          >
                            {parseAOAttemptDate(attempt)}
                          </span>
                            )
                          : null}
                      </td>
                      <td>
                        {attempt?.last_used_purchasing_account_name
                          ? (
                          <span
                            className={isManualTeam() ? "cursor-pointer" : ""}
                            onClick={() =>
                              isManualTeam()
                                ? navigator.clipboard.writeText(attempt.last_used_purchasing_account_name)
                                : null
                            }
                          >
                            {attempt.last_used_purchasing_account_name}
                          </span>
                            )
                          : null}
                      </td>
                      <td>
                        {parseSellerName(attempt)
                          ? (
                          <span
                            className={isManualTeam() ? "cursor-pointer" : ""}
                            onClick={() =>
                              isManualTeam() ? navigator.clipboard.writeText(parseSellerName(attempt)) : null
                            }
                          >
                            {parseSellerName(attempt)}
                          </span>
                            )
                          : null}
                      </td>
                      <td className="text-center">{attempt?.attempt}</td>
                      <td>
                        <AoAttemptStatus attempt={attempt} />
                      </td>
                      <td className="text-center">
                        <img src={getBrandImageSrc(attempt.platform)} alt={attempt.platform} />
                      </td>
                      {shoudDisplayScreenShot(filterAttempts(sourceOrder)) && (
                        <td className="text-center">
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <i
                              onClick={() => {
                                const ssStatus = !(selectedAttempt === key && screenshotsCollapse === true);
                                setSelectedAttempt(key);
                                handleFetchScreenShots(attempt, ssStatus, key);
                                setScreenshotsCollapse(ssStatus);
                              }}
                              className={`cursor-pointer bx bx-sm bx-chevron-${
                                selectedAttempt === key && screenshotsCollapse ? "up" : "down"
                              }-circle`}
                            ></i>
                          </div>
                        </td>
                      )}
                      {shoudDisplay(sourceOrder)
                        ? (
                        <td className="text-center">
                          {!attempt.success
                            ? (
                            <i
                              onClick={() => updateOrderAttempt(attempt.id, { success: true })}
                              className="bx bx-sm bx-check-circle text-success cursor-pointer"
                            ></i>
                              )
                            : null}
                        </td>
                          )
                        : null}
                    </tr>
                    {selectedAttempt === key && screenshotsCollapse && (
                      <tr key={"_order_attempt_collapse_" + key}>
                        <td align="center" colSpan="8">
                          <Collapse isOpen={screenshotsCollapse}>
                            <CardBody>
                              <ImageUi images={imageUrls[key]} />
                            </CardBody>
                          </Collapse>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          {logsModal.isOpen && logs[logsModal.attempt?.id]?.length
            ? (
            <LogsModal logs={logs} logsModal={logsModal} setLogsModal={setLogsModal} />
              )
            : null}
        </CardBody>
      </Card>
    </>
  );
};

export default OrderAttempts;
