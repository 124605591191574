import request from "../restClient";
const notificationUrl = "/notification_emails";

export const deleteNotificationEmail = async (id) => {
  const url = `${notificationUrl}/${id}`;
  return await request({ url, method: "DELETE" });
};

export const createNotificationEmails = async (payload) => {
  const { nestedVariation: emails, type } = payload;
  return await request({ url: notificationUrl, method: "POST", body: { emails, type } });
};

export const bannerNotification = (payload) => request({ url: "/notifications/banner", method: "POST", body: payload });

export const fetchNotifications = async () => {
  return await request({ url: notificationUrl, method: "GET" });
};

export const setEmailType = async (data) => {
  const url = `${notificationUrl}/enabled`;
  return await request({ url, method: "PATCH", body: data });
};

export const informSlack = async (body) => {
  return await request({ url: "/notifications/slack_alert", method: "POST", body });
};
