import $ from "lodash";
import { getValidStores } from "../../../utils/common";

export const choosePriceOpts = {
  price_below: "Price below",
  match_price: "Match price",
};

export const noCompetitionOpts = {
  do_not_reprice: "Do not reprice",
  use_mean_value: "Use mean value between listed price and maximum price",
  use_max_price: "Use maximum price",
  use_min_price: "Use Min Price",
};

export const competitionBelowOpts = {
  do_not_reprice: "Do not reprice",
  use_min_price: "Use Min Price",
};

export const competitionMatchOpts = {
  do_not_reprice: "Do not reprice",
  use_min_price: "Use Min Price",
};

export const competitionAboveOpts = {
  do_not_reprice: "Do not reprice",
  use_min_price: "Use minimum price",
  use_max_price: "Use maximum price",
  reduce_value_from_listed_price: "Reduce value from listed price",
};

export const hasBuxBoxOpts = {
  do_not_reprice: "Do not reprice",
  use_mean_value: "Maximize Profit: Use mean value between listed price and competitor price",
};

export const whenBuyBoxSuppressedOpts = {
  do_not_reprice: "Do not reprice",
  use_min_price: "Use minimum price",
  use_max_price: "Use maximum price",
  set_lowest_featured_merchant: "Set lowest featured merchant price",
};

export const platFormUniquekeys = {
  walmart: { ec: "unique_identifier", repricer: "marketplace_account_id" },
  amazon: { ec: "access_id", repricer: "seller_id" },
};

export const getInitialStates = (platform, accounts) =>
  $.reduce(
    getValidStores(accounts, { forRepricer: true, isValid: true }),
    (obj, x) => {
      if (x.marketplace === platform)
        obj[x.id] = {
          name: x.name,
          marketplace: x.marketplace,
          state: false,
          uniqueId: x?.[platFormUniquekeys?.[platform]?.ec],
        };
      return obj;
    },
    {},
  );

export const getMatchedIds = (allRepricerAccounts, assignedRepricerAccount) =>
  (allRepricerAccounts || [])
    ?.filter((account) => assignedRepricerAccount?.includes(account.name))
    ?.map((account) => account);

export const getSelectedUniqueKeys = (matchedIds, platform) =>
  matchedIds.map((value) => value?.[platFormUniquekeys?.[platform]?.repricer]);

export const getSelectedFilteredAccounts = (initialState, selectedUniqueKeys) =>
  $.reduce(
    initialState,
    (acc, value, key) => {
      if (!selectedUniqueKeys.includes(value.uniqueId)) acc[key] = value;
      return acc;
    },
    {},
  );

export const REDUCE_LISTED_PRICE = "Reduce value from listed price";
