import React, { useEffect, useState, Suspense } from "react";
import moment from "moment";
import queryString from "query-string";

import { connect, useDispatch, useSelector } from "react-redux";
import { FormGroup, Alert } from "reactstrap";
import Select, { components } from "react-select";
import Modal from "../Common/Modal";
import * as actions from "../../store/actions";

import { Link, withRouter } from "react-router-dom";

// Import Toast
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import Onboarding from "../../pages/Onboarding/index";
import ThemeSwitch from "../CommonForBoth/ThemeSwitch";
// import images
import logoLight from "../../assets/svg/logoTopBar.svg";
import logoDark from "../../assets/svg/logoDark.svg";
import logoIconLight from "../../assets/svg/logoIcon.svg";
import logoIconDark from "../../assets/svg/logoIconDark.svg";
import agencyIcon from "../../assets/svg/agencyIcon.svg";
import CurrentTime from "./CurrentTime";
import { sortBy, chunk, values, map, pick, forEach } from "lodash";
import {
  INACTIVE_MESSAGE,
  NON_STORE_USERS,
  USER_ROLES,
  onboardingStepsIds,
  AO_DEV_IDS,
  SHOW_TENANT_SWITCH_IDS,
  ACCOUNT_STATUSES,
  LOCAL_STORAGE_KEYS,
} from "../../constants/index";
import {
  filterSteps,
  verifyRole,
  downloadReportPDF,
  getUserInfo,
  isManualTeam,
  isWHStaff,
  getRedirectionUrl,
  RenderIf,
  isUserHaveFullAccess,
  decodeState,
  getTokenRefreshedAt,
} from "../../utils/common";

import ExportOrdersModal from "../Common/ExportOrdersModal";
import PaymentReportModal from "../Common/PaymentReportModal";
import WHLabelReportModal from "../Common/WHLabelReportModal";
import TosAcceptanceModal from "../Common/TosModel";
import PandLReportModal from "../../pages/Dashboard/components/P&LReportModal";

// Redux Store
const {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  fetchMarketplaceAccounts,
  fetchAccountSettings,
  fetchAbilities,
  fetchProxies,
  setPreloader,
  fetchToken,
  setDashboardPdfOpts,
  setTosAcceptanceModal,
  setShowOnboarding,
  fetchStampsCreds,
} = actions;

const ReportModal = React.lazy(() => import("../Common/ReportModal"));

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <div className="d-flex flex-row align-items-center">
      <i
        className={`mr-2 bx bx-sm ${props.data.status === "active" ? "bx-check text-success" : "bx-x text-warning"}`}
      ></i>
      {props.data.username}
      <br />
      {props.data.label}
      <br />
      Tenant ID: {props.data.value}
      {props.data.aoStatus ? <i className="ml-2 fas fa-robot"></i> : null}
    </div>
  </Option>
);

const ProxyModal = (props) => {
  return (
    <Modal size="md" isOpen={props.isOpen} toggle={() => props.toggle(false)}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Available Proxies ({props.allProxies.length})
        </h5>
      </div>
      <div className="px-4" style={{ height: 300, overflow: "auto" }}>
        <table className="table">
          <tbody>
            {chunk(props.allProxies, 5).map((proxies, i) => (
              <tr key={i}>
                <td>{proxies.map((x) => x.port).join(", ") || ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

const Header = (props) => {
  const queryParameters = queryString.parse(document.location.search);
  const { agency_id: userAgencyId, account_id: userAccountId, email: tenantEmail } = getUserInfo() || {};
  const tenantId = getUserInfo()?.account_id;
  const isReadOnly = verifyRole("readOnly");
  const [proxyModal, setProxyModal] = useState(false);
  const [onBoardSteps, setOnBoardSteps] = useState([]);
  const [isOnboard, setIsOnboard] = useState(false);
  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;
  const dispatch = useDispatch();
  const getAgencyEmail = (agency) => {
    const user = agency?.users?.find((user) => user.role === "owner");
    return `${user?.first_name} ${user?.last_name}`;
  };
  const {
    accounts: marketplaceAccounts,
    loading,
    curTenantId,
    isAmz,
  } = useSelector((state) => state.MarketPlaceAccount);
  const {
    account,
    agencies,
    allTenants,
    showOnboarding,
    onBoardingStep,
    loading: settingsLoading,
    accountsLoading,
    error,
    abilities,
    allProxies,
    proxyLoading,
    skipAgencyOnboard,
    pdfOpts,
    tenantsData,
    fetchStampsCredsData,
    refreshTokenLoading,
    isAddAgencyModalOpen,
  } = useSelector((state) => state.Settings);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function tToggle () {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }

  function isTenantSwitchApplicable () {
    let userData;
    const userInfo = getUserInfo() || {};
    if (SHOW_TENANT_SWITCH_IDS.includes(userInfo.id)) return true;
    switch (userInfo.role) {
      case USER_ROLES.admin:
        return true;
      case USER_ROLES.owner: {
        const data = agencies?.data?.find((x) => x.email === userInfo.user_email);
        if (userInfo.isAgencySwitch || (data?.email && !data.agency_id)) return true;
        break;
      }
      case USER_ROLES.va:
        return agencies?.data?.length > 1;
      case USER_ROLES.manager:
        userData = account?.data?.users?.find((x) => x.id === userInfo.id);
        if (userData?.agency_info) return true;
        break;
      case USER_ROLES.readOnly:
        userData = account?.data?.users?.find((x) => x.id === userInfo.id);
        if (userData?.agency_info) return true;
        break;
      default:
        return false;
    }
  }

  const userStatus = account?.data?.users?.find((x) => x.account_id === getUserInfo()?.account_id)?.status;
  const isNewAgency =
    (getUserInfo()?.role === USER_ROLES.owner &&
      !account?.data?.agency_id &&
      account?.data?.users?.find((x) => x?.account_id === account?.data?.id)?.role === USER_ROLES.owner) ||
    !!getUserInfo()?.isAgencySwitch;

  function getUsername (tenant) {
    if (Array.isArray(tenant?.users)) return tenant?.users?.find((x) => x.email === tenant.email)?.username;
    else if (Array.isArray(tenant.AccountUser) && tenant.AccountUser[0].username) return tenant.AccountUser[0].username;
    return "";
  }

  const isAnyStoreExists = (_) => account?.data?.stores_count > 0;
  const isTokenNeedsRefresh = () => {
    const date = getTokenRefreshedAt();
    return !date || moment().diff(moment(date), "hours") >= 1;
  };

  useEffect(() => {
    const tenant = account?.data || {};
    if (tenant) localStorage.setItem("stripe_customer_id", tenant?.stripe_customer_id || "");
    if (!tenant || settingsLoading || !["owner", "admin"].includes(getUserInfo()?.role)) return;
    if (!getUserInfo()?.agency_id || tenant?.is_agency) return;

    // Extra Check to prevent account freeze
    let selectedSteps;
    if (account?.data) {
      const filteredSteps = filterSteps(
        account?.data,
        map(values(onboardingStepsIds), (x) => ({ id: x })),
      );
      if (!filteredSteps.length && showOnboarding && isAmz.isOpen) {
        const steps = values(
          pick(onboardingStepsIds, "welcom", "amazonSetUp", "walmartsetUp", "facebooksetUp", "shopifysetUp"),
        );
        setOnBoardSteps(steps);
        selectedSteps = steps;
      } else {
        const steps = map(filteredSteps, "id");
        setOnBoardSteps(steps);
        selectedSteps = steps;
      }
    }

    if (onBoardingStep && showOnboarding && !selectedSteps?.length) setOnBoardSteps([onBoardingStep]);

    const redirectUrl = getRedirectionUrl(tenant);
    if (redirectUrl) props.history.push(redirectUrl);
  }, [account, settingsLoading, isAmz.isOpen, showOnboarding]);

  useEffect(() => {
    if (isWHStaff()) return;
    if (
      ((!marketplaceAccounts && !loading) || (marketplaceAccounts && (!curTenantId || curTenantId !== tenantId))) &&
      isAnyStoreExists()
    ) {
      dispatch(fetchMarketplaceAccounts({}));
    }
    if (!accountsLoading && (!allTenants || !account)) {
      dispatch(fetchAccountSettings(getUserInfo()?.role === "admin" || isManualTeam()));
    }

    if (!refreshTokenLoading && isTokenNeedsRefresh()) dispatch(actions.refreshAuthToken());

    if (account?.data?.status === ACCOUNT_STATUSES.in_active && userStatus === "complete") {
      toastr.options = {
        timeOut: 0,
        closeButton: true,
        extendedTimeOut: 0,
        preventDuplicates: true,
        onclick: () => props.history.push("/settings?account=true"),
      };
      toastr.error(INACTIVE_MESSAGE, "Inactive Account");
    }
    if (!Object.keys(abilities || {}).length && !settingsLoading && !isNewAgency) dispatch(fetchAbilities());
  }, [marketplaceAccounts, account, abilities]);

  useEffect(() => {
    !isWHStaff() &&
      !fetchStampsCredsData &&
      getUserInfo()?.role !== USER_ROLES.readOnly &&
      dispatch(fetchStampsCreds());
  }, []);

  useEffect(() => {
    props.setPreloader(loading || accountsLoading || pdfOpts?.pdfLoader);
  }, [loading, accountsLoading, pdfOpts?.pdfLoader]);

  useEffect(() => {
    pdfOpts?.pdfDivStyle !== "none" && setTimeout((_) => downloadReportPDF(dispatch, setDashboardPdfOpts), 3000);
  }, [pdfOpts?.pdfDivStyle]);

  useEffect(() => {
    if (getUserInfo()?.role === "admin" && !proxyLoading && !allProxies) dispatch(fetchProxies());
  }, []);

  useEffect(() => {
    account?.data && props.setTosAcceptanceModal(!account?.data?.onboard?.tos_accepted);
  }, [account?.data?.onboard?.tos_accepted]);

  useEffect(() => {
    const { forRepricer } = decodeState(queryParameters) || {};
    if (forRepricer && !props.addRepricerStore) dispatch(actions.setAddRepricerStore(true));
  }, [queryParameters.spapi_oauth_code]);

  function fetchTenantToken (e) {
    if (getUserInfo()?.role === USER_ROLES.whStaff) return;
    const params = [e.label, props.history];
    if (isNewAgency) params.push({ isAgencySwitch: true });
    props.fetchToken(...params);
    forEach(LOCAL_STORAGE_KEYS, (key) => localStorage.removeItem(key));
  }

  function TenantSwitch (props) {
    const currentId = getUserInfo()?.account_id;
    const index = props.options.findIndex((x) => x.value === currentId);
    const opt = index === -1 ? undefined : props.options[index + (props.type === "next" ? 1 : -1)];
    if (!opt) return <></>;
    return (
      <FormGroup className="select2-container mt-3 cursor-pointer">
        <i
          className={`bx bx-sm bxs-${
            props.type === "next" ? "right" : "left"
          }-arrow-alt select2-selection__control d-flex align-items-center`}
          title={`Switch to ${opt.label}`}
          onClick={(_) => fetchTenantToken(opt)}
        ></i>
      </FormGroup>
    );
  }

  function makeOpts (allTenants) {
    return sortBy(allTenants, (a) => a.status.length).reduce((acc, tenant, i) => {
      if (account?.data?.agency_id) {
        const isActive = [ACCOUNT_STATUSES.active, ACCOUNT_STATUSES.payment_failed].includes(tenant.status);
        const isValidInActive =
          [ACCOUNT_STATUSES.payment_failed].includes(tenant.status) &&
          (tenant.onboard?.store_added || tenant.stores_count > 0 || tenant.credentials_count > 0);
        if (!isActive && isValidInActive) return acc;
      }

      acc.push({
        label: tenant.email,
        value: tenant.id,
        username: getUsername(tenant),
        status: tenant.status,
        aoStatus: tenant.ao_enabled,
        key: i,
      });
      return acc;
    }, []);
  }

  const BalanceCard = ({ icon, text, width }) => {
    return (
      <div className="form-control select2-container ml-1 d-flex align-items-center" style={{ width }}>
        <i>
          <i className={`bx bx-xs ${icon} pt-1 pr-1`}></i>
        </i>
        <span>{text}</span>
      </div>
    );
  };

  const isAllowOnboarding = isUserHaveFullAccess() || props.addRepricerStore;

  const shouldOnBoarded = (_) =>
    onBoardSteps.length &&
    ["create_schema", "pending_confirm", "onboarding"].includes(userStatus) &&
    !isNaN(account?.data?.stores_count) &&
    (account.data.stores_count === 0 || !account.data.payment_source_added) &&
    (!NON_STORE_USERS.includes(account?.data?.id) || !account?.data.payment_source_added);

  useEffect(() => {
    const isOnboard = shouldOnBoarded();
    setIsOnboard(isOnboard);
  }, [onBoardSteps]);

  return (
    <React.Fragment>
      <RenderIf
        isTrue={
          account?.data &&
          !skipAgencyOnboard &&
          onBoardSteps.length &&
          isAllowOnboarding &&
          showOnboarding
        }
      >
        <Onboarding
          isOnboarding={isOnboard}
          steps={onBoardSteps}
          onBoarding={isOnboard || undefined}
          active={showOnboarding}
          setActive={(...args) => dispatch(setShowOnboarding(...args))}
          activeStep={onBoardingStep}
        />
      </RenderIf>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box" style={isReadOnly || isWHStaff() ? { background: "none" } : {}}>
              <Link to="/" className="logo">
                <RenderIf isTrue={AO_DEV_IDS.includes(getUserInfo()?.id)}>
                  <CurrentTime />
                </RenderIf>
                <span className="logo-lg">
                  <img id="logo_image" src={props.theme === "light" ? logoLight : logoDark} alt="" height="25" />
                </span>
                <span className="logo-sm">
                  <img src={props.theme === "light" ? logoIconLight : logoIconDark} alt="" height="25" />
                </span>
              </Link>
            </div>
            {props.tosAcceptanceModalIsOpen && getUserInfo().role !== "wh_staff" && (
              <TosAcceptanceModal open={props.tosAcceptanceModalIsOpen} history={props.history} />
            )}
            <RenderIf isTrue={pdfOpts?.isPLModal}>
              <PandLReportModal accounts={props.MarketPlaceAccount?.accounts?.data || []} isOpen={pdfOpts?.isPLModal} />
            </RenderIf>
            <ExportOrdersModal accounts={props.MarketPlaceAccount.accounts} />
            <PaymentReportModal MarketPlaceAccount={{ accounts: props.MarketPlaceAccount.accounts }} />
            <Suspense fallback={<></>}>
              <ReportModal />
            </Suspense>
            <WHLabelReportModal MarketPlaceAccount={{ accounts: props.MarketPlaceAccount.accounts }} />
            {!isReadOnly && (
              <button
                type="button"
                onClick={() => {
                  tToggle();
                }}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            )}
          </div>

          {error && !isAddAgencyModalOpen && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          )}

          <div className="d-flex align-items-center">
            <RenderIf isTrue={isAdmin && userAgencyId && userAccountId !== userAgencyId && tenantsData?.[userAgencyId]}>
              <div className="agency-badge mr-3">
                <img src={agencyIcon} alt="noAgency" className="pr-2" />
                {`Agency: ${getAgencyEmail(tenantsData?.[userAgencyId])}`}
              </div>
            </RenderIf>
            {allTenants?.length && isTenantSwitchApplicable() && (
              <>
                <TenantSwitch options={makeOpts(allTenants)} type="previous" />
                <FormGroup className="select2-container mt-3" style={{ minWidth: "300px" }} id="switch_users">
                  <Select
                    value={{
                      value: tenantId,
                      label: `${tenantId}: ${tenantEmail}`,
                    }}
                    onChange={fetchTenantToken}
                    options={makeOpts(allTenants)}
                    classNamePrefix="select2-selection"
                    components={{ Option: IconOption }}
                  />
                </FormGroup>
                <TenantSwitch options={makeOpts(allTenants)} type="next" />
              </>
            )}
            <RenderIf isTrue={getUserInfo()?.account_id !== 2 && !isNewAgency && account?.data && isUserHaveFullAccess()}>
              <>
                {account?.data?.two_step_feature && account?.data?.alt_payment !== 0 && (
                  <BalanceCard
                    icon="bxs-dollar-circle"
                    text={`Shipping Balance: ${account?.data?.alt_payment?.toLocaleString()}`}
                    width="210px"
                  />
                )}
              </>
            </RenderIf>
            {allProxies ? <ProxyModal isOpen={proxyModal} toggle={setProxyModal} allProxies={allProxies} /> : null}
            <ThemeSwitch />
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { MarketPlaceAccount } = state;
  const { tosAcceptanceModalIsOpen } = state.User;
  const { layoutType, showRightSidebar, leftMenu, timezone, leftSideBarType, appTheme } = state.Layout;
  const { addRepricerStore } = state.Settings;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    timezone,
    leftSideBarType,
    theme: appTheme,
    MarketPlaceAccount,
    tosAcceptanceModalIsOpen,
    isAmz: MarketPlaceAccount.isAmz,
    isReportModalOpen: state.Report.downloadModal,
    addRepricerStore,
  };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  setPreloader,
  fetchToken,
  setTosAcceptanceModal,
  setDashboardPdfOpts,
})(withRouter(Header));
