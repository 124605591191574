import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateBannerNotification } from "../../store/actions";
import { RenderIf } from "../../utils/common";

const StampsTransitionAlert = (props) => {
  const { account: tenantAccount } = useSelector((state) => state.Settings);
  const dispatch = useDispatch();

  return (
    <RenderIf isTrue={tenantAccount?.data?.is_enable_banners?.stamps_banner}>
      <div className="alert alert-primary alert-dismissible" color="primary">
        <i className="bx bx-info-circle pr-2"></i>
        <span className="black-color" style={{ fontSize: "13px" }}>
          <a className="black-color" style={{ color: "#2c3978" }} target="_blank" href="/settings?account=true">
            Get cheaper shipping rates by integrating with{" "}
            <span>
              <a
                style={{ fontWeight: "bold" }}
                href="https://ecomcircles.helpdocs.com/settings-and-billings/how-to-set-up-stamps-for-your-account?preview=true"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Stamps.com
              </a>
            </span>{" "}
            Connect Stamps to your account here.
          </a>
        </span>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() =>
            dispatch(
              updateBannerNotification({
                banner_type: "stamps_banner",
                value: false,
              }),
            )
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </RenderIf>
  );
};
export default StampsTransitionAlert;
