import { combineReducers } from "redux";
import { pick } from "lodash";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import AmazonSellers from "./amazonSellers/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import SourceOrders from "./sourceOrders/reducer";
import Emails from "./emails/reducer";
import SupplierOrders from "./supplierOrders/reducer";
import CreditCards from "./creditCard/reducer";
import SourceItems from "./sourceItems/reducer";
import Supplier from "./suppliers/reducer";
import OrderLog from "./orderLogs/reducer";
import Credential from "./credentials/reducer";
import Listings from "./listings/reducer";
import UnpublishedListings from "./unpublishedListings/reducer";
import Graph from "./graphs/reducer";
import Agency from "./Agency/reducer";
import MarketPlaceAccount from "./marketPlaceAccounts/reducer";
import Feed from "./feed/reducer";
import ShipmentReport from "./shipmentReport/reducer";
import User from "./user/reducer";
import AuthToken from "./authToken/reducer";
import Settings from "./settings/reducer";
import Platform from "./platforms/reducer";
import CreditLog from "./creditLog/reducer";
import OrderAttempt from "./orderAttempt/reducer";
import RestrictedBrands from "./restrictedBrands/reducer";
import RestrictedItems from "./restrictedItems/reducer";
import AOScreen from "./aoScreens/reducer";
import MatchesRequests from "./matchesRequests/reducer";
import FiftyFiftyProfit from "./FiftyFiftyProfit/reducer";
import CustomPlans from "./customPlans/reducer";
import FreeCredits from "./FreeCredits/reducer";
import AOStats from "./aoStats/reducer";
import AOTasks from "./aoTasks/reducer";
import ProxyUsers from "./proxyUsers/reducer";
import NotificationEmails from "./NotificationEmails/reducer";
import WareHouses from "./warehouse/reducer";
import Stripe from "./Stripe/reducer";
import Returns from "./Returns/reducer";
import Report from "./reports/reducer";
import Repricer from "./repricer/reducer";
import Insights from "./insights/reducer";
import ProfitAnalyzer from "./ProfitAnalyzer/reducer";
import CheckOffers from "./CheckOffers/reducer";

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  AmazonSellers,
  ForgetPassword,
  Profile,
  SourceOrders,
  Emails,
  SupplierOrders,
  CreditCards,
  SourceItems,
  Supplier,
  OrderLog,
  Credential,
  Listings,
  UnpublishedListings,
  Graph,
  Agency,
  MarketPlaceAccount,
  Feed,
  ShipmentReport,
  User,
  AuthToken,
  Settings,
  Platform,
  CreditLog,
  OrderAttempt,
  RestrictedBrands,
  RestrictedItems,
  AOScreen,
  MatchesRequests,
  FiftyFiftyProfit,
  CustomPlans,
  FreeCredits,
  AOStats,
  AOTasks,
  ProxyUsers,
  NotificationEmails,
  WareHouses,
  Stripe,
  Returns,
  Report,
  Repricer,
  Insights,
  ProfitAnalyzer,
  CheckOffers,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "LOGOUT_USER") state = pick(state, "Layout");
  return appReducer(state, action);
};

export default rootReducer;
