import {
  API_ERROR,
  CREATE_PROXY_USER,
  CREATE_PROXY_USER_SUCCESS,
  FETCH_ALL_PROXIES,
  FETCH_ALL_PROXIES_SUCCESS,
  FETCH_PROXY_USERS,
  FETCH_PROXY_USERS_SUCCESS,
  SET_ADD_PROXY_USER_MODEL,
  UPDATE_PROXY_USER,
  UPDATE_PROXY_USER_SUCCESS,
  FETCH_PROXY_BY_PORT_SUCCESS,
  FETCH_PROXY_BY_PORT,
  SWAP_PROXY_BY_PORT,
  SWAP_PROXY_BY_PORT_SUCCESS,
  SET_UPDATE_LPM_PROXY_MODAL,
  SET_SELECTED_PROXY,
  UPDATE_LPM_PROXY,
  UPDATE_LPM_PROXY_SUCCESS,
  SET_UPLOAD_PROXY_MODAL,
  UPLOAD_PROXY_CSV,
  UPLOAD_PROXY_CSV_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  proxyUsers: null,
  proxiesData: [],
  loading: false,
  setAddProxyUserModelIsOpen: false,
  setUpdateLpmProxyModalIsOpen: false,
  setUpLoadProxyModalIsOpen: false,
  selectedProxy: null,
};

const ProxyUsers = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADD_PROXY_USER_MODEL:
      state = { ...state, setAddProxyUserModelIsOpen: action.payload };
      if (action.payload) {
        ["error", "success", "message"].forEach((key) => (state[key] = ""));
        state.shouldRefresh = false;
      }
      break;
    case SET_UPDATE_LPM_PROXY_MODAL:
      state = { ...state, setUpdateLpmProxyModalIsOpen: action.payload };
      if (action.payload) {
        ["error", "success", "message"].forEach((key) => (state[key] = ""));
        state.shouldRefresh = false;
      }
      break;
    case SET_UPLOAD_PROXY_MODAL:
      state = { ...state, setUpLoadProxyModalIsOpen: action.payload };
      if (action.payload) {
        ["error", "success", "message"].forEach((key) => (state[key] = ""));
        state.shouldRefresh = false;
      }
      break;
    case FETCH_PROXY_USERS:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case FETCH_PROXY_USERS_SUCCESS:
      state = { ...state, error: "", success: "", loading: false, data: action.payload };
      break;
    case CREATE_PROXY_USER:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case CREATE_PROXY_USER_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload.success || "",
        loading: false,
        message: action.payload.message,
        shouldRefresh: true,
      };
      break;
    case UPDATE_PROXY_USER:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case UPDATE_PROXY_USER_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload.success || "",
        loading: false,
        message: action.payload.message,
        shouldRefresh: true,
      };
      break;
    case FETCH_ALL_PROXIES:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case FETCH_ALL_PROXIES_SUCCESS:
      state = { ...state, error: "", success: "", loading: false, proxiesData: action.payload.data };
      break;
    case FETCH_PROXY_BY_PORT:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case FETCH_PROXY_BY_PORT_SUCCESS:
      state = { ...state, error: "", success: "", loading: false, proxiesData: action.payload.data };
      break;
    case SWAP_PROXY_BY_PORT:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case SWAP_PROXY_BY_PORT_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Proxies swapped successfully",
        loading: false,
        message: action.payload.message,
        shouldRefresh: true,
      };
      break;
    case SET_SELECTED_PROXY:
      state = {
        ...state,
        success: "",
        error: "",
        selectedProxy: action.payload,
      };
      break;
    case UPDATE_LPM_PROXY:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_LPM_PROXY_SUCCESS:
      state = {
        ...state,
        success: "Update successfully",
        error: "",
        loading: false,
        setUpdateLpmProxyModalIsOpen: false,
      };
      break;
    case UPLOAD_PROXY_CSV:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPLOAD_PROXY_CSV_SUCCESS:
      state = {
        ...state,
        success: "File uploaded and processed succesfully",
        error: "",
        loading: false,
        setUpLoadProxyModalIsOpen: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, success: false, loading: false };
      break;
    default:
      break;
  }
  return state;
};

export default ProxyUsers;
