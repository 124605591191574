import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Logout from "../pages/Authentication/Logout";
import ResetPassword from "../pages/Authentication/ResetPasswordNew";
import EmailNotification from "../pages/Authentication/EmailNotification";
import EmailVerification from "../pages/Authentication/EmailVerification";
import TwoFactorVerification from "../pages/Authentication/TwoFactorVerification";

// Dashboard
import SourceOrders from "../pages/SourceOrders/index";
import SourceOrdersDetail from "../pages/SourceOrders/detail";
import ViewReport from "../pages/SourceOrders/components/ViewReport";
import ViewReports from "../pages/Reports/index";
import OrderProcessorReport from "../pages/SourceOrders/components/OrderProcessorReport";
import AmazonSellers from "../pages/AmazonSellers";
import Listings from "../pages/Listings/index";
import MatchingLogs from "../components/Common/MatchingLogs";
import UnpublishedListings from "../pages/UnpublishedListings/index";
import EmailListing from "../pages/Emails/index";
import EmailView from "../pages/Emails/EmailView";
import Credentials from "../pages/Credentials/index";
import TwoFactorAuthInstructions from "../pages/Credentials/TwoFactorAuthInstructions";
import CSVListings from "../pages/Listings/Components/CSVListings";
import UploadedFiles from "../pages/Listings/Components/UploadedFiles";
import CreditCards from "../pages/CreditCards/index";
import ShipmentReport from "../pages/ShipmentReport";
import Users from "../pages/User/index";
import AddNewListing from "../pages/Listings/Components/AddNewListing";
import RepricerListings from "../pages/RepricerListings/index";
import Dashboard from "../pages/Dashboard/index";
import AgencyDashboard from "../pages/Dashboard/agencyDashboard";
import FiftyDashboard from "../pages/Dashboard/fiftyDashboard";
import AdminDashboard from "../pages/Dashboard/adminDashboard";
import Settings from "../pages/Settings";
import SettingAOInstructions from "../pages/Settings/components/SettingAOInstructions";
import MarketplaceFeed from "../pages/Feed/index";
import PlatformSuppliers from "../pages/PlatformSuppliers";
import CreditLog from "../pages/CreditLog";
import TenantAccount from "../pages/Accounts";
import ActivityLogs from "../pages/ActivityLogs";
import LeaderBoard from "../pages/LeaderBoard";
import CustomPlans from "../pages/CustomPlans";
import RestrictedBrands from "../pages/RestrictedBrands";
import Resources from "../pages/Resources";
import ScratchPad from "../pages/ScratchPad";
import AOScreens from "../pages/AOScreens";
import AccountOrdersHistory from "../pages/AccountOrdersHistory";
import Repricer from "../pages/Repricer";
import MatchingPlatforms from "../pages/Listings/Components/MatchingPlatforms";
import MatchesRequests from "../pages/MatchesRequests";
import FreeCredits from "../pages/FreeCredits";
import AOStats from "../pages/AOStats";
import FiftyFiftyProfit from "../pages/FiftyFiftyProfit";
import ReturnLabels from "../pages/ReturnLabel";
import WareHouse from "../pages/WareHouse";
import WarehouseDashboard from "../pages/WareHouse/Components/WarehouseDashboard";
import WareHouseItems from "../pages/WareHouse/Components/WareHouseItems";
import WHChargeHistories from "../pages/WareHouse/Components/WarehouseChargeHistories";
import InventoryItems from "../pages/WareHouse/Components/WarehouseInventory";
import PaymentMethods from "../pages/PaymentMethods";
import {
  JOB_DASHBOARD_USERS,
  AO_DEV_IDS,
  INVENTORY_ALLOWED_ACCS,
  NEW_DASHBOARD_URL,
  RETURN_LABEL_USERS,
  UNPUBLISHED_LISTINGS_USERS,
  PROFIT_ANALYZER_URL,
} from "../constants";
import ProfileSettings from "../pages/ProfileSettings";
import ShippingTemplates from "../pages/Listings/Components/ShippingTemplates";
import AOTasks from "../pages/AOTasks";
import ProxyUsers from "../pages/ProxyUsers";
import LeastSellingItems from "../pages/LeastSellingItems";
import NotificationSettings from "../pages/NotificationSettings";
import SearchOrders from "../pages/SourceOrders/components/SearchOrders";
import Refreshers from "../pages/Refreshers";
import LuminaticProxies from "../pages/LuminatiProxies";
import Returns from "../pages/Returns";
import RestrictedItems from "../pages/RestrictedItems";
import PurchaseOrder from "../pages/WareHouse/Components/PurchaseOrderUser";
import OutboundItems from "../pages/WareHouse/Components/OutboundItems";
import InventryItems from "../pages/WareHouse/Components/InventryItems";
import Inbounds from "../pages/Inbounds";
import redirectToSettingsPageModal from "../pages/Settings/components/AddLabelGenerationServiceModal";
import LabelGenerationStats from "../pages/LabelGenerationStats";
import StorageInvoice from "../pages/WareHouse/Components/StorageInvoice";
import ListScanforms from "../pages/Scanforms";
import NewDashboard from "../pages/Dashboard/NewDashboard";
import ProfitAnalyzer from "../pages/ProfitAnalyzer";
import NewRegisterationForm from "../pages/Authentication/NewRegister";
import NewSignInForm from "../pages/Authentication/NewSignIn";
import NewSignInWH from "../pages/Authentication/NewSignWH";
import NewForgetPassword from "../pages/Authentication/NewForgetPassword";
import BulkUpdate from "../pages/RepricerListings/Components/BulkUpdate";
import FormulasListings from "../pages/Formulas";
import RepricerListingsStrategies from "../pages/Strategies";
import jobs from "../pages/jobs";
import WarehouseInsights from "../pages/Insights/WarehouseInsights";
import Tags from "../pages/RepricerListings/Tags/index";
import ProductDetails from "../pages/RepricerListings/ProductDetails/index";
import CheckOffers from "../pages/CheckOffers";

const userRoutes = [
  {
    path: "/jobs_summary",
    component: jobs,
    allowedUserIds: JOB_DASHBOARD_USERS,
  },
  // Dashboard
  { path: "/agency", component: AgencyDashboard },
  { path: "/dashboard", component: Dashboard },
  { path: NEW_DASHBOARD_URL, component: NewDashboard },
  { path: PROFIT_ANALYZER_URL, component: ProfitAnalyzer },
  { path: "/fifty_dashboard", component: FiftyDashboard, roles: ["admin"] },
  { path: "/admin_dashboard", component: AdminDashboard, roles: ["admin"] },

  // Source Orders
  { path: "/source_orders/:statusTab", component: SourceOrders },
  {
    path: "/source_orders_details/:marketplaceOrderId/:id",
    component: SourceOrdersDetail,
  },
  { path: "/view_report", component: ViewReport },
  { path: "/exported_reports", component: ViewReports },
  { path: "/order_processor_report", component: OrderProcessorReport },

  // Credentials
  { path: "/credentials/:username?", component: Credentials },
  { path: "/credit_cards", component: CreditCards },
  {
    path: "/two_factor_auth_instructions",
    component: TwoFactorAuthInstructions,
  },

  // Listings
  { path: "/listings_new", component: RepricerListings },
  { path: "/tags", component: Tags },
  { path: "/listing_details/:platform/:itemId/:sku/:mpAccountId", component: ProductDetails },
  { path: "/formulas", component: FormulasListings },
  { path: "/strategies", component: RepricerListingsStrategies },
  { path: "/upload_listings_new", component: BulkUpdate },
  { path: "/listings/new/:sku", component: AddNewListing },
  { path: "/listings/new", component: AddNewListing },
  { path: "/shipping_templates", component: ShippingTemplates },
  { path: "/listings_csv", component: CSVListings },
  { path: "/unpublished-listings", component: UnpublishedListings, allowUsers: UNPUBLISHED_LISTINGS_USERS },
  { path: "/listings/:sku?", component: Listings },
  { path: "/uploaded_files", component: UploadedFiles },
  { path: "/matching_logs", component: MatchingLogs },

  // Emails
  { path: "/emails", component: EmailListing },
  { path: "/email/view/:key", component: EmailView, showLayout: false },

  // Users
  { path: "/users", component: Users, roles: ["admin", "owner"] },
  { path: "/proxy_users", component: ProxyUsers, roles: ["admin"] },

  // Feeds
  { path: "/feeds", component: MarketplaceFeed },

  // profile setting
  { path: "/profile_settings", component: ProfileSettings },
  { path: "/notification_settings", component: NotificationSettings },

  // Amazon Sellers
  { path: "/amazon_sellers", component: AmazonSellers },

  // Shipment Report
  { path: "/shipment-report", component: ShipmentReport },

  // Settings
  { path: "/settings", component: Settings, roles: ["admin", "owner"] },
  { path: "/instructions_setup_ao", component: SettingAOInstructions },

  // Platform Suppliers
  {
    path: "/suppliers",
    component: PlatformSuppliers,
    roles: ["admin", "owner", "manager"],
  },

  // Least Selling Items
  {
    path: "/least_selling_items",
    component: LeastSellingItems,
    roles: ["admin", "owner"],
  },

  // Credit Logs
  {
    path: "/credit_logs",
    component: CreditLog,
  },

  {
    path: "/return_labels",
    component: ReturnLabels,
    allowedUserIds: RETURN_LABEL_USERS,
  },

  // Warehouse
  {
    path: "/warehouse_dashboard",
    component: WarehouseDashboard,
    roles: ["admin", "owner", "wh_staff"],
  },
  {
    path: "/warehouse",
    component: WareHouse,
    roles: ["admin", "owner"],
  },
  {
    path: "/warehouse_items",
    component: WareHouseItems,
    roles: ["wh_staff", "owner", "admin", "manager", "va"],
  },
  {
    path: "/warehouse_storage",
    component: InventoryItems,
    roles: ["wh_staff", "owner", "admin", "manager", "va"],
  },
  {
    path: "/label_generation_stats",
    component: LabelGenerationStats,
    roles: ["wh_staff", "admin"],
  },
  {
    path: "/scanforms",
    component: ListScanforms,
    roles: ["wh_staff", "admin"],
  },
  {
    path: "/payment_methods",
    component: PaymentMethods,
    roles: ["manager", "admin", "owner"],
  },
  {
    path: "/warehouse_charges",
    component: WHChargeHistories,
    roles: ["wh_staff", "owner", "admin", "manager"],
    allowUsers: [2],
  },
  { path: "/storage_invoice/:invoiceId", component: StorageInvoice },
  {
    path: "/purchase_order",
    component: PurchaseOrder,
    roles: ["owner", "admin", "manager", "va"],
  },

  // Inbounds
  {
    path: "/inbound_items",
    component: Inbounds,
    roles: ["wh_staff", "admin"],
    allowUsers: [2],
  },
  {
    path: "/inventory-items",
    component: InventryItems,
    roles: ["wh_staff", "owner", "admin", "manager", "va"],
    allowUsers: INVENTORY_ALLOWED_ACCS,
  },
  {
    path: "/outbound-items",
    component: OutboundItems,
    roles: ["wh_staff"],
    allowUsers: [2],
  },

  // Returns
  { path: "/returns", component: Returns, roles: ["wh_staff", "owner", "admin", "manager", "va"] },

  // All Tenants
  {
    path: "/tenant_accounts",
    component: TenantAccount,
    roles: ["admin"],
  },

  // Global Search Orders
  {
    path: "/search_orders",
    component: SearchOrders,
    roles: ["admin"],
  },

  // Proxies Data
  {
    path: "/proxy_manager",
    component: LuminaticProxies,
    roles: ["admin"],
  },

  // All Activity Logs
  {
    path: "/activity_logs",
    component: ActivityLogs,
    roles: ["admin"],
  },

  // LearderBoard
  {
    path: "/leaderboard",
    component: LeaderBoard,
    roles: ["admin"],
  },

  // Custom Plans
  {
    path: "/custom_plans",
    component: CustomPlans,
    roles: ["admin"],
  },

  // Restricted Brands
  {
    path: "/restricted_brands",
    component: RestrictedBrands,
  },

  // Restricted Items
  {
    path: "/restricted_items",
    component: RestrictedItems,
  },

  // Refreshers View
  {
    path: "/refreshers",
    component: Refreshers,
    roles: ["admin"],
  },

  // Resources
  {
    path: "/resources",
    component: Resources,
  },

  // AO Screens
  {
    path: "/ao_ecs_tasks",
    component: AOScreens,
    title: "AO ECS Tasks",
    allowedUserIds: [298, 569, 17, ...AO_DEV_IDS],
  },
  {
    path: "/ao_attempts_history",
    component: AccountOrdersHistory,
    title: "Account Orders History",
    allowedUserIds: [27, 23, 298],
  },

  // Repricer
  {
    path: "/repricer_settings",
    component: Repricer,
    roles: ["admin", "owner", "manager", "va"],
  },

  // Mathcing Platforms
  {
    path: "/platforms",
    component: MatchingPlatforms,
    roles: ["admin", "owner"],
  },

  // AO Stats
  {
    path: "/ao_stats",
    component: AOStats,
    roles: ["admin"],
  },

  // AO Tasks
  {
    path: "/ao_tasks",
    component: AOTasks,
    roles: ["admin"],
  },

  // Requests Matches
  {
    path: "/matches_requests",
    component: MatchesRequests,
    roles: ["admin", "owner", "manager"],
  },
  {
    path: "/free_credits_history",
    component: FreeCredits,
    roles: ["admin"],
  },
  { path: "/fifty_fifty", component: FiftyFiftyProfit, roles: ["admin"] },
  { path: "/marketpalce_scratchpad", component: ScratchPad, roles: ["admin"] },
  { path: "/check_offers", component: CheckOffers, roles: ["admin"] },
  { path: "/authorise_stamps", component: redirectToSettingsPageModal, roles: ["admin"] },

  // Insights
  {
    path: "/warehouse_insights",
    component: WarehouseInsights,
    roles: ["admin"],
  },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: NewSignInForm },
  { path: "/login_warehouse", component: NewSignInWH },
  { path: "/forgot-password", component: NewForgetPassword },
  { path: "/forgot_pass/:token", component: ResetPassword },
  { path: "/register/:hash?", component: NewRegisterationForm },
  { path: "/invite/:token", component: NewRegisterationForm },
  { path: "/verify/:token", component: EmailNotification },
  { path: "/verify_wh_invite/:token", component: NewSignInForm },
  { path: "/agency_invite/:token", component: EmailNotification },
  { path: "/agency_invite_as_client/:token", component: EmailNotification },
  { path: "/notification/:email", component: EmailNotification },
  { path: "/email_verification/:email", component: EmailVerification },
  { path: "/two_factor_verification", component: TwoFactorVerification },
];

export { userRoutes, authRoutes };
