import { API_ERROR, FETCH_ALL_AO_STATS, FETCH_ALL_AO_STATS_SUCCESS } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  aoStats: { data: [] },
};

const AOStats = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_AO_STATS:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case FETCH_ALL_AO_STATS_SUCCESS:
      state = { ...state, success: "", error: "", loading: false, aoStats: action.payload };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        success: false,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default AOStats;
