import React, { useState, useEffect } from "react";
import { Alert, Card, CardBody, Input, Container } from "reactstrap";
import { toPascalCase } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationsSettings,
  setEmailTypeEnabled,
  setPreloader,
  createNotificationEmails,
  deleteNotificationEmail,
} from "../../store/actions";
import Cross from "../../assets/svg/cross.svg";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import NotificationSettingsModel from "./components/NotificationSettingsModel";
import { NOTIFICATION_TYPE, EMAIL_REGEX } from "../../constants";
const NotificationSettings = () => {
  const dispatch = useDispatch();
  const { loading, success, message, notificationsSettings, emailType } = useSelector(
    (state) => state.NotificationEmails,
  );
  const [model, setModel] = useState({ isOpen: false, type: "" });
  const [searchTerm, setSearchTerm] = useState([]);
  const [emailDelete, setEmailDelete] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");

  useEffect(() => {
    dispatch(fetchNotificationsSettings());
  }, [emailType]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    if (emailDelete) dispatch(deleteNotificationEmail(emailDelete));
  }, [emailDelete]);

  useEffect(() => {
    setTimeout(() => {
      setInvalidEmail("");
    }, 5000);
  }, [invalidEmail]);

  const handleKeyPress = (e, type, key) => {
    if (e.key === "Enter") {
      if (!EMAIL_REGEX.test(searchTerm[key])) {
        setInvalidEmail("This is  Invalid Email");
      } else if (EMAIL_REGEX.test(searchTerm[key])) dispatch(createNotificationEmails([searchTerm[key]], type));
      setSearchTerm({});
    }
  };
  return (
    <>
      {model && <NotificationSettingsModel model={model} setModel={setModel} />}

      <div className="page-content">
        {invalidEmail && (
          <div className="auto-hide">
            <Alert color="warning">
              <i className="bx bx-info-circle pr-2"></i>
              {invalidEmail}
            </Alert>
          </div>
        )}
        {success && (
          <div className="auto-hide">
            <Alert color="success">
              <i className="bx bx-info-circle pr-2"></i>
              {message}
            </Alert>
          </div>
        )}
        {emailType && (
          <div className="auto-hide">
            <Alert color="success">
              <i className="bx bx-info-circle pr-2"></i>
              {emailType?.message}
            </Alert>
          </div>
        )}
        <Container fluid>
          <Breadcrumbs title="Notification Settings" />
          <Card>
            <CardBody>
              {notificationsSettings?.notifications?.length > 0 && (
                <div className="table-responsive bg-color-ebony">
                  <table className="table table-centered table-nowrap mb-0">
                    <tbody>
                      {notificationsSettings?.notifications
                        .filter((x) => [...Object.keys(NOTIFICATION_TYPE).slice(0, 4)].includes(x.type))
                        .map(({ type, enabled, notification_emails: emails }, index) => (
                          <React.Fragment key={`_notification_${index}`}>
                            <tr key={`_notification_${index}`}>
                              <td>
                                <h6 className="mb-0">{toPascalCase(type)}</h6>
                              </td>
                              <td className="w-50">
                                <div className="d-flex grid-column-gap-5 flex-wrap">
                                  {emails?.map((emails, key) => (
                                    <div className="notifaction_email mb-1" key={key}>
                                      <span>{emails.email}</span>
                                      <img
                                        className="cursor-pointer"
                                        src={Cross}
                                        alt="no-icon"
                                        onClick={() => setEmailDelete(emails.id)}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </td>

                              <td>
                                <Input
                                  className="bg-color-ebony border-radius-50"
                                  name="email"
                                  type="email"
                                  placeholder="add email"
                                  value={searchTerm[index] || ""}
                                  onChange={(e) => {
                                    setSearchTerm({ ...searchTerm, [index]: e.target.value });
                                  }}
                                  onKeyPress={(e) => handleKeyPress(e, type, index)}
                                />
                              </td>
                              <td>
                                <div className="custom-control custom-switch custom-switch-lg mb-3">
                                  <input
                                    key={index}
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={type}
                                    checked={enabled}
                                    onChange={() => null}
                                  />
                                  <label
                                    onClick={() => {
                                      dispatch(setEmailTypeEnabled({ type, enabled: !enabled }));
                                    }}
                                    className="custom-control-label"
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {!notificationsSettings?.notifications?.length && <h4 className="text-center">No Notifications</h4>}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default NotificationSettings;
