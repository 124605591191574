import {
  FETCH_RETURNS,
  FETCH_RETURNS_SUCCESS,
  API_ERROR,
  UPDATE_RETURN,
  UPDATE_RETURN_SUCCESS,
  SET_SELECTED_RETURN_ITEM,
  MOVE_TO_INVENTORY,
  MOVE_TO_INVENTORY_SUCCESS,
  SET_UPLOAD_IMAGES_MODAL,
  DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  UPLOAD_ISSUE_IMAGES,
  UPLOAD_ISSUE_IMAGES_SUCCESS,
  GET_IMAGES_URL,
  GET_IMAGES_URL_SUCCESS,
  SET_FILTER_BAR,
  UPDATE_BULK_RETURNS,
  UPDATE_BULK_RETURNS_SUCCESS,
  SET_ADD_RETURN_MODAL,
  ADD_ITEM_TO_INVENTORY,
  ADD_ITEM_TO_INVENTORY_SUCCESS,
  RESET_SUCCESS_ERROR_STATE,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  returns: null,
  success: "",
  imagesUrls: undefined,
  selectedReturnItem: null,
  uploadImagesModal: false,
  addReturnModal: false,
  filtersSideBarIsOpen: false,
};

const Returns = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RETURNS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_RETURNS_SUCCESS:
      state = { ...state, returns: action.payload, loading: false };
      break;
    case UPDATE_RETURN:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case UPDATE_RETURN_SUCCESS:
      state = {
        ...state,
        success: "Return Updated Successfully",
        loading: false,
        selectedReturnItem: undefined,
        noteReturnItemModalIsOpen: false,
      };
      break;
    case SET_SELECTED_RETURN_ITEM:
      state = { ...state, selectedReturnItem: action.payload };
      break;
    case SET_UPLOAD_IMAGES_MODAL:
      state = { ...state, uploadImagesModal: action.payload };
      break;
    case SET_ADD_RETURN_MODAL:
      state = { ...state, addReturnModal: action.payload };
      break;
    case SET_FILTER_BAR:
      state = { ...state, filtersSideBarIsOpen: action.payload };
      break;
    case MOVE_TO_INVENTORY:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case MOVE_TO_INVENTORY_SUCCESS:
      state = { ...state, loading: false, error: "", success: action.payload.message };
      break;
    case UPLOAD_ISSUE_IMAGES:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPLOAD_ISSUE_IMAGES_SUCCESS:
      state = { ...state, loading: false, success: "File Uploaded Successfully", uploadImagesModal: false };
      break;
    case UPDATE_BULK_RETURNS:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case UPDATE_BULK_RETURNS_SUCCESS:
      state = { ...state, loading: false, success: action.payload.message };
      break;
    case DELETE_IMAGE:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case DELETE_IMAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload.res.message,
        uploadImagesModal: action.payload.isOpen,
        imagesUrls: action.payload.imagesUrls,
      };
      break;
    case GET_IMAGES_URL:
      state = { ...state, success: "", error: "", loading: true, imagesUrls: undefined };
      break;
    case GET_IMAGES_URL_SUCCESS:
      state = { ...state, loading: false, imagesUrls: action.payload };
      break;
    case ADD_ITEM_TO_INVENTORY:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case ADD_ITEM_TO_INVENTORY_SUCCESS:
      state = { ...state, error: "", loading: false, success: action.payload.message };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case RESET_SUCCESS_ERROR_STATE:
      state = { ...state, success: "", error: "" };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Returns;
