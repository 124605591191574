import request from "../restClient";

const resource = "/source_items";

export const updateSourceItem = async ({ id, data }) => {
  const url = `${resource}/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const bulkUpdateItems = ({ data }) => request({ url: `${resource}/bulkUpdate`, method: "PUT", body: data });

export const getFiftyFiftyCount = async () => {
  const url = `${resource}/fiftyFiftyCount`;
  return await request({ url, method: "GET" });
};
