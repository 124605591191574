import { getUserInfo, makeQP } from "../../utils/common";
import request from "../restClient";

export const getAllCredentials = async (params) => {
  const url =
    "/credentials" +
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const createCredential = async ({ data }) => {
  const url = "/credentials";
  return await request({ url, method: "POST", body: data });
};

export const getProxyManual = async (id) => {
  const url = `/get_manual_proxy/${id}`;
  return await request({ url, method: "PUT" });
};

export const refreshAccountProxy = async (id) => {
  const url = `/refresh_proxy/${id}`;
  return await request({ url, method: "PUT" });
};

export const createCreditCardTypes = async ({ data }) => {
  const url = "/credentials/associate_acc";
  return await request({ url, method: "POST", body: data });
};

export const updateCredential = async ({ data, id }) => {
  const url = `/credentials/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const unlockCredential = async ({ email, site }) => {
  const url = `/unlock/${email}/${site}`;
  return await request({ url, method: "GET" });
};

export const deleteCredential = async ({ id }) => {
  const url = `/credentials/${id}`;
  return await request({ url, method: "DELETE" });
};

export const fetchTotp = async (params) => {
  const url = "/totp" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const bulkUpdateCredential = async ({ data }) => {
  const url = "/credentials";
  return await request({ url, method: "PUT", body: data });
};

export const getAllPurchasingAccounts = async (params) => {
  const url =
    "/purchasing_accounts" +
    "?" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const getBuyingEmails = async () => {
  const url = `/credentials/all?tenants[]=${getUserInfo()?.account_id}&manual_fulfilment=true`;
  return await request({ url, method: "GET" });
};

export const createPurchasingAccount = async ({ data }) => {
  const url = "/purchasing_accounts";
  return await request({ url, method: "POST", body: data });
};

export const updatePurchasingAccount = async ({ data, id }) => {
  const url = `/purchasing_accounts/${id}`;
  return await request({ url, method: "PUT", body: data });
};

export const deletePurchasingAccount = async ({ id }) => {
  const url = `/purchasing_accounts/${id}`;
  return await request({ url, method: "DELETE" });
};

export const assignPurchasingAccount = async ({ data }) => {
  const url = "/purchasing_accounts/assign_accounts";
  return await request({ url, method: "POST", body: data });
};
