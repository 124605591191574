import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { MARKET_PLACES } from "../../../constants";
import { getUserInfo, isAdmin, RenderIf } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { updateListingWeight, resetWeightErr } from "../../../store/actions";
import CustomTooltip from "../../../components/Common/CustomTooltip";

const toF = (num) => parseFloat(parseFloat(num).toFixed(2));
const mw100 = { width: "150px" };

const WeightField = ({ amountText, item, weightKey, setWeightKey, storeFront, index }) => {
  const [weight, setWeight] = useState("");
  const { resetState, weightLoader, weightErr } = useSelector((state) => state.Listings);
  const dispatch = useDispatch();

  useEffect(() => {
    !weightLoader && weightKey && setWeightKey("");
  }, [resetState]);

  if (!isAdmin()) return <></>;

  const updateWeight = (_) => {
    dispatch(
      updateListingWeight(index, {
        platform: storeFront.marketplace,
        partner_id: storeFront.value,
        sku: item?.sku,
        weight,
        va_id: getUserInfo()?.id,
      }),
    );
  };

  return (
    <td className="text-center" style={mw100}>
      <RenderIf
        isTrue={storeFront?.marketplace === MARKET_PLACES.walmart}
        fallback={<span style={mw100}>{amountText(item?.weight, "float")}</span>}
      >
        <RenderIf isTrue={weightKey === item?.id} fallback={<span style={mw100}>{amountText(item?.weight, "float")}</span>}>
          <div className="weightInput d-flex align-items-center justify-content-center">
            <input
              type="number"
              value={weight}
              step="0.01"
              min="0.01"
              className="form-control"
              onChange={(e) => setWeight(toF(e.target.value))}
            ></input>
            <RenderIf
              isTrue={!weightLoader}
              fallback={<Spinner color="primary" style={{ width: "20px", height: "20px" }} />}
            >
              <div className="iconBox">
                <RenderIf
                  isTrue={weightErr}
                  fallback={<i className="bx bx-sm bx-check color-primary cursor-pointer" onClick={updateWeight} />}
                >
                  <i
                    className="bx bx-info-circle danger cursor-pointer"
                    style={{ fontSize: "20px" }}
                    id={`tooltip-weight-${index}`}
                  />
                  <CustomTooltip placement="top" content={weightErr} target={`tooltip-weight-${index}`} />
                </RenderIf>
                <i
                  className="bx bx-sm bx-x danger cursor-pointer"
                  onClick={() => {
                    setWeight("");
                    setWeightKey("");
                    dispatch(resetWeightErr());
                  }}
                />
              </div>
            </RenderIf>
          </div>
        </RenderIf>
      </RenderIf>
    </td>
  );
};

export default WeightField;
