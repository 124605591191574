import { ConfirmationAlert } from "../../../components/Common/ConfirmDialog";
import { RenderIf } from "../../../utils/common";
import CheckBox from "../../../components/Common/CheckBox";
import React, { useState } from "react";

export default function ManualBillingCheckBox ({ value, onConfirm }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <RenderIf isTrue={isOpen}>
        <ConfirmationAlert
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          msg={<p>Do you want to {value ? "Disable" : "Enable"} Manual Billing?</p>}
          onConfirm={onConfirm}
        />
      </RenderIf>
      <CheckBox
        name="is_manual_billing"
        label="Manual Billing"
        state={value}
        setState={() => null}
        onClick={() => setIsOpen(!isOpen)}
        isSwitch={true}
      />
    </>
  );
}
