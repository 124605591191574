import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Label, Input, Alert } from "reactstrap";
import { useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "../../../components/Common/Modal";
import { isStrongPassword } from "../../../utils/common";

// lodash
import { startCase } from "lodash";

// actions
import { setAssignUserWareHouseModal, assignUserWareHouse } from "../../../store/actions";

const AssignUserWareHouseModal = (_) => {
  const [isShownPass, setIsShownPass] = useState(false);
  const dispatch = useDispatch();
  const { assignUserWareHouseModalIsOpen, selectedWarehouse, error } = useSelector((state) => state.WareHouses);
  const initialValues = { email: "", password: "" };
  const toggleModal = () => {
    dispatch(setAssignUserWareHouseModal(!assignUserWareHouseModalIsOpen));
    formik.resetForm();
  };

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "") errors[key] = "Field is Required";
    });
    if (values.password && !isStrongPassword(values.password))
      errors.password =
        "Password must contain at least one uppercase letter, one number, one special character and must be of 8 characters minimum.";
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      dispatch(assignUserWareHouse({ data: { warehouse_id: selectedWarehouse.id, ...values } }));
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={assignUserWareHouseModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">{`ASSIGN USER TO "${startCase(selectedWarehouse.state)}" WAREHOUSE`}</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mt-2">
                <Label>Email</Label>
                <Input
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email
                  ? (
                  <small className="text-danger">{formik.errors.email}</small>
                    )
                  : null}
              </Col>
            </Row>
            <Row>
              <Col lg="12 mt-2">
                <Label>Password</Label>
                <div className="d-flex justify-content-between">
                  <Input
                    name="password"
                    type={isShownPass ? "text" : "password"}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <span className="input-group-append">
                    <span
                      className="input-group-text colorpicker-input-addon rounded-right"
                      onClick={() => {
                        setIsShownPass(!isShownPass);
                      }}
                    >
                      <i className="bx bx-xs bx-show mx-1 cursor-pointer"></i>
                    </span>
                  </span>
                </div>
                {formik.touched.password && formik.errors.password
                  ? (
                  <small className="text-danger">{formik.errors.password}</small>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" className="mr-3">
                Add User
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default AssignUserWareHouseModal;
