import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardHeader, Container, Alert, FormGroup, Col, Nav, NavItem, NavLink } from "reactstrap";
import Select from "react-select";
import { useSelector, connect } from "react-redux";
import queryString from "query-string";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PillBtn from "../../components/Common/PillBtn";
import CustomPagination from "../../components/Common/CustomPagination";
import BulkBrandsUpdateModal from "./Components/BulkBrandsUpdateModal";
import RestrictedBrandModal from "./Components/RestrictedBrandModal";
import { ConfirmDialogBtn, ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import { getUserInfo, isAgency, RenderIf, toPascalCase } from "../../utils/common";
import { BRAND_OPTIONS } from "../../constants/index";
import SweetAlert from "react-bootstrap-sweetalert";

// actions
import {
  fetchRestrictedBrands,
  setPreloader,
  setSelectedBrands,
  bulkUpdateBrands,
  setBrandsBulkUpdateModal,
  setAddBrandModal,
  setBulkAddBrandModal,
  setEditAddBrandModal,
  deleteBrand,
} from "../../store/actions";
import classnames from "classnames";
import { compact } from "lodash";

const brandDesccription = {
  manual: {
    title: "We have confirmed that this brand issues IP complaints and/or Cease & Desist letters to sellers.",
    class: "text-danger",
  },
  scraped: {
    title: "This brand has reported to give IP complaints by other sources.",
    class: "text-warning",
  },
  system: {
    title:
      "This brand has been restricted by the marketplace for your account. Continuing to list these items could eventually lead to suspension.",
    class: "text-info",
  },
};

const selectTypes = {
  current_page: "current_page",
  all_selection: "all_selection",
};

const RestrictedBrands = (props) => {
  const [bulkSelectType, setBulkSelectType] = useState(selectTypes.current_page);
  const [selectedBrand, setSelectedBrand] = useState();
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const queryParameters = queryString.parse(props.location.search);
  const searchRef = useRef(null);
  const { accounts, loading: AccountLoading } = useSelector((state) => state.MarketPlaceAccount);
  const [activeTab, setActiveTab] = useState(0);
  const [restrictedConfirmation, setRestrictedConfirmation] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const tabs = [
    {
      title: "Walmart",
      id: 0,
      platform: "walmart",
    },
    {
      title: "Amazon",
      id: 1,
      platform: "amazon",
    },
  ];

  const [search, setSearch] = useState({
    field: "name",
    operator: "like",
    value: "",
  });

  const marketplaceAccounts = new Set(
    accounts?.data.reduce((acc, account) => {
      if (account.valid && account.enabled) acc.push(account.marketplace);
      return acc;
    }, []),
  );

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [filters, setFilters] = useState({
    page_number: queryParameters?.page || 1,
    per_page: 30,
    platform: tabs[activeTab].platform,
    filters_hash: {
      filters: [],
    },
  });

  const tableHeaders = compact([
    {
      title: "Name",
    },
    !isAgency() && { title: "Source" },
    {
      title: "Restricted",
    },
    {
      title: "Action",
    },
  ]);

  const RestrictedBrandIds = props.RestrictedBrands.restrictedBrands?.results?.map((x) => x.id);

  function selectAllBrands () {
    props.setSelectedBrands(RestrictedBrandIds?.map((item) => item));
  }

  function handleUpdateBulkBrands () {
    props.setBrandsBulkUpdateModal(true);
  }

  function handleDeleteBulkBrands (item) {
    props.deleteBrand({
      data: {
        va_id: getUserInfo().id,
        platform: tabs[activeTab].platform,
      },
      id: Array.isArray(item) ? item : isDeleteAll ? [] : props.RestrictedBrands.selectedBrands,
    });
    props.setSelectedBrands([]);
  }

  useEffect(() => {
    props.setSelectedBrands([]);
  }, [filters.page_number]);

  useEffect(() => {
    props.setPreloader(props.RestrictedBrands.loading);
  }, [props.RestrictedBrands.loading]);

  useEffect(() => {
    if (!props.RestrictedBrands.loading && (accounts?.data || isAgency())) {
      if (marketplaceAccounts.size === 1 && marketplaceAccounts.has("amazon")) {
        setActiveTab(1);
        props.fetchRestrictedBrands({ ...filters, platform: tabs[1].platform });
        setFilters({ ...filters, platform: tabs[1].platform });
      } else {
        props.fetchRestrictedBrands({
          ...filters,
          platform: tabs[activeTab].platform,
        });
        setFilters({ ...filters, platform: tabs[activeTab].platform });
      }
    }
  }, [filters, accounts]);

  return (
    <React.Fragment>
      {props.RestrictedBrands.brandsBulkUpdateModalIsOpen && (
        <BulkBrandsUpdateModal platform={tabs[activeTab].platform} selectType={bulkSelectType} />
      )}
      {props.RestrictedBrands.addBrandModalIsOpen && (
        <RestrictedBrandModal platform={tabs[activeTab].platform} type="add" />
      )}

      <RenderIf isTrue={props.RestrictedBrands.bulkAddBrandModalIsOpen}>
        <RestrictedBrandModal platform={tabs[activeTab].platform} type="bulkAdd" />
      </RenderIf>

      {props.RestrictedBrands.editBrandModalIsOpen && (
        <RestrictedBrandModal platform={tabs[activeTab].platform} selectedBrand={selectedBrand} type="edit" />
      )}
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            props.bulkUpdateBrands(restrictedConfirmation);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {`to ${restrictedConfirmation?.blacklist ? "enable" : "disable"} restricted?`}
        </SweetAlert>
      </RenderIf>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Restricted Brands"
            children={
              <div className="d-flex flex-row">
                {props.RestrictedBrands.selectedBrands?.length > 0 && (
                  <>
                    <RenderIf isTrue={isAgency()}>
                      <ConfirmDialogBtn
                        button={{
                          title: "Bulk Delete",
                          icon: "bx bx-xs bx-trash",
                          color: "danger",
                        }}
                        msg={<p>Delete All Selected Brands</p>}
                        onConfirm={handleDeleteBulkBrands}
                      />
                    </RenderIf>
                    <PillBtn
                      color="warning"
                      title="Bulk Update"
                      name="Bulk Update"
                      icon="bx bx-xs bx-edit"
                      className="mr-1"
                      onClick={handleUpdateBulkBrands}
                    />
                  </>
                )}
                <RenderIf isTrue={isAgency()}>
                  <div className="d-flex">
                    <a
                      className="mr-1 btn btn-primary waves-effect waves-light"
                      href={"/restricted_brands_sample.csv"}
                      download
                    >
                      Download Sample File
                    </a>
                  </div>
                  <PillBtn
                    className="mr-1"
                    color="info"
                    title="Add brands in bulk"
                    name="Bulk Add"
                    icon="bx bx-xs bx-plus"
                    onClick={() => props.setBulkAddBrandModal(true)}
                  />
                </RenderIf>
                <PillBtn
                  color="info"
                  title="Add new brand"
                  name="Add"
                  icon="bx bx-xs bx-plus"
                  onClick={() => props.setAddBrandModal(true)}
                />
              </div>
            }
          />

          {props.RestrictedBrands?.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.RestrictedBrands?.success || ""}
              </Alert>
            </div>
          )}

          {props.RestrictedBrands.error && (
            <div className="auto-hide">
              <Alert color="danger" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.RestrictedBrands.error || ""}
              </Alert>
            </div>
          )}

          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setFilters({
                      ...filters,
                      page_number: 1,
                      filters_hash: {
                        filters: [
                          ...filters.filters_hash.filters.filter((x) => x.field !== "name"),
                          {
                            field: search.field,
                            operator: search.operator,
                            value: search.value.trim(),
                          },
                        ],
                      },
                    });
                  }}
                >
                  <div className="position-relative" style={{ display: "inline-flex" }}>
                    <input
                      id="search_field"
                      type="text"
                      ref={searchRef}
                      className="form-control"
                      placeholder="Search by Brand"
                      value={search.value}
                      onChange={(e) => {
                        if (!e.target.value.trim()) {
                          setSearch({
                            ...search,
                            value: "",
                          });
                          setFilters({
                            ...filters,
                            page_number: 1,
                            filters_hash: {
                              filters: [
                                ...filters.filters_hash.filters.filter((x) => x.field !== "name"),
                                {
                                  field: search.field,
                                  operator: search.operator,
                                  value: "",
                                },
                              ],
                            },
                          });
                        } else {
                          setSearch({
                            ...search,
                            value: e.target.value,
                          });
                        }
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
                <div className="d-flex">
                  <FormGroup className="select2-container mt-3 mr-2" style={{ minWidth: "200px" }}>
                    <Select
                      name="blacklist"
                      value={{
                        value: filters.filters_hash.filters?.find((x) => x.field === "blacklist")?.value || "",
                        label:
                          filters.filters_hash.filters?.find((x) => x.field === "blacklist")?.label || "All Brands",
                      }}
                      onChange={(e) => {
                        const index = filters.filters_hash.filters?.findIndex((x) => x.field === "blacklist");
                        if (index !== -1) {
                          filters.filters_hash.filters[index].value = e.value;
                          filters.filters_hash.filters[index].label = e.label;
                          setFilters({ ...filters, page_number: 1 });
                        } else {
                          setFilters({
                            ...filters,
                            page_number: 1,
                            filters_hash: {
                              filters: [
                                ...filters.filters_hash.filters,
                                {
                                  field: "blacklist",
                                  operator: "=",
                                  value: e.value,
                                  label: e.label,
                                },
                              ],
                            },
                          });
                        }
                      }}
                      options={Object.keys(BRAND_OPTIONS).map((brand, i) => ({
                        label: toPascalCase(brand),
                        value: BRAND_OPTIONS[brand],
                        key: i,
                      }))}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <RenderIf isTrue={!isAgency()}>
                    <FormGroup className="select2-container mt-3" style={{ minWidth: "200px" }}>
                      <Select
                        name="source"
                        value={{
                          value: filters.filters_hash.filters?.find((x) => x.field === "source")?.value || "",
                          label:
                            filters.filters_hash.filters?.find((x) => x.field === "source")?.label || "Select a Source",
                        }}
                        onChange={(e) => {
                          if (e.value !== "") {
                            const index = filters.filters_hash.filters?.findIndex((x) => x.field === "source");
                            if (index !== -1) {
                              filters.filters_hash.filters[index].value = e.value;
                              filters.filters_hash.filters[index].label = e.label;
                              setFilters({ ...filters, page_number: 1 });
                            } else {
                              setFilters({
                                ...filters,
                                page_number: 1,
                                filters_hash: {
                                  filters: [
                                    ...filters.filters_hash.filters,
                                    {
                                      field: "source",
                                      operator: "=",
                                      value: e.value,
                                      label: e.label,
                                    },
                                  ],
                                },
                              });
                            }
                          }
                        }}
                        options={Object.keys(props.RestrictedBrands.restrictedBrands?.sources || {}).map((key, i) => ({
                          label: toPascalCase(key),
                          value: props.RestrictedBrands.restrictedBrands.sources[key],
                          key: i,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </RenderIf>
                </div>
              </div>
            </CardHeader>

            <Col lg={12}>
              <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {tabs.map((tab, index) => (
                    <React.Fragment key={index}>
                      <RenderIf isTrue={marketplaceAccounts.has(tab.platform) || isAgency()}>
                        <NavItem key={`tab-${index}`}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === index,
                            })}
                            onClick={() => {
                              if (activeTab !== index) {
                                props.setSelectedBrands([]);
                                toggleActiveTab(index);
                                setFilters({
                                  page_number: 1,
                                  ...filters,
                                  platform: tab.platform,
                                });
                              }
                            }}
                          >
                            <span className="d-none d-sm-block">{tab.title}</span>
                          </NavLink>
                        </NavItem>
                      </RenderIf>
                    </React.Fragment>
                  ))}
                </Nav>
              </CardBody>
            </Col>
            {props.RestrictedBrands.selectedBrands?.length
              ? (
              <div className="blockquote-warning">
                <h6>
                  {bulkSelectType === selectTypes.current_page
                    ? props.RestrictedBrands.selectedBrands?.length
                    : props.RestrictedBrands.restrictedBrands?.total_count}{" "}
                  brands selected {bulkSelectType === selectTypes.current_page ? "on current page" : "for all pages"}
                </h6>
                {bulkSelectType === selectTypes.all_selection && props.RestrictedBrands.selectedBrands?.length
                  ? (
                  <h6
                    className="cursor-pointer"
                    onClick={() => {
                      setBulkSelectType(selectTypes.current_page);
                      props.setSelectedBrands([]);
                    }}
                  >
                    Deselect All {props.RestrictedBrands.restrictedBrands?.total_count}
                  </h6>
                    )
                  : (
                  <h6
                    className="cursor-pointer"
                    onClick={() => {
                      setBulkSelectType(selectTypes.all_selection);
                      selectAllBrands();
                      setIsDeleteAll(true);
                    }}
                  >
                    Select All {props.RestrictedBrands.restrictedBrands?.total_count} filtered brands
                  </h6>
                    )}
              </div>
                )
              : null}
            <CardBody>
              {!props.RestrictedBrands.restrictedBrands?.results?.length &&
                !props.RestrictedBrands.loading &&
                !AccountLoading && <h2 className="text-center">No Records Found</h2>}
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {props.RestrictedBrands.restrictedBrands?.results?.length
                        ? (
                        <th className="input-width">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={
                                RestrictedBrandIds?.length > 0 &&
                                RestrictedBrandIds?.length === props.RestrictedBrands.selectedBrands?.length
                              }
                              onChange={() => false}
                            />
                            <label
                              className="custom-control-label"
                              onClick={(e) => {
                                if (RestrictedBrandIds?.length === props.RestrictedBrands.selectedBrands?.length) {
                                  setBulkSelectType(selectTypes.current_page);
                                  props.setSelectedBrands([]);
                                } else {
                                  selectAllBrands();
                                }
                              }}
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th>
                          )
                        : null}
                      {/* table headers */}
                      {props.RestrictedBrands.restrictedBrands?.results?.length
                        ? tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`} onClick={header.onClick}>
                              {header.title}
                              {header.sort
                                ? (
                                <div className="d-inline ml-1">
                                  <i id="up-filter" className="dripicons-arrow-thin-up cursor-pointer"></i>
                                  <i id="down-filter" className="dripicons-arrow-thin-down cursor-pointer"></i>
                                </div>
                                  )
                                : (
                                    ""
                                  )}
                            </th>
                        ))
                        : null}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {props.RestrictedBrands.restrictedBrands?.results?.map((item, key) => (
                      <tr key={"_brands_" + key} className="mt-3 mb-3">
                        <td>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={!!props.RestrictedBrands.selectedBrands?.find((x) => x === item?.id)}
                              onChange={() => false}
                            />
                            <label
                              className="custom-control-label mt-2"
                              onClick={() => {
                                setBulkSelectType(selectTypes.current_page);
                                if (props.RestrictedBrands.selectedBrands?.find((x) => x === item?.id)) {
                                  props.setSelectedBrands(
                                    props.RestrictedBrands.selectedBrands?.filter((x) => item.id !== x),
                                  );
                                } else {
                                  props.setSelectedBrands([...props.RestrictedBrands.selectedBrands, item.id]);
                                }
                              }}
                            ></label>
                          </div>
                        </td>
                        <td style={{ userSelect: "none" }}>{item?.name}</td>
                        <RenderIf isTrue={!isAgency()}>
                          <td>{brandDescriptionIcon(item?.source)}</td>
                        </RenderIf>
                        <td>
                          <div className="custom-control custom-switch cursor-pointer">
                            <input
                              type="checkbox"
                              name="skip_brand_restriction"
                              className="custom-control-input"
                              checked={!!item?.blacklist}
                              onChange={() => null}
                            />
                            <label
                              onClick={() => {
                                setRestrictedConfirmation({
                                  va_id: getUserInfo().id,
                                  platform: tabs[activeTab].platform,
                                  blacklist: !item?.blacklist,
                                  update_type: selectTypes.current_page,
                                  brand_ids: [item.id],
                                });
                                setIsOpen(true);
                              }}
                              className="custom-control-label"
                            />
                          </div>
                        </td>
                        <td>
                          <RenderIf isTrue={!isAgency()}>
                            <i
                              title="Edit"
                              className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                              onClick={() => {
                                setSelectedBrand(item);
                                props.setEditAddBrandModal(true);
                              }}
                            />
                          </RenderIf>
                          <ConfirmDialogIcon
                            icon={{
                              title: "Delete Account",
                              className: "bx bx-sm text-danger bx-trash",
                            }}
                            msg={<p>To Delete Brand</p>}
                            onConfirm={() => handleDeleteBulkBrands([item.id])}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {props.RestrictedBrands?.restrictedBrands?.total_count &&
              props.RestrictedBrands.restrictedBrands?.results?.length
                ? (
                <CustomPagination
                  total={props.RestrictedBrands?.restrictedBrands?.total_count}
                  page={props.RestrictedBrands.restrictedBrands.page_number}
                  perPage={props.RestrictedBrands.restrictedBrands.per_page}
                  tabsFilter={filters}
                  setTabFilter={setFilters}
                  pageOptions={[30, 100]}
                  matching={true}
                />
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

function brandDescriptionIcon (source) {
  const brand = brandDesccription[source] || brandDesccription.manual;
  return <i title={brand.title} className={"bx bx-sm bx-info-circle cursor-pointer " + brand.class}></i>;
}

const mapStatetoProps = (state) => {
  const { RestrictedBrands } = state;
  return { RestrictedBrands };
};

export default connect(mapStatetoProps, {
  fetchRestrictedBrands,
  setSelectedBrands,
  setBrandsBulkUpdateModal,
  bulkUpdateBrands,
  setPreloader,
  setAddBrandModal,
  setBulkAddBrandModal,
  setEditAddBrandModal,
  deleteBrand,
})(RestrictedBrands);
