import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { Button, FormGroup, Row, Col, Alert, Label } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { SUPPLIER_ORDER_STATUSES, ALL_SUPPLIERS } from "../../../constants";

// actions
import {
  updateSupplierOrder,
  setPreloader,
  fetchCreditCardDetails,
  refreshSourceOrderDetail,
  resetSupplierOrderState,
  fetchBuyingEmails,
} from "../../../store/actions";
import { decode, getSupplierName } from "../../../utils/common";
import getBrandImageSrc from "../../../utils/brandImages";
import { startCase } from "lodash";

const { Option } = components;

const EditSupplierOrder = (props) => {
  const dispatch = useDispatch();
  const { allAccounts } = useSelector((state) => state.Credential);
  const [formData, setFormData] = useState({
    id: props?.supplierOrder?.id,
    purchasing_account_name: props?.supplierOrder?.purchasing_account_name,
    target_order_id: props?.supplierOrder?.target_order_id,
    cc_id: props?.supplierOrder?.cc_id,
    cost: props?.supplierOrder?.cost,
    qty: props?.supplierOrder?.qty,
    qty_multiplier: props?.supplierOrder?.qty_multiplier,
    shipping_cost: props?.supplierOrder?.shipping_cost || "0",
    tax: props?.supplierOrder?.tax || "0",
    order_total: props?.supplierOrder?.order_total,
    status: props?.supplierOrder?.status,
    promotions: props?.supplierOrder?.promotions || "0",
    sku: props?.supplierOrder?.sku,
    supplier: props?.supplierOrder?.supplier,
  });
  const toggleModal = () => {
    reset();
    props.toggleIsOpen(!props.isOpen);
  };

  function returnCard (id) {
    const card = props.CreditCards.ccData.data?.find((x) => x.id === id);
    if (!card) return;
    return `${card?.last_four} - ${decode(card?.card_holder_name)}`;
  }

  const reset = () => {
    setFormData({
      id: props?.supplierOrder?.id,
      purchasing_account_name: props?.supplierOrder?.purchasing_account_name,
      target_order_id: props?.supplierOrder?.target_order_id,
      cc_id: props?.supplierOrder?.cc_id,
      cost: props?.supplierOrder?.cost,
      qty: props?.supplierOrder?.qty,
      qty_multiplier: props?.supplierOrder?.qty_multiplier,
      shipping_cost: props?.supplierOrder?.shipping_cost,
      tax: props?.supplierOrder?.tax,
      order_total: props?.supplierOrder?.order_total,
      status: props?.supplierOrder?.status,
      promotions: props?.supplierOrder?.promotions,
      sku: props?.supplierOrder?.sku,
      supplier: props?.supplierOrder?.supplier,
    });
  };

  const handleFormSubmit = () => {
    Object.keys(formData).forEach((val) => formData[val] === "" && delete formData[val]);
    props.updateSupplierOrder({
      data: {
        ...formData,
        order_total: (
          parseFloat(formData.cost) * parseFloat(formData.qty) +
          parseFloat(formData.tax) +
          parseFloat(formData.shipping_cost) -
          parseFloat(formData.promotions)
        )?.toFixed(2),
      },
      sourceOrderId: props.supplierOrder?.source_order_id,
    });
  };

  useEffect(() => {
    props.fetchCreditCardDetails({ pagination: false });
    !allAccounts?.length && dispatch(fetchBuyingEmails());
  }, []);

  useEffect(() => {
    if (props.SupplierOrders.success && !props.SupplierOrders.loading) {
      props.refreshSourceOrderDetail();
      toggleModal();
    }
  }, [props.SupplierOrders.success, props.SupplierOrders.loading]);

  useEffect(() => {
    props.setPreloader(props.SupplierOrders.loading);
  }, [props.SupplierOrders.loading]);

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Edit Supplier Order
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SupplierOrders.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SupplierOrders.error}
            </Alert>
          )}
          <div className="table-responsive overflow-auto mb-2" style={{ maxHeight: "300px" }}></div>
          <AvForm onValidSubmit={handleFormSubmit}>
            <Row className="m-1">
              {}
              <Col lg="6">
                <FormGroup className="select2-container w-100">
                  <Label>Supplier*</Label>
                  <Select
                    name="supplier"
                    value={{ label: formData.supplier, value: formData.supplier }}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        supplier: e.value,
                      });
                    }}
                    options={Object.keys(ALL_SUPPLIERS).map((acc) => ({
                      label: startCase(getSupplierName(acc)),
                      value: acc,
                    }))}
                    classNamePrefix="select2-selection"
                    height={{ maxHeight: "250px" }}
                    components={{
                      Option: (_props) => (
                        <components.Option {..._props}>
                          <img src={getBrandImageSrc(_props.data.value)} alt="store logo" />
                          {_props.data.label}
                        </components.Option>
                      ),
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <Label>Supplier Account Email*</Label>
                <FormGroup className="select2-container">
                  <Select
                    name="purchasing_account_name"
                    value={{
                      value: formData.purchasing_account_id || "",
                      label: formData.purchasing_account_name,
                    }}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        purchasing_account_name: e.label,
                        purchasing_account_id: e.value,
                      });
                    }}
                    options={allAccounts?.map(
                      (acc, i) => ({
                        label: acc.email,
                        value: acc.id,
                        site: acc.site,
                        key: i,
                      }),
                      "label",
                    )}
                    components={{ Option: IconOption }}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>

              <Col lg="6">
                <AvField
                  name="target_order_id"
                  label="Supplier Order Id*"
                  placeholder="Supplier Order Id"
                  type="text"
                  errorMessage="Field is Required"
                  validate={{ required: { value: true } }}
                  value={formData.target_order_id || ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      target_order_id: e.target.value,
                    })
                  }
                />
              </Col>
              <Col lg="6">
                <AvField
                  name="supplier_order_status"
                  label="Status*"
                  placeholder="Select Status"
                  type="select"
                  errorMessage="Select Status"
                  validate={{ required: { value: true } }}
                  value={formData.status || ""}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                >
                  <option value="">Select Status</option>

                  {Object.keys(SUPPLIER_ORDER_STATUSES).map((key, index) => (
                    <option key={"_status_select_" + index} value={SUPPLIER_ORDER_STATUSES[key]}>
                      {SUPPLIER_ORDER_STATUSES[key]}
                    </option>
                  ))}
                </AvField>
              </Col>
              {props.CreditCards?.ccData?.data?.length ? (
                <Col lg="6">
                  <Label>Credit Card*</Label>
                  <FormGroup className="select2-container">
                    <Select
                      name="cc_id"
                      value={{
                        value: formData.cc_id || "",
                        label: returnCard(formData.cc_id) || "",
                      }}
                      onChange={(e) => {
                        setFormData({ ...formData, cc_id: e.value });
                      }}
                      options={props.CreditCards.ccData.data.map((card, i) => ({
                        label: `${card.last_four} - ${decode(card.card_holder_name)}`,
                        value: card.id,
                        key: i,
                      }))}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              ) : null}

              <Col lg="3">
                <AvField
                  name="cost"
                  label="Cost*"
                  placeholder="Cost Per Item"
                  type="number"
                  min="0"
                  step="0.01"
                  errorMessage="Field is Required"
                  validate={{ required: { value: true } }}
                  value={formData.cost || ""}
                  onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
                />
              </Col>
              <Col lg="3">
                <AvField
                  name="promotions"
                  label="Promotions*"
                  placeholder="Gift credits amount"
                  type="number"
                  min="0"
                  step="0.01"
                  value={formData.promotions || ""}
                  onChange={(e) => setFormData({ ...formData, promotions: e.target.value })}
                />
              </Col>
              <Col lg="6">
                <AvField
                  name="qty"
                  label="Qty Placed*"
                  placeholder="Quantity Placed"
                  type="number"
                  min="1"
                  errorMessage="Field is Required"
                  validate={{ required: { value: true } }}
                  value={formData.qty || ""}
                  onChange={(e) => setFormData({ ...formData, qty: e.target.value })}
                />
              </Col>
              <Col lg="6">
                <AvField
                  name="shipping_cost"
                  label="Shipping*"
                  placeholder="Shipping"
                  type="number"
                  min="0"
                  step="0.01"
                  errorMessage="Field is Required"
                  validate={{ required: { value: true } }}
                  value={formData.shipping_cost}
                  onChange={(e) => setFormData({ ...formData, shipping_cost: e.target.value })}
                />
              </Col>
              <Col lg="6">
                <AvField
                  name="tax"
                  label="Tax*"
                  placeholder="Tax"
                  type="number"
                  min="0"
                  step="0.01"
                  errorMessage="Field is Required"
                  validate={{ required: { value: true } }}
                  value={formData.tax}
                  onChange={(e) => setFormData({ ...formData, tax: e.target.value })}
                />
              </Col>
              <Col lg="6">
                <Label>Total*</Label>
                <AvInput
                  name="order_total"
                  label="Total*"
                  placeholder="Total"
                  type="number"
                  min="0"
                  readOnly
                  errorMessage="Field is Required"
                  validate={{ required: { value: true } }}
                  value={
                    (
                      parseFloat(formData.cost) * parseFloat(formData.qty) +
                      parseFloat(formData.tax) +
                      parseFloat(formData.shipping_cost) -
                      parseFloat(formData.promotions)
                    )?.toFixed(2) || ""
                  }
                />
              </Col>
            </Row>
            <FormGroup className="d-flex justify-content-end">
              <div>
                <Button type="reset" color="secondary" className="mr-3" onClick={reset}>
                  Reset
                </Button>
                <Button type="submit" color="success" className="mr-3">
                  Update
                </Button>
              </div>
            </FormGroup>
          </AvForm>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SupplierOrders, CreditCards, SourceOrders } = state;
  return { SupplierOrders, CreditCards, SourceOrders };
};

export default connect(mapStateToProps, {
  fetchCreditCardDetails,
  setPreloader,
  refreshSourceOrderDetail,
  resetSupplierOrderState,
  updateSupplierOrder,
  fetchBuyingEmails,
})(EditSupplierOrder);

const IconOption = (props) => (
  <Option {...props}>
    <div className="d-flex flex-row align-items-center">
      <span>{props.data.label}</span>
      {getBrandImageSrc(props.data.site) && (
        <img
          className="ml-2"
          style={{ height: "28px", width: "28px" }}
          src={getBrandImageSrc(props.data.site)}
          alt="supplier logo"
        />
      )}
    </div>
  </Option>
);
