import {
  FETCH_PURCHASING_ACCOUNTS,
  FETCH_PURCHASING_ACCOUNTS_SUCCESS,
  SET_ADD_PURCHASING_ACCOUNTS_MODAL,
  SET_EDIT_PURCHASING_ACCOUNTS_MODAL,
  CREATE_PURCHASING_ACCOUNTS,
  CREATE_PURCHASING_ACCOUNTS_SUCCESS,
  SELECT_PURCHASING_ACCOUNTS,
  UPDATE_PURCHASING_ACCOUNTS,
  UPDATE_PURCHASING_ACCOUNTS_SUCCESS,
  DELETE_PURCHASING_ACCOUNT,
  DELETE_PURCHASING_ACCOUNT_SUCCESS,
  ADD_USERS_PURCHASING_ACCOUNT,
  ADD_USERS_PURCHASING_ACCOUNT_SUCCESS,
  SET_CREATE_CREDIT_CARD_TYPES_MODAL,
  CREATE_CREDIT_CARD_TYPES,
  SET_ADD_CREDENTIALS_MODAL,
  SET_EDIT_CREDENTIALS_MODAL,
  CREATE_CREDENTIALS,
  CREATE_CREDENTIALS_SUCCESS,
  CREATE_CREDIT_CARD_TYPES_SUCCESS,
  UPDATE_CREDENTIALS,
  UPDATE_CREDENTIALS_SUCCESS,
  SELECT_CREDENTIALS,
  UNLOCK_CREDENTIAL,
  UNLOCK_CREDENTIAL_SUCCESS,
  DELETE_CREDENTIAL,
  DELETE_CREDENTIAL_SUCCESS,
  SET_SELECTED_IDS,
  SET_CREDENTIALS_FILTERS,
  SET_CREDENTIAL_BULK_UPDATE_MODAL,
  CREDENTIAL_BULK_UPDATE,
  CREDENTIAL_BULK_UPDATE_SUCCESS,
  API_ERROR,
  FETCH_BUYING_EMAILS,
  FETCH_BUYING_EMAILS_SUCCESS,
  ADD_PROXY_MANUAL,
  ADD_PROXY_MANUAL_SUCCESS,
  REFRESH_ACCOUNT_PROXY,
  REFRESH_ACCOUNT_PROXY_SUCCESS,
  FETCH_TOTP,
  FETCH_TOTP_SUCCESS,
} from "./actionTypes";

export const fetchTotp = (payload) => ({ type: FETCH_TOTP, payload });

export const fetchTotpSuccess = (payload) => ({ type: FETCH_TOTP_SUCCESS, payload });

export const fetchPurchasingAccounts = () => {
  return {
    type: FETCH_PURCHASING_ACCOUNTS,
    payload: {},
  };
};

export const fetchPurchasingAccountsSuccess = (credentials) => {
  return {
    type: FETCH_PURCHASING_ACCOUNTS_SUCCESS,
    payload: credentials,
  };
};

export const fetchBuyingEmails = () => {
  return {
    type: FETCH_BUYING_EMAILS,
    payload: {},
  };
};

export const fetchBuyingEmailsSuccess = (credentials) => {
  return {
    type: FETCH_BUYING_EMAILS_SUCCESS,
    payload: credentials,
  };
};

export const unlockCredential = ({ email, site }) => {
  return {
    type: UNLOCK_CREDENTIAL,
    payload: { email, site },
  };
};

export const unlockCredentialSuccess = () => {
  return {
    type: UNLOCK_CREDENTIAL_SUCCESS,
    payload: {},
  };
};

export const deleteCredential = (id) => {
  return {
    type: DELETE_CREDENTIAL,
    payload: { id },
  };
};

export const deleteCredentialSuccess = () => {
  return {
    type: DELETE_CREDENTIAL_SUCCESS,
    payload: {},
  };
};

export const deletePurchasingAccount = (id) => {
  return {
    type: DELETE_PURCHASING_ACCOUNT,
    payload: { id },
  };
};

export const deletePurchasingAccountSuccess = () => {
  return {
    type: DELETE_PURCHASING_ACCOUNT_SUCCESS,
    payload: {},
  };
};

export const assignAccountToUsers = ({ data }) => {
  return {
    type: ADD_USERS_PURCHASING_ACCOUNT,
    payload: { data },
  };
};

export const assignAccountToUsersSuccess = () => {
  return {
    type: ADD_USERS_PURCHASING_ACCOUNT_SUCCESS,
    payload: {},
  };
};

export const setSelectedCredentials = (credential) => {
  return {
    type: SELECT_CREDENTIALS,
    payload: credential,
  };
};

export const setSelectedPurchasingAccounts = (account) => {
  return {
    type: SELECT_PURCHASING_ACCOUNTS,
    payload: account,
  };
};

export const createCredentials = ({ data }) => {
  return {
    type: CREATE_CREDENTIALS,
    payload: { data },
  };
};

export const createCredentialsSuccess = () => {
  return {
    type: CREATE_CREDENTIALS_SUCCESS,
    payload: {},
  };
};

export const createPurchasingAccounts = ({ data }) => {
  return {
    type: CREATE_PURCHASING_ACCOUNTS,
    payload: { data },
  };
};

export const createPurchasingAccountsSuccess = () => {
  return {
    type: CREATE_PURCHASING_ACCOUNTS_SUCCESS,
    payload: {},
  };
};

export const createCreditCardTypes = ({ data }) => {
  return {
    type: CREATE_CREDIT_CARD_TYPES,
    payload: { data },
  };
};

export const createCreditCardTypesSuccess = () => {
  return {
    type: CREATE_CREDIT_CARD_TYPES_SUCCESS,
    payload: {},
  };
};

export const updateCredentials = ({ data, id }) => {
  return {
    type: UPDATE_CREDENTIALS,
    payload: { data, id },
  };
};

export const updateCredentialsSuccess = () => {
  return {
    type: UPDATE_CREDENTIALS_SUCCESS,
    payload: {},
  };
};

export const updatePurchasingAccounts = ({ data, id }) => {
  return {
    type: UPDATE_PURCHASING_ACCOUNTS,
    payload: { data, id },
  };
};

export const updatePurchasingAccountsSuccess = () => {
  return {
    type: UPDATE_PURCHASING_ACCOUNTS_SUCCESS,
    payload: {},
  };
};

export const setAddPurchasingAccountsModal = (bool) => {
  return {
    type: SET_ADD_PURCHASING_ACCOUNTS_MODAL,
    payload: bool,
  };
};

export const setAddCredentialsModal = (bool) => {
  return {
    type: SET_ADD_CREDENTIALS_MODAL,
    payload: bool,
  };
};

export const setEditCredentialsModal = (bool) => {
  return {
    type: SET_EDIT_CREDENTIALS_MODAL,
    payload: bool,
  };
};

export const setCredentialsBulkActionModal = (bool) => {
  return {
    type: SET_CREDENTIAL_BULK_UPDATE_MODAL,
    payload: bool,
  };
};

export const bulkUpdateCredentials = ({ data }) => {
  return {
    type: CREDENTIAL_BULK_UPDATE,
    payload: { data },
  };
};

export const bulkUpdateCredentialsSuccess = () => {
  return {
    type: CREDENTIAL_BULK_UPDATE_SUCCESS,
    payload: {},
  };
};

export const setCreditCardTypesModal = (bool, type) => {
  return {
    type: SET_CREATE_CREDIT_CARD_TYPES_MODAL,
    payload: { bool, type },
  };
};

export const setEditPurchasingAccountsModal = (bool) => {
  return {
    type: SET_EDIT_PURCHASING_ACCOUNTS_MODAL,
    payload: bool,
  };
};

export const setCredentialsFilter = (filters) => {
  return {
    type: SET_CREDENTIALS_FILTERS,
    payload: filters,
  };
};

export const setSelectedIds = (id) => {
  return {
    type: SET_SELECTED_IDS,
    payload: id,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const addProxyManual = (data) => {
  return {
    type: ADD_PROXY_MANUAL,
    payload: data,
  };
};

export const addProxyManualSuccess = (data) => {
  return {
    type: ADD_PROXY_MANUAL_SUCCESS,
    payload: data,
  };
};

export const refreshAccountProxy = (data) => {
  return {
    type: REFRESH_ACCOUNT_PROXY,
    payload: data,
  };
};

export const refreshAccountProxySuccess = (data) => {
  return {
    type: REFRESH_ACCOUNT_PROXY_SUCCESS,
    payload: data,
  };
};
