import {
  FETCH_SHARED_LISTING_DATA,
  FETCH_SHARED_LISTING_DATA_SUCCESS,
  SEND_INVOICE_MODAL,
  SEND_INVOICE_EMAIL,
  SEND_INVOICE_EMAIL_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  sharedListings: undefined,
  sendInvoiceModalIsOpen: false,
  success: "",
};

const FiftyFiftyProfit = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHARED_LISTING_DATA:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_SHARED_LISTING_DATA_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        sharedListings: action.payload,
        loading: false,
      };
      break;
    case SEND_INVOICE_EMAIL:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case SEND_INVOICE_EMAIL_SUCCESS:
      state = {
        ...state,
        sendInvoiceModalIsOpen: false,
        success: action.payload,
        error: "",
        loading: false,
      };
      break;
    case SEND_INVOICE_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        sendInvoiceModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      break;
  }
  return state;
};

export default FiftyFiftyProfit;
