import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import "flatpickr/dist/themes/dark.css";
import Modal from "../../../components/Common/Modal";
import Select, { components } from "react-select";
import { values } from "lodash";

// actions
import { setOrdersImportModal, importOrderManually } from "../../../store/actions";
import { MARKET_PLACES } from "../../../constants";
import getBrandImageSrc from "../../../utils/brandImages";

const { Option } = components;
const StoreOption = (props) => (
  <Option {...props}>
    <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
    {props.data.label}
  </Option>
);

const ImportOrderManuallyModal = (props) => {
  const toggleModal = () => props.setOrdersImportModal(!props.SourceOrders.ordersImportModalIsOpen);
  const stores =
    props.MarketPlaceAccount?.accounts?.data
      ?.filter((x) => x.valid)
      ?.reduce((obj, acc) => {
        obj[acc.id] = acc;
        return obj;
      }, {}) || {};

  const formik = useFormik({
    initialValues: {
      storeId: "",
      orderId: "",
    },
    validate: (values) => {
      const errors = {};
      !values.orderId && (errors.orderId = "Order Id required");
      const account = stores[values.storeId?.value];
      if (values.orderId && account?.marketplace) {
        const [isAmazon, isWalmart] = [MARKET_PLACES.amazon, MARKET_PLACES.walmart].map(
          (x) => x === account.marketplace,
        );
        if (isAmazon && !/^\d{3}-\d{7}-\d{7}$/.test(values.orderId)) errors.orderId = "Enter a valid amazon order id";
        if (isWalmart && !/^\d{15}$/.test(values.orderId)) errors.orderId = "Enter a valid walmart order id";
      }
      !values.storeId && (errors.storeId = "Account selection is required");
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (values.storeId && values.orderId) {
        props.importOrderManually(values.orderId, values.storeId.value);
      }
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.SourceOrders.ordersImportModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Import Order Manually</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders.error}
            </Alert>
          )}

          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Col className="my-1">
              <Label>Order Id</Label>
              <Input
                name={"orderId"}
                value={formik.values.orderId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.orderId ? <small className="text-danger m-1">{formik.errors.orderId}</small> : null}
            </Col>
            <Col className="my-1">
              <Label>Marketplace Account</Label>
              <FormGroup className="select2-container w-100">
                <Select
                  name="storeId"
                  value={formik.values.storeId}
                  onChange={(e) => {
                    formik.setFieldValue("storeId", e);
                  }}
                  options={values(stores).reduce((init, acc, i) => {
                    init.push({ label: acc.name, value: acc.id, marketplace: acc.marketplace, key: i });
                    return init;
                  }, [])}
                  classNamePrefix="select2-selection"
                  components={{ Option: StoreOption }}
                />
                {formik.errors.storeId ? <small className="text-danger m-1">{formik.errors.storeId}</small> : null}
              </FormGroup>
            </Col>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders, MarketPlaceAccount } = state;
  return { SourceOrders, MarketPlaceAccount };
};

export default connect(mapStateToProps, {
  setOrdersImportModal,
  importOrderManually,
})(ImportOrderManuallyModal);
