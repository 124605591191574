import * as types from "./actionTypes";

export const fetchReports = (params) => ({ type: types.FETCH_REPORTS, payload: params });
export const fetchReportsSuccess = (res) => ({ type: types.FETCH_REPORTS_SUCCESS, payload: res });

export const downloadReport = (id, qP) => ({ type: types.DOWNLOAD_REPORT, payload: { id, qP } });
export const downloadReportSuccess = (payload) => ({ type: types.DOWNLOAD_REPORT_SUCCESS, payload });

export const genOrderDetailsReport = (payload) => ({ type: types.GEN_ORDER_DETAILS_REPORT, payload });
export const genOrderDetailsReportSuccess = (payload) => ({ type: types.GEN_ORDER_DETAILS_REPORT_SUCCESS, payload });

export const genTransactionReport = (payload) => ({ type: types.GEN_TRANSACTIONAL_REPORT, payload });
export const genTransactionReportSuccess = (payload) => ({ type: types.GEN_TRANSACTIONAL_REPORT_SUCCESS, payload });

export const genSettlementReport = (payload) => ({ type: types.GEN_SETTLEMENT_REPORT, payload });
export const genSettlementReportSuccess = (payload) => ({ type: types.GEN_SETTLEMENT_REPORT_SUCCESS, payload });

export const genOrdersReport = (payload) => ({ type: types.GEN_ORDERS_REPORT, payload });
export const genOrdersReportSuccess = (payload) => ({ type: types.GEN_ORDERS_REPORT_SUCCESS, payload });

export const genInventoryReport = (payload) => ({ type: types.GEN_INVENTORY_REPORT, payload });
export const genInventoryReportSuccess = (payload) => ({ type: types.GEN_INVENTORY_REPORT_SUCCESS, payload });

export const genInventoryInsight = (payload) => ({ type: types.GEN_INVENTORY_INSIGHT, payload });
export const genInventoryInsightSuccess = (payload) => ({ type: types.GEN_INVENTORY_INSIGHT_SUCCESS, payload });

export const genReturnsReport = (payload) => ({ type: types.GEN_RETURNS_REPORT, payload });
export const genReturnsReportSuccess = (payload) => ({ type: types.GEN_RETURNS_REPORT_SUCCESS, payload });

export const genWhLabelReport = (payload) => ({ type: types.GEN_WH_LABEL_REPORT, payload });
export const genWhLabelReportSuccess = (payload) => ({ type: types.GEN_WH_LABEL_REPORT_SUCCESS, payload });

export const getAvailableSettlementReports = (payload) => ({ type: types.GET_AVAILABLE_SETTLEMENT_REPORTS, payload });
export const getAvailableSettlementReportsSucess = (payload) => ({
  type: types.GET_AVAILABLE_SETTLEMENT_REPORTS_SUCCESS,
  payload,
});

export const toggleReportModal = (payload) => ({ type: types.TOGGLE_REPORT_MODAL, payload });
export const resetState = (_) => ({ type: types.RESET_STATE });
export const resetDownloadFileURL = _ => ({ type: types.RESET_URL_DOWNLOAD_FILE_REPORTS, payload: {} });

export const apiError = (error) => ({ type: types.API_ERROR, payload: error });
