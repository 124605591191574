import { useSelector, useDispatch } from "react-redux";
import React, { Suspense, useEffect, useState } from "react";
import { Alert, Container, CardBody, Card, Badge } from "reactstrap";
import PillBtn from "../../../components/Common/PillBtn";
import { getWarehouseName, RenderIf, getUserInfo, parseString } from "../../../utils/common";
import dashboardImg from "../../../assets/svg/warehouse/wh_dashboard_img.svg";
import { USER_ROLES, ORDERABLE_TYPES_MAPPING, WH_NOTICES_TYPES } from "../../../constants";
import { merge, omit, some, get } from "lodash";

// Components
import AddShipmentModal from "../../Inbounds/components/AddShipmentModal";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  fetchUserWarehouses,
  searchOrder,
  setReceivedShipmentModel,
  shipmentsCheckedIn,
  fetchWHInventoryStats,
  fetchWhOutboundStats,
  fetchWHInboundStats,
} from "../../../store/warehouse/actions";
import { setAddShipmentModal, setPreloader } from "../../../store/actions";
import ShipmentsTable from "../../Inbounds/components/ShipmentsTable";
import ReceivedShipmentModal from "../../Inbounds/components/ReceivedShipmentModal";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleSelect from "../../../components/Common/SimpleSelect";
const InboundNoticeModal = React.lazy(() => import("../../WareHouse/Components/AddWarehouseNoteModal"));

const isAdmin = getUserInfo()?.role === USER_ROLES.admin;
const getText = (text) => text || "0";
const ComingSoon = (_) => (
  <p className="font-size-11">
    <Badge color="primary" className="p-1">
      Coming Soon
    </Badge>
  </p>
);

// fetchUserWarehouses
const WarehouseDashboard = (_) => {
  const dispatch = useDispatch();
  const {
    userWarehouses,
    shipments,
    error,
    message,
    loading,
    success,
    addShipmentModal,
    receivedShipmentModel,
    warehouseNoteModalIsOpen,
    inboundShipment,
    whInventoryStats,
    whOutboundStats,
    whInboundStats,
  } = useSelector((state) => state.WareHouses);

  const [isSomeLiquidated, setIsSomeLiquidated] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [searchedCounter, setSearchedCounter] = useState(0);
  const [wareHouseFilter, setWareHouseFilter] = useState({ label: "All Warehouses", value: "" });
  const [typeFilter, setTypeFilter] = useState({ label: "Select Type", value: "" });
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20,
    dashboard: true,
  });
  const [ids, setIds] = useState([]);
  const [shipment, setShipment] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (searchedCounter && isSearched) dispatch(searchOrder(filters));
  }, [searchedCounter, filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    setIsSomeLiquidated(false);
    dispatch(fetchWHInventoryStats());
    dispatch(fetchWhOutboundStats());
    dispatch(fetchWHInboundStats());
    if (isAdmin) {
      dispatch(fetchUserWarehouses());
    }
  }, []);

  useEffect(() => {
    if (!shipments.data || !search) return;
    const hasLiquidatedAccount = some(shipments.data, (obj) => get(obj, "wh_order.account.is_liquidated", false));
    setIsSomeLiquidated(hasLiquidatedAccount);
  }, [shipments.data]);

  useEffect(() => {
    if (shipment) dispatch(setReceivedShipmentModel(true));
  }, [shipment]);

  useEffect(() => {
    window.scrollTo(0, 0);
    success && setSearch("");
  }, [error, success]);

  const searchBar = (minWidth) => (
    <form
      className="app-search d-none d-lg-block"
      onSubmit={(e) => {
        e.preventDefault();
        if (search.trim()) {
          setIsSomeLiquidated(false);
          setIsSearched(true);
          setSearchedCounter(searchedCounter + 1);
          setFilters(merge(filters, { page: 1, search: search.trim() }));
        }
      }}
    >
      <div className="position-relative mr-2">
        <input
          className="form-control"
          placeholder="Search by Tracking #, Order #, UPC, SKU or Title..."
          maxLength="80"
          style={{ minWidth }}
          value={search}
          onChange={(e) => {
            if (!e.target.value) {
              delete filters.search;
              setSearch("");
              setIsSearched(false);
              setFilters({ ...filters });
            } else setSearch(parseString(e.target.value));
          }}
        />
        <span className="bx bx-search-alt"></span>
      </div>
    </form>
  );

  return (
    <React.Fragment>
      <div className="page-content purchase-order">
        <RenderIf isTrue={addShipmentModal}>
          <AddShipmentModal />
        </RenderIf>
        <RenderIf isTrue={receivedShipmentModel}>
          <ReceivedShipmentModal shipment={shipment} setShipment={setShipment} />
        </RenderIf>
        <RenderIf isTrue={warehouseNoteModalIsOpen}>
          <Suspense fallback={<></>}>
            <InboundNoticeModal
              is_warehouse={true}
              notice_type={WH_NOTICES_TYPES.shipment_lines}
              notices={inboundShipment?.warehouse_notices || []}
              selectedItem={inboundShipment}
              warehouses={userWarehouses}
            />
          </Suspense>
        </RenderIf>
        <RenderIf isTrue={isOpen}>
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              dispatch(shipmentsCheckedIn(ids, filters));
              setIsOpen(false);
              setIds();
            }}
            onCancel={() => {
              setIds();
              setIsOpen(false);
            }}
          />
        </RenderIf>
        <RenderIf isTrue={success}>
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {message || success}
            </Alert>
          </div>
        </RenderIf>
        <Container fluid>
          <div className="d-flex flex-row align-items-center">
            {/* Card 1 */}
            <Card style={{ width: "32rem" }} className="d-flex mr-5">
              <CardBody>
                <div className="row mb-3">
                  <div className="col">
                    <h5 className="card-text">Inbound Inventory</h5>
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <h6 className="card-text">POs Orders:</h6>
                    <h3 className="pt-2">{getText(whInboundStats?.inbound_pos)}</h3>
                  </div>
                  <div className="col">
                    <h6 className="card-text">2-step:</h6>
                    <h3 className="pt-2">{getText(whInboundStats?.inbound_tracking_items)}</h3>
                  </div>
                  <div className="col">
                    <h6 className="card-text">Returns:</h6>
                    <h3 className="pt-2">{getText(whInboundStats?.inbound_returns)}</h3>
                  </div>
                  <div className="col">
                    <h6 className="card-text">Other:</h6>
                    <h3 className="pt-2">
                      <ComingSoon />
                    </h3>
                  </div>
                </div>
                <hr style={{ width: "100%", marginLeft: "0" }}></hr>
                <div className="row mb-3">
                  <div className="col">
                    <h5 className="card-text">Inventory Age</h5>
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <h6 className="card-text">
                      <span>&#60;</span>1 Week:
                    </h6>
                    <h3 className="text-success pt-2">{getText(whInventoryStats?.lessThanWeek)}</h3>
                  </div>
                  <div className="col">
                    <h6 className="card-text">7-30 days:</h6>
                    <h3 className="text-warning pt-2">{getText(whInventoryStats?.sevenToFourteen)}</h3>
                  </div>
                  <div className="col">
                    <h6 className="card-text">
                      <span>&#62;</span>30 days:
                    </h6>
                    <h3 className="text-danger pt-2">{getText(whInventoryStats?.graterThanFourteen)}</h3>
                  </div>
                  <div className="col"></div>
                </div>
              </CardBody>
            </Card>
            {/* Card 2 */}
            <Card style={{ width: "32rem" }} className="d-flex">
              <CardBody>
                <div className="row mb-3">
                  <div className="col">
                    <h5 className="card-text">Orders</h5>
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <h6 className="card-text">Overdue:</h6>
                    <h3 className="text-danger pt-2">{getText(whOutboundStats?.overdueOrders)}</h3>
                  </div>
                  <div className="col">
                    <h6 className="card-text">Ship Today:</h6>
                    <h3 className="text-warning pt-2">{getText(whOutboundStats?.orderToShipToday)}</h3>
                  </div>
                  <div className="col">
                    <h6 className="card-text">Ship Soon:</h6>
                    <h3 className="text-success pt-2">{getText(whOutboundStats?.totalOrderToShip)}</h3>
                  </div>
                  <div className="col"></div>
                </div>
                <hr style={{ width: "100%", marginLeft: "0" }}></hr>

                <div className="d-flex">
                  <div style={{ width: "50%" }}>
                    <div className="row mb-3">
                      <div className="col">
                        <h5 className="card-text">Plans Ready</h5>
                      </div>
                    </div>
                    <div>
                      <div className="row ">
                        <div className="col">
                          <h6 className="card-text">FBA:</h6>
                          <h3 className="pt-2">
                            <ComingSoon />
                          </h3>
                        </div>
                        <div className="col">
                          <h6 className="card-text">WFS:</h6>
                          <h3 className="pt-2">
                            <ComingSoon />
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="row mb-3">
                      <div className="col">
                        <h5 className="card-text">Returns Ready</h5>
                      </div>
                    </div>
                    <div>
                      <div className="row ">
                        <div className="col">
                          <h6 className="card-text">Returns:</h6>
                          <h3 className="pt-2">
                            <ComingSoon />
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <RenderIf isTrue={isSomeLiquidated}>
            <div className="auto-hide">
              <Alert color="danger" className="my-1 mb-2">
                <i className="bx bx-info-circle pr-2"></i>
                Please liquidate the items that belong to cancelled accounts.
              </Alert>
            </div>
          </RenderIf>
          <div className="d-flex justify-content-between align-item-center">
            <div className="d-flex flex-column">
              <Breadcrumbs title="Warehouse Dashboard" />
              <RenderIf isTrue={isSearched}>{searchBar("320px")}</RenderIf>
            </div>

            <div className="d-flex">
              <PillBtn
                style={{ height: "38px" }}
                title="Add Inventory"
                name="Add Inventory"
                icon="bx bx-plus"
                color="success"
                onClick={() => dispatch(setAddShipmentModal(true))}
              />
              <RenderIf isTrue={isAdmin}>
                <div className="pl-2" style={{ width: "200px" }}>
                  <SimpleSelect
                    name="warehouse_id"
                    value={{
                      value: wareHouseFilter.value,
                      label: wareHouseFilter.label,
                    }}
                    onChange={(e) => {
                      setWareHouseFilter({ label: e.label, value: e.value });
                      setIsSearched(false);
                      setFilters(
                        omit(
                          { ...filters, warehouse_id: e.value, page: 1, per_page: 20 },
                          e.value ? "" : "warehouse_id",
                        ),
                      );
                    }}
                    options={
                      userWarehouses &&
                      Object.keys(userWarehouses).reduce(
                        (acc, x, i) => {
                          acc.push({
                            label: getWarehouseName(userWarehouses[x]),
                            value: userWarehouses[x].id,
                            key: i,
                          });
                          return acc;
                        },
                        [{ value: "", label: "Select Warehouse" }],
                      )
                    }
                    classNamePrefix="select2-selection"
                  />
                </div>
              </RenderIf>
              <div className="pl-2" style={{ width: "200px" }}>
                <SimpleSelect
                  name="orderable_type"
                  value={{
                    value: typeFilter.value,
                    label: typeFilter.label,
                  }}
                  onChange={(e) => {
                    setTypeFilter({ label: e.label, value: e.value });
                    setIsSearched(false);
                    setFilters(
                      omit(
                        { ...filters, orderable_type: e.value, page: 1, per_page: 20 },
                        e.value ? "" : "orderable_type",
                      ),
                    );
                  }}
                  options={Object.keys(ORDERABLE_TYPES_MAPPING).reduce(
                    (acc, x, i) => {
                      acc.push({
                        label: ORDERABLE_TYPES_MAPPING[x],
                        value: x,
                        key: i,
                      });
                      return acc;
                    },
                    [{ value: "", label: "Select Type" }],
                  )}
                  classNamePrefix="select2-selection"
                />
              </div>
              {/* <RenderIf isTrue={wareHouseAccounts?.length > 0}>
                <div style={{ marginTop: "-0.125rem" }}>
                  <SimpleSelect
                    name="account_id"
                    placeholder="All Accounts"
                    className="col mb-0"
                    onChange={(acc) => {
                      setIsSearched(false);
                      setFilters(omit({ ...filters, account_id: acc.value, page: 1 }, acc.value ? "" : "account_id"));
                    }}
                    options={wareHouseAccounts.reduce(
                      (acc, x) => {
                        acc.push({ value: x.id, label: x.email });
                        return acc;
                      },
                      [{ value: "", label: "All Accounts" }]
                    )}
                    formStyle={{ minWidth: "250px", marginBottom: 0 }}
                  />
                </div>
              </RenderIf> */}
            </div>
          </div>
          <RenderIf isTrue={!isSearched}>
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex flex-column justify-content-between align-items-center">
                <img src={dashboardImg} alt="search svg here" style={{ height: "15rem", width: "10rem" }}></img>
                {searchBar("600px")}
              </div>
            </div>
          </RenderIf>
          <RenderIf isTrue={isSearched && !loading}>
            <ShipmentsTable
              inboundsData={shipments}
              filters={filters}
              setFilters={setFilters}
              shipment={shipment}
              setShipment={setShipment}
              setIsOpen={setIsOpen}
              setIds={setIds}
              ids={ids}
              isWhDashboard={true}
            />
          </RenderIf>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WarehouseDashboard;
