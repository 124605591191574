import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, Input } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";

// actions
import { refreshSourceOrderDetail, updateAddress } from "../../../store/actions";

const ShippingDetailModal = (props) => {
  const address = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    phone: "",
  };
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => values[key] === "" && key !== "address2" && (errors[key] = "Field Rquired"));
    return errors;
  };
  const formik = useFormik({
    initialValues: address,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.sourceOrderId && props.updateAddress({ data: { ...values }, id: props.sourceOrderId });
      props.refreshSourceOrderDetail();
      if (props.SourceOrder.success) {
        formik.resetForm();
        toggleModal();
      }
    },
  });

  useEffect(() => {
    props.address && Object.keys(address).forEach((key) => formik.setFieldValue(key, props.address[key] || "", false));
  }, [props.address]);

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Shipping Details</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.OrderLog?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.OrderLog?.error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12">
                <Label>Name</Label>
                <Input
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name
                  ? (
                  <span className="text-danger mt-1">{formik.errors.name}</span>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2">
                <Label>Address 1</Label>
                <textarea
                  className="form-control"
                  name="address1"
                  value={formik.values.address1}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.address1 && formik.errors.address1
                  ? (
                  <span className="text-danger mt-1">{formik.errors.address1}</span>
                    )
                  : null}
              </Col>
              <Col lg="12 mb-2">
                <Label>Address 2</Label>
                <textarea
                  className="form-control"
                  name="address2"
                  value={formik.values.address2}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col lg="4 mb-2">
                <Label>City</Label>
                <Input
                  className="form-control"
                  name="city"
                  value={formik.values.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city
                  ? (
                  <span className="text-danger mt-1">{formik.errors.city}</span>
                    )
                  : null}
              </Col>
              <Col lg="4 mb-2">
                <Label>State</Label>
                <Input
                  className="form-control"
                  name="state"
                  value={formik.values.state}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.state && formik.errors.state
                  ? (
                  <span className="text-danger mt-1">{formik.errors.state}</span>
                    )
                  : null}
              </Col>
              <Col lg="4 mb-2">
                <Label>Country</Label>
                <Input
                  className="form-control"
                  name="country"
                  value={formik.values.country}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.country && formik.errors.country
                  ? (
                  <span className="text-danger mt-1">{formik.errors.country}</span>
                    )
                  : null}
              </Col>
              <Col lg="4 mb-2">
                <Label>Zipcode</Label>
                <Input
                  className="form-control"
                  name="zipcode"
                  value={formik.values.zipcode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.zipcode && formik.errors.zipcode
                  ? (
                  <span className="text-danger mt-1">{formik.errors.zipcode}</span>
                    )
                  : null}
              </Col>
              <Col lg="4 mb-2">
                <Label>Phone</Label>
                <Input
                  className="form-control"
                  name="phone"
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.phone && formik.errors.phone
                  ? (
                  <span className="text-danger mt-1">{formik.errors.phone}</span>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrder } = state;
  return { SourceOrder };
};

export default connect(mapStateToProps, {
  refreshSourceOrderDetail,
  updateAddress,
})(ShippingDetailModal);
