export const FETCH_RESTRICTED_BRANDS = "FETCH_RESTRICTED_BRANDS";
export const FETCH_RESTRICTED_BRANDS_SUCCESS = "FETCH_RESTRICTED_BRANDS_SUCCESS";
export const SET_SELECTED_BRANDS = "SET_SELECTED_BRANDS";
export const SET_BRANDS_BULK_UPDATE_MODAL = "SET_BRANDS_BULK_UPDATE_MODAL";
export const BRANDS_BULK_UPDATE = "BRANDS_BULK_UPDATE";
export const BRANDS_BULK_UPDATE_SUCCESS = "BRANDS_BULK_UPDATE_SUCCESS";
export const API_ERROR = "FETCH_RESTRICTED_BRANDS_ERROR";
export const ADD_RESTRICTED_BRANDS = "ADD_RESTRICTED_BRANDS";
export const ADD_RESTRICTED_BRANDS_SUCCESS = "ADD_RESTRICTED_BRANDS_SUCCESS";
export const BULK_ADD_RESTRICTED_BRANDS = "BULK_ADD_RESTRICTED_BRANDS";
export const BULK_ADD_RESTRICTED_BRANDS_SUCCESS = "BULK_ADD_RESTRICTED_BRANDS_SUCCESS";
export const DEL_RESTRICTED_BRANDS = "DEL_RESTRICTED_BRANDS";
export const DEL_RESTRICTED_BRANDS_SUCCESS = "DEL_RESTRICTED_BRANDS_SUCCESS";
export const UPDATE_RESTRICTED_BRANDS = "UPDATE_RESTRICTED_BRANDS";
export const UPDATE_RESTRICTED_BRANDS_SUCCESS = "UPDATE_RESTRICTED_BRANDS_SUCCESS";
export const SET_BRAND_EDIT_MODAL = "SET_BRAND_EDIT_MODAL";
export const SET_BRAND_ADD_MODAL = "SET_BRAND_ADD_MODAL";
export const SET_BRAND_BULK_ADD_MODAL = "SET_BRAND_BULK_ADD_MODAL";
