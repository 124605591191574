import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as $ from "lodash";
import * as actions from "../../../../store/actions";
import Modal from "../../../../components/Common/Modal";
import InviteUser from "../../../../assets/svg/invite-user.svg";
import { Button } from "reactstrap";
import { daysLeftInTrial, makeSubscriptionsUrls } from "../../../../utils/common";

export default function ProfitTrialModal () {
  const dispatch = useDispatch();
  const history = useHistory();

  const { account } = useSelector((state) => state.Settings);
  const { profitTrialModalIsOpen } = useSelector((state) => state.Repricer);
  const { sources } = useSelector((state) => state.Stripe);
  const toggleProfitTrialModal = () => dispatch(actions.setProfitDashboardTrialModal(!profitTrialModalIsOpen));
  const daysLeft = daysLeftInTrial(account?.data);

  return (
    <Modal size="md" isOpen={profitTrialModalIsOpen}>
      <div className="modal-body p-4 let-modal">
        <i className="bx bx-sm bx-x cross-icon inventory-color cursor-pointer" onClick={toggleProfitTrialModal}></i>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <img src={InviteUser} alt="no-user" />
          <h5 className="mt-3 font-size-20">Let's Gooooo!</h5>
          <div className="d-flex flex-column align-items-center">
            <p className="mt-3 font-size-13">
              {`You are about to start your FREE ${daysLeft > 0 ? daysLeft : $.result(account, "data.plan_group.trial_days", 0)}-day trial of the Pro Extension and the Profit Dashboard! You will get access to the features of 6+ extensions all for one low monthly price as well as...`}
            </p>
            <ul className="font-size-13 mb-0 card-text" style={{ paddingLeft: "20px" }}>
              <li>An easy-to-understand dashboard to help you track numbers.</li>
              <li>Easily add your cost of goods to track the profitability of your storefront.</li>
              <li>Connect multiple storefronts to see your business numbers in one place.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="modal-footer justify-content-between" onClick={toggleProfitTrialModal}>
        <div>
          <Button color="primary" outline className="waves-effect waves-light mr-3">
            Cancel
          </Button>
        </div>
        <div>
          <Button color="success" className="mr-3" onClick={() => history.push(sources.length ? makeSubscriptionsUrls("basic", false) : makeSubscriptionsUrls("basic", false, true))}>
            Upgrade Now
          </Button>
          <Button color="primary" onClick={() => history.push(makeSubscriptionsUrls("basic", true))}>
            Start Free Trial
          </Button>
        </div>
      </div>
    </Modal>
  );
}
