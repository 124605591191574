import { useSelector } from "react-redux";
import * as $ from "lodash";
import CreatableSelect from "react-select/creatable";
import React, { useEffect, useState } from "react";

export default function EditableFieldWithDropdown ({ tag, mappedTags, clearSelectedTag, createTag, updateTag }) {
  const { loader } = useSelector((state) => state.Repricer);

  const [option, setOption] = useState(tag ? { label: $.get(tag, "name"), value: $.get(tag, "id") } : null);
  const [options, setOptions] = useState($.map(mappedTags, (tag) => ({ label: tag.name, value: tag.id })));
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [error, setError] = useState(null);

  const handleCreate = (value) => {
    const newOption = { label: value, value };
    setOptions((prev) => [...prev, newOption]);
    setOption(newOption);
    if (error) setError(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!$.get(option, "value")) {
      setError("Please select a value!");
      return;
    }

    if ($.get(tag, "id") === option.value) {
      clearSelectedTag();
      return;
    }

    if ($.get(mappedTags, option.value)) {
      updateTag(option.value);
    } else {
      createTag(option.label);
    }

    setIsSubmitted(true);
  };

  useEffect(() => {
    if (isSubmitted && !loader) clearSelectedTag();
  }, [isSubmitted, loader]);

  return (
    <div className="d-flex flex-column">
      <form onSubmit={onSubmit} onReset={clearSelectedTag} className="d-flex align-items-center gap-2">
        <div className="select2-container">
          <CreatableSelect
            isLoading={loader}
            isClearable
            placeholder=""
            value={option}
            options={options}
            onChange={(selection) => {
              setOption(selection);
              if (error) setError(null);
            }}
            onCreateOption={handleCreate}
            menuPosition="fixed"
            classNamePrefix="select2-selection"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                cursor: "text",
                minWidth: "150px",
                border: state.isFocused ? "1px solid #FBC02D !important" : "",
              }),
              dropdownIndicator: () => ({ display: "none" }),
            }}
          />
        </div>
        <button type="submit" style={{ all: "unset" }}>
          <i className="bx bx-check text-primary cursor-pointer" />
        </button>
        <button type="reset" style={{ all: "unset" }}>
          <i className="bx bx-x text-danger cursor-pointer" />
        </button>
      </form>
      {error && <small className="text-danger mt-2">{error}</small>}
    </div>
  );
}
