import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getAllMarketplaceFeed } from "../../api";
// Login Redux States
import { FETCH_MARKETPLACE_FEED } from "./actionTypes";

import { apiError, fetchMarketplaceFeedSuccess } from "./actions";

function * fetchMarketplaceFeedSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllMarketplaceFeed, params);
    yield put(fetchMarketplaceFeedSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchWalmartFeedFetch () {
  yield takeEvery(FETCH_MARKETPLACE_FEED, fetchMarketplaceFeedSaga);
}

function * feedSaga () {
  yield all([fork(watchWalmartFeedFetch)]);
}

export default feedSaga;
