import { pick, values, concat, startCase, merge } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React from "react";
import { Col, Label, FormGroup, Row } from "reactstrap";
import Select, { components } from "react-select";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import renderErrors from "./RenderErrors";
import { adjustDate, getUserInfo, RenderIf } from "../../../utils/common";
import getBrandImageSrc from "../../../utils/brandImages";
import { MARKET_PLACES, REPORT_TYPES } from "../../../constants";
import CheckBox from "../CheckBox";

const makeStoreOptions = (props) => {
  const isAgency = getUserInfo()?.is_agency;
  const reducer = (arr, acc, i) =>
    acc.valid && [MARKET_PLACES.amazon, MARKET_PLACES.walmart].includes(acc.marketplace)
      ? concat(arr, { label: acc.name, value: acc.id, marketplace: acc.marketplace, key: i })
      : arr;
  return props[isAgency ? "agencyAccounts" : "accounts"]?.data.reduce(reducer, []);
};

const DropdownItem = (props) => (
  <components.Option {...props}>
    <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
    {props.data.label}
  </components.Option>
);

const OrdersReportForm = (props) => {
  const { formik } = props;
  const dateCols = ["start_date", "end_date"];

  return (
    <>
      <Col className="my-1">
        <Label>Select Date Range {renderErrors(formik, dateCols)}</Label>
        <div className="w-100">
          <Flatpickr
            className="form-control"
            placeholder="Select Date Range"
            value={values(pick(formik.values, dateCols))}
            options={{ mode: "range" }}
            onClose={(val) => {
              const newDates = val.map(adjustDate);
              if (newDates.length > 1) {
                formik.setFieldValue("start_date", newDates[0]);
                formik.setFieldValue("end_date", newDates[1]);
              }
            }}
          />
        </div>
      </Col>
      <RenderIf isTrue={props.reportType === REPORT_TYPES.marketplace}>
        <Col className="my-1">
          <Label>Marketplace {renderErrors(formik, ["marketplace"])}</Label>
          <FormGroup className="select2-container w-100">
            <Select
              name="marketplace"
              value={formik.values.marketplace}
              onChange={(e) => formik.setFieldValue("marketplace", e)}
              options={[MARKET_PLACES.amazon, MARKET_PLACES.walmart].map((acc) => ({
                label: startCase(acc),
                value: acc,
              }))}
              classNamePrefix="select2-selection"
              height={{ maxHeight: "250px" }}
              components={{
                Option: (_props) => (
                  <components.Option {..._props}>
                    <img src={getBrandImageSrc(_props.data.value)} alt="store logo" />
                    {_props.data.label}
                  </components.Option>
                ),
              }}
            />
          </FormGroup>
        </Col>
      </RenderIf>
      <RenderIf isTrue={props.reportType === REPORT_TYPES.store_front}>
        <Col className="my-1">
          <Label>Marketplace Account {renderErrors(formik, ["store"])}</Label>
          <FormGroup className="select2-container w-100">
            <Select
              name="store"
              value={formik.values.store}
              onChange={(e) => formik.setFieldValue("store", e)}
              options={makeStoreOptions(props)}
              classNamePrefix="select2-selection"
              height={{ maxHeight: "250px" }}
              components={{ Option: DropdownItem }}
            />
          </FormGroup>
        </Col>
      </RenderIf>
      <Col className="my-1">
        <Row>
          <Col md={6}>
            <CheckBox
              name="lost_orders"
              state={formik.values.lost_orders}
              setState={(e) => formik.setFieldValue("lost_orders", e)}
              isSwitch={true}
              label="Lost/Delayed Orders"
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => merge({}, pick(state.MarketPlaceAccount, "accounts", "agencyAccounts"));

export default withRouter(connect(mapStateToProps, {})(OrdersReportForm));
