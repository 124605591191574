import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DATE_FORMATS } from "../../../constants";
import { getProductURL, toPascalCase } from "../../../utils/common";
import ReactTable from "../../../components/Common/Table";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import moment from "moment-timezone";

// Import Actions
import { setFilteredListings } from "../../../store/actions";

const { DATE_TIME } = DATE_FORMATS;

const InProgressListing = (props) => {
  const dispatch = useDispatch();
  const { allStoresObj, storeFront, filters, setFilters, getTableHeaders, columns, isSingleListing } = props;
  const { listings, filteredListings } = useSelector((state) => state.Listings);
  const { users } = useSelector((state) => state.User);
  const { account } = useSelector((state) => state.Settings);
  const { listing } = useSelector((state) => state.Supplier);
  const [sortFilter, setSortFilter] = useState(undefined);

  const isTwoStepOn = account?.data?.two_step_feature;

  const progressHeaders = [
    {
      title: "SKU",
    },
    {
      title: "Item ID/ASIN",
    },
    {
      title: "User",
    },
    {
      title: "Handling Time",
      sort: true,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions: { handling_time: listings?.sorted_directions === "DESC" ? "ASC" : "DESC" },
          },
        });
        setSortFilter({ col: "Handling Time", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    {
      title: "Created At",
      sort: true,
      onClick: () => {
        setFilters({
          ...filters,
          filters_hash: {
            ...filters.filters_hash,
            sortOptions: { created_at: listings?.sorted_directions === "DESC" ? "ASC" : "DESC" },
          },
        });
        setSortFilter({ col: "Created At", direction: listings?.sorted_directions === "DESC" ? "up" : "down" });
      },
    },
    {
      title: "Listing Type",
    },
    {
      title: "Brand",
    },
    {
      title: "UPC",
    },
  ];

  useEffect(() => {
    getTableHeaders(progressHeaders);
  }, []);

  useEffect(() => {
    if (isSingleListing) dispatch(setFilteredListings([listing]));
    else dispatch(setFilteredListings(listings?.results));
  }, [isSingleListing, listings?.results, listing]);

  const SortArrows = ({ arrow }) => (
    <i
      id={arrow + "-filter"}
      style={{ fontSize: "12px" }}
      className={`dripicons-arrow-thin-${arrow} cursor-pointer ${arrow === "down" ? "ml-n3" : ""}`}
    />
  );

  return (
    <>
      <ReactTable
        tableHeader={progressHeaders.map(
          (header, index) =>
            columns[header.title] && (
              <th onClick={header.onClick} key={`table-header ${index}`}>
                {header.title}
                {header.sort
                  ? (
                  <div className="d-inline ml-1">
                    {sortFilter && sortFilter.col === header.title
                      ? (
                      <SortArrows title={header.title} arrow={sortFilter.direction} />
                        )
                      : (
                      <>
                        <SortArrows arrow={"up"} />
                        <SortArrows arrow={"down"} />{" "}
                      </>
                        )}
                  </div>
                    )
                  : null}
              </th>
            ),
        )}
        tableBody={
          <>
            {filteredListings?.map((item, key) => (
              <tr key={"_listings_in_progress_" + key} className="mt-3 mb-3">
                {columns.SKU && (
                  <td>
                    {item?.sku}
                    <CopyToClipBoard text={item?.sku} />
                  </td>
                )}
                {columns["Item ID/ASIN"] && (
                  <td>
                    <a
                      href={getProductURL(item, storeFront.marketplace, allStoresObj[item.partner_id])}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary mb-1"
                    >
                      {item.item_id}
                    </a>
                    <CopyToClipBoard text={item?.item_id} />
                  </td>
                )}
                {columns.User && (
                  <td>{toPascalCase(users?.data?.find((x) => x.id === item.va_id)?.username) || "N/A"}</td>
                )}
                {columns["Handling Time"] && <td>{item?.handling_time}</td>}
                {columns["Created At"] && <td>{item?.created_at && moment(item?.created_at).format(DATE_TIME)}</td>}
                {columns["Listing Type"] && isTwoStepOn && (
                  <td>
                    {!item?.mp_fi && (item?.ship_to_warehouse || allStoresObj[storeFront.value]?.two_step_enabled)
                      ? "2-Step"
                      : "Normal"}
                  </td>
                )}
                {columns.Brand && <td>{item?.brand || "N/A"}</td>}
                {columns.UPC && <td>{item?.upc || "N/A"}</td>}
              </tr>
            ))}
          </>
        }
      />
    </>
  );
};
export default InProgressListing;
