import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, CardBody, Card, Alert } from "reactstrap";
import { fetchMatchingLogs, fetchUsers, setPreloader } from "../../store/actions";
import { getUserInfo, toPascalCase } from "../../utils/common";
import queryString from "query-string";
import moment from "moment";
import { DATE_FORMATS } from "../../constants";
const { DATE_TIME } = DATE_FORMATS;

const MatchingLogs = (props) => {
  const dispatch = useDispatch();
  const queryParameters = queryString.parse(props.location.search);
  const tableHeaders = ["User", "Event", "Created At", "Changes"].map((title) => ({ title }));
  const { logs, loading } = useSelector((state) => state.Listings);
  const { users, loading: userLoading } = useSelector((state) => state.User);

  useEffect(() => {
    !users?.data.length &&
      dispatch(fetchUsers({ account_id: getUserInfo().account_id, all_users: true, pagination: false }));
    dispatch(
      fetchMatchingLogs(
        Object.keys(queryParameters).reduce((acc, x) => {
          acc[x] = queryParameters[x];
          return acc;
        }, {}),
      ),
    );
  }, []);

  useEffect(() => {
    dispatch(setPreloader(loading || userLoading));
  }, [loading, userLoading]);

  const getChangesHTML = (x, index, obj) => (
    <div className="d-flex flex-column" key={`_matching_logs_${index}`}>
      <div className="d-flex align-items-center">
        <span>{toPascalCase(x)}:&nbsp;</span>
        <span>{toPascalCase(String(obj[0]))}</span>
        <i className="bx bx-sm bx-right-arrow-alt text-primary" />
        <span>{toPascalCase(String(obj[1]))}</span>
      </div>
    </div>
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Activity Logs" />
        <Card>
          {queryParameters.url_type === "listing_activity" && (
            <div className="mb-2">
              <Alert color="primary">
                This log tracks user changes for any of these things: Verified Match, Override Restriction Brands, Mark
                as Mismatch listings
              </Alert>
            </div>
          )}

          <CardBody>
            {Array.isArray(logs?.data) && logs.data.length
              ? (
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {tableHeaders.map((header, index) => (
                        <th key={`table-header ${index}`}>{header.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {logs.data.map((log, i) => (
                      <tr key={"_matching_logs_" + i}>
                        <td>{toPascalCase(users?.data?.find((x) => x.id === +log.va_id)?.username) || "N/A"}</td>
                        <td>{log.event}</td>
                        <td>{moment(log.created_at).isValid() ? moment(log.created_at).format(DATE_TIME) : ""}</td>
                        <td>
                          {Object.keys(log?.object_changes || {}).map((x, i) =>
                            getChangesHTML(x, i, log.object_changes[x]),
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
                )
              : null}
            {logs?.data.length === 0 && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default MatchingLogs;
