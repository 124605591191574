import React, { useEffect, useState, Suspense } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { fetchProfitGraph, fetchSalesGraph } from "../../../store/actions";
import { formatNumber, RenderIf } from "../../../utils/common";
import { DATE_FORMATS } from "../../../constants";

const { DATE } = DATE_FORMATS;

const DateRange = React.lazy(() => import("../../../components/Common/DateRange"));
const ChartName = React.lazy(() => import("../components/ChartName"));

const RevenueProfitGraph = () => {
  const dispatch = useDispatch();
  const currentDate = moment();
  const oneWeekBefore = moment().subtract(6, "days");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);
  const { salesGraph, profitGraph, profitLoading, salesGraphLoader } = useSelector((state) => state.Graph);
  const { archived } = useSelector((state) => state.Agency);

  const fetchGraphDetails = ({ startDate, endDate }) => {
    const sharedParams = { start_date: startDate, end_date: endDate };
    dispatch(fetchProfitGraph({ ...sharedParams, utc_offset: moment().format("Z"), archived }));
    dispatch(fetchSalesGraph({ ...sharedParams, utc_offset: moment().format("Z"), archived }));
  };

  useEffect(() => {
    fetchGraphDetails({
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
    });
  }, [dates[0], dates[1], archived]);

  const series = [
    {
      name: "Revenue",
      data: (salesGraph?.data?.length &&
        salesGraph.data.slice(0, profitGraph?.data?.length || 0).map((x) => parseFloat(x.total).toFixed(2))) || [0],
    },
    {
      name: "Client Profit",
      data: (profitGraph?.data?.length && profitGraph.data.map((x) => parseFloat(x.total).toFixed(2))) || [0],
    },
    {
      name: "Agency Profit",
      data: (profitGraph?.data?.length && profitGraph.data.map((x) => parseFloat(x.agencyProfit).toFixed(2))) || [0],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No Data for given date range",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#fcba03", "#34c38f"],
    xaxis: {
      tickPlacement: "on",
      type: "datetime",
      categories: (salesGraph?.data?.length &&
        salesGraph.data.slice(0, profitGraph?.data?.length || 0).map((x) => x?.date)) || [
        dates[0].toDate(),
        currentDate.toDate(),
      ],
      labels: {
        rotate: -50,
        rotateAlways: true,
        formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE), // Timestamp is ALWAYS UTC
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  const priceText = () => {
    return formatNumber(
      salesGraph?.data
        ?.slice(0, profitGraph?.data?.length || 0)
        ?.reduce((acc, x) => acc + parseFloat(x.total), 0)
        .toFixed(2) || 0,
      "currency",
    );
  };

  const priceTextAgencyProfit = () => {
    return formatNumber(
      profitGraph?.data?.reduce((acc, x) => acc + parseFloat(x.agencyProfit), 0).toFixed(2) || 0,
      "currency",
    );
  };

  const priceTextClientProfit = () => {
    return formatNumber(
      profitGraph?.data?.reduce((acc, x) => acc + parseFloat(x.total), 0).toFixed(2) || 0,
      "currency",
    );
  };

  return (
    <Card className="mh-493">
      <CardBody>
        <div className="mb-4 d-flex justify-content-between">
          <Suspense fallback={<></>}>
            <RenderIf
              isTrue={!profitLoading && !salesGraphLoader}
              fallback={<h4 className="card-title">Loading...</h4>}
            >
              <ChartName name="Revenue" dates={dates} totalOrders={priceText()} showDate={false} />
              <ChartName
                name="Client Profit"
                minwidth={90}
                dates={dates}
                totalOrders={priceTextClientProfit()}
                showDate={false}
              />
              <ChartName
                name="Agency Profit"
                minwidth={120}
                dates={dates}
                totalOrders={priceTextAgencyProfit()}
                showDate={false}
              />
            </RenderIf>
            <DateRange
              dates={dates}
              setDates={setDates}
              loader={profitLoading || salesGraphLoader}
              timePeriods={[7, 30, 180, 365]}
              isInLine={false}
            />
          </Suspense>
        </div>
        <div className="clearfix" />
        <div className="mr-3">
          <ReactApexChart options={options} series={series} type="area" height="350" />
        </div>
      </CardBody>
    </Card>
  );
};

export default RevenueProfitGraph;
