import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Card, TabContent, TabPane, Progress, Label, Alert } from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// redux actions
import {
  createMarketplaceAccount,
  setIsAmz,
  setPreloader,
  setFormData as _setFormData,
  setShopifyRefreshToken,
  setIsShopify as _setIsShopify,
} from "../../../store/actions";
import ErrorMessage from "./errorMessage";
import { AddAccountBtn, ConfirmBtn, StoreAddedMsg } from "./StoreAddedMsg";
import { MARKET_PLACES, onboardingStepsIds, SHOPIFY_AUTH_URL, STORE_ADD_MSG } from "../../../constants";

import { getUserInfo, RenderIf } from "../../../utils/common";
import PillBtn from "../../../components/Common/PillBtn";
import shopifyLogo from "../../../assets/svg/brands/shopify-logo.svg";
import SellerCentral from "../../../assets/svg/onboard/partner-dashboard.svg";

const ShopifySetup = (props) => {
  const userInfo = getUserInfo();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const setIsShopify = (...args) => dispatch(_setIsShopify(...args));
  const {
    accounts,
    onboardAccountSuccess,
    error,
    loading,
    shopifySuccess,
    shopifyRefToken,
    shopifyErr,
    formData,
    isShopify,
  } = useSelector((state) => state.MarketPlaceAccount);
  const [storeName, setStoreName] = useState("");
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabProgress, setActiveTabProgress] = useState(1);
  const [progressValue, setProgressValue] = useState(25);
  const [isValid, setIsValid] = useState(true);
  const { loading: onboardFlagLoading } = useSelector((state) => state.Settings);
  const setFormData = (...args) => dispatch(_setFormData(...args));

  function openShopifyLogin (shop) {
    window.open(SHOPIFY_AUTH_URL(shop, "867564edftuygr46576tgyftd657"));
  }
  const oauthShopify = () => {
    return (
      <>
        <div className="p-2">
          <div className="py-2">
            <p> Step 1: Add Store Details</p>
          </div>
          <div>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                const data = { ...formData, ...v };
                setFormData({ ...data, refresh_token: shopifyRefToken, marketplace: "shopify" });
                dispatch(
                  createMarketplaceAccount({
                    name: data.name,
                    refresh_token: shopifyRefToken,
                    access_id: `shopify_store_access_id_${data.name}`,
                    access_secret: `shopify_store_access_secret_${data.name}`,
                    marketplace: "shopify",
                    tracking_enabled: false,
                  }),
                );
                toggleTabProgress(2);
                toggleTab(2);
                formRef.current.reset();
                setFormData({});
              }}
              ref={(c) => (formRef.current = c)}
            >
              <div className="form-group">
                <small className="text-warning">
                  Please note that this should be the same as your shopify store url. E.g, The "xyz" portion in
                  "xyz.myshopify.com"
                </small>
                <AvField
                  name="name"
                  label="Shopify Shop Name:"
                  value={formData.name || ""}
                  className="form-control"
                  placeholder="Choose a display name for your Shopify shop"
                  type="text"
                  onChange={(e) => setStoreName(e.target.value)}
                  validate={{
                    customValidation: (value) => {
                      if (alreadyOnboarded(value)) {
                        return "This store has already been onboarded";
                      }
                      const valid = /^[0-9A-Za-z]+(-[0-9A-Za-z]+)*$/.test(value);
                      setIsValid(valid);
                      return valid || "Please enter a valid store name. Allowed characters: Alphanumeric & hyphen(-)";
                    },
                  }}
                />
              </div>
              <div className="py-2">
                <Label className="d-block">Step 2: Connect to Shopify</Label>
                <PillBtn
                  color="primary"
                  title="GO TO ADMIN DASHBOARD"
                  name="GO TO ADMIN DASHBOARD"
                  img={shopifyLogo}
                  disabled={!storeName || !isValid}
                  onClick={(_) => openShopifyLogin(storeName)}
                  className={`w-md btn-md waves-effect waves-light ${storeName ? "" : "disabled"}`}
                ></PillBtn>
                <RenderIf isTrue={shopifySuccess}>
                  <div className="d-flex mt-3">
                    <span className="text-primary">Note: &nbsp;</span>
                    <h6>
                      <i>{shopifySuccess}</i>
                    </h6>
                    <i className="bx bxs-check-circle font-size-16 cursor-pointer text-success ml-2"></i>
                  </div>
                </RenderIf>
                <div className="mt-3">
                  <img style={{ maxHeight: "295px" }} src={SellerCentral} alt="Action Successful" />
                </div>
              </div>
              <ul className="pager wizard twitter-bs-wizard-pager-link">
                <li
                  onClick={() => {
                    props.setActiveStep(onboardingStepsIds.welcom);
                    dispatch(setShopifyRefreshToken(undefined));
                  }}
                  className="btn btn-primary w-md btn-sm waves-effect waves-light"
                >
                  Previous
                </li>
                <li className={activeTabProgress === 2 ? "next disabled" : "next"}>
                  <button
                    type="submit"
                    disabled={!!shopifyErr || !shopifyRefToken}
                    className="btn btn-primary w-md btn-sm waves-effect waves-light"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </AvForm>
          </div>
        </div>
      </>
    );
  };

  const directCredsShopify = () => {
    return (
      <>
        <div className="p-2">
          <div className="py-2">
            <p> Step 1: Add Store Details</p>
          </div>
          <div>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                const data = { ...formData, ...v };
                setFormData({ ...data, refresh_token: data.access_token, marketplace: "shopify" });
                dispatch(
                  createMarketplaceAccount({
                    name: data.name,
                    refresh_token: data.access_token,
                    access_id: `shopify_store_access_id_${data.name}`,
                    access_secret: `shopify_store_access_secret_${data.name}`,
                    marketplace: "shopify",
                    tracking_enabled: false,
                  }),
                );
                toggleTabProgress(2);
                toggleTab(2);
                formRef.current.reset();
                setFormData({});
              }}
              ref={(c) => (formRef.current = c)}
            >
              <div className="form-group">
                <small className="text-warning">
                  Please note that this should be the same as your shopify store url. E.g, The "xyz" portion in
                  "xyz.myshopify.com"
                </small>
                <AvField
                  name="name"
                  label="Shopify Shop Name:"
                  value={formData.name || ""}
                  className="form-control"
                  placeholder="Choose a display name for your Shopify shop"
                  type="text"
                  onChange={(e) => setStoreName(e.target.value)}
                  validate={{
                    customValidation: (value) => {
                      if (alreadyOnboarded(value)) {
                        return "This store has already been onboarded";
                      }
                      const valid = /^[0-9A-Za-z]+(-[0-9A-Za-z]+)*$/.test(value);
                      setIsValid(valid);
                      return valid || "Please enter a valid store name. Allowed characters: Alphanumeric & hyphen(-)";
                    },
                  }}
                />
              </div>
              <div className="py-2">
                <Label className="d-block">Step 2: Connect to your Shopify store</Label>
                <PillBtn
                  color="primary"
                  title="GO TO STORE ADMIN DASHBOARD"
                  name="GO TO ADMIN DASHBOARD"
                  img={shopifyLogo}
                  disabled={!storeName || !isValid}
                  onClick={(_) => {
                    window.open(`https://${storeName}.myshopify.com/admin`);
                  }}
                  className={`w-md btn-md waves-effect waves-light ${storeName ? "" : "disabled"}`}
                ></PillBtn>
              </div>
              <div className="py-2">
                <p>
                  {" "}
                  Step 3:{" "}
                  <a href="https://help.shopify.com/en/manual/apps/app-types/custom-apps#enable-custom-app-development" target="_blank" rel="noreferrer">
                    {" "}
                    How to create & install a custom app on store.
                  </a>
                  <strong>{"        "}*Use app name as ecom-circles</strong>
                </p>
              </div>
              <div className="py-2">
                <p>Step 4: Read the link provided in step 3 & to get the access token and provide in the following field</p>
              </div>
              <div className="form-group">
                <AvField
                  name="access_token"
                  label="Access Token:"
                  value=""
                  className="form-control"
                  placeholder="Enter your access token"
                  type="text"
                  required
                />
              </div>
              <ul className="pager wizard twitter-bs-wizard-pager-link">
                <li
                  onClick={() => {
                    props.setActiveStep(onboardingStepsIds.welcom);
                    dispatch(setShopifyRefreshToken(undefined));
                  }}
                  className="btn btn-primary w-md btn-sm waves-effect waves-light"
                >
                  Previous
                </li>
                <li className={activeTabProgress === 2 ? "next disabled" : "next"}>
                  <button
                    type="submit"
                    className="btn btn-primary w-md btn-sm waves-effect waves-light"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </AvForm>
          </div>
        </div>
      </>
    );
  };

  function toggleTab (tab) {
    if (activeTab !== tab && tab >= 1 && tab <= 2) {
      setactiveTab(tab);
    }
  }

  function toggleTabProgress (tab) {
    if (tab >= 1 && tab <= 2) {
      setActiveTabProgress(tab);

      if (tab === 1) {
        setProgressValue(50);
      }
      if (tab === 2) {
        setProgressValue(100);
      }
    }
  }

  function alreadyOnboarded (storeName) {
    const store = accounts?.data?.find((acc) => acc?.name === storeName && acc?.marketplace === MARKET_PLACES.shopify);
    return store?.status === "active" && store?.valid;
  }

  useEffect(() => {
    setPreloader(loading || onboardFlagLoading);
  }, [loading, onboardFlagLoading]);

  return (
    <Card>
      {isShopify && (
        <div className="p-4">
          <div id="progrss-wizard" className="twitter-bs-wizard">
            <div className="px-5 py-2">
              <Progress color="primary" value={progressValue} />
            </div>
            <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content">
              <TabPane key="1" tabId={1}>
                <RenderIf isTrue={shopifyErr}>
                  <Alert color="danger">
                    <i className="bx bx-info-circle pr-2"></i>
                    {shopifyErr}
                  </Alert>
                </RenderIf>
                <h4 className="card-title mb-3 text-center">Connect your shopify shop with Ecom Circles</h4>
                {[5940].includes(userInfo?.account_id) ? oauthShopify() : directCredsShopify()}
              </TabPane>
              <TabPane key="2" tabId={2}>
                {onboardAccountSuccess && !error && !loading ? (
                  <StoreAddedMsg marketplace="shopify">
                    <AddAccountBtn
                      onClick={() => {
                        setIsShopify(true);
                        props.setIsOpen(true);
                        setactiveTab(1);
                        setActiveTabProgress(1);
                        setProgressValue(25);
                        props.setActiveStep(onboardingStepsIds.welcom);
                      }}
                    />
                    <ConfirmBtn
                      onClick={() => {
                        props.setActiveStep(onboardingStepsIds.payment);
                        if (!props.onBoarding) {
                          props.setIsOpen(false);
                          dispatch(setIsAmz(false));
                        }
                        setIsShopify(false);
                      }}
                    />
                  </StoreAddedMsg>
                ) : !error ? (
                  <h4 className="card-title mb-3 text-center">{STORE_ADD_MSG}</h4>
                ) : (
                  <div className="text-center">
                    <ErrorMessage error={error} />
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li>
                        <button
                          onClick={() => {
                            toggleTabProgress(1);
                            toggleTab(1);
                          }}
                          className="waves-effect waves-light btn btn-primary"
                        >
                          Back
                        </button>
                      </li>
                      {props.onBoarding === false && (
                        <li>
                          <button
                            onClick={() => {
                              props.setIsOpen(false);
                            }}
                            className="ml-3 waves-effect waves-light btn btn-primary"
                          >
                            Exit
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      )}
    </Card>
  );
};

export default ShopifySetup;

ShopifySetup.propTypes = {
  setActiveStep: PropTypes.func,
};
