import React from "react";
import { UncontrolledPopover as ReactPopover } from "reactstrap";
import { connect } from "react-redux";

const PopOver = (props) => {
  return (
    <ReactPopover {...props} className={`rounded ${props.theme}`}>
      {props.children}
    </ReactPopover>
  );
};

const mapStatetoProps = (state) => {
  return { theme: state.Layout.appTheme };
};

export default connect(mapStatetoProps, {})(PopOver);
