import { API_ERROR, CREATE_ORDER_LOG, CREATE_ORDER_LOG_SUCCESS, RESET_SUCCESS_ERR_MSG } from "./actionTypes";

export const createOrderLog = ({ data }) => ({
  type: CREATE_ORDER_LOG,
  payload: { data },
});

export const createOrderLogSuccess = () => ({
  type: CREATE_ORDER_LOG_SUCCESS,
  payload: {},
});

export const resetSuccessErrMsg = () => ({
  type: RESET_SUCCESS_ERR_MSG,
});

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
