import React, { useState } from "react";
import CheckBox from "../../../components/Common/CheckBox";
import { ConfirmationAlert } from "../../../components/Common/ConfirmDialog";
import { RenderIf } from "../../../utils/common";

const StorageCheckbox = ({
  val,
  onConfirm,
  isShowLabel = true,
  className = "",
  isShowConfirmation = true,
  msg,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <RenderIf isTrue={isOpen && isShowConfirmation}>
        <ConfirmationAlert
          onConfirm={() => onConfirm(!val)}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          msg={
            <>
              <p>Do you want to {val ? "Disable" : "Enable"} Storage Charges Exemption?</p>
              <p>
                {`Warning: ${val ? "Disabling" : "Enabling"} this means ${msg} will be ${
                  val ? "eligible for" : "exempt from"
                } storage charges`}
              </p>
            </>
          }
        />
      </RenderIf>
      <CheckBox
        className={className}
        disabled={disabled}
        name="is_storage_exempt"
        state={val}
        setState={(_) => {
          if (isShowConfirmation) setIsOpen(true);
          else onConfirm(!val);
        }}
        isSwitch={true}
        label={isShowLabel ? "Storage Charges Exemption" : ""}
      />
    </>
  );
};

export default StorageCheckbox;
