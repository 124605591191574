import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import merge from "lodash/merge";
import * as types from "./actionTypes";
import * as api from "../../api";
import * as actions from "./actions";
const {
  getAgencyStats,
  getAgencyClients,
  getAgencyClientsProfit,
  updatePaymentSettings,
  removeClient,
  getTenantsStats,
  getStorefrontHealth,
} = api;
// Login Redux States
const {
  FETCH_AGENCY_STATS,
  FETCH_AGENCY_CLIENTS,
  FETCH_AGENCY_CLIENTS_PROFIT,
  UPDATE_PAYMENT_SOURCE_SETTINGS,
  REMOVE_CLIENT,
  FETCH_TENANTS_STATS,
  FETCH_STOREFRONT_HEALTH_MATRICS,
} = types;
const {
  apiError,
  fetchAgencyStatsSuccess,
  fetchAgencyClientsSuccess,
  fetchAgencyClientsProfitSuccess,
  updatePaymentSourceSettingsSuccess,
  removeClientSuccess,
  fetchTenantsStatsSuccess,
  fetchStoreFrontHealthMatricsSuccess,
} = actions;

const Settings = (state) => state.Settings;
const Agency = (state) => state.Agency;

function * fetchAgencyStatsSaga () {
  try {
    const res = yield call(getAgencyStats);
    yield put(fetchAgencyStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchTenantsStatsSaga () {
  try {
    const res = yield call(getTenantsStats);
    yield put(fetchTenantsStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchAgencyClientsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAgencyClients, params);
    yield put(fetchAgencyClientsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchAgencyClientsProfitSaga () {
  try {
    const res = yield call(getAgencyClientsProfit);
    yield put(fetchAgencyClientsProfitSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchStorefrontHealthMatricsSaga ({ payload: { email } }) {
  try {
    const res = yield call(getStorefrontHealth, email);
    yield put(fetchStoreFrontHealthMatricsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updatePaymentSourceSettingsSaga ({ payload: body }) {
  try {
    const res = yield call(updatePaymentSettings, body);
    if (res?.success) {
      const { clients } = yield select(Agency);
      if (res.childUpdate) {
        // Client Settings Updation
        const client = clients?.find((client) => client.id === body.id);
        merge(client, res.data);
      } else {
        // Agency Settings Updation
        const { account } = yield select(Settings);
        merge(account.data, res.data);
        if (body?.data?.isAll) {
          clients.forEach((client) => merge(client, res.data));
        }
      }
    }
    yield put(updatePaymentSourceSettingsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * removeClientSaga ({ payload: id }) {
  try {
    const res = yield call(removeClient, id);
    if (res?.success) {
      const { clients } = yield select(Agency);
      res.data = clients?.filter((x) => x.id !== id);
    }
    yield put(removeClientSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * archiveClientSaga ({ payload }) {
  try {
    const res = yield call(api.archiveClients, payload);
    if (res?.success) {
      const { clients, archived, totalCount } = yield select(Agency);
      (clients || []).forEach((x) => x.id === payload.id && (x.archived = payload.archived));
      res.data = (clients || []).filter((x) => !(!archived && x.id === payload.id));
      res.total = (payload?.archived && !archived) ? totalCount - 1 : totalCount;
    }
    yield put(actions.archiveClientSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchArchiveClientSaga () {
  yield takeEvery(types.ARCHIVE_CLIENT, archiveClientSaga);
}

export function * watchStoreFrontHealthFetch () {
  yield takeEvery(FETCH_STOREFRONT_HEALTH_MATRICS, fetchStorefrontHealthMatricsSaga);
}

export function * watchAgencyFetch () {
  yield takeEvery(FETCH_AGENCY_STATS, fetchAgencyStatsSaga);
}

export function * watchAgencyClientsFetch () {
  yield takeEvery(FETCH_AGENCY_CLIENTS, fetchAgencyClientsSaga);
}

export function * watchAgencyClientsProfitFetch () {
  yield takeEvery(FETCH_AGENCY_CLIENTS_PROFIT, fetchAgencyClientsProfitSaga);
}

export function * watchUpdatePaymentSourceSettings () {
  yield takeEvery(UPDATE_PAYMENT_SOURCE_SETTINGS, updatePaymentSourceSettingsSaga);
}

export function * watchRemoveClient () {
  yield takeEvery(REMOVE_CLIENT, removeClientSaga);
}

export function * watchTenantStatsFetch () {
  yield takeEvery(FETCH_TENANTS_STATS, fetchTenantsStatsSaga);
}

function * agencySaga () {
  yield all([fork(watchAgencyFetch)]);
  yield all([fork(watchAgencyClientsFetch)]);
  yield all([fork(watchAgencyClientsProfitFetch)]);
  yield all([fork(watchUpdatePaymentSourceSettings)]);
  yield all([fork(watchStoreFrontHealthFetch)]);
  yield all([fork(watchRemoveClient)]);
  yield all([fork(watchTenantStatsFetch)]);
  yield all([fork(watchArchiveClientSaga)]);
}

export default agencySaga;
