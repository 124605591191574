import React, { useEffect } from "react";
const BarePay = () => {
  const customerId = localStorage.getItem("stripe_customer_id");
  useEffect(() => {
    if (window.barepay && window.barepay.created) {
      window.console && console.error && console.error("Barepay snippet included twice.");
    } else {
      window.barepay = { created: true };
      const a = document.createElement("script");
      a.src = "https://baremetrics-dunning.baremetrics.com/js/application.js";
      a.async = true;
      const b = document.getElementsByTagName("script")[0];
      b.parentNode.insertBefore(a, b);
      window.barepay.params = {
        access_token_id: "1f57d42a-7112-45ec-8452-628de5dffbed",
        customer_oid: customerId,
      };
    }
  }, []);
  return <barepay is="x3d"></barepay>;
};

export default BarePay;
