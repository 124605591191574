import { all } from "redux-saga/effects";

// public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import AmazonSellers from "./amazonSellers/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import SourceOrdersSaga from "./sourceOrders/saga";
import EmailsSaga from "./emails/saga";
import SupplierOrdersSaga from "./supplierOrders/saga";
import CreditCardsSaga from "./creditCard/saga";
import SourceItemsSaga from "./sourceItems/saga";
import SuppliersSaga from "./suppliers/saga";
import OrderLogsSaga from "./orderLogs/saga";
import CredentialSaga from "./credentials/saga";
import ListingsSaga from "./listings/saga";
import UnpublishedListingsSaga from "./unpublishedListings/saga";
import GraphsSaga from "./graphs/saga";
import AgencySaga from "./Agency/saga";
import MarketPlaceAccountsSaga from "./marketPlaceAccounts/saga";
import FeedSaga from "./feed/saga";
import ShipmentReport from "./shipmentReport/saga";
import UserSaga from "./user/saga";
import AuthTokenSaga from "./authToken/saga";
import SettingsSaga from "./settings/saga";
import PlatformsSaga from "./platforms/saga";
import CreditLog from "./creditLog/saga";
import OrderAttempt from "./orderAttempt/saga";
import RestrictedBrands from "./restrictedBrands/saga";
import RestrictedItems from "./restrictedItems/saga";
import AOScreens from "./aoScreens/saga";
import MatchesRequestsSaga from "./matchesRequests/saga";
import FiftyFiftyProfitSaga from "./FiftyFiftyProfit/saga";
import CustomPlansSaga from "./customPlans/saga";
import FreeCreditsSaga from "./FreeCredits/saga";
import AOStats from "./aoStats/saga";
import AOTasks from "./aoTasks/saga";
import ProxyUsersSaga from "./proxyUsers/saga";
import NotificationEmails from "./NotificationEmails/saga";
import WareHouseSaga from "./warehouse/saga";
import StripeSaga from "./Stripe/saga";
import ReturnsSaga from "./Returns/saga";
import ReportSaga from "./reports/saga";
import RepricerSaga from "./repricer/saga";
import InsightSaga from "./insights/saga";
import ProfitAnalyzer from "./ProfitAnalyzer/saga";
import CheckOffersSaga from "./CheckOffers/saga";

export default function * rootSaga () {
  yield all([
    // public
    AccountSaga(),
    AuthSaga(),
    AmazonSellers(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    SourceOrdersSaga(),
    EmailsSaga(),
    SupplierOrdersSaga(),
    CreditCardsSaga(),
    SourceItemsSaga(),
    SuppliersSaga(),
    OrderLogsSaga(),
    CredentialSaga(),
    ListingsSaga(),
    UnpublishedListingsSaga(),
    GraphsSaga(),
    AgencySaga(),
    MarketPlaceAccountsSaga(),
    FeedSaga(),
    ShipmentReport(),
    UserSaga(),
    AuthTokenSaga(),
    SettingsSaga(),
    PlatformsSaga(),
    CreditLog(),
    OrderAttempt(),
    RestrictedBrands(),
    RestrictedItems(),
    AOScreens(),
    MatchesRequestsSaga(),
    FiftyFiftyProfitSaga(),
    CustomPlansSaga(),
    FreeCreditsSaga(),
    AOStats(),
    AOTasks(),
    ProxyUsersSaga(),
    NotificationEmails(),
    WareHouseSaga(),
    StripeSaga(),
    ReturnsSaga(),
    ReportSaga(),
    RepricerSaga(),
    InsightSaga(),
    ProfitAnalyzer(),
    CheckOffersSaga(),
  ]);
}
