/* eslint-disable no-unused-vars */
import React from "react";
import { Col, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
import $ from "lodash";
import StatusBadge from "../../../components/Common/StatusBadge";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import PillBtn from "../../../components/Common/PillBtn";
import { RenderIf } from "../../../utils/common";
import { choosePriceOpts, competitionAboveOpts, competitionBelowOpts, competitionMatchOpts, hasBuxBoxOpts, noCompetitionOpts, REDUCE_LISTED_PRICE, whenBuyBoxSuppressedOpts } from "./common";
import { useFormik } from "formik";
import NumericField from "../../../components/Common/NumericField";

const Step1 = ({
  stepCounter,
  setStepCounter,
  step1InitialData,
  setStep1InitialData,
  formikValues,
  selectedPlatform,
}) => {
  const getDropdownValues = (object) => $.entries(object).map(([value, label]) => ({ value, label }));
  const getLabelValue = (dict, value) => ({ label: dict[value] || "Please Select", value });

  const isAmazon = selectedPlatform === "amazon";

  const keys = [
    "strategyNameField",
    "matchingPriceType",
    "matchingType",
    "matchingTypeAmount",
    "noCompetition",
    "competitionBelow",
    "competitionAbove",
    "matchCompetition",
    "haveBuyBox",
  ];

  isAmazon && keys.push("excludeSellerRating", "whenBuyBoxIsSuppressed");

  const checkError = (values) => {
    const errors = {};
    keys.forEach((key) => {
      if (key === "matchingType" || key === "excludeSellerRating");
      else if (!values[key]) errors[key] = "Required";
    });
    return errors;
  };

  const errorExists = (values) => !$.isEmpty(checkError(values));

  const checkAndSetErrors = (values) => {
    const errors = checkError(values);
    formik.setErrors(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: $.pick(step1InitialData || formikValues, [...keys, "beat_by_competition_when_gt_listed_price"]),
    validate: checkAndSetErrors,
    onSubmit: (values) => {
      if (!$.isEmpty(formik.errors)) return;
      setStep1InitialData(values);
      setStepCounter(2);
    },
  });

  const isMatchPrice = formik.values.matchingPriceType === "match_price";
  const isListedPriceFieldRequired = competitionAboveOpts[formik.values.competitionAbove] === REDUCE_LISTED_PRICE;
  isListedPriceFieldRequired && keys.push("beat_by_competition_when_gt_listed_price");
  return (
    <Col md={12}>
      <div
        className="strategy-card-header font-size-15 font-weight-semibold inventory-color"
        style={{ marginBottom: "24px" }}
      >
        <div className="d-flex justify-content-between">
          <span>{stepCounter}. Strategy Name and Setting</span>
          <StatusBadge status={"Strategy: Get BuyBox"} colorsMapping={{ "Strategy: Get BuyBox": "orange" }} />
        </div>
      </div>
      <Row className="font-size-12 font-weight-medium">
        <Col md={12}>
          <div className="font-size-13" style={{ padding: "10px 0px" }}>
            <Label>
              Name Your Strategy<span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Enter Strategy Name"
              onChange={(e) => {
                formik.setFieldValue("strategyNameField", e.target.value);
              }}
              value={formik.values.strategyNameField}
            />
          </div>
        </Col>
      </Row>

      <Row className="font-size-13 font-weight-medium">
        <Col md={6}>
          <div style={{ padding: "10px 0px" }}>
            <Label>
              Choose how to reprice against your competition<span className="text-danger">*</span>
            </Label>
            <Row className="font-size-13 font-weight-medium">
              <Col md={3}>
                <SimpleSelect
                  className="m-0 mb-2"
                  onChange={(e) => {
                    formik.setFieldValue("matchingPriceType", e.value);
                    if (e.value === "match_price") {
                      formik.setFieldValue("matchingTypeAmount", "0");
                    } else {
                      formik.setFieldValue("matchingTypeAmount", "");
                    }
                  }}
                  options={getDropdownValues(choosePriceOpts)}
                  value={getLabelValue(choosePriceOpts, formik.values.matchingPriceType)}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col md={9}>
                <InputGroup className="align-items-center" style={{ width: isMatchPrice ? "102.5%" : "100%" }}>
                  <NumericField
                    disabled={isMatchPrice}
                    placeholder="Enter Amount"
                    className={`form-control ${isMatchPrice ? "cursor-not-allowed" : ""}`}
                    step="0.01"
                    min="0.01"
                    onChange={(e) => formik.setFieldValue("matchingTypeAmount", e.target.value || "")}
                    value={formik.values.matchingTypeAmount}
                  />
                  <RenderIf isTrue={isMatchPrice}>
                    <InputGroupAddon addonType="append" style={{ position: "relative", left: "-25px", width: "2.5%" }}>
                      <i className="bx bx-lock-alt" />
                    </InputGroupAddon>
                  </RenderIf>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div style={{ padding: "10px 0px" }}>
            <SimpleSelect
              className="m-0"
              label={
                <span>
                  When there is no competition<span className="text-danger">*</span>
                </span>
              }
              options={getDropdownValues(noCompetitionOpts)}
              onChange={(e) => {
                formik.setFieldValue("noCompetition", e.value);
              }}
              value={getLabelValue(noCompetitionOpts, formik.values.noCompetition)}
              placeholder={"Please Select"}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      </Row>

      <Row className="font-size-11 font-weight-medium" style={{ marginBottom: "24px" }}>
        <Col md={12}>
          <div className="d-flex align-items-center text-primary">
            <i className="bx bx-info-circle mx-1" />
            Enter the amount in dollars that will be reduced to beat the competitor.
          </div>
        </Col>
      </Row>

      <Row className="font-size-13 font-weight-medium">
        <Col md={6}>
          <div style={{ padding: "10px 0px", marginBottom: "24px" }}>
            <SimpleSelect
              className="m-0"
              label={
                <span>
                  When the competition is below your minimum price<span className="text-danger">*</span>
                </span>
              }
              options={getDropdownValues(competitionBelowOpts)}
              onChange={(e) => {
                formik.setFieldValue("competitionBelow", e.value);
              }}
              value={getLabelValue(competitionBelowOpts, formik.values.competitionBelow)}
              placeholder={"Please Select"}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <Col md={6}>
          <div style={{ padding: "10px 0px", marginBottom: "24px" }}>
            <SimpleSelect
              className="m-0"
              label={
                <span>
                  When the competition matches your minimum price<span className="text-danger">*</span>
                </span>
              }
              options={getDropdownValues(competitionMatchOpts)}
              onChange={(e) => {
                formik.setFieldValue("matchCompetition", e.value);
              }}
              value={getLabelValue(competitionMatchOpts, formik.values.matchCompetition)}
              placeholder={"Please Select"}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      </Row>
      <Row className="font-size-13 font-weight-medium">
        <Col md={6}>
          <div className="d-flex">
            <div
              style={{
                padding: "10px 0px",
                marginBottom: "24px",
                marginRight: "1%",
                width: isListedPriceFieldRequired ? "65%" : "100%",
              }}
            >
              <SimpleSelect
                className="m-0"
                label={
                  <span>
                    When the competition is greater than listed price<span className="text-danger">*</span>
                  </span>
                }
                options={getDropdownValues(competitionAboveOpts)}
                onChange={(e) => {
                  formik.setFieldValue("competitionAbove", e.value);
                  formik.setFieldValue("beat_by_competition_when_gt_listed_price", null);
                }}
                value={getLabelValue(competitionAboveOpts, formik.values.competitionAbove)}
                placeholder={"Please Select"}
                classNamePrefix="select2-selection"
              />
            </div>
            <RenderIf isTrue={isListedPriceFieldRequired}>
              <div className="d-flex align-items-center" style={{ width: "34%" }}>
                <span className="input-group-append">
                  <span
                    style={{ position: "relative", borderRadius: "1" }}
                    className="input-group-text colorpicker-input-addon"
                  >
                    <i className="bx bx-dollar p-1" />
                  </span>
                </span>
                <NumericField
                  step="0.01"
                  min="0.01"
                  className="form-control"
                  value={formik.values?.beat_by_competition_when_gt_listed_price || ""}
                  onChange={(e) =>
                    formik.setFieldValue("beat_by_competition_when_gt_listed_price", e.target.value || "")
                  }
                />
              </div>
            </RenderIf>
          </div>
        </Col>
        <Col md={6}>
          <div style={{ padding: "10px 0px", marginBottom: "24px" }}>
            <SimpleSelect
              className="m-0"
              label={
                <span>
                  If you have the buybox<span className="text-danger">*</span>
                </span>
              }
              options={getDropdownValues(hasBuxBoxOpts)}
              onChange={(e) => {
                formik.setFieldValue("haveBuyBox", e.value);
              }}
              value={getLabelValue(hasBuxBoxOpts, formik.values.haveBuyBox)}
              placeholder={"Please Select"}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
        <RenderIf isTrue={isAmazon}>
          <Col md={6}>
            <div style={{ padding: "10px 0px", marginBottom: "24px" }}>
              <Label>Exclude seller rating: </Label>
              <div className="d-flex">
                <span className="input-group-append">
                  <span style={{ position: "relative" }} className="input-group-text colorpicker-input-addon">
                    At least
                  </span>
                </span>
                <Input
                  type="number"
                  step="1"
                  min="0"
                  className="form-control"
                  placeholder="Enter exclude seller rating greater than equal to 0"
                  onKeyPress={(e) => ["-", "e", "E"].includes(e.key) && e.preventDefault()}
                  onChange={(e) => formik.setFieldValue("excludeSellerRating", +e.target.value || "")}
                  value={formik.values.excludeSellerRating}
                />
              </div>
            </div>
          </Col>
        </RenderIf>
        <RenderIf isTrue={isAmazon}>
          <Col md={6}>
            <div style={{ padding: "10px 0px", marginBottom: "24px" }}>
              <SimpleSelect
                className="m-0"
                label={
                  <span>
                    When buybox is suppressed<span className="text-danger">*</span>
                  </span>
                }
                options={getDropdownValues(whenBuyBoxSuppressedOpts)}
                onChange={(e) => {
                  formik.setFieldValue("whenBuyBoxIsSuppressed", e.value);
                }}
                value={getLabelValue(whenBuyBoxSuppressedOpts, formik.values.whenBuyBoxIsSuppressed)}
                placeholder={"Please Select"}
                classNamePrefix="select2-selection"
              />
            </div>
          </Col>
        </RenderIf>
      </Row>
      <RenderIf isTrue={errorExists(formik.values)}>
        <Row className="font-size-11 font-weight-medium">
          <Col md={12}>
            <div className="d-flex align-items-center text-warning">
              <i className="bx bx-info-circle mx-1" />
              Note: Enter all the fields to enable Save & Next button
            </div>
          </Col>
        </Row>
      </RenderIf>
      <Row className="font-size-13 font-weight-medium">
        <Col md={12}>
          <div style={{ padding: "10px 0px" }}>
            <hr />
          </div>
          <div className="d-flex justify-content-end" style={{ padding: "10px 0px" }}>
            <PillBtn
              disabled={errorExists(formik.values)}
              className={`${errorExists(formik.values) ? "cursor-not-allowed" : ""} ml-2`}
              title={"Save & Next"}
              name={"Save & Next"}
              color={"primary"}
              onClick={formik.handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Step1;
