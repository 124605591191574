import React, { useEffect, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useParams, Link } from "react-router-dom";
import classnames from "classnames";

// availity-reactstrap-validation
import { AvForm, AvField, AvFeedback, AvInput, AvGroup } from "availity-reactstrap-validation";
// action
import { addAgencyChildAccount, registerUser, apiError, registerUserFailed, setRegError } from "../../store/actions";

// Redux
import { useSelector, connect } from "react-redux";

// utils
import { decode, encode, setLoader } from "../../utils/common";

// constants
import {
  PASSWORD_REGEX,
  PRIVACY_URL,
  TERMS_OF_CONDITION_URL,
  USER_NAME_REGEX,
  FIRST_LAST_NAME_REGEX,
} from "../../constants";

// import images
import logoLight from "../../assets/svg/logo.svg";
import logoDark from "../../assets/svg/logoDark.svg";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import Preloader from "../../components/Common/Preloader";

const Register = (props) => {
  const { isAgency } = props;
  const { token, hash } = useParams();
  const { exp, email } = parseToken(token);
  const [activeTab, setActiveTab] = useState(1);
  const { loading } = useSelector((state) => state.Account);
  const { loading: settingsLoading, error: SettingError } = useSelector((state) => state.Settings);
  const isExpired = exp && new Date(token.exp) < new Date();
  const logo = localStorage.getItem("THEME") === "light" ? logoLight : logoDark;
  const [isShownPass, setIsShownPass] = useState(false);
  const [phone, setPhone] = useState();
  const [isShownConfirmPass, setIsShownConfirmPass] = useState(false);
  const tabs = ["New Client", "Existing Client"].map((title, i) => ({ title, id: i + 1 }));

  // getting queries params from url
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // get specific fpr value from params and store it in cookies
  if (params.fpr) document.cookie = "promotion-value-fp = " + params.fpr;

  const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

  const [localPhoneNumberError, setLocalPhoneNumberError] = useState("");
  // handleValidSubmit
  function handleValidSubmit (e, vals) {
    if (isAgency && activeTab === 2) props.addAgencyChildAccount({ data: vals });
    else {
      vals.is_agency = vals.is_agency === "true";
      if (!phone) setLocalPhoneNumberError("Phone number required");
      if (!phone || localPhoneNumberError) return;
      vals.phone = phone;
      token && (vals = { ...vals, invite: token });
      hash && (vals = { ...vals, hash });
      getCookieValue("promotion-value-fp") && (vals = { ...vals, fpr: getCookieValue("promotion-value-fp") });
      if (isAgency) vals.is_client = true;
      delete vals.password_confirm;
      props.registerUser(vals, history);
    }
  }

  function parseToken (token) {
    try {
      return JSON.parse(decode(token || "", 3) || "{}");
    } catch (error) {
      props.setRegError("Token invalid.");
      return {};
    }
  }

  useEffect(() => {
    if (isExpired) props.setRegError("Invite link expired ask account owner to generate a new one.");
    if (props.user?.email && !isAgency) {
      if (props.match?.path === "/invite/:token") props.history.push("/login");
      else props.history.push(`/notification/${encode(props.user?.email)}`);
    }
  });

  useEffect(() => {
    setLoader(loading || settingsLoading);
  }, [loading, settingsLoading]);

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const colSizes = {
    md: isAgency ? null : 12,
    lg: isAgency ? null : 6,
    xl: isAgency ? null : 5,
  };

  return (
    <React.Fragment>
      {!localStorage.getItem("authTokenJwt") && <Preloader />}
      <div className="account-pages pt-sm-2">
        <Container>
          <Row className="justify-content-center">
            <Col {...colSizes}>
              {!isAgency && (
                <div className="d-flex justify-content-center m-3">
                  <img src={logo} alt={"Ecom Circles"}></img>
                </div>
              )}
              <Card className="overflow-hidden">
                {!isAgency && (
                  <div className="bg-soft-primary p-4">
                    <Col className="col-12">
                      <div className="text-primary">
                        <div className="card-title m-0">Welcome onboard!</div>
                        <span>
                          <small>Register to continue to Ecom Circles</small>
                        </span>
                      </div>
                    </Col>
                  </div>
                )}
                <CardBody className="pt-0">
                  {isAgency && (
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      {tabs.map((tab) => (
                        <NavItem key={`add-client-tab-${tab.id}`}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: activeTab === tab.id })}
                            onClick={() => toggleActiveTab(tab.id)}
                          >
                            <span className="d-none d-sm-block">{tab.title}</span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  )}
                  <div className="p-2">
                    <TabContent activeTab={activeTab}>
                      <TabPane
                        key={"tab_pane_1"}
                        tabId={1}
                        className="pt-3"
                        children={
                          <AvForm className="form-horizontal" onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                            {props.success && !isAgency
                              ? (
                              <Alert color="success">Register User Successfully</Alert>
                                )
                              : null}
                            {props.error ? <Alert color="danger">{props.error}</Alert> : null}

                            {!isExpired && (
                              <>
                                <Row className="d-flex justify-content">
                                  <Col md={6}>
                                    <Label>First Name</Label>
                                    <AvField
                                      name="first_name"
                                      type="text"
                                      errorMessage="First name is required"
                                      placeholder="Enter First name"
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: FIRST_LAST_NAME_REGEX,
                                          errorMessage:
                                            "First name shouldn't contain any number or special character other than dash or apostrophes.",
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <Label>Last Name</Label>
                                    <AvField
                                      name="last_name"
                                      type="text"
                                      errorMessage="Last name is required"
                                      placeholder="Enter Last name"
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: FIRST_LAST_NAME_REGEX,
                                          errorMessage:
                                            "Last name shouldn't contain any number or special character other than dash or apostrophes.",
                                        },
                                      }}
                                    />
                                  </Col>
                                </Row>

                                <Label>Username</Label>
                                <AvField
                                  name="username"
                                  type="text"
                                  errorMessage="Username is required"
                                  required
                                  placeholder="Enter username"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: USER_NAME_REGEX,
                                      errorMessage: "User name must contains alpha numeric (*special character).",
                                    },
                                  }}
                                />

                                <div className="form-group">
                                  <Label>Email</Label>
                                  <AvField
                                    name="email"
                                    value={email || ""}
                                    errorMessage="Email is required"
                                    className="form-control"
                                    placeholder="Enter email"
                                    type="email"
                                    required
                                    readOnly={!!email}
                                  />
                                </div>

                                <div className="form-group">
                                  <Label>Phone</Label>
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    name="phone"
                                    defaultCountry="US"
                                    international={true}
                                    countryCallingCodeEditable={false}
                                    value={phone}
                                    onChange={(phone) => {
                                      const isPhonePossible = phone
                                        ? isPossiblePhoneNumber(phone)
                                          ? setLocalPhoneNumberError()
                                          : "Invalid phone number"
                                        : "Phone number required";
                                      if (isPhonePossible) {
                                        setLocalPhoneNumberError(isPhonePossible);
                                      }
                                      setPhone(phone);
                                    }}
                                  />
                                  {localPhoneNumberError
                                    ? (
                                    <small className="text-danger mt-1">{localPhoneNumberError}</small>
                                      )
                                    : null}
                                </div>
                                <Label>Password</Label>
                                <AvGroup>
                                  <div className="input-group">
                                    <AvInput
                                      name="password"
                                      type={isShownPass ? "text" : "password"}
                                      placeholder="Enter Password"
                                      className="form-control d-block flatpickr-input"
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: PASSWORD_REGEX,
                                          errorMessage:
                                            "Password must contain at least one uppercase letter, one number, one special character and must be of 8 characters minimum.",
                                        },
                                      }}
                                    />

                                    <span className="input-group-append">
                                      <span
                                        className="input-group-text colorpicker-input-addon rounded-right"
                                        onClick={() => {
                                          setIsShownPass(!isShownPass);
                                        }}
                                      >
                                        <i className="bx bx-xs bx-show mx-1 cursor-pointer"></i>
                                      </span>
                                    </span>
                                    <AvFeedback>
                                      Password must contain at least one uppercase letter, one number, one special
                                      character and must be of 8 characters minimum.
                                    </AvFeedback>
                                  </div>
                                </AvGroup>

                                <Label>Confirm Password</Label>
                                <AvGroup>
                                  <div className="input-group">
                                    <AvInput
                                      name="password_confirm"
                                      type={isShownConfirmPass ? "text" : "password"}
                                      className="form-control d-block flatpickr-input"
                                      placeholder="Re-type Password"
                                      errorMessage="Re-type Password"
                                      validate={{
                                        required: { value: true },
                                        match: { value: "password" },
                                      }}
                                    />

                                    <span className="input-group-append">
                                      <span
                                        className="input-group-text colorpicker-input-addon rounded-right"
                                        onClick={() => {
                                          setIsShownConfirmPass(!isShownConfirmPass);
                                        }}
                                      >
                                        <i className="bx bx-xs bx-show mx-1 cursor-pointer"></i>
                                      </span>
                                    </span>
                                    <AvFeedback>Password does not match.</AvFeedback>
                                  </div>
                                </AvGroup>
                                {!isAgency && (
                                  <>
                                    <div className="form-group">
                                      <AvGroup>
                                        <AvInput
                                          tag={CustomInput}
                                          type="checkbox"
                                          id="invalidCheck"
                                          name="tos_accepted"
                                          className="d-inline"
                                          required
                                        />
                                        <Label>
                                          Agree with
                                          <a target="_blank" rel="noopener noreferrer" href={TERMS_OF_CONDITION_URL}>
                                            {" "}
                                            Terms of Condition{" "}
                                          </a>
                                          and
                                          <a target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
                                            {" "}
                                            Privacy Policy{" "}
                                          </a>
                                        </Label>
                                        <AvFeedback>You must agree before continue.</AvFeedback>
                                      </AvGroup>
                                    </div>
                                    <div className="radio-select">
                                      <AvGroup>
                                        <AvRadioGroup inline name="is_agency" required>
                                          <AvRadio customInput label="I am running my own account" value="false" />
                                          <AvRadio customInput label="I am an agency" value="true" />
                                        </AvRadioGroup>
                                        <AvFeedback>You must select one of them.</AvFeedback>
                                      </AvGroup>
                                    </div>
                                  </>
                                )}

                                <div className="mt-4">
                                  <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                                    {isAgency ? "Invite" : "Register"}
                                  </button>
                                </div>
                              </>
                            )}
                          </AvForm>
                        }
                      />

                      <TabPane
                        key={"tab_pane_2"}
                        tabId={2}
                        className="pt-3"
                        children={
                          <AvForm className="form-horizontal" onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                            {SettingError ? <Alert color="danger">{SettingError}</Alert> : null}

                            <div className="form-group">
                              <Label>Email</Label>
                              <AvField
                                name="email"
                                value={email || ""}
                                errorMessage="Email is required"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                required
                              />
                            </div>

                            <div className="mt-4">
                              <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                                Invite
                              </button>
                            </div>
                          </AvForm>
                        }
                      />
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
              {!isAgency && (
                <div className="mt-2 text-center font-weight-bold">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/login" className="text-primary">
                      {" "}
                      Sign in
                    </Link>{" "}
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading, error, success } = state.Account;
  return { user, registrationError, loading, error, success };
};

export default connect(mapStatetoProps, {
  addAgencyChildAccount,
  registerUser,
  apiError,
  registerUserFailed,
  setRegError,
})(Register);
