import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  SET_REG_ERROR,
  SEND_VERIFY_TOKEN,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_WAREHOUSE_USER,
  VERIFY_WAREHOUSE_USER_SUCCESS,
  SET_VERIFY_ERROR,
  SEND_VERIFY_TOKEN_SUCCESS,
  GET_VERIFICATION_LINK,
  RESET_AUTH_REG_REDUCER,
  GET_VERIFICATION_LINK_FAILED,
  GET_VERIFICATION_LINK_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  success: "",
  error: "",
  verifyError: "",
  verifySuccess: "",
  verifyLinkApiSuccess: "",
  verifyLinkApiError: "",
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        error: "",
        success: "",
      };
      break;
    case REGISTER_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload?.user,
        success: action.payload?.message,
        error: null,
      };
      break;
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        error: action.payload,
      };
      break;
    case SET_REG_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case SEND_VERIFY_TOKEN:
      state = {
        ...state,
        verifySuccess: "",
        verifyError: "",
        loading: true,
      };
      break;
    case SEND_VERIFY_TOKEN_SUCCESS:
      state = {
        ...state,
        verifySuccess: "Verification email sent",
        verifyError: "",
        loading: false,
      };
      break;
    case SET_VERIFY_ERROR:
      state = {
        ...state.payload,
        verifyError: action.payload,
      };
      break;
    case VERIFY_USER:
      state = {
        ...state,
        verifySuccess: "",
        verifyError: "",
        loading: true,
      };
      break;
    case VERIFY_USER_SUCCESS:
      state = {
        ...state,
        verifySuccess: action.payload?.message || "User verified",
        verifyError: "",
        loading: false,
      };
      break;
    case VERIFY_WAREHOUSE_USER:
      state = {
        ...state,
        loading: true,
        verifySuccess: "",
        verifyError: "",
      };
      break;
    case VERIFY_WAREHOUSE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        verifySuccess: action.payload.message || "WareHouse User Added successfully",
      };
      break;
    case GET_VERIFICATION_LINK:
      state = {
        ...state,
        verifyLinkApiSuccess: "",
        verifyLinkApiError: "",
        loading: true,
      };
      break;
    case GET_VERIFICATION_LINK_SUCCESS:
      state = {
        ...state,
        verifyLinkApiSuccess: "Email sent! please verify.",
        verifyLinkApiError: "",
        loading: false,
      };
      break;
    case GET_VERIFICATION_LINK_FAILED:
      state = {
        ...state,
        verifyLinkApiSuccess: "",
        verifyLinkApiError: action.payload,
        loading: false,
      };
      break;
    case RESET_AUTH_REG_REDUCER:
      state = {
        ...state,
        loading: false,
        success: "",
        error: "",
        verifyError: "",
        verifySuccess: "",
        verifyLinkApiSuccess: "",
        verifyLinkApiError: "",
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;
