import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Preloader from "../../components/Common/Preloader";
import { useHistory } from "react-router";
import { Col, CardBody, Card, Container, Row, Alert } from "reactstrap";
import OtpInput from "react-otp-input";
import { setLoader } from "../../utils/common";

import { generateOtp, verifyOtp as _verifyOtp } from "../../store/actions";
// import images
import logoLight from "../../assets/svg/logo.svg";
import logoDark from "../../assets/svg/logoDark.svg";

function TwoFactorVerification (props) {
  const logo = localStorage.getItem("THEME") === "light" ? logoLight : logoDark;
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, loading, success } = useSelector((state) => state.Login);

  const [otp, setOtp] = useState("");
  const [formError, setFormError] = useState("");

  const verifyOtp = () => {
    if (!otp || otp.length !== 6) return setFormError("Verification code must be 6 characters long");
    dispatch(_verifyOtp({ otp_code: otp }, history));
    setFormError("");
  };

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <>
      <Preloader />
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center align-items-center" style={{ height: "95vh" }}>
            <Col md={8} lg={6} xl={5}>
              <div className="d-flex justify-content-center mb-3">
                <img src={logo} alt={"Ecom Circles"}></img>
              </div>
              {success && (
                <div className="auto-hide">
                  <Alert color="success" className="my-1">
                    <i className="bx bx-info-circle pr-2"></i>
                    {success}
                  </Alert>
                </div>
              )}
              {(error || formError) && (
                <div className="auto-hide">
                  <Alert color="danger" className="my-1">
                    <i className="bx bx-info-circle pr-2"></i>
                    {error || formError}
                  </Alert>
                </div>
              )}
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Col className="col-12">
                    <div className="text-primary p-4">
                      <div className="card-title m-0">A 6-digit verfication code is sent to you!</div>
                      <span className="mt-3 inventory-color">Kindly enter the verification code</span>
                    </div>
                  </Col>
                </div>
                <CardBody className="pt-0">
                  <form
                    className="p-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      verifyOtp();
                    }}
                  >
                    <OtpInput
                      containerStyle={{ paddingTop: "20px" }}
                      inputStyle={{ width: "50px", height: "50px" }}
                      value={otp}
                      onChange={(otp) => setOtp(otp)}
                      numInputs={6}
                      separator={
                        <b>
                          <span style={{ padding: "5px", fontSsize: "30px" }}>-</span>
                        </b>
                      }
                      shouldAutoFocus={true}
                      isInputNum={true}
                    />
                    <div className="d-flex flex-row justify-content-between p-4">
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn btn-primary btn-block waves-effect waves-light"
                          onClick={() => dispatch(generateOtp())}
                        >
                          Get OTP
                        </button>
                      </div>
                      <div className="mt-3">
                        <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                          Verify
                        </button>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TwoFactorVerification;
