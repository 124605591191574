import request from "../restClient";
import { makeQP } from "../../utils/common";
const resource = "/graphs";

export const getAllGraphs = async (params) => {
  const url = `${resource}` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getFeesDetails = (params) => request({ url: `${resource}/fee_details${makeQP(params)}`, method: "GET" });

export const getAllTenantGraphs = async (params) => {
  const url = "/global_graphs" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getOrdersGraph = async (params) => {
  const url = `${resource}/orders` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getAllTenantOrdersGraph = async (params) => {
  const url = `${resource}/global_orders` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getSalesGraph = async (params) => {
  const url = `${resource}/sales` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getClientStats = async (params) => {
  const url = "/client_stats" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getAllTenantSalesGraph = async (params) => {
  const url = `${resource}/global_sales` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getExpensesGraph = async (params) => {
  const url = `${resource}/expenses` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getAllTenantExpensesGraph = async (params) => {
  const url = `${resource}/global_expenses` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getProfitGraph = async (params) => {
  const url = `${resource}/profit` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getAllTenantProfitGraph = async (params) => {
  const url = `${resource}/global_profit` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getSharedProfitGraph = async (params) => {
  const url = `${resource}/global_shared_profit` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getSharedOrderStatus = async (params) => {
  const url = `${resource}/global_status` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getDailyReportData = async (params) => {
  const url = `${resource}/daily_report` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getSalesRevenueData = (params) =>
  request({ url: `${resource}/new_dashboard` + makeQP(params), method: "GET" });

export const getReturnsGraph = async (params) => {
  const url = `${resource}/returns` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getAllTenantReturnsGraph = async (params) => {
  const url = `${resource}/global_returns` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getUsedCreditsGraph = async (params) => {
  const url = `${resource}/used_credits` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getAllTenantUsedCreditsGraph = async (params) => {
  const url = `${resource}/global_used_credits` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getIssueStats = async (params) => {
  const url = `${resource}/issues` + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getListingStats = async (params) => {
  const url = "/auto_orders/listing_stats" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getBuyBoxStats = async (params) => {
  const url = "/auto_orders/refresher_stats" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getErrorStats = async (params) => {
  const url =
    "/source_orders/get_in_queue?errored=1&" +
    Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  return await request({ url, method: "GET" });
};

export const getBestSellersStats = async (params = {}) => {
  const url = "/graphs/best_selling_items" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getTopSellingItems = async (params = {}) => {
  const url = "/graphs/top_selling_items" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getGlobalTopSellingItems = async (params = {}) => {
  const url = "/graphs/global_top_selling_items" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getLeastSellingItems = async (params = {}) => {
  const url = "/graphs/least_selling_items" + makeQP(params);
  return await request({ url, method: "GET" });
};

export const getProductsBySales = (params) =>
  request({ url: `${resource}/sales_by_brand` + makeQP(params), method: "GET" });

export const getProductsByCategory = (params) =>
  request({ url: `${resource}/sales_by_category` + makeQP(params), method: "GET" });
