import * as types from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  success: "",
  chargesStats: {},
  inboundStats: {},
  inboundStatsLoading: false,
  storageStats: {},
  storageStatsLoading: false,
  outboundStats: {},
  outboundStatsLoading: false,
  labelGeneratedData: [],
  labelCancelledData: [],
};

const insights = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS:
      state = {
        ...state,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS_SUCCESS:
      state = {
        ...state,
        chargesStats: action.payload,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS:
      state = {
        ...state,
        inboundStatsLoading: true,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS_SUCCESS:
      state = {
        ...state,
        inboundStats: action.payload,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS:
      state = {
        ...state,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS_SUCCESS:
      state = {
        ...state,
        storageStats: action.payload,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS:
      state = {
        ...state,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS_SUCCESS:
      state = {
        ...state,
        outboundStats: action.payload,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH:
      state = {
        ...state,
        labelGeneratedData: [],
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH_SUCCESS:
      state = {
        ...state,
        labelGeneratedData: action.payload,
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH:
      state = {
        ...state,
        labelCancelledData: [],
      };
      break;
    case types.FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH_SUCCESS:
      state = {
        ...state,
        labelCancelledData: action.payload,
      };
      break;
    case types.API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default insights;
