import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import { fetchPlatformSettings, updatePlatformSettings } from "../../api";

// Login Redux States
import { FETCH_ALL_PLATFORM, UPDATE_PLATFORM } from "./actionTypes";

import { apiError, fetchPlatformsSuccess, updatePlatformSuccess, setPlatformEditModal } from "./actions";

const Platform = (state) => state.Platform;

function * fetchPlatformSaga ({ payload: { params } }) {
  try {
    const res = yield call(fetchPlatformSettings, { params });
    yield put(fetchPlatformsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updatePlatformSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updatePlatformSettings, { data, id });
    if (res?.data) {
      const { platforms } = yield select(Platform);
      const index = platforms.data.findIndex((p) => p.id === res.data.id);
      if (platforms?.data[index]) {
        platforms.data[index] = res.data;
      }
      yield put(setPlatformEditModal(false));
      yield put(updatePlatformSuccess(res.data));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchFetchPlatforms () {
  yield takeEvery(FETCH_ALL_PLATFORM, fetchPlatformSaga);
}

export function * watchUpdatePlatform () {
  yield takeEvery(UPDATE_PLATFORM, updatePlatformSaga);
}

function * PlatformSaga () {
  yield all([fork(watchFetchPlatforms), fork(watchUpdatePlatform)]);
}

export default PlatformSaga;
