import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, Card, CardSubtitle, CardBody } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";

// actions
import {
  bulkUpdateItems,
  setSelectedItems,
  setAddItemModal,
  bulkAddNewItem,
  setEditAddItemModal,
  setBulkAddItemModal,
  updateItem,
  addNewItem,
  selectFileError,
} from "../../../store/actions";
import { getUserInfo, RenderIf, toPascalCase, isAgency } from "../../../utils/common";
import Dropzone from "react-dropzone";

const RestictedBulkItemsModal = (props) => {
  const validate = (values) => {
    const errors = {};
    !values.item_identifier && (errors.item_identifier = "Item identifier is requried");
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      item_identifier: props?.selectedItem?.item_identifier || "",
      restricted_reason: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (props.type === "add") {
        props.addNewItem({
          va_id: getUserInfo().id,
          platform: props.platform,
          item_identifier: values.item_identifier,
          ...(isAgency ? { reason: values.restricted_reason } : {}),
        });
      } else if (props.type === "edit") {
        props.updateItem({
          data: {
            va_id: getUserInfo().id,
            platform: props.platform,
            item_identifier: values.item_identifier,
          },
          id: props.selectedItem.id,
        });
      }
    },
  });

  const toggleModal = () => {
    if (props.type === "add") props.setAddItemModal(false);
    else if (props.type === "bulkAdd") props.setBulkAddItemModal(false);
    else props.setEditAddItemModal(false);
    formik.resetForm();
  };

  return (
    <React.Fragment>
      <Modal
        size={props.type === "bulkAdd" ? "md" : "sm"}
        isOpen={
          props.RestrictedItems.addItemModalIsOpen ||
          props.RestrictedItems.editItemModalIsOpen ||
          props.RestrictedItems.bulkAddItemModalIsOpen
        }
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {props.type === "add"
              ? "Add Restricted Item"
              : props.type === "bulkAdd"
                ? `Add Bulk Restricted Items (${props.platform})`
                : "Update Restricted Item"}
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.RestrictedItems.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.RestrictedItems.error}
            </Alert>
          )}

          <RenderIf isTrue={props.type === "bulkAdd"}>
            <UploadFile props={props} toggleModal={toggleModal} />
          </RenderIf>

          <RenderIf isTrue={props.type !== "bulkAdd"}>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <RenderIf isTrue={props.type === "add"}>
                  <Col lg="12" className="py-1">
                    <Label>Platform</Label>
                    <input disabled={true} type="text" value={toPascalCase(props.platform)} className="form-control" />
                  </Col>
                </RenderIf>
                <Col lg="12" className="py-1">
                  <Label>Item Identifier</Label>
                  <input
                    name="item_identifier"
                    placeholder={props.platform === "walmart" ? "Item Id" : "ASIN"}
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.item_identifier}
                    className="form-control"
                  />
                  {formik.touched.item_identifier && formik.errors.item_identifier
                    ? (
                    <small className="text-danger">{formik.errors.item_identifier}</small>
                      )
                    : null}
                </Col>
                <Col lg="12">
                  <Label>Reason</Label>
                  <input
                    name="restricted_reason"
                    placeholder="Reason to mark item as restricted"
                    maxLength="255"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.restricted_reason}
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-end mt-3">
                <Button
                  type="reset"
                  color="secondary"
                  className="mr-3"
                  onClick={() => {
                    formik.handleReset();
                    toggleModal();
                  }}
                >
                  Cancel
                </Button>
                {
                  <Button type="submit" color="success" className="mr-3">
                    Submit
                  </Button>
                }
              </Row>
            </form>
          </RenderIf>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const UploadFile = ({ props, toggleModal }) => {
  const [selectedFile, setselectedFile] = useState([]);
  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function handleReportSubmit () {
    if (selectedFile.length > 0) {
      props.bulkAddNewItem({
        va_id: getUserInfo().id,
        platform: props.platform,
        file: selectedFile[0],
      });
      toggleModal();
      setselectedFile([]);
    } else props.selectFileError();
  }

  return (
    <Card>
      <div style={{ marginLeft: 20, marginTop: 20 }}>
        <CardSubtitle className="mb-3">
          {" "}
          CSV File must have these Columns with same naming conventions: Item Identifier, Restricted Reason
        </CardSubtitle>
      </div>
      <CardBody>
        <form className="m-2">
          <Col className="my-1">
            {selectedFile.length === 0 && (
              <Dropzone
                onDrop={(acceptedFile) => {
                  handleAcceptedFile(acceptedFile);
                }}
                accept=".csv"
                maxFiles="1"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                      </div>
                      <h4>Drag or Click to Upload CSV File</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
            )}
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFile.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col>
                          {f.name}
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                          <p className="mb-0"></p>
                        </Col>
                        <i
                          title="Remove File"
                          className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                          onClick={() => setselectedFile([])}
                        ></i>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
            {selectedFile.length !== 0 && (
              <div className="text-center mt-4">
                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={handleReportSubmit}>
                  Upload File
                </button>
              </div>
            )}
          </Col>
        </form>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { RestrictedItems } = state;
  return { RestrictedItems };
};

export default connect(mapStateToProps, {
  bulkUpdateItems,
  selectFileError,
  setSelectedItems,
  bulkAddNewItem,
  setAddItemModal,
  setEditAddItemModal,
  setBulkAddItemModal,
  updateItem,
  addNewItem,
})(RestictedBulkItemsModal);
