export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const SET_REG_ERROR = "SET_REG_ERROR";
export const SET_VERIFY_ERROR = "SET_VERIFY_ERROR";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_WAREHOUSE_USER = "VERIFY_WAREHOUSE_USER";
export const VERIFY_WAREHOUSE_USER_SUCCESS = "VERIFY_WAREHOUSE_USER_SUCCESS";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const SEND_VERIFY_TOKEN = "SEND_VERIFY_TOKEN";
export const SEND_VERIFY_TOKEN_SUCCESS = "SEND_VERIFY_TOKEN_SUCCESS";
export const API_ERROR = "REGISTRATION_API_ERROR";
export const GET_VERIFICATION_LINK = "GET_VERIFICATION_LINK";
export const GET_VERIFICATION_LINK_FAILED = "GET_VERIFICATION_LINK_FAILED";
export const GET_VERIFICATION_LINK_SUCCESS = "GET_VERIFICATION_LINK_SUCCESS";
export const RESET_AUTH_REG_REDUCER = "RESET_AUTH_REG_REDUCER";
