import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Api
import { forgetUser, resetPassword } from "../../../api";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError, resetPasswordSuccess } from "./actions";

function * forgetUserSaga ({ payload: { email } }) {
  try {
    const response = yield call(forgetUser, email);
    yield put(userForgetPasswordSuccess(response.message));
  } catch (error) {
    yield put(userForgetPasswordError(error.message));
  }
}

function * resetUserSaga ({ payload: { data } }) {
  try {
    yield call(resetPassword, data);
    yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(userForgetPasswordError(error.message));
  }
}

export function * watchUserPasswordForget () {
  yield takeEvery(FORGET_PASSWORD, forgetUserSaga);
}

export function * watchUserPasswordReset () {
  yield takeEvery(RESET_PASSWORD, resetUserSaga);
}

function * forgetPasswordSaga () {
  yield all([fork(watchUserPasswordForget), fork(watchUserPasswordReset)]);
}

export default forgetPasswordSaga;
