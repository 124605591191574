import React from "react";
import getBrandImageSrc from "../../../utils/brandImages";
import { getUserInfo, setSupplierURL, verifyRole } from "../../../utils/common";
import { INVENTORY_ALLOWED_ACCS, USER_ROLES } from "../../../constants";

function SupplierSKU (props) {
  const isPlumberStock = (name) => name === "plumberstock";
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        {uniqueSKUs(props.item?.source_items)
          .map((sku) => props.skuSupplier[sku])
          .flat()
          .filter((x) => x?.name !== "unknown")
          .map(
            (x, index) =>
              x && (
                <a
                  key={"_sku_supplier_" + index}
                  className="p-1"
                  href={
                    x.name === "warehouse" &&
                    (!verifyRole(USER_ROLES.va) || INVENTORY_ALLOWED_ACCS.includes(getUserInfo()?.account_id))
                      ? `/inventory-items?search=${x.sku}&account_id=${getUserInfo()?.account_id}`
                      : setSupplierURL(x.url)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={isPlumberStock(x.name) ? { borderRadius: "20px", width: "23px" } : {}}
                    src={getBrandImageSrc(x.name)}
                    alt={x.name}
                  />
                </a>
              ),
          )}
      </div>
    </>
  );
}

function uniqueSKUs (a) {
  return [...new Set(a.map((item) => item.sku))];
}

export default SupplierSKU;
