import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Label, FormGroup, Alert } from "reactstrap";
import * as $ from "lodash";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";
import Select from "react-select";
import { RenderIf, getWarehouseName } from "../../../utils/common";
import "react-datepicker/dist/react-datepicker.css";

// actions
import { setChangeWareHouseModal, setChangedWareHouse } from "../../../store/actions";

const ChangeWareHouseModal = (_) => {
  const dispatch = useDispatch();
  const { associatedWarehouses, error } = useSelector((state) => state.WareHouses);
  const {
    changedWareHouseId,
    sourceOrderDetail: { data },
    changeWareHouseModalIsOpen,
  } = useSelector((state) => state.SourceOrders);

  const initialValues = { warehouse: { label: "Select WareHouse", value: "" } };
  const toggleModal = () => {
    dispatch(setChangeWareHouseModal(!changeWareHouseModalIsOpen));
    formik.resetForm();
  };
  const getSelectedWHId = () => changedWareHouseId || +data.wh_address.id;

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key].label === "" || values[key].value === "") errors[key] = "Field is Required";
    });
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: ({ warehouse: { value } }) => {
      dispatch(setChangedWareHouse(value));
      toggleModal();
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={changeWareHouseModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">UPDATE WAREHOUSE</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mt-2">
                <FormGroup className="select2-container mb-0">
                  <Label>WareHouse Name</Label>
                  <Select
                    name="warehouse"
                    value={formik.values.warehouse}
                    onBlur={formik.handleBlur}
                    onChange={(option) => formik.setFieldValue("warehouse", option)}
                    options={$.map(
                      $.filter(associatedWarehouses?.data, (x) => x.id !== getSelectedWHId()),
                      (y) => ({ label: getWarehouseName(y), value: y.id }),
                    )}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                <RenderIf isTrue={formik.touched.warehouse && formik.errors.warehouse}>
                  <small className="text-danger">{formik.errors.warehouse}</small>
                </RenderIf>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Update
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default ChangeWareHouseModal;
