import {
  FETCH_AMAZON_SELLERS,
  FETCH_AMAZON_SELLERS_SUCCESS,
  SET_AMAZON_SELLERS_FILTERS,
  CREATE_AMAZON_SELLER,
  CREATE_AMAZON_SELLER_SUCCESS,
  SET_SUCCESS_MESSAGE,
  UPDATE_AMAZON_SELLER,
  UPDATE_AMAZON_SELLER_SUCCESS,
  SET_ADD_AMAZON_SELLER_MODAL,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  addAmazonSellerModalIsOpen: false,
  sellers: [],
  filters: {
    search: "",
    is_blacklisted: "",
  },
};

const AmazonSellers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AMAZON_SELLERS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_AMAZON_SELLERS_SUCCESS:
      state = {
        ...state,
        sellers: action.payload,
        loading: false,
      };
      break;
    case CREATE_AMAZON_SELLER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case CREATE_AMAZON_SELLER_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Amazon Seller has been Created Successfully",
        loading: false,
      };
      break;
    case SET_SUCCESS_MESSAGE:
      state = {
        ...state,
        success: action.payload,
      };
      break;
    case UPDATE_AMAZON_SELLER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_AMAZON_SELLER_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Amazon Seller has been Updated Successfully",
        loading: false,
      };
      break;
    case SET_ADD_AMAZON_SELLER_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        addAmazonSellerModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_AMAZON_SELLERS_FILTERS:
      state = {
        ...state,
        filters: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AmazonSellers;
