import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Alert, Label, Row, Button } from "reactstrap";

import Modal from "../../../components/Common/Modal";
import SelectFilter from "./SelectFilter";
import { formatFilter, requiredValidation } from "../common";
import { getUserInfo } from "../../../utils/common";

import { addNewSavedFilter, updateSavedFilter } from "../../../store/actions";

const SaveFilterModal = (props) => {
  const { isOpen, toggleModal } = props;
  const dispatch = useDispatch();
  const { selectedFilter, filters: filtersState, selectedMarketplace } = useSelector((state) => state.ProfitAnalyzer);
  const [formData, setFormData] = useState({ isDefaultFilter: false });

  const upsertFilter = (e, v) => {
    const updatedFilter = formatFilter(filtersState, selectedMarketplace);

    if (selectedFilter) {
      dispatch(
        updateSavedFilter(
          {
            _id: filtersState?.[selectedMarketplace]?._id,
          },
          {
            filter: updatedFilter,
            email: getUserInfo()?.email,
            name: v.filter_name,
            destination: selectedMarketplace,
            isDefault: v.isDefaultFilter,
          },
        ),
      );
    } else {
      dispatch(
        addNewSavedFilter({
          filter: updatedFilter,
          email: getUserInfo()?.email,
          name: v.filter_name,
          destination: selectedMarketplace,
          isDefault: v.isDefaultFilter,
        }),
      );
    }
    toggleModal();
  };

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Save EC Filter
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body p-2">
        <div className="px-2">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              v.isDefaultFilter = formData?.isDefaultFilter;
              upsertFilter(e, v);
            }}
          >
            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}
            <div className="form-group">
              <AvField
                name="filter_name"
                label="Filter Name"
                value={`${selectedFilter || ""}`}
                className="form-control"
                placeholder="Get your filter a name"
                validate={requiredValidation}
              />

              <div className="custom-control custom-checkbox" style={{ width: "150px" }}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="isDefaultFilter"
                  checked={formData?.isDefaultFilter}
                />
                <label
                  onClick={() =>
                    setFormData({
                      ...formData,
                      isDefaultFilter: !formData?.isDefaultFilter,
                    })
                  }
                  className="custom-control-label"
                >
                  Set as default
                </label>
              </div>
              <hr />
              <Label>Update an Existing Filter</Label>
              <SelectFilter defaultValue="Select a filter" />
              <Row className="align-items-center d-flex justify-content-end mr-1">
                <Button color="primary " outline className="waves-effect waves-light mr-3" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Save
                </Button>
              </Row>
            </div>
          </AvForm>
        </div>
      </div>
    </Modal>
  );
};

export default SaveFilterModal;
