import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Card, ModalBody, Alert, Label, FormGroup, Input, Row, Spinner } from "reactstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Modal from "../../../components/Common/Modal";
import Select from "react-select";
import { getAllStates } from "../../../utils/common";
import { DATE_FORMATS, regions } from "../../../constants";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
import { useFormik } from "formik";
// api action
import { addNewPaymentSource } from "../../../api/Stripe";

// redux actions
import { updateAccountSettings, updateAccountSettingsSuccess } from "../../../store/actions";

import moment from "moment-timezone";
import { merge } from "lodash";

const { DATE_TIME } = DATE_FORMATS;

const AddCard = (props) => {
  const { account } = useSelector((state) => state.Settings);
  const [error, setError] = useState(null);
  const [apiErr, setApiErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const initialValues = {
    userName: "",
    country: "",
    state: "",
    city: "",
    zip: "",
  };
  const validate = (values) => {
    const errors = {};
    !values.userName && (errors.userName = "Field is Required");
    !values.country && (errors.country = "Field is Required");
    !values.state && (errors.state = "Field is Required");
    !values.city && (errors.city = "Field is Required");
    !values.zip && (errors.zip = "Field is Required");
    values.zip &&
      !/^([a-zA-Z0-9]+-)*[a-zA-Z0-9]+$/.test(values.zip) &&
      (errors.zip = "Only alphanumeric and hyphen allowed");
    if (values.city && values.city.length < 3) errors.city = "Invalid City Name";
    return errors;
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (values) => {
      const address = {
        userName: values.userName || "",
        country: values.country?.label || "",
        state: values.state?.label || "",
        city: values.city || "",
        zip: values.zip || "",
      };
      try {
        setLoading(true);
        if (stripe && !error) {
          const data = {
            name: address.userName,
            address_city: address.city,
            address_state: address.state,
            address_country: address.country,
            address_zip: address.zip,
          };
          const { token, error } = await stripe.createToken(elements.getElement(CardElement), data);
          if (error) throw error;
          await addPaymentSource(token.id);
        }
        setLoading(false);
        props.toggleOpen();
      } catch (error) {
        setApiErr(error.message || error);
        setLoading(false);
      }
    },
  });
  useEffect(() => {
    Object.entries(formik.values).every(([key, val]) => {
      if (["country", "id"].includes(key)) return true;
      if (["state", "city"].includes(key)) return val?.value === initialValues[key]?.value;
      return val === initialValues[key];
    });
  }, [formik.values]);
  const handleChange = async (event) => {
    setDisabled(event.empty || !event.complete || event.error);
    setError(event?.error?.message || "");
  };

  function dismissError () {
    dispatch(updateAccountSettings({ last_error_dismissed: new Date() }));
    props.toggleOpen(props.isOpen);
  }

  async function addPaymentSource (token, address) {
    dispatch(updateAccountSettingsSuccess());
    return addNewPaymentSource({ token, address });
  }
  function CustomLabel (props) {
    return <Label className="mt-3">{props.children}</Label>;
  }
  useEffect(() => {
    setTimeout(() => setApiErr(""), 3000);
  }, [apiErr]);

  function toggleModal () {
    props.toggleOpen(props.isOpen);
  }
  const style = localStorage.getItem("THEME") === "light" ? styleLight : styleDark;
  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal} className="border-0">
        <ModalBody>
          <h4 className="card-title mb-3 text-center">Enter your payment details</h4>
          {moment(account?.data?.trial_end) > moment() && !account?.data?.payment_source_added && (
            <Alert color="warning">
              <i className="bx bx-info-circle pr-2"></i>
              Add your payment details before {moment(account?.trial_end).format(DATE_TIME)} for uninterrupted services.
            </Alert>
          )}

          {apiErr && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {apiErr}
              </Alert>
            </div>
          )}

          <Card className="align-items-center bg-grey">
            <Col className="p-2">
              <AvForm className="form-horizontal" onSubmit={() => formik.handleSubmit()}>
                <FormGroup className="select2-container">
                  <Row>
                    <Col md={12}>
                      <CustomLabel>Card Holder Name</CustomLabel>
                      <Input
                        name="userName"
                        placeholder="Card Holder Name"
                        value={formik.values.userName}
                        onChange={(e) => formik.setFieldValue("userName", e.target.value)}
                      />
                      {formik.touched.userName && formik.errors.userName
                        ? (
                        <small className="text-danger mt-1">{formik.errors.userName}</small>
                          )
                        : null}
                    </Col>
                    <Col md={6}>
                      <CustomLabel>Country</CustomLabel>
                      <Select
                        name="country"
                        value={formik.values?.country || ""}
                        onChange={(option) => {
                          formik.setFieldValue("country", option);
                          formik.setFieldValue("state", null);
                          formik.setFieldValue("city", null);
                        }}
                        options={Object.keys(regions).map((isoCode) => ({
                          label: regions[isoCode].name,
                          value: isoCode,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                      {formik.touched.country && formik.errors.country
                        ? (
                        <small className="text-danger mt-1">{formik.errors.country}</small>
                          )
                        : null}
                    </Col>
                    <Col md={6}>
                      <CustomLabel>State</CustomLabel>
                      <Select
                        name="state"
                        value={formik.values?.state || ""}
                        onChange={(option) => {
                          formik.setFieldValue("state", option);
                          formik.setFieldValue("city", null);
                        }}
                        options={getAllStates(formik?.values?.country.value).map(([isoCode, { name }]) => ({
                          label: name,
                          value: isoCode,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                      {formik.touched.state && formik.errors.state
                        ? (
                        <small className="text-danger mt-1">{formik.errors.state}</small>
                          )
                        : null}
                    </Col>
                    <Col md={6}>
                      <div className="mb-2">
                        <CustomLabel> City</CustomLabel>
                        <Input
                          name="city"
                          placeholder="City"
                          value={formik.values.city}
                          onChange={(e) => formik.setFieldValue("city", e.target.value)}
                        />
                        {formik.touched.city && formik.errors.city
                          ? (
                          <small className="text-danger mt-1">{formik.errors.city}</small>
                            )
                          : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-2">
                        <CustomLabel>Zip Code</CustomLabel>
                        <Input
                          name="zip"
                          placeholder="Zip Code"
                          value={formik.values.zip}
                          onChange={(e) => formik.setFieldValue("zip", e.target.value)}
                        />
                        {formik.touched.zip && formik.errors.zip
                          ? (
                          <small className="text-danger mt-1">{formik.errors.zip}</small>
                            )
                          : null}
                      </div>
                    </Col>
                  </Row>
                  <CardElement
                    className="card-element"
                    options={merge(style, { hidePostalCode: true })}
                    onChange={handleChange}
                  />
                  {error && <small className="text-danger">{error}</small>}
                  <div className="mt-3">
                    <button
                      type="submit"
                      disabled={loading || error || disabled}
                      className="btn btn-success btn-block waves-effect waves-light"
                    >
                      <span className="d-flex align-items-center justify-content-center">
                        Add Card{" "}
                        {loading
                          ? (
                          <Spinner color="primary" style={{ width: "20px", height: "20px" }} className="ml-3" />
                            )
                          : null}
                      </span>
                    </button>
                  </div>
                </FormGroup>
              </AvForm>
            </Col>
          </Card>
          <div className="p-4 row justify-content-between">
            {moment(account?.data?.trial_end) > moment() && !account?.data?.payment_source_added && (
              <button
                onClick={dismissError}
                className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-warning"
              >
                Dismiss for 24 Hours
              </button>
            )}
            {!account?.data?.payment_source_added && !account?.data?.is_agency && (
              <button
                onClick={() => props.history.push("/logout")}
                className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-danger"
              >
                Logout
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(AddCard);

AddCard.propTypes = {
  setActiveStep: PropTypes.func,
};

const styleDark = {
  style: {
    base: {
      iconColor: "#bfc8e2",
      color: "#bfc8e2",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#bfc8e2",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};

const styleLight = {
  style: {
    base: {
      iconColor: "#545a6d",
      color: "#495057",
      ":-webkit-autofill": {
        color: "#495057",
      },
      "::placeholder": {
        color: "#495057",
      },
    },
    invalid: {
      iconColor: "#eb1c26",
      color: "#eb1c26",
    },
  },
};
