import request from "../restClient";

const resource = "/platform_settings";

export const fetchPlatformSettings = async ({ params }) => {
  const url =
    resource +
    "?" +
    Object.keys(params)
      .map((key) =>
        Array.isArray(params[key]) ? params[key].map((val) => `${key}[]=${val}`).join("&") : `${key}=${params[key]} `,
      )
      .join("&");
  return await request({ url, method: "GET" });
};

export const updatePlatformSettings = async ({ data, id }) => {
  return await request({ url: `${resource}/${id}`, method: "PUT", body: data });
};
