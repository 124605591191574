import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Row, Button, Label, Col, FormGroup, Alert } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setEditPlanGroupModal, fetchPlanGroups, updateGroupAndPlan } from "../../../store/actions";
import Select from "react-select";
import { formatNumber } from "../../../utils/common";

const EditPlanGroupModal = (props) => {
  const { account } = props;
  const dispatch = useDispatch();
  const { grpLoading, groups, error, isPlanGroupModalOpen } = useSelector((state) => state.CustomPlans);

  const [wrongGroup, setWrongGroup] = useState();
  const [planGroup, setPlanGroup] = useState();
  const [plan, setPlan] = useState();
  const toggleModal = () => dispatch(setEditPlanGroupModal(!isPlanGroupModalOpen));
  const isAgency = !account.agency_id || account.is_agency;

  const initialValues = { plan_group_id: 0, current_plan: "" };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      if (!values.plan_group_id) errors.plan_group_id = "is required";
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const group = groups?.find((grp) => grp.id === account.plan_group_id);
      const curPlan = account?.stripe_subscription_id
        ? group.plans?.find((_plan) => _plan.stripe_price_id === account.plan_metadata?.id)
        : false;
      const row = {
        newGrpId: values.plan_group_id?.value,
        newPlanId: values.current_plan?.value || null,
        planId: curPlan?.id || null,
      };
      dispatch(updateGroupAndPlan(account.id, row));
    },
  });

  const planLabel = (plan) => {
    const { stripe_price: stripePrice } = plan;
    const amount = +(plan.amount / 100);
    const { data } = stripePrice || {};
    return `${formatNumber(amount)}${data?.listings ? " - " + data.listings + " listings" : ""}${
      stripePrice.title ? `(${stripePrice.title}/${stripePrice.recurring_interval})` : ""
    }`;
  };

  useEffect(() => {
    if (account.plan_group_id && !planGroup && Array.isArray(groups)) {
      const group = groups?.find((grp) => grp.id === account.plan_group_id);
      setPlanGroup({ value: group.id, label: group.name, plans: group.plans });
      const curPlan = account.plan_metadata?.id
        ? group.plans?.find((_plan) => _plan.stripe_price_id === account.plan_metadata?.id)
        : null;
      if (curPlan) setPlan({ label: planLabel(curPlan), value: curPlan.stripe_price_id, plan: curPlan });
      if (account.plan_metadata?.id && !curPlan) setWrongGroup(account.plan_metadata);
    }
    !Array.isArray(groups) && !grpLoading && dispatch(fetchPlanGroups());
  }, [groups]);

  useEffect(() => {
    if (planGroup) {
      formik.setFieldValue("plan_group_id", planGroup);
      if (!planGroup.plans) return;
      const curPlan =
        planGroup.plans?.find((_plan) => _plan.stripe_price_id === account.plan_metadata?.id) ||
        (account.plan_metadata?.id ? planGroup.plans[0] : null);
      if (curPlan) setPlan({ label: planLabel(curPlan), value: curPlan.stripe_price_id, plan: curPlan });
    }
  }, [planGroup]);

  useEffect(() => {
    if (plan) formik.setFieldValue("current_plan", plan);
  }, [plan]);

  return (
    <Modal size="md" isOpen={isPlanGroupModalOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h4>Edit Plan/Group</h4>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      {error && (
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2"></i>
          {error}
        </Alert>
      )}
      {wrongGroup && (
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2"></i>
          Current plan ({wrongGroup.nickname}) is not in this group.
        </Alert>
      )}
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            {Array.isArray(groups)
              ? (
              <>
                <Col md="12">
                  <Label className={formik.errors.plan_group_id ? "text-danger" : ""}>Group</Label>
                  {formik.errors.plan_group_id
                    ? (
                    <small className="text-danger m-1">{formik.errors.plan_group_id}</small>
                      )
                    : null}
                  <FormGroup className="select2-container">
                    <Select
                      name="plan_group_id"
                      value={planGroup || { label: "Select a group", value: "" }}
                      onChange={(option) => setPlanGroup(option)}
                      options={groups.map((x) => ({ label: x.name, value: x.id, plans: x.plans }))}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
                {!isAgency
                  ? (
                  <Col md="12">
                    <Label className={formik.errors.current_plan ? "text-danger" : ""}>Plan</Label>
                    {formik.errors.current_plan
                      ? (
                      <small className="text-danger m-1">{formik.errors.current_plan}</small>
                        )
                      : null}
                    <FormGroup className="select2-container">
                      <Select
                        name="current_plan"
                        value={plan || { label: "Select a plan", value: "" }}
                        onChange={(option) => setPlan(option)}
                        options={
                          planGroup?.plans?.map((x) => ({ label: planLabel(x), value: x.stripe_price_id, plan: x })) ||
                          []
                        }
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                    )
                  : null}
              </>
                )
              : null}
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Update Plan
            </Button>
          </Row>
        </form>
      </div>
    </Modal>
  );
};

export default EditPlanGroupModal;
