import React from "react";
import { useDispatch } from "react-redux";
import Modal from "../../components/Common/Modal";
import { getUserInfo } from "../../utils/common";
import { TERMS_OF_CONDITION_URL } from "../../constants";
import { Row, Button } from "reactstrap";

// actions
import { setTosAcceptanceModal, logoutUser, updateTos } from "../../store/actions";

const TosAcceptanceModal = (props) => {
  const dispatch = useDispatch();

  const toggleModal = () => {
    dispatch(setTosAcceptanceModal(props.open));
  };

  return (
    <Modal size="lg" isOpen={props.open} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {"Updated Terms of Conditions"}
        </h5>
      </div>
      <div className="modal-body">
        <iframe
          title="TOSFrame"
          src={TERMS_OF_CONDITION_URL}
          type="application/pdf"
          style={{
            overflow: "auto",
            width: "100%",
            height: "537px",
          }}
        />
      </div>
      <div className="modal-footer">
        <Row className="d-flex justify-content-between w-100">
          <Button onClick={() => dispatch(logoutUser(props.history))} color="primary" className="px-20" outline={true}>
            Decline
          </Button>
          <Button
            onClick={() => {
              dispatch(updateTos(getUserInfo()?.account_id));
              dispatch(setTosAcceptanceModal(false));
            }}
            className="px-20"
            color="primary"
          >
            Agree
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default TosAcceptanceModal;
