import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { capitalize, currentUser } from "../../../utils/common";
// actions
import { setOnboardingFlow, setOnboardingStep, setShowOnboarding } from "../../../store/actions";

const onboardingProgress = {
  confirm_email: {
    icon: "mdi mdi-24px mdi-email-check-outline",
    index: 1,
    name: "Confirm Email",
  },
  store_added: {
    icon: "mdi mdi-24px mdi-storefront-outline",
    index: 2,
    name: "Add marketplace",
  },
  payment: {
    icon: "mdi mdi-24px mdi-credit-card-plus-outline",
    index: 3,
    name: "Add valid payment method",
  },
  forwarder_setting_visited: {
    icon: "mdi mdi-24px mdi-email-send-outline",
    index: 6,
    name: "Setup email forwarding",
  },
  lister_visited: {
    icon: "mdi mdi-24px mdi-package-variant-closed",
    index: 7,
    name: "Add first products",
  },
};

const OnboardProgress = () => {
  const dispatch = useDispatch();
  const { account, loading } = useSelector((state) => state.Settings);
  return Object.keys(account?.data?.onboard || {}).some((x) => !account?.data?.onboard[x])
    ? (
    <>
      <Card>
        <CardBody>
          <div>
            Hi, <b>{capitalize(currentUser())}!</b> Please complete your setup process to fully enjoy Ecom
            Circle.
          </div>
          <div style={{ justifyContent: "space-evenly" }} className="d-flex flex-row align-items-center">
            {!loading &&
              account?.data?.onboard &&
              Object.keys(onboardingProgress).reduce((acc, key, index, indexes) => {
                acc.push([
                  <div
                    className="d-flex flex-column align-items-center cursor-pointer"
                    key={index + "_onbaord_prog"}
                    onClick={() => {
                      if (account.data.onboard[key]) return;
                      dispatch(setOnboardingFlow(true));
                      dispatch(setOnboardingStep(onboardingProgress[key].index));
                      dispatch(setShowOnboarding(true));
                    }}
                  >
                    <div className="d-flex flex-row align-items-baseline px-4">
                      <i
                        className={
                          onboardingProgress[key].icon + (account.data.onboard[key] ? " text-success" : " text-primary")
                        }
                      ></i>
                    </div>
                    <h6 className="py-1">{onboardingProgress[key].name}</h6>
                    {account.data.onboard[key]
                      ? (
                      <i className={"bx bx-sm bxs-check-circle py-1 text-success"}></i>
                        )
                      : (
                      <h6 className="py-1 text-primary"> Set Up</h6>
                        )}
                  </div>,
                  <div key={index + "_forward_arrow"}>
                    {indexes.length - 1 !== index && (
                      <i
                        className={"dripicons-arrow-thin-right" + (account.data.onboard[key] ? " text-success" : "")}
                        style={{ fontSize: "25px" }}
                      ></i>
                    )}
                  </div>,
                ]);
                return acc;
              }, [])}
          </div>
        </CardBody>
      </Card>
    </>
      )
    : null;
};

export default OnboardProgress;
