import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { switchTheme, changeSidebarTheme, changeTopbarTheme } from "../../store/actions";

const ThemeSwitch = () => {
  const dispatch = useDispatch();

  const [themeSwitch, setThemeSwitch] = useState(localStorage.getItem("THEME") === "light");
  const toggleTheme = (themeStatus) => {
    setThemeSwitch(themeStatus);
    const setTheme = themeStatus ? "light" : "dark";
    localStorage.setItem("THEME", setTheme);
    dispatch(switchTheme(setTheme));
    dispatch(changeSidebarTheme(setTheme));
    dispatch(changeTopbarTheme(setTheme === "light" ? "dark" : "light"));
  };
  return (
    <div className="ml-1 mt-2" dir="ltr">
      <label id="switch" className="switch">
        <input type="checkbox" checked={themeSwitch} onChange={() => toggleTheme(!themeSwitch)} id="slider" />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ThemeSwitch;
