import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import CustomTooltip from "../../components/Common/CustomTooltip";
import { Card, FormGroup, Row, Col, CardBody } from "reactstrap";
import SimpleSelect from "../../components/Common/SimpleSelect";
import { DatePicker } from "../../components/Common/DatePickr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment-timezone";
import Select from "react-select";
import { isEmpty, merge, isEqual, assign } from "lodash";

// Graphs
import LabelGenerateGraph from "./component/labelGeneratePerDay";
// Actions
import {
  fetchAllWarehouses,
  fetchWarehouseInsightInboundStats,
  fetchWarehouseInsightStorageStats,
  fetchWarehouseInsightOutboundStats,
  fetchWarehouseInsightLabelGeneratedGraph,
  fetchWarehouseInsightLabelCancelledGraph,
  fetchWarehouseAccounts,
  fetchWarehouseInsightChargesStats,
} from "../../store/actions";
import { RenderIf, formatNumber, getWarehouseName } from "../../utils/common";

const WarehouseInsights = () => {
  const dispatch = useDispatch();
  const { associatedWarehouses, wareHouseAccounts } = useSelector((state) => state.WareHouses);
  const { chargesStats, inboundStats, storageStats, outboundStats, labelGeneratedData, labelCancelledData } =
    useSelector((state) => state.Insights);
  // Select Data range button
  const dateOutputFormat = "ddd MMM D YYYY HH:mm:ss [GMT]ZZ (z)";
  const getStartDate = (num, time) => moment().subtract(num, time);
  const startTimeOptions = {
    lifetime: moment([1970]).startOf("year"),
    oneYear: getStartDate(1, "year"),
    sixMonths: getStartDate(6, "months"),
    threeMonths: getStartDate(3, "months"),
    thirtyDays: getStartDate(30, "days"),
    sevenDays: getStartDate(7, "days"),
  };
  const handleDurationChange = (selectedValue, index) => {
    let startDate, endDate;
    if (selectedValue in startTimeOptions) {
      startDate = startTimeOptions[selectedValue];
      endDate = moment();
      setDateCardIndex(index);
    }
    const dateRange = {
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      utc_offset: moment().format("Z"),
    };
    setInitialDatesForChangesStats([startDate._d, endDate._d]);
    setFilters(merge({}, filters, dateRange));
  };
  const getInitialDatesForLabelGraph = [startTimeOptions.sevenDays._d, moment()._d];
  const getInitialDatesForLabelGraphStats = {
    start_date: startTimeOptions.sevenDays.format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    utc_offset: moment().format("Z"),
  };

  const [dateCardIndex, setDateCardIndex] = useState(5);
  const [filters, setFilters] = useState({});
  const initialWarehouseFilter = { label: "All Warehouses", value: "" };
  const [wareHouseFilter, setWareHouseFilter] = useState(initialWarehouseFilter);
  const [wareHouseChargesFilter, setWareHouseChargesFilter] = useState(initialWarehouseFilter);
  const [wareHouseInboundFilter, setWareHouseInboundFilter] = useState(initialWarehouseFilter);
  const [wareHouseStorageFilter, setWareHouseStorageFilter] = useState(initialWarehouseFilter);
  const [wareHouseOutboundFilter, setWareHouseOutboundFilter] = useState(initialWarehouseFilter);
  const [wareHouseGeneratedLabelFilter, setWareHouseGeneratedLabelFilter] = useState(getInitialDatesForLabelGraphStats);
  const [wareHouseCancelledLabelFilter, setWareHouseCancelledLabelFilter] = useState({
    ...getInitialDatesForLabelGraphStats,
    ...{ status: "cancelled" },
  });
  const [initialDatesForChangesStats, setInitialDatesForChangesStats] = useState(getInitialDatesForLabelGraph);
  useEffect(() => {
    handleDurationChange("sevenDays", 5);
    dispatch(fetchWarehouseInsightLabelGeneratedGraph(wareHouseGeneratedLabelFilter));
    dispatch(fetchWarehouseInsightLabelCancelledGraph(wareHouseCancelledLabelFilter));
    dispatch(fetchAllWarehouses({ associated: true }));
    dispatch(fetchWarehouseAccounts());
  }, []);
  useEffect(() => {
    if (!isEmpty(filters)) {
      dispatch(fetchWarehouseInsightChargesStats(assign({}, filters, { warehouse_id: wareHouseChargesFilter.value })));
      dispatch(fetchWarehouseInsightInboundStats(assign({}, filters, { warehouse_id: wareHouseInboundFilter.value })));
      dispatch(fetchWarehouseInsightStorageStats(assign({}, filters, { warehouse_id: wareHouseStorageFilter.value })));
      dispatch(
        fetchWarehouseInsightOutboundStats(assign({}, filters, { warehouse_id: wareHouseOutboundFilter.value })),
      );
    }
  }, [filters]);

  const handleWarehouseFilter = (data, section) => {
    if (isEqual(section, "inbound")) {
      dispatch(fetchWarehouseInsightInboundStats(assign({}, filters, { warehouse_id: data })));
    } else if (isEqual(section, "storage")) {
      dispatch(fetchWarehouseInsightStorageStats(assign({}, filters, { warehouse_id: data })));
    } else if (isEqual(section, "outbound")) {
      dispatch(fetchWarehouseInsightOutboundStats(assign({}, filters, { warehouse_id: data })));
    } else if (isEqual(section, "charges")) {
      dispatch(fetchWarehouseInsightChargesStats(assign({}, filters, wareHouseChargesFilter, { warehouse_id: data })));
    } else if (isEqual(section, "chargesDate")) {
      setWareHouseChargesFilter(assign({}, wareHouseChargesFilter, data));
      dispatch(fetchWarehouseInsightChargesStats(assign({}, wareHouseChargesFilter, data)));
    } else if (isEqual(section, "generatedLabelId")) {
      setWareHouseGeneratedLabelFilter(assign({}, wareHouseGeneratedLabelFilter, { account_id: data }));
      dispatch(
        fetchWarehouseInsightLabelGeneratedGraph(assign({}, wareHouseGeneratedLabelFilter, { account_id: data })),
      );
    } else if (isEqual(section, "generatedLabelDate")) {
      setWareHouseGeneratedLabelFilter(assign({}, wareHouseGeneratedLabelFilter, data));
      dispatch(fetchWarehouseInsightLabelGeneratedGraph(assign({}, wareHouseGeneratedLabelFilter, data)));
    } else if (isEqual(section, "cancelledLabelId")) {
      setWareHouseCancelledLabelFilter(assign({}, wareHouseCancelledLabelFilter, { account_id: data }));
      dispatch(
        fetchWarehouseInsightLabelCancelledGraph(assign({}, wareHouseCancelledLabelFilter, { account_id: data })),
      );
    } else if (isEqual(section, "cancelledLabelDate")) {
      setWareHouseCancelledLabelFilter(assign({}, wareHouseCancelledLabelFilter, data));
      dispatch(fetchWarehouseInsightLabelCancelledGraph(assign({}, wareHouseCancelledLabelFilter, data)));
    }
  };

  const getNum = (num) => (num ? (+num).toLocaleString() : 0);

  const labelGenerate = [
    {
      name: "Labels Generated",
      data: labelGeneratedData?.data?.map((x) => x?.count) || [],
      date: labelGeneratedData?.data?.map((x) => moment(x?.date).format("MMM-DD-YY")) || [],
      curve: "smooth",
    },
  ];
  const labelCancelled = [
    {
      name: "Labels Cancelled",
      data: labelCancelledData?.data?.map((x) => x?.count) || [],
      date: labelCancelledData?.data?.map((x) => moment(x?.date).format("MMM-DD-YY")) || [],
      curve: "smooth",
    },
  ];

  const inSightsDuration = [
    { title: "Lifetime", value: "lifetime" },
    { title: "1 Year", value: "oneYear" },
    { title: "6 months", value: "sixMonths" },
    { title: "3 months", value: "threeMonths" },
    { title: "30 Days", value: "thirtyDays" },
    { title: "7 Days", value: "sevenDays" },
  ];

  const inboundInventory = [
    { title: "POs Orders", value: getNum(inboundStats?.data?.inbound_pos), icon: "package" },
    { title: "2-Step", value: getNum(inboundStats?.data?.inbound_tracking_items), icon: "package" },
    { title: "Returns", value: getNum(inboundStats?.data?.inbound_returns), icon: "subdirectory-left" },
    { title: "Other", value: "", icon: "package" },
  ];

  const unitStorage = [
    { title: "< 1 Week", value: getNum(storageStats?.data?.lessThanWeek), icon: "calendar" },
    { title: "7-30 days", value: getNum(storageStats?.data?.sevenToFourteen), icon: "calendar" },
    { title: "> 30 days", value: getNum(storageStats?.data?.graterThanFourteen), icon: "calendar" },
    { title: "Storage Exempted Items", value: getNum(storageStats?.data?.storageExempted), icon: "cart" },
  ];

  const outBoundItems = [
    { title: "Total Orders to Ship", value: getNum(outboundStats?.data?.totalOrderToShip), icon: "cart" },
    { title: "Overdue Orders", value: getNum(outboundStats?.data?.overdueOrders), icon: "alarm" },
    { title: "Orders to Ship Today", value: getNum(outboundStats?.data?.orderToShipToday), icon: "calendar" },
    { title: "Orders to Ship by Tomorrow ", value: getNum(outboundStats?.data?.orderToShipTomorrow), icon: "calendar" },
    { title: "Returns to Ship ", value: getNum(outboundStats?.data?.returnsToShip), icon: "truck" },
    { title: "Inventory Fulfilled Orders ", value: getNum(outboundStats?.data?.inventoryToShip), icon: "task" },
  ];

  const titleToColorClass = {
    "< 1 Week": "color-primary",
    "7-30 days": "color-warning",
    "> 30 days": "color-danger",
  };
  const outboundColor = {
    "Overdue Orders": "color-danger",
    "Orders to Ship Today": "color-warning",
    "Orders to Ship by Tomorrow ": "color-primary",
  };

  const calculateSumCount = (data) => data?.reduce((total, item) => total + item.count, 0) || 0;
  const calculateRatesSum = (data) => data?.reduce((acc, cur) => acc + cur.rate, 0)?.toFixed(2);
  const checkCustomWarehouseFilter = () => {
    const filtersArr = [
      wareHouseChargesFilter,
      wareHouseInboundFilter,
      wareHouseStorageFilter,
      wareHouseOutboundFilter,
    ];
    const firstValue = filtersArr[0].value;
    return filtersArr.every((item) => item.value === firstValue) ? wareHouseFilter.label : "Custom Selected";
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Breadcrumbs title={"Warehouse Insights"} />
        <div className="d-flex gap-3 flex-wrap">
          {inSightsDuration.map((duration, index) => (
            <Card
              key={index}
              onClick={() => handleDurationChange(duration.value, index)}
              className={`p-2 cursor-pointer ${dateCardIndex === index ? "transparent-card" : ""}`}
            >
              {" "}
              {duration.title}{" "}
            </Card>
          ))}
          <div className="d-flex align-items-center inbound-date-range">
            <DatePicker
              icon
              style={{ width: "230px", border: "none" }}
              placeholder="Select Date Range"
              className="mx-1 my-2"
              pickrClass="pl-2"
              maxDate="today"
              onClose={(dates) => {
                setDateCardIndex(-1);
                setFilters({ ...filters, ...dates, page: 1 });

                setInitialDatesForChangesStats([
                  moment(dates.start_date).format(dateOutputFormat),
                  moment(dates.end_date).format(dateOutputFormat),
                ]);
              }}
              handleDurationChange={handleDurationChange}
            />
            <i className="bx font-size-16 bx-calendar text-primary mx-3" />
          </div>
          <FormGroup className="select2-container ml-2" style={{ width: "180px" }}>
            <Select
              name="warehouse_id"
              placeholder="Select Warehouse"
              value={{
                label: checkCustomWarehouseFilter(),
                value: wareHouseFilter.value,
              }}
              onChange={(e) => {
                const { label, value } = e;
                if (e.value !== filters.warehouse_id) {
                  setWareHouseFilter({ label, value });
                  setWareHouseChargesFilter({ label, value });
                  setWareHouseInboundFilter({ label, value });
                  setWareHouseStorageFilter({ label, value });
                  setWareHouseOutboundFilter({ label, value });
                  setFilters({ ...filters, warehouse_id: value });
                }
              }}
              options={[
                { value: "", label: "All Warehouses" },
                ...(associatedWarehouses?.data.map((y) => ({
                  label: getWarehouseName(y),
                  value: y.id,
                })) || []),
              ]}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </div>
      </div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h5>Warehouse Charges</h5>
            <div className="d-flex gap-2">
              <div className="d-flex align-items-center inbound-date-range">
                <DatePicker
                  icon
                  style={{ width: "230px", border: "none" }}
                  placeholder="Select Date Range"
                  className="mx-1 my-2"
                  pickrClass="pl-2"
                  updateInitialDates={true}
                  maxDate={moment().add(1, "days").format(dateOutputFormat)}
                  onClose={(date) => handleWarehouseFilter(date, "chargesDate")}
                  initialDates={initialDatesForChangesStats}
                  hideCrossIcon={true}
                />
                <i className="bx font-size-16 bx-calendar text-primary mx-3" />
              </div>
              <FormGroup className="select2-container ml-2" style={{ width: "159px" }}>
                <Select
                  name="warehouse_id"
                  placeholder="Select Warehouse"
                  value={{
                    value: wareHouseChargesFilter.value,
                    label: wareHouseChargesFilter.label,
                  }}
                  onChange={(e) => {
                    setWareHouseChargesFilter({ label: e.label, value: e.value });
                    handleWarehouseFilter(e.value, "charges");
                  }}
                  options={[
                    { value: "", label: "All Warehouses" },
                    ...(associatedWarehouses?.data.map((y) => ({
                      label: getWarehouseName(y),
                      value: y.id,
                    })) || []),
                  ]}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </div>
          </div>
          <Row>
            <Col md={4}>
              <Card className="rounded mb-4 card-layout">
                <CardBody style={{ minHeight: "110px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col">
                      <span className="card-text">Warehouse Profit</span>
                      <h5 className="pt-2 color-primary">{formatNumber(chargesStats?.data?.profit)}</h5>
                    </div>
                    <div className="primary-box">
                      <i className="bx bx-cart" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="rounded mb-4 card-layout">
                <CardBody style={{ minHeight: "110px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col">
                      <span className="card-text">Total Failed Charges</span>
                      <div className="d-flex mt-2 gap-16 position-relative">
                        <div className="d-flex flex-column divider-right ">
                          <span className="font-size-10">Number</span>
                          <h5 className="pt-2 color-danger mb-0">
                            {getNum(chargesStats?.data?.failed_charges?.number)}
                          </h5>
                        </div>
                        <div className="d-flex flex-column ">
                          <span className="font-size-10">Value</span>
                          <h5 className="pt-2 color-danger mb-0">
                            {formatNumber(chargesStats?.data?.failed_charges?.value)}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="primary-box">
                      <i className="bx bx-info-circle" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="rounded mb-4 card-layout">
                <CardBody style={{ minHeight: "110px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col ">
                      <CustomTooltip
                        placement="top"
                        minWidth="255px"
                        target={"profit"}
                        content={
                          <div className="text-left">
                            <h4 className="mb-3">Actual Profit</h4>
                            <div className="d-flex justify-content-between">
                              <span> Profit </span>
                              <span className="color-primary">{formatNumber(chargesStats?.data?.profit, "float")}</span>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                              <span>Charges: </span>
                              <span className="color-danger">{formatNumber(chargesStats?.data?.charge, "float")}</span>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                              <span className="font-size-12">Total Profit:</span>
                              <span className="color-primary">
                                {formatNumber((chargesStats?.data?.profit || 0) - (chargesStats?.data?.charge || 0), "float")}
                              </span>
                            </div>
                          </div>
                        }
                      />
                      <span className="card-text">
                        Actual Profit <i className="bx bx-info-circle color-purple cursor-pointer" id="profit" />
                      </span>
                      <h5 className="pt-2 color-primary">{formatNumber((chargesStats?.data?.profit || 0) - (chargesStats?.data?.charge || 0))}</h5>
                    </div>
                    <div className="primary-box">
                      <i className="bx bx-dollar" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h5>Inbound Inventory</h5>
            <FormGroup className="select2-container ml-2" style={{ width: "159px" }}>
              <Select
                name="warehouse_id"
                placeholder="Select Warehouse"
                value={{
                  value: wareHouseInboundFilter.value,
                  label: wareHouseInboundFilter.label,
                }}
                onChange={(e) => {
                  setWareHouseInboundFilter({ label: e.label, value: e.value });
                  handleWarehouseFilter(e.value, "inbound");
                }}
                options={[
                  { value: "", label: "All Warehouses" },
                  ...(associatedWarehouses?.data.map((y) => ({
                    label: getWarehouseName(y),
                    value: y.id,
                  })) || []),
                ]}
                classNamePrefix="select2-selection"
              />
            </FormGroup>
          </div>
          <Row>
            {inboundInventory.map((inbound, index) => (
              <Col md={3} key={index}>
                <Card className="rounded mb-4 card-layout ">
                  <CardBody style={{ minHeight: "100px" }}>
                    <div className="d-flex justify-content-between align-items-center h-100">
                      <div className="col">
                        <span className="card-text">{inbound.title}</span>
                        <h5 className="pt-2 color-primary">
                          {inbound.value === "" ? <div className="coming-soon">Coming Soon</div> : inbound.value}
                        </h5>
                      </div>
                      <div className="primary-box">
                        <i className={`bx bx-${inbound.icon}`} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h5>Units in Storage</h5>
              <CustomTooltip
                placement="top"
                target={"unit-storage"}
                content={"Units incur storage fees after 14 days. Take action on these items to avoid storage charges."}
              />
              <i className="bx bx-info-circle ml-2 pt-1  color-purple cursor-pointer" id="unit-storage" />
            </div>
            <FormGroup className="select2-container ml-2" style={{ width: "159px" }}>
              <Select
                name="warehouse_id"
                placeholder="Select Warehouse"
                value={{
                  value: wareHouseStorageFilter.value,
                  label: wareHouseStorageFilter.label,
                }}
                onChange={(e) => {
                  setWareHouseStorageFilter({ label: e.label, value: e.value });
                  handleWarehouseFilter(e.value, "storage");
                }}
                options={[
                  { value: "", label: "All Warehouses" },
                  ...(associatedWarehouses?.data.map((y) => ({
                    label: getWarehouseName(y),
                    value: y.id,
                  })) || []),
                ]}
                classNamePrefix="select2-selection"
              />
            </FormGroup>
          </div>
          <Row>
            {unitStorage.map((inbound, index) => (
              <Col md={3} key={index}>
                <Card className="rounded mb-4 card-layout ">
                  <CardBody style={{ minHeight: "100px" }}>
                    <div className="d-flex justify-content-between align-items-center h-100">
                      <div className="col">
                        <span className="card-text">{inbound.title}</span>
                        <h5 className={`pt-2 ${titleToColorClass[inbound.title || ""]} `}>{inbound.value}</h5>
                      </div>
                      <div className="primary-box">
                        <i className={`bx bx-${inbound.icon}`} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h5>Outbound Items</h5>
            </div>
            <FormGroup className="select2-container ml-2" style={{ width: "159px" }}>
              <Select
                name="warehouse_id"
                placeholder="Select Warehouse"
                value={{
                  value: wareHouseOutboundFilter.value,
                  label: wareHouseOutboundFilter.label,
                }}
                onChange={(e) => {
                  setWareHouseOutboundFilter({ label: e.label, value: e.value });
                  handleWarehouseFilter(e.value, "outbound");
                }}
                options={[
                  { value: "", label: "All Warehouses" },
                  ...(associatedWarehouses?.data.map((y) => ({
                    label: getWarehouseName(y),
                    value: y.id,
                  })) || []),
                ]}
                classNamePrefix="select2-selection"
              />
            </FormGroup>
          </div>
          <Row>
            {outBoundItems.map((inbound, index) => (
              <Col md={4} key={index}>
                <Card className="rounded mb-4 card-layout ">
                  <CardBody style={{ minHeight: "100px" }}>
                    <div className="d-flex justify-content-between align-items-center h-100">
                      <div className="col">
                        <span className="card-text">{inbound.title}</span>
                        <h5 className={`pt-2 ${outboundColor[inbound.title || ""]} `}>{inbound.value}</h5>
                      </div>
                      <div className="primary-box">
                        <i
                          className={`bx ${inbound.icon === "truck" ? `bxs-${inbound.icon}` : `bx-${inbound.icon}`}`}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      {/* </Row> */}
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <h4 className="card-title">
                    Labels Generated:{" "}
                    <span className="color-primary ">{getNum(calculateSumCount(labelGeneratedData?.data))}</span>
                  </h4>
                  <h4 className="card-title">
                    Value <span className="color-primary ">{formatNumber(calculateRatesSum(labelGeneratedData?.data))}</span>
                  </h4>
                </div>
                <FormGroup className="select2-container ml-2" style={{ width: "258px" }}>
                  <RenderIf isTrue={wareHouseAccounts?.length}>
                    <div style={{ marginRight: "100px" }}>
                      <SimpleSelect
                        name="account_id"
                        placeholder="All Accounts"
                        className="col mb-0"
                        onChange={(acc) => handleWarehouseFilter(acc.value, "generatedLabelId")}
                        options={[{ value: "", label: "All Accounts" }].concat(
                          wareHouseAccounts?.map((x) => ({ value: x.id, label: `${x.id}: ${x.email}` })),
                        )}
                        formStyle={{ minWidth: "270px", marginBottom: 0 }}
                      />
                    </div>
                  </RenderIf>
                </FormGroup>
              </div>
              <div className="d-flex justify-content-end">
                <div className="d-flex align-items-center inbound-date-range">
                  <DatePicker
                    icon
                    style={{ width: "230px", border: "none" }}
                    placeholder="Select Date Range"
                    className="mx-1 my-2"
                    pickrClass="pl-2"
                    maxDate={moment().add(1, "days").format(dateOutputFormat)}
                    onClose={(date) => handleWarehouseFilter(date, "generatedLabelDate")}
                    initialDates={getInitialDatesForLabelGraph}
                    hideCrossIcon={true}
                  />
                  <i className="bx font-size-16 bx-calendar text-primary mx-3" />
                </div>
              </div>
              <LabelGenerateGraph series={labelGenerate} color="#34C38F" />
            </CardBody>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <h4 className="card-title">
                    Labels Cancelled:{" "}
                    <span className="color-danger ">{getNum(calculateSumCount(labelCancelledData?.data))}</span>
                  </h4>
                  <h4 className="card-title">
                    Value <span className="color-danger ">{formatNumber(calculateRatesSum(labelCancelledData?.data))}</span>
                  </h4>
                </div>
                <FormGroup className="select2-container ml-2" style={{ width: "258px" }}>
                  <RenderIf isTrue={wareHouseAccounts?.length}>
                    <div style={{ marginRight: "100px" }}>
                      <SimpleSelect
                        name="account_id"
                        placeholder="All Accounts"
                        className="col mb-0"
                        onChange={(acc) => handleWarehouseFilter(acc.value, "cancelledLabelId")}
                        options={[{ value: "", label: "All Accounts" }].concat(
                          wareHouseAccounts?.map((x) => ({ value: x.id, label: `${x.id}: ${x.email}` })),
                        )}
                        formStyle={{ minWidth: "270px", marginBottom: 0 }}
                      />
                    </div>
                  </RenderIf>
                </FormGroup>
              </div>
              <div className="d-flex justify-content-end">
                <div className="d-flex align-items-center inbound-date-range">
                  <DatePicker
                    icon
                    style={{ width: "230px", border: "none" }}
                    placeholder="Select Date Range"
                    className="mx-1 my-2"
                    pickrClass="pl-2"
                    maxDate={moment().add(1, "days").format(dateOutputFormat)}
                    onClose={(date) => handleWarehouseFilter(date, "cancelledLabelDate")}
                    initialDates={getInitialDatesForLabelGraph}
                    hideCrossIcon={true}
                  />
                  <i className="bx font-size-16 bx-calendar text-primary mx-3" />
                </div>
              </div>
              <LabelGenerateGraph series={labelCancelled} color="#F46A6A" />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WarehouseInsights;
