import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import React, { useState, useEffect } from "react";

export default function FiltersDrawer ({ isOpen, toggleDrawer, children }) {
  const animationDuration = 300;
  const [renderChildren, setRenderChildren] = useState(false);

  useEffect(() => {
    let timer;

    if (!isOpen) {
      timer = setTimeout(() => {
        setRenderChildren(isOpen);
      }, animationDuration);
    } else {
      setRenderChildren(isOpen);
    }

    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <Drawer
      direction="right"
      open={isOpen}
      onClose={toggleDrawer}
      duration={animationDuration}
      zIndex={1005}
      className="d-flex flex-column justify-content-start align-items-center filters-width filters-background card-text"
      lockBackgroundScroll={true}
    >
      {renderChildren && children}
    </Drawer>
  );
}
