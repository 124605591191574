import React from "react";
import { Container, Row, Col } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import logoLight from "../../assets/svg/logo.svg";
import logoDark from "../../assets/svg/logoDark.svg";
import BookCall from "../Common/BookCall";
import { verifyRole, RenderIf } from "../../utils/common";
import { PRIVACY_URL, TERMS_OF_CONDITION_URL } from "../../constants";
import DownloadPDF from "../../pages/Dashboard/components/PandLPdf";

const Footer = (props) => {
  const isReadOnly = verifyRole("readOnly");
  const logo = props.theme === "light" ? logoLight : logoDark;
  return (
    <React.Fragment>
      <footer className="footer" style={isReadOnly ? { left: 0 } : {}}>
        <Container fluid={true}>
          <Row>
            <Col md={7}>
              <BookCall />
            </Col>
            <Col md={5} className="d-flex justify-content-end align-items-center grid-column-gap-15">
              <div>
                {new Date().getFullYear()} © <img src={logo} alt="Ecom Circles" height="20px" />
              </div>
              <span className="dot"></span>
              <div>
                <a target="_blank" rel="noopener noreferrer" href={TERMS_OF_CONDITION_URL}>
                  Terms of Condition{" "}
                </a>
                and
                <a target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
                  {" "}
                  Privacy Policy{" "}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <RenderIf isTrue={!!props.pAndLData?.marketplaceFee}>
        <DownloadPDF style={{ display: props.pdfOpts?.pdfDivStyle }} clientsStats={{ data: props.pAndLData || {} }} />
      </RenderIf>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { theme: state.Layout.appTheme };
};

export default withRouter(connect(mapStatetoProps, {})(Footer));
