import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, FormGroup, Container, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomPagination from "../../components/Common/CustomPagination";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import PillBtn from "../../components/Common/PillBtn";
import "react-datepicker/dist/react-datepicker.css";
import { downloadFile, getEstDate } from "../../utils/common";
import moment from "moment-timezone";
import { fetchLeastSellingItems, setPreloader } from "../../store/actions";
import getBrandImageSrc from "../../utils/brandImages";
import { DATE_FORMATS } from "../../constants";
import { DatePicker } from "../../components/Common/DatePickr";
const { Option } = components;

const StoreOption = (props) => (
  <Option {...props}>
    {getBrandImageSrc(props.data.marketplace)
      ? (
      <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
        )
      : null}
    {props.data.label}
  </Option>
);
const LeastSellingItems = (props) => {
  const dispatch = useDispatch();
  const userId = props.userId;
  let { leastSellingItems, leastSkusCategories, leastSellingItemsPricing, paginationData, loading } = useSelector(
    (state) => state.Graph,
  );
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const dates = [moment().subtract(1, "months"), moment()];
  const [search, setSearch] = useState("");
  const [accountId, setAccountId] = useState("");
  const [marketPlace, setMarketPlace] = useState({
    value: "",
    label: "All Accounts",
  });
  const [tabsFilter, setTabFilter] = useState({
    page: 1,
    per_page: 100,
    sort_by: "sales",
    sort_direction: "asc",
    start_date: moment().subtract(1, "months").format(DATE_FORMATS.BACKEND),
    end_date: moment().format(DATE_FORMATS.BACKEND),
    utc_offset: moment().format("Z"),
  });

  const fetchDetails = () =>
    dispatch(fetchLeastSellingItems({ ...tabsFilter, ...(accountId && { marketplace_id: accountId }) }));

  const colMapping = {
    Sales: "sales",
    "Profit/Avg": "avg_profit",
    Profit: "profit",
    Orders: "count",
  };

  const tableHeaders = [
    { title: "Item" },
    { title: "Store" },
    { title: "Price" },
    {
      title: "Sales",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "sales",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 0,
        }),
    },
    {
      title: "Profit/Avg",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "avg_profit",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 1,
        }),
    },
    {
      title: "Profit",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "profit",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 2,
        }),
    },
    {
      title: "Orders",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "count",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 3,
        }),
    },
  ];

  useEffect(() => {
    fetchDetails();
  }, [tabsFilter, accountId]);

  useEffect(() => {
    if (userId) setTabFilter({ ...tabsFilter, user_id: userId });
    else tabsFilter.user_id && delete tabsFilter.user_id && setTabFilter({ ...tabsFilter });
  }, [userId]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  const modifiedDates = dates.map((x) => getEstDate(x).toDate());

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="ml-1">
            <Breadcrumbs
              title="Least Selling Items"
              children={
                <FormGroup className="select2-container ml-2" style={{ width: "200px" }}>
                  <Select
                    name="marketplace_id"
                    value={{
                      value: marketPlace.value,
                      label: marketPlace.label,
                    }}
                    onChange={(e) => {
                      setAccountId(e.value);
                      setMarketPlace({ value: e.value, label: e.label });
                    }}
                    options={accounts?.data.reduce(
                      (init, acc, i) => {
                        init.push({
                          label: acc.name || "",
                          value: acc.id ? acc.id : acc.value,
                          marketplace: acc.marketplace,
                          key: i,
                        });
                        return init;
                      },
                      [{ value: "", label: "All Accounts" }],
                    )}
                    classNamePrefix="select2-selection"
                    components={{ Option: StoreOption }}
                  />
                </FormGroup>
              }
            />
          </div>
          <Card>
            <CardHeader>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-row search-customize">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!search.trim()) return;
                      setTabFilter({ ...tabsFilter, search: search.trim() });
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setSearch("");
                          setTabFilter({ ...tabsFilter, search: "" });
                        } else {
                          setSearch(e.target.value);
                        }
                      }}
                    />
                  </form>
                </div>
                <div className="d-flex flex-row align-items-center ">
                  <FormGroup className="mx-2 mt-3">
                    <DatePicker
                      initialDates={modifiedDates}
                      onCross={modifiedDates}
                      onClose={(dates) => setTabFilter({ ...tabsFilter, ...dates })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <PillBtn
                      color="primary"
                      title="Download Least Selling"
                      name="Download Least Selling"
                      className="btn-least-selling"
                      icon="bx bx-xs bx-cloud-download ml-1"
                      onClick={() => {
                        const allData = leastSellingItems?.map((curr) => ({
                          "Product sku": curr.sku,
                          Price: curr.cost,
                          Sale: curr.sales,
                          "Average price": curr.avg_profit,
                          Profit: curr.profit,
                          Orders: curr.count,
                        }));
                        downloadFile(allData, "_least_selling_items");
                      }}
                    />
                  </FormGroup>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {Array.isArray(leastSellingItems)
                ? (
                <>
                  <div className="table-responsive" style={{ maxHeight: "400px" }}>
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <TableHeader />
                        </tr>
                      </thead>
                      <tbody>
                        <TableData />
                      </tbody>
                    </table>
                  </div>
                  <CustomPagination
                    page={paginationData?.page}
                    perPage={paginationData?.per_page}
                    total={paginationData?.total}
                    tabsFilter={tabsFilter}
                    setTabFilter={setTabFilter}
                  />
                </>
                  )
                : null}
              {leastSellingItems.length === 0
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
  function TableHeader () {
    return tableHeaders.map((header, index) => (
      <th key={`table-header ${index}`} onClick={header.onClick}>
        {header.title}
        {header.sort
          ? (
          <div className="d-inline">
            <i
              id="up-filter"
              style={{ fontSize: "10px" }}
              className={`dripicons-arrow-thin-up cursor-pointer ${
                tabsFilter.sort_by !== colMapping[header.title]
                  ? ""
                  : tabsFilter.sort_direction === "asc"
                  ? ""
                  : "d-none"
              }`}
            ></i>
            <i
              id="down-filter"
              style={{ fontSize: "10px" }}
              className={`dripicons-arrow-thin-down cursor-pointer ${
                tabsFilter.sort_by !== colMapping[header.title]
                  ? ""
                  : tabsFilter.sort_direction === "desc"
                  ? ""
                  : "d-none"
              }`}
            ></i>
          </div>
            )
          : (
              ""
            )}
      </th>
    ));
  }
  function findStoreName (id) {
    return accounts?.data?.find((e) => e.id === id);
  }
  function TableData () {
    leastSellingItems = leastSellingItems.reduce((acc, item) => {
      const current = leastSellingItemsPricing?.find((element) => element.sku === item.sku);
      if (current?.last_submitted_price) {
        item.current_pricing = current.last_submitted_price;
        acc.push(item);
      }
      return acc;
    }, []);

    return leastSellingItems?.map((item, key) =>
      leastSkusCategories[item.sku]
        ? (
        <tr key={"_least_selling_item_" + key} className="mt-3 mb-3">
          <td>
            <Link
              to={`/listings/${item.sku}/suppliers?platform=${item.store_name}&storeId=${
                leastSkusCategories[item.sku]?.marketplace_account_id
              }`}
              target="_blank"
              className="text-primary mb-1"
            >
              {item.sku}
            </Link>
          </td>
          <td>
            <>
              <img src={getBrandImageSrc(item.store_name)} alt="store logo" />
              <>{findStoreName(leastSkusCategories[item.sku]?.marketplace_account_id)?.name}</>
            </>
          </td>
          <td>${item?.current_pricing?.toFixed(2)}</td>
          <td>${item?.sales?.toFixed(2)}</td>
          <td>${item?.avg_profit?.toFixed(2)}</td>
          <td>${item?.profit?.toFixed(2)}</td>
          <td>{item?.count}</td>
        </tr>
          )
        : null,
    );
  }
};
export default LeastSellingItems;
