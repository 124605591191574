import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getAllCreditLogs, consumeCredit, deleteCreditLog } from "../../api";
// Login Redux States
import { FETCH_CREDIT_LOG, CONSUME_CREDIT, DELETE_CREDIT_LOG } from "./actionTypes";
import {
  apiError,
  fetchCreditLogSuccess,
  consumeCreditSuccess,
  deleteCreditLogSuccess,
  fetchCreditLogs,
} from "./actions";

function * fetchCreditLogsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllCreditLogs, params);
    yield put(fetchCreditLogSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

function * consumeCreditSaga ({ payload: { data } }) {
  try {
    const res = yield call(consumeCredit, data);
    yield put(consumeCreditSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

function * deleteCreditLogSaga ({ payload: { id } }) {
  try {
    const res = yield call(deleteCreditLog, id);
    yield put(deleteCreditLogSuccess(res));
    yield put(fetchCreditLogs());
  } catch (error) {
    yield put(apiError(error));
  }
}

export function * watchCreditLogsFetch () {
  yield takeEvery(FETCH_CREDIT_LOG, fetchCreditLogsSaga);
}

export function * watchConsumeCredit () {
  yield takeEvery(CONSUME_CREDIT, consumeCreditSaga);
}

export function * watchDeleteCreditLog () {
  yield takeEvery(DELETE_CREDIT_LOG, deleteCreditLogSaga);
}

function * CreditLogsSaga () {
  yield all([fork(watchCreditLogsFetch), fork(watchConsumeCredit), fork(watchDeleteCreditLog)]);
}

export default CreditLogsSaga;
