import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Row, Col, Label, Input } from "reactstrap";
import CheckBox from "../../../components/Common/CheckBox";
import Modal from "../../../components/Common/Modal";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment-timezone";
// utils

// actions
import { updateAccountSettings, setEditTenantAccModal } from "../../../store/actions";
import { ACCOUNT_STATUSES } from "../../../constants";

const UserModal = () => {
  const dispatch = useDispatch();
  const { success, error, loading, selectedTenantAcc, isEditTenantModalOpen } = useSelector((state) => state.Settings);

  const currentEndDate = selectedTenantAcc?.last_sub_end
    ? moment(selectedTenantAcc.last_sub_end).format("YYYY-MM-DD")
    : undefined;
  const [isActive, setActive] = useState(selectedTenantAcc.status);
  const [subExtend, setSubExtend] = useState({ show: false, date: currentEndDate });
  const [isFreeCredits, setIsFreeCredits] = useState(selectedTenantAcc.is_credit_promotion);
  const initialValues = {
    ao_credits: selectedTenantAcc.ao_credits,
    bce_credits: selectedTenantAcc.bce_credits,
    trial_end: moment(selectedTenantAcc.trial_end).format("YYYY-MM-DD"),
    status: selectedTenantAcc.status,
    plan_group: selectedTenantAcc.plan_group,
  };
  function handleUpdate (data) {
    if (subExtend.date && currentEndDate !== subExtend.date) data.sub_extend_date = subExtend.date;
    if (typeof isFreeCredits === "boolean") data.is_credit_promotion = isFreeCredits;
    dispatch(updateAccountSettings(data, selectedTenantAcc.id));
  }

  useEffect(() => {
    success && !loading && toggleModal();
  }, [success, loading]);

  const toggleModal = () => {
    dispatch(setEditTenantAccModal(!isEditTenantModalOpen));
  };

  return (
    <Modal size="md" isOpen={isEditTenantModalOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">Update Tenant {selectedTenantAcc.email}</h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}
        <div className="p-2">
          <AvForm
            model={initialValues}
            onValidSubmit={(e, v) => {
              handleUpdate({ ...v, status: isActive });
            }}
          >
            <Row>
              <Col md="6">
                <AvField
                  name="ao_credits"
                  label="AO Credits*"
                  className="form-control"
                  placeholder=""
                  type="number"
                  validate={{
                    required: { value: true },
                    min: {
                      value: 0,
                      errorMessage: "Minimum value allowed is 0",
                    },
                  }}
                />
              </Col>
              <Col md="6">
                <AvField
                  name="bce_credits"
                  label="Proxy Tracking Credits*"
                  className="form-control"
                  placeholder=""
                  type="number"
                  validate={{
                    required: { value: true },
                    min: {
                      value: 0,
                      errorMessage: "Minimum value allowed is 0",
                    },
                  }}
                />
              </Col>
              <Col md="12">
                <AvField
                  name="trial_end"
                  type="date"
                  label="Trial Date*"
                  format="YYYY-MM-DD"
                  min={moment().format("YYYY-MM-DD")}
                  readOnly={
                    !!(selectedTenantAcc.trial_end && moment(selectedTenantAcc.trial_end).isSameOrBefore(moment()))
                  }
                  className="form-control"
                  placeholder="Date when trial ends"
                  validate={{
                    required: { value: true },
                  }}
                />
              </Col>
              <Col md="6">
                <div className="custom-control custom-switch custom-switch-lg my-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={Boolean(isActive === ACCOUNT_STATUSES.active)}
                    onChange={() => null}
                  />
                  <label
                    onClick={() =>
                      setActive(
                        isActive === ACCOUNT_STATUSES.active ? ACCOUNT_STATUSES.in_active : ACCOUNT_STATUSES.active,
                      )
                    }
                    className="custom-control-label"
                  >
                    Active
                  </label>
                </div>
              </Col>
              {selectedTenantAcc?.stripe_subscription_id &&
              selectedTenantAcc?.last_sub_end &&
              moment(selectedTenantAcc.last_sub_end).isAfter(moment().endOf("day"))
                ? (
                <Col md="6" className="d-flex align-items-center">
                  <CheckBox
                    name="show_extend"
                    state={subExtend.show}
                    isSwitch={true}
                    setState={(state) => setSubExtend({ ...subExtend, show: state })}
                  />
                  <Label className="mt-2">
                    <span>Subscription End Date</span>
                  </Label>
                </Col>
                  )
                : null}
              {subExtend.show
                ? (
                <Col md="12">
                  <Input
                    name="delivered_older_than"
                    value={subExtend.date || ""}
                    min={moment(currentEndDate).add(1, "day").format("YYYY-MM-DD")}
                    onChange={(e) => setSubExtend({ ...subExtend, date: e.target.value })}
                    type="date"
                  />
                </Col>
                  )
                : null}
              <Col md="6" className="d-flex align-items-center">
                <CheckBox
                  name="is_credit_promotion"
                  state={isFreeCredits}
                  isSwitch={true}
                  setState={(state) => setIsFreeCredits(state)}
                />
                <Label className="mt-2">
                  <span>Free Credits</span>
                </Label>
              </Col>
            </Row>
            <div className="mt-3">
              <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                Send
              </button>
            </div>
          </AvForm>
        </div>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default UserModal;
