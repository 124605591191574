import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import { pick, merge, first } from "lodash";
import { getUserInfo } from "../../utils/common";
import * as types from "./actionTypes";
import * as apis from "../../api";
import * as actions from "./actions";
// Login Redux States
const {
  getAllListings,
  bulkUpdateListings,
  deleteBulkListings,
  relistRetiredListings,
  fetchMarketplaceProductInfo,
  fetchMatchingLogs,
  syncShippingTemplate,
  fetchShippingTemplate,
  assignShippingTemplate,
  updateUPC,
  changeProductVariation,
  changeQuantityMultiplier,
  addCSVListings,
  downloadListings,
  updateBrandRestriction,
  updateErroredListing,
  getAllUploadedFiles,
  setupManualMatches,
  bulkUpdateHandlingTime,
  updateMapOrHandlingTime,
  fetchMatchingPlatforms,
  addMatchingPlatforms,
  updateShippingTemplate,
  bulkUpdateTwoStepListing,
  deleteTemplateAPI,
  createTemplateAPI,
  updateListingWeight,
  downloadFile,
} = apis;

const {
  FETCH_LISTINGS,
  LISTINGS_BULK_UPDATE,
  FETCH_MARKETPLACE_PRODUCT_INFO,
  FETCH_SHIPPING_TEMPLATE,
  SYNC_SHIPPING_TEMPLATE,
  FETCH_MATCHING_LOGS,
  ASSIGN_SHIPPING_TEMPLATE,
  UPDATE_ERRORED_LISTING,
  UPDATE_UPC,
  CHANGE_PRODUCT_VARIATION,
  CHANGE_QUANTITY_MULTIPLIER,
  LISTINGS_BULK_DELETE,
  RELIST_RETIRED_LISTINGS,
  UPDATE_BRAND_RESTRICTION,
  UPDATE_BRAND_RESTRICTION_BULK,
  ADD_CSV_LISTINGS,
  DOWNLOAD_LISTINGS,
  SHOW_CUSTOM_FILTERS,
  FETCH_UPLOADED_FILES,
  SETUP_MANUAL_MATCHES,
  BULK_UPDATE_HANDLING_TIME,
  UPDATE_MAP_AND_HANDLING_TIME,
  FETCH_MATCHING_PLATFORMS,
  ADD_MATCHING_PLATFORM,
  UPDATE_SHIPPING_TEMPLATE,
  BULK_UPDATE_TWO_STEP_MODAL,
  DELETE_TEMPLATE,
  CREATE_TEMPLATE,
  UPLOAD_FACEBOOK_LISTINGS,
  UPDATE_LISTING_WEIGHT,
  DOWNLOAD_FILE,
} = types;

const {
  apiError,
  fetchListingsSuccess,
  bulkUpdateListingsSuccess,
  bulkDeleteListingsSuccess,
  setListingsBulkUpdateModal,
  setShippingTemplateUpdateModal,
  updateErroredListingSuccess,
  setErroredListingsUpdateModal,
  setSelectedListings,
  fetchMarketplaceProductInfoSuccess,
  fetchShippingTemplateSuccess,
  syncShippingTemplateSuccess,
  updateUPCSuccess,
  fetchMatchingLogsSuccess,
  assignShippingTemplateSuccess,
  changeProductVariationSuccess,
  changeQuantityMultiplierSuccess,
  updateBrandRestrictionSuccess,
  updateBrandRestrictionBulkSuccess,
  addCSVListingsSuccess,
  downloadListingsSuccess,
  fetchUploadedFilesSuccess,
  setupManualMatchesSuccess,
  bulkUpdateHandlingTimeSuccess,
  updateMAPriceAndHandlingTimeSuccess,
  setMaPriceModal,
  addMatchingPlatformsSuccess,
  relistRetiredListingsSuccess,
  fetchMatchingPlatformsSuccess,
  updateShippingTemplateSuccess,
  setTwoStepUpdateModalSuccess,
  deleteTemplateSuccess,
  createTemplateSuccess,
  uploadFacebookListingSuccess,
  uploadFacebookListings,
  updateListingWeightSuccess,
  downloadFileSuccess,
} = actions;

const Listings = (state) => state.Listings;
const Supplier = (state) => state.Supplier;

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass (cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

function * fetchListingsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllListings, params);
    if (Array.isArray(res?.sorted_directions)) res.sorted_directions = res.sorted_directions.shift();
    yield put(fetchListingsSuccess(res));
  } catch (error) {
    const { listings } = yield select(Listings);
    listings?.results && (listings.results = []);
    yield put(apiError(error.message, error.message.includes("went wrong") ? true : undefined));
  }
}

function * fetchMarketplaceProductInfoSaga ({ payload: { params } }) {
  try {
    const res = yield call(fetchMarketplaceProductInfo, params);
    yield put(fetchMarketplaceProductInfoSuccess(res?.results));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchMatchingLogsSaga ({ payload: { params } }) {
  try {
    const res = yield call(fetchMatchingLogs, params);
    yield put(fetchMatchingLogsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateShippingTemplateSaga ({ payload: { data, params } }) {
  try {
    const res = yield call(updateShippingTemplate, { data, params });
    yield put(updateShippingTemplateSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchShippingTemplateInfoSaga ({ payload }) {
  try {
    const res = yield call(fetchShippingTemplate, payload);
    yield put(fetchShippingTemplateSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * syncShippingTemplateSaga ({ payload: { params } }) {
  try {
    let { templates } = yield select(Listings);
    const res = yield call(syncShippingTemplate, params);
    templates = {
      ...templates,
      [params?.partner_id]: res,
    };
    yield put(syncShippingTemplateSuccess(templates));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * assignShippingTemplateSaga ({ payload: { data, params } }) {
  try {
    const res = yield call(assignShippingTemplate, { data, params });
    if (res.success) {
      const listingStore = yield select(Listings);
      let index = "";
      if (data.skus && data.skus.length === 1) {
        listingStore.listings.results.find((x) => x.sku === data.skus[0]).shipping_template_id = +data.template_id;
      } else {
        if (data.select_type === "current_page") {
          data.skus.forEach((x) => {
            index = listingStore.listings.results?.findIndex((y) => y.sku === x);
            listingStore.listings.results[index].shipping_template_id = +data.template_id;
          });
        } else listingStore.listings.results.forEach((x) => (x.shipping_template_id = +data.template_id));
      }
      yield put(setShippingTemplateUpdateModal(false));
      yield put(setSelectedListings([]));
      yield put(assignShippingTemplateSuccess());
    }
  } catch (error) {
    yield put(setShippingTemplateUpdateModal(false));
    yield put(setSelectedListings([]));
    yield put(apiError(error.message));
  }
}

function * updateUPCSaga ({ payload: { data } }) {
  try {
    const res = yield call(updateUPC, data);
    if (res.success) {
      const { pendingListings } = yield select(Listings);
      pendingListings.results.find((x) => x.sku === data.sku).upc = res.upc;
      yield put(updateUPCSuccess());
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateErroredListingSaga ({ payload: { data } }) {
  try {
    const res = yield call(updateErroredListing, data);
    if (res.success) {
      const { listings } = yield select(Listings);
      if (res?.listing?.listing_status === "errored") {
        const index = listings.results.findIndex((x) => x.id === res.listing.id);
        merge(
          listings.results[index],
          pick(res.listing, ["last_submitted_price", "brand", "upc", "error_type", "error_log"]),
        );
      } else listings.results = listings.results.filter((x) => x.id !== res?.listing?.id);
      yield put(setErroredListingsUpdateModal(false));
      yield put(updateErroredListingSuccess());
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * changeProductVariationSaga ({ payload: { params } }) {
  try {
    const res = yield call(changeProductVariation, params);
    yield put(changeProductVariationSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * changeQuantityMultiplierSaga ({ payload: { params } }) {
  try {
    const res = yield call(changeQuantityMultiplier, params);
    yield put(changeQuantityMultiplierSuccess(res?.results));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * setupManualMatchesSaga ({ payload: { params } }) {
  try {
    const res = yield call(setupManualMatches, params);
    yield put(setupManualMatchesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createInventoryListingSaga ({ payload: { data, history } }) {
  try {
    const res = yield call(apis.addInventoryListing, data);
    yield put(actions.createInventoryListingSuccess(res));
    setTimeout(() => history.push("/listings"), 2000);
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addCSVListingsSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(addCSVListings, { data, id });
    yield put(addCSVListingsSuccess(res.message));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * uploadFacebookListingsSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(uploadFacebookListings, { data, id });
    yield put(uploadFacebookListingSuccess(res.message));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * downloadListingsSaga ({ payload: { params } }) {
  try {
    params.va_id = getUserInfo()?.id;
    const res = yield call(downloadListings, params);
    yield put(downloadListingsSuccess(res.message));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * bulkUpdateListingsSaga ({ payload: { data } }) {
  try {
    const res = yield call(bulkUpdateListings, data);
    if (res?.success) {
      const { listings, selectedListings } = yield select(Listings);
      const shouldUpdateIds = selectedListings.map((x) => x.id);
      let index = "";
      shouldUpdateIds.forEach((x) => {
        index = listings.results?.findIndex((y) => y.id === x);
        listings.results[index].auto_ordering_enabled = JSON.parse(data.verified_status);
      });
      yield put(setListingsBulkUpdateModal(false));
      yield put(setSelectedListings([]));
    }
    yield put(bulkUpdateListingsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * UpdateTwoStepTemplateSaga ({ payload: data }) {
  try {
    const res = yield call(bulkUpdateTwoStepListing, data);
    if (res.success) {
      const { listings } = yield select(Listings);
      if (data.skus && data.skus.length === 1) {
        listings.results.find((x) => x.sku === data.skus[0]).ship_to_warehouse = data.ship_to_warehouse;
      } else listings.results.forEach((x) => (x.ship_to_warehouse = data.ship_to_warehouse));
      yield put(setSelectedListings([]));
      yield put(setTwoStepUpdateModalSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkDeleteListingsSaga ({ payload: { data, params, fetchParams } }) {
  try {
    const res = yield call(deleteBulkListings, { data, params });
    const { listings } = yield select(Listings);
    const suppliers = yield select(Supplier);

    if (res?.success && data.singleListing) suppliers.listing = {};
    else {
      if (data.delete_type === "all_listings") {
        if (res?.success) {
          const response = yield call(getAllListings, fetchParams);
          if (Array.isArray(res?.sorted_directions)) res.sorted_directions = res.sorted_directions.shift();
          yield put(fetchListingsSuccess(response));
        }
      } else {
        if (res?.success) {
          res.results.forEach((result) => {
            listings.total_count -= result.deleted_skus.length;
            result.deleted_skus.forEach((sku) => {
              listings.results = listings.results.filter(
                (listing) => !(listing.partner_id === result.partner_id && listing.sku === sku),
              );
            });
          });
        }
      }
    }
    yield put(setSelectedListings([]));
    yield put(bulkDeleteListingsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * relistRetiredListingSaga ({ payload: { data, fetchParams } }) {
  try {
    const res = yield call(relistRetiredListings, data);
    const { listings } = yield select(Listings);
    if (data.select_type === "all_listings") {
      if (res?.success) {
        const response = yield call(getAllListings, fetchParams);
        if (Array.isArray(res?.sorted_directions)) res.sorted_directions = res.sorted_directions.shift();
        yield put(fetchListingsSuccess(response));
      }
    } else {
      if (res?.success) {
        res.results.forEach((result) => {
          listings.total_count -= result.re_listed_skus.length;
          result.re_listed_skus.forEach((sku) => {
            listings.results = listings.results.filter(
              (listing) => !(listing.partner_id === result.partner_id && listing.sku === sku),
            );
          });
        });
      }
    }
    yield put(setSelectedListings([]));
    yield put(relistRetiredListingsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchUploadedFilesSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllUploadedFiles, params);
    yield put(fetchUploadedFilesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * showCustomFilters () {
  try {
    yield call(manageBodyClass, "custom-filter-enabled", "add");
  } catch (error) {}
}

function * updateBrandRestrictionSaga ({ payload: { data } }) {
  try {
    const res = yield call(updateBrandRestriction, data);
    if (res?.results.length) {
      const listings = yield select(Listings);
      const index = listings.pendingListings.results.findIndex((x) => x.sku === res.results[0].skus[0]);
      listings.pendingListings.results[index].skip_brand_restriction = data.skus_data[0].status;
      yield put(updateBrandRestrictionSuccess());
    }
  } catch (error) {
    yield put(apiError(error.error || error.message || error));
  }
}

function * bulkUpdateBrandRestrictionSaga ({ payload: { data, params } }) {
  try {
    const res = yield call(updateBrandRestriction, { data, params });
    if (res?.success) {
      yield put(setSelectedListings([]));
      yield put(updateBrandRestrictionBulkSuccess());
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * checkListingOnStoreSaga ({ payload: { params } }) {
  try {
    const res = yield call(apis.checkListingOnStore, params);
    if (res) yield put(actions.checkListingOnStoreSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkUpdateHandlingTimeSaga ({ payload: { data, isSingleUpdate, isSingleListing } }) {
  try {
    const res = yield call(bulkUpdateHandlingTime, data);
    if (res?.success) {
      const { listings, selectedListings } = yield select(Listings);
      const { listing } = yield select(Supplier);

      if (listing && isSingleListing) listing.handling_time = data.handling_time;
      else {
        let shouldUpdate;
        if (isSingleUpdate) shouldUpdate = first(res?.results)?.skus || [];
        else shouldUpdate = selectedListings.map((x) => x.id);

        let index = "";
        shouldUpdate.forEach((x) => {
          index = listings.results?.findIndex((y) => y[isSingleUpdate ? "sku" : "id"] === x);
          listings.results[index].handling_time = JSON.parse(data.handling_time);
        });

        if (!isSingleUpdate) yield put(setListingsBulkUpdateModal(false));
        yield put(setSelectedListings([]));
      }
    }
    yield put(bulkUpdateHandlingTimeSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateMaPriceSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateMapOrHandlingTime, data);
    if (res?.success) {
      const listings = yield select(Listings);
      const index = listings.listings.results.findIndex((x) => x.id === id);
      if (index !== undefined) {
        listings.listings.results[index].handling_time = data.handling_time;
        listings.listings.results[index].ma_price = data.ma_price;
      }
      yield put(setMaPriceModal(false));
    }
    yield put(updateMAPriceAndHandlingTimeSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addMatchingPlatformsSaga ({ payload }) {
  try {
    const res = yield call(addMatchingPlatforms, payload);
    if (res?.success) {
      const listings = yield select(Listings);
      listings.matchingPlatforms.unshift(res.platform);
    }
    yield put(addMatchingPlatformsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchMatchingPlatformsSaga () {
  try {
    const res = yield call(fetchMatchingPlatforms);
    if (res?.platforms) {
      yield put(fetchMatchingPlatformsSuccess(res.platforms));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteTemplateSaga ({ payload: id }) {
  try {
    const res = yield call(deleteTemplateAPI, id);
    yield put(deleteTemplateSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * createTemplateSaga ({ payload: { data } }) {
  try {
    const res = yield call(createTemplateAPI, data);
    yield put(createTemplateSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * downloadFileSaga ({ payload }) {
  try {
    const res = yield call(downloadFile, payload);
    yield put(downloadFileSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updateListingWeightSaga ({ payload: { key, body } }) {
  try {
    const res = yield call(updateListingWeight, body);
    if (res?.success) {
      const listingsState = yield select(Listings);
      ["estimated_shipping_fee_lock", "estimated_shipping_fee", "weight"].forEach((keyName) => {
        if (keyName in body) listingsState.listings.results[key][keyName] = body?.[keyName];
      });
    }
    yield put(updateListingWeightSuccess(res, true));
  } catch (error) {
    yield put(apiError(error.message || error, false, true));
  }
}

function * fetchInventoryItemInfoSaga ({ payload }) {
  try {
    const res = yield call(apis.fetchInventoryItemInfoAPI, payload);
    if (res?.success) yield put(actions.fetchInventoryItemInfoSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchUpdateShippingTemplateSaga () {
  yield takeEvery(UPDATE_SHIPPING_TEMPLATE, updateShippingTemplateSaga);
}

export function * watchCheckListingOnStoreSaga () {
  yield takeEvery(types.CHECK_LISTING_IN_STORE, checkListingOnStoreSaga);
}

export function * watchCreateInventoryListingSaga () {
  yield takeEvery(types.CREATE_INVENTORY_LISTING, createInventoryListingSaga);
}

export function * watchListingFetch () {
  yield takeEvery(FETCH_LISTINGS, fetchListingsSaga);
}

export function * watchAddCSVListingsFetch () {
  yield takeEvery(ADD_CSV_LISTINGS, addCSVListingsSaga);
}

export function * watchUploadFacebookListings () {
  yield takeEvery(UPLOAD_FACEBOOK_LISTINGS, uploadFacebookListingsSaga);
}

export function * watchDownloadListingsFetch () {
  yield takeEvery(DOWNLOAD_LISTINGS, downloadListingsSaga);
}

export function * watchUploadedFilesFetch () {
  yield takeEvery(FETCH_UPLOADED_FILES, fetchUploadedFilesSaga);
}

export function * watchFetchMarketplaceProductInfo () {
  yield takeEvery(FETCH_MARKETPLACE_PRODUCT_INFO, fetchMarketplaceProductInfoSaga);
}

export function * watchFetchMatchingLogsSaga () {
  yield takeEvery(FETCH_MATCHING_LOGS, fetchMatchingLogsSaga);
}

export function * watchUpdateListingWeightSaga () {
  yield takeEvery(UPDATE_LISTING_WEIGHT, updateListingWeightSaga);
}

export function * watchFetchShippingTemplateInfo () {
  yield takeEvery(FETCH_SHIPPING_TEMPLATE, fetchShippingTemplateInfoSaga);
}

export function * watchSyncShippingTemplate () {
  yield takeEvery(SYNC_SHIPPING_TEMPLATE, syncShippingTemplateSaga);
}

export function * watchAssignShippingTemplate () {
  yield takeEvery(ASSIGN_SHIPPING_TEMPLATE, assignShippingTemplateSaga);
}

export function * watchUpdateUPC () {
  yield takeEvery(UPDATE_UPC, updateUPCSaga);
}

export function * watchUpdateErroredListing () {
  yield takeEvery(UPDATE_ERRORED_LISTING, updateErroredListingSaga);
}

export function * watchChangeProductVariation () {
  yield takeEvery(CHANGE_PRODUCT_VARIATION, changeProductVariationSaga);
}

export function * watchChangeQuantityMultiplier () {
  yield takeEvery(CHANGE_QUANTITY_MULTIPLIER, changeQuantityMultiplierSaga);
}

export function * watchSetupManualMatches () {
  yield takeEvery(SETUP_MANUAL_MATCHES, setupManualMatchesSaga);
}

export function * watchBulkListingsUpdate () {
  yield takeEvery(LISTINGS_BULK_UPDATE, bulkUpdateListingsSaga);
}

export function * watchBulkListingsDelete () {
  yield takeEvery(LISTINGS_BULK_DELETE, bulkDeleteListingsSaga);
}

export function * watchRelistRetiredListings () {
  yield takeEvery(RELIST_RETIRED_LISTINGS, relistRetiredListingSaga);
}

export function * watchShowCustomFilters () {
  yield takeEvery(SHOW_CUSTOM_FILTERS, showCustomFilters);
}

export function * watchUpdateBrandRestriction () {
  yield takeEvery(UPDATE_BRAND_RESTRICTION, updateBrandRestrictionSaga);
}

export function * watchBulkUpdateBrandRestriction () {
  yield takeEvery(UPDATE_BRAND_RESTRICTION_BULK, bulkUpdateBrandRestrictionSaga);
}

export function * watchBulkUpdateHandlingTime () {
  yield takeEvery(BULK_UPDATE_HANDLING_TIME, bulkUpdateHandlingTimeSaga);
}

export function * watchUpdateMapPrice () {
  yield takeEvery(UPDATE_MAP_AND_HANDLING_TIME, updateMaPriceSaga);
}

export function * watchAddMatchingPlatformsSaga () {
  yield takeEvery(ADD_MATCHING_PLATFORM, addMatchingPlatformsSaga);
}

export function * watchFetchMatchingPlatformsSaga () {
  yield takeEvery(FETCH_MATCHING_PLATFORMS, fetchMatchingPlatformsSaga);
}

export function * watchAssignTwoStepUpdateModal () {
  yield takeEvery(BULK_UPDATE_TWO_STEP_MODAL, UpdateTwoStepTemplateSaga);
}

export function * watchDeleteTemplateSaga () {
  yield takeEvery(DELETE_TEMPLATE, deleteTemplateSaga);
}

export function * watchCreateTemplateSaga () {
  yield takeEvery(CREATE_TEMPLATE, createTemplateSaga);
}

export function * watchDownloadFileSaga () {
  yield takeEvery(DOWNLOAD_FILE, downloadFileSaga);
}

export function * watchFetchInventoryItemInfoSaga () {
  yield takeEvery(types.FETCH_INVENTORY_ITEM_INFO, fetchInventoryItemInfoSaga);
}

function * listingSaga () {
  yield all([
    fork(watchUpdateShippingTemplateSaga),
    fork(watchListingFetch),
    fork(watchAddCSVListingsFetch),
    fork(watchDownloadListingsFetch),
    fork(watchUploadedFilesFetch),
    fork(watchBulkListingsUpdate),
    fork(watchBulkListingsDelete),
    fork(watchFetchMatchingLogsSaga),
    fork(watchFetchMarketplaceProductInfo),
    fork(watchFetchShippingTemplateInfo),
    fork(watchSyncShippingTemplate),
    fork(watchAssignShippingTemplate),
    fork(watchCheckListingOnStoreSaga),
    fork(watchCreateInventoryListingSaga),
    fork(watchUpdateErroredListing),
    fork(watchUpdateUPC),
    fork(watchChangeProductVariation),
    fork(watchChangeQuantityMultiplier),
    fork(watchSetupManualMatches),
    fork(watchUpdateBrandRestriction),
    fork(watchBulkUpdateBrandRestriction),
    fork(watchRelistRetiredListings),
    fork(watchShowCustomFilters),
    fork(watchBulkUpdateHandlingTime),
    fork(watchUpdateMapPrice),
    fork(watchAddMatchingPlatformsSaga),
    fork(watchFetchMatchingPlatformsSaga),
    fork(watchAssignTwoStepUpdateModal),
    fork(watchDeleteTemplateSaga),
    fork(watchCreateTemplateSaga),
    fork(watchUploadFacebookListings),
    fork(watchUpdateListingWeightSaga),
    fork(watchDownloadFileSaga),
    fork(watchFetchInventoryItemInfoSaga),
  ]);
}

export default listingSaga;
