import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import shippingLabelIcon from "../../../assets/svg/shipping-label.svg";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import { Container, Card, CardBody, CardHeader, FormGroup, Button, Alert, UncontrolledPopover } from "reactstrap";
import { ConfirmDialogImage, ConfirmationAlert, ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import CustomPagination from "../../../components/Common/CustomPagination";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { DatePicker } from "../../../components/Common/DatePickr";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import requestRefundIcon from "../../../assets/svg/cancel.svg";
import CheckBox from "../../../components/Common/CheckBox";
import ReactTable from "../../../components/Common/Table";
import PillBtn from "../../../components/Common/PillBtn";
import { useHistory } from "react-router";
import Select from "react-select";
import MarkAsIssueReturns from "../../../components/Common/MarkAsIssueReturns";
import {
  omit,
  pick,
  values,
  map,
  minBy,
  groupBy,
  last,
  keyBy,
  orderBy,
  startCase,
  flatten,
  some,
  sumBy,
  compact,
  cloneDeep,
  merge,
  assign,
  keys,
  valuesIn,
  size,
  entries,
  slice,
  isEmpty,
  get,
  has,
  toUpper,
} from "lodash";
import moment from "moment-timezone";
import queryString from "query-string";

import {
  RenderIf,
  getWarehouseName,
  toPascalCase,
  getUserInfo,
  formatNumber,
  downloadFileClientSide,
  isWarehouseAdmin,
} from "../../../utils/common";
import OffCanvas from "../../../components/Common/offcanvas";

import {
  LABEL_STATUSES,
  DATE_FORMATS,
  LABEL_SERVICES,
  MARKETPLACE_STATUSES,
  STATUS_COLOR_CODE,
  MARKET_PLACES,
  INT_REGEX,
  TRACKING_STATUS_OPTIONS,
  DELETE_OUTBOUND_ITEM,
  OUTBOUND_TYPES,
  OUTBOUND_STATUS_OPTIONS,
  OUTBOUND_STATUSES,
} from "../../../constants";
// Images
import ReturnShipment from "../../../assets/svg/returns-ship.svg";
import OverdueOrder from "../../../assets/svg/overdue-orders.svg";
import ShipToday from "../../../assets/svg/ship-today.svg";
import TotalShip from "../../../assets/svg/total-ship.svg";
import Refresh from "../../../assets/svg/refresh.svg";
import Plans from "../../../assets/svg/plans.svg";

// Actions
import * as actions from "../../../store/actions";
const {
  fetchWHOutbounds,
  fetchUserWarehouses,
  fetchAllShippingRates,
  generateLabel,
  fetchAllOutboundShipments,
  downloadShippingLabel,
  refundShipmentLabel,
  generateShippingLabelToggle,
  setPreloader,
  fetchWhOutboundStats,
  markAsCompleteItem,
  bulkMarkAsCompleteItem,
  downloadOutboundLabel,
} = actions;

const isDeleteOutboundAccess = DELETE_OUTBOUND_ITEM.includes(getUserInfo()?.email);

const ordersObj = [
  {
    key: "totalOrderToShip",
    title: "Total Orders to Ship",
    value: 0,
    img: TotalShip,
  },
  { key: "overdueOrders", title: "Overdue Orders", value: 0, img: OverdueOrder },
  {
    key: "orderToShipToday",
    title: "Orders to Ship Today",
    value: 0,
    img: ShipToday,
  },
  {
    key: "orderToShipTomorrow",
    title: "Orders to Ship by Tomorrow",
    value: 0,
    img: ShipToday,
  },
  {
    key: "returnsToShip",
    title: "Returns to Ship",
    value: 0,
    img: ReturnShipment,
  },
  {
    key: "inventoryToShip",
    title: "Inventory Fulfilled Orders",
    value: 0,
    img: Plans,
  },
];

const mainStatusMapping = {
  "Orders to Ship by Tomorrow": "text-success",
  "Orders to Ship Today": "text-warning",
  "Overdue Orders": "text-danger",
};

const isEnabled = (dimensions) => {
  const cols = ["length", "width", "height"];
  return (
    (["length", "width", "height"].every((x) => !dimensions[x]) && dimensions.weight && dimensions.weight > 0) ||
    cols.concat("weight").every((x) => dimensions[x])
  );
};

const isNegative = (dimensions) =>
  ["length", "width", "height"].concat("weight").some((x) => dimensions[x] && dimensions[x] <= 0);

const getRatesTooltip = (dim) => {
  if (isNegative(dim)) return <div className="tooltip-example">Size or weight dimensions should be +ve values.</div>;
  else if (!isEnabled(dim))
    return <div className="tooltip-example">Please enter all the dimensions or weight only</div>;
};

let dimensionsTimeout;

const getBins = (obj) => {
  const bins = map(values(obj), "bins");
  const binsInObj = bins?.reduce((acc, curr) => assign(acc, curr), {});
  const binSize = size(keys(binsInObj));
  return { binsInObj, binSize };
};

const setItemStatus = (itemTrackingNum, outboundStatus) =>
  itemTrackingNum || outboundStatus === "complete" ? "completed" : "Pending Action";

const OutboundItems = () => {
  const dispatch = useDispatch();
  const refOutboundItem = useRef({});
  const history = useHistory();
  const { appTheme } = useSelector((state) => state.Layout);
  const initialLabelService = {
    value: LABEL_SERVICES.ecom_circles_shipping,
    label: startCase(LABEL_SERVICES.ecom_circles_shipping),
  };
  // Selectors
  const {
    whOutboundData,
    loading,
    getAllShippingRates,
    stampsCreds,
    userWarehouses,
    allOutboundShipments,
    generateShippingLabelToggleIsOpen,
    error,
    success,
    whOutboundStats,
    filePath,
  } = useSelector((state) => state.WareHouses);

  // States
  const [checkBox, setCheckBox] = useState({});
  const [isMarkAsCompleteBtnClick, setIsMarkAsCompleteBtnClick] = useState(false);
  const [outboundType, setOutboundType] = useState("");
  const [orders, setOrders] = useState([]);
  const [collapse, setCollapsed] = useState(0);
  const [outboundObj, setOutboundObj] = useState("");
  const [isItemMarked, setIsItemMarked] = useState("");
  const [outboundItemsObj, setOutboundItemsObj] = useState([]);
  const [itemQtyValidation, setItemQtyValidation] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState({});

  const shipDate = (val) => (val ? moment(val).format(DATE_FORMATS.DATE) : "N/A");

  const isLabelNotCreated = (item) =>
    (outboundType === "inventory_fulfilled" || item.warehouse_inventory?.sku === "FAKE_INVENTORY_SKU") &&
    !item.tracking_number;

  const isInventoryFulfilledItemExist = (arr) => arr.some((x) => x.orderable_type === "inventory_fulfilled");
  const isVirtualInventoryItemExist = (arr) => arr.some((x) => x?.warehouse_inventory?.sku === "FAKE_INVENTORY_SKU");
  const checkDrawerItem = (arr) =>
    arr.some((x) => x?.warehouse_inventory?.sku === "FAKE_INVENTORY_SKU" && !x?.tracking_number);
  const isVirtualInventoryItem = (obj) =>
    obj.orderable_type === "inventory_fulfilled" &&
    !obj.tracking_number &&
    obj.warehouse_inventory?.sku === "FAKE_INVENTORY_SKU";
  const isFakeInv = (obj) => obj.warehouse_inventory?.sku === "FAKE_INVENTORY_SKU";
  const [weightSet, setWeight] = useState(null);
  const [ozWeightSet, setOzWeight] = useState(null);
  const [createLableObj, setCreateLableObj] = useState({
    account_id: "",
    dimensions: {},
    customer_address: {},
    warehouse_id: "",
    itemName: "",
    itemQuantity: "",
    warehouseName: "",
  });
  const [dimensions, setDimensions] = useState({
    length: createLableObj?.dimensions?.length || null,
    width: createLableObj?.dimensions?.width || null,
    height: createLableObj?.dimensions?.height || null,
    weight: createLableObj?.dimensions?.weight || weightSet,
    ozWeight: createLableObj?.dimensions?.weight * 16 || ozWeightSet,
  });

  let { rates: shippingRates } = getAllShippingRates?.data?.all_rates || {};
  shippingRates = shippingRates?.filter((x) => x.service !== "usps_first_class_mail");
  const ratesGroup = keyBy(shippingRates, "service");
  const minShippingRate = minBy(
    shippingRates?.filter((x) => !["usps_media_mail", "MediaMail"].includes(x.service)),
    (x) => +x.rate,
  );
  const [serviceFilter, setServiceFilter] = useState({
    value: minShippingRate?.service || "",
    label: minShippingRate?.service || "",
  });

  const canShowLabelIcon = (item) => {
    const trackingPresent = item?.outbound_items.every((x) => x.tracking_number);
    return !(
      !item?.account?.is_two_step_paid ||
      ([MARKETPLACE_STATUSES.Shipped, MARKETPLACE_STATUSES.Delivered].includes(item.marketplace_status) &&
        trackingPresent) ||
      trackingPresent ||
      ([MARKETPLACE_STATUSES.Cancelled, MARKETPLACE_STATUSES.Canceled].includes(item.marketplace_status) &&
        item.warehouse_id &&
        ["partial", "in_complete"].includes(item.status))
    );
  };

  const getTooltipContent = (item) => {
    if (!item?.account?.is_two_step_paid) return "Account's payment method is getting declined";
    if (!item.warehouse_id) return "No warehouse is assigned to this order";
    if (
      [MARKETPLACE_STATUSES.Shipped, MARKETPLACE_STATUSES.Delivered].includes(item.marketplace_status) &&
      item?.outbound_items.every((x) => x.tracking_number)
    )
      return `You can't create label of marketplace ${item.marketplace_status} orders.`;
    if ([MARKETPLACE_STATUSES.Canceled, MARKETPLACE_STATUSES.Cancelled].includes(item.marketplace_status))
      return "Order is cancelled at marketplace";
    if (item?.status === "complete") return "This outbound order is complete.";
    return "Label already generated.";
  };

  useEffect(() => {
    filePath && downloadFileClientSide(filePath);
    dispatch(actions.emptyDownloadFileURL());
  }, [filePath]);

  useEffect(() => {
    if (whOutboundStats) {
      const getStatKeys = map(ordersObj, "key");
      const bufferOrders = keyBy(cloneDeep(ordersObj), "key");
      getStatKeys.forEach((x) => (bufferOrders[x].value = bufferOrders[x].value || whOutboundStats[x] || 0));
      setOrders(values(bufferOrders));
    }
  }, [whOutboundStats]);

  useEffect(() => {
    setServiceFilter({ value: minShippingRate?.service, label: getServiceLabel(minShippingRate) });
  }, [minShippingRate]);

  const [labelService, setLabelService] = useState(initialLabelService);
  const selectedService = ratesGroup[serviceFilter?.value];
  const query = queryString.parse(document.location.search);

  // States for filter
  const [filters, setFilters] = useState(merge({ page: 1, per_page: 30, outbound_status: "all" }, query.search && { search: query.search }));
  const [search, setSearch] = useState(query.search || "");
  const searchTypeOptions = { All: "", "Bin#": "bin", "Account ID": "account_id" };
  const [searchType, setSearchType] = useState({ label: "All", value: searchTypeOptions.All });
  const handleSubmit = () => {
    setFilters({ ...filters, search, search_column: searchType?.value, page: 1 });
  };
  const isValidDimensions = (dims) => {
    const newDimensions = dims || dimensions;
    const cols = ["length", "width", "height"];
    return (
      (cols.every((x) => !newDimensions[x]) &&
        newDimensions.weight &&
        newDimensions.weight > 0 &&
        newDimensions.ozWeight &&
        newDimensions.weight > 0) ||
      cols.concat("weight", "ozWeight").every((x) => newDimensions[x] && newDimensions[x] > 0)
    );
  };

  const isAMZOrder = (orderId) => /\d{3}-\d{7}-\d{7}/.test(orderId);

  const fetchRates = (dimensions) => {
    setServiceFilter({ value: "", label: "" });

    if (isValidDimensions(dimensions)) {
      dispatch(
        fetchAllShippingRates({
          data: {
            item_id: createLableObj?.item_id,
            warehouse_id: createLableObj?.warehouse_id,
            dimensions: dimensions || createLableObj?.dimensions,
            customer_address: createLableObj?.customer_address,
            label_service: labelService?.value,
            label_from: "outbound",
          },
        }),
      );
    }
  };

  const getCustomerAddress = (outbound) => values(pick(outbound, "address1", "city", "country")).join(", ");

  const getDim = (e, key) => {
    const { value } = e.target;
    let newDimensions;
    if (key === "weight") newDimensions = { ...dimensions, weight: value, ozWeight: value * 16 };
    else if (key === "ozWeight") newDimensions = { ...dimensions, ozWeight: value, weight: value / 16 };
    else if (key === "length") newDimensions = { ...dimensions, length: value };
    else if (key === "width") newDimensions = { ...dimensions, width: value };
    else if (key === "height") newDimensions = { ...dimensions, height: value };

    setDimensions(newDimensions);
    setServiceFilter({ value: "", label: "" });
    if (dimensionsTimeout) clearTimeout(dimensionsTimeout);
    dimensionsTimeout = setTimeout(fetchRates, 1500, newDimensions);
  };

  const getLabelService = (service) => ({ value: service, label: startCase(service) });

  const skipStampsOption = (allCreds, accountId) =>
    !some(allCreds, (account) => account.account_id === accountId && account.enabled);

  const filterLabelServices = (orderId, allStampsCreds, accountId) => {
    let services = omit(LABEL_SERVICES, "easypost");
    if (!isAMZOrder(orderId)) services = omit(services, "amazon");
    if (!allStampsCreds || skipStampsOption(allStampsCreds, accountId)) services = omit(services, "stamps");
    return services;
  };

  const getRate = (x) => (has(x, "rate") ? `$${get(x, "rate")}` : "");
  const getCarrier = (x) => toUpper(get(x, "carrier", ""));
  const getService = (x) => startCase(get(x, "service", ""));
  const getDeliveryDays = (x) => `Delivery Days: ${get(x, "delivery_days", "N/A")}`;
  const getProvider = (x) => startCase(get(x, "label_service", ""));
  const getServiceLabel = (x) =>
    x
      ? compact([
        getRate(x),
        isWarehouseAdmin() && getProvider(x),
        getCarrier(x),
        getService(x),
        getDeliveryDays(x),
      ]).join(" - ")
      : "";
  const rateOptions = useMemo(
    () =>
      map(
        orderBy(shippingRates, (x) => +x.rate),
        (x) => ({ value: x.service, label: getServiceLabel(x) }),
      ),
    [shippingRates],
  );
  const outboundIds = map(whOutboundData?.data, "id");
  const generateShippingLabel = () => {
    const ratesByService = keyBy(shippingRates, "service");
    const keys = ["service"];
    if (labelService?.value === LABEL_SERVICES.easypost) keys.push("shipment_id");
    else keys.push(["offer_id", "service_id"]);
    const outboundItemsObjBuffer = outboundItemsObj.filter((x) => x.addedToShipment && !x.tracking_number);
    dispatch(
      generateLabel(
        {
          data: pick(ratesByService[serviceFilter?.value], flatten(keys)),
          label_service: labelService?.value,
          dimensions,
          wh_id: createLableObj?.warehouse_id,
          customerAddress: createLableObj?.customer_address,
          item_id: createLableObj?.item_id,
          label_from: "outbounds",
          source_order_id: outboundObj?.source_order_id,
          outbound_ids: outboundIds,
          outbound_items_data: outboundItemsObjBuffer?.map((x) => ({ id: x?.id, shipped_qty: x?.qty })),
        },
        getAllShippingRates?.data?.id,
      ),
    );
  };

  const itemsGroup = groupBy?.(allOutboundShipments, "id");
  const getItem = (item) => {
    const items = itemsGroup[+item?.id]?.[0]?.OutboundItemShipments;
    return items?.filter((x) => x?.postage_label) ? last(items?.filter((x) => x?.postage_label)) : last(item);
  };

  const downloadOnClick = (item, isPreview = false) => {
    const downloadData = {
      file_name: getItem(item)?.postage_label,
      tracking_number: getItem(item)?.tracking_number,
      customer_name: item?.customer_name,
      is_preview: isPreview,
    };
    dispatch(downloadShippingLabel(getItem(item)?.id, downloadData));
  };

  const downloadReturnLabel = (item, isPreview = false) =>
    dispatch(downloadOutboundLabel(item?.id, { is_preview: isPreview, file_name: item?.label, is_tracking_item: item.orderable_type === "tracking_items" }));

  // Use Effects
  useEffect(() => {
    if (!loading) {
      dispatch(fetchWHOutbounds(filters));
      dispatch(fetchWhOutboundStats({ status: filters?.outbound_status }));
    }
  }, [filters]);

  useEffect(() => {
    dispatch(fetchUserWarehouses());
  }, []);

  useEffect(() => {
    if (!whOutboundData) return;
    outboundIds?.length && dispatch(fetchAllOutboundShipments(outboundIds));
  }, [whOutboundData]);

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }

  useEffect(() => {
    setLoading(loading);
  }, [loading, whOutboundData, userWarehouses]);

  useEffect(() => {
    if (query.search && whOutboundData?.data?.length && !outboundItemsObj.some((x) => x.tracking_number)) {
      const [node] = document.getElementsByClassName("generate-label");
      if (node) node.click();
    }
  }, [query.search, whOutboundData?.data]);

  useEffect(() => {
    isValidDimensions() && fetchRates(dimensions);
  }, [labelService?.value]);

  const getText = (text) => text || "N/A";

  const itemActionsMapping = {
    returns: "Returns",
    fba: "Move to FBA",
    wfs: "Move to WFS",
    return_initiated: "Return initiated",
    discard_initiated: "Discard initiated",
    donate_initiated: "Donate initiated",
    liquidation_initiated: "Liquidation initiated",
    returned_to_customer: "Returned to customer",
    returned_to_supplier: "Returned to supplier",
    tracking_items: "2-Step",
  };

  const outBoardingHeader = [
    { title: "Account Details" },
    { title: "Order ID" },
    { title: "Items" },
    { title: "Customer Address" },
    { title: "Total Qty." },
    { title: "Outbound Type" },
    { title: "Marketplace Status" },
    { title: "Expected Ship Date" },
    { title: "Status" },
    { title: "Action" },
  ];
  const ItemsHeader = [
    { title: "Title" },
    { title: "Tracking Details" },
    { title: "Price Details" },
    { title: "Bins#/Qty" },
    { title: "Quantity" },
    { title: "Ship Dates" },
    { title: "Status" },
    { title: "Action" },
  ];

  const updateItemObjFun = (itemId, index, state, name) => {
    const itemObjs = cloneDeep(outboundItemsObj);
    const preOutboundItem = refOutboundItem.current.value;
    const getItemObj = itemObjs?.find((x) => x.id === itemId);
    if (name === "addedToShipment") {
      getItemObj[name] = state;
      itemObjs[index] = merge(outboundItemsObj[index], getItemObj);
      setOutboundItemsObj(itemObjs);
    } else if (name === "qty") {
      getItemObj[name] = state;
      itemObjs[index] = merge(outboundItemsObj[index], getItemObj);
      setOutboundItemsObj(itemObjs);
      const actualQty = preOutboundItem?.outbound_items[index]?.qty;
      if (actualQty < state) {
        setItemQtyValidation(`Enter <= ${actualQty}`);
      } else {
        setItemQtyValidation("");
        !state || state <= 0
          ? setItemQtyValidation("Enter positive value")
          : setItemQtyValidation(INT_REGEX.test(state) ? "" : "Enter only Intergers.");
      }
    }
  };

  const checkItemsQty = (id) => !refOutboundItem?.current?.value?.outbound_items[id]?.qty;

  const setOutboundObjFun = (obj) => {
    const warehouseId = obj && obj?.warehouse_id;
    const bufferCreateLableObj = {
      account_id: obj?.account_id,
      order_id: obj?.id,
      item_id: obj?.id,
      warehouse_id: warehouseId,
      marketplace_order_id: obj?.order_id,
      itemName: obj?.outbound_items.length ? obj?.outbound_items[0]?.warehouse_inventory?.title : "",
      itemQuantity: sumBy(obj?.outbound_items, "warehouse_inventory.qty"),
      warehouseName: getWarehouseName(userWarehouses?.[warehouseId]),
      est_shipping: obj?.est_shipping,
      customer_address: {
        name: obj?.customer_details?.customer_name,
        address1: obj?.customer_details?.address1,
        address2: obj?.customer_details?.address2,
        city: obj?.customer_details?.city,
        country: obj?.customer_details?.country,
        zipcode: obj?.customer_details?.zipcode,
        state: obj?.customer_details?.state,
        phone: obj?.customer_details?.phone,
      },
    };

    setCreateLableObj(bufferCreateLableObj);
  };

  const markAsCompleteFun = (id) => {
    dispatch(markAsCompleteItem(id, filters?.statType));
  };
  const markAsCompleteBulk = () => {
    dispatch(
      bulkMarkAsCompleteItem(
        keys(checkBox).map((x) => +x),
        filters,
      ),
    );
    setCheckBox({});
  };

  const checkMarkAsComplete = (x) => {
    return (
      (x?.status !== "complete" && x?.outbound_type !== "tracking_items" && itemActionsMapping[x?.outbound_type]) ||
      (x?.outbound_type === "inventory_fulfilled" && x?.outbound_items.some((x) => x.tracking_number)) ||
      (x?.outbound_type === "tracking_items" && size(x.outbound_items) && x.outbound_items.every((x) => x?.label))
    );
  };

  const tickAllCondition = (data) => data?.reduce((acc, cur) => (merge(acc, !!checkMarkAsComplete(cur) && { [cur.id]: true })), {});

  const setInitialService = (x) => {
    const service = getLabelService(
      isAMZOrder(x.order_id)
        ? LABEL_SERVICES.amazon
        : skipStampsOption(stampsCreds, x.account_id)
          ? LABEL_SERVICES.ecom_circles_shipping
          : LABEL_SERVICES.stamps,
    );
    setLabelService(service);
    refOutboundItem.current.value = cloneDeep(x);
    setOutboundObjFun(x);
    setOutboundObj(x);
  };

  const setShipDate = (x) => {
    const expiriy = last(x?.outbound_items)?.return_expiry;
    return x.outbound_type === "returns"
      ? expiriy
        ? moment(expiriy).format(DATE_FORMATS.DATE)
        : "N/A"
      : x?.ship_date
        ? moment(x?.ship_date).format(DATE_FORMATS.DATE)
        : "N/A";
  };

  const generateFilterOptions = (x, key, hasReset = true) =>
    Object.entries(x).reduce(
      (arr, [k, v]) => arr.concat({ label: k, value: v }),
      filters[key] && hasReset ? [{ label: "All Statuses", value: "" }] : [],
    );

  const getSelectValue = (filterValue, type) => {
    const bufferOption = type === "outbound_status" ? OUTBOUND_STATUS_OPTIONS : OUTBOUND_TYPES;
    return {
      label:
          entries(bufferOption).find(
            ([, key]) => key === filterValue,
          )?.[0] || "All Statuses",
      value: filterValue || "all",
    };
  };

  const resetCheckboxSelection = () => setCheckBox({});

  return (
    <>
      <RenderIf isTrue={isItemMarked}>
        <ConfirmationAlert
          onConfirm={() => isMarkAsCompleteBtnClick ? markAsCompleteBulk() : markAsCompleteFun(isItemMarked)}
          isOpen={isItemMarked}
          setIsOpen={setIsItemMarked}
          msg={<p>To mark as Complete?</p>}
        />
      </RenderIf>

      <div className="page-content purchase-order outbound-items">
        <RenderIf isTrue={success}>
          <div className="auto-hide-10">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </div>
        </RenderIf>
        <RenderIf isTrue={error}>
          <div className="auto-hide-10">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          </div>
        </RenderIf>
        <Container fluid>
          <Breadcrumbs
            title="OUTBOUND ITEMS"
            children={
              keys(checkBox).length > 0 && (
                <PillBtn
                  className="m-2 mt-3"
                  color="primary"
                  title={`Total ${size(checkBox)} item${size(checkBox) > 1 ? "s" : ""} selected.`}
                  name={`Mark as Complete (${size(checkBox)})`}
                  onClick={() => {
                    setIsMarkAsCompleteBtnClick(true);
                    setIsItemMarked(true);
                  }}
                />
              )
            }
          />
          <div>
            <div className="d-flex border-radius-sm info-cards">
              {orders.map((order, idx) => {
                return (
                  <React.Fragment key={"__ob_item__" + idx}>
                    <Card
                      className={`mini-stats-wid purchase-order-widgets border-radius-none w-100 mr-1 cursor-pointer ${
                        filters?.statType === order?.key ? "advance-option-border" : ""
                      }`}
                      onClick={() => {
                        if (filters?.statType === order?.key) return;
                        resetCheckboxSelection();
                        const bufferFilter = order.key === "returnsToShip" ? "returns" : order.key === "inventoryToShip" ? "inventory_fulfilled" : "";
                        history.push("/outbound-items");
                        setFilters(
                          merge({}, omit(filters, "tracking_status"), {
                            page: 1,
                            search: "",
                            outbound_status: "in_complete",
                            statType: order.key,
                            outbound_type: bufferFilter,
                            search_column: searchType?.value,
                          }),
                        );
                        setSearch("");
                      }}
                    >
                      <CardBody className="d-flex justify-content-between">
                        <span className="d-flex flex-direction-row">
                          <div className="d-flex pr-2">
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                              <span className="avatar-title">
                                <img src={order.img} alt="no-order" />
                              </span>
                            </div>
                          </div>
                          <div>
                            <p className="font-weight-medium" style={{ height: "34px" }}>
                              {order.title}
                            </p>
                            <h4 className={`mb-0 ${mainStatusMapping?.[order.title]}`}>
                              {order.value.toLocaleString("en-US")}
                            </h4>
                          </div>
                        </span>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                );
              })}
            </div>
            <div>
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between">
                    <form
                      className="app-search d-lg-block"
                      onSubmit={(e) => {
                        e.preventDefault();
                        resetCheckboxSelection();
                        if (search.trim()) {
                          setFilters(
                            merge({}, omit(filters, "tracking_status", "outbound_type", "outbound_status", "statType"), {
                              page: 1,
                              search: search.trim(),
                              outbound_status: "all",
                              search_column: searchType?.value,
                            }),
                          );
                        }
                      }}
                    >
                      <input type="submit" hidden />
                      <div className="d-flex inputs-group-inbounds outbound-filter-section">
                        <Select
                          name="select_type"
                          value={searchType}
                          style={{ width: "200px" }}
                          className="select2-container mb-0 form-group"
                          onChange={(e) => setSearchType({ label: e.label, value: e.value })}
                          options={map(entries(searchTypeOptions), ([l, v]) => ({ label: l, value: v }))}
                          classNamePrefix="select2-selection"
                        />
                        <div className="vertical-line2" />
                        <div className="position-relative">
                          <i
                            onClick={handleSubmit}
                            className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                          />
                          <input
                            id="search_field"
                            type="text"
                            className="form-control"
                            style={{ width: "230px" }}
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => {
                              if (!e.target.value.trim()) {
                                setSearch("");
                                setFilters({ ...merge(omit(filters, "page", "search"), { page: 1 }) });
                                history.push("/outbound-items");
                              } else {
                                setSearch(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center inbound-date-range">
                        <DatePicker
                          icon
                          style={{ width: "230px", border: "none" }}
                          placeholder="Select Date Range"
                          className="mx-1 my-2"
                          pickrClass="pl-2"
                          onClose={(dates) => {
                            resetCheckboxSelection();
                            setFilters({ ...filters, ...dates, page: 1 });
                          }}
                        />
                        <i className="bx font-size-16 bx-calendar text-primary mx-3" />
                      </div>
                      <FormGroup className="select2-container ml-2" style={{ width: "150px", marginTop: 15 }}>
                        <Select
                          classNamePrefix="select2-selection"
                          name="outbound_status"
                          value={getSelectValue(filters.outbound_status, "outbound_status")}
                          placeholder="Incomplete"
                          options={generateFilterOptions(OUTBOUND_STATUS_OPTIONS, "outbound_status", false)}
                          formStyle={{ Width: "100%" }}
                          onChange={(e) => {
                            resetCheckboxSelection();
                            setFilters(merge({}, filters, { outbound_status: e.value, page: 1 }));
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="select2-container ml-2" style={{ width: "170px", marginTop: 15 }}>
                        <Select
                          value={getSelectValue(filters.outbound_type, "outbound_type")}
                          classNamePrefix="select2-selection"
                          name="outbound_type"
                          placeholder="Outbound Type"
                          options={generateFilterOptions(OUTBOUND_TYPES, "outbound_type")}
                          formStyle={{ Width: "100%" }}
                          onChange={(e) => {
                            resetCheckboxSelection();
                            setFilters(merge({}, filters, { outbound_type: e.value, page: 1 }));
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="select2-container ml-2" style={{ width: "200px", marginTop: 15 }}>
                        <Select
                          value={TRACKING_STATUS_OPTIONS.find(option => option.value === filters.tracking_status) || { label: TRACKING_STATUS_OPTIONS[0].label, value: "all" }}
                          classNamePrefix="select2-selection"
                          name="tracking_status"
                          placeholder="Select Tracking Status"
                          options={TRACKING_STATUS_OPTIONS}
                          formStyle={{ Width: "100%" }}
                          onChange={(e) => {
                            resetCheckboxSelection();
                            setFilters(merge({}, filters, { tracking_status: e.value, page: 1 }));
                          }}
                        />
                      </FormGroup>
                      <RenderIf isTrue={!filters.tracking_status}>
                        <i
                          id="-helping-tooltip-label"
                          className="fas fa-question-circle text-primary fa-lg ml-2 cursor-pointer"
                        />
                        <CustomTooltip
                          placement="top"
                          content={"All Tracking Statuses exclude Delivered, Shipped and  In Transit statuses."}
                          target={"-helping-tooltip-label"}
                        />
                      </RenderIf>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </div>
          </div>
          <Card>
            <CardBody>
              <ReactTable
                tableHeader={
                  <>
                    <th>
                      <CheckBox
                      state={size(checkBox) ? size(keys(checkBox)) === size(tickAllCondition(whOutboundData?.data)) : false}
                      disabled={ !(size(tickAllCondition(whOutboundData?.data)))}
                        setState={(e) => {
                          const { data } = whOutboundData;
                          if (size(keys(checkBox)) === size(tickAllCondition(whOutboundData?.data))) setCheckBox({});
                          else setCheckBox(tickAllCondition(data));
                        }}
                      />
                    </th>
                    {outBoardingHeader.map((header, index) => (
                      <th
                        onClick={header.onClick}
                        key={`table-header ${index}`}
                        className={header.title === "Weight (lbs)" ? "text-center" : ""}
                      >
                        {header.title}
                      </th>
                    ))}
                  </>
                }
                tableBody={
                  <>
                    {whOutboundData?.data?.map((x, i) => (
                      <React.Fragment key={i}>
                        <tr className={x.issue_type ? "row-danger" : ""}>
                          <th>
                            <CheckBox
                              state={checkBox[x.id] || false}
                              setState={(e) => {
                                if (checkBox[x.id]) delete checkBox[x.id];
                                else checkBox[x.id] = true;
                                setCheckBox({ ...checkBox });
                              }}
                              disabled={!checkMarkAsComplete(x)}
                              isSwitch={false}
                            />
                          </th>
                          <td>
                            <div>
                              <span className="d-flex align-items-center">
                                Account ID: <h6 className="mb-0 pl-2">{x?.account_id}</h6>
                              </span>
                            </div>
                            <div>
                              <span className="d-flex align-items-center">
                                Store name:
                                <h6 className="mb-0 pl-2">
                                  {compact([
                                    x?.marketplace_details?.store_name,
                                    startCase(x?.marketplace_details?.marketplace),
                                  ]).join(", ")}
                                </h6>
                              </span>
                            </div>
                            <div>
                              <span className="d-flex align-items-center">
                                Client Name: <h6 className="mb-0 pl-2">{x?.customer_details?.customer_name}</h6>
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="text-primary">{x?.order_id} </span>
                            <CopyToClipBoard text={x?.order_id} />
                          </td>
                          <td>
                            <div className="overflow-add-modal">
                              <span className="d-flex align-items-center">
                                Item:{" "}
                                <h6 className="mb-0 pl-2 text-ellipse">
                                  {x?.outbound_items[0]?.warehouse_inventory?.title || "N/A"}
                                </h6>
                                <span
                                  className="counter-circle"
                                  onClick={() => setCollapsed(collapse === x.id ? 0 : x.id)}
                                >
                                  {x?.outbound_items?.length}
                                </span>
                              </span>
                              <span className="d-flex align-items-center">
                                UPC:{" "}
                                <h6 className="mb-0 pl-2 text-ellipse">
                                  {x?.outbound_items[0]?.warehouse_inventory?.upc || "N/A"}
                                </h6>
                                <span
                                  className="counter-circle"
                                  onClick={() => setCollapsed(collapse === x.id ? 0 : x.id)}
                                >
                                  {x?.outbound_items?.length}
                                </span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="d-flex">
                                <i className="bx bx-user pr-2" />
                                <p className="seemore-text m-0">{x?.customer_details?.customer_name || "N/A"}</p>
                              </span>
                              <span className="d-flex">
                                <i className="bx bx-home pr-2" />
                                <p className="seemore-text m-0">{getCustomerAddress(x?.customer_details) || "N/A"}</p>
                              </span>
                            </div>
                          </td>
                          <td className="text-center">{sumBy(x?.outbound_items, "qty") || "N/A"}</td>
                          <td className="text-center">
                            {getText(itemActionsMapping[x?.outbound_type] || startCase(x?.outbound_type))}
                          </td>
                          <td className="text-center">
                            <span
                              className={`badge-soft-${
                                STATUS_COLOR_CODE[x?.marketplace_status] || "custom"
                              } badge badge-pill`}
                            >
                              {getText(x?.marketplace_status)}
                            </span>
                          </td>
                          <td className="text-center">
                            <p className="seemore-text m-0">
                              {setShipDate(x)}
                            </p>
                          </td>
                          <td className="text-center">
                            <p className="seemore-text m-0">{OUTBOUND_STATUSES[x?.status]}</p>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <RenderIf isTrue={x?.outbound_type === "returns" && x.return_found}>

                                <MarkAsIssueReturns
                                  updateReturnOutbounds={actions.updateReturnOutbounds}
                                  outboundId={x?.id}
                                  issue={!!x.issue_type}
                                  filters={filters}
                                />
                              </RenderIf>

                              <span
                                id="expand/collapse"
                                className="icon-topRight bx-sm cursor-pointer"
                                onClick={() => {
                                  setOutboundType(x?.outbound_type);
                                  setCollapsed(collapse === x.id ? 0 : x.id);
                                }}
                              >
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </span>
                              <RenderIf isTrue={checkMarkAsComplete(x)}>
                                <i
                                  title="Mark as complete"
                                  className="bx bx-check-circle bx-sm text-primary cursor-pointer px-1"
                                  onClick={() => setIsItemMarked(x?.id)}
                                />
                              </RenderIf>
                              <RenderIf isTrue={x?.outbound_items?.some((y) => !y.tracking_number)}>
                                <RenderIf
                                  isTrue={isInventoryFulfilledItemExist(x?.outbound_items) || x?.outbound_type === "inventory_fulfilled"}
                                  fallback={
                                    <RenderIf isTrue={x?.outbound_type === "tracking_items"}>
                                      <RenderIf isTrue={!canShowLabelIcon(x)}>
                                        <CustomTooltip
                                          placement="top"
                                          content={getTooltipContent(x)}
                                          target={`tooltip-label-${x?.id}`}
                                        />
                                      </RenderIf>
                                      <img
                                        className={`cursor-pointer icons-sizes generate-label ${
                                          canShowLabelIcon(x) ? null : "disabled"
                                        }`}
                                        title="Generate Label"
                                        id={`tooltip-label-${x?.id}`}
                                        src={shippingLabelIcon}
                                        alt="label"
                                        onClick={
                                          canShowLabelIcon(x)
                                            ? () => {
                                                setInitialService(x);
                                                x.outbound_items.forEach(
                                                  (m, itemIndex) => (m.addedToShipment = !checkItemsQty(itemIndex)),
                                                );
                                                setOutboundItemsObj(cloneDeep(x?.outbound_items));
                                                dispatch(generateShippingLabelToggle(true));
                                              }
                                            : null
                                        }
                                      />
                                    </RenderIf>
                                  }
                                >
                                  <RenderIf isTrue={!canShowLabelIcon(x)}>
                                    <CustomTooltip
                                      placement="top"
                                      content={getTooltipContent(x)}
                                      target={`tooltip-label-${x?.id}`}
                                    />
                                  </RenderIf>
                                  <img
                                    className={`cursor-pointer icons-sizes generate-label ${
                                      canShowLabelIcon(x) ? null : "disabled"
                                    }`}
                                    title="Generate Label"
                                    id={`tooltip-label-${x?.id}`}
                                    src={shippingLabelIcon}
                                    alt="label"
                                    onClick={
                                      canShowLabelIcon(x)
                                        ? () => {
                                            setInitialService(x);
                                            x.outbound_items.forEach((item, index) => {
                                              const isQtyNotAvailable = !checkItemsQty(index);
                                              const isInventoryFulfilled = isInventoryFulfilledItemExist(
                                                x?.outbound_items,
                                              );
                                              const isVirtualInventory = isVirtualInventoryItemExist(x?.outbound_items);
                                              if (isQtyNotAvailable && !isInventoryFulfilled) {
                                                item.addedToShipment = false;
                                              } else if (
                                                isQtyNotAvailable &&
                                                isInventoryFulfilled &&
                                                !isVirtualInventory
                                              ) {
                                                item.addedToShipment = true;
                                              } else if (
                                                isQtyNotAvailable &&
                                                isInventoryFulfilled &&
                                                isVirtualInventory
                                              ) {
                                                item.addedToShipment = isVirtualInventoryItem(item);
                                              } else {
                                                item.addedToShipment = false;
                                              }
                                            });
                                            setOutboundItemsObj(cloneDeep(x?.outbound_items));
                                            dispatch(generateShippingLabelToggle(true));
                                          }
                                        : null
                                    }
                                  />
                                </RenderIf>
                              </RenderIf>
                            </div>
                          </td>
                        </tr>

                        <tr className={collapse === x?.id ? "p-0 collapse-show" : "p-0 collapse-hide "}>
                          <td colSpan="10" className="p-0">
                            <RenderIf
                              isTrue={x?.outbound_items?.length}
                              fallback={
                                <div className="d-flex justify-content-center m-2">
                                  <div className="d-flex flex-column">
                                    <h2>No Records Found</h2>
                                  </div>
                                </div>
                              }
                            >
                              <Card>
                                <CardBody className="collapse-card">
                                  <h6>Shipment Items</h6>
                                  <ReactTable
                                    tableHeader={
                                      <>
                                        {ItemsHeader.map((header, index) => (
                                          <th
                                            onClick={header.onClick}
                                            key={`table-header ${index}`}
                                            className={header.title === "Weight (lbs)" ? "text-center" : ""}
                                          >
                                            {header.title}
                                          </th>
                                        ))}
                                      </>
                                    }
                                    tableBody={
                                      <>
                                        {x?.outbound_items?.map((item, i) => (
                                          <tr key={i}>
                                            <td className="text-ellipse">
                                              <div className="text-truncate">
                                                {getText(item?.warehouse_inventory?.title)}
                                              </div>
                                              <span className="inner-caption d-flex align-items-center">
                                                SKU:{" "}
                                                <h6 className="ml-2 mb-0">{getText(item?.warehouse_inventory?.sku)}</h6>
                                              </span>
                                              <span className="inner-caption d-flex align-items-center">
                                                UPC:{" "}
                                                <h6 className="ml-2 mb-0 ">
                                                  {getText(item?.warehouse_inventory?.upc)}
                                                </h6>{" "}
                                              </span>
                                            </td>
                                            <td>
                                              <div className="d-flex flex-column align-items-start">
                                                <span className="text-primary">{item?.tracking_number || "N/A"}</span>
                                                <span className="inner-caption d-flex align-items-center">
                                                  Status:{" "}
                                                  <span
                                                    className={`badge-soft-${
                                                      STATUS_COLOR_CODE[item?.tracking_status] || "custom"
                                                    } badge badge-pill`}
                                                  >
                                                    {toPascalCase(item?.tracking_status) || "N/A"}
                                                  </span>{" "}
                                                </span>
                                                <span className="inner-caption d-flex align-items-center">
                                                  Carrier:{" "}
                                                  <h6 className="ml-2 mb-0 ">{item?.tracking_carrier || "N/A"}</h6>{" "}
                                                </span>
                                              </div>
                                            </td>
                                            <td>{getText(item?.warehouse_inventory?.price)}</td>
                                            <td>
                                              <RenderIf isTrue={getBins(item?.bin_details)?.binSize} fallback={"N/A"}>
                                                <div className="d-flex align-items-center">
                                                  <span className="mr-2 inner-caption">
                                                    bin#
                                                    <h6>{keys(getBins(item?.bin_details)?.binsInObj)[0]}</h6>
                                                  </span>
                                                  <div className="separation-line-listing" />
                                                  <span className="overflow-add-modal d-flex align-items-center">
                                                    <span className=" inner-caption ml-2">
                                                      Qty
                                                      <h6>{valuesIn(getBins(item?.bin_details)?.binsInObj)[0]}</h6>
                                                    </span>
                                                    <RenderIf isTrue={getBins(item?.bin_details)?.binSize > 1}>
                                                      <span
                                                        className="counter-circle"
                                                        style={{
                                                          backgroundColor: "rgba(186, 186, 186, 0.15)",
                                                          color: "#a4a3a3",
                                                        }}
                                                        id={`bin_location_${item?.id}`}
                                                      >
                                                        {getBins(item?.bin_details)?.binSize || 0}
                                                      </span>
                                                      <UncontrolledPopover
                                                        placementPrefix="bs-popover"
                                                        placement="bottom-start"
                                                        isOpen={isPopoverOpen?.[i]}
                                                        toggle={() => setIsPopoverOpen({ [i]: !isPopoverOpen?.[i] })}
                                                        hideArrow={true}
                                                        positionFixed={true}
                                                        className={appTheme === "light" ? "light" : "dark"}
                                                        trigger="legacy"
                                                        target={`bin_location_${item?.id}`}
                                                      >
                                                        <div className="p-2">
                                                          <ReactTable
                                                            tableHeader={
                                                              <>
                                                                <th className="miw-160">Bin#</th>
                                                                <th className="miw-160">Qty</th>
                                                              </>
                                                            }
                                                            tableBody={
                                                              <>
                                                                {keys(getBins(item?.bin_details)?.binsInObj).map(
                                                                  (b) => (
                                                                    <tr key={`__binDetailsRow${i}__`}>
                                                                      <td>{b}</td>
                                                                      <td>
                                                                        {getBins(item?.bin_details)?.binsInObj[b]}
                                                                      </td>
                                                                    </tr>
                                                                  ),
                                                                )}
                                                              </>
                                                            }
                                                          />
                                                        </div>
                                                      </UncontrolledPopover>
                                                    </RenderIf>
                                                  </span>
                                                </div>
                                              </RenderIf>
                                            </td>
                                            <td>{getText(item?.qty)}</td>
                                            <td>
                                              <div className="d-flex flex-column">
                                                <span className="inner-caption d-flex align-items-center mb-1">
                                                  Inbound:
                                                  <h6 className="ml-2 mb-0 ">
                                                    {shipDate(item?.received_shipment?.shipment_line?.created_at)}
                                                  </h6>
                                                </span>
                                                <span className="inner-caption d-flex align-items-center mb-1">
                                                  At WH:
                                                  <h6 className="ml-2 mb-0 ">
                                                    {shipDate(item?.received_shipment?.shipment_line?.received_at)}
                                                  </h6>
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <span
                                                className={`badge-soft-${
                                                  STATUS_COLOR_CODE[setItemStatus(item?.tracking_number, x?.status)] || "custom"
                                                } badge badge-pill`}
                                              >
                                                {startCase(setItemStatus(item?.tracking_number, x?.status))}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="d-flex align-items-center">
                                                <>
                                                  <RenderIf
                                                    isTrue={
                                                      getItem(item)?.label_status === LABEL_STATUSES.created ||
                                                      (item?.label && item?.orderable_type === "returns") ||
                                                      ((item?.label || item?.postage_label) && item?.orderable_type === "tracking_items")
                                                    }
                                                  >
                                                    <i
                                                      title="Download Label"
                                                      onClick={() =>
                                                        item?.orderable_type === "returns" ||
                                                        (item?.label && item?.orderable_type === "tracking_items")
                                                          ? downloadReturnLabel(item)
                                                          : downloadOnClick(item)
                                                      }
                                                      className="bx bx-sm bx-download pr-1 text-primary cursor-pointer"
                                                    />
                                                    <i
                                                      title="Preview Label"
                                                      onClick={() =>
                                                        item?.orderable_type === "returns" ||
                                                        (item?.label && item?.orderable_type === "tracking_items")
                                                          ? downloadReturnLabel(item, true)
                                                          : downloadOnClick(item, true)
                                                      }
                                                      className="bx bx-sm bx-file-find pr-1 text-primary cursor-pointer"
                                                    />
                                                  </RenderIf>
                                                  <RenderIf
                                                    isTrue={
                                                      item?.tracking_status !== "shipped" &&
                                                      getItem(item)?.label_status === LABEL_STATUSES.created
                                                    }
                                                  >
                                                    <ConfirmDialogImage
                                                      img={{
                                                        heading: "Cancel Label",
                                                        src: `${requestRefundIcon}`,
                                                        alt: "Cancel",
                                                        class: "cursor-pointer icons-sizes",
                                                      }}
                                                      msg={"to cancel label for this shipment?"}
                                                      onConfirm={() => {
                                                        dispatch(
                                                          refundShipmentLabel(getItem(item)?.id, {
                                                            label_from: "outbound",
                                                            outbound_item_id: item?.id,
                                                            outbound_ids: outboundIds,
                                                          }),
                                                        );
                                                      }}
                                                    />
                                                  </RenderIf>
                                                  <RenderIf isTrue={isDeleteOutboundAccess && isLabelNotCreated(x)}>
                                                    <ConfirmDialogIcon
                                                      icon={{
                                                        title: "Delete",
                                                        className: "bx bx-sm text-danger bx-trash",
                                                      }}
                                                      msg="To Delete Outbound Item."
                                                      onConfirm={() => {
                                                        dispatch(
                                                          actions.deleteOutboundItem(item?.id, {
                                                            outboundId: collapse,
                                                          }),
                                                        );
                                                      }}
                                                    />
                                                  </RenderIf>
                                                </>
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    }
                                  />
                                </CardBody>
                              </Card>
                            </RenderIf>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </>
                }
              />
              <div className="tent-pagination">
                <CustomPagination
                  pageOptions={[30, 50, 100]}
                  total={whOutboundData?.count}
                  page={whOutboundData?.page}
                  perPage={whOutboundData?.per_page}
                  filters={filters}
                  setTabFilter={(e) => {
                    setFilters({
                      ...filters,
                      page: filters.per_page !== e.per_page ? 1 : e.page,
                      per_page: e.per_page,
                    });
                    resetCheckboxSelection();
                  }}
                />
              </div>
              <RenderIf isTrue={!whOutboundData?.data?.length && !loading}>
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h2>No Records Found</h2>
                  </div>
                </div>
              </RenderIf>
            </CardBody>
          </Card>
        </Container>
      </div>

      <OffCanvas open={generateShippingLabelToggleIsOpen} size="636px" className="label-genertaion overflow-auto">
        <div className="drawer-heading d-flex justify-content-between">
          <h6>Generate Shipping Label</h6>
          <i
            title="close"
            className="bx bx-sm bx-x pr-1  cursor-pointer"
            onClick={() => {
              dispatch(fetchAllOutboundShipments(outboundIds));
              dispatch(generateShippingLabelToggle(false));
              setServiceFilter({ label: "", value: "" });
              setDimensions({ length: "", width: "", height: "", weight: "", ozWeight: "" });
              setItemQtyValidation("");
            }}
          />
        </div>
        {error && (
          <div className="auto-hide">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          </div>
        )}
        <hr />
        <RenderIf
          isTrue={outboundItemsObj.some((x) => x.tracking_number)}
        >
          <Alert color="danger" className="my-1 mb-2">
            <i className="bx bx-info-circle pr-2"></i>
            There is an <b>existing label</b> for this order. Are you sure you want to continue.
          </Alert>
        </RenderIf>
        <div className="drawer-table">
          <div className="ship">
            <div className="d-flex">
              <label>Ship From</label>
              <input className="form-control" value={createLableObj?.warehouseName} disabled />
            </div>
            <div className="d-flex">
              <label>Ship to</label>
              <input className="form-control" value={compact(values(createLableObj?.customer_address))} disabled />
            </div>
          </div>
          <div className="items">
            <h6>Items</h6>
            <table className="items-table w-100">
              <thead>
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {outboundItemsObj?.map((y, index) => (
                  <tr key={y?.id}>
                    <td>
                      <CheckBox
                        state={y?.addedToShipment}
                        disabled={
                          checkItemsQty(index) ||
                          y.tracking_number ||
                          (checkDrawerItem(outboundItemsObj) && !isFakeInv(y))
                        }
                        setState={(state) => updateItemObjFun(y.id, index, state, "addedToShipment")}
                      />
                    </td>
                    <td>
                      <div
                        className={`text-truncate ${
                          checkItemsQty(index) ||
                          y.tracking_number ||
                          (checkDrawerItem(outboundItemsObj) && !isFakeInv(y))
                            ? "text-deco-line-through"
                            : ""
                        }`}
                      >
                        {y.warehouse_inventory?.title || "N/A"}
                      </div>
                    </td>
                    <td>
                      <input
                        className="form-control"
                        disabled={
                          outboundObj?.marketplace_details?.marketplace === MARKET_PLACES.amazon ||
                          checkItemsQty(index) ||
                          (checkDrawerItem(outboundItemsObj) && !isFakeInv(y))
                        }
                        type="number"
                        value={y?.qty}
                        onChange={(e) => updateItemObjFun(y?.id, index, e.target.value, "qty")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="float-right text-danger">{itemQtyValidation}</div>
        </div>
        <hr />
        <div className="info">
          <div className="d-flex size">
            <label>Size</label>
            <div className="input-group">
              <input
                className="form-control"
                value={dimensions.length || ""}
                type="number"
                step="1"
                onChange={(e) => {
                  getDim(e, "length");
                }}
              />
              <span className="input-group-text" id="basic-addon2">
                L
              </span>
            </div>
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                step="1"
                value={dimensions.width || ""}
                onChange={(e) => {
                  getDim(e, "width");
                }}
              />
              <span className="input-group-text" id="basic-addon2">
                W
              </span>
            </div>
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                step="1"
                value={dimensions.height || ""}
                onChange={(e) => {
                  getDim(e, "height");
                }}
              />
              <span className="input-group-text" id="basic-addon2">
                H
              </span>
            </div>
          </div>
          <div className="d-flex size">
            <label>Weight</label>
            <div className="input-group">
              <input
                id="weight"
                className="form-control"
                type="number"
                step="0.01"
                min="0.01"
                value={dimensions.weight || dimensions?.ozWeight / 16 || ""}
                onKeyPress={(e) => {
                  if (+e.target.value < 0) return e.preventDefault();
                  if (+e.target.value >= 100000) return e.preventDefault();
                }}
                onChange={(e) => {
                  if (+e.target.value < 0) return e.preventDefault();
                  setWeight(null);
                  getDim(e, "weight");
                }}
              />
              <span className="input-group-text" id="basic-addon2">
                lb
              </span>
            </div>
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                step="1"
                value={dimensions.ozWeight || dimensions?.weight * 16 || ""}
                onKeyPress={(e) => {
                  if (+e.target.value < 0) return e.preventDefault();
                  else if (+e.target.value >= 100000) return e.preventDefault();
                }}
                onChange={(e) => {
                  if (+e.target.value < 0) return e.preventDefault();
                  setOzWeight(null);
                  getDim(e, "ozWeight");
                }}
              />
              <span className="input-group-text" id="basic-addon2">
                oz
              </span>
            </div>
          </div>
          <FormGroup className="select2-container  d-flex">
            <label className="control-label">Label Service</label>
            <SimpleSelect
              id="label_service"
              name="label_service"
              classNamePrefix="select2-selection"
              value={{ value: labelService?.value || "", label: labelService?.label || "" }}
              onChange={(e) => setLabelService({ value: e.value, label: e.label })}
              options={map(
                filterLabelServices(createLableObj?.marketplace_order_id, stampsCreds, createLableObj?.account_id),
                (value, label) => ({ value, label: startCase(label) }),
              )}
              formStyle={{ Width: "100%" }}
            />
          </FormGroup>
          <FormGroup className="select2-container  d-flex">
            <label className="control-label">Service</label>
            <div className="w-100 py-2 d-flex flex-column " style={{ rowGap: "4px" }}>
              <SimpleSelect
                name="service"
                classNamePrefix="select2-selection"
                value={{
                  value: serviceFilter?.value || minShippingRate?.service || "",
                  label: serviceFilter?.label || getServiceLabel(minShippingRate) || "",
                }}
                onChange={(e) => setServiceFilter({ value: e.value, label: e.label })}
                options={rateOptions}
                formStyle={{ Width: "100%" }}
              />
              <RenderIf isTrue={!isEmpty(shippingRates)}>
                <div style={{ color: "#2E3B74", backgroundColor: "#DDE3F9", padding: "8px 8px", borderRadius: "6px" }}>
                  <div className="d-inline-flex align-items-center gap-1">
                    <i className="bx bx-info-circle bx-xs" />
                    <span style={{ color: "#2E3B74", fontSize: "12px", fontWeight: 700 }}>Next 3 Cheapest Options</span>
                  </div>
                  <ul style={{ marginBlockEnd: "0rem", paddingInlineStart: "1.125rem" }}>
                    {map(slice(rateOptions, 1, 4), ({ label }, index) => (
                      <li key={`__${label}-${index}__`} style={{ color: "#2E3B74", fontSize: "12px", fontWeight: 500 }}>
                        {label}
                      </li>
                    ))}
                  </ul>
                </div>
              </RenderIf>
              <small className="text-warning" style={{ fontSize: "12px" }}>
                {"Estimated Shipping of this order is "}
                <b>{`${formatNumber(createLableObj?.est_shipping || 0)}`}</b>
              </small>
            </div>
          </FormGroup>
          <FormGroup className="select2-container  d-flex">
            <label className="control-label">Package</label>
            <SimpleSelect
              classNamePrefix="select2-selection"
              value={{
                value: "Cubic",
                label: "Cubic",
              }}
              options={[{ value: "Cubic", label: "Cubic" }]}
              formStyle={{ Width: "100%" }}
            />
          </FormGroup>
          <div className="d-flex justify-content-between est-time align-items-center">
            <div className="delivery-days">
              <span className="fs-16">Est. Delivery Days</span>
              <span className="fs-14">{selectedService?.delivery_days}</span>
            </div>
            <div className="rate d-flex">
              <span className="fs-16">Rate</span>
              <span className="fs-14">{selectedService?.rate || minShippingRate?.rate}</span>
              <div className="position-relative custom-tooltip">
                {getRatesTooltip(dimensions)}
                <Button
                  type="reset"
                  src={Refresh}
                  outline
                  color="primary"
                  className="btn btn-outline-primary waves-effect waves-light m-1"
                  disabled={!isEnabled(dimensions) || isNegative(dimensions)}
                  id="tooltip-fetch-rates"
                  onClick={() => {
                    if (isEnabled(dimensions) || !isNegative(dimensions)) {
                      setServiceFilter({ value: "", label: "" });
                      dispatch(
                        fetchAllShippingRates({
                          data: {
                            item_id: createLableObj?.item_id,
                            warehouse_id: createLableObj?.warehouse_id,
                            dimensions: dimensions || createLableObj?.dimensions,
                            customer_address: createLableObj?.customer_address,
                            label_service: labelService?.value,
                            label_from: "outbound",
                          },
                        }),
                      );
                    } else {
                      return null;
                    }
                  }}
                >
                  Pick Rates
                </Button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="drawer-footer d-flex justify-content-end">
          <Button
            type="reset"
            color="primary"
            onClick={() => {
              dispatch(generateShippingLabelToggle(false));
              setServiceFilter({ label: "", value: "" });
              setDimensions({ length: "", width: "", height: "", weight: "", ozWeight: "" });
              setItemQtyValidation("");
            }}
            outline
            className="btn btn-outline-primary waves-effect waves-light m-1"
          >
            Cancel
          </Button>
          <Button
            disabled={
              !getAllShippingRates?.data?.all_rates?.rates?.length ||
              itemQtyValidation ||
              (outboundItemsObj?.length && !outboundItemsObj?.some((x) => x?.addedToShipment)) ||
              !isValidDimensions(dimensions)
            }
            onClick={() => {
              generateShippingLabel();
              dispatch(generateShippingLabelToggle(false));
              setServiceFilter({ label: "", value: "" });
              setDimensions({ length: "", width: "", height: "", weight: "", ozWeight: "" });
            }}
            type="submit"
            color="primary"
            className="btn btn-primary m-1"
          >
            Generate Label
          </Button>
        </div>
      </OffCanvas>
    </>
  );
};

export default OutboundItems;
