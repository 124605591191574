import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as types from "./actionTypes";
import * as apis from "../../api";
import * as actions from "./actions";
import { merge } from "lodash";

function * fetchWarehouseInsightChargesStatsSaga ({ payload: { params } }) {
  try {
    const res = yield call(apis.warehouseInsightChargesStatsAPI, params);
    yield put(actions.fetchWarehouseInsightChargesStatsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchWarehouseInsightInboundStatsSaga ({ payload: { params } }) {
  try {
    const res = yield call(apis.warehouseInsightInboundStatsAPI, params);
    yield put(actions.fetchWarehouseInsightInboundStatsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchWarehouseInsightStorageStatsSaga ({ payload: { params } }) {
  try {
    const res = yield call(apis.warehouseInsightStorageStatsAPI, params);
    yield put(actions.fetchWarehouseInsightStorageStatsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchWarehouseInsightOutboundStatsSaga ({ payload: { params } }) {
  try {
    const res = yield call(apis.warehouseInsightOutboundStatsAPI, params);
    yield put(actions.fetchWarehouseInsightOutboundStatsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchWarehouseInsightLabelGeneratedGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(apis.fetchWarehouseInsightLabelGraphAPI, params);
    yield put(actions.fetchWarehouseInsightLabelGeneratedGraphSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

function * fetchWarehouseInsightLabelCancelledGraphSaga ({ payload: { params } }) {
  try {
    const res = yield call(apis.fetchWarehouseInsightLabelGraphAPI, merge(params, { status: "cancelled" }));
    yield put(actions.fetchWarehouseInsightLabelCancelledGraphSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchFetchWarehouseInsightChargesStats () {
  yield takeEvery(types.FETCH_WAREHOUSE_INSIGHT_CHARGES_STATS, fetchWarehouseInsightChargesStatsSaga);
}
export function * watchFetchWarehouseInsightInboundStats () {
  yield takeEvery(types.FETCH_WAREHOUSE_INSIGHT_INBOUND_STATS, fetchWarehouseInsightInboundStatsSaga);
}
export function * watchFetchWarehouseInsightStorageStats () {
  yield takeEvery(types.FETCH_WAREHOUSE_INSIGHT_STORAGE_STATS, fetchWarehouseInsightStorageStatsSaga);
}
export function * watchFetchWarehouseInsightOutboundStats () {
  yield takeEvery(types.FETCH_WAREHOUSE_INSIGHT_OUTBOUND_STATS, fetchWarehouseInsightOutboundStatsSaga);
}
export function * watchFetchWarehouseInsightLabelGeneratedGraph () {
  yield takeEvery(types.FETCH_WAREHOUSE_INSIGHT_LABELS_GENERATED_GRAPH, fetchWarehouseInsightLabelGeneratedGraphSaga);
}
export function * watchFetchWarehouseInsightLabelCancelledGraph () {
  yield takeEvery(types.FETCH_WAREHOUSE_INSIGHT_LABELS_CANCELLED_GRAPH, fetchWarehouseInsightLabelCancelledGraphSaga);
}

function * insightSaga () {
  yield all([
    fork(watchFetchWarehouseInsightChargesStats),
    fork(watchFetchWarehouseInsightInboundStats),
    fork(watchFetchWarehouseInsightStorageStats),
    fork(watchFetchWarehouseInsightOutboundStats),
    fork(watchFetchWarehouseInsightLabelGeneratedGraph),
    fork(watchFetchWarehouseInsightLabelCancelledGraph),
  ]);
}

export default insightSaga;
