import React, { useEffect, useState } from "react";
import ReactTable from "../../../components/Common/Table";
import CheckBox from "../../../components/Common/CheckBox";
import { removeURLQueryParams, RenderIf } from "../../../utils/common";
import { Alert, Card } from "reactstrap";
import * as $ from "lodash";
import queryString from "query-string";
import moment from "moment";
import * as actions from "../../../store/actions";
import PillBtn from "../../../components/Common/PillBtn";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import TagsModal from "./Components/TagsModal";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { setPreloader } from "../../../store/actions";
import Preloader from "../../../components/Common/Preloader";
import CustomPagination from "../../../components/Common/CustomPagination";
import StatusBadge from "../../../components/Common/StatusBadge";

function Tags () {
  const queryParams = queryString.parse(document.location.search);
  const dispatch = useDispatch();
  const [selectedPlatform, setSelectedPlatform] = useState({ label: "Walmart", value: "walmart" });
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({});
  const { repricerTagsModalIsOpen, tagsLoading, tags, success, error } = useSelector((state) => state.Repricer);
  const [operation, setOperation] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [currentTagData, setCurrentTagData] = useState(null);
  const [checkBox, setCheckBox] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const selectedTagsCount = $.size($.keys(checkBox));
  const checkBoxState = selectAll || $.isEqual(selectedTagsCount, $.size(tags?.results));
  const isAnyListingSelected = selectAll || selectedTagsCount;
  const selectedTags = `Selected ${selectedTagsCount} tag${selectedTagsCount > 1 ? "s" : ""}`;
  const tableHeaders = [
    {
      hidden: false,
      id: "name",
      title: "Tag",
      content: ({ data }) => (
        <div className="inventory-color">
          <div className="bg-soft-primary rounded-pill px-3 py-1" style={{ width: "fit-content" }}>
            {data?.name}
          </div>
        </div>
      ),
    },
    {
      hidden: false,
      id: "assigned_skus",
      title: "Assigned SKUs",
      content: ({ data }) => <div>{data?.assigned_skus}</div>,
    },
    {
      hidden: false,
      id: "created_at",
      title: "Date Created",
      content: ({ data }) => <div>{moment(data?.created_at).format("LLL")}</div>,
    },
    {
      hidden: false,
      id: "actions",
      title: "Actions",
      content: ({ data }) => (
        <div className="d-flex align-items-center gap-1">
          <span className="font-size-12">
            <i
              className="mx-1 cursor-pointer text-primary bx bx-sm bx-edit"
              onClick={() => {
                dispatch(actions.setRepricerTagsModal(true));
                setOperation("Edit");
                setTagId(data?.id);
                setCurrentTagData(data);
              }}
            />
            <i
              className="mx-1 cursor-pointer text-danger bx bx-sm bx-trash-alt"
              onClick={() => {
                dispatch(actions.setRepricerTagsModal(true));
                setOperation("Delete");
                setTagId(data?.id);
                setCurrentTagData(data);
              }}
            />
          </span>
        </div>
      ),
    },
  ].filter(({ hidden }) => !hidden);
  const columnsToShow = $.keyBy(tableHeaders, "id");

  const handleSearch = (e) => {
    e.preventDefault();
    if (!search.trim()) return;
    setFilters({
      ...filters,
      search: search.trim(),
      page: 1,
    });
  };

  useEffect(() => {
    if (!selectedPlatform.value) return;
    dispatch(actions.getTags(selectedPlatform.value, $.merge(
      { ...filters, limit: filters.per_page, offset: (filters?.page - 1) * filters?.per_page },
    )));
  }, [filters, selectedPlatform]);

  useEffect(() => {
    removeURLQueryParams();
  }, []);

  useEffect(() => {
    dispatch(setPreloader(tagsLoading));
  }, [tagsLoading]);

  return (
    <>
      <RenderIf isTrue={tagsLoading}>
        <Preloader />
      </RenderIf>
      <div className="p-2">
        <RenderIf isTrue={repricerTagsModalIsOpen}>
          <TagsModal
            currentTagData={currentTagData}
            tagId={tagId}
            title={`${["Delete"].includes(operation) ? "Delete" : operation} Tag`}
            buttonTitle={["Delete", "Edit"].includes(operation) ? "Confirm" : "Create"}
            selectedPlatform={selectedPlatform.value}
            filters={filters}
            setFilters={setFilters}
          />
        </RenderIf>
        <RenderIf isTrue={success}>
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-check pr-2" />
              {success || ""}
            </Alert>
          </div>
        </RenderIf>
        <RenderIf isTrue={error}>
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2" />
              {error || ""}
            </Alert>
          </div>
        </RenderIf>
        <Breadcrumb
          title="Tags"
          children={
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex inputs-group mr-3 my-2">
                <div className="d-flex align-items-center">
                  <i className="ml-3 font-size-16 bx bx bx-search" />
                </div>
                <form className="app-search p-0 d-lg-block" onSubmit={handleSearch}>
                  <div className="position-relative">
                    <i
                      onClick={handleSearch}
                      className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                    />
                    <input
                      disabled={tagsLoading}
                      id="search_field"
                      type="text"
                      className="form-control search-input"
                      placeholder="Search Tag..."
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setFilters({ search: "", page: 1 });
                          setSearch("");
                        } else setSearch(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="mr-3">
                <SimpleSelect
                  className="mx-1 my-2"
                  name="Platform"
                  value={selectedPlatform}
                  onChange={setSelectedPlatform}
                  options={[
                    { label: "Walmart", value: "walmart" },
                    { label: "Amazon", value: "amazon" },
                  ]}
                  formStyle={{ width: "132px" }}
                  classNamePrefix="select2-selection"
                />
              </div>
              <div>
                <PillBtn
                  className="my-2"
                  title="Add New Tag"
                  name="Add New Tag"
                  color="primary"
                  icon="bx bx-xs bx-plus"
                  onClick={() => {
                    dispatch(actions.setRepricerTagsModal(true));
                    setOperation("Add");
                    setTagId(null);
                    setCurrentTagData([]);
                  }}
                />
              </div>
            </div>
          }
        />
        <Card className="p-4">
          <RenderIf isTrue={isAnyListingSelected}>
            <div className="d-flex align-items-center">
              <PillBtn
                className="my-2 mb-3"
                title="Delete all selected tags"
                name="Delete all selected tags"
                color="primary"
                onClick={() => {
                  const resetFilters = () => {
                    if (filters.page > 1) setFilters({ ...filters, page: 1 });
                    setCheckBox({});
                  };
                  dispatch(actions.deleteAllTags(selectedPlatform.value, { tags: $.keys(checkBox) }, resetFilters));
                }}
              />
              <StatusBadge
                className="m-2 mb-3"
                status={selectedTags}
                colorsMapping={{ [selectedTags]: "success" }}
              />
            </div>
          </RenderIf>
          <ReactTable
            tableHeader={
              <>
                <th>
                  <CheckBox
                    state={isAnyListingSelected && checkBoxState}
                    setState={(e) => {
                      setSelectAll(false);
                      checkBoxState
                        ? setCheckBox({})
                        : setCheckBox($.reduce(tags?.results, (acc, cur) => $.merge(acc, { [cur.id]: true }), {}));
                    }}
                  />
                </th>
                {$.values(columnsToShow).map((header, index) => (
                  <th key={`table-header ${index}`}>{header.title}</th>
                ))}
              </>
            }
            style={{ width: "100%", overflow: "auto" }}
            tableBody={
              <RenderIf
                isTrue={!$.isEmpty(tags?.results)}
                fallback={
                  <RenderIf isTrue={!tagsLoading}>
                    <tr>
                      <td colSpan="100%">
                        <h2 className="text-center">No Records Found</h2>
                      </td>
                    </tr>
                  </RenderIf>
                }
              >
                {$.map(tags?.results, (data, key) => (
                  <tr key={`table-row-${key}`}>
                    <th>
                      <CheckBox
                        state={selectAll || checkBox[data.id] || false}
                        setState={(e) => {
                          setSelectAll(false);
                          checkBox[data.id] ? delete checkBox[data.id] : (checkBox[data.id] = true);
                          setCheckBox({ ...checkBox });
                        }}
                      />
                    </th>
                    {$.values(columnsToShow).map((header, colKey) => (
                      <td key={`table-col-${colKey}`}>
                        <header.content data={data} index={key} />
                      </td>
                    ))}
                  </tr>
                ))}
              </RenderIf>
            }
          />
          <CustomPagination
            total={tags?.count}
            page={filters?.page || +queryParams?.page || 1}
            perPage={filters?.perPage || +queryParams?.per_page || 50}
            tabsFilter={filters}
            setTabFilter={setFilters}
            pageOptions={[50, 100, 250]}
            repricer={true}
          />
        </Card>
      </div>
    </>
  );
}

export default Tags;
