import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Fade } from "reactstrap";
import CustomTooltip from "./CustomTooltip";

function FadeEffect (props) {
  const identifier = props?.note?.users_notifications[0].id;
  const { fadeStatuses, setFadeStatuses } = props;
  const [title] = (props?.note?.title || "").split(String.fromCharCode(255));
  return (
    <>
      <Fade
        in={fadeStatuses[identifier]}
        onExited={() => props.removeNotification(identifier, `_notifications_${props?.index}`)}
        timeout={100}
      >
        <div className="text-reset notification-item">
          <div className="media">
            <div className="media-body d-flex justify-content-between">
              <Link
                key={`_notifications_${identifier}`}
                to={props?.note?.link || "#"}
                className="text-reset notification-item"
                onClick={() => {
                  props.removeNotification(identifier, `_notifications_${props?.index}`);
                  props.toggleMenu && props.toggleMenu();
                }}
              >
                <h6 className="mt-0 mb-1">{title}</h6>
                <div className="font-size-12 text-muted">
                  <p className="mb-1">{props?.note?.description}</p>
                  <p className="mb-0">
                    <i className="mdi mdi-clock-outline"></i> {moment(props.note?.created_at).fromNow()}{" "}
                  </p>
                </div>
              </Link>
              <CustomTooltip placement="top" content="Mark As Read" target={`tooltip-${identifier}`} />
              <Button
                tag="i"
                color="link"
                onClick={() => {
                  setFadeStatuses({ ...fadeStatuses, [identifier]: false });
                }}
              >
                <i id={`tooltip-${identifier}`} className="bx bx-check-circle fa-la" />
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}

export default FadeEffect;
