import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "reactstrap";
import { createNotificationEmails } from "../../../store/actions";
import { toPascalCase } from "../../../utils/common";

import DynamicFields from "../../../components/Common/DynamicFields";

const NotificationSettingsModel = (props) => {
  const { model, setModel } = props;
  const dispatch = useDispatch();
  const [nestedVariation, setNestedVariation] = useState([{ name: "", value: "", error: [] }]);

  const toggleModal = (_) => setModel({ isOpen: !model.isOpen });

  function handleCreateNotificationEmails (nestedVariation, type) {
    dispatch(createNotificationEmails(nestedVariation, type));
  }

  return (
    <Modal size="md" isOpen={model.isOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Update Notification Setting
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        <h6>Notifications Type - {toPascalCase(model.type)}</h6>
        <div className="scrollable-div-450 mt-2">
          <DynamicFields
            title="New Email"
            isMulti={false}
            nestedFields={nestedVariation}
            setNestedFields={setNestedVariation}
          />
        </div>
      </div>
      <div className="modal-footer col">
        <Button
          type="submit"
          color="success"
          className="mr-3"
          onClick={() => {
            const errors = nestedVariation.reduce((arr, x) => (x.error.length ? [...arr, x.error] : arr), []);
            const mails = nestedVariation.reduce((arr, x) => (!x.error.length && x.name ? [...arr, x.name] : arr), []);
            if (errors.length || !mails.length) return;

            handleCreateNotificationEmails(mails, model.type);
            setNestedVariation([{ name: "", value: "", error: [] }]);
            toggleModal();
          }}
        >
          Update
        </Button>
      </div>
    </Modal>
  );
};

export default NotificationSettingsModel;
