import React, { useState, useRef, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment-timezone";
import { merge } from "lodash";
import { RenderIf } from "../../utils/common";
import { DATE_FORMATS } from "../../constants";

const formatDate = (val) => {
  const [start, end] = val || [null, null];
  return {
    start_date: start ? moment(start).tz(moment.tz.guess()).format("YYYY-MM-DD") : null,
    end_date: end ? moment(end).tz(moment.tz.guess()).format("YYYY-MM-DD") : null,
  };
};

const getDateFilters = (val) => merge(formatDate(val), { utc_offset: moment().format("Z") });

function DatePicker ({
  initialDates,
  onClose,
  className = "",
  pickrClass = "",
  style = {},
  format,
  placeholder = "Select Date Range",
  onCross, // Dates to be set when X is clicked
  filters,
  handleDurationChange,
  hideCrossIcon,
  maxDate,
  updateInitialDates,
}) {
  const [dates, setDates] = useState(initialDates || [null, null]);
  const pickrOpen = useRef(false);

  useEffect(() => {
    if (filters) {
      !filters.start_date && !filters.end_date && dates?.every(Boolean) && setDates([null, null]);
    }
  }, [filters]);

  useEffect(() => {
    updateInitialDates && setDates(initialDates || [null, null]);
  }, [initialDates]);

  return (
    <div className={`d-flex ${className}`} style={{ width: "250px", ...style }}>
      <Flatpickr
        className={`form-control rounded-pill flatpickr-input ${pickrClass.includes("pl") ? pickrClass : "pl-4"}`}
        placeholder={placeholder}
        value={dates}
        options={{
          mode: "range",
          dateFormat: format || DATE_FORMATS.DATE_PICKR,
          defaultDate: dates,
          ...(maxDate && { maxDate }),
        }}
        onOpen={() => (pickrOpen.current = true)}
        onClose={(val) => {
          setDates(val);
          if (pickrOpen.current) {
            onClose(getDateFilters(val));
            pickrOpen.current = false;
          }
        }}
      />
      <RenderIf isTrue={dates.some((x) => x) && !hideCrossIcon}>
        <i
          title="Reset date filter"
          className="bx bx-sm mdi mdi-close pt-1 text-danger cursor-pointer"
          onClick={() => {
            setDates(onCross || [null, null]);
            onClose(getDateFilters(onCross));
            if (handleDurationChange) handleDurationChange("sevenDays", 5);
          }}
        />
      </RenderIf>
    </div>
  );
}

export { formatDate, DatePicker };
