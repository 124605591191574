import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllRestrictedBrands,
  bulkUpdateRestrictedBrands,
  addNewBrand,
  updateBrand,
  deleteBrand,
  bulkAddNewBrand,
} from "../../api";
// Login Redux States
import {
  FETCH_RESTRICTED_BRANDS,
  BRANDS_BULK_UPDATE,
  ADD_RESTRICTED_BRANDS,
  UPDATE_RESTRICTED_BRANDS,
  DEL_RESTRICTED_BRANDS,
  BULK_ADD_RESTRICTED_BRANDS,
} from "./actionTypes";

import {
  apiError,
  fetchRestrictedBrandsSuccess,
  bulkUpdateBrandsSuccess,
  setBrandsBulkUpdateModal,
  setSelectedBrands,
  addNewBrandSuccess,
  deleteBrandSuccess,
  updateBrandSuccess,
  bulkAddNewBrandSuccess,
} from "./actions";

const RestrictedBrands = (state) => state.RestrictedBrands;

function * fetchRestrictedBrandsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllRestrictedBrands, params);
    yield put(fetchRestrictedBrandsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateBrandSaga ({ payload }) {
  try {
    const res = yield call(updateBrand, payload);
    const brands = yield select(RestrictedBrands);
    if (res.success) {
      const index = brands.restrictedBrands.results.findIndex((x) => x.id === res.brand.id);
      index !== undefined && (brands.restrictedBrands.results[index].name = res.brand.name);
      brands.editBrandModalIsOpen = false;
    }
    yield put(updateBrandSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * addNewBrandSaga ({ payload }) {
  try {
    const res = yield call(addNewBrand, payload);
    const brands = yield select(RestrictedBrands);
    if (res.success) {
      brands?.restrictedBrands?.results &&
        brands.restrictedBrands.results.unshift({
          ...res.brand,
          blacklist: true,
        });
      brands.restrictedBrands.results.length = Math.max(brands.restrictedBrands.per_page, 30);
      brands.restrictedBrands.per_page = Math.max(brands.restrictedBrands.per_page, 30);
      ++brands.restrictedBrands.total_count;
      brands.addBrandModalIsOpen = false;
    }
    yield put(addNewBrandSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkAddNewBrandSaga ({ payload }) {
  try {
    const res = yield call(bulkAddNewBrand, payload);
    const brands = yield select(RestrictedBrands);
    if (res.success) {
      brands.restrictedBrands.results = [...brands.restrictedBrands.results, ...res.brands].filter((x) => x.id);
      brands.restrictedBrands.total_count = res.total_count;
      brands.restrictedBrands.per_page = res.per_page;
      brands.restrictedBrands.page_number = res.page_number;
      brands.addBrandModalIsOpen = false;
    }
    yield put(bulkAddNewBrandSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteBrandSaga ({ payload }) {
  try {
    const res = yield call(deleteBrand, payload);
    const brands = yield select(RestrictedBrands);
    if (res.success) {
      const brandIds = Array.isArray(res.platform_brand.brand_id)
        ? res.platform_brand.brand_id
        : [res.platform_brand.brand_id];
      if (brands?.restrictedBrands?.results) {
        if (!brandIds.length) brands.restrictedBrands.results = [];
        else brands.restrictedBrands.results = brands.restrictedBrands.results.filter((x) => !brandIds.includes(x.id));
        brands.restrictedBrands.total_count = brands.restrictedBrands?.total_count - brandIds.length;
        brands.restrictedBrands.per_page = brands.restrictedBrands.per_page - brandIds.length;
      }
    }
    yield put(deleteBrandSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkUpdateBrandsSaga ({ payload: { data } }) {
  try {
    const res = yield call(bulkUpdateRestrictedBrands, data);
    if (res?.success && data.update_type === "current_page") {
      const brands = yield select(RestrictedBrands);

      let shouldUpdateIds = [];
      if (data.brand_ids.length === 1) shouldUpdateIds = data.brand_ids;
      else shouldUpdateIds = brands.selectedBrands.map((x) => x);

      let index = "";
      shouldUpdateIds.forEach((x) => {
        index = brands.restrictedBrands.results?.findIndex((y) => y.id === x);
        brands.restrictedBrands.results[index].blacklist = JSON.parse(data.blacklist);
      });
    } else if (res?.success && data.update_type === "all_selection") {
      // Refresh the Page in the case of All Selections
    }
    yield put(setBrandsBulkUpdateModal(false));
    yield put(setSelectedBrands([]));
    yield put(bulkUpdateBrandsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchRestrictedBrandsFetch () {
  yield takeEvery(FETCH_RESTRICTED_BRANDS, fetchRestrictedBrandsSaga);
}

export function * watchBulkBrandsUpdate () {
  yield takeEvery(BRANDS_BULK_UPDATE, bulkUpdateBrandsSaga);
}

export function * watchUpdateBrandSaga () {
  yield takeEvery(UPDATE_RESTRICTED_BRANDS, updateBrandSaga);
}

export function * watchAddNewBrandSaga () {
  yield takeEvery(ADD_RESTRICTED_BRANDS, addNewBrandSaga);
}

export function * watchBulkAddNewBrandSaga () {
  yield takeEvery(BULK_ADD_RESTRICTED_BRANDS, bulkAddNewBrandSaga);
}

export function * watchDeleteBrandSaga () {
  yield takeEvery(DEL_RESTRICTED_BRANDS, deleteBrandSaga);
}

function * restrictedBrandsSaga () {
  yield all([
    fork(watchBulkAddNewBrandSaga),
    fork(watchRestrictedBrandsFetch),
    fork(watchBulkBrandsUpdate),
    fork(watchUpdateBrandSaga),
    fork(watchAddNewBrandSaga),
    fork(watchDeleteBrandSaga),
  ]);
}

export default restrictedBrandsSaga;
