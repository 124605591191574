export const FETCH_SUPPLIERS = "FETCH_SUPPLIERS";
export const FETCH_SUPPLIERS_SUCCESS = "FETCH_SUPPLIERS_SUCCESS";
export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const UPDATE_AO_STATUS = "UPDATE_AO_STATUS";
export const UPDATE_AO_STATUS_SUCCESS = "UPDATE_AO_STATUS_SUCCESS";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const API_ERROR = "SUPPLIER_ERROR";
export const RESET_STATE = "RESET_SUPPLIERS";
export const FETCH_AO_STATUS = "FETCH_AO_STATUS";
export const FETCH_AO_STATUS_SUCCESS = "FETCH_AO_STATUS_SUCCESS";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const SET_SKU_AS_MISMATCH = "SET_SKU_AS_MISMATCH";
export const SET_SKU_AS_MISMATCH_SUCCESS = "SET_SKU_AS_MISMATCH_SUCCESS";
export const LISTING_SUPPLIERS_COLLAPSE = "LISTING_SUPPLIERS_COLLAPSE";
export const SET_ACTION_SUPPLIER = "SET_ACTION_SUPPLIER";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS";
export const SET_STOCK_MODAL = "SET_STOCK_MODAL";
export const FETCH_REFRESHERS = "FETCH_REFRESHERS";
export const FETCH_REFRESHERS_SUCCESS = "FETCH_REFRESHERS_SUCCESS";
