import {
  FETCH_RESTRICTED_BRANDS,
  FETCH_RESTRICTED_BRANDS_SUCCESS,
  SET_SELECTED_BRANDS,
  SET_BRANDS_BULK_UPDATE_MODAL,
  BRANDS_BULK_UPDATE,
  BRANDS_BULK_UPDATE_SUCCESS,
  ADD_RESTRICTED_BRANDS,
  ADD_RESTRICTED_BRANDS_SUCCESS,
  DEL_RESTRICTED_BRANDS,
  DEL_RESTRICTED_BRANDS_SUCCESS,
  UPDATE_RESTRICTED_BRANDS,
  UPDATE_RESTRICTED_BRANDS_SUCCESS,
  SET_BRAND_ADD_MODAL,
  SET_BRAND_BULK_ADD_MODAL,
  SET_BRAND_EDIT_MODAL,
  API_ERROR,
  BULK_ADD_RESTRICTED_BRANDS,
  BULK_ADD_RESTRICTED_BRANDS_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  restrictedBrands: undefined,
  selectedBrands: [],
  addBrandModalIsOpen: false,
  editBrandModalIsOpen: false,
  brandsBulkUpdateModalIsOpen: false,
  bulkAddBrandModalIsOpen: false,
  success: "",
};

const RestrictedBrands = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESTRICTED_BRANDS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_RESTRICTED_BRANDS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        restrictedBrands: action.payload,
        loading: false,
      };
      break;
    case SET_SELECTED_BRANDS:
      state = {
        ...state,
        selectedBrands: action.payload,
      };
      break;
    case SET_BRANDS_BULK_UPDATE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        brandsBulkUpdateModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case BRANDS_BULK_UPDATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case BRANDS_BULK_UPDATE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Brands have been Updated Successfully",
        loading: false,
      };
      break;
    case SET_BRAND_ADD_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        addBrandModalIsOpen: action.payload,
      };
      break;
    case SET_BRAND_BULK_ADD_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        bulkAddBrandModalIsOpen: action.payload,
      };
      break;
    case SET_BRAND_EDIT_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        editBrandModalIsOpen: action.payload,
      };
      break;
    case ADD_RESTRICTED_BRANDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_RESTRICTED_BRANDS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Brand added Successfully!",
        loading: false,
      };
      break;
    case BULK_ADD_RESTRICTED_BRANDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case BULK_ADD_RESTRICTED_BRANDS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Bulk Brands added Successfully!",
        loading: false,
      };
      break;
    case DEL_RESTRICTED_BRANDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DEL_RESTRICTED_BRANDS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Brand Deleted Successfully!",
        loading: false,
      };
      break;
    case UPDATE_RESTRICTED_BRANDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_RESTRICTED_BRANDS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Brand Updated Successfully!",
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RestrictedBrands;
