import request from "../restClient";
import { parseQP } from "../../utils/common";

export const getScreenshots = async (folderURL) => {
  try {
    let data = {};
    if (folderURL?.includes("prefix=")) {
      const parsed = parseQP(folderURL);
      data = { bucket: parsed.baseUrl.split("/").pop(), prefix: parsed.prefix };
    } else {
      const [bucket, ...prefixes] = folderURL?.split("buckets/").pop().split("/");
      data = { prefix: prefixes.join("/"), bucket };
    }

    const urls = await request({
      url: `/aws_utils/get_ss?bucket=${data.bucket}&prefix=${encodeURIComponent(data.prefix)}`,
      method: "GET",
    });
    if (!urls.success) throw new Error("Error");
    return urls.data;
  } catch (err) {
    return [];
  }
};

export const getLogs = async (url) => {
  try {
    const resp = await request({ url: "/aws_utils/get_logs", method: "POST", body: { url } });
    return resp.logs;
  } catch (err) {
    return [];
  }
};
