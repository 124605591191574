import { checkFilterValue, getFilterValueLabel } from "./common";
import { RenderIf } from "../../../utils/common";
import $ from "lodash";
import FilterCard from "./FilterCard";
import FilterChip from "./FilterChip";
import FilterContext from "./FilterContext";
import PillBtn from "../../../components/Common/PillBtn";
import React, { useContext } from "react";
import SimpleSelect from "../../../components/Common/SimpleSelect";

export default function DropDown ({ filterKey, filterTitle }) {
  const { dropDownValues, localFilters, setFilter, removeFilter, addedFilters, addFilter } = useContext(FilterContext);
  const filterValue = localFilters[filterKey];
  const filterValueLabel = getFilterValueLabel(dropDownValues, filterKey, filterValue, null);
  const filterOptions = $.reduce(
    $.get(dropDownValues, filterKey),
    (acc, label, value) => [...acc, { label, value }],
    [],
  );
  const canAddFilter = checkFilterValue(filterValue);
  const isFilterAdded = addedFilters[filterKey];
  return (
    <FilterCard filterKey={filterKey} filterTitle={filterTitle}>
      <RenderIf isTrue={isFilterAdded}>
        <FilterChip
          filterTitle={filterTitle}
          filterValueLabel={filterValueLabel}
          removeFilter={() => removeFilter(filterKey)}
        />
      </RenderIf>
      <div className="w-100 d-flex justify-content-start align-items-end gap-3">
        <div style={{ flexBasis: "40%" }}>
          <SimpleSelect
            disabled={isFilterAdded}
            label="Select Value to Apply Filter"
            className={`mb-0 ${isFilterAdded ? "cursor-not-allowed" : ""}`}
            options={filterOptions}
            value={{ label: filterValueLabel ?? "Select Value", value: filterValue }}
            onChange={(e) => {
              const value = Number(e.value);
              setFilter(filterKey, $.isFinite(value) ? value : e.value);
            }}
          />
        </div>
        <PillBtn
          disabled={!canAddFilter || isFilterAdded}
          className={`${!canAddFilter || isFilterAdded ? "cursor-not-allowed" : ""}`}
          color="primary"
          name="Add"
          onClick={() => addFilter(filterKey)}
        />
      </div>
    </FilterCard>
  );
}
