import React, { useState, useEffect, Suspense } from "react";
import ReactApexChart from "react-apexcharts";
import { humanize, RenderIf } from "../../../utils/common";
import moment from "moment";
import { Card, CardBody } from "reactstrap";
import { fetchOrderGraph } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { DATE_FORMATS } from "../../../constants";

const { DATE } = DATE_FORMATS;

const DateRange = React.lazy(() => import("../../../components/Common/DateRange"));
const ChartName = React.lazy(() => import("../components/ChartName"));

const AgencyOrders = () => {
  const dispatch = useDispatch();
  const { orderGraph, totalOrdersLoader } = useSelector((state) => state.Graph);
  const { archived } = useSelector((state) => state.Agency);
  const currentDate = moment();
  const oneWeekBefore = moment().subtract(6, "days");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);
  const series = [
    {
      name: "Orders Count",
      data: (orderGraph?.data?.length && orderGraph.data.map((x) => [x?.date, parseFloat(x.orders).toFixed(2)])) || [
        [dates[0]],
        [currentDate],
      ],
    },
  ];
  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: `${dates[1].diff(dates[0], "days") + 1 < 15 ? "7%" : "45%"}`,
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#556ee6"],
    xaxis: {
      tickPlacement: "on",
      type: "datetime",
      labels: {
        rotate: -35,
        rotateAlways: true,
        formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE), // Timestamp is ALWAYS UTC
      },
    },
    yaxis: { labels: { formatter: (x) => humanize(x) } },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `Orders: ${val}`;
        },
      },
    },
  };

  const fetchGraphDetails = ({ startDate, endDate, archived }) => {
    const sharedParams = { start_date: startDate, end_date: endDate };
    dispatch(
      fetchOrderGraph({
        ...sharedParams,
        utc_offset: moment().format("Z"),
        archived,
      }),
    );
  };

  useEffect(() => {
    fetchGraphDetails({
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
      archived,
    });
  }, [dates, archived]);

  return (
    <Card className="mh-493">
      <CardBody>
        <div className="mb-4 d-flex justify-content-between">
          <Suspense fallback={<></>}>
            <RenderIf isTrue={!totalOrdersLoader} fallback={<h4 className="card-title">Loading...</h4>}>
              <ChartName
                name="Orders"
                dates={dates}
                totalOrders={humanize(orderGraph?.data?.reduce((acc, x) => acc + parseFloat(x.orders), 0) || 0)}
              />
            </RenderIf>
            <DateRange
              dates={dates}
              setDates={setDates}
              loader={false} // to be set later
              timePeriods={[7, 30, 180, 365]}
              isInLine
            />
          </Suspense>
        </div>
        <div className="clearfix" />
        <div className="mr-3">
          <ReactApexChart options={options} series={series} type="bar" height={350} />
        </div>
      </CardBody>
    </Card>
  );
};

export default AgencyOrders;
