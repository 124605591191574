import React from "react";

function ErrorMessage ({ error }) {
  return (
    error
      ?.split("\n")
      ?.map((line, key) => (
        <span key={"_heading_error_" + key}>{key ? <h6>{line}</h6> : <h4 className="card-title mb-2">{line}</h4>}</span>
      )) || error
  );
}

export default ErrorMessage;
