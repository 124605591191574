import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { isEmpty } from "lodash";
import { fetchStrandedCount } from "../../store/actions";
import { getUserInfo, RenderIf } from "../../utils/common";

const InventoryAlert = (props) => {
  const dispatch = useDispatch();
  const { strandedCount } = useSelector((state) => state.WareHouses);

  useEffect(() => {
    isEmpty(strandedCount) && getUserInfo()?.role !== "wh_staff" && dispatch(fetchStrandedCount({}));
  }, []);

  return (
    <RenderIf isTrue={strandedCount?.data}>
      <Alert color="primary">
        <i className="bx bx-info-circle pr-2"></i>
        <span className="black-color" style={{ fontSize: "13px" }}>
          {"You have "}
          <b>{strandedCount?.data}</b> Items at the warehouse that will incur storage fees in 5 days. Please handle this
          inventory as soon as possible to avoid storage fees. <a href="/inventory-items">Click here</a>
          {" to view warehouse inventory."}
        </span>
      </Alert>
    </RenderIf>
  );
};
export default InventoryAlert;
