import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  SET_ADD_USER_MODAL,
  SET_EDIT_USER_MODAL,
  SET_SELECTED_USER_MODAL,
  FETCH_ALL_ACCOUNTS,
  FETCH_ALL_ACCOUNTS_SUCCESS,
  SEND_USER_INVITE,
  SEND_USER_INVITE_SUCCESS,
  SET_USER_INVITE_MODAL,
  SET_AGENCY_ACCOUNT_MODAL,
  ASSIGN_AGENCY_ACCOUNTS_TO_USERS,
  ASSIGN_AGENCY_ACCOUNTS_TO_USERS_SUCCESS,
  ASSIGN_AGENCIES,
  ASSIGN_AGENCIES_SUCCESS,
  COMPLETE_USER_ONBOARDING,
  COMPLETE_USER_ONBOARDING_SUCCESS,
  API_ERROR,
  EXPIRE_SESSION,
  EXPIRE_SESSION_SUCCESS,
  SET_CHANGE_PASSWORD_MODEL,
  SET_MANAGE_AGENCIES_MODAL,
  UPDATE_PROFILE_AND_COMPANY_INFO,
  UPDATE_PROFILE_AND_COMPANY_INFO_SUCCESS,
  FETCH_COMPANY_INFO,
  FETCH_COMPANY_INFO_SUCCESS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  SET_ADD_BALANCE_MODAL,
  FETCH_DISPUTE_LOGS,
  FETCH_DISPUTE_LOGS_SUCCESS,
  SET_TOS_ACCEPTANCE_MODAL,
  UPDATE_TOS_BIT,
  UPDATE_TOS_BIT_SUCCESS,
  UPDATE_TOS_FOR_ALL,
  UPDATE_TOS_FOR_ALL_SUCCESS,
  GET_AGENCY_CLIENTS,
  GET_AGENCY_CLIENTS_SUCCESS,
} from "./actionTypes";

export const fetchUsers = (params) => {
  return {
    type: FETCH_ALL_USERS,
    payload: { params },
  };
};

export const expireSession = (params) => {
  return {
    type: EXPIRE_SESSION,
    payload: { params },
  };
};

export const expireSessionSuccess = (params) => {
  return {
    type: EXPIRE_SESSION_SUCCESS,
    payload: params,
  };
};

export const fetchUsersSuccess = (data) => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: data,
  };
};

export const fetchAccounts = (params = {}) => {
  return {
    type: FETCH_ALL_ACCOUNTS,
    payload: { params },
  };
};

export const fetchAccountsSuccess = (data) => {
  return {
    type: FETCH_ALL_ACCOUNTS_SUCCESS,
    payload: data,
  };
};

export const createUser = (data) => {
  return {
    type: CREATE_USER,
    payload: data,
  };
};

export const createUserSuccess = (data) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: data,
  };
};

export const updateUser = ({ id, data, logout = true }) => {
  return {
    type: UPDATE_USER,
    payload: { id, data, logout },
  };
};

export const updateProfileAndCompanyInfo = ({ id, data }) => {
  return {
    type: UPDATE_PROFILE_AND_COMPANY_INFO,
    payload: { id, data },
  };
};

export const updateProfileAndCompanyInfoSuccess = (data) => {
  return {
    type: UPDATE_PROFILE_AND_COMPANY_INFO_SUCCESS,
    payload: data,
  };
};

export const fetchCompanyInfo = (id) => {
  return {
    type: FETCH_COMPANY_INFO,
    payload: id,
  };
};

export const fetchCompanyInfoSuccess = (data) => {
  return {
    type: FETCH_COMPANY_INFO_SUCCESS,
    payload: data,
  };
};

export const updateUserSuccess = (data) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: data,
  };
};

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: id,
  };
};

export const deleteUserSuccess = (data) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: data,
  };
};

export const setEditUserModal = (bool) => {
  return {
    type: SET_EDIT_USER_MODAL,
    payload: bool,
  };
};

export const setAddUserModal = (bool) => {
  return {
    type: SET_ADD_USER_MODAL,
    payload: bool,
  };
};

export const setChangePasswordModel = (bool) => {
  return {
    type: SET_CHANGE_PASSWORD_MODEL,
    payload: bool,
  };
};

export const setManageAgenciesModal = (bool) => {
  return {
    type: SET_MANAGE_AGENCIES_MODAL,
    payload: bool,
  };
};

export const setInviteUserModal = (bool) => {
  return {
    type: SET_USER_INVITE_MODAL,
    payload: bool,
  };
};

export const setTosAcceptanceModal = (bool) => {
  return {
    type: SET_TOS_ACCEPTANCE_MODAL,
    payload: bool,
  };
};
export const setAgencyAccountModal = (bool) => {
  return {
    type: SET_AGENCY_ACCOUNT_MODAL,
    payload: bool,
  };
};

export const assignAgencyAccountToUsers = ({ data }) => {
  return {
    type: ASSIGN_AGENCY_ACCOUNTS_TO_USERS,
    payload: { data },
  };
};

export const assignAgencyAccountToUsersSuccess = () => {
  return {
    type: ASSIGN_AGENCY_ACCOUNTS_TO_USERS_SUCCESS,
    payload: {},
  };
};

export const assignAgencies = ({ data, id }) => {
  return {
    type: ASSIGN_AGENCIES,
    payload: { data, id },
  };
};

export const assignAgenciesSuccess = (res) => {
  return {
    type: ASSIGN_AGENCIES_SUCCESS,
    payload: res,
  };
};

export const sendInviteForUser = (data) => {
  return {
    type: SEND_USER_INVITE,
    payload: { data },
  };
};

export const sendInviteForUserSuccess = (email) => {
  return {
    type: SEND_USER_INVITE_SUCCESS,
    payload: email,
  };
};

export const setSelectedUser = (user) => {
  return {
    type: SET_SELECTED_USER_MODAL,
    payload: user,
  };
};

export const completeUserOnboarding = () => {
  return {
    type: COMPLETE_USER_ONBOARDING,
    payload: {},
  };
};

export const completeUserOnboardingSuccess = () => {
  return {
    type: COMPLETE_USER_ONBOARDING_SUCCESS,
    payload: {},
  };
};

export const updateAccount = ({ data, id }) => {
  return { type: UPDATE_ACCOUNT, payload: { data, id } };
};

export const updateAccountSuccess = () => {
  return { type: UPDATE_ACCOUNT_SUCCESS };
};

export const setAddBalanceModal = (bool) => {
  return { type: SET_ADD_BALANCE_MODAL, payload: bool };
};

export const fetchDisputeLogs = (params) => {
  return { type: FETCH_DISPUTE_LOGS, payload: params };
};

export const fetchDisputeLogsSuccess = (data) => {
  return { type: FETCH_DISPUTE_LOGS_SUCCESS, payload: data };
};

export const updateTos = () => {
  return { type: UPDATE_TOS_BIT, payload: {} };
};

export const updateTosSuccess = () => {
  return { type: UPDATE_TOS_BIT_SUCCESS };
};

export const updateTosForAll = () => {
  return { type: UPDATE_TOS_FOR_ALL };
};

export const updateTosForAllSuccess = () => {
  return {
    type: UPDATE_TOS_FOR_ALL_SUCCESS,
  };
};

export const getAgencyClients = () => ({ type: GET_AGENCY_CLIENTS });

export const getAgencyClientsSuccess = (payload) => ({ type: GET_AGENCY_CLIENTS_SUCCESS, payload });

export const apiError = (error) => ({ type: API_ERROR, payload: error });
