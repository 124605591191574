import {
  FETCH_SUPPLIERS,
  FETCH_SUPPLIERS_SUCCESS,
  CREATE_SUPPLIER,
  CREATE_SUPPLIER_SUCCESS,
  RESET_STATE,
  UPDATE_AO_STATUS,
  UPDATE_AO_STATUS_SUCCESS,
  DELETE_SUPPLIER,
  DELETE_SUPPLIER_SUCCESS,
  API_ERROR,
  FETCH_AO_STATUS,
  FETCH_AO_STATUS_SUCCESS,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  SET_SKU_AS_MISMATCH,
  SET_SKU_AS_MISMATCH_SUCCESS,
  LISTING_SUPPLIERS_COLLAPSE,
  SET_ACTION_SUPPLIER,
  UPDATE_STOCK,
  UPDATE_STOCK_SUCCESS,
  SET_STOCK_MODAL,
  FETCH_REFRESHERS,
  FETCH_REFRESHERS_SUCCESS,
} from "./actionTypes";

export const fetchSuppliers = (data) => {
  return {
    type: FETCH_SUPPLIERS,
    payload: data,
  };
};

export const fetchSuppliersSuccess = (data) => {
  return {
    type: FETCH_SUPPLIERS_SUCCESS,
    payload: data,
  };
};

export const createSupplier = (data) => {
  return {
    type: CREATE_SUPPLIER,
    payload: data,
  };
};

export const createSupplierSuccess = () => {
  return {
    type: CREATE_SUPPLIER_SUCCESS,
    payload: {},
  };
};

export const resetSupplierState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};

export const updateAOStatus = (data, id) => {
  return {
    type: UPDATE_AO_STATUS,
    payload: { data, id },
  };
};

export const updateAOStatusSuccess = () => {
  return {
    type: UPDATE_AO_STATUS_SUCCESS,
    payload: {},
  };
};

export const fetchAOStatus = (params) => {
  return {
    type: FETCH_AO_STATUS,
    payload: { params },
  };
};

export const fetchAOStatusSuccess = (data) => {
  return {
    type: FETCH_AO_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateSupplier = (data) => {
  return {
    type: UPDATE_SUPPLIER,
    payload: data,
  };
};

export const updateSupplierSuccess = () => {
  return {
    type: UPDATE_SUPPLIER_SUCCESS,
    payload: {},
  };
};

export const setListingSuppliersCollapse = (bool) => {
  return {
    type: LISTING_SUPPLIERS_COLLAPSE,
    payload: bool,
  };
};

export const deleteSupplier = (data) => {
  return {
    type: DELETE_SUPPLIER,
    payload: data,
  };
};

export const deleteSupplierSuccess = () => {
  return {
    type: DELETE_SUPPLIER_SUCCESS,
    payload: {},
  };
};

export const setSkuAsMismatch = (data, id) => {
  return {
    type: SET_SKU_AS_MISMATCH,
    payload: { data, id },
  };
};

export const setSkuAsMismatchSuccess = () => {
  return {
    type: SET_SKU_AS_MISMATCH_SUCCESS,
    payload: {},
  };
};

export const setActionSupplier = (data) => {
  return {
    type: SET_ACTION_SUPPLIER,
    payload: data,
  };
};

export const updateStock = (data) => {
  return {
    type: UPDATE_STOCK,
    payload: { data },
  };
};

export const updateStockSuccess = () => {
  return {
    type: UPDATE_STOCK_SUCCESS,
    payload: {},
  };
};

export const setUpdateStockModal = (bool) => {
  return {
    type: SET_STOCK_MODAL,
    payload: bool,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const fetchRefreshers = (data) => {
  return {
    type: FETCH_REFRESHERS,
    payload: data,
  };
};

export const fetchRefreshersSuccess = (data) => {
  return {
    type: FETCH_REFRESHERS_SUCCESS,
    payload: data,
  };
};
