import * as actionTypes from "./actionTypes";

const {
  FETCH_ALL_GRAPHS,
  FETCH_ALL_GRAPHS_SUCCESS,
  FETCH_ALL_TENANT_GRAPHS,
  FETCH_ALL_TENANT_GRAPHS_SUCCESS,
  FETCH_ORDER_GRAPH_DETAILS,
  FETCH_ORDER_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_ORDER_GRAPH,
  FETCH_ALL_TENANT_ORDER_GRAPH_SUCCESS,
  FETCH_SALES_GRAPH_DETAILS,
  FETCH_SALES_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_SALES_GRAPH,
  FETCH_ALL_TENANT_SALES_GRAPH_SUCCESS,
  FETCH_PROFIT_GRAPH_DETAILS,
  FETCH_PROFIT_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_PROFIT_GRAPH,
  FETCH_ALL_TENANT_PROFIT_GRAPH_SUCCESS,
  FETCH_SHARED_PROFIT_GRAPH_DETAILS,
  FETCH_SHARED_PROFIT_GRAPH_DETAILS_SUCCESS,
  FETCH_DAILY_REPORT,
  FETCH_DAILY_REPORT_SUCCESS,
  FETCH_SALES_REVENUE_STATS,
  FETCH_SALES_REVENUE_STATS_SUCCESS,
  FETCH_SHARED_ORDER_STATUS,
  FETCH_SHARED_ORDER_STATUS_SUCCESS,
  FETCH_EXPENSES_GRAPH_DETAILS,
  FETCH_EXPENSES_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_EXPENSE_GRAPH,
  FETCH_ALL_TENANT_EXPENSE_GRAPH_SUCCESS,
  FETCH_RETURNS_GRAPH_DETAILS,
  FETCH_RETURNS_GRAPH_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_RETURNS_GRAPH,
  FETCH_ALL_TENANT_RETURNS_GRAPH_SUCCESS,
  FETCH_USED_CREDITS_DETAILS,
  FETCH_USED_CREDITS_DETAILS_SUCCESS,
  FETCH_ALL_TENANT_USED_CREDITS_DETAILS,
  FETCH_ALL_TENANT_USED_CREDITS_DETAILS_SUCCESS,
  FETCH_ISSUE_DETAILS,
  FETCH_ISSUE_DETAILS_SUCCESS,
  FETCH_LISTING_DETAILS,
  FETCH_LISTING_DETAILS_SUCCESS,
  FETCH_BUYBOX_DETAILS,
  FETCH_BUYBOX_DETAILS_SUCCESS,
  FETCH_ERROR_STATS,
  FETCH_ERROR_STATS_SUCCESS,
  FETCH_TOP_SELLING_ITEMS,
  FETCH_TOP_SELLING_ITEMS_SUCCESS,
  FETCH_GLOBAL_TOP_SELLING_ITEMS,
  FETCH_GLOBAL_TOP_SELLING_ITEMS_SUCCESS,
  FETCH_LEAST_SELLING_ITEMS,
  FETCH_LEAST_SELLING_ITEMS_SUCCESS,
  API_ERROR,
  FETCH_CLIENTS_STATS,
  FETCH_CLIENTS_STATS_SUCCESS,
  FETCH_FEES_DETAILS,
  FETCH_FEES_DETAILS_SUCCESS,
  FETCH_BEST_SELLERS_DATA,
  FETCH_BEST_SELLERS_DATA_SUCCESS,
} = actionTypes;

const initialState = {
  error: "",
  success: "",
  loading: false,
  allTenantGraphsLoading: false,
  allGraphsLoading: false,
  subLoading: false,
  erroStatsLoader: false,
  issueStatsLoader: false,
  buyBoxLoader: false,
  totalOrdersLoader: false,
  refresherLoader: false,
  usedCreditLoader: false,
  returnsLoader: false,
  orderStatusLoader: false,
  salesGraphLoader: false,
  orderGraphLoading: false,
  salesGraphLoading: false,
  profitGraphLoading: false,
  expensesGraphLoading: false,
  dailyReportLoading: false,
  salesRevenueStatsLoading: false,
  bestSellersLoading: false,
  returnsGraphLoading: false,
  usedCreditsGraphLoading: false,
  IssueStatsLoading: false,
  listingStatsLoading: false,
  buyboxStatsLoading: false,
  errorStatsLoading: false,
  topSellingItemsLoading: false,
  profitLoading: false,
  clientsStatsLoading: false,
  orderGraph: {},
  salesGraph: {},
  profitGraph: {},
  sharedProfitGraph: {},
  sharedOrderStatus: {},
  expensesGraph: {},
  dailyReport: {},
  salesRevenueStats: {},
  returnsGraph: {},
  usedCreditsGraph: {},
  IssueStats: {},
  listingStats: {},
  buyboxStats: {},
  errorStats: {},
  skusCategories: {},
  leastSkusCategories: {},
  topSellingItems: [],
  topSellingCategories: [],
  globalSkusCategories: {},
  globalTopSellingItems: [],
  leastSellingItems: [],
  leastSellingItemsPricing: [],
  paginationData: {},
};

const loadingKeys = [
  "allGraphsLoading",
  "allTenantGraphsLoading",
  "orderGraphLoading",
  "salesGraphLoading",
  "profitGraphLoading",
  "expensesGraphLoading",
  "dailyReportLoading",
  "salesRevenueStatsLoading",
  "bestSellersLoading",
  "returnsGraphLoading",
  "usedCreditsGraphLoading",
  "IssueStatsLoading",
  "listingStatsLoading",
  "buyboxStatsLoading",
  "errorStatsLoading",
  "topSellingItemsLoading",
  "leastSellingItemsLoading",
];
function getLoadingState (state) {
  return loadingKeys.some((key) => state[key]);
}

const Graphs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_GRAPHS:
      state = {
        ...state,
        success: "",
        error: "",
        profitLoading: true,
        salesGraphLoader: true,
        totalOrdersLoader: true,
      };
      break;
    case FETCH_ALL_GRAPHS_SUCCESS:
      state = {
        ...state,
        success: "Graphs data fetched successfully.",
        error: "",
        salesGraph: action.payload.sales,
        profitGraph: action.payload.profits,
        expensesGraph: action.payload.expenses,
        orderGraph: action.payload.orders,
        salesGraphLoader: false,
        profitLoading: false,
        totalOrdersLoader: false,
      };
      break;
    case FETCH_ALL_TENANT_GRAPHS:
      state = {
        ...state,
        success: "",
        error: "",
        allTenantGraphsLoading: true,
        loading: true,
      };
      break;
    case FETCH_ALL_TENANT_GRAPHS_SUCCESS:
      state = {
        ...state,
        success: "All Tenant Graphs data fetched successfully.",
        error: "",
        salesGraph: action.payload.sales,
        profitGraph: action.payload.profits,
        expensesGraph: action.payload.expenses,
        orderGraph: action.payload.orders,
        returnsGraph: action.payload.returns,
        usedCreditsGraph: action.payload.usedCredits,
        allTenantGraphsLoading: false,
        loading: getLoadingState({ ...state, allTenantGraphsLoading: false }),
      };
      break;
    case FETCH_SALES_GRAPH_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        salesGraphLoader: true,
      };
      break;
    case FETCH_SALES_GRAPH_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Sales graph data fetched successfully.",
        error: "",
        salesGraph: action.payload,
        salesGraphLoader: false,
      };
      break;
    case FETCH_ALL_TENANT_SALES_GRAPH:
      state = {
        ...state,
        success: "",
        error: "",
        salesGraphLoader: true,
      };
      break;
    case FETCH_ALL_TENANT_SALES_GRAPH_SUCCESS:
      state = {
        ...state,
        success: "All Tenant Sales graph data fetched successfully.",
        error: "",
        salesGraph: action.payload,
        salesGraphLoader: false,
      };
      break;
    case FETCH_EXPENSES_GRAPH_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        expensesGraphLoading: true,
        loading: true,
      };
      break;
    case FETCH_EXPENSES_GRAPH_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Expenses graph data fetched successfully.",
        error: "",
        expensesGraph: action.payload,
        expensesGraphLoading: false,
        loading: getLoadingState({ ...state, expensesGraphLoading: false }),
      };
      break;
    case FETCH_ALL_TENANT_EXPENSE_GRAPH:
      state = {
        ...state,
        success: "",
        error: "",
        expensesGraphLoading: true,
        loading: true,
      };
      break;
    case FETCH_ALL_TENANT_EXPENSE_GRAPH_SUCCESS:
      state = {
        ...state,
        success: "All Tenant Expenses graph data fetched successfully.",
        error: "",
        expensesGraph: action.payload,
        expensesGraphLoading: false,
        loading: getLoadingState({ ...state, expensesGraphLoading: false }),
      };
      break;
    case FETCH_ORDER_GRAPH_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        totalOrdersLoader: true,
      };
      break;
    case FETCH_ORDER_GRAPH_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Order graph data fetched successfully.",
        error: "",
        orderGraph: action.payload,
        totalOrdersLoader: false,
      };
      break;
    case FETCH_CLIENTS_STATS:
      state = {
        ...state,
        success: "",
        error: "",
        clientsStatsLoading: true,
      };
      break;
    case FETCH_CLIENTS_STATS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        clientsStats: action.payload,
        clientsStatsLoading: false,
      };
      break;
    case FETCH_ALL_TENANT_ORDER_GRAPH:
      state = {
        ...state,
        success: "",
        error: "",
        totalOrdersLoader: true,
      };
      break;
    case FETCH_ALL_TENANT_ORDER_GRAPH_SUCCESS:
      state = {
        ...state,
        success: "All Tenant Order graph data fetched successfully.",
        error: "",
        orderGraph: action.payload,
        totalOrdersLoader: false,
      };
      break;
    case FETCH_PROFIT_GRAPH_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        profitLoading: true,
      };
      break;
    case FETCH_PROFIT_GRAPH_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Profit graph data fetched successfully.",
        error: "",
        profitGraph: action.payload,
        profitLoading: false,
      };
      break;
    case FETCH_ALL_TENANT_PROFIT_GRAPH:
      state = {
        ...state,
        success: "",
        error: "",
        subLoading: true,
      };
      break;
    case FETCH_ALL_TENANT_PROFIT_GRAPH_SUCCESS:
      state = {
        ...state,
        success: "All Tenant Profit graph data fetched successfully.",
        error: "",
        profitGraph: action.payload,
        subLoading: false,
      };
      break;
    case FETCH_SHARED_PROFIT_GRAPH_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        profitGraphLoading: true,
        loading: true,
        subLoading: true,
      };
      break;
    case FETCH_SHARED_PROFIT_GRAPH_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Shared Profit graph data fetched successfully.",
        error: "",
        sharedProfitGraph: action.payload,
        profitGraphLoading: false,
        loading: getLoadingState({ ...state, profitGraphLoading: false }),
        subLoading: false,
      };
      break;
    case FETCH_DAILY_REPORT:
      state = {
        ...state,
        success: "",
        error: "",
        dailyReportLoading: true,
        orderStatusLoader: true,
        loading: true,
      };
      break;
    case FETCH_DAILY_REPORT_SUCCESS:
      state = {
        ...state,
        success: "Daily Report data fetched successfully.",
        error: "",
        dailyReport: action.payload,
        dailyReportLoading: false,
        orderStatusLoader: false,
        loading: getLoadingState({ ...state, dailyReportLoading: false }),
      };
      break;
    case FETCH_SALES_REVENUE_STATS:
      state = {
        ...state,
        error: "",
        salesRevenueStatsLoading: true,
        loading: true,
      };
      break;
    case FETCH_SALES_REVENUE_STATS_SUCCESS:
      state = {
        ...state,
        error: "",
        salesRevenueStats: action.payload,
        salesRevenueStatsLoading: false,
        loading: getLoadingState({ ...state, salesRevenueStatsLoading: false }),
      };
      break;
    case FETCH_SHARED_ORDER_STATUS:
      state = {
        ...state,
        success: "",
        error: "",
        orderStatusLoader: true,
      };
      break;
    case FETCH_SHARED_ORDER_STATUS_SUCCESS:
      state = {
        ...state,
        success: "Order Statuses Fetched Successfully",
        error: "",
        sharedOrderStatus: action.payload,
        orderStatusLoader: false,
      };
      break;
    case FETCH_FEES_DETAILS:
      state = { ...state, success: "", error: "", feesDetailsLoader: true };
      break;
    case FETCH_FEES_DETAILS_SUCCESS:
      state = { ...state, feesDetailsLoader: false, error: "", feesDetails: action.payload };
      break;
    case FETCH_BEST_SELLERS_DATA:
      state = { ...state, success: "", error: "", bestSellersLoading: true };
      break;
    case FETCH_BEST_SELLERS_DATA_SUCCESS:
      state = { ...state, bestSellersLoading: false, error: "", bestSellers: action.payload };
      break;
    case FETCH_RETURNS_GRAPH_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        returnsLoader: true,
      };
      break;
    case FETCH_RETURNS_GRAPH_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Returns graph data fetched successfully.",
        error: "",
        returnsGraph: action.payload,
        returnsLoader: false,
      };
      break;
    case FETCH_ALL_TENANT_RETURNS_GRAPH:
      state = {
        ...state,
        success: "",
        error: "",
        returnsGraphLoading: true,
        loading: true,
      };
      break;
    case FETCH_ALL_TENANT_RETURNS_GRAPH_SUCCESS:
      state = {
        ...state,
        success: "All Tenant Returns graph data fetched successfully.",
        error: "",
        returnsGraph: action.payload,
        returnsGraphLoading: false,
        loading: getLoadingState({ ...state, returnsGraphLoading: false }),
      };
      break;
    case FETCH_USED_CREDITS_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        usedCreditLoader: true,
      };
      break;
    case FETCH_USED_CREDITS_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Used Credits graph data fetched successfully.",
        error: "",
        usedCreditsGraph: action.payload,
        usedCreditLoader: false,
      };
      break;
    case FETCH_ALL_TENANT_USED_CREDITS_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        usedCreditsGraphLoading: true,
        loading: true,
      };
      break;
    case FETCH_ALL_TENANT_USED_CREDITS_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "All Tenant Used Credits graph data fetched successfully.",
        error: "",
        usedCreditsGraph: action.payload,
        usedCreditsGraphLoading: false,
        loading: getLoadingState({ ...state, usedCreditsGraphLoading: false }),
      };
      break;
    case FETCH_ISSUE_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        IssueStatsLoading: true,
        issueStatsLoader: true,
      };
      break;
    case FETCH_ISSUE_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Issue details data fetched successfully.",
        error: "",
        IssueStats: action.payload,
        IssueStatsLoading: false,
        issueStatsLoader: false,
      };
      break;
    case FETCH_LISTING_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        refresherLoader: true,
      };
      break;
    case FETCH_LISTING_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "Listing details data fetched successfully.",
        error: "",
        listingStats: action.payload,
        refresherLoader: false,
      };
      break;
    case FETCH_BUYBOX_DETAILS:
      state = {
        ...state,
        success: "",
        error: "",
        buyBoxLoader: true,
      };
      break;
    case FETCH_BUYBOX_DETAILS_SUCCESS:
      state = {
        ...state,
        success: "BuyBox details data fetched successfully.",
        error: "",
        buyboxStats: action.payload,
        buyBoxLoader: false,
      };
      break;
    case FETCH_ERROR_STATS:
      state = {
        ...state,
        success: "",
        error: "",
        errorStatsLoading: true,
        erroStatsLoader: true,
      };
      break;
    case FETCH_ERROR_STATS_SUCCESS:
      state = {
        ...state,
        success: "Error details data fetched successfully.",
        error: "",
        errorStats: action.payload,
        errorStatsLoading: false,
        erroStatsLoader: false,
      };
      break;
    case FETCH_LEAST_SELLING_ITEMS:
      state = {
        ...state,
        success: "",
        error: "",
        errorStats: action.payload,
        errorStatsLoading: false,
        loading: getLoadingState({ ...state, leastSellingItemsLoading: true }),
      };
      break;
    case FETCH_LEAST_SELLING_ITEMS_SUCCESS:
      state = {
        ...state,
        success: "Data fetched successfully.",
        error: "",
        leastSkusCategories: action.payload?.skus,
        leastSellingItems: action.payload?.stats,
        leastSellingItemsPricing: action.payload?.current_pricing,
        paginationData: action.payload?.paginationData,
        loading: getLoadingState({ ...state, leastSellingItemsLoading: false }),
      };
      break;
    case FETCH_TOP_SELLING_ITEMS:
      state = {
        ...state,
        success: "",
        error: "",
        errorStats: action.payload,
        errorStatsLoading: false,
        loading: getLoadingState({ ...state, topSellingItemsLoading: true }),
      };
      break;
    case FETCH_TOP_SELLING_ITEMS_SUCCESS:
      state = {
        ...state,
        success: "Error details data fetched successfully.",
        error: "",
        skusCategories: action.payload?.skus || {},
        topSellingItems: action.payload?.stats || [],
        topSellingCategories: action.payload?.category || {},
        topSellingItemsLoading: false,
        loading: getLoadingState({ ...state, topSellingItemsLoading: false }),
      };
      break;
    case FETCH_GLOBAL_TOP_SELLING_ITEMS:
      state = {
        ...state,
        success: "",
        error: "",
        topSellingItemsLoading: true,
        loading: getLoadingState({ ...state, topSellingItemsLoading: true }),
      };
      break;
    case FETCH_GLOBAL_TOP_SELLING_ITEMS_SUCCESS:
      state = {
        ...state,
        success: "Global Top Selling Items Data Fetched Successfully",
        error: "",
        globalSkusCategories: action.payload.skus,
        globalTopSellingItems: action.payload.stats,
        topSellingItemsLoading: false,
        loading: getLoadingState({ ...state, topSellingItemsLoading: false }),
      };
      break;
    case actionTypes.FETCH_PRODUCTS_BY_SALES:
      state = { ...state, success: "", error: "", brandProductLoading: true };
      break;
    case actionTypes.FETCH_PRODUCTS_BY_SALES_SUCCESS:
      state = { ...state, brandProductLoading: false, error: "", productsByBrand: action.payload };
      break;
    case actionTypes.FETCH_PRODUCTS_BY_CATEGORY:
      state = { ...state, success: "", error: "", categoryProductLoading: true };
      break;
    case actionTypes.FETCH_PRODUCTS_BY_CATEGORY_SUCCESS:
      state = { ...state, categoryProductLoading: false, error: "", productsByCategory: action.payload };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        feesDetailsLoader: false,
        bestSellersLoading: false,
        loading: false,
        categoryProductLoading: false,
        brandProductLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Graphs;
