import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { randStr } from "../../utils/common";

const CopyToClipBoard = (props) => {
  const [open, setToolTip] = useState(false);
  const [id] = useState(`pop-over-${randStr()}`);
  return (
    <>
      <Popover
        placement={props.openDirection ? props.openDirection : "right"}
        isOpen={open}
        target={id}
        toggle={() => {
          setToolTip(!open);
          setTimeout(() => {
            setToolTip(false);
          }, 1500);
        }}
      >
        <PopoverBody className="secondary">Copied!</PopoverBody>
      </Popover>
      {!props.image
        ? (
        <i
          className={`bx bx-xs bx-copy pl-${props.padding || 3} cursor-pointer ${props.className || ""}`}
          id={id}
          onClick={() => navigator.clipboard?.writeText(props.text)}
        ></i>
          )
        : (
        <img
          src={props.image}
          id={id}
          className="cursor-pointer"
          onClick={() => navigator.clipboard?.writeText(props.text)}
          alt={props.alt}
        />
          )}
    </>
  );
};

export default CopyToClipBoard;
