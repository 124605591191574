import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Col, Card, Row, Alert, Label, Input, FormGroup } from "reactstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import Select from "react-select";
import { getAllStates, getUserInfo, isUserHaveFullAccess, RenderIf } from "../../../utils/common";
import { onboardingStepsIds, PAYMENT_TYPES, regions } from "../../../constants";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
import { merge } from "lodash";

// api action
import { addNewPaymentSource } from "../../../api/Stripe";

// redux actions
import { setSkipAgency, updateOnboardingSettings, setAddWareHouseModal, fetchToken } from "../../../store/actions";

const features = [
  "Easy lister tools for listing one-by-one or in bulk",
  "Refreshing of stock and price for all supplier links",
  "Built-in repricer",
  "Automatically have tracking numbers uploaded to a marketplace",
  "Process orders automatically using the auto-order feature",
  "Access to the Ecom Circles community on Facebook",
];

const Payment = (props) => {
  const dispatch = useDispatch();
  const style = localStorage.getItem("THEME") === "light" ? styleLight : styleDark;
  const { account, tenantsData } = useSelector((state) => state.Settings);
  const [error, setError] = useState(null);
  const [apiErr, setApiErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  function CustomLabel (props) {
    return <Label className="mt-3">{props.children}</Label>;
  }
  function fetchTenantToken (email, params) {
    dispatch(fetchToken(email, {}, params));
  }
  const handleChange = async (event) => {
    setDisabled(event.empty || !event.complete || event.error);
    setError(event?.error?.message || "");
  };
  const initialValues = {
    userName: "",
    country: "",
    state: "",
    city: "",
    zip: "",
  };
  const validate = (values) => {
    const errors = {};
    !values.userName && (errors.userName = "Field is Required");
    !values.country && (errors.country = "Field is Required");
    !values.state && (errors.state = "Field is Required");
    !values.city && (errors.city = "Field is Required");
    !values.zip && (errors.zip = "Field is Required");
    values.zip &&
      !/^([a-zA-Z0-9]+-)*[a-zA-Z0-9]+$/.test(values.zip) &&
      (errors.zip = "Only alphanumeric and hyphen allowed");
    if (values.city && values.city.length < 3) errors.city = "Invalid City Name ";
    return errors;
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (values) => {
      const address = {
        userName: values.userName || "",
        country: values.country?.label || "",
        state: values.state?.label || "",
        city: values.city || "",
        zip: values.zip || "",
      };
      try {
        setLoading(true);
        if (stripe && !error) {
          const data = {
            name: address.userName,
            address_city: address.city,
            address_state: address.state,
            address_country: address.country,
            address_zip: address.zip,
          };
          const { token, error } = await stripe.createToken(elements.getElement(CardElement), data);
          if (error) throw error;
          await addPaymentSource(token.id);
        }
        setLoading(false);
        props.toggleOpen && props.toggleOpen();
      } catch (error) {
        setApiErr(error.message || error);
        setLoading(false);
      }
    },
  });
  async function addPaymentSource (token, address) {
    try {
      await addNewPaymentSource({ token, address });
      if (getUserInfo()?.isAgencySwitch && !account?.data?.onboard?.store_added) {
        dispatch(updateOnboardingSettings({ payment: true }));
        props.setActiveStep(onboardingStepsIds.welcom);
        return;
      }
      dispatch(updateOnboardingSettings({ payment: true, onboard: true }));
      props.setIsOpen(false);
      dispatch(setAddWareHouseModal(true));
    } catch (error) {
      setApiErr(error.message);
    }
  }
  useEffect(() => {
    setTimeout(() => setApiErr(""), 3000);
  }, [apiErr]);
  const parentAgency = tenantsData[getUserInfo()?.agency_id];

  return (
    <React.Fragment>
      <Card className="mb-0">
        <div>
          {apiErr && (
            <div className="auto-hide">
              <Alert color="warning">
                <i className="bx bx-info-circle pr-2"></i>
                {apiErr}
              </Alert>
            </div>
          )}
        </div>
        <Row className="px-2 align-items-center justify-content-around">
          <Col md="6 mt-4">
            <Card className="align-items-center bg-grey">
              <Col className="p-2">
                <AvForm className="form-horizontal" onSubmit={() => formik.handleSubmit()}>
                  <FormGroup className="select2-container">
                    <Row>
                      <Col md={12}>
                        <CustomLabel>Card Holder Name</CustomLabel>
                        <Input
                          name="userName"
                          placeholder="Card Holder Name"
                          value={formik.values.userName}
                          onChange={(e) => formik.setFieldValue("userName", e.target.value)}
                        />
                        {formik.touched.userName && formik.errors.userName
                          ? (
                          <small className="text-danger mt-1">{formik.errors.userName}</small>
                            )
                          : null}
                      </Col>
                      <Col md={6}>
                        <CustomLabel>Country</CustomLabel>
                        <Select
                          name="country"
                          id="country-select"
                          value={formik.values?.country || ""}
                          onChange={(option) => {
                            formik.setFieldValue("country", option);
                            formik.setFieldValue("state", null);
                            formik.setFieldValue("city", null);
                          }}
                          options={Object.keys(regions).map((isoCode) => ({
                            label: regions[isoCode].name,
                            value: isoCode,
                          }))}
                          classNamePrefix="select2-selection"
                        />
                        {formik.touched.country && formik.errors.country
                          ? (
                          <small className="text-danger mt-1">{formik.errors.country}</small>
                            )
                          : null}
                      </Col>
                      <Col md={6}>
                        <CustomLabel>State</CustomLabel>
                        <Select
                          name="state"
                          id="state-select"
                          value={formik.values?.state || ""}
                          onChange={(option) => {
                            formik.setFieldValue("state", option);
                            formik.setFieldValue("city", null);
                          }}
                          options={getAllStates(formik?.values?.country.value).map(([isoCode, { name }]) => ({
                            label: name,
                            value: isoCode,
                          }))}
                          classNamePrefix="select2-selection"
                        />
                        {formik.touched.state && formik.errors.state
                          ? (
                          <small className="text-danger mt-1">{formik.errors.state}</small>
                            )
                          : null}
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <CustomLabel> City</CustomLabel>
                          <Input
                            name="city"
                            id="city-select"
                            placeholder="City"
                            value={formik.values.city || ""}
                            onChange={(e) => formik.setFieldValue("city", e.target.value)}
                          />
                          {formik.touched.city && formik.errors.city
                            ? (
                            <small className="text-danger mt-1">{formik.errors.city}</small>
                              )
                            : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <CustomLabel>Zip Code</CustomLabel>
                          <Input
                            name="zip"
                            placeholder="Zip Code"
                            value={formik.values.zip || ""}
                            onChange={(e) => formik.setFieldValue("zip", e.target.value)}
                          />
                          {formik.touched.zip && formik.errors.zip
                            ? (
                            <small className="text-danger mt-1">{formik.errors.zip}</small>
                              )
                            : null}
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                  <CardElement
                    className="card-element"
                    options={merge(style, { hidePostalCode: true })}
                    onChange={handleChange}
                  />
                  {error && <small className="text-danger">{error}</small>}
                  <div className="mt-3">
                    <button
                      disabled={loading || error || disabled}
                      className="btn btn-success btn-block waves-effect waves-light"
                    >
                      Add
                    </button>
                  </div>
                </AvForm>
              </Col>
            </Card>
          </Col>
          <Col>
            {features.map((x, i) => (
              <div className="p-1 d-flex flex-row" style={{ alignItems: "baseline" }} key={"_feature_" + i}>
                <i className="bx bxs-check-circle text-success"></i>
                <span>{x}</span>
              </div>
            ))}
          </Col>
        </Row>
        <div className="p-4 row justify-content-between">
          <RenderIf isTrue={getUserInfo()?.isAgencySwitch && !account?.data?.stores_count}>
            <div className="text-center">
              <button
                color="primary"
                className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-success"
                onClick={() => {
                  props.setActiveStep(onboardingStepsIds.welcom);
                }}
              >
                Back
              </button>
            </div>
          </RenderIf>
          <RenderIf isTrue={getUserInfo()?.isAgencySwitch && account?.data?.stores_count}>
            <div className="text-center">
              <button
                color="primary"
                className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-success"
                onClick={() =>
                  fetchTenantToken(parentAgency?.email, {
                    isAgencySwitch: true,
                  })
                }
              >
                Back To Agency
              </button>
            </div>
          </RenderIf>
          <RenderIf
            isTrue={
              account?.data?.onboard?.store_added &&
              (account?.data?.payment_source_added ||
                (getUserInfo()?.isAgencySwitch &&
                  account?.data?.subscription_source === PAYMENT_TYPES.parent &&
                  parentAgency?.payment_source_added) ||
                !isUserHaveFullAccess())
            }
          >
            <div className="text-center">
              <button
                color="primary"
                className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-success"
                onClick={() => {
                  dispatch(setSkipAgency());
                  props.setIsOpen(false);
                }}
              >
                Skip
              </button>
            </div>
          </RenderIf>
          <RenderIf isTrue={!account?.data?.payment_source_added}>
            <div>
              <button
                onClick={() => props.history.push("/logout")}
                className="m-1 w-sm waves-effect waves-light btn btn-sm btn-outline-danger"
              >
                Logout
              </button>
            </div>
          </RenderIf>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Payment;

Payment.propTypes = {
  setActiveStep: PropTypes.func,
};

const styleDark = {
  style: {
    base: {
      iconColor: "#bfc8e2",
      color: "#bfc8e2",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#bfc8e2",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};

const styleLight = {
  style: {
    base: {
      iconColor: "#545a6d",
      color: "#495057",
      ":-webkit-autofill": {
        color: "#495057",
      },
      "::placeholder": {
        color: "#495057",
      },
    },
    invalid: {
      iconColor: "#eb1c26",
      color: "#eb1c26",
    },
  },
};
