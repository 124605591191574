import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTable from "../../../../components/Common/Table";
import Modal from "../../../../components/Common/Modal";
import moment from "moment-timezone";
import { groupBy, sumBy, entries, map, merge } from "lodash";
import { RenderIf } from "../../../../utils/common";
import { ConfirmDialogIcon } from "../../../../components/Common/ConfirmDialog";
import { DATE_FORMATS } from "../../../../constants";
import { Alert } from "reactstrap";
import * as actions from "../../../../store/actions";
// Actions
const {
  setBinDetailModal,
  deleteInventoryBin,
  updateInventoryBin,
  createInventoryBin,
} = actions;

const ShowBinLocationsModal = ({ binLocationDetail, isShipmentWise, success, error }) => {
  const [updateData, setUpdateData] = useState({});
  const [mainState, setMainState] = useState({
    addBin: false,
    editingIndex: -1,
    validationStateMessage: "",
    binMessage: "",
    isBinNoError: false,
    isQtyError: false,
    trackingNumber: "",
    recieveShipmentId: "",
    editQty: 0,
  });

  const getTotalSum = () => {
    const sumObj = {};
    binLocationDetail.forEach((x) => {
      const totalSum = sumBy(x?.WhInventoryLocation, (x) => parseInt(x.wh_inventory_locations.qty));
      const recQty = parseInt(x.remaining_qty || 0);
      if (x?.shipment_line?.tracking_number) sumObj[x?.shipment_line?.tracking_number] = { totalSum, recQty };
    });
    return sumObj;
  };

  const dispatch = useDispatch();
  const deleteBin = (binNo, recShipId) => dispatch(deleteInventoryBin(binNo, recShipId));

  const handleSave = (id, recShipId) => {
    dispatch(updateInventoryBin(id, recShipId, updateData, isShipmentWise));
    setMainState({ ...mainState, editingIndex: -1 });
  };
  const handleCreate = () => {
    dispatch(createInventoryBin(mainState.recieveShipmentId, updateData, isShipmentWise));
    setMainState({ ...mainState, addBin: false });
  };

  function handleCondition (trackingNumber) {
    const newObj = getTotalSum();
    return Number(newObj[trackingNumber]?.totalSum) < Number(newObj[trackingNumber]?.recQty);
  }

  const checkBinValidation = (value) => {
    let binMessage = "";
    let isBinNoError = true;
    if (!value) {
      binMessage = "Bin no can't be empty.";
    } else if (value.length > 255) {
      binMessage = "Max length 256 characters.";
    } else {
      isBinNoError = false;
    }
    setMainState({ ...mainState, isBinNoError, binMessage });
  };
  const handleAddBin = (val, reShipId) => {
    setMainState({
      ...mainState,
      editQty: 0,
      trackingNumber: val,
      editingIndex: -1,
      binMessage: "",
      isBinNoError: false,
      addBin: true,
      recieveShipmentId: reShipId,
      validationStateMessage: "",
    });
  };

  const checkQtyValidation = (value, tId) => {
    const obj = getTotalSum();
    let errorMessage = "";
    let isQtyError = true;
    if (obj[tId]?.totalSum - mainState.editQty + Number(value) > obj[tId]?.recQty && !isShipmentWise) {
      errorMessage = `Should be <= ${obj[tId].recQty}.`;
    } else if (+value > 1000) {
      errorMessage = "Should be 0 - 1000.";
    } else if (!Number.isInteger(Number(value))) {
      errorMessage = "Should be integer.";
    } else if (value <= 0) {
      errorMessage = "Should be > 0";
    } else if (!value) {
      errorMessage = "can't be empty.";
    } else {
      isQtyError = false;
    }
    setMainState({ ...mainState, validationStateMessage: errorMessage, isQtyError });
  };

  const getBinsAndCount = (receivedShipment) => {
    const data =
      receivedShipment?.WhInventoryLocation && receivedShipment?.WhInventoryLocation.length
        ? receivedShipment?.WhInventoryLocation.reduce((bins, shipment) => {
          const shipmentData = {
            ...shipment?.wh_inventory_locations,
            qty: parseInt(shipment?.wh_inventory_locations.qty),
            tracking_number: receivedShipment?.shipment_line?.tracking_number,
            remaining_qty: receivedShipment?.remaining_qty,
            bin_no: shipment?.bin_no,
          };
          return merge(bins, {
            [shipment?.bin_no]: shipmentData,
          });
        }, {})
        : {
            [receivedShipment?.shipment_line?.tracking_number]: {
              remaining_qty: receivedShipment?.remaining_qty,
              tracking_number: receivedShipment?.shipment_line?.tracking_number,
            },
          };
    const groupedData = groupBy(data, "tracking_number");
    return map(entries(groupedData), ([trackingNumber, dataArray], key) => (
      <React.Fragment key={key}>
        {dataArray.map((data, index) => (
          <React.Fragment key={index}>
            <tr key={index}>
              {mainState.editingIndex === data.id
                ? (
                <>
                  <RenderIf isTrue={index === 0}>
                    <td rowSpan={index === 0 ? dataArray.length : 1}>
                      {data?.tracking_number} ({data?.remaining_qty || 0})
                      <RenderIf isTrue={handleCondition(data?.tracking_number) || isShipmentWise}>
                        <i
                          className="mt-2 text-success bx bx-plus cursor-pointer"
                          onClick={() => {
                            setUpdateData({});
                            handleAddBin(data?.tracking_number, receivedShipment.id);
                          }}
                        />
                      </RenderIf>
                    </td>
                  </RenderIf>

                  <td>
                    <span className="position-relative d-block min-height-55 mt-4">
                      <input
                        className="form-control"
                        id={`bin-${key}`}
                        type="text"
                        defaultValue={data?.bin_no}
                        onChange={(e) => {
                          setUpdateData({ ...updateData, binNo: e.target.value });
                          checkBinValidation(e.target.value);
                        }}
                      />
                      <RenderIf isTrue={mainState.binMessage}>
                        <p className="text-danger postiton-absolute mb-0">{mainState.binMessage}</p>
                      </RenderIf>
                    </span>
                  </td>
                  <td>
                    <span className="position-relative d-block min-height-55 mt-4">
                      <input
                        className="form-control"
                        id={`qty-${key}`}
                        type="number"
                        defaultValue={data?.qty}
                        onKeyDown={(e) => (["-", "+", "e"].includes(e.key)) && e.preventDefault()}
                        onChange={(e) => {
                          setUpdateData({ ...updateData, qty: e.target.value });
                          checkQtyValidation(e.target.value, data?.tracking_number);
                        }}
                      />
                      <RenderIf isTrue={mainState.validationStateMessage}>
                        <p className="text-danger postiton-absolute mb-0">{mainState.validationStateMessage}</p>
                      </RenderIf>
                    </span>
                  </td>
                  <td>{moment(data?.created_at).format(DATE_FORMATS.DATE_TIME)}</td>
                  <td>
                    <i
                      className="text-success bx bx-md bx-check cursor-pointer"
                      onClick={() => {
                        if (!mainState.isQtyError && !mainState.isBinNoError) handleSave(data.id, receivedShipment.id);
                      }}
                    />
                    <i
                      className="text-danger bx bx-md bx-x cursor-pointer"
                      onClick={() => setMainState({ ...mainState, editingIndex: -1 })}
                    />
                  </td>
                </>
                  )
                : (
                <>
                  <RenderIf isTrue={index === 0}>
                    <td rowSpan={index === 0 ? dataArray.length : 1}>
                      {trackingNumber} ({data?.remaining_qty || 0})
                      <RenderIf isTrue={handleCondition(data?.tracking_number) || isShipmentWise}>
                        <i
                          className="mt-2 text-success bx bx-plus cursor-pointer"
                          onClick={() => {
                            setUpdateData({});
                            handleAddBin(data?.tracking_number, receivedShipment.id);
                          }}
                        />
                      </RenderIf>
                    </td>
                  </RenderIf>
                  <RenderIf isTrue={data?.bin_no}>
                    <td>{data?.bin_no}</td>
                    <td>{data?.qty}</td>
                    <td>{moment(data?.created_at).format(DATE_FORMATS.DATE_TIME)}</td>
                    <td>
                      <i
                        title="Edit"
                        className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                        onClick={() => {
                          setUpdateData({});
                          setMainState({
                            ...mainState,
                            editQty: data.qty,
                            validationStateMessage: "",
                            binMessage: "",
                            addBin: false,
                            editingIndex: data?.id,
                          });
                        }}
                      />
                      <ConfirmDialogIcon
                        icon={{
                          title: "Delete",
                          className: "bx bx-sm text-danger bx-trash",
                        }}
                        msg={"To Delete Bin."}
                        onConfirm={() => {
                          setUpdateData({});
                          deleteBin(data?.id, receivedShipment.id);
                        }}
                      />
                    </td>
                  </RenderIf>
                </>
                  )}
            </tr>
          </React.Fragment>
        ))}
      </React.Fragment>
    ));
  };
  const { setBinDetailModalIsOpen, loading } = useSelector((state) => state.WareHouses);
  const toggleBinDetailModal = () => dispatch(setBinDetailModal(!setBinDetailModalIsOpen));

  useEffect(() => {
    dispatch(actions.setPreloader(loading));
  }, [loading]);

  return (
    <div>
      <Modal size="lg" isOpen={setBinDetailModalIsOpen} toggle={toggleBinDetailModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Bin Location
          </h5>
          <button
            onClick={() => toggleBinDetailModal()}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x" />
          </button>
        </div>
        <div className="modal-body">
          {success && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {success}
              </Alert>
            </div>
          )}
          {error && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          )}
          <ReactTable
            height={{ maxHeight: "400px" }}
            tableHeader={
              <>
                <th>Tracking# (Remaining qty)</th>
                <th>Bin#</th>
                <th>Bin qty</th>
                <th>Location Date</th>
                <th>Actions</th>
              </>
            }
            tableBody={
              <>
                {(binLocationDetail || []).map((x) => getBinsAndCount(x))}
                <RenderIf isTrue={mainState.addBin}>
                  <tr>
                    <td>{mainState.trackingNumber}</td>
                    <td>
                      <span className="position-relative d-block min-height-55 mt-4">
                        <input
                          className="form-control"
                          id={"new-bin"}
                          type="text"
                          placeholder="bin no#"
                          onChange={(e) => {
                            setUpdateData({ ...updateData, binNo: e.target.value });
                            checkBinValidation(e.target.value);
                          }}
                        />
                        <RenderIf isTrue={mainState.binMessage}>
                          <p className="text-danger postiton-absolute mb-0">{mainState.binMessage}</p>
                        </RenderIf>
                      </span>
                    </td>
                    <td>
                      <span className="position-relative d-block min-height-55 mt-4">
                        <input
                          className="form-control"
                          style={{ width: "100px" }}
                          id={"new-qty"}
                          type="number"
                          placeholder="qty"
                          onKeyDown={(e) => (["-", "+", "e"].includes(e.key)) && e.preventDefault()}
                          onChange={(e) => {
                            setUpdateData({ ...updateData, qty: e.target.value });
                            checkQtyValidation(e.target.value, mainState.trackingNumber);
                          }}
                        />
                        <RenderIf isTrue={mainState.validationStateMessage}>
                          <p className="text-danger postiton-absolute mb-0">{mainState.validationStateMessage}</p>
                        </RenderIf>
                      </span>
                    </td>
                    <td>{moment().format(DATE_FORMATS.DATE_TIME)}</td>
                    <td>
                      <i
                        className="text-success bx bx-md bx-check cursor-pointer"
                        onClick={() => {
                          if (!mainState.isQtyError && !mainState.isBinNoError) {
                            if (!updateData.binNo) {
                              setMainState({ ...mainState, binMessage: "Bin no can't be empty.", isBinNoError: true });
                            } else if (!updateData.qty) {
                              setMainState({
                                ...mainState,
                                validationStateMessage: "should be >= 1.",
                                isQtyError: true,
                              });
                            } else {
                              handleCreate();
                            }
                          }
                        }}
                      />
                      <i
                        className="text-danger bx bx-md bx-x cursor-pointer"
                        onClick={() => setMainState({ ...mainState, addBin: false })}
                      />
                    </td>
                  </tr>
                </RenderIf>
              </>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default ShowBinLocationsModal;
