import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Card, CardBody, Container, FormGroup, Alert, Badge } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/dark.css";
import CustomTooltip from "../../components/Common/CustomTooltip";

import { setPreloader, downloadReport, fetchReports, resetDownloadFileURL } from "../../store/actions";
import CustomPagination from "../../components/Common/CustomPagination";
import { concat, keys, startCase, values } from "lodash";
import moment from "moment";
import { EC_REPORT_TYPES, DATE_FORMATS, REPORT_STATUSES } from "../../constants";
import { downloadFileClientSide, getUserInfo, RenderIf } from "../../utils/common";
import SimpleSelect from "../../components/Common/SimpleSelect";
import queryString from "query-string";
import { components } from "react-select";
const { FULL_DATE } = DATE_FORMATS;

const DropdownItem = (props) => (
  <components.Option {...props}>
    {props.data.label}
    <RenderIf isTrue={props.data.version > 1}>
      <Badge color="primary" className="ml-2">
        V{props.data.version}
      </Badge>
    </RenderIf>
  </components.Option>
);

const Reports = (props) => {
  const token = getUserInfo();
  const dispatch = useDispatch();
  const qP = queryString.parse(props.location.search);

  const { reports, error, loading, filePath } = useSelector((state) => state.Report);
  const { agencies } = useSelector((state) => state.Settings);
  const [filters, setFilters] = useState({ page: 1, per_page: 20 });

  const humanizeStr = (str) => startCase(str)?.replace("Wh", "WH");

  const [selectedReport, setSelectedReport] = useState({
    value: "",
    label: "Select Report...",
  });

  const currentClient = () => agencies?.data?.find((agency) => agency.email === qP?.email);
  const [selectedReportStatus, setSelectReportStatus] = useState({ value: "", label: "Select Report Status..." });

  const tableHeaders = [
    { title: "Author" },
    { title: "Report Type" },
    { title: "Date Created" },
    { title: "Processing Status" },
    { title: "Action" },
  ];

  useEffect(() => {
    !qP?.email && dispatch(fetchReports(filters));
  }, [filters]);

  useEffect(() => {
    if (qP?.email && agencies?.data?.length && !token?.agency_id) {
      const client = currentClient();
      filters.client = client?.id;
      setFilters(filters);
      dispatch(fetchReports(filters));
    }
  }, [qP?.email, agencies, filters]);

  useEffect(() => {
    filePath && downloadFileClientSide(filePath);
    dispatch(resetDownloadFileURL());
  }, [filePath]);

  useEffect(() => {
    props.setPreloader(loading);
  }, [loading]);

  return (
    <React.Fragment>
      <div className="page-content">
        {error && (
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Reports"
            children={
              <div className="d-flex">
                <FormGroup className="select2-container ml-2" style={{ width: "200px" }}>
                  <SimpleSelect
                    name="status"
                    value={{ value: selectedReportStatus.value, label: selectedReportStatus.label }}
                    onChange={(e) => {
                      setSelectReportStatus({ value: e.value, label: e.label });
                      setFilters({ ...filters, status: e.value });
                    }}
                    options={keys(REPORT_STATUSES).reduce(
                      (acc, x) => concat(acc, { label: startCase(x), value: x }),
                      selectedReportStatus.value ? [{ label: "All Statuses", value: "" }] : [],
                    )}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                <FormGroup className="select2-container ml-2" style={{ width: "200px" }}>
                  <SimpleSelect
                    name="report_id"
                    value={{ value: selectedReport.value, label: selectedReport.label }}
                    onChange={(e) => {
                      setSelectedReport({ value: e.value, label: e.label });
                      setFilters({ ...filters, report_type: e.value });
                    }}
                    options={values(EC_REPORT_TYPES).reduce(
                      (acc, x) => {
                        const [label] = x.split(" -> ");
                        return concat(acc, {
                          label: humanizeStr(label),
                          value: label,
                          version: x.includes(" -> ") ? parseInt(x.split(" -> ").pop()) || 0 : 0,
                        });
                      },
                      selectedReport.value ? [{ label: "All Reports", value: "" }] : [],
                    )}
                    classNamePrefix="select2-selection"
                    components={{ Option: DropdownItem }}
                  />
                </FormGroup>
                <i
                  className="mdi mdi-refresh ml-2 h1 cursor-pointer"
                  onClick={() => dispatch(fetchReports(filters))}
                  title="Refresh data"
                ></i>
              </div>
            }
          />
          <Card>
            {Array.isArray(reports?.data) && reports.data.length
              ? (
              <CardBody>
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reports.data.map((report, key) => (
                          <tr key={"__report" + key}>
                            <td>{startCase(report.author)}</td>
                            <td>
                              <span>{humanizeStr(report.report_type)}</span>
                              <RenderIf isTrue={report.version > 1}>
                                <Badge color="primary" className="ml-2">
                                  V{report.version}
                                </Badge>
                              </RenderIf>
                            </td>
                            <td>{report.created_at ? moment(report.created_at).format(FULL_DATE) : "N/A"}</td>
                            <td>{startCase(report.status)}</td>
                            <td>
                              {report.report_url
                                ? (
                                <i
                                  className="bx bx-sm bx-cloud-download text-primary cursor-pointer"
                                  onClick={() =>
                                    dispatch(
                                      downloadReport(report.id, {
                                        client: qP.email && !token?.agency_id ? currentClient().id : undefined,
                                      }),
                                    )
                                  }
                                />
                                  )
                                : report.reason
                                  ? (
                                <span id={"tooltip__" + key} className="text-danger" style={{ cursor: "pointer" }}>
                                  No Report
                                  <CustomTooltip placement="top" content={report.reason} target={"tooltip__" + key} />
                                </span>
                                    )
                                  : (
                                      ""
                                    )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination
                    total={reports.count}
                    pageOptions={[20, 50, 100]}
                    page={filters.page}
                    perPage={filters.per_page}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                </>
              </CardBody>
                )
              : null}
            <RenderIf isTrue={!reports?.data?.length && !loading}>
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
            </RenderIf>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { vaReport, loading } = state.SourceOrders;
  return { vaReport, loading };
};

export default connect(mapStatetoProps, {
  setPreloader,
})(Reports);
