import React, { useEffect, useState, useRef, Suspense } from "react";
import {
  Container,
  Card,
  CardBody,
  Badge,
  CardHeader,
  CardSubtitle,
  Nav,
  NavItem,
  NavLink,
  Col,
  Alert,
  Row,
  Collapse,
  Tooltip,
} from "reactstrap";
import "react-toggle/style.css";
import { useDispatch, useSelector, connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ConfirmDialogIcon, ConfirmationAlert } from "../../components/Common/ConfirmDialog";
import ProcessingItemModal from "./components/ProcessingItemModal";
import ShipmentsTable from "./components/InboundModel";
import MarkIssueModal from "./components/MarkIssueModal";
import CancelMarketPlaceOrderModal from "./components/CancelMarketPlaceOrderModal";
import OrderLogModal from "./components/OrderLogModal";
import ShippingDetailModal from "./components/ShippingDetailModal";
import OrderAttempts from "./components/OrderAttempts";
import ActionButtons from "./components/DetailActionButtons";
import TrackingInfoModal from "./components/TrackingInfoModal";
import OrderStatusModal from "./components/OrderStatusModal";
import OrderLogsTable from "./components/OrderLogsTable";
import EditSupplierOrderModal from "./components/EditSupplierOrderModal";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import CustomTooltip from "../../components/Common/CustomTooltip";
import UpdateStock from "../../components/Common/UpdateStock";
import PillBtn from "../../components/Common/PillBtn";
import SeeMoreText from "../../components/Common/SeeMoreText";
import NoteTrackingItemModal from "../WareHouse/Components/AddWarehouseNoteModal";
import SelectWarehouseModal from "./components/SelectWarehouseModal";
import SweetAlert from "react-bootstrap-sweetalert";
import GenerateShippingLabelModal from "./components/GenerateShippingLabelModal";
import EditWHCostModal from "./components/EditWHCostModal";
import SearchInventoryModal from "./components/SearchInventoryModal";
import { is5050Match } from "../Listings/Helpers/common";
import * as actions from "../../store/actions";
import { Link, withRouter, useParams } from "react-router-dom";
import {
  STATUS_COLOR_CODE,
  SOURCE_ORDER_STATUSES,
  DEV_IDS,
  MARKET_PLACES,
  RETURN_LABEL_USERS,
  ORDER_STATUS_MAPPING,
  WH_NOTICES_TYPES,
  DATE_FORMATS,
  ORDER_TRACKING_STATUSES,
  IL_WH_ID,
} from "../../constants";
import { mapTrackingUrl } from "../../utils/trackingUrlMap";
import moment from "moment-timezone";
import classnames from "classnames";
import { calculateAOPrice } from "../../utils/profitCalculator";
import $ from "lodash";
import { State } from "country-state-city";

// actions
import {
  fetchSourceOrderDetail,
  fetchNextOrder,
  refreshSourceOrderDetail,
  setPreloader,
  setMarkIssueModal,
  setCancelMarketPlaceOrderModal,
  getFeedStatus,
  updateSourceOrder,
  setOrderLogModal,
  setAssignInventoryModal,
  setAssignOutboundModal,
  assignInventory,
  updateAOStatus,
  setItemsForProcessing,
  setShippingDetailModal,
  setTrackingInfoModal,
  fetchAOStatus,
  setOrderStatusModal,
  fetchMarketPlaceStatus,
  deleteSupplierOrder,
  setSkuAsMismatch,
  setEditSupplierOrderModal,
  fetchPlatforms,
  updateOrderAttempt,
  setReturnAddressModal,
  createOrderNote,
  setTrackingUpload,
  importOrderManually,
  updateSourceOrderStatus,
  changeOrderType,
  setChangeWareHouseModal,
  fetchAllWarehouses,
  setSelectWareHouseModal,
  updateS3Object,
  setGenerateShippingLabelModal,
  manualFulfillmentCollapseIsOpen,
  setEditWHCostModal,
  resetSuccessErrMsg,
  fetchUserWarehouses,
  togglePriceEventEditModal,
} from "../../store/actions";

import {
  capitalize,
  isManualTeam,
  makeAmzOfferLink,
  decode,
  toPascalCase,
  getUserInfo,
  parseIssueNote,
  makeTopDawgOfferLink,
  warehouseCost,
  encodeSKU,
  toF,
  toUpper,
  RenderIf,
  parseErr,
  formatNumber,
  getDate,
  getTotalPriceAdj,
  getWarehouseName,
  encode,
  formatNoteText,
  isUserHaveFullAccess,
  calculateEstCost,
  estCostPredicate,
  amountText,
  setSupplierURL,
  isMarketplaceFulfilled,
} from "../../utils/common";
import getBrandImageSrc from "../../utils/brandImages";
import UpdateCOGSModal from "./components/UpdateCOGSModal";
import {
  FeesTooltip,
  ConditionalColouredValue,
  ColouredRightAlignText,
  RightAlignText,
  parseInQueueStatus,
  Anchor,
  isManualFulfilled,
  isAOHidden,
  isEditShippingAllowed,
  isStoreFulfilled,
  isIFulfil,
  ShowProfit,
  CategoriesTooltip,
} from "./common";
const PriceEventsTable = React.lazy(() => import("./components/PriceEvents/IndexTable"));
const PriceEventsModal = React.lazy(() => import("./components/PriceEvents/IndexModal"));
const PriceEventsAddModal = React.lazy(() => import("./components/PriceEvents/AddModal"));

const { FULL_DATE } = DATE_FORMATS;
const TYPES_TO_EXCLUDE = ["other-transaction", "fees"];
const PRICE_MAPPING = { "Principal Tax": "Tax" };
const SALE_TAX_MAPPING = { "Principal Tax": "Tax Retained", "Shipping Tax": "Shipping Tax Retained" };
const REFUND_TAX_MAPPING = { "Principal Tax": "Tax Returned", "Shipping Tax": "Shipping Tax Returned" };
const PROMOTION_MAPPING = { Shipping: "Promotion Shipping" };
const FALLBACK_MAPPING = { Principal: "price", Shipping: "shipping", Tax: "tax" };
const SALE_KEYS_MAPPING = { price: PRICE_MAPPING, taxes: SALE_TAX_MAPPING, promotion: PROMOTION_MAPPING };
const REFUND_KEYS_MAPPING = { price: PRICE_MAPPING, taxes: REFUND_TAX_MAPPING, promotion: PROMOTION_MAPPING };

const tableHeaders = ["Item Details", "Qty.", "Price", "Warehouse", "Tracking Details"].map((title) => ({ title }));
const sellerName = (item) => item?.amazon_seller?.name || (/amazon/i.test(item?.seller_id) && "Amazon") || "";
const filterAOStatuses = (props, item) => props.Supplier?.aoStatuses?.filter((status) => (status.sku === item.sku) || (status.item_id === item.sku));

const processLink = (link) => {
  if (/amazon/i.test(link) && isManualTeam()) return setSupplierURL(makeAmzOfferLink(link));
  return setSupplierURL(link) || "";
};

function parseMarketplaceLink (props, item, key) {
  try {
    const link = filterAOStatuses(props, item)?.[0]?.[key];
    return processLink(link);
  } catch (error) {
    return null;
  }
}

function OrderBadge (props) {
  const store = isStoreFulfilled(props);
  const isWH = props.isWH(); // WH stands for warehouse
  const isWS = props.SourceOrders.sourceOrderDetail?.data?.source_items?.some(
    (item) => filterAOStatuses(props, item)?.[0]?.supplier_type === "wholesale",
  ); // WS stands for wholesale
  const HeaderBadge = ({ content }) => (
    <span className="d-flex align-items-center ml-2">
      <Badge className={"font-size-14 badge-primary"} color="primary" pill>
        {content}
      </Badge>
    </span>
  );
  switch (true) {
    case !!store:
      return <HeaderBadge content={store} />;
    case !!isWH:
      return <HeaderBadge content="2 Step" />;
    case !!isWS:
      return <HeaderBadge content="Wholesale" />;
    default:
      return <></>;
  }
}

function handleWholeSalersLink (props, item) {
  const filterStatus = filterAOStatuses(props, item)?.[0];
  if (!(filterStatus?.supplier_type === "wholesale" && filterStatus?.supplier_sku)) return null;
  return filterStatus?.supplier_platform === "topdawg" ? (
    <div>
      {makeTopDawgOfferLink(filterStatus?.upc) ? (
        <CopyToClipBoard
          image={getBrandImageSrc(filterStatus?.supplier_platform)}
          alt="topdawg logo"
          text={filterStatus?.supplier_sku || ""}
          openDirection="left"
        />
      ) : null}
      <span className="text-white">Topdawg Id: </span>
      {filterStatus?.supplier_sku || ""}
    </div>
  ) : (
    <>
      <span className="text-body">Supplier Name: {filterStatus?.supplier_platform || ""}</span>
      <span className="text-body">Supplier SKU: {filterStatus?.supplier_sku || ""}</span>
    </>
  );
}

const getCOGS = (order) => calculateEstCost(order) + getTotalPriceAdj(order);

const loaders = ["SourceOrders", "OrderAttempt", "Supplier", "Emails", "SupplierOrders", "SourceItems"];

const SourceOrderDetails = (props) => {
  const dispatch = useDispatch();
  const orderAction = useRef({});
  const isFullAccess = isUserHaveFullAccess();
  const [seeMore, setSeeMore] = useState("");
  const [activeAddressTab, setActiveAddressTab] = useState(0);
  const { error } = useSelector((state) => state.SupplierOrders);
  const { wareHouses } = useSelector((state) => state.WareHouses);
  const { manualFulfillmentCollapse, editWHCostModalIsOpen } = useSelector((state) => state.SourceOrders);
  const { updateCOGSModalIsOpen } = useSelector((state) => state.Repricer);
  const [sourceOrder, setSourceOrder] = useState(props.SourceOrders.sourceOrderDetail);
  const id = useParams()?.id?.replace(/\D+/, "");
  const [toolTipEvenBreak, setToolTipEvenBreak] = useState(false);
  const [selectedSupplierOrder, setSelectedSupplierOrder] = useState(undefined);
  const [totalCostHidden, setTotalCostHidden] = useState({
    cost: true,
    refund: false,
    marketplace: false,
    cogs: false,
    warehouse: false,
    adjustments: false,
    otherAdjustments: false,
    extra: false,
    wh_return: false,
  });
  const { account_id: accountId } = getUserInfo();
  const [address, setAddress] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [itemVerifiedConfirmation, setItemVerifiedConfirmation] = useState({});
  const [orderLogTable, setOrderLogTable] = useState(false);
  const [aoProfit, setaoProfit] = useState({ supplierPrice: 0, profit: 0 });
  const { changedWareHouseId, sourceOrderDetail } = props.SourceOrders;
  const warehouseIssue = sourceOrder?.tracking_items?.find((item) => item.issue_type);
  const priceAdj = getTotalPriceAdj(sourceOrder);
  const isMPFulfilledOrder = ["AFN", "WFS"].includes(sourceOrder?.fulfillment_channel);

  const mapFees = (value, title) => ({ title, value });

  const perItemMartketplaceFees = $.map(sourceOrder?.source_items, (sourceItem) =>
    $.map(
      ["Sale", "Refund"].reduce(
        (fees, type) => $.merge(fees, $.get(sourceItem, `settlement_details.${type}.fees`, {})),
        {},
      ),
      mapFees,
    ),
  );

  const allItemMarketplaceFees = $.uniqBy(perItemMartketplaceFees, $.isEqual);

  const getAllItemSettlement = (amountType, func) =>
    $.reduce(
      amountType,
      (allValues, currentValue) => {
        $.forEach(currentValue, ({ title }) => allValues.push({ title, value: func(title) }));
        return allValues;
      },
      [],
    );

  const convertKey = (key, obj) => obj[key] || key;

  const updateObj = (obj, key, mapping, path) => {
    const mappedKey = convertKey(key, mapping);
    !obj[mappedKey] && $.update(obj, mappedKey, () => $.sumBy(sourceOrder?.source_items, `${path}.${key}`));
  };

  const setFallbackValues = (obj) => {
    $.forEach(FALLBACK_MAPPING, (mapping, type) =>
      $.update(obj, type, (x) => x ?? $.sumBy(sourceOrder?.source_items, mapping)),
    );
  };

  const sortObj = (obj) =>
    ["price", "promotion", "taxes"].reduce(
      (allTypes, type) => $.merge(allTypes, $.fromPairs($.sortBy($.toPairs(obj[type]), [$.first]))),
      {},
    );

  const setType = (amountType, keyMapping) =>
    $.reduce(
      sourceOrder?.source_items,
      (obj, sourceItem) => {
        $.forEach(keyMapping, (mapping, type) => {
          const settlementKey = `settlement_details.${amountType}.${type}`;
          $.forEach($.get(sourceItem, settlementKey), (_, title) =>
            updateObj(obj[type], title, mapping, settlementKey),
          );
        });
        return obj;
      },
      { price: {}, taxes: {}, promotion: {} },
    );

  const setSale = () => {
    const sale = sortObj(setType("Sale", SALE_KEYS_MAPPING));
    setFallbackValues(sale);
    return sale;
  };

  const mergeSum = (srcObj, destObj) =>
    $.mergeWith(srcObj, destObj, (dest, src) => ($.isNumber(dest) ? dest + src : undefined));

  const settlementDetails = {
    sale: setSale(),
    refund: sortObj(setType("Refund", REFUND_KEYS_MAPPING)),
    marketplaceFees: getAllItemSettlement(allItemMarketplaceFees, (title) =>
      $.sumBy(["Sale", "Refund"], (type) =>
        $.sumBy(sourceOrder?.source_items, `settlement_details.${type}.fees.${title}`),
      ),
    ),
    OtherTransaction: $.reduce(
      {
        ...sourceOrder?.order_fees?.OtherTransaction,
        ...$.reduce(
          sourceOrder?.source_items,
          (result, sourceItem) => mergeSum(result, $.get(sourceItem, "settlement_details.OtherTransaction")),
          {},
        ),
      },
      (transactions, transactionType, transactionTypeName) => {
        $.forEach(transactionType, (value, title) => {
          if ($.includes(TYPES_TO_EXCLUDE, transactionTypeName) || $.size(transactionType) > 1) {
            transactions.push({ title: $.startCase(title), value, [transactionTypeName]: transactionType });
          } else {
            transactions.push({
              title: $.startCase(transactionTypeName),
              value,
              [transactionTypeName]: transactionType,
            });
          }
        });
        return transactions;
      },
      [],
    ),
    totals: {},
  };

  const sumObj = (obj) => $.reduce(obj, (sum, currentValue) => sum + currentValue, 0);

  $.forEach(["sale", "refund"], (type) => (settlementDetails.totals[type] = sumObj(settlementDetails[type])));
  $.forEach(["marketplaceFees", "OtherTransaction"], (type) => {
    settlementDetails.totals[type] = $.sumBy(settlementDetails[type], "value");
  });
  settlementDetails.totals.shipping = -warehouseCost(sourceOrder);

  const breakEvenPriceAmount = amountText(
    $.reduce($.omit(settlementDetails.totals, "refund"), (total, val) => total + val, 0),
  );

  const calculateSingleItemMarketplaceFees = (itemNumber) => {
    const allFees = [];
    $.forEach(perItemMartketplaceFees[itemNumber], ({ title }) => {
      allFees.push({
        title,
        value:
          $.result(sourceOrder?.source_items[itemNumber], `settlement_details.Sale.fees.${title}`, 0) +
          $.result(sourceOrder?.source_items[itemNumber], `settlement_details.Refund.fees.${title}`, 0),
      });
    });
    return allFees;
  };

  const isVisbile = (x) => !x.isHide;

  const addressTabs = [
    { title: "Customer Address", id: 0 },
    { title: "Warehouse Address", id: 1 },
  ];
  const [adjustments, setAdjustments] = useState([]);
  const toggleActiveTab = (tab) => {
    if (activeAddressTab !== tab) setActiveAddressTab(tab);
  };
  const [isAttachVirtualInventory, setIsAttachVirtualInventory] = useState(false);
  const [isAttachVirtualInventoryBuffer, setIsAttachVirtualInventoryBuffer] = useState({});
  const handleUpdateAOStatus = (item) => {
    props.updateAOStatus(
      {
        platform: sourceOrder?.store_name,
        [sourceOrder?.store_name === MARKET_PLACES.shopify ? "item_id" : "sku"]: item.sku,
        status: !getItemStatus(item),
        partner_id: sourceOrder?.marketplace_account_id,
      },
      sourceOrder.id,
    );
  };
  const [warehouseObj, setWarehouseObj] = useState();
  const [isUploadLabelModelOpen, setIsUploadLabelModelOpen] = useState(false);
  const { warehouseNoteModalIsOpen, selectedWarehouseItem, associatedWarehouses } = useSelector((state) => state.WareHouses);
  useEffect(() => {
    setWarehouseObj($.keyBy(associatedWarehouses?.data, "id"));
  }, [associatedWarehouses]);
  useEffect(() => {
    if (!activeAddressTab) setActiveAddressTab(isWareHouseOrder() ? 1 : 0);
    !props.SourceOrders.sourceOrderDetail && props.fetchSourceOrderDetail(id);
    props.SourceOrders.sourceOrderDetail && setSourceOrder(props.SourceOrders.sourceOrderDetail.data);
    props.SourceOrders.sourceOrderDetail &&
      !props.Supplier.loading &&
      props.fetchAOStatus({
        platform: props.SourceOrders.sourceOrderDetail?.data.store_name,
        skus: props.SourceOrders.sourceOrderDetail?.data.source_items?.map((item) => item.sku),
        partner_id: props.SourceOrders.sourceOrderDetail?.data?.marketplace_account_id,
      });
    if (props.SourceOrders.sourceOrderDetail?.data?.supplier_orders.length) {
      const supplierOrders = props.SourceOrders.sourceOrderDetail.data?.supplier_orders;
      for (const suppOrder of supplierOrders) {
        if (suppOrder?.emails.length) {
          suppOrder.emails.forEach((x) => {
            if (x.email_type === "refund")
              setAdjustments([...adjustments, { date: x.email_date, adjustment: x.adjustments }]);
          });
        }
      }
    }
  }, [props.SourceOrders.sourceOrderDetail]);

  useEffect(() => {
    dispatch(resetSuccessErrMsg());
    dispatch(actions.resetItemsState());
    !props.Platform.platforms.data?.length && props.fetchPlatforms({});
    !props.SourceOrders.sourceOrders?.data?.length && props.fetchNextOrder(id);
    props.fetchAllWarehouses({ associated: true });
    props.fetchUserWarehouses({ include_deleted: false });
    dispatch(manualFulfillmentCollapseIsOpen(isManualEligible()));
    dispatch(actions.setUpdateCOGSModal(false));
  }, []);

  useEffect(
    () => {
      props.setPreloader(loaders?.some((key) => props[key]?.loading));
    },
    loaders.map((key) => props[key].loading),
  );

  const isProcessed = () => (sourceOrder?.supplier_orders || []).some((x) => x.status === "processed");

  const settlementExists = () => (sourceOrder?.source_items || []).some((x) => x.settlement_parsed);

  const inboundData = props?.SourceOrders?.sourceOrderDetail?.inbound_data;
  const groupInbounds = $.keyBy(inboundData, "target_order_id");
  const handleSupplierStatuses = (item) => {
    const inboundData = groupInbounds[item?.target_order_id];
    const { status: inboundStatus, tracking_status_update_date: updateDate } = inboundData || {};
    if (item?.tracking_status === "delivered" && inboundStatus === "incomplete" && item?.warehouse_id) {
      return (
        <div>
          <div className="d-flex align-items-baseline">
            <h6>Status:&nbsp;</h6>
            <Badge
              className={"font-size-12 mb-1 badge-soft-" + STATUS_COLOR_CODE.showing_delivered}
              color={STATUS_COLOR_CODE.showing_delivered}
              pill
            >
              {toPascalCase("showing_delivered")}
            </Badge>
            <CustomTooltip
              target={`_status_${item?.id}`}
              content="It might be showing delivered but not actually arrived/checked-in  at warehouse yet."
            />
            <i className="bx bx-info-circle ml-1" id={`_status_${item.id}`}></i>
          </div>
          <RenderIf isTrue={updateDate}>
            <div className="d-flex align-items-baseline">
              <h6>Last Updated:&nbsp;</h6>
              {getDate(updateDate, DATE_FORMATS.FULL_DATE)}
            </div>
          </RenderIf>
        </div>
      );
    }
    return (
      <div>
        <div className="d-flex align-items-baseline">
          <h6>Status:&nbsp;</h6>
          <Badge
            className={"font-size-12 badge-soft-" + STATUS_COLOR_CODE[item?.tracking_status]}
            color={STATUS_COLOR_CODE[item?.tracking_status]}
            pill
          >
            {toPascalCase(item?.tracking_status)}
          </Badge>
          <RenderIf
            isTrue={
              $.values($.pick(ORDER_TRACKING_STATUSES, "undeliverable", "cancelled")).includes(item?.tracking_status) &&
              item?.tracking_response_text
            }
          >
            <CustomTooltip target={`_tracker_response_${item?.id}`} content={item.tracking_response_text} />
            <i className="bx bx-info-circle ml-1" id={`_tracker_response_${item?.id}`}></i>
          </RenderIf>
        </div>
        <RenderIf isTrue={item?.tracking_status_update_date}>
          <div className="d-flex align-items-baseline">
            <h6>Last Updated:&nbsp;</h6>
            {getDate(item?.tracking_status_update_date, DATE_FORMATS.FULL_DATE)}
          </div>
        </RenderIf>
      </div>
    );
  };

  const handleSkuMismatch = (order, item) => {
    const [sku, mismatch] = [item?.sku, !getMismatchStatus(item?.sku)];
    const { id, username, email } = getUserInfo() || {};
    props.setSkuAsMismatch(
      {
        platform: order?.store_name,
        sku,
        is_mismatch: mismatch,
        partner_id: order?.marketplace_account_id,
        va_id: id,
        actor_name: username,
        actor_email: email,
        store_name: order?.marketplace_account?.name,
      },
      order?.id,
    );
  };

  const orderItemsHeaders = [
    {
      title: "Product Details",
      isAlignmentDefault: true,
      content: ({ item }) => (
        <>
          {!is5050Match(filterAOStatuses(props, item)?.[0]) ? (
            <span className="text-body font-weight-bold">
              <h6 className="mb-0">
                SKU
                <Link
                  to={
                    isFullAccess
                      ? `/listings/${encodeSKU(item)}/suppliers?platform=${sourceOrder?.store_name}&storeId=${
                          sourceOrder?.marketplace_account_id
                        }`
                      : `/listings_new?sku=${encodeSKU(item)}&account_id=${sourceOrder?.marketplace_account_id}`
                  }
                  target="_blank"
                  className="text-primary mb-1 ml-2"
                >
                  {item?.sku}
                </Link>
                <CopyToClipBoard text={item?.sku} />
              </h6>
            </span>
          ) : (
            <span className="text-body font-weight-bold">
              SKU: {item?.sku}
              {item.shared_listing && <i className="bx bx-shekel" />}
              <CopyToClipBoard text={item?.sku} />
            </span>
          )}
          <span className="d-flex flex-row">
            <u title={item?.name} className="text-overflow-wrap cursor-pointer">
              {item?.name}
            </u>
            <CopyToClipBoard className="text-decoration-none" text={item?.name} />
          </span>
          <span className="d-flex align-items-center">
            <h6 className="mb-0 align-items-center">Line Number:</h6>
            <p className="mb-0 ml-1">{item?.marketplace_item_id}</p>
            <Badge
              className={"font-size-10 ml-2 badge-soft-" + STATUS_COLOR_CODE[item?.marketplace_status]}
              color={STATUS_COLOR_CODE[item?.marketplace_status]}
              pill
            >
              {toPascalCase(item?.marketplace_status)}
            </Badge>
          </span>
          {item?.marketplace_status?.toLowerCase() === SOURCE_ORDER_STATUSES.shipped && item?.tracking_num && (
            <span className="d-flex">
              <h6 className="mb-0 align-items-center">Tracking Num: </h6>
              <p className="mb-0 ml-1">{item?.tracking_num}</p>
            </span>
          )}
          {item?.status_date && (
            <span className="d-flex align-items-center">
              <h6 className="mb-0 ">Status Date: </h6>
              <p className="mb-0 ml-1">{moment(item?.status_date).format(FULL_DATE)}</p>
            </span>
          )}
        </>
      ),
    },
    {
      title: "Quantity",
      content: ({ item }) => <div className="text-center">{item.qty}</div>,
    },
    { title: "Price", content: ({ item }) => <div className="text-center">{formatNumber(item.price, "currency")}</div> },
    { title: "Tax", content: ({ item }) => <div className="text-center">{formatNumber(item.tax, "currency")}</div> },
    {
      title: "Shipping",
      content: ({ item }) => <div className="text-center">{formatNumber(item.shipping, "currency")}</div>,
    },
    {
      title: "Marketplace Fees",
      content: ({ index }) => {
        const mpFees = calculateSingleItemMarketplaceFees(index);
        const total = $.sumBy(mpFees, "value");
        return (
          <div className="d-flex justify-content-center">
            <FeesTooltip
              index={index}
              underline={false}
              sourceItem={sourceOrder.source_items[index]}
              title={amountText(total)}
              fees={mpFees}
              total={total}
            />
          </div>
        );
      },
    },
    {
      renderHeader: () => (
        <span className="d-flex align-items-center">
          <span>COGS</span>
          <i id="COGSPriceToolTip" className="bx bx-xs bx-info-circle text-primary mx-1 cursor-pointer" />
          <CustomTooltip placement="top" content="Order + Tax = COGS" target="COGSPriceToolTip" />
        </span>
      ),
      content: ({ item }) => (
        <div className="text-center">
          <div className="d-inline-flex justify-content-center align-items-center gap-1">
            <p className="text-primary mt-3">{formatNumber(estCostPredicate(item))}</p>
            <i
              className="bx bx-pencil text-primary mx-1 cursor-pointer"
              onClick={() => dispatch(actions.setUpdateCOGSModal(true))}
            />
          </div>
        </div>
      ),
    },
    {
      isHide: !isFullAccess,
      title: "Verified Match",
      content: ({ item, colKey }) => (
        <div className="text-center">
          <RenderIf fallback="SKU not found" isTrue={filterAOStatuses(props, item).length}>
            <div key={colKey} className="p-1 custom-control custom-switch custom-switch-lg">
              <input
                type="checkbox"
                className="custom-control-input"
                id={"_ao_toggle_supplier_order" + colKey}
                checked={getItemStatus(item)}
                onChange={() => null}
              />
              <label
                onClick={() => {
                  setItemVerifiedConfirmation(item);
                  setIsOpen(true);
                }}
                className="cursor-pointer custom-control-label"
              ></label>
            </div>
          </RenderIf>
        </div>
      ),
    },
    {
      isHide: !isFullAccess,
      title: "Product Link",
      content: ({ item }) => (
        <div>
          {filterAOStatuses(props, item).length > 0 && (
            <div className="d-flex flex-column">
              {parseMarketplaceLink(props, item, "marketplace_link") && (
                <div>
                  {parseMarketplaceLink(props, item, "marketplace_link") ? (
                    <CopyToClipBoard
                      image={getBrandImageSrc(parseMarketplaceLink(props, item, "marketplace_link"))}
                      text={parseMarketplaceLink(props, item, "marketplace_link")}
                      alt="marketplace_image"
                      openDirection="left"
                    />
                  ) : (
                    ""
                  )}
                  {Anchor(parseMarketplaceLink(props, item, "marketplace_link"), "Marketplace Link")}
                </div>
              )}
              {parseMarketplaceLink(props, item, "supplier_link") ? (
                <div>
                  {parseMarketplaceLink(props, item, "supplier_link") ? (
                    <CopyToClipBoard
                      image={getBrandImageSrc(parseMarketplaceLink(props, item, "supplier_link"))}
                      text={parseMarketplaceLink(props, item, "supplier_link")}
                      alt="supplier_image"
                      openDirection="left"
                    />
                  ) : (
                    ""
                  )}
                  {Anchor(
                    parseMarketplaceLink(props, item, "supplier_link"),
                    `Supplier Link ${item.qty > 1 ? `(${item.qty})` : ""}`,
                  )}
                  {getUserInfo()?.account_id === 2 && item?.ship_to_warehouse ? (
                    <i className="ml-2 mdi mdi-home-city-outline" title="Ship to warehouse"></i>
                  ) : null}
                </div>
              ) : (
                handleWholeSalersLink(props, item)
              )}
            </div>
          )}
        </div>
      ),
      isAlignmentDefault: true,
    },
    {
      isHide: !isFullAccess,
      title: "Action",
      content: ({ item }) => (
        <div className="text-center">
          {!is5050Match(filterAOStatuses(props, item)?.[0]) && accountId === 2
            ? sourceOrder?.store_name === "walmart" && (
                <UpdateStock
                  sku={item?.sku}
                  platform={sourceOrder?.store_name}
                  partnerId={sourceOrder?.marketplace_account_id}
                  onDetailsPage
                />
            )
            : null}
          <ConfirmDialogIcon
            icon={{
              title: "Set SKU as Mismatch",
              className: "ml-1 font-size-11 font-style-normal",
              color: "primary",
            }}
            msg={`Set as ${getMismatchStatus(item?.sku) ? "Match" : "Mismatch"}! SKU:${item?.sku}`}
            text="Set SKU as Mismatch"
            onConfirm={() => handleSkuMismatch(sourceOrder, item)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!props.SupplierOrders.error && props.SupplierOrders.success && !props.SupplierOrders.loading) {
      props.refreshSourceOrderDetail();
    }
  }, [props.SupplierOrders.error, props.SupplierOrders.success]);

  useEffect(() => {
    setAddress(getAddress(address));
  }, [activeAddressTab, sourceOrderDetail?.data?.source_order_address, changedWareHouseId]);

  const getMismatchStatus = (sku) => filterAOStatuses(props, { sku })?.[0]?.is_mismatch;

  function handlePriceChange (e) {
    setaoProfit({
      supplierPrice: e.target.value,
      profit: toF(calculateAOPrice(sourceOrder, props.Platform.platforms.data) - e.target.value),
    });
  }

  function isWareHouseOrder () {
    return (
      props.SourceOrders.sourceOrderDetail?.data?.fulfillment_channel === "WH" ||
      props.SourceOrders.sourceOrderDetail?.data?.source_items?.some((item) => item.ship_to_warehouse)
    );
  }

  const getAddress = () => {
    if (activeAddressTab === 0) return sourceOrderDetail?.data?.source_order_address;
    if (isWareHouseOrder() && changedWareHouseId) return wareHouses?.data?.find((x) => x.id === changedWareHouseId);
    return sourceOrderDetail?.data.wh_address;
  };

  const getShipmentAddress = (address, isJSX = false) => {
    if (!address) return "";
    return !isJSX
      ? (
          [
            address?.address1,
            address?.address2,
            address?.city + ", ",
            address?.state + ", ",
            address?.country + ", ",
            address?.zipcode,
          ]
            .filter(Boolean)
            .join(" ") || ""
        )
      : (
      <span>
        {[address?.address1, address?.address2, address?.city + ", "].filter(Boolean).join(" ")}
        <span id={`_state_${sourceOrder?.id}`}>{address?.state + ", "}</span>
        {stateName ? <CustomTooltip target={`_state_${sourceOrder?.id}`} content={stateName} /> : null}
        {[address?.country + ",", address?.zipcode].filter(Boolean).join(" ")}
      </span>
        );
  };

  const isManualEligible = () =>
    manualFulfillmentCollapse &&
    sourceOrder?.order_logs?.some(
      (x) =>
        (toUpper(getUserInfo()?.username).includes(x.created_by) && x.note.includes("manual_fulfillment")) ||
        getUserInfo()?.id === 27,
    );

  const getWHShipmentAddress = (address, isJSX = false) => {
    const isILWH = address?.id === IL_WH_ID;
    const tenantId = getUserInfo()?.account_id;
    const newAddress1 = `${address?.address1}${isILWH ? `-${tenantId}` : ""}`;
    const newAddress2 = `${address?.address2 || ""} ${!isILWH ? `STE # ${tenantId}` : ""}`;
    return isJSX
      ? (
      <span>
        {newAddress1} <br />
        <RenderIf isTrue={!isILWH}>
          {newAddress2}
          <br />
        </RenderIf>
        {address?.city + ", "}
        <span id={`_state_${sourceOrder?.id}`}>{address?.state + ", "}</span>
        {stateName ? <CustomTooltip target={`_state_${sourceOrder?.id}`} content={stateName} /> : null}
        {[address?.country + ",", address?.zipcode].filter(Boolean).join(" ")}
      </span>
        )
      : (
          getShipmentAddress({ ...address, address1: newAddress1, address2: newAddress2 })
        );
  };

  const sumObjValues = obj => $.sum($.values(obj));
  const getPath = (obj, path) => $.get(obj, path, {});
  const calSumOfItemPath = (items, path, func = sumObjValues, ...args) =>
    $.sumBy(items, item => func(getPath(item, path), ...args));

  const calculateBuyerTotal = () => {
    const sourceItems = sourceOrder?.source_items;
    const paths = ["price", "promotion"];

    const total = paths.reduce((acc, path) => {
      const pathSum = calSumOfItemPath(sourceItems, `settlement_details.Sale.${path}`);
      return acc + pathSum;
    }, 0);

    return total;
  };

  const getSupplierTotal = (item) => {
    const getMsg = ({ adjustments, promotions }) => {
      if (adjustments && promotions) return "Adjustment and Promotion";
      else if (adjustments) return "Adjustment";
      else return "Promotion";
    };
    const isPromotion = item.promotions || item.adjustments;
    return (
      <>
        <div
          className={`d-flex ${isPromotion ? "align-items-baseline ml-1" : ""}`}
          style={{ color: isPromotion && "#fcbc03" }}
        >
          <div id={`supplierTotalToolTip_${item.id}`}>${item?.order_total}</div>
          {isPromotion ? <i id={`supplierPromotionMsgToolTip_${item.id}`} className="bx bxs-info-circle ml-1" /> : null}
        </div>
        <>
          {isPromotion
            ? (
            <CustomTooltip
              placement="top"
              target={`supplierPromotionMsgToolTip_${item.id}`}
              content={`${getMsg(
                item,
              )} was applied to the Supplier order which is affecting the total cost. See below.`}
            />
              )
            : null}
          <CustomTooltip
            placement="top"
            target={`supplierTotalToolTip_${item.id}`}
            content={
              <>
                <div className="d-flex ">
                  <u>
                    Price: ${item?.cost} x {item.qty}
                  </u>
                </div>
                <div className="d-flex ">Tax: ${item?.tax}</div>
                <div className="d-flex ">Shipping: ${item?.shipping_cost}</div>
                {item.promotions ? <div className="d-flex ">Promotion: ${item.promotions}</div> : null}
                {item.adjustments ? <div className="d-flex ">Adjustment: ${item.adjustments}</div> : null}
              </>
            }
          />
        </>
      </>
    );
  };
  const sourceOrderDetailBuffer = props?.SourceOrders?.sourceOrderDetail?.data;
  const addressData = sourceOrderDetailBuffer?.source_order_address;
  const marketplaceData = sourceOrderDetailBuffer?.marketplace_account;

  const getItemStatus = (item) => filterAOStatuses(props, item)?.[0]?.auto_ordering_enabled;
  const countryState = getAddress()?.state;
  const stateName = countryState
    ? State.getStatesOfCountry("US")?.find((x) => x.isoCode?.toLowerCase() === countryState?.toLowerCase())?.name
    : "";

  const getManualFulfillmentPerson = (sourceOrder) => {
    const createdBy = sourceOrder?.order_logs?.find((x) => x.note?.includes("to manual_fulfillment"))?.created_by;
    return `${createdBy ? `by ${createdBy}` : "manually"}`;
  };

  const getWHAddressName = () =>
    `${getAddress()?.id === IL_WH_ID ? getUserInfo()?.account_id : ""} 2SEC - ${
      sourceOrderDetail?.data?.source_order_address?.name || ""
    }`;

  const formatTransactionDetails = (amount, label) => (amount < 0 ? `${amountText(amount)} (${label})` : "");

  const getBreakEvenTooltip = () => {
    const whCostMsg = `${
      isWareHouseOrder() && warehouseCost(sourceOrder) < 0
        ? amountText(warehouseCost(sourceOrder)) + " (Shipping)"
        : ""
    }`;
    return `${amountText(settlementDetails.totals.sale) + " (Order Total)"}
     ${formatTransactionDetails(settlementDetails.totals.marketplaceFees, "Marketplace Fees")}
     ${formatTransactionDetails(settlementDetails.totals.OtherTransaction, "Adjustments")}
     ${formatTransactionDetails(settlementDetails.totals.shipping, "Shipping")}
     ${whCostMsg} = ${breakEvenPriceAmount}`;
  };
  const getText = (text) => text || "N/A";
  const data = props.SourceOrders.sourceOrderDetail?.data;
  const keyBySku = $.keyBy(data?.outbound?.outbound_items, "warehouse_inventory.sku");
  const isLabelUploaded = data?.source_items.every(el => !!keyBySku[el.sku]?.label);

  const componentDetails = orderItemsHeaders.filter(isVisbile);
  return (
    <React.Fragment>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleUpdateAOStatus(itemVerifiedConfirmation);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {`to ${!getItemStatus(itemVerifiedConfirmation) ? "enable" : "disable"} verified match`}
        </SweetAlert>
      </RenderIf>
      <RenderIf isTrue={isAttachVirtualInventory}>
        <ConfirmationAlert
          onConfirm={() => props.assignInventory(isAttachVirtualInventoryBuffer)}
          isOpen={isAttachVirtualInventory}
          setIsOpen={setIsAttachVirtualInventory}
          msg={<p>To attch virtual inventory?</p>}
        />
      </RenderIf>
      <RenderIf isTrue={updateCOGSModalIsOpen}>
        <UpdateCOGSModal order={sourceOrder} />
      </RenderIf>
      {warehouseNoteModalIsOpen && (
        <NoteTrackingItemModal
          notice_type={WH_NOTICES_TYPES.tracking_items}
          notices={selectedWarehouseItem?.warehouse_notices}
          selectedItem={selectedWarehouseItem}
        />
      )}
      {editWHCostModalIsOpen && (
        <EditWHCostModal
          isOpen={editWHCostModalIsOpen}
          toggleIsOpen={props.setEditWHCostModal}
          updateOrder={props.updateSourceOrder}
          sourceOrderDetail={sourceOrderDetail}
        />
      )}
      {props.SourceOrders.selectWarehouseModalIsOpen && <SelectWarehouseModal orderAction={orderAction} />}
      {props.SourceOrders.generateShippingLabelModal && (
        <GenerateShippingLabelModal
          isOpen={props.SourceOrders.generateShippingLabelModal}
          toggleIsOpen={props.setGenerateShippingLabelModal}
          getShipmentAddress={getShipmentAddress}
          sourceOrder={sourceOrder}
        />
      )}
      {props.SourceOrders.markIssueModalIsOpen && (
        <MarkIssueModal
          isOpen={props.SourceOrders.markIssueModalIsOpen}
          toggleIsOpen={props.setMarkIssueModal}
          sourceOrderId={props.SourceOrders.sourceOrderDetail?.data.id}
        />
      )}
      {props.SourceOrders.cancelMarketPlaceOrderModalIsOpen && (
        <CancelMarketPlaceOrderModal
          isOpen={props.SourceOrders.cancelMarketPlaceOrderModalIsOpen}
          toggleIsOpen={props.setCancelMarketPlaceOrderModal}
          sourceOrderId={props.SourceOrders.sourceOrderDetail?.data?.id}
          isWalmart={props.SourceOrders.sourceOrderDetail?.data?.store_name === MARKET_PLACES.walmart}
        />
      )}
      {props.SourceOrders.orderLogModalIsOpen && (
        <OrderLogModal
          isOpen={props.SourceOrders.orderLogModalIsOpen}
          toggleIsOpen={props.setOrderLogModal}
          sourceOrderId={props.SourceOrders.sourceOrderDetail?.data.id}
          sourceOrder={props.SourceOrders.sourceOrderDetail?.data}
          error={parseErr(props.SourceOrders.error) || ""}
          type="order_log"
          isTab={true}
        />
      )}
      <RenderIf isTrue={props.SourceOrders.assignInventroyModalIsOpen}>
        <SearchInventoryModal
          sourceOrderData={props?.SourceOrders?.sourceOrderDetail?.data}
          assignInventory={props.assignInventory}
          outboundData={props?.SourceOrders?.sourceOrderDetail?.data?.outbound}
          isOpen={props.SourceOrders.assignInventroyModalIsOpen}
          assignOutbound={props.setAssignOutboundModal}
          isAssignOutboundOpen={props.SourceOrders.outboundModalIsOpen}
          toggleIsOpen={props.setAssignInventoryModal}
        />
      </RenderIf>
      {props.SourceOrders.shippingDetailModalIsOpen && (
        <ShippingDetailModal
          isOpen={props.SourceOrders.shippingDetailModalIsOpen}
          toggleIsOpen={props.setShippingDetailModal}
          sourceOrderId={props.SourceOrders.sourceOrderDetail?.data.id}
          address={props.SourceOrders.sourceOrderDetail?.data.source_order_address}
        />
      )}
      {props.SupplierOrders.trackingInfoModalIsOpen && selectedSupplierOrder && (
        <TrackingInfoModal
          isOpen={props.SupplierOrders.trackingInfoModalIsOpen}
          toggleIsOpen={props.setTrackingInfoModal}
          supplierOrder={selectedSupplierOrder}
          partnerId={props.SourceOrders?.sourceOrderDetail?.data?.marketplace_account_id}
        />
      )}
      {props.SourceOrders.orderStatusModalIsOpen && (
        <OrderStatusModal
          isOpen={props.SourceOrders.orderStatusModalIsOpen}
          toggleIsOpen={props.setOrderStatusModal}
          sourceOrder={props.SourceOrders.sourceOrderDetail?.data}
          type="single"
        />
      )}

      {orderLogTable && (
        <OrderLogsTable isOpen={orderLogTable} toggleIsOpen={setOrderLogTable} orderLogs={sourceOrder.order_logs} />
      )}

      {props.SupplierOrders.editSupplierOrderModalIsOpen && (
        <EditSupplierOrderModal
          isOpen={props.SupplierOrders.editSupplierOrderModalIsOpen}
          toggleIsOpen={props.setEditSupplierOrderModal}
          supplierOrder={selectedSupplierOrder}
          sourceOrder={props.SupplierOrders.sourceOrderDetail}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="col-12">
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-2">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="mb-0" style={{ fontWeight: 600, fontSize: 16, textTransform: "uppercase" }}>
                    Order Detail
                  </h4>
                </div>
                <div className="page-title-right">
                  <ActionButtons
                    {...props}
                    isFullAccess={isFullAccess}
                    show={!isAOHidden(sourceOrder)}
                    sourceOrder={sourceOrder}
                    isStoreFulfilled={isStoreFulfilled}
                    isIFulfil={isIFulfil}
                    isWH={isWareHouseOrder}
                    setActiveAddressTab={setActiveAddressTab}
                    manualFulfillmentCollapse={manualFulfillmentCollapse}
                    orderAction={orderAction}
                    inboundData={inboundData}
                    isUploadLabelModelOpen={isUploadLabelModelOpen}
                    setIsUploadLabelModelOpen={setIsUploadLabelModelOpen}
                  />
                </div>
              </div>
            </div>
          </Row>
          <RenderIf isTrue={error}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2" />
                <strong>{error}</strong>
              </Alert>
            </div>
          </RenderIf>
          {sourceOrder && (
            <>
              {sourceOrder?.issue_reason && (
                <Alert color="danger">
                  <i
                    className="bx bx-info-circle pr-2"
                    onClick={() =>
                      navigator.clipboard.writeText(sourceOrder?.issue_reason + ": " + parseIssueNote(sourceOrder))
                    }
                  ></i>
                  <strong>{sourceOrder?.issue_reason + ": "}</strong>
                  {parseIssueNote(sourceOrder)}
                </Alert>
              )}
              {isWareHouseOrder() && warehouseIssue && (
                <Alert color="danger">
                  <i
                    className="bx bx-info-circle pr-2"
                    onClick={() =>
                      navigator.clipboard.writeText(sourceOrder?.issue_reason + ": " + parseIssueNote(sourceOrder))
                    }
                  ></i>
                  <strong>Warehouse Issue: </strong>
                  {warehouseIssue?.issue_type}
                </Alert>
              )}
              {sourceOrder?.notice && (
                <Alert color="warning">
                  <i className="bx bx-info-circle pr-2"></i>
                  <strong>{sourceOrder.notice}</strong>
                </Alert>
              )}
              {sourceOrder?.status === SOURCE_ORDER_STATUSES.manual_fulfillment && (
                <Alert color="warning">
                  <i className="bx bx-info-circle pr-2"></i>
                  <strong>Manual Fulfillment: </strong>
                  {`Order is being processed ${getManualFulfillmentPerson(sourceOrder)}`}
                </Alert>
              )}
              {(sourceOrder?.is_locked || sourceOrder?.status === "checking") && (
                <Alert color="warning">
                  <i className="bx bx-info-circle pr-2"></i>
                  <strong>AO Order Placement: </strong>
                  Order is being placed by AO
                </Alert>
              )}
              {props.logMessage && (
                <div className="auto-hide">
                  <Alert color="success" className="my-1">
                    <i className="bx bx-info-circle pr-2"></i>
                    {props.logMessage || ""}
                  </Alert>
                </div>
              )}
              {props.SourceOrders?.success && !props.isLoading && (
                <div className="auto-hide">
                  <Alert color="success" className="my-1">
                    <i className="bx bx-info-circle pr-2"></i>
                    {props.SourceOrders?.success || ""}
                  </Alert>
                </div>
              )}
              {!props.SourceOrders.cancelMarketPlaceOrderModalIsOpen && props.SourceOrders?.error && (
                <div className="auto-hide">
                  <Alert color="danger" className="my-1 auto-hide">
                    <i className="bx bx-info-circle pr-2"></i>
                    {parseErr(props.SourceOrders.error) || ""}
                  </Alert>
                </div>
              )}
              {props.Supplier?.success && (
                <div className="auto-hide">
                  <Alert color="success" className="my-1 auto-hide">
                    <i className="bx bx-info-circle pr-2"></i>
                    {props.Supplier?.success || ""}
                  </Alert>
                </div>
              )}
              {sourceOrder?.sockMessage && (
                <Alert color="warning">
                  <i className="bx bx-info-circle pr-2"></i>
                  <strong>Order updated! </strong>
                  {sourceOrder.sockMessage}
                </Alert>
              )}
              {props.SourceOrders?.lockStatus?.is_locked && !props.SourceOrders.loading && (
                <div className="mb-2">
                  <Alert color="danger" className="my-1">
                    <i className="bx bx-info-circle pr-2"></i>
                    Order is in Progress By BOT
                  </Alert>
                </div>
              )}
              <RenderIf isTrue={props.SourceItems?.success && !props.SourceItems?.loading}>
                <div className="auto-hide">
                  <Alert color="success" className="my-1">
                    <i className="bx bx-info-circle pr-2"></i>
                    {props.SourceItems?.success || ""}
                  </Alert>
                </div>
              </RenderIf>
              <CardHeader className="rounded-header mb-3">
                <Row className="align-items-center">
                  <span className="col-xl col-sm-6 h5 row">
                    <img src={getBrandImageSrc(sourceOrder?.store_name)} alt="store logo" />
                    <div className="d-flex align-items-center">{capitalize(sourceOrder?.store_name)}</div>
                    <OrderBadge {...props} isWH={isWareHouseOrder} />
                  </span>
                  <div className="col-xl col-sm-6 d-flex align-items-center">
                    <h4>
                      <b>
                        {props.SourceOrders?.sourceOrderDetail?.marketplace_account?.name ? (
                          <span
                            className={isManualTeam() ? "cursor-pointer" : ""}
                            onClick={() =>
                              isManualTeam()
                                ? navigator.clipboard.writeText(
                                  props.SourceOrders.sourceOrderDetail.marketplace_account.name,
                                )
                                : null
                            }
                          >
                            {props.SourceOrders?.sourceOrderDetail?.marketplace_account?.name}
                          </span>
                        ) : null}
                      </b>
                    </h4>
                    {getUserInfo()?.id === 298 ? (
                      <i
                        title="Fetch latest order details"
                        className="ml-3 bx bxs-downvote h2 cursor-pointer"
                        onClick={() =>
                          props.importOrderManually(
                            sourceOrder.marketplace_order_id,
                            sourceOrder.marketplace_account_id,
                          )
                        }
                      ></i>
                    ) : null}
                  </div>
                  <strong className="mr-3 h5">
                    <CopyToClipBoard text={sourceOrder?.marketplace_order_id} />
                    {" " + sourceOrder?.marketplace_order_id}
                    {sourceOrder?.marketplace_customer_order_id
                      ? " / " + sourceOrder?.marketplace_customer_order_id
                      : ""}
                  </strong>
                  {props.SourceOrders?.nextOrder?.id && (
                    <Link
                      to={`/source_orders_details/${props.SourceOrders.nextOrder.marketplace_order_id}/${props.SourceOrders.nextOrder.id}`}
                      target="_blank"
                      className="text-primary mb-1"
                    >
                      <i className="bx bx-md bxs-right-arrow-alt"></i>
                    </Link>
                  )}
                </Row>
              </CardHeader>
              <Row className="mb-4">
                <Col lg="4" style={{ maxHeight: "425px" }}>
                  <Card className="mb-0 h-100 py-3">
                    <CardBody className="overflow-auto">
                      {isWareHouseOrder() && (
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {addressTabs.map((tab, index) => (
                            <NavItem key={`tab-${index}`}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeAddressTab === tab.id,
                                })}
                                onClick={() => {
                                  if (activeAddressTab !== tab.id) {
                                    toggleActiveTab(tab.id);
                                  }
                                }}
                              >
                                <span className="d-none d-sm-block">{tab.title}</span>
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      )}
                      <Row className="my-2">
                        <Col lg="10" className="d-flex">
                          <h5>{activeAddressTab === 0 ? "CUSTOMER INFO" : "WAREHOUSE ADDRESS"}</h5>
                        </Col>
                        {isEditShippingAllowed(sourceOrder, activeAddressTab) && (
                          <Col lg="2">
                            <i
                              title="Edit Shipping Details"
                              className="bx bx-sm bx-edit px-2 cursor-pointer text-primary"
                              onClick={() => {
                                if (activeAddressTab === 0) props.setShippingDetailModal(true);
                                else {
                                  orderAction.current = {
                                    action_name: "updateSourceOrder",
                                    action: updateSourceOrder,
                                    args: { data: {}, id: sourceOrder.id },
                                    order: sourceOrder,
                                  };
                                  props.setSelectWareHouseModal(true);
                                }
                              }}
                            ></i>
                          </Col>
                        )}
                      </Row>
                      <RenderIf isTrue={address?.name}>
                        <Row className="my-2">
                          <Col lg="12" className="mb-2">
                            <CardSubtitle>Shipping Name</CardSubtitle>
                            <h6 className="d-flex justify-content-between my-2">
                              <div className="d-flex align-center">
                                <i className="bx bxs-user pr-2"></i>
                                {activeAddressTab === 0 ? address?.name : getWHAddressName()}
                              </div>

                              <CopyToClipBoard text={activeAddressTab === 0 ? address?.name : getWHAddressName()} />
                            </h6>
                          </Col>
                        </Row>
                      </RenderIf>
                      <Row className={`${address?.name ? "my-2" : "my-4"}`}>
                        <Col lg="12">
                          <CardSubtitle className="mb-2">Shipment Address</CardSubtitle>
                          <h6 className="d-flex justify-content-between">
                            <div className="d-flex align-center">
                              <i className="bx bxs-home" />
                              <div className=" ml-2" style={{ width: "16rem" }}>
                                {isWareHouseOrder() && activeAddressTab === 1
                                  ? getWHShipmentAddress(getAddress(), true)
                                  : getShipmentAddress(getAddress(), true)}
                              </div>
                            </div>
                            <CopyToClipBoard
                              className="mt-1"
                              text={
                                isWareHouseOrder() && activeAddressTab === 1
                                  ? getWHShipmentAddress(getAddress())
                                  : getShipmentAddress(getAddress())
                              }
                            />
                          </h6>
                        </Col>
                        <RenderIf isTrue={address?.phone}>
                          <Col lg="12">
                            <h6 className="d-flex justify-content-between">
                              <div className="d-flex align-center">
                                <i className="bx bxs-phone pr-2"></i>
                                {address?.phone}
                              </div>
                              <CopyToClipBoard text={address?.phone} />
                            </h6>
                          </Col>
                        </RenderIf>
                        <Col lg="12" className="mb-2">
                          <CardSubtitle className="mt-2 mb-2">Email</CardSubtitle>
                          <h6 className="d-flex justify-content-between">
                            <div className="d-flex align-center">
                              <i className="mdi mdi-email pr-2" />
                              <div style={{ width: "16rem" }}>{sourceOrder?.buyer_email || ""}</div>
                            </div>
                            <CopyToClipBoard text={sourceOrder?.buyer_email} />
                          </h6>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="4" style={{ maxHeight: "425px" }}>
                  <Card className="h-100 py-3">
                    <CardBody className="overflow-auto">
                      <div className="my-1 d-flex justify-content-between align-items-baseline">
                        <h5 className="font-weight-bold">TOTAL COST DETAILS</h5>

                        <RenderIf isTrue={!isMPFulfilledOrder}>
                          <div className="mt-1 d-flex justify-content-end">
                            <div id="evenBrakePriceToolTip" className="d-flex align-items-baseline">
                              <h6 className="mr-2 mb-0">Break-Even Price</h6> <p>{breakEvenPriceAmount}</p>
                            </div>
                            <Tooltip
                              style={{ maxWidth: "175px" }}
                              placement="top"
                              isOpen={toolTipEvenBreak}
                              target="evenBrakePriceToolTip"
                              toggle={() => setToolTipEvenBreak(!toolTipEvenBreak)}
                            >
                              {getBreakEvenTooltip()}
                            </Tooltip>
                          </div>
                        </RenderIf>
                      </div>
                      <>
                        <div
                          className="d-flex justify-content-between align-items-baseline cursor-pointer"
                          onClick={() => setTotalCostHidden({ ...totalCostHidden, cost: !totalCostHidden.cost })}
                        >
                          <p className="my-1 cursor-pointer">
                            <u>Order Total</u>
                          </p>
                          <div className="d-flex grid-column-gap-10">
                            <ColouredRightAlignText amount={settlementDetails.totals.sale} />
                            <i
                              className={`bx ${
                                totalCostHidden.cost ? "bx bx-chevron-up" : "bx bx-chevron-down"
                              } font-size-16`}
                            />
                          </div>
                        </div>
                        <hr className="hrMargin" />
                        <Collapse isOpen={totalCostHidden.cost} className="grid-column-gap-10 mb-1">
                          <ConditionalColouredValue title={"Buyer Total"} value={calculateBuyerTotal()} />
                          {$.map(settlementDetails?.sale, (value, title) => (
                            <ConditionalColouredValue key={title} title={title} value={value} />
                          ))}
                          <hr className="hrMargin" />
                        </Collapse>
                        <RenderIf isTrue={!$.isEmpty(settlementDetails?.refund)}>
                          <div
                            className="d-flex justify-content-between align-items-baseline cursor-pointer"
                            onClick={() => setTotalCostHidden({ ...totalCostHidden, refund: !totalCostHidden.refund })}
                          >
                            <p className="my-1 cursor-pointer">
                              <u>Refund</u>
                            </p>
                            <div className="d-flex grid-column-gap-10">
                              <ColouredRightAlignText amount={settlementDetails.totals.refund} />
                              <i
                                className={`bx ${
                                  totalCostHidden.refund ? "bx bx-chevron-up" : "bx bx-chevron-down"
                                } font-size-16`}
                              />
                            </div>
                          </div>
                          <hr className="hrMargin" />
                          <Collapse isOpen={totalCostHidden.refund} className="grid-column-gap-10 mb-1">
                            {$.map(settlementDetails?.refund, (value, title) => (
                              <ConditionalColouredValue key={title} title={title} value={value} />
                            ))}
                            <hr className="hrMargin" />
                          </Collapse>
                        </RenderIf>
                        <RenderIf isTrue={settlementDetails.totals.marketplaceFees}>
                          <div
                            className="d-flex justify-content-between align-items-baseline cursor-pointer"
                            onClick={(e) =>
                              !e.target.classList.contains("bx-info-circle") &&
                              setTotalCostHidden({ ...totalCostHidden, marketplace: !totalCostHidden.marketplace })
                            }
                          >
                            <FeesTooltip
                              title={"Marketplace Fee"}
                              fees={settlementDetails.marketplaceFees}
                              total={settlementDetails.totals.marketplaceFees}
                            />
                            <div className="d-flex grid-column-gap-10">
                              <ColouredRightAlignText amount={settlementDetails.totals.marketplaceFees} />
                              <i
                                className={`bx ${
                                  totalCostHidden.marketplace ? "bx bx-chevron-up" : "bx bx-chevron-down"
                                } font-size-16`}
                              />
                            </div>
                          </div>
                          <hr className="hrMargin" />
                          <Collapse isOpen={totalCostHidden.marketplace} className="grid-column-gap-10 mb-1">
                            {settlementDetails?.marketplaceFees?.slice(0, 3)?.map(({ title, value }, index) => (
                              <RenderIf
                                key={index}
                                isTrue={title === "Commission" && $.round(value, 2)}
                                fallback={<ConditionalColouredValue title={title} value={value} />}
                              >
                                <div className="d-flex mb-2">
                                  <h6 className="w-100 d-flex align-items-center justify-content-start gap-1">
                                    <span>
                                      {`${settlementExists() ? "" : "Est."} ${$.startCase(
                                        sourceOrder?.store_name,
                                      )} ${title}`}
                                    </span>
                                    <CategoriesTooltip sourceItems={sourceOrder.source_items} />
                                  </h6>
                                  <span className={value < 0 ? "text-danger" : "text-success"}>
                                    {amountText(value)}
                                  </span>
                                </div>
                              </RenderIf>
                            ))}
                            <hr className="hrMargin" />
                          </Collapse>
                        </RenderIf>
                        <div
                          className="d-flex justify-content-between align-items-baseline cursor-pointer"
                          onClick={(e) =>
                            !e.target.classList.contains("bx-edit") &&
                            setTotalCostHidden({ ...totalCostHidden, cogs: !totalCostHidden.cogs })
                          }
                        >
                          <p className="miw-160 mb-1">
                            <span className="d-flex align-items-center">
                              <u>COGS</u>{" "}
                              <i
                                className="bx bx-xs bx-edit text-primary mx-1 cursor-pointer"
                                onClick={() => dispatch(actions.setUpdateCOGSModal(true))}
                              />
                            </span>
                          </p>
                          <div className="d-flex grid-column-gap-10">
                            <RightAlignText
                              heading={isProcessed() || isMPFulfilledOrder ? "Total" : "Estimated Total"}
                              text={-getCOGS(sourceOrder)}
                              valueStyle="text-danger"
                            />
                            <i
                              className={`bx ${
                                totalCostHidden.cogs ? "bx bx-chevron-up" : "bx bx-chevron-down"
                              } font-size-16`}
                            />
                          </div>
                        </div>
                        <hr className="hrMargin" />
                        <Collapse isOpen={totalCostHidden.cogs} className="grid-column-gap-10 mb-1">
                          <div className="d-flex mb-2">
                            <h6 className="w-100">Item Cost</h6>
                            <span className="text-danger">
                              {formatNumber(-$.sumBy(sourceOrder?.source_items, (item) => item.sup_cost * item.qty))}
                            </span>
                          </div>
                          <RenderIf isTrue={$.sumBy(sourceOrder?.source_items, "sup_shipping")}>
                            <div className="d-flex mb-2">
                              <h6 className="w-100">Shipping Fee</h6>
                              <span className="text-danger">
                                {formatNumber(-$.sumBy(sourceOrder?.source_items, "sup_shipping"))}
                              </span>
                            </div>
                          </RenderIf>
                          <RenderIf isTrue={priceAdj}>
                            <div className="d-flex mb-2">
                              <h6 className="w-100">Price Adjustments</h6>
                              <span className="text-danger">{formatNumber(-priceAdj)}</span>
                            </div>
                          </RenderIf>
                          <RenderIf isTrue={$.sumBy(sourceOrder?.source_items, "sup_tax")}>
                            <div className="d-flex mb-2">
                              <h6 className="w-100">Tax Fee</h6>
                              <span className="text-danger">
                                {formatNumber(-$.sumBy(sourceOrder?.source_items, "sup_tax"))}
                              </span>
                            </div>
                          </RenderIf>
                          <hr className="hrMargin" />
                        </Collapse>
                        <RenderIf isTrue={!isMarketplaceFulfilled(sourceOrder?.fulfillment_channel)}>
                          <>
                            <div
                              className="d-flex justify-content-between align-items-center cursor-pointer"
                              onClick={(e) =>
                                !e.target.classList.contains("bx-edit") &&
                                setTotalCostHidden({ ...totalCostHidden, warehouse: !totalCostHidden.warehouse })
                              }
                            >
                              <div className="d-inline-flex justify-content-start align-items-center">
                                <p className="mt-1 mb-1">
                                  <u>Shipping</u>
                                </p>
                                <i
                                  title="Edit"
                                  className="bx font-size-16 bx-edit text-primary px-2 cursor-pointer"
                                  onClick={() => props.setEditWHCostModal(true)}
                                />
                              </div>
                              <div className="d-flex grid-column-gap-10">
                                <ColouredRightAlignText amount={settlementDetails.totals.shipping} />
                                <i
                                  className={`bx ${
                                    totalCostHidden.warehouse ? "bx bx-chevron-up" : "bx bx-chevron-down"
                                  } font-size-16`}
                                />
                              </div>
                            </div>
                            <hr className="hrMargin" />
                            <Collapse isOpen={totalCostHidden.warehouse}>
                              <div>
                                <ConditionalColouredValue
                                  title={`${sourceOrder?.has_est_wh_shipping ? "Est. " : ""} WH Shipping`}
                                  value={-sourceOrder.warehouse_shipping}
                                />
                                <ConditionalColouredValue title={"WH Handling"} value={-sourceOrder.warehouse_fee} />
                              </div>
                              <hr className="hrMargin" />
                            </Collapse>
                          </>
                        </RenderIf>
                        <RenderIf isTrue={sourceOrder.return_charges}>
                          <div
                            className="d-flex justify-content-between align-items-baseline cursor-pointer"
                            onClick={() =>
                              setTotalCostHidden({ ...totalCostHidden, wh_return: !totalCostHidden.wh_return })
                            }
                          >
                            <p className="miw-160 mb-1">
                              <u>{"Warehouse (Return)"}</u>
                            </p>
                            <div className="d-flex grid-column-gap-10">
                              <RightAlignText
                                heading="Total"
                                text={-sourceOrder.return_charges}
                                valueStyle="text-danger"
                              />
                              <i
                                className={`bx ${
                                  totalCostHidden.wh_return ? "bx bx-chevron-up" : "bx bx-chevron-down"
                                } font-size-16`}
                              />
                            </div>
                          </div>
                          <hr className="hrMargin" />
                          <Collapse isOpen={totalCostHidden.wh_return}>
                            <div className="d-flex mb-2">
                              <h6 className="w-100">Return Cost</h6>
                              <span className="text-danger">{formatNumber(-sourceOrder.return_charges)}</span>
                            </div>
                            <hr className="hrMargin" />
                          </Collapse>
                        </RenderIf>
                        <RenderIf isTrue={!$.isEmpty(settlementDetails?.OtherTransaction)}>
                          <>
                            <div
                              className="d-flex justify-content-between align-items-baseline cursor-pointer"
                              onClick={() =>
                                setTotalCostHidden({ ...totalCostHidden, adjustments: !totalCostHidden.adjustments })
                              }
                            >
                              <p className="miw-160 mb-1">
                                <u>Adjustments</u>
                              </p>
                              <div className="d-flex grid-column-gap-10">
                                <ColouredRightAlignText amount={settlementDetails.totals.OtherTransaction} />
                                <i
                                  className={`bx ${
                                    totalCostHidden.adjustments ? "bx bx-chevron-up" : "bx bx-chevron-down"
                                  } font-size-16`}
                                />
                              </div>
                            </div>
                            <hr className="hrMargin" />
                          </>
                          <Collapse isOpen={totalCostHidden.adjustments}>
                            {settlementDetails?.OtherTransaction?.map(({ title, value }, index) => (
                              <ConditionalColouredValue key={index} title={title} value={value} />
                            ))}
                            <hr className="hrMargin" />
                          </Collapse>
                        </RenderIf>
                        <RenderIf isTrue={adjustments.length > 0}>
                          <>
                            <div
                              className="d-flex justify-content-between align-items-baseline cursor-pointer"
                              onClick={() =>
                                setTotalCostHidden({
                                  ...totalCostHidden,
                                  otherAdjustments: !totalCostHidden.otherAdjustments,
                                })
                              }
                            >
                              <p className="miw-160 mb-1">
                                <u>Other Adjustments</u>
                              </p>
                              <div className="d-flex grid-column-gap-10">
                                <RightAlignText
                                  heading="Total:"
                                  text={amountText(adjustments?.reduce((x, y) => x + y.adjustment, 0))}
                                />
                                <i
                                  className={`bx ${
                                    totalCostHidden.otherAdjustments ? "bx bx-chevron-up" : "bx bx-chevron-down"
                                  } font-size-16`}
                                />
                              </div>
                            </div>
                            <hr className="hrMargin" />
                          </>
                          <Collapse isOpen={totalCostHidden.otherAdjustments}>
                            <div>
                              <div className="d-flex mb-2 justify-content-between">
                                <h6>Date</h6>
                                <h6>Refund Amount</h6>
                              </div>
                              {adjustments.map((x, i) => (
                                <div key={i} className="d-flex mb-2 justify-content-between">
                                  {moment(x.date).format(FULL_DATE)}
                                  <span className="text-center">{formatNumber(x.adjustment)}</span>
                                </div>
                              ))}
                            </div>
                            <hr className="hrMargin" />
                          </Collapse>
                        </RenderIf>
                        {DEV_IDS.includes(getUserInfo()?.id) ? (
                          <>
                            <div
                              className="d-flex justify-content-between align-items-baseline cursor-pointer"
                              onClick={() => setTotalCostHidden({ ...totalCostHidden, extra: !totalCostHidden.extra })}
                            >
                              <p className="mt-1 mb-1 cursor-pointer">
                                <u>Extra Prices</u>
                              </p>
                              <i
                                className={`bx ${
                                  totalCostHidden.extra ? "bx bx-chevron-up" : "bx bx-chevron-down"
                                } font-size-16`}
                              />
                            </div>
                            <hr className="hrMargin mb-3" />
                            <Collapse isOpen={totalCostHidden.extra}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h6>AO Price</h6>
                                  {formatNumber(calculateAOPrice(sourceOrder, props.Platform.platforms.data))}
                                </div>
                                <input
                                  type="number"
                                  min="0.01"
                                  placeholder="Supplier Price"
                                  step="0.01"
                                  className="form-control col-7"
                                  value={aoProfit.supplierPrice || ""}
                                  onChange={handlePriceChange}
                                ></input>
                                <div>
                                  <h6>Profit</h6>
                                  {formatNumber(aoProfit.profit)}
                                </div>
                              </div>
                              <hr className="hrMargin" />
                            </Collapse>
                          </>
                        ) : null}
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-item-center justify-content-between gap-1">
                            <h6 className="m-0">
                              {settlementExists() ? "" : "Estimated "}
                              Total Profit
                            </h6>
                            <RenderIf isTrue={!settlementExists()}>
                              <span className="cursor-pointer">
                                <CustomTooltip
                                  placement="top"
                                  style={{ maxWidth: "fit-content" }}
                                  content={<p>We will confirm the numbers when we receive the settlement report</p>}
                                  target="profit-tooltip"
                                  modifiers={{ preventOverflow: { enabled: false }, fade: { enabled: false } }}
                                />
                                <i id="profit-tooltip" className="bx bx-xs bx-info-circle text-primary" />
                              </span>
                            </RenderIf>
                          </div>
                          <ShowProfit order={sourceOrder} />
                        </div>
                      </>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="4" style={{ maxHeight: "425px" }}>
                  <Card className="h-100 py-3">
                    <CardBody className="overflow-auto">
                      <>
                        <div className="my-1">
                          <h5 className="font-weight-bold">ORDER STATUS AND DATES</h5>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-column">
                            <CardSubtitle className="my-2">Marketplace Status</CardSubtitle>
                            <div className="d-flex">
                              <Badge
                                className={
                                  "font-size-12 badge-soft-" + STATUS_COLOR_CODE[sourceOrder?.marketplace_status]
                                }
                                color={STATUS_COLOR_CODE[sourceOrder?.marketplace_status]}
                                pill
                              >
                                {toPascalCase(sourceOrder?.marketplace_status)}
                              </Badge>
                              <i
                                className="bx font-size-16 bx-reset text-primary cursor-pointer ml-1"
                                onClick={() => {
                                  props.fetchMarketPlaceStatus({
                                    id: sourceOrder.id,
                                    storeName: sourceOrder.store_name,
                                  });
                                }}
                              ></i>
                            </div>
                          </div>
                          <RenderIf isTrue={isFullAccess}>
                            <div className="d-flex flex-column">
                              <CardSubtitle className="my-2">Fulfilment Status:</CardSubtitle>
                              <div className="d-flex">
                                <Badge
                                  className={
                                    "font-size-12 badge-soft-" + STATUS_COLOR_CODE[parseInQueueStatus(sourceOrder)]
                                  }
                                  color={STATUS_COLOR_CODE[parseInQueueStatus(sourceOrder)]}
                                  pill
                                >
                                  {toPascalCase(
                                    ORDER_STATUS_MAPPING[parseInQueueStatus(sourceOrder)] ||
                                      parseInQueueStatus(sourceOrder),
                                  )}
                                </Badge>
                                {!isAOHidden(sourceOrder) &&
                                  !isStoreFulfilled(props) &&
                                  !isIFulfil(sourceOrder) &&
                                  isManualFulfilled(sourceOrder) && (
                                    <i
                                      title="Update Fulfilment Status"
                                      className="bx font-size-16 bx-edit text-primary px-2 cursor-pointer"
                                      onClick={() => props.setOrderStatusModal(true)}
                                    ></i>
                                )}
                              </div>
                            </div>
                          </RenderIf>
                        </div>
                        {sourceOrder?.marketplace_status_timestamp ? (
                          <div className="d-flex flex-column my-3">
                            <CardSubtitle>Refreshed At:</CardSubtitle>
                            <h6 className="my-2">
                              {moment(sourceOrder?.marketplace_status_timestamp).format(FULL_DATE) || ""}
                            </h6>
                          </div>
                        ) : null}
                      </>
                      <div className="d-flex my-4 align-items-center justify-content-between">
                        <h6 className="mb-0">Order Date</h6>
                        <p className="mb-0">
                          {(sourceOrder?.order_date && moment(sourceOrder?.order_date).format(FULL_DATE)) || ""}
                        </p>
                      </div>
                      <div className="d-flex my-4 align-items-center justify-content-between">
                        <h6 className="mb-0">Expected Ship Date</h6>
                        <p className="mb-0">
                          {sourceOrder?.required_shipping_date
                            ? `${moment(sourceOrder?.required_shipping_date).format(FULL_DATE)} (EST)`
                            : ""}
                        </p>
                      </div>
                      {sourceOrder?.required_delivery_date ? (
                        <div className="d-flex my-4 align-items-center justify-content-between">
                          <h6 className="mb-0">Expected Delivery Date</h6>
                          <p className="mb-0">{`${moment(sourceOrder?.required_delivery_date).format(
                            FULL_DATE,
                          )} (EST)`}</p>
                        </div>
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {isManualEligible() && (
                <>
                  <Breadcrumbs title="PROCESS ORDER MANUALLY"></Breadcrumbs>
                  <ProcessingItemModal
                    order={props.SourceOrders.itemsForProcessing && props.SourceOrders.itemsForProcessing}
                    storeId={sourceOrder?.marketplace_account_id}
                    marketplaceLink={parseMarketplaceLink(props, sourceOrder?.source_items?.[0], "marketplace_link")}
                    isWareHouseOrder={isWareHouseOrder}
                    address={address}
                    sourceItems={props.SourceOrders?.sourceOrderDetail}
                    setItemsForProcessing={props.setItemsForProcessing}
                    manualFulfillmentCollapse={manualFulfillmentCollapse}
                    updateSourceOrder={updateSourceOrder}
                  />
                </>
              )}
              <Row className=" ml-1 d-flex justify-content-between align-items-baseline">
                <Breadcrumbs title="Order Items" />
                <RenderIf isTrue={!sourceOrder.price_events?.length}>
                  <PillBtn
                    title="Add Price Adjustment"
                    name="Add Price Adjustment"
                    className="mr-3"
                    color="info"
                    icon="bx bx-xs bx-plus-circle"
                    onClick={() => props.togglePriceEventEditModal()}
                  />
                </RenderIf>
              </Row>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {componentDetails.map((header, index) => (
                            <th
                              key={`order-tems-column-${index}`}
                              className={header.isAlignmentDefault ? "" : "text-center"}
                            >
                              {header.renderHeader ? <header.renderHeader /> : header.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {(sourceOrder?.source_items || []).map((item, key) => (
                          <tr key={"_tr_" + key}>
                            {componentDetails.map((header, colKey) => (
                              <td key={`table-col ${colKey}`}>
                                <header.content key={`table-col-val-${key}`} item={item} index={key} />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
              <RenderIf isTrue={(sourceOrder?.supplier_orders || []).length > 0 && isFullAccess}>
                <>
                  <Breadcrumbs title="Supplier Orders" />
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th>Order</th>
                              <th className="text-center">Quantity</th>
                              <th className="text-center">Total</th>
                              <th>Tracking Status</th>
                              <th>Tracking ID/URL</th>
                              <th>Payment Info</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(sourceOrder?.supplier_orders || []).map((item, key) => (
                              <tr key={"_tr_" + key}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Supplier: </h6>{" "}
                                    {item?.supplier && getBrandImageSrc(item.supplier) ? (
                                      <>
                                        <img src={getBrandImageSrc(item.supplier)} alt={item.supplier} />
                                        {toPascalCase(item.supplier)}
                                      </>
                                    ) : item?.supplier ? (
                                      toPascalCase(item.supplier)
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="d-flex align-items-baseline">
                                    <h6 className="mb-0">Supplier Order ID: </h6>
                                    <p className="mb-0 ml-1">
                                      {item?.target_order_id && (
                                        <span>
                                          {item.target_order_id}
                                          <CopyToClipBoard text={item.target_order_id} />
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  {sellerName(item) && (
                                    <div className="d-flex align-items-baseline">
                                      <h6 className="mb-0">Seller: </h6>
                                      <p className="mb-0 ml-1">{sellerName(item)}</p>
                                    </div>
                                  )}
                                  <div className="d-flex align-items-baseline">
                                    <h6 className="mb-0">SKU: </h6>
                                    <p className="mb-0 ml-1">
                                      {item?.sku && (
                                        <span className="">
                                          {item.sku}
                                          <CopyToClipBoard text={item.sku} />
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-baseline">
                                    <h6 className="mb-0">
                                      <RenderIf isTrue={item.supplier}>{toPascalCase(item.supplier)}&nbsp;</RenderIf>
                                      Order Status:
                                    </h6>
                                    <Badge
                                      className={"font-size-12 ml-1 badge-soft-" + STATUS_COLOR_CODE[item?.status]}
                                      color={STATUS_COLOR_CODE[item?.status]}
                                      pill
                                    >
                                      {toPascalCase(item?.status)}
                                    </Badge>
                                  </div>
                                  <span className="d-flex align-items-baseline">
                                    <h6 className="mb-0">Ship Date: </h6>
                                    <p className="mb-0 ml-1">
                                      {item.shipped_date ? `${moment(item.shipped_date).format(FULL_DATE)} (EST)` : ""}
                                    </p>
                                  </span>
                                </td>
                                <td className="text-center">{item.qty}</td>
                                <td className="text-center">{getSupplierTotal(item)}</td>
                                <td className="text-center">{handleSupplierStatuses(item)}</td>
                                <td className="justify-content-start">
                                  {
                                    <TrackingUrl
                                      supplierOrder={item}
                                      orderAddress={sourceOrder?.source_order_address}
                                    />
                                  }
                                </td>
                                <td className="justify-content-start">
                                  {item.cc_detail &&
                                    `${decode(item.cc_detail.card_holder_name)} - ${item.cc_detail.last_four}`}
                                  {item.purchasing_account_name && (
                                    <div className="d-flex align-items-baseline">
                                      <h6 className="mb-0 font-size-10 pr-1">Buying Account:</h6>
                                      <span className="font-size-10">
                                        <a
                                          href={`/credentials/${encode(item.purchasing_account_name)}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {item.purchasing_account_name}
                                        </a>
                                        <CopyToClipBoard text={item.purchasing_account_name} />
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td className="text-center">
                                  {((item?.tracking_num && item?.tracking_carrier) ||
                                    (item?.proxy_tracking_number && item?.proxy_carrier)) &&
                                  ["acknowledged", "partially_shipped", "unshipped"].includes(
                                    sourceOrder?.marketplace_status?.toLowerCase(),
                                  ) &&
                                  !isWareHouseOrder() &&
                                  DEV_IDS.includes(getUserInfo()?.id) ? (
                                    <i
                                      title="Upload Tracking Info"
                                      data-placement="right"
                                      className="cursor-pointer fa fa-lg fa-upload action-icon ml-3 pr-3 black-color"
                                      onClick={() => {
                                        props.setTrackingUpload({ data: item });
                                      }}
                                    ></i>
                                      ) : null}
                                  {/amazon/i.test(item?.supplier) && RETURN_LABEL_USERS.includes(getUserInfo()?.id) ? (
                                    <i
                                      title="Generate Return Label"
                                      data-toggle="tooltip"
                                      className="cursor-pointer fa fa-lg fa-undo-alt action-icon pr-2 black-color"
                                      onClick={() => {
                                        props.setReturnAddressModal(true);
                                      }}
                                    ></i>
                                  ) : null}
                                  <i
                                    title="Edit Tracking Info"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    className="cursor-pointer fa fa-lg fa-truck-moving truck-icon action-icon ml-3 pr-2 black-color"
                                    onClick={() => {
                                      setSelectedSupplierOrder(item);
                                      props.setTrackingInfoModal(true);
                                    }}
                                  ></i>
                                  <i
                                    title="Update Supplier Order"
                                    className="cursor-pointer fa fa-lg fa-edit ml-3 pr-2 black-color"
                                    onClick={() => {
                                      setSelectedSupplierOrder(item);
                                      props.setEditSupplierOrderModal(true);
                                    }}
                                  ></i>
                                  <ConfirmDialogIcon
                                    icon={{
                                      className: "fa fa-lg fa-trash-alt ml-3 black-color",
                                    }}
                                    title="Delete Supplier Order"
                                    msg={`Are you sure to delete this order ${item?.target_order_id}`}
                                    onConfirm={() => {
                                      props.deleteSupplierOrder({
                                        data: item,
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </>
              </RenderIf>
              <RenderIf isTrue={inboundData?.length && isFullAccess}>
                <div className="mt-2">
                  <Breadcrumbs title="WAREHOUSE ITEMS UPDATES" />
                  <ShipmentsTable inboundsData={inboundData} />
                </div>
              </RenderIf>
              <RenderIf isTrue={isFullAccess}>
                <Row className=" mx-1 d-flex justify-content-between align-items-baseline">
                  <span>
                    <Breadcrumbs title="Outbound Items" />
                  </span>
                  <div>
                    {isWareHouseOrder() && inboundData.some((x) => x.status === "complete") && !isLabelUploaded && (
                      <PillBtn
                        color="primary"
                        title="Upload Label"
                        name="Upload Label"
                        icon="bx bx-xs bx-plus-circle"
                        className="mr-1"
                        onClick={() => setIsUploadLabelModelOpen(!isUploadLabelModelOpen)}
                      />
                    )}
                    <RenderIf isTrue={isWareHouseOrder()}>
                      <PillBtn
                        title="Attach Virtual Inventory"
                        name="Attach Virtual Inventory"
                        className="mr-1 button-success"
                        color="success"
                        icon="bx bx-xs bx-plus-circle"
                        onClick={() => {
                          setIsAttachVirtualInventory(!isAttachVirtualInventory);
                          const virtualInventoryBuffer = {
                            type: "virtual_inventory",
                            qty: 1,
                            outbound_id: props?.SourceOrders?.sourceOrderDetail?.data?.outbound?.id || null,
                            source_order_data: {
                              warehouse_id: props?.SourceOrders?.sourceOrderDetail?.data?.wh_id,
                              source_order_id: props?.SourceOrders?.sourceOrderDetail?.data?.id,
                              order_id: props?.SourceOrders?.sourceOrderDetail?.data?.marketplace_order_id,
                              outbound_type: "inventory_fulfilled",
                              customer_details: {
                                customer_name: addressData?.name,
                                address1: addressData?.address1,
                                city: addressData?.city,
                                state: addressData?.state,
                                country: addressData?.country,
                                phone: addressData?.phone,
                                zipcode: addressData?.zipcode,
                              },
                              marketplace_details: {
                                store_name: marketplaceData?.name,
                                marketplace: marketplaceData?.marketplace,
                                marketplace_status: marketplaceData?.status,
                              },
                              marketplace_status: props?.sourceOrder?.marketplace_status,
                              ship_date: props?.sourceOrder?.required_shipping_date,
                            },
                          };
                          setIsAttachVirtualInventoryBuffer(virtualInventoryBuffer);
                        }}
                      />
                    </RenderIf>
                    <PillBtn
                      title="Assign Inventory"
                      name="Assign Inventory"
                      color="info"
                      icon="bx bx-xs bx-plus-circle"
                      onClick={() => props.setAssignInventoryModal(true)}
                    />
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <RenderIf
                      isTrue={sourceOrder?.outbound?.outbound_items?.length}
                      fallback={<h1 className="text-center">No outbound items found!</h1>}
                    >
                      <div className="table-responsive overflow-auto" style={{ maxHeight: "180px" }}>
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="thead-light">
                            <tr>
                              {tableHeaders.map((header, index) => (
                                <th key={`table-header-${index}`}>{header.title}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <RenderIf isTrue={sourceOrder?.outbound?.outbound_items}>
                              {$.orderBy(sourceOrder?.outbound?.outbound_items, "created_at", "desc").map((x, key) => (
                                <tr key={"_outbound_" + key}>
                                  <td>
                                    <div className="d-flex flex-direction-column justify-content-start">
                                      <span className="d-flex align-items-center min-width-400">
                                        <h6 className="mb-0 mr-1">Title: </h6>
                                        <SeeMoreText
                                          id={key}
                                          value={x?.warehouse_inventory?.title}
                                          length={70}
                                          seeMore={seeMore}
                                          setSeeMore={setSeeMore}
                                          onHoverDisable={true}
                                        />
                                      </span>
                                      <span className="d-flex align-items-center">
                                        <h6 className="mb-0 ">SKU: </h6>
                                        <p className="mb-0 ml-1">{x?.warehouse_inventory?.sku}</p>
                                        <RenderIf isTrue={x?.warehouse_inventory?.sku}>
                                          <CopyToClipBoard text={x?.warehouse_inventory?.sku} />
                                        </RenderIf>
                                      </span>
                                      <span className="d-flex align-items-center">
                                        <h6 className="mb-0 ">UPC: </h6>
                                        <p className="mb-0 ml-1">{getText(x?.warehouse_inventory?.upc)}</p>
                                        <RenderIf isTrue={x?.warehouse_inventory?.upc}>
                                          <CopyToClipBoard text={x?.warehouse_inventory?.upc} />
                                        </RenderIf>
                                      </span>
                                    </div>
                                  </td>
                                  <td>{x?.qty}</td>
                                  <td>{x?.warehouse_inventory?.price}</td>
                                  <td>{getWarehouseName(warehouseObj?.[x?.warehouse_inventory?.warehouse_id])}</td>
                                  <td>
                                    <div className="d-flex flex-direction-column justify-content-start">
                                      <span className="d-flex align-items-center mb-2">
                                        {getText(x?.tracking_number)}
                                      </span>
                                      <span className="d-flex align-items-center  mb-2">
                                        <h6 className="mb-0 ">Carrier: </h6>
                                        <p className="mb-0 ml-1">{getText(x?.tracking_carrier)}</p>
                                      </span>
                                      <span className="d-flex align-items-center">
                                        <span
                                          className={`badge-soft-${
                                            STATUS_COLOR_CODE[x?.tracking_status] || "custom"
                                          } badge badge-pill`}
                                        >
                                          {toPascalCase(getText(x?.tracking_status))}
                                        </span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </RenderIf>
                          </tbody>
                        </table>
                      </div>
                    </RenderIf>
                  </CardBody>
                </Card>
              </RenderIf>
              <Suspense fallback={<></>}>
                <PriceEventsTable />
                <RenderIf isTrue={props.SourceOrders?.isPriceEventsModalOpen}>
                  <PriceEventsModal />
                </RenderIf>
                <RenderIf isTrue={props.SourceOrders?.isPriceEventEditModalOpen}>
                  <PriceEventsAddModal />
                </RenderIf>
              </Suspense>
              <Row className=" ml-1 d-flex justify-content-between align-items-baseline">
                <span className="cursor-pointer h6" onClick={() => setOrderLogTable(true)}>
                  <Breadcrumbs title="ORDER LOGS" />
                </span>
                <PillBtn
                  title="Add Order Log"
                  name="Add Manual Log"
                  className="mr-3"
                  color="info"
                  icon="bx bx-xs bx-plus-circle"
                  onClick={() => props.setOrderLogModal(true)}
                />
              </Row>
              <Card>
                <CardBody>
                  {(sourceOrder?.order_logs || []).length > 0 ? (
                    <div className="table-responsive overflow-auto" style={{ maxHeight: "180px" }}>
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="thead-light">
                          <tr>
                            <th>Created By</th>
                            <th>Added On</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sourceOrder?.order_logs &&
                            $.orderBy(sourceOrder?.order_logs, "created_at", "desc").map((log, key) => (
                              <tr key={"_log_" + key}>
                                <td>
                                  <span className="text-body font-weight-bold">{log?.created_by || ""}</span>
                                </td>
                                <td>{(log?.created_at && moment(log?.created_at).format(FULL_DATE)) || ""}</td>
                                <td
                                  className="mw-300"
                                  dangerouslySetInnerHTML={{ __html: formatNoteText(log?.note) || "" }}
                                ></td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <h1 className="text-center">No Logs Added!</h1>
                  )}
                </CardBody>
              </Card>
              <RenderIf isTrue={isFullAccess}>
                {[sourceOrder?.supplier_orders, sourceOrder]
                  .flat()
                  ?.map((order) => order?.emails || [])
                  ?.flat()
                  ?.filter((x) => x).length > 0 && (
                  <>
                    <Row className="d-flex justify-content-between mb-2">
                      <Col lg="6">
                        <Breadcrumbs title="Emails" />
                      </Col>
                    </Row>
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                              <tr>
                                <th>Email Date</th>
                                <th className="text-center">Supplier Order Id</th>
                                <th className="text-center">Type</th>
                                <th>Subject</th>
                                <th className="text-center">Platform</th>
                                <th className="text-center">Email Link</th>
                                {DEV_IDS.includes(getUserInfo()?.id) && <th className="text-center">Retry</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {[sourceOrder?.supplier_orders, sourceOrder]
                                .flat()
                                ?.map((order) => order.emails)
                                ?.flat()
                                ?.filter((x) => x)
                                ?.sort((a, b) => moment(b.email_date) - moment(a.email_date))
                                ?.map(
                                  (email, key) =>
                                    email && (
                                      <tr key={"_email_key_" + key}>
                                        <td>
                                          {(email?.email_date && moment(email?.email_date).format(FULL_DATE)) || ""}
                                        </td>
                                        <td className="text-center">
                                          {email?.parsed_order_id && (
                                            <span>
                                              {email?.parsed_order_id}
                                              <CopyToClipBoard text={email?.parsed_order_id} />
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {email?.email_type === email?.subject ? null : email?.email_type}
                                        </td>
                                        <td>{email?.subject}</td>
                                        <td className="text-center">
                                          {email?.platform && (
                                            <img src={getBrandImageSrc(email.platform)} alt={email.platform} />
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <a
                                            href={`/email/view/${email?.s3_key}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "inherit" }}
                                          >
                                            <i className="bx bx-sm bx-envelope px-3"></i>
                                          </a>
                                        </td>
                                        {DEV_IDS.includes(getUserInfo()?.id) && (
                                          <td className="text-center">
                                            <i
                                              className="bx bx-sm bx-reset cursor-pointer ml-3"
                                              onClick={() => props.updateS3Object(email.s3_key, { status: "pending" })}
                                            ></i>
                                          </td>
                                        )}
                                      </tr>
                                    ),
                                )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </>
                )}
                <OrderAttempts
                  sourceOrder={sourceOrder}
                  platform={props.Platform}
                  aoPrice={calculateAOPrice}
                  updateOrderAttempt={props.updateOrderAttempt}
                  updateOrder={props.updateSourceOrder}
                />
              </RenderIf>
            </>
          )}
          {props.SourceOrders.isOrderNotFound && loaders.every((key) => !props[key]?.loading) ? (
            <Card>
              <CardBody>
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Order Found</h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};
// order_attempts
const mapStatetoProps = (state) => {
  const { SourceOrders, OrderLog, SourceItems, SupplierOrders, Supplier, Platform, OrderAttempt, Settings } = state;
  return {
    logMessage: OrderLog.success,
    SourceOrders,
    SourceItems,
    SupplierOrders,
    Supplier,
    Settings,
    Platform,
    OrderAttempt,
    Emails: { loading: state.Emails.loading },
    returnAddressModal: Settings.returnAddressModal,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    fetchSourceOrderDetail,
    fetchNextOrder,
    refreshSourceOrderDetail,
    updateOrderAttempt,
    setPreloader,
    setMarkIssueModal,
    setCancelMarketPlaceOrderModal,
    getFeedStatus,
    updateSourceOrder,
    setOrderLogModal,
    setAssignInventoryModal,
    setAssignOutboundModal,
    assignInventory,
    updateAOStatus,
    setShippingDetailModal,
    setTrackingInfoModal,
    fetchAOStatus,
    setOrderStatusModal,
    fetchMarketPlaceStatus,
    deleteSupplierOrder,
    setSkuAsMismatch,
    setEditSupplierOrderModal,
    fetchPlatforms,
    setReturnAddressModal,
    createOrderNote,
    setTrackingUpload,
    importOrderManually,
    setItemsForProcessing,
    updateSourceOrderStatus,
    changeOrderType,
    setChangeWareHouseModal,
    fetchAllWarehouses,
    setSelectWareHouseModal,
    updateS3Object,
    setEditWHCostModal,
    setGenerateShippingLabelModal,
    fetchUserWarehouses,
    togglePriceEventEditModal,
  })(SourceOrderDetails),
);

function TrackingUrl ({ supplierOrder, orderAddress }) {
  return (
    <div className="d-flex flex-column">
      {
        <div className="d-flex flex-row justify-content-start font-size-12">
          <a
            href={mapTrackingUrl(
              supplierOrder?.tracking_carrier,
              supplierOrder?.tracking_num,
              supplierOrder?.tracking_url,
              orderAddress,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {supplierOrder?.tracking_num || ""}
            {!supplierOrder?.tracking_num && supplierOrder?.tracking_url && "Track"}
          </a>
          {supplierOrder?.tracking_num && <CopyToClipBoard text={supplierOrder?.tracking_num} />}
        </div>
      }

      {supplierOrder?.proxy_tracking_number && (
        <div className="d-flex flex-row justify-content-start font-size-12">
          <a
            href={mapTrackingUrl(
              supplierOrder?.proxy_carrier,
              supplierOrder?.proxy_tracking_number,
              supplierOrder?.tracking_url,
              orderAddress,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {supplierOrder?.proxy_tracking_number}
          </a>
          {supplierOrder?.tracking_num && <CopyToClipBoard text={supplierOrder?.proxy_tracking_number} />}
        </div>
      )}

      {supplierOrder?.marketplace_tracking_url && (
        <div className="justify-content-start font-size-12">
          <a href={supplierOrder.marketplace_tracking_url} target="_blank" rel="noopener noreferrer">
            Marketplace Tracking Url
          </a>
          <CopyToClipBoard text={supplierOrder.marketplace_tracking_url} />
        </div>
      )}
    </div>
  );
}
