import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getMarketPlaceAccounts,
  updateMarketPlaceAccounts,
  deleteMarketPlaceAccounts,
  createMarketPlaceAccounts,
  setMarketPlaceAccountVacationMode,
  updateRepricer,
  fetchSpApiRefreshToken,
  checkStore,
  checkStoreNameApi,
  fetShopifyRefreshToken,
  sendEmailErrorApi,
} from "../../api";
// Login Redux States
import {
  FETCH_MARKETPLACE_ACCOUNTS,
  FETCH_USER_MARKETPLACE_ACCOUNT,
  UPDATE_MARKETPLACE_ACCOUNT,
  DELETE_MARKETPLACE_ACCOUNT,
  CREATE_MARKETPLACE_ACCOUNTS,
  UPDATE_VACATION_MODE,
  UPDATE_REPRICER,
  FETCH_SP_API_REFRESH_TOKEN,
  CHECK_STORE,
  CHECK_STORE_NAME,
  FETCH_SHOPIFY_REFRESH_TOKEN,
  SEND_ERROR_EMAIL,
} from "./actionTypes";
import {
  apiError,
  setEditModalMarketplaceAccount,
  setAddModalMarketplaceAccount,
  fetchMarketplaceAccountsSuccess,
  fetchUserMarketplaceAccountSuccess,
  updateMarketplaceAccountsSuccess,
  deleteMarketPlaceAccountSuccess,
  createMarketplaceAccountSuccess,
  fetchSpApiRefreshTokenSuccess,
  updateRepricerSuccess,
  setFormData,
  checkStoreSuccess,
  checkStoreNameSuccess,
  fetchShopifyRefreshTokenSuccess,
  sendErrorEmailSuccess,
} from "./actions";
import { setPaymentReportModal, setShowOnboarding, setOnboardingFlow } from "../actions";
import { merge, pick } from "lodash";
import { removeURLQueryParams, setAuthTokenInBrowser } from "../../utils/common";
import { MARKET_PLACES } from "../../constants";

const MarketPlaceAccount = (state) => state.MarketPlaceAccount;
const Settings = (state) => state.Settings;

function * sendEmailErrorSaga ({ payload }) {
  try {
    const res = yield call(sendEmailErrorApi, payload);
    yield put(sendErrorEmailSuccess(res));
  } catch (error) {}
}

function * fetchMarketPlaceAccountsSaga ({ payload: { params, openModal } }) {
  try {
    const res = yield call(getMarketPlaceAccounts, params);
    yield put(fetchMarketplaceAccountsSuccess({ res, params }));
    if (openModal) {
      yield put(setPaymentReportModal(true));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchUserMarketPlaceAccountSaga ({ payload: { params } }) {
  try {
    const res = yield call(getMarketPlaceAccounts, params);
    yield put(fetchUserMarketplaceAccountSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchSpApiRefreshTokenSaga ({ payload }) {
  try {
    const res = yield call(fetchSpApiRefreshToken, payload);
    yield put(fetchSpApiRefreshTokenSuccess(res));
  } catch (error) {
    yield put(fetchSpApiRefreshTokenSuccess({ error: error.message }));
  }
}

function * fetchShopifyRefreshTokenSaga ({ payload }) {
  try {
    const res = yield call(fetShopifyRefreshToken, payload);
    yield put(fetchShopifyRefreshTokenSuccess(res));
  } catch (error) {
    yield put(fetchShopifyRefreshTokenSuccess({ error: error.message }));
  }
}

function * updateMarketPlaceAccountsSaga ({ payload: { data, id, params, history } }) {
  try {
    if (data.disable_repricer?.constructor !== Boolean) {
      const { accounts } = yield select(MarketPlaceAccount);
      const acc = pick(
        accounts?.data?.find((acc) => acc.id === +id),
        "repricer_type",
        "disable_repricer",
      );
      merge(
        data,
        !data.repricer_type && { repricer_type: acc.repricer_type },
        !data.disable_repricer && { disable_repricer: acc.disable_repricer },
      );
    }
    const res = yield call(updateMarketPlaceAccounts, { data, id, params });
    if (res?.redirect_wh) {
      setTimeout(() => {
        history && history.push("/warehouse");
      }, 5000);
      throw new Error(
        "You cannot enable two step until you add a warehouse. You will be redirected to warehouse page within 5 seconds",
      );
    }
    if (res?.data) {
      let { accounts } = yield select(MarketPlaceAccount);
      if (!accounts) accounts = { data: [] };

      let index = accounts.data.findIndex((acc) => acc.id === res.data.id);
      if (index < 0) index = 0;

      accounts.data[index] = res.data;

      if (data.is_archive || data.is_archive === false) {
        accounts.data[index] = merge(accounts.data[index], { is_archive: !data.is_archive });
      }
      accounts.data = [...accounts.data];

      yield put(setEditModalMarketplaceAccount(false));
      if (res?.data?.marketplace === MARKET_PLACES.amazon) removeURLQueryParams();
      yield put(updateMarketplaceAccountsSuccess(res.data));
      yield put(setOnboardingFlow(false));
      yield put(setShowOnboarding(false));
      yield put(setFormData({}));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * setVacationModeSaga ({ payload }) {
  try {
    const res = yield call(setMarketPlaceAccountVacationMode, payload);
    if (res?.data) {
      let { accounts } = yield select(MarketPlaceAccount);
      if (!accounts) accounts = { data: [] };

      const index = accounts.data.findIndex((acc) => acc.id === res.data.id);
      if (index !== undefined) accounts.data[index] = { ...(accounts.data[index] || {}), ...res.data };
      accounts.data = [...accounts.data];
      yield put(updateMarketplaceAccountsSuccess(res.data));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createMarketPlaceAccountsSaga ({ payload: { data, params } }) {
  try {
    const res = yield call(createMarketPlaceAccounts, { data, params });
    if (res?.data) {
      if (res.token) setAuthTokenInBrowser(res.token);
      let { accounts } = yield select(MarketPlaceAccount);
      const { account: tenantAcc } = yield select(Settings);

      if (!accounts) accounts = { data: [] };
      accounts.data = [...accounts.data, res?.data];
      if (tenantAcc?.data && tenantAcc.data.onboard?.store_added === false)
        tenantAcc.data.onboard = { ...tenantAcc.data.onboard, store_added: true };
      if (res.account && tenantAcc?.data) merge(tenantAcc?.data, res.account);
      yield put(setAddModalMarketplaceAccount(false));
      if (data.marketplace === MARKET_PLACES.amazon) removeURLQueryParams();
      yield put(createMarketplaceAccountSuccess({ accounts }));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteMarketPlaceAccountsSaga ({ payload: { id } }) {
  try {
    const { data } = yield call(deleteMarketPlaceAccounts, { id });
    if (data) {
      const { accounts } = yield select(MarketPlaceAccount);
      accounts.data = accounts.data.filter((account) => account.id !== data.id);
    }
    yield put(deleteMarketPlaceAccountSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateReprierSaga ({ payload }) {
  try {
    const res = yield call(updateRepricer, payload);
    yield put(updateRepricerSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * checkStoreSaga ({ payload }) {
  try {
    const res = yield call(checkStore, payload);
    yield put(checkStoreSuccess(res?.data || { message: "No response from marketplace" }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * checkStoreNameSaga ({ payload }) {
  try {
    const res = yield call(checkStoreNameApi, payload);
    yield put(checkStoreNameSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchUpdateRepricerSaga () {
  yield takeEvery(UPDATE_REPRICER, updateReprierSaga);
}

export function * watchCheckStoreSaga () {
  yield takeEvery(CHECK_STORE, checkStoreSaga);
}

export function * watchCheckStoreNameSaga () {
  yield takeEvery(CHECK_STORE_NAME, checkStoreNameSaga);
}

export function * watchFetchMarketPlaceAccountsSaga () {
  yield takeEvery(FETCH_MARKETPLACE_ACCOUNTS, fetchMarketPlaceAccountsSaga);
}

export function * watchFetchUserMarketPlaceAccountSaga () {
  yield takeEvery(FETCH_USER_MARKETPLACE_ACCOUNT, fetchUserMarketPlaceAccountSaga);
}

export function * watchUpdateMarketPlaceAccountsSaga () {
  yield takeEvery(UPDATE_MARKETPLACE_ACCOUNT, updateMarketPlaceAccountsSaga);
}

export function * watchDeleteMarketPlaceAccountsSaga () {
  yield takeEvery(DELETE_MARKETPLACE_ACCOUNT, deleteMarketPlaceAccountsSaga);
}

export function * watchCreateMarketPlaceAccountsSaga () {
  yield takeEvery(CREATE_MARKETPLACE_ACCOUNTS, createMarketPlaceAccountsSaga);
}

export function * watchSetVacationModeSaga () {
  yield takeEvery(UPDATE_VACATION_MODE, setVacationModeSaga);
}

export function * watchFetchSpApiRefreshTokenSaga () {
  yield takeEvery(FETCH_SP_API_REFRESH_TOKEN, fetchSpApiRefreshTokenSaga);
}

export function * watchFetchShopifyRefreshTokenSaga () {
  yield takeEvery(FETCH_SHOPIFY_REFRESH_TOKEN, fetchShopifyRefreshTokenSaga);
}

export function * watchSendEmailError () {
  yield takeEvery(SEND_ERROR_EMAIL, sendEmailErrorSaga);
}

function * marketPlaceAccountsSaga () {
  yield all([
    fork(watchSendEmailError),
    fork(watchUpdateRepricerSaga),
    fork(watchFetchMarketPlaceAccountsSaga),
    fork(watchFetchUserMarketPlaceAccountSaga),
    fork(watchUpdateMarketPlaceAccountsSaga),
    fork(watchDeleteMarketPlaceAccountsSaga),
    fork(watchCreateMarketPlaceAccountsSaga),
    fork(watchFetchSpApiRefreshTokenSaga),
    fork(watchFetchShopifyRefreshTokenSaga),
    fork(watchSetVacationModeSaga),
    fork(watchCheckStoreSaga),
    fork(watchCheckStoreNameSaga),
  ]);
}

export default marketPlaceAccountsSaga;
