import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import Modal from "../../../components/Common/Modal";
import CheckBox from "../../../components/Common/CheckBox";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// utils
import { toPascalCase, getUserInfo, RenderIf, isUserHaveFullAccess } from "../../../utils/common";
import { MARKET_PLACES } from "../../../constants/index";
import { pick, merge, startCase, cloneDeep } from "lodash";

// actions
import {
  setEditModalMarketplaceAccount,
  setAddModalMarketplaceAccount,
  updateMarketplaceAccounts,
  createMarketplaceAccount,
} from "../../../store/actions";

const zipCodeCols = ["from_zipcode", "to_zipcode"];

const getAccessCredentialLabels = (marketplace, idx) => {
  switch (marketplace) {
    case MARKET_PLACES.amazon:
      return ["Merchant Id", "MWS Auth Token"][idx];
    case MARKET_PLACES.shopify:
      return ["API key*", "API Secret Key"][idx];
    case MARKET_PLACES.walmart:
      return ["Client Id*", "Client Secret"][idx];
    default:
      return "";
  }
};

const MarketPlaceAccountEditModal = (props) => {
  const dispatch = useDispatch();
  const { selectedAccount, error, success, onboardAccountSuccess, loading, editModalIsOpen, addModalIsOpen } =
    useSelector((state) => state.MarketPlaceAccount);
  const { account } = useSelector((state) => state.Settings);
  const [showSecrets, setShowSecrets] = useState(false);
  const [confirmationToggleKey, setConfirmationToggleKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  function handleUpdate (data) {
    dispatch(updateMarketplaceAccounts(data));
  }

  function handleAdd (data) {
    dispatch(createMarketplaceAccount(data));
  }

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "") errors[key] = "Field is Required";
      // Remove "refresh_token" in case of non-Shopify
      if (values.marketplace !== MARKET_PLACES.shopify) delete errors.refresh_token;
    });
    zipCodeCols.forEach((col) => {
      if (values[col]) {
        !/^([a-zA-Z0-9]+-)*[a-zA-Z0-9]+$/.test(values[col]) && (errors[col] = "Only alphanumeric and hyphen allowed");
        !errors[col] && values[col].length > 12 && (errors[col] = "Zip code should be of less than 12 characters");
      }
    });
    return errors;
  };

  const initialValues = {
    name: "",
    access_id: "",
    access_secret: "",
    refresh_token: "",
    marketplace: "",
    enabled: true,
    tracking_enabled: false,
    self_ao: true,
    handling_charges: 0,
    from_zipcode: "",
    to_zipcode: "",
    estimated_shipping: 0,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (props.type === "edit" && selectedAccount) {
        values.va_id = getUserInfo()?.id;
        values.handling_charges = parseFloat(values.handling_charges);
        const _values = cloneDeep(values);
        if (_values.marketplace !== MARKET_PLACES.shopify) delete _values.refresh_token;
        handleUpdate({
          data: merge(pick(selectedAccount, ["disable_repricer", "repricer_type"]), _values),
          id: selectedAccount.id,
        });
      } else if (props.type === "add") {
        handleAdd({
          ...values,
        });
      }
    },
  });

  useEffect(() => {
    if (selectedAccount && props.type === "edit") {
      Object.keys(initialValues).forEach((key) => {
        selectedAccount[key] !== "" && formik.setFieldValue(key, selectedAccount[key], false);
      });
    }
  }, [selectedAccount]);

  useEffect(() => {
    (success || onboardAccountSuccess) && !loading && formik.resetForm();
  }, [success, loading, onboardAccountSuccess]);

  const toggleModal = () => {
    props.type === "edit"
      ? dispatch(setEditModalMarketplaceAccount(!editModalIsOpen))
      : dispatch(setAddModalMarketplaceAccount(!addModalIsOpen));
    formik.resetForm();
  };
  const confirmationMsgMapping = {
    tracking_enabled: "Upload Tracking for",
    self_ao: "Self AO for",
    enabled: "",
  };

  const isShopify = formik.values.marketplace === MARKET_PLACES.shopify;

  return (
    <Modal size="lg" isOpen={editModalIsOpen || addModalIsOpen} toggle={toggleModal}>
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            formik.setFieldValue(confirmationToggleKey, !formik.values?.[confirmationToggleKey]);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {`to ${!formik.values?.[confirmationToggleKey] ? "enable" : "disable"} ${
            confirmationMsgMapping[confirmationToggleKey]
          } ${startCase(formik.values.marketplace)} marketplace account`}
        </SweetAlert>
      </RenderIf>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.type === "edit"
            ? `Edit ${startCase(formik.values.marketplace)} Marketplace Account`
            : "Add Marketplace Account"}
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row className="align-items-end">
            <Col lg={`${props.type === "edit" ? 12 : 6} mb-2 pb-3`}>
              <Label>Store Front Name{isShopify ? "" : "*"}</Label>
              <Input
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={isShopify}
              />
              {formik.touched.name && formik.errors.name
                ? (
                <span className="text-danger mt-1">{formik.errors.name}</span>
                  )
                : null}
            </Col>
            <RenderIf isTrue={props.type !== "edit"}>
              <Col lg="6 mb-2 pb-3">
                <Label>Marketplace*</Label>
                <select
                  name="marketplace"
                  className="custom-select"
                  value={formik.values.marketplace || ""}
                  onChange={(e) => {
                    formik.setFieldValue("marketplace", e.target.value);
                  }}
                >
                  <option value="">Select a marketplace</option>
                  {Object.keys(MARKET_PLACES)
                    .slice(0, 2)
                    .map((key, index) => (
                      <option key={"_marketplace_opt_" + index} value={MARKET_PLACES[key]}>
                        {toPascalCase(key)}
                      </option>
                    ))}
                </select>
                {formik.touched.marketplace && formik.errors.marketplace
                  ? (
                  <span className="text-danger mt-1 position-absolute">{formik.errors.marketplace}</span>
                    )
                  : null}
              </Col>
            </RenderIf>
            <RenderIf isTrue={isUserHaveFullAccess()}>
              <RenderIf isTrue={account?.data?.two_step_feature && formik.values.marketplace !== "facebook"}>
                <Col lg="6 mb-2 pb-3">
                  <Label>Default Estimated Shipping</Label>
                  <Input
                    name="estimated_shipping"
                    type="number"
                    step="0.01"
                    value={formik.values.estimated_shipping}
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("estimated_shipping", e.target.value)}
                    className="form-control"
                    validate={{
                      min: { value: 0, errorMessage: "Minimum value allowed is 0.01" },
                      max: { value: 10000, errorMessage: "Maximum value allowed is 10000" },
                    }}
                  />
                  <RenderIf isTrue={formik.touched.estimated_shipping && formik.errors.estimated_shipping}>
                    <span className="text-danger position-absolute">{formik.errors.estimated_shipping}</span>
                  </RenderIf>
                </Col>
                <Col lg="6 mb-2 pb-3">
                  <Label id="handling">Handling Fee</Label>
                  <Input
                    name="handling_fee"
                    type="number"
                    step="0.01"
                    value={formik.values.handling_charges}
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("handling_charges", e.target.value)}
                    className="form-control"
                    validate={{
                      min: { value: 0, errorMessage: "Minimum value allowed is 0.01" },
                      max: { value: 99, errorMessage: "Maximum value allowed is 99" },
                    }}
                  />
                  <RenderIf isTrue={formik.touched.handling_charges && formik.errors.handling_charges}>
                    <span className="text-danger mt-1 position-absolute">{formik.errors.handling_charges}</span>
                  </RenderIf>
                </Col>
                {zipCodeCols.map((col, i) => (
                  <Col lg="6 mb-2 pb-3" key={`__zipCodeCols${i}__`}>
                    <Label>{startCase(col)}</Label>
                    <Input
                      name={col}
                      placeholder={startCase(col)}
                      value={formik.values[col]}
                      onChange={(e) => formik.setFieldValue(col, e.target.value)}
                    />
                    <RenderIf isTrue={formik.touched[col] && formik.errors[col]}>
                      <span className="text-danger position-absolute">{formik.errors[col]}</span>
                    </RenderIf>
                  </Col>
                ))}
              </RenderIf>
            </RenderIf>
            <RenderIf isTrue={formik.values.marketplace !== MARKET_PLACES.facebook}>
            <RenderIf isTrue={formik.values.marketplace !== MARKET_PLACES.amazon}>
              <Col lg="12 my-2" className="d-flex justify-content-end">
                <CheckBox
                  name="show_secrets"
                  state={showSecrets}
                  setState={() => setShowSecrets(!showSecrets)}
                  isSwitch={true}
                  label="Show Secrets"
                />
              </Col>
              </RenderIf>
              <RenderIf isTrue={!isShopify}>
                <Col lg="6 mb-2">
                  <Label>{getAccessCredentialLabels(formik.values.marketplace, 0)}</Label>
                  <Input
                    name="access_id"
                    value={showSecrets || formik.values.marketplace === MARKET_PLACES.amazon ? formik.values.access_id : "*".repeat(formik.values.access_id.length)}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={formik.values.marketplace === MARKET_PLACES.amazon}
                  />
                  {formik.touched.access_id && formik.errors.access_id
                    ? (
                    <span className="text-danger mt-1">{formik.errors.access_id}</span>
                      )
                    : null}
                </Col>
                <RenderIf isTrue={formik.values.marketplace !== MARKET_PLACES.amazon}>
                  <Col lg="6 mb-2">
                    <Label>{getAccessCredentialLabels(formik.values.marketplace, 1)}*</Label>
                    <Input
                      name="access_secret"
                      value={showSecrets ? formik.values.access_secret : "*".repeat(formik.values.access_secret?.length)}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.access_secret && formik.errors.access_secret
                      ? (
                      <span className="text-danger mt-1">{formik.errors.access_secret}</span>
                        )
                      : null}
                  </Col>
                </RenderIf>
              </RenderIf>
              <RenderIf isTrue={isShopify}>
                <Col lg="6 mb-2">
                  <Label>Access Token</Label>
                  <Input
                    name="refresh_token"
                    value={showSecrets ? formik.values?.refresh_token : "*".repeat(formik.values?.refresh_token?.length)}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.refresh_token && formik.errors.refresh_token
                    ? (
                    <span className="text-danger mt-1">{formik.errors.refresh_token}</span>
                      )
                    : null}
                </Col>
              </RenderIf>
            </RenderIf>
            <RenderIf isTrue={formik.values.marketplace === "facebook"}>
              <Col lg="6 mb-2">
                <Label>{"Unique Url"}*</Label>
                <div className="position-relative input-copy">
                  <Input
                    name="unique_identifier"
                    value={`https://app.ecomcircles.com/api/${selectedAccount?.unique_identifier}`}
                    style={{ width: "70ch" }}
                    disabled={true}
                  />
                  <CopyToClipBoard
                    className="position-absolute clip-board-icon color-purple"
                    text={`https://app.ecomcircles.com/api/${selectedAccount?.unique_identifier}`}
                  />
                </div>
              </Col>
            </RenderIf>
          </Row>
          <RenderIf isTrue={isUserHaveFullAccess()}>
            <Row>
              <Col lg="4">
                <div className="custom-control custom-switch custom-switch-lg my-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="account_status"
                    checked={!!formik.values.enabled}
                    onChange={() => null}
                  />
                  <label
                    onClick={() => {
                      setConfirmationToggleKey("enabled");
                      setIsOpen(true);
                    }}
                    className="custom-control-label cursor-pointer"
                  >
                    Enabled
                  </label>
                </div>
              </Col>
              <Col lg="4">
                <div className="custom-control custom-switch custom-switch-lg my-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="tracking_enabled"
                    checked={!!formik.values.tracking_enabled}
                    onChange={(e) => null}
                  />
                  <label
                    onClick={() => {
                      setConfirmationToggleKey("tracking_enabled");
                      setIsOpen(true);
                    }}
                    className="custom-control-label cursor-pointer"
                  >
                    Upload Tracking
                  </label>
                </div>
              </Col>
              {account?.data?.ao_enabled && selectedAccount?.marketplace === MARKET_PLACES.amazon ? (
                <Col lg="4">
                  <div className="custom-control custom-switch custom-switch-lg my-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="self_ao"
                      checked={!!formik.values.self_ao}
                      onChange={() => null}
                    />
                    <label
                      onClick={() => {
                        setConfirmationToggleKey("self_ao");
                        setIsOpen(true);
                      }}
                      className="custom-control-label cursor-pointer"
                    >
                      Self AO
                    </label>
                  </div>
                </Col>
              ) : null}
            </Row>
          </RenderIf>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default MarketPlaceAccountEditModal;
