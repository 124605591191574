import {
  FETCH_CC_DETAILS,
  FETCH_FETCH_CC_DETAILS_SUCCESS,
  FETCH_CARD_TYPES,
  FETCH_CARD_TYPES_SUCCESS,
  SET_EDIT_CREDIT_CARDS_MODAL,
  SET_ADD_CREDIT_CARDS_MODAL,
  SELECT_CREDIT_CARD,
  ADD_CREDIT_CARDS,
  ADD_CREDIT_CARDS_SUCCESS,
  UPDATE_CREDIT_CARDS,
  UPDATE_CREDIT_CARDS_SUCCESS,
  DELETE_CREDIT_CARD,
  DELETE_CREDIT_CARD_SUCCESS,
  SET_CREDIT_CARDS_FILTERS,
  API_ERROR,
  UPDATE_CREDIT_CARDS_PRIORITY,
  UPDATE_CREDIT_CARDS_PRIORITY_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  ccData: [],
  ccTypes: [],
  selectedCreditCard: undefined,
  editCreditCardModalIsOpen: false,
  addCreditCardModalIsOpen: false,
  filters: {},
};

const CreditCards = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CC_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_FETCH_CC_DETAILS_SUCCESS:
      state = {
        ...state,
        ccData: action.payload,
        loading: false,
      };
      break;
    case FETCH_CARD_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_CARD_TYPES_SUCCESS:
      state = {
        ...state,
        ccTypes: action.payload,
        loading: false,
      };
      break;
    case ADD_CREDIT_CARDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_CREDIT_CARDS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credit Card has been Created Successfully",
        loading: false,
      };
      break;
    case SELECT_CREDIT_CARD:
      state = {
        ...state,
        selectedCreditCard: action.payload,
      };
      break;
    case SET_EDIT_CREDIT_CARDS_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        editCreditCardModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_CREDIT_CARDS_FILTERS:
      state = {
        ...state,
        filters: action.payload,
      };
      break;
    case SET_ADD_CREDIT_CARDS_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        addCreditCardModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case UPDATE_CREDIT_CARDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_CREDIT_CARDS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credit Cards Updated Successfully",
        loading: false,
      };
      break;
    case DELETE_CREDIT_CARD:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DELETE_CREDIT_CARD_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Credit Card Deleted Successfully",
        loading: false,
      };
      break;
    case UPDATE_CREDIT_CARDS_PRIORITY:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
        ccData: action.payload,
      };
      break;
    case UPDATE_CREDIT_CARDS_PRIORITY_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload?.msg,
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CreditCards;
