import * as types from "./actionTypes";
const {
  FETCH_LISTINGS,
  FETCH_LISTINGS_SUCCESS,
  SET_SUPPLIER_MODAL,
  SET_SUPPLIER_ADD_MODAL,
  FETCH_MARKETPLACE_PRODUCT_INFO,
  FETCH_MARKETPLACE_PRODUCT_INFO_SUCCESS,
  FETCH_SHIPPING_TEMPLATE,
  FETCH_SHIPPING_TEMPLATE_SUCCESS,
  SYNC_SHIPPING_TEMPLATE,
  SYNC_SHIPPING_TEMPLATE_SUCCESS,
  FETCH_MATCHING_LOGS,
  FETCH_MATCHING_LOGS_SUCCESS,
  ASSIGN_SHIPPING_TEMPLATE,
  ASSIGN_SHIPPING_TEMPLATE_SUCCESS,
  UPDATE_UPC,
  UPDATE_UPC_SUCCESS,
  RESET_FETCH_ERROR,
  CHANGE_PRODUCT_VARIATION,
  CHANGE_PRODUCT_VARIATION_SUCCESS,
  CHANGE_QUANTITY_MULTIPLIER,
  CHANGE_QUANTITY_MULTIPLIER_SUCCESS,
  SETUP_MANUAL_MATCHES,
  SETUP_MANUAL_MATCHES_SUCCESS,
  RESET_MANUAL_MATCH_STATE,
  ADD_CSV_LISTINGS,
  ADD_CSV_LISTINGS_SUCCESS,
  DOWNLOAD_LISTINGS,
  DOWNLOAD_LISTINGS_SUCCESS,
  FETCH_UPLOADED_FILES,
  FETCH_UPLOADED_FILES_SUCCESS,
  SET_SELECTED_LISTINGS,
  SET_SELECTED_LIVE_LISTING,
  SET_FILTERED_LISTINGS,
  SET_LISTINGS_BULK_UPDATE_MODAL,
  SET_SHIPPING_TEMPLATE_UPDATE_MODAL,
  LISTINGS_BULK_UPDATE,
  LISTINGS_BULK_UPDATE_SUCCESS,
  LISTINGS_BULK_DELETE,
  LISTINGS_BULK_DELETE_SUCCESS,
  UPDATE_BRAND_RESTRICTION,
  UPDATE_BRAND_RESTRICTION_SUCCESS,
  UPDATE_BRAND_RESTRICTION_BULK,
  UPDATE_BRAND_RESTRICTION_BULK_SUCCESS,
  UPDATE_ERRORED_LISTING,
  UPDATE_ERRORED_LISTING_SUCCESS,
  SET_ERRORED_LISTINGS_UPDATE_MODAL,
  SHOW_CUSTOM_FILTERS,
  SELECT_FILE_ERROR,
  SET_CUSTOM_FILTERS,
  RESET_CUSTOM_FILTERS,
  UPDATE_MAP_AND_HANDLING_TIME,
  UPDATE_MAP_AND_HANDLING_TIME_SUCCESS,
  BULK_UPDATE_HANDLING_TIME,
  BULK_UPDATE_HANDLING_TIME_SUCCESS,
  SET_MA_PRICE_MODAL,
  FETCH_MATCHING_PLATFORMS,
  FETCH_MATCHING_PLATFORMS_SUCCESS,
  ADD_MATCHING_PLATFORM,
  ADD_MATCHING_PLATFORM_SUCCESS,
  API_ERROR,
  UPDATE_SHIPPING_TEMPLATE,
  UPDATE_SHIPPING_TEMPLATE_SUCCESS,
  BULK_UPDATE_TWO_STEP_MODAL,
  BULK_UPDATE_TWO_STEP_MODAL_SUCCESS,
  RELIST_RETIRED_LISTINGS,
  RELIST_RETIRED_LISTINGS_SUCCESS,
  SET_TWO_STEP_MODAL,
  UPDATE_SEARCHED_LISTING,
  SET_TEMPLATE_MODAL,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESS,
  UPLOAD_FACEBOOK_LISTINGS,
  UPLOAD_FACEBOOK_LISTINGS_SUCCESS,
  UPDATE_LISTING_WEIGHT,
  UPDATE_LISTING_WEIGHT_SUCCESS,
  RESET_WEIGHT_ERR,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  RESET_LISTING_STATE,
} = types;

export const resetListingState = () => {
  return {
    type: RESET_LISTING_STATE,
  };
};

export const fetchListings = (params) => {
  return {
    type: FETCH_LISTINGS,
    payload: { params },
  };
};

export const fetchListingsSuccess = (orders) => {
  return {
    type: FETCH_LISTINGS_SUCCESS,
    payload: orders,
  };
};

export const updateListingWeight = (key, body) => ({ type: UPDATE_LISTING_WEIGHT, payload: { key, body } });

export const updateListingWeightSuccess = (response, resetState) => ({
  type: UPDATE_LISTING_WEIGHT_SUCCESS,
  payload: { resetState, response },
});

export const resetWeightErr = (_) => ({ type: RESET_WEIGHT_ERR, payload: {} });

export const updateSearchedListing = (payload) => ({ type: UPDATE_SEARCHED_LISTING, payload });

export const fetchMatchingLogs = (params) => {
  return {
    type: FETCH_MATCHING_LOGS,
    payload: { params },
  };
};

export const checkListingOnStore = (params) => ({
  type: types.CHECK_LISTING_IN_STORE,
  payload: { params },
});

export const checkListingOnStoreSuccess = (res) => ({
  type: types.CHECK_LISTING_IN_STORE_SUCCESS,
  payload: res,
});

export const fetchMatchingLogsSuccess = (logs) => {
  return {
    type: FETCH_MATCHING_LOGS_SUCCESS,
    payload: logs,
  };
};

export const fetchShippingTemplate = (value) => {
  return {
    type: FETCH_SHIPPING_TEMPLATE,
    payload: value ? { partner_id: value } : {},
  };
};

export const fetchShippingTemplateSuccess = (templates) => {
  return {
    type: FETCH_SHIPPING_TEMPLATE_SUCCESS,
    payload: templates,
  };
};

export const syncShippingTemplate = (params) => {
  return {
    type: SYNC_SHIPPING_TEMPLATE,
    payload: { params },
  };
};

export const syncShippingTemplateSuccess = (templates) => {
  return {
    type: SYNC_SHIPPING_TEMPLATE_SUCCESS,
    payload: templates,
  };
};

export const updateShippingTemplate = (data) => {
  return {
    type: UPDATE_SHIPPING_TEMPLATE,
    payload: data,
  };
};

export const updateShippingTemplateSuccess = (data) => {
  return {
    type: UPDATE_SHIPPING_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const setSupplierModal = (bool) => {
  return {
    type: SET_SUPPLIER_MODAL,
    payload: bool,
  };
};

export const setSupplierAddModal = (bool) => {
  return {
    type: SET_SUPPLIER_ADD_MODAL,
    payload: bool,
  };
};

export const resetFetchError = (_) => {
  return {
    type: RESET_FETCH_ERROR,
    payload: {},
  };
};

export const setErroredListingsUpdateModal = (bool) => {
  return {
    type: SET_ERRORED_LISTINGS_UPDATE_MODAL,
    payload: bool,
  };
};

export const fetchMarketplaceProductInfo = (params) => {
  return {
    type: FETCH_MARKETPLACE_PRODUCT_INFO,
    payload: { params },
  };
};

export const fetchMarketplaceProductInfoSuccess = (data) => {
  return {
    type: FETCH_MARKETPLACE_PRODUCT_INFO_SUCCESS,
    payload: data,
  };
};

export const assignShippingTemplate = (data, params = {}) => {
  return {
    type: ASSIGN_SHIPPING_TEMPLATE,
    payload: { data, params },
  };
};

export const assignShippingTemplateSuccess = () => {
  return {
    type: ASSIGN_SHIPPING_TEMPLATE_SUCCESS,
    payload: {},
  };
};

export const updateUPC = (data) => {
  return {
    type: UPDATE_UPC,
    payload: { data },
  };
};

export const updateUPCSuccess = () => {
  return {
    type: UPDATE_UPC_SUCCESS,
    payload: {},
  };
};

export const updateErroredListing = (data) => {
  return {
    type: UPDATE_ERRORED_LISTING,
    payload: { data },
  };
};

export const updateErroredListingSuccess = (_) => {
  return {
    type: UPDATE_ERRORED_LISTING_SUCCESS,
    patload: {},
  };
};

export const changeProductVariation = (params) => {
  return {
    type: CHANGE_PRODUCT_VARIATION,
    payload: { params },
  };
};

export const changeProductVariationSuccess = (data) => {
  return {
    type: CHANGE_PRODUCT_VARIATION_SUCCESS,
    payload: data,
  };
};

export const changeQuantityMultiplier = (params) => {
  return {
    type: CHANGE_QUANTITY_MULTIPLIER,
    payload: { params },
  };
};

export const changeQuantityMultiplierSuccess = (data) => {
  return {
    type: CHANGE_QUANTITY_MULTIPLIER_SUCCESS,
    payload: data,
  };
};

export const setupManualMatches = (params) => {
  return {
    type: SETUP_MANUAL_MATCHES,
    payload: { params },
  };
};

export const setupManualMatchesSuccess = (data) => {
  return {
    type: SETUP_MANUAL_MATCHES_SUCCESS,
    payload: data,
  };
};

export const createInventoryListing = (payload) => ({
  type: types.CREATE_INVENTORY_LISTING,
  payload,
});

export const createInventoryListingSuccess = (payload) => ({
  type: types.CREATE_INVENTORY_LISTING_SUCCESS,
  payload,
});

export const resetManualMatchState = () => {
  return {
    type: RESET_MANUAL_MATCH_STATE,
    payload: {},
  };
};

export const addCSVListings = ({ data, id }) => ({
  type: ADD_CSV_LISTINGS,
  payload: { data, id },
});

export const uploadFacebookListings = ({ data, id }) => ({
  type: UPLOAD_FACEBOOK_LISTINGS,
  payload: { data, id },
});

export const uploadFacebookListingSuccess = (message) => ({
  type: UPLOAD_FACEBOOK_LISTINGS_SUCCESS,
  payload: message,
});

export const selectFileError = (err) => ({
  type: SELECT_FILE_ERROR,
  payload: err,
});

export const addCSVListingsSuccess = (message) => ({
  type: ADD_CSV_LISTINGS_SUCCESS,
  payload: message,
});

export const downloadListings = (params) => ({
  type: DOWNLOAD_LISTINGS,
  payload: { params },
});

export const downloadListingsSuccess = (message) => ({
  type: DOWNLOAD_LISTINGS_SUCCESS,
  payload: message,
});

export const fetchUploadedFiles = (params) => {
  return {
    type: FETCH_UPLOADED_FILES,
    payload: { params },
  };
};

export const fetchUploadedFilesSuccess = (uploadedFiles) => {
  return {
    type: FETCH_UPLOADED_FILES_SUCCESS,
    payload: uploadedFiles,
  };
};

export const setSelectedListings = (id) => {
  return {
    type: SET_SELECTED_LISTINGS,
    payload: id,
  };
};

export const setSelectedLiveListing = (data) => {
  return {
    type: SET_SELECTED_LIVE_LISTING,
    payload: data,
  };
};

export const setFilteredListings = (data) => {
  return {
    type: SET_FILTERED_LISTINGS,
    payload: data,
  };
};

export const setListingsBulkUpdateModal = (bool) => {
  return {
    type: SET_LISTINGS_BULK_UPDATE_MODAL,
    payload: bool,
  };
};

export const setShippingTemplateUpdateModal = (bool) => {
  return {
    type: SET_SHIPPING_TEMPLATE_UPDATE_MODAL,
    payload: bool,
  };
};

export const resetWHSKUInventory = (_) => ({
  type: types.RESET_WH_SKU_INVENTORY,
});

export const bulkUpdateListings = (data) => {
  return {
    type: LISTINGS_BULK_UPDATE,
    payload: { data },
  };
};

export const bulkUpdateListingsSuccess = () => {
  return {
    type: LISTINGS_BULK_UPDATE_SUCCESS,
    payload: {},
  };
};

export const bulkDeleteListings = (data, params = {}, fetchParams = {}) => {
  return {
    type: LISTINGS_BULK_DELETE,
    payload: { data, params, fetchParams },
  };
};

export const bulkDeleteListingsSuccess = () => {
  return {
    type: LISTINGS_BULK_DELETE_SUCCESS,
    payload: {},
  };
};

export const relistRetiredListings = (data, fetchParams = {}) => {
  return {
    type: RELIST_RETIRED_LISTINGS,
    payload: { data, fetchParams },
  };
};

export const relistRetiredListingsSuccess = () => {
  return {
    type: RELIST_RETIRED_LISTINGS_SUCCESS,
    payload: {},
  };
};

export const updateBrandRestriction = (data) => {
  return {
    type: UPDATE_BRAND_RESTRICTION,
    payload: { data },
  };
};

export const updateBrandRestrictionSuccess = () => {
  return {
    type: UPDATE_BRAND_RESTRICTION_SUCCESS,
    payload: {},
  };
};

export const updateBrandRestrictionBulk = (data, params) => {
  return {
    type: UPDATE_BRAND_RESTRICTION_BULK,
    payload: { data, params },
  };
};

export const updateBrandRestrictionBulkSuccess = () => {
  return {
    type: UPDATE_BRAND_RESTRICTION_BULK_SUCCESS,
    payload: {},
  };
};

export const showCustomFiltersAction = (isopen) => ({
  type: SHOW_CUSTOM_FILTERS,
  payload: isopen,
});

export const setCustomFiltersAction = (params) => {
  return {
    type: SET_CUSTOM_FILTERS,
    payload: params,
  };
};

export const resetCustomFiltersAction = () => {
  return { type: RESET_CUSTOM_FILTERS };
};

export const updateMAPriceAndHandlingTime = (data, id) => {
  return {
    type: UPDATE_MAP_AND_HANDLING_TIME,
    payload: { data, id },
  };
};

export const updateMAPriceAndHandlingTimeSuccess = (data) => {
  return {
    type: UPDATE_MAP_AND_HANDLING_TIME_SUCCESS,
    payload: data,
  };
};

export const bulkUpdateHandlingTime = (data, isSingleUpdate, isSingleListing) => {
  return {
    type: BULK_UPDATE_HANDLING_TIME,
    payload: { data, isSingleUpdate, isSingleListing },
  };
};

export const bulkUpdateHandlingTimeSuccess = (data) => {
  return {
    type: BULK_UPDATE_HANDLING_TIME_SUCCESS,
    payload: data,
  };
};

export const apiError = (error, isFetchErr, isWeightErr) => {
  return {
    type: API_ERROR,
    payload: { error, isFetchErr, isWeightErr },
  };
};

export const setMaPriceModal = (bool) => {
  return {
    type: SET_MA_PRICE_MODAL,
    payload: bool,
  };
};

export const fetchMatchingPlatforms = () => {
  return {
    type: FETCH_MATCHING_PLATFORMS,
    payload: {},
  };
};

export const fetchMatchingPlatformsSuccess = (res) => {
  return {
    type: FETCH_MATCHING_PLATFORMS_SUCCESS,
    payload: res,
  };
};

export const addMatchingPlatforms = (data) => {
  return {
    type: ADD_MATCHING_PLATFORM,
    payload: data,
  };
};

export const addMatchingPlatformsSuccess = (data) => {
  return {
    type: ADD_MATCHING_PLATFORM_SUCCESS,
    payload: data,
  };
};

export const setTwoStepUpdateModal = (data) => {
  return {
    type: BULK_UPDATE_TWO_STEP_MODAL,
    payload: data,
  };
};

export const setTwoStepUpdateModalSuccess = (res) => {
  return {
    type: BULK_UPDATE_TWO_STEP_MODAL_SUCCESS,
    payload: res,
  };
};

export const setTwoStepModal = (bool) => {
  return {
    type: SET_TWO_STEP_MODAL,
    payload: bool,
  };
};

export const deleteTemplate = (id) => ({ type: DELETE_TEMPLATE, payload: id });

export const deleteTemplateSuccess = (res) => ({ type: DELETE_TEMPLATE_SUCCESS, payload: res });

export const downloadFile = (url) => ({ type: DOWNLOAD_FILE, payload: { url } });

export const downloadFileSuccess = (res) => ({ type: DOWNLOAD_FILE_SUCCESS, payload: res });

export const setTemplateModal = (bool) => {
  return {
    type: SET_TEMPLATE_MODAL,
    payload: bool,
  };
};

export const createTemplate = (data) => ({
  type: CREATE_TEMPLATE,
  payload: { data },
});

export const createTemplateSuccess = (res) => ({
  type: CREATE_TEMPLATE_SUCCESS,
  payload: res,
});

// Warehouse item info fetch at new inventory page
export const fetchInventoryItemInfo = (payload) => ({ type: types.FETCH_INVENTORY_ITEM_INFO, payload });
export const fetchInventoryItemInfoSuccess = (data) => ({
  type: types.FETCH_INVENTORY_ITEM_INFO_SUCCESS,
  payload: data,
});
