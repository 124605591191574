import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { updateSupplierOrdersByShipmentReport } from "../../api";
// Login Redux States
import { UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT } from "./actionTypes";
import { apiError, updateSupplierOrderFromShipmentReportSuccess } from "./actions";

function * updateSupplierOrdersFromShipmentReportSaga ({ payload: { data } }) {
  try {
    const res = yield call(updateSupplierOrdersByShipmentReport, data);
    yield put(updateSupplierOrderFromShipmentReportSuccess(res.updatedRecords));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchUpdateSupplierOrders () {
  yield takeEvery(UPDATE_SUPPLIER_ORDERS_FROM_SHIPMENT_REPORT, updateSupplierOrdersFromShipmentReportSaga);
}

function * supplierOrdersFromShipmentReportSaga () {
  yield all([fork(watchUpdateSupplierOrders)]);
}

export default supplierOrdersFromShipmentReportSaga;
