import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import CustomTooltip from "../../components/Common/CustomTooltip";
import { AO_DEV_IDS } from "../../constants/index";
import { getUserInfo } from "../../utils/common";
const CurrentTime = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD hh:mm:ss a"));
  useEffect(() => {
    if (!AO_DEV_IDS.includes(getUserInfo()?.id)) return;
    const interval = setInterval(() => setDate(moment().format("DD-MMM-YYYY hh:mm:ss A")), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return <CustomTooltip target="logo_image" placement="top" content={`${date} EST`} />;
};

export default CurrentTime;
