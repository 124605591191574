import moment from "moment-timezone";
import { cloneDeep, compact, keys, sumBy } from "lodash";
import React, { Suspense, useRef, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import CustomPagination from "../../../components/Common/CustomPagination";
import SeeMoreText from "../../../components/Common/SeeMoreText";
import ReactTable from "../../../components/Common/Table";
import { DATE_FORMATS, ORDERABLE_TYPES, STATUS_COLOR_CODE } from "../../../constants";
import { getDate, RenderIf, toPascalCase } from "../../../utils/common";
import ViewNotesIcon from "../../Returns/components/Common/ViewNotesIcon";
import { setInboundShipment } from "../../../store/actions";
import { useSelector } from "react-redux";

function ShipmentsTable ({ inboundsData, filters, setFilters, setShipment, ids, setIds, setIsOpen, isWhDashboard }) {
  const { loading } = useSelector((state) => state.WareHouses);
  const [seeMore, setSeeMore] = useState();
  const [seeMoreAddress, setSeeMoreAddress] = useState();
  const [collapse, setCollapse] = useState();
  const tableHeaders = [
    { title: "Account ID" },
    { title: "Order Details" },
    { title: "Items" },
    { title: "Qty." },
    { title: "Tracking #" },
    { title: "Customer Address" },
    { title: "Type" },
    { title: "Date" },
    { title: "Expected Date" },
    { title: "Warehouse Status" },
    { title: "Tracking Status" },
    { title: "Actions" },
  ];

  const shipmentStatusMapping = {
    complete: "badge-soft-success",
    incomplete: "badge-soft-danger",
    checked_in: "badge-soft-warning",
  };

  const orderableTypesMapping = {
    tracking_items: "2-Step",
  };

  const statusMapping = (item) => {
    if (item?.status === "incomplete" && item?.tracking_status === "delivered") return "Awaiting check-in";
    if (item?.status === "incomplete" && item?.tracking_status !== "delivered") return "Awaiting Shipment";
    if (item?.status === "complete") return "Moved To Inventory";
  };

  const isShipmentComplete = (item) => item?.status === "complete";
  const getSearchable = (item) =>
    item.line_items?.[0]?.sku || item.line_items?.[0]?.upc || item.line_items?.[0]?.title?.slice(0, 5);

  const orderDetailsMapping = {
    purchase_orders: (item) => (
      <div className="d-flex flex-column pt-3">
        <p>
          PO #: <span className="text-primary pl-1">{item?.wh_order?.order_id}</span>
        </p>
        <RenderIf isTrue={item?.wh_order?.supplier}>
          <p>
            Supplier: <span className="seemore-text">{toPascalCase(item?.wh_order?.supplier)}</span>
          </p>
        </RenderIf>
      </div>
    ),
    returns: (item) => (
      <div className="d-flex flex-column pt-3">
        <p>
          RMA #: <span className="text-primary pl-1">{item?.wh_order?.order_id}</span>
        </p>
        <RenderIf isTrue={item?.wh_order?.supplier}>
          <p>
            Supplier: <span className="seemore-text">{toPascalCase(item?.wh_order?.supplier)}</span>
          </p>
        </RenderIf>
      </div>
    ),
    tracking_items: (item) => (
      <div className="d-flex flex-column pt-3">
        <p>
          PO #: <span className="text-primary pl-1">{item?.wh_order?.order_id}</span>
        </p>
        <RenderIf isTrue={item?.wh_order?.supplier}>
          <p>
            Supplier: <span className="seemore-text">{toPascalCase(item?.wh_order?.supplier)}</span>
          </p>
        </RenderIf>
      </div>
    ),
  };

  const getItemText = (id, key, value, seeMoreOpts, length) => {
    return (
      <SeeMoreText
        id={id}
        value={value}
        length={length || 40}
        {...seeMoreOpts}
        className="text-primary"
        onHoverDisable
      />
    );
  };

  const ItemDetails = ({ data, collapse, isCollapse = true }) => {
    const [seeMore, setSeeMore] = useState();
    const tableHeaders = [
      { title: "Title" },
      { title: "Quantity" },
      { title: "UPC" },
      { title: "SKU" },
      { title: "Supplier SKU" },
      { title: "Item ID" },
      { title: "ASIN" },
    ];
    return (
      <RenderIf isTrue={isCollapse}>
        <Collapse isOpen={collapse === data.id}>
          <Card>
            <CardBody className="collapse-card card-body">
              <RenderIf
                isTrue={data?.line_items?.length}
                fallback={
                  <div className="d-flex justify-content-center m-2">
                    <div className="d-flex flex-column">
                      <h1>No Items Found</h1>
                    </div>
                  </div>
                }
              >
                <div className="d-flex justify-content-between">
                  <h6>Items</h6>
                </div>
                <ReactTable
                  tableHeader={
                    <>
                      {tableHeaders.map((x, index) => (
                        <th key={`table-header ${index}`}>{x.title}</th>
                      ))}
                    </>
                  }
                  tableBody={data?.line_items?.map((x, i) => (
                    <tr key={`__lineItemDetail${i}__`}>
                      <td>{getItemText(i, "Item", x?.item_title || "N/A", { seeMore, setSeeMore }, 100)}</td>
                      <td>{getText(x?.quantity)}</td>
                      <td>{getText(x?.upc)}</td>
                      <td>{getText(x?.sku)}</td>
                      <td>{getText(x?.supplier_sku)}</td>
                      <td>{getText(x?.item_id)}</td>
                      <td>{getText(x?.asin)}</td>
                    </tr>
                  ))}
                ></ReactTable>
              </RenderIf>
            </CardBody>
          </Card>
        </Collapse>
      </RenderIf>
    );
  };

  const getText = (text) => text || "N/A";

  const ItemDetail = (item, seeMore, setSeeMore) => (
    <div className="d-flex flex-column pt-3">
      <span className="d-flex">
        Item:{" "}
        <span className="seemore-text">
          {getItemText(item?.id, "Item", compact(item?.line_items?.map((x) => x?.item_title)).join(", ") || "N/A", {
            seeMore,
            setSeeMore,
          })}
        </span>
      </span>
      <span className="d-flex">
        UPC:{" "}
        <span className="seemore-text">
          {getItemText(item?.id, "Item", compact(item?.line_items?.map((x) => x?.upc)).join(", ") || "N/A", {
            seeMore,
            setSeeMore,
          })}
        </span>
      </span>
    </div>
  );

  const sortCount = useRef(0);

  const fieldsMapping = { "Expected Date": "required_shipping_date" };

  const getSortFilter = (title, dir) => {
    setFilters({
      ...filters,
      sort_by: fieldsMapping[title],
      sort_dir: !dir || dir === "desc" ? "asc" : "desc",
    });
  };
  const collapseRow = (id) => setCollapse(collapse === id ? "" : id);
  const getTrackingNumber = (trackingNumber) => (/(TEMP-)\w+/.test(trackingNumber) ? "N/A" : trackingNumber);

  const SortArrows = ({ title }) => {
    const sortOpts = [
      ["asc", "up"],
      ["desc", "down"],
    ];
    const isSameCol = fieldsMapping[title] === filters.sort_by;
    return (
      <span
        onClick={() => {
          if (fieldsMapping[title] === filters.sort_by) sortCount.current++;
          else sortCount.current = 1;
          if (sortCount.current === 3) setFilters({ ...filters, sort_by: null, sort_dir: null });
          else getSortFilter(title, isSameCol && sortCount.current < 3 ? filters.sort_dir : null);
        }}
      >
        {sortOpts
          .filter(([dir]) => (isSameCol ? dir === filters.sort_dir : true))
          .map(([, arrow], index) => (
            <i
              key={"_sort_opts_" + index}
              id={arrow + "-filter"}
              style={{ fontSize: "10px" }}
              className={`dripicons-arrow-thin-${arrow} cursor-pointer`}
            ></i>
          ))}
      </span>
    );
  };

  return (
    <Card>
      <CardBody>
        <RenderIf isTrue={Array.isArray(inboundsData?.data) && inboundsData?.data.length}>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  {tableHeaders.map((header, index) => (
                    <React.Fragment key={`table-header ${index}`}>
                      <th key={`table-header ${index}`}>
                        {header.title}
                        <RenderIf isTrue={keys(fieldsMapping).includes(header.title)}>
                          <div className="d-inline">
                            <SortArrows title={header.title} />
                          </div>
                        </RenderIf>
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {inboundsData?.data?.map((item, key) => (
                  <React.Fragment key={"_return_row_" + key}>
                    <tr key={"_return_" + key} className={isWhDashboard && item?.wh_order?.account?.is_liquidated ? "row-danger" : ""}>
                      <td>{item?.wh_order?.account_id}</td>
                      <td>{orderDetailsMapping?.[item?.wh_order?.orderable_type]?.(item)}</td>
                      <td style={{ minWidth: "398px" }}>{ItemDetail?.(item, seeMore, setSeeMore)}</td>
                      <td>{item?.total_units || sumBy(item?.line_items, "received_shipments.expected_qty")}</td>
                      <td>{getTrackingNumber(item?.tracking_number)}</td>
                      <td style={{ minWidth: "500px" }}>
                        <div className="d-flex flex-column">
                          <span>
                            <i className="bx bx-user pr-2" />
                            <span className="seemore-text">{getText(item?.customer_address?.split(",")[0])}</span>
                          </span>
                          <span className="d-flex">
                            <i className="bx bx-home pr-2" />
                            <span className="seemore-text">
                              {getItemText(
                                item?.id,
                                "Address",
                                item?.customer_address?.split(",").splice(1).join(",").trim() || "N/A",
                                { seeMore: seeMoreAddress, setSeeMore: setSeeMoreAddress },
                                50,
                              )}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td>
                        {toPascalCase(
                          orderableTypesMapping[item?.wh_order?.orderable_type] || item?.wh_order?.orderable_type,
                        )}
                        <RenderIf isTrue={item?.wh_order?.orderable_type === ORDERABLE_TYPES.purchase_orders}>
                          <br />
                          SubType:{" "}
                          <RenderIf isTrue={item?.wh_order?.po_type} fallback={"N/A"}>
                            <span className="badge-pill badge-soft-success">
                              {toPascalCase(orderableTypesMapping[item?.wh_order?.po_type] || item?.wh_order?.po_type)}
                            </span>
                          </RenderIf>
                        </RenderIf>
                      </td>
                      <td>
                        <span className="d-flex pt-2">
                          Order placed:
                          <p className="seemore-text">
                            <RenderIf isTrue={item?.wh_order?.date} fallback="N/A">
                              {moment(item?.wh_order?.date).format(DATE_FORMATS.DATE)}
                            </RenderIf>
                          </p>
                        </span>
                        <span className="d-flex">
                          Shipment created:
                          <p className="seemore-text">{moment(item?.created_at).format(DATE_FORMATS.DATE)}</p>
                        </span>
                      </td>
                      <td>
                        <span className="d-flex pt-2">
                          Expected Ship Date:
                          <p className="seemore-text">
                            <RenderIf isTrue={item?.wh_order?.required_shipping_date} fallback="N/A">
                              {moment(item?.wh_order?.required_shipping_date).format(DATE_FORMATS.DATE)}
                            </RenderIf>
                          </p>
                        </span>
                        <RenderIf isTrue={item?.wh_order?.orderable_type === "tracking_items"}>
                          <span className="d-flex justify-content-between mb-3">
                            Store Status:
                            <span
                              className={`badge-soft-${
                                STATUS_COLOR_CODE[item?.wh_order?.marketplace_status] || "custom"
                              } badge badge-pill`}
                            >
                              {getText(item?.wh_order?.marketplace_status)}
                            </span>
                          </span>
                        </RenderIf>
                      </td>
                      <td className="text-center">
                        <span
                          className={`${
                            shipmentStatusMapping?.[item?.status] || "badge-soft-custom"
                          } badge badge-pill ${isShipmentComplete(item) ? "cursor-pointer" : ""}`}
                          onClick={() =>
                            !isShipmentComplete(item)
                              ? null
                              : window.open(
                                  `/inventory-items?search=${getSearchable(item)}&account_id=${
                                    item?.wh_order?.account_id
                                  }`,
                                  "blank",
                              )
                          }
                        >
                          {toPascalCase(statusMapping(item) || item?.status) || "N/A"}
                        </span>
                      </td>
                      <td>
                        <div>
                          <RenderIf isTrue={item?.tracking_status_update_date}>
                            <span className="d-flex pt-2">
                              Last Updated:&nbsp;
                              <p className="seemore-text">
                                {getDate(item?.tracking_status_update_date, DATE_FORMATS.FULL_DATE)}
                              </p>
                            </span>
                          </RenderIf>
                          <span className="d-flex align-item-baseline mb-3">
                            Status:&nbsp;
                            <span
                              className={`badge-soft-${
                                STATUS_COLOR_CODE[item?.tracking_status] || "custom"
                              } badge badge-pill`}
                            >
                              {toPascalCase(item?.tracking_status) || "N/A"}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i
                            title="Expand/Collapse"
                            className="mdi mdi-arrow-expand bx-sm text-primary cursor-pointer px-1"
                            onClick={() => collapseRow(item.id)}
                          />
                          <ViewNotesIcon
                            item={item}
                            notices={item.warehouse_notices}
                            setSelected={setInboundShipment}
                            className="px-1"
                          />
                          <i
                            title="Move to inventory"
                            className="mdi mdi-file-replace-outline bx-sm text-primary cursor-pointer px-1"
                            onClick={() => setShipment(cloneDeep(item))}
                          />
                          <RenderIf isTrue={item?.status === "incomplete"}>
                            <i
                              title="Shipment Checked-In"
                              className="bx bx-check-circle bx-sm text-primary cursor-pointer px-1"
                              onClick={() => {
                                setIds({ ids: [item.id] });
                                setIsOpen(true);
                              }}
                            />
                          </RenderIf>
                          <RenderIf isTrue={item?.status === "complete"}>
                            <i
                              title="Redirect To Outbound"
                              className="bx bx-right-arrow-alt bx-sm text-primary cursor-pointer px-1"
                              onClick={() =>
                                window.open(
                                  `/outbound-items?page=1&per_page=30&search=${item?.wh_order?.order_id}`,
                                  "blank",
                                )
                              }
                            />
                          </RenderIf>
                        </div>
                      </td>
                    </tr>
                    <tr className="p-0 collapse-show">
                      <td align="center" colSpan="12">
                        <ItemDetails data={item} collapse={collapse} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <Suspense>
            <CustomPagination
              total={inboundsData?.count}
              pageOptions={[20, 30, 50]}
              page={inboundsData?.page}
              perPage={inboundsData?.per_page}
              tabsFilter={filters}
              setTabFilter={setFilters}
            />
          </Suspense>
        </RenderIf>
        <RenderIf isTrue={!inboundsData?.data?.length && !loading}>
          <div className="d-flex justify-content-center m-2">
            <div className="d-flex flex-column">
              <h1>No Records Found</h1>
            </div>
          </div>
        </RenderIf>
      </CardBody>
    </Card>
  );
}

export default ShipmentsTable;
