import * as types from "./actionTypes";
import { getUserInfo } from "../../utils/common";

const initialState = {
  error: "",
  success: "",
  onboardAccountSuccess: "",
  agencyAccounts: null,
  loading: false,
  accounts: null,
  userAccounts: null,
  selectedAccount: null,
  editModalIsOpen: false,
  shopifySuccess: "",
  refTokenLoading: false,
  repricerIntegrationModalIsOpen: false,
  twoStepModalIsOpen: false,
  addModalIsOpen: false,
  settingsModalIsOpen: false,
  isAmz: false,
  isShopify: false,
  isUpdate: false,
  marketplaceData: {},
  formData: {},
  storeNameData: {},
};

const MarketplaceAccounts = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_SUCCESS:
      state = { ...state, success: "" };
      break;
    case types.SEND_ERROR_EMAIL_SUCCESS:
      state = { ...state, success: action.payload.message, error: "", loading: false };
      break;
    case types.CHECK_STORE:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.CHECK_STORE_SUCCESS:
      state = { ...state, success: "", error: "", loading: false, storeStatus: action.payload };
      break;
    case types.CHANGE_STORE_STATE:
      state = { ...state, ...action.payload };
      break;
    case types.FETCH_MARKETPLACE_ACCOUNTS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case types.FETCH_MARKETPLACE_ACCOUNTS_SUCCESS: {
      const { res, params } = action.payload;
      const data = params?.id ? { agencyAccounts: res } : { accounts: res };
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        ...data,
      };
      const tenantId = getUserInfo()?.account_id;
      if (tenantId) state.curTenantId = tenantId;
      break;
    }
    case types.FETCH_SP_API_REFRESH_TOKEN:
      state.loading = true;
      state.refTokenLoading = true;
      state.spApiErr = "";
      state.spApiMsg = "";
      break;
    case types.FETCH_SP_API_REFRESH_TOKEN_SUCCESS:
      state = {
        ...state,
        loading: false,
        refTokenLoading: false,
        spApiMsg: action.payload.error ? "" : "You have successfully authorized your store to Ecom Circles",
        spApiErr: action.payload.error,
        spApiRefToken: action.payload.refreshToken,
      };
      break;
    case types.FETCH_SHOPIFY_REFRESH_TOKEN:
      state = {
        ...state,
        loading: true,
        refTokenLoading: true,
        shopifyErr: "",
        shopifySuccess: "",
      };
      break;
    case types.FETCH_SHOPIFY_REFRESH_TOKEN_SUCCESS:
      state = {
        ...state,
        loading: false,
        refTokenLoading: false,
        shopifySuccess: action.payload.error ? "" : "You have successfully authorized your store to Ecom Circles",
        shopifyErr: action.payload.error,
        shopifyRefToken: action.payload.refreshToken,
      };
      break;
    case types.SET_AMZ_ONBOARDING_MODAL:
      state = { ...state, isAmz: action.payload, spApiErr: "", spApiMsg: "", formData: {} };
      break;
    case types.SET_SHOPIFY_ONBOARDING_MODAL:
      state = { ...state, isShopify: action.payload, shopifyErr: "", shopifySuccess: "", formData: {} };
      break;
    case types.SET_REFRESH_TOKEN:
      state = { ...state, spApiRefToken: action.payload };
      break;
    case types.SET_SHOPIFY_REFRESH_TOKEN:
      state = { ...state, shopifyRefToken: action.payload };
      break;
    case types.SET_AMZ_ONBOARDING_FORM_DATA:
      state = { ...state, formData: action.payload };
      break;
    case types.FETCH_USER_MARKETPLACE_ACCOUNT:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
        userAccounts: undefined,
      };
      break;
    case types.FETCH_USER_MARKETPLACE_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
        userAccounts: action.payload,
      };
      break;
    case types.SET_TWO_STEP_MODAL:
      state = { ...state, twoStepModalIsOpen: action.payload };
      break;
    case types.SET_SP_API_ERR:
      state = {
        ...state,
        success: "",
        spApiMsg: "",
        spApiErr: action.payload,
      };
      break;
    case types.UPDATE_MARKETPLACE_ACCOUNT:
      state = {
        ...state,
        success: "",
        error: "",
        spApiMsg: "",
        spApiErr: "",
        loading: true,
      };
      break;
    case types.UPDATE_MARKETPLACE_ACCOUNT_SUCCESS:
      state = {
        ...state,
        spApiRefToken: undefined,
        success: "Marketplace Account Updated Successfully",
        isAmz: false,
        error: "",
        loading: false,
      };
      break;
    case types.UPDATE_REPRICER:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case types.UPDATE_REPRICER_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        data: action.payload,
      };
      break;
    case types.DELETE_MARKETPLACE_ACCOUNT:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case types.DELETE_MARKETPLACE_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "MarketPlace Account Deleted Successfully",
        loading: false,
      };
      break;
    case types.CREATE_MARKETPLACE_ACCOUNTS:
      state = {
        ...state,
        spApiMsg: "",
        spApiErr: "",
        success: "",
        error: "",
        loading: true,
      };
      break;
    case types.CREATE_MARKETPLACE_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        spApiRefToken: undefined,
        shopifyRefToken: undefined,
        marketplaceData: action.payload.accounts,
        accounts: action.payload.accounts,
        success: "",
        onboardAccountSuccess: "Marketplace Account Added Successfully",
        error: "",
        loading: false,
      };
      break;
    case types.SET_EDIT_MODAL_MARKETPLACE_ACCOUNT:
      state = {
        ...state,
        success: "",
        error: "",
        selectedAccount: action.payload === false ? null : state.selectedAccount,
        editModalIsOpen: action.payload,
      };
      break;
    case types.SET_REPRICER_INTEGRATION_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        repricerIntegrationModalIsOpen: action.payload,
      };
      break;
    case types.SET_ADD_MODAL_MARKETPLACE_ACCOUNT:
      state = {
        ...state,
        success: "",
        error: "",
        onboardAccountSuccess: "",
        addModalIsOpen: action.payload,
      };
      break;
    case types.SET_SETTINGS_MODAL_MARKETPLACE_ACCOUNT:
      state = {
        ...state,
        success: "",
        error: "",
        settingsModalIsOpen: action.payload,
      };
      break;
    case types.SET_SELECTED_MARKETPLACE_ACCOUNT:
      state = {
        ...state,
        success: "",
        error: "",
        onboardAccountSuccess: "",
        selectedAccount: action.payload,
      };
      break;
    case types.UPDATE_VACATION_MODE:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case types.CHECK_STORE_NAME:
      state = { ...state, success: "", error: "", loading: true };
      break;
    case types.CHECK_STORE_NAME_SUCCESS:
      state = { ...state, success: "", error: "", loading: false, storeNameData: action.payload };
      break;
    case types.RESET_STORE_NAME:
      state.storeNameData = {};
      break;
    case types.RESET_MARKETPLACE_ACC_ERR:
      state.error = "";
      break;
    case types.API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MarketplaceAccounts;
