import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Label, FormGroup, Alert } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";
import Select from "react-select";
import { getWarehouseName, isAdmin, RenderIf } from "../../../utils/common";
import "react-datepicker/dist/react-datepicker.css";

// actions
import { setSelectWareHouseModal } from "../../../store/actions";
import { merge, values } from "lodash";

const ChangeWareHouseModal = (props) => {
  const {
    orderAction: { current: orderAction },
  } = props;
  const {
    action_name: actionName,
    order: { wh_id: whId },
    args: { data, id },
    action,
  } = orderAction;

  const [warehouseOption, setWarehouseOption] = useState({});
  const isUpdateOrder = actionName === "updateSourceOrder";
  const dispatch = useDispatch();
  const { associatedWarehouses, userWarehouses } = useSelector((state) => state.WareHouses);
  const { selectWarehouseModalIsOpen, error } = useSelector((state) => state.SourceOrders);

  const initialValues = { warehouse: { label: "Select WareHouse", value: "" } };
  const toggleModal = () => {
    dispatch(setSelectWareHouseModal(!selectWarehouseModalIsOpen));
    formik.resetForm();
  };

  const getWarehouses = () => (isUpdateOrder && isAdmin() ? values(userWarehouses) : associatedWarehouses?.data);

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key].label === "" || values[key].value === "") errors[key] = "Field is Required";
    });
    return errors;
  };

  const isAssociatedWarehouse = () => associatedWarehouses?.data.some((item) => item.id === warehouseOption?.value);

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: ({ warehouse: { value } }) => {
      if (isUpdateOrder) dispatch(action({ id, data: merge(data, { wh_id: value }) }));
      else dispatch(action(merge(data, { warehouse_id: value }), id));
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={selectWarehouseModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">SELECT WAREHOUSE</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          )}
          <RenderIf isTrue={warehouseOption?.value && !isAssociatedWarehouse()}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                This warehouse is not associated to your account.
              </Alert>
            </div>
          </RenderIf>
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mt-2">
                <FormGroup className="select2-container mb-0">
                  <Label>WareHouse Name</Label>
                  <Select
                    name="warehouse"
                    value={formik.values.warehouse}
                    onBlur={formik.handleBlur}
                    onChange={(option) => {
                      formik.setFieldValue("warehouse", option);
                      setWarehouseOption(option);
                    }}
                    options={getWarehouses().reduce((arr, x) => {
                      if (x.id !== whId) arr.push({ label: getWarehouseName(x), value: x.id });
                      return arr;
                    }, [])}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                {formik.touched.warehouse && formik.errors.warehouse ? (
                  <small className="text-danger">{formik.errors.warehouse}</small>
                ) : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3" disabled={!isAssociatedWarehouse()}>
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default ChangeWareHouseModal;
