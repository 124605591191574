import { CREATE_ORDER_LOG, CREATE_ORDER_LOG_SUCCESS, API_ERROR, RESET_SUCCESS_ERR_MSG } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
};

const OrderLog = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_LOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_ORDER_LOG_SUCCESS:
      state = {
        ...state,
        success: "Note Added to Orderlog",
        error: "",
        loading: false,
      };
      break;
    case RESET_SUCCESS_ERR_MSG:
      state = {
        ...state,
        success: "",
        error: "",
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default OrderLog;
