import React from "react";
import { Badge } from "reactstrap";
import { STATUS_COLOR_CODE } from "../../constants";
import { RenderIf, toPascalCase } from "../../utils/common";
const StatusBadge = ({ status, heading, colorsMapping = {}, textParser, style, otherComponent = null, className = "" }) =>
  status && (
    <div className="d-flex flex-row">
      {heading && <span>{heading}&nbsp;</span>}
      <Badge
        style={style}
        className={"font-size-12 badge-soft-" + (STATUS_COLOR_CODE[status] || colorsMapping[status]) + " " + className}
        color={STATUS_COLOR_CODE[status] || colorsMapping[status]}
        pill
      >
        <RenderIf isTrue={otherComponent} fallback={textParser ? textParser(status) : toPascalCase(status)}>
        {otherComponent}
        </RenderIf>
      </Badge>
    </div>
  );

export default StatusBadge;
