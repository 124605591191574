import {
  FETCH_UNVERIFIED_MATCHES,
  FETCH_UNVERIFIED_MATCHES_SUCCESS,
  FETCH_MARKET_PLACE_CATEGORIES,
  FETCH_MARKET_PLACE_CATEGORIES_SUCCESS,
  FETCH_MARKET_PLACE_STORES,
  FETCH_MARKET_PLACE_STORES_SUCCESS,
  SET_SELECTED_UNPUBLISHED_LISTINGS,
  LIST_UNVERIFIED_MATCHES,
  LIST_UNVERIFIED_MATCHES_SUCCESS,
  UPDATE_LISTINGS_DATA_SUCCESS,
  SET_BULK_UNVERIFIED_MATCHES_MODAL,
  SUBMIT_UNVERIFIED_MATCHES_REQUEST,
  SUBMIT_UNVERIFIED_MATCHES_REQUEST_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const fetchUnverifiedMatches = (params) => {
  return {
    type: FETCH_UNVERIFIED_MATCHES,
    payload: { params },
  };
};

export const fetchUnverifiedMatchesSuccess = (listings) => {
  return {
    type: FETCH_UNVERIFIED_MATCHES_SUCCESS,
    payload: listings,
  };
};

export const listUnverifiedMatches = (items) => {
  return {
    type: LIST_UNVERIFIED_MATCHES,
    payload: items,
  };
};

export const listUnverifiedMatchesSuccess = (res) => {
  return {
    type: LIST_UNVERIFIED_MATCHES_SUCCESS,
    payload: res,
  };
};

export const setBulkUnverifiedMatchesModal = (bool) => {
  return {
    type: SET_BULK_UNVERIFIED_MATCHES_MODAL,
    payload: bool,
  };
};

export const updateListingsDataSuccess = (res) => {
  return {
    type: UPDATE_LISTINGS_DATA_SUCCESS,
    payload: res,
  };
};

export const fetchMarketPlaceCategories = (params) => {
  return {
    type: FETCH_MARKET_PLACE_CATEGORIES,
    payload: params,
  };
};

export const fetchMarketPlaceCategoriesSuccess = (categories) => {
  return {
    type: FETCH_MARKET_PLACE_CATEGORIES_SUCCESS,
    payload: categories,
  };
};

export const fetchMarketPlaceStores = (params) => {
  return {
    type: FETCH_MARKET_PLACE_STORES,
    payload: params,
  };
};

export const fetchMarketPlaceStoresSuccess = (stores) => {
  return {
    type: FETCH_MARKET_PLACE_STORES_SUCCESS,
    payload: stores,
  };
};

export const setSelectedUnpublishedListings = (id) => {
  return {
    type: SET_SELECTED_UNPUBLISHED_LISTINGS,
    payload: id,
  };
};

export const submitUnverifiedMatchesRequest = (body) => {
  return {
    type: SUBMIT_UNVERIFIED_MATCHES_REQUEST,
    payload: body,
  };
};

export const submitUnverifiedMatchesRequestSuccess = () => {
  return {
    type: SUBMIT_UNVERIFIED_MATCHES_REQUEST_SUCCESS,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
