export const FETCH_ALL_WAREHOUSES = "FETCH_ALL_WAREHOUSES";
export const FETCH_ALL_WAREHOUSES_SUCCESS = "FETCH_ALL_WAREHOUSES_SUCCESS";
export const FETCH_ALL_WAREHOUSE_ITEMS = "FETCH_ALL_WAREHOUSE_ITEMS";
export const FETCH_ALL_WAREHOUSE_ITEMS_SUCCESS = "FETCH_ALL_WAREHOUSE_ITEMS_SUCCESS";
export const FETCH_WAREHOUSE_ACCOUNTS = "FETCH_WAREHOUSE_ACCOUNTS";
export const FETCH_WAREHOUSE_ACCOUNTS_SUCCESS = "FETCH_WAREHOUSE_ACCOUNTS_SUCCESS";
export const SET_ADD_WAREHOUSE_MODAL = "SET_ADD_WAREHOUSE_MODAL";
export const SET_VIEW_WAREHOUSE_USERS_MODAL = "SET_VIEW_WAREHOUSE_USERS_MODAL";
export const SET_ASSIGN_USER_WAREHOUSE_MODAL = "SET_ASSIGN_USER_WAREHOUSE_MODAL";
export const SELECT_EXISTING_WAREHOUSE = "SELECT_EXISTING_WAREHOUSE";
export const SET_SELECTED_WAREHOUSE = "SET_SELECTED_WAREHOUSE";
export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE";
export const UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS";
export const SET_SELECTED_WAREHOUSE_ITEM = "SET_SELECTED_WAREHOUSE_ITEM";
export const CREATE_WAREHOUSE = "CREATE_WAREHOUSE";
export const SET_SELECT_ON_BOARD_STORE = "SET_SELECT_ON_BOARD_STORE";
export const CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS";
export const ASSIGN_USER_WAREHOUSE = "ASSIGN_USER_WAREHOUSE";
export const ASSIGN_USER_WAREHOUSE_SUCCESS = "ASSIGN_USER_WAREHOUSE_SUCCESS";
export const UPDATE_WAREHOUSE_ITEM = "UPDATE_WAREHOUSE_ITEM";
export const UPDATE_WAREHOUSE_ITEM_SUCCESS = "UPDATE_WAREHOUSE_ITEM_SUCCESS";
export const DELETE_WAREHOUSE_USER = "DELETE_WAREHOUSE_USER";
export const DELETE_WAREHOUSE_USER_SUCCESS = "DELETE_WAREHOUSE_USER_SUCCESS";
export const ADD_TRACKING_ITEM_NOTE_MODAL = "ADD_TRACKING_ITEM_NOTE_MODAL";
export const ADD_WAREHOUSE_NOTE = "ADD_WAREHOUSE_NOTE";
export const ADD_WAREHOUSE_NOTE_SUCCES = "ADD_WAREHOUSE_NOTE_SUCCES";
export const RESOLVE_STRANDED_ITEM = "RESOLVE_STRANDED_ITEM";
export const RESOLVE_STRANDED_ITEM_SUCCESS = "RESOLVE_STRANDED_ITEM_SUCCESS";
export const FETCH_INVENTORY_ITEMS = "FETCH_INVENTORY_ITEMS";
export const FETCH_INVENTORY_ITEMS_SUCCESS = "FETCH_INVENTORY_ITEMS_SUCCESS";
export const UPDATE_INVENTORY_ITEM_STATUS = "UPDATE_INVENTORY_ITEM";
export const UPDATE_INVENTORY_ITEM_STATUS_SUCCESS = "UPDATE_INVENTORY_ITEM_SUCCESS";
export const SET_UPLOAD_RETURN_LABEL_MODAL = "SET_UPLOAD_RETURN_LABEL_MODAL";
export const SET_SELECTED_INVENTORY_ITEM = "SET_SELECTED_INVENTORY_ITEM";
export const UPLOAD_RETURN_LABEL_FILE = "UPLOAD_RETURN_LABEL_FILE";
export const UPLOAD_RETURN_LABEL_FILE_SUCCESS = "UPLOAD_RETURN_LABEL_FILE_SUCCESS";
export const DOWNLOAD_RETURN_LABEL = "DOWNLOAD_RETURN_LABEL";
export const DOWNLOAD_RETURN_LABEL_SUCCESS = "DOWNLOAD_RETURN_LABEL_SUCCESS";
export const DELETE_RETURN_LABEL = "DELETE_RETURN_LABEL";
export const DELETE_RETURN_LABEL_SUCCESS = "DELETE_RETURN_LABEL_SUCCESS";
export const SET_DOWNLOAD_RETURN_LABEL_MODAL = "SET_DOWNLOAD_RETURN_LABEL_MODAL";
export const SET_TRACKING_STATUS_MODAL = "SET_TRACKING_STATUS_MODAL";
export const ASSIGN_AGENCY_TO_WAREHOUSE = "ASSIGN_AGENCY_TO_WAREHOUSE";
export const ASSIGN_AGENCY_TO_WAREHOUSE_SUCCESS = "ASSIGN_AGENCY_TO_WAREHOUSE_SUCCESS";
export const DELETE_WH_ACCOUNT = "DELETE_WH_ACCOUNT";
export const DELETE_WH_ACCOUNT_SUCCESS = "DELETE_WH_ACCOUNT_SUCCESS";
export const SET_VIEW_AGENCY_WAREHOUSE_MODEL = "SET_VIEW_AGENCY_WAREHOUSE_MODEL";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const UPDATE_WH_ACCOUNT = "UPDATE_WH_ACCOUNT";
export const UPDATE_WH_ACCOUNT_SUCCESS = "UPDATE_WH_ACCOUNT_SUCCESS";
export const UPDATE_BULK_ITEMS = "UPDATE_BULK_ITEMS";
export const UPDATE_BULK_ITEMS_SUCCESS = "UPDATE_BULK_ITEMS_SUCCESS";
export const MARK_NOT_RECEIVED = "MARK_NOT_RECEIVED";
export const MARK_NOT_RECEIVED_SUCCESS = "MARK_NOT_RECEIVED_SUCCESS";
export const FETCH_USER_WAREHOUSES = "FETCH_USER_WAREHOUSES";
export const FETCH_USER_WAREHOUSES_SUCCESS = "FETCH_USER_WAREHOUSES_SUCCESS";
export const FETCH_ALL_SHIPPING_RATES = "FETCH_ALL_SHIPPING_RATES";
export const FETCH_ALL_SHIPPING_RATES_SUCCESS = "FETCH_ALL_SHIPPING_RATES_SUCCESS";
export const GENERATE_LABEL = "GENERATE_LABEL";
export const GENERATE_LABEL_SUCCESS = "GENERATE_LABEL_SUCCESS";
export const FETCH_ALL_SHIPMENTS = "GET_ALL_SHIPMENTS";
export const FETCH_ALL_SHIPMENTS_SUCCESS = "GET_ALL_SHIPMENTS_SUCCESS";
export const FETCH_ALL_OUTBOUND_SHIPMENTS = "FETCH_ALL_OUTBOUND_SHIPMENTS";
export const FETCH_ALL_OUTBOUND_SHIPMENTS_SUCCESS = "FETCH_ALL_OUTBOUND_SHIPMENTS_SUCCESS";
export const REFUND_SHIPMENT_LABEL = "REFUND_SHIPMENT_LABEL";
export const REFUND_SHIPMENT_LABEL_SUCCESS = "REFUND_SHIPMENT_LABEL_SUCCESS";
export const API_ERROR = "WAREHOUSE_ERROR";
export const DOWNLOAD_SHIPPING_LABEL = "DOWNLOAD_SHIPPING_LABEL";
export const DOWNLOAD_SHIPPING_LABEL_SUCCESS = "DOWNLOAD_SHIPPING_LABEL_SUCCESS";
export const DOWNLOAD_INVENTORY_REPORT = "DOWNLOAD_INVENTORY_REPORT";
export const DOWNLOAD_INVENTORY_REPORT_SUCCESS = "DOWNLOAD_INVENTORY_REPORT_SUCCESS";
export const DOWNLOAD_WH_ITEMS_REPORT = "DOWNLOAD_WH_ITEMS_REPORT";
export const DOWNLOAD_WH_ITEMS_REPORT_SUCCESS = "DOWNLOAD_WH_ITEMS_REPORT_SUCCESS";
export const SET_BIN_NO_MODAL = "SET_BIN_NO_MODAL";
export const SET_ADD_INVENTORY_MODAL = "SET_ADD_INVENTORY_MODAL";
export const ADD_INVENTORY_ITEM = "ADD_INVENTORY_ITEM";
export const ADD_INVENTORY_ITEM_SUCCESS = "ADD_INVENTORY_ITEM_SUCCESS";
export const UPDATE_INVENTORY_ITEM_BINS = "UPDATE_INVENTORY_ITEM_BINS";
export const UPDATE_INVENTORY_ITEM_BINS_SUCCESS = "UPDATE_INVENTORY_ITEM_BINS_SUCCESS";
export const DELETE_INVENTORY_ITEM_BIN = "DELETE_INVENTORY_ITEM_BIN";
export const DELETE_INVENTORY_ITEM_BIN_SUCCESS = "DELETE_INVENTORY_ITEM_BIN_SUCCESS";
// Purchase Orders
export const FETCH_PURCHASE_ORDERS = "FETCH_PURCHASE_ORDERS";
export const FETCH_PURCHASE_ORDERS_SUCCESS = "FETCH_PURCHASE_ORDERS_SUCCESS";
export const FETCH_PURCHASE_ORDERS_METADATA = "FETCH_PURCHASE_ORDERS_METADATA";
export const FETCH_PURCHASE_ORDERS_METADATA_SUCCESS = "FETCH_PURCHASE_ORDERS_METADATA_SUCCESS";
export const FETCH_PO_LOGS = "FETCH_PO_LOGS";
export const FETCH_PO_LOGS_SUCCESS = "FETCH_PO_LOGS_SUCCESS";
export const FETCH_PO_STATS = "FETCH_PO_STATS";
export const FETCH_PO_STATS_SUCCESS = "FETCH_PO_STATS_SUCCESS";
export const FETCH_PO_SUPPLIERS = "FETCH_PO_SUPPLIERS";
export const FETCH_PO_SUPPLIERS_SUCCESS = "FETCH_PO_SUPPLIERS_SUCCESS";
export const CREATE_PURCHASE_ORDERS = "CREATE_PURCHASE_ORDERS";
export const CREATE_PURCHASE_ORDERS_SUCCESS = "CREATE_PURCHASE_ORDERS_SUCCESS";
export const CREATE_LINE_ITEM = "CREATE_LINE_ITEM";
export const CREATE_LINE_ITEM_SUCCESS = "CREATE_LINE_ITEM_SUCCESS";
export const CREATE_PO_SHIPMENT = "CREATE_PO_SHIPMENT";
export const CREATE_PO_SHIPMENT_SUCCESS = "CREATE_PO_SHIPMENT_SUCCESS";
export const DELETE_PURCHASE_ORDER = "DELETE_PURCHASE_ORDER";
export const DELETE_PURCHASE_ORDER_SUCCESS = "DELETE_PURCHASE_ORDER_SUCCESS";
export const UPDATE_PURCHASE_ORDER = "UPDATE_PURCHASE_ORDER";
export const UPDATE_PURCHASE_ORDER_SUCCESS = "UPDATE_PURCHASE_ORDER_SUCCESS";
export const UPDATE_PURCHASE_ORDER_ITEM = "UPDATE_PURCHASE_ORDER_ITEM";
export const UPDATE_PURCHASE_ORDER_ITEM_SUCCESS = "UPDATE_PURCHASE_ORDER_ITEM_SUCCESS";
export const UPDATE_PURCHASE_ORDER_SHIPMENT = "UPDATE_PURCHASE_ORDER_SHIPMENT";
export const UPDATE_PURCHASE_ORDER_SHIPMENT_SUCCESS = "UPDATE_PURCHASE_ORDER_SHIPMENT_SUCCESS";
export const DELETE_PURCHASE_ORDER_ITEM = "DELETE_PURCHASE_ORDER_ITEM";
export const DELETE_PURCHASE_ORDER_ITEM_SUCCESS = "DELETE_PURCHASE_ORDER_ITEM_SUCCESS";
export const DELETE_PURCHASE_ORDER_SHIPMENT = "DELETE_PURCHASE_ORDER_SHIPMENT";
export const DELETE_PURCHASE_ORDER_SHIPMENT_SUCCESS = "DELETE_PURCHASE_ORDER_SHIPMENT_SUCCESS";
export const SET_ADJUSTMENT_MODAL = "SET_ADJUSTMENT_MODAL";
export const SET_SHIPMENT_MODAL = "SET_SHIPMENT_MODAL";
export const SET_PO_LOGS_MODAL = "SET_PO_LOGS_MODAL";
export const SET_UPDATE_TENANT_MODAL = "SET_UPDATE_TENANT_MODAL";
export const SET_INVENTORY_CHECKBOXES = "SET_INVENTORY_CHECKBOXES";
export const RESET_FETCH_RATES_ERR = "RESET_FETCH_RATES_ERR";
export const RESET_RATES = "RESET_RATES";
export const BULK_UPDATE_INVENTORY_ITEM = "BULK_UPDATE_INVENTORY_ITEM";
export const BULK_UPDATE_INVENTORY_ITEM_SUCCESS = "BULK_UPDATE_INVENTORY_ITEM_SUCCESS";
export const UPDATE_TRACKING_ITEM_SHIPPPING_STATUS = "UPDATE_TRACKING_ITEM_SHIPPPING_STATUS";
export const UPDATE_TRACKING_ITEM_SHIPPPING_STATUS_SUCCESS = "UPDATE_TRACKING_ITEM_SHIPPPING_STATUS_SUCCESS";
export const DOWNLOAD_WH_LABEL_REPORT = "DOWNLOAD_WH_LABEL_REPORT";
export const DOWNLOAD_WH_LABEL_REPORT_SUCCESS = "DOWNLOAD_WH_LABEL_REPORT_SUCCESS";
export const SET_WH_LABEL_REPORT_MODAL = "SET_WH_LABEL_REPORT_MODAL";

// WH Charges
export const FETCH_WH_CHARGE_HISTORIES = "FETCH_WH_CHARGE_HISTORIES";
export const FETCH_WH_CHARGE_HISTORIES_SUCCESS = "FETCH_WH_CHARGE_HISTORIES_SUCCESS";
export const FETCH_WH_CHARGES_STATUS_STATS = "FETCH_WH_CHARGES_STATUS_STATS";
export const FETCH_WH_CHARGES_STATUS_STATS_SUCCESS = "FETCH_WH_CHARGES_STATUS_STATS_SUCCESS";
export const DOWNLOAD_CHARGES_REPORT = "DOWNLOAD_CHARGES_REPORT";
export const DOWNLOAD_CHARGES_REPORT_SUCCESS = "DOWNLOAD_CHARGES_REPORT_SUCCESS";
export const FETCH_INVOICE_DETAIL = "FETCH_INVOICE_DETAIL";
export const FETCH_INVOICE_DETAIL_SUCCESS = "FETCH_INVOICE_DETAIL_SUCCESS";
export const UPDATE_WH_CHARGE_HISTORY = "UPDATE_WH_CHARGE_HISTORY";
export const UPDATE_WH_CHARGE_HISTORY_SUCCESS = "UPDATE_WH_CHARGE_HISTORY_SUCCESS";

// Inbounds
export const RESET_INBOUND_IS_LIQUATED = "RESET_INBOUND_IS_LIQUATED";
export const FETCH_INBOUND_IS_LIQUATED = "FETCH_INBOUND_IS_LIQUATED";
export const FETCH_INBOUND_IS_LIQUATED_SUCCESS = "FETCH_INBOUND_IS_LIQUATED_SUCCESS";
export const FETCH_INBOUND_ITEMS = "FETCH_INBOUND_ITEMS";
export const FETCH_INBOUND_ITEMS_SUCCESS = "FETCH_INBOUND_ITEMS_SUCCESS";
export const SET_ADD_SHIPMENT_MODEL = "SET_ADD_SHIPMENT_MODEL";
export const SET_RECEIVED_SHIPMENT_MODEL = "SET_RECEIVED_SHIPMENT_MODEL";
export const SET_STORE_BINS_MODEL = "SET_STORE_BINS_MODEL";
export const ADD_SHIPMENT = "ADD_SHIPMENT";
export const ADD_SHIPMENT_SUCCESS = "ADD_SHIPMENT_SUCCESS";
export const SHIPMENT_CHECKED_IN = "SHIPMENT_CHECKED_IN";
export const SHIPMENT_CHECKED_IN_SUCCESS = "SHIPMENT_CHECKED_IN_SUCCESS";
export const RECEIVE_SHIPMENT = "RECEIVE_SHIPMENT";
export const RECEIVE_SHIPMENT_SUCCESS = "RECEIVE_SHIPMENT_SUCCESS";
export const SET_INBOUND_SHIPMENT = "SET_INBOUND_SHIPMENT";
export const FETCH_WH_INBOUND_STATS = "FETCH_WH_INBOUND_STATS";
export const FETCH_WH_INBOUND_STATS_SUCCESS = "FETCH_WH_INBOUND_STATS_SUCCESS";

// Warehouse Invetory items
export const FETCH_WH_INVENTORY = "FETCH_WH_INVENTORY";
export const FETCH_WH_INVENTORY_SUCCESS = "FETCH_WH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_SHIPMENT_WISE = "FETCH_INVENTORY_SHIPMENT_WISE";
export const FETCH_INVENTORY_SHIPMENT_WISE_SUCCESS = "FETCH_INVENTORY_SHIPMENT_WISE_SUCCESS";
export const FETCH_WH_INVENTORY_STATS = "FETCH_WH_INVENTORY_STATS";
export const FETCH_WH_INVENTORY_STATS_SUCCESS = "FETCH_WH_INVENTORY_STATS_SUCCESS";
export const SET_BIN_DETAIL_MODAL = "SET_BIN_DETAIL_MODAL";
export const SET_ITEM_ACTIONS_MODAL = "SET_ITEM_ACTIONS_MODAL";
export const FETCH_WH_INVENTORY_ACTIONS = "FETCH_WH_INVENTORY_ACTIONS";
export const FETCH_WH_INVENTORY_ACTIONS_SUCCESS = "FETCH_WH_INVENTORY_ACTIONS_SUCCESS";
export const UPDATE_INVENTORY_OVERSIZED = "UPDATE_INVENTORY_OVERSIZED";
export const UPDATE_INVENTORY_OVERSIZED_SUCCESS = "UPDATE_INVENTORY_OVERSIZED_SUCCESS";
export const DELETE_WH_INVENTORY_BIN = "DELETE_WH_INVENTORY_BIN";
export const DELETE_WH_INVENTORY_BIN_SUCCESS = "DELETE_WH_INVENTORY_BIN_SUCCESS";
export const UPDATE_WH_INVENTORY_BIN = "UPDATE_WH_INVENTORY_BIN";
export const UPDATE_WH_INVENTORY_BIN_SUCCESS = "UPDATE_WH_INVENTORY_BIN_SUCCESS";
export const CREATE_WH_INVENTORY_BIN = "CREATE_WH_INVENTORY_BIN";
export const CREATE_WH_INVENTORY_BIN_SUCCESS = "CREATE_WH_INVENTORY_BIN_SUCCESS";
export const DELETE_INVENTORY_ITEM = "DELETE_INVENTORY_ITEM";
export const DELETE_INVENTORY_ITEM_SUCCESS = "DELETE_INVENTORY_ITEM_SUCCESS";
export const FETCH_STRANDED_COUNT = "FETCH_STRANDED_COUNT";
export const FETCH_STRANDED_COUNT_SUCCESS = "FETCH_STRANDED_COUNT_SUCCESS";

// Warehouse Dashboard
export const SEARCH_ORDER = "SEARCH_ORDER";
export const SEARCH_ORDER_SUCCESS = "SEARCH_ORDER_SUCCESS";

// Warehouse Outbound items
export const FETCH_WH_OUTBOUNDS = "FETCH_WH_OUTBOUNDS";
export const FETCH_WH_OUTBOUNDS_SUCCESS = "FETCH_WH_OUTBOUNDS_SUCCESS";
export const GENERATE_SHIPPING_LABEL_TOGGLE = "GENERATE_SHIPPING_LABEL_TOGGLE";
export const FETCH_WH_OUTBOUND_STATS = "FETCH_WH_OUTBOUND_STATS";
export const FETCH_WH_OUTBOUND_STATS_SUCCESS = "FETCH_WH_OUTBOUND_STATS_SUCCESS";
export const MARK_AS_COMPLETE_ITEM = "MARK_AS_COMPLETE_ITEM";
export const MARK_AS_COMPLETE_ITEM_SUCCESS = "MARK_AS_COMPLETE_ITEM_SUCCESS";
export const BULK_MARK_AS_COMPLETE = "BULK_MARK_AS_COMPLETE";
export const BULK_MARK_AS_COMPLETE_SUCCESS = "BULK_MARK_AS_COMPLETE_SUCCESS";
export const UPDATE_INVENTORY_ITEM_PRICE = "UPDATE_INVENTORY_ITEM_PRICE";
export const UPDATE_INVENTORY_ITEM_PRICE_SUCCESS = "UPDATE_INVENTORY_ITEM_PRICE_SUCCESS";
export const DOWNLOAD_OUTBOUND_LABEL = "DOWNLOAD_OUTBOUND_LABEL";
export const DOWNLOAD_OUTBOUND_LABEL_SUCCESS = "DOWNLOAD_OUTBOUND_LABEL_SUCCESS";
export const DELETE_OUTBOUND_ITEM = "DELETE_OUTBOUND_ITEM";
export const DELETE_OUTBOUND_ITEM_SUCCESS = "DELETE_OUTBOUND_ITEM_SUCCESS";
export const UPDATE_RETURNS_OUTBOUND = "UPDATE_RETURNS_OUTBOUND";
export const UPDATE_RETURNS_OUTBOUND_SUCCESS = "UPDATE_RETURNS_OUTBOUND_SUCCESS";

// Label Generation Stats
export const FETCH_LABEL_USERS_AND_COUNT = "FETCH_LABEL_USERS_AND_COUNT";
export const FETCH_LABEL_USERS_AND_COUNT_SUCCESS = "FETCH_LABEL_USERS_AND_COUNT_SUCCESS";

// Scanforms
export const FETCH_SHIPPING_SCANFORMS = "FETCH_SHIPPING_SCANFORMS";
export const FETCH_SHIPPING_SCANFORMS_SUCCESS = "FETCH_SHIPPING_SCANFORMS_SUCCESS";
export const SET_GENERATE_SCANFORM_MODAL = "OPEN_GENERATE_SCANFORM_MODAL";
export const GENERATE_SCANFORM = "GENERATE_SCANFORM";
export const GENERATE_SCANFORM_SUCCESS = "GENERATE_SCANFORM_SUCCESS";
export const DOWNLOAD_FORM_FILE = "DOWNLOAD_FORM_FILE";
export const DOWNLOAD_FORM_FILE_SUCCESS = "DOWNLOAD_FORM_FILE_SUCCESS";
export const EMPTY_DOWNLOAD_FORM_FILE = "EMPTY_DOWNLOAD_FORM_FILE";
export const FETCH_SCANFORM_SHIPMENTS = "FETCH_SCANFORM_SHIPMENTS";
export const FETCH_SCANFORM_SHIPMENTS_SUCCESS = "FETCH_SCANFORM_SHIPMENTS_SUCCESS";

export const RESET_SUCCESS_ERROR = "RESET_SUCCESS_ERROR";
export const UPDATE_INVENTORY_ITEMS = "UPDATE_INVENTORY_ITEMS";
