import request from "../restClient";

export const consumeCredit = async (data) => {
  return await request({
    url: "/consume_credits",
    method: "POST",
    body: data,
  });
};

export const deleteCreditLog = async (id) => {
  return await request({
    url: `/credit_logs/${id}`,
    method: "DELETE",
  });
};
