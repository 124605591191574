export const FETCH_RESTRICTED_ITEMS = "FETCH_RESTRICTED_ITEMS";
export const FETCH_RESTRICTED_ITEMS_SUCCESS = "FETCH_RESTRICTED_ITEMS_SUCCESS";
export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";
export const SET_ITEMS_BULK_UPDATE_MODAL = "SET_ITEMS_BULK_UPDATE_MODAL";
export const ITEMS_BULK_UPDATE = "ITEMS_BULK_UPDATE";
export const ITEMS_BULK_UPDATE_SUCCESS = "ITEMS_BULK_UPDATE_SUCCESS";
export const API_ERROR = "FETCH_RESTRICTED_ITEMS_ERROR";
export const ADD_RESTRICTED_ITEMS = "ADD_RESTRICTED_ITEMS";
export const ADD_RESTRICTED_ITEMS_SUCCESS = "ADD_RESTRICTED_ITEMS_SUCCESS";
export const BULK_ADD_RESTRICTED_ITEMS = "BULK_ADD_RESTRICTED_ITEMS";
export const BULK_ADD_RESTRICTED_ITEMS_SUCCESS = "BULK_ADD_RESTRICTED_ITEMS_SUCCESS";
export const DEL_RESTRICTED_ITEMS = "DEL_RESTRICTED_ITEMS";
export const DEL_RESTRICTED_ITEMS_SUCCESS = "DEL_RESTRICTED_ITEMS_SUCCESS";
export const UPDATE_RESTRICTED_ITEMS = "UPDATE_RESTRICTED_ITEMS";
export const UPDATE_RESTRICTED_ITEMS_SUCCESS = "UPDATE_RESTRICTED_ITEMS_SUCCESS";
export const SET_ITEM_EDIT_MODAL = "SET_ITEM_EDIT_MODAL";
export const SET_ITEM_ADD_MODAL = "SET_ITEM_ADD_MODAL";
export const SET_ITEM_BULK_ADD_MODAL = "SET_ITEM_BULK_ADD_MODAL";
export const SELECT_FILE_ERROR = "SELECT_FILE_ERROR";
