import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Row, Button, FormGroup, Badge } from "reactstrap";
import Select, { components } from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShippingTemplate,
  setPreloader,
  syncShippingTemplate,
  updateShippingTemplate,
  deleteTemplate,
  setTemplateModal,
} from "../../../store/actions";
import { toPascalCase, getUserInfo } from "../../../utils/common";

// Component
import AddAmazonTemplateModal from "./AddAmazonTemplateModal";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import getBrandImageSrc from "../../../utils/brandImages";

import { isEmpty } from "lodash";
const { Option } = components;

const ShippingTemplates = (_) => {
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const [storeFront, setStoreFront] = useState({ label: "", value: "", platform: "" });
  const [handlingTime, setHandlingTime] = useState("");
  const [handlingTimeTextBox, setHandlingTimeTextBox] = useState("");
  const { templates, shippingTemplateLoading, updateShippingData } = useSelector((state) => state.Listings);
  const [isOpen, setIsOpen] = useState(false);
  const [update, setTempID] = useState(null);
  const [checkDiffName, setCheckDiffName] = useState(true);
  const [filterTemplates, setFilterTemplates] = useState({});
  const dispatch = useDispatch();

  const templateNames =
    !isEmpty(filterTemplates) &&
    filterTemplates.map(({ shipping_template_uuid: uuId }) => uuId.toLowerCase());

  useEffect(() => {
    if (templates) setFilterTemplates(templates[storeFront?.value]);
  }, [storeFront, templates]);

  useEffect(() => {
    dispatch(fetchShippingTemplate());
  }, [updateShippingData]);

  useEffect(() => {
    dispatch(setPreloader(shippingTemplateLoading));
  }, [shippingTemplateLoading]);

  useEffect(() => {
    const stores = accounts?.data
      ?.filter((activeAcc) => activeAcc.enabled && activeAcc.valid)
      .map((acc) => ({
        label: acc.name,
        value: acc.id,
        platform: acc.marketplace,
      }));
    if (Array.isArray(stores) && !storeFront.value) setStoreFront(stores[0]);
  }, [accounts?.data]);

  const handleAddSampleTemplate = (value) => {
    dispatch(fetchShippingTemplate(value));
  };

  const StoreOption = (props) => (
    <Option {...props}>
      {getBrandImageSrc(props.data.marketplace)
        ? (
        <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
          )
        : null}
      {props.data.label}
    </Option>
  );

  const tableHeadersWalmart = ["Template", "UUID", "Rate Model Type", "Status", "Default"].map((title) => ({ title }));
  const tableHeadersAmazon = ["Template", "Default", "Actions"].map((title) => ({ title }));
  async function myOnClick (templateId) {
    dispatch(
      updateShippingTemplate({
        data: { is_default: true },
        params: templateId,
      }),
    );
  }

  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  return (
    <React.Fragment>
      <AddAmazonTemplateModal platformId={storeFront?.value} />
      {isOpen && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            myOnClick(update);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          To make default this template!
        </SweetAlert>
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Shipping Templates" />
          <Card>
            <CardHeader>
              <Row className="justify-content-between d-flex align-items-center">
                <div className="w-25 mt-2 ml-3">
                  {accounts?.data.filter((x) => x.enabled && x.valid).length > 1 && (
                    <FormGroup className="select2-container" style={{ maxHeight: "100px", width: "100%" }}>
                      <Select
                        theme={theme}
                        name="marketplace_account"
                        value={storeFront}
                        onChange={(e) => {
                          setStoreFront({ label: e.label, value: e.value, platform: e.marketplace });
                          handleAddSampleTemplate(e.value);
                        }}
                        options={accounts?.data.reduce((acc, x) => {
                          if (x.enabled && x.valid)
                            acc.push({
                              label: x.name,
                              value: x.id,
                              marketplace: x.marketplace,
                            });
                          return acc;
                        }, [])}
                        classNamePrefix="select2-selection"
                        components={{ Option: StoreOption }}
                      />
                    </FormGroup>
                  )}
                </div>
                {storeFront?.platform === "walmart"
                  ? (
                      getUserInfo()?.account_id === 2
                        ? (
                    <i
                      title="Sync Shipping Templates"
                      className="bx font-size-24 bx-reset text-primary cursor-pointer mr-3"
                      onClick={() => dispatch(syncShippingTemplate({ partner_id: storeFront.value }))}
                    />
                          )
                        : null
                    )
                  : getUserInfo()?.account_id === 2
                    ? (
                  <Button color="primary" className="mr-3" onClick={() => dispatch(setTemplateModal(true))}>
                    Add template
                  </Button>
                      )
                    : null}
              </Row>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                {filterTemplates?.length ? (
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {/* table headers */}
                        {(storeFront?.platform === "walmart" ? tableHeadersWalmart : tableHeadersAmazon).map(
                          (header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ),
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {filterTemplates?.map((item, i) =>
                        storeFront?.platform === "walmart"
                          ? (
                          <tr key={`_templete_${i}`}>
                            <td>{item.name}</td>
                            <td>{item.shipping_template_uuid}</td>
                            <td>{item.rate_model_type}</td>
                            <td>
                              <Badge className="font-size-14 badge-soft-success" color="success" pill>
                                {toPascalCase(item.status)}
                              </Badge>
                            </td>
                            <td>
                              {!item.is_default
                                ? (
                                <div className="custom-control custom-switch custom-switch-lg mb-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="bce_enabled"
                                    checked={Boolean(item.is_default)}
                                    onChange={() => null}
                                  />
                                  <label
                                    onClick={() => {
                                      setTempID(item.id);
                                      setIsOpen(true);
                                    }}
                                    className="custom-control-label"
                                  ></label>
                                </div>
                                  )
                                : (
                                <p className="mt-3 color-purple">This is Default</p>
                                  )}
                            </td>
                          </tr>
                            )
                          : (
                          <tr key={`_templete_${i}`}>
                            <td className="template-edit-td">
                              {item === handlingTimeTextBox
                                ? (
                                <div className="editableInputTemp d-flex align-items-center">
                                  <FormGroup className="mb-0">
                                    <input
                                      type="text"
                                      value={handlingTime}
                                      className="form-control"
                                      onClick={() => setHandlingTime(item.shipping_template_uuid)}
                                      onChange={(e) => {
                                        templateNames.includes(e.target.value.toLocaleLowerCase())
                                          ? setCheckDiffName(false)
                                          : setCheckDiffName(true);
                                        setHandlingTime(e.target.value);
                                      }}
                                    ></input>
                                  </FormGroup>
                                  {item.shipping_template_uuid.length
                                    ? (
                                    <div className="iconBox d-flex align-items-center">
                                      {handlingTime && checkDiffName
                                        ? (
                                        <i
                                          className="bx bx-sm bx-check color-primary"
                                          onClick={() => {
                                            dispatch(
                                              updateShippingTemplate({
                                                data: { shipping_template_uuid: handlingTime },
                                                params: item.id,
                                              }),
                                            );
                                            setHandlingTime("");
                                          }}
                                        />
                                          )
                                        : null}
                                      <i
                                        className="bx bx-sm bx-x danger"
                                        onClick={() => {
                                          setHandlingTimeTextBox("");
                                          setHandlingTime("");
                                        }}
                                      />
                                      {!checkDiffName && <span className="text-danger">Template already exist!</span>}
                                    </div>
                                      )
                                    : (
                                        ""
                                      )}
                                </div>
                                  )
                                : (
                                <div
                                  className="editable-input"
                                  onClick={() => {
                                    if (handlingTime) setHandlingTime("");
                                    setHandlingTimeTextBox(item);
                                  }}
                                >
                                  {item?.shipping_template_uuid || "N/A"}
                                </div>
                                  )}
                            </td>
                            <td>
                              {!item.is_default
                                ? (
                                <div className="custom-control custom-switch custom-switch-lg mb-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="bce_enabled"
                                    checked={Boolean(item.is_default)}
                                    onChange={() => null}
                                  />
                                  <label
                                    onClick={() => {
                                      setTempID(item.id);
                                      setIsOpen(true);
                                    }}
                                    className="custom-control-label"
                                  ></label>
                                </div>
                                  )
                                : (
                                <p className="mt-3 color-purple">This is Default</p>
                                  )}
                            </td>
                            <td>
                              {!item.is_default
                                ? (
                                <ConfirmDialogIcon
                                  icon={{
                                    title: "Delete Account",
                                    className: "bx bx-sm text-danger bx-trash",
                                  }}
                                  msg={<p>To Delete Template</p>}
                                  onConfirm={() => {
                                    dispatch(deleteTemplate(item.id));
                                  }}
                                />
                                  )
                                : null}
                            </td>
                          </tr>
                            ),
                      )}
                    </tbody>
                  </table>
                ) : (
                  <h4 className="text-center">No Records Found</h4>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShippingTemplates;
