import React, { useEffect } from "react";
import $ from "lodash";
import { RenderIf } from "../../../../utils/common";
import ReactTable from "../../../../components/Common/Table";
import moment from "moment";
import { DATE_FORMATS } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/repricer/actions";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import { Spinner } from "reactstrap";

const ProductLogs = ({ dateRange }) => {
  const { platform, mpAccountId, sku } = useParams();
  const dispatch = useDispatch();
  const { logs, logsLoading } = useSelector((state) => state.Repricer);
  useEffect(() => {
    dispatch(
      actions.fetchRepricerProductLogs({
        platform: platform === "amazon" || platform === "walmart" ? platform : null,
        search: sku,
        account: mpAccountId,
        start_date: dateRange?.[0],
        end_date: dateRange?.[1],
        priceChangeHistory: true,
      }),
    );
  }, [dateRange]);

  const renderContent = ({ data, contentStyle = "" }) => (
    <div className={`d-flex align-items-center ${contentStyle}`}>
      <span className="mx-1 font-size-12">{data}</span>
    </div>
  );

  const tableHeaders = [
    {
      title: "Message",
      Content: ({ data }) => renderContent({ data: data?.message }),
    },
    {
      title: "Date",
      Content: ({ data }) => renderContent({ data: moment(data?.timestamp).format(DATE_FORMATS.FULL_DATE) }),
    },
  ];

  const cols = $.keyBy(tableHeaders, "title");

  return (
    <>
      <Breadcrumb title="Product Logs" />
      <ReactTable
        className="w-100 overflow-auto"
        height={{ maxHeight: "350px" }}
        tableHeader={
          <>
            {$.values(cols).map((header, index) => (
              <th key={`log-table-header-${index}`}>{header.title}</th>
            ))}
          </>
        }
        tableBody={
          <RenderIf
            isTrue={!logsLoading}
            fallback={
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />
                </td>
              </tr>
            }
          >
            <RenderIf
              isTrue={!$.isEmpty(logs?.results)}
              fallback={
                <tr>
                  <td colSpan="100%">
                    <h2 className="text-center">No Logs Found</h2>
                  </td>
                </tr>
              }
            >
              {$.map(logs?.results, (data, key) => (
                <tr key={`log-table-row-${key}`}>
                  {$.values(cols).map(({ Content }, colKey) => (
                    <td key={`log-table-col-${colKey}`}>
                      <Content data={data} index={key} />
                    </td>
                  ))}
                </tr>
              ))}
            </RenderIf>
          </RenderIf>
        }
      />
    </>
  );
};

export default ProductLogs;
