import {
  FETCH_ALL_PLATFORM,
  FETCH_ALL_PLATFORM_SUCCESS,
  UPDATE_PLATFORM,
  UPDATE_PLATFORM_SUCCESS,
  SET_EDIT_PLATFORM_MODAL,
  SET_SELECTED_PLATFORM,
  API_ERROR,
} from "./actionTypes";

export const fetchPlatforms = (params) => {
  return {
    type: FETCH_ALL_PLATFORM,
    payload: { params },
  };
};

export const fetchPlatformsSuccess = (data) => {
  return {
    type: FETCH_ALL_PLATFORM_SUCCESS,
    payload: data,
  };
};

export const updatePlatform = ({ data, id }) => {
  return {
    type: UPDATE_PLATFORM,
    payload: { data, id },
  };
};

export const updatePlatformSuccess = (data) => {
  return {
    type: UPDATE_PLATFORM_SUCCESS,
    payload: data,
  };
};

export const setPlatformEditModal = (bool) => {
  return {
    type: SET_EDIT_PLATFORM_MODAL,
    payload: bool,
  };
};

export const setSelectedPlatform = (platform) => {
  return {
    type: SET_SELECTED_PLATFORM,
    payload: platform,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
