import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { toPascalCase, formatNumber } from "../../../utils/common";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CustomPagination from "../../../components/Common/CustomPagination";
import getBrandImageSrc from "../../../utils/brandImages";

const StoreFrontHealth = () => {
  const { storefrontHealthMatrics } = useSelector((state) => state.Agency);
  const [filters, setFilters] = useState({ page: 1, per_page: 20 });
  const displayedData = storefrontHealthMatrics?.slice(
    (filters.page - 1) * filters.per_page,
    filters.page * filters.per_page,
  );
  const getItem = (item, isLateShipments = false) => (item > 0 ? item + `${isLateShipments ? "" : "%"}` : "N/A");
  const tableHeaders = [
    "Storefronts",
    "New Published Items",
    "BuyBox Percentage",
    "Order Defect Rate",
    "On-Time Delivery Rate",
    "Late Shipments",
    "Cancelation Rate",
  ].map((title) => ({ title }));
  return (
    storefrontHealthMatrics?.length > 0 && (
      <>
        <Breadcrumbs title="STOREFRONT HEALTH MATRICS" />
        <Card>
          <CardBody>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <th key={`table-header ${index}`}>{header.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {displayedData.map((stat, i) => (
                    <tr key={`table-data ${i}`}>
                      <td className="left-0">
                        {getBrandImageSrc(stat.marketplace) && (
                          <img title={stat.name} src={getBrandImageSrc(stat.marketplace)} alt="" />
                        )}
                        {toPascalCase(stat.name)}
                      </td>
                      <td>
                        <div>
                          <span className="d-flex">
                            24 Hr: &nbsp;<h6 className="mt1px">{stat?.last_24_hours || "N/A"}</h6>
                          </span>
                          <span className="d-flex">
                            7 Days: &nbsp;<h6 className="mt1px">{stat.last_7_days || "N/A"}</h6>
                          </span>
                          <span className="d-flex">
                            30 Days: &nbsp;<h6 className="mt1px">{stat.last_30_days || "N/A"}</h6>
                          </span>
                        </div>
                      </td>
                      <td>{stat?.buybox_percentage ? `${formatNumber(+stat?.buybox_percentage, "float")}%` : "N/A"}</td>
                      <td>{getItem(stat.marketplace_accounts_health_stat.cancellation_rate)}</td>
                      <td>{getItem(stat.marketplace_accounts_health_stat.on_time_delivery_rate)}</td>
                      <td>{getItem(stat.marketplace_accounts_health_stat.late_shipments, true)}</td>
                      <td>{getItem(stat.marketplace_accounts_health_stat.on_time_delivery_rate)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <CustomPagination
              total={storefrontHealthMatrics.length}
              pageOptions={[20, 50, 100]}
              page={filters.page}
              perPage={filters.per_page}
              tabsFilter={filters}
              setTabFilter={setFilters}
              inAgencyComponent={true}
            />
          </CardBody>
        </Card>
      </>
    )
  );
};

export default StoreFrontHealth;
