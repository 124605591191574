import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import { Container, Card, CardHeader, CardBody, FormGroup, Alert, Badge } from "reactstrap";
import ShowUpc from "../../../components/Common/ShowUpc";
import Select from "react-select";
import queryString from "query-string";
import {
  getDate,
  getWarehouseName,
  RenderIf,
  toPascalCase,
  getUserInfo,
  toUpper,
  getWHStatus,
} from "../../../utils/common";
import {
  fetchWarehouseAccounts,
  fetchAllWarehouseItems,
  setPreloader,
  setTrackingStatusModal,
  updateBulkItems,
  fetchAllShipments,
  fetchUserWarehouses,
  downloadWarehouseItemsReport,
  resetErrorSuccessStates,
} from "../../../store/actions";
import {
  TRACKING_ITEM_FILTERS,
  USER_ROLES,
  ADMIN_IDS,
  TRACKING_ITEM_ISSUES,
  ORDER_TRACKING_STATUSES,
  TRACKING_ITEMS_STATUSES,
  WH_NOTICES_TYPES,
  STATUS_COLOR_CODE,
  DATE_FORMATS,
} from "../../../constants";
import { Link } from "react-router-dom";
import {
  omit,
  pick,
  merge,
  values,
  map,
  keys,
} from "lodash";

// Components
import NoteTrackingItemModal from "./AddWarehouseNoteModal";
import TrackingStatusModal from "./TrackingStatusModal";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import StatusBadge from "../../../components/Common/StatusBadge";
import Text from "../../Returns/components/Common/Text";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TrackingURL from "../../../components/Common/TrackingUrl";
import CustomPagination from "../../../components/Common/CustomPagination";
import CheckBox from "../../../components/Common/CheckBox";
import PillBtn from "../../../components/Common/PillBtn";
import ItemDetails from "../../Returns/components/Common/ItemDetails";
import TrackingDetails from "../../Returns/components/Common/TrackingDetails";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import AddBinNoModal from "./AddBinNo";
import { DatePicker } from "../../../components/Common/DatePickr";
import VerticalLine from "../../../components/Common/VerticalLine";
import AddReturnModal from "../../Returns/components/Common/AddReturnModal";
const { DATE_TIME } = DATE_FORMATS;
const Col = (props) => (
  <td onClick={props.onClick} className="col p-2 m-0">
    {props.children}
  </td>
);
const Row = (props) => <span className="d-flex flex-row p-0 m-0">{props.children}</span>;

const allStatuses = { value: "", label: "All Tracking Statuses" };
const otherStatuses = { value: "", label: "All Item Statuses" };
const noIssue = { label: "Select Issue Type", value: "" };
const tableCols = ["Order#", "Tracking #", "Customer Address", "Warehouse", "Ship Dates", "Account ID", "Action"];

const TRACKING_ITEM_FILTER = omit(TRACKING_ITEM_FILTERS, getUserInfo()?.isWH ? ["completed"] : []);
const { inbound: inBound, at_warehouse: atWH, shipped: itemShipped } = TRACKING_ITEM_FILTERS;
const datesColMapping = { [inBound]: "Inbound Date", [atWH]: "At Warehouse Date", [itemShipped]: "Shipped Date" };
const WareHouseItems = (_) => {
  const dispatch = useDispatch();
  const isWHStaff = USER_ROLES.whStaff.includes(getUserInfo()?.role);
  const {
    wareHouseItems,
    userWarehouses,
    wareHouseAccounts,
    warehouseNoteModalIsOpen,
    binNoModalIsOpen,
    selectedWarehouseItem,
    loading,
    success,
    trackingStatusModalIsOpen,
    error,
  } = useSelector((state) => state.WareHouses);
  const { account } = useSelector((state) => state.Settings);
  const {
    addReturnModal,
    success: inventorySuccess,
    loading: inventoryLoading,
  } = useSelector((state) => state.Returns);
  const [checkBox, setCheckBox] = useState({});
  const [collapse, setCollapse] = useState();
  const [wareHouseFilter, setWareHouseFilter] = useState({ label: "All Warehouses", value: "" });
  const [issueFilter, setIssueFilter] = useState({ label: "Select Issue Type", value: "" });
  const [search, setSearch] = useState("");
  const isAdmin = getUserInfo()?.role === USER_ROLES.admin;

  const datesColCondiion = ({ value: filter }, status) => {
    status =
      Object.entries(datesColMapping)
        ?.find(([, v]) => status === v)
        ?.shift() ?? status;
    if (!filter) return true;
    if (filter === inBound && status !== inBound) return false;
    if (filter === atWH && ![inBound, atWH].includes(status)) return false;
    return true;
  };

  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20,
    sort_dir: "asc",
    sort_by: "created_at",
    issue_type: noIssue.value,
    wh_filter: { label: toPascalCase("at_warehouse"), value: atWH },
    status: otherStatuses,
  });

  const isAllowed = isAdmin && getUserInfo()?.account_id === 2;
  const tableHeaders = tableCols.map((title) => ({ title }));
  const getTimeDiff = ({ shipped_date: shipDate, delivered_date: deliverDate }) => {
    const getDiff = (unit) => moment(shipDate).diff(moment(deliverDate), unit);
    const days = getDiff("days");
    if (days === 0) return { time: getDiff("hours"), unit: "hour(s)" };
    else return { time: days, unit: `${days === 1 ? "day" : "days"}` };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error, success]);

  const isShowWHCol =
    !isWHStaff || !filters?.wh_filter?.value || filters?.wh_filter?.value === TRACKING_ITEM_FILTER.shipped;

  const query = queryString.parse(document.location.search);
  useEffect(() => {
    if (query.search && filters.search !== query.search) setFilters({ ...filters, search: query.search });
    else if (filters.search) setFilters({ ...filters, search: "" });
  }, [document.location.search]);

  useEffect(() => {
    dispatch(resetErrorSuccessStates());
    if (!isWHStaff && !account?.data?.two_step_feature) return;
    filters.account_id = isAllowed ? filters?.account_id : getUserInfo()?.account_id;
    if (query?.search)
      dispatch(fetchAllWarehouseItems(omit({ ...filters, search: query.search }, ["wh_filter", "status"])));
    else
      dispatch(
        fetchAllWarehouseItems({ ...filters, wh_filter: filters.wh_filter.value, status: filters.status.value }),
      );
    !wareHouseAccounts.length && dispatch(fetchWarehouseAccounts());
  }, [filters, account?.data?.two_step_feature]);

  useEffect(() => {
    dispatch(setPreloader(loading || inventoryLoading));
  }, [loading, inventoryLoading]);

  useEffect(() => {
    dispatch(fetchUserWarehouses());
  }, []);

  useEffect(() => {
    dispatch(fetchAllShipments());
  }, []);

  const OrderDetails = ({ orderId, qty, upc, sourceOrderId, marketplaceStatus }) => {
    return (
      <Col>
        {isWHStaff
          ? (
              orderId
            )
          : (
          <Link to={`/source_orders_details/${orderId}/${sourceOrderId}`} target="_blank">
            {orderId}
          </Link>
            )}
        <RenderIf isTrue={upc?.length}>
          <Row>
            <Text heading="Qty" data={qty} />
            <h6 style={{ marginTop: "2px" }}>UPC: </h6>
            <ShowUpc index={orderId} upc={upc} className={"ml-1"} />
          </Row>
        </RenderIf>
        <Row>
          Store status
          <Badge
            className={"font-size-12 ml-2 badge-soft-" + STATUS_COLOR_CODE[marketplaceStatus]}
            color={STATUS_COLOR_CODE[marketplaceStatus]}
            pill
          >
            {marketplaceStatus}
          </Badge>
        </Row>
      </Col>
    );
  };

  const WHDetails = ({ data }) => (
    <Col>
      <RenderIf isTrue={!isWHStaff}>
        <span className="inventory-color">{getWarehouseName(userWarehouses?.[data?.warehouse_id])}</span>
      </RenderIf>
      <RenderIf isTrue={data.wh_tracking_number}>
        <Row>
          <Text heading="WH Carrier" data={data.wh_tracking_carrier} />
          <StatusBadge status={data.wh_tracking_status} heading="WH Status" />
          <RenderIf
            isTrue={
              values(pick(ORDER_TRACKING_STATUSES, "undeliverable", "cancelled")).includes(data?.wh_tracking_status) &&
              data?.easypost_response
            }
          >
            <CustomTooltip target={`_tracker_response_${data?.id}`} content={data.easypost_response} />
            <i className="bx bx-info-circle mt-1" id={`_tracker_response_${data?.id}`}></i>
          </RenderIf>
        </Row>
        <TrackingURL tracking_num={data.wh_tracking_number} carrier={toUpper(data.wh_tracking_carrier)} />
      </RenderIf>
    </Col>
  );

  const Dates = ({ data }) => {
    const dates = [
      { status: inBound, field: "created_at", heading: "Inbound" },
      { status: atWH, field: "delivered_date", heading: "At Warehouse" },
      { status: itemShipped, field: "shipped_date", heading: "Shipped" },
    ];
    return (
      <Col>
        {dates.map(
          (x, key) =>
            datesColCondiion(filters.wh_filter, x.status) && (
              <div key={"___text__" + key}>
                <Text divClass="" heading={x.heading} data={getDate(data[x.field], DATE_TIME)} />
                {data[x.field] && <br />}
              </div>
            ),
        )}
        {getWHStatus(data) === itemShipped && data?.delivered_date && data?.shipped_date && (
          <p>{`Order stayed ${getTimeDiff(data).time} ${getTimeDiff(data).unit} in Warehouse`}</p>
        )}
      </Col>
    );
  };

  const TrackingItemDetails = ({ data }) => {
    return (
      <>
        <OrderDetails
          sourceOrderId={data.source_order_id}
          orderId={data.order_id}
          qty={data.quantity}
          upc={data.upc}
          marketplaceStatus={data.marketplace_status}
        />
        <TrackingDetails
          trackingNum={data.tracking_number}
          carrier={data.tracking_carrier}
          status={data.tracking_status}
          inventoryId={data.inventory_id}
        />
        <CustomerAddress address={data.customer_address} />
        <RenderIf isTrue={isShowWHCol}>
          <WHDetails data={data} />
        </RenderIf>
        <Dates data={data} />
        {!filters.account_id && <Col>{data.account_id}</Col>}
      </>
    );
  };

  const ActionButtons = ({ item }) => {
    return (
      <td>
        <div className="d-flex align-items-center grid-column-gap-10">
          <i
            title="Expand/Collapse"
            className="mdi mdi-arrow-expand bx-sm text-primary cursor-pointer"
            onClick={() => collapseRow(item.id)}
          />
        </div>
      </td>
    );
  };

  const CustomerAddress = ({ address }) => {
    const formattedAddress = values(pick(address, "address1", "address2", "city", "state", "country", "zipcode"))
      .filter((x) => x)
      .join(", ");
    return (
      <Col>
        <span className="inventory-color">{address?.name || ""}</span>
        <br />
        <span className="mb-1">{formattedAddress}</span>
      </Col>
    );
  };

  const filterHeaders = ({ title }) => {
    if (!title) return false;
    else if (title === "Warehouse") return isShowWHCol;
    else if (title === "Account ID") return !filters.account_id;
    return true;
  };

  const collapseRow = (id) => setCollapse(collapse === id ? "" : id);

  return (
    <React.Fragment>
      <Alert color="warning">
        <i className="bx bx-info-circle pr-2"></i>
        All actions have been removed from this page and have been moved to <Link to="/warehouse_dashboard">New Inventory Flow</Link>.
      </Alert>
      {warehouseNoteModalIsOpen && (
        <NoteTrackingItemModal
          notice_type={WH_NOTICES_TYPES.tracking_items}
          notices={selectedWarehouseItem?.warehouse_notices}
          selectedItem={selectedWarehouseItem}
          warehouses={userWarehouses}
        />
      )}
      {trackingStatusModalIsOpen && (
        <TrackingStatusModal
          isOpen={trackingStatusModalIsOpen}
          toggleIsOpen={setTrackingStatusModal}
          selectedItem={selectedWarehouseItem}
        />
      )}
      {binNoModalIsOpen && (
        <AddBinNoModal selectedItem={selectedWarehouseItem} type={WH_NOTICES_TYPES.tracking_items} />
      )}
      <RenderIf isTrue={addReturnModal}>
        <AddReturnModal />
      </RenderIf>

      <div className="page-content">
        {(success || inventorySuccess) && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success || inventorySuccess}
            </Alert>
          </div>
        )}
        {error && (
          <div className="auto-hide">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          </div>
        )}
        <Container fluid>
          <Breadcrumbs
            title="Warehouse 2 step"
            children={
              <div className="d-flex">
                {keys(checkBox).length > 0 && (
                  <PillBtn
                    className="mr-2 mt-3"
                    style={{ width: "120px", height: "38px", marginTop: "-0.125rem" }}
                    color="primary"
                    title="Mark On Hold"
                    name="Mark On Hold"
                    onClick={() => {
                      dispatch(
                        updateBulkItems(
                          keys(checkBox).map((x) => +x),
                          { status: "on_hold" },
                        ),
                      );
                      setCheckBox({});
                    }}
                  />
                )}
                <RenderIf isTrue={isAdmin}>
                  <i
                    title="Export to XLSX"
                    className="fas fa-file-export bx-md pl-2 text-primary cursor-pointer"
                    onClick={() =>
                      dispatch(
                        downloadWarehouseItemsReport(
                          merge(
                            pick(filters, "warehouse_id", "account_id"),
                            filters.wh_filter.value && { wh_filter: filters.wh_filter.value },
                          ),
                        ),
                      )
                    }
                  ></i>
                </RenderIf>
                {wareHouseAccounts?.length > 0 && isAllowed && (
                  <div style={{ marginTop: "-0.125rem" }}>
                    <SimpleSelect
                      name="account_id"
                      placeholder="All Accounts"
                      className="col mb-0"
                      onChange={(acc) => {
                        const newF = omit(
                          { ...filters, account_id: acc.value, page: 1 },
                          acc.value ? "" : "account_id",
                        );
                        setFilters(newF);
                      }}
                      options={wareHouseAccounts.reduce(
                        (acc, x) => {
                          acc.push({ value: x.id, label: x.email });
                          return acc;
                        },
                        [{ value: "", label: "All Accounts" }],
                      )}
                      formStyle={{ minWidth: "250px", marginBottom: 0 }}
                    />
                  </div>
                )}
              </div>
            }
          />
          <Card>
            <CardHeader>
              <div className="row d-flex justify-content-start">
                <div className="d-flex ml-2">
                  <form
                    className="app-search d-none d-lg-block"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (search.trim()) {
                        setFilters({ ...filters, page: 1, wh_filter: allStatuses, search: search.trim() });
                      }
                    }}
                  >
                    <div className="position-relative mr-2">
                      <input
                        className="form-control"
                        placeholder="Search..."
                        maxLength="40"
                        value={search}
                        onChange={(e) => {
                          if (!e.target.value) {
                            delete filters.search;
                            setSearch("");
                            setFilters({ ...filters });
                          } else setSearch(e.target.value);
                        }}
                      />
                      <span className="bx bx-search-alt"></span>
                    </div>
                  </form>
                </div>
                {wareHouseItems?.stats
                  ? (
                  <div className="d-flex mt-2">
                    <VerticalLine style={{ marginRight: "3px", marginLeft: "5px" }} />
                    {map(wareHouseItems.stats, (count, status) =>
                      ADMIN_IDS.includes(getUserInfo().id) || status !== "completed"
                        ? (
                        <div className="d-flex flex-column p-2" key={`_${status}_`}>
                          <span className="card-text">{toPascalCase(status)}</span>
                          <h6 className="pt-2">{count}</h6>
                        </div>
                          )
                        : null,
                    )}
                    <VerticalLine className="pl-1" />
                  </div>
                    )
                  : null}
                <div className="d-flex align-items-center ml-2">
                  <FormGroup className="select2-container mt-3 pl-2" style={{ minWidth: "165px" }}>
                    <Select
                      name="status"
                      value={{
                        value: filters.status.value,
                        label: filters.status.label,
                      }}
                      onChange={(e) => {
                        if (e.value !== filters.status.value)
                          setFilters({ ...filters, page: 1, status: { label: e.label, value: e.value } });
                      }}
                      options={Object.keys(TRACKING_ITEMS_STATUSES).reduce(
                        (acc, status, i) => {
                          acc.push({
                            label: toPascalCase(status),
                            value: TRACKING_ITEMS_STATUSES[status],
                            key: i,
                          });
                          return acc;
                        },
                        [otherStatuses],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <FormGroup className="select2-container mt-3 pl-2" style={{ minWidth: "190px" }}>
                    <Select
                      name="wh_filter"
                      value={{
                        value: filters.wh_filter.value,
                        label: filters.wh_filter.label,
                      }}
                      onChange={(e) => {
                        if (e.value !== filters.wh_filter.value)
                          setFilters({ ...filters, page: 1, wh_filter: { label: e.label, value: e.value } });
                      }}
                      options={Object.keys(TRACKING_ITEM_FILTER).reduce(
                        (acc, status, i) => {
                          acc.push({
                            label: toPascalCase(status),
                            value: TRACKING_ITEM_FILTER[status],
                            key: i,
                          });
                          return acc;
                        },
                        [allStatuses],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  {!isWHStaff && (
                    <FormGroup className="select2-container mt-3 pl-2" style={{ minWidth: "165px" }}>
                      <Select
                        name="warehouse_id"
                        value={{
                          value: wareHouseFilter.value,
                          label: wareHouseFilter.label,
                        }}
                        onChange={(e) => {
                          setWareHouseFilter({ label: e.label, value: e.value });
                          if (e.value !== filters.warehouse_id) setFilters({ ...filters, warehouse_id: e.value });
                        }}
                        options={Object.keys(userWarehouses || {}).reduce(
                          (acc, x, i) => {
                            acc.push({
                              label: getWarehouseName(userWarehouses[x]),
                              value: userWarehouses[x].id,
                              key: i,
                            });
                            return acc;
                          },
                          [{ value: "", label: "All Warehouses" }],
                        )}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  )}
                  <FormGroup className="select2-container mt-3 pl-2" style={{ minWidth: "200px" }}>
                    <Select
                      name="issue_type"
                      value={issueFilter.value ? issueFilter : noIssue}
                      onChange={(e) => {
                        setIssueFilter(e);
                        if (e.value !== filters.issue_type) setFilters({ ...filters, issue_type: e.value });
                      }}
                      options={Object.values(TRACKING_ITEM_ISSUES).reduce(
                        (acc, status, i) => {
                          acc.push({
                            label: status,
                            value: status,
                            key: i,
                          });
                          return acc;
                        },
                        issueFilter.value
                          ? [
                              { label: "All Issues", value: "all" },
                              { label: "No Issues", value: "" },
                            ]
                          : [],
                      )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </div>
                <DatePicker
                  className="pl-2"
                  style={{ marginTop: "1.1rem" }}
                  onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })}
                />
              </div>
            </CardHeader>
            {Array.isArray(wareHouseItems?.data) && wareHouseItems?.data?.length
              ? (
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>
                          <CheckBox
                            state={keys(checkBox).length === wareHouseItems?.data?.length}
                            setState={(e) => {
                              const { data } = wareHouseItems;
                              if (keys(checkBox).length === data.length) setCheckBox({});
                              else setCheckBox(data?.reduce((acc, cur) => merge(acc, { [cur.id]: true }), {}));
                            }}
                            isSwitch={false}
                          />
                        </th>
                        {tableHeaders.filter(filterHeaders).map((header, index) => (
                          <th key={`table-header ${index}`} onClick={header.onClick}>
                            {header.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {wareHouseItems.data.map((item, key) => (
                        <React.Fragment key={"_warehouse_items_row_" + key}>
                          <tr key={"_ware_houses_items_" + key}>
                            <td>
                              <CheckBox
                                state={checkBox[item.id] || false}
                                setState={(e) => {
                                  if (checkBox[item.id]) delete checkBox[item.id];
                                  else checkBox[item.id] = true;
                                  setCheckBox({ ...checkBox });
                                }}
                                isSwitch={false}
                              />
                            </td>
                            <TrackingItemDetails data={item} index={key} />
                            <ActionButtons item={item} index={key} />
                          </tr>
                          <tr>
                            <td align="center" colSpan="12">
                              <ItemDetails data={item} collapse={collapse} omitFields={["Dimensions"]} />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                {wareHouseItems?.data?.length
                  ? (
                  <CustomPagination
                    total={wareHouseItems.count}
                    pageOptions={[20, 50, 100]}
                    page={filters.page}
                    perPage={filters.per_page}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                    )
                  : null}
              </CardBody>
                )
              : null}
            {wareHouseItems?.data?.length === 0 && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WareHouseItems;
