import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Alert, Button, Col, Label, Row } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import "flatpickr/dist/themes/dark.css";

// actions
import { setUpdateTenantModal, updateBulkInventoryItems } from "../../../store/actions";
import { entries, first, keys, reduce } from "lodash";
import SimpleSelect from "../../../components/Common/SimpleSelect";

const UpdateTenantModal = (props) => {
  const dispatch = useDispatch();
  const { filters, setFilters } = props;
  const { success, error, updateTenantModalIsOpen, inventoryCheckBoxes } = useSelector((state) => state.WareHouses);
  const { allTenants } = useSelector((state) => state.Settings);
  const toggleModal = () => {
    dispatch(setUpdateTenantModal(!updateTenantModalIsOpen));
    formik.resetForm();
  };

  const initialValues = { account: "" };

  const validate = (values) => {
    const errors = {};
    keys(values).forEach((key) => !values[key] && (errors[key] = "Field is Required"));
    return errors;
  };

  const updateProduct = (ids, data) => dispatch(updateBulkInventoryItems(ids, data, { filters, setFilters }));

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      const data = {};
      entries(values).forEach(([key, value]) => value && (data[key] = value));
      updateProduct(keys(inventoryCheckBoxes), { account_id: data.account.value });
    },
  });

  useEffect(() => {
    if (!allTenants.length) return;
    const { id: value, email: label } = first(allTenants);
    formik.setFieldValue("account", { value, label });
  }, [allTenants]);

  return (
    <React.Fragment>
      <Modal size="md" isOpen={updateTenantModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Move to Other User</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        {error && (
          <div className="auto-hide">
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          </div>
        )}

        {success && (
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          </div>
        )}
        <div className="modal-body">
          <form className="m-2 mt-3" onSubmit={formik.handleSubmit}>
            <Col lg="12 mb-2">
              <Label>Choose User</Label>
              <SimpleSelect
                name="account"
                value={formik.values.account || "Select User..."}
                className="col"
                options={reduce(
                  allTenants,
                  (list, acc) => {
                    if (acc.agency_id) list.push({ value: acc.id, label: acc.email });
                    return list;
                  },
                  [],
                )}
                onChange={(e) => formik.setFieldValue("account", e)}
                formStyle={{ minWidth: "250px" }}
              />
              {formik.touched.account && formik.errors.account
                ? (
                <p className="text-danger pt-1">{formik.errors.account}</p>
                  )
                : null}
            </Col>
          </form>
          <Row className="d-flex justify-content-end mt-3 mr-3">
            <Button
              type="reset"
              color="primary"
              onClick={toggleModal}
              outline
              className="btn btn-outline-primary waves-effect waves-light m-1"
            >
              Cancel
            </Button>
            <Button type="submit" onClick={formik.handleSubmit} color="primary" className="btn btn-primary m-1">
              Submit
            </Button>
          </Row>
        </div>

        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateTenantModal;
