import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useFormik } from "formik";
import {
  Alert,
  Row,
  Col,
  Label,
  Input,
  Button,
  CardBody,
  Card,
  FormGroup,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "react-toggle/style.css";
import Modal from "../../../components/Common/Modal";
import CheckBox from "../../../components/Common/CheckBox";
import { keys, merge, omit, pick, uniqBy, map } from "lodash";
import {
  MATCHING_SYSTEM_SUPPLIERS,
  MATCHES_REQ_WEIGHT_SUPPORT,
  MATCHING_REQ_TYPES,
  DATE_FORMATS,
} from "../../../constants/index";
import { RenderIf, getUserInfo, toPascalCase } from "../../../utils/common";
import classnames from "classnames";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
// actions
import {
  setPreloader,
  setAddAgencyModal,
  fetchAvailableMatchCounts,
  submitMatchesRequest,
  resetAvailableMatches,
} from "../../../store/actions";

import { apiError } from "../../../store/settings/actions";

const { DATE_PICKR } = DATE_FORMATS;

const RequestFiftyModal = (props) => {
  const dispatch = useDispatch();
  const { selectedTenantAcc, availableMatches, loading } = useSelector((state) => state.Settings);
  const { accounts: mattAccount, userAccounts } = useSelector((state) => state.MarketPlaceAccount);
  const accounts = selectedTenantAcc.id === 2 ? mattAccount : userAccounts;
  const uniqueMarketPlaces = uniqBy(accounts?.data, (a) => a.marketplace).map((x) => x.marketplace);
  const fpStartDateRef = useRef(null);
  const fpEndDateRef = useRef(null);
  const MATCHES_TYPES = {
    normal: "normal",
  };

  const tabs = [
    {
      title: "Walmart",
      id: 0,
      platform: "walmart",
    },
    {
      title: "Amazon",
      id: 1,
      platform: "amazon",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const initPlatforms = activeTab
    ? map(["farmandfleet", "fleetfarm", "walgreens"], (x) => ({ label: toPascalCase(x), value: x }))
    : [];

  useEffect(() => {
    if (uniqueMarketPlaces?.length === 1) {
      uniqueMarketPlaces[0] === "walmart" && setActiveTab(0);
      uniqueMarketPlaces[0] === "amazon" && setActiveTab(1);
    }
  });

  useEffect(() => {
    if (props.Settings.error) return document.getElementsByClassName("modal-header")[0].scrollIntoView();

    dispatch(setPreloader(loading));
  }, [loading, props.Settings.error]);

  const validate = (values) => {
    const errors = {};
    ["email", "requested", "source_platform"].forEach((key) => !values[key] && (errors[key] = "Field is Required"));
    [
      { title: "Min Weight", key: "minWeight" },
      { title: "Max Weight", key: "maxWeight" },
    ].forEach((x) => values[x.key] && values[x.key] <= 0 && (errors[x.key] = `${x.title} must be a positive number`));

    [
      { title: "Min Sales Rank", key: "minSalesRank" },
      { title: "Max Sales Rank", key: "maxSalesRank" },
      { title: "Min Price", key: "minPrice" },
      { title: "Max Price", key: "maxPrice" },
      { title: "Min Reviews Count", key: "minReviewsCount" },
      { title: "Max Reviews Count", key: "maxReviewsCount" },
      { title: "Matches Requests", key: "requested" },
    ].forEach(
      (x) =>
        [undefined, ""].every((val) => values[x.key] !== val) &&
        values[x.key] <= 0 &&
        (errors[x.key] = `${x.title} must be greater than 0`),
    );
    if (availableMatches?.matches < values.requested)
      errors.requested = "Requested Matches must be less than equal to Available Matches";

    // if requestType is matches then store is required
    if (values.request_type?.value === "matches" && !values.store) errors.store = "Field is Required";

    if (
      (values?.minSalesRank && values?.maxSalesRank && +values.maxSalesRank < +values.minSalesRank) ||
      (values?.minSalesRank && values?.maxSalesRank === 0)
    )
      errors.salesWeight = "Min Sales Rank must be less than Max Sales Rank";

    if (
      (values?.minPrice && values?.maxPrice && +values.maxPrice < +values.minPrice) ||
      (values?.minPrice && values?.maxPrice === 0)
    )
      errors.salesPrice = "Min Price Rank must be less than Max Price Rank";

    if (
      (values?.minReviewsCount && values?.maxReviewsCount && +values.maxReviewsCount < +values.minReviewsCount) ||
      (values?.minReviewsCount && values?.maxReviewsCount === 0)
    )
      errors.reviewsCount = "Min Reviews Count must be less than Max Reviews Count";

    if (
      (values.minWeight && values.maxWeight && +values.maxWeight < +values.minWeight) ||
      (values.minWeight && values.maxWeight === 0)
    )
      errors.weight = "Min weight must be less than Max weight";
    if (values.startDate && values.endDate && values.startDate > values.endDate)
      errors.date = "Start Date must be less than End Date";

    return errors;
  };

  const initialValues = {
    email: selectedTenantAcc.email,
    store: "",
    requested: "",
    source_platform: "",
    is_two_step: false,
    isSourceOnly: false,
    minWeight: "",
    maxWeight: "",
    maxSalesRank: "",
    minSalesRank: "",
    minPrice: "",
    maxPrice: "",
    minReviewsCount: "",
    maxReviewsCount: "",
    startDate: "",
    endDate: "",
    inStock: false,
    isVerified: true,
    brand: "",
    request_type: { label: "Request Matches", value: "matches" },
    matches_request_type: { label: "normal", value: "normal" },
  };
  const filtersArr = [
    "minWeight",
    "maxWeight",
    "minSalesRank",
    "maxSalesRank",
    "minPrice",
    "maxPrice",
    "minReviewsCount",
    "maxReviewsCount",
    "inStock",
    "startDate",
    "endDate",
    "isSourceOnly",
    "isVerified",
    "brand",
  ];

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      let data = omit(values, filtersArr);
      const obj = {
        ...data,
        source_platform: data.source_platform.value,
        request_type: data.request_type.value,
        filters: filtersArr.reduce((obj, x) => merge(obj, formik.values[x] && { [x]: formik.values[x] }), {}),
      };
      if (data.store.value) obj.partner_id = data.store.value;
      if (activeTab === 0) data = { ...obj, matches_type: "walmart_matches" };
      else data = { ...obj, matches_type: "amazon_matches" };
      if (values.request_type?.value === "matches") data.matches_request_type = values.matches_request_type.value;
      else delete data.matches_request_type;
      delete data.store;
      data.va_id = getUserInfo()?.id;
      dispatch(submitMatchesRequest(data));
    },
  });

  useEffect(() => {
    const { salesRank, minWeight, maxWeight, startDate, endDate, minPrice, maxPrice, minReviewsCount, maxReviewsCount } = formik.values;
    if (
      (salesRank < 1 && salesRank !== "") ||
      (minWeight && maxWeight && minWeight > maxWeight) ||
      (minPrice && maxPrice && minPrice > maxPrice) ||
      (minReviewsCount && maxReviewsCount && minReviewsCount > maxReviewsCount) ||
      (startDate && endDate && startDate > endDate)
    )
      return;
    const timeoutRef = setTimeout(() => {
      handlefetchAvailableCounts({
        source_platform: formik.values.source_platform.value,
        ...pick(formik.values, filtersArr),
      });
    }, 1500);
    return () => clearTimeout(timeoutRef);
  }, [
    formik.values.minWeight,
    formik.values.maxWeight,
    formik.values.salesRank,
    formik.values.startDate,
    formik.values.endDate,
    formik.values.minPrice,
    formik.values.maxPrice,
    formik.values.minReviewsCount,
    formik.values.maxReviewsCount,
  ]);

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      formik.resetForm();
      dispatch(resetAvailableMatches());
    }
  };

  const toggleModal = () => {
    props.setAddAgencyModal(!props.Settings.isAddAgencyModalOpen);
    dispatch(resetAvailableMatches());
    formik.resetForm();
  };

  const handlefetchAvailableCounts = (query) => {
    if (!query.source_platform) {
      formik.setErrors({ ...formik.errors, source_platform: "Field is required" });
      return;
    }
    const q = [{ key: "userId", value: null, operator: "=" }];

    const params = {
      q,
      source_platform: query.source_platform,
      marketplace: tabs[activeTab].platform,
      filters: filtersArr.reduce((obj, x) => merge(obj, query[x] && { [x]: query[x] }), {}),
    };
    dispatch(fetchAvailableMatchCounts(params));
  };

  return (
    <Modal size="md" isOpen={props.Settings.isAddAgencyModalOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          REQUEST FOR MATCHES
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <Card className="mb-0">
        <CardBody>
          <Nav tabs className="nav-tabs-custom nav-justified">
            {tabs
              .filter((x) => uniqueMarketPlaces.includes(x.platform))
              .map((tab, index) => (
                <NavItem key={`tab-${index}`}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === tab.id,
                    })}
                    onClick={() => {
                      if (activeTab !== tab.id) {
                        toggleActiveTab(tab.id);
                        dispatch(apiError(""));
                      }
                    }}
                  >
                    <span className="d-none d-sm-block">{tab.title}</span>
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
        </CardBody>
      </Card>
      <div className="modal-body">
        {props.Settings.error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Settings.error}
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <Label>Email*</Label>
              <Input name="email" type="email" readOnly value={formik.values.email} />
            </Col>
            <Col lg="12">
              <Label>Request Type</Label>
              <FormGroup className="select2-container w-100">
                <Select
                  name="request_type"
                  value={formik.values.request_type}
                  onChange={(e) => {
                    formik.resetForm();
                    formik.setFieldValue("request_type", e);
                  }}
                  options={keys(MATCHING_REQ_TYPES).reduce((arr, x, i) => {
                    arr.push({ label: MATCHING_REQ_TYPES[x], value: x, key: i });
                    return arr;
                  }, [])}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
            {(() => {
              const accountDropdown = accounts?.data?.reduce((list, acc, i) => {
                if (acc.valid && acc.enabled && acc.marketplace === tabs[activeTab].platform)
                  list.push({ label: acc.name, value: acc.id, key: i });
                return list;
              }, []);
              if (!accountDropdown?.length || formik.values?.request_type?.value !== "matches") return null;
              return (
                <Col lg="12">
                  <Label>Marketplace Account</Label>
                  <FormGroup className="select2-container w-100">
                    <Select
                      name="store"
                      value={formik.values.store}
                      onChange={(e) => formik.setFieldValue("store", e)}
                      options={accountDropdown}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <RenderIf isTrue={formik.touched.store && formik.errors.store}>
                    <small className="text-danger" style={{ position: "absolute", bottom: "-2px" }}>
                      {formik.errors.store}
                    </small>
                  </RenderIf>
                </Col>
              );
            })()}
            {formik.values?.request_type?.value === "matches" && (
              <Col lg="12 mt-2">
                <Label>Matches Request Type</Label>
                <FormGroup className="select2-container w-100">
                  <Select
                    name="matches_request_type"
                    value={formik.values.matches_request_type}
                    onChange={(e) => formik.setFieldValue("matches_request_type", e)}
                    options={Object.keys(MATCHES_TYPES).reduce((init, acc, i) => {
                      init.push({ label: toPascalCase(acc), value: MATCHES_TYPES[acc], key: i });
                      return init;
                    }, [])}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
            )}
            <Col lg="12 mb-2 hide-arrows">
              <Label>Requested Matches</Label>
              <Input
                name="requested"
                type="number"
                invalid={formik.touched.requested && !RegExp("^[0-9]+$").test(formik.values.requested)}
                min={1}
                value={formik.values.requested}
                onChange={(e) => {
                  if (!Number.isInteger(+e.target.value)) return e.preventDefault();
                  formik.handleChange(e);
                }}
                onKeyPress={(e) => {
                  if (["-", "."].some((x) => e.key === x)) e.preventDefault();
                }}
              />
              <FormFeedback>Only Positive Numbers are allowed</FormFeedback>
              <RenderIf isTrue={formik.touched.requested && formik.errors.requested}>
                <small className="text-danger mt-1">{formik.errors.requested}</small>
              </RenderIf>
            </Col>

            {formik.values?.request_type?.value === "matches" && (
              <Col lg="12" className="d-flex">
                <Label>
                  <span>2-Step</span>
                </Label>
                <CheckBox
                  className="ml-3"
                  name="is_two_step"
                  state={formik.values.is_two_step}
                  isSwitch={true}
                  setState={(state) => formik.setFieldValue("is_two_step", state)}
                />
              </Col>
            )}

            {formik.values?.request_type?.value === "refresh" && (
              <Col lg="12" className="d-flex">
                <Label>
                  <span>Refresh Source Only</span>
                </Label>
                <CheckBox
                  className="ml-3"
                  name="isSourceOnly"
                  state={formik.values.isSourceOnly}
                  isSwitch={true}
                  setState={(state) => formik.setFieldValue("isSourceOnly", state)}
                />
              </Col>
            )}

            <Col>
              <br />
              <h5>
                <Label>Filters</Label>
              </h5>
            </Col>

            <Col lg="12">
              <Label>Source Platform</Label>
              <FormGroup className="select2-container w-100">
                <Select
                  name="source_platform"
                  value={formik.values.source_platform}
                  onChange={(e) => {
                    formik.setFieldValue("source_platform", e);
                    handlefetchAvailableCounts({ source_platform: e.value, ...pick(formik.values, filtersArr) });
                  }}
                  options={Object.keys(MATCHING_SYSTEM_SUPPLIERS)
                    .filter((x) => x !== tabs[activeTab].platform)
                    .reduce((init, acc, i) => {
                      init.push({
                        label: acc === "plumberstock" ? toPascalCase(MATCHING_SYSTEM_SUPPLIERS[acc]) : toPascalCase(acc),
                        value: acc,
                        key: i,
                      });
                      return init;
                    }, initPlatforms)}
                  classNamePrefix="select2-selection"
                />
                <RenderIf isTrue={formik.touched.source_platform && formik.errors.source_platform}>
                  <small className="text-danger m-1">{formik.errors.source_platform}</small>
                </RenderIf>
              </FormGroup>
            </Col>
            <Col lg="12 mb-2">
              <Label>Brand</Label>
              <Input
                name="brand"
                value={formik.values.brand}
                onChange={(e) => {
                  formik.setFieldValue("brand", e.target.value);
                }}
                onBlur={() => {
                  handlefetchAvailableCounts({
                    source_platform: formik.values.source_platform.value,
                    ...merge(pick(formik.values, filtersArr), { brand: formik.values.brand }),
                  });
                }}
              />
            </Col>
            <>
              {formik.values?.request_type?.value === "refresh" && (
                <Col lg="12" className="flex-column mb-3 ">
                  <Label>{"Select Date Range"}</Label>
                  <div className={"d-flex hide-arrows align-items-center"}>
                    {[
                      { name: "startDate", label: "Start Date", margin_type: "l", key: "start", ref: fpStartDateRef },
                      { name: "endDate", label: "End Date", margin_type: "r", key: "end", ref: fpEndDateRef },
                    ].map((x) => (
                      <>
                        <Flatpickr
                          ref={x.ref}
                          id={`${x.name}Pickr`}
                          key={x.key}
                          className={`m${x.margin_type} form-control`}
                          placeholder={x.label}
                          value={x.name}
                          options={{
                            mode: "single",
                            dateFormat: DATE_PICKR,
                          }}
                          onChange={(e) => {
                            if (e.length) formik.setFieldValue(x.name, e[0]);
                          }}
                        />

                        <i
                          key={`${x.key}_clear`}
                          title="Reset date filter"
                          className="bx bx-sm mdi mdi-close pt-1 text-danger cursor-pointer"
                          onClick={() => {
                            formik.setFieldValue(x.name, null);
                            if (x.ref.current.flatpickr) x.ref.current.flatpickr.clear();
                          }}
                        />
                      </>
                    ))}
                  </div>
                  <RenderIf isTrue={formik.errors.date}>
                    <small className="text-danger m-1">{formik.errors.date}</small>
                  </RenderIf>
                </Col>
              )}
              {[tabs[activeTab].platform, formik.values.source_platform.value].some((x) =>
                MATCHES_REQ_WEIGHT_SUPPORT.includes(x),
              ) && (
                <Col lg="12" className="flex-column mb-3 ">
                  <Label>{"Weight(lbs)"}</Label>
                  <div className="d-flex hide-arrows align-items-center">
                    {[
                      { name: "min", label: "Min", margin_type: "r", key: "minWeight" },
                      { name: "max", label: "Max", margin_type: "l", key: "maxWeight" },
                    ]
                      .map((x, key) => (
                        <div key={"__weight__" + key}>
                          <Input
                            type="number"
                            placeholder={x.label}
                            className={`m${x.margin_type}-2`}
                            name={x.key}
                            value={formik.values[x.key]}
                            onKeyPress={(e) => {
                              if (e.key === "-") e.preventDefault();
                              if (+e.target.value >= 100000) e.preventDefault();
                            }}
                            onChange={formik.handleChange}
                          />
                          <RenderIf isTrue={formik.errors[x.key]}>
                            <small className="text-danger m-1">{formik.errors[x.key]}</small>
                          </RenderIf>
                        </div>
                      ))
                      .reduce((prev, curr, key) => [prev, <span key={"__span__" + key}>{"-"}</span>, curr])}
                  </div>
                  <RenderIf isTrue={formik.errors.weight}>
                    <small className="text-danger m-1">{formik.errors.weight}</small>
                  </RenderIf>
                </Col>
              )}
              <Col lg="12" className="flex-column mb-3 ">
                <Label>{"Price Rank"}</Label>
                <div className="d-flex hide-arrows align-items-center">
                  {[
                    { name: "min", label: "Min", margin_type: "r", key: "minPrice" },
                    { name: "max", label: "Max", margin_type: "l", key: "maxPrice" },
                  ]
                    .map((x, key) => (
                      <div key={"__weight__" + key}>
                        <Input
                          type="number"
                          placeholder={x.label}
                          className={`m${x.margin_type}-2`}
                          name={x.key}
                          value={formik.values[x.key]}
                          onKeyPress={(e) => {
                            if (e.key === "-") e.preventDefault();
                            if (+e.target.value >= 100000) e.preventDefault();
                          }}
                          onChange={formik.handleChange}
                        />
                        <RenderIf isTrue={formik.errors[x.key]}>
                          <small className="text-danger m-1">{formik.errors[x.key]}</small>
                        </RenderIf>
                      </div>
                    ))
                    .reduce((prev, curr, key) => [prev, <span key={"__span__" + key}>{"-"}</span>, curr])}
                </div>
                <RenderIf isTrue={formik.errors.salesPrice}>
                  <small className="text-danger m-1">{formik.errors.salesPrice}</small>
                </RenderIf>
              </Col>
              <Col lg="12" className="flex-column mb-3 ">
                <Label>{"Reviews Count"}</Label>
                <div className="d-flex hide-arrows align-items-center">
                  {[
                    { name: "min", label: "Min", margin_type: "r", key: "minReviewsCount" },
                    { name: "max", label: "Max", margin_type: "l", key: "maxReviewsCount" },
                  ]
                    .map((x, key) => (
                      <div key={"__weight__" + key}>
                        <Input
                          type="number"
                          placeholder={x.label}
                          className={`m${x.margin_type}-2`}
                          name={x.key}
                          value={formik.values[x.key]}
                          onKeyPress={(e) => {
                            if (e.key === "-") e.preventDefault();
                            if (+e.target.value >= 100000) e.preventDefault();
                          }}
                          onChange={formik.handleChange}
                        />
                        <RenderIf isTrue={formik.errors[x.key]}>
                          <small className="text-danger m-1">{formik.errors[x.key]}</small>
                        </RenderIf>
                      </div>
                    ))
                    .reduce((prev, curr, key) => [prev, <span key={"__span__" + key}>{"-"}</span>, curr])}
                </div>
                <RenderIf isTrue={formik.errors.reviewsCount}>
                  <small className="text-danger m-1">{formik.errors.reviewsCount}</small>
                </RenderIf>
              </Col>

              {[tabs[activeTab].platform, formik.values.source_platform.value].some((x) => x === "amazon") && (
                <Col lg="12" className="flex-column mb-3 ">
                  <Label>{"Sales Rank(BSR)"}</Label>
                  <div className="d-flex hide-arrows align-items-center">
                    {[
                      { name: "min", label: "Min", margin_type: "r", key: "minSalesRank" },
                      { name: "max", label: "Max", margin_type: "l", key: "maxSalesRank" },
                    ]
                      .map((x, key) => (
                        <div key={"__weight__" + key}>
                          <Input
                            type="number"
                            placeholder={x.label}
                            className={`m${x.margin_type}-2`}
                            name={x.key}
                            value={formik.values[x.key]}
                            onKeyPress={(e) => {
                              if (e.key === "-") e.preventDefault();
                              if (+e.target.value >= 100000) e.preventDefault();
                            }}
                            onChange={formik.handleChange}
                          />
                          <RenderIf isTrue={formik.errors[x.key]}>
                            <small className="text-danger m-1">{formik.errors[x.key]}</small>
                          </RenderIf>
                        </div>
                      ))
                      .reduce((prev, curr, key) => [prev, <span key={"__span__" + key}>{"-"}</span>, curr])}
                  </div>
                  <RenderIf isTrue={formik.errors.salesWeight}>
                    <small className="text-danger m-1">{formik.errors.salesWeight}</small>
                  </RenderIf>
                </Col>
              )}

              {[
                { label: "Verified Matches", filter: "verified", value: true },
                { label: "Unverified Matches", filter: "unverified", value: false },
              ].map((x, key) => (
                <Col lg="12" className="d-flex" key={key}>
                  <div className="form-check form-check-inline  d-flex grid-column-gap-10 ">
                    <Label className="form-check-label" for={`id_${x.filter}`}>
                      {x.label}
                    </Label>{" "}
                    <input
                      className="form-check-input"
                      type="radio"
                      key={`_${key}_`}
                      name="isVerified"
                      value={x.filter}
                      checked={x.value ? formik.values.isVerified : !formik.values.isVerified}
                      onClick={() => {
                        formik.setFieldValue("isVerified", x.value);
                        handlefetchAvailableCounts({
                          source_platform: formik.values.source_platform.value,
                          ...merge(pick(formik.values, filtersArr), { isVerified: x.value }),
                        });
                      }}
                      onChange={() => null}
                    />
                  </div>
                </Col>
              ))}

              {formik.values?.request_type?.value === "matches" && (
                <Col lg="12" className="d-flex mt-3">
                  <Label>In Stock Only</Label>{" "}
                  <CheckBox
                    className="ml-3"
                    name="inStock"
                    state={formik.values.inStock}
                    setState={(state) => {
                      formik.setFieldValue("inStock", state);
                      handlefetchAvailableCounts({
                        source_platform: formik.values.source_platform.value,
                        ...merge(pick(formik.values, filtersArr), { inStock: state }),
                      });
                    }}
                  />
                </Col>
              )}
            </>

            <Col lg="12" className="mb-2">
              <span className="text-primary">Available Matches: {availableMatches?.matches || 0}</span>
              <i
                title="Refresh Available Matches"
                className="bx bx-reset cursor-pointer mx-1 text-primary"
                onClick={() =>
                  handlefetchAvailableCounts({
                    source_platform: formik.values.source_platform.value,
                    ...pick(formik.values, filtersArr),
                  })
                }
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3" disabled={!(availableMatches?.matches || true)}>
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { Settings } = state;
  return { Settings };
};

export default connect(mapStateToProps, {
  setAddAgencyModal,
})(RequestFiftyModal);
