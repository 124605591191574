import React from "react";

const BlurCard = (props) => {
  const { height, children, top } = props;
  return (
  <div
    className="rounded"
    style={{
      position: "absolute",
      backgroundColor: "rgba(0,0,0,0.1)",
      width: "99%",
      height: height || "80%",
      zIndex: "1",
      backdropFilter: "blur(3px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: top || "82px",
      left: "0.5%",
    }}
  >
    <div
      className="rounded font-size-16 inventory-color"
      style={{
        position: "absolute",
        height: "136px",
        zIndex: "2",
        backdropFilter: "blur(3px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "0px 20px",
      }}
    >
      <i className="bx bx-sm bx-lock"></i>
      {children}
    </div>
  </div>
  );
};

export default BlurCard;
