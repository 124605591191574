export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const EXPIRE_SESSION = "EXPIRE_SESSION";
export const EXPIRE_SESSION_SUCCESS = "EXPIRE_SESSION_SUCCESS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const SET_EDIT_USER_MODAL = "SET_EDIT_USER_MODAL";
export const SET_ADD_USER_MODAL = "SET_ADD_USER_MODAL";
export const SET_SELECTED_USER_MODAL = "SET_SELECTED_USER_MODAL";
export const SET_USER_INVITE_MODAL = "SET_USER_INVITE_MODAL";
export const SET_AGENCY_ACCOUNT_MODAL = "SET_AGENCY_ACCOUNT_MODAL";
export const ASSIGN_AGENCY_ACCOUNTS_TO_USERS = "ASSIGN_AGENCY_ACCOUNTS_TO_USERS";
export const ASSIGN_AGENCY_ACCOUNTS_TO_USERS_SUCCESS = "ASSIGN_AGENCY_ACCOUNTS_TO_USERS_SUCCESS";
export const ASSIGN_AGENCIES = "ASSIGN_AGENCIES";
export const ASSIGN_AGENCIES_SUCCESS = "ASSIGN_AGENCIES_SUCCESS";
export const SEND_USER_INVITE = "SEND_USER_INVITE";
export const SEND_USER_INVITE_SUCCESS = "SEND_USER_INVITE_SUCCESS";
export const FETCH_ALL_ACCOUNTS = "FETCH_ALL_ACCOUNTS";
export const FETCH_ALL_ACCOUNTS_SUCCESS = "FETCH_ALL_ACCOUNTS_SUCCESS";
export const COMPLETE_USER_ONBOARDING = "COMPLETE_USER_ONBOARDING";
export const COMPLETE_USER_ONBOARDING_SUCCESS = "COMPLETE_USER_ONBOARDING_SUCCESS";
export const API_ERROR = "USER_API_ERROR";
export const SET_CHANGE_PASSWORD_MODEL = "SET_CHANGE_PASSWORD_MODEL";
export const SET_MANAGE_AGENCIES_MODAL = "SET_MANAGE_AGENCIES_MODAL";
export const UPDATE_PROFILE_AND_COMPANY_INFO = "UPDATE_PROFILE_AND_COMPANY_INFO";
export const UPDATE_PROFILE_AND_COMPANY_INFO_SUCCESS = "UPDATE_PROFILE_AND_COMPANY_INFO_SUCCESS";
export const FETCH_COMPANY_INFO = "FETCH_COMPANY_INFO";
export const FETCH_COMPANY_INFO_SUCCESS = "FETCH_COMPANY_INFO_SUCCESS";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const SET_ADD_BALANCE_MODAL = "  SET_ADD_BALANCE_MODAL";
export const FETCH_DISPUTE_LOGS = "FETCH_DISPUTE_LOGS";
export const FETCH_DISPUTE_LOGS_SUCCESS = "FETCH_DISPUTE_LOGS_SUCCESS";
export const SET_TOS_ACCEPTANCE_MODAL = "SET_TOS_ACCEPTANCE_MODAL";
export const UPDATE_TOS_BIT = "UPDATE_TOS_BIT";
export const UPDATE_TOS_BIT_SUCCESS = "UPDATE_TOS_BIT_SUCCESS";
export const UPDATE_TOS_FOR_ALL = "UPDATE_TOS_FOR_ALL";
export const UPDATE_TOS_FOR_ALL_SUCCESS = " UPDATE_TOS_FOR_ALL_SUCCESS";
export const GET_AGENCY_CLIENTS = "GET_AGENCY_CLIENTS";
export const GET_AGENCY_CLIENTS_SUCCESS = "GET_AGENCY_CLIENTS_SUCCESS";
