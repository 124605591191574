import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getAllSharedListings, sendInvoiceEmail } from "../../api";
// Login Redux States
import { FETCH_SHARED_LISTING_DATA, SEND_INVOICE_EMAIL } from "./actionTypes";

import { apiError, sendInvoiceEmailSuccess, fetchSharedListingDataSuccess } from "./actions";

function * fetchSharedListingsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllSharedListings, params);
    yield put(fetchSharedListingDataSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * sendInvoiceEmailSaga ({ payload }) {
  try {
    const res = yield call(sendInvoiceEmail, payload);
    yield put(sendInvoiceEmailSuccess(res?.message));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchSharedListingsSaga () {
  yield takeEvery(FETCH_SHARED_LISTING_DATA, fetchSharedListingsSaga);
}

export function * watchSendInvoiceEmailSaga () {
  yield takeEvery(SEND_INVOICE_EMAIL, sendInvoiceEmailSaga);
}

function * feedSaga () {
  yield all([fork(watchSharedListingsSaga), fork(watchSendInvoiceEmailSaga)]);
}

export default feedSaga;
