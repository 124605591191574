import React from "react";

const HeaderBanner = (props) => {
  return (
    <div className='header-banner mb-4 p-4'>
      {props.children}
    </div>
  );
};

export default HeaderBanner;
