import React, { useEffect } from "react";
import Modal from "../../../components/Common/Modal";
import PillBtn from "../../../components/Common/PillBtn";
import ReactTable from "../../../components/Common/Table";
import { RenderIf } from "../../../utils/common";
import * as $ from "lodash";
import { Spinner, Alert } from "reactstrap";
import moment from "moment";
import { DATE_FORMATS } from "../../../constants";
import * as actions from "../../../store/repricer/actions";
import { useDispatch, useSelector } from "react-redux";

const LogsModal = ({ isOpen, toggleModal, modelType, modelId, platform }) => {
  const dispatch = useDispatch();
  const { logs, historyLogsLoading, error } = useSelector((state) => state.Repricer);

  useEffect(() => {
    dispatch(actions.fetchHistoryLogs({ model_type: modelType, platform }, modelId));
  }, []);

  const tableHeaders = [
    {
      title: "Message",
      Content: ({ data }) => (
        <div
          className="d-flex align-items-center"
          style={{ wordWrap: "break-word", whiteSpace: "normal" }}
        >
          <span className="mx-1 font-size-12">{data.message}</span>
        </div>
      ),
    },
    {
      title: "Date",
      Content: ({ data }) => (
        <div className="d-flex align-items-center">
          <span className="mx-1 font-size-12">{moment(data.timestamp).format(DATE_FORMATS.FULL_DATE)}</span>
        </div>
      ),
    },
  ];

  const cols = $.keyBy(tableHeaders, "title");

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggleModal}>
      <div className="modal-header card-text font-size-20 p-3 let-modal">
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="font-weight-bold">Logs</span>
          <i className="bx bx-sm bx-x inventory-color cursor-pointer" onClick={toggleModal} />
        </div>
      </div>
      <div className="modal-body p-3 let-modal">
      <RenderIf isTrue={!!error}>
          <Alert color="danger" className="text-center">
            {error || "An error occurred while fetching logs."}
          </Alert>
        </RenderIf>
        <RenderIf
          isTrue={!historyLogsLoading}
          fallback={
            <div className="d-flex justify-content-center my-3">
              <Spinner color="primary" style={{ width: "40px", height: "40px" }} />
            </div>
          }
        >
          <ReactTable
            className="w-100 overflow-auto"
            height={{ maxHeight: "350px" }}
            tableHeader={
              <>
                {$.values(cols).map((header, index) => (
                  <th style={{ width: header.title === "Message" ? "85%" : undefined }} key={`log-table-header-${index}`}>{header.title}</th>
                ))}
              </>
            }
            tableBody={
              <RenderIf
                isTrue={!$.isEmpty(logs)}
                fallback={
                  <tr>
                    <td colSpan="100%">
                      <h2 className="text-center">No Logs Found</h2>
                    </td>
                  </tr>
                }
              >
                {$.map(logs, (data, key) => (
                  <tr key={`log-table-row-${key}`}>
                    {$.values(cols).map(({ Content }, colKey) => (
                      <td key={`log-table-col-${colKey}`}>
                        <Content data={data} />
                      </td>
                    ))}
                  </tr>
                ))}
              </RenderIf>
            }
          />
        </RenderIf>
      </div>
      <div className="modal-footer p-3 let-modal">
        <PillBtn className="mr-2" title="Close" name="Close" color="primary" outline={true} onClick={toggleModal} />
      </div>
    </Modal>
  );
};

export default LogsModal;
