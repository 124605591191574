import {
  CREATE_SUPPLIER_ORDER_SUCCESS,
  CREATE_SUPPLIER_ORDER,
  RESET_STATE,
  UPDATE_TRACKING_INFO,
  UPDATE_TRACKING_INFO_SUCCESS,
  SET_TRACKING_INFO_MODAL,
  API_ERROR,
  DELETE_SUPPLIER_ORDER,
  DELETE_SUPPLIER_ORDER_SUCCESS,
  MARK_AS_IGNORE,
  MARK_AS_IGNORE_SUCCESS,
  SET_EDIT_SUPPLIER_ORDER_MODAL,
  UPDATE_SUPPLIER_ORDER,
  UPDATE_SUPPLIER_ORDER_SUCCESS,
  FETCH_ALL_RETURN_LABEL_HISTORY,
  FETCH_ALL_RETURN_LABEL_HISTORY_SUCCESS,
  FETCH_RETURN_LABEL_HISTORY,
  FETCH_RETURN_LABEL_HISTORY_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  trackingInfoModalIsOpen: false,
  editSupplierOrderModalIsOpen: false,
};

const supplierOrders = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUPPLIER_ORDER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case MARK_AS_IGNORE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case MARK_AS_IGNORE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Supplier Order Has Been Marked As Ignored Successfully",
        loading: false,
      };
      break;
    case UPDATE_SUPPLIER_ORDER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_SUPPLIER_ORDER_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Supplier Order Has Been Updated Successfully",
        loading: false,
      };
      break;
    case CREATE_SUPPLIER_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Supplier Order Created Successfully",
      };
      break;
    case UPDATE_TRACKING_INFO:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_TRACKING_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Supplier Order Updated Successfully",
      };
      break;
    case DELETE_SUPPLIER_ORDER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DELETE_SUPPLIER_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Supplier Order Deleted Successfully",
      };
      break;
    case FETCH_ALL_RETURN_LABEL_HISTORY:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_ALL_RETURN_LABEL_HISTORY_SUCCESS:
      state = {
        ...state,
        returnLabels: action.payload,
        loading: false,
        error: "",
        success: "Return Label Fetched Successfully",
      };
      break;
    case FETCH_RETURN_LABEL_HISTORY:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_RETURN_LABEL_HISTORY_SUCCESS:
      state = {
        ...state,
        returnLabelHistory: action.payload?.data,
        loading: false,
        error: "",
      };
      break;
    case SET_TRACKING_INFO_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        trackingInfoModalIsOpen: action.payload,
      };
      break;
    case SET_EDIT_SUPPLIER_ORDER_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        editSupplierOrderModalIsOpen: action.payload,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        success: false,
        loading: false,
      };
      break;
    case RESET_STATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default supplierOrders;
