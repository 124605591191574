import React, { useEffect, useState } from "react";
import * as $ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { filtersMapping, options } from "../common";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { RenderIf } from "../../../utils/common";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import DataProcessingMsg from "../NewDashboard/components/DataProcessingMsg";

const BuyboxChart = ({ store, storeId }) => {
  const dispatch = useDispatch();
  const getLabel = (days) => ({ label: days + " Days", value: days });
  const [filter, setFilter] = useState(getLabel(7));
  const { account } = useSelector((state) => state.Settings);
  const { buyboxData, buyBoxStatsLoading } = useSelector((state) => state.Repricer);
  const isStoreAdded = !!account?.data?.stores_count;

  const chartData = $.map(buyboxData?.listing_insights, (stat) => ({
    x: stat.datetime,
    y: stat.total_buybox,
    z: stat.buybox_ratio,
  }));

  const statsProps = {
    buybox_ownership: { title: "Buy Box Ownership" },
    buybox_eligible: { title: "Buy Box Eligible" },
    buybox_winner: { title: "Buy Box Winner" },
    buybox_ineligible: { title: "Buy Box Ineligible" },
    out_of_buybox: { title: "Out of Buy Box" },
  };

  useEffect(() => {
    isStoreAdded &&
      !buyBoxStatsLoading &&
      storeId &&
      dispatch(actions.getBuyboxStats($.merge({ filter: filtersMapping[filter.value] }, store)));
  }, [filter, storeId]);
  return (
    <>
      <Breadcrumb
        isCapitalize={true}
        title="Buybox"
        children={
          <RenderIf isTrue={storeId}>
            <div className="d-flex flex-row float-sm-right">
              <SimpleSelect
                name="date_filter"
                value={filter}
                onChange={setFilter}
                options={$.keys(filtersMapping).map((days) => getLabel(days))}
                formStyle={{ width: "200px" }}
                classNamePrefix="select2-selection"
              />
            </div>
          </RenderIf>
        }
      />
      <div className={`row ${buyBoxStatsLoading ? "justify-content-center" : ""}`}>
        <RenderIf
          isTrue={!buyBoxStatsLoading}
          fallback={<Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />}
        >
          <DataProcessingMsg data={buyboxData?.listing_insights} store={storeId}>
            <div className="col-xl-2 col-md-3 col-sm-4" style={{ borderRight: "1px solid #A6B0CF33" }}>
              {$.entries(statsProps).map(([statType, attrs]) => (
                <RenderIf isTrue={Number.isInteger(buyboxData?.[statType])} key={"__buybox__" + statType}>
                  <div key={statType} className="mt-3">
                    <div className="d-flex font-size-13 justify-content-between">{attrs.title}</div>
                    <div className="d-flex align-items-center">
                      <div className="inventory-color font-size-16">{buyboxData?.[statType]}</div>
                    </div>
                  </div>
                </RenderIf>
              ))}
            </div>
            <div className="col-xl-10 col-md-9 col-sm-8">
              <ReactApexChart
                options={{ ...options, tooltip: { z: { title: "% Buybox" } } }}
                series={[{ name: "Buybox", data: chartData }]}
                type="area"
                height="350"
              />
            </div>
          </DataProcessingMsg>
        </RenderIf>
      </div>
    </>
  );
};

export default React.memo(BuyboxChart);
