import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import {
  fetchProxyUsers,
  createProxyUser,
  updateProxyUser,
  fetchLuminatiProxyData,
  fetchProxyByPort,
  swapProxyByPort,
  updateProxyByPort,
  uploadProxyCsv,
} from "../../api";
import {
  apiError,
  setAddProxyUserModel,
  fetchProxyUsersSuccess,
  createProxyUserSuccess,
  updateProxyUserSuccess,
  fetchLuminatiProxiesSuccess,
  fetchProxyByPortSuccess,
  swapProxyByPortSuccess,
  updateLpmProxySuccess,
  setUpdateLpmProxyModal,
  uploadProxyCsvSuccess,
} from "./actions";
import {
  CREATE_PROXY_USER,
  FETCH_ALL_PROXIES,
  FETCH_PROXY_USERS,
  UPDATE_PROXY_USER,
  FETCH_PROXY_BY_PORT,
  SWAP_PROXY_BY_PORT,
  UPDATE_LPM_PROXY,
  UPLOAD_PROXY_CSV,
} from "./actionTypes";
const ProxyUsers = (state) => state.ProxyUsers;

function * fetchProxyUsersSaga ({ payload: filter }) {
  try {
    const res = yield call(fetchProxyUsers, filter);
    yield put(fetchProxyUsersSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function * fetchProxiesSaga () {
  try {
    const res = yield call(fetchLuminatiProxyData);
    yield put(fetchLuminatiProxiesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchProxyByPortSaga ({ payload: port }) {
  try {
    const res = yield call(fetchProxyByPort, port);
    yield put(fetchProxyByPortSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * swapProxyByPortSaga ({ payload: port }) {
  try {
    const res = yield call(swapProxyByPort, port);
    if (res?.data) {
      const { proxiesData } = yield select(ProxyUsers);
      const index = proxiesData?.findIndex((x) => x.wrapper_port === res.data.wrapper_port);
      if (proxiesData[index]) {
        proxiesData[index] = res.data;
        yield put(swapProxyByPortSuccess(res));
      }
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updateProxyByPortSaga ({ payload: { data, port } }) {
  try {
    const res = yield call(updateProxyByPort, { data, port });
    if (res?.data) {
      const { proxiesData } = yield select(ProxyUsers);
      const index = proxiesData?.findIndex((x) => x.wrapper_port === res.data.wrapper_port);
      if (proxiesData[index]) {
        proxiesData[index] = res.data;
        yield put(updateLpmProxySuccess(res));
        yield put(setUpdateLpmProxyModal(false));
      }
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * uploadProxyCsvSaga ({ payload: data }) {
  try {
    const res = yield call(uploadProxyCsv, data);
    yield put(uploadProxyCsvSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * createProxyUserSaga ({ payload: proxyUser }) {
  try {
    const res = yield call(createProxyUser, proxyUser);
    yield put(createProxyUserSuccess(res));
    yield put(setAddProxyUserModel(false));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateProxyUserSaga ({ payload: proxyUser }) {
  try {
    const res = yield call(updateProxyUser, proxyUser);
    yield put(updateProxyUserSuccess(res));
    yield put(setAddProxyUserModel(false));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchFetchProxyUsersSaga () {
  yield takeEvery(FETCH_PROXY_USERS, fetchProxyUsersSaga);
}

export function * watchCreateProxyUserSaga () {
  yield takeEvery(CREATE_PROXY_USER, createProxyUserSaga);
}

export function * watchUpdateProxyUserSaga () {
  yield takeEvery(UPDATE_PROXY_USER, updateProxyUserSaga);
}

export function * watchFetchAllProxiesSaga () {
  yield takeEvery(FETCH_ALL_PROXIES, fetchProxiesSaga);
}

export function * watchFetchProxyByPortSaga () {
  yield takeEvery(FETCH_PROXY_BY_PORT, fetchProxyByPortSaga);
}

export function * watchSwapProxyByPortSaga () {
  yield takeEvery(SWAP_PROXY_BY_PORT, swapProxyByPortSaga);
}

export function * watchUpdateProxyByPortSaga () {
  yield takeEvery(UPDATE_LPM_PROXY, updateProxyByPortSaga);
}

export function * watchUploadProxyCsvSaga () {
  yield takeEvery(UPLOAD_PROXY_CSV, uploadProxyCsvSaga);
}

function * ProxyUsersSaga () {
  yield all([
    fork(watchFetchProxyUsersSaga),
    fork(watchCreateProxyUserSaga),
    fork(watchUpdateProxyUserSaga),
    fork(watchFetchAllProxiesSaga),
    fork(watchFetchProxyByPortSaga),
    fork(watchSwapProxyByPortSaga),
    fork(watchUpdateProxyByPortSaga),
    fork(watchUploadProxyCsvSaga),
  ]);
}

export default ProxyUsersSaga;
