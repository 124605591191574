import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import PropTypes from "prop-types";
import { Card, TabContent, TabPane, Progress } from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// redux actions
import { createMarketplaceAccount, setIsAmz, setPreloader } from "../../../store/actions";
import ErrorMessage from "./errorMessage";
import { NEW_DASHBOARD_URL, onboardingStepsIds, STORE_ADD_MSG } from "../../../constants";
import { AddAccountBtn, ConfirmBtn, StoreAddedMsg } from "./StoreAddedMsg";

const AmazonSetUp = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { onboardAccountSuccess, error, loading } = useSelector((state) => state.MarketPlaceAccount);
  const [isWm, setIsWm] = useState(true);
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabProgress, setactiveTabProgress] = useState(1);
  const [progressValue, setprogressValue] = useState(25);
  const [formData, setFormData] = useState({});
  const { loading: onboardFlagLoading, addRepricerStore } = useSelector((state) => state.Settings);

  function toggleTab (tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab);
      }
    }
  }

  function toggleTabProgress (tab) {
    if (tab >= 1 && tab <= 2) {
      setactiveTabProgress(tab);

      if (tab === 1) {
        setprogressValue(50);
      }
      if (tab === 2) {
        setprogressValue(100);
      }
    }
  }

  useEffect(() => {
    setPreloader(loading || onboardFlagLoading);
  }, [loading, onboardFlagLoading]);

  return (
    <React.Fragment>
      <Card>
        {isWm && (
          <div className="p-4">
            <div id="progrss-wizard" className="twitter-bs-wizard">
              <div className="px-5 py-2">
                <Progress color="primary" value={progressValue} />
              </div>
              <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content">
                <TabPane key="1" tabId={1}>
                  <h4 className="card-title mb-3 text-center">Register Ecom Circles on Walmart Seller Central</h4>
                  <div className="p-2">
                    <div className="py-2">
                      <p>Step 1: Navigate to walmart Seller Central</p>
                      <p>a) Login to Walmart Developer Portal</p>
                      <a
                        href="https://developer.walmart.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary w-md btn-sm waves-effect waves-light mb-2"
                      >
                        GO TO DEVELOPER PORTAL
                      </a>
                      <p>b) Get the Production keys (My API Key) in API keys section</p>
                    </div>

                    <div className="py-2">
                      <p>Step 2: Copy and Paste the Credentials</p>
                      <p>
                        Copy and paste the Client ID and Client Secret from the Walmart developer center to the
                        corresponding fields:
                      </p>
                    </div>

                    <div>
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          const data = { ...formData, ...v };
                          setFormData({ ...data });
                          dispatch(
                            createMarketplaceAccount({
                              name: data.name,
                              access_id: data.access_id,
                              access_secret: data.access_secret,
                              marketplace: "walmart",
                            }),
                          );
                          toggleTabProgress(2);
                          toggleTab(2);
                          formRef.current.reset();
                        }}
                        ref={(c) => (formRef.current = c)}
                      >
                        <div className="form-group">
                          <AvField
                            name="name"
                            label="Walmart Storefront Name:"
                            value=""
                            className="form-control"
                            placeholder="Choose a display name for your Walmart account"
                            type="text"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="access_id"
                            label="Client ID:"
                            placeholder="Client id"
                            className="form-control"
                            value=""
                            type="text"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="access_secret"
                            label="Client Secret:"
                            value=""
                            className="form-control"
                            placeholder="Client Secret"
                            type="text"
                            required
                          />
                        </div>
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li
                            onClick={() => props.setActiveStep(onboardingStepsIds.welcom)}
                            className="btn btn-primary w-md btn-sm waves-effect waves-light"
                          >
                            Previous
                          </li>
                          <li className={activeTabProgress === 2 ? "next disabled" : "next"}>
                            <button type="submit" className="btn btn-primary w-md btn-sm waves-effect waves-light">
                              Next
                            </button>
                          </li>
                        </ul>
                      </AvForm>
                    </div>
                  </div>
                </TabPane>
                <TabPane key="2" tabId={2}>
                  {onboardAccountSuccess && !error && !loading ? (
                    <StoreAddedMsg marketplace="walmart">
                      <AddAccountBtn
                        onClick={() => {
                          setIsWm(true);
                          props.setIsOpen(true);
                          setactiveTab(1);
                          setactiveTabProgress(1);
                          setprogressValue(25);
                          props.setActiveStep(onboardingStepsIds.welcom);
                        }}
                      />
                      <ConfirmBtn
                        onClick={() => {
                          props.setActiveStep(onboardingStepsIds.payment);
                          if (!props.onBoarding || addRepricerStore) {
                            props.setIsOpen(false);
                            dispatch(setIsAmz(false));
                            if (addRepricerStore) {
                              props.history.push(NEW_DASHBOARD_URL);
                              dispatch(actions.setAddRepricerStore(false));
                            }
                          }
                          setIsWm(false);
                        }}
                      />
                    </StoreAddedMsg>
                  ) : !error ? (
                    <h4 className="card-title mb-3 text-center">{STORE_ADD_MSG}</h4>
                  ) : (
                    <div className="text-center">
                      <ErrorMessage error={error} />
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li>
                          <button
                            onClick={() => {
                              toggleTabProgress(1);
                              toggleTab(1);
                            }}
                            className="waves-effect waves-light btn btn-primary"
                          >
                            Back
                          </button>
                        </li>
                        {props.onBoarding === false && (
                          <li>
                            <button
                              onClick={() => {
                                props.setIsOpen(false);
                              }}
                              className="ml-3 waves-effect waves-light btn btn-primary"
                            >
                              Exit
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </TabPane>
              </TabContent>
            </div>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

export default AmazonSetUp;

AmazonSetUp.propTypes = {
  setActiveStep: PropTypes.func,
};
