export const FETCH_PLAN_GROUPS = "FETCH_PLAN_GROUPS";
export const FETCH_PLAN_GROUPS_SUCCESS = "FETCH_PLAN_GROUPS_SUCCESS";
export const CREATE_PLAN = "CREATE_PLAN";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_GROUP = "CREATE_PLAN_GROUP";
export const CREATE_PLAN_GROUP_SUCCESS = "CREATE_PLAN_GROUP_SUCCESS";
export const UPDATE_PLAN_GROUP = "UPDATE_PLAN_GROUP";
export const SET_EDIT_PLAN_GROUP_MODAL = "SET_EDIT_PLAN_GROUP_MODAL";
export const SET_EDITABLE_PLAN = "SET_EDITABLE_PLAN";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_GROUP_SUCCESS = "UPDATE_PLAN_GROUP_SUCCESS";
export const UPDATE_GROUP_AND_PLAN = "UPDATE_GROUP_AND_PLAN";
export const UPDATE_GROUP_AND_PLAN_SUCCESS = "UPDATE_GROUP_AND_PLAN_SUCCESS";
export const SET_ADD_PLAN_MODAL = "SET_ADD_PLAN_MODAL";
export const SET_VIEW_PLANS_MODAL = "SET_VIEW_PLANS_MODAL";
export const SET_PLAN_GROUP_MODAL = "SET_PLAN_GROUP_MODAL";
export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const API_ERROR = "API_ERROR";
