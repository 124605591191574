import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { map, startCase, keys, ceil } from "lodash";
// components
import { Card, CardBody, Container, CardHeader, Badge, FormGroup, NavLink } from "reactstrap";
import moment from "moment-timezone";
import CustomPagination from "../../components/Common/CustomPagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toPascalCase, humanize } from "../../utils/common";
import {
  setPreloader,
  fetchFulfillMatchesStatus,
  fetchRequestsbyQueury,
  fetchUsers,
  cancelMatchesReq,
} from "../../store/actions";
import CustomTooltip from "../../components/Common/CustomTooltip";
import { DEFAULT_TENANT_ID, DATE_FORMATS, MATCHES_REQ_STATUS, MATCHING_REQ_TYPES } from "../../constants";
const { FULL_DATE, DATE_TIME } = DATE_FORMATS;
const STATUS_COLOR_CODE = {
  pending: "info",
  inProgress: "warning",
  completed: "success",
  cancelled: "danger",
};

const JsonFilters = ({ data }) => {
  if (!data || !keys(data).length) return null;
  return map(data, (num, col) => (
    <div key={`_${col}_`}>
      {[
        startCase(col),
        ["startDate", "endDate"].includes(col) ? moment(num.toLocaleString()).format(DATE_TIME) : num.toLocaleString(),
      ].join(" => ")}
    </div>
  ));
};

const MatchesRequests = () => {
  const {
    matchesRequests: { requests, statuses, users, count: totalRecords },
    loading,
  } = useSelector((state) => state.MatchesRequests);
  const { users: allUsers } = useSelector((state) => state.User);
  const { fulfillRequestServiceStatus, matchesRequestsNote } = useSelector((state) => state.Settings);
  const [matchesRequests, setMatchesRequests] = useState();
  const [pageFilters, setPageFilters] = useState({ page: 1, per_page: 30 });
  const [requestStatus, setRequestStatus] = useState({ value: "", label: "Select Status" });
  const [user, setUser] = useState({ value: "", label: "Select User" });
  const [matchesReqActionId, setMatchesReqActionId] = useState({ id: "" });
  const dispatch = useDispatch();
  const tableHeaders = [
    { title: "Email" },
    { title: "Requested By" },
    { title: "Marketplace" },
    { title: "Source Platform" },
    { title: "Matches Type" },
    { title: "Request Type" },
    { title: "Request Demand" },
    { title: "Request Sent" },
    { title: "Status" },
    { title: "Marketplace Account" },
    { title: "Created at" },
    { title: "Filters" },
    { title: "Actions" },
  ];

  const mapVaID = (VaID) => allUsers?.data?.find((user) => user.id === VaID);

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const genParams = (_) => {
    const makeParams = {};
    if (user.value) makeParams.email = user.value;
    if (requestStatus.value) makeParams.status = requestStatus.value;
    if (pageFilters.page) makeParams.page = pageFilters.page;
    if (pageFilters.per_page) makeParams.perPage = pageFilters.per_page;
    return makeParams;
  };

  useEffect(() => {
    setPageFilters({ ...pageFilters, page: 1 });
  }, [requestStatus, user, matchesReqActionId]);

  useEffect(() => {
    !loading && dispatch(fetchRequestsbyQueury(genParams()));
  }, [pageFilters]);

  useEffect(() => {
    if (requests) {
      setMatchesRequests(requests);
    }
  }, [requests]);
  useEffect(() => {
    matchesReqActionId.id && dispatch(cancelMatchesReq(matchesReqActionId));
  }, [matchesReqActionId]);

  useEffect(() => {
    dispatch(fetchFulfillMatchesStatus());
    dispatch(
      fetchUsers({
        account_id: DEFAULT_TENANT_ID,
        pagination: false,
      }),
    );
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Requests Matches" />
          <Card>
            <CardHeader>
              <div className="row d-flex col-6">
                <FormGroup className="col select2-container ml-6 position-relative" style={{ width: "200px" }}>
                  <Select
                    name="requestStatusName"
                    value={{
                      value: requestStatus.value,
                      label: requestStatus.label,
                    }}
                    onChange={(e) =>
                      setRequestStatus({ value: e.value, label: e.value === "" ? "Select Status" : e.label })
                    }
                    options={statuses?.reduce(
                      (acc, type) => {
                        acc.push({ label: startCase(type), value: type });
                        return acc;
                      },
                      requestStatus.label === "Select Status" ? [] : [{ value: "", label: "All Statuses" }],
                    )}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                <FormGroup className="col select2-container ml-6">
                  <Select
                    name="selectUser"
                    value={{
                      value: user.value,
                      label: user.label,
                    }}
                    onChange={(e) => setUser({ value: e.value, label: e.value === "" ? "Select User" : e.label })}
                    options={users?.reduce(
                      (acc, type) => {
                        acc.push({ label: type, value: type });
                        return acc;
                      },
                      user.label === "Select User" ? [] : [{ value: "", label: "All Users" }],
                    )}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                <h6 className="px-2 d-flex align-items-center">
                  Fulfill Matches Service:
                  <i
                    id="fulfillMatchesStatus"
                    className={`bx bx-sm bxs-circle pl-2 text-${fulfillRequestServiceStatus ? "success" : "danger"}`}
                  ></i>
                  <CustomTooltip target={"fulfillMatchesStatus"} content={matchesRequestsNote} />
                </h6>
              </div>
            </CardHeader>

            <CardBody>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {tableHeaders.map((header, index) => (
                        <th
                          className={/status|trial end date|actions/i.test(header.title) ? "text-center" : ""}
                          key={`table-header ${index}`}
                        >
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {matchesRequests?.map((item, key) => (
                      <tr key={"_listings_" + key} className="mt-3 mb-3">
                        <td>{item?.["user.email"]?.toLowerCase()}</td>
                        <td>{startCase(mapVaID(item.vaId)?.username) || "N/A"}</td>
                        <td className="text-center">{startCase(item?.matchesType.split("_")[0]) || ""}</td>
                        <td>{startCase(item.sourcePlatform)}</td>
                        <td>{startCase(item.matchesRequestType)}</td>
                        <td>{startCase(item.requestType)}</td>
                        <td>{humanize(item?.requested)}</td>
                        <td>
                          {item.requestType === keys(MATCHING_REQ_TYPES)[0] && !item.filters?.isSourceOnly
                            ? humanize(ceil(item.sent / 2))
                            : humanize(item.sent)}
                        </td>
                        <td className="text-center">
                          <Badge
                            className={"font-size-12 badge-soft-" + STATUS_COLOR_CODE[item.status]}
                            color={STATUS_COLOR_CODE[item.status]}
                            pill
                          >
                            {toPascalCase(item.status)}
                          </Badge>
                        </td>
                        <td>{item?.platformName || "N/A"}</td>
                        <td>{(item?.createdAt && moment(item.createdAt).format(FULL_DATE)) || ""}</td>
                        <td>
                          <JsonFilters data={item.filters} />
                        </td>
                        <td>
                          {item.status === MATCHES_REQ_STATUS.pending &&
                          item.requestType === keys(MATCHING_REQ_TYPES)[1]
                            ? (
                            <NavLink
                              id={item.id}
                              className="text-center"
                              onClick={(e) => {
                                setMatchesReqActionId({ id: e.target?.id });
                              }}
                            >
                              Cancel
                            </NavLink>
                              )
                            : (
                            <div className="text-center">N/A</div>
                              )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {Array.isArray(matchesRequests)
                ? (
                <CustomPagination
                  pageOptions={[10, 30, 50, 100]}
                  total={totalRecords}
                  page={pageFilters.page}
                  perPage={pageFilters.per_page}
                  tabsFilter={pageFilters}
                  setTabFilter={(e) => setPageFilters(pageFilters.per_page !== e.per_page ? { ...e, page: 1 } : e)}
                />
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MatchesRequests;
