import {
  API_ERROR,
  BANNER_NOTIFICATION_UPDATE,
  CREATE_NOTIFICATION_EMAILS,
  CREATE_NOTIFICATION_EMAILS_SUCCESS,
  DELETE_NOTIFICATION_EMAIL,
  DELETE_NOTIFICATION_EMAIL_SUCCESS,
  FETCH_NOTIFICATIONS_SETTINGS,
  FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
  FETCH_NOTIFICATION_EMAILS,
  FETCH_NOTIFICATION_EMAILS_SUCCESS,
  SET_EMAIL_TYPE_ENABLED,
  SET_EMAIL_TYPE_ENABLED_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  emailType: "",
};
const NotificationEmails = (state = initialState, action) => {
  switch (action.type) {
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case CREATE_NOTIFICATION_EMAILS:
      state = {
        ...state,
        success: "",
        error: "",
      };
      break;
    case CREATE_NOTIFICATION_EMAILS_SUCCESS:
      state = {
        ...state,
        success: action.payload.success,
        error: "",
        message: action.payload.message,
        notificationsSettings: action.payload,
      };
      break;
    case FETCH_NOTIFICATION_EMAILS:
      state = {
        ...state,
        success: "",
        error: "",
      };
      break;
    case FETCH_NOTIFICATION_EMAILS_SUCCESS:
      state = {
        ...state,
        success: action.payload.data.success,
        loading: false,
        emails: action.payload.data,
      };
      break;
    case DELETE_NOTIFICATION_EMAIL:
      state = {
        ...state,
        success: "",
        error: "",
      };
      break;
    case DELETE_NOTIFICATION_EMAIL_SUCCESS:
      state = {
        ...state,
        success: action.payload.success,
        error: "",
        message: action.payload.message,
        notificationsSettings: action.payload,
      };
      break;
    case FETCH_NOTIFICATIONS_SETTINGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_NOTIFICATIONS_SETTINGS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        notificationsSettings: action.payload,
      };
      break;
    case SET_EMAIL_TYPE_ENABLED:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
        emailType: "",
      };
      break;
    case SET_EMAIL_TYPE_ENABLED_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        emailType: action.payload,
      };
      break;
    case BANNER_NOTIFICATION_UPDATE:
      state = {
        ...state,
        success: "",
        loading: true,
        error: "",
      };
      break;
    default:
      break;
  }

  return state;
};

export default NotificationEmails;
