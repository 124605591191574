import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Alert, Collapse, FormGroup, Row, Col, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { useFormik } from "formik";

// actions
import { setPreloader, fetchMatchingPlatforms, addMatchingPlatforms } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getUserInfo, RenderIf, toPascalCase } from "../../../utils/common";
import PillBtn from "../../../components/Common/PillBtn";

const MatchingPlatforms = (props) => {
  const tableHeaders = [{ title: "Name" }, { title: "Seller Type" }];
  const [addPlatform, setAddPlatform] = useState(false);
  useEffect(() => {
    props.setPreloader(props.Listings.loading);
  }, [props.Listings.loading]);

  const formik = useFormik({
    initialValues: { name: "", seller_type: "" },
    validate: (values) => {
      const errors = {};
      Object.keys(values).forEach((x) => {
        !values[x] && (errors[x] = `${toPascalCase(x)} is required`);
      });
      return errors;
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      values.va_id = getUserInfo().id;
      props.addMatchingPlatforms(values);
      formik.resetForm();
      setAddPlatform(false);
    },
  });

  useEffect(() => {
    props.fetchMatchingPlatforms();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Platforms"
            children={
              <PillBtn
                color="success"
                title="Add Platform"
                name="Add Platform"
                icon="bx bx-xs bx-plus"
                className="col ml-2"
                onClick={() => setAddPlatform(!addPlatform)}
              />
            }
          />

          {props.Listings.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.Listings.success || ""}
              </Alert>
            </div>
          )}

          <Card>
            <Collapse isOpen={addPlatform} className="py-2 align-self-center">
              <Col md="12" className="content-justify-center">
                <form className="m-2" onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg="12 mt-2">
                      <FormGroup className="select2-container">
                        <Label>Name*</Label>
                        <Input
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                          type="text"
                        />
                        {formik.touched.name && formik.errors.name
                          ? (
                          <small className="text-danger mt-1">{formik.errors.name}</small>
                            )
                          : null}
                      </FormGroup>
                    </Col>
                    <Col lg="12 mt-2">
                      <FormGroup className="select2-container">
                        <Label>Seller Type*</Label>
                        <select
                          name="seller_type"
                          className="custom-select"
                          value={formik.values.seller_type || ""}
                          onChange={formik.handleChange}
                        >
                          <option value="">Select a Seller Type</option>
                          {[
                            { name: "Whole Seller", val: "wholesale" },
                            { name: "Marketplace", val: "normal" },
                          ].map((supplier, key) => (
                            <option key={"seller_type" + key} value={supplier.val}>
                              {supplier.name}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                      {formik.touched.seller_type && formik.errors.seller_type
                        ? (
                        <small className="text-danger mt-1">{formik.errors.seller_type}</small>
                          )
                        : null}
                    </Col>
                    <Col lg="12 mt-2">
                      <div className="text-right mt-4">
                        <Button type="submit" color="success" className="mr-3">
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Collapse>
            <CardBody>
              <RenderIf isTrue={props.Listings?.matchingPlatforms?.length > 0}>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {/* table headers */}
                        {tableHeaders.map((header, index) => (
                          <th key={`table-header ${index}`}>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {/* iterating data */}
                      {props.Listings?.matchingPlatforms?.map((item, key) => (
                        <tr key={"_suppliers_" + key} className="mt-3 mb-3">
                          <td>{item.title}</td>
                          <td>
                            {item.seller_type === "wholesale"
                              ? "Wholesaler"
                              : item.seller_type === null
                                ? "Warehouse"
                                : "Retailer"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </RenderIf>
              <RenderIf isTrue={props.Listings?.matchingPlatforms && !props.Listings?.matchingPlatforms?.length}>
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
              </RenderIf>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { Listings } = state;
  return { Listings };
};

export default connect(mapStatetoProps, {
  setPreloader,
  fetchMatchingPlatforms,
  addMatchingPlatforms,
})(MatchingPlatforms);
