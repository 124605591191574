import { map, sumBy } from "lodash";
import { SUPPLIER_ORDER_STATUSES, MARKET_PLACES, AMAZON_TAX_VALUES, WALMART_TAX_VALUES } from "../constants/index";
import { calculateSale, toF, warehouseCost, marketplaceFee, getTotalPriceAdj, isUserHaveFullAccess } from "../utils/common";
const { refund, ignored, cancelled } = SUPPLIER_ORDER_STATUSES;

export const findDefault = (suppliers, partnerId) =>
  Array.isArray(suppliers)
    ? suppliers?.find((supplier) =>
      partnerId ? +supplier.partner_id === partnerId && supplier.is_default : supplier.is_default,
    )
    : suppliers;

export function calculateProfit (sourceOrder, matchingData, supplierSetting, productCategories, storeName) {
  const { source_items: sourceItems, supplier_orders: supplierOrders } = sourceOrder;
  if (!sourceItems) return {};
  if (!supplierOrders.length && !matchingData) return;

  const isProcessed = !!supplierOrders.filter((item) => ![refund, cancelled, ignored].includes(item.status)).length;
  const extraPrices = getTotalPriceAdj(sourceOrder) - warehouseCost(sourceOrder, false);

  const checkStatuses = (order) => {
    const statusesToCheck = {
      status: ["delayed", "in_queue", "processing", "checking", "new"],
      marketplace_status: ["Created", "Acknowledged", "Unshipped"],
    };
    const checkStatus = (statuses, status) => statuses.includes(status);
    return map(statusesToCheck, (statuses, status) => checkStatus(statuses, order[status])).some(Boolean);
  };

  return {
    profit: toF(
      isProcessed || !isUserHaveFullAccess() || !checkStatuses(sourceOrder)
        ? calculateSupplierProfit(sourceOrder)
        : calculateEstProfit(sourceItems, matchingData, supplierSetting, productCategories, storeName) + extraPrices,
    ),
    skusNotFound: isUserHaveFullAccess()
      ? [
          ...new Set(
            sourceItems.reduce((acc, item) => {
              !matchingData[item.sku] && acc.push(item.sku);
              return acc;
            }, []),
          ),
        ]
      : [],
  };
}

function calculateEstProfit (sourceItems, matchingData, supplierSetting, productCategories, storeName) {
  const skusFound = sourceItems.reduce((acc, item) => {
    const matchItem = findDefault(matchingData[item.sku], item.marketplace_account_id);
    if (!matchItem) return acc;
    item.match = matchItem;
    acc.push(item);
    return acc;
  }, []);

  const totalPrice = skusFound.reduce((acc, x) => {
    let price = x.price;
    price *= x.qty;
    price += x.shipping;
    price = price * (1 - taxPercentage(price, x.sku, productCategories, storeName) / 100);
    return acc + price;
  }, 0);

  const supplierEstCost = skusFound.reduce((acc, item) => {
    if (item.match && item.match.price) {
      const { supplierTax } = getSupplierSetting(item.match.name, supplierSetting);
      let itemPrice = item.match.price;
      itemPrice = itemPrice * item.match.quantity_multiplier || 1;
      itemPrice = itemPrice * item.qty;
      itemPrice += item.match.shipping_fee;
      itemPrice = itemPrice * (1 + supplierTax);
      acc += itemPrice;
    }
    return acc;
  }, 0);

  return totalPrice === 0 ? 0 : totalPrice - supplierEstCost;
}

function calculateSupplierProfit (order) {
  return order.order_profit;
}

export function isAutoOrderable (sku, matchingData, partnerId) {
  return findDefault(matchingData[sku], partnerId)?.auto_ordering_enabled;
}

export function getUniqueSkus (items = []) {
  return [...new Set(items.map((i) => i.sku))];
}

const toFloat = (num, places = 2) => parseFloat(num.toFixed(places));

export function calculateAOPrice (order, platforms) {
  const supplierSetting = Array.from(platforms)?.find((x) => [MARKET_PLACES.amazon].includes(x.name));
  if (!supplierSetting) return "";
  const { profit_threshold: profitThreshold } = supplierSetting;
  const price = (order.source_items || []).reduce((total, x) => {
    let price = x.price;
    price *= x.qty;
    price *= 0.85;
    price += x.shipping;
    return total + price;
  }, 0);
  const margin = isNaN(profitThreshold) ? 0 : profitThreshold;
  return toFloat(margin * -1 + price - warehouseCost(order));
}

export function breakEvenPrice (sourceOrder = {}) {
  return toF(
    +calculateSale(sourceOrder, false) -
      sumBy(sourceOrder.source_items, "tax") -
      marketplaceFee(sourceOrder?.source_items) -
      warehouseCost(sourceOrder),
  );
}

function getSupplierSetting (name, platforms) {
  const { profit_threshold: profitThreshold, tax } = platforms?.find((x) => x.name === name) || {};
  const margin = isNaN(profitThreshold) ? 0 : profitThreshold;
  const supplierTax = isNaN(tax) ? 0 : tax / 100;
  return { margin, supplierTax };
}

const taxPercentage = (price, sku, productCategories, storeName) => {
  switch (storeName) {
    case "amazon":
      return calculateTax(price, sku, productCategories, AMAZON_TAX_VALUES);
    case "walmart":
      return calculateTax(price, sku, productCategories, WALMART_TAX_VALUES);
    default:
      return 15;
  }
};

const calculateTax = (price, sku, productCategories, storeValues) => {
  if (productCategories) {
    const productCategory = productCategories?.find((x) => x.sku === sku);
    if (!productCategory?.category) {
      return 15;
    }
    const checkType = typeof storeValues[productCategory.category];
    if (checkType === "number") {
      return storeValues[productCategory.category];
    } else {
      const obj = storeValues[productCategory.category];
      if (!obj) return 15;
      const ranges = Object.keys(obj)
        .map((x) => parseInt(x))
        .sort()
        .reverse();
      for (const rang of ranges) {
        if (rang < price) {
          return obj[`${rang}`];
        }
      }
      return 1;
    }
  }
};
