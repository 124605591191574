import { API_ERROR, FETCH_AO_TASKS, FETCH_AO_TASKS_SUCCESS } from "./actionTypes";

export const fetchAOTasks = (params) => {
  return {
    type: FETCH_AO_TASKS,
    payload: params,
  };
};

export const fetchAOTasksSuccess = (res) => {
  return {
    type: FETCH_AO_TASKS_SUCCESS,
    payload: res,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
