import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AllTimeAlerts from "./AllTimeAlerts";

import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Preloader from "../../components/Common/Preloader";
import Rightbar from "../CommonForBoth/Rightbar";
import { toPascalCase, verifyRole, setLoader, getInventoryStuckItems, getUserInfo, RenderIf } from "../../utils/common";
import InventoryAlert from "../Common/InventoryAlert";
import { USER_ROLES } from "../../constants";

class Layout extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.isReadOnly = verifyRole("readOnly");
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidUpdate (prevProps) {
    if (this.props.preloaderIsopen !== prevProps.preloaderIsopen) {
      setLoader(this.props.preloaderIsopen);
    }
  }

  componentDidMount () {
    setLoader(this.props.isPreloader);
    if (this.state.preloaderIsopen) {
      document.getElementById("preloader") && (document.getElementById("preloader").style.display = "block");
      document.getElementById("status") && (document.getElementById("status").style.display = "block");
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    const currentage = this.props.title || toPascalCase(this.props.location.pathname.split("/")[1]);

    document.title = currentage;
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
  }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  };

  render () {
    return (
      <React.Fragment>
        <Preloader />
        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          />
          <div className="main-content" style={this.isReadOnly ? { marginLeft: "0" } : {}}>
            <RenderIf isTrue={this.props.account?.data}>
              <AllTimeAlerts />
            </RenderIf>
            <div style={{ marginTop: getInventoryStuckItems(this.props.alertData?.data)?.length ? "" : "0" }}>
              <RenderIf
                isTrue={![USER_ROLES.va, USER_ROLES.whStaff, USER_ROLES.readOnly].includes(getUserInfo()?.role)}
              >
                <InventoryAlert />
              </RenderIf>
              {this.props.children}
            </div>
          </div>
          <Footer pdfOpts={this.props?.pdfOpts} pAndLData={this.props.pAndLData} />
        </div>
        {this.props.showRightSidebar ? <Rightbar /> : null}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    account: state.Settings.account,
    alertData: state.WareHouses.alertData,
    pdfOpts: state.Settings.pdfOpts,
    pAndLData: state.SourceOrders.pAndLData,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout));
