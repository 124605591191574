import request from "../restClient";

const resource = "/supplier_orders";

export const createSupplierOrder = async (data) => {
  return await request({ url: resource, method: "POST", body: data });
};

export const updateSupplierOrderByTargetOrderId = async ({ data, targetOrderId }) => {
  return await request({
    url: `${resource}/${targetOrderId}`,
    method: "PUT",
    body: data,
  });
};

export const bulkUpdateSupplierOrders = async ({ data }) => {
  return await request({
    url: resource,
    method: "PUT",
    body: data,
  });
};

export const deleteSupplierOrderIdById = async (id) => {
  return await request({
    url: `${resource}/${id}`,
    method: "DELETE",
  });
};

export const fetchReturnLabelHistory = async (orderId) => {
  return await request({
    url: `/return_label_history/${orderId}`,
    method: "GET",
  });
};
