import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import Select, { components } from "react-select";
import { adjustDate, RenderIf } from "../../utils/common";
import CheckBox from "./CheckBox";
import Modal from "./Modal";
import moment from "moment-timezone";
import { DEFAULT_TZ, DATE_FORMATS } from "../../constants/index";
// actions
import { requestSourceOrderReport, setExportOrderModal } from "../../store/actions";
import ReportDownloadMsg from "./ReportDownloadMsg";
import getBrandImageSrc from "../../utils/brandImages";
import { merge } from "lodash";

const { DATE_PICKR } = DATE_FORMATS;

const SourceOrderExportModal = (props) => {
  const [dates, setDates] = useState([]);
  const toggleModal = () => props.setExportOrderModal(!props.SourceOrders.orderExportModalIsOpen);
  const validate = (values) => {
    const errors = {};
    !values.start_date && (errors.start_date = "Start Date is required");
    !values.end_date && (errors.end_date = "End Date is required");
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      marketplace_id: "",
      lost_orders: false,
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const modalType = props.SourceOrders.orderExportModalType;
      const params = {
        start_date: values.start_date.format(DATE_FORMATS.BACKEND),
        end_date: values.end_date.format(DATE_FORMATS.BACKEND),
        [modalType === "order" ? "partner_id" : "marketplace_id"]: values.marketplace_id?.value || "",
      };

      if (modalType !== "order") {
        merge(params, { utc_offset: moment().format("Z"), offset: DEFAULT_TZ });
      }

      if (values.lost_orders) params.lost_orders = values.lost_orders;
      params.report_type = modalType;
      props.requestSourceOrderReport(params);
    },
  });

  useEffect(() => {
    setDates([]);
    formik.resetForm();
  }, [props.SourceOrders.orderExportModalIsOpen]);

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.SourceOrders.orderExportModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">
            Download {props.SourceOrders.orderExportModalType === "5050" ? " 50/50 Matches " : " Order"} Report
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders.error}
            </Alert>
          )}
          <RenderIf isTrue={props.SourceOrders.success}>
            <ReportDownloadMsg action={setExportOrderModal} />
          </RenderIf>
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Col className="my-1">
              <Label>Select Date Range</Label>
              <div className="w-100">
                <Flatpickr
                  className="form-control"
                  placeholder="Select Date Range"
                  value={dates.map((x) => x.format(DATE_FORMATS.DATE))}
                  options={{
                    mode: "range",
                    dateFormat: DATE_PICKR,
                    defaultDate: dates,
                  }}
                  onClose={(val) => {
                    const newDates = val.map(adjustDate);
                    setDates(newDates.map((x) => moment(x)));
                    if (val.length > 1) {
                      formik.setFieldValue("start_date", moment(newDates[0]));
                      formik.setFieldValue("end_date", moment(newDates[1]));
                    }
                  }}
                />
              </div>
              {formik.touched.start_date && formik.errors.start_date
                ? (
                <small className="text-danger m-1">{formik.errors.start_date}</small>
                  )
                : null}
              {formik.touched.end_date && formik.errors.end_date
                ? (
                <small className="text-danger m-1">{formik.errors.end_date}</small>
                  )
                : null}
            </Col>
            <Col className="my-1">
              <Label>Marketplace Account</Label>
              <FormGroup className="select2-container w-100">
                <Select
                  name="marketplace_id"
                  value={formik.values.marketplace_id}
                  onChange={(e) => formik.setFieldValue("marketplace_id", e)}
                  options={(props.SourceOrders?.sourceOrders?.accounts || props.accounts?.data)?.map(
                    (acc, i) => ({ label: acc.name, value: acc.id, marketplace: acc.marketplace, key: i }),
                    [{ value: "", label: "All Accounts" }],
                  )}
                  classNamePrefix="select2-selection"
                  components={{
                    Option: (props) => (
                      <components.Option {...props}>
                        <RenderIf isTrue={getBrandImageSrc(props.data.marketplace)}>
                          <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
                        </RenderIf>
                        {props.data.label}
                      </components.Option>
                    ),
                  }}
                />
              </FormGroup>
            </Col>
            <Col className="my-1">
              <CheckBox
                name="lost_orders"
                state={formik.values.lost_orders}
                setState={(e) => formik.setFieldValue("lost_orders", e)}
                isSwitch={true}
                label="Lost/Delayed Orders"
              />
            </Col>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders } = state;
  return { SourceOrders };
};

export default connect(mapStateToProps, {
  requestSourceOrderReport,
  setExportOrderModal,
})(SourceOrderExportModal);
