import { all, call, fork, put, takeLeading } from "redux-saga/effects";
import { CALL_SP_API, CHECK_WALMART_OFFERS } from "./actionTypes";
import { callSPAPI } from "../../api/SPAPI";
import { callSPAPISuccess, checkWalmartOffersSuccess, apiError } from "./actions";
import { checkWalmartOffers } from "../../api/Accounts";
import * as $ from "lodash";

const errorMessage = "An error occurred while processing your request";

function * callSPAPISaga ({ payload: { body } }) {
  try {
    const res = yield call(callSPAPI, body);
    yield put(callSPAPISuccess(res));
  } catch (error) {
    yield put(apiError(error.message || errorMessage));
  }
}

function * checkWalmartOffersSaga ({ payload: { body } }) {
  try {
    const res = yield call(checkWalmartOffers, body);
    yield put(checkWalmartOffersSuccess($.get(res, "data.items[0]", res)));
  } catch (error) {
    yield put(apiError(error.message || errorMessage));
  }
}

export function * watchCallSPAPI () {
  yield takeLeading(CALL_SP_API, callSPAPISaga);
}

export function * watchCheckWalmartOffer () {
  yield takeLeading(CHECK_WALMART_OFFERS, checkWalmartOffersSaga);
}

function * CheckOffers () {
  yield all([fork(watchCallSPAPI), fork(watchCheckWalmartOffer)]);
}

export default CheckOffers;
