import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button, FormGroup } from "reactstrap";
import Modal from "../../components/Common/Modal";
import "react-toggle/style.css";
import { connect } from "react-redux";
import Select from "react-select";
import { toPascalCase } from "../../utils/common";
// actions
import { createCreditCardTypes, assignAccountToUsers, setCreditCardTypesModal } from "../../store/actions";

const CCTypesModal = (props) => {
  const [creditCardTypes, setcreditCardTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectMultipleCard, setSelectMultipleCard] = useState([]);
  const [selectMultipleUser, setSelectMultipleUser] = useState([]);
  function handleCard (selectMultipleCard) {
    setSelectMultipleCard(selectMultipleCard);
  }
  function handleUser (selectMultipleUser) {
    setSelectMultipleUser(selectMultipleUser);
  }

  const initialValues = {
    credentials_id: props.selected.id,
    cc_ids: "",
    users: "",
  };

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || values[key] === []) errors[key] = "Field is Required";
    });
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.Credential.creditCardTypesModalType === "card"
        ? props.createCreditCardTypes({
          data: {
            credentials_id: props.selected?.id,
            cc_ids: selectMultipleCard?.map((x) => x.value) || [],
          },
        })
        : props.assignAccountToUsers({
          data: {
            purchasing_account_id: props.selected?.id,
            users: selectMultipleUser?.map((x) => x.value) || [],
          },
        });
    },
  });

  useEffect(() => {
    if (props.Credential.creditCardTypesModalType === "card") {
      if (!props.CreditCards.loading && props.CreditCards.ccData) {
        const checkStatus = (arr, cardId) => arr?.map((x) => x.id).includes(cardId);
        const row = props.CreditCards.ccData?.data?.reduce((acc, data) => {
          const selected = checkStatus(props.selected?.cc_details, data.id);
          const obj = { label: data?.card_type?.replace("_", " ") + "-" + data?.last_four, value: data?.id };
          if (selected && data?.card_type) setSelectMultipleCard([...selectMultipleCard, obj]);
          if (data?.card_type) acc.push(obj);
          return acc;
        }, []);
        setcreditCardTypes(row);

        setSelectMultipleCard(
          props.CreditCards.ccData?.data?.reduce((acc, data) => {
            const selected = checkStatus(props.selected?.cc_details, data.id);
            const obj = { label: data?.card_type?.replace("_", " ") + "-" + data?.last_four, value: data?.id };
            if (selected && data?.card_type) acc.push(obj);
            return acc;
          }, []),
        );
      }
    } else {
      if (!props.User.loading && props.User?.users?.data) {
        setUsers(
          props.User.users.data
            ?.filter((x) => x.role === "va")
            ?.reduce((acc, data) => {
              const selected = props.selected?.selected_accounts?.map((x) => x.user_id)?.includes(data.id);
              if (selected) {
                setSelectMultipleUser([
                  ...selectMultipleUser,
                  {
                    label: toPascalCase(data?.username),
                    value: data?.id,
                  },
                ]);
              }
              acc.push({
                label: toPascalCase(data?.username),
                value: data?.id,
              });
              return acc;
            }, []),
        );
        setSelectMultipleUser(
          props.User.users.data
            .filter((x) => x.role === "va")
            .reduce((acc, data) => {
              const selected = props.selected.selected_accounts?.map((x) => x.user_id)?.includes(data.id);
              if (selected) {
                acc.push({
                  label: toPascalCase(data?.username),
                  value: data?.id,
                });
              }
              return acc;
            }, []),
        );
      }
    }
  }, [props.CreditCards.ccData, props.CreditCards.loading, props.User.users, props.User.loading]);

  useEffect(() => {
    selectMultipleCard && formik.setFieldValue("cc_ids", selectMultipleCard.value, false);
  }, [selectMultipleCard]);

  useEffect(() => {
    selectMultipleUser && formik.setFieldValue("users", selectMultipleUser.value, false);
  }, [selectMultipleUser]);

  const toggleModal = () => {
    props.setCreditCardTypesModal(
      !props.Credential.createCreditCardTypesModalIsOpen,
      props.Credential.creditCardTypesModalType,
    );
    formik.resetForm();
  };

  return (
    <Modal size="md" isOpen={props.Credential.createCreditCardTypesModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.Credential.creditCardTypesModalType === "card" ? "ADD CREDIT CARDS" : "ASSIGN ACCOUNT TO USERS"}
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {props.Credential.success && (
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Credential.success}
          </Alert>
        )}
        {props.Credential.error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {"Credentials: " + props.Credential.error}
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <Label>Email</Label>
              <Input name="email" value={props.selected.email} readOnly />
            </Col>
            {props.Credential.creditCardTypesModalType === "card"
              ? (
              <Col lg="12 mb-2">
                <FormGroup className="select2-container">
                  <label className="control-label">Select Credit Card(s)</label>
                  <Select
                    name="cc_ids"
                    id="card_select"
                    value={selectMultipleCard}
                    isMulti={true}
                    onChange={handleCard}
                    options={creditCardTypes}
                    classNamePrefix="select2-selection"
                  />
                  {formik.touched.cc_ids && formik.errors.cc_ids
                    ? (
                    <span className="text-danger mt-1">{formik.errors.cc_ids}</span>
                      )
                    : null}
                </FormGroup>
              </Col>
                )
              : (
              <Col lg="12 mb-2">
                <FormGroup className="select2-container">
                  <label className="control-label">Select User(s)</label>
                  <Select
                    name="users"
                    id="users_select"
                    value={selectMultipleUser}
                    isMulti={true}
                    onChange={handleUser}
                    options={users}
                    classNamePrefix="select2-selection"
                  />
                  {formik.touched.users && formik.errors.users
                    ? (
                    <span className="text-danger mt-1">{formik.errors.users}</span>
                      )
                    : null}
                </FormGroup>
              </Col>
                )}
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { Credential, CreditCards, User } = state;
  return { Credential, CreditCards, User };
};

export default connect(mapStateToProps, {
  createCreditCardTypes,
  assignAccountToUsers,
  setCreditCardTypesModal,
})(CCTypesModal);
