import React from "react";
import * as $ from "lodash";
import Checked from "../../../assets/svg/checked.svg";

export const StoreAddedMsg = (props) => (
  <>
    <h4 className="card-title mb-3 text-center">Seller Account Successfully Added!</h4>
    <div className="px-5">
      <div className="d-flex justify-content-center flex-column">
        <div className="d-flex align-items-center justify-content-center mt-3">
          <div className="checked-box">
            <img style={{ maxHeight: "100px" }} src={Checked} alt="no-checked" />
          </div>
        </div>
        <div className="font-size-14 text-center mt-4">
          <p>
            {`Congratulations! You have successfully connected your ${$.startCase(
              props.marketplace,
            )} account. Ecom Circles will start pulling data in immediately.`}
          </p>
          <p>Please note that it could take up to 24 hours for all data to fully sync.</p>
        </div>
      </div>
      <br />
      {props.children}
    </div>
  </>
);

export const AddAccountBtn = ({ onClick }) => (
  <div className="text-center">
    <button onClick={onClick} className="btn btn-primary w-50 btn-sm waves-effect waves-light">
      Add Another Storefront
    </button>
  </div>
);

export const ConfirmBtn = ({ onClick }) => (
  <div className="text-center">
    <button onClick={onClick} className="mt-3 btn btn-primary w-50 btn-sm waves-effect waves-light">
      Close
    </button>
  </div>
);
