import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import { merge } from "lodash";
import queryString from "query-string";
// Account Redux states
import {
  REGISTER_USER,
  VERIFY_USER,
  VERIFY_WAREHOUSE_USER,
  SEND_VERIFY_TOKEN,
  GET_VERIFICATION_LINK,
} from "./actionTypes";
import {
  registerUserSuccessful,
  registerUserFailed,
  setVerifyError,
  verifyUserSuccess,
  verifyWareHouseUserSuccess,
  sendVerifyTokenSuccess,
  getVerificationLinkSuccess,
  getVerificationLinkFail,
  resetRegReducer,
} from "./actions";
import { setAddClientModal } from "../../actions";
// API
import {
  registerUser,
  verifyUser,
  verifyWarehouseUser,
  sendVerificationToken,
  genVerificationEmail,
} from "../../../api";
import * as gtm from "../../../utils/gtmHelpers";
import { isInviteUrl, setAuthTokenInBrowser } from "../../../utils/common";

const Agency = (state) => state.Agency;
const Settings = (state) => state.Settings;
// Is user register successfull then direct plot user in redux.
function * registerUserSaga ({ payload: { data } }) {
  try {
    const response = yield call(registerUser, { data });
    if (response?.success && data.is_client) {
      const { agencies } = yield select(Settings);
      const { stats, clients } = yield select(Agency);
      merge(stats, { total_clients: stats.total_clients + 1 });
      clients.push(response.data);
      agencies.data.push(response.data);
    }
    const { signup_on: signUpOn } = queryString.parse(document.location.search) || {};
    gtm.register();
    if (signUpOn?.includes("extension")) gtm.extensionInstalled(signUpOn);

    if (isInviteUrl() && response?.token) setAuthTokenInBrowser(response?.token);
    yield put(registerUserSuccessful(response));
    if (data.is_client) yield put(setAddClientModal(false));
  } catch (error) {
    yield put(registerUserFailed(error.message));
  }
}

function * getVerificationEmailSaga () {
  try {
    const response = yield call(genVerificationEmail);
    yield put(getVerificationLinkSuccess(response));
  } catch (error) {
    yield put(getVerificationLinkFail(error.message));
  }
}

function * verifyUserSaga ({ payload: { token, history } }) {
  try {
    const response = yield call(verifyUser, { token });
    if (history && response.token) {
      setAuthTokenInBrowser(response.token);
      window.open("https://ecomcircles.com/registration-confirmed/", "_self");
      yield put(resetRegReducer());
    } else yield put(verifyUserSuccess(response));
    gtm.verified();
  } catch (error) {
    yield put(setVerifyError(error.message));
  }
}

function * verifyWareHouseUserSaga ({ payload: { data, history } }) {
  try {
    yield call(verifyWarehouseUser, data);
    history.push("/");
    yield put(verifyWareHouseUserSuccess());
  } catch (error) {
    yield put(setVerifyError(error.message));
  }
}

function * createVerifyTokenSaga ({ payload: { email } }) {
  try {
    const response = yield call(sendVerificationToken, email);
    yield put(sendVerifyTokenSuccess(response));
  } catch (error) {
    yield put(setVerifyError(error.message));
  }
}

export function * watchUserRegister () {
  yield takeEvery(REGISTER_USER, registerUserSaga);
}

export function * watchVerifyUserSaga () {
  yield takeEvery(VERIFY_USER, verifyUserSaga);
}

export function * watchCreateVerifyTokenSaga () {
  yield takeEvery(SEND_VERIFY_TOKEN, createVerifyTokenSaga);
}

export function * watchVerifyWarehouseUserSaga () {
  yield takeEvery(VERIFY_WAREHOUSE_USER, verifyWareHouseUserSaga);
}

export function * watchGenVerifyEmail () {
  yield takeEvery(GET_VERIFICATION_LINK, getVerificationEmailSaga);
}

function * accountSaga () {
  yield all([
    fork(watchUserRegister),
    fork(watchVerifyUserSaga),
    fork(watchVerifyWarehouseUserSaga),
    fork(watchCreateVerifyTokenSaga),
    fork(watchGenVerifyEmail),
  ]);
}

export default accountSaga;
