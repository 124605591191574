import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, CardBody, Card, CardHeader, NavLink, Nav, NavItem } from "reactstrap";
import { fetchActivityLogs, setPreloader } from "../../store/actions";
import CustomPagination from "../../components/Common/CustomPagination";
import moment from "moment";
import { getUserInfo } from "../../utils/common";
import classnames from "classnames";
import { DATE_FORMATS } from "../../constants";
import SeeMoreText from "../../components/Common/SeeMoreText";

// availity-reactstrap-validation
const { DATE_TIME } = DATE_FORMATS;
const ActivityLogs = (props) => {
  const tableHeaders = ["Author", "Email", "Log", "Action Time", "IP"].map((title) => ({ title }));
  const orderLogsHeader = ["Order Id", "Created by", "Note", "Created at"].map((title) => ({ title }));
  const emailLogsHeader = ["To", "Subject", "Created at"].map((title) => ({ title }));
  const { activityLogs, loading } = useSelector((state) => state.Settings);
  const [filters, setFilters] = useState({ search: "", page: 1, per_page: 50, agency: null });
  const [search, setSearch] = useState("");
  const [seeMore, setSeeMore] = useState("");
  const [handleLogs, setHandleLogs] = useState("actvity");
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);

  function fetchLogs (params) {
    dispatch(fetchActivityLogs(params));
  }

  useEffect(() => {
    fetchLogs(filters);
  }, []);

  useEffect(() => {
    fetchLogs(filters);
    setFilters(filters);
  }, [filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);
  function changeTab (tabIndex) {
    if (tab !== tabIndex) {
      setTab(tabIndex);
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Logs" />
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              className={classnames({
                active: tab === 0,
              })}
              onClick={() => {
                changeTab(0);
                setFilters({ ...filters, type: "activity" });
                setHandleLogs("actvity");
              }}
              style={{ cursor: "pointer" }}
            >
              <span className="d-none d-sm-block">Actvity Logs</span>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: tab === 1,
              })}
              onClick={() => {
                changeTab(1);
                setFilters({ ...filters, type: "emails" });
                setHandleLogs("emails");
              }}
              style={{ cursor: "pointer" }}
            >
              <span className="d-none d-sm-block">Email Logs</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: tab === 2,
              })}
              onClick={() => {
                changeTab(2);
                setFilters({ ...filters, type: "orders" });
                setHandleLogs("orders");
              }}
              style={{ cursor: "pointer" }}
            >
              <span className="d-none d-sm-block">Orders Logs</span>
            </NavLink>
          </NavItem>
        </Nav>
        {handleLogs === "actvity" && (
          <Card>
            <CardHeader className={handleLogs === "actvity" ? "p-3" : "p-0"}>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (search.trim()) {
                      setFilters({ ...filters, search: search.trim(), page: 1 });
                    }
                  }}
                >
                  <div className="position-relative" style={{ display: "inline-flex" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          delete filters.search;
                          setSearch("");
                          setFilters({ ...filters });
                        } else setSearch(e.target.value);
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                    <div className="custom-control custom-checkbox ml-2 mt-2" style={{ width: "250px" }}>
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="blocked"
                        checked={!!filters.agency}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() =>
                          setFilters({ ...filters, agency: !filters.agency ? getUserInfo()?.account_id : "" })
                        }
                        className="custom-control-label"
                      >
                        Logs for Dispute
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </CardHeader>
            <CardBody>
              {Array.isArray(activityLogs?.logs) && activityLogs?.logs?.length
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {activityLogs.logs.map((log, i) => (
                          <tr key={"_logs_" + i}>
                            <td>{log.author}</td>
                            <td>{getUserInfo()?.email} </td>
                            <td>
                              <SeeMoreText
                                id={i}
                                value={log.note}
                                length={70}
                                seeMore={seeMore}
                                setSeeMore={setSeeMore}
                                onHoverDisable={true}
                              />
                            </td>
                            <td>{moment(log.created_at).isValid() ? moment(log.created_at).format(DATE_TIME) : ""}</td>
                            <td>{log.ip}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <CustomPagination
                    pageOptions={[50, 100, 250]}
                    total={activityLogs.total}
                    page={activityLogs.page}
                    perPage={activityLogs.perPage}
                    tabsFilter={filters}
                    setTabFilter={(e) =>
                      setFilters({
                        ...filters,
                        page: filters.per_page !== e.per_page ? 1 : e.page,
                        per_page: e.per_page,
                        submit: true,
                      })
                    }
                  />
                </>
                  )
                : null}
              {activityLogs?.logs?.length === 0 && !loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        )}
        {handleLogs === "emails" && (
          <Card>
            <CardHeader className={handleLogs === "actvity" ? "p-3" : "p-0"}>
              <div className="row d-flex align-items-center justify-content-between"></div>
            </CardHeader>
            <CardBody>
              {Array.isArray(activityLogs?.logs) && activityLogs?.logs?.length
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {emailLogsHeader.map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {activityLogs.logs.map((emailLog, i) => (
                          <tr key={"_logs_" + i}>
                            <td>{emailLog?.to}</td>
                            <td>{emailLog?.subject} </td>
                            <td>
                              {moment(emailLog.created_at).isValid()
                                ? moment(emailLog.created_at).format(DATE_TIME)
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <CustomPagination
                    pageOptions={[50, 100, 250]}
                    total={activityLogs.total}
                    page={activityLogs.page}
                    perPage={activityLogs.perPage}
                    tabsFilter={filters}
                    setTabFilter={(e) =>
                      setFilters({
                        ...filters,
                        page: filters.per_page !== e.per_page ? 1 : e.page,
                        per_page: e.per_page,
                        submit: true,
                      })
                    }
                  />
                </>
                  )
                : null}
              {activityLogs?.logs?.length === 0 && !loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        )}
        {handleLogs === "orders" && (
          <Card>
            <CardHeader className={handleLogs === "actvity" ? "p-3" : "p-0"}>
              <div className="row d-flex align-items-center justify-content-between"></div>
            </CardHeader>
            <CardBody>
              {Array.isArray(activityLogs?.logs) && activityLogs?.logs?.length
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {orderLogsHeader.map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {activityLogs.logs.map((orderLog, i) => (
                          <tr key={"_logs_" + i}>
                            <td>{orderLog.source_order_id}</td>
                            <td>{orderLog.created_by} </td>
                            <td>{orderLog.note}</td>
                            <td>
                              {moment(orderLog.created_at).isValid()
                                ? moment(orderLog.created_at).format(DATE_TIME)
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <CustomPagination
                    pageOptions={[50, 100, 250]}
                    total={activityLogs.total}
                    page={activityLogs.page}
                    perPage={activityLogs.perPage}
                    tabsFilter={filters}
                    setTabFilter={(e) =>
                      setFilters({
                        ...filters,
                        page: filters.per_page !== e.per_page ? 1 : e.page,
                        per_page: e.per_page,
                        submit: true,
                      })
                    }
                  />
                </>
                  )
                : null}
              {activityLogs?.logs?.length === 0 && !loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default ActivityLogs;
