import { omit } from "lodash";
import React from "react";
import { Input } from "reactstrap";

const NumberField = (props) => {
  const { isInteger, min, max, onChange, isPositive } = props;
  const invalidKeys = [];
  if (isInteger) invalidKeys.push(".");
  if (isPositive) invalidKeys.push("-");
  const validate = (e) => {
    if (e.target.value.trim() === "") e.target.value = "0";
    const v = +e.target.value;
    let isInValid = false;

    if (!/^[0-9]+$/.test(e.target.value)) isInValid = true;
    if (v < min) isInValid = true;
    else if (v > max) isInValid = true;
    else if (invalidKeys.some((x) => e.key === x)) isInValid = true;
    else if (isInteger && !Number.isInteger(+e.target.value)) isInValid = true;
    if (isInValid) e.preventDefault();
    if (!isInValid) e.target.value = parseFloat(v, 10) || "0";
    return isInValid;
  };
  return (
    <Input
      {...omit(props, "isPositive", "isInteger")}
      type="number"
      onKeyPress={validate}
      onChange={(e) => !validate(e) && onChange(e)}
    />
  );
};

export default NumberField;
