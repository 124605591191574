import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Alert, Input, Label, Col } from "reactstrap";
import CheckBox from "../../../components/Common/CheckBox";
import Modal from "../../../components/Common/Modal";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import { useFormik } from "formik";

// actions
import {
  generateShippingLabel,
  setPreloader,
  refreshSourceOrderDetail,
  getShippingRates,
} from "../../../store/actions";
import { groupBy, keys, map, merge, pick, startCase } from "lodash";

const GenerateShippingLabel = (props) => {
  const dispatch = useDispatch();
  const { shippingRates } = useSelector((state) => state.SourceOrders);
  const { sourceOrder } = props;
  const [dimensions, setDimensions] = useState({});
  const { tracking_items: items } = sourceOrder;
  const checkAll = items?.reduce((acc, cur) => merge(acc, { [cur.id]: true }), {});
  const [checkBox, setCheckBox] = useState(checkAll);
  const groupedRates = groupBy(shippingRates, "shipping_service");

  const toggleModal = () => {
    props.toggleIsOpen(!props.isOpen);
    formik.resetForm();
  };

  const validate = (values) => {
    const errors = {};
    if (!values.shipping_service.value) errors.shipping_service = "Field is Required";
    Object.keys(values).forEach((key) => {
      if (values[key] === "") errors[key] = "Field is Required";
    });
    return errors;
  };

  const numberFields = ["length", "width", "height", "weight"];
  const itemDimensions = () => numberFields.reduce((obj, x) => merge(obj, { [x]: formik.values[x] }), {});

  const formik = useFormik({
    initialValues: merge(
      { shipping_service: { value: "", label: "" } },
      numberFields.reduce((obj, x) => merge(obj, { [x]: "" }), {}),
    ),
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (keys(checkBox).length === 0) toggleModal();
      else {
        const dimensions = numberFields.reduce((obj, x) => merge(obj, { [x]: values[x] }), {});
        dispatch(
          generateShippingLabel({
            id: props.sourceOrder.id,
            data: {
              itemIds: keys(checkBox).map((x) => +x),
              shipping_service: values.shipping_service.value,
              dimensions,
            },
          }),
        );
      }
    },
  });

  const selectedRate = groupedRates[formik.values.shipping_service.value]?.[0]?.rate;
  useEffect(() => {
    props.setPreloader(props.SourceOrders.loading);
  }, [props.SourceOrders.loading]);

  useEffect(() => {
    const isDimensionsFilled = keys(pick(formik.values, numberFields)).every((key) => formik.values[key] !== "");

    if (
      isDimensionsFilled &&
      keys(pick(formik.values, numberFields)).some((x) => formik.values[x] !== dimensions[x]) &&
      !props.SourceOrders.loading
    ) {
      setDimensions(pick(formik.values, numberFields));
      formik.setFieldValue("shipping_service", { shipping_service: { value: "", label: "" } });
      dispatch(
        getShippingRates({
          from_zipcode: sourceOrder.wh_address?.zipcode,
          to_zipcode: sourceOrder?.source_order_address?.zipcode,
          dimensions: itemDimensions(),
        }),
      );
    }
  }, [formik.values]);

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="myLargeModalLabel">
            Generate Shipping Label
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders.error}
            </Alert>
          )}
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th>
                    <CheckBox
                      state={keys(checkBox).length === items?.length}
                      setState={(e) => {
                        if (keys(checkBox).length === items.length) setCheckBox({});
                        else setCheckBox(checkAll);
                      }}
                      isSwitch={false}
                    />
                  </th>
                  <th className="text-center">Warehouse</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Customer Address</th>
                </tr>
              </thead>
              <tbody>
                {items?.map((item, key) => (
                  <tr key={"_tr_" + key}>
                    <td>
                      <CheckBox
                        state={checkBox[item.id] || false}
                        setState={(e) => {
                          if (checkBox[item.id]) delete checkBox[item.id];
                          else checkBox[item.id] = true;
                          setCheckBox({ ...checkBox });
                        }}
                        isSwitch={false}
                      />
                    </td>
                    <td className="text-center">{props.getShipmentAddress(props.sourceOrder?.wh_address)}</td>
                    <td className="text-center">{item?.quantity}</td>
                    <td className="text-center">{props.getShipmentAddress(props.sourceOrder?.source_order_address)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex">
            {map(numberFields, (x, key) => (
              <Col key={"___" + key + "__fields"}>
                <Label>{startCase(x)}</Label>
                <Input
                  name={x}
                  type="number"
                  placeholder={`${startCase(x)} ${x === "weight" ? "(lb)" : "(inches)"}`}
                  min={1}
                  value={formik.values[x]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched[x] && formik.errors[x]
                  ? (
                  <small className="text-danger">{formik.errors[x]}</small>
                    )
                  : null}
              </Col>
            ))}
          </div>
          {shippingRates && (
            <div className="d-flex mt-2">
              <Col lg="2">
                <SimpleSelect
                  value={formik.values.shipping_service}
                  onChange={(val) => formik.setFieldValue("shipping_service", val)}
                  placeholder="Select Shipping Service"
                  onBlur={formik.handleBlur}
                  name="shipping_service"
                  options={map(shippingRates, (x) => ({
                    label: startCase(x.shipping_service),
                    value: x.shipping_service,
                  }))}
                  formStyle={{ minWidth: "250px" }}
                  label="Shipping Service*"
                />
                {formik.touched.shipping_service && formik.errors.shipping_service
                  ? (
                  <small className="text-danger">{formik.errors.shipping_service}</small>
                    )
                  : null}
              </Col>
            </div>
          )}
          {selectedRate && <span className="p-2">Rate: {selectedRate}</span>}
          <div className="d-flex justify-content-end mt-3">
            <Button type="reset" color="secondary" className="mr-3" onClick={toggleModal}>
              Close
            </Button>
            <Button type="submit" color="success" className="mr-3" onClick={formik.handleSubmit}>
              Generate Shipping Label
            </Button>
          </div>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SupplierOrders, CreditCards, SourceOrders } = state;
  return { SupplierOrders, CreditCards, SourceOrders };
};

export default connect(mapStateToProps, { setPreloader, refreshSourceOrderDetail })(GenerateShippingLabel);
