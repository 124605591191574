import React from "react";
import amazonLogo from "../../../assets/svg/amazonLogo.svg";
import shopifyLogo from "../../../assets/svg/shopifyLogo.svg";
import walmartLogo from "../../../assets/svg/walmartLogo.svg";

export default function SupportedMarketplaces () {
  return (
    <div className="approved-logos-wrapper">
      <p>Approved By</p>
      <div
        className="row justify-content-between approve-box rounded"
        style={{ backgroundColor: "white", border: "1px solid #E3E6EF", padding: "8px 24px" }}
      >
        {[
          { logo: amazonLogo, alt: "amazon logo" },
          { logo: walmartLogo, alt: "walmart logo" },
          { logo: shopifyLogo, alt: "shopify logo" },
        ].map(({ logo, alt }, key) => (
          <div className="col-md-4 col-sm-6" key={"__marketplace___" + key}>
            <div className="text-center">
              <img src={logo} alt={alt} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
