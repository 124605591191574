import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";
import { CANCEL_MARKETPLACE_ORDER_OPTIONS, MARKET_PLACES } from "../../../constants/index";
// actions
import { refreshSourceOrderDetail, cancelMarketplaceOrder } from "../../../store/actions";
import { startCase } from "lodash";
import { toPascalCase } from "../../../utils/common";

const CancelMarketPlaceOrderModal = (props) => {
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);

  const validate = (values) => {
    const errors = {};
    !values.cancel_reason && (errors.cancel_reason = "Field is Required");
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      cancel_reason: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.cancelMarketplaceOrder({
        id: props.sourceOrderId,
        cancel_reason: values.cancel_reason,
      });
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Cancel Marketplace Order</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.SourceOrders?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.SourceOrders?.error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12">
                <Label>Select a Reason</Label>
                <div className="form-group">
                  <select
                    name="cancel_reason"
                    value={formik.values.cancel_reason}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="custom-select"
                  >
                    <option value="">Select a Reason</option>
                    {CANCEL_MARKETPLACE_ORDER_OPTIONS[
                      props.isWalmart ? MARKET_PLACES.walmart : MARKET_PLACES.amazon
                    ].map((item, key) => (
                      <option key={`cancel_opt_${key}`} value={item}>
                        {props.isWalmart ? toPascalCase(item) : startCase(item)}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.cancel_reason && formik.errors.cancel_reason
                  ? (
                  <span className="text-danger mt-1">{formik.errors.cancel_reason}</span>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { SourceOrders } = state;
  return { SourceOrders };
};

export default connect(mapStateToProps, {
  refreshSourceOrderDetail,
  cancelMarketplaceOrder,
})(CancelMarketPlaceOrderModal);
