import {
  FETCH_RESTRICTED_ITEMS,
  FETCH_RESTRICTED_ITEMS_SUCCESS,
  SET_SELECTED_ITEMS,
  SET_ITEMS_BULK_UPDATE_MODAL,
  ITEMS_BULK_UPDATE,
  ITEMS_BULK_UPDATE_SUCCESS,
  ADD_RESTRICTED_ITEMS,
  ADD_RESTRICTED_ITEMS_SUCCESS,
  DEL_RESTRICTED_ITEMS,
  DEL_RESTRICTED_ITEMS_SUCCESS,
  UPDATE_RESTRICTED_ITEMS,
  UPDATE_RESTRICTED_ITEMS_SUCCESS,
  SET_ITEM_ADD_MODAL,
  SET_ITEM_EDIT_MODAL,
  API_ERROR,
  SET_ITEM_BULK_ADD_MODAL,
  BULK_ADD_RESTRICTED_ITEMS,
  BULK_ADD_RESTRICTED_ITEMS_SUCCESS,
} from "./actionTypes";

export const fetchRestrictedItems = (params) => {
  return {
    type: FETCH_RESTRICTED_ITEMS,
    payload: { params },
  };
};

export const fetchRestrictedItemsSuccess = (items) => {
  return {
    type: FETCH_RESTRICTED_ITEMS_SUCCESS,
    payload: items,
  };
};

export const setSelectedItems = (id) => {
  return {
    type: SET_SELECTED_ITEMS,
    payload: id,
  };
};

export const setItemsBulkUpdateModal = (bool) => {
  return {
    type: SET_ITEMS_BULK_UPDATE_MODAL,
    payload: bool,
  };
};

export const bulkUpdateItems = (data) => {
  return {
    type: ITEMS_BULK_UPDATE,
    payload: { data },
  };
};

export const bulkUpdateItemsSuccess = () => {
  return {
    type: ITEMS_BULK_UPDATE_SUCCESS,
    payload: {},
  };
};

export const addNewItem = (data) => {
  return {
    type: ADD_RESTRICTED_ITEMS,
    payload: data,
  };
};

export const addNewItemSuccess = (res) => {
  return {
    type: ADD_RESTRICTED_ITEMS_SUCCESS,
    payload: {},
  };
};

export const bulkAddNewItem = (data) => {
  return {
    type: BULK_ADD_RESTRICTED_ITEMS,
    payload: data,
  };
};

export const bulkAddNewItemSuccess = (res) => {
  return {
    type: BULK_ADD_RESTRICTED_ITEMS_SUCCESS,
    payload: {},
  };
};

export const deleteItem = (data) => {
  return {
    type: DEL_RESTRICTED_ITEMS,
    payload: data,
  };
};

export const deleteItemSuccess = (res) => {
  return {
    type: DEL_RESTRICTED_ITEMS_SUCCESS,
    payload: res,
  };
};

export const updateItem = (data) => {
  return {
    type: UPDATE_RESTRICTED_ITEMS,
    payload: data,
  };
};

export const updateItemSuccess = (res) => {
  return {
    type: UPDATE_RESTRICTED_ITEMS_SUCCESS,
    payload: res,
  };
};

export const setAddItemModal = (bool) => {
  return {
    type: SET_ITEM_ADD_MODAL,
    payload: bool,
  };
};

export const setBulkAddItemModal = (bool) => {
  return {
    type: SET_ITEM_BULK_ADD_MODAL,
    payload: bool,
  };
};

export const setEditAddItemModal = (bool) => {
  return {
    type: SET_ITEM_EDIT_MODAL,
    payload: bool,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
