import * as types from "./actionTypes";
const {
  API_ERROR,
  FETCH_TOKEN,
  FETCH_TOKEN_SUCCESS,
  FETCH_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_STATE,
  FETCH_AGENCIES_SUCCESS,
  FETCH_ABILITIES,
  FETCH_ABILITIES_SUCCESS,
  SET_ADD_AGENCY_MODAL,
  ADD_AGENCY_CHILD_ACCOUNT,
  ADD_AGENCY_CHILD_ACCOUNT_SUCCESS,
  INVITE_TO_AGENCY,
  INVITE_TO_AGENCY_SUCCESS,
  SET_VERIFY_ERROR,
  VERIFY_AGENCY_INVITE,
  VERIFY_AGENCY_INVITE_SUCCESS,
  UNLINK_AGENCY,
  UNLINK_AGENCY_SUCCESS,
  FETCH_ACCOUNT_SETTINGS_SUCCESS,
  FETCH_ALL_ACCOUNT_SETTINGS_SUCCESS,
  UPDATE_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  SET_SELECTED_TENAT_ACC,
  SET_CANCEL_FEEDBACK_MODAL,
  SUBMIT_CANCEL_FEEDBACK,
  SUBMIT_CANCEL_FEEDBACK_SUCCESS,
  SET_EDIT_TENAT_ACC_MODAL,
  UPDATE_ONBORDING_FLAG,
  RESET_AVAILABLE_MATCHES,
  FETCH_AO_BOT_STATUS,
  FETCH_AO_BOT_STATUS_SUCCESS,
  CHECK_FOR_AO_SERVICE_UPDATE,
  CHECK_FOR_AO_SERVICE_UPDATE_SUCCESS,
  FETCH_AVAILABLE_MATCH_COUNTS,
  FETCH_AVAILABLE_MATCH_COUNTS_SUCCESS,
  SUBMIT_MATCHES_REQUEST,
  SUBMIT_MATCHES_REQUEST_SUCCESS,
  FETCH_CANCELLED_SUBSCRIPTIONS,
  FETCH_CANCELLED_SUBSCRIPTIONS_SUCCESS,
  SET_RETURN_ADDRESS_MODAL,
  SET_SETTING_MESSAGE,
  FETCH_PROXIES,
  FETCH_PROXIES_SUCCESS,
  FETCH_ACTIVITY_LOGS,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  SET_TRIGGERS_MODAL,
  FETCH_STORE_WISE_STATS,
  FETCH_STORE_WISE_STATS_SUCCESS,
  FETCH_TENANT_WISE_STATS,
  FETCH_TENANT_WISE_STATS_SUCCESS,
  SET_AGENCY_PROFIT_MODAL,
  UPDATE_AGENCY_PROFIT,
  UPDATE_AGENCY_PROFIT_SUCCESS,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION,
  SET_SHOW_ONBOARDING,
  SET_ONBOARDING_STEP,
  SET_ONBOARDING_FLOW,
  FILTER_ALL_TENANTS,
  FILTER_ALL_TENANTS_SUCCESS,
  GET_PAYMENT_FAILED_USERS,
  GET_PAYMENT_FAILED_USERS_SUCCESS,
  GET_CANCELED_USERS,
  GET_CANCELED_USERS_SUCCESS,
  GET_STATUSES_COUNT,
  GET_STATUSES_COUNT_SUCCESS,
  GET_DROPDOWN_DATA,
  GET_DROPDOWN_DATA_SUCCESS,
  GET_AGENCY_DROPDOWN_DATA,
  GET_AGENCY_DROPDOWN_DATA_SUCCESS,
  FETCH_MATCHES_REQUESTS_STATUS_SUCCESS,
  FETCH_MATCHES_REQUESTS_STATUS,
  SKIP_ONBOARD_AGENCY,
  SET_DASHBOARD_PDF_OPTS,
  SET_ADD_LABEL_GENERATION_SERVICE,
  ADD_STAMP_CREDENTIALS,
  ADD_STAMP_CREDENTIALS_SUCCESS,
  FETCH_STAMPS_CREDS,
  FETCH_STAMPS_CREDS_SUCCESS,
  DELETE_STAMPS_CREDS,
  DELETE_STAMPS_CREDS_SUCCESS,
  ENABLE_STAMPS_CREDS,
  ENABLE_STAMPS_CREDS_SUCCESS,
} = types;

export const setDashboardPdfOpts = (payload) => ({ type: SET_DASHBOARD_PDF_OPTS, payload });

export const fetchToken = (email, history, qP) => ({
  type: FETCH_TOKEN,
  payload: { email, history, qP },
});

export const fetchTokenSuccess = () => ({
  type: FETCH_TOKEN_SUCCESS,
  payload: {},
});

export const refreshAuthToken = () => ({ type: types.REFRESH_AUTH_TOKEN });
export const refreshAuthTokenSuccess = () => ({ type: types.REFRESH_AUTH_TOKEN_SUCCESS });

export const fetchAvailableMatchCounts = (params) => {
  return {
    type: FETCH_AVAILABLE_MATCH_COUNTS,
    payload: { params },
  };
};

export const fetchAvailableMatchCountsSuccess = (matches) => {
  return {
    type: FETCH_AVAILABLE_MATCH_COUNTS_SUCCESS,
    payload: matches,
  };
};

export const submitMatchesRequest = (data) => {
  return {
    type: SUBMIT_MATCHES_REQUEST,
    payload: { data },
  };
};

export const submitMatchesRequestSuccess = () => {
  return {
    type: SUBMIT_MATCHES_REQUEST_SUCCESS,
    payload: {},
  };
};

export const fetchStoreWiseStats = (params) => ({
  type: FETCH_STORE_WISE_STATS,
  payload: params,
});

export const fetchStoreWiseStatsSuccess = (res) => ({
  type: FETCH_STORE_WISE_STATS_SUCCESS,
  payload: res,
});

export const fetchTenantWiseStats = (params) => ({
  type: FETCH_TENANT_WISE_STATS,
  payload: params,
});

export const fetchTenantWiseStatsSuccess = (res) => ({
  type: FETCH_TENANT_WISE_STATS_SUCCESS,
  payload: res,
});

export const fetchActivityLogs = (search) => ({
  type: FETCH_ACTIVITY_LOGS,
  payload: search,
});

export const fetchActivityLogsSuccess = (logs) => ({
  type: FETCH_ACTIVITY_LOGS_SUCCESS,
  payload: logs,
});

export const fetchAccountSettings = (isAllTenants) => ({
  type: FETCH_ACCOUNT_SETTINGS,
  payload: { isAllTenants },
});

export const updateAccountStatus = (account) => ({
  type: UPDATE_ACCOUNT_STATE,
  payload: account,
});

export const fetchAccountSettingsSuccess = (res) => ({
  type: FETCH_ACCOUNT_SETTINGS_SUCCESS,
  payload: res,
});

export const setTriggersModal = (bool) => ({
  type: SET_TRIGGERS_MODAL,
  payload: bool,
});

export const fetchAllAccountSettingsSuccess = (res) => ({
  type: FETCH_ALL_ACCOUNT_SETTINGS_SUCCESS,
  payload: res,
});

export const updateAccountSettings = (data, id) => ({
  type: UPDATE_ACCOUNT_SETTINGS,
  payload: { data, id },
});

export const fetchAOBotStatus = () => ({
  type: FETCH_AO_BOT_STATUS,
  payload: {},
});

export const fetchAOBotStatusSuccess = (res) => ({
  type: FETCH_AO_BOT_STATUS_SUCCESS,
  payload: res,
});

export const resetAvailableMatches = () => ({
  type: RESET_AVAILABLE_MATCHES,
  payload: {},
});

export const fetchAbilities = () => ({
  type: FETCH_ABILITIES,
  payload: {},
});

export const fetchProxies = () => ({
  type: FETCH_PROXIES,
  payload: {},
});

export const fetchProxiesSuccess = (data) => ({
  type: FETCH_PROXIES_SUCCESS,
  payload: data,
});

export const setReturnAddressModal = (bool) => {
  return {
    type: SET_RETURN_ADDRESS_MODAL,
    payload: bool,
  };
};

export const setSettingMessage = (data = {}) => {
  return {
    type: SET_SETTING_MESSAGE,
    payload: data,
  };
};

export const fetchAbilitiesSuccess = (abilities) => ({
  type: FETCH_ABILITIES_SUCCESS,
  payload: abilities,
});

export const fetchAgenciesSuccess = (data) => ({
  type: FETCH_AGENCIES_SUCCESS,
  payload: { data },
});

export const setAddAgencyModal = (bool) => ({
  type: SET_ADD_AGENCY_MODAL,
  payload: bool,
});

export const setLabelGenarationModel = (bool) => ({
  type: SET_ADD_LABEL_GENERATION_SERVICE,
  payload: bool,
});

export const addAgencyChildAccount = ({ data }) => {
  return {
    type: ADD_AGENCY_CHILD_ACCOUNT,
    payload: { data },
  };
};

export const addAgencyChildAccountSuccess = () => {
  return {
    type: ADD_AGENCY_CHILD_ACCOUNT_SUCCESS,
    payload: {},
  };
};

export const inviteToAgency = ({ data }) => {
  return {
    type: INVITE_TO_AGENCY,
    payload: { data },
  };
};

export const inviteToAgencySuccess = () => {
  return {
    type: INVITE_TO_AGENCY_SUCCESS,
    payload: {},
  };
};

export const addStampsCreds = (data) => {
  return {
    type: ADD_STAMP_CREDENTIALS,
    payload: { data },
  };
};

export const addStampsCredsSuccess = (data) => {
  return {
    type: ADD_STAMP_CREDENTIALS_SUCCESS,
    payload: data,
  };
};

export const fetchStampsCreds = () => {
  return {
    type: FETCH_STAMPS_CREDS,
    payload: {},
  };
};

export const fetchStampsCredsSuccess = ({ data }) => {
  return {
    type: FETCH_STAMPS_CREDS_SUCCESS,
    payload: data,
  };
};

export const deleteStampsCreds = () => {
  return {
    type: DELETE_STAMPS_CREDS,
    payload: {},
  };
};

export const deleteStampsCredsSuccess = ({ data }) => {
  return {
    type: DELETE_STAMPS_CREDS_SUCCESS,
    payload: data,
  };
};

export const enableStampsCreds = (data) => {
  return {
    type: ENABLE_STAMPS_CREDS,
    payload: data,
  };
};

export const enableStampsCredsSuccess = ({ data }) => {
  return {
    type: ENABLE_STAMPS_CREDS_SUCCESS,
    payload: data,
  };
};

export const verifyAgency = (token) => {
  return {
    type: VERIFY_AGENCY_INVITE,
    payload: { token },
  };
};

export const verifyAgencySuccess = (agency) => {
  return {
    type: VERIFY_AGENCY_INVITE_SUCCESS,
    payload: agency,
  };
};

export const setVerifyError = (err) => {
  return {
    type: SET_VERIFY_ERROR,
    payload: err,
  };
};

export const unlinkAgency = (id) => {
  return {
    type: UNLINK_AGENCY,
    payload: { id },
  };
};

export const setSkipAgency = () => {
  return {
    type: SKIP_ONBOARD_AGENCY,
    payload: true,
  };
};

export const unlinkAgencySuccess = () => {
  return {
    type: UNLINK_AGENCY_SUCCESS,
    payload: {},
  };
};

export const updateOnboardingSettings = (data) => ({
  type: UPDATE_ONBORDING_FLAG,
  payload: { data },
});

export const updateAccountSettingsSuccess = () => ({
  type: UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  payload: {},
});

export const setSelectedTenantAcc = (acc) => ({
  type: SET_SELECTED_TENAT_ACC,
  payload: acc,
});

export const fetchCancelledSubscriptions = () => ({
  type: FETCH_CANCELLED_SUBSCRIPTIONS,
  payload: {},
});

export const fetchCancelledSubscriptionsSuccess = (data) => ({
  type: FETCH_CANCELLED_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const filterAllTenants = (params) => {
  return {
    type: FILTER_ALL_TENANTS,
    payload: { params },
  };
};

export const filterAllTenantsSuccess = (res) => {
  return {
    type: FILTER_ALL_TENANTS_SUCCESS,
    payload: res,
  };
};

export const getPaymentFailedUsers = () => {
  return {
    type: GET_PAYMENT_FAILED_USERS,
    payload: {},
  };
};

export const getPaymentFailedUsersSuccess = (res) => {
  return {
    type: GET_PAYMENT_FAILED_USERS_SUCCESS,
    payload: res,
  };
};

export const getCanceledUsers = () => {
  return {
    type: GET_CANCELED_USERS,
    payload: {},
  };
};

export const getCanceledUserSuccess = (res) => {
  return {
    type: GET_CANCELED_USERS_SUCCESS,
    payload: res,
  };
};

export const getStatusesCount = () => {
  return {
    type: GET_STATUSES_COUNT,
    payload: {},
  };
};

export const getStatusesCountSuccess = (res) => {
  return {
    type: GET_STATUSES_COUNT_SUCCESS,
    payload: res,
  };
};

export const getDropdownData = (params) => {
  return {
    type: GET_DROPDOWN_DATA,
    payload: { params },
  };
};

export const getDropDownDataSuccess = (res) => {
  return {
    type: GET_DROPDOWN_DATA_SUCCESS,
    payload: res,
  };
};

export const getAgencyDropDown = () => {
  return {
    type: GET_AGENCY_DROPDOWN_DATA,
    payload: {},
  };
};

export const getAgencyDropDownSuccess = (res) => {
  return {
    type: GET_AGENCY_DROPDOWN_DATA_SUCCESS,
    payload: res,
  };
};

export const setEditTenantAccModal = (bool) => ({
  type: SET_EDIT_TENAT_ACC_MODAL,
  payload: bool,
});

export const setCancelFeedbackModal = (bool) => ({
  type: SET_CANCEL_FEEDBACK_MODAL,
  payload: bool,
});

export const submitCancelFeedback = (data) => {
  return {
    type: SUBMIT_CANCEL_FEEDBACK,
    payload: data,
  };
};

export const submitCancelFeedbackSuccess = () => {
  return {
    type: SUBMIT_CANCEL_FEEDBACK_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const checkForAOStatusUpdate = (data) => {
  return {
    type: CHECK_FOR_AO_SERVICE_UPDATE,
    payload: data,
  };
};

export const checkForAOStatusUpdateSuccess = () => {
  return {
    type: CHECK_FOR_AO_SERVICE_UPDATE_SUCCESS,
    payload: "AO service update successfully",
  };
};

export const setAgencyProfitModal = (bool) => {
  return {
    type: SET_AGENCY_PROFIT_MODAL,
    payload: bool,
  };
};

export const updateAgencyProfit = (data) => {
  return {
    type: UPDATE_AGENCY_PROFIT,
    payload: data,
  };
};

export const updateAgencyProfitSuccess = (data) => {
  return {
    type: UPDATE_AGENCY_PROFIT_SUCCESS,
    payload: data,
  };
};

export const fetchNotifications = (id, offset) => {
  return {
    type: FETCH_NOTIFICATIONS,
    payload: { id, offset },
  };
};

export const fetchNotificationsSuccess = (data) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const createNotification = (data) => {
  return {
    type: CREATE_NOTIFICATION,
    payload: data,
  };
};

export const createNotificationSuccess = () => {
  return {
    type: CREATE_NOTIFICATION_SUCCESS,
  };
};

export const deleteNotification = (id) => {
  return {
    type: DELETE_NOTIFICATION,
    payload: id,
  };
};

export const setShowOnboarding = (bool) => {
  return {
    type: SET_SHOW_ONBOARDING,
    payload: bool,
  };
};

export const setOnboardingStep = (integer) => {
  return {
    type: SET_ONBOARDING_STEP,
    payload: integer,
  };
};

export const setOnboardingFlow = (bool) => {
  return {
    type: SET_ONBOARDING_FLOW,
    payload: bool,
  };
};

export const setAddRepricerStore = (params) => ({ type: types.SET_ADD_REPRICER_STORE, payload: params });

export const fetchFulfillMatchesStatus = () => ({
  type: FETCH_MATCHES_REQUESTS_STATUS,
  payload: {},
});

export const fetchFulfillMatchesStatusSuccess = (data) => ({
  type: FETCH_MATCHES_REQUESTS_STATUS_SUCCESS,
  payload: data,
});

export const updateAgencyName = (id, body, type) => ({
  type: types.UPDATE_AGENCY_NAME,
  payload: { id, body, type },
});

export const updateAgencyNameSuccess = (payload) => ({
  type: types.UPDATE_AGENCY_NAME_SUCCESS,
  payload,
});
