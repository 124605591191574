import {
  FETCH_SUPPLIERS,
  FETCH_SUPPLIERS_SUCCESS,
  CREATE_SUPPLIER,
  CREATE_SUPPLIER_SUCCESS,
  UPDATE_AO_STATUS,
  UPDATE_AO_STATUS_SUCCESS,
  DELETE_SUPPLIER,
  DELETE_SUPPLIER_SUCCESS,
  RESET_STATE,
  FETCH_AO_STATUS,
  FETCH_AO_STATUS_SUCCESS,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  API_ERROR,
  SET_SKU_AS_MISMATCH,
  SET_SKU_AS_MISMATCH_SUCCESS,
  SET_ACTION_SUPPLIER,
  UPDATE_STOCK,
  UPDATE_STOCK_SUCCESS,
  LISTING_SUPPLIERS_COLLAPSE,
  SET_STOCK_MODAL,
  FETCH_REFRESHERS,
  FETCH_REFRESHERS_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  suppliers: [],
  platforms: [],
  loading: false,
  aoStatuses: [],
  refresherStats: [],
  actionSupplier: "",
  listingSupplierCollapse: false,
  stockUpdateModalIsOpen: false,
};

const Suppliers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUPPLIERS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_SUPPLIERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: true,
        ...action.payload,
      };
      break;
    case CREATE_SUPPLIER:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case CREATE_SUPPLIER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Supplier Created Successfuly",
      };
      break;
    case UPDATE_AO_STATUS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_AO_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Verified Match Status Updated Successfully",
      };
      break;
    case DELETE_SUPPLIER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DELETE_SUPPLIER_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Supplier Deleted Successfully",
        loading: false,
      };
      break;
    case FETCH_AO_STATUS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_AO_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        aoStatuses: action.payload,
      };
      break;
    case UPDATE_SUPPLIER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_SUPPLIER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Supplier Update Successfully",
      };
      break;
    case SET_SKU_AS_MISMATCH:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case SET_SKU_AS_MISMATCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "SKU Match Status Updated Successfully",
      };
      break;
    case SET_ACTION_SUPPLIER:
      state = {
        ...state,
        actionSupplier: action.payload,
      };
      break;
    case UPDATE_STOCK:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_STOCK_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Stock Updated Sucessfully.",
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        success: false,
        loading: false,
      };
      break;
    case RESET_STATE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
      break;
    case SET_STOCK_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        stockUpdateModalIsOpen: action.payload,
      };
      break;
    case LISTING_SUPPLIERS_COLLAPSE:
      state = {
        ...state,
        listingSupplierCollapse: action.payload,
      };
      break;
    case FETCH_REFRESHERS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_REFRESHERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        refresherStats: action.payload.data,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Suppliers;
