import React from "react";
import { UncontrolledAlert } from "reactstrap";

const SimpleAlert = ({ msg, color, className }) => (
  <UncontrolledAlert color={color || "success"} className={className} role="alert">
    <i className="bx bx-info-circle pr-2" />
    {msg}
  </UncontrolledAlert>
);

export default SimpleAlert;
