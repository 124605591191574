import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Label, FormGroup, Input, Alert } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import { State, Country } from "country-state-city";
import CheckBox from "../../../components/Common/CheckBox";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import moment from "moment";
// actions
import {
  fetchCardTypes,
  updateCreditCards,
  createCreditCards,
  setAddCreditCardsModal,
  setEditCreditCardsModal,
} from "../../../store/actions";
import { decode as base64Decode, getUserInfo, verifyRole } from "../../../utils/common";
const cardIds = [27, 23, 298];
const defaultCountry = Country.getAllCountries()?.find((x) => ["US"].includes(x.isoCode));

const EditCreditCardsModal = (props) => {
  const isAllowed = verifyRole("owner");
  const [isLoading, setIsLoading] = useState(false);
  const [cardOpts, setCardOpts] = useState([]);
  const [phone, setPhone] = useState();
  const [localPhoneNumberError, setLocalPhoneNumberError] = useState("");

  const initialValues = {
    card_holder_name: "",
    card_no: "",
    is_blocked: false,
    primary: false,
    address: "",
    address_2: "",
    country: { label: defaultCountry.name, value: defaultCountry.isoCode },
    state: { label: "Select State", value: "" },
    city: "",
    zip: "",
    card_expiry_year: "",
    card_expiry_month: "",
    is_custom: false,
    should_add_by_ao: false,
    cvv: "",
  };
  const toggleModal = () => {
    props.type === "edit"
      ? props.setEditCreditCardsModal(!props.CreditCards.editCreditCardModalIsOpen)
      : props.setAddCreditCardsModal(!props.CreditCards.addCreditCardModalIsOpen);
    formik.resetForm();
  };
  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || values[key].label === "" || values[key].value === "")
        !["primary", "address_2", "cvv", "is_blocked"].includes(key) && (errors[key] = "Field is Required");
    });
    values.zip &&
      !/^([a-zA-Z0-9]+-)*[a-zA-Z0-9]+$/.test(values.zip) &&
      (errors.zip = "Only alphanumeric and hyphen allowed");

    values.card_expiry_year &&
      moment().year() > values.card_expiry_year &&
      (errors.card_expiry_year = "Previous date not allowed");

    values.card_holder_name &&
      !/^((?:[A-Za-zÀ-ÿ]+ ?){1,5})$/.test(values.card_holder_name) &&
      (errors.card_holder_name = "Enter valid name");

    values.cvv && !/^\d+$/.test(values.cvv) && (errors.cvv = "Only numbers allowed");

    (props.type === "edit" ? cardIds.includes(getUserInfo()?.id) : true) &&
      values.card_no &&
      (!/^\d+$/.test(values.card_no) || /^[^1-9]+$/.test(values.card_no)) &&
      (errors.card_no = "Enter valid number");
    values.city && !/^[A-Za-z\s]+$/.test(values.city) && (errors.city = "Only alphabets are allowed");
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (!phone || phone === ("+1" + "*".repeat(8))) setLocalPhoneNumberError("Phone number required");
      if (!isPossiblePhoneNumber(phone) || localPhoneNumberError) return;

      values.phone = phone;
      const data = { ...values };
      if (props.type === "edit" && props.CreditCards.selectedCreditCard) {
        if (!cardIds.includes(getUserInfo()?.id)) {
          delete data.card_no;
          delete data.phone;
        }
        props.updateCreditCards({
          data: {
            ...data,
            card_type: data.card_type.value || data.card_type,
            state: data.state.value,
            country: data.country.value,
          },
          id: props.CreditCards.selectedCreditCard.id,
        });
      } else {
        props.createCreditCards({
          data: {
            ...data,
            card_type: data.card_type.value || data.card_type,
            state: data.state.value,
            country: data.country.value,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (props.CreditCards.selectedCreditCard && props.type === "edit") {
      const boolCols = ["primary", "is_blocked", "should_add_by_ao"];
      Object.keys(initialValues).forEach((key) => {
        !["card_expiry_year", "card_expiry_month", "cvv", ...boolCols].includes(key) &&
          props.CreditCards.selectedCreditCard[key] &&
          formik.setFieldValue(key, props.CreditCards.selectedCreditCard[key], false);
      });
      boolCols.forEach((key) => formik.setFieldValue(key, Boolean(props.CreditCards.selectedCreditCard[key]), false));
      const { card_expiry_year: expYear, card_expiry_month: expMonth, card_type_id: typeId, state, country, cvv, phone } =
        props.CreditCards.selectedCreditCard;
      const ccType = props.CreditCards?.ccTypes?.find((x) => x.id === typeId);
      const selectedCountry = Country.getCountryByCode(country);
      const selectedState = State.getStatesOfCountry(country)?.find((x) => x.isoCode === state);
      formik.setFieldValue("card_expiry_year", base64Decode(expYear));
      formik.setFieldValue("cvv", cvv);
      setPhone(!phone.includes("+") ? `+1${phone}` : `${phone}`);
      formik.setFieldValue(
        "card_expiry_month",
        (base64Decode(expMonth).length < 2 ? "0" : "") + base64Decode(expMonth),
      );
      formik.setFieldValue("card_type", {
        label: ccType?.name,
        value: ccType?.id,
      });
      formik.setFieldValue("state", {
        label: selectedState?.name,
        value: selectedState?.isoCode,
      });
      formik.setFieldValue("country", {
        label: selectedCountry?.name,
        value: selectedCountry?.isoCode,
      });
    }
    if (!cardOpts.length)
      setCardOpts(
        props.CreditCards.ccTypes.map(({ name, id }) => ({
          label: name,
          value: id,
          isCustom: false,
        })),
      );
  }, [props.CreditCards.selectedCreditCard, props.CreditCards.ccTypes]);

  useEffect(() => {
    !props.CreditCards.ccTypes.length && props.fetchCardTypes({});
  }, []);

  useEffect(() => {
    props.CreditCards.success && !props.CreditCards.loading && toggleModal();
  }, [props.CreditCards.success, props.CreditCards.loading]);

  const handleCreateType = (newType) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOpt = { label: newType, value: newType, isCustom: true };
      setIsLoading(false);
      cardOpts.push(newOpt);
      setCardOpts(cardOpts);
      formik.setFieldValue("card_type", newOpt);
      formik.setFieldValue("is_custom", true);
    }, 1000);
  };

  const removeCountry = [
    "Aland Islands",
    "Anguilla",
    " Antarctica",
    "Aruba",
    "Bouvet Island",
    "British Indian Ocean Territory",
    "Cayman Islands",
    "Christmas Island",
    "Cocos(Keeling) Island",
    "Cook Island",
    "Falklands Islands",
    "Faroe Islands",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gibraltar",
    "Greenland",
    "Guadeloupe",
    "Guam",
    "Guernsey and Alderney",
    "Heard Island and Macdonald Island",
    "Jersey",
    "Macau S.A.R",
    "Man(Isle of)",
    "Martinique",
    "Mayotte",
    "Montserrat",
    "Bonaire, Sint Eustatius and saba",
    "New Caledonia",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Island",
    "Palestinian Territory Occupied",
    "Pitcairn Island",
    "Puerto Rico",
    "Reunion",
    "Saint Helena",
    "Saint Pierre and Miquelon",
    "Saint - Barthelemy",
    "Saint - Martin(French part)",
    "South Georgia",
    "Svalbard And Jan Mayen Islands",
    "Tokelau",
    "Turks and Caicos Islands",
    "United States Minor Outlying Islands",
    "Vatican City State",
    "Virgin Islands(British)",
    "Virgin Islands(US)",
    "Wallis And Futuna Islands",
    "Western Sahara",
    "Curacao",
    "Sint Maarten(Dutch Part)",
  ];
  const selectCountry = Country?.getAllCountries().filter((item) => !removeCountry.includes(item.name));

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.CreditCards.addCreditCardModalIsOpen || props.CreditCards.editCreditCardModalIsOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.type === "edit" ? "EDIT CREDIT CARD(S)" : "ADD CREDIT CARD(S)"}</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.CreditCards?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.CreditCards?.error || ""}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="6 mt-2">
                <Label>Card Holder Name</Label>
                <Input
                  name="card_holder_name"
                  value={formik.values.card_holder_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.card_holder_name && formik.errors.card_holder_name
                  ? (
                  <small className="text-danger mt-1">{formik.errors.card_holder_name}</small>
                    )
                  : null}
              </Col>
              <Col lg="6 mt-2">
                <FormGroup className="select2-container">
                  <Label>Card Type</Label>
                  <CreatableSelect
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={(option) => {
                      formik.setFieldValue("is_custom", option.isCustom);
                      formik.setFieldValue("card_type", option);
                    }}
                    onCreateOption={handleCreateType}
                    options={cardOpts}
                    value={formik.values.card_type || ""}
                    classNamePrefix="select2-selection"
                    id={"cardType"}
                  />
                </FormGroup>
                {formik.touched.card_type && formik.errors.card_type
                  ? (
                  <small className="text-danger mt-1">{formik.errors.card_type}</small>
                    )
                  : null}
              </Col>
              <Col lg="6 mt-2">
                <div className="d-flex">
                  <Label>Card Number</Label>
                </div>
                <Input
                  name="card_no"
                  minLength={12}
                  maxLength={19}
                  value={formik.values.card_no}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={props.type === "edit" ? !cardIds.includes(getUserInfo()?.id) : false}
                />
                {formik.touched.card_no && formik.errors.card_no
                  ? (
                  <small className="text-danger mt-1">{formik.errors.card_no}</small>
                    )
                  : null}
              </Col>
              <Col lg="6 mt-2">
                <Label>Phone</Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="US"
                  international={true}
                  countryCallingCodeEditable={false}
                  value={phone}
                  onChange={(phone) => {
                    const isPhonePossible = phone
                      ? isPossiblePhoneNumber(phone)
                        ? setLocalPhoneNumberError()
                        : "Invalid phone number"
                      : "Phone number required";
                    if (isPhonePossible) {
                      setLocalPhoneNumberError(isPhonePossible);
                      return;
                    }
                    setPhone(phone);
                  }}
                />
                {localPhoneNumberError ? <small className="text-danger mt-1">{localPhoneNumberError}</small> : null}
              </Col>
              <Col lg="6 mt-2">
                <Label>Address line 1</Label>
                <Input
                  name="address"
                  value={formik.values.address}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.address && formik.errors.address
                  ? (
                  <small className="text-danger mt-1">{formik.errors.address}</small>
                    )
                  : null}
              </Col>
              <Col lg="6 mt-2">
                <Label>Address line 2</Label>
                <Input
                  name="address_2"
                  value={formik.values.address_2}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.address_2 && formik.errors.address_2
                  ? (
                  <small className="text-danger mt-1">{formik.errors.address_2}</small>
                    )
                  : null}
              </Col>
              <Col lg="3 mt-2">
                <Label>City</Label>
                <Input
                  name="city"
                  value={formik.values.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city
                  ? (
                  <small className="text-danger mt-1">{formik.errors.city}</small>
                    )
                  : null}
              </Col>
              <Col lg="3 mt-2">
                <FormGroup className="select2-container">
                  <Label>Country</Label>
                  <Select
                    name="country"
                    value={formik.values.country}
                    onBlur={formik.handleBlur}
                    onChange={(option) => {
                      formik.setFieldValue("country", option);
                      formik.setFieldValue("state", "");
                    }}
                    options={selectCountry
                      .filter((x) => !["AS"].includes(x.isoCode))
                      .map((y) => ({
                        label: y.name,
                        value: y.isoCode,
                      }))}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                {formik.touched.country && formik.errors.country
                  ? (
                  <small className="text-danger mt-1">{formik.errors.country}</small>
                    )
                  : null}
              </Col>
              <Col lg="3 mt-2">
                <FormGroup className="select2-container">
                  <Label>State</Label>
                  <Select
                    name="state"
                    value={formik.values.state}
                    onChange={(option) => {
                      formik.setFieldValue("state", option);
                    }}
                    options={State.getStatesOfCountry(formik.values.country?.value || "").map((x) => ({
                      label: x.name,
                      value: x.isoCode,
                    }))}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
                {formik.touched.state && formik.errors.state
                  ? (
                  <small className="text-danger mt-1">{formik.errors.state}</small>
                    )
                  : null}
              </Col>
              <Col lg="3 mt-2">
                <Label>Zip Code</Label>
                <Input
                  name="zip"
                  maxLength={15}
                  value={formik.values.zip}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.state && formik.errors.zip
                  ? (
                  <small className="text-danger mt-1">{formik.errors.zip}</small>
                    )
                  : null}
              </Col>
              <Col lg="6 mt-2">
                <Label>Card Expiry</Label>
                <Input
                  name="expiryDate"
                  type="month"
                  value={
                    formik.values.card_expiry_year +
                    "-" +
                    (formik.values.card_expiry_month.length < 2 ? "0" : "") +
                    formik.values.card_expiry_month
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (e.target.value) {
                      const date = e.target.value.split("-");
                      formik.setFieldValue("card_expiry_year", date[0].toString());
                      formik.setFieldValue("card_expiry_month", date[1].toString());
                    }
                  }}
                />
                {formik.touched.card_expiry_year && formik.errors.card_expiry_year
                  ? (
                  <small className="text-danger mt-1">{formik.errors.card_expiry_year}</small>
                    )
                  : null}
              </Col>
              <Col lg="3 mt-2">
                {isAllowed
                  ? (
                  <>
                    <Label>CVV</Label>
                    <Input
                      name="cvv"
                      maxLength={4}
                      value={formik.values.cvv}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.cvv && formik.errors.cvv
                      ? (
                      <small className="text-danger mt-1">{formik.errors.cvv}</small>
                        )
                      : null}
                  </>
                    )
                  : null}
              </Col>
              <Col lg="3"></Col>
              <Col md="3" className="d-flex align-items-center mt-4">
                <CheckBox
                  name="is_blocked"
                  state={Boolean(formik.values.is_blocked)}
                  isSwitch={true}
                  setState={() => formik.setFieldValue("is_blocked", !formik.values.is_blocked)}
                />
                <Label className="mt-2">
                  <span>Blocked</span>
                </Label>
              </Col>
              <Col md="3" className="d-flex align-items-center mt-4">
                <CheckBox
                  name="primary"
                  state={Boolean(formik.values.primary)}
                  isSwitch={true}
                  setState={() => formik.setFieldValue("primary", !formik.values.primary)}
                />
                <Label className="mt-2">
                  <span>Primary</span>
                </Label>
              </Col>
              <Col md="3" className="d-flex align-items-center mt-4">
                <CheckBox
                  name="should_add_by_ao"
                  state={Boolean(formik.values.should_add_by_ao)}
                  isSwitch={true}
                  setState={() => formik.setFieldValue("should_add_by_ao", !formik.values.should_add_by_ao)}
                />
                <Label className="mt-2">
                  <span>Should Add By AO</span>
                </Label>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { CreditCards } = state;
  return { CreditCards };
};

export default connect(mapStateToProps, {
  fetchCardTypes,
  updateCreditCards,
  createCreditCards,
  setAddCreditCardsModal,
  setEditCreditCardsModal,
})(EditCreditCardsModal);
