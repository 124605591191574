import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, FormGroup, Label } from "reactstrap";
import { Link } from "react-router-dom";
import PillBtn from "../../../components/Common/PillBtn";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { omit } from "lodash";
import { downloadFile, formatNumber, humanize } from "../../../utils/common";

// actions
import { fetchTopSellingItems, fetchGlobalTopSellingItems } from "../../../store/actions";
import DateRange from "../../../components/Common/DateRange";

const TopSellingItems = (props) => {
  const dispatch = useDispatch();
  const [enableCategory, setEnableCategory] = useState(false);
  const userId = props.userId;
  const {
    topSellingItems,
    topSellingCategories,
    globalTopSellingItems,
    skusCategories,
    globalSkusCategories,
    loading,
  } = useSelector((state) => state.Graph);
  const type = enableCategory ? "category" : props.type;

  const [dates, setDates] = useState([moment().subtract(30, "days"), moment()]);

  const [tabsFilter, setTabFilter] = useState({
    ...(props.type !== "global" && { sort_by: "sales" }),
    ...(props.type !== "global" && { sort_direction: "desc" }),
    ...(props.type !== "global" && { filter_index: 0 }),
    start_date: dates[0].format("YYYY-MM-DD"),
    end_date: dates[1].format("YYYY-MM-DD"),
  });

  const fetchDetails = () => {
    switch (type) {
      case "global":
        return dispatch(fetchGlobalTopSellingItems(tabsFilter));
      case "category":
        return dispatch(fetchTopSellingItems(tabsFilter));
      default:
        return dispatch(fetchTopSellingItems(omit(tabsFilter, ["type"])));
    }
  };

  const colMapping = {
    Sales: "sales",
    "Profit/Avg": "avg_profit",
    Profit: "profit",
    Orders: "count",
  };

  const tableHeaders = [
    {
      title: "Item",
    },
    {
      title: "Price",
    },
    {
      title: "Sales",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "sales",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 0,
        }),
    },
    {
      title: "Profit/Avg",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "avg_profit",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 1,
        }),
    },
    {
      title: "Profit",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "profit",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 2,
        }),
    },
    {
      title: "Orders",
      sort: true,
      onClick: () =>
        setTabFilter({
          ...tabsFilter,
          sort_by: "count",
          sort_direction: tabsFilter.sort_direction === "desc" ? "asc" : "desc",
          filter_index: 3,
        }),
    },
  ];

  const tableHeadersCategories = [
    {
      title: "Category",
    },
    {
      title: "Orders",
    },
  ];

  const globalStatsTableHeaders = [
    { title: "Item" },
    { title: "Sales" },
    { title: "Profit/Avg" },
    { title: "Profit" },
    { title: "Orders" },
  ];

  useEffect(() => {
    fetchDetails();
  }, [tabsFilter]);

  useEffect(() => {
    if (userId) setTabFilter({ ...tabsFilter, user_id: userId });
    else tabsFilter.user_id && delete tabsFilter.user_id && setTabFilter({ ...tabsFilter });
  }, [userId]);

  useEffect(() => {
    setTabFilter({
      ...tabsFilter,
      ...(enableCategory ? { type: "category" } : {}),
      start_date: moment(dates[0]).format("YYYY-MM-DD"),
      end_date: moment(dates[1]).format("YYYY-MM-DD"),
    });
  }, [dates]);

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">
          <h4 className="card-title">{loading ? "Loading..." : "Top Selling Items"}</h4>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              name="shared_listings"
              className="custom-control-input"
              checked={enableCategory}
              onChange={() => null}
            />
            <label
              onClick={() => {
                if (!enableCategory) {
                  tabsFilter.type = "category";
                  dispatch(fetchTopSellingItems(tabsFilter));
                } else {
                  tabsFilter.sort_by = "sales";
                  dispatch(fetchTopSellingItems(omit(tabsFilter, ["type"])));
                }
                setEnableCategory(!enableCategory);
              }}
              className="custom-control-label"
            >
              Top Selling Categories
            </label>
          </div>
        </CardTitle>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <FormGroup className="mx-2">
            <Label className="ml-0">Date Range</Label>
            <DateRange dates={dates} setDates={setDates} loader={loading} noMargin={true} />
          </FormGroup>
          <FormGroup>
            <PillBtn
              className="mt-4 align-items-right"
              color="primary"
              title="Download Top Selling"
              name="Download Top Selling"
              icon="bx bx-xs bx-cloud-download ml-1"
              onClick={() => {
                const allData = topSellingItems?.reduce((acc, curr) => {
                  acc.push({
                    "Product sku": curr.sku,
                    Price: curr.cost,
                    Sale: curr.sales,
                    "Average price": curr.avg_profit,
                    Profit: curr.profit,
                    Orders: curr.count,
                  });
                  return acc;
                }, []);
                downloadFile(allData, "_top_selling_items");
              }}
            />
          </FormGroup>
        </div>
        <div className="table-responsive" style={{ maxHeight: "400px" }}>
          <table className="table table-centered table-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                <TableHeader />
              </tr>
            </thead>
            <tbody>
              <TableData />
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );

  function TableHeader () {
    switch (type) {
      case "global":
        return globalStatsTableHeaders.map((header, index) => (
          <th key={`table-header-global ${index}`}>{header.title}</th>
        ));
      case "category":
        return tableHeadersCategories.map((header, index) => (
          <th key={`table-header-categories ${index}`}>{header.title}</th>
        ));
      default:
        return tableHeaders.map((header, index) => (
          <th key={`table-header ${index}`} onClick={header.onClick}>
            {header.title}
            {header.sort
              ? (
              <div className="d-inline">
                <i
                  id="up-filter"
                  style={{ fontSize: "10px" }}
                  className={`dripicons-arrow-thin-up cursor-pointer ${
                    tabsFilter.sort_by !== colMapping[header.title]
                      ? ""
                      : tabsFilter.sort_direction === "asc"
                      ? ""
                      : "d-none"
                  }`}
                ></i>
                <i
                  id="down-filter"
                  style={{ fontSize: "10px" }}
                  className={`dripicons-arrow-thin-down cursor-pointer ${
                    tabsFilter.sort_by !== colMapping[header.title]
                      ? ""
                      : tabsFilter.sort_direction === "desc"
                      ? ""
                      : "d-none"
                  }`}
                ></i>
              </div>
                )
              : (
                  ""
                )}
          </th>
        ));
    }
  }

  function TableData () {
    const StatsCols = ({ item }) => (
      <>
        <td>{formatNumber(item.sales)}</td>
        <td>{formatNumber(item.avg_profit)}</td>
        <td>{formatNumber(item.profit)}</td>
        <td>{humanize(item.count)}</td>
      </>
    );
    switch (type) {
      case "global":
        return globalTopSellingItems?.map((item, key) => (
          <tr key={"_global_top_selling_item_" + key} className="mt-3 mb-3">
            <td>
              <Link
                to={`/listings/${item.sku}/suppliers?platform=${item.store_name}`}
                target="_blank"
                className="text-primary mb-1"
              >
                {item.sku}
              </Link>
              <Category skusCategories={globalSkusCategories} sku={item.sku} />
            </td>
            <StatsCols item={item} />
          </tr>
        ));
      case "category":
        return Object.keys(topSellingCategories)?.map((item, key) => (
          <tr key={"_top_selling_category_" + key} className="mt-3 mb-3">
            <td>{item}</td>
            <td>{topSellingCategories[item]}</td>
          </tr>
        ));
      default:
        return topSellingItems?.map((item, key) => (
          <tr key={"_top_selling_item_" + key} className="mt-3 mb-3">
            <td>
              {props.abilities.can("visit", "listings")
                ? (
                <Link
                  to={`/listings/${item.sku}/suppliers?platform=${item.store_name}&storeId=${
                    skusCategories[item.sku]?.marketplace_account_id || item.marketplace_account_id
                  }`}
                  target="_blank"
                  className="text-primary mb-1"
                >
                  {item.sku}
                </Link>
                  )
                : (
                    item.sku
                  )}
              <Category skusCategories={skusCategories} sku={item.sku} />
            </td>
            <td>
              {skusCategories[item.sku]?.current_pricing
                ? `${formatNumber(skusCategories[item.sku]?.current_pricing)}`
                : "N/A"}
            </td>
            <StatsCols item={item} />
          </tr>
        ));
    }
  }
};

export default TopSellingItems;

function Category ({ skusCategories, sku }) {
  const category = skusCategories[sku];
  if (!category) return null;
  return (
    <>
      <small title={category.name} className="d-block text-overflow-wrap">
        <b>Title: </b>
        {category.name}
      </small>
      <small title={category.category} className="d-block text-overflow-wrap">
        <b>Category: </b>
        {category.category}
      </small>
    </>
  );
}
