import { ConfirmationAlert } from "../../../components/Common/ConfirmDialog";
import { RenderIf } from "../../../utils/common";
import React, { useState } from "react";

export default function AutoUpgradeCheckBox ({ checked, onConfirm }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <RenderIf isTrue={isOpen}>
        <ConfirmationAlert
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          msg={<p>Do you want to {checked ? "Disable" : "Enable"} Auto Upgrade?</p>}
          onConfirm={onConfirm}
        />
      </RenderIf>
      <div className="custom-control custom-switch custom-switch-lg mb-3">
        <input
          id="auto_subscription_handler"
          type="checkbox"
          className="custom-control-input"
          checked={checked}
          onChange={() => null}
        />
        <label className="custom-control-label cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          Auto Upgrade
          <small> (In case of active your subscription plan will be automatically upgraded)</small>
        </label>
      </div>
    </>
  );
}
