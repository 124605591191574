import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as api from "../../api";
// Login Redux States
import * as types from "./actionTypes";
import * as actions from "./actions";
const _success = (payload, res) => (payload.query?.is_download ? { success: true, closeModal: true } : res);

// ************************************************ //
function * fetchReportsSaga ({ payload: params }) {
  try {
    const res = yield call(api.fetchReports, { params });
    yield put(actions.fetchReportsSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

export function * watchFetchReports () {
  yield takeEvery(types.FETCH_REPORTS, fetchReportsSaga);
}

// ************************************************ //
function * downloadReportSaga ({ payload }) {
  try {
    const res = yield call(api.downloadReport, payload);
    yield put(actions.downloadReportSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message || error));
  }
}

export function * watchDownloadReport () {
  yield takeEvery(types.DOWNLOAD_REPORT, downloadReportSaga);
}

// ************************************************ //
function * downloadOrderReportSaga ({ payload }) {
  try {
    const res = yield call(api.genOrderDetailsReport, payload);
    yield put(actions.genOrderDetailsReportSuccess(_success(payload, res)));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchDownloadOrderReportSaga () {
  yield takeEvery(types.GEN_ORDER_DETAILS_REPORT, downloadOrderReportSaga);
}

// ************************************************ //
function * genTransactionReportSaga ({ payload }) {
  try {
    const res = yield call(api.genTransactionReport, payload);
    yield put(actions.genTransactionReportSuccess(_success(payload, res)));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchGenTransactionReportSaga () {
  yield takeEvery(types.GEN_TRANSACTIONAL_REPORT, genTransactionReportSaga);
}

// ************************************************ //
function * getAvailableSettlementReportsSaga ({ payload }) {
  try {
    const res = yield call(api.getAvailableSettlementReports, payload);
    yield put(actions.getAvailableSettlementReportsSucess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchGetAvailableSettlementReportsSaga () {
  yield takeEvery(types.GET_AVAILABLE_SETTLEMENT_REPORTS, getAvailableSettlementReportsSaga);
}

// ************************************************ //
export function * genSettlementReportSaga ({ payload }) {
  try {
    const res = yield call(api.genSettlementReport, payload);
    yield put(actions.genSettlementReportSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchGenSettlementReportSaga () {
  yield takeEvery(types.GEN_SETTLEMENT_REPORT, genSettlementReportSaga);
}

// ************************************************ //
export function * genOrdersReportSaga ({ payload }) {
  try {
    const res = yield call(api.genOrdersReport, payload);
    yield put(actions.genOrdersReportSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchGenOrdersReportSaga () {
  yield takeEvery(types.GEN_ORDERS_REPORT, genOrdersReportSaga);
}

// ************************************************ //
export function * genInventoryReportSaga ({ payload }) {
  try {
    const res = yield call(api.genInventoryReport, payload);
    yield put(actions.genInventoryReportSuccess(res || { success: true, closeModal: true }));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * genInventoryInsightSaga ({ payload }) {
  try {
    const res = yield call(api.genInventoryInsight, payload);
    yield put(actions.genInventoryInsightSuccess(res || { success: true, closeModal: true }));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchGenInventoryReportSaga () {
  yield takeEvery(types.GEN_INVENTORY_REPORT, genInventoryReportSaga);
}

export function * watchGenInventoryInsightSaga () {
  yield takeEvery(types.GEN_INVENTORY_INSIGHT, genInventoryInsightSaga);
}
// ************************************************ //
export function * genReturnsReportSaga ({ payload }) {
  try {
    const res = yield call(api.genReturnsReport, payload);
    yield put(actions.genReturnsReportSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchGenReturnsReportSaga () {
  yield takeEvery(types.GEN_RETURNS_REPORT, genReturnsReportSaga);
}

// ************************************************ //
export function * genWhLabelReportSaga ({ payload }) {
  try {
    const res = yield call(api.genWhLabelReport, payload);
    yield put(actions.genWhLabelReportSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error.message));
  }
}

export function * watchGenWhLabelReportSaga () {
  yield takeEvery(types.GEN_WH_LABEL_REPORT, genWhLabelReportSaga);
}

function * Reports () {
  yield all([
    fork(watchFetchReports),
    fork(watchDownloadReport),
    fork(watchDownloadOrderReportSaga),
    fork(watchGenTransactionReportSaga),
    fork(watchGetAvailableSettlementReportsSaga),
    fork(watchGenSettlementReportSaga),
    fork(watchGenOrdersReportSaga),
    fork(watchGenInventoryReportSaga),
    fork(watchGenInventoryInsightSaga),
    fork(watchGenReturnsReportSaga),
    fork(watchGenWhLabelReportSaga),
  ]);
}

export default Reports;
