import React, { Component } from "react";
import { Row, Card, CardBody, CardImg } from "reactstrap";
import { RenderIf } from "../../utils/common";

const parseImage = (img) => String(img.name?.split("/")?.pop())?.replace(/\.|jpeg|jpg|png/g, "");

function ScreenshotCard ({ image }) {
  if (!image?.imgSrc) return <></>;

  return (
    <Card>
      <a target="_blank" href={image.imgSrc} rel="noopener noreferrer">
        <CardBody style={{ width: 200 }}>
          <div style={{ textAlign: "center" }}>
            <h5>{parseImage(image)}</h5>
          </div>
          <CardImg
            className="img-fluid"
            src={image.imgSrc}
            alt="No Image Found"
            style={{
              objectFit: "cover",
              objectPosition: "0 0",
              height: 100,
              borderRadius: 10,
            }}
          />
        </CardBody>
      </a>
    </Card>
  );
}

export default class ImageUi extends Component {
  render () {
    const { images } = this.props;

    return (
      <>
        <RenderIf isTrue={images?.length}>
          <Row>
            {images
              ?.sort((a, b) => parseInt(parseImage(a)) - parseInt(parseImage(b)))
              .map((image, _i) => (
                <ScreenshotCard image={image} key={_i} />
              ))}
          </Row>
        </RenderIf>
        <RenderIf isTrue={images?.length === 0}>
          <Row className="d-flex justify-content-center">
            <h4>Screenshots no longer available.</h4>
          </Row>
        </RenderIf>
      </>
    );
  }
}
