import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Alert } from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PillBtn from "../../../components/Common/PillBtn";
import { MARKET_PLACES } from "../../../constants";
import "flatpickr/dist/themes/dark.css";
import { RenderIf, toPascalCase } from "../../../utils/common";

import { setPreloader, requestVAPerformanceReport } from "../../../store/actions";
import ReportDownloadMsg from "../../../components/Common/ReportDownloadMsg";

const ViewReport = (props) => {
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const [platform, setPlatform] = useState([]);
  const [dropDownPlatform, setDropDownPlatform] = useState({
    value: "",
    label: "",
  });

  const tableHeaders = [
    { title: "Listed By" },
    { title: "Total Products Listed" },
    { title: "Total Orders" },
    { title: "Refund Count" },
    { title: "Refund Rate" },
    { title: "Gross Sales" },
    { title: "Net Profit" },
    { title: "Net Margin" },
  ];

  const fetchReport = (platform, isDownload = "") =>
    props.requestVAPerformanceReport({ platform, is_export: isDownload });

  useEffect(() => {
    props.setPreloader(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (accounts?.data.length) {
      const platforms = accounts.data?.reduce((acc, init) => {
        if (Object.keys(MARKET_PLACES).splice(0, 2).includes(init.marketplace)) {
          !acc.includes(init.marketplace) && acc.push(init.marketplace);
        }
        return acc;
      }, []);
      setPlatform(platforms);
      setDropDownPlatform({
        label: toPascalCase(platforms?.[0]),
        value: platforms?.[0],
      });
      fetchReport(platforms?.[0]);
    }
  }, [accounts?.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        {props.error && (
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {props.error}
            </Alert>
          </div>
        )}
        <RenderIf isTrue={props.success}>
          <ReportDownloadMsg />
        </RenderIf>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="VA Performance Report"
            children={
              props.vaReport?.length
                ? (
                <PillBtn
                  color="primary"
                  title="Export to CSV"
                  name="Export to CSV"
                  icon="bx bx-xs bx-cloud-download ml-1"
                  onClick={() => fetchReport(dropDownPlatform.value, true)}
                />
                  )
                : null
            }
          />

          <Card>
            <CardHeader>
              <Row>
                {platform?.length > 1 && (
                  <Col lg={2} className="mt-3">
                    <FormGroup className="select2-container" style={{ width: "200px" }}>
                      <Select
                        name="platform"
                        value={{
                          value: dropDownPlatform.value,
                          label: dropDownPlatform.label,
                        }}
                        onChange={(e) => {
                          fetchReport(e.value);
                          setDropDownPlatform({ label: e.label, value: e.value });
                        }}
                        options={platform.reduce((acc, x, i) => {
                          acc.push({
                            label: toPascalCase(x),
                            value: x,
                            key: i,
                          });
                          return acc;
                        }, [])}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </CardHeader>
            {props.vaReport?.length ? (
              <CardBody>
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {/* table headers */}
                          {tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`}>{header.title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {/* iterating data */}
                        {props.vaReport.map((item, key) => (
                          <tr key={"_view_report_" + key}>
                            <td>{item.listed_by}</td>
                            <td>{item.total_products_listed}</td>
                            <td>{item.total_orders}</td>
                            <td>{item.refund_count}</td>
                            <td>{item.refund_rate}</td>
                            <td>{item.gross_sales}</td>
                            <td>{item.net_profit}</td>
                            <td>{item.net_margin}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              </CardBody>
            ) : null}
            {!props.vaReport?.length && !props.loading && (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
            )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { vaReport, loading, error, success } = state.SourceOrders;
  return { vaReport, loading, error, success };
};

export default connect(mapStatetoProps, {
  setPreloader,
  requestVAPerformanceReport,
})(ViewReport);
