import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { startCase, sortBy as sortArrayByKey, uniqBy, isEmpty, keyBy, pick, sumBy } from "lodash";

import { Container, Button, FormGroup, Alert, Row, Col, Card, CardBody, Media, CardHeader, Input } from "reactstrap";

import { ConfirmDialogIcon, ConfirmationAlert } from "../../../components/Common/ConfirmDialog";
import CustomPagination from "../../../components/Common/CustomPagination";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import CustomTooltip from "./../../../components/Common/CustomTooltip";
import { DatePicker } from "../../../components/Common/DatePickr";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ReactTable from "../../../components/Common/Table";
import ShowUpc from "../../../components/Common/ShowUpc";
import CreatableSelect from "react-select/creatable";
import Flatpickr from "react-flatpickr";
import moment from "moment-timezone";
import { useFormik } from "formik";
import Select from "react-select";
import FloppyWhite from "../../../assets/svg/white-floppy- icon.svg";

// modals
import PurchaseOrderLog from "./POModals/PurchaseOrderLog";
import AdjustmentModal from "./POModals/AdjustmentModal";
import ShipmentModal from "./POModals/ShipmentModal";

import { RenderIf, getWarehouseName, replaceUnderscores } from "../../../utils/common";

// Actions
import {
  fetchPurchaseOrderSuppliers,
  fetchPurchaseOrderMetadata,
  fetchPurchaseOrderStats,
  updatePurchaseOrderItem,
  fetchPurchaseOrderLogs,
  fetchPurchaseOrders,
  deletePurchaseOrder,
  updatePurchaseOrder,
  fetchAllWarehouses,
  setAdjustmentModal,
  deletePOShipment,
  setShipmentModal,
  setPOLogsModal,
  fetchPlatforms,
  createLineItem,
  deletePOItem,
  setPreloader,
} from "../../../store/actions";

const PurchaseOrderUser = () => {
  const purchaseOrderInitialObj = {
    purchase_order_id: "",
    supplier: "",
    date_placed: "",
    total_shipping: 0,
    total_cost: 0,
    total_quantity: 0,
    total_received: 0,
    warehouse_id: "",
  };

  const itemInitialObj = {
    item_title: "",
    upc: "",
    sku: "",
    supplier_sku: "",
    supplier_url: "",
    item_id: "",
    asin: "",
    quantity: 0,
    qty_multiplier: 1,
    cost_per_item: 0,
    shipping_per_item: 0,
    tax: 0,
    error: [],
  };

  const {
    success,
    error,
    loadingPlatforms,
    adjustmentModalIsOpen,
    shipmentModalIsOpen,
    POLogsModalIsOpen,
    associatedWarehouses,
    purchaseOrders,
    purchaseOrdersMetadata,
    purchaseOrdersStats,
    purchaseOrdersSuppliers,
  } = useSelector((state) => state.WareHouses);

  const orders = [
    { title: "Open Purchase Orders", value: purchaseOrdersStats?.openPurchaseOrders || 0, icon: "bx bx-dollar" },
    {
      title: "Orders in Transit to Warehouses",
      value: { orders: purchaseOrdersStats?.inTransitPOs || 0, units: purchaseOrdersStats?.openPurchaseOrders || 0 },
      icon: "bx bx-time-five",
    },
    { title: "Total Inventory Value", value: purchaseOrdersStats?.totalPoValue?.toFixed(2) || 0, icon: "bx bx-dollar" },
  ];

  const [purchaseOrderCollapse, setPurchaseOrderCollapse] = useState(false);
  const [shipmentModalType, setShipmentModalType] = useState("read");
  const [existingPoItemsSKU, setExistingPoItemsSKU] = useState([]);
  const [purchaseOrderObj, setPurchaseOrderObj] = useState({});
  const [viewShipmentitem, setViewShipmentitem] = useState([]);
  const [addNewItemInPO, setAddNewItemInPO] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(false);
  const [createdSupplier, setCreatedSupplier] = useState();
  const [supplierOpts, setSupplierOpts] = useState([]);
  const [isdeletePO, setIsdeletePO] = useState(false);
  const [trackingNum, setTrackingNum] = useState("");
  const [warehouseObj, setWarehouseObj] = useState();
  const [itemObj, setItemObj] = useState({});
  const [poObj, setPoObj] = useState({});

  const [poId, setPoId] = useState();

  // States for filter
  const [filters, setFilters] = useState({ page: 1, per_page: 30, PO_filter: "" });
  const [search, setSearch] = useState("");

  const handleAddNewSupplier = (newSupplier) => {
    setCreatedSupplier(newSupplier);
  };

  // Row Show hide start
  const [expandState, setExpandState] = useState(0);

  const handleEpandRow = (rowID) => {
    resetFilterValues();
    if (expandState === rowID) {
      setExistingPoItemsSKU([]);
      setExpandState(0);
    } else {
      setExpandState(rowID);
      dispatch(fetchPurchaseOrderMetadata(rowID));
    }
  };

  // Dynamic Item Start
  const [inputList, setInputList] = useState([itemInitialObj]);
  const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);

  const existingPoShipments = !isEmpty(purchaseOrdersMetadata?.shipments)
    ? purchaseOrdersMetadata?.shipments.map((x) => x.tracking_number)
    : [];
  // handle input change
  const handleInputValidation = (type) => {
    const list = [...inputList];
    let err = {};
    list.forEach((x, i) => {
      ["sku", "quantity"].forEach((y) => (!x[y] ? (err = { ...err, [y]: "Filed is Required" }) : delete err[y]));
      list[i].error = { ...error, ...err };
    });

    const checkSKUs = findDuplicates([...existingPoItemsSKU, ...list.map((x) => x.sku)]);
    if (checkSKUs?.length) {
      list.forEach((x, i) => {
        ["sku"].forEach((y) => (err = { ...err, [y]: "Verify SKU!" }));
        list[i].error = { ...error, ...err };
      });
    }
    if (type === "sendItemsToSummaryModal") {
      formik.handleSubmit();
      if (isEmpty(err) && isEmpty(formik.errors)) {
        toggleAdjustmentModal();
      }
    } else {
      if (isEmpty(err) && !checkSKUs?.length) {
        list.forEach((x, i) => delete list[i].error);
        addLineItemFun(inputList);
      }
    }
    setInputList(list);
  };

  const comulativeColumns = ["quantity", "qty_multiplier", "cost_per_item", "shipping_per_item"];
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    comulativeColumns.forEach(
      (y) =>
        name === y &&
        (value !== "" && value < 0
          ? (list[index].error = { ...error, [name]: "Enter positive number." })
          : delete list[index].error[name]),
    );
    if (name === "sku") {
      const bufferAddedSkus = list.map((x) => x.sku);
      const bufferAllSkus = [...bufferAddedSkus, ...existingPoItemsSKU];
      if (bufferAllSkus.some((x) => x === value)) {
        comulativeColumns.forEach((y) => (list[index][y] = 0));
        list[index].error = { ...error, [name]: "Repeated SKU" };
      } else delete list[index].error[name];
    }
    list[index][name] = value.startsWith("0") ? (comulativeColumns.includes(name) ? +value : value) : value;
    const bufferCountTotal = list?.reduce(
      (total, obj) => (obj.qty_multiplier ? +obj.quantity * +obj.qty_multiplier : +obj.quantity) + +total,
      0,
    );
    const bufferCountCost = list?.reduce(
      (total, obj) => (obj.cost_per_item ? +obj.quantity * +obj.cost_per_item : +obj.cost_per_item) + +total,
      0,
    );
    const bufferCountShipment = list?.reduce(
      (total, obj) =>
        (obj.shipping_per_item ? +obj.quantity * +obj.shipping_per_item : +obj.shipping_per_item) + +total,
      0,
    );

    setPurchaseOrderObj({
      ...purchaseOrderObj,
      total_quantity: bufferCountTotal,
      total_cost: bufferCountCost,
      total_shipping: bufferCountShipment,
    });
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, itemInitialObj]);
  };
  // Dynamic Item End

  const toggleAdjustmentModal = () => dispatch(setAdjustmentModal(!adjustmentModalIsOpen));
  const toggleShipmentModal = () => dispatch(setShipmentModal(!shipmentModalIsOpen));
  const togglePOLogsModal = () => dispatch(setPOLogsModal(!POLogsModalIsOpen));

  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    ["purchase_order_id", "supplier", "warehouse_id"].forEach(
      (key) => !values[key] && (errors[key] = "Field is Required"),
    );
    return errors;
  };

  const formik = useFormik({
    initialValues: purchaseOrderInitialObj,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
  });

  // Use Effects
  useEffect(() => {
    dispatch(fetchPurchaseOrders(filters));
  }, [filters]);

  useEffect(() => {
    if (success || error) {
      resetFilterValues();
      dispatch(setPreloader(false));
      setPurchaseOrderObj({});
      setPoObj({});
      setItemObj();
      setAddNewItemInPO();
      window.scrollTo(0, 0);
    }
  }, [success, error]);

  useEffect(() => {
    dispatch(fetchPlatforms({}));
    dispatch(fetchPurchaseOrderStats());
    dispatch(fetchAllWarehouses({ associated: true }));
    dispatch(fetchPurchaseOrderSuppliers());
  }, []);

  useEffect(() => {
    setWarehouseObj(keyBy(associatedWarehouses?.data, "id"));
  }, [associatedWarehouses]);

  useEffect(() => {
    setExistingPoItemsSKU(
      !isEmpty(purchaseOrdersMetadata.line_items) && purchaseOrdersMetadata?.line_items.map((x) => x.sku),
    );
  }, [purchaseOrdersMetadata.line_items]);

  useEffect(() => {
    if (purchaseOrdersSuppliers) {
      setSupplierOpts(
        uniqBy(
          sortArrayByKey(
            purchaseOrdersSuppliers?.map((x, i) => ({
              label: x.name,
              value: x.name,
              key: i,
            })) || [],
            "label",
          ),
          "label",
        ),
      );
    }
  }, [purchaseOrdersSuppliers]);

  // Helping Functions
  const addLineItemFun = (lineItems) => {
    dispatch(setPreloader(true));
    const itemsObj = { po_id: poId, line_items: lineItems };
    dispatch(createLineItem(itemsObj, purchaseOrderObj, expandState));
  };

  const deletePOFun = (id) => {
    dispatch(setPreloader(true));
    dispatch(deletePurchaseOrder(id));
  };

  const deletePOItemFun = (itemInfo) => {
    dispatch(setPreloader(true));
    dispatch(deletePOItem(itemInfo));
  };

  const deletePOShipmentFun = (shipmentInfo) => {
    dispatch(setPreloader(true));
    dispatch(deletePOShipment(shipmentInfo));
  };

  const updatePoFun = (rowData) => {
    dispatch(setPreloader(true));
    dispatch(
      updatePurchaseOrder(
        pick(rowData, ["warehouse_id", "supplier", "date_placed", "purchase_order_id"]),
        rowData?.id,
        purchaseOrderObj,
      ),
    );
  };

  const updateItemFun = (itemRowData) => {
    dispatch(setPreloader(true));
    dispatch(updatePurchaseOrderItem(itemRowData, itemRowData?.id, purchaseOrderObj));
  };

  const setPOState = (e) => {
    const { name, value } = e.target ?? e;
    setPurchaseOrderObj({ ...purchaseOrderObj, [name]: value });
  };

  const reCaculatePoOnUpdate = (obj, oldObj) => {
    setPurchaseOrderObj({
      ...purchaseOrderObj,
      total_quantity: +obj?.quantity * +obj?.qty_multiplier - +oldObj?.quantity * +oldObj?.qty_multiplier,
      total_cost: +obj?.quantity * +obj?.cost_per_item - +oldObj?.quantity * +oldObj?.cost_per_item,
      total_shipping: +obj?.quantity * +obj?.shipping_per_item - +oldObj?.quantity * +oldObj?.shipping_per_item,
    });
  };

  const resetFilterValues = () => {
    formik.resetForm();
    setCreatedSupplier();
    setInputList([itemInitialObj]);
    setPurchaseOrder(false);
  };

  const shipmentModalOpenFun = (value) => {
    setShipmentModalType(value);
    toggleShipmentModal();
  };

  const POLogsModalOpenFun = (value) => {
    dispatch(fetchPurchaseOrderLogs(value));
    togglePOLogsModal();
  };

  const SortArrows = ({ arrow }) => (
    <i
      id={arrow + "-filter"}
      style={{ fontSize: "12px" }}
      className={`dripicons-arrow-thin-${arrow} cursor-pointer ${arrow === "down" ? "ml-n3" : ""}`}
    />
  );

  const liveListingHeader = [
    { title: "Order No.*" },
    { title: "Supplier*" },
    { title: "Date Placed" },
    { title: "Status" },
    { title: "Total Cost" },
    { title: "Total Shipping" },
    { title: "Total Qty" },
    { title: "Warehouse*" },
    { title: "Action" },
  ];

  const ItemsHeader = [
    { title: "Title" },
    { title: "UPC" },
    { title: "SKU*" },
    { title: "Supplier SKU" },
    { title: "Supplier URL" },
    { title: "Item Id" },
    { title: "ASIN" },
    { title: "Adjustments" },
    { title: "Quantity*" },
    { title: "Quantity Multiplier" },
    { title: "Cost Per Item" },
    { title: "Shipping Per Item" },
    { title: "Tax" },
    { title: "Action" },
  ];

  const shipmentHeader = [
    { title: "Tracking Number" },
    { title: "Tracking Status" },
    { title: "Total SKUs" },
    { title: "Total Units" },
    { title: "Units Received" },
    { title: "Status" },
    { title: "Actions" },
  ];

  const purchaseOrderHeader = [
    { title: "Title" },
    { title: "UPC" },
    { title: "SKU*" },
    { title: "Supplier SKU" },
    { title: "Supplier URL" },
    { title: "Item ID" },
    { title: "ASIN" },
    { title: "Adjustments" },
    { title: "Quantity*" },
    { title: "Quantity Multiplier" },
    { title: "Cost Per Item" },
    { title: "Shipping Per Item" },
    { title: "Tax" },
    { title: "Action" },
  ];

  const dynamicallyAddItems = inputList.map((input, i) => (
    <tr key={i}>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="text"
            className="inner form-control po-input-100"
            placeholder="Item title"
            name="item_title"
            value={input.item_title}
            onChange={(e) => handleInputChange(e, i)}
          />
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="text"
            className="inner form-control po-input-100"
            placeholder="UPC"
            name="upc"
            value={input.upc}
            onChange={(e) => handleInputChange(e, i)}
          />
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="text"
            className="inner form-control po-input-141"
            placeholder="SKU"
            name="sku"
            value={input.sku}
            onChange={(e) => handleInputChange(e, i)}
          />
          {input?.error?.sku ? <span className="text-danger mt-1 position-absolute">{input?.error?.sku}</span> : null}
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="text"
            className="inner form-control po-input-100"
            placeholder="Supplier SKU"
            name="supplier_sku"
            value={input.supplier_sku}
            onChange={(e) => handleInputChange(e, i)}
          />
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="text"
            className="inner form-control po-input-141"
            placeholder="Supplier URL"
            name="supplier_url"
            value={input.supplier_url}
            onChange={(e) => handleInputChange(e, i)}
          />
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="text"
            className="inner form-control po-input-100"
            placeholder="Item ID"
            name="item_id"
            value={input.item_id}
            onChange={(e) => handleInputChange(e, i)}
          />
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="text"
            className="inner form-control po-input-100"
            placeholder="ASIN"
            name="asin"
            value={input.asin}
            onChange={(e) => handleInputChange(e, i)}
          />
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="number"
            className="inner form-control po-input-150"
            placeholder="Quantity Multiplier"
            name="qty_multiplier"
            value={0}
            disabled
          />
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="number"
            className="inner form-control po-input-141"
            placeholder="Quantity"
            name="quantity"
            value={input?.error?.sku ? 0 : input.quantity}
            onChange={(e) => handleInputChange(e, i)}
            disabled={input?.error?.sku}
          />
          {input?.error?.quantity
            ? (
            <span className="text-danger mt-1 position-absolute">{input?.error?.quantity}</span>
              )
            : null}
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="number"
            className="inner form-control po-input-150"
            placeholder="Quantity Multiplier"
            name="qty_multiplier"
            value={input?.error?.sku ? 0 : input.qty_multiplier}
            onChange={(e) => handleInputChange(e, i)}
            disabled={input?.error?.sku}
          />
          {input?.error?.qty_multiplier
            ? (
            <span className="text-danger mt-1 position-absolute">{input?.error?.qty_multiplier}</span>
              )
            : null}
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="number"
            className="inner form-control po-input-150"
            placeholder="Cost per item"
            name="cost_per_item"
            value={input?.error?.sku ? 0 : input.cost_per_item}
            onChange={(e) => handleInputChange(e, i)}
            disabled={input?.error?.sku}
          />
          {input?.error?.cost_per_item
            ? (
            <span className="text-danger mt-1 position-absolute">{input?.error?.cost_per_item}</span>
              )
            : null}
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="number"
            className="inner form-control po-input-150"
            placeholder="Shipping per item"
            name="shipping_per_item"
            value={input?.error?.sku ? 0 : input.shipping_per_item}
            onChange={(e) => handleInputChange(e, i)}
            disabled={input?.error?.sku}
          />
          {input?.error?.shipping_per_item
            ? (
            <span className="text-danger mt-1 position-absolute">{input?.error?.shipping_per_item}</span>
              )
            : null}
        </span>
      </td>
      <td>
        <span className="position-relative d-block min-height-55">
          <Input
            type="number"
            className="inner form-control po-input-150"
            placeholder="Tax"
            name="tax"
            value={input?.error?.sku ? 0 : input.tax}
            onChange={(e) => handleInputChange(e, i)}
            disabled={input?.error?.sku}
          />
          <RenderIf isTrue={input?.error?.tax}>
            <span className="text-danger mt-1 position-absolute">{input?.error?.tax}</span>
          </RenderIf>
        </span>
      </td>
      <td colSpan={12}>
        <div>
          {inputList?.length - 1 === i && (
            <i onClick={handleAddClick} className="bx bx-plus bx-sm text-primary cursor-pointer" />
          )}
          {inputList?.length !== 1 && (
            <i onClick={() => handleRemoveClick(i)} className="bx bx-minus bx-sm text-danger cursor-pointer" />
          )}
        </div>
      </td>
    </tr>
  ));

  const [selectedPOFilterOption, setSelectedPOFilterOption] = useState(null);

  const POFilteroptions = [
    { label: "All Purchase Orders", value: "" },
    { label: "Unshipped POs", value: "no_shipment_po" },
    { label: "Unreconciled POs", value: "un_reconciled" },
  ];

  return (
    <>
      {isdeletePO && (
        <ConfirmationAlert
          onConfirm={() => deletePOFun(poId)}
          isOpen={isdeletePO}
          setIsOpen={setIsdeletePO}
          msg={<p>To Delete Purchase Order.</p>}
        />
      )}
      <div className="page-content purchase-order">
        <Container fluid>
          <Breadcrumbs
            title="Purchase Order"
            children={
              <div className="d-flex align-items-center gap-3">
                <Button
                  color="primary"
                  onClick={() => {
                    setPurchaseOrderCollapse(true);
                    setPurchaseOrder(true);
                    setExpandState(0);
                    setExistingPoItemsSKU([]);
                  }}
                >
                  <i className="bx bx-plus pr-1"></i>
                  Add Purchase Order
                </Button>
              </div>
            }
          />
          <div>
            <Row>
              <Col md={12} lg={12}>
                {success && (
                  <div className="auto-hide">
                    <Alert color="success">
                      <i className="bx bx-info-circle pr-2"></i>
                      {success}
                    </Alert>
                  </div>
                )}
                {error && (
                  <div className="auto-hide">
                    <Alert color="danger">
                      <i className="bx bx-info-circle pr-2"></i>
                      {error}
                    </Alert>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              {orders.map((order, index) => {
                return (
                  <Col md={4} key={index}>
                    <Card className="mini-stats-wid purchase-order-widgets">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">{order.title}</p>
                            {typeof order.value === "object"
                              ? (
                              <div className="d-flex align-items-center">
                                <span className="text-muted font-weight-medium d-flex align-items-baseline ">
                                  Orders: <h4 className="ml-2 mb-0">{order?.value?.orders}</h4>
                                </span>
                                <div className="divider"></div>
                                <span className="text-muted font-weight-medium d-flex align-items-baseline ">
                                  Orders: <h4 className="ml-2 mb-0">{order?.value?.orders}</h4>
                                </span>
                              </div>
                                )
                              : (
                              <h4 className="mb-0">{order.value}</h4>
                                )}
                          </Media>
                          <div className="d-flex flex-column">
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className={`bx ${order.icon} font-size-16`}></i>
                              </span>
                            </div>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <div>
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between align-items-center">
                    <form
                      className="app-search d-none d-lg-block"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (!search.trim()) return;
                        setFilters({ ...filters, search: search.trim(), page: 1 });
                      }}
                    >
                      <div className="position-relative d-block ">
                        <input
                          type="text"
                          className="form-control see-more-text"
                          placeholder="Search SKU, Order ID, Tracking #, UPC"
                          value={search}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setFilters({ search: "", page: 1 });
                              setSearch("");
                            } else {
                              setSearch(e.target.value);
                            }
                          }}
                        />
                        <span className="bx bx-search-alt"></span>
                      </div>
                    </form>
                    <div className="d-flex align-items-center">
                      <FormGroup className="select2-container ml-2" style={{ width: "200px", marginTop: 15 }}>
                        <Select
                          classNamePrefix="select2-selection"
                          name="PO_filter"
                          defaultValue={selectedPOFilterOption}
                          placeholder="Select PO type"
                          options={POFilteroptions}
                          formStyle={{ Width: "100%" }}
                          onChange={(option) => {
                            setFilters({ ...filters, PO_filter: option.value });
                            setSelectedPOFilterOption(option);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="select2-container ml-2" style={{ width: "200px", marginTop: 15 }}>
                        <Select
                          name="warehouse_id"
                          placeholder="Select Warehouse"
                          value={formik.values.warehouse}
                          onBlur={formik.handleBlur}
                          onChange={(option) => {
                            setFilters({ ...filters, wh_id: option?.value });
                            formik.setFieldValue("warehouse_id", option);
                          }}
                          options={[
                            { value: "", label: "All Warehouses" },
                            ...(associatedWarehouses?.data.map((y) => ({
                              label: getWarehouseName(y),
                              value: y.id,
                            })) || []),
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                      <FormGroup className="select2-container ml-2" style={{ marginTop: "10px" }}>
                        <div className="pt-2">
                          <DatePicker
                            icon
                            style={{ width: "300px" }}
                            placeholder="Select Date Pange for order created"
                            className="mx-1 my-2"
                            onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })}
                          />
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </div>
          </div>
          <Card>
            <form className="m-2">
              <CardBody>
                <ReactTable
                  tableHeader={
                    <>
                      {liveListingHeader?.map((header, index) => (
                        <th
                          onClick={header.onClick}
                          key={`table-header ${index}`}
                          className={header.title === "Weight (lbs)" ? "text-center" : ""}
                        >
                          {header.title}
                          {header.sort
                            ? (
                            <div className="d-inline ml-1">
                              <>
                                <SortArrows arrow={"up"} />
                                <SortArrows arrow={"down"} />{" "}
                              </>
                            </div>
                              )
                            : null}
                        </th>
                      ))}
                    </>
                  }
                  tableBody={
                    <>
                      <tr className={purchaseOrder ? null : "d-none"}>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              type="text"
                              style={{ minWidth: "250px" }}
                              className="inner form-control po-input-250 "
                              placeholder="Order No"
                              name="purchase_order_id"
                              value={formik.values.purchase_order_id}
                              onChange={(e) => {
                                setPOState(e);
                                formik.setFieldValue("purchase_order_id", e.target.value);
                              }}
                            />
                            {formik.errors.purchase_order_id
                              ? (
                              <span className="text-danger mt-1 position-absolute">
                                {formik.errors.purchase_order_id}
                              </span>
                                )
                              : null}
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <FormGroup className="select2-container  mb-0" style={{ minWidth: "180px" }}>
                              <CreatableSelect
                                isDisabled={false}
                                isLoading={loadingPlatforms}
                                onChange={(option) => {
                                  setPOState({ name: "supplier", value: option.label });
                                  formik.setFieldValue("supplier", option.label);
                                  setCreatedSupplier(option.label);
                                }}
                                onCreateOption={(option) => {
                                  setPOState({ name: "supplier", value: option });
                                  formik.setFieldValue("supplier", option);
                                  handleAddNewSupplier(option);
                                }}
                                options={supplierOpts}
                                value={{
                                  value: createdSupplier || "",
                                  label: createdSupplier || "Supplier",
                                }}
                                classNamePrefix="select2-selection"
                              />
                              {formik.errors.supplier
                                ? (
                                <span className="text-danger mt-1 position-absolute">{formik.errors.supplier}</span>
                                  )
                                : null}
                            </FormGroup>
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Flatpickr
                              className={"form-control  flatpickr-input pl-4 "}
                              placeholder="Date Placed"
                              name="date_placed"
                              value={formik.values.date_placed}
                              onClose={(val) => {
                                setPOState({
                                  name: "date_placed",
                                  value: moment(val[0]).tz(moment.tz.guess()).format("YYYY-MM-DD"),
                                });
                                formik.setFieldValue(
                                  "date_placed",
                                  moment(val[0]).tz(moment.tz.guess()).format("YYYY-MM-DD"),
                                );
                              }}
                            />
                            {formik.errors.date_placed
                              ? (
                              <span className="text-danger mt-1 position-absolute">{formik.errors.date_placed}</span>
                                )
                              : null}
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              type="text"
                              className="inner form-control po-input-150"
                              value="Incomplete"
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              type="text"
                              className="inner form-control po-input-150"
                              placeholder="0"
                              name="total_cost"
                              value={purchaseOrderObj?.total_cost}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              type="text"
                              className="inner form-control po-input-150"
                              placeholder="0"
                              name="total_shipping"
                              value={purchaseOrderObj?.total_shipping}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <Input
                              type="text"
                              className="inner form-control po-input-150"
                              placeholder="0"
                              name="total_quantity"
                              value={purchaseOrderObj?.total_quantity}
                              disabled
                            />
                          </span>
                        </td>
                        <td>
                          <span className="position-relative d-block min-height-55">
                            <FormGroup className="select2-container  mb-0 po-input-150" style={{ minWidth: "180px" }}>
                              <Select
                                name="warehouse_id"
                                value={{
                                  value: purchaseOrderObj?.warehouse_id || "",
                                  label: getWarehouseName(warehouseObj?.[purchaseOrderObj?.warehouse_id]) || "Select",
                                }}
                                onBlur={formik.handleBlur}
                                onChange={(option) => {
                                  setPOState({ name: "warehouse_id", value: option?.value });
                                  formik.setFieldValue("warehouse_id", option);
                                }}
                                options={associatedWarehouses?.data.map((y) => ({
                                  label: getWarehouseName(y),
                                  value: y.id,
                                }))}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                            {formik.errors.warehouse_id
                              ? (
                              <span className="text-danger mt-1 position-absolute">{formik.errors.warehouse_id}</span>
                                )
                              : null}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center ">
                            <span
                              className="icon-topRight bx-sm cursor-pointer"
                              onClick={() => setPurchaseOrderCollapse(!purchaseOrderCollapse)}
                            >
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span>
                              <i
                                className="bx bx-x text-danger bx-sm cursor-pointer pl-2 pt-1"
                                onClick={() => resetFilterValues()}
                              />
                            </span>
                          </div>
                        </td>
                      </tr>

                      {/* Add items section */}
                      <tr
                        className={purchaseOrderCollapse && purchaseOrder ? "p-0 collapse-show" : "p-0 collapse-hide"}
                      >
                        <td colSpan={9} className="p-0">
                          <Card>
                            <CardBody className="collapse-card">
                              <div className="d-flex justify-content-between">
                                <h6>Items</h6>
                              </div>
                              <ReactTable
                                tableHeader={
                                  <>
                                    {purchaseOrderHeader.map((header, index) => (
                                      <th
                                        onClick={header.onClick}
                                        key={`table-header ${index}`}
                                        className={header.title === "Weight (lbs)" ? "text-center" : ""}
                                      >
                                        {header.title}
                                        {header.sort
                                          ? (
                                          <div className="d-inline ml-1">
                                            <>
                                              <SortArrows arrow={"up"} />
                                              <SortArrows arrow={"down"} />{" "}
                                            </>
                                          </div>
                                            )
                                          : null}
                                      </th>
                                    ))}
                                  </>
                                }
                                tableBody={
                                  <>
                                    {dynamicallyAddItems}
                                    <tr>
                                      <td colSpan={14} className="text-right">
                                        <Button
                                          color="primary"
                                          onClick={() => {
                                            handleInputValidation("sendItemsToSummaryModal");
                                          }}
                                        >
                                          <img
                                            src={FloppyWhite}
                                            alt="no-icon"
                                            width={16}
                                            className="add-bottom-space mr-2"
                                          />
                                          Save Purchase Order
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                }
                              />
                            </CardBody>
                          </Card>
                        </td>
                      </tr>
                      {/* Add items section */}

                      {purchaseOrders?.res?.data?.map((x, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>
                              {poObj && poObj?.id === x?.purchase_order?.id
                                ? (
                                <Input
                                  type="text"
                                  className="inner form-control "
                                  placeholder="0"
                                  name="total_shipping"
                                  value={poObj?.purchase_order_id}
                                  onChange={(e) => setPoObj({ ...poObj, purchase_order_id: e.target.value })}
                                />
                                  )
                                : (
                                    x?.purchase_order?.purchase_order_id
                                  )}
                            </td>
                            <td>
                              {poObj && poObj?.id === x?.purchase_order?.id
                                ? (
                                <span className="position-relative d-block min-height-55">
                                  <FormGroup className="select2-container mb-0 mt-3" style={{ minWidth: "180px" }}>
                                    <CreatableSelect
                                      isDisabled={false}
                                      isLoading={loadingPlatforms}
                                      onChange={(option) => {
                                        setCreatedSupplier(option.label);
                                        setPoObj({ ...poObj, supplier: option.label });
                                      }}
                                      onCreateOption={(option) => {
                                        setPoObj({ ...poObj, supplier: option });
                                        handleAddNewSupplier(option);
                                      }}
                                      options={supplierOpts}
                                      value={{
                                        value: createdSupplier || "",
                                        label: createdSupplier || poObj?.supplier,
                                      }}
                                      classNamePrefix="select2-selection"
                                    />
                                  </FormGroup>
                                </span>
                                  )
                                : (
                                    x?.purchase_order?.supplier
                                  )}
                            </td>
                            <td>
                              {poObj && poObj?.id === x?.purchase_order?.id
                                ? (
                                <Flatpickr
                                  className={"form-control  flatpickr-input pl-4 "}
                                  placeholder="Date Placed"
                                  name="date_placed"
                                  value={poObj?.date_placed}
                                  onClose={([val]) => {
                                    setPoObj({
                                      ...poObj,
                                      date_placed: moment(val).tz(moment.tz.guess()).format("YYYY-MM-DD"),
                                    });
                                  }}
                                />
                                  )
                                : (
                                    moment(x?.purchase_order?.date_placed).tz(moment.tz.guess()).format("ll")
                                  )}
                            </td>
                            <td>
                              <span
                                className={`font-size-12 badge-soft-success  badge badge-pill ${
                                  x?.purchase_order?.status === "completed" ? "badge-soft-success" : "badge-soft-danger"
                                }`}
                              >
                                {startCase(replaceUnderscores(x?.purchase_order?.status))}
                              </span>
                            </td>
                            <td>
                              {expandState && expandState === x?.id
                                ? x?.purchase_order?.total_cost + (purchaseOrderObj?.total_cost || 0)
                                : x?.purchase_order?.total_cost}{" "}
                            </td>
                            <td>
                              {expandState && expandState === x?.id
                                ? x?.purchase_order?.total_shipping + (purchaseOrderObj?.total_shipping || 0)
                                : x?.purchase_order?.total_shipping}{" "}
                            </td>
                            <td>
                              {expandState && expandState === x?.id
                                ? x?.purchase_order?.total_quantity + (purchaseOrderObj?.total_quantity || 0)
                                : x?.purchase_order?.total_quantity}{" "}
                            </td>

                            <td>
                              {poObj && poObj?.id === x?.purchase_order?.id
                                ? (
                                <FormGroup
                                  className="select2-container  mb-0 po-input-150"
                                  style={{ minWidth: "180px" }}
                                >
                                  <Select
                                    name="warehouse_id"
                                    value={{
                                      value: poObj.warehouse_id,
                                      label: getWarehouseName(warehouseObj?.[poObj.warehouse_id]),
                                    }}
                                    onChange={(option) => {
                                      setPoObj({ ...poObj, warehouse_id: option?.value });
                                    }}
                                    options={associatedWarehouses?.data.map((y) => ({
                                      label: getWarehouseName(y),
                                      value: y.id,
                                    }))}
                                    classNamePrefix="select2-selection"
                                  />
                                </FormGroup>
                                  )
                                : (
                                    getWarehouseName(warehouseObj?.[x?.purchase_order?.warehouse_id])
                                  )}
                            </td>

                            <td>
                              {isEmpty(poObj)
                                ? (
                                <>
                                  <span
                                    className="icon-topRight bx-sm cursor-pointer"
                                    onClick={(_) => {
                                      setPoId(x?.purchase_order?.id);
                                      handleEpandRow(x?.id);
                                    }}
                                  >
                                    {expandState && expandState === x?.id
                                      ? (
                                      <span className="icon-topRight bx-sm cursor-pointer">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                      </span>
                                        )
                                      : (
                                      <span className="icon-bottomleft bx-sm cursor-pointer">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                      </span>
                                        )}
                                  </span>
                                  <i
                                    className="bx bx-detail text-primary bx-sm cursor-pointer pl-2"
                                    onClick={() => POLogsModalOpenFun(x?.purchase_order?.id)}
                                  ></i>
                                  <i
                                    className="bx bx-pencil text-primary bx-sm cursor-pointer pl-2"
                                    onClick={() => setPoObj(x?.purchase_order)}
                                  />
                                  <i
                                    className="bx bx-trash-alt bx-sm text-danger pl-2 cursor-pointer"
                                    onClick={() => {
                                      setPoId(x?.purchase_order?.id);
                                      setIsdeletePO(!isdeletePO);
                                    }}
                                  />
                                  <RenderIf isTrue={x?.purchase_order?.total_adjustments?.length}>
                                    <i
                                      id={`adjustment-tooltip${x?.purchase_order?.id}`}
                                      className="bx bx-info-circle color-primary bx-sm cursor-pointer pl-1"
                                    />
                                  </RenderIf>
                                </>
                                  )
                                : poObj && poObj?.id === x?.purchase_order?.id
                                  ? (
                                <>
                                  <i
                                    className="text-success bx bx-md bx-check cursor-pointer"
                                    onClick={() => updatePoFun(poObj)}
                                  ></i>
                                  <i
                                    className="text-danger bx bx-md bx-x cursor-pointer"
                                    onClick={() => setPoObj()}
                                  ></i>
                                </>
                                    )
                                  : null}
                            </td>
                            {x?.purchase_order?.total_adjustments?.length
                              ? (
                              <CustomTooltip
                                placement="left"
                                content={
                                  <>
                                    <p>
                                      Total Adjustment of price:{" "}
                                      <span className="text-warning pl-1"> {x?.purchase_order?.purchase_order_id}</span>
                                    </p>
                                    <p>
                                      Total Adjustment of units:{" "}
                                      <span className="text-danger pl-1"> {x?.purchase_order?.purchase_order_id}</span>
                                    </p>
                                  </>
                                }
                                target={`adjustment-tooltip${x?.purchase_order?.id}`}
                              />
                                )
                              : null}
                          </tr>
                          {expandState && expandState === x?.id ? (
                            <>
                              <tr>
                                <td colSpan="10" className="p-0">
                                  <Card>
                                    <CardBody className="collapse-card">
                                      <div className="d-flex justify-content-between">
                                        <h6>Items</h6>
                                        <p
                                          className="text-primary cursor-pointer"
                                          onClick={() => setAddNewItemInPO(true)}
                                        >
                                          <i className="bx bx-plus"></i>
                                          Add Item
                                        </p>
                                      </div>
                                      <ReactTable
                                        tableHeader={
                                          <>
                                            {ItemsHeader.map((header, index) => (
                                              <th
                                                onClick={header.onClick}
                                                key={`table-header ${index}`}
                                                className={header.title === "Weight (lbs)" ? "text-center" : ""}
                                              >
                                                {header.title}
                                                {header.sort
                                                  ? (
                                                  <div className="d-inline ml-1">
                                                    <>
                                                      <SortArrows arrow={"up"} />
                                                      <SortArrows arrow={"down"} />{" "}
                                                    </>
                                                  </div>
                                                    )
                                                  : null}
                                              </th>
                                            ))}
                                          </>
                                        }
                                        // Items tabel
                                        tableBody={
                                          <>
                                            {!isEmpty(purchaseOrdersMetadata.line_items)
                                              ? (
                                                  purchaseOrdersMetadata?.line_items.map((x, key) =>
                                                    x.id === itemObj?.id
                                                      ? (
                                                  <tr key={key}>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="item_title"
                                                        value={itemObj?.item_title}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, item_title: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="upc"
                                                        value={itemObj?.upc}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, upc: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="sku"
                                                        value={itemObj?.sku}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, sku: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="supplier_sku"
                                                        value={itemObj?.supplier_sku}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, supplier_sku: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="supplier_url"
                                                        value={itemObj?.supplier_url}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, supplier_url: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="item_id"
                                                        value={itemObj?.item_id}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, item_id: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="asin"
                                                        value={itemObj?.asin}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, asin: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>{0}</td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="quantity"
                                                        value={itemObj?.quantity}
                                                        onChange={(e) => {
                                                          setItemObj({ ...itemObj, quantity: e.target.value });
                                                          reCaculatePoOnUpdate(
                                                            { ...itemObj, quantity: e.target.value },
                                                            x,
                                                          );
                                                        }}
                                                      />
                                                    </td>

                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="qty_multiplier"
                                                        value={itemObj?.qty_multiplier}
                                                        onChange={(e) => {
                                                          setItemObj({ ...itemObj, qty_multiplier: e.target.value });
                                                          reCaculatePoOnUpdate(
                                                            { ...itemObj, qty_multiplier: e.target.value },
                                                            x,
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="cost_per_item"
                                                        value={itemObj?.cost_per_item}
                                                        onChange={(e) => {
                                                          setItemObj({ ...itemObj, cost_per_item: e.target.value });
                                                          reCaculatePoOnUpdate(
                                                            { ...itemObj, cost_per_item: e.target.value },
                                                            x,
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control "
                                                        placeholder="shipping_per_item"
                                                        value={itemObj?.shipping_per_item}
                                                        onChange={(e) => {
                                                          setItemObj({ ...itemObj, shipping_per_item: e.target.value });
                                                          reCaculatePoOnUpdate(
                                                            { ...itemObj, shipping_per_item: e.target.value },
                                                            x,
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        className="inner form-control min-width-150"
                                                        placeholder="tax"
                                                        value={itemObj?.tax}
                                                        onChange={(e) =>
                                                          setItemObj({ ...itemObj, tax: e.target.value })
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <>
                                                        <i
                                                          className="text-success bx bx-md bx-check cursor-pointer"
                                                          onClick={() => updateItemFun(itemObj)}
                                                        ></i>
                                                        <i
                                                          className="text-danger bx bx-md bx-x cursor-pointer"
                                                          onClick={() => {
                                                            setPurchaseOrderObj();
                                                            setItemObj();
                                                          }}
                                                        ></i>
                                                      </>
                                                    </td>
                                                  </tr>
                                                        )
                                                      : (
                                                  <tr key={key}>
                                                    <td>
                                                      <span className="text-primary">{x.item_title}</span>
                                                    </td>
                                                    <td>
                                                      <ShowUpc index={key} upc={x.upc} />
                                                    </td>
                                                    <td>{x.sku}</td>
                                                    <td>{x.supplier_sku}</td>
                                                    <td className="d-flex align-items-center">
                                                      <a
                                                        id={`URL_tooltip__${x.id}`}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        href={x.supplier_url}
                                                      >
                                                        {x.supplier_url?.slice(0, 25)}{" "}
                                                      </a>
                                                      <RenderIf isTrue={x?.supplier_url?.length}>
                                                        <CopyToClipBoard text={x.supplier_url} />
                                                      </RenderIf>
                                                      <CustomTooltip
                                                        placement="left"
                                                        content={x.supplier_url}
                                                        target={`URL_tooltip__${x.id}`}
                                                      />
                                                    </td>
                                                    <td>{x.item_id}</td>
                                                    <td>{x.asin}</td>
                                                    <td>{0}</td>
                                                    <td>{x.quantity}</td>
                                                    <td>{x?.qty_multiplier}</td>
                                                    <td>{x.cost_per_item}</td>
                                                    <td>{x.shipping_per_item}</td>
                                                    <td>{x?.tax}</td>
                                                    <td>
                                                      <div>
                                                        <i
                                                          className="bx bx-edit bx-sm text-primary cursor-pointer"
                                                          onClick={() => setItemObj(x)}
                                                        />
                                                        {purchaseOrdersMetadata?.line_items?.length > 1 && (
                                                          <ConfirmDialogIcon
                                                            icon={{
                                                              title: "Delete Account",
                                                              className:
                                                                "bx bx-trash-alt bx-sm text-danger pl-2 cursor-pointer",
                                                            }}
                                                            msg={<p>To Delete item.</p>}
                                                            onConfirm={() =>
                                                              deletePOItemFun({
                                                                POid: x.po_id,
                                                                WhOrderId: x.wh_order_id,
                                                                ItemId: x.id,
                                                              })
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                        ),
                                                  )
                                                )
                                              : (
                                              <tr>
                                                <RenderIf isTrue={!addNewItemInPO}>
                                                  <td colSpan={12}>
                                                    {" "}
                                                    <h2 className="text-center">No item exist.</h2>
                                                  </td>
                                                </RenderIf>
                                              </tr>
                                                )}

                                            <RenderIf isTrue={addNewItemInPO}>
                                              <>
                                                {dynamicallyAddItems}
                                                <tr>
                                                  <td colSpan={13} className="text-right">
                                                    <Button
                                                      color="primary mr-3"
                                                      onClick={() => handleInputValidation("validateAndAddLineItems")}
                                                    >
                                                      <span className="d-flex align-items-center">
                                                        <img
                                                          src={FloppyWhite}
                                                          alt="no-icon"
                                                          width={16}
                                                          className="add-bottom-space mr-2"
                                                        />
                                                        Add Items
                                                      </span>
                                                    </Button>
                                                    <Button color="secondary" onClick={() => setAddNewItemInPO(false)}>
                                                      Cancel
                                                    </Button>
                                                  </td>
                                                </tr>
                                              </>
                                            </RenderIf>
                                          </>
                                        }
                                      />
                                    </CardBody>
                                    <CardBody className="collapse-card">
                                      <div className="d-flex justify-content-between">
                                        <h6>Shipments</h6>
                                        {!isEmpty(purchaseOrdersMetadata.line_items) &&
                                        purchaseOrdersMetadata?.line_items?.length
                                          ? (
                                          <p
                                            className="text-primary cursor-pointer"
                                            onClick={() => shipmentModalOpenFun("create")}
                                          >
                                            <i className="bx bx-plus"></i>
                                            Add Shipment
                                          </p>
                                            )
                                          : (
                                          <>
                                            <p id="disableShipmentTooltip" className="disabled cursor-pointer">
                                              <i className="bx bx-plus"></i>
                                              Add Shipment
                                            </p>
                                            <CustomTooltip
                                              placement="top"
                                              content={<>Add items to create shipment.</>}
                                              target="disableShipmentTooltip"
                                            />
                                          </>
                                            )}
                                      </div>
                                      <ReactTable
                                        tableHeader={
                                          <>
                                            {shipmentHeader.map((header, index) => (
                                              <th
                                                onClick={header.onClick}
                                                key={`table-header ${index}`}
                                                className={header.title === "Weight (lbs)" ? "text-center" : ""}
                                              >
                                                {header.title}
                                                {header.sort
                                                  ? (
                                                  <div className="d-inline ml-1">
                                                    <>
                                                      <SortArrows arrow={"up"} />
                                                      <SortArrows arrow={"down"} />{" "}
                                                    </>
                                                  </div>
                                                    )
                                                  : null}
                                              </th>
                                            ))}
                                          </>
                                        }
                                        // Shipments tabel
                                        tableBody={
                                          <>
                                            {!isEmpty(purchaseOrdersMetadata) &&
                                            purchaseOrdersMetadata?.shipments?.length
                                              ? (
                                                  purchaseOrdersMetadata?.shipments.map((x, i) => (
                                                <tr key={i}>
                                                  <td>
                                                    <span className="text-primary">{x.tracking_number}</span>
                                                  </td>
                                                  <td>
                                                    <span
                                                      className={`font-size-12 badge badge-pill ${
                                                        x.tracking_status ? "badge-soft-success" : "badge-soft-danger"
                                                      }`}
                                                    >
                                                      {x.tracking_status ?? "N/A"}
                                                    </span>
                                                  </td>
                                                  <td>{x.total_skus}</td>
                                                  <td>{sumBy(x.line_items, "received_shipments.expected_qty")}</td>
                                                  <td>{x.units_received}</td>
                                                  <td>
                                                    <span
                                                      className={`font-size-12 badge-soft-success  badge badge-pill ${
                                                        x.status === "complete"
                                                          ? "badge-soft-success"
                                                          : "badge-soft-danger"
                                                      }`}
                                                    >
                                                      {x.status}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <i
                                                        className="fa fa-eye bx-sm text-primary cursor-pointer"
                                                        onClick={() => {
                                                          setViewShipmentitem(x.line_items);
                                                          setTrackingNum(x);
                                                          shipmentModalOpenFun("read");
                                                        }}
                                                      ></i>
                                                      <i
                                                        className="bx bx-edit bx-sm text-primary pl-2 cursor-pointer"
                                                        onClick={() => {
                                                          setViewShipmentitem(x.line_items);
                                                          shipmentModalOpenFun("update");
                                                          setTrackingNum(x);
                                                        }}
                                                      />
                                                      <ConfirmDialogIcon
                                                        icon={{
                                                          title: "Delete Account",
                                                          className:
                                                            "bx bx-trash-alt bx-sm text-danger pl-2 cursor-pointer",
                                                        }}
                                                        msg={<p>To Delete Shipment.</p>}
                                                        onConfirm={() =>
                                                          deletePOShipmentFun({ POid: x.po_id, shipmentId: x.id })
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>
                                                  ))
                                                )
                                              : (
                                              <tr>
                                                <td colSpan={12}>
                                                  {" "}
                                                  <h2 className="text-center">No shipment exist.</h2>
                                                </td>
                                              </tr>
                                                )}
                                          </>
                                        }
                                      />
                                    </CardBody>
                                  </Card>
                                </td>
                              </tr>
                            </>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </>
                  }
                />
              </CardBody>
              {purchaseOrders?.res?.per_page
                ? (
                <div className="tent-pagination">
                  <CustomPagination
                    pageOptions={[30, 50, 100]}
                    total={purchaseOrders?.res?.count}
                    page={purchaseOrders?.res?.page}
                    perPage={purchaseOrders?.res?.per_page}
                    filters={filters}
                    tabsFilter={filters}
                    setTabFilter={(e) => setFilters(filters.per_page !== e.per_page ? { ...e, page: 1 } : e)}
                  />
                </div>
                  )
                : null}
            </form>
          </Card>
        </Container>
      </div>
      {adjustmentModalIsOpen && (
        <AdjustmentModal
          PODetail={purchaseOrderObj}
          itemsDetail={inputList}
          pageSize={purchaseOrders?.res?.per_page}
          type={shipmentModalType}
        />
      )}
      {shipmentModalIsOpen && (
        <ShipmentModal
          itemsDetail={shipmentModalType === "read" ? viewShipmentitem : purchaseOrdersMetadata?.line_items}
          shipmentItemsDetail={viewShipmentitem}
          type={shipmentModalType}
          trackingNum={trackingNum}
          existingPoShipments={existingPoShipments}
        />
      )}
      {POLogsModalIsOpen && <PurchaseOrderLog />}
    </>
  );
};
export default PurchaseOrderUser;
