import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { RenderIf, toPascalCase } from "../../utils/common";
import { MARKET_PLACES, ACCOUNT_STATUSES } from "../../constants/index";
import { sortBy } from "lodash";

// Pages Components
import OrderStatusStats from "./components/OrderStatusStats";
import ProfitChart from "./components/ProfitChart";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { setPreloader } from "../../store/actions";

const FiftyDashboard = () => {
  const [marketPlace, setMarketPlace] = useState({ value: "", label: "All Stores" });
  const [storeName, setStoreName] = useState("");

  const [user, setUser] = useState({ value: "", label: "All Tenants" });
  const [userId, setUserId] = useState("");

  const dispatch = useDispatch();
  const { account: tenantAccount, allTenants, loading: tenantAccountLoading } = useSelector((state) => state.Settings);
  const { loading } = useSelector((state) => state.Graph);

  useEffect(() => {
    dispatch(setPreloader(tenantAccountLoading || loading));
  }, [loading, tenantAccountLoading]);

  return (
    <React.Fragment>
      <div className="page-content">
        <RenderIf isTrue={tenantAccount?.data?.stores_count > 0}>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="50/50 Dashboard"
              children={
                <div className="d-flex">
                  {allTenants?.length > 1 && (
                    <>
                      <FormGroup className="select2-container mr-2" style={{ minWidth: "250px" }} id="users">
                        <Select
                          value={{
                            value: user.value,
                            label: user.label,
                          }}
                          onChange={(e) => {
                            setUserId(e.value);
                            setUser({ value: e.value, label: e.label });
                          }}
                          options={sortBy(allTenants, (a) => a.status.length).reduce(
                            (acc, tenant, i) => {
                              (tenant.status === ACCOUNT_STATUSES.active ||
                                (tenant.status === ACCOUNT_STATUSES.in_active && tenant.stores_count > 0)) &&
                                acc.push({
                                  label: tenant.email,
                                  value: tenant.id,
                                  key: i,
                                });
                              return acc;
                            },
                            [{ value: "", label: "All Tenants" }],
                          )}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    </>
                  )}
                  <FormGroup className="select2-container ml-2" style={{ width: "200px" }}>
                    <Select
                      name="marketplace"
                      value={{
                        value: marketPlace.value,
                        label: marketPlace.label,
                      }}
                      onChange={(e) => {
                        setStoreName(e.value);
                        setMarketPlace({ value: e.value, label: e.label });
                      }}
                      options={Object.keys(MARKET_PLACES)
                        .splice(0, 2)
                        .reduce(
                          (init, acc, i) => {
                            init.push({
                              label: toPascalCase(acc),
                              value: MARKET_PLACES[acc],
                              key: i,
                            });
                            return init;
                          },
                          [{ value: "", label: "All Stores" }],
                        )}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </div>
              }
            />

            <Row>
              <Col md="6">
                <OrderStatusStats marketplace={storeName} userId={userId} type="shared" />
              </Col>
              <Col md="6">
                <ProfitChart marketplace={storeName} userId={userId} type="shared" />
                {/* <TopSellingItems userId={userId} abilities={userAbility} type="global" /> */}
              </Col>
            </Row>
          </Container>
        </RenderIf>
        <RenderIf isTrue={tenantAccount?.data && !tenantAccount.data.stores_count}>
          <div className="d-flex justify-content-center m-2">
            <div className="d-flex flex-column">
              <h1>No Records Found</h1>
            </div>
          </div>
        </RenderIf>
      </div>
    </React.Fragment>
  );
};

export default FiftyDashboard;
