import React from "react";
import { State } from "country-state-city";
import { getUserInfo } from "../../utils/common";
import Lazy from "../Common/LazyComponent";
import { IL_WH_ID } from "../../constants";

const getText = (text) => text || "";

export const getWHAddress = (warehouse, stateName, isJSX = false) => {
  const { state, country, address2, id } = warehouse;
  const isILWH = id === IL_WH_ID;
  const tenantId = getUserInfo()?.account_id;

  if (!stateName) {
    stateName = country
      ? State.getStatesOfCountry(country)?.find((x) => x.isoCode.toLowerCase() === state?.toLowerCase())?.name
      : "";
  }
  if (!warehouse) return "";
  const suiteNoAddress2 = `${address2 || ""}${isILWH ? "" : ` STE # ${getText(tenantId)}`}`;

  return isJSX
    ? (
    <div className="d-flex flex-column">
      {[`Warehouse ${state}`, `${warehouse.address1}${isILWH ? `-${getText(tenantId)}` : ""}`, suiteNoAddress2].map(
        (x, i) => (
          <span key={"address__" + i}>{getText(x)}</span>
        ),
      )}

      <span>
        {getText(warehouse?.city + ", ")}
        <span id={`_state_${warehouse.id}`} className="cursor-pointer">
          {warehouse?.state + ", "}
        </span>
        {stateName ? <Lazy path="./CustomTooltip" target={`_state_${warehouse.id}`} content={stateName} /> : null}
        {[warehouse?.country + ",", warehouse?.zipcode].filter(Boolean).join(" ")}
      </span>
    </div>
      )
    : (
        [warehouse?.address1, suiteNoAddress2, warehouse?.city, warehouse?.state, warehouse?.country, warehouse?.zipcode]
          .filter(Boolean)
          .join(", ") || ""
      );
};
