import React from "react";
import { RenderIf } from "../../../utils/common";

const renderErrors = (formik, fields = []) => {
  const { errors, touched } = formik;
  return fields.map((field, _i) => (
    <React.Fragment key={`_err_${_i + 1}_`}>
      <RenderIf isTrue={touched[field] && errors[field]}>
        <small className="text-danger m-1">{errors[field]}</small>
      </RenderIf>
    </React.Fragment>
  ));
};

export default renderErrors;
