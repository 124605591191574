import React, { useEffect, useState } from "react";
import { Card, Container, CardBody, CardHeader, Col, Row, FormGroup } from "reactstrap";
import { connect, useSelector } from "react-redux";
import Select, { components } from "react-select";

import queryString from "query-string";
// actions
import { fetchMarketplaceFeed, setPreloader } from "../../store/actions";
import CustomPagination from "../../components/Common/CustomPagination";
import moment from "moment-timezone";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toPascalCase } from "../../utils/common";
import getBrandImageSrc from "../../utils/brandImages";
import { DATE_FORMATS } from "../../constants";
import ArchiveBadge from "../../components/Common/ArchiveBadge";
const { FULL_DATE } = DATE_FORMATS;

const MarketplaceFeed = (props) => {
  const { Option } = components;

  const StoreOption = (props) => (
    <Option {...props} className="d-flex align-items-center">
      {getBrandImageSrc(props.data.marketplace)
        ? (
        <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
          )
        : null}
      {props.data.label}
      {props.data.is_archive && ArchiveBadge()}
    </Option>
  );

  const [marketPlace, setMarketPlace] = useState({
    value: "",
    label: "Select an Account",
  });
  const [chkboxInformed, setChkboxInformed] = useState(false);
  const queryParameters = queryString.parse(props.location.search);
  const { accounts, loading: AccountLoading } = useSelector((state) => state.MarketPlaceAccount);
  const tableHeaders = [
    { title: "Feed ID" },
    { title: "Feed Type" },
    { title: "S3 URL" },
    { title: "Feed Status" },
    { title: "Created At" },
  ];

  const [filters, setFilters] = useState({
    page_number: queryParameters?.page || 1,
    per_page: 30,
    partner_id: null,
  });

  useEffect(() => {
    if (filters.partner_id || filters.feed_type) {
      props.fetchMarketplaceFeed({ ...filters });
    }
  }, [filters]);

  useEffect(() => {
    if (accounts?.data && !AccountLoading) {
      const accountId = accounts?.data?.[0]?.id;
      if (accountId) {
        setFilters({
          ...filters,
          partner_id: accountId,
        });
        setMarketPlace({
          label: accounts?.data?.[0]?.name,
          value: accounts?.data?.[0]?.id,
          marketplace: accounts?.data?.[0]?.marketplace,
        });
      }
    }
  }, [accounts]);

  useEffect(() => {
    props.setPreloader(props.Feed.loading);
  }, [props.Feed.loading]);

  const checkInformed = () => {
    if (chkboxInformed) {
      delete filters.feed_type;
      setFilters({ ...filters });
    } else {
      setFilters({
        ...filters,
        feed_type: "informed",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Feeds" />
          <Card>
            <CardHeader>
              <Row>
                <Col md={3} className="m-2">
                  <div className="d-flex">
                    <FormGroup className="select2-container ml-2" style={{ minWidth: "70%" }}>
                      <Select
                        name="marketplace_id"
                        value={{
                          value: marketPlace.value,
                          label: marketPlace.label,
                        }}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            partner_id: e.value,
                          });
                          setMarketPlace(e);
                        }}
                        options={accounts?.data?.reduce((init, acc, i) => {
                          init.push({
                            label: acc.name,
                            value: acc.id,
                            marketplace: acc.marketplace,
                            is_archive: acc.is_archive,
                          });
                          return init;
                        }, [])}
                        classNamePrefix="select2-selection"
                        components={{ Option: StoreOption }}
                      />
                    </FormGroup>
                    <div className="custom-control custom-checkbox ml-2 mt-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="blocked"
                        checked={chkboxInformed}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          checkInformed();
                          setChkboxInformed(!chkboxInformed);
                        }}
                        className="custom-control-label"
                      >
                        Informed&nbsp;Feed
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!props.Feed.marketPlace?.results?.length && <h2 className="text-center">No Records Found</h2>}
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  {props.Feed.marketPlace?.results?.length > 0 && (
                    <thead className="thead-light">
                      <tr>
                        {/* table headers */}
                        {tableHeaders.map((header, index) => (
                          <th key={`table-header ${index}`}>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {/* iterating data */}
                    {props.Feed?.marketPlace?.results?.map((item, key) => (
                      <tr key={"_parse_email" + key} className="mt-3 mb-3">
                        <td>{item.feed_id}</td>
                        <td>{toPascalCase(item.feed_type)}</td>
                        <td>
                          <a href={item && item.s3_url} target="_blank" rel="noopener noreferrer">
                            View Submitted Feed
                          </a>
                        </td>
                        <td>{toPascalCase(item.status)}</td>
                        <td>{moment(item?.feed_created_at).format(FULL_DATE) || ""}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {props.Feed.marketPlace?.results?.length > 0 && (
                <CustomPagination
                  total={props.Feed.marketPlace.total_count}
                  page={props.Feed.marketPlace.page_number}
                  perPage={props.Feed.marketPlace.per_page}
                  tabsFilter={filters}
                  setTabFilter={setFilters}
                  pageOptions={[30, 100]}
                  matching={true}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { Feed, MarketPlaceAccount } = state;
  return { Feed, MarketPlaceAccount };
};

export default connect(mapStatetoProps, {
  fetchMarketplaceFeed,
  setPreloader,
})(MarketplaceFeed);
