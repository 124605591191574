import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, CardBody, Alert, Badge, Spinner } from "reactstrap";
import { setPreloader, fetchAOECSTasks, stopAOECSTask } from "../../store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { RenderIf, toPascalCase } from "../../utils/common";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import CustomTooltip from "../../components/Common/CustomTooltip";
import moment from "moment-timezone";
import { sortBy } from "lodash";
import { ECS_STATUS_COLOR_CODE } from "../../constants";
import getBrandImageSrc from "../../utils/brandImages";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";

function sortTasks (tasks, filter) {
  const { col, direction } = filter;
  const data = sortBy(tasks, (item) => (col === "startedAt" ? moment(item.startedAt) : item[col]));
  return direction === "desc" ? data.reverse() : data;
}

function getTaskId (task) {
  return task.taskArn.split("/").pop();
}

const AoEcsScreens = () => {
  const dispatch = useDispatch();
  const { error, loading, tasks, taskId } = useSelector((state) => state.AOScreen);
  const [sortFilter, setSortFilter] = useState({ col: "startedAt", direction: "asc" });

  const tableHeaders = [
    { title: "Platform", class: "text-center", sortCol: "platform" },
    { title: "Task ARN" },
    { title: "Order Ids" },
    { title: "Buying Account", sortCol: "email" },
    { title: "Tenant Info", sortCol: "tenant_email" },
    { title: "Started Since", sortCol: "startedAt" },
    { title: "ECS Info", class: "text-center" },
    { title: "Token" },
    { title: "Stop" },
  ];

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }
  function fetchTasks () {
    dispatch(fetchAOECSTasks());
  }

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between m-2">
            <Breadcrumbs title="AO ECS Tasks" />
            <i className="mdi mdi-refresh ml-2 h1 cursor-pointer" onClick={() => fetchTasks()}></i>
          </div>
          {error && (
            <div className="auto-hide">
              <Alert color="danger" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {error || ""}
              </Alert>
            </div>
          )}
          <Card>
            <CardBody>
              {tasks?.length
                ? (
                <>
                  <div className="d-flex justify-content-between m-2">
                    <h4>{`Pending: ${tasks.filter((x) => !x.startedAt).length}`}</h4>
                    <h4>{`Running: ${tasks.filter((x) => x.startedAt).length}`}</h4>
                    <h4>{`Total: ${tasks.length}`}</h4>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <th
                              className={header.class || ""}
                              key={`table-header ${index}`}
                              style={/ecs info/i.test(header.title) ? { width: "210px" } : {}}
                            >
                              {header.title}
                              {header.sortCol
                                ? (
                                <div className="d-inline">
                                  <i
                                    id="up-filter"
                                    className="dripicons-arrow-thin-up cursor-pointer mx-1"
                                    onClick={() => setSortFilter({ col: header.sortCol, direction: "asc" })}
                                  ></i>
                                  <i
                                    id="down-filter"
                                    className="dripicons-arrow-thin-down cursor-pointer mx-1"
                                    onClick={() => setSortFilter({ col: header.sortCol, direction: "desc" })}
                                  ></i>
                                </div>
                                  )
                                : null}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {sortTasks(tasks, sortFilter).map((item, key) => (
                          <tr
                            key={"_listings_" + key}
                            className={
                              "mt-3 mb-3" +
                              (tasks.filter((x) => x.email === item.email).length > 1 ? " row-danger" : "")
                            }
                          >
                            <td className="text-center">
                              <img src={getBrandImageSrc(item.platform)} alt="store logo" />
                            </td>
                            <td>
                              {getTaskId(item)}
                              <CopyToClipBoard text={item.taskArn} />
                            </td>
                            <td className="d-flex align-items-center">
                              {`${item.store_order_id} / ${item.order_id}`}
                              <a
                                href={`/source_orders_details/${item.store_order_id}/${item.order_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="bx bx-link-external ml-2 font-size-20 mt-1"></i>
                              </a>
                            </td>
                            <td>
                              {item.email}
                              <CopyToClipBoard text={item.email} />
                            </td>
                            <td>
                              <span id={`tenantEmail${key}`}>{item.tenant_email}</span>
                              <CustomTooltip target={`tenantEmail${key}`} content={`Tenant ID: ${item.tenant}`} />
                            </td>
                            <td>
                              {item.startedAt
                                ? (
                                <>
                                  <span id={`startedSince${key}`}>{moment(item.startedAt).fromNow()}</span>
                                  <CustomTooltip
                                    target={`startedSince${key}`}
                                    content={moment(item.startedAt).format("dddd(DD-MM-YYYY) hh:mm A")}
                                  />
                                </>
                                  )
                                : (
                                    ""
                                  )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">Launch Type:</span>
                                <span>{toPascalCase(item.launchType)}</span>
                              </div>
                              <div className="d-flex justify-content-between my-2">
                                <span className="font-weight-bold">Current Status:</span>
                                <Badge
                                  className={"font-size-11 ml-2 badge-soft-" + ECS_STATUS_COLOR_CODE(item.lastStatus)}
                                  color={ECS_STATUS_COLOR_CODE(item.lastStatus)}
                                >
                                  {toPascalCase(item.lastStatus)}
                                </Badge>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">Desired Status:</span>
                                <Badge
                                  className={"font-size-11 badge-soft-" + ECS_STATUS_COLOR_CODE(item.desiredStatus)}
                                  color={ECS_STATUS_COLOR_CODE(item.desiredStatus)}
                                >
                                  {toPascalCase(item.desiredStatus)}
                                </Badge>
                              </div>
                            </td>
                            <td>
                              <CopyToClipBoard text={item.token} />
                            </td>
                            <td>
                              <RenderIf
                                isTrue={taskId !== getTaskId(item)}
                                fallback={
                                  <Spinner
                                    color="secondary"
                                    style={{ width: "20px", height: "20px" }}
                                    className="ml-3"
                                  />
                                }
                              >
                                <ConfirmDialogIcon
                                  icon={{ className: "fa fa-lg fa-trash-alt ml-3" }}
                                  title="Stop AO ECS Task"
                                  msg={
                                    <>
                                      <p>To stop this AO ECS task</p>
                                      <p>{getTaskId(item)}</p>
                                    </>
                                  }
                                  onConfirm={() => dispatch(stopAOECSTask(getTaskId(item)))}
                                />
                              </RenderIf>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
                  )
                : null}
              {tasks?.length === 0 && !loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AoEcsScreens;
