import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getAllEmails, getAllEmailUpdates, getAllS3Objects, updateS3Object } from "../../api";
// Login Redux States
import { FETCH_EMAILS, FETCH_ORDER_UPDATES, FETCH_S3_OBJECTS, UPDATE_S3_OBJECT } from "./actionTypes";
import {
  apiError,
  fetchEmailsSuccess,
  fetchOrderUpdatesSuccess,
  fetchS3ObjectsSuccess,
  updateS3ObjectSuccess,
} from "./actions";

function * fetchEmailsSaga ({ payload: { data } }) {
  try {
    const res = yield call(getAllEmails, data);
    yield put(fetchEmailsSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

function * fetchS3ObjectsSaga ({ payload: { data } }) {
  try {
    const res = yield call(getAllS3Objects, data);
    yield put(fetchS3ObjectsSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

function * updateS3ObjectSaga ({ payload: { id, params } }) {
  try {
    const res = yield call(updateS3Object, id, params);
    yield put(updateS3ObjectSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

function * fetchOrderUpdatesSaga ({ payload: { data } }) {
  try {
    const res = yield call(getAllEmailUpdates, data);
    yield put(fetchOrderUpdatesSuccess(res));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function * watchEmailsFetch () {
  yield takeEvery(FETCH_EMAILS, fetchEmailsSaga);
}

export function * watchS3ObjectUpdate () {
  yield takeEvery(UPDATE_S3_OBJECT, updateS3ObjectSaga);
}

export function * watchOrderUpdatesFetch () {
  yield takeEvery(FETCH_ORDER_UPDATES, fetchOrderUpdatesSaga);
}

export function * watchS3ObjectsFetch () {
  yield takeEvery(FETCH_S3_OBJECTS, fetchS3ObjectsSaga);
}

function * emailSaga () {
  yield all([
    fork(watchEmailsFetch),
    fork(watchOrderUpdatesFetch),
    fork(watchS3ObjectsFetch),
    fork(watchS3ObjectUpdate),
  ]);
}

export default emailSaga;
