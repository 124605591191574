import {
  ADD_PROXY_USER,
  API_ERROR,
  CREATE_PROXY_USER,
  CREATE_PROXY_USER_SUCCESS,
  FETCH_PROXY_USERS,
  FETCH_PROXY_USERS_SUCCESS,
  SET_ADD_PROXY_USER_MODEL,
  UPDATE_PROXY_USER,
  UPDATE_PROXY_USER_SUCCESS,
  FETCH_ALL_PROXIES,
  FETCH_ALL_PROXIES_SUCCESS,
  FETCH_PROXY_BY_PORT,
  FETCH_PROXY_BY_PORT_SUCCESS,
  SWAP_PROXY_BY_PORT,
  SWAP_PROXY_BY_PORT_SUCCESS,
  SET_UPDATE_LPM_PROXY_MODAL,
  SET_SELECTED_PROXY,
  UPDATE_LPM_PROXY,
  UPDATE_LPM_PROXY_SUCCESS,
  SET_UPLOAD_PROXY_MODAL,
  UPLOAD_PROXY_CSV,
  UPLOAD_PROXY_CSV_SUCCESS,
} from "./actionTypes";

export const addProxyUser = (user) => {
  return {
    type: ADD_PROXY_USER,
    payload: user,
  };
};

export const setAddProxyUserModel = (isOpen) => {
  return {
    type: SET_ADD_PROXY_USER_MODEL,
    payload: isOpen,
  };
};

export const fetchProxyUsers = (filter) => {
  return {
    type: FETCH_PROXY_USERS,
    payload: filter,
  };
};

export const fetchProxyUsersSuccess = (accounts) => {
  return {
    type: FETCH_PROXY_USERS_SUCCESS,
    payload: accounts,
  };
};

export const createProxyUser = (proxyUser) => {
  return {
    type: CREATE_PROXY_USER,
    payload: proxyUser,
  };
};

export const createProxyUserSuccess = (message) => {
  return {
    type: CREATE_PROXY_USER_SUCCESS,
    payload: message,
  };
};

export const updateProxyUser = (proxyUser) => {
  return {
    type: UPDATE_PROXY_USER,
    payload: proxyUser,
  };
};

export const updateProxyUserSuccess = (message) => {
  return {
    type: UPDATE_PROXY_USER_SUCCESS,
    payload: message,
  };
};

export const fetchLuminatiProxies = (_) => {
  return {
    type: FETCH_ALL_PROXIES,
    payload: {},
  };
};

export const fetchLuminatiProxiesSuccess = (data) => {
  return {
    type: FETCH_ALL_PROXIES_SUCCESS,
    payload: data,
  };
};

export const fetchPorxyByPort = (port) => {
  return {
    type: FETCH_PROXY_BY_PORT,
    payload: port,
  };
};

export const fetchProxyByPortSuccess = (data) => {
  return {
    type: FETCH_PROXY_BY_PORT_SUCCESS,
    payload: data,
  };
};

export const swapPorxyByPort = (port) => {
  return {
    type: SWAP_PROXY_BY_PORT,
    payload: port,
  };
};

export const swapProxyByPortSuccess = (data) => {
  return {
    type: SWAP_PROXY_BY_PORT_SUCCESS,
    payload: data,
  };
};

export const setUpdateLpmProxyModal = (isOpen) => {
  return {
    type: SET_UPDATE_LPM_PROXY_MODAL,
    payload: isOpen,
  };
};

export const setUploadProxyModal = (isOpen) => {
  return {
    type: SET_UPLOAD_PROXY_MODAL,
    payload: isOpen,
  };
};

export const uploadProxyCsv = (data) => ({
  type: UPLOAD_PROXY_CSV,
  payload: data,
});

export const uploadProxyCsvSuccess = (msg) => ({
  type: UPLOAD_PROXY_CSV_SUCCESS,
  payload: msg,
});

export const setSelectedProxy = (proxy) => {
  return {
    type: SET_SELECTED_PROXY,
    payload: proxy,
  };
};

export const updateLpmProxy = (data, port) => ({
  type: UPDATE_LPM_PROXY,
  payload: { data, port },
});

export const updateLpmProxySuccess = () => ({
  type: UPDATE_LPM_PROXY_SUCCESS,
  payload: {},
});

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
