import React from "react";
import * as actions from "../../../store/actions";
import Modal from "../../../components/Common/Modal";
import { useDispatch, useSelector } from "react-redux";
import PillBtn from "../../../components/Common/PillBtn";
import { RenderIf } from "../../../utils/common";

const ConfirmationDialog = ({ onSave, message, isError, errorMessage }) => {
  const dispatch = useDispatch();
  const { repricerListingsModalIsOpen } = useSelector((state) => state.Repricer);
  const toggleModal = () => dispatch(actions.setRepricerListingsModal(!repricerListingsModalIsOpen));
  const confirmButtonTitle = isError ? "OK" : "Confirm";
  return (
    <Modal style={{ maxWidth: "534px" }} size="md" isOpen={repricerListingsModalIsOpen}>
      <div className="px-5 pb-4">
        <i
          className="bx bx-sm bx-x inventory-color cursor-pointer"
          style={{ position: "absolute", right: "24px", top: "24px" }}
          onClick={toggleModal}
        />
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <i style={{ fontSize: "100px" }} className="text-danger bx bx-error" />
        </div>
        <RenderIf
          isTrue={!isError}
          fallback={
            <div className="mt-3 d-flex justify-content-center align-items-center font-weight-medium text-center text-warning font-size-13">
              <i className="bx bx-info-circle mr-1" />
              {errorMessage}
            </div>
          }
        >
          <div className="mt-3 d-flex justify-content-center align-items-center font-weight-semibold inventory-color font-size-20">
            Are You Sure?
          </div>
          <div className="mt-3 d-flex justify-content-center align-items-center font-weight-medium text-center font-size-13">
            {message}
          </div>
        </RenderIf>
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <PillBtn className="mr-2" title="Cancel" name="Cancel" color="primary" outline={true} onClick={toggleModal} />
          <PillBtn
            className={`ml-2 ${isError ? "px-4" : ""}`}
            title={confirmButtonTitle}
            name={confirmButtonTitle}
            color="primary"
            onClick={() => {
              onSave();
              toggleModal();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
