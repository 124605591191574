import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Tooltip } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

// actions
import { fetchBuyBoxStats } from "../../../store/actions";
import { humanize } from "../../../utils/common";
import { DATE_FORMATS } from "../../../constants";
import DateRange from "../../../components/Common/DateRange";

const BuyBox = (props) => {
  const dispatch = useDispatch();
  const accountId = props.accountId;
  const timezone = props.timezone;
  const { buyboxStats, buyBoxLoader } = useSelector((state) => state.Graph);
  const [toolTipBuyBox, setToolTipBuyBox] = useState(false);

  const currentDate = moment().startOf("day");
  const oneWeekBefore = moment().subtract(6, "days").startOf("day");
  const [dates, setDates] = useState([oneWeekBefore, currentDate]);
  const [seriesStock, setSeriesStock] = useState([]);
  const getDate = (date) => moment(date).utc();

  const fetchGraphDetails = ({ startDate, endDate }) => {
    dispatch(
      fetchBuyBoxStats({
        start_date: moment.utc(startDate).format("YYYY-MM-DD"),
        end_date: moment.utc(endDate).format("YYYY-MM-DD"),
        partner_id: accountId,
      }),
    );
  };

  useEffect(() => {
    fetchGraphDetails({
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
    });
  }, [accountId, timezone, dates[0], dates[1]]);

  useEffect(() => {
    !buyBoxLoader && buyboxStats?.bbox_stats?.length
      ? setSeriesStock([
        {
          name: "Buy Boxed",
          data: filterResults(buyboxStats.bbox_stats),
        },
      ])
      : setSeriesStock([{ name: "Buy Boxed", data: [[dates[0]], [dates[1]]] }]);
  }, [buyboxStats]);

  const filterResults = (stats) => {
    return (
      stats
        .map((refresher) => [getDate(refresher.date_hour), refresher.buy_box])
        .filter(
          (x) =>
            x[0].isSameOrAfter(moment(dates[0]).startOf("day"), "day") &&
            x[0].isSameOrBefore(moment(dates[1]).endOf("day"), "day"),
        ) || []
    );
  };

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2.3,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tickAmount: 3,
      labels: { formatter: (_, timestamp) => moment(timestamp).add(5, "hours").format(DATE_FORMATS.DATE) }, // Timestamp is ALWAYS UTC
    },
    yaxis: { labels: { formatter: (x) => humanize(x) } },
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="card-title mb-4">
              {buyBoxLoader ? "Loading..." : "Buy Box Stats  "}
              {buyboxStats?.bbox_stats?.length
                ? (
                <i id="bb-percentage" className="bx bx-info-circle text-primary" />
                  )
                : null}
            </h4>
            {buyboxStats?.bbox_stats?.length
              ? (
              <Tooltip
                placement="right"
                isOpen={toolTipBuyBox}
                target="bb-percentage"
                toggle={() => setToolTipBuyBox(!toolTipBuyBox)}
              >
                {parseInt(
                  (buyboxStats.bbox_stats[buyboxStats.bbox_stats.length - 1].buy_box /
                    buyboxStats.bbox_stats[buyboxStats.bbox_stats.length - 1].in_stock_published) *
                    100,
                )}{" "}
                % of buybox listings. Considers Active Listings only (Active Listings are listings which are both
                published and which have stock)
              </Tooltip>
                )
              : null}
          </div>
          <div className="d-flex flex-row float-sm-right">
            <DateRange dates={dates} setDates={setDates} loader={buyBoxLoader} timePeriods={[7, 30, 365]} />
          </div>
        </div>
        <ReactApexChart options={options} series={seriesStock} type="line" height="350" />
      </CardBody>
    </Card>
  );
};

export default BuyBox;
