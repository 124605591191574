import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardHeader, Container, Alert, Button, FormGroup, Input } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SubmitBulkUnverifiedMatchesModal from "./components/submitBulkUnverifiedMatchesModal";
// actions
import {
  fetchUnverifiedMatches,
  setPreloader,
  fetchMarketPlaceCategories,
  fetchMarketPlaceStores,
  listUnverifiedMatches,
  setSelectedUnpublishedListings,
  setBulkUnverifiedMatchesModal,
  fetchUnverifiedMatchesSuccess,
} from "../../store/actions";
import { getUserInfo, toPascalCase } from "../../utils/common";
// constants
import { MATCHING_SYSTEM_SUPPLIERS, MATCHING_SYSTEM_FLAGS } from "../../constants";

// lodash
import { startCase, concat } from "lodash";
const makeURL = (url) => {
  url = String(url).replace(/\s+/g, "");
  return url.substr(0, 4) === "http" ? url : "https://" + url;
};

const UnpublishedListings = (props) => {
  const tableHeaders = [
    "Check All",
    "Supplier Link",
    "Marketplace Link",
    "Category",
    "BSR",
    "% Estimated Match",
    "Action",
  ];

  const fetchStoreWise = useRef(true);

  const [supplier, setSupplier] = useState({
    value: "",
    label: "Select Supplier",
  });

  const [salesRank, setSalesRank] = useState({ previous: null, current: "" });

  const [marketplace, setMarketPlace] = useState({
    value: "",
    label: "Select Marketplace",
  });

  const [category, setCategory] = useState({
    previous: null,
    value: "",
    label: "By Category",
  });

  const [fetchFlag, setFetchFlag] = useState(false);

  const [store, setStore] = useState({
    value: "",
    label: "Select Store",
  });

  const parsedLengths = {
    listing: props.UnpublishedListings?.listingsData?.length,
    selectedListing: props.UnpublishedListings.selectedListings?.length,
  };

  function listUnverifiedMatches (ids) {
    props.listUnverifiedMatches({
      marketplace: marketplace.value,
      email: getUserInfo()?.email,
      partner_id: store.key,
      source_platform: supplier.value,
      ids,
      category: category.value,
      salesRank: salesRank.current,
      perPage: 250,
    });
  }

  function fetchUnverifiedMatches (key) {
    props.fetchUnverifiedMatches({
      marketplace: marketplace.value,
      email: getUserInfo()?.email,
      partner_id: store?.key || key,
      source_platform: supplier.value,
      category: category.value,
      sales_rank: salesRank.current,
      perPage: 250,
    });
  }

  useEffect(() => {
    props.setPreloader(props.UnpublishedListings.loading);
  }, [props.UnpublishedListings.loading]);

  useEffect(() => {
    if (marketplace.value) {
      setSupplier({
        value: "",
        label: "Select Supplier",
      });
      setStore({
        value: "",
        label: "Select Store",
      });
      setCategory({
        value: "",
        label: "By Category",
      });
      fetchStoreWise.current = true;
      props.fetchMarketPlaceCategories({ marketplace: marketplace.value });
      props.fetchMarketPlaceStores({ marketplace: marketplace.value });
    }
  }, [marketplace.value]);

  useEffect(() => {
    if (marketplace.value && supplier.value && store.value) {
      fetchUnverifiedMatches();
    }
  }, [supplier.value]);

  useEffect(() => {
    if (marketplace.value && supplier.value && category.previous !== category.value) {
      setCategory({ ...category, previous: category.value });
      fetchUnverifiedMatches();
    }
  }, [category.value]);

  useEffect(() => {
    if (marketplace.value && supplier.value && salesRank.previous !== salesRank.current) {
      setSalesRank({ previous: salesRank.current, current: salesRank.current });
      fetchUnverifiedMatches();
    }
  }, [fetchFlag]);

  useEffect(() => {
    props.UnpublishedListings.success = "";
    props.UnpublishedListings.error = "";
    props.fetchUnverifiedMatchesSuccess(undefined);
  }, []);

  return (
    <React.Fragment>
      {props.UnpublishedListings.bulkUnverifiedMatchesModal && (
        <SubmitBulkUnverifiedMatchesModal
          data={{
            email: getUserInfo()?.email,
            partner_id: store.key,
            matches_type: marketplace.value + "_matches",
            source_platform: supplier.value,
            matches_request_type: "unverified",
            category: category.value,
            sales_rank: salesRank.current,
          }}
          isOpen={props.UnpublishedListings.bulkUnverifiedMatchesModal}
          toggleIsOpen={props.setBulkUnverifiedMatchesModal}
        />
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="PRE-MATCHED ITEMS"
            children={
              <div className="d-flex">
                {[store.value, supplier.value, marketplace.value].every((x) => x) &&
                  props.UnpublishedListings?.listingsData?.length !== 0 && (
                    <Button
                      type="reset"
                      color="info"
                      className="mr-3"
                      onClick={() => props.setBulkUnverifiedMatchesModal(true)}
                    >
                      Submit Matches Request
                    </Button>
                )}
                {props.UnpublishedListings.selectedListings?.length > 1 && (
                  <Button
                    type="reset"
                    color="warning"
                    className="mr-3"
                    onClick={() => listUnverifiedMatches(props.UnpublishedListings.selectedListings)}
                  >
                    Bulk List Items
                  </Button>
                )}
              </div>
            }
          />
          {props.UnpublishedListings?.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.UnpublishedListings?.success || ""}
              </Alert>
            </div>
          )}

          {props.UnpublishedListings?.error && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {props.UnpublishedListings?.error || ""}
              </Alert>
            </div>
          )}
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <div className="col-md-6">
                  <span className="block-header">
                    <div className="d-flex align-items-center">
                      <div className="number-circle mr-2">1</div>
                      <div>
                        <u>Marketplace &amp; Store</u>
                      </div>
                    </div>
                  </span>
                  <div className="row d-flex justify-content-between mb-2 ml-2 mt-2">
                    <FormGroup className="select2-container" style={{ minWidth: "180px" }}>
                      <Select
                        name="marketplace"
                        value={{
                          value: marketplace.value,
                          label: marketplace.label,
                        }}
                        onChange={(e) => {
                          setMarketPlace({ value: e.value, label: e.value === "" ? "Select Maketplace" : e.label });
                        }}
                        options={["amazon", "walmart"].map((store) => ({
                          value: store,
                          label: startCase(store),
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <FormGroup className="select2-container" style={{ minWidth: "180px" }}>
                      <Select
                        name="store"
                        value={{
                          value: store.value,
                          label: store.label,
                        }}
                        onChange={(e) => {
                          setStore({ value: e.value, key: e.key, label: e.value === "" ? "Select Store" : e.label });
                          if (fetchStoreWise.current) {
                            fetchStoreWise.current = false;
                            if ([e.value, supplier.value, marketplace.value].every((x) => x))
                              fetchUnverifiedMatches(e.key);
                          }
                        }}
                        options={props.UnpublishedListings?.marketPlaceStores?.map((store) => ({
                          label: startCase(store.name),
                          value: store.name,
                          key: store.id,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <div className="vl mr-5"></div>
                  </div>
                </div>
                <div className="col-6">
                  <span className="block-header">
                    <div className="d-flex align-items-center">
                      <div className="number-circle mr-2">2</div>
                      <div>
                        <u>Supplier</u>
                      </div>
                    </div>
                  </span>
                  <div className="row d-flex justify-content-between ml-1 mt-2">
                    <FormGroup className="select2-container" style={{ minWidth: "180px" }}>
                      <Select
                        name="supplier"
                        value={{
                          value: supplier.value,
                          label: supplier.label,
                        }}
                        onChange={(e) => {
                          setSupplier({ value: e.value, label: e.value === "" ? "Select Supplier" : e.label });
                        }}
                        options={
                          marketplace.value
                            ? Object.values(MATCHING_SYSTEM_SUPPLIERS)
                              .filter((x) => x !== marketplace.value)
                              .map((x) => ({ label: toPascalCase(x), value: x }))
                            : []
                        }
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {[store.value, supplier.value, marketplace.value].every((x) => x) && (
                <div className="row col-5 d-flex justify-content-between mt-1">
                  <FormGroup className="select2-container" style={{ minWidth: "180px" }}>
                    <Select
                      name="category"
                      value={{
                        value: category.value,
                        label: category.label,
                      }}
                      onChange={(e) => {
                        setCategory({
                          previous: category.value,
                          value: e.value,
                          label: e.value === "" ? "By Category" : e.label,
                        });
                      }}
                      options={(props.UnpublishedListings?.marketPlaceCategories || []).sort()?.map((category) => ({
                        value: category,
                        label: startCase(category),
                      }))}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  {[marketplace.value, supplier.value].some((x) => x === "amazon") && (
                    <FormGroup className="select2-container" style={{ minWidth: "180px" }}>
                      <Input
                        name="sales_rank"
                        type="number"
                        placeholder="Sales Rank"
                        min={1}
                        value={salesRank.current}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (!value) setSalesRank({ previous: null, current: value });
                          else if (parseInt(value) > 0 && parseInt(value) <= 1200000)
                            setSalesRank({ previous: salesRank.current, current: value });
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") setFetchFlag(!fetchFlag);
                        }}
                        onBlur={() => {
                          setFetchFlag(!fetchFlag);
                        }}
                      />
                    </FormGroup>
                  )}
                </div>
              )}
              {Array.isArray(props.UnpublishedListings?.listingsData) &&
                props.UnpublishedListings?.listingsData?.length > 0 &&
                [store.value, supplier.value, marketplace.value].every((x) => x) && (
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {/* table headers */}
                        {tableHeaders.map((header, index) =>
                          header === "Check All"
                            ? (
                            <th key={`table-header ${index}`}>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={parsedLengths.listing === parsedLengths.selectedListing}
                                  onChange={() => false}
                                />
                                <label
                                  className="custom-control-label"
                                  onClick={(e) => {
                                    props.setSelectedUnpublishedListings(
                                      parsedLengths.listing === parsedLengths.selectedListing
                                        ? []
                                        : props.UnpublishedListings?.listingsData?.map((item) => item?.id),
                                    );
                                  }}
                                ></label>
                              </div>
                            </th>
                              )
                            : (header === "BSR" &&
                              (marketplace.value === "amazon" || marketplace.value === "walmart")) ||
                            header !== "BSR"
                                ? (
                            <th key={`table-header ${index}`}>{header}</th>
                                  )
                                : null,
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {/* iterating data */}
                      {props.UnpublishedListings?.listingsData?.map((item, key) => (
                        <tr key={"_unpublished_listings_" + key} className="mt-3 mb-3">
                          <td>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={props.UnpublishedListings.selectedListings?.includes(item?.id)}
                                onChange={() => false}
                              />
                              <label
                                className="custom-control-label mt-2"
                                onClick={() => {
                                  const newArr = props.UnpublishedListings.selectedListings?.includes(item.id)
                                    ? props.UnpublishedListings.selectedListings?.filter((x) => item.id !== x)
                                    : concat(props.UnpublishedListings.selectedListings, item?.id);
                                  props.setSelectedUnpublishedListings(newArr);
                                }}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <a target="_blank" href={makeURL(item[supplier.value + "Source"]?.url)} rel="noreferrer">
                              {item[supplier.value + "Source"]?.itemIdentifier}
                            </a>
                          </td>
                          <td>
                            <a target="_blank" href={makeURL(item.destination?.url)} rel="noreferrer">
                              {item.destination.itemIdentifier}
                            </a>
                          </td>
                          <td>{item?.destination.category}</td>
                          {(marketplace.value === "amazon" || marketplace.value === "walmart") && (
                            <td>
                              {marketplace.value === "amazon"
                                ? item.destination?.salesRank
                                : item[supplier.value + "Source"]?.salesRank}
                            </td>
                          )}
                          <td>
                            {Math.round(
                              (MATCHING_SYSTEM_FLAGS.filter((k) => item[k]).length * 100) /
                                MATCHING_SYSTEM_FLAGS.length,
                            )}
                          </td>
                          <td>
                            <Button
                              type="submit"
                              color="success"
                              className="mr-3"
                              onClick={() => listUnverifiedMatches([item?.id])}
                            >
                              List Item
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              )}
              {props.UnpublishedListings?.listingsData && props.UnpublishedListings?.listingsData?.length === 0 && (
                <h2 className="text-center">No Records Found</h2>
              )}
              {props.UnpublishedListings?.listingsData && props.UnpublishedListings?.listingsData?.length !== 0 && (
                <>
                  <div className="d-flex flex-column">
                    <h6>{`Total Records: ${props.UnpublishedListings?.listingsData?.length}`}</h6>
                  </div>
                  <div className="text-center mt-2">
                    <Button type="reset" color="primary" className="mr-3" onClick={fetchUnverifiedMatches}>
                      Refresh Data
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { UnpublishedListings, MarketplaceAccounts } = state;
  return { UnpublishedListings, MarketplaceAccounts };
};

export default connect(mapStatetoProps, {
  fetchUnverifiedMatches,
  fetchMarketPlaceCategories,
  fetchMarketPlaceStores,
  setSelectedUnpublishedListings,
  listUnverifiedMatches,
  setBulkUnverifiedMatchesModal,
  setPreloader,
  fetchUnverifiedMatchesSuccess,
})(UnpublishedListings);
