import React, { useEffect, useState } from "react";
import { colors, getDateParamObj, options } from "../common";
import $ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Progress, Spinner } from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { RenderIf } from "../../../utils/common";
import DataProcessingMsg from "../NewDashboard/components/DataProcessingMsg";
import { fetchOrderGraph } from "../../../store/actions";
import { ORDER_STATUS_MAPPING } from "../../../constants";
import moment from "moment";
import DateRange from "../../../components/Common/DateRange";

export default function TotalOrders ({ storeId, utcOffset }) {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([moment().subtract(6, "days").startOf("days"), moment().startOf("days")]);
  const { orderGraph, totalOrdersLoader } = useSelector((state) => state.Graph);
  const stats = orderGraph?.data || [];
  const chartData = $.orderBy(
    $.map(stats, (stat) => ({ x: stat.date, y: stat.orders })),
    "x",
  );
  const statusMapper = {
    ...ORDER_STATUS_MAPPING,
    delivered_with_tba: "Delivered with TBA",
    shipped_with_tba: "Shipped with TBA",
  };

  const totalOrdersCount = orderGraph?.aggregated_status?.total_orders;

  useEffect(() => {
    !totalOrdersLoader &&
      storeId &&
      dispatch(fetchOrderGraph(getDateParamObj({ marketplace_id: storeId, dates, newDashboard: true, utcOffset })));
  }, [storeId, dates, utcOffset]);

  return (
    <>
      <Breadcrumb
        isCapitalize={true}
        title="Orders"
        responsive
        children={
          <RenderIf isTrue={storeId}>
            <div className="d-flex flex-row float-sm-right">
              <DateRange dates={dates} setDates={setDates} loader={totalOrdersLoader} timePeriods={[7, 30, 365]} />
            </div>
          </RenderIf>
        }
      />
      <div className={`row ${totalOrdersLoader ? "justify-content-center" : ""}`}>
        <RenderIf
          isTrue={!totalOrdersLoader}
          fallback={<Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />}
        >
          <DataProcessingMsg data={stats} store={storeId}>
            <div className="col-xl-2 col-md-3 col-sm-4" style={{ borderRight: "1px solid #A6B0CF33" }}>
              <div className="d-flex flex-direction-column align-items-center">
                <div className="inventory-color font-size-18">{totalOrdersCount || 0}</div>
                <div className="font-size-13">Open Orders</div>
              </div>
              {$.entries($.omit(orderGraph?.aggregated_status, "total_orders")).map(([type, count], idx) => {
                if (!count) return null;
                return (
                  <div key={"__" + type + idx} className="mt-4">
                    {$.get(statusMapper, type, $.startCase(type))}: <span className="inventory-color">{count}</span>
                    <div className="mt-2">
                      <Progress value={Math.ceil((count / totalOrdersCount) * 100)} color={colors[idx]} size="sm" />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-xl-10 col-md-9 col-sm-8">
              <ReactApexChart
                options={options}
                series={[{ name: "Orders", data: chartData }]}
                type="area"
                height="350"
              />
            </div>
          </DataProcessingMsg>
        </RenderIf>
      </div>
    </>
  );
}
