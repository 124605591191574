import React from "react";

const VerticalLine = ({ className = "", style = {} }) => (
  <div
    className={"vertical-line " + className}
    style={{ marginLeft: "0px", marginTop: "12px", height: "30px", borderRightWidth: "3px", ...style }}
  ></div>
);

export default VerticalLine;
