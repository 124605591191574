import { FETCH_FREE_CREDITS, FETCH_FREE_CREDITS_SUCCESS, API_ERROR } from "./actionTypes";

const initialState = { error: "", success: "", creditLoader: false };
const resetState = (state, creditLoader = true) => ({ ...state, error: "", success: "", creditLoader });

const FreeCreditsHistory = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FREE_CREDITS:
      state = { ...state, creditLoader: true };
      break;
    case FETCH_FREE_CREDITS_SUCCESS:
      state = { ...resetState(state, false), creditsHistory: action.payload };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, creditLoader: false };
      break;
    default:
      break;
  }
  return state;
};
export default FreeCreditsHistory;
