import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  bannerNotification,
  createNotificationEmails,
  deleteNotificationEmail,
  fetchNotifications,
  setEmailType,
} from "../../api/NotificationEmails";
import {
  createNotificationEmailsSuccess,
  deleteNotificationEmailSuccess,
  apiError,
  fetchNotificationsSettingsSuccess,
  setEmailTypeEnabledSuccess,
} from "./actions";
import {
  BANNER_NOTIFICATION_UPDATE,
  CREATE_NOTIFICATION_EMAILS,
  DELETE_NOTIFICATION_EMAIL,
  FETCH_NOTIFICATIONS_SETTINGS,
  SET_EMAIL_TYPE_ENABLED,
} from "./actionTypes";

const NotificationEmailsState = (state) => state.NotificationEmails;
const Settings = (state) => state.Settings;

function * createNotificationEmailsSaga ({ payload }) {
  try {
    const res = yield call(createNotificationEmails, payload);
    if (res.success) {
      const notificationEmails = yield select(NotificationEmailsState);
      if (notificationEmails?.emails) {
        res.emails.forEach((item) => notificationEmails?.emails.push(item));
      }
    }
    yield put(createNotificationEmailsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * bannerNotificationSaga ({ payload }) {
  try {
    const res = yield call(bannerNotification, payload);
    const { account } = yield select(Settings);
    if (res.success && account) {
      if (account) {
        account.data.is_enable_banners.stamps_banner = false;
      }
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * deleteNotificationEmailSaga ({ payload: id }) {
  try {
    const res = yield call(deleteNotificationEmail, id);
    yield put(deleteNotificationEmailSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchNotificationsSettingsSaga () {
  try {
    const res = yield call(fetchNotifications);
    yield put(fetchNotificationsSettingsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * setEmailTypeSaga ({ payload }) {
  try {
    const res = yield call(setEmailType, payload);
    yield put(setEmailTypeEnabledSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

export function * watchBannerNotification () {
  yield takeEvery(BANNER_NOTIFICATION_UPDATE, bannerNotificationSaga);
}

export function * watchSetEmailTypeEnabled () {
  yield takeEvery(SET_EMAIL_TYPE_ENABLED, setEmailTypeSaga);
}

export function * watchFetchNotificationsSettings () {
  yield takeEvery(FETCH_NOTIFICATIONS_SETTINGS, fetchNotificationsSettingsSaga);
}
export function * watchCreateNotificationEmailsSaga () {
  yield takeEvery(CREATE_NOTIFICATION_EMAILS, createNotificationEmailsSaga);
}

export function * watchDeleteNotificationEmail () {
  yield takeEvery(DELETE_NOTIFICATION_EMAIL, deleteNotificationEmailSaga);
}

function * NotificationEmails () {
  yield all([
    fork(watchBannerNotification),
    fork(watchSetEmailTypeEnabled),
    fork(watchFetchNotificationsSettings),
    fork(watchCreateNotificationEmailsSaga),
    fork(watchDeleteNotificationEmail),
  ]);
}

export default NotificationEmails;
