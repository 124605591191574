import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { amountText, RenderIf } from "../../../utils/common";
import { fetchPaginatedRecords, getDateParamObj } from "../common";
import * as $ from "lodash";
import ReactTable from "../../../components/Common/Table";
import getBrandImageSrc from "../../../utils/brandImages";
import StatusBadge from "../../../components/Common/StatusBadge";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { Spinner } from "reactstrap";
import CustomPagination from "../../../components/Common/CustomPagination";
import { MARKET_PLACES } from "../../../constants";
import moment from "moment";
import DateRange from "../../../components/Common/DateRange";

const BestSellerCard = ({ storeId, platform, utcOffset, isStoreAdded }) => {
  const dispatch = useDispatch();
  const { bestSellersLoading, bestSellers } = useSelector((state) => state.Graph);
  const [dates, setDates] = useState([moment().subtract(6, "days").startOf("days"), moment().startOf("days")]);
  const [filter, setFilter] = useState({ page: 1, per_page: 10 });

  const sellersData = fetchPaginatedRecords(bestSellers?.data, filter);

  const handleStock = (data) => {
    if (["ACTIVE", "PUBLISHED"].includes(data.status)) return data.stock ? `${data.stock} In Stock` : "Out of Stock";
    else return "No Longer Exists";
  };

  const tableHeaders = [
    {
      title: "Item",
      Content: ({ data }) => (
        <div style={{ whiteSpace: "nowrap", width: "310px", overflow: "hidden", textOverflow: "ellipsis" }}>
          <span className="mx-1 font-size-12" style={{ cursor: "pointer" }} title={data?.name}>
            {data?.name}
          </span>
          <div className="mx-1 font-size-12">
            {platform === MARKET_PLACES.walmart ? "Item ID" : "ASIN"}:&nbsp;
            <span className="mx-1 inventory-color">{data?.item_identifier ?? "N/A"}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Marketplace",
      isHide: true,
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <span className="mx-1 font-size-12">
            {<img src={getBrandImageSrc(data?.marketplaceLogo)} alt="store logo" />}
          </span>
        </div>
      ),
    },
    {
      title: "Availability",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <span className="mx-1 font-size-12">
            <RenderIf isTrue={!$.isNil(data?.stock)} fallback={"N/A"}>
              <StatusBadge
                style={{ whiteSpace: "pre-line" }}
                className="p-2 px-3"
                status={handleStock(data)}
                colorsMapping={{
                  [`${data?.stock} In Stock`]: data?.stock > 5 ? "success" : "warning",
                  "Out of Stock": "danger",
                  "No Longer Exists": "orange",
                }}
              />
            </RenderIf>
          </span>
        </div>
      ),
    },
    {
      title: "SKU",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <Link
            to={`listings_new?sku=${data?.sku}&account_id=${storeId}`}
            className="mx-1 font-size-12 text-primary cursor-pointer"
          >
            {data?.sku}
          </Link>
        </div>
      ),
    },
    {
      title: "Unit Sold",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <span className="mx-1 font-size-12">{data?.units_sold || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Total Sales",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <span className="mx-1 font-size-12">{amountText(data?.sales)}</span>
        </div>
      ),
    },
  ];

  const cols = $.keyBy(tableHeaders, "title");

  useEffect(() => {
    if (bestSellersLoading || !storeId) return;

    setFilter({ ...filter, page: 1 });
    dispatch(actions.fetchBestSellersData(getDateParamObj({ marketplace_id: storeId, dates, utcOffset })));
  }, [dates, storeId, utcOffset]);

  return (
    <>
      <Breadcrumb
        isCapitalize={true}
        title="Best Sellers"
        responsive
        children={
          <RenderIf isTrue={storeId}>
            <div className="d-flex flex-row float-sm-right flex-wrap">
              <div className="ml-2">
                <DateRange dates={dates} setDates={setDates} loader={bestSellersLoading} timePeriods={[7, 30, 365]} />
              </div>
            </div>
          </RenderIf>
        }
      />
      <ReactTable
        className={`table-select ${isStoreAdded ? "" : "blur-effect"}`}
        tableHeader={
          <>
            {$.values(cols)
              .filter((x) => !x.isHide)
              .map((header, index) => (
                <th key={`table-header-${index}`} className={[0].includes(index) ? null : "text-center"}>
                  {header.title}
                </th>
              ))}
          </>
        }
        style={{ width: "100%", overflow: "auto" }}
        tableBody={
          <RenderIf
            isTrue={!$.isEmpty(bestSellers?.data) && !bestSellersLoading}
            fallback={
              <RenderIf
                isTrue={bestSellersLoading}
                fallback={
                  storeId ? (
                    <tr>
                      <td colSpan="100%">
                        <h2 className="text-center">No Records Found</h2>
                      </td>
                    </tr>
                  ) : null
                }
              >
                <tr>
                  <td colSpan="100%" className="text-center">
                    <Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />
                  </td>
                </tr>
              </RenderIf>
            }
          >
            {$.map(sellersData, (data, key) => (
              <tr key={`table-row-${key}`}>
                {$.values(cols)
                  .filter((x) => !x.isHide)
                  .map((header, colKey) => (
                    <td key={`table-col-${colKey}`}>
                      <header.Content data={data} index={key} />
                    </td>
                  ))}
              </tr>
            ))}
          </RenderIf>
        }
      />
      <RenderIf isTrue={!$.isEmpty(bestSellers?.data)}>
        <div className={`${bestSellersLoading ? "d-none" : ""}`}>
          <CustomPagination
            pageOptions={[10, 50, 100]}
            total={bestSellers?.count}
            page={filter?.page}
            perPage={filter?.per_page}
            tabsFilter={filter}
            setTabFilter={setFilter}
            inAgencyComponent={true}
          />
        </div>
      </RenderIf>
    </>
  );
};

export default BestSellerCard;
