import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { isBoolean, isInteger, isNaN, isArray, values, keys } from "lodash";
import queryString from "query-string";
import moment from "moment-timezone";

import { LISTING_SEARCH_TYPES, CUSTOM_FILTERS, FILTER_TAGS_MAPPING, DATE_FORMATS } from "../../../constants";
import { toPascalCase } from "../../../utils/common";
// Actions
import { setCustomFiltersAction, setListingSuppliersCollapse, setSelectedLiveListing } from "../../../store/actions";

const getValue = (filter) => {
  if (isBoolean(filter.value)) return String(filter.label) || String(filter.value);
  if (isInteger(filter.value) || isArray(filter.value)) return String(filter.label);
  if (!isNaN(filter.value)) return String(filter.value);
  if (Date.parse(filter.value)) return moment(filter.value).format(DATE_FORMATS.DATE);
  return filter.value;
};

const FilterTags = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, setFilters } = props;

  const queryParams = queryString.parse(useLocation().search);
  return (
    <div className="p-2 d-flex">
      {filters.filters_hash.filters.map((filter, key) => (
        <div
          key={`__filters_tag__${key}`}
          className={`filter-tag p-2 d-flex align-items-center position-relative ${key > 0 ? "ml-2" : ""}`}
        >
          <span className="px-1">
            {toPascalCase(filter.apply_label ? filter.label : FILTER_TAGS_MAPPING[filter.field])}
          </span>
          <span className="px-1 text-white">
            <b>{decodeURIComponent(getValue(filter))}</b>
          </span>
          <i
            className="bx bx-x cursor-pointer filter-remove position-absolute"
            onClick={() => {
              if (keys(queryParams).length) history.push("/listings");
              const updatedFilters = {
                filters_hash: {
                  ...filters.filters_hash,
                  filters: filters.filters_hash.filters.filter((f) =>
                    f.apply_label ? f.label !== filter.label : f.field !== filter.field,
                  ),
                },
              };
              setFilters({ ...filters, ...updatedFilters });
              if (!values(LISTING_SEARCH_TYPES).includes(filter.field)) {
                dispatch(
                  setCustomFiltersAction({ [filter.key || filter.field]: CUSTOM_FILTERS[filter.key || filter.field] }),
                );
              }
              dispatch(setListingSuppliersCollapse(false));
              dispatch(setSelectedLiveListing({}));
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default FilterTags;
