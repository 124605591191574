import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Card,
  TabContent,
  TabPane,
  Progress,
  Row,
  Col,
  Alert,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { onboardingStepsIds } from "../../../constants";
import UploadFile from "../../../assets/svg/upload.svg";
import Steps1 from "../../../assets/svg/step-1.webp";
import Steps2 from "../../../assets/svg/step-2.webp";
import Steps3 from "../../../assets/svg/step-3.webp";
import Steps4 from "../../../assets/svg/step-4.webp";
import Steps5 from "../../../assets/svg/step-5.webp";
import Steps6 from "../../../assets/svg/step-6.webp";
import Steps7 from "../../../assets/svg/step-7.webp";
import { StoreAddedMsg } from "./StoreAddedMsg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import {
  setPreloader,
  addCSVListings,
  checkStoreName,
  resetStoreName,
  resetMarketplaceErr,
  createMarketplaceAccount,
  setIsAmz,
} from "../../../store/actions";
import { first, last } from "lodash";
import { getUserInfo } from "../../../utils/common";
const FacebooksetUp = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { loading, marketplaceData, error, onboardAccountSuccess, storeNameData } = useSelector(
    (state) => state.MarketPlaceAccount,
  );
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabProgress, setactiveTabProgress] = useState(1);
  const [progressValue, setprogressValue] = useState(25);
  const [selectedFile, setselectedFile] = useState([]);
  const [shopName, setShopName] = useState("");
  const [formData, setFormData] = useState({});
  const [fileData, setFileData] = useState({ fileName: "", fileSize: "" });
  const { loading: onboardFlagLoading } = useSelector((state) => state.Settings);
  const fileSize = selectedFile?.map((f) => f.formattedSize);

  function toggleTab (tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
      }
    }
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
    setFileData({ fileSize: file[0].size, fileName: file[0].name });
  }

  const uploadFileListings = () => {
    dispatch(
      addCSVListings({
        data: {
          file_name: selectedFile[0],
          file_type: "facebook_bulk_listing",
          va_id: getUserInfo()?.id,
        },
        id: last?.(marketplaceData?.data)?.id,
      }),
    );
    setselectedFile([]);
  };

  function toggleTabProgress (tab) {
    if (tab >= 1 && tab <= 3) {
      setactiveTabProgress(tab);

      if (tab === 1) {
        setprogressValue(33);
      } else if (tab === 2) {
        setprogressValue(66);
      } else if (tab === 3) {
        setprogressValue(100);
      }
    }
  }

  useEffect(() => {
    setPreloader(loading || onboardFlagLoading);
  }, [loading, onboardFlagLoading]);
  const items = [
    {
      src: Steps1,
      key: 1,
      caption: "Step 1",
    },
    {
      src: Steps2,
      key: 2,
      caption: "Step 2",
    },
    {
      src: Steps3,
      key: 3,
      caption: "Step 3",
    },
    {
      src: Steps4,
      key: 4,
      caption: "Step 4",
    },
    {
      src: Steps5,
      key: 5,
      caption: "Step 5",
    },
    {
      src: Steps6,
      key: 6,
      caption: "Step 6",
    },
    {
      src: Steps7,
      key: 7,
      caption: "Step 7",
    },
  ];

  useEffect(() => {
    setPreloader(loading || onboardFlagLoading);
  }, [loading, onboardFlagLoading]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    if (error.includes("This shop name already exists for facebook marketplace")) {
      toggleTabProgress(1);
      toggleTab(1);
      formRef.current.reset();
    } else if (storeNameData?.message?.includes("This is a valid store_name") && activeTabProgress === 1) {
      toggleTabProgress(2);
      toggleTab(2);
      formRef.current.reset();
    } else if (onboardAccountSuccess.includes("Marketplace Account Added Successfully")) {
      if (selectedFile.length) {
        uploadFileListings();
      }
      formRef.current.reset();
    } else if (storeNameData?.uniqId && activeTabProgress === 1) {
      toggleTabProgress(2);
      toggleTab(2);
      formRef.current.reset();
    }
  }, [error, onboardAccountSuccess, storeNameData]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    dispatch(resetMarketplaceErr());
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const slides = items.map((item) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
        <img src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });
  return (
    <React.Fragment>
      <Card>
        <div className="p-4">
          <div id="progrss-wizard" className="twitter-bs-wizard">
            <div className="px-5 py-2">
              <Progress color="primary" value={progressValue} />
            </div>
            <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content">
              {error && (
                <div className="auto-hide">
                  <Alert color="danger">
                    <i className="bx bx-info-circle pr-2"></i>
                    {error}
                  </Alert>
                </div>
              )}
              <TabPane key="1" tabId={1}>
                <h4 className="card-title mb-3 text-center">Integrate Ecom Circles with Facebook Shop/Marketplace</h4>
                <div className="p-2">
                  <div>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(_, v) => {
                        const data = { ...formData, ...v };
                        setFormData({ ...data });
                        dispatch(checkStoreName(data.name));
                      }}
                      ref={(c) => (formRef.current = c)}
                    >
                      <div className="form-group">
                        <AvField
                          name="name"
                          label="Shop Name"
                          value={shopName}
                          onChange={(e) => setShopName(e.target.value)}
                          className="form-control"
                          placeholder="Shop Name"
                          type="text"
                        />
                      </div>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          onClick={() => {
                            props.setActiveStep(onboardingStepsIds.welcom);
                            setShopName("");
                            setFormData({ ...formData, name: "" });
                            dispatch(resetMarketplaceErr());
                            dispatch(resetStoreName());
                          }}
                          className="btn btn-primary w-md btn-sm waves-effect waves-light"
                        >
                          Previous
                        </li>
                        <li className={activeTabProgress === 3 ? "next disabled" : "next"}>
                          <button type="submit" className="btn btn-primary w-md btn-sm waves-effect waves-light">
                            Next
                          </button>
                        </li>
                      </ul>
                    </AvForm>
                  </div>
                </div>
              </TabPane>
              {storeNameData?.uniqId ? (
                <TabPane key="2" tabId={2}>
                  <h4 className="card-title mb-3 text-center">Integrate Ecom Circles with Facebook Shop/Marketplace</h4>
                  <div>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        const data = { ...formData, ...v };
                        setFormData({ ...data });
                        toggleTabProgress(3);
                        toggleTab(3);
                        formRef.current.reset();
                      }}
                      ref={(c) => (formRef.current = c)}
                    >
                      <div className="position-relative input-copy">
                        <AvField
                          name="unique_identifier"
                          label="URL:"
                          value={`https://app.ecomcircles.com/api/${storeNameData.uniqId}`}
                          readOnly={true}
                          placeholder={`https://app.ecomcircles.com/api/${storeNameData?.uniqId}`}
                          className="form-control"
                          type="text"
                        />
                        <CopyToClipBoard
                          className="position-absolute copy-icon color-purple bx-sm"
                          text={`https://app.ecomcircles.com/api/${storeNameData?.uniqId}`}
                        />
                      </div>
                      <div className="upload-file mb-4">
                        <Row>
                          <Col md={6}>
                            <div className="position-relative image-detail">
                              <div className="d-flex flex-column details">
                                <span>{fileData.fileName}</span>
                                {fileData.fileName && (
                                  <i
                                    className="bx bx-trash-alt position-absolute trash-icon"
                                    title="Trash"
                                    onClick={() => {
                                      setFileData({ fileName: "", fileSize: "" });
                                      setselectedFile([]);
                                    }}
                                  />
                                )}
                                {fileSize && <span>{fileSize}</span>}
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="d-flex justify-content-end grid-column-gap-10">
                              <div>
                                <a href={"/template_file_for_facebook_bulk_listing.csv"} download>
                                  Download Sample File
                                </a>
                              </div>
                              <div>
                                <input
                                  type="file"
                                  accept=".csv"
                                  onChange={(e) => {
                                    handleAcceptedFile([first(e.target.files)]);
                                  }}
                                />
                                <div className="d-flex color-purple justify-content-end">
                                  <img src={UploadFile} alt="no-upload" className="pr-2" /> Upload File
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="carousel">
                        <h4 className="follow-steps mb-3">Steps to follow:</h4>
                        <div className="px-3">
                          <h6 className="mb-3">Step: 1 of 7 </h6>
                          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                          </Carousel>
                        </div>
                      </div>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          onClick={() => {
                            toggleTabProgress(1);
                            toggleTab(1);
                          }}
                          className="waves-effect w-md waves-light btn btn-primary"
                        >
                          Previous
                        </li>
                        <li className={activeTabProgress === 3 ? "next disabled" : "next"}>
                          <button
                            type="submit"
                            className="waves-effect w-md waves-light btn btn-primary"
                            onClick={() =>
                              dispatch(
                                createMarketplaceAccount({
                                  name: shopName,
                                  access_id: shopName,
                                  access_secret: shopName,
                                  file_name: selectedFile[0],
                                  unique_url: storeNameData?.uniqId,
                                  short_id: storeNameData?.shortId,
                                  marketplace: "facebook",
                                }),
                              )
                            }
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </AvForm>
                  </div>
                </TabPane>
              ) : null}
              <TabPane key="3" tabId={3}>
                <i
                  className="bx bx-x bx-sm cross-icon cursor-pointer"
                  onClick={() => {
                    props.setActiveStep(onboardingStepsIds.payment);
                    toggleTabProgress(1);
                    toggleTab(1);
                    dispatch(resetMarketplaceErr());
                    dispatch(resetStoreName());
                    if (!props.onBoarding) {
                      props.setIsOpen(false);
                      setIsAmz(false);
                    }
                  }}
                ></i>
                <StoreAddedMsg marketplace="facebook">
                  <div className="d-flex mt-4 justify-content-center">
                    <button
                      onClick={() => {
                        props.setActiveStep(onboardingStepsIds.welcom);
                        toggleTabProgress(1);
                        toggleTab(1);
                        setFileData({ fileName: "", fileSize: "" });
                        dispatch(resetStoreName());
                      }}
                      className="waves-effect w-md waves-light btn btn-primary"
                    >
                      Connect Another Marketplace
                    </button>
                    <div className="or-seperation">OR</div>
                    <button
                      onClick={() => dispatch(setIsAmz(false))}
                      type="submit"
                      className={`btn btn-primary btn-sm waves-effect waves-light ${
                        activeTabProgress === 3 ? "next disabled" : "next"
                      }`}
                    >
                      Continue with Setup
                    </button>
                  </div>
                </StoreAddedMsg>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default FacebooksetUp;

FacebooksetUp.propTypes = {
  FacebooksetUp: PropTypes.func,
};
