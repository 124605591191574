import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";

// actions
import { refreshSourceOrderDetail, createOrderLog, createOrderNote, updateSourceOrder } from "../../../store/actions";
import { parseErr, RenderIf } from "../../../utils/common";

const OrderLog = (props) => {
  const toggleModal = () => props.toggleIsOpen(!props.isOpen);

  const validate = (values) => {
    const errors = {};
    !values.note && (errors.note = "Field is Required");
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      note: props.type === "order_note" ? props.sourceOrder?.note || "" : "",
      source_order_id: props.sourceOrder?.id || 0,
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.sourceOrderId && (values.source_order_id = props.sourceOrderId);
      if (props.type === "order_log") {
        props.createOrderLog({ data: { ...values } });
      } else props.createOrderNote({ data: { ...values }, loading: true });

      formik.resetForm();
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">
            {props.type === "order_log" ? "Add Log" : props.sourceOrder?.note ? "Update Note" : "Add Note"}
          </h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <RenderIf isTrue={props.OrderLog?.error || props.error}>
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {parseErr(props.OrderLog?.error || props.error)}
            </Alert>
          </RenderIf>
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12 mb-2">
                <Label>{props.type === "order_log" ? "Order Log" : "Note"}</Label>
                <textarea
                  className="form-control"
                  name="note"
                  maxLength="255"
                  value={formik.values.note}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <RenderIf isTrue={formik.touched.note && formik.errors.note}>
                  <span className="text-danger mt-1">{formik.errors.note}</span>
                </RenderIf>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { OrderLog } = state;
  return { OrderLog };
};

export default connect(mapStateToProps, {
  refreshSourceOrderDetail,
  createOrderLog,
  createOrderNote,
  updateSourceOrder,
})(OrderLog);
