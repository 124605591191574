import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup } from "reactstrap";
import moment from "moment-timezone";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toPascalCase } from "../../utils/common";
import { MARKET_PLACES } from "../../constants/index";

// Pages Components
import SalesAndExpensesChart from "./components/SalesAndExpensesChart";
import ProfitChart from "./components/ProfitChart";
import TotalOrdersChart from "./components/TotalOrdersChart";
import ReturnsChart from "./components/ReturnsChart";
import UsedCreditsChart from "./components/UsedCreditsChart";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { setPreloader, fetchAllTenantGraphs } from "../../store/actions";

const AdminDashboard = () => {
  const [marketPlace, setMarketPlace] = useState({ value: "", label: "All Stores" });
  const [storeName, setStoreName] = useState("");

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.Graph);

  useEffect(() => {
    dispatch(
      fetchAllTenantGraphs({
        start_date: moment().subtract(6, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        ...(storeName && { store_name: storeName }),
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Admin Dashboard"
            children={
              <FormGroup className="select2-container ml-2" style={{ width: "200px" }}>
                <Select
                  name="marketplace"
                  value={{ value: marketPlace.value, label: marketPlace.label }}
                  onChange={(e) => {
                    setStoreName(e.value);
                    setMarketPlace({ value: e.value, label: e.label });
                  }}
                  options={Object.keys(MARKET_PLACES)
                    .splice(0, 2)
                    .reduce(
                      (init, acc, i) => {
                        init.push({ label: toPascalCase(acc), value: MARKET_PLACES[acc], key: i });
                        return init;
                      },
                      [{ value: "", label: "All Stores" }],
                    )}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            }
          />

          <Row>
            <Col md="6">
              <SalesAndExpensesChart marketplace={storeName} type="global" />
              <TotalOrdersChart marketplace={storeName} type="global" />
              <UsedCreditsChart marketplace={storeName} type="global" />
            </Col>
            <Col md="6">
              <ProfitChart marketplace={storeName} type="global" />
              <ReturnsChart marketplace={storeName} type="global" />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminDashboard;
