import * as types from "./actionTypes";
const {
  UPDATE_SOURCE_ITEM_SUCCESS,
  UPDATE_SOURCE_ITEM,
  API_ERROR,
  GET_FIFTY_FIFTY_MATCHES_COUNT,
  GET_FIFTY_FIFTY_MATCHES_COUNT_SUCCESS,
} = types;

const initialState = { error: "", success: "", loading: false, fiftyFiftyCount: 0 };

const SourceItems = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SOURCE_ITEM_SUCCESS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_SOURCE_ITEM:
      state = {
        ...state,
        success: "Item Updated",
        error: "",
        loading: false,
      };
      break;
    case types.BULK_UPDATE_SOURCE_ITEM:
      state = { ...state, error: "", loading: true };
      break;
    case types.BULK_UPDATE_SOURCE_ITEM_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case GET_FIFTY_FIFTY_MATCHES_COUNT_SUCCESS:
      state = {
        ...state,
        loading: true,
        fiftyFiftyCount: action.payload,
      };
      break;
    case GET_FIFTY_FIFTY_MATCHES_COUNT:
      state = {
        ...state,
        error: "",
        loading: false,
      };
      break;
    case types.RESET_ITEMS_STATE:
      state = { ...state, error: "", loading: false, success: "" };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SourceItems;
