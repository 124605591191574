import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getS3Email } from "../../api";

const EmailView = (props) => {
  const { key } = useParams();
  const [email, setEmail] = useState("");

  useEffect(() => {
    (async () => {
      await getS3Email({ key }).then((email) => {
        document.body.style.backgroundColor = "transparent";
        setEmail(email);
      });
    })();
  }, [key]);

  return <div className="email-view" dangerouslySetInnerHTML={{ __html: email }} />;
};

export default EmailView;
