import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Alert, Label } from "reactstrap";
import { useFormik } from "formik";
import Modal from "../../../components/Common/Modal";
import { startCase, values, pick } from "lodash";
import { ORDER_TRACKING_STATUSES } from "../../../constants/index";
import SimpleSelect from "../../../components/Common/SimpleSelect";

// actions
import { updateWareHouseItem } from "../../../store/actions";

const TrackingStatusUpdate = (props) => {
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(props.toggleIsOpen(!props.isOpen));
  const { error } = useSelector((state) => state.WareHouses);

  const validate = (values) => {
    const errors = {};
    !values.tracking_status && (errors.tracking_status = "Field is Required");
  };
  const formik = useFormik({
    initialValues: {
      tracking_status: props.selectedItem.tracking_status,
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (values.tracking_status !== props.selectedItem.tracking_status)
        dispatch(updateWareHouseItem({ data: { tracking_status: values.tracking_status }, id: props.selectedItem.id }));
      else toggleModal();
    },
  });

  return (
    <React.Fragment>
      <Modal size="sm" isOpen={props.isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Tracking Status</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12">
                <Label>Status</Label>
                <div className="form-group">
                  <SimpleSelect
                    value={{ label: startCase(formik.values.tracking_status), value: formik.values.tracking_status }}
                    onChange={(e) => formik.setFieldValue("tracking_status", e.value)}
                    placeholder="Select Status"
                    onBlur={formik.handleBlur}
                    name="tracking_status"
                    options={values(pick(ORDER_TRACKING_STATUSES, ["in_transit", "delivered"])).map((val) => ({
                      label: startCase(val),
                      value: val,
                    }))}
                    formStyle={{ minWidth: "250px" }}
                  />
                </div>
                {formik.touched.tracking_status && formik.errors.tracking_status
                  ? (
                  <span className="text-danger">{formik.errors.tracking_status}</span>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default TrackingStatusUpdate;
