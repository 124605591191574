import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Card, CardBody, CardHeader, Container, NavLink, NavItem, Nav, Badge } from "reactstrap";
import Select from "react-select";
import moment from "moment-timezone";
import classnames from "classnames";
import { keyBy, merge, pick, startCase } from "lodash";

import { downloadFileClientSide, getWarehouseName, isAdmin, RenderIf } from "../../utils/common";
import CustomPagination from "../../components/Common/CustomPagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CheckBox from "../../components/Common/CheckBox";
import PillBtn from "../../components/Common/PillBtn";
import { DATE_FORMATS, LABEL_SERVICES } from "../../constants";
import { GenerateScanformModal } from "./GenerateScanformModal";
import { carrierOptions, isEasypost, serivceOptions } from "./common";

import * as actions from "../../store/actions";

const ListScanforms = () => {
  const dispatch = useDispatch();
  const {
    loading,
    scanforms,
    wareHouses,
    success: warehouseSuccess,
    error: warehouseError,
    scanformShipments,
    scanformModalIsOpen,
    filePath,
  } = useSelector((state) => state.WareHouses);

  const initialFilter = { page: 1, per_page: 20, carrier: "", label_service: "" };
  const [filters, setFilters] = useState(initialFilter);
  const isAdminUser = isAdmin();

  // Tab manipulation
  const tabs = [
    { title: "Scanforms", id: 1 },
    { title: "Shipments", id: 2 },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [search, setSearch] = useState("");
  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilters(initialFilter);
      setSearch("");
    }
  };

  const isScanformTab = (tab) => tab === 1;
  const getText = (text) => startCase(text) || "N/A";

  useEffect(() => {
    dispatch(actions.setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    filePath && downloadFileClientSide(filePath);
    dispatch(actions.emptyDownloadFileURL());
  }, [filePath]);

  useEffect(() => {
    isScanformTab(activeTab)
      ? dispatch(actions.fetchShippingScanforms(filters))
      : dispatch(actions.fetchScanformShipments(filters));
    isAdminUser && dispatch(actions.fetchAllWarehouses());
  }, [activeTab, filters]);

  const grpWarehouses = keyBy(wareHouses?.data, "id");

  const serviceLabel = (service) =>
    ({
      [LABEL_SERVICES.easypost]: "EasyPost",
      [LABEL_SERVICES.ecom_circles_shipping]: "Stamps",
    }[service]);

  const toggleGenerateScanformModal = () => dispatch(actions.setGenerateScanformModal(!scanformModalIsOpen));

  return (
    <>
      <div className="page-content">
        <RenderIf isTrue={warehouseSuccess}>
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {warehouseSuccess}
            </Alert>
          </div>
        </RenderIf>
        <RenderIf isTrue={warehouseError && !scanformModalIsOpen}>
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {warehouseError}
            </Alert>
          </div>
        </RenderIf>
        <RenderIf isTrue={scanformModalIsOpen}>
          <GenerateScanformModal toggleModal={toggleGenerateScanformModal} />
        </RenderIf>
        <Container fluid>
          <Breadcrumbs title="Scanforms for generated labels" />
          <Card>
            <RenderIf isTrue={!isAdminUser}>
              <CardHeader>
                <div className="ml-3 mr-3">
                  <RenderIf
                    isTrue={isScanformTab(activeTab)}
                    fallback={
                      <div className="row d-flex align-items-center justify-content-between">
                        <div>
                          <form
                            className="app-search d-none d-lg-block"
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (!search.trim()) return;
                              setFilters({ ...pick(filters, "shipment_filter", "per_page"), search: search.trim(), page: 1 });
                            }}
                          >
                            <div className="position-relative d-block ">
                              <input
                                type="text"
                                className="form-control see-more-text"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => {
                                  if (!e.target.value) {
                                    setFilters({ search: "", page: 1 });
                                    setSearch("");
                                  } else {
                                    setSearch(e.target.value);
                                  }
                                }}
                              />
                              <span className="bx bx-search-alt"></span>
                              <i className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"></i>
                            </div>
                          </form>
                        </div>
                        <CheckBox
                          name="shipment_filter"
                          state={filters?.shipment_filter || false}
                          setState={(e) => setFilters({ ...filters, shipment_filter: e })}
                          isSwitch={true}
                          label="Include Scanned Shipment"
                        />
                      </div>
                    }
                  >
                    <div className="row d-flex align-items-center justify-content-between">
                      <div className=" d-flex align-items-center ">
                        <form
                          className="app-search d-none d-lg-block"
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (!search.trim()) return;
                            setFilters({ ...pick(filters, "shipment_filter", "per_page"), search: search.trim(), page: 1 });
                          }}
                        >
                          <div className="position-relative d-block ">
                            <input
                              type="text"
                              className="form-control see-more-text"
                              placeholder="Search..."
                              value={search}
                              onChange={(e) => {
                                if (!e.target.value) {
                                  setFilters({ search: "", page: 1 });
                                  setSearch("");
                                } else {
                                  setSearch(e.target.value);
                                }
                              }}
                            />
                            <span className="bx bx-search-alt"></span>
                            <i className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"></i>
                          </div>
                        </form>
                        <div className="select2-container ml-1" style={{ width: "200px" }}>
                          <Select
                            classNamePrefix="select2-selection"
                            name="label_service"
                            options={serivceOptions}
                            value={serivceOptions.find((opt) => opt.value === filters.label_service)}
                            formStyle={{ Width: "100%" }}
                            onChange={(e) => setFilters(merge({}, filters, { label_service: e.value }))}
                          />
                        </div>
                        {isEasypost(filters.label_service) && (
                          <div className="select2-container ml-2" style={{ width: "200px" }}>
                            <Select
                              classNamePrefix="select2-selection"
                              value={carrierOptions.find((opt) => opt.value === filters.carrier)}
                              name="carrier"
                              options={carrierOptions}
                              formStyle={{ Width: "100%" }}
                              onChange={(e) => setFilters(merge({}, filters, { carrier: e.value }))}
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-block">
                        <PillBtn
                          className="mr-2"
                          color="primary"
                          title="Generate Scanforms"
                          name="Generate Scanforms"
                          onClick={toggleGenerateScanformModal}
                        />
                      </div>
                    </div>
                  </RenderIf>
                </div>
              </CardHeader>
            </RenderIf>
            <Nav tabs className="nav-tabs-custom nav-justified" style={{ height: "50px" }}>
              {tabs.map((tab) => (
                <NavItem key={`email_tab-${tab.id}`}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === tab.id,
                    })}
                    onClick={() => {
                      toggleActiveTab(tab.id);
                    }}
                  >
                    <span className="d-none d-sm-block">{tab.title}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <CardBody>
              <div className="table-responsive">
                <RenderIf
                  isTrue={
                    (Array.isArray(scanforms?.data) && scanforms?.data?.length) ||
                    (Array.isArray(scanformShipments?.data) && scanformShipments?.data?.length)
                  }
                  fallback={
                    <div className="d-flex justify-content-center m-2">
                      <div className="d-flex flex-column">
                        <h1>No Records Found</h1>
                      </div>
                    </div>
                  }
                >
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <RenderIf
                        isTrue={isScanformTab(activeTab)}
                        fallback={
                          <tr>
                            <th>Tracking Number</th>
                            <th>Carrier</th>
                            <th>Created At</th>
                            <RenderIf isTrue={filters?.shipment_filter}>
                              <th>Scanform Batch ID</th>
                              <th>Status</th>
                              <th>Associated Scanform</th>
                            </RenderIf>
                          </tr>
                        }
                      >
                        <tr>
                          <th>Batch ID</th>
                          <RenderIf isTrue={isAdminUser}>
                            <th>Warehouse</th>
                          </RenderIf>
                          <th>Carrier</th>
                          <th>Label Service</th>
                          <th>Created At</th>
                          <th>Form URL</th>
                          <th>Last Downloaded</th>
                        </tr>
                      </RenderIf>
                    </thead>
                    <tbody>
                      <RenderIf
                        isTrue={isScanformTab(activeTab)}
                        fallback={scanformShipments?.data?.map((row, key) => (
                          <tr key={`__scanformShipmentRow${key}__`}>
                            <td>{getText(row?.tracking_number)}</td>
                            <td>{getText(row?.carrier)}</td>
                            <td>{moment(row?.created_at).format(DATE_FORMATS.DATE_TIME) || ""}</td>
                            <RenderIf isTrue={filters?.shipment_filter}>
                              <td>{row?.scanform?.batch_id}</td>
                              <td>{getText(row?.scanform?.status)}</td>
                              <td>
                                <RenderIf isTrue={row?.scanform?.form_url}>
                                  <i
                                    title="Download Scanform"
                                    className="bx bx-sm bx bx-download pl-2 text-primary cursor-pointer"
                                    onClick={() => dispatch(actions.downloadFormFile(row?.scanform?.form_url))}
                                  ></i>
                                </RenderIf>
                              </td>
                            </RenderIf>
                          </tr>
                        ))}
                      >
                        {scanforms?.data?.map((row, key) => (
                          <tr key={`__scanformRow${key}__`}>
                            <td className="w-50">{row?.batch_id || "N/A"}</td>
                            <RenderIf isTrue={isAdminUser}>
                              <td>{getWarehouseName(grpWarehouses[row?.wh_id])}</td>
                            </RenderIf>
                            <td>{startCase(row?.carrier)}</td>
                            <td>{serviceLabel(row?.label_service) || "N/A"}</td>
                            <td>{moment(row?.created_at).format(DATE_FORMATS.DATE_TIME) || ""}</td>
                            <td>
                              <RenderIf isTrue={row?.form_url} fallback={""}>
                                <i
                                  title="Download Scanform"
                                  className="bx bx-sm bx bx-cloud-download pl-2 text-primary cursor-pointer"
                                  onClick={() => dispatch(actions.downloadFormFile(row?.form_url, row?.id))}
                                ></i>
                              </RenderIf>
                            </td>
                            <td>
                              <Badge className={"font-size-14 badge-primary"} color={row?.downloaded ? "success" : "warning"} pill>
                                {row?.downloaded || "Not Downloaded"}
                              </Badge>
                            </td>
                          </tr>
                        ))}
                      </RenderIf>
                    </tbody>
                  </table>
                </RenderIf>
              </div>

              <RenderIf isTrue={isScanformTab(activeTab) ? scanforms?.data?.length : scanformShipments?.data?.length}>
                <CustomPagination
                  total={isScanformTab(activeTab) ? scanforms?.count : scanformShipments?.count}
                  pageOptions={[20, 50, 100]}
                  page={filters.page}
                  perPage={filters.per_page}
                  tabsFilter={filters}
                  setTabFilter={setFilters}
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default ListScanforms;
