import { pick, values, merge, size, filter } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import renderErrors from "./RenderErrors";
import { Col, Label, FormGroup } from "reactstrap";
import { RenderIf, adjustDate, getUserInfo } from "../../../utils/common";

const InventoryReportForm = (props) => {
  const { formik } = props;
  const [warehouses, setWarehouses] = useState([]);
  const dateCols = ["start_date", "end_date"];
  const userId = getUserInfo()?.account_id;

  useEffect(() => {
    if (props.wareHouses?.data) {
      setWarehouses(filter(props.wareHouses?.data, (el) => el.AccountWarehouse.some((x) => x.id === userId)));
    }
  }, [props.wareHouses?.data]);

  const staffWhId = getUserInfo()?.warehouse_id;

  return (
    <>
      <RenderIf isTrue={!size(props.data) && !props.isInsight}>
        <Col className="my-1">
          <Label>Select Date Range {renderErrors(formik, dateCols)}</Label>
          <div className="w-100">
            <Flatpickr
              className="form-control"
              placeholder="Select Date Range"
              value={values(pick(formik.values, dateCols))}
              options={{ mode: "range" }}
              onClose={(val) => {
                const newDates = val.map(adjustDate);
                if (newDates.length > 1) {
                  formik.setFieldValue("start_date", newDates[0]);
                  formik.setFieldValue("end_date", newDates[1]);
                }
              }}
            />
          </div>
        </Col>
      </RenderIf>
      <RenderIf isTrue={!staffWhId}>
        <Col className="my-1">
          <Label>Warehouse {renderErrors(formik, ["warehouse"])}</Label>
          <FormGroup className="select2-container w-100">
            <Select
              name="warehouse"
              value={formik.values.warehouse}
              onChange={(e) => formik.setFieldValue("warehouse", e)}
              options={warehouses.reduce(
                (arr, wh) => {
                  arr.push({ label: `Warehouse#${wh.id}-${wh.state}`, value: wh.id });
                  return arr;
                },
                props.isReturns ? [{ label: "Select all warehouses", value: "all" }] : [],
              )}
              classNamePrefix="select2-selection"
              height={{ maxHeight: "250px" }}
            />
          </FormGroup>
        </Col>
      </RenderIf>
    </>
  );
};

const mapStateToProps = (state) => merge({}, pick(state.WareHouses, "wareHouses"));

export default withRouter(connect(mapStateToProps, {})(InventoryReportForm));
