import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { setPreloader, fetchStoreWiseStats, fetchTenantWiseStats } from "../../store/actions";
import CustomPagination from "../../components/Common/CustomPagination";
import { keys, snakeCase } from "lodash";
import classnames from "classnames";
import getBrandImageSrc from "../../utils/brandImages";
import { USER_ROLES, DATE_FORMATS } from "../../constants";
import { formatNumber } from "../../utils/common";
import { DatePicker } from "../../components/Common/DatePickr";

const ActivityLogs = (_) => {
  const { loading, storeWiseStats, tenantWiseStats, tenantsData } = useSelector((state) => state.Settings);
  const [filters, setFilters] = useState({
    sort_by: "total_sales",
    sort_direction: "desc",
    search: "",
    page: 1,
    per_page: 20,
  });
  const [selectedReport, setSelectedReport] = useState(0);
  const dispatch = useDispatch();
  const selectUser = (user) => tenantsData?.[user.tenant_id]?.users?.find((u) => u.role === USER_ROLES.owner);
  const reports = ["Store Wise", "Tenant Wise"];

  const currentReport = [storeWiseStats, tenantWiseStats][selectedReport];
  const ShowData = ({ item, keyName: key }) => {
    if (/marketplace/i.test(key)) return <img src={getBrandImageSrc(item[key])} alt="store logo" />;
    const value = item[key];
    return (
      <span>
        {["Total Sales", "Total Profits", "Total Roi", "Profits Per Listing", "Profits Per Item"].includes(key)
          ? key === "Total Roi" ? `${formatNumber(value, "float")}%` : formatNumber(value, "currency")
          : value.toLocaleString()}
      </span>
    );
  };

  function showTable (item, keyName) {
    const user = selectUser(item);
    if (keyName === "tenant_id") return user?.email || "";
    else if (keyName === "Name") return user?.username || "";
    else return <ShowData item={item} keyName={keyName} />;
  }

  function fetchReport () {
    switch (selectedReport) {
      case 0:
        dispatch(fetchStoreWiseStats(filters));
        break;
      case 1:
        dispatch(fetchTenantWiseStats(filters));
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (loading) return;
    fetchReport();
  }, [selectedReport, filters]);

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between m-2">
          <Breadcrumbs title="Leader Board" />
          <div className="d-flex align-items-center justify-content-between ml-3" style={{ width: "530px" }}>
          {currentReport?.refreshed_at
            ? (
            <div className="black-color">
              <span className="font-weight-bold">Last Refreshed At </span>
              <span>({moment(currentReport.refreshed_at).format(DATE_FORMATS.DATE_TIME)})</span>
            </div>
              )
            : null}
            <DatePicker onClose={(dates) => setFilters({ ...filters, ...dates, page: 1 })} />
          </div>
        </div>
        <Row>
          <Col lg={12}>
            <Nav pills className="navtab-bg nav-justified">
              {reports.map((report, key) => (
                <NavItem key={"_col_" + key}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: key === selectedReport })}
                    onClick={() => {
                      setSelectedReport(key);
                      setFilters({
                        ...filters,
                        sort_by: key === 0 ? "total_sales" : "profits_per_listing",
                        sort_direction: "desc",
                        page: 1,
                        per_page: 20,
                      });
                    }}
                  >
                    {report}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
          {Array.isArray(currentReport?.data) && currentReport.data.length
            ? (
            <>
              <div className="table-responsive mt-3">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {keys(currentReport.data[0]).map((header, index) =>
                        /marketplace/i.test(header) ||
                        /store/i.test(header) ||
                        /email/i.test(header) ||
                        /sku/i.test(header) ||
                        /tenant_id/i.test(header) ||
                        /name/i.test(header)
                          ? (
                          <th key={`table-header ${index}`}>{header === "tenant_id" ? "Email" : header}</th>
                            )
                          : (
                          <th key={`table-header ${index}`}>
                            {header}
                            <div className="d-inline">
                              <i
                                id="up-filter"
                                className="dripicons-arrow-thin-up cursor-pointer mx-1"
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    sort_by: snakeCase(header),
                                    sort_direction: "asc",
                                  })
                                }
                              ></i>
                              <i
                                id="down-filter"
                                className="dripicons-arrow-thin-down cursor-pointer mx-1"
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    sort_by: snakeCase(header),
                                    sort_direction: "desc",
                                  })
                                }
                              ></i>
                            </div>
                          </th>
                            ),
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentReport.data.map((item, key) => (
                      <tr key={"_top_" + key}>
                        {keys(item).map((keyName, _ind) => (
                          <td key={"_row_" + _ind}>{showTable(item, keyName)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <CustomPagination
                total={currentReport.total}
                pageOptions={[20, 50, 100]}
                page={currentReport.page}
                perPage={currentReport.perPage}
                tabsFilter={filters}
                setTabFilter={(e) =>
                  setFilters({
                    ...filters,
                    page: filters.per_page !== e.per_page ? 1 : e.page,
                    per_page: e.per_page,
                  })
                }
              />
            </>
              )
            : null}
          {currentReport?.total === 0
            ? (
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No records found in given date range</h1>
              </div>
            </div>
              )
            : null}
      </Container>
    </div>
  );
};

export default ActivityLogs;
