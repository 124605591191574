import React, { Suspense, lazy } from "react";

const LazyComponent = (props) => {
  const Component = lazy(() => import(`${props.path}`));
  return (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );
};

export default LazyComponent;
