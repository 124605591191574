import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Row, Col, Label, Input, Button } from "reactstrap";
import { useFormik } from "formik";
import Modal from "./Modal";
import CheckBox from "./CheckBox";

// actions
import { updateStock } from "../../store/actions";
import { getUserInfo } from "../../utils/common";

const UpdateStock = (props) => {
  const { sku, platform, partnerId } = props;
  const dispatch = useDispatch();
  const [open, setIsOpen] = useState(false);
  const { success, error, loading } = useSelector((state) => state.Supplier);

  const handleUpdateStock = (data) => {
    dispatch(updateStock(data));
  };

  const validate = (values) => {
    const errors = {};
    values.stock === "" && (errors.stock = "Field is Required");
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      sku,
      platform,
      stock: "",
      partner_id: partnerId,
      permanent: false,
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      values.va_id = getUserInfo().id;
      handleUpdateStock(values);
    },
  });

  const toggleModal = () => {
    setIsOpen(!open);
    formik.resetForm();
  };

  useEffect(() => {
    open && success && !loading && toggleModal();
  }, [success, loading]);

  return (
    <>
      {!props.onDetailsPage
        ? (
        <i title="Update Stock" className="cursor-pointer bx bx-sm bx-trending-down mx-2" onClick={toggleModal} />
          )
        : (
        <>
          <span className="text-primary font-size-11 cursor-pointer" onClick={toggleModal}>
            Update Stock
          </span>
          <span className="border-right ml-1" />
        </>
          )}
      <Modal size="md" isOpen={open} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Stock</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error}
            </Alert>
          )}
          {success && (
            <Alert color="success">
              <i className="bx bx-info-circle pr-2"></i>
              {success}
            </Alert>
          )}
          <form className="m-2" onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="12" className="my-1">
                <Label>Stock</Label>
                <Input
                  name="stock"
                  type="number"
                  min={0}
                  value={formik.values.stock}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.stock && formik.errors.stock
                  ? (
                  <span className="text-danger mt-1">{formik.errors.stock}</span>
                    )
                  : null}
              </Col>
              <Col md="12" className="my-1">
                <CheckBox
                  isSwitch={true}
                  name="permanent"
                  label="Permanent"
                  className="mt-2"
                  state={formik.values.permanent}
                  setState={(state) => {
                    formik.setFieldValue("permanent", state);
                  }}
                />
                <Label className="mt-3">
                  This will enable manual stock lock and item will not be restock on Marketplace!
                </Label>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="success" className="mr-3">
                Submit
              </Button>
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </>
  );
};

export default UpdateStock;
