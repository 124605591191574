export const FETCH_LISTINGS = "FETCH_LISTINGS";
export const FETCH_LISTINGS_SUCCESS = "FETCH_LISTINGS_SUCCESS";
export const RESET_LISTING_STATE = "RESET_LISTING_STATE";

export const CHECK_LISTING_IN_STORE = "CHECK_LISTING_IN_STORE";
export const CHECK_LISTING_IN_STORE_SUCCESS = "CHECK_LISTING_IN_STORE_SUCCESS";

export const SET_SUPPLIER_MODAL = "SET_SUPPLIER_MODAL";
export const SET_SUPPLIER_ADD_MODAL = "SET_SUPPLIER_ADD_MODAL";
export const FETCH_MARKETPLACE_PRODUCT_INFO = "FETCH_MARKETPLACE_PRODUCT_INFO";
export const FETCH_MARKETPLACE_PRODUCT_INFO_SUCCESS = "FETCH_MARKETPLACE_PRODUCT_INFO_SUCCESS";
export const FETCH_SHIPPING_TEMPLATE = "FETCH_SHIPPING_TEMPLATE";
export const FETCH_SHIPPING_TEMPLATE_SUCCESS = "FETCH_SHIPPING_TEMPLATE_SUCCESS";
export const SYNC_SHIPPING_TEMPLATE = "SYNC_SHIPPING_TEMPLATE";
export const SYNC_SHIPPING_TEMPLATE_SUCCESS = "SYNC_SHIPPING_TEMPLATE_SUCCESS";
export const FETCH_MATCHING_LOGS = "FETCH_MATCHING_LOGS";
export const FETCH_MATCHING_LOGS_SUCCESS = "FETCH_MATCHING_LOGS_SUCCESS";
export const ASSIGN_SHIPPING_TEMPLATE = "ASSIGN_SHIPPING_TEMPLATE";
export const ASSIGN_SHIPPING_TEMPLATE_SUCCESS = "ASSIGN_SHIPPING_TEMPLATE_SUCCESS";

export const CREATE_INVENTORY_LISTING = "CREATE_INVENTORY_LISTING";
export const CREATE_INVENTORY_LISTING_SUCCESS = "CREATE_INVENTORY_LISTING_SUCCESS";

export const UPDATE_UPC = "UPDATE_UPC";
export const UPDATE_UPC_SUCCESS = "UPDATE_UPC_SUCCESS";
export const RESET_FETCH_ERROR = "RESET_FETCH_ERROR";
export const CHANGE_PRODUCT_VARIATION = "CHANGE_PRODUCT_VARIATION";
export const CHANGE_PRODUCT_VARIATION_SUCCESS = "CHANGE_PRODUCT_VARIATION_SUCCESS";
export const CHANGE_QUANTITY_MULTIPLIER = "CHANGE_QUANTITY_MULTIPLIER";
export const CHANGE_QUANTITY_MULTIPLIER_SUCCESS = "CHANGE_QUANTITY_MULTIPLIER_SUCCESS";
export const SETUP_MANUAL_MATCHES = "SETUP_MANUAL_MATCHES";
export const SETUP_MANUAL_MATCHES_SUCCESS = "SETUP_MANUAL_MATCHES_SUCCESS";
export const RESET_MANUAL_MATCH_STATE = "RESET_MANUAL_MATCH_STATE";
export const ADD_CSV_LISTINGS = "ADD_CSV_LISTINGS";
export const ADD_CSV_LISTINGS_SUCCESS = "ADD_CSV_LISTINGS_SUCCESS";
export const DOWNLOAD_LISTINGS = "DOWNLOAD_LISTINGS";
export const DOWNLOAD_LISTINGS_SUCCESS = "DOWNLOAD_LISTINGS_SUCCESS";
export const FETCH_UPLOADED_FILES = "FETCH_UPLOADED_FILES";
export const FETCH_UPLOADED_FILES_SUCCESS = "FETCH_UPLOADED_FILES_SUCCESS";
export const SET_SELECTED_LISTINGS = "SET_SELECTED_LISTINGS";
export const SET_SELECTED_LIVE_LISTING = "SET_SELECTED_LIVE_LISTING";
export const SET_FILTERED_LISTINGS = "SET_FILTERED_LISTINGS";
export const SET_LISTINGS_BULK_UPDATE_MODAL = "SET_LISTINGS_BULK_UPDATE_MODAL";
export const SET_SHIPPING_TEMPLATE_UPDATE_MODAL = "SET_SHIPPING_TEMPLATE_UPDATE_MODAL";
export const LISTINGS_BULK_UPDATE = "LISTINGS_BULK_UPDATE";
export const LISTINGS_BULK_UPDATE_SUCCESS = "LISTINGS_BULK_UPDATE_SUCCESS";
export const LISTINGS_BULK_DELETE = "LISTINGS_BULK_DELETE";
export const LISTINGS_BULK_DELETE_SUCCESS = "LISTINGS_BULK_DELETE_SUCCESS";
export const UPDATE_BRAND_RESTRICTION = "UPDATE_BRAND_RESTRICTION";
export const UPDATE_BRAND_RESTRICTION_SUCCESS = "UPDATE_BRAND_RESTRICTION_SUCCESS";
export const UPDATE_BRAND_RESTRICTION_BULK = "UPDATE_BRAND_RESTRICTION_BULK";
export const UPDATE_BRAND_RESTRICTION_BULK_SUCCESS = "UPDATE_BRAND_RESTRICTION_BULK_SUCCESS";
export const SET_ERRORED_LISTINGS_UPDATE_MODAL = "SET_ERRORED_LISTINGS_UPDATE_MODAL";
export const UPDATE_ERRORED_LISTING = "UPDATE_ERRORED_LISTING";
export const UPDATE_ERRORED_LISTING_SUCCESS = "UPDATE_ERRORED_LISTING_SUCCESS";
export const SHOW_CUSTOM_FILTERS = "SHOW_CUSTOM_FILTERS";
export const SET_CUSTOM_FILTERS = "SET_CUSTOM_FILTERS";
export const RESET_CUSTOM_FILTERS = "RESET_CUSTOM_FILTERS";
export const SELECT_FILE_ERROR = "SELECT_FILE_ERROR";
export const UPDATE_MAP_AND_HANDLING_TIME = "UPDATE_MAP_AND_HANDLING_TIME";
export const UPDATE_MAP_AND_HANDLING_TIME_SUCCESS = "UPDATE_MAP_AND_HANDLING_TIME_SUCCESS";
export const BULK_UPDATE_HANDLING_TIME = "BULK_UPDATE_HANDLING_TIME";
export const BULK_UPDATE_HANDLING_TIME_SUCCESS = "BULK_UPDATE_HANDLING_TIME_SUCCESS";
export const API_ERROR = "FETCH_LISTINGS_ERROR";
export const SET_MA_PRICE_MODAL = "SET_MA_PRICE_MODAL";
export const FETCH_MATCHING_PLATFORMS = "FETCH_MATCHING_PLATFORMS";
export const FETCH_MATCHING_PLATFORMS_SUCCESS = "FETCH_MATCHING_PLATFORMS_SUCCESS";
export const ADD_MATCHING_PLATFORM_SUCCESS = "ADD_MATCHING_PLATFORM_SUCCESS";
export const ADD_MATCHING_PLATFORM = "ADD_MATCHING_PLATFORM";
export const UPDATE_SHIPPING_TEMPLATE = "UPDATE_SHIPPING_TEMPLATE";
export const UPDATE_SHIPPING_TEMPLATE_SUCCESS = "UPDATE_SHIPPING_TEMPLATE_SUCCESS";
export const BULK_UPDATE_TWO_STEP_MODAL = "BULK_UPDATE_TWO_STEP_MODAL";
export const BULK_UPDATE_TWO_STEP_MODAL_SUCCESS = "BULK_UPDATE_TWO_STEP_MODAL_SUCCESS";
export const SET_TWO_STEP_MODAL = "SET_TWO_STEP_MODAL";
export const UPDATE_SEARCHED_LISTING = "UPDATE_SEARCHED_LISTING";
export const RELIST_RETIRED_LISTINGS = "RELIST_RETIRED_LISTINGS";
export const RELIST_RETIRED_LISTINGS_SUCCESS = "RELIST_RETIRED_LISTINGS_SUCCESS";
export const SET_TEMPLATE_MODAL = "SET_TEMPLATE_MODAL";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const UPLOAD_FACEBOOK_LISTINGS = "UPLOAD_FACEBOOK_LISTINGS";
export const UPLOAD_FACEBOOK_LISTINGS_SUCCESS = "UPLOAD_FACEBOOK_LISTINGS_SUCCESS";
export const UPDATE_LISTING_WEIGHT = "UPDATE_LISTING_WEIGHT";
export const UPDATE_LISTING_WEIGHT_SUCCESS = "UPDATE_LISTING_WEIGHT_SUCCESS";
export const RESET_WEIGHT_ERR = "RESET_WEIGHT_ERR";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";

export const FETCH_INVENTORY_ITEM_INFO = "FETCH_INVENTORY_ITEM_INFO";
export const FETCH_INVENTORY_ITEM_INFO_SUCCESS = "FETCH_INVENTORY_ITEM_INFO_SUCCESS";

export const RESET_WH_SKU_INVENTORY = "RESET_WH_SKU_INVENTORY";
