import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import { setSavedFilter } from "../../../store/actions";

const SelectFilter = (props) => {
  const { defaultValue, classes, style } = props;

  const dispatch = useDispatch();
  const { savedFilters, selectedFilter, selectedMarketplace } = useSelector((state) => state.ProfitAnalyzer);
  const currentMarketFilters = savedFilters?.[selectedMarketplace] ?? {};
  const additionalClasses = classes || "";
  const formatOptions = (options) =>
    options?.map((val) => ({ value: val, label: val })).filter((val) => val.value !== "_id");

  const options = [
    { value: "", label: defaultValue ?? "No Filter" },
    ...formatOptions(Object.keys(currentMarketFilters)),
  ];
  const selectedOption = selectedFilter
    ? formatOptions([selectedFilter])[0]
    : { value: "", label: defaultValue ?? "No Filter" };

  return (
    <FormGroup className={`select2-container ${additionalClasses}`} style={style}>
      <Select
        name="filters"
        value={selectedOption}
        options={options}
        onChange={(e) => {
          const { value } = e;
          dispatch(
            setSavedFilter({
              selectedFilter: value,
            }),
          );
        }}
        classNamePrefix="select2-selection"
      />
    </FormGroup>
  );
};

export default SelectFilter;
