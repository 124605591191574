import * as types from "./actionTypes";
const {
  FETCH_AGENCY_STATS,
  FETCH_AGENCY_STATS_SUCCESS,
  FETCH_AGENCY_CLIENTS,
  FETCH_AGENCY_CLIENTS_PROFIT,
  FETCH_AGENCY_CLIENTS_SUCCESS,
  FETCH_AGENCY_CLIENTS_PROFIT_SUCCESS,
  UPDATE_PAYMENT_SOURCE_SETTINGS,
  UPDATE_PAYMENT_SOURCE_SETTINGS_SUCCESS,
  SET_ADD_CLIENT_MODAL,
  SET_EDIT_CLIENT_MODAL,
  REMOVE_CLIENT,
  REMOVE_CLIENT_SUCCESS,
  FETCH_TENANTS_STATS,
  FETCH_TENANTS_STATS_SUCCESS,
  API_ERROR,
  FETCH_STOREFRONT_HEALTH_MATRICS,
  FETCH_STOREFRONT_HEALTH_MATRICS_SUCCESS,
} = types;

const initialState = {
  error: "",
  success: "",
  successMessage: "",
  clientPaymentSuccess: "",
  loading: false,
  fetchClientLoader: false,
  clientLoader: false,
  stats: {},
  agencyStats: {},
  clients: [],
  totalCount: 0,
  perPage: 0,
  page: 0,
  archived: false,
  addClientModalIsOpen: false,
  editClientModalIsOpen: false,
};

const Agency = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AGENCY_STATS:
      state = {
        ...state,
        error: "",
        success: "",
        clientLoader: true,
      };
      break;
    case FETCH_AGENCY_STATS_SUCCESS:
      state = {
        ...state,
        stats: action.payload.data,
        clientLoader: false,
      };
      break;
    case FETCH_AGENCY_CLIENTS:
      state = {
        ...state,
        error: "",
        success: "",
        fetchClientLoader: true,
      };
      break;
    case FETCH_AGENCY_CLIENTS_SUCCESS:
      state = {
        ...state,
        clients: action.payload.data,
        totalCount: action.payload.total,
        loading: false,
        page: action.payload.page,
        perPage: action.payload.per_page,
        fetchClientLoader: false,
      };
      break;
    case FETCH_AGENCY_CLIENTS_PROFIT:
      state = {
        ...state,
        error: "",
        success: "",
        clientLoader: true,
      };
      break;
    case FETCH_AGENCY_CLIENTS_PROFIT_SUCCESS:
      state = {
        ...state,
        clientsProfit: action.payload.data,
        clientLoader: false,
      };
      break;
    case UPDATE_PAYMENT_SOURCE_SETTINGS:
      state = {
        ...state,
        error: "",
        success: "",
        clientPaymentSuccess: "",
        loading: true,
      };
      break;
    case UPDATE_PAYMENT_SOURCE_SETTINGS_SUCCESS: {
      const isClientSuccess = action.payload.childUpdate;
      state = {
        ...state,
        error: "",
        loading: false,
        clientPaymentSuccess: isClientSuccess ? action.payload.message : "",
        editClientModalIsOpen: false,
        success: !isClientSuccess ? action.payload.message : "",
      };
      break;
    }
    case SET_ADD_CLIENT_MODAL:
      state = {
        ...state,
        addClientModalIsOpen: action.payload,
        loading: false,
      };
      break;
    case SET_EDIT_CLIENT_MODAL:
      action.payload.client && (state.selectedClient = action.payload.client);
      state = {
        ...state,
        editClientModalIsOpen: action.payload.isOpen,
        loading: false,
      };
      break;
    case REMOVE_CLIENT:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case REMOVE_CLIENT_SUCCESS:
      state = {
        ...state,
        success: action.payload.message,
        clients: action.payload.data,
        error: "",
        loading: false,
      };
      break;
    case types.ARCHIVE_CLIENT:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case types.ARCHIVE_CLIENT_SUCCESS:
      state = {
        ...state,
        successMessage: action.payload.message,
        clients: action.payload.data,
        totalCount: action.payload.total,
        error: "",
        loading: false,
      };
      break;
    case types.SET_ARCHIVED:
      state = {
        ...state,
        archived: action.payload,
      };
      break;
    case types.UPDATE_MESSAGE:
      state = {
        ...state,
        successMessage: action.payload,
      };
      break;
    case FETCH_TENANTS_STATS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_TENANTS_STATS_SUCCESS:
      state = {
        ...state,
        agencyStats: action.payload.data,
        loading: false,
      };
      break;
    case FETCH_STOREFRONT_HEALTH_MATRICS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_STOREFRONT_HEALTH_MATRICS_SUCCESS:
      state = {
        ...state,
        storefrontHealthMatrics: action.payload.data,
        loading: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      break;
  }
  return state;
};

export default Agency;
