import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { updateOrderAttempt } from "../../api";
import { UPDATE_ORDER_ATTEMPT } from "./actionTypes";
import { apiError, updateOrderAttemptSuccess } from "./actions";

function * updateOrderAttemptSaga ({ payload: { id, params } }) {
  try {
    const res = yield call(updateOrderAttempt, id, params);
    yield put(updateOrderAttemptSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchOrderAttemptUpdate () {
  yield takeEvery(UPDATE_ORDER_ATTEMPT, updateOrderAttemptSaga);
}

function * orderSaga () {
  yield all([fork(watchOrderAttemptUpdate)]);
}

export default orderSaga;
