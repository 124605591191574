import { makeQP } from "../../utils/common";
import request from "../restClient";
import { omit } from "lodash";

export const fetchProxyUsers = async (filters) => await request({ url: "/proxy_user" + makeQP(filters) });

export const createProxyUser = async (proxyUser) => {
  const url = "/proxy_user";
  return await request({ url, method: "POST", body: proxyUser });
};

export const updateProxyUser = async (proxyUser) => {
  const url = `/proxy_user/${encodeURIComponent(proxyUser.email)}`;
  return await request({ url, method: "PUT", body: omit(proxyUser, "email") });
};

export const fetchLuminatiProxyData = async (_) => await request({ url: "/get_proxies", method: "GET" });

export const fetchProxyByPort = async (port) => await request({ url: `/get_proxy_by_port/${port}`, method: "GET" });

export const swapProxyByPort = async (port) => await request({ url: `/swap_proxy/${port}`, method: "PUT" });

export const updateProxyByPort = async ({ data, port }) =>
  await request({ url: `/update_proxy/${port}`, method: "PUT", body: data });

export const uploadProxyCsv = async ({ data }) => {
  const formData = new FormData();
  formData.append("csvFile", data, "csvFile.csv");
  await request({ url: "/import_proxies", method: "POST", formData });
};
