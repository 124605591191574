import React from "react";
import { useFormik } from "formik";
import { MARKET_PLACES } from "../../../constants";
import { FormGroup, Row, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../../components/Common/Modal";
import CheckBox from "../../../components/Common/CheckBox";

// actions
import { updateErroredListing, setErroredListingsUpdateModal } from "../../../store/actions";

function ResolveErrorModal (props) {
  const dispatch = useDispatch();
  const { listing, resolveErrorTypes, storeFront } = props;
  const { erroredListingsUpdateModalIsOpen } = useSelector((state) => state.Listings);

  const isResolveable = {
    last_submitted_price: [resolveErrorTypes.needs_more_info, resolveErrorTypes.price_not_present].includes(
      listing.error_type,
    ),
    upc:
      (resolveErrorTypes.needs_more_info === listing.error_type && storeFront.marketplace === MARKET_PLACES.walmart) ||
      resolveErrorTypes.upc_not_present === listing.error_type,
    brand:
      resolveErrorTypes.needs_more_info === listing.error_type ||
      resolveErrorTypes.brand_not_present === listing.error_type,
    skip_brand_restriction: resolveErrorTypes.brand_restricted === listing.error_type,
    skip_product_restriction: resolveErrorTypes.product_restricted === listing.error_type,
  };

  const formik = useFormik({
    initialValues: {
      last_submitted_price: listing?.last_submitted_price || "",
      upc: listing?.upc || "",
      brand: listing?.brand || "",
      skip_brand_restriction: !!listing?.skip_brand_restriction,
      skip_product_restriction: !!listing?.skip_product_restriction,
    },
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const data = { sku: listing.sku, platform: storeFront.marketplace, partner_id: storeFront.value, listing: {} };
      if (isResolveable.last_submitted_price) data.listing.last_submitted_price = values.last_submitted_price;
      if (isResolveable.upc) data.listing.upc = values.upc;
      if (isResolveable.brand) data.listing.brand = values.brand;
      if (isResolveable.skip_brand_restriction) data.listing.skip_brand_restriction = values.skip_brand_restriction;
      if (isResolveable.skip_product_restriction)
        data.listing.skip_product_restriction = values.skip_product_restriction;

      dispatch(updateErroredListing(data));
      formik.handleReset();
    },
  });

  return (
    <React.Fragment>
      <Modal size="md" isOpen={erroredListingsUpdateModalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title">Fixing the error</h5>
          <button
            onClick={() => dispatch(setErroredListingsUpdateModal(!erroredListingsUpdateModalIsOpen))}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          <form className="m-2">
            {isResolveable.last_submitted_price && (
              <FormGroup>
                <p>
                  Listed Price could not be calculated for this item. Please add the price you want to list this item
                  at.
                </p>
                <label>Listed Price</label>
                <input
                  type="text"
                  value={formik.values.last_submitted_price}
                  name="last_submitted_price"
                  className="form-control"
                  placeholder="Listed Price"
                  onChange={formik.handleChange}
                />
              </FormGroup>
            )}
            {isResolveable.upc && (
              <FormGroup>
                <label>UPC</label>
                <input
                  type="text"
                  value={formik.values.upc}
                  name="upc"
                  className="form-control"
                  placeholder="UPC"
                  onChange={formik.handleChange}
                />
              </FormGroup>
            )}
            {isResolveable.brand && (
              <FormGroup>
                <label>Brand</label>
                <input
                  type="text"
                  name="brand"
                  value={formik.values.brand}
                  className="form-control"
                  placeholder="Brand"
                  onChange={formik.handleChange}
                />
              </FormGroup>
            )}
            {isResolveable.skip_brand_restriction && (
              <CheckBox
                label="Override Brand Restriction"
                isSwitch={true}
                name="skip_brand_restriction"
                state={!!formik.values.skip_brand_restriction}
                setState={() => formik.setFieldValue("skip_brand_restriction", !formik.values.skip_brand_restriction)}
              />
            )}
            {isResolveable.skip_product_restriction && (
              <CheckBox
                label="Override Product Restriction"
                isSwitch={true}
                name="skip_product_restriction"
                state={!!formik.values.skip_product_restriction}
                setState={() =>
                  formik.setFieldValue("skip_product_restriction", !formik.values.skip_product_restriction)
                }
              />
            )}
          </form>
        </div>
        <div className="modal-footer">
          <Row className="d-flex justify-content-end mt-3">
            <Button
              color="primary"
              onClick={() => {
                formik.handleReset();
                dispatch(setErroredListingsUpdateModal(!erroredListingsUpdateModalIsOpen));
              }}
              outline
              className="waves-effect waves-light mr-3"
            >
              Cancel
            </Button>
            <Button type="submit" onClick={() => formik.handleSubmit()} color="primary" className="mr-3">
              Submit
            </Button>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default ResolveErrorModal;
