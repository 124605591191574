import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button, FormGroup } from "reactstrap";
import Modal from "../../components/Common/Modal";
import "react-toggle/style.css";
import { connect } from "react-redux";
import Select from "react-select";
import { MARKET_PLACES, PRIME_ACC_TYPES, HELP_DOCS_LINK } from "../../constants/index";
import CheckBox from "../../components/Common/CheckBox";
import { getUserInfo, toPascalCase, RenderIf } from "../../utils/common";
import SweetAlert from "react-bootstrap-sweetalert";
// actions
import {
  createCredentials,
  updateCredentials,
  setAddCredentialsModal,
  setEditCredentialsModal,
} from "../../store/actions";
import { startCase, last } from "lodash";

const CredentialsModal = (props) => {
  const [show2fa, setShow2fa] = useState(false);
  const [aoEnabledToggleConfirmation, setAoEnabledToggleConfirmation] = useState({ key: "", value: "" });
  const [isOpen, setIsOpen] = useState(false);
  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || (values[key].label === "" && values[key].value === ""))
        !["otp_secret", "acc_type"].includes(key) && (errors[key] = "Field is Required");
    });
    return errors;
  };

  const siteName = props.Credential.selectedCredentials?.site;
  const confirmMsgMapping = {
    enabled: `to ${aoEnabledToggleConfirmation.value ? "enable" : "disable"} AO`,
    blocked: `to ${aoEnabledToggleConfirmation.value ? "block" : "unblock"} account`,
  };

  const initialValues = {
    site: {
      label: toPascalCase(MARKET_PLACES[siteName]) || "",
      value: siteName || "",
    },
    password: "",
    otp_secret: "",
    is_prime: false,
    enabled: false,
    blocked: false,
    pay_by_invoice: false,
    acc_type: "",
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      const data = { ...values, is_prime: values.acc_type.includes("prime") };
      if (!data.acc_type) data.acc_type = last(PRIME_ACC_TYPES);
      if (!(show2fa || props.type === "add")) delete data.otp_secret;

      if (props.type === "edit" && props.Credential.selectedCredentials) {
        props.updateCredentials({
          data: { ...data, site: formik.values.site.value },
          id: props.Credential.selectedCredentials.id,
        });
      } else {
        props.createCredentials({
          data: {
            ...data,
            site: formik.values.site.value,
            id: props.accountId,
          },
        });
      }
    },
  });

  useEffect(() => {
    props.Credential.selectedCredentials &&
      props.type === "edit" &&
      Object.keys(initialValues).forEach((key) => {
        key !== "site" &&
          props.Credential.selectedCredentials[key] !== "" &&
          props.Credential.selectedCredentials[key] !== undefined &&
          props.Credential.selectedCredentials[key] !== null &&
          formik.setFieldValue(key, props.Credential.selectedCredentials[key], false);
      });
  }, [props.Credential.selectedCredentials]);

  useEffect(() => {
    props.Credential.success && !props.Credential.loading && toggleModal();
  }, [props.Credential.success, props.Credential.loading]);

  const toggleModal = () => {
    props.type === "edit"
      ? props.setEditCredentialsModal(!props.Credential.editCredentialModalIsOpen)
      : props.setAddCredentialsModal(!props.Credential.addCredentialModalIsOpen);
    formik.resetForm();
  };
  const accountTypes = (flag) => PRIME_ACC_TYPES.filter((x) => (flag ? x.includes("prime") : x));
  const accountType = formik.values.acc_type || last(accountTypes(formik.values.is_prime));

  return (
    <Modal
      size="md"
      isOpen={props.Credential.addCredentialModalIsOpen || props.Credential.editCredentialModalIsOpen}
      toggle={toggleModal}
    >
      <RenderIf isTrue={isOpen}>
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            const { key, value } = aoEnabledToggleConfirmation;
            formik.setFieldValue(key, value);
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {confirmMsgMapping[aoEnabledToggleConfirmation.key]}
        </SweetAlert>
      </RenderIf>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.type === "edit" ? "EDIT CREDENTIALS" : "ADD CREDENTIALS"}
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {props.Credential.success && (
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {props.Credential.success}
          </Alert>
        )}
        {props.Credential.error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {"Credentials: " + props.Credential.error}
          </Alert>
        )}

        {formik.values.site.value === "amazon" && (
          <Alert color="warning">
            <i className="bx bx-info-circle pr-2"></i>
            {
              "When you add Amazon accounts and use Auto Ordering, Auto Ordering will automatically route orders >$35 to non-prime accounts (if you have them) and orders <$25 to prime accounts (if you have them). We have found that this generally keeps Prime accounts open longer since it reduces the overall amount of orders going to them. However, if you want to use Prime accounts for all of your orders, simply disable AO for any account which does not have prime (turn off \"AO Enabled\")"
            }
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="6 mb-2">
              <FormGroup className="select2-container">
                <Label>Site*</Label>
                <Select
                  id="platform"
                  name="site"
                  value={formik.values.site || ""}
                  onChange={(option) => {
                    formik.setFieldValue("site", option);
                  }}
                  options={props?.platforms?.reduce((acc, platform) => {
                    if (platform?.name?.toLowerCase() !== "unknown")
                      acc.push({
                        label: toPascalCase(platform?.name),
                        value: platform?.name,
                      });
                    return acc;
                  }, [])}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
              {formik.touched.site && formik.errors.site
                ? (
                <span className="text-danger mt-1">{formik.errors.site}</span>
                  )
                : null}
            </Col>
            <Col lg="6 mb-2">
              <Label>Password*</Label>
              <Input
                name="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.password && formik.errors.password
                ? (
                <span className="text-danger mt-1">{formik.errors.password}</span>
                  )
                : null}
            </Col>
            {props.type === "edit" && (
              <Col lg="12 mb-2">
                <div className="custom-control custom-checkbox ml-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="CustomCheck1"
                    onChange={() => false}
                    checked={show2fa}
                  />
                  <label className="custom-control-label" onClick={() => setShow2fa(!show2fa)}>
                    {`${props.Credential.selectedCredentials?.has_otp_secret ? "Update" : "Add"} 2FA String`}
                  </label>
                </div>
              </Col>
            )}
            {formik.values.site.value === "amazon" && (show2fa || props.type === "add") && (
              <Col lg="12 mb-2">
                <div className="d-flex">
                  <Label>
                    2FA Code String ({" "}
                    <a href={HELP_DOCS_LINK} rel="noopener noreferrer" target="_blank" className="text-primary mb-1">
                      Instructions{" "}
                    </a>{" "}
                    to get 2FA Code )
                  </Label>
                </div>
                <Input
                  name="otp_secret"
                  value={formik.values.otp_secret}
                  placeholder={"Enter a Secret Key"}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
            )}
            <Col lg="6 mb-2">
              <CheckBox
                isSwitch={true}
                name="blocked"
                label="Blocked"
                className="mt-2"
                state={formik.values.blocked}
                setState={(state) => {
                  setAoEnabledToggleConfirmation({ key: "blocked", value: state });
                  setIsOpen(true);
                }}
              />
            </Col>
            <Col lg="6 mb-2">
              {[MARKET_PLACES.amazon, MARKET_PLACES.walmart, MARKET_PLACES.home_depot].includes(
                formik.values.site.value,
              )
                ? (
                <CheckBox
                  isSwitch={true}
                  name="enabled"
                  label="AO enabled"
                  className="mt-2"
                  state={formik.values.enabled}
                  setState={(state) => {
                    setAoEnabledToggleConfirmation({ key: "enabled", value: state });
                    setIsOpen(true);
                  }}
                />
                  )
                : null}
              {/* User with request for pay by invoice */}
              {getUserInfo()?.account_id === 2029 && formik.values.site.value === MARKET_PLACES.amazon
                ? (
                <CheckBox
                  isSwitch={true}
                  name="pay_by_invoice"
                  label="Pay By Invoice"
                  className="mt-2"
                  state={formik.values.pay_by_invoice}
                  setState={(state) => formik.setFieldValue("pay_by_invoice", state)}
                />
                  )
                : null}
            </Col>
            {formik.values.site.value === MARKET_PLACES.amazon
              ? (
              <Col>
                <FormGroup className="select2-container">
                  <Label>Account Type</Label>
                  <Select
                    name="acc_type"
                    value={{ value: accountType, label: startCase(accountType) }}
                    onChange={(option) => formik.setFieldValue("acc_type", option.value)}
                    options={PRIME_ACC_TYPES.reduce((acc, type, key) => {
                      acc.push({ label: startCase(type), value: type, key });
                      return acc;
                    }, [])}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
                )
              : null}
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { Credential } = state;
  return { Credential };
};

export default connect(mapStateToProps, {
  createCredentials,
  updateCredentials,
  setAddCredentialsModal,
  setEditCredentialsModal,
})(CredentialsModal);
