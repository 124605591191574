import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardHeader, Container, Alert, FormGroup, Col, Nav, NavItem, NavLink } from "reactstrap";
import Select from "react-select";
import { useSelector, connect } from "react-redux";
import queryString from "query-string";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PillBtn from "../../components/Common/PillBtn";
import CustomPagination from "../../components/Common/CustomPagination";
import { ConfirmDialogBtn, ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import { getUserInfo, isAgency, RenderIf, toPascalCase } from "../../utils/common";
import { BRAND_OPTIONS } from "../../constants/index";

// actions
import {
  fetchRestrictedItems,
  setPreloader,
  setSelectedItems,
  bulkUpdateItems,
  setItemsBulkUpdateModal,
  setAddItemModal,
  setBulkAddItemModal,
  setEditAddItemModal,
  deleteItem,
} from "../../store/actions";
import classnames from "classnames";
import { compact } from "lodash";
import BulkItemsUpdateModal from "./Components/BulkItemsUpdateModal";
import RestrictedItemModal from "./Components/RestrictedItemModal";

const itemDesccription = {
  manual: {
    title: "We have confirmed that this item issues IP complaints and/or Cease & Desist letters to sellers.",
    class: "text-danger",
  },
  scraped: {
    title: "This item has reported to give IP complaints by other sources.",
    class: "text-warning",
  },
  system: {
    title:
      "This item has been restricted by the marketplace for your account. Continuing to list these items could eventually lead to suspension.",
    class: "text-info",
  },
};

const selectTypes = {
  current_page: "current_page",
  all_selection: "all_selection",
};

const RestrictedItems = (props) => {
  const [bulkSelectType, setBulkSelectType] = useState(selectTypes.current_page);
  const [selectedItem, setSelectedItem] = useState();
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const queryParameters = queryString.parse(props.location.search);
  const searchRef = useRef(null);
  const { accounts, loading: AccountLoading } = useSelector((state) => state.MarketPlaceAccount);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Walmart",
      id: 0,
      platform: "walmart",
    },
    {
      title: "Amazon",
      id: 1,
      platform: "amazon",
    },
  ];

  const [search, setSearch] = useState({
    field: "item_identifier",
    operator: "like",
    value: "",
  });

  const marketplaceAccounts = new Set(
    accounts?.data.reduce((acc, account) => {
      if (account.valid && account.enabled) acc.push(account.marketplace);
      return acc;
    }, []),
  );

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [filters, setFilters] = useState({
    page_number: queryParameters?.page || 1,
    per_page: 30,
    platform: tabs[activeTab].platform,
    filters_hash: {
      filters: [],
    },
  });

  const tableHeaders = compact([
    { title: "Item Identifier" },
    !isAgency() && { title: "Source" },
    { title: "Restricted" },
    { title: "Action" },
  ]);

  const RestrictedItemIds = compact(props?.RestrictedItems?.restrictedItems?.results?.map((x) => x.id));

  function selectAllItems () {
    props.setSelectedItems(RestrictedItemIds?.map((item) => item));
  }

  function handleUpdateBulkItems () {
    props.setItemsBulkUpdateModal(true);
  }

  function handleDeleteBulkItems (item) {
    props.deleteItem({
      data: {
        va_id: getUserInfo().id,
        platform: tabs[activeTab].platform,
      },
      id: Array.isArray(item) ? item : isDeleteAll ? [] : props.RestrictedItems.selectedItems,
    });
    props.setSelectedItems([]);
  }

  useEffect(() => {
    props.setSelectedItems([]);
  }, [filters.page_number]);

  useEffect(() => {
    props.setPreloader(props.RestrictedItems.loading);
  }, [props.RestrictedItems.loading]);

  useEffect(() => {
    if (!props.RestrictedItems.loading && (accounts?.data || isAgency())) {
      if (marketplaceAccounts.size === 1 && marketplaceAccounts.has("amazon")) {
        setActiveTab(1);
        props.fetchRestrictedItems({ ...filters, platform: tabs[1].platform });
        setFilters({ ...filters, platform: tabs[1].platform });
      } else {
        props.fetchRestrictedItems({
          ...filters,
          platform: tabs[activeTab].platform,
        });
        setFilters({ ...filters, platform: tabs[activeTab].platform });
      }
    }
  }, [filters, accounts]);

  return (
    <React.Fragment>
      {props.RestrictedItems.itemsBulkUpdateModalIsOpen && (
        <BulkItemsUpdateModal platform={tabs[activeTab].platform} selectType={bulkSelectType} />
      )}
      {props.RestrictedItems.addItemModalIsOpen && (
        <RestrictedItemModal platform={tabs[activeTab].platform} type="add" />
      )}

      <RenderIf isTrue={props.RestrictedItems.bulkAddItemModalIsOpen}>
        <RestrictedItemModal platform={tabs[activeTab].platform} type="bulkAdd" />
      </RenderIf>

      {props.RestrictedItems.editItemModalIsOpen && (
        <RestrictedItemModal platform={tabs[activeTab].platform} selectedItem={selectedItem} type="edit" />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Restricted Items"
            children={
              <div className="d-flex flex-row">
                {props.RestrictedItems.selectedItems?.length > 0 && (
                  <>
                    <RenderIf isTrue={isAgency()}>
                      <ConfirmDialogBtn
                        button={{
                          title: "Bulk Delete",
                          icon: "bx bx-xs bx-trash",
                          color: "danger",
                        }}
                        msg={<p>Delete All Selected Items</p>}
                        onConfirm={handleDeleteBulkItems}
                      />
                    </RenderIf>
                    <PillBtn
                      color="warning"
                      title="Bulk Update"
                      name="Bulk Update"
                      icon="bx bx-xs bx-edit"
                      className="mr-1"
                      onClick={handleUpdateBulkItems}
                    />
                  </>
                )}
                <RenderIf isTrue={isAgency()}>
                  <div className="d-flex">
                    <a
                      className="mr-1 btn btn-primary waves-effect waves-light"
                      href={"/restricted_items_sample.csv"}
                      download
                    >
                      Download Sample File
                    </a>
                  </div>
                  <PillBtn
                    className="mr-1"
                    color="info"
                    title="Add items in bulk"
                    name="Bulk Add"
                    icon="bx bx-xs bx-plus"
                    onClick={() => props.setBulkAddItemModal(true)}
                  />
                </RenderIf>
                <PillBtn
                  color="info"
                  title="Add new item"
                  name="Add"
                  icon="bx bx-xs bx-plus"
                  onClick={() => props.setAddItemModal(true)}
                />
              </div>
            }
          />

          {props.RestrictedItems?.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.RestrictedItems?.success || ""}
              </Alert>
            </div>
          )}

          {props.RestrictedItems.error && (
            <div className="auto-hide">
              <Alert color="danger" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.RestrictedItems.error || ""}
              </Alert>
            </div>
          )}

          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setFilters({
                      ...filters,
                      page_number: 1,
                      page: 1,
                      filters_hash: {
                        filters: [
                          ...filters.filters_hash.filters.filter((x) => x.field !== "name"),
                          {
                            field: search.field,
                            operator: search.operator,
                            value: search.value.trim(),
                          },
                        ],
                      },
                    });
                  }}
                >
                  <div className="position-relative" style={{ display: "inline-flex" }}>
                    <input
                      id="search_field"
                      type="text"
                      ref={searchRef}
                      className="form-control"
                      placeholder="Search by Item"
                      value={search.value}
                      onChange={(e) => {
                        if (!e.target.value.trim()) {
                          setSearch({
                            ...search,
                            value: "",
                          });
                          setFilters({
                            ...filters,
                            page_number: 1,
                            filters_hash: {
                              filters: [
                                ...filters.filters_hash.filters.filter((x) => x.field !== "name"),
                                {
                                  field: search.field,
                                  operator: search.operator,
                                  value: "",
                                },
                              ],
                            },
                          });
                        } else {
                          setSearch({
                            ...search,
                            value: e.target.value,
                          });
                        }
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
                <div className="d-flex">
                  <FormGroup className="select2-container mt-3 mr-2" style={{ minWidth: "200px" }}>
                    <Select
                      name="blacklist"
                      value={{
                        value: filters.filters_hash.filters?.find((x) => x.field === "blacklist")?.value || "",
                        label: filters.filters_hash.filters?.find((x) => x.field === "blacklist")?.label || "All Items",
                      }}
                      onChange={(e) => {
                        const index = filters.filters_hash.filters?.findIndex((x) => x.field === "blacklist");
                        if (index !== -1) {
                          filters.filters_hash.filters[index].value = e.value;
                          filters.filters_hash.filters[index].label = e.label;
                          setFilters({ ...filters, page_number: 1 });
                        } else {
                          setFilters({
                            ...filters,
                            page_number: 1,
                            filters_hash: {
                              filters: [
                                ...filters.filters_hash.filters,
                                {
                                  field: "blacklist",
                                  operator: "=",
                                  value: e.value,
                                  label: e.label,
                                },
                              ],
                            },
                          });
                        }
                      }}
                      options={Object.keys(BRAND_OPTIONS).map((item, i) => ({
                        label: toPascalCase(item),
                        value: BRAND_OPTIONS[item],
                        key: i,
                      }))}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <RenderIf isTrue={!isAgency()}>
                    <FormGroup className="select2-container mt-3" style={{ minWidth: "200px" }}>
                      <Select
                        name="source"
                        value={{
                          value: filters.filters_hash.filters?.find((x) => x.field === "source")?.value || "",
                          label:
                            filters.filters_hash.filters?.find((x) => x.field === "source")?.label || "Select a Source",
                        }}
                        onChange={(e) => {
                          if (e.value !== "") {
                            const index = filters.filters_hash.filters?.findIndex((x) => x.field === "source");
                            if (index !== -1) {
                              filters.filters_hash.filters[index].value = e.value;
                              filters.filters_hash.filters[index].label = e.label;
                              setFilters({ ...filters, page_number: 1 });
                            } else {
                              setFilters({
                                ...filters,
                                page_number: 1,
                                filters_hash: {
                                  filters: [
                                    ...filters.filters_hash.filters,
                                    {
                                      field: "source",
                                      operator: "=",
                                      value: e.value,
                                      label: e.label,
                                    },
                                  ],
                                },
                              });
                            }
                          }
                        }}
                        options={Object.keys(props.RestrictedItems.restrictedItems?.sources || {}).map((key, i) => ({
                          label: toPascalCase(key),
                          value: props.RestrictedItems.restrictedItems.sources[key],
                          key: i,
                        }))}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </RenderIf>
                </div>
              </div>
            </CardHeader>

            <Col lg={12}>
              <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {tabs.map((tab, index) => (
                    <React.Fragment key={index}>
                      <RenderIf isTrue={marketplaceAccounts.has(tab.platform) || isAgency()}>
                        <NavItem key={`tab-${index}`}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === index,
                            })}
                            onClick={() => {
                              if (activeTab !== index) {
                                props.setSelectedItems([]);
                                toggleActiveTab(index);
                                setFilters({
                                  page_number: 1,
                                  ...filters,
                                  platform: tab.platform,
                                });
                              }
                            }}
                          >
                            <span className="d-none d-sm-block">{tab.title}</span>
                          </NavLink>
                        </NavItem>
                      </RenderIf>
                    </React.Fragment>
                  ))}
                </Nav>
              </CardBody>
            </Col>
            {props.RestrictedItems.selectedItems?.length
              ? (
              <div className="blockquote-warning">
                <h6>
                  {bulkSelectType === selectTypes.current_page
                    ? props.RestrictedItems.selectedItems?.length
                    : props.RestrictedItems.restrictedItems?.total_count}{" "}
                  items selected {bulkSelectType === selectTypes.current_page ? "on current page" : "for all pages"}
                </h6>
                {bulkSelectType === selectTypes.all_selection && props.RestrictedItems.selectedItems?.length
                  ? (
                  <h6
                    className="cursor-pointer"
                    onClick={() => {
                      setBulkSelectType(selectTypes.current_page);
                      props.setSelectedItems([]);
                    }}
                  >
                    Deselect All {props.RestrictedItems.restrictedItems?.total_count}
                  </h6>
                    )
                  : (
                  <h6
                    className="cursor-pointer"
                    onClick={() => {
                      setBulkSelectType(selectTypes.all_selection);
                      selectAllItems();
                      setIsDeleteAll(true);
                    }}
                  >
                    Select All {props.RestrictedItems.restrictedItems?.total_count} filtered items
                  </h6>
                    )}
              </div>
                )
              : null}
            <CardBody>
              {!props.RestrictedItems.restrictedItems?.results?.length &&
                !props.RestrictedItems.loading &&
                !AccountLoading && <h2 className="text-center">No Records Found</h2>}
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {props.RestrictedItems.restrictedItems?.results?.length
                        ? (
                        <th className="input-width">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={
                                RestrictedItemIds?.length > 0 &&
                                RestrictedItemIds?.length === props.RestrictedItems.selectedItems?.length
                              }
                              onChange={() => false}
                            />
                            <label
                              className="custom-control-label"
                              onClick={(e) => {
                                if (RestrictedItemIds?.length === props.RestrictedItems.selectedItems?.length) {
                                  setBulkSelectType(selectTypes.current_page);
                                  props.setSelectedItems([]);
                                } else {
                                  selectAllItems();
                                }
                              }}
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th>
                          )
                        : null}
                      {/* table headers */}
                      {props.RestrictedItems.restrictedItems?.results?.length
                        ? tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`} onClick={header.onClick}>
                              {header.title}
                              {header.sort
                                ? (
                                <div className="d-inline ml-1">
                                  <i id="up-filter" className="dripicons-arrow-thin-up cursor-pointer"></i>
                                  <i id="down-filter" className="dripicons-arrow-thin-down cursor-pointer"></i>
                                </div>
                                  )
                                : (
                                    ""
                                  )}
                            </th>
                        ))
                        : null}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {props.RestrictedItems.restrictedItems?.results?.map((item, key) => (
                      <tr key={"_items_" + key} className="mt-3 mb-3">
                        <td>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={!!props.RestrictedItems.selectedItems?.find((x) => x === item?.id)}
                              onChange={() => false}
                            />
                            <label
                              className="custom-control-label mt-2"
                              onClick={() => {
                                setBulkSelectType(selectTypes.current_page);
                                if (props.RestrictedItems.selectedItems?.find((x) => x === item?.id)) {
                                  props.setSelectedItems(
                                    props.RestrictedItems.selectedItems?.filter((x) => item.id !== x),
                                  );
                                } else {
                                  props.setSelectedItems([...props.RestrictedItems.selectedItems, item.id]);
                                }
                              }}
                            ></label>
                          </div>
                        </td>
                        <td style={{ userSelect: "none" }}>{item?.item_identifier}</td>
                        <RenderIf isTrue={!isAgency()}>
                          <td>{itemDescriptionIcon(item?.source)}</td>
                        </RenderIf>
                        <td>
                          <div className="custom-control custom-switch cursor-pointer">
                            <input
                              type="checkbox"
                              name="skip_item_restriction"
                              className="custom-control-input"
                              checked={!!item?.blacklist}
                              onChange={() => null}
                            />
                            <label
                              onClick={() => {
                                props.bulkUpdateItems({
                                  va_id: getUserInfo().id,
                                  platform: tabs[activeTab].platform,
                                  blacklist: !item?.blacklist,
                                  update_type: selectTypes.current_page,
                                  item_identifiers: [item.id],
                                });
                              }}
                              className="custom-control-label"
                            />
                          </div>
                        </td>
                        <td>
                          <RenderIf isTrue={!isAgency()}>
                            <i
                              title="Edit"
                              className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                              onClick={() => {
                                setSelectedItem(item);
                                props.setEditAddItemModal(true);
                                deleteItem();
                              }}
                            />
                          </RenderIf>
                          <ConfirmDialogIcon
                            icon={{
                              title: "Delete Account",
                              className: "bx bx-sm text-danger bx-trash",
                            }}
                            msg={<p>To Delete Item</p>}
                            onConfirm={() => handleDeleteBulkItems([item.id])}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {props.RestrictedItems?.restrictedItems?.total_count &&
              props.RestrictedItems.restrictedItems?.results?.length
                ? (
                <CustomPagination
                  total={props.RestrictedItems?.restrictedItems?.total_count}
                  page={props.RestrictedItems.restrictedItems.page_number}
                  perPage={props.RestrictedItems.restrictedItems.per_page}
                  tabsFilter={filters}
                  setTabFilter={setFilters}
                  pageOptions={[30, 100]}
                  matching={true}
                />
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

function itemDescriptionIcon (source) {
  const item = itemDesccription[source] || itemDesccription.manual;
  return <i title={item.title} className={"bx bx-sm bx-info-circle cursor-pointer " + item.class}></i>;
}

const mapStatetoProps = (state) => {
  const { RestrictedItems } = state;
  return { RestrictedItems };
};

export default connect(mapStatetoProps, {
  fetchRestrictedItems,
  setSelectedItems,
  setItemsBulkUpdateModal,
  bulkUpdateItems,
  setPreloader,
  setAddItemModal,
  setBulkAddItemModal,
  setEditAddItemModal,
  deleteItem,
})(RestrictedItems);
