import { Ability } from "@casl/ability";
import { merge, values } from "lodash";
import * as types from "./actionTypes";
const {
  FETCH_TOKEN,
  FETCH_TOKEN_SUCCESS,
  UPDATE_ACCOUNT_STATE,
  FETCH_ACCOUNT_SETTINGS,
  FETCH_ACCOUNT_SETTINGS_SUCCESS,
  FETCH_AGENCIES_SUCCESS,
  FETCH_ABILITIES,
  FETCH_ABILITIES_SUCCESS,
  SET_ADD_AGENCY_MODAL,
  ADD_AGENCY_CHILD_ACCOUNT,
  ADD_AGENCY_CHILD_ACCOUNT_SUCCESS,
  INVITE_TO_AGENCY,
  INVITE_TO_AGENCY_SUCCESS,
  VERIFY_AGENCY_INVITE,
  VERIFY_AGENCY_INVITE_SUCCESS,
  SET_VERIFY_ERROR,
  UNLINK_AGENCY,
  UNLINK_AGENCY_SUCCESS,
  FETCH_ALL_ACCOUNT_SETTINGS_SUCCESS,
  FETCH_AO_BOT_STATUS_SUCCESS,
  UPDATE_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  SET_SELECTED_TENAT_ACC,
  SET_EDIT_TENAT_ACC_MODAL,
  SET_CANCEL_FEEDBACK_MODAL,
  RESET_AVAILABLE_MATCHES,
  SUBMIT_CANCEL_FEEDBACK,
  SUBMIT_CANCEL_FEEDBACK_SUCCESS,
  UPDATE_ONBORDING_FLAG,
  CHECK_FOR_AO_SERVICE_UPDATE,
  CHECK_FOR_AO_SERVICE_UPDATE_SUCCESS,
  FETCH_PROXY_TRACKING_SERVICES,
  FETCH_PROXY_TRACKING_SERVICES_SUCCESS,
  FETCH_AVAILABLE_MATCH_COUNTS,
  FETCH_AVAILABLE_MATCH_COUNTS_SUCCESS,
  SUBMIT_MATCHES_REQUEST,
  SUBMIT_MATCHES_REQUEST_SUCCESS,
  API_ERROR,
  FETCH_CANCELLED_SUBSCRIPTIONS,
  FETCH_CANCELLED_SUBSCRIPTIONS_SUCCESS,
  SET_RETURN_ADDRESS_MODAL,
  SET_SETTING_MESSAGE,
  FETCH_PROXIES,
  FETCH_PROXIES_SUCCESS,
  FETCH_ACTIVITY_LOGS,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  SET_TRIGGERS_MODAL,
  FETCH_STORE_WISE_STATS,
  FETCH_STORE_WISE_STATS_SUCCESS,
  FETCH_TENANT_WISE_STATS,
  FETCH_TENANT_WISE_STATS_SUCCESS,
  UPDATE_AGENCY_PROFIT,
  UPDATE_AGENCY_PROFIT_SUCCESS,
  SET_AGENCY_PROFIT_MODAL,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION,
  SET_SHOW_ONBOARDING,
  SET_ONBOARDING_STEP,
  SET_ONBOARDING_FLOW,
  FILTER_ALL_TENANTS,
  FILTER_ALL_TENANTS_SUCCESS,
  GET_CANCELED_USERS,
  GET_CANCELED_USERS_SUCCESS,
  GET_PAYMENT_FAILED_USERS,
  GET_PAYMENT_FAILED_USERS_SUCCESS,
  GET_STATUSES_COUNT,
  GET_STATUSES_COUNT_SUCCESS,
  GET_DROPDOWN_DATA,
  GET_DROPDOWN_DATA_SUCCESS,
  GET_AGENCY_DROPDOWN_DATA,
  GET_AGENCY_DROPDOWN_DATA_SUCCESS,
  FETCH_MATCHES_REQUESTS_STATUS_SUCCESS,
  SKIP_ONBOARD_AGENCY,
  SET_DASHBOARD_PDF_OPTS,
  SET_ADD_LABEL_GENERATION_SERVICE,
  ADD_STAMP_CREDENTIALS,
  ADD_STAMP_CREDENTIALS_SUCCESS,
  FETCH_STAMPS_CREDS,
  FETCH_STAMPS_CREDS_SUCCESS,
  DELETE_STAMPS_CREDS,
  ENABLE_STAMPS_CREDS,
} = types;

const initialState = {
  error: "",
  success: "",
  stampsSuccess: "",
  account: {},
  allTenants: null,
  agencies: [],
  abilities: [],
  proxyServices: { data: [] },
  notifications: [],
  selectedTenantAcc: null,
  isEditTenantModalOpen: false,
  isAddAgencyModalOpen: false,
  isLabelGenerationModalOpen: false,
  addStampsCredsSuccess: {},
  fetchStampsCredsData: null,
  isCancelFeedbackModalOpen: false,
  verifyError: "",
  verifySuccess: "",
  agencyProfitModalIsOpen: false,
  hasNotifications: false,
  loading: false,
  dropDownLoading: false,
  accountsLoading: false,
  uploadTracking: "",
  showOnboarding: false,
  onBoardingStep: 0,
  OnboardingFlow: false,
  filteredTenants: [],
  paymentFailed: [],
  canceledUsers: [],
  statusesCountData: [],
  dropdownData: [],
  hasDropDownData: false,
  agencyDropDownData: [],
  fulfillRequestServiceStatus: "",
  skipAgencyOnboard: false,
  addRepricerStore: false,
  pdfOpts: { isPLModal: false, pdfLoader: false, pdfDivStyle: "none" },
  tenantsData: {},
};

const AccountSettings = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_PDF_OPTS:
      state = { ...state, pdfOpts: merge(state.pdfOpts, action.payload) };
      break;
    case FETCH_TOKEN:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_TOKEN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
      };
      break;
    case types.REFRESH_AUTH_TOKEN:
      state = { ...state, refreshTokenLoading: true };
      break;
    case types.REFRESH_AUTH_TOKEN_SUCCESS:
      state = { ...state, refreshTokenLoading: false };
      break;
    case FILTER_ALL_TENANTS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FILTER_ALL_TENANTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        filteredTenants: action.payload,
      };
      break;
    case GET_PAYMENT_FAILED_USERS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case GET_PAYMENT_FAILED_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        paymentFailed: action.payload,
      };
      break;
    case SKIP_ONBOARD_AGENCY:
      state = {
        ...state,
        skipAgencyOnboard: action.payload,
      };
      break;
    case GET_CANCELED_USERS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case GET_CANCELED_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        canceledUsers: action.payload,
      };
      break;
    case GET_STATUSES_COUNT:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case GET_STATUSES_COUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        statusesCountData: action.payload,
      };
      break;
    case GET_DROPDOWN_DATA:
      state = {
        ...state,
        success: "",
        error: "",
        dropDownLoading: true,
      };
      break;
    case GET_DROPDOWN_DATA_SUCCESS:
      state = {
        ...state,
        dropDownLoading: false,
        dropdownData: action.payload,
        hasDropDownData: !!action.payload.count,
      };
      break;
    case GET_AGENCY_DROPDOWN_DATA:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case GET_AGENCY_DROPDOWN_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        agencyDropDownData: action.payload,
      };
      break;
    case FETCH_ACTIVITY_LOGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_ACTIVITY_LOGS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        activityLogs: action.payload,
      };
      break;
    case FETCH_STORE_WISE_STATS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_STORE_WISE_STATS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        storeWiseStats: action.payload,
      };
      break;
    case FETCH_TENANT_WISE_STATS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_TENANT_WISE_STATS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: false,
        tenantWiseStats: action.payload,
      };
      break;
    case SET_TRIGGERS_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        isTriggerModal: action.payload,
      };
      break;

    case UPDATE_ACCOUNT_STATE:
      state = { ...state, account: { ...state.account, data: action.payload } };
      break;
    case FETCH_ACCOUNT_SETTINGS:
      state = {
        ...state,
        success: "",
        error: "",
        accountsLoading: true,
      };
      break;
    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
      state = {
        ...state,
        accountsLoading: false,
        error: "",
        success: "",
        account: action.payload,
      };
      break;
    case FETCH_AVAILABLE_MATCH_COUNTS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_AVAILABLE_MATCH_COUNTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        availableMatches: action.payload,
      };
      break;
    case SUBMIT_MATCHES_REQUEST:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case SUBMIT_MATCHES_REQUEST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Matches Request Submitted Successfully",
      };
      break;
    case FETCH_ABILITIES:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_ABILITIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        abilities: new Ability().update(action.payload),
      };
      break;
    case FETCH_PROXIES:
      state = {
        ...state,
        proxyLoading: true,
        success: "",
        error: "",
      };
      break;
    case FETCH_PROXIES_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        proxyLoading: false,
        allProxies: action.payload,
      };
      break;
    case FETCH_ALL_ACCOUNT_SETTINGS_SUCCESS:
      state = {
        ...state,
        accountsLoading: false,
        error: "",
        success: "",
        allTenants: action.payload,
        tenantsData: action.payload.reduce((obj, acc) => {
          obj[acc.id] = acc;
          return obj;
        }, {}),
      };
      break;
    case SET_RETURN_ADDRESS_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        returnAddressModal: action.payload,
      };
      break;
    case SET_SETTING_MESSAGE:
      state = {
        ...state,
        success: "",
        error: "",
        ...action.payload,
      };
      break;
    case FETCH_AGENCIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        agencies: action.payload,
      };
      break;
    case SET_ADD_AGENCY_MODAL:
      state = {
        ...state,
        success: "",
        error: "",
        isAddAgencyModalOpen: action.payload,
        loading: false,
      };
      break;
    case SET_ADD_LABEL_GENERATION_SERVICE:
      state = {
        ...state,
        success: "",
        error: "",
        isLabelGenerationModalOpen: action.payload,
        loading: false,
      };
      break;
    case ADD_STAMP_CREDENTIALS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_STAMP_CREDENTIALS_SUCCESS:
      state = {
        ...state,
        stampsSuccess: action.payload.success ? action.payload.message : "",
        error: action.payload.success ? "" : action.payload.message,
        addStampsCredsSuccessdata: action.payload,
        loading: false,
      };
      break;
    case FETCH_STAMPS_CREDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_STAMPS_CREDS_SUCCESS:
      state = {
        ...state,
        error: "",
        stampsSuccess: "",
        success: "",
        loading: false,
        fetchStampsCredsData: action.payload,
      };
      break;
    case DELETE_STAMPS_CREDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ENABLE_STAMPS_CREDS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_AGENCY_CHILD_ACCOUNT:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ADD_AGENCY_CHILD_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Invite has been Sent Successfully to Client",
        loading: false,
      };
      break;
    case INVITE_TO_AGENCY:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case INVITE_TO_AGENCY_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Invite has been Sent Successfully to Agency Owner",
        loading: false,
      };
      break;
    case VERIFY_AGENCY_INVITE:
      state = {
        ...state,
        verifySuccess: "",
        verifyError: "",
        loading: true,
      };
      break;
    case VERIFY_AGENCY_INVITE_SUCCESS:
      state = {
        ...state,
        verifySuccess: "Agency verified",
        verifyError: "",
        loading: false,
      };
      break;
    case SET_VERIFY_ERROR:
      state = {
        ...state.payload,
        verifyError: action.payload,
      };
      break;
    case UNLINK_AGENCY:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UNLINK_AGENCY_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Agency Unlinked Successfully",
        loading: false,
      };
      break;
    case FETCH_AO_BOT_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        aoStatus: action.payload.ao_running,
        aoStatusNote: action.payload.note,
        aoCredsPresent: action.payload.creds_exists,
      };
      break;
    case FETCH_MATCHES_REQUESTS_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        fulfillRequestServiceStatus: action.payload.serviceResponse.finalState,
        matchesRequestsNote: action.payload.serviceResponse.finalState
          ? "Fulfill matches service is running"
          : "Service is stopped for dev purposes",
      };
      break;
    case UPDATE_ACCOUNT_SETTINGS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_ACCOUNT_SETTINGS_SUCCESS:
      state = {
        ...state,
        success: "Update successful",
        error: "",
        loading: false,
        returnAddressModal: false,
        isTriggerModal: false,
      };
      break;
    case FETCH_CANCELLED_SUBSCRIPTIONS:
      state = {
        ...state,
        success: "",
        error: "",
        subLoading: true,
      };
      break;
    case FETCH_CANCELLED_SUBSCRIPTIONS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        subLoading: false,
        cancelledSubscriptions: action.payload,
      };
      break;
    case SET_SELECTED_TENAT_ACC:
      state = {
        ...state,
        success: "",
        error: "",
        selectedTenantAcc: action.payload,
      };
      break;
    case SET_EDIT_TENAT_ACC_MODAL:
      state = {
        ...state,
        isEditTenantModalOpen: action.payload,
      };
      break;
    case SET_CANCEL_FEEDBACK_MODAL:
      state = {
        ...state,
        isCancelFeedbackModalOpen: action.payload,
      };
      break;
    case SUBMIT_CANCEL_FEEDBACK:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case SUBMIT_CANCEL_FEEDBACK_SUCCESS:
      state = {
        ...state,
        success: "Feedback Submitted Successfully",
        loading: false,
        isCancelFeedbackModalOpen: false,
      };
      break;
    case RESET_AVAILABLE_MATCHES:
      state = {
        ...state,
        availableMatches: undefined,
      };
      break;
    case UPDATE_ONBORDING_FLAG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECK_FOR_AO_SERVICE_UPDATE:
      state = {
        ...state,
        loading: true,
        aoOffReasons: [],
      };
      break;
    case CHECK_FOR_AO_SERVICE_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case FETCH_PROXY_TRACKING_SERVICES:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_PROXY_TRACKING_SERVICES_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        proxyServices: action.payload,
        loading: false,
      };
      break;
    case SET_AGENCY_PROFIT_MODAL:
      state = {
        ...state,
        agencyProfitModalIsOpen: action.payload,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case UPDATE_AGENCY_PROFIT:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case UPDATE_AGENCY_PROFIT_SUCCESS:
      state = {
        ...state,
        ...("override_clients" in action.payload
          ? { agencyProfitSuccess: "Agency Profit Updated Successfully." }
          : { success: "Agency Profit Updated Successfully." }),
        error: "",
        loading: false,
        agencyProfitModalIsOpen: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case FETCH_NOTIFICATIONS:
      state = {
        ...state,
        success: "",
        error: "",
        notifyLoading: true,
      };
      break;
    case FETCH_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        success: "",
        error: "",
        notifyLoading: false,
        notifications: action.payload.notifications,
        count: action.payload.count,
        hasNotifications: !!action.payload.count,
      };
      break;
    case CREATE_NOTIFICATION:
      state = {
        ...state,
        success: "",
        error: "",
        notifyLoading: true,
      };
      break;
    case CREATE_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        success: "Notification created successfully",
        error: "",
        notifyLoading: false,
      };
      break;
    case types.UPDATE_AGENCY_NAME:
      state = { ...state, acgencyNameUpdateloading: true, success: "", error: "" };
      break;
    case types.UPDATE_AGENCY_NAME_SUCCESS: {
      if (action.payload.type === "allTenantPage") {
        const testantsData = values(state.tenantsData);
        const bufferAgencyAccount = testantsData.find((x) => x.id === action.payload.res.data.id);
        merge(bufferAgencyAccount, action.payload.res.data);
        const bufferFilteredTenants = state.filteredTenants.data.find((x) => x.id === action.payload.res.data.id);
        merge(bufferFilteredTenants, action.payload.res.data);
      } else {
        const bufferAgencyDashboardData = state.agencies.data?.find((x) => x.id === action.payload.res.data.id);
        merge(bufferAgencyDashboardData, action.payload.res.data);
      }
      state = { ...state, acgencyNameUpdateloading: false, error: "", success: action.payload.res.message };
      break;
    }
    case DELETE_NOTIFICATION:
      state = {
        ...state,
        success: "",
        error: "",
      };
      break;
    case SET_SHOW_ONBOARDING:
      state = {
        ...state,
        showOnboarding: action.payload,
      };
      break;
    case SET_ONBOARDING_STEP:
      state = {
        ...state,
        onBoardingStep: action.payload,
      };
      break;
    case types.SET_ADD_REPRICER_STORE:
      state = { ...state, addRepricerStore: action.payload };
      break;
    case SET_ONBOARDING_FLOW:
      state = {
        ...state,
        OnboardingFlow: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default AccountSettings;
