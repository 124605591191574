import React from "react";
import { Modal as ReactModal } from "reactstrap";
import { connect } from "react-redux";

const Modal = (props) => {
  return (
    <ReactModal {...props} className={`rounded ${props.theme}`}>
      {props.children}
    </ReactModal>
  );
};

const mapStatetoProps = (state) => {
  return { theme: state.Layout.appTheme };
};

export default connect(mapStatetoProps, {})(Modal);
