export const FETCH_RETURNS = "FETCH_RETURNS";
export const FETCH_RETURNS_SUCCESS = "FETCH_RETURNS_SUCCESS";
export const UPDATE_RETURN = "UPDATE_RETURN";
export const UPDATE_RETURN_SUCCESS = "UPDATE_RETURN_SUCCESS";
export const SET_SELECTED_RETURN_ITEM = "SET_SELECTED_RETURN_ITEM";
export const MOVE_TO_INVENTORY = "MOVE_TO_INVENTORY";
export const MOVE_TO_INVENTORY_SUCCESS = "MOVE_TO_INVENTORY_SUCCESS";
export const SET_UPLOAD_IMAGES_MODAL = "SET_UPLOAD_IMAGES_MODAL";
export const UPLOAD_ISSUE_IMAGES = "UPLOAD_ISSUE_IMAGES";
export const UPLOAD_ISSUE_IMAGES_SUCCESS = "UPLOAD_ISSUE_IMAGES_SUCCESS";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const GET_IMAGES_URL = "GET_IMAGES_URL";
export const GET_IMAGES_URL_SUCCESS = "GET_IMAGES_URL_SUCCESS";
export const UPDATE_BULK_RETURNS = "UPDATE_BULK_RETURNS";
export const UPDATE_BULK_RETURNS_SUCCESS = "UPDATE_BULK_RETURNS_SUCCESS";
export const SET_FILTER_BAR = "SET_FILTER_BAR";
export const SET_ADD_RETURN_MODAL = "SET_ADD_RETURN_MODAL";
export const ADD_ITEM_TO_INVENTORY = "ADD_ITEM_TO_INVENTORY";
export const ADD_ITEM_TO_INVENTORY_SUCCESS = "ADD_ITEM_TO_INVENTORY_SUCCESS";
export const RESET_SUCCESS_ERROR_STATE = "RESET_SUCCESS_ERROR_STATE";
export const API_ERROR = "API_ERROR";
