import React, { useState } from "react";
import { HELP_DOCS_LINK, USER_ROLES } from "../../../../src/constants";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { first, upperCase } from "lodash";

// utils
import { currentUser, decode, jsonParse, getUserInfo, isWHStaff, RenderIf, isUserHaveFullAccess } from "../../../utils/common";

// i18n
import { withNamespaces } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// users
import { abilitiesFor } from "../../../utils/abilities";

const ProfileMenu = (props) => {
  const userAbility = abilitiesFor(getUserInfo()?.role);
  const [menu, setMenu] = useState(false);
  const authUser = localStorage.getItem("authTokenJwt");
  const [username] = useState(currentUser(authUser) || "Admin");
  const adminToken = localStorage.getItem("I6fiFamLVmL");

  const getColorForChar = (chars) => {
    const index = (chars.charCodeAt(0) - 65) * 13.84;
    return `hsla(${index * 15}, ${80}%, ${50}%, ${0.5})`;
  };

  const getInitials = (userObj) => {
    const email = userObj?.role === USER_ROLES.whStaff ? userObj?.email : userObj?.user_email;
    const [firstName, lastName] = userObj.first_name
      ? [userObj.first_name, userObj.last_name]
      : email?.split("@")?.[0]?.split(/[-_.]/);
    return [firstName, lastName].map((name) => upperCase(first(name))).join("");
  };

  const userInfo = getUserInfo();
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div
            style={{
              color: "white",
              backgroundColor: getColorForChar(getInitials(userInfo)),
            }}
            className="d-flex justify-content-center align-items-center rounded-circle header-profile-user"
          >
            {getInitials(userInfo)}
          </div>
          <span className="d-none d-xl-inline-block ml-2 mr-1 white-span">
            {(userInfo?.full_name || userInfo?.username || "").toUpperCase() ||
              (adminToken && jsonParse(decode(adminToken, 10))?.username?.toUpperCase()) ||
              username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <RenderIf isTrue={getUserInfo()?.user_email && userAbility.can("visit", "settings")}>
            <Link to="/settings?account=true" className="dropdown-item">
              <i className="bx bx-envelope font-size-16 align-middle mr-1"></i>
              <span>{getUserInfo()?.user_email}</span>
            </Link>
          </RenderIf>
          {[USER_ROLES.admin, USER_ROLES.owner].includes(getUserInfo()?.role) && isUserHaveFullAccess() && (
            <Link to="/notification_settings" className="dropdown-item">
              <i className="bx bxs-notification font-size-16 mr-1 align-middle"></i>
              <span> Notification Settings</span>
            </Link>
          )}
          {!isWHStaff() && isUserHaveFullAccess() && (
            <Link to="/profile_settings" className="dropdown-item">
              <i className="bx bxs-user-circle font-size-16 align-middle mr-1"></i>
              <span>Profile Settings</span>
            </Link>
          )}
          <RenderIf isTrue={[5655].includes(getUserInfo()?.id) || [USER_ROLES.admin, USER_ROLES.owner].includes(getUserInfo()?.role)}>
            <Link to="/payment_methods" className="dropdown-item">
              <i className="bx bx-credit-card font-size-16 align-middle mr-1"></i>
              <span>Payment Methods</span>
            </Link>
          </RenderIf>
          {userAbility.can("visit", "registration_docs") && (
            <Link to={{ pathname: HELP_DOCS_LINK }} target="_blank" className="dropdown-item">
              <i className="bx bx bx-info-circle mr-1 font-size-16 align-middle"></i>
              <span>Help Docs</span>
            </Link>
          )}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withNamespaces()(ProfileMenu)));
