import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Alert } from "reactstrap";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomPagination from "../../components/Common/CustomPagination";
import PillBtn from "../../components/Common/PillBtn";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import AddSellerModal from "./Components/AddSellerModal";
// actions
import { fetchAmazonSellers, setPreloader, setAmazonSellersFilter, setAddAmazonSellerModal } from "../../store/actions";

const AmazonSellers = (props) => {
  const [chkboxBlacklisted, setChkboxBlacklisted] = useState(false);
  const [search, setSearch] = useState("");
  const [amazonSeller, setAmazonSeller] = useState();
  const filters = props.AmazonSellers.filters;
  const setFilters = (filters) => props.setAmazonSellersFilter(filters);

  const tableHeaders = [
    { title: "Seller Id", class: "ml-2" },
    { title: "Name" },
    { title: "Action", class: "text-center" },
    { title: "Feedback Url", class: "text-center" },
  ];

  function handleAddAmazonSeller () {
    setAmazonSeller(undefined);
    props.setAddAmazonSellerModal(true);
  }

  useEffect(() => {
    props.fetchAmazonSellers(filters);
  }, [filters]);

  useEffect(() => {
    props.setPreloader(props.AmazonSellers.loading);
  }, [props.AmazonSellers.loading]);

  return (
    <React.Fragment>
      {props.AmazonSellers.addAmazonSellerModalIsOpen && <AddSellerModal amazonSeller={amazonSeller} />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Amazon Sellers"
            children={
              <PillBtn
                color="success"
                title="Add Amazon Seller"
                name="Add Amazon Seller"
                icon="bx bx-xs bx-plus"
                className="col ml-2"
                onClick={handleAddAmazonSeller}
              />
            }
          />

          {props.AmazonSellers?.success && (
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {props.AmazonSellers?.success || ""}
              </Alert>
            </div>
          )}

          {!props.AmazonSellers.addAmazonSellerModalIsOpen && props.AmazonSellers?.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.AmazonSellers?.error || ""}
            </Alert>
          )}

          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setChkboxBlacklisted(false);
                    setFilters({
                      ...filters,
                      search: search.trim(),
                      is_blacklisted: "",
                      page: 1,
                    });
                  }}
                >
                  <div className="position-relative" style={{ display: "inline-flex" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setFilters({
                            ...filters,
                            search: "",
                          });
                          setSearch("");
                        } else {
                          setSearch(e.target.value);
                        }
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                    <div className="custom-control custom-checkbox ml-2 mt-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="blacklisted"
                        checked={chkboxBlacklisted}
                        onChange={() => {}}
                      />
                      <label
                        onClick={() => {
                          setFilters({
                            ...filters,
                            is_blacklisted: chkboxBlacklisted === true ? "" : true,
                          });
                          setChkboxBlacklisted(!chkboxBlacklisted);
                        }}
                        className="custom-control-label"
                      >
                        Blacklisted
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </CardHeader>

            <CardBody>
              {props.AmazonSellers?.sellers?.count
                ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          {tableHeaders.map((header, index) => (
                            <th key={`table-header ${index}`} className={header.class || ""}>
                              {header.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {props.AmazonSellers.sellers?.data?.map((item, key) => (
                          <tr key={"_sellers_" + key} className="mt-3 mb-3">
                            <td>
                              <span className="row  ml-2">
                                {item?.is_blacklisted && (
                                  <div className="d-flex align-items-center">
                                    <i title="Blocked" className="bx bx-sm bx-block text-danger pr-2" disabled></i>
                                  </div>
                                )}
                                {item?.id}
                                <CopyToClipBoard text={item?.id} />
                              </span>
                            </td>
                            <td>{item?.name}</td>
                            <td className="text-center">
                              <i
                                className={"cursor-pointer pl-2 text-warning cursor-pointer fas fa-lg fa-edit"}
                                onClick={() => {
                                  setAmazonSeller(item);
                                  props.setAddAmazonSellerModal(true);
                                }}
                              ></i>
                            </td>
                            <td className="text-center">
                              <a
                                href={`https://www.amazon.com/sp?seller=${item?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="bx bx-sm bx-link-external"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {props.AmazonSellers?.sellers && (
                    <CustomPagination
                      total={props.AmazonSellers?.sellers?.count}
                      page={props.AmazonSellers?.sellers?.page}
                      perPage={props.AmazonSellers?.sellers?.perPage}
                      tabsFilter={props.filters}
                      setTabFilter={props.setAmazonSellersFilter}
                    />
                  )}
                </>
                  )
                : null}
              {props.AmazonSellers?.sellers?.count === 0 && !props.AmazonSellers.loading
                ? (
                <div className="d-flex justify-content-center m-2">
                  <div className="d-flex flex-column">
                    <h1>No Records Found</h1>
                  </div>
                </div>
                  )
                : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { AmazonSellers } = state;
  return { AmazonSellers };
};

export default connect(mapStatetoProps, {
  fetchAmazonSellers,
  setAmazonSellersFilter,
  setAddAmazonSellerModal,
  setPreloader,
})(AmazonSellers);
