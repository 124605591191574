import { authenticator } from "otplib";
import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllPurchasingAccounts,
  createPurchasingAccount,
  updatePurchasingAccount,
  deletePurchasingAccount,
  assignPurchasingAccount,
  createCredential,
  updateCredential,
  unlockCredential,
  deleteCredential,
  createCreditCardTypes,
  bulkUpdateCredential,
  getBuyingEmails,
  getProxyManual,
  refreshAccountProxy,
  fetchTotp,
} from "../../api";
// Login Redux States
import {
  FETCH_PURCHASING_ACCOUNTS,
  FETCH_BUYING_EMAILS,
  CREATE_PURCHASING_ACCOUNTS,
  UPDATE_PURCHASING_ACCOUNTS,
  DELETE_PURCHASING_ACCOUNT,
  ADD_USERS_PURCHASING_ACCOUNT,
  CREATE_CREDENTIALS,
  UPDATE_CREDENTIALS,
  UNLOCK_CREDENTIAL,
  DELETE_CREDENTIAL,
  CREATE_CREDIT_CARD_TYPES,
  CREDENTIAL_BULK_UPDATE,
  ADD_PROXY_MANUAL,
  REFRESH_ACCOUNT_PROXY,
  FETCH_TOTP,
} from "./actionTypes";
import {
  apiError,
  setCredentialsFilter,
  setAddPurchasingAccountsModal,
  setEditPurchasingAccountsModal,
  setAddCredentialsModal,
  setEditCredentialsModal,
  setCreditCardTypesModal,
  setCredentialsBulkActionModal,
  fetchPurchasingAccountsSuccess,
  createPurchasingAccountsSuccess,
  updatePurchasingAccountsSuccess,
  deletePurchasingAccountSuccess,
  assignAccountToUsersSuccess,
  createCredentialsSuccess,
  updateCredentialsSuccess,
  unlockCredentialSuccess,
  deleteCredentialSuccess,
  createCreditCardTypesSuccess,
  bulkUpdateCredentialsSuccess,
  fetchBuyingEmailsSuccess,
  addProxyManualSuccess,
  refreshAccountProxySuccess,
  fetchPurchasingAccounts,
  fetchTotpSuccess,
} from "./actions";
import moment from "moment";
import { fetchCardTypes } from "../creditCard/actions";

const Credential = (state) => state.Credential;

function * addProxyManualSaga ({ payload }) {
  try {
    const res = yield call(getProxyManual, payload);
    if (res.success) yield put(fetchPurchasingAccounts());
    yield put(addProxyManualSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * refreshAccountProxySaga ({ payload }) {
  try {
    const res = yield call(refreshAccountProxy, payload);
    if (res.success) yield put(fetchPurchasingAccounts());
    yield put(refreshAccountProxySuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchPurchasingAccountsSaga () {
  try {
    const credentials = yield select(Credential);
    const res = yield call(getAllPurchasingAccounts, credentials.filters);
    yield put(fetchPurchasingAccountsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchBuyingEmailsSaga () {
  try {
    const res = yield call(getBuyingEmails);
    yield put(fetchBuyingEmailsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createPurchasingAccountsSaga ({ payload: { data } }) {
  try {
    const res = yield call(createPurchasingAccount, { data });
    if (res?.data) {
      const credentials = yield select(Credential);
      credentials.accounts = [...credentials.accounts, res?.data];
      yield put(setAddPurchasingAccountsModal(false));
    }
    yield put(createPurchasingAccountsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updatePurchasingAccountsSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updatePurchasingAccount, { data, id });
    if (res?.data) {
      const credentials = yield select(Credential);
      const index = credentials.accounts.findIndex((account) => account.id === res.data.id);
      credentials.accounts[index] = {
        ...credentials.accounts[index],
        ...res.data,
      };
      yield put(setEditPurchasingAccountsModal(false));
    }
    yield put(updatePurchasingAccountsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deletePurchasingAccountSaga ({ payload: { id } }) {
  try {
    const { data } = yield call(deletePurchasingAccount, { id });
    if (data) {
      const credentials = yield select(Credential);
      credentials.accounts = credentials.accounts.filter((account) => account.id !== data.id);
    }
    yield put(deletePurchasingAccountSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * assignPurchasingAccountSaga ({ payload: { data } }) {
  try {
    const res = yield call(assignPurchasingAccount, { data });
    if (res?.success) {
      const credentials = yield select(Credential);
      const accounts = yield call(getAllPurchasingAccounts, credentials.filters);
      yield put(fetchPurchasingAccountsSuccess(accounts));
    }
    yield put(setCreditCardTypesModal(false));
    yield put(assignAccountToUsersSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createCredentialsSaga ({ payload: { data } }) {
  try {
    const res = yield call(createCredential, { data });
    if (res?.credentials) {
      const credentials = yield select(Credential);
      const index = credentials.accounts.findIndex((account) => account.id === res.credentials.purchasing_account_id);
      credentials.accounts[index].credentials?.length
        ? credentials.accounts[index].credentials.push(res.credentials)
        : (credentials.accounts[index].credentials = [res.credentials]);

      yield put(setAddCredentialsModal(false));
    }
    yield put(createCredentialsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateCredentialsSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateCredential, { data, id });
    if (res?.credentials) {
      const credentials = yield select(Credential);
      const index = credentials.accounts.findIndex((account) => account.id === res.credentials.purchasing_account_id);
      const credentialsIndex = credentials.accounts[index].credentials.findIndex(
        (credential) => credential.id === res.credentials.id,
      );
      credentials.accounts[index].credentials[credentialsIndex] = {
        ...credentials.accounts[index].credentials[credentialsIndex],
        ...res.credentials,
      };
      yield put(setCredentialsFilter({ ...credentials.filters }));
      yield put(setEditCredentialsModal(false));
    }
    yield put(updateCredentialsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteCredentialsSaga ({ payload: { id } }) {
  try {
    const res = yield call(deleteCredential, { id });
    if (res?.credentials) {
      const credentials = yield select(Credential);
      const index = credentials.accounts.findIndex((account) => account.id === res.credentials.purchasing_account_id);
      credentials.accounts[index].credentials = credentials?.accounts[index].credentials?.filter(
        (credential) => credential.id !== res.credentials.id,
      );
    }
    yield put(deleteCredentialSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createCreditCardTypeSaga ({ payload: { data } }) {
  try {
    const res = yield call(createCreditCardTypes, { data });
    yield put(setCreditCardTypesModal(false));
    const credentials = yield select(Credential);
    const accounts = yield call(getAllPurchasingAccounts, credentials.filters);
    yield put(fetchPurchasingAccountsSuccess(accounts));
    yield put(createCreditCardTypesSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * bulkUpdateCredentialsSaga ({ payload: { data } }) {
  try {
    yield call(bulkUpdateCredential, { data });
    yield put(setCredentialsBulkActionModal(false));
    const credentials = yield select(Credential);
    const accounts = yield call(getAllPurchasingAccounts, credentials.filters);
    yield put(fetchPurchasingAccountsSuccess(accounts));
    if (data.items[0].is_custom) yield put(fetchCardTypes());
    yield put(bulkUpdateCredentialsSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * unlockCredentialsSaga ({ payload: { email, site } }) {
  try {
    yield call(unlockCredential, { email, site });
    const credentials = yield select(Credential);
    const accounts = yield call(getAllPurchasingAccounts, credentials.filters);
    yield put(fetchPurchasingAccountsSuccess(accounts));
    yield put(unlockCredentialSuccess({}));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchTotpSaga ({ payload }) {
  try {
    const res = yield call(fetchTotp, payload);
    const { totps } = yield select(Credential);
    totps[payload.username] = {
      totp: res?.["2fa_code"] || "",
      time: moment().add(authenticator.timeRemaining(), "seconds"),
    };
    yield put(fetchTotpSuccess(totps));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchAddProxyManual () {
  yield takeEvery(ADD_PROXY_MANUAL, addProxyManualSaga);
}

export function * watchRefreshAccountProxy () {
  yield takeEvery(REFRESH_ACCOUNT_PROXY, refreshAccountProxySaga);
}

export function * watchPurchasingAccountsFetch () {
  yield takeEvery(FETCH_PURCHASING_ACCOUNTS, fetchPurchasingAccountsSaga);
}

export function * watchBuyingEmailsFetch () {
  yield takeEvery(FETCH_BUYING_EMAILS, fetchBuyingEmailsSaga);
}

export function * watchPurchasingAccountsCreate () {
  yield takeEvery(CREATE_PURCHASING_ACCOUNTS, createPurchasingAccountsSaga);
}

export function * watchPurchasingAccountsUpdate () {
  yield takeEvery(UPDATE_PURCHASING_ACCOUNTS, updatePurchasingAccountsSaga);
}

export function * watchPurchasingAccountsDelete () {
  yield takeEvery(DELETE_PURCHASING_ACCOUNT, deletePurchasingAccountSaga);
}

export function * watchPurchasingAccountsAssign () {
  yield takeEvery(ADD_USERS_PURCHASING_ACCOUNT, assignPurchasingAccountSaga);
}

export function * watchCredentialsCreate () {
  yield takeEvery(CREATE_CREDENTIALS, createCredentialsSaga);
}

export function * watchCreditCardTypes () {
  yield takeEvery(CREATE_CREDIT_CARD_TYPES, createCreditCardTypeSaga);
}

export function * watchCredentialsUpdate () {
  yield takeEvery(UPDATE_CREDENTIALS, updateCredentialsSaga);
}

export function * watchFetchTotp () {
  yield takeEvery(FETCH_TOTP, fetchTotpSaga);
}

export function * watchCredentialsUnlock () {
  yield takeEvery(UNLOCK_CREDENTIAL, unlockCredentialsSaga);
}

export function * watchCredentialsDelete () {
  yield takeEvery(DELETE_CREDENTIAL, deleteCredentialsSaga);
}

export function * watchCredentialsBulkUpdate () {
  yield takeEvery(CREDENTIAL_BULK_UPDATE, bulkUpdateCredentialsSaga);
}

function * credentialsSaga () {
  yield all([
    fork(watchPurchasingAccountsFetch),
    fork(watchPurchasingAccountsCreate),
    fork(watchPurchasingAccountsUpdate),
    fork(watchPurchasingAccountsDelete),
    fork(watchPurchasingAccountsAssign),
    fork(watchCredentialsCreate),
    fork(watchCredentialsUpdate),
    fork(watchCredentialsDelete),
    fork(watchCreditCardTypes),
    fork(watchCredentialsUnlock),
    fork(watchCredentialsBulkUpdate),
    fork(watchBuyingEmailsFetch),
    fork(watchAddProxyManual),
    fork(watchRefreshAccountProxy),
    fork(watchFetchTotp),
  ]);
}

export default credentialsSaga;
