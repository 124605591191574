import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { fetchMatchesRequests, fetchMatchesRequestsByQuery, cancelMatchesReq } from "../../api"; // change later

import { FETCH_MATCHES_REQUEST, FETCH_MATCHES_REQUEST_QUERY, CANCEL_MATCHES_REQ } from "./actionTypes";

import {
  apiError,
  fetchMatchesRequestsSuccess,
  fetchRequestsbyQueurySuccess,
  cancelMatchesReqSuccess,
} from "./actions";

function * fetchMatchesRequestsSaga () {
  try {
    const res = yield call(fetchMatchesRequests);
    yield put(fetchMatchesRequestsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchRequestsbyQueurySaga ({ payload: { params } }) {
  try {
    const res = yield call(fetchMatchesRequestsByQuery, params);
    yield put(fetchRequestsbyQueurySuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * cancelMatchesReqSaga ({ payload: params }) {
  try {
    const res = yield call(cancelMatchesReq, params);
    yield put(cancelMatchesReqSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchMatchesRequests () {
  yield takeEvery(FETCH_MATCHES_REQUEST, fetchMatchesRequestsSaga);
}
export function * watchRequestsbyQueury () {
  yield takeEvery(FETCH_MATCHES_REQUEST_QUERY, fetchRequestsbyQueurySaga);
}

export function * watchCancelMatchesReq () {
  yield takeEvery(CANCEL_MATCHES_REQ, cancelMatchesReqSaga);
}

function * MatchesRequestsSaga () {
  yield all([fork(watchMatchesRequests), fork(watchRequestsbyQueury), fork(watchCancelMatchesReq)]);
}

export default MatchesRequestsSaga;
