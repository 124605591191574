import { last, uniq } from "lodash";
import React from "react";
import { Row, Col, Input, Label, Button } from "reactstrap";
import { EMAIL_REGEX } from "../../constants";

function DynamicFields (props) {
  const { title, isMulti, nestedFields, setNestedFields } = props;

  const handleAddRowNested = () => {
    const keyValuePairs = { name: "", value: "", error: [] };

    setNestedFields([...nestedFields, keyValuePairs]);
  };

  const handleRemoveRowNested = (e, idx) => {
    nestedFields.splice(idx, 1);
    setNestedFields([...nestedFields]);
  };

  return (
    <>
      <Label>{title}</Label>
      <table style={{ width: "95%" }}>
        <tbody>
          {nestedFields.map((item, idx) => (
            <React.Fragment key={"_dynamic_fields_" + idx}>
              <tr id={"nested" + idx} key={"_dynamic_fields_" + idx}>
                <td>
                  <Row className="mb-2">
                    <Col md={11}>
                      <Row>
                        {isMulti
                          ? (
                          <>
                            <Col md={6}>
                              <Input
                                type="text"
                                className="inner form-control"
                                placeholder={`${title} Name`}
                                value={item.name}
                                onBlur={() => {
                                  item.name && !item.value
                                    ? !item.error.some((x) => x.includes("Value")) &&
                                      item.error.push(`${title} Value should not be empty.`)
                                    : (item.error = item.error?.filter((x) => !x.includes("Value")));

                                  !item.name && item.value
                                    ? !item.error.some((x) => x.includes("Name")) &&
                                      item.error.push(`${title} Name should not be empty.`)
                                    : (item.error = item.error?.filter((x) => !x.includes("Name")));
                                  setNestedFields([...nestedFields]);
                                }}
                                onChange={(e) => {
                                  !e.target.value && item.value
                                    ? !item.error.some((x) => x.includes("Name")) &&
                                      item.error.push(`${title} Name should not be empty.`)
                                    : (item.error = item.error?.filter((x) => !x.includes("Name")));

                                  e.target.value &&
                                  nestedFields.some((x) => x.name.toLowerCase() === e.target.value.toLowerCase())
                                    ? item.error.push(`Duplicate ${title} Name.`)
                                    : (item.error = item.error?.filter((x) => !x.includes("Duplicate")));

                                  nestedFields[idx].name = e.target.value;
                                  setNestedFields([...nestedFields]);
                                }}
                              />
                            </Col>
                            <Col md={6}>
                              <Input
                                type="text"
                                className="inner form-control"
                                placeholder={`${title} Value`}
                                value={item.value}
                                onBlur={(e) => {
                                  item.name && !e.target.value
                                    ? !item.error.some((x) => x.includes("Value")) &&
                                      item.error.push(`${title} Value should not be empty.`)
                                    : (item.error = item.error?.filter((x) => !x.includes("Value")));

                                  !item.name && e.target.value
                                    ? !item.error.some((x) => x.includes("Name")) &&
                                      item.error.push(`${title} Name should not be empty.`)
                                    : (item.error = item.error?.filter((x) => !x.includes("Name")));

                                  setNestedFields([...nestedFields]);
                                }}
                                onChange={(e) => {
                                  nestedFields[idx].value = e.target.value;
                                  setNestedFields([...nestedFields]);
                                }}
                              />
                            </Col>
                          </>
                            )
                          : (
                          <Col md={12}>
                            <Input
                              type="text"
                              className="inner form-control"
                              placeholder={title}
                              value={item.name}
                              onBlur={() => {
                                if (!EMAIL_REGEX.test(item.name) && !item.error.length && item.name.length)
                                  item.error.push("Invalid Email Address");
                                else if (item.name.length === 0 || EMAIL_REGEX.test(item.name))
                                  item.error = item.error.filter((x) => x !== "Invalid Email Address");
                                setNestedFields([...nestedFields]);
                              }}
                              onChange={(e) => {
                                e.target.value &&
                                nestedFields.some((x) => x?.name.toLowerCase() === e.target.value.toLowerCase())
                                  ? item.error.push("Duplicate Email!")
                                  : (item.error = item.error?.filter((x) => !x.includes("Duplicate")));

                                nestedFields[idx].name = e.target.value;
                                setNestedFields([...nestedFields]);
                              }}
                            />
                          </Col>
                            )}
                      </Row>
                    </Col>
                    {idx > 0 && (
                      <Col md={1} className="mt-2">
                        <i
                          onClick={(e) => {
                            handleRemoveRowNested(e, idx);
                          }}
                          className="bx bx-x bx-sm text-warning cursor-pointer"
                        />
                      </Col>
                    )}
                  </Row>
                </td>
              </tr>
              {item?.error?.length
                ? (
                <tr>
                  <td>
                    <small className="text-danger">{uniq(item.error).map((x) => `${x}${" "}`)}</small>
                  </td>
                </tr>
                  )
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Button
        disabled={nestedFields.length > 0 && (!last(nestedFields)?.name || !last(nestedFields)?.value)}
        onClick={() => {
          handleAddRowNested();
        }}
        color="success"
        className="mt-1 mb-1 ml-1"
      >
        {`Add ${title}`}
      </Button>
    </>
  );
}

export default DynamicFields;
