import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Alert, Label } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { useFormik } from "formik";
// actions
import { bulkUpdateBrands, setBrandsBulkUpdateModal, setSelectedBrands } from "../../../store/actions";
import { getUserInfo } from "../../../utils/common";

const BulkBrandsUpdateModal = (props) => {
  const validate = (values) => {
    const errors = {};
    !values.status && (errors.status = "Status is requried");
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.bulkUpdateBrands({
        va_id: getUserInfo().id,
        platform: props.platform,
        blacklist: values.status,
        update_type: props.selectType,
        brand_ids: props.RestrictedBrands.selectedBrands,
      });
    },
  });

  const toggleModal = () => {
    props.setBrandsBulkUpdateModal(!props.RestrictedBrands.brandsBulkUpdateModalIsOpen);
    formik.resetForm();
  };

  return (
    <React.Fragment>
      <Modal size="sm" isOpen={props.RestrictedBrands.brandsBulkUpdateModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Update Brands</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {props.RestrictedBrands.error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {props.RestrictedBrands.error}
            </Alert>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg="12">
                <Label>Status</Label>
                <div className="form-group">
                  <select
                    name="status"
                    value={formik.values.status}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="custom-select"
                  >
                    <option value="">Select Status</option>
                    <option value="true">Restricted</option>
                    <option value="false">Not Restricted</option>
                  </select>
                </div>
                {formik.touched.status && formik.errors.status
                  ? (
                  <small className="text-danger">{formik.errors.status}</small>
                    )
                  : null}
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-3">
              <Button
                type="reset"
                color="secondary"
                className="mr-3"
                onClick={() => {
                  formik.handleReset();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              {
                <Button type="submit" color="success" className="mr-3">
                  Submit
                </Button>
              }
            </Row>
          </form>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { RestrictedBrands } = state;
  return { RestrictedBrands };
};

export default connect(mapStateToProps, {
  bulkUpdateBrands,
  setBrandsBulkUpdateModal,
  setSelectedBrands,
})(BulkBrandsUpdateModal);
