export const FETCH_AGENCY_STATS = "FETCH_AGENCY_STATS";
export const FETCH_AGENCY_STATS_SUCCESS = "FETCH_AGENCY_STATS_SUCCESS";
export const FETCH_AGENCY_CLIENTS = "FETCH_AGENCY_CLIENTS";
export const FETCH_AGENCY_CLIENTS_SUCCESS = "FETCH_AGENCY_CLIENTS_SUCCESS";
export const FETCH_AGENCY_CLIENTS_PROFIT = "FETCH_AGENCY_CLIENTS_PROFIT";
export const FETCH_AGENCY_CLIENTS_PROFIT_SUCCESS = "FETCH_AGENCY_CLIENTS_PROFIT_SUCCESS";
export const SET_ADD_CLIENT_MODAL = "SET_ADD_CLIENT_MODAL";
export const SET_EDIT_CLIENT_MODAL = "SET_EDIT_CLIENT_MODAL";
export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const REMOVE_CLIENT_SUCCESS = "REMOVE_CLIENT_SUCCESS";
export const UPDATE_PAYMENT_SOURCE_SETTINGS = "UPDATE_PAYMENT_SOURCE_SETTINGS";
export const UPDATE_PAYMENT_SOURCE_SETTINGS_SUCCESS = "UPDATE_PAYMENT_SOURCE_SETTINGS_SUCCESS";
export const API_ERROR = "AGENCY_API_ERROR";
export const FETCH_TENANTS_STATS = "FETCH_TENANTS_STATS";
export const FETCH_TENANTS_STATS_SUCCESS = "FETCH_TENANTS_STATS_SUCCESS";
export const FETCH_STOREFRONT_HEALTH_MATRICS = "FETCH_STOREFRONT_HEALTH_MATRICS";
export const FETCH_STOREFRONT_HEALTH_MATRICS_SUCCESS = "FETCH_STOREFRONT_HEALTH_MATRICS_SUCCESS";

export const ARCHIVE_CLIENT = "ARCHIVE_CLIENT";
export const ARCHIVE_CLIENT_SUCCESS = "ARCHIVE_CLIENT_SUCCESS";

export const SET_ARCHIVED = "SET_ARCHIVED";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
