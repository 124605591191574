import React from "react";
import { Card, CardBody, Media } from "reactstrap";
import { RenderIf } from "../../../utils/common";

const RepricerListingComparisonCard = ({
  title,
  labelLeft,
  labelRight,
  valueLeft,
  valueRight,
  icon,
  onClickLeft,
  onClickRight,
}) => {
  return (
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 d-flex align-items-stretch">
      <Card className="mini-stats-wid w-100">
        <CardBody className="h-100">
          <Media>
            <Media body className="w-75">
              <p className="mb-1 text-muted font-size-12 font-weight-medium">{title}</p>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-left align-items-center w-100">
                  <div className="mr-2 mt-1" style={{ marginRight: "1px Solid #424B61", maxWidth: "50%" }}>
                    <div className="d-flex align-items-center gap-1">
                      <div className="font-size-10 card-text">{labelLeft}</div>
                      <RenderIf isTrue={onClickLeft}>
                        <i
                          className="font-size-10 bx bx-right-arrow-circle text-primary cursor-pointer"
                          onClick={onClickLeft}
                        />
                      </RenderIf>
                    </div>
                    <div
                      className="font-size-20 font-weight-semibold inventory-color separation-line-listing pr-1 ellipse"
                      title={valueLeft}
                    >
                      {valueLeft}&nbsp;&nbsp;
                    </div>
                  </div>
                  <div className="mr-2 mt-1 pl-1" style={{ maxWidth: "50%" }}>
                    <div className="d-flex align-items-center gap-1">
                      <div className="font-size-10 card-text">{labelRight}</div>
                      <RenderIf isTrue={onClickRight}>
                        <i
                          className="font-size-10 bx bx-right-arrow-circle text-primary cursor-pointer"
                          onClick={onClickRight}
                        />
                      </RenderIf>
                    </div>
                    <div className="font-size-20 font-weight-semibold inventory-color ellipse" title={valueRight}>
                      {valueRight}
                    </div>
                  </div>
                </div>
              </div>
            </Media>
            <div className="d-flex flex-column">
              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                <span className="avatar-title">
                  <i className={`bx ${icon} font-size-24`} />
                </span>
              </div>
            </div>
          </Media>
        </CardBody>
      </Card>
    </div>
  );
};

export default RepricerListingComparisonCard;
