import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment-timezone";
import CustomPagination from "../../components/Common/CustomPagination";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
// actions
import { fetchEmails, setPreloader, updateS3Object } from "../../store/actions";
import getBrandImageSrc from "../../utils/brandImages";
import { Link } from "react-router-dom";
import { RenderIf, formatNumber } from "../../utils/common";
import { DATE_FORMATS } from "../../constants";
const { FULL_DATE } = DATE_FORMATS;
const UnAccountedEmails = (props) => {
  const [search, setSearch] = useState("");
  const [tabsFilter, setTabFilter] = useState({
    platform: "",
    email_type: "",
    parsed_order_id: "",
    exclude_names: "Harry,Matt",
    unacounted: "true",
    sort_by: "email_date",
    sort_direction: "desc",
  });

  const tableHeaders = [
    { title: "Platform" },
    { title: "Supplier Order Id" },
    { title: "Source Order Id" },
    { title: "Subject" },
    { title: "Order Status" },
    { title: "Email Date" },
    { title: "Customer Name" },
    { title: "Address" },
    { title: "ASIN" },
    { title: "Quantity" },
    { title: "Price" },
    { title: "Shipping" },
    { title: "Tax" },
    { title: "Order Total" },
    { title: "Delivery Date" },
    { title: "Attach" },
  ];

  useEffect(() => {
    props.fetchEmails(tabsFilter);
  }, [tabsFilter]);

  useEffect(() => {
    props.setPreloader(props.loading);
  }, [props.loading]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="row">
            <form
              className="app-search d-none d-lg-block"
              onSubmit={(e) => {
                e.preventDefault();
                setTabFilter({
                  ...tabsFilter,
                  search: search.trim(),
                  page: 1,
                });
              }}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => {
                    if (!e.target.value) {
                      tabsFilter.search && delete tabsFilter.search;
                      setTabFilter({
                        ...tabsFilter,
                        page: 1,
                      });
                      setSearch("");
                    } else {
                      setSearch(e.target.value);
                    }
                  }}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
          </div>
        </CardHeader>

        <CardBody>
          {props.emails?.data?.length ? (
            <>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {/* table headers */}
                      {tableHeaders.map((header, index) => (
                        <th
                          className={
                            /platform|quantity|asin|order status|supplier order id|email date/i.test(header.title)
                              ? "text-center"
                              : ""
                          }
                          key={`table-header ${index}`}
                        >
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* iterating data */}
                    {props.emails &&
                      props.emails.data.map((item, key) => (
                        <tr key={"_parse_email" + key} className="mt-3 mb-3">
                          <td className="text-center">
                            <img src={getBrandImageSrc(item.platform)} alt="store logo" />
                          </td>
                          <td className="text-center">
                            {item.parsed_order_id && (
                              <span>
                                {item.parsed_order_id}
                                <CopyToClipBoard text={item?.parsed_order_id} />
                              </span>
                            )}
                          </td>
                          <td className="text-center">
                            <RenderIf isTrue={item.orderable_id && item.orderable_type === "source_order"}>
                              <span>
                                <Link
                                  to={`/source_orders_details/${item.source_order?.marketplace_order_id}/${item.orderable_id}`}
                                  target="_blank"
                                  className="text-primary mb-1"
                                >
                                  {`${item.source_order?.marketplace_order_id}/${item.orderable_id}`}
                                </Link>
                                <CopyToClipBoard
                                  text={`${item.source_order?.marketplace_order_id}/${item.orderable_id}`}
                                />
                              </span>
                            </RenderIf>
                          </td>
                          <td>
                            <a href={"/email/view/" + item?.s3_key} target="_blank" rel="noopener noreferrer">
                              {item.subject?.substring(0, 50)}
                            </a>
                          </td>
                          <td className="text-center">{item.email_type !== item.subject && item.email_type}</td>
                          <td className="text-center">{moment(item?.email_date).format(FULL_DATE) || ""}</td>
                          <td>{item.customer_info?.name}</td>
                          <td>{item.customer_info?.address}</td>
                          <td className="text-center">{item.customer_info?.asin}</td>
                          <td className="text-center">{item.customer_info?.quantity}</td>
                          <td>{formatNumber(item.customer_info?.price, "float")}</td>
                          <td>{formatNumber(item.customer_info?.shipping, "float")}</td>
                          <td>{formatNumber(item.customer_info?.tax, "float")}</td>
                          <td>{formatNumber(item.customer_info?.order_total, "float")}</td>
                          <td>{item.customer_info?.delivery_date}</td>
                          <td className="text-center">
                            <RenderIf isTrue={item.is_attachment_required}>
                              <i
                                className="bx bx-sm bx-reset cursor-pointer ml-3"
                                onClick={() => props.updateS3Object(item.s3_key, { status: "pending" })}
                              ></i>
                            </RenderIf>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {props.emails && (
                <CustomPagination
                  total={props.emails.count}
                  page={props.emails.page}
                  perPage={props.emails.perPage}
                  tabsFilter={tabsFilter}
                  setTabFilter={setTabFilter}
                />
              )}
            </>
          ) : null}

          {props.emails?.data?.length === 0 && !props.Emails?.loading
            ? (
            <div className="d-flex justify-content-center m-2">
              <div className="d-flex flex-column">
                <h1>No Records Found</h1>
              </div>
            </div>
              )
            : null}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { Emails } = state;
  return Emails;
};

export default connect(mapStatetoProps, {
  fetchEmails,
  setPreloader,
  updateS3Object,
})(UnAccountedEmails);
