import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Alert } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import moment from "moment-timezone";
import { orderBy } from "lodash";

// actions
import { setDownloadLabelModal, downloadReturnLabel, emptyDownloadFileURL } from "../../../store/actions";
import CheckBox from "../../../components/Common/CheckBox";
import { downloadFileClientSide } from "../../../utils/common";

const DownloadReturnLabelModal = (props) => {
  const dispatch = useDispatch();
  const { item, type } = props;
  const files = item?.labels;
  const { error, downloadLabelModalIsOpen, filePath } = useSelector((state) => state.WareHouses);
  const toggleModal = () => dispatch(setDownloadLabelModal(!downloadLabelModalIsOpen));

  useEffect(() => {
    filePath && downloadFileClientSide(filePath);
    dispatch(emptyDownloadFileURL());
  }, [filePath]);

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={downloadLabelModalIsOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">DOWNLOAD RETURN LABEL</h5>
          <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body">
          {error && (
            <Alert color="danger">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          )}
          {files?.length > 0 && (
            <div className="table-responsive overflow-auto" style={{ maxHeight: "220px" }}>
              <table className="table table-centered  mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>File</th>
                    <th>Date</th>
                    <th>Return Pickup</th>
                    <th>Download</th>
                    <th>Preview</th>
                  </tr>
                </thead>
                <tbody>
                  {orderBy(files, "date", "desc").map((file, key) => (
                    <tr key={"_return_label_" + key}>
                      <td>
                        <span className="text-body font-weight-bold">{file?.file_name || ""}</span>
                      </td>
                      <td>{(file?.date && moment(file?.date).format("MMMM Do YYYY, h:mm:ss a")) || ""}</td>
                      <td className="text-center">
                        <CheckBox
                          className="mt-2"
                          disabled={true}
                          state={file?.is_return_pickup || false}
                          setState={(_) => null}
                        />
                      </td>
                      <td
                        className="text-primary cursor-pointer"
                        onClick={() =>
                          dispatch(
                            downloadReturnLabel(item.id, {
                              file_name: file?.file_name,
                              item_type: type,
                              is_preview: false,
                            }),
                          )
                        }
                      >
                        Download
                      </td>
                      <td
                        className="bx bx-sm bx-file-find text-primary cursor-pointer mt-1"
                        onClick={() =>
                          dispatch(
                            downloadReturnLabel(item.id, {
                              file_name: file?.file_name,
                              item_type: type,
                              is_preview: true,
                            }),
                          )
                        }
                      ></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <Row className="d-flex justify-content-end mt-3">
            <Button type="reset" color="secondary" className="mr-3" onClick={() => toggleModal()}>
              Cancel
            </Button>
          </Row>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </React.Fragment>
  );
};

export default DownloadReturnLabelModal;
