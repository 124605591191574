import React, { useEffect } from "react";
import { Col, Card, Row } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import * as $ from "lodash";
import Carousel from "../../../components/Common/Carousel";
import { RenderIf, formatNumber, toPascalCase } from "../../../utils/common";
import { ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import { setViewPlansModal, deletePlan, setAddPlanModal, setEditablePlan } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import StatusBadge from "../../../components/Common/StatusBadge";
import { METADATA_KEYS } from "../../../constants";

function PlanCard ({ dispatch, plan }) {
  const { addPlanModal, editablePlan } = useSelector((state) => state.CustomPlans);
  useEffect(() => {
    editablePlan && dispatch(setAddPlanModal(!addPlanModal));
  }, [editablePlan]);

  return (
    <Col lg={3} className="border rounded plan-border m-2">
      <ConfirmDialogIcon
        icon={{ className: "fa fa-lg fa-trash-alt mt-3 float-right", title: "Delete Plan" }}
        msg={`Are you sure to delete this plan ${formatNumber(+(plan.stripe_price.amount / 100 || 0))}?`}
        onConfirm={() => dispatch(deletePlan(plan.id))}
      />
      <i
        className="fas fa-lg fa-edit mr-2 mt-3 float-right cursor-pointer"
        onClick={() => dispatch(setEditablePlan(plan))}
      />
      <Card className="plan-box border-1 m-3">
        <RenderIf isTrue={plan?.stripe_price?.title}>
          <div className="d-flex justify-content-center mt-5">
            <StatusBadge status={plan?.stripe_price?.title} colorsMapping={{ [plan?.stripe_price?.title]: "orange" }} />
          </div>
        </RenderIf>
        <div className="py-4 text-center">
          <h2>
            <sup>
              <small>$</small>
            </sup>{" "}
            {plan?.stripe_price?.amount && (
              <>
                {Number(+(plan.stripe_price.amount / 100).toFixed(2)).toLocaleString()}
                <small>/{plan.stripe_price?.recurring_interval || "month"}</small>
              </>
            )}
          </h2>
        </div>
        <div className="plan-features mt-5">
          {$.map($.keys($.pick(plan?.stripe_price?.data, METADATA_KEYS)), (key, index) => (
            <div key={"_feature_" + index} className="d-flex">
              <i className={"bx bx-sm bx-check text-primary mr-3"}></i>{" "}
              <p className="mt-1">{getDescription(plan?.stripe_price?.data, key)}</p>
            </div>
          ))}
        </div>
      </Card>
    </Col>
  );
}

function getDescription (metadata, key) {
  if (key.includes("ao")) return metadata[key].toLocaleString() + " Free AO Credits";
  else if (key.includes("va")) return toPascalCase(metadata[key].toLocaleString()) + " VA Accounts";
  else if (key.includes("repricer")) return toPascalCase(key);
  else if (key.includes("fees"))
    return (
      <span>
        {toPascalCase(key)}
        <span className="text-warning">{`(${formatNumber(metadata[key] || 0)})`}</span>
      </span>
    );
  else return toPascalCase(metadata[key] + " " + key);
}

const PlansModal = (props) => {
  const dispatch = useDispatch();
  const { viewPlansModal } = useSelector((state) => state.CustomPlans);
  const toggleModal = () => dispatch(setViewPlansModal(!viewPlansModal));

  const { item } = props;
  const plans = item?.plans || [];

  return (
    <Modal size={plans.length ? "xl" : "md"} isOpen={viewPlansModal} toggle={toggleModal}>
      {plans.length ? (
        <div>
          <div className="modal-header">
            <h4>Total Plans: {plans.length}</h4>
            <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
            </button>
          </div>
          <div className="modal-body">
            <Row className="justify-content-center m-3">
              <Carousel
                activeSlide={0}
                hideControls={plans.length <= 3}
                controlClass="carousel-btns"
                items={$.chunk(
                  $.orderBy(
                    plans.map((plan, key) => <PlanCard plan={plan} key={"_pricing_" + key} dispatch={dispatch} />),
                    (plan) => +plan?.stripe_price?.amount,
                  ),
                  3,
                )}
              />
            </Row>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center m-2">
          <div className="d-flex flex-column">
            <h1>No Plans</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PlansModal;
