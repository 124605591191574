import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Media, FormGroup, UncontrolledAlert } from "reactstrap";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { verifyRole, getUserInfo, RenderIf, formatNumber } from "../../utils/common";
import { abilitiesFor } from "../../utils/abilities";

// Pages Components
import SalesAndExpensesChart from "./components/SalesAndExpensesChart";
import TotalOrdersChart from "./components/TotalOrdersChart";
import ProfitChart from "./components/ProfitChart";
import ReturnsChart from "./components/ReturnsChart";
import UsedCreditsChart from "./components/UsedCreditsChart";
import IssueStats from "./components/IssueStats";
import ListingStats from "./components/ListingStats";
import RefresherStats from "./components/RefresherStats";
import SupplierStats from "./components/SupplierStats";
import ErrorStats from "./components/ErrorStats";
import OrderStatusStats from "./components/OrderStatusStats";
import OnboardProgress from "./components/OnboardProgress";
import BuyBoxStats from "./components/BuyBoxStats";
import TopSellingItems from "./components/TopSellingItems";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Onboarding from "../../pages/Onboarding/index";

// actions
import {
  fetchMarketplaceAccounts,
  fetchDailyReport,
  fetchAllGraphs,
  setPreloader,
  setOnboardingStep,
  setShowOnboarding,
} from "../../store/actions";
import getBrandImageSrc from "../../utils/brandImages";
import { onboardingStepsIds } from "../../constants";
import StampsTransitionAlert from "../../components/Common/StampsTransitionAlert";
import ArchiveBadge from "../../components/Common/ArchiveBadge";

const { Option } = components;

const StoreOption = (props) => (
  <Option {...props} className="d-flex align-items-center">
    {getBrandImageSrc(props.data.marketplace)
      ? (
      <img src={getBrandImageSrc(props.data.marketplace)} alt="store logo" />
        )
      : null}
    {props.data.label}
    {props.data.is_archive && ArchiveBadge()}
  </Option>
);

const Dashboard = (_) => {
  const userAbility = abilitiesFor(getUserInfo()?.role);
  const isAllowed = verifyRole("admin", "owner", "readOnly");
  const isReadOnly = verifyRole("readOnly");
  const isAdminOnly = verifyRole("admin", "readOnly");
  const [marketPlace, setMarketPlace] = useState({
    value: "",
    label: "All Accounts",
  });
  const dispatch = useDispatch();
  const [accountId, setAccountId] = useState("");
  const { accounts, loading: marketPlaceAccountLoading } = useSelector((state) => state.MarketPlaceAccount);

  const {
    account: tenantAccount,
    loading: tenantAccountLoading,
    accountsLoading,
    showOnboarding,
    onBoardingStep,
  } = useSelector((state) => state.Settings);

  const { timezone } = useSelector((state) => state.Layout);
  const { dailyReport, dailyReportLoading } = useSelector((state) => state.Graph);
  const data = {
    totalOrders: dailyReport?.data?.orders?.orders,
    totalSales: dailyReport?.data?.sales?.total,
    totalExpenses: dailyReport?.data?.expenses?.total,
    totalProfit: dailyReport?.data?.profits?.total,
  };

  const reports = [
    {
      title: "Total Orders Today",
      iconClass: "bx bx-cart-alt",
      description: formatNumber(data.totalOrders || 0, "int"),
      restricted: false,
    },
    {
      title: "Total Sales Today",
      iconClass: "bx bx-dollar",
      description: formatNumber(data.totalSales || 0),
      avg: data.totalSales / data.totalOrders || "0",
      restricted: true,
    },
    {
      title: "Total Expenses Today",
      iconClass: "bx bx-money",
      description: formatNumber(data.totalExpenses || 0),
      avg: data.totalExpenses / data.totalOrders || "0",
      restricted: true,
    },
    {
      title: "Total Profit Today",
      iconClass: "bx bxs-badge-dollar",
      description: formatNumber(data.totalProfit || 0),
      avg: data.totalProfit / data.totalOrders || "0",
      restricted: true,
    },
  ];

  const showEmailForwarding = (_) => {
    const onboard = tenantAccount?.data?.onboard;
    return (
      ["payment", "store_added", "confirm_email", "tos_accepted"].every((col) => onboard?.[col]) &&
      !onboard?.forwarder_setting_visited
    );
  };

  const fetchAccounts = (id) => dispatch(fetchMarketplaceAccounts({ marketplace_id: id }));

  const fetchReport = (id) => {
    dispatch(
      fetchDailyReport({
        marketplace_id: id,
        date: moment().format("YYYY-MM-DD"),
        utc_offset: moment().format("Z"),
      }),
    );
  };

  const fetchGraphs = (id) => {
    const currentDate = moment();
    const oneWeekBefore = moment().subtract(6, "days");
    dispatch(
      fetchAllGraphs({
        start_date: oneWeekBefore.format("YYYY-MM-DD"),
        end_date: currentDate.format("YYYY-MM-DD"),
        utc_offset: moment().format("Z"),
        marketplace_id: id,
      }),
    );
  };

  useEffect(() => {
    if (tenantAccount?.data?.stores_count > 0) {
      !marketPlaceAccountLoading && !accounts && accountId && fetchAccounts(accountId);
      !dailyReportLoading && fetchReport(accountId);
      fetchGraphs(accountId);
    }
  }, [accountId, tenantAccount, timezone]);

  useEffect(() => {
    dispatch(setPreloader(tenantAccountLoading || marketPlaceAccountLoading));
  }, [tenantAccountLoading, marketPlaceAccountLoading]);

  return (
    <React.Fragment>
      {showOnboarding && (
        <Onboarding
          activeStep={onBoardingStep}
          onBoarding={false}
          active={showOnboarding}
          setActive={(...args) => dispatch(setShowOnboarding(...args))}
        />
      )}
      <div className="page-content">
        {showEmailForwarding()
          ? (
          <UncontrolledAlert color="primary">
            <i className="bx bx-info-circle pr-2"></i>
            <span className="black-color" style={{ fontSize: "13px" }}>
              You have not set up email forwarding for your account yet.{" "}
              <span
                className="text-primary"
                onClick={(_) => {
                  dispatch(setOnboardingStep(onboardingStepsIds.emailForwarder));
                  dispatch(setShowOnboarding(true));
                }}
              >
                {" "}
                Click here
              </span>
              {" to set up email forwarding."}
            </span>
          </UncontrolledAlert>
            )
          : null}
        <StampsTransitionAlert />

        {tenantAccount?.data?.stores_count > 0 || tenantAccount?.data?.onboard?.store_added ? (
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Dashboard"
              children={
                <div className="d-flex">
                  <FormGroup className="select2-container ml-2" style={{ width: "200px" }}>
                    <Select
                      name="marketplace_id"
                      value={{
                        value: marketPlace.value,
                        label: marketPlace.label,
                      }}
                      onChange={(e) => {
                        setAccountId(e.value);
                        setMarketPlace({ value: e.value, label: e.label });
                      }}
                      options={accounts?.data.reduce(
                        (init, acc, i) => {
                          init.push({
                            label: acc.name || "",
                            value: acc.id ? acc.id : acc.value,
                            marketplace: acc.marketplace,
                            key: i,
                            is_archive: acc.is_archive,
                          });
                          return init;
                        },
                        [{ value: "", label: "All Accounts" }],
                      )}
                      classNamePrefix="select2-selection"
                      components={{ Option: StoreOption }}
                    />
                  </FormGroup>
                </div>
              }
            />

            <Row>
              {reports
                .filter((report) => (report.restricted ? isAllowed : true))
                .map((report, key) => (
                  <Col key={"_col_" + key}>
                    <Card className="mini-stats-wid" style={{ height: "80%" }}>
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">{report.title}</p>
                            <h4 className="mb-0">{report.description}</h4>
                            <span className="text-muted font-weight-medium">
                              {report.avg ? "Avg. " + formatNumber(report.avg) : ""}
                            </span>
                          </Media>
                          <div className="d-flex flex-column">
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className={"bx " + report.iconClass + " font-size-24"}></i>
                              </span>
                            </div>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
            {isAllowed && (
              <Row>
                <Col>
                  <OnboardProgress />
                </Col>
              </Row>
            )}
            <Row>
              <Col md="6">
                <TotalOrdersChart timezone={timezone} accountId={accountId} />
                <RenderIf isTrue={getUserInfo()?.account_id === 2 && isAllowed}>
                  <SalesAndExpensesChart timezone={timezone} accountId={accountId} />
                </RenderIf>
                <ListingStats
                  timezone={timezone}
                  accountId={accountId}
                  platform={accounts?.data?.find((acc) => acc.id === accountId)?.marketplace}
                />
                {!isReadOnly && <IssueStats timezone={timezone} accountId={accountId} abilities={userAbility} />}{" "}
                <ReturnsChart timezone={timezone} accountId={accountId} />
                <UsedCreditsChart />
                {<TopSellingItems accountId={accountId} abilities={userAbility} />}
              </Col>
              <Col md="6">
                {verifyRole("admin", "owner", "readOnly", "manager") && (
                  <ProfitChart timezone={timezone} accountId={accountId} />
                )}
                <RenderIf isTrue={getUserInfo()?.account_id === 2}>
                  <BuyBoxStats timezone={timezone} accountId={accountId} />
                </RenderIf>
                <SupplierStats abilities={userAbility} />
                {!isReadOnly && <ErrorStats timezone={timezone} accountId={accountId} abilities={userAbility} />}
                {isAdminOnly && <RefresherStats />}
                <OrderStatusStats timezone={timezone} statuses={dailyReport?.data?.status} />
              </Col>
            </Row>
          </Container>
        ) : (
          !marketPlaceAccountLoading &&
          !tenantAccountLoading &&
          !accountsLoading && <h1 className="text-center">No Store Found</h1>
        )}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
