import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Label, Input, Row, Col, InputGroupAddon, InputGroup, Alert, Spinner } from "reactstrap";

import Breadcrumb from "../../../components/Common/Breadcrumb";
import { fetchAccountSettings, fetchAgencyClients, updateAgencyProfit } from "../../../store/actions";

const OtherSettings = ({ account }) => {
  const dispatch = useDispatch();
  const agencyProfit = useRef(account?.data?.agency_profit || 0);
  const [overrideAll, setoverrideAll] = useState(false);
  const { loading, agencyProfitSuccess } = useSelector((state) => state.Settings);
  const initialValues = {
    id: "",
    agency_profit: "",
  };

  const validate = (values) => {
    const errors = {};
    if (values.agency_profit < 0 || values.agency_profit > 100) {
      errors.agency_profit = "Default value for agency profit must be in 0-100.";
    }
    if ([null, undefined, ""].includes(values.agency_profit)) {
      errors.agency_profit = "Default value for agency profit is required.";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      dispatch(
        updateAgencyProfit({
          data: { agency_profit: values.agency_profit, override_clients: overrideAll },
          id: values.id,
        }),
      );
      dispatch(fetchAccountSettings(false));
    },
  });

  useEffect(() => {
    if (account?.data) {
      formik.setFieldValue("agency_profit", account.data.agency_profit);
      formik.setFieldValue("id", account.data.id);
      agencyProfit.current = account.data.agency_profit;
    }

    overrideAll && agencyProfit.current !== formik.values.agency_profit && dispatch(fetchAgencyClients());
  }, [account, agencyProfit]);

  return (
    <>
      <Breadcrumb title="Other Settings" />
      {agencyProfitSuccess
        ? (
        <div className="auto-hide">
          <Alert color="success">{agencyProfitSuccess}</Alert>
        </div>
          )
        : null}
      <Card>
        <CardBody>
          <Row>
            <Col lg={3} md={4}>
              <Label>Average Agency %</Label>
              <div className="d-flex align-items-center">
                <InputGroup className="averge-input">
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  <Input
                    name="agency_profit"
                    type="number"
                    min="0"
                    max="100"
                    value={formik.values.agency_profit}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </InputGroup>
                {agencyProfit.current !== formik.values.agency_profit
                  ? (
                  <div className="d-flex pl-3 ">
                    <i
                      className="bx bx-check font-size-24 text-success cursor-pointer"
                      title="Submit"
                      onClick={formik.handleSubmit}
                    />
                    <i
                      className="bx bx-x font-size-24 text-danger cursor-pointer ml-2"
                      title="Default"
                      onClick={() => {
                        formik.setFieldValue("agency_profit", agencyProfit.current);
                      }}
                    />
                  </div>
                    )
                  : (
                      loading && <Spinner className="ml-3" size="sm" animation="border" role="status" />
                    )}
              </div>
              {formik.touched.agency_profit && formik.errors.agency_profit
                ? (
                <small className="text-danger mt-1">{formik.errors.agency_profit}</small>
                  )
                : null}
              {agencyProfit.current !== formik.values.agency_profit && (
                <div className="custom-control custom-switch custom-switch-lg my-3 ">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="override_clients"
                    checked={overrideAll}
                    onChange={() => null}
                  />
                  <label onClick={() => setoverrideAll(!overrideAll)} className="custom-control-label">
                    Override for all clients
                  </label>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default OtherSettings;
