import React from "react";
import { Row, Col, Container } from "reactstrap";
import step2Img from "../../assets/images/twoFAInstructions/Two-FA-Step2.webp";
import step3Img from "../../assets/images/twoFAInstructions/Two-FA-Step3.webp";
import step4Img from "../../assets/images/twoFAInstructions/Two-FA-Step4.webp";
import step5Img from "../../assets/images/twoFAInstructions/Two-FA-Step5.webp";

const TwoFactorAuthInstructions = () => {
  const steps = [
    {
      title: "Step 2: Click 'Login & Security'",
      image: step2Img,
      alt: "Login & Security Image",
      width: "60%",
    },
    {
      title: "Step 3: Click 'Edit' next to Two-Step Verification (2SV) Settings",
      image: step3Img,
      alt: "2SV Settings Image",
      width: "60%",
    },
    {
      title: "Step 4: Select Authenticator App and then click Can't scan barcode",
      image: step4Img,
      alt: "Authenticator App Image",
      width: "50%",
    },
    {
      title: "Step 5: Copy/paste code into 2FA field on Ecom Circles",
      image: step5Img,
      alt: "2FA Image",
      width: "40%",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center mb-5">
                <h4>INSTRUCTIONS TO GET 2FA</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="justify-content-center align-items-center">
                <h6 className="mb-3">
                  Step 1: Login to Amazon and go to
                  <a
                    href={"https://www.amazon.com/gp/css/homepage.html"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary mb-1"
                  >
                    {" "}
                    Your Accounts Page
                  </a>
                </h6>
                {steps.map((i, _index) => (
                  <p className="mb-3" key={_index}>
                    <h6>{i.title}</h6>
                    <img src={i.image} alt={i.alt} style={{ maxWidth: i.width }}></img>
                  </p>
                ))}
                <h6 className="mb-3">
                  Step 6: <b>Important!! </b> Be sure to scan the QR code with your own device using Google
                  Authenticator app or
                  <a
                    href={"https://authy.com/download/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary mb-1"
                  >
                    {" "}
                    Authy{" "}
                  </a>
                  so that you can login to the account yourself also
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TwoFactorAuthInstructions;
